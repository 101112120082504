import { faker } from "@faker-js/faker";
import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  RRuleSet: string;
  Time: string;
  Timezone: string;
};




export type AccountVerificationMethod =
  | 'email'
  | 'txt'
  | 'selfServiceLink'
  | 'selfServiceQr'
  | '%future added value';

export type ActiveAthleteTransferOffsetConnection = {
  readonly edges: ReadonlyArray<AthleteTransfer>;
  readonly pageInfo: OffsetPageInfo;
};

export type Activity = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: ActivityType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type ActivityTemplate = {
  readonly id: Scalars['ID'];
  readonly activity: Activity;
  readonly hasDateModifications: Scalars['Boolean'];
};


export type ActivityTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

/**
 * Modification of an ActivityTemplate, tied to either an individual date or a day of the week
 * Currently only time edits (start and end) are supported
 */
export type ActivityTemplateModification = {
  readonly activityTemplate: ActivityTemplate;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type ActivityType =
  | 'practice'
  | 'lift'
  | 'conditioning'
  | 'competition'
  | 'class'
  | '%future added value';

export type AddActivityInput = {
  readonly name: Scalars['String'];
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
  readonly type: ActivityType;
};

export type AddActivityTemplateCalendarInput = {
  readonly activity: AddActivityInput;
  readonly dateModifications: ReadonlyArray<MealPlanDateModificationInput>;
  readonly dayOfWeekModifications: ReadonlyArray<MealPlanDayOfWeekModificationInput>;
};

export type AddAgreementFormInput = {
  readonly title: Scalars['String'];
  readonly url: Scalars['String'];
  readonly acceptAgreementText: Scalars['String'];
};

export type AddAgreementFormPayload = {
  readonly agreementForm: AgreementForm;
};

export type AddAgreementFormSubmissionInput = {
  readonly agreementFormId: Scalars['ID'];
};

export type AddAgreementFormSubmissionPayload = {
  readonly agreementForm: AgreementForm;
};

export type AddAndLinkTeamworksTeamsInput = {
  readonly id: Scalars['ID'];
  readonly linkedTeams: ReadonlyArray<LinkTeamworksTeamInput>;
  readonly newTeams: ReadonlyArray<CreateTeamworksTeamInput>;
  readonly addedSportsMappings: ReadonlyArray<SportsMappingInput>;
  readonly addPositionsMappings: ReadonlyArray<PositionsMappingInput>;
};

export type AddAndLinkTeamworksTeamsPayload = {
  readonly org: Org;
};

export type AddAnthropometryEntryInput = {
  readonly athleteId: Scalars['ID'];
  readonly anthropometryEntry: AnthropometryEntryFormInput;
};

export type AddAthleteDislikedFoodGroupPayload = {
  readonly athlete: Athlete;
};

export type AddAthleteDislikedFoodPayload = {
  readonly athlete: Athlete;
};

export type AddAthleteEmailInput = {
  readonly athleteId: Scalars['ID'];
  readonly email: Scalars['String'];
};

export type AddAthleteEmailPayload = {
  readonly athlete: Athlete;
};

export type AddAthleteLikedFoodGroupPayload = {
  readonly athlete: Athlete;
};

export type AddAthleteLikedFoodPayload = {
  readonly athlete: Athlete;
};

export type AddAthletePhoneNumberInput = {
  readonly athleteId: Scalars['ID'];
  readonly phoneNumber: Scalars['String'];
};

export type AddAthletePhoneNumberPayload = {
  readonly athlete: Athlete;
};

export type AddBulkOrderInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly teamId: Scalars['ID'];
  readonly deliveryLocation: Scalars['String'];
  readonly childMealMenuPlates: ReadonlyArray<AddMealMenuPlateInput>;
};

export type AddBulkOrderPayload = {
  readonly bulkOrder: BulkOrder;
  readonly skippedUsers: ReadonlyArray<SkippedUserPayload>;
  readonly skippedPlates: ReadonlyArray<SkippedPlatePayload>;
};

export type AddDeliveryLocationInput = {
  readonly name: Scalars['String'];
  readonly deliveryInstructions: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly teamLabelIds: ReadonlyArray<Scalars['ID']>;
  readonly googlePlaceId: Scalars['String'];
  readonly lat: Scalars['Float'];
  readonly long: Scalars['Float'];
};

export type AddDeliveryLocationPayload = {
  readonly deliveryLocation: DeliveryLocation;
};

export type AddDeviceInput = {
  readonly deviceType: DeviceType;
  readonly deviceId: Scalars['String'];
};

export type AddDevicePayload = {
  readonly result: Scalars['Boolean'];
};

export type AddEventInput = {
  readonly entityId: Scalars['ID'];
  readonly entityType: Scalars['String'];
  readonly datetime: Scalars['DateTime'];
  readonly type: Scalars['String'];
};

export type AddEventPayload = {
  readonly event: Event;
};

export type AddFoodItemToGroceryListInput = {
  readonly groceryListId: Scalars['ID'];
  readonly servingId: Scalars['ID'];
  readonly amount: Scalars['Int'];
};

export type AddFoodLogActivityWithTemplateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activityTemplateId: Scalars['ID'];
  readonly activity: AddActivityInput;
};

export type AddFoodLogActivityWithTemplateForAthletePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type AddFoodLogActivityWithTemplateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activityTemplateId: Scalars['ID'];
  readonly activity: AddActivityInput;
};

export type AddFoodLogActivityWithTemplatePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type AddFoodLogActivityWithoutTemplateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activity: AddActivityInput;
};

export type AddFoodLogActivityWithoutTemplateForAthletePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type AddFoodLogActivityWithoutTemplateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activity: AddActivityInput;
};

export type AddFoodLogActivityWithoutTemplatePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type AddFoodLogMealCommentInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly comment: CommentInput;
};

export type AddFoodLogMealCommentPayload = {
  readonly foodLogMeal: FoodLogMeal;
  readonly comment: Comment;
};

export type AddFoodLogMealImageInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly imageUrl: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type AddFoodLogMealImagePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealPayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithTemplateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly mealTemplateId: Scalars['ID'];
  readonly meal: AddMealInput;
};

export type AddFoodLogMealWithTemplateForAthletePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithTemplateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly mealTemplateId: Scalars['ID'];
  readonly meal: AddMealInput;
};

export type AddFoodLogMealWithTemplatePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithoutTemplateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly meal: AddMealInput;
};

export type AddFoodLogMealWithoutTemplateForAthletePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithoutTemplateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly meal: AddMealInput;
};

export type AddFoodLogMealWithoutTemplatePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddMealInput = {
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type AddMealMenuDiningStationInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly diningStationTemplateId: Maybe<Scalars['ID']>;
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
};

export type AddMealMenuLogItemForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealMenuId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly item: MealMenuLogItemInput;
};

export type AddMealMenuLogItemInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly item: MealMenuLogItemInput;
};

export type AddMealMenuLogItemPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly mealMenuLogItem: MealMenuLogItem;
};

export type AddMealMenuLogItemsForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealMenuId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly items: ReadonlyArray<MealMenuLogItemInput>;
};

export type AddMealMenuLogItemsInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly items: ReadonlyArray<MealMenuLogItemInput>;
};

export type AddMealMenuLogItemsPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly mealMenuLogItems: ReadonlyArray<MealMenuLogItem>;
};

export type AddMealMenuPlateInput = {
  readonly name: Scalars['String'];
  readonly athleteIds: ReadonlyArray<Scalars['ID']>;
  readonly items: ReadonlyArray<AddMealMenuPlateItemInput>;
};

export type AddMealMenuPlateItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<MealMenuPlateItemOptionInput>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type AddMealOptionInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly mealOption: MealOptionInput;
};

export type AddMealOptionPayload = {
  readonly mealOption: MealOption;
};

export type AddMealTemplateCalendarInput = {
  readonly meal: AddMealInput;
  readonly dateModifications: ReadonlyArray<MealPlanDateModificationInput>;
  readonly dayOfWeekModifications: ReadonlyArray<MealPlanDayOfWeekModificationInput>;
};

export type AddMenuItemChoiceInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly required: Scalars['Boolean'];
  readonly maxOptionsCount: Maybe<Scalars['Int']>;
  readonly options: ReadonlyArray<AddMenuItemChoiceOptionInput>;
};

export type AddMenuItemChoiceOptionInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly canEditAmount: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
  readonly maxAmount: Scalars['Float'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type AddMenuItemHistoryMissingIngredientsInput = {
  readonly menuItemHistoryId: Scalars['ID'];
  readonly menuItems: ReadonlyArray<MenuItemMissingIngredientsInput>;
};

export type AddMenuItemHistoryMissingIngredientsPayload = {
  readonly menuItemHistory: MenuItemHistory;
};

export type AddMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly appearance: MenuItemAppearanceInput;
};

export type AddMenuOrderCommentInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly comment: CommentInput;
};

export type AddMenuOrderCommentPayload = {
  readonly menuOrder: MenuOrder;
  readonly comment: Comment;
};

export type AddMenuOrderForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealMenuId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly pickupTime: Scalars['DateTime'];
  readonly items: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly orderItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly logItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly images: Maybe<ReadonlyArray<ImageInput>>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
  readonly expectedOrderCount: Maybe<Scalars['Int']>;
};

export type AddMenuOrderForAthletePayload = {
  readonly menuOrder: MenuOrder;
};

export type AddMenuOrderImageInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly imageUrl: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type AddMenuOrderImagePayload = {
  readonly menuOrder: MenuOrder;
};

export type AddMenuOrderItemRatingInput = {
  readonly menuItemId: Scalars['ID'];
  readonly menuOrderItemId: Scalars['ID'];
  readonly value: RatingValue;
  readonly review: Maybe<Scalars['String']>;
};

export type AddMenuOrderItemRatingsInput = {
  readonly ratings: ReadonlyArray<AddMenuOrderItemRatingInput>;
};

export type AddMenuOrderItemRatingsPayload = {
  readonly menuOrderItems: ReadonlyArray<MenuOrderItem>;
};

export type AddMenuOrderPayload = {
  readonly menuOrder: MenuOrder;
};

export type AddMenuOrderWithTemplateInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly items: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly orderItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly logItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly images: Maybe<ReadonlyArray<ImageInput>>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
  readonly expectedOrderCount: Maybe<Scalars['Int']>;
};

export type AddMenuOrderWithTemplatePayload = {
  readonly menuOrder: MenuOrder;
};

export type AddMenuOrderWithoutTemplateInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly items: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly orderItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly logItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly images: Maybe<ReadonlyArray<ImageInput>>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
  readonly expectedOrderCount: Maybe<Scalars['Int']>;
};

export type AddMenuOrderWithoutTemplatePayload = {
  readonly menuOrder: MenuOrder;
};

export type AddNixBrandedFoodByUpcInput = {
  readonly upc: Scalars['String'];
};

export type AddNixBrandedFoodByUpcPayload = {
  readonly food: Maybe<Food>;
};

export type AddNixBrandedFoodInput = {
  readonly nixBrandType: Scalars['Int'];
  readonly nixItemId: Scalars['ID'];
};

export type AddNixBrandedFoodPayload = {
  readonly food: Maybe<Food>;
};

export type AddNixCommonFoodInput = {
  readonly name: Scalars['String'];
  readonly nixTagId: Scalars['String'];
};

export type AddNixCommonFoodPayload = {
  readonly food: Maybe<Food>;
};

export type AddPositionInput = {
  readonly sportId: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type AddPositionPayload = {
  readonly position: Position;
};

export type AddRecipeImageInput = {
  readonly recipeId: Scalars['ID'];
  readonly image: ImageInput;
};

export type AddRecipeImagePayload = {
  readonly recipe: Recipe;
};

export type AddRestaurantLocationContactInput = {
  readonly locationId: Scalars['ID'];
  readonly restaurantLocationContact: RestaurantLocationContactInput;
};

export type AddRestaurantLocationContactPayload = {
  readonly restaurantLocationContact: RestaurantLocationContact;
};

export type AddRestaurantLocationInput = {
  readonly restaurantId: Scalars['String'];
  readonly lat: Scalars['Float'];
  readonly long: Scalars['Float'];
  readonly googlePlaceId: Scalars['String'];
  readonly notes: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly deliveryLocationIds: ReadonlyArray<Scalars['ID']>;
};

export type AddRestaurantLocationPayload = {
  readonly restaurantLocation: RestaurantLocation;
};

export type AddRestaurantMenuLinkInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly restaurantId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly deliveryLocationId: Maybe<Scalars['ID']>;
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly allowCustomOrders: Scalars['Boolean'];
  readonly allowPlateOrders: Scalars['Boolean'];
  readonly sections: ReadonlyArray<RestaurantMenuSectionLinkInput>;
  readonly plates: ReadonlyArray<AddRestaurantMenuLinkPlateInput>;
};

export type AddRestaurantMenuLinkOrderForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuLinkId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly items: ReadonlyArray<RestaurantMenuLinkOrderItemInput>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkOrderForAthletePayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
  readonly timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLinkOrderInput = {
  readonly restaurantMenuLinkId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly items: ReadonlyArray<RestaurantMenuLinkOrderItemInput>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkOrderPayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
  readonly timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLinkPayload = {
  readonly restaurantMenuLink: RestaurantMenuLink;
};

export type AddRestaurantMenuLinkPlateInput = {
  readonly position: Scalars['Int'];
  readonly bulkOrderAmount: Maybe<Scalars['Int']>;
  readonly items: ReadonlyArray<AddRestaurantMenuLinkPlateItemInput>;
  readonly isDefault: Maybe<Scalars['Boolean']>;
};

export type AddRestaurantMenuLinkPlateItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<RestaurantMenuLinkPlateItemOptionInput>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type AddRestaurantMenuLinkPlateOrderForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuLinkPlateId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkPlateOrderForAthletePayload = {
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
  readonly timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLinkPlateOrderInput = {
  readonly restaurantMenuLinkPlateId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkPlateOrderPayload = {
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
  readonly timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLogForAthleteInput = {
  readonly dateTime: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly items: ReadonlyArray<RestaurantMenuLogItemInput>;
  readonly type: MealType;
};

export type AddRestaurantMenuLogForAthletePayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type AddRestaurantMenuLogInput = {
  readonly dateTime: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly items: ReadonlyArray<RestaurantMenuLogItemInput>;
  readonly type: MealType;
};

export type AddRestaurantMenuLogItemForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly timelineMeal: TimelineMealInput;
  readonly item: RestaurantMenuLogItemInput;
};

export type AddRestaurantMenuLogItemInput = {
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly timelineMeal: TimelineMealInput;
  readonly item: RestaurantMenuLogItemInput;
};

export type AddRestaurantMenuLogItemPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly restaurantMenuLogItem: RestaurantMenuLogItem;
};

export type AddRestaurantMenuLogItemsForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly timelineMeal: TimelineMealInput;
  readonly items: ReadonlyArray<RestaurantMenuLogItemInput>;
};

export type AddRestaurantMenuLogItemsInput = {
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly timelineMeal: TimelineMealInput;
  readonly items: ReadonlyArray<RestaurantMenuLogItemInput>;
};

export type AddRestaurantMenuLogItemsPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly restaurantMenuLogItems: ReadonlyArray<RestaurantMenuLogItem>;
};

export type AddRestaurantMenuLogPayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type AddRestaurantMenuSectionInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
};

export type AddSportInput = {
  readonly name: Scalars['String'];
};

export type AddSportPayload = {
  readonly sport: Sport;
};

export type AddTeamLabelInput = {
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type AddTeamLabelPayload = {
  readonly teamLabel: TeamLabel;
};

export type AddTimelineMealCommentForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly text: Scalars['String'];
  readonly imageUrl: Maybe<Scalars['String']>;
};

export type AddTimelineMealCommentForMeInput = {
  readonly timelineMeal: TimelineMealInput;
  readonly text: Scalars['String'];
  readonly imageUrl: Maybe<Scalars['String']>;
};

export type AddTimelineMealCommentPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly comment: Comment;
};

export type AddTimelineMealForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly type: MealType;
  readonly mealTemplateId: Maybe<Scalars['ID']>;
};

export type AddTimelineMealImageForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly url: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type AddTimelineMealImageInput = {
  readonly timelineMeal: TimelineMealInput;
  readonly url: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type AddTimelineMealImagePayload = {
  readonly timelineMeal: TimelineMeal;
};

export type AddTimelineMealInput = {
  readonly name: Scalars['String'];
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly type: MealType;
  readonly mealTemplateId: Maybe<Scalars['ID']>;
};

export type AddTimelineMealPayload = {
  readonly timelineMeal: TimelineMeal;
};

export type AddTimelineMealServingAmountForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly servingAmount: CreateServingAmountInput;
};

export type AddTimelineMealServingAmountInput = {
  readonly timelineMeal: TimelineMealInput;
  readonly servingAmount: CreateServingAmountInput;
};

export type AddTimelineMealServingAmountPayload = {
  readonly timelineMeal: TimelineMeal;
};

export type Address = {
  readonly displayName: Scalars['String'];
  readonly lat: Scalars['Float'];
  readonly long: Scalars['Float'];
};

export type AdvancedSelectionGroup = {
  readonly conditions: ReadonlyArray<TagCondition>;
};

export type AdvancedSelectionGroupInput = {
  readonly conditions: ReadonlyArray<AssignTagConditionInput>;
};

export type AdvancedSelectionInput = {
  readonly groups: ReadonlyArray<AdvancedSelectionGroupInput>;
  readonly includeAthleteIds: ReadonlyArray<Scalars['ID']>;
  readonly excludeAthleteIds: ReadonlyArray<Scalars['ID']>;
};

export type AdvancedSelectionType = {
  readonly id: Scalars['ID'];
  readonly groups: ReadonlyArray<AdvancedSelectionGroup>;
  readonly includeAthletes: ReadonlyArray<Athlete>;
  readonly excludeAthletes: ReadonlyArray<Athlete>;
};

export type AgreementForm = {
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly url: Scalars['String'];
  readonly acceptAgreementText: Scalars['String'];
};

export type AgreementFormOffsetConnection = {
  readonly edges: ReadonlyArray<AgreementForm>;
  readonly pageInfo: OffsetPageInfo;
};

export type AiAugmented = {
  readonly isAiGenerated: Scalars['Boolean'];
  readonly isAiTranscribed: Scalars['Boolean'];
};

export type AiType =
  | 'generated'
  | 'transcribed'
  | '%future added value';

export type AnthropometryEntry = {
  readonly id: Scalars['ID'];
  readonly heightInCm: Maybe<Scalars['Float']>;
  readonly weightInKg: Scalars['Float'];
  readonly leanBodyMassInKg: Maybe<Scalars['Float']>;
  readonly bodyFatMassInKg: Maybe<Scalars['Float']>;
  readonly skeletalMuscleMassInKg: Maybe<Scalars['Float']>;
  readonly trunkFatInKg: Maybe<Scalars['Float']>;
  readonly dryLeanMassInKg: Maybe<Scalars['Float']>;
  readonly visceralFatArea: Maybe<Scalars['Float']>;
  readonly percentBodyFat: Maybe<Scalars['Float']>;
  readonly datetime: Scalars['DateTime'];
  readonly sex: SexType;
  readonly type: AnthropometryEntryType;
  readonly comment: Maybe<Scalars['String']>;
  readonly boneMineralDensity: Maybe<Scalars['Float']>;
  readonly boneMineralContent: Maybe<Scalars['Float']>;
  readonly boneArea: Maybe<Scalars['Float']>;
  readonly boneMass: Maybe<Scalars['Float']>;
  readonly boneMineralDensityZScore: Maybe<Scalars['Float']>;
  readonly boneMineralDensityTScore: Maybe<Scalars['Float']>;
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly leanBodyMass: Maybe<Scalars['Float']>;
  readonly bodyFatMass: Maybe<Scalars['Float']>;
  readonly dryLeanMass: Maybe<Scalars['Float']>;
  readonly skeletalMuscleMass: Maybe<Scalars['Float']>;
  readonly trunkFat: Maybe<Scalars['Float']>;
};

export type AnthropometryEntryField =
  | 'percentBodyFat'
  | 'height'
  | 'weight'
  | 'leanBodyMass'
  | '%future added value';

export type AnthropometryEntryFormInput = {
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly leanBodyMass: Maybe<Scalars['Float']>;
  readonly bodyFatMass: Maybe<Scalars['Float']>;
  readonly percentBodyFat: Maybe<Scalars['Float']>;
  readonly datetime: Scalars['DateTime'];
  readonly sex: SexType;
  readonly type: AnthropometryEntryType;
  readonly comment: Maybe<Scalars['String']>;
  readonly boneMineralDensity: Maybe<Scalars['Float']>;
  readonly boneMass: Maybe<Scalars['Float']>;
  readonly boneMineralContent: Maybe<Scalars['Float']>;
  readonly boneArea: Maybe<Scalars['Float']>;
  readonly boneMineralDensityZScore: Maybe<Scalars['Float']>;
  readonly boneMineralDensityTScore: Maybe<Scalars['Float']>;
  readonly dryLeanMass: Maybe<Scalars['Float']>;
  readonly skeletalMuscleMass: Maybe<Scalars['Float']>;
  readonly trunkFat: Maybe<Scalars['Float']>;
  readonly visceralFatArea: Maybe<Scalars['Float']>;
};

export type AnthropometryEntryMetricField =
  | 'percentBodyFat'
  | 'heightInCm'
  | 'weightInKg'
  | 'leanBodyMass'
  | '%future added value';

export type AnthropometryEntryType =
  | 'estimate'
  | 'dexa'
  | 'bodpod'
  | 'bioelectricalImpedance'
  | 'isak'
  | 'weighIn'
  | '%future added value';

export type AnthropometryForm = {
  readonly questions: ReadonlyArray<AnthropometryFormQuestion>;
};

export type AnthropometryFormField =
  | 'height'
  | 'weight'
  | 'percentBodyFat'
  | '%future added value';

export type AnthropometryFormInput = {
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly percentBodyFat: Maybe<Scalars['Float']>;
};

export type AnthropometryFormQuestion = {
  readonly field: AnthropometryFormField;
};

export type AnthropometryTemplate = {
  readonly id: Scalars['ID'];
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly heightInCm: Maybe<Scalars['Float']>;
  readonly weightInKg: Maybe<Scalars['Float']>;
  readonly percentBodyFat: Maybe<Scalars['Float']>;
};

export type AnthropometryTemplateInput = {
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly percentBodyFat: Maybe<Scalars['Float']>;
};

export type AppModeRoles = {
  readonly athlete: ReadonlyArray<Role>;
  readonly dietitian: ReadonlyArray<Role>;
  readonly chef: ReadonlyArray<Role>;
  readonly nonRdAdmin: ReadonlyArray<Role>;
};

export type ArchiveAthleteInput = {
  readonly id: Scalars['ID'];
};

export type ArchiveAthletePayload = {
  readonly athlete: Athlete;
};

export type ArchiveGoalConfigurationInput = {
  readonly id: Scalars['ID'];
};

export type ArchiveGoalConfigurationPayload = {
  readonly goalConfigurationId: Scalars['ID'];
};

export type AssignMealPlanTemplateToAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly anthropometry: MealPlanFromTemplateAnthropometryInput;
  readonly foodPreferences: Maybe<FoodPreferenceInput>;
  readonly mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type AssignMealPlanTemplateToAthletePayload = {
  readonly mealPlan: MealPlan;
};

export type AssignMetricMealPlanTemplateToAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly anthropometry: MealPlanFromTemplateMetricAnthropometryInput;
  readonly foodPreferences: Maybe<FoodPreferenceInput>;
  readonly mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type AssignTagConditionInput = {
  readonly isComplement: Scalars['Boolean'];
  readonly type: TagConditionType;
  readonly entityIds: ReadonlyArray<Scalars['ID']>;
};

export type Athlete = {
  readonly id: Scalars['ID'];
  readonly activationLinkSent: Maybe<Scalars['DateTime']>;
  readonly isNotemealOnly: Scalars['Boolean'];
  readonly isProfileNotemealOnly: Scalars['Boolean'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly sex: SexType;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly headshotUrl: Maybe<Scalars['String']>;
  readonly secaUid: Maybe<Scalars['String']>;
  readonly inbodyUid: Maybe<Scalars['String']>;
  readonly jerseyNumber: Maybe<Scalars['String']>;
  readonly team: Team;
  readonly position: Maybe<Position>;
  readonly likedFoods: ReadonlyArray<Food>;
  readonly dislikedFoods: ReadonlyArray<Food>;
  readonly likedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly dislikedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly currentGoal: Maybe<Goal>;
  readonly goals: ReadonlyArray<Goal>;
  readonly mostRecentNote: Maybe<Note>;
  readonly notes: ReadonlyArray<Note>;
  readonly mealPlans: ReadonlyArray<MealPlan>;
  readonly sharedMealPlans: ReadonlyArray<MealPlan>;
  readonly sharedMealPlanTemplates: ReadonlyArray<MealPlanTemplate>;
  readonly priorityMealPlanDaysOfWeekInRange: ReadonlyArray<MealPlanDayOfWeekAssignment>;
  readonly mealPlanIndividualDatesInRange: ReadonlyArray<MealPlanIndividualDateAssignment>;
  /** @deprecated Pending web notifications overhaul */
  readonly mealPlanNotifications: ReadonlyArray<Notification>;
  /** @deprecated Pending web notifications overhaul */
  readonly foodLogNotifications: ReadonlyArray<Notification>;
  readonly timelineMealNotifications: ReadonlyArray<Notification>;
  readonly defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettings>;
  readonly defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettings>;
  readonly defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettings>;
  readonly defaultHideAnthropometry: Maybe<Scalars['Boolean']>;
  readonly macroDisplaySettings: MacroMealPlanDisplaySettings;
  readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettings;
  readonly noTargetsDisplaySettings: NoTargetsDisplaySettings;
  readonly hideAnthropometry: Scalars['Boolean'];
  readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntry>;
  readonly mostRecentAnthropometryEntryWith: Maybe<AnthropometryEntry>;
  readonly mostRecentAnthropometryEntryForRmrMethod: Maybe<AnthropometryEntry>;
  readonly anthropometryEntries: ReadonlyArray<AnthropometryEntry>;
  readonly isArchived: Scalars['Boolean'];
  readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettings>;
  /** @deprecated Remove when getting rid of FoodLogMeal */
  readonly foodLogMealCount: Scalars['Int'];
  readonly imageUploadCount: Scalars['Int'];
  readonly mealPlanViewCount: Scalars['Int'];
  readonly timelineMealCount: Scalars['Int'];
  readonly user: User;
  readonly orgMembership: Maybe<OrgMembership>;
  readonly timelineItemsInRange: ReadonlyArray<TimelineItem>;
  /** @deprecated pending forced update for multiple MenuOrders per menu */
  readonly orderForMenu: Maybe<MenuOrder>;
  readonly ordersForMenu: ReadonlyArray<MenuOrder>;
  readonly ordersForMenus: ReadonlyArray<MenuOrder>;
  readonly kioskPreviewOrderInfo: ReadonlyArray<MenuOrderInfo>;
  readonly checkInsForMealMenus: ReadonlyArray<MealMenuCheckIn>;
  readonly myNamedTagsPreview: MyNamedTagsPreviewPayload;
  readonly myNamedTagsOffsetConnection: NamedTagOffsetConnection;
  readonly allOrderedNamedTags: ReadonlyArray<NamedTag>;
  readonly printableTagsForAthlete: ReadonlyArray<NamedTag>;
  readonly timelineMealsInRange: ReadonlyArray<TimelineMeal>;
  readonly timelineDate: TimelineDate;
  readonly timelineDatesInRange: ReadonlyArray<TimelineDate>;
  readonly recentTimelineCalendarDatesWithActivity: ReadonlyArray<Scalars['String']>;
  readonly mealMenusOnDate: ReadonlyArray<MealMenu>;
  readonly mealPlanDateAssignmentsInRange: ReadonlyArray<MealPlanDateAssignment>;
  readonly upcomingMealMenus: ReadonlyArray<MealMenu>;
  readonly mealMenusCursorConnection: MealMenusCursorConnection;
  readonly activeTransfer: Maybe<AthleteTransfer>;
  readonly hasEverHadGoalTrackerGoals: Scalars['Boolean'];
  readonly goalTrackerGoals: ReadonlyArray<GoalTrackerGoal>;
  readonly assignedToMealMenu: Scalars['Boolean'];
};


export type AthletePriorityMealPlanDaysOfWeekInRangeArgs = {
  start: Scalars['Date'];
  end: Maybe<Scalars['Date']>;
};


export type AthleteMealPlanIndividualDatesInRangeArgs = {
  start: Scalars['Date'];
  end: Maybe<Scalars['Date']>;
};


export type AthleteMostRecentAnthropometryEntryWithArgs = {
  fields: ReadonlyArray<AnthropometryEntryField>;
};


export type AthleteMostRecentAnthropometryEntryForRmrMethodArgs = {
  rmrMethod: Maybe<RmrMethod>;
};


export type AthleteFoodLogMealCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
};


export type AthleteImageUploadCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
};


export type AthleteMealPlanViewCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
};


export type AthleteTimelineMealCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
  requireOrder: Maybe<Scalars['Boolean']>;
  requireLog: Maybe<Scalars['Boolean']>;
};


export type AthleteTimelineItemsInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AthleteOrderForMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type AthleteOrdersForMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type AthleteOrdersForMenusArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type AthleteKioskPreviewOrderInfoArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type AthleteCheckInsForMealMenusArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type AthleteMyNamedTagsPreviewArgs = {
  limit: Scalars['Int'];
  priorityTagIds: ReadonlyArray<Scalars['ID']>;
  smartTagsFirst: Maybe<Scalars['Boolean']>;
};


export type AthleteMyNamedTagsOffsetConnectionArgs = {
  input: OffsetPaginationInput;
};


export type AthleteTimelineMealsInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AthleteTimelineDateArgs = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type AthleteTimelineDatesInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type AthleteRecentTimelineCalendarDatesWithActivityArgs = {
  limit: Scalars['Int'];
  timezone: Scalars['Timezone'];
};


export type AthleteMealMenusOnDateArgs = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type AthleteMealPlanDateAssignmentsInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type AthleteUpcomingMealMenusArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AthleteMealMenusCursorConnectionArgs = {
  anchorDate: Scalars['DateTime'];
  input: CursorPaginationInput;
};


export type AthleteGoalTrackerGoalsArgs = {
  date: Scalars['Date'];
};


export type AthleteAssignedToMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};

export type AthleteAnthropometryFilter =
  | 'has_anthropometry_entry'
  | 'no_anthropometry_entry'
  | '%future added value';

export type AthleteAttendeePayload = {
  readonly athlete: Athlete;
  readonly checkedIn: Scalars['Boolean'];
};

export type AthleteCursorConnection = {
  readonly edges: ReadonlyArray<Athlete>;
  readonly pageInfo: CursorPageInfo;
};

export type AthleteCursorConnectionWithSelectAll = {
  readonly edges: ReadonlyArray<Athlete>;
  readonly pageInfo: CursorPageInfo;
  readonly selectAllInfo: SelectAllInfo;
};

export type AthleteFilter =
  | 'active'
  | 'no_link_sent'
  | 'link_expired'
  | 'archived'
  | '%future added value';

export type AthleteFoodGroupPreferenceInput = {
  readonly athleteId: Scalars['ID'];
  readonly foodGroupId: Scalars['ID'];
};

export type AthleteFoodPreferenceInput = {
  readonly athleteId: Scalars['ID'];
  readonly foodId: Scalars['ID'];
};

export type AthleteFormInput = {
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly sex: SexType;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly positionId: Maybe<Scalars['ID']>;
  readonly inbodyUid: Maybe<Scalars['String']>;
  readonly secaUid: Maybe<Scalars['String']>;
  readonly jerseyNumber: Maybe<Scalars['String']>;
  readonly removedTagIds: ReadonlyArray<Scalars['ID']>;
  readonly addedTagIds: ReadonlyArray<Scalars['ID']>;
};

export type AthleteGoalConfigurationAndTracker = {
  readonly id: Scalars['ID'];
  readonly goalTitle: Scalars['String'];
  readonly goalCategory: Scalars['String'];
  readonly goalCompletionType: Scalars['String'];
  readonly goalTarget: Maybe<Scalars['Int']>;
  readonly goalTargetUnit: Maybe<Scalars['String']>;
  readonly startDate: Maybe<Scalars['DateTime']>;
  readonly endDate: Maybe<Scalars['DateTime']>;
  readonly goalCompletionSummary: GoalCompletionSummary;
  readonly dailyGoalTracker: ReadonlyArray<DailyGoalTrackerGoal>;
};

export type AthleteOffsetConnection = {
  readonly edges: ReadonlyArray<Athlete>;
  readonly pageInfo: OffsetPageInfo;
};

export type AthleteSelfOnboardedNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
};

export type AthleteSummary = {
  readonly mealLogCount: Scalars['Int'];
  readonly dayLogCount: Scalars['Int'];
  readonly commentCount: Scalars['Int'];
  readonly prepPlanToGroceryListCount: Scalars['Int'];
  readonly imageUploadCount: Scalars['Int'];
  readonly sharedMealPlanCount: Scalars['Int'];
  readonly noteCount: Scalars['Int'];
};

export type AthleteTag = Tag & {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
  readonly athletes: ReadonlyArray<Athlete>;
};

export type AthleteTransfer = {
  readonly note: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly athlete: Athlete;
  readonly releasedByOrg: Org;
  readonly receivedByOrg: Maybe<Org>;
  readonly orgGroup: OrgGroup;
};

export type AuthInput = {
  readonly allow: ReadonlyArray<Role>;
  readonly allowStaff: Maybe<Scalars['Boolean']>;
};

export type BonAppetitCafe = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type BonAppetitMenuIdentity = MealMenuIdentity & {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type BonAppetitMenuInput = {
  readonly date: Scalars['Date'];
  readonly mealId: Scalars['String'];
};

export type BonAppetitMenusPreview = {
  readonly cafeId: Scalars['String'];
  readonly name: Scalars['String'];
  readonly days: ReadonlyArray<BonAppetitMenusPreviewDay>;
};

export type BonAppetitMenusPreviewDay = {
  readonly date: Scalars['String'];
  readonly dayParts: ReadonlyArray<BonAppetitMenusPreviewDayPart>;
};

export type BonAppetitMenusPreviewDayPart = {
  readonly id: Scalars['String'];
  readonly label: Scalars['String'];
  readonly startTime: Scalars['String'];
  readonly endTime: Scalars['String'];
};

export type Brand = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly usdaManufacturerName: Maybe<Scalars['String']>;
  readonly foodCount: Scalars['Int'];
};

export type BrandInputForm = {
  readonly name: Scalars['String'];
  readonly usdaManufacturerName: Maybe<Scalars['String']>;
};

export type BrandedFood = Food & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly kcalPer100g: Scalars['Float'];
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly nixItemId: Maybe<Scalars['String']>;
  readonly nixTagId: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly hasFullAccess: Scalars['Boolean'];
  readonly org: Maybe<Org>;
  readonly groceryListCategory: Maybe<GroceryListCategory>;
  readonly servings: ReadonlyArray<Serving>;
  readonly defaultServing: Serving;
  readonly foodGroups: ReadonlyArray<FoodGroup>;
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  /** @deprecated Field no longer supported */
  readonly genericFood: Maybe<GenericFood>;
  /** @deprecated Field no longer supported */
  readonly genericFoodId: Maybe<Scalars['String']>;
  readonly brand: Maybe<Brand>;
  readonly createdById: Maybe<Scalars['ID']>;
  readonly createdBy: Maybe<User>;
  readonly updatedAt: Scalars['DateTime'];
  readonly showGramServing: Scalars['Boolean'];
  readonly showOunceServing: Scalars['Boolean'];
};

export type BrandedFoodCursorConnection = {
  readonly edges: ReadonlyArray<BrandedFood>;
  readonly pageInfo: CursorPageInfo;
};

export type BrandedFoodFormInput = {
  readonly name: Scalars['String'];
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly exchangeTypes: ReadonlyArray<ExchangeType>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly groceryListCategoryId: Maybe<Scalars['String']>;
  readonly brandId: Maybe<Scalars['String']>;
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly foodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly mccanceId: Maybe<Scalars['String']>;
  readonly mccanceName: Maybe<Scalars['String']>;
};

export type BrandedFoodOffsetConnection = {
  readonly edges: ReadonlyArray<BrandedFood>;
  readonly pageInfo: OffsetPageInfo;
};

export type BulkAssignMealPlanTemplateJob = {
  readonly id: Scalars['ID'];
  readonly queuedAt: Maybe<Scalars['DateTime']>;
  readonly processingAt: Maybe<Scalars['DateTime']>;
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly createdById: Maybe<Scalars['ID']>;
  readonly createdBy: Maybe<User>;
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly weightTargetTypeId: Scalars['ID'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly athleteCount: Scalars['Int'];
  readonly athleteSucceededCount: Scalars['Int'];
  readonly athleteFailedCount: Scalars['Int'];
};

export type BulkAssignMealPlanTemplateJobOffsetConnection = {
  readonly edges: ReadonlyArray<BulkAssignMealPlanTemplateJob>;
  readonly pageInfo: OffsetPageInfo;
};

export type BulkCopyMealPlanInput = {
  readonly athleteIds: ReadonlyArray<Scalars['ID']>;
  readonly mealPlanId: Scalars['ID'];
  readonly mealPlan: MealPlanEditFormInput;
  readonly copyFoodPreferences: Scalars['Boolean'];
  readonly copyPickListServingIds: Scalars['Boolean'];
};

export type BulkCopyMealPlanNewInput = {
  readonly athleteIds: ReadonlyArray<Scalars['ID']>;
  readonly mealPlanId: Scalars['ID'];
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly copyFoodPreferences: Scalars['Boolean'];
  readonly copyPickListServingIds: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type BulkCopyMealPlanPayload = {
  readonly mealPlans: ReadonlyArray<MealPlan>;
};

export type BulkDeleteMealMenusInput = {
  readonly mealMenuIds: ReadonlyArray<Scalars['ID']>;
};

export type BulkDeleteMealMenusPayload = {
  readonly mealMenuIds: ReadonlyArray<Scalars['ID']>;
};

export type BulkEditMealMenusInput = {
  readonly mealMenuIds: ReadonlyArray<Scalars['ID']>;
  readonly hasTimingEdit: Maybe<Scalars['Boolean']>;
  readonly hasNotificationEdit: Maybe<Scalars['Boolean']>;
  readonly hasSharingEdit: Maybe<Scalars['Boolean']>;
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly startTime: Maybe<Scalars['Time']>;
  readonly timezone: Maybe<Scalars['Timezone']>;
  readonly durationInMinutes: Maybe<Scalars['Int']>;
  readonly lastOrderTimeBeforeEndInMinutes: Maybe<Scalars['Int']>;
  readonly prepTimeInMinutes: Maybe<Scalars['Int']>;
};

export type BulkEditMealMenusPayload = {
  readonly mealMenus: ReadonlyArray<StandaloneMealMenu>;
};

export type BulkImportCreateAndDeprecateGenericFoodInput = {
  readonly id: Scalars['ID'];
  readonly food: GenericFoodFormInput;
  readonly servings: ReadonlyArray<FoodServingInput>;
  readonly nutrientAmounts: ReadonlyArray<FoodNutrientAmountFormInput>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type BulkImportCreateAndDeprecateGenericFoodsInput = {
  readonly foods: ReadonlyArray<BulkImportCreateAndDeprecateGenericFoodInput>;
};

export type BulkImportCreateAndDeprecateGenericFoodsPayload = {
  readonly genericFoods: ReadonlyArray<GenericFood>;
};

export type BulkImportCreateGenericFoodsInput = {
  readonly foods: ReadonlyArray<CreateGenericFoodInput>;
};

export type BulkImportCreateGenericFoodsPayload = {
  readonly genericFoods: ReadonlyArray<GenericFood>;
};

export type BulkImportCreateRestautantBrandedFoodsInput = {
  readonly foods: ReadonlyArray<CreateRestaurantBrandedFoodInput>;
};

export type BulkImportCreateRestautantBrandedFoodsPayload = {
  readonly brandedFoods: ReadonlyArray<BrandedFood>;
};

export type BulkImportEditRestautantBrandedFoodsInput = {
  readonly foods: ReadonlyArray<EditRestaurantBrandedFoodInput>;
};

export type BulkImportEditRestautantBrandedFoodsPayload = {
  readonly brandedFoods: ReadonlyArray<BrandedFood>;
};

export type BulkOrder = {
  readonly id: Scalars['ID'];
  readonly createdBy: User;
  readonly createdAt: Scalars['DateTime'];
  readonly pickupTime: Scalars['DateTime'];
  readonly deliveryLocation: Scalars['String'];
  readonly team: Team;
  readonly code: Scalars['String'];
  readonly mealMenu: MealMenu;
  readonly childMealMenuPlates: ReadonlyArray<MealMenuPlate>;
  readonly mealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly status: MenuOrderItemStatus;
};

export type BulkOrderPublication = {
  readonly scope: BulkOrderPublisherScope;
  readonly payload: BulkOrderPublicationPayload;
};

export type BulkOrderPublicationPayload = AddBulkOrderPayload | EditBulkOrderPayload | RemoveBulkOrderPayload | { readonly __typename?: "%other" };

export type BulkOrderPublisherScope = {
  readonly mealMenuId: Scalars['ID'];
};

export type BulkOrderStatusNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly entityId: Scalars['ID'];
  readonly bulkOrder: BulkOrder;
};

export type BulkOrderStatusPublication = {
  readonly scope: BulkOrderPublisherScope;
  readonly payload: UpdateBulkOrderStatusPayload;
};

export type BulkOrderSubscriberScope = {
  readonly mealMenuIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type CalorieBudget = {
  readonly id: Scalars['ID'];
  readonly rmrMethod: Maybe<RmrMethod>;
  readonly rmrCalories: Maybe<Scalars['Int']>;
  readonly activityFactor: Scalars['Float'];
  readonly kcalOffset: Scalars['Int'];
  readonly goalSnapshot: Goal;
};

export type CalorieBudgetInput = {
  readonly rmrMethod: Maybe<RmrMethod>;
  readonly rmrCalories: Maybe<Scalars['Int']>;
  readonly activityFactor: Scalars['Float'];
  readonly kcalOffset: Scalars['Int'];
};

export type CbordBusinessUnit = {
  readonly oid: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type CbordFacility = CbordBusinessUnit & {
  readonly oid: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly businessUnits: Maybe<ReadonlyArray<CbordBusinessUnit>>;
};

export type CbordMenuGroupInput = {
  readonly serviceMenuOids: ReadonlyArray<Scalars['Int']>;
  readonly baseServiceMenuOid: Maybe<Scalars['Int']>;
};

export type CbordMenuIdentity = MealMenuIdentity & {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type CbordMenuItem = {
  readonly itemOid: Scalars['Int'];
  readonly serviceName: Scalars['String'];
  readonly servedUofmName: Scalars['String'];
  readonly serviceStationOid: Maybe<Scalars['Int']>;
  readonly serviceStationSort: Maybe<Scalars['String']>;
  readonly serviceStationName: Maybe<Scalars['String']>;
};

export type CbordServiceMenu = {
  readonly oid: Scalars['Int'];
  readonly serviceDate: Scalars['DateTime'];
  readonly meal: Scalars['String'];
  readonly menuItems: ReadonlyArray<CbordMenuItem>;
};

export type CbordServiceUnit = CbordBusinessUnit & {
  readonly oid: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly facilityOid: Maybe<Scalars['Int']>;
};

export type CheckGroceryListCustomItemInput = {
  readonly id: Scalars['ID'];
};

export type CheckGroceryListCustomItemPayload = {
  readonly id: Scalars['ID'];
};

export type CheckGroceryListFoodItemInput = {
  readonly id: Scalars['ID'];
};

export type CheckGroceryListFoodItemPayload = {
  readonly id: Scalars['ID'];
};

export type ClientCredential = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly clientId: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type ClientCredentialOffsetConnection = {
  readonly edges: ReadonlyArray<ClientCredential>;
  readonly pageInfo: OffsetPageInfo;
};

export type ComboTag = Tag & {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
  readonly combinationMethod: TagCombinationMethod;
  readonly childTags: ReadonlyArray<Tag>;
  readonly namedChildTags: ReadonlyArray<NamedTag>;
};

export type Comment = {
  readonly id: Scalars['ID'];
  readonly text: Scalars['String'];
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly by: User;
  readonly at: Scalars['DateTime'];
  readonly notification: Maybe<Notification>;
};

export type CommentInput = {
  readonly text: Scalars['String'];
  readonly imageUrl: Maybe<Scalars['String']>;
};

export type CompassMenuIdentity = MealMenuIdentity & {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type CompassMenuInput = {
  readonly date: Scalars['Date'];
  readonly mealPeriodId: Scalars['Int'];
};

export type CompassLocation = {
  readonly locationId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly mealPeriods: ReadonlyArray<CompassMealPeriod>;
};

export type CompassMealPeriod = {
  readonly displayOrder: Scalars['Int'];
  readonly mealPeriodId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly stations: ReadonlyArray<CompassStation>;
};

export type CompassMenuItem = {
  readonly id: Scalars['Int'];
  readonly date: Scalars['String'];
  readonly displayOrder: Scalars['Int'];
  readonly stationId: Scalars['Int'];
  readonly mealPeriodId: Scalars['Int'];
  readonly locationId: Scalars['Int'];
  readonly portion: Scalars['String'];
  readonly name: Scalars['String'];
};

export type CompassRecipe = {
  readonly name: Scalars['String'];
  readonly shortName: Scalars['String'];
  readonly masterRefNumber: Scalars['String'];
};

export type CompassStation = {
  readonly displayOrder: Scalars['Int'];
  readonly stationId: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type ConvertMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly isOneOff: Scalars['Boolean'];
  readonly description: Maybe<Scalars['String']>;
  readonly appearance: MenuItemAppearanceInput;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly addChoices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly editChoices: ReadonlyArray<EditMenuItemChoiceInput>;
  readonly removeChoices: ReadonlyArray<RemoveMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type ConvertedServingInput = {
  readonly servingId: Scalars['ID'];
  readonly toUnitId: Scalars['ID'];
};

export type CopyExchangeSetInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type CopyExchangeSetPayload = {
  readonly exchangeSet: ExchangeSet;
};

export type CopyMacroMealPlanTemplateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type CopyMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type CopyMealMenuInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly newStartDay: Scalars['Date'];
};

export type CopyMealMenusInput = {
  readonly mealMenuInputs: ReadonlyArray<CopyMealMenuInput>;
};

export type CopyMealMenusPayload = {
  readonly newMealMenus: ReadonlyArray<MealMenu>;
};

export type CopyMealPlanInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealPlanId: Scalars['ID'];
  readonly mealPlan: MealPlanEditFormInput;
  readonly copyFoodPreferences: Scalars['Boolean'];
  readonly copyPickListServingIds: Scalars['Boolean'];
};

export type CopyMealPlanNewInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealPlanId: Scalars['ID'];
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly copyFoodPreferences: Scalars['Boolean'];
  readonly copyPickListServingIds: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type CopyMealPlanPayload = {
  readonly mealPlan: MealPlan;
};

export type CopyPrepPlanInput = {
  readonly newStart: Scalars['Date'];
  readonly newEnd: Scalars['Date'];
  readonly prepPlanId: Scalars['ID'];
};

export type CopyPrepPlanPayload = {
  readonly prepPlan: PrepPlan;
};

export type CreateAnthropometryEntriesPayload = {
  readonly anthropometryEntries: ReadonlyArray<AnthropometryEntry>;
};

export type CreateAnthropometryEntryInput = {
  readonly athleteId: Scalars['ID'];
  readonly anthropometryEntry: AnthropometryEntryFormInput;
};

export type CreateAnthropometryEntryPayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type CreateAthleteInput = {
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
  readonly goal: Maybe<GoalFormInput>;
  readonly anthropometryEntries: Maybe<ReadonlyArray<AnthropometryEntryFormInput>>;
  readonly accountVerificationMethod: Maybe<AccountVerificationMethod>;
  readonly isNotemealOnly: Scalars['Boolean'];
};

export type CreateAthletePayload = {
  readonly athlete: Athlete;
};

export type CreateAthleteTransferInput = {
  readonly athleteId: Scalars['ID'];
  readonly orgGroupId: Scalars['ID'];
  readonly note: Scalars['String'];
};

export type CreateAthleteTransferPayload = {
  readonly athleteTransfer: AthleteTransfer;
};

export type CreateAthletesPayload = {
  readonly athletes: ReadonlyArray<Athlete>;
};

export type CreateBrandInput = {
  readonly name: Scalars['String'];
  readonly usdaManufacturerName: Maybe<Scalars['String']>;
};

export type CreateBrandPayload = {
  readonly brand: Brand;
};

export type CreateBrandedFoodInput = {
  readonly food: BrandedFoodFormInput;
  readonly servings: ReadonlyArray<FoodServingInput>;
  readonly nutrientAmounts: ReadonlyArray<FoodNutrientAmountFormInput>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type CreateBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type CreateBulkAssignMealPlanTemplateJobInput = {
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly weightTargetTypeId: Scalars['ID'];
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly athleteIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateClientCredentialInput = {
  readonly name: Scalars['String'];
};

export type CreateClientCredentialPayload = {
  readonly clientCredential: ClientCredential;
  readonly clientSecret: Scalars['String'];
};

export type CreateCuisineInput = {
  readonly name: Scalars['String'];
};

export type CreateCuisinePayload = {
  readonly cuisine: Cuisine;
};

export type CreateCustomTagInput = {
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly athleteIds: ReadonlyArray<Scalars['ID']>;
  readonly isPrintable: Maybe<Scalars['Boolean']>;
};

export type CreateDietInput = {
  readonly name: Scalars['String'];
};

export type CreateDietPayload = {
  readonly diet: Diet;
};

export type CreateDiningStationTemplateInput = {
  readonly name: Scalars['String'];
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
};

export type CreateDiningStationTemplatePayload = {
  readonly diningStationTemplate: DiningStationTemplate;
};

export type CreateExchangeInput = {
  readonly exchange: ExchangeInput;
  readonly exchangeServingList: ExchangeServingListInput;
};

export type CreateExchangeSetInput = {
  readonly name: Scalars['String'];
  readonly exchanges: ReadonlyArray<CreateExchangeInput>;
};

export type CreateExchangeSetPayload = {
  readonly exchangeSet: ExchangeSet;
};

export type CreateFoodGroupInput = {
  readonly name: Scalars['String'];
  readonly source: Maybe<Scalars['String']>;
  readonly foodIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateFoodGroupPayload = {
  readonly foodGroup: FoodGroup;
};

export type CreateFoodNutrientAmountFormInput = {
  readonly nutrientName: Scalars['String'];
  readonly amount: Scalars['Float'];
};

export type CreateFoodServingsInput = {
  readonly foodId: Scalars['ID'];
  readonly servings: ReadonlyArray<FoodServingInput>;
};

export type CreateFoodServingsPayload = {
  readonly servings: ReadonlyArray<Serving>;
};

export type CreateGenericFoodInput = {
  readonly food: GenericFoodFormInput;
  readonly servings: ReadonlyArray<FoodServingInput>;
  readonly nutrientAmounts: ReadonlyArray<FoodNutrientAmountFormInput>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type CreateGenericFoodPayload = {
  readonly genericFood: GenericFood;
};

export type CreateGoalConfigurationBaseInput = {
  readonly athleteId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly emoji: Scalars['String'];
  readonly categoryId: Scalars['ID'];
  readonly timezone: Scalars['String'];
  readonly reminders: ReadonlyArray<SetGoalReminderConfigurationInput>;
  readonly target: Maybe<CreateGoalQuantityInput>;
};

export type CreateGoalConfigurationPayload = {
  readonly goalConfiguration: GoalConfiguration;
};

export type CreateGoalConfigurationWithDaysOfWeekInput = {
  readonly input: CreateGoalConfigurationBaseInput;
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly dayOfWeekAssignments: ReadonlyArray<DayOfWeek>;
};

export type CreateGoalConfigurationWithIndividualDatesInput = {
  readonly input: CreateGoalConfigurationBaseInput;
  readonly individualDates: ReadonlyArray<Scalars['Date']>;
};

export type CreateGoalInput = {
  readonly athleteId: Scalars['ID'];
  readonly goal: GoalFormInput;
};

export type CreateGoalPayload = {
  readonly goal: Goal;
};

export type CreateGoalQuantityInput = {
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export type CreateGroceryListCustomItemInput = {
  readonly name: Scalars['String'];
  readonly groceryListId: Scalars['String'];
};

export type CreateGroceryListCustomItemPayload = {
  readonly groceryListId: Scalars['String'];
};

export type CreateMacroMealPlanTemplateInput = {
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly additionalState: Maybe<Scalars['String']>;
  readonly macroProtocol: MealPlanTemplateMacroProtocolInput;
  readonly meals: ReadonlyArray<MacroMealPlanTemplateMealInput>;
  readonly foodPreferences: FoodPreferenceInput;
  readonly activities: ReadonlyArray<MealPlanTemplateActivityInput>;
};

export type CreateMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type CreateMealMenusInput = {
  readonly starts: ReadonlyArray<Scalars['DateTime']>;
  readonly timezone: Scalars['Timezone'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly diningStations: ReadonlyArray<AddMealMenuDiningStationInput>;
  readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLinkInput>;
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  readonly isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  readonly theme: Maybe<Scalars['String']>;
};

export type CreateMealMenusPayload = {
  readonly mealMenus: ReadonlyArray<StandaloneMealMenu>;
};

export type CreateMealPlanCalendarInput = {
  readonly scheduleId: Scalars['ID'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly individualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly removeConflictingIndividualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly dayOfWeekPriorities: Maybe<ReadonlyArray<DayOfWeekPriorityInput>>;
  readonly mealTemplates: ReadonlyArray<AddMealTemplateCalendarInput>;
  readonly activityTemplates: ReadonlyArray<AddActivityTemplateCalendarInput>;
};

export type CreateMealPlanFromFoodLogsNewInput = {
  readonly athleteId: Scalars['ID'];
  readonly foodLogMealTemplates: ReadonlyArray<FoodLogMealTemplateInput>;
  readonly foodLogActivityTemplates: ReadonlyArray<AddActivityInput>;
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly macroProtocol: MacroProtocolNewInput;
  readonly foodPreferences: FoodPreferenceInput;
};

export type CreateMealPlanFromFoodLogsPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanFromFoodLogsWithNewGoalPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanFromFoodLogsWithOldGoalPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanFromMealPlanTemplateInput = {
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly anthropometry: MealPlanFromTemplateAnthropometryInput;
  readonly foodPreferences: Maybe<FoodPreferenceInput>;
  readonly mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
};

export type CreateMealPlanFromMealPlanTemplatePayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanNewInput = {
  readonly athleteId: Scalars['ID'];
  readonly calendar: CreateMealPlanCalendarInput;
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly macroProtocol: MacroProtocolNewInput;
  readonly foodPreferences: FoodPreferenceInput;
};

export type CreateMealPlanPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanWithNewGoalPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanWithOldGoalPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMenuItemInput = {
  readonly isOneOff: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly appearance: MenuItemAppearanceInput;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly choices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type CreateMetricAnthropometryEntryInput = {
  readonly athleteId: Scalars['ID'];
  readonly anthropometryEntry: MetricAnthropometryEntryFormInput;
};

export type CreateMetricAthleteInput = {
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
  readonly goal: Maybe<GoalFormInput>;
  readonly anthropometryEntries: Maybe<ReadonlyArray<MetricAnthropometryEntryFormInput>>;
  readonly accountVerificationMethod: Maybe<AccountVerificationMethod>;
  readonly isNotemealOnly: Scalars['Boolean'];
};

export type CreateMetricMealPlanFromFoodLogsInput = {
  readonly athleteId: Scalars['ID'];
  readonly foodLogMealTemplates: ReadonlyArray<FoodLogMealTemplateInput>;
  readonly foodLogActivityTemplates: ReadonlyArray<AddActivityInput>;
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly macroProtocol: MetricMacroProtocolInput;
  readonly foodPreferences: FoodPreferenceInput;
};

export type CreateMetricMealPlanFromMealPlanTemplateInput = {
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly anthropometry: MealPlanFromTemplateMetricAnthropometryInput;
  readonly foodPreferences: Maybe<FoodPreferenceInput>;
  readonly mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
};

export type CreateMetricMealPlanInput = {
  readonly athleteId: Scalars['ID'];
  readonly calendar: CreateMealPlanCalendarInput;
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly macroProtocol: MetricMacroProtocolInput;
  readonly foodPreferences: FoodPreferenceInput;
};

export type CreateNoteInput = {
  readonly athleteId: Scalars['ID'];
  readonly note: NoteFormInput;
};

export type CreateNotePayload = {
  readonly note: Note;
};

export type CreateNoteTypeInput = {
  readonly name: Scalars['String'];
};

export type CreateNoteTypePayload = {
  readonly noteType: NoteType;
};

export type CreateOrgFromTeamworksOrgInput = {
  readonly teamworksId: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type CreateOrgFromTeamworksOrgPayload = {
  readonly org: Org;
};

export type CreateOrgGroupInput = {
  readonly name: Scalars['String'];
  readonly allowsResourceSharing: Scalars['Boolean'];
  readonly allowsAthleteTransfer: Scalars['Boolean'];
  readonly parentOrgGroupId: Maybe<Scalars['ID']>;
  readonly orgIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateOrgGroupPayload = {
  readonly orgGroup: OrgGroup;
};

export type CreateOrgInput = {
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly allowsPublicAccess: Scalars['Boolean'];
  readonly memberships: ReadonlyArray<CreateOrgMembershipAsStaffInput>;
  readonly localeCode: Scalars['String'];
  readonly territoryName: Maybe<Scalars['String']>;
};

export type CreateOrgMembershipAsStaffInput = {
  readonly method: AccountVerificationMethod;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly teamName: Scalars['String'];
  readonly roles: ReadonlyArray<Role>;
  readonly isAdmin: Scalars['Boolean'];
  readonly isNotemealOnly: Scalars['Boolean'];
};

export type CreateOrgMembershipForAthleteInput = {
  readonly athlete: CreateOrgMembershipForAthleteObjectInput;
};

export type CreateOrgMembershipForAthleteObjectInput = {
  readonly athleteId: Scalars['ID'];
  readonly teamName: Scalars['String'];
  readonly method: AccountVerificationMethod;
  readonly roles: ReadonlyArray<Role>;
};

export type CreateOrgMembershipForAthletePayload = {
  readonly athlete: Athlete;
  readonly generatedSelfServiceActivationLink: Maybe<Scalars['String']>;
};

export type CreateOrgMembershipInput = {
  readonly method: AccountVerificationMethod;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly teamName: Scalars['String'];
  readonly roles: ReadonlyArray<Role>;
};

export type CreateOrgMembershipsAsStaffInput = {
  readonly memberships: ReadonlyArray<CreateOrgMembershipAsStaffInput>;
  readonly orgId: Scalars['ID'];
};

export type CreateOrgMembershipsInput = {
  readonly memberships: ReadonlyArray<CreateOrgMembershipInput>;
};

export type CreateOrgMembershipsPayload = {
  readonly memberships: ReadonlyArray<OrgMembership>;
};

export type CreateOrgPayload = {
  readonly org: Org;
};

export type CreatePaymentMethodInput = {
  readonly name: Scalars['String'];
};

export type CreatePaymentMethodPayload = {
  readonly paymentMethod: PaymentMethod;
};

export type CreatePlannedMenuInput = {
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly occurrence: PlannedMenuOccurrence;
  readonly timezone: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
};

export type CreatePlannedMenuPayload = {
  readonly plannedMenu: PlannedMenu;
};

export type CreatePrepPlanInput = {
  readonly start: Scalars['Date'];
  readonly end: Scalars['Date'];
};

export type CreatePrepPlanMealInput = {
  readonly prepPlanId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly recipeId: Maybe<Scalars['ID']>;
  readonly mealOptionId: Maybe<Scalars['ID']>;
  readonly edited: Maybe<Scalars['Boolean']>;
  readonly prepPlanMealAssignments: Maybe<ReadonlyArray<PrepPlanMealAssignmentInput>>;
  readonly prepPlanMealSlotAssignments: Maybe<ReadonlyArray<PrepPlanMealSlotAssignmentInput>>;
};

export type CreatePrepPlanMealPayload = {
  readonly prepPlanMeal: PrepPlanMeal;
};

export type CreatePrepPlanPayload = {
  readonly prepPlan: PrepPlan;
};

export type CreateRecipeFullInput = {
  readonly recipe: RecipeFullInput;
  readonly servings: ReadonlyArray<RecipeServingFullInput>;
  readonly copiedFromId: Maybe<Scalars['ID']>;
};

export type CreateRecipeFullPayload = {
  readonly recipe: Recipe;
};

export type CreateRecipeSimpleInput = {
  readonly recipe: RecipeSimpleInput;
  readonly serving: RecipeServingSimpleInput;
};

export type CreateRecipeSimplePayload = {
  readonly recipe: Recipe;
};

export type CreateRestaurantBrandedFoodInput = {
  readonly food: BrandedFoodFormInput;
  readonly servings: ReadonlyArray<FoodServingInput>;
  readonly nutrientAmounts: ReadonlyArray<FoodNutrientAmountFormInput>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
  readonly restaurantId: Scalars['ID'];
};

export type CreateRestaurantBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type CreateRestaurantInput = {
  readonly name: Scalars['String'];
  readonly nutritionixId: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly website: Maybe<Scalars['String']>;
  readonly proteinPortionSizeInOunces: Maybe<Scalars['Int']>;
  readonly firstPartyDelivery: Scalars['Boolean'];
  readonly athleteVisible: Scalars['Boolean'];
  readonly refundPolicy: Maybe<RefundPolicyType>;
  readonly refundDueBeforeInMinutes: Maybe<Scalars['Int']>;
  readonly restaurantTypeId: Maybe<Scalars['ID']>;
  readonly paymentMethodIds: ReadonlyArray<Scalars['ID']>;
  readonly cuisineIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateRestaurantMenuInput = {
  readonly restaurantId: Scalars['ID'];
  readonly sections: ReadonlyArray<AddRestaurantMenuSectionInput>;
  readonly supportedDietIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateRestaurantMenuPayload = {
  readonly restaurantMenu: RestaurantMenu;
};

export type CreateRestaurantPayload = {
  readonly restaurant: Restaurant;
};

export type CreateRestaurantTypeInput = {
  readonly name: Scalars['String'];
};

export type CreateRestaurantTypePayload = {
  readonly restaurantType: RestaurantType;
};

export type CreateReusableMenuItemInput = {
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly defaultAvailableForOrder: Scalars['Boolean'];
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly choices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly defaultAllowSpecialRequests: Scalars['Boolean'];
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type CreateReusableMenuItemPayload = {
  readonly menuItem: MenuItem;
};

export type CreateScheduleInput = {
  readonly teamId: Scalars['ID'];
  readonly meals: ReadonlyArray<AddMealInput>;
  readonly activities: ReadonlyArray<AddActivityInput>;
  readonly activityFactor: Maybe<Scalars['Float']>;
  readonly name: Scalars['String'];
};

export type CreateSchedulePayload = {
  readonly schedule: Schedule;
};

export type CreateServingAmountInput = {
  readonly servingId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly position: Scalars['Int'];
};

export type CreateSimpleBrandedFoodInput = {
  readonly name: Scalars['String'];
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly defaultAmount: Scalars['Float'];
  readonly units: ServingUnitsInput;
  readonly weight: Scalars['Float'];
  readonly exchangeTypes: ReadonlyArray<ExchangeType>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly nutrientAmounts: Maybe<ReadonlyArray<FoodNutrientAmountFormInput>>;
  readonly foodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type CreateSimpleBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type CreateSmartTagInput = {
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly condition: AssignTagConditionInput;
  readonly isPrintable: Maybe<Scalars['Boolean']>;
};

export type CreateTeamInput = {
  readonly sportId: Maybe<Scalars['ID']>;
  readonly gender: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type CreateTeamPayload = {
  readonly team: Team;
};

export type CreateTeamworksPositionInput = {
  readonly teamworksId: Scalars['Int'];
  readonly teamworksTeamId: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type CreateTeamworksSportInput = {
  readonly name: Scalars['String'];
  readonly newPositions: ReadonlyArray<CreateTeamworksPositionInput>;
};

export type CreateTeamworksTeamInput = {
  readonly teamworksId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly gender: Maybe<Scalars['String']>;
  readonly linkedSport: Maybe<LinkedSportInput>;
  readonly newSport: Maybe<CreateTeamworksSportInput>;
};

export type CreateTerritoryInput = {
  readonly name: Scalars['String'];
  readonly orgIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateUserBrandedFoodFormInput = {
  readonly name: Scalars['String'];
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly defaultAmount: Scalars['Float'];
  readonly units: ServingUnitsInput;
  readonly nutrientAmounts: ReadonlyArray<CreateFoodNutrientAmountFormInput>;
};

export type CreateWeightUnitServingForFoodInput = {
  readonly foodId: Scalars['ID'];
  readonly unitId: Scalars['ID'];
};

export type CreateWeightUnitServingForFoodPayload = {
  readonly serving: Serving;
  readonly amount: Scalars['Float'];
};

export type Cuisine = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type CuisineCursorConnection = {
  readonly edges: ReadonlyArray<Cuisine>;
  readonly pageInfo: CursorPageInfo;
};

export type CuisineOffsetConnection = {
  readonly edges: ReadonlyArray<Cuisine>;
  readonly pageInfo: OffsetPageInfo;
};

export type CursorPageInfo = {
  readonly hasNextPage: Scalars['Boolean'];
  readonly endCursor: Scalars['String'];
};

export type CursorPaginationInput = {
  readonly cursor: Maybe<Scalars['String']>;
  readonly limit: Maybe<Scalars['Int']>;
};

export type DailyGoalTrackerGoal = {
  readonly id: Scalars['ID'];
  readonly date: Scalars['DateTime'];
  readonly completed: Scalars['Boolean'];
  readonly completedAtInUTC: Maybe<Scalars['DateTime']>;
  readonly loggedValue: Maybe<Scalars['Int']>;
};



export type DayOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | '%future added value';

export type DayOfWeekPriority = {
  readonly dayOfWeek: DayOfWeek;
  readonly priority: Scalars['Int'];
};

export type DayOfWeekPriorityInput = {
  readonly dayOfWeek: DayOfWeek;
  readonly priority: Scalars['Int'];
};

export type DaySummary = {
  readonly datetime: Scalars['DateTime'];
  readonly targetMacrosForDay: MacrosAndKcals;
  readonly totalMacrosLogged: MacrosAndKcals;
};

export type DeleteAnthropometryEntryInput = {
  readonly id: Scalars['ID'];
};

export type DeleteAnthropometryEntryPayload = {
  readonly anthropometryEntryId: Scalars['ID'];
};

export type DeleteAthleteInput = {
  readonly id: Scalars['ID'];
};

export type DeleteAthletePayload = {
  readonly athleteId: Scalars['ID'];
};

export type DeleteCuisineInput = {
  readonly id: Scalars['ID'];
};

export type DeleteCuisinePayload = {
  readonly id: Scalars['ID'];
};

export type DeleteDietInput = {
  readonly id: Scalars['ID'];
};

export type DeleteDietPayload = {
  readonly id: Scalars['ID'];
};

export type DeleteDiningStationTemplateInput = {
  readonly diningStationTemplateId: Scalars['ID'];
};

export type DeleteDiningStationTemplatePayload = {
  readonly diningStationTemplateId: Scalars['ID'];
};

export type DeleteExchangeSetInput = {
  readonly id: Scalars['ID'];
};

export type DeleteExchangeSetPayload = {
  readonly id: Scalars['ID'];
};

export type DeleteFoodInput = {
  readonly id: Scalars['ID'];
};

export type DeleteFoodPayload = {
  readonly foodId: Scalars['ID'];
};

export type DeleteGoalConfigurationInput = {
  readonly id: Scalars['ID'];
};

export type DeleteGoalConfigurationPayload = {
  readonly goalConfigurationId: Scalars['ID'];
};

export type DeleteGroceryListCustomItemInput = {
  readonly id: Scalars['ID'];
};

export type DeleteGroceryListCustomItemPayload = {
  readonly id: Scalars['ID'];
};

export type DeleteGroceryListFoodItemInput = {
  readonly id: Scalars['ID'];
};

export type DeleteInProgressPrepPlanInput = {
  readonly prepPlanId: Scalars['ID'];
};

export type DeleteInProgressPrepPlanPayload = {
  readonly prepPlanId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplatePendingStateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplatePendingStatePayload = {
  readonly macroMealPlanTemplate: MealPlanTemplate;
};

export type DeleteMealMenuInput = {
  readonly mealMenuId: Scalars['ID'];
};

export type DeleteMealMenuPayload = {
  readonly mealMenuId: Scalars['ID'];
};

export type DeleteMealPlanInput = {
  readonly id: Scalars['ID'];
};

export type DeleteMealPlanPayload = {
  readonly mealPlanId: Scalars['ID'];
  readonly athlete: Athlete;
};

export type DeleteMealPlansInput = {
  readonly ids: ReadonlyArray<Scalars['ID']>;
};

export type DeleteMealPlansPayload = {
  readonly mealPlanIds: ReadonlyArray<Scalars['ID']>;
};

export type DeleteNoteInput = {
  readonly id: Scalars['ID'];
};

export type DeleteNotePayload = {
  readonly noteId: Scalars['ID'];
};

export type DeleteNoteTypeInput = {
  readonly id: Scalars['ID'];
};

export type DeleteNoteTypePayload = {
  readonly noteTypeId: Scalars['ID'];
};

export type DeletePaymentMethodInput = {
  readonly id: Scalars['ID'];
};

export type DeletePaymentMethodPayload = {
  readonly id: Scalars['ID'];
};

export type DeletePlannedMenuInput = {
  readonly id: Scalars['ID'];
};

export type DeletePlannedMenuPayload = {
  readonly plannedMenuId: Scalars['ID'];
};

export type DeleteRecipeInput = {
  readonly recipeId: Scalars['ID'];
};

export type DeleteRecipePayload = {
  readonly recipeId: Scalars['ID'];
};

export type DeleteRestaurantTypeInput = {
  readonly id: Scalars['ID'];
};

export type DeleteRestaurantTypePayload = {
  readonly id: Scalars['ID'];
};

export type DeleteReusableMenuItemInput = {
  readonly after: Scalars['DateTime'];
  readonly menuItemId: Scalars['ID'];
};

export type DeleteReusableMenuItemPayload = {
  readonly menuItemId: Scalars['ID'];
};

export type DeleteTagPayload = {
  readonly tagId: Scalars['ID'];
};

export type DeleteTeamInput = {
  readonly teamId: Scalars['ID'];
};

export type DeleteTeamPayload = {
  readonly teamId: Scalars['ID'];
};

export type DeleteUserBrandedFoodInput = {
  readonly id: Scalars['ID'];
};

export type DeleteUserBrandedFoodPayload = {
  readonly id: Scalars['ID'];
};

export type DeliveryLocation = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly deliveryInstructions: Maybe<Scalars['String']>;
  readonly googlePlaceId: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly cityInfo: Maybe<Scalars['String']>;
  readonly address: Maybe<Address>;
  readonly teamLabels: ReadonlyArray<TeamLabel>;
};

export type DeliveryLocationCursorConnection = {
  readonly edges: ReadonlyArray<DeliveryLocation>;
  readonly pageInfo: CursorPageInfo;
};

export type DeliveryLocationOffsetConnection = {
  readonly edges: ReadonlyArray<DeliveryLocation>;
  readonly pageInfo: OffsetPageInfo;
};

export type DeprecateFoodInput = {
  readonly id: Scalars['ID'];
};

export type DeprecateFoodPayload = {
  readonly foodId: Scalars['ID'];
};

export type DeprecateRestaurantBrandedFoodInput = {
  readonly id: Scalars['ID'];
  readonly restaurantId: Scalars['ID'];
};

export type DeprecateRestaurantBrandedFoodPayload = {
  readonly foodId: Scalars['ID'];
};

export type DeviceType =
  | 'IOS'
  | 'ANDROID'
  | '%future added value';

export type Diet = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type DietCursorConnection = {
  readonly edges: ReadonlyArray<Diet>;
  readonly pageInfo: CursorPageInfo;
};

export type DietOffsetConnection = {
  readonly edges: ReadonlyArray<Diet>;
  readonly pageInfo: OffsetPageInfo;
};

export type DigitalDisplaysMealMenu = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['DateTime'];
  readonly end: Scalars['DateTime'];
  readonly durationInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly mealMenuDiningStations: ReadonlyArray<DigitalDisplaysMealMenuDiningStation>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
};

export type DigitalDisplaysMealMenuDiningStation = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly menuItemAppearances: ReadonlyArray<DigitalDisplaysMenuItemAppearance>;
};

export type DigitalDisplaysMenuItem = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly macros: Macros;
  readonly hasAddOn: Scalars['Boolean'];
  readonly imageUrl: Maybe<Scalars['String']>;
};

export type DigitalDisplaysMenuItemAppearance = {
  readonly id: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly menuItem: DigitalDisplaysMenuItem;
};

export type DiningStationTemplate = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly menuItemAppearances: ReadonlyArray<MenuItemAppearance>;
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
};

export type DiningStationTemplateCursorConnection = {
  readonly edges: ReadonlyArray<DiningStationTemplate>;
  readonly pageInfo: CursorPageInfo;
};

export type DiningStationTemplateOffsetConnection = {
  readonly edges: ReadonlyArray<DiningStationTemplate>;
  readonly pageInfo: OffsetPageInfo;
};

export type DuplicatePlannedMenuInput = {
  readonly originalMenuId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly timezone: Scalars['String'];
};

export type EditActivityInput = {
  readonly activity: AddActivityInput;
  readonly activityId: Scalars['ID'];
};

export type EditActivityTemplateCalendarInput = {
  readonly activityTemplateId: Scalars['ID'];
  readonly activity: Maybe<AddActivityInput>;
  readonly resetModifications: Scalars['Boolean'];
  readonly dateModifications: ReadonlyArray<MealPlanDateModificationInput>;
  readonly dayOfWeekModifications: ReadonlyArray<MealPlanDayOfWeekModificationInput>;
};

export type EditAgreementFormInput = {
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly url: Scalars['String'];
  readonly acceptAgreementText: Scalars['String'];
};

export type EditAgreementFormPayload = {
  readonly agreementForm: AgreementForm;
};

export type EditAnthropometryEntriesPayload = {
  readonly anthropometryEntries: ReadonlyArray<AnthropometryEntry>;
};

export type EditAnthropometryEntryCommentInput = {
  readonly id: Scalars['ID'];
  readonly comment: Scalars['String'];
};

export type EditAnthropometryEntryCommentPayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type EditAnthropometryEntryInput = {
  readonly id: Scalars['ID'];
  readonly anthropometryEntry: AnthropometryEntryFormInput;
};

export type EditAnthropometryEntryPayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type EditAnthropometryEntryWeighInForDateForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly weight: Scalars['Float'];
  readonly date: Scalars['Date'];
};

export type EditAnthropometryEntryWeighInForDateForAthletePayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type EditAnthropometryEntryWeighInForDateInput = {
  readonly weight: Scalars['Float'];
  readonly date: Scalars['Date'];
};

export type EditAnthropometryEntryWeighInForDatePayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type EditAthleteAndAnthroInput = {
  readonly athleteId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
  readonly anthropometryEntry: Maybe<AnthropometryEntryFormInput>;
};

export type EditAthleteAndMetricAnthroInput = {
  readonly athleteId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
  readonly anthropometryEntry: Maybe<MetricAnthropometryEntryFormInput>;
};

export type EditAthleteDisplaySettingsInput = {
  readonly athleteId: Scalars['ID'];
  readonly macroDisplaySettings: Maybe<MacroMealPlanDisplaySettingsInput>;
  readonly exchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettingsInput>;
  readonly noTargetsDisplaySettings: Maybe<NoTargetsDisplaySettingsInput>;
  readonly hideAnthropometry: Maybe<Scalars['Boolean']>;
};

export type EditAthleteDisplaySettingsPayload = {
  readonly athlete: Athlete;
};

export type EditAthleteFoodLogNotificationSettingsInput = {
  readonly athleteId: Scalars['ID'];
  readonly settings: Maybe<FoodLogNotificationSettingsInput>;
};

export type EditAthleteFoodLogNotificationSettingsPayload = {
  readonly athlete: Athlete;
};

export type EditAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
};

export type EditAthletePayload = {
  readonly athlete: Athlete;
};

export type EditAthletesPayload = {
  readonly athletes: ReadonlyArray<Athlete>;
};

export type EditBrandInput = {
  readonly id: Scalars['ID'];
  readonly brand: BrandInputForm;
};

export type EditBrandPayload = {
  readonly brand: Brand;
};

export type EditBrandedFoodInput = {
  readonly id: Scalars['ID'];
  readonly food: BrandedFoodFormInput;
  readonly nutrientAmounts: Maybe<ReadonlyArray<FoodNutrientAmountFormInput>>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type EditBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type EditBulkOrderInput = {
  readonly bulkOrderId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly teamId: Scalars['ID'];
  readonly deliveryLocation: Scalars['String'];
  readonly addPlates: Maybe<ReadonlyArray<AddMealMenuPlateInput>>;
  readonly editPlates: Maybe<ReadonlyArray<EditMealMenuPlateInput>>;
  readonly removePlateIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type EditBulkOrderPayload = {
  readonly bulkOrder: BulkOrder;
  readonly addedMealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly editedMealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly removedMealMenuPlateOrderIds: ReadonlyArray<Scalars['ID']>;
};

export type EditClientCredentialInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type EditClientCredentialPayload = {
  readonly clientCredential: ClientCredential;
};

export type EditCuisineInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditCuisinePayload = {
  readonly cuisine: Cuisine;
};

export type EditCustomTagInput = {
  readonly id: Scalars['ID'];
  readonly tagInput: CreateCustomTagInput;
};

export type EditCustomizedPrepPlanMealInput = {
  readonly prepPlanMealId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type EditCustomizedPrepPlanMealPayload = {
  readonly prepPlanMeal: PrepPlanMeal;
};

export type EditDeliveryLocationInput = {
  readonly deliveryLocationId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly deliveryInstructions: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly teamLabelIds: ReadonlyArray<Scalars['ID']>;
  readonly cityInfo: Maybe<Scalars['String']>;
};

export type EditDeliveryLocationPayload = {
  readonly deliveryLocation: DeliveryLocation;
};

export type EditDietInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditDietPayload = {
  readonly diet: Diet;
};

export type EditDiningStationTemplateInput = {
  readonly after: Scalars['DateTime'];
  readonly diningStationTemplateId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
  readonly moveMenuItems: ReadonlyArray<MoveMenuItemInput>;
  readonly convertMenuItems: ReadonlyArray<ConvertMenuItemInput>;
  readonly removeMenuItems: ReadonlyArray<RemoveMenuItemInput>;
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
};

export type EditDiningStationTemplatePayload = {
  readonly diningStationTemplate: DiningStationTemplate;
};

export type EditExchangeInput = {
  readonly id: Scalars['ID'];
  readonly exchange: ExchangeInput;
  readonly exchangeServingList: Maybe<ExchangeServingListInput>;
};

export type EditExchangeMealTemplateInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly exchangeTargets: ReadonlyArray<ExchangeAmountInput>;
};

export type EditExchangeMealTemplatePayload = {
  readonly exchangeMealTemplate: ExchangeMealTemplate;
};

export type EditExchangeMealTemplatesPayload = {
  readonly exchangeMealTemplates: ReadonlyArray<ExchangeMealTemplate>;
};

export type EditExchangeServingListInput = {
  readonly id: Scalars['ID'];
  readonly exchangeServingList: ExchangeServingListInput;
};

export type EditExchangeSetInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly addExchanges: ReadonlyArray<CreateExchangeInput>;
  readonly editExchanges: ReadonlyArray<EditExchangeInput>;
  readonly removeExchanges: ReadonlyArray<Scalars['ID']>;
};

export type EditExchangeSetPayload = {
  readonly exchangeSet: ExchangeSet;
};

export type EditFoodGroupInput = {
  readonly id: Scalars['ID'];
  readonly foodGroup: CreateFoodGroupInput;
};

export type EditFoodGroupPayload = {
  readonly foodGroup: FoodGroup;
};

export type EditFoodLogActivityRpeInput = {
  readonly foodLogActivityId: Scalars['String'];
  readonly rpe: Scalars['Int'];
};

export type EditFoodLogActivityRpePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type EditFoodLogActivityTemplateInput = {
  readonly foodLogActivityId: Scalars['ID'];
  readonly activityTemplateId: Scalars['ID'];
};

export type EditFoodLogActivityTemplatePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type EditFoodLogActivityTimeWithTimezoneInput = {
  readonly foodLogActivityId: Scalars['String'];
  readonly activity: AddActivityInput;
  readonly timezone: Scalars['Timezone'];
};

export type EditFoodLogActivityTimeWithTimezonePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type EditFoodLogMealImageInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly imageUrl: Scalars['String'];
};

export type EditFoodLogMealImagePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealServingAmountsInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type EditFoodLogMealServingAmountsPayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealTemplateInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditFoodLogMealTemplatePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealTimePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealTimeWithTimezoneInput = {
  readonly foodLogMealId: Scalars['String'];
  readonly meal: AddMealInput;
  readonly timezone: Scalars['Timezone'];
};

export type EditFoodLogMealTimeWithTimezonePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodServingInput = {
  readonly id: Scalars['ID'];
  readonly serving: FoodServingInput;
};

export type EditFoodServingPayload = {
  readonly serving: Serving;
};

export type EditGenericFoodInput = {
  readonly id: Scalars['ID'];
  readonly food: GenericFoodFormInput;
  readonly nutrientAmounts: Maybe<ReadonlyArray<FoodNutrientAmountFormInput>>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type EditGenericFoodPayload = {
  readonly genericFood: GenericFood;
};

export type EditGoalConfigurationBaseInput = {
  readonly id: Scalars['ID'];
  readonly name: Maybe<Scalars['String']>;
  readonly target: Maybe<CreateGoalQuantityInput>;
  readonly removeTarget: Scalars['Boolean'];
  readonly timezone: Scalars['String'];
  readonly reminders: ReadonlyArray<SetGoalReminderConfigurationInput>;
};

export type EditGoalConfigurationPayload = {
  readonly goalConfiguration: GoalConfiguration;
};

export type EditGoalConfigurationWithDaysOfWeekInput = {
  readonly input: EditGoalConfigurationBaseInput;
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly dayOfWeekAssignments: ReadonlyArray<DayOfWeek>;
};

export type EditGoalConfigurationWithIndividualDatesInput = {
  readonly input: EditGoalConfigurationBaseInput;
  readonly addIndividualDates: ReadonlyArray<Scalars['Date']>;
  readonly removeIndividualDates: ReadonlyArray<Scalars['Date']>;
};

export type EditGroceryListCustomItemInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditGroceryListCustomItemPayload = {
  readonly id: Scalars['ID'];
};

export type EditGroceryListFoodItemInput = {
  readonly id: Scalars['ID'];
  readonly groceryListCategoryId: Maybe<Scalars['ID']>;
  readonly amount: Maybe<Scalars['Int']>;
  readonly adjustment: Maybe<Scalars['Float']>;
  readonly servingId: Scalars['ID'];
};

export type EditGroceryListFoodItemPayload = {
  readonly id: Scalars['ID'];
};

export type EditGroceryListInput = {
  readonly id: Scalars['ID'];
  readonly notes: Maybe<Scalars['String']>;
};

export type EditGroceryListPayload = {
  readonly id: Scalars['ID'];
};

export type EditMacroMealPlanTemplateInput = {
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly additionalState: Maybe<Scalars['String']>;
  readonly macroProtocol: MealPlanTemplateMacroProtocolInput;
  readonly meals: ReadonlyArray<MacroMealPlanTemplateMealInput>;
  readonly foodPreferences: FoodPreferenceInput;
  readonly activities: ReadonlyArray<MealPlanTemplateActivityInput>;
};

export type EditMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type EditMacroMealTemplateInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly cho: Maybe<Scalars['Float']>;
  readonly pro: Maybe<Scalars['Float']>;
  readonly fat: Maybe<Scalars['Float']>;
};

export type EditMacroMealTemplatePayload = {
  readonly macroMealTemplate: MacroMealTemplate;
};

export type EditMacroMealTemplatesPayload = {
  readonly macroMealTemplates: ReadonlyArray<MacroMealTemplate>;
};

export type EditMacroProtocolInput = {
  readonly macroProtocolId: Scalars['String'];
  readonly macroProtocol: MacroProtocolInput;
};

export type EditMacroProtocolPayload = {
  readonly macroProtocol: MacroProtocol;
};

export type EditMacroProtocolWithNewGoalInput = {
  readonly macroProtocolId: Scalars['String'];
  readonly macroProtocol: MacroProtocolWithNewGoalInput;
};

export type EditMacroProtocolWithNewGoalPayload = {
  readonly macroProtocol: MacroProtocol;
};

export type EditMacroProtocolWithOldGoalInput = {
  readonly macroProtocolId: Scalars['String'];
  readonly macroProtocol: MacroProtocolWithOldGoalInput;
};

export type EditMacroProtocolWithOldGoalPayload = {
  readonly macroProtocol: MacroProtocol;
};

export type EditMealInput = {
  readonly mealId: Scalars['ID'];
  readonly meal: AddMealInput;
};

export type EditMealMenuDiningStationInput = {
  readonly mealMenuDiningStationId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
  readonly moveMenuItems: ReadonlyArray<MoveMenuItemInput>;
  readonly convertMenuItems: ReadonlyArray<ConvertMenuItemInput>;
  readonly removeMenuItems: ReadonlyArray<RemoveMenuItemInput>;
};

export type EditMealMenuInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  readonly isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  readonly theme: Maybe<Scalars['String']>;
  readonly addDiningStations: ReadonlyArray<AddMealMenuDiningStationInput>;
  readonly editDiningStations: ReadonlyArray<EditMealMenuDiningStationInput>;
  readonly removeDiningStations: ReadonlyArray<RemoveMealMenuDiningStationInput>;
  readonly addRestaurantMenuLinks: ReadonlyArray<RestaurantMenuLinkInput>;
  readonly editRestaurantMenuLinks: ReadonlyArray<EditRestaurantMenuLinkInput>;
  readonly removeRestaurantMenuLinks: ReadonlyArray<RemoveRestaurantMenuLinkInput>;
};

export type EditMealMenuLogItemInput = {
  readonly mealMenuLogItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<MealMenuLogItemOptionInput>;
};

export type EditMealMenuLogItemPayload = {
  readonly mealMenuLogItem: MealMenuLogItem;
};

export type EditMealMenuPayload = {
  readonly mealMenu: StandaloneMealMenu;
};

export type EditMealMenuPlateInput = {
  readonly mealMenuPlateId: Scalars['ID'];
  readonly parentBulkOrderId: Scalars['ID'];
  readonly addItems: Maybe<ReadonlyArray<AddMealMenuPlateItemInput>>;
  readonly editItems: Maybe<ReadonlyArray<EditMealMenuPlateItemInput>>;
  readonly removePlateItemIds: ReadonlyArray<Scalars['ID']>;
};

export type EditMealMenuPlateItemInput = {
  readonly mealMenuPlateItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly editMealMenuPlate: EditMealMenuPlateInput;
  readonly options: ReadonlyArray<MealMenuPlateItemOptionInput>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type EditMealMenuWithIdentityInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  readonly isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  readonly availableForOrder: Scalars['Boolean'];
  readonly allowSpecialRequests: Scalars['Boolean'];
  readonly theme: Maybe<Scalars['String']>;
};

export type EditMealMenuWithIdentityPayload = {
  readonly mealMenu: StandaloneMealMenu;
};

export type EditMealOptionInput = {
  readonly mealOptionId: Scalars['ID'];
  readonly mealOption: MealOptionInput;
};

export type EditMealOptionLocalInput = {
  readonly mealOptionId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<ServingAmountLocalInput>;
  readonly position: Scalars['Int'];
  readonly note: Scalars['String'];
  readonly name: Scalars['String'];
};

export type EditMealOptionPayload = {
  readonly mealOption: MealOption;
};

export type EditMealOptionPendingInput = {
  readonly mealOptionPendingId: Scalars['ID'];
  readonly mealOptionPending: MealOptionPendingInput;
};

export type EditMealOptionPendingLocalInput = {
  readonly mealOptionPendingId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<ServingAmountLocalInput>;
  readonly note: Scalars['String'];
  readonly name: Scalars['String'];
};

export type EditMealOptionPendingPayload = {
  readonly mealOptionPending: MealOptionPending;
};

export type EditMealPlanCalendarInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly addIndividualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly removeIndividualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly dayOfWeekPriorities: Maybe<ReadonlyArray<DayOfWeekPriorityInput>>;
  readonly removeConflictingIndividualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly addMealTemplates: ReadonlyArray<AddMealTemplateCalendarInput>;
  readonly editMealTemplates: ReadonlyArray<EditMealTemplateCalendarInput>;
  readonly removeMealTemplateIds: ReadonlyArray<Scalars['ID']>;
  readonly addActivityTemplates: ReadonlyArray<AddActivityTemplateCalendarInput>;
  readonly editActivityTemplates: ReadonlyArray<EditActivityTemplateCalendarInput>;
  readonly removeActivityTemplateIds: ReadonlyArray<Scalars['ID']>;
};

export type EditMealPlanCalendarPayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlanDateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly mealPlanId: Scalars['ID'];
};

export type EditMealPlanDateForAthletePayload = {
  readonly mealPlanDate: MealPlanDate;
};

export type EditMealPlanDateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly mealPlanId: Scalars['ID'];
};

export type EditMealPlanDatePayload = {
  readonly mealPlanDate: MealPlanDate;
};

export type EditMealPlanExchangeTargetsInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly exchangeTargets: ReadonlyArray<ExchangeAmountInput>;
};

export type EditMealPlanExchangeTargetsPayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlanInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly mealPlan: MealPlanEditFormInput;
  readonly foodPreferences: FoodPreferenceInput;
  readonly exchangeSetId: Maybe<Scalars['ID']>;
};

export type EditMealPlanIsSharedInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly isShared: Scalars['Boolean'];
};

export type EditMealPlanIsSharedPayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlanNewInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly foodPreferences: FoodPreferenceInput;
};

export type EditMealPlanPayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlanScheduleInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly addMeals: ReadonlyArray<AddMealInput>;
  readonly editMeals: ReadonlyArray<EditMealInput>;
  readonly removeMeals: ReadonlyArray<RemoveMealInput>;
  readonly addActivities: ReadonlyArray<AddActivityInput>;
  readonly editActivities: ReadonlyArray<EditActivityInput>;
  readonly removeActivities: ReadonlyArray<RemoveActivityInput>;
};

export type EditMealPlanSchedulePayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlansIsSharedInput = {
  readonly mealPlanIds: ReadonlyArray<Scalars['ID']>;
  readonly isShared: Scalars['Boolean'];
};

export type EditMealPlansIsSharedPayload = {
  readonly mealPlans: ReadonlyArray<MealPlan>;
};

export type EditMealTemplateCalendarInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly meal: Maybe<AddMealInput>;
  readonly resetModifications: Scalars['Boolean'];
  readonly dateModifications: ReadonlyArray<MealPlanDateModificationInput>;
  readonly dayOfWeekModifications: ReadonlyArray<MealPlanDayOfWeekModificationInput>;
};

export type EditMenuItemChoiceInput = {
  readonly menuItemChoiceId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly required: Scalars['Boolean'];
  readonly maxOptionsCount: Maybe<Scalars['Int']>;
  readonly addOptions: ReadonlyArray<AddMenuItemChoiceOptionInput>;
  readonly editOptions: ReadonlyArray<EditMenuItemChoiceOptionInput>;
  readonly removeOptions: ReadonlyArray<RemoveMenuItemChoiceOptionInput>;
};

export type EditMenuItemChoiceOptionInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
  readonly menuItemChoiceOption: AddMenuItemChoiceOptionInput;
};

export type EditMenuItemImageInput = {
  readonly menuItemId: Scalars['ID'];
  readonly imageUrl: Maybe<Scalars['String']>;
};

export type EditMenuItemImagePayload = {
  readonly menuItem: MenuItem;
};

export type EditMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly appearance: MenuItemAppearanceInput;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly addChoices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly editChoices: ReadonlyArray<EditMenuItemChoiceInput>;
  readonly removeChoices: ReadonlyArray<RemoveMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type EditMenuOrderInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly items: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly addOrderItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly addLogItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly editItems: Maybe<ReadonlyArray<EditMenuOrderItemInput>>;
  readonly removeItemIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type EditMenuOrderItemInput = {
  readonly menuOrderItemId: Scalars['ID'];
  readonly forOrder: Scalars['Boolean'];
  readonly amount: Scalars['Float'];
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly options: ReadonlyArray<MenuOrderItemOptionInput>;
};

export type EditMenuOrderMealTemplateInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditMenuOrderMealTemplatePayload = {
  readonly menuOrder: MenuOrder;
};

export type EditMenuOrderPayload = {
  readonly menuOrder: MenuOrder;
};

export type EditMetricAnthropometryEntryInput = {
  readonly id: Scalars['ID'];
  readonly anthropometryEntry: MetricAnthropometryEntryFormInput;
};

export type EditMetricAnthropometryEntryWeighInForDateForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly weightInKg: Scalars['Float'];
  readonly date: Scalars['Date'];
};

export type EditMetricAnthropometryEntryWeighInForDateInput = {
  readonly weightInKg: Scalars['Float'];
  readonly date: Scalars['Date'];
};

export type EditMetricMacroProtocolInput = {
  readonly macroProtocolId: Scalars['String'];
  readonly macroProtocol: MetricMacroProtocolInput;
};

export type EditNoteInput = {
  readonly id: Scalars['ID'];
  readonly note: NoteFormInput;
};

export type EditNotePayload = {
  readonly note: Note;
};

export type EditNoteTypeInput = {
  readonly id: Scalars['ID'];
  readonly noteType: NoteTypeFormInput;
};

export type EditNoteTypePayload = {
  readonly noteType: NoteType;
};

export type EditOrgAutoShareNewNotemealRecipesInput = {
  readonly autoShareNewNotemealRecipes: Scalars['Boolean'];
  readonly shareCurrent: Scalars['Boolean'];
};

export type EditOrgAutoShareNewNotemealRecipesPayload = {
  readonly org: Org;
};

export type EditOrgFeatureFlagsInput = {
  readonly orgId: Scalars['ID'];
  readonly featureFlags: FeatureFlagsInput;
};

export type EditOrgFeatureFlagsPayload = {
  readonly featureFlags: FeatureFlags;
};

export type EditOrgGroupInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly allowsResourceSharing: Scalars['Boolean'];
  readonly allowsAthleteTransfer: Scalars['Boolean'];
  readonly parentOrgGroupId: Maybe<Scalars['ID']>;
  readonly orgIds: ReadonlyArray<Scalars['ID']>;
};

export type EditOrgGroupPayload = {
  readonly orgGroup: OrgGroup;
};

export type EditOrgInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly allowsPublicAccess: Scalars['Boolean'];
  readonly editMemberships: ReadonlyArray<EditOrgMembershipAsStaffInput>;
  readonly createMemberships: ReadonlyArray<CreateOrgMembershipAsStaffInput>;
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly integrations: ReadonlyArray<IntegrationInput>;
  readonly agreementFormId: Maybe<Scalars['ID']>;
  readonly scoringSystemId: Maybe<Scalars['ID']>;
  readonly localeCode: Scalars['String'];
  readonly territoryName: Maybe<Scalars['String']>;
};

export type EditOrgMembershipAsStaffInput = {
  readonly id: Scalars['ID'];
  readonly isActive: Scalars['Boolean'];
  readonly roles: ReadonlyArray<Role>;
  readonly isAdmin: Scalars['Boolean'];
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
};

export type EditOrgMembershipInput = {
  readonly id: Scalars['ID'];
  readonly isActive: Scalars['Boolean'];
  readonly roles: ReadonlyArray<Role>;
};

export type EditOrgMembershipSettingsInput = {
  readonly isSubscribedToDigest: Scalars['Boolean'];
};

export type EditOrgMembershipSettingsPayload = {
  readonly orgMembership: OrgMembership;
};

export type EditOrgMembershipsAsStaffInput = {
  readonly memberships: ReadonlyArray<EditOrgMembershipAsStaffInput>;
};

export type EditOrgMembershipsInput = {
  readonly memberships: ReadonlyArray<EditOrgMembershipInput>;
};

export type EditOrgMembershipsPayload = {
  readonly memberships: ReadonlyArray<OrgMembership>;
};

export type EditOrgPayload = {
  readonly org: Org;
};

export type EditOrgTeamworksProfilesInput = {
  readonly orgId: Scalars['ID'];
  readonly profileSyncRules: ReadonlyArray<TeamworksProfileSyncRuleInput>;
  readonly profileLinks: ReadonlyArray<TeamworksProfileLinkInput>;
  readonly deactivateTeamworksProfileIds: ReadonlyArray<Scalars['Int']>;
  readonly deactivateOrgMembershipIds: ReadonlyArray<Scalars['ID']>;
  readonly notemealOnlyOrgMembershipIds: ReadonlyArray<Scalars['ID']>;
  readonly removeNotemealOnlyOrgMembershipIds: ReadonlyArray<Scalars['ID']>;
  readonly archiveAthleteIds: ReadonlyArray<Scalars['ID']>;
  readonly notemealOnlyAthleteIds: ReadonlyArray<Scalars['ID']>;
  readonly removeNotemealOnlyAthleteIds: ReadonlyArray<Scalars['ID']>;
  readonly unlinkProfiles: ReadonlyArray<UnlinkTeamworksProfileInput>;
};

export type EditOrgTeamworksProfilesPayload = {
  readonly org: Org;
  readonly skippedProfiles: ReadonlyArray<EditOrgTeamworksSkippedProfile>;
};

export type EditOrgTeamworksSkippedProfile = {
  readonly teamworksUserId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly conflictedUserIds: ReadonlyArray<Scalars['String']>;
};

export type EditPaymentMethodInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditPaymentMethodPayload = {
  readonly paymentMethod: PaymentMethod;
};

export type EditPlannedMenuInput = {
  readonly plannedMenuId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly timezone: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
};

export type EditPlannedMenuPayload = {
  readonly plannedMenu: PlannedMenu;
};

export type EditPrepPlanInput = {
  readonly prepPlanId: Scalars['ID'];
  readonly start: Scalars['Date'];
  readonly end: Scalars['Date'];
};

export type EditPrepPlanMealInput = {
  readonly prepPlanMealId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly prepPlanMealAssignments: Maybe<ReadonlyArray<PrepPlanMealAssignmentInput>>;
  readonly prepPlanMealSlotAssignments: Maybe<ReadonlyArray<PrepPlanMealSlotAssignmentInput>>;
};

export type EditPrepPlanMealPayload = {
  readonly prepPlanMeal: PrepPlanMeal;
};

export type EditPrepPlanPayload = {
  readonly prepPlan: PrepPlan;
};

export type EditRecipeFullInput = {
  readonly id: Scalars['ID'];
  readonly recipe: RecipeFullInput;
  readonly createServings: ReadonlyArray<RecipeServingFullInput>;
  readonly editServings: ReadonlyArray<EditRecipeServingFullInput>;
};

export type EditRecipeFullPayload = {
  readonly recipe: Recipe;
};

export type EditRecipeIsSharedInput = {
  readonly id: Scalars['ID'];
  readonly isShared: Scalars['Boolean'];
};

export type EditRecipeIsSharedPayload = {
  readonly recipe: Recipe;
};

export type EditRecipePartialInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly prepTimeInMinutes: Maybe<Scalars['Int']>;
  readonly cookTimeInMinutes: Maybe<Scalars['Int']>;
  readonly steps: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly note: Maybe<Scalars['String']>;
  readonly images: ReadonlyArray<ImageInput>;
  readonly isShared: Maybe<Scalars['Boolean']>;
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type EditRecipePartialPayload = {
  readonly recipe: Recipe;
};

export type EditRecipeServingFullInput = {
  readonly id: Scalars['ID'];
  readonly serving: RecipeServingFullInput;
};

export type EditRecipeServingInput = {
  readonly servingId: Scalars['ID'];
  readonly serving: RecipeServingFormInput;
};

export type EditRecipeSimpleInput = {
  readonly id: Scalars['ID'];
  readonly recipe: RecipeSimpleInput;
  readonly serving: RecipeServingSimpleInput;
};

export type EditRecipeSimplePayload = {
  readonly recipe: Recipe;
};

export type EditRestaurantBrandedFoodInput = {
  readonly id: Scalars['ID'];
  readonly food: BrandedFoodFormInput;
  readonly nutrientAmounts: Maybe<ReadonlyArray<FoodNutrientAmountFormInput>>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
  readonly restaurantId: Scalars['ID'];
};

export type EditRestaurantBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type EditRestaurantInput = {
  readonly restaurantId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly nutritionixId: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly website: Maybe<Scalars['String']>;
  readonly proteinPortionSizeInOunces: Maybe<Scalars['Int']>;
  readonly firstPartyDelivery: Scalars['Boolean'];
  readonly athleteVisible: Scalars['Boolean'];
  readonly refundPolicy: Maybe<RefundPolicyType>;
  readonly refundDueBeforeInMinutes: Maybe<Scalars['Int']>;
  readonly restaurantTypeId: Maybe<Scalars['ID']>;
  readonly paymentMethodIds: ReadonlyArray<Scalars['ID']>;
  readonly cuisineIds: ReadonlyArray<Scalars['ID']>;
};

export type EditRestaurantLocationContactInput = {
  readonly id: Scalars['ID'];
  readonly restaurantLocationContact: RestaurantLocationContactInput;
};

export type EditRestaurantLocationContactPayload = {
  readonly restaurantLocationContact: RestaurantLocationContact;
};

export type EditRestaurantLocationInput = {
  readonly restaurantLocationId: Scalars['ID'];
  readonly cityInfo: Maybe<Scalars['String']>;
  readonly notes: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly deliveryLocationIds: ReadonlyArray<Scalars['ID']>;
};

export type EditRestaurantLocationPayload = {
  readonly restaurantLocation: RestaurantLocation;
};

export type EditRestaurantMenuInput = {
  readonly restaurantMenuId: Scalars['ID'];
  readonly addSections: ReadonlyArray<AddRestaurantMenuSectionInput>;
  readonly editSections: ReadonlyArray<EditRestaurantMenuSectionInput>;
  readonly removeSections: ReadonlyArray<RemoveRestaurantMenuSectionInput>;
  readonly supportedDietIds: ReadonlyArray<Scalars['ID']>;
};

export type EditRestaurantMenuLinkInput = {
  readonly restaurantMenuLinkId: Scalars['ID'];
  readonly sections: ReadonlyArray<RestaurantMenuSectionLinkInput>;
  readonly addPlates: ReadonlyArray<AddRestaurantMenuLinkPlateInput>;
  readonly editPlates: ReadonlyArray<EditRestaurantMenuLinkPlateInput>;
  readonly removePlateIds: ReadonlyArray<Scalars['ID']>;
  readonly allowCustomOrders: Scalars['Boolean'];
  readonly allowPlateOrders: Scalars['Boolean'];
};

export type EditRestaurantMenuLinkOrderInput = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
  readonly addItems: ReadonlyArray<RestaurantMenuLinkOrderItemInput>;
  readonly editItems: ReadonlyArray<EditRestaurantMenuLinkOrderItemInput>;
  readonly removeItemIds: ReadonlyArray<Scalars['ID']>;
};

export type EditRestaurantMenuLinkOrderItemInput = {
  readonly restaurantMenuLinkOrderItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly options: ReadonlyArray<RestaurantMenuLinkOrderItemOptionInput>;
};

export type EditRestaurantMenuLinkOrderMealTemplateInput = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditRestaurantMenuLinkOrderMealTemplatePayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
};

export type EditRestaurantMenuLinkOrderPayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
};

export type EditRestaurantMenuLinkPayload = {
  readonly restaurantMenuLink: RestaurantMenuLink;
};

export type EditRestaurantMenuLinkPlateInput = {
  readonly restaurantMenuLinkPlateId: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly bulkOrderAmount: Maybe<Scalars['Int']>;
  readonly addItems: ReadonlyArray<AddRestaurantMenuLinkPlateItemInput>;
  readonly editItems: ReadonlyArray<EditRestaurantMenuLinkPlateItemInput>;
  readonly removeItemIds: ReadonlyArray<Scalars['ID']>;
  readonly isDefault: Maybe<Scalars['Boolean']>;
};

export type EditRestaurantMenuLinkPlateItemInput = {
  readonly restaurantMenuLinkPlateItemId: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<RestaurantMenuLinkPlateItemOptionInput>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type EditRestaurantMenuLinkPlateOrderMealTemplateInput = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditRestaurantMenuLinkPlateOrderMealTemplatePayload = {
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
};

export type EditRestaurantMenuLogInput = {
  readonly dateTime: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly restaurantMenuLogId: Scalars['ID'];
  readonly addItems: ReadonlyArray<RestaurantMenuLogItemInput>;
  readonly editItems: ReadonlyArray<EditRestaurantMenuLogItemInput>;
  readonly removeItemIds: ReadonlyArray<Scalars['ID']>;
  readonly type: MealType;
};

export type EditRestaurantMenuLogItemInput = {
  readonly restaurantMenuLogItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<RestaurantMenuLogItemOptionInput>;
};

export type EditRestaurantMenuLogItemPayload = {
  readonly restaurantMenuLogItem: RestaurantMenuLogItem;
};

export type EditRestaurantMenuLogMealTemplateInput = {
  readonly restaurantMenuLogId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditRestaurantMenuLogMealTemplatePayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type EditRestaurantMenuLogPayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type EditRestaurantMenuPayload = {
  readonly restaurantMenu: RestaurantMenu;
};

export type EditRestaurantMenuSectionInput = {
  readonly restaurantMenuSectionId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
  readonly moveMenuItems: ReadonlyArray<MoveMenuItemInput>;
  readonly convertMenuItems: ReadonlyArray<ConvertMenuItemInput>;
  readonly removeMenuItems: ReadonlyArray<RemoveMenuItemInput>;
};

export type EditRestaurantPayload = {
  readonly restaurant: Restaurant;
};

export type EditRestaurantTypeInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditRestaurantTypePayload = {
  readonly restaurantType: RestaurantType;
};

export type EditReusableMenuItemInput = {
  readonly after: Scalars['DateTime'];
  readonly menuItemId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly defaultAvailableForOrder: Scalars['Boolean'];
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly addChoices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly editChoices: ReadonlyArray<EditMenuItemChoiceInput>;
  readonly removeChoices: ReadonlyArray<RemoveMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly defaultAllowSpecialRequests: Scalars['Boolean'];
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type EditReusableMenuItemPartialInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly defaultAvailableForOrder: Scalars['Boolean'];
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly defaultAllowSpecialRequests: Scalars['Boolean'];
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type EditReusableMenuItemPartialPayload = {
  readonly menuItem: MenuItem;
};

export type EditReusableMenuItemPayload = {
  readonly menuItem: MenuItem;
};

export type EditScheduleInput = {
  readonly scheduleId: Scalars['ID'];
  readonly addMeals: ReadonlyArray<AddMealInput>;
  readonly editMeals: ReadonlyArray<EditMealInput>;
  readonly removeMeals: ReadonlyArray<RemoveMealInput>;
  readonly addActivities: ReadonlyArray<AddActivityInput>;
  readonly editActivities: ReadonlyArray<EditActivityInput>;
  readonly removeActivities: ReadonlyArray<RemoveActivityInput>;
  readonly activityFactor: Maybe<Scalars['Float']>;
  readonly name: Maybe<Scalars['String']>;
};

export type EditSchedulePayload = {
  readonly schedule: Schedule;
};

export type EditSmartTagInput = {
  readonly id: Scalars['ID'];
  readonly tagInput: CreateSmartTagInput;
};

export type EditSyncedAthleteAndAnthroInput = {
  readonly athleteId: Scalars['ID'];
  readonly athlete: SyncedAthleteFormInput;
  readonly anthropometryEntry: Maybe<AnthropometryEntryFormInput>;
};

export type EditSyncedAthleteAndMetricAnthroInput = {
  readonly athleteId: Scalars['ID'];
  readonly athlete: SyncedAthleteFormInput;
  readonly anthropometryEntry: Maybe<MetricAnthropometryEntryFormInput>;
};

export type EditSyncedAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly athlete: SyncedAthleteFormInput;
};

export type EditTeamDisplaySettingsInput = {
  readonly teamId: Scalars['ID'];
  readonly macroDisplaySettings: MacroMealPlanDisplaySettingsInput;
  readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsInput;
  readonly noTargetsDisplaySettings: NoTargetsDisplaySettingsInput;
  readonly hideAnthropometry: Scalars['Boolean'];
};

export type EditTeamDisplaySettingsPayload = {
  readonly team: Team;
};

export type EditTeamFoodLogNotificationSettingsInput = {
  readonly teamId: Scalars['ID'];
  readonly settings: Maybe<FoodLogNotificationSettingsInput>;
};

export type EditTeamFoodLogNotificationSettingsPayload = {
  readonly team: Team;
};

export type EditTeamForStaffInput = {
  readonly id: Scalars['ID'];
  readonly orgId: Scalars['ID'];
  readonly team: CreateTeamInput;
};

export type EditTeamInput = {
  readonly id: Scalars['ID'];
  readonly team: CreateTeamInput;
};

export type EditTeamLabelInput = {
  readonly teamLabelId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type EditTeamLabelPayload = {
  readonly teamLabel: TeamLabel;
};

export type EditTeamPayload = {
  readonly team: Team;
};

export type EditTerritoryInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly orgIds: ReadonlyArray<Scalars['ID']>;
  readonly removeOrgIds: ReadonlyArray<Scalars['ID']>;
};

export type EditTimelineMealDetailsInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly type: MealType;
};

export type EditTimelineMealDetailsPayload = {
  readonly timelineMeal: TimelineMeal;
};

export type EditTimelineMealMealTemplateInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditTimelineMealMealTemplatePayload = {
  readonly timelineMeal: TimelineMeal;
};

export type EditTimelineMealServingAmountInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly removeServingAmountId: Scalars['ID'];
  readonly servingAmount: CreateServingAmountInput;
};

export type EditTimelineMealServingAmountPayload = {
  readonly timelineMeal: TimelineMeal;
};

export type EditUserBrandedFoodFormInput = {
  readonly id: Scalars['ID'];
  readonly input: CreateUserBrandedFoodFormInput;
};

export type EditUserBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type EditUserTeamworksIdToOverwriteInput = {
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  readonly teamworksIdToOverwrite: Scalars['Int'];
};

export type EditUserTeamworksIdToOverwritePayload = {
  readonly userIds: ReadonlyArray<Scalars['ID']>;
};

export type EmailMealPlanToAthletePayload = {
  readonly result: Scalars['Boolean'];
};

export type Event = {
  readonly datetime: Scalars['DateTime'];
  readonly type: Scalars['String'];
};

export type Exchange = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly shortName: Scalars['String'];
  readonly type: ExchangeType;
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly kcal: Scalars['Float'];
  readonly exchangeServingList: ExchangeServingList;
};

export type ExchangeAmount = {
  readonly amount: Scalars['Float'];
  readonly amountInput: Scalars['String'];
  readonly exchange: Exchange;
  readonly id: Scalars['ID'];
  readonly pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type ExchangeAmountInput = {
  readonly exchangeId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type ExchangeAmountTemplate = {
  readonly id: Scalars['ID'];
  readonly pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly exchange: Exchange;
};

export type ExchangeInput = {
  readonly name: Scalars['String'];
  readonly shortName: Scalars['String'];
  readonly type: ExchangeType;
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
};

export type ExchangeMealPlanDisplaySettings = {
  readonly dailyProgressViewsForExchanges: ExchangeProgressViews;
  readonly mealProgressViewsForExchanges: ExchangeProgressViews;
  readonly dailyProgressViewsForMacros: MacroProgressViews;
  readonly mealProgressViewsForMacros: MacroProgressViews;
};

export type ExchangeMealPlanDisplaySettingsInput = {
  readonly dailyProgressViewsForExchanges: ExchangeProgressViews;
  readonly mealProgressViewsForExchanges: ExchangeProgressViews;
  readonly dailyProgressViewsForMacros: MacroProgressViews;
  readonly mealProgressViewsForMacros: MacroProgressViews;
};

export type ExchangeMealPlanTemplate = MealPlanTemplate & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly owner: User;
  /** @deprecated Field no longer supported */
  readonly orgId: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  readonly orgGroupId: Maybe<Scalars['ID']>;
  readonly teams: Maybe<ReadonlyArray<Team>>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly copiedCount: Scalars['Int'];
  readonly avoidedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly promotedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly avoidedFoods: ReadonlyArray<Food>;
  readonly promotedFoods: ReadonlyArray<Food>;
  readonly macroProtocol: MealPlanTemplateMacroProtocol;
  readonly meals: ReadonlyArray<ExchangeMealPlanTemplateMeal>;
  readonly activities: ReadonlyArray<MealPlanTemplateActivity>;
  readonly exchangeTargets: Maybe<ReadonlyArray<ExchangeAmountTemplate>>;
  readonly exchangeSet: Maybe<ExchangeSet>;
  readonly pendingState: Maybe<Scalars['String']>;
  readonly additionalState: Maybe<Scalars['String']>;
};

export type ExchangeMealPlanTemplateMeal = MealPlanTemplateMeal & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
  readonly mealOptions: ReadonlyArray<MealPlanTemplateMealOption>;
  readonly exchangeTargets: ReadonlyArray<ExchangeAmount>;
};

export type ExchangeMealTemplate = MealTemplate & {
  readonly exchangeTargets: ReadonlyArray<ExchangeAmount>;
  readonly hasDateModifications: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly meal: Meal;
  readonly mealOptionCount: Scalars['Int'];
  readonly mealOptions: ReadonlyArray<MealOption>;
  readonly mealPlan: MealPlan;
  readonly newMealOptionIds: ReadonlyArray<Scalars['ID']>;
  readonly pendingMealOptions: ReadonlyArray<MealOptionPending>;
  readonly selectedMealOptionId: Maybe<Scalars['ID']>;
};


export type ExchangeMealTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

export type ExchangeProgressViews =
  | 'all'
  | 'none'
  | '%future added value';

export type ExchangeRatio = {
  readonly exchange: Exchange;
  readonly ratio: Scalars['Float'];
};

export type ExchangeServingList = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type ExchangeServingListInput = {
  readonly name: Scalars['String'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type ExchangeSet = {
  readonly createdBy: Maybe<User>;
  readonly exchanges: ReadonlyArray<Exchange>;
  readonly foodOrRecipeExchangeRatios: ReadonlyArray<FoodOrRecipeExchangeRatios>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type ExchangeSetOffsetConnection = {
  readonly edges: ReadonlyArray<ExchangeSet>;
  readonly pageInfo: OffsetPageInfo;
};

export type ExchangeType =
  | 'starch'
  | 'fruit'
  | 'vegetable'
  | 'protein'
  | 'dairy'
  | 'fat'
  | '%future added value';

export type ExternalEvent = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly timezone: Scalars['Timezone'];
  readonly startDate: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly isAllDay: Scalars['Boolean'];
  readonly eventType: Scalars['String'];
  readonly location: Maybe<Scalars['String']>;
};

export type FeatureFlag =
  | 'dietitianMenuSuggestions'
  | 'athleteMenuSuggestions'
  | '%future added value';

export type FeatureFlags = {
  readonly athleteMenuSuggestions: Scalars['Boolean'];
  readonly clientCredentials: Scalars['Boolean'];
  readonly hasRestaurantMenus: Scalars['Boolean'];
  readonly restaurantLogging: Scalars['Boolean'];
  readonly foodCategory: Scalars['Boolean'];
  readonly orgGroupRecipes: Scalars['Boolean'];
  readonly orgGroupFoods: Scalars['Boolean'];
  readonly performanceKitchen: Scalars['Boolean'];
  readonly printableTags: Scalars['Boolean'];
  readonly hubMenuCheckIn: Scalars['Boolean'];
  readonly dietitianOnlyMenus: Scalars['Boolean'];
  readonly bulkOrdering: Scalars['Boolean'];
  readonly ukMealPlanOptionSuggestions: Scalars['Boolean'];
  readonly digitalDisplays: Scalars['Boolean'];
  readonly selfServiceOnboarding: Scalars['Boolean'];
  readonly messaging: Scalars['Boolean'];
  readonly eatingDisorderScreens: Scalars['Boolean'];
  readonly profilesV3Api: Scalars['Boolean'];
  readonly mealPlanTemplateBulkAssignments: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly recipeScaling: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly selfServiceActivationLink: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly goalTracker: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly pk2MenuBuilder: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly aiRecipes: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly aiTranscriber: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly rebranding: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly teamworksSchedules: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly mealPlanSuggestions: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly mealPlanTemplates: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly tags: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly multipleMenuOrders: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly prepPlan: Scalars['Boolean'];
};

export type FeatureFlagsInput = {
  readonly athleteMenuSuggestions: Maybe<Scalars['Boolean']>;
  readonly clientCredentials: Maybe<Scalars['Boolean']>;
  readonly hasRestaurantMenus: Maybe<Scalars['Boolean']>;
  readonly restaurantLogging: Maybe<Scalars['Boolean']>;
  readonly foodCategory: Maybe<Scalars['Boolean']>;
  readonly orgGroupRecipes: Maybe<Scalars['Boolean']>;
  readonly orgGroupFoods: Maybe<Scalars['Boolean']>;
  readonly performanceKitchen: Maybe<Scalars['Boolean']>;
  readonly printableTags: Maybe<Scalars['Boolean']>;
  readonly hubMenuCheckIn: Maybe<Scalars['Boolean']>;
  readonly dietitianOnlyMenus: Maybe<Scalars['Boolean']>;
  readonly bulkOrdering: Maybe<Scalars['Boolean']>;
  readonly ukMealPlanOptionSuggestions: Maybe<Scalars['Boolean']>;
  readonly digitalDisplays: Maybe<Scalars['Boolean']>;
  readonly selfServiceOnboarding: Maybe<Scalars['Boolean']>;
  readonly messaging: Maybe<Scalars['Boolean']>;
  readonly eatingDisorderScreens: Maybe<Scalars['Boolean']>;
  readonly profilesV3Api: Maybe<Scalars['Boolean']>;
  readonly mealPlanTemplateBulkAssignments: Maybe<Scalars['Boolean']>;
};

export type FinalizePrepPlanInput = {
  readonly prepPlanId: Scalars['ID'];
};

export type FinalizePrepPlanPayload = {
  readonly prepPlan: PrepPlan;
};

export type Food = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly kcalPer100g: Scalars['Float'];
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly nixItemId: Maybe<Scalars['String']>;
  readonly nixTagId: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly hasFullAccess: Scalars['Boolean'];
  readonly org: Maybe<Org>;
  readonly groceryListCategory: Maybe<GroceryListCategory>;
  readonly servings: ReadonlyArray<Serving>;
  readonly defaultServing: Serving;
  readonly foodGroups: ReadonlyArray<FoodGroup>;
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  readonly createdById: Maybe<Scalars['ID']>;
  readonly createdBy: Maybe<User>;
  readonly updatedAt: Scalars['DateTime'];
  readonly showGramServing: Scalars['Boolean'];
  readonly showOunceServing: Scalars['Boolean'];
};

export type FoodCategoryType =
  | 'carbohydrate'
  | 'protein'
  | 'fats'
  | 'combination'
  | '%future added value';

export type FoodCursorConnection = {
  readonly edges: ReadonlyArray<Food>;
  readonly pageInfo: CursorPageInfo;
};

export type FoodDataSource =
  | 'manual'
  | 'usda'
  | 'nutritionix'
  | 'bonAppetit'
  | 'compass'
  | 'sodexo'
  | 'cbord'
  | 'restaurant'
  | 'mccance'
  | '%future added value';

export type FoodGroup = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly source: Maybe<Scalars['String']>;
  readonly foodsCount: Scalars['Int'];
  readonly foods: ReadonlyArray<Food>;
};

export type FoodGroupsCursorConnection = {
  readonly edges: ReadonlyArray<FoodGroup>;
  readonly pageInfo: CursorPageInfo;
};

export type FoodGroupsOffsetConnection = {
  readonly edges: ReadonlyArray<FoodGroup>;
  readonly pageInfo: OffsetPageInfo;
};

export type FoodLogActivity = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: ActivityType;
  readonly date: Scalars['Date'];
  readonly startTime: Scalars['Time'];
  readonly endTime: Scalars['Time'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activityTemplate: Maybe<ActivityTemplate>;
};

export type FoodLogMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly date: Scalars['Date'];
  readonly startTime: Scalars['Time'];
  readonly endTime: Scalars['Time'];
  readonly timezone: Scalars['Timezone'];
  readonly images: ReadonlyArray<Image>;
  readonly athlete: Maybe<Athlete>;
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly comments: ReadonlyArray<Comment>;
  readonly notifications: ReadonlyArray<Notification>;
};

export type FoodLogMealCommentNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly foodLogMeal: Maybe<FoodLogMeal>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type FoodLogMealEditNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly foodLogMeal: Maybe<FoodLogMeal>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type FoodLogMealImageNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly foodLogMeal: Maybe<FoodLogMeal>;
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly image: Maybe<Image>;
};

export type FoodLogMealPublication = {
  readonly payload: FoodLogMealPublicationPayload;
};

export type FoodLogMealPublicationPayload = AddFoodLogMealPayload | EditFoodLogMealTimePayload | RemoveFoodLogMealPayload | EditFoodLogMealServingAmountsPayload | EditFoodLogMealImagePayload | AddFoodLogMealCommentPayload | AddFoodLogMealImagePayload | { readonly __typename?: "%other" };

export type FoodLogMealSubscriberScope = {
  readonly foodLogMealId: Maybe<Scalars['ID']>;
};

export type FoodLogMealTemplateInput = {
  readonly timelineMealIds: ReadonlyArray<Scalars['ID']>;
  readonly meal: AddMealInput;
};

export type FoodLogNotificationSettings = {
  readonly edits: Scalars['Boolean'];
  readonly imageUploads: Scalars['Boolean'];
  readonly messages: Scalars['Boolean'];
};

export type FoodLogNotificationSettingsInput = {
  readonly edits: Scalars['Boolean'];
  readonly imageUploads: Scalars['Boolean'];
  readonly messages: Scalars['Boolean'];
};

export type FoodNutrientAmountFormInput = {
  readonly fdcNutrientId: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly footnote: Maybe<Scalars['String']>;
  readonly dataPoints: Maybe<Scalars['Int']>;
  readonly min: Maybe<Scalars['Float']>;
  readonly max: Maybe<Scalars['Float']>;
  readonly median: Maybe<Scalars['Float']>;
};

export type FoodOffsetConnection = {
  readonly edges: ReadonlyArray<Food>;
  readonly pageInfo: OffsetPageInfo;
};

export type FoodOrRecipe = BrandedFood | GenericFood | Recipe | { readonly __typename?: "%other" };

export type FoodOrRecipeExchangeRatios = {
  readonly id: Scalars['ID'];
  readonly foodOrRecipeId: Scalars['ID'];
  readonly exchangeRatios: ReadonlyArray<ExchangeRatio>;
};

export type FoodPreferenceInput = {
  readonly avoidedFoodGroupIds: ReadonlyArray<Scalars['ID']>;
  readonly promotedFoodGroupIds: ReadonlyArray<Scalars['ID']>;
  readonly avoidedFoodIds: ReadonlyArray<Scalars['ID']>;
  readonly promotedFoodIds: ReadonlyArray<Scalars['ID']>;
};

export type FoodServingInput = {
  readonly units: ServingUnitsInput;
  readonly weight: Scalars['Float'];
  readonly isDefault: Scalars['Boolean'];
  readonly isRecipeServingOnly: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
  readonly usdaWeightSeq: Maybe<Scalars['String']>;
};

export type ForceLockPlannedMenuInput = {
  readonly plannedMenuId: Scalars['ID'];
};

export type ForceLockPlannedMenuPayload = {
  readonly plannedMenuId: Scalars['ID'];
};

export type GptRecipeByNameInput = {
  readonly name: Scalars['String'];
  readonly servingYield: Scalars['Float'];
};

export type GptRecipeFromImageInput = {
  readonly presignedGetImageUrl: Scalars['String'];
};

export type GptRecipeFromUrlInput = {
  readonly url: Scalars['String'];
};

export type GptRecipePayload = {
  readonly name: Scalars['String'];
  readonly servingYield: Maybe<GptServingYield>;
  readonly steps: ReadonlyArray<Scalars['String']>;
  readonly ingredients: ReadonlyArray<GptServingAmount>;
  readonly unmatchedIngredients: UnmatchedIngredients;
  readonly aiType: AiType;
};

export type GptServingAmount = {
  readonly serving: Serving;
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
};

export type GptServingYield = {
  readonly amount: Scalars['Float'];
  readonly name: Scalars['String'];
};

export type GenericFood = Food & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly kcalPer100g: Scalars['Float'];
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly nixItemId: Maybe<Scalars['String']>;
  readonly nixTagId: Maybe<Scalars['String']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly hasFullAccess: Scalars['Boolean'];
  readonly org: Maybe<Org>;
  readonly orgGroup: Maybe<OrgGroup>;
  readonly groceryListCategory: Maybe<GroceryListCategory>;
  readonly servings: ReadonlyArray<Serving>;
  readonly defaultServing: Serving;
  readonly foodGroups: ReadonlyArray<FoodGroup>;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  /** @deprecated Field no longer supported */
  readonly brandedFoods: ReadonlyArray<BrandedFood>;
  readonly createdById: Maybe<Scalars['ID']>;
  readonly createdBy: Maybe<User>;
  readonly owner: Maybe<User>;
  readonly updatedAt: Scalars['DateTime'];
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly showGramServing: Scalars['Boolean'];
  readonly showOunceServing: Scalars['Boolean'];
};

export type GenericFoodFormInput = {
  readonly name: Scalars['String'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly exchangeTypes: ReadonlyArray<ExchangeType>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly groceryListCategoryId: Maybe<Scalars['String']>;
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly foodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly mccanceId: Maybe<Scalars['String']>;
  readonly mccanceName: Maybe<Scalars['String']>;
};

export type Goal = {
  readonly id: Scalars['ID'];
  readonly kcalOffset: Scalars['Int'];
  readonly start: Scalars['Date'];
  readonly end: Maybe<Scalars['Date']>;
  readonly type: GoalType;
};

export type GoalCategory = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly defaultEmoji: Scalars['String'];
};

export type GoalCompletionSummary = {
  readonly goalCompletedDayCount: Scalars['Int'];
  readonly totalGoalDayCount: Scalars['Int'];
};

export type GoalConfiguration = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly emoji: Scalars['String'];
  readonly category: GoalCategory;
  readonly isArchived: Scalars['Boolean'];
  readonly timezone: Scalars['String'];
  readonly reminders: ReadonlyArray<GoalReminderConfiguration>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly dayOfWeekAssignments: ReadonlyArray<DayOfWeek>;
  readonly individualDates: ReadonlyArray<Scalars['Date']>;
  readonly target: Maybe<GoalQuantity>;
  readonly athlete: Maybe<Athlete>;
  readonly goals: ReadonlyArray<GoalTrackerGoal>;
  readonly completedGoalCount: Scalars['Int'];
  readonly totalGoalCount: Scalars['Int'];
};


export type GoalConfigurationCompletedGoalCountArgs = {
  startDate: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
};


export type GoalConfigurationTotalGoalCountArgs = {
  startDate: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
};

export type GoalConfigurationHistory = {
  readonly id: Scalars['ID'];
  readonly current: GoalConfiguration;
  readonly all: ReadonlyArray<GoalConfiguration>;
};

export type GoalConfigurationOffsetConnection = {
  readonly edges: ReadonlyArray<GoalConfiguration>;
  readonly pageInfo: OffsetPageInfo;
};

export type GoalDayOfWeekAssignment = {
  readonly id: Scalars['ID'];
  readonly dayOfWeek: DayOfWeek;
};

export type GoalFormInput = {
  readonly goalTypeId: Scalars['ID'];
  readonly kcalOffset: Scalars['Int'];
  readonly start: Scalars['Date'];
  readonly end: Maybe<Scalars['Date']>;
};

export type GoalQuantity = {
  readonly id: Scalars['ID'];
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export type GoalReminderConfiguration = {
  readonly id: Scalars['ID'];
  readonly time: Scalars['Time'];
  readonly message: Scalars['String'];
};

export type GoalReminderNotification = Notification & {
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly message: Scalars['String'];
  readonly title: Scalars['String'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly goalTrackerGoal: Maybe<GoalTrackerGoal>;
};

export type GoalTrackerGoal = {
  readonly id: Scalars['ID'];
  readonly goalConfiguration: GoalConfiguration;
  readonly date: Scalars['Date'];
  readonly completedAtInUTC: Maybe<Scalars['String']>;
  readonly athlete: Athlete;
  readonly value: Maybe<Scalars['Int']>;
};

export type GoalTrackerGoalOffsetConnection = {
  readonly edges: ReadonlyArray<GoalTrackerGoal>;
  readonly pageInfo: OffsetPageInfo;
};

export type GoalType = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly defaultKcalOffset: Scalars['Int'];
};

export type GroceryList = {
  readonly id: Scalars['ID'];
  readonly prepPlan: PrepPlan;
  readonly notes: Maybe<Scalars['String']>;
  readonly foodItems: ReadonlyArray<GroceryListFoodItem>;
  readonly customItems: ReadonlyArray<GroceryListCustomItem>;
};

export type GroceryListCategory = {
  readonly id: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type GroceryListCustomItem = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly groceryList: GroceryList;
  readonly checked: Scalars['Boolean'];
};

export type GroceryListFoodItem = {
  readonly id: Scalars['ID'];
  readonly checked: Scalars['Boolean'];
  readonly serving: Serving;
  readonly amount: Scalars['Float'];
  readonly adjustment: Scalars['Float'];
  readonly groceryList: GroceryList;
  readonly groceryListCategory: Maybe<GroceryListCategory>;
};

export type Image = {
  readonly id: Scalars['ID'];
  readonly url: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type ImageInput = {
  readonly url: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type ImportBonAppetitMenusInput = {
  readonly cafeId: Scalars['String'];
  readonly menus: ReadonlyArray<BonAppetitMenuInput>;
  readonly options: ImportMenuOptionsInput;
};

export type ImportBonAppetitMenusPayload = {
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly skippedMealMenus: ReadonlyArray<MealMenu>;
};

export type ImportCbordMenusInput = {
  readonly serviceUnitOid: Scalars['Int'];
  readonly facilityOid: Maybe<Scalars['Int']>;
  readonly menuGroups: ReadonlyArray<CbordMenuGroupInput>;
  readonly options: ImportMenuOptionsInput;
};

export type ImportCbordMenusPayload = {
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly skippedMealMenus: ReadonlyArray<MealMenu>;
};

export type ImportCompassMenusInput = {
  readonly locationId: Scalars['Int'];
  readonly menus: ReadonlyArray<CompassMenuInput>;
  readonly options: ImportMenuOptionsInput;
};

export type ImportCompassMenusPayload = {
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly skippedMealMenus: ReadonlyArray<MealMenu>;
};

export type ImportCompassRecipeInput = {
  readonly masterRefNumber: Scalars['String'];
};

export type ImportCompassRecipeInputPayload = {
  readonly recipe: Recipe;
};

export type ImportDiningStationInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly menuItems: ReadonlyArray<ImportMenuItemInput>;
};

export type ImportMealMenuInput = {
  readonly name: Scalars['String'];
  readonly timezone: Scalars['Timezone'];
  readonly startDateTime: Scalars['DateTime'];
  readonly durationInMinutes: Scalars['Int'];
  readonly type: MealType;
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly diningStations: ReadonlyArray<ImportDiningStationInput>;
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly theme: Maybe<Scalars['String']>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
};

export type ImportMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly availableForOrder: Scalars['Boolean'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly allowSpecialRequests: Scalars['Boolean'];
};

export type ImportMenuOptionsInput = {
  readonly menuTimes: ReadonlyArray<ImportMenuTimeInput>;
  readonly timezone: Scalars['Timezone'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly availableForOrder: Scalars['Boolean'];
  readonly allowSpecialRequests: Scalars['Boolean'];
  readonly overrideIdentity: Scalars['Boolean'];
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  readonly isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  readonly theme: Maybe<Scalars['String']>;
};

export type ImportMenuTimeInput = {
  readonly menuName: Scalars['String'];
  readonly mealType: MealType;
  readonly startTime: Scalars['Time'];
  readonly durationInMinutes: Scalars['Int'];
};

export type ImportPlannedMenuInput = {
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly meals: ReadonlyArray<ImportMealMenuInput>;
};

export type ImportPlannedMenuPayload = {
  readonly mealMenus: ReadonlyArray<StandaloneMealMenu>;
};

export type ImportSodexoMenusInput = {
  readonly locationId: Scalars['String'];
  readonly menuSourceKey: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly mealTimes: ReadonlyArray<SodexoMealTimeInput>;
  readonly options: ImportMenuOptionsInput;
};

export type ImportSodexoMenusPayload = {
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly skippedMealMenus: ReadonlyArray<MealMenu>;
};

export type Integration = {
  readonly type: IntegrationType;
  readonly externalOrgId: Scalars['String'];
};

export type IntegrationInput = {
  readonly externalOrgId: Scalars['String'];
  readonly type: IntegrationType;
};

export type IntegrationType =
  | 'compass'
  | 'cbord'
  | 'bonAppetit'
  | 'sodexo'
  | '%future added value';

export type IsTagNameUniquePayload = {
  readonly isUnique: Scalars['Boolean'];
};

export type LinkTeamworksOrgInput = {
  readonly id: Scalars['ID'];
  readonly teamworksId: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type LinkTeamworksOrgPayload = {
  readonly org: Org;
};

export type LinkTeamworksPositionInput = {
  readonly teamworksId: Scalars['Int'];
  readonly teamworksTeamId: Scalars['Int'];
  readonly positionId: Scalars['ID'];
};

export type LinkTeamworksTeamInput = {
  readonly id: Scalars['ID'];
  readonly teamworksId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly gender: Maybe<Scalars['String']>;
  readonly linkedSport: Maybe<LinkedSportInput>;
  readonly newSport: Maybe<CreateTeamworksSportInput>;
};

export type LinkedSportInput = {
  readonly sportId: Scalars['ID'];
  readonly newPositions: ReadonlyArray<CreateTeamworksPositionInput>;
  readonly linkedPositions: ReadonlyArray<LinkTeamworksPositionInput>;
};

export type Locale = {
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
};

export type LockPlannedMenuInput = {
  readonly plannedMenuId: Scalars['ID'];
};

export type LockPlannedMenuPayload = {
  readonly lockAcquired: Scalars['Boolean'];
  readonly plannedMenu: PlannedMenu;
};

export type MacroMath = {
  readonly gPerKg: Scalars['Float'];
  readonly leftOverRatio: Scalars['Float'];
};

export type MacroMathInput = {
  readonly gPerKg: Scalars['Float'];
  readonly leftOverRatio: Scalars['Float'];
};

export type MacroMealPlanDisplaySettings = {
  readonly dailyProgressViews: MacroProgressViews;
  readonly mealProgressViews: MacroProgressViews;
};

export type MacroMealPlanDisplaySettingsInput = {
  readonly dailyProgressViews: MacroProgressViews;
  readonly mealProgressViews: MacroProgressViews;
};

export type MacroMealPlanTemplate = MealPlanTemplate & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly owner: User;
  /** @deprecated Field no longer supported */
  readonly orgId: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  readonly orgGroupId: Maybe<Scalars['ID']>;
  readonly teams: Maybe<ReadonlyArray<Team>>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly copiedCount: Scalars['Int'];
  readonly avoidedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly promotedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly avoidedFoods: ReadonlyArray<Food>;
  readonly promotedFoods: ReadonlyArray<Food>;
  readonly macroProtocol: MealPlanTemplateMacroProtocol;
  readonly meals: ReadonlyArray<MacroMealPlanTemplateMeal>;
  readonly activities: ReadonlyArray<MealPlanTemplateActivity>;
  readonly pendingState: Maybe<Scalars['String']>;
  readonly additionalState: Maybe<Scalars['String']>;
};

export type MacroMealPlanTemplateMeal = MealPlanTemplateMeal & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
  readonly mealOptions: ReadonlyArray<MealPlanTemplateMealOption>;
  readonly choRatio: Scalars['Float'];
  readonly proRatio: Scalars['Float'];
  readonly fatRatio: Scalars['Float'];
};

export type MacroMealPlanTemplateMealInput = {
  readonly meal: AddMealInput;
  readonly mealOptions: ReadonlyArray<MealOptionInput>;
  readonly choRatio: Scalars['Float'];
  readonly proRatio: Scalars['Float'];
  readonly fatRatio: Scalars['Float'];
};

export type MacroMealPlanTemplatePendingStateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
  readonly pendingState: Scalars['String'];
};

export type MacroMealTemplate = MealTemplate & {
  readonly cho: Maybe<Scalars['Float']>;
  readonly choInput: Scalars['String'];
  readonly fat: Maybe<Scalars['Float']>;
  readonly fatInput: Scalars['String'];
  readonly hasDateModifications: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly kcal: Maybe<Scalars['Float']>;
  readonly meal: Meal;
  readonly mealOptionCount: Scalars['Int'];
  readonly mealOptions: ReadonlyArray<MealOption>;
  readonly mealPlan: MealPlan;
  readonly newMealOptionIds: ReadonlyArray<Scalars['ID']>;
  readonly pendingMealOptions: ReadonlyArray<MealOptionPending>;
  readonly pro: Maybe<Scalars['Float']>;
  readonly proInput: Scalars['String'];
  readonly selectedMealOptionId: Maybe<Scalars['ID']>;
};


export type MacroMealTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

export type MacroProgressViews =
  | 'all'
  | 'hideNumbers'
  | 'none'
  | '%future added value';

export type MacroProtocol = {
  readonly id: Scalars['ID'];
  readonly cho: MacroMath;
  readonly pro: MacroMath;
  readonly fat: MacroMath;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly weightTargetInKg: Maybe<Scalars['Float']>;
  readonly anthropometrySnapshot: AnthropometryEntry;
  readonly calorieBudget: Maybe<CalorieBudget>;
};

export type MacroProtocolInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
};

export type MacroProtocolNewInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
  readonly calorieBudget: Maybe<CalorieBudgetInput>;
  readonly goalId: Maybe<Scalars['ID']>;
  readonly goal: Maybe<GoalFormInput>;
};

export type MacroProtocolWithNewGoalInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
  readonly calorieBudget: CalorieBudgetInput;
  readonly goal: GoalFormInput;
};

export type MacroProtocolWithOldGoalInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
  readonly calorieBudget: CalorieBudgetInput;
  readonly goalId: Scalars['ID'];
};

export type MacroType =
  | 'cho'
  | 'fat'
  | 'pro'
  | '%future added value';

export type Macros = {
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly kcal: Scalars['Float'];
};

export type MacrosAndKcals = {
  readonly cho: Scalars['Float'];
  readonly choKcal: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly proKcal: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly fatKcal: Scalars['Float'];
  readonly kcal: Scalars['Float'];
};

export type MacrosInput = {
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
};

export type MarkAllNotificationsAsViewedPayload = {
  readonly notifications: ReadonlyArray<Notification>;
};

export type MarkNotificationsAsViewedBeforeInput = {
  readonly before: Scalars['DateTime'];
};

export type MarkNotificationsAsViewedBeforePayload = {
  readonly notifications: ReadonlyArray<Notification>;
};

export type MarkNotificationsAsViewedInput = {
  readonly notificationIds: ReadonlyArray<Scalars['ID']>;
};

export type MarkNotificationsAsViewedPayload = {
  readonly notifications: ReadonlyArray<Notification>;
};

export type Meal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealEvent = {
  readonly id: Scalars['ID'];
  readonly datetime: Scalars['DateTime'];
  readonly timezone: Scalars['String'];
  readonly mealType: MealType;
  readonly totalMacros: Macros;
  readonly targetMacros: Macros;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  readonly mealMenuInfo: ReadonlyArray<MealMenuInfo>;
};

export type MealMenu = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStation>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimit>;
  readonly userOrderLimit: Maybe<Scalars['Int']>;
  readonly isLogOnly: Scalars['Boolean'];
  readonly isInHouseMenuOrderable: Scalars['Boolean'];
  readonly isRestaurantMenuOrderable: Scalars['Boolean'];
  readonly isBulkOrderable: Scalars['Boolean'];
  readonly isOrderable: Scalars['Boolean'];
  readonly identity: Maybe<MealMenuIdentity>;
  readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLink>;
  readonly isHubCheckInEnabled: Scalars['Boolean'];
  readonly hubEventUrl: Maybe<Scalars['String']>;
  readonly isOrderAndLogRestricted: Scalars['Boolean'];
  readonly isViewOnlyForMe: Scalars['Boolean'];
  readonly teams: ReadonlyArray<Team>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly athleteCount: Scalars['Int'];
  readonly athletes: ReadonlyArray<Athlete>;
  readonly allOrders: ReadonlyArray<MenuOrder>;
  readonly allMealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  /** @deprecated pending forced update for multiple MenuOrders per menu */
  readonly order: Maybe<MenuOrder>;
  readonly orders: ReadonlyArray<MenuOrder>;
  readonly ordersForUser: ReadonlyArray<MenuOrder>;
  readonly ordersForAthlete: ReadonlyArray<MenuOrder>;
  readonly logs: ReadonlyArray<MealMenuLog>;
  readonly logsForAthlete: ReadonlyArray<MealMenuLog>;
  readonly theme: Maybe<Scalars['String']>;
};


export type MealMenuOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type MealMenuOrdersForAthleteArgs = {
  athleteId: Scalars['ID'];
};


export type MealMenuLogsForAthleteArgs = {
  athleteId: Scalars['ID'];
};

export type MealMenuAttendeeNonNotemealAthlete = {
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly checkedIn: Scalars['Boolean'];
  readonly checkInDisplayDate: Maybe<Scalars['String']>;
  readonly hasAccess: Maybe<Scalars['Boolean']>;
};

export type MealMenuAttendeeNotemealAthlete = {
  readonly athlete: Athlete;
  readonly checkedIn: Scalars['Boolean'];
  readonly checkInDisplayDate: Maybe<Scalars['String']>;
  readonly hasAccess: Maybe<Scalars['Boolean']>;
};

export type MealMenuAttendeePayload = {
  readonly notemealAttendees: ReadonlyArray<MealMenuAttendeeNotemealAthlete>;
  readonly nonNotemealAttendees: ReadonlyArray<MealMenuAttendeeNonNotemealAthlete>;
  readonly queryCompletedAt: Scalars['DateTime'];
};

export type MealMenuAvailableNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly mealMenu: Maybe<StandaloneMealMenu>;
};

export type MealMenuCheckIn = {
  readonly id: Scalars['ID'];
  readonly mealMenuId: Scalars['ID'];
  readonly athleteId: Scalars['ID'];
};

export type MealMenuDiningStation = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly diningStationTemplate: Maybe<DiningStationTemplate>;
  /** @deprecated includeLogOnlyItems not used on back-end anymore, can remove on next mobile force update */
  readonly menuItemAppearances: ReadonlyArray<MenuItemAppearance>;
};


export type MealMenuDiningStationMenuItemAppearancesArgs = {
  includeLogOnlyItems: Maybe<Scalars['Boolean']>;
  searchMenuItemQuery: Maybe<Scalars['String']>;
};

export type MealMenuIdentity = {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type MealMenuInfo = {
  readonly name: Maybe<Scalars['String']>;
  readonly usedToOrder: Scalars['Boolean'];
};

export type MealMenuIsSharedNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly mealPlan: Maybe<MealPlan>;
};

export type MealMenuLog = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly mealMenu: MealMenu;
  readonly items: ReadonlyArray<MealMenuLogItem>;
};

export type MealMenuLogItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MealMenuLogItemOption>;
  readonly log: MealMenuLog;
};

export type MealMenuLogItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<MealMenuLogItemOptionInput>;
};

export type MealMenuLogItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MealMenuLogItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type MealMenuOrderDueNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly mealMenu: Maybe<StandaloneMealMenu>;
};

export type MealMenuPlate = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly parentBulkOrder: BulkOrder;
  readonly mealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly orderCount: Scalars['Int'];
  readonly plateItems: ReadonlyArray<MealMenuPlateItem>;
};

export type MealMenuPlateItem = {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MealMenuPlateItemOption>;
  readonly mealMenuPlate: MealMenuPlate;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type MealMenuPlateItemOption = {
  readonly amount: Scalars['Float'];
  readonly mealMenuPlateItem: MealMenuPlateItem;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MealMenuPlateItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type MealMenuPlateItemOrderStatus = {
  readonly status: MenuOrderItemStatus;
  readonly mealMenuPlateOrder: MealMenuPlateOrder;
  readonly mealMenuPlateItem: MealMenuPlateItem;
};

export type MealMenuPlateItemOrderStatusPublication = {
  readonly scope: MealMenuPlateItemOrderStatusPublisherScope;
  readonly payload: UpdateMultipleMealMenuPlateItemOrderStatusesPayload;
};

export type MealMenuPlateItemOrderStatusPublisherScope = {
  readonly mealMenuId: Scalars['ID'];
};

export type MealMenuPlateItemOrderStatusSubscriberScope = {
  readonly mealMenuIds: ReadonlyArray<Scalars['ID']>;
};

export type MealMenuPlateOrder = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly userFullName: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly code: Scalars['String'];
  readonly parentBulkOrder: BulkOrder;
  readonly plate: MealMenuPlate;
  readonly items: ReadonlyArray<MealMenuPlateOrderItem>;
  readonly itemCount: Scalars['Int'];
  readonly itemsByDiningStation: ReadonlyArray<MealMenuPlateOrderItem>;
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly status: Scalars['String'];
  readonly athlete: Maybe<Athlete>;
  readonly mealMenu: MealMenu;
};


export type MealMenuPlateOrderItemsByDiningStationArgs = {
  diningStationIds: ReadonlyArray<Scalars['ID']>;
};

export type MealMenuPlateOrderCursorConnection = {
  readonly edges: ReadonlyArray<MealMenuPlateOrder>;
  readonly pageInfo: CursorPageInfo;
};

export type MealMenuPlateOrderItem = MenuSelectionItem & {
  readonly id: Scalars['String'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MealMenuPlateOrderItemOption>;
  readonly mealMenuPlate: MealMenuPlate;
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly status: MenuOrderItemStatus;
  readonly rating: Maybe<Rating>;
};

export type MealMenuPlateOrderItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly mealMenuPlateItem: MealMenuPlateItem;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MealMenuUserOrderLimitInput = {
  readonly limit: Maybe<Scalars['Int']>;
};

export type MealMenusCursorConnection = {
  readonly edges: ReadonlyArray<MealMenu>;
  readonly pageInfo: CursorPageInfo;
};

export type MealMenusWithCheckinPayload = {
  readonly mealMenu: StandaloneMealMenu;
  readonly mealMenuExpectedAttendeeCount: Scalars['Int'];
  readonly mealMenuActualAttendeeCount: Scalars['Int'];
  readonly attendeeUnlistedCount: Scalars['Int'];
  readonly attendeeListedCount: Scalars['Int'];
};

export type MealOption = {
  readonly id: Scalars['ID'];
  readonly ingredientText: Scalars['String'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly name: Maybe<Scalars['String']>;
  readonly note: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type MealOptionCursorConnection = {
  readonly edges: ReadonlyArray<MealOption>;
  readonly pageInfo: CursorPageInfo;
};

export type MealOptionInput = {
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly position: Scalars['Int'];
  readonly note: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
};

export type MealOptionPending = {
  readonly id: Scalars['ID'];
  readonly ingredientText: Scalars['String'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly name: Maybe<Scalars['String']>;
  readonly note: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type MealOptionPendingInput = {
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly note: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
};

export type MealPlan = {
  readonly activityTemplates: ReadonlyArray<ActivityTemplate>;
  readonly athlete: Athlete;
  readonly avoidedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly avoidedFoods: ReadonlyArray<Food>;
  readonly createdAt: Scalars['DateTime'];
  /** @deprecated Field no longer supported */
  readonly dates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly daysOfWeek: ReadonlyArray<MealPlanDayOfWeekAssignment>;
  readonly description: Maybe<Scalars['String']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly events: ReadonlyArray<Event>;
  readonly exchangeSet: Maybe<ExchangeSet>;
  readonly exchangeTargets: Maybe<ReadonlyArray<ExchangeAmount>>;
  readonly hasIndividualDateAssignments: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly individualDatesInRange: ReadonlyArray<Scalars['Date']>;
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly macroProtocol: MacroProtocol;
  readonly mealTemplates: ReadonlyArray<MealTemplate>;
  readonly modificationsInRange: ReadonlyArray<MealPlanDateModifications>;
  readonly mostRecentEvent: Maybe<Event>;
  readonly name: Scalars['String'];
  readonly promotedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly promotedFoods: ReadonlyArray<Food>;
  readonly schedule: Maybe<Schedule>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly type: MealPlanType;
  readonly updatedAt: Scalars['DateTime'];
  /** @deprecated Field no longer supported */
  readonly usesDateRange: Scalars['Boolean'];
  readonly wasCreatedFromMealPlanTemplate: Scalars['Boolean'];
};


export type MealPlanIndividualDatesInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type MealPlanModificationsInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};

export type MealPlanCreateFormInput = {
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly usesDateRange: Scalars['Boolean'];
  readonly dates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly handleDatesConflict: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
};

export type MealPlanCursorConnection = {
  readonly edges: ReadonlyArray<MealPlan>;
  readonly pageInfo: CursorPageInfo;
};

export type MealPlanDate = {
  readonly mealPlan: MealPlan;
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
};

/**
 * Entity that combines individual date and day of week assignments to answer questions
 * 1. What MealPlan is assigned to a date?
 * 2. How is the MealPlan assigned to a date (via individual date or day of week)?
 * Without performing all of the resolution logic in on the frontend
 */
export type MealPlanDateAssignment = {
  readonly date: Scalars['Date'];
  readonly mealPlan: MealPlan;
  readonly dayOfWeekPriority: Maybe<DayOfWeekPriority>;
  readonly mealTemplateModifications: ReadonlyArray<MealTemplateModification>;
  readonly activityTemplateModifications: ReadonlyArray<ActivityTemplateModification>;
};

export type MealPlanDateModificationInput = {
  readonly date: Scalars['Date'];
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealPlanDateModifications = {
  readonly date: Scalars['Date'];
  readonly mealTemplateModifications: ReadonlyArray<MealTemplateModification>;
  readonly activityTemplateModifications: ReadonlyArray<ActivityTemplateModification>;
};

export type MealPlanDayOfWeekAssignment = {
  readonly mealPlan: MealPlan;
  readonly dayOfWeekPriority: DayOfWeekPriority;
  readonly mealTemplateModifications: ReadonlyArray<MealTemplateModification>;
  readonly activityTemplateModifications: ReadonlyArray<ActivityTemplateModification>;
};

export type MealPlanDayOfWeekModificationInput = {
  readonly dayOfWeek: DayOfWeek;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealPlanEditFormInput = {
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly usesDateRange: Scalars['Boolean'];
  readonly dates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly handleDatesConflict: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly type: MealPlanType;
};

export type MealPlanFromTemplateAnthropometryInput = {
  readonly existingAnthropometryEntryId: Maybe<Scalars['ID']>;
  readonly anthropometryEntryInput: Maybe<AnthropometryEntryFormInput>;
};

export type MealPlanFromTemplateMetricAnthropometryInput = {
  readonly existingAnthropometryEntryId: Maybe<Scalars['ID']>;
  readonly anthropometryEntryInput: Maybe<MetricAnthropometryEntryFormInput>;
};

export type MealPlanIndividualDateAssignment = {
  readonly date: Scalars['Date'];
  readonly mealPlan: MealPlan;
};

export type MealPlanOffsetConnection = {
  readonly edges: ReadonlyArray<MealPlan>;
  readonly pageInfo: OffsetPageInfo;
};

export type MealPlanSharedNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly mealPlan: Maybe<MealPlan>;
};

export type MealPlanTemplate = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly owner: User;
  /** @deprecated Field no longer supported */
  readonly orgId: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  readonly orgGroupId: Maybe<Scalars['ID']>;
  readonly teams: Maybe<ReadonlyArray<Team>>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly copiedCount: Scalars['Int'];
  readonly avoidedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly promotedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly avoidedFoods: ReadonlyArray<Food>;
  readonly promotedFoods: ReadonlyArray<Food>;
  readonly macroProtocol: MealPlanTemplateMacroProtocol;
  readonly meals: ReadonlyArray<MealPlanTemplateMeal>;
  readonly activities: ReadonlyArray<MealPlanTemplateActivity>;
  readonly pendingState: Maybe<Scalars['String']>;
  /**
   * This field stores values the user entered in the UI but not required for creating a meal plan from a meal plan template.
   * The sample data the user used is stored.  When the user turns off calorie budget we also save it here so things feel familiar
   * if they decide to turn it back on.
   */
  readonly additionalState: Maybe<Scalars['String']>;
};

export type MealPlanTemplateActivity = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: ActivityType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealPlanTemplateActivityInput = {
  readonly name: Scalars['String'];
  readonly type: ActivityType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealPlanTemplateAnthropometry = AnthropometryTemplate | AnthropometryForm | { readonly __typename?: "%other" };

export type MealPlanTemplateAnthropometryInput = {
  readonly formFields: Maybe<ReadonlyArray<AnthropometryFormField>>;
  readonly template: Maybe<AnthropometryTemplateInput>;
};

export type MealPlanTemplateCalorieBudget = {
  readonly id: Scalars['ID'];
  readonly rmrMethod: RmrMethod;
  readonly activityFactor: Scalars['Float'];
  readonly goals: ReadonlyArray<MealPlanTemplateGoal>;
};

export type MealPlanTemplateCalorieBudgetInput = {
  readonly rmrMethod: RmrMethod;
  readonly activityFactor: Scalars['Float'];
  readonly goals: ReadonlyArray<MealPlanTemplateGoalInput>;
};

export type MealPlanTemplateCursorConnection = {
  readonly edges: ReadonlyArray<MealPlanTemplate>;
  readonly pageInfo: CursorPageInfo;
};

export type MealPlanTemplateGoal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly kcalOffset: Scalars['Int'];
  readonly goalType: GoalType;
};

export type MealPlanTemplateGoalInput = {
  readonly name: Scalars['String'];
  readonly kcalOffset: Scalars['Int'];
  readonly goalTypeId: Scalars['ID'];
};

export type MealPlanTemplateMacroProtocol = {
  readonly id: Scalars['ID'];
  readonly cho: MacroMath;
  readonly pro: MacroMath;
  readonly fat: MacroMath;
  readonly useExistingAnthropometry: Scalars['Boolean'];
  readonly anthropometry: MealPlanTemplateAnthropometry;
  readonly calorieBudget: Maybe<MealPlanTemplateCalorieBudget>;
};

export type MealPlanTemplateMacroProtocolInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly useExistingAnthropometry: Scalars['Boolean'];
  readonly anthropometry: MealPlanTemplateAnthropometryInput;
  readonly calorieBudget: Maybe<MealPlanTemplateCalorieBudgetInput>;
};

export type MealPlanTemplateMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
  readonly mealOptions: ReadonlyArray<MealPlanTemplateMealOption>;
};

export type MealPlanTemplateMealOption = {
  readonly id: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly note: Maybe<Scalars['String']>;
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly name: Maybe<Scalars['String']>;
};

export type MealPlanTemplateMealOptionInput = {
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly position: Scalars['Int'];
  readonly note: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
};

export type MealPlanTemplateOffsetConnection = {
  readonly edges: ReadonlyArray<MealPlanTemplate>;
  readonly pageInfo: OffsetPageInfo;
};

export type MealPlanType =
  | 'macro'
  | 'exchange'
  | '%future added value';

export type MealTemplate = {
  readonly hasDateModifications: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly meal: Meal;
  readonly mealOptionCount: Scalars['Int'];
  readonly mealOptions: ReadonlyArray<MealOption>;
  readonly mealPlan: MealPlan;
  readonly newMealOptionIds: ReadonlyArray<Scalars['ID']>;
  readonly pendingMealOptions: ReadonlyArray<MealOptionPending>;
  readonly selectedMealOptionId: Maybe<Scalars['ID']>;
};


export type MealTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

/**
 * Modification of a MealTemplate, tied to either an individual date or a day of the week
 * Currently only time edits (start and end) are supported
 */
export type MealTemplateModification = {
  readonly mealTemplate: MealTemplate;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealType =
  | 'breakfast'
  | 'lunch'
  | 'dinner'
  | 'snack'
  | 'meal'
  | '%future added value';

export type MenuItem = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isOneOff: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
  readonly defaultAvailableForOrder: Scalars['Boolean'];
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly ratingCounts: RatingCounts;
  readonly isCurrent: Scalars['Boolean'];
  readonly isDeleted: Scalars['Boolean'];
  readonly orderItemCount: Scalars['Int'];
  readonly isMissingIngredients: Scalars['Boolean'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly servingAmountsCount: Scalars['Int'];
  readonly choices: ReadonlyArray<MenuItemChoice>;
  readonly history: MenuItemHistory;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly defaultAllowSpecialRequests: Scalars['Boolean'];
  readonly score: Maybe<Score>;
  readonly foodCategory: Maybe<MenuItemFoodCategory>;
  /** @deprecated Field no longer supported */
  readonly isAiGenerated: Scalars['Boolean'];
  readonly aiAugmented: AiAugmented;
};

export type MenuItemAmount = MenuSelectionItem & {
  readonly menuItem: MenuItem;
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<MenuItemChoiceOptionAmount>;
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type MenuItemAmountInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Int'];
  readonly options: ReadonlyArray<MenuItemChoiceOptionAmountInput>;
};

export type MenuItemAppearance = {
  readonly id: Scalars['ID'];
  readonly menuItem: MenuItem;
  readonly position: Scalars['Int'];
  readonly availableForOrder: Scalars['Boolean'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly allowSpecialRequests: Scalars['Boolean'];
};

export type MenuItemAppearanceInput = {
  readonly position: Scalars['Int'];
  readonly availableForOrder: Scalars['Boolean'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly allowSpecialRequests: Scalars['Boolean'];
};

export type MenuItemChoice = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly maxOptionsCount: Maybe<Scalars['Int']>;
  readonly required: Scalars['Boolean'];
  readonly options: ReadonlyArray<MenuItemChoiceOption>;
};

export type MenuItemChoiceOption = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly canEditAmount: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
  readonly maxAmount: Scalars['Float'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type MenuItemChoiceOptionAmount = MenuSelectionItemOption & {
  readonly menuItemChoiceOption: MenuItemChoiceOption;
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type MenuItemChoiceOptionAmountInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
  readonly amount: Scalars['Int'];
};

export type MenuItemChoiceOptionMissingIngredientsInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type MenuItemCursorConnection = {
  readonly edges: ReadonlyArray<MenuItem>;
  readonly pageInfo: CursorPageInfo;
};

export type MenuItemFoodCategory = {
  readonly category: FoodCategoryType;
};

export type MenuItemHistory = {
  readonly id: Scalars['ID'];
  readonly current: MenuItem;
  readonly all: ReadonlyArray<MenuItem>;
  readonly ratingCounts: RatingCounts;
};

export type MenuItemMissingIngredientsInput = {
  readonly menuItemId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly menuItemChoiceOptions: ReadonlyArray<MenuItemChoiceOptionMissingIngredientsInput>;
};

export type MenuItemOffsetConnection = {
  readonly edges: ReadonlyArray<MenuItem>;
  readonly pageInfo: OffsetPageInfo;
};

export type MenuItemRating = Rating & {
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly value: RatingValue;
  readonly review: Maybe<Scalars['String']>;
  readonly createdBy: Maybe<User>;
  readonly menuOrderItem: Maybe<MenuOrderItem>;
  readonly menuItem: MenuItem;
};

export type MenuItemRatingOffsetConnection = {
  readonly edges: ReadonlyArray<Rating>;
  readonly pageInfo: OffsetPageInfo;
};

export type MenuItemSuggestionCategory =
  | 'combo'
  | 'main'
  | 'protein'
  | 'starch'
  | 'vegetable'
  | 'fruit'
  | 'other'
  | '%future added value';

export type MenuOrder = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly pickupTime: Scalars['DateTime'];
  readonly code: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly mealMenu: MealMenu;
  readonly items: ReadonlyArray<MenuOrderItem>;
  readonly itemCount: Scalars['Int'];
  readonly itemsByDiningStation: ReadonlyArray<MenuOrderItem>;
  readonly athlete: Maybe<Athlete>;
  readonly userFullName: Scalars['String'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly comments: ReadonlyArray<Comment>;
  readonly images: ReadonlyArray<Image>;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly timelineMeal: Maybe<TimelineMeal>;
};


export type MenuOrderItemsByDiningStationArgs = {
  diningStationIds: ReadonlyArray<Scalars['ID']>;
};

export type MenuOrderCommentNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly menuOrder: Maybe<MenuOrder>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type MenuOrderCursorConnection = {
  readonly edges: ReadonlyArray<MenuOrder>;
  readonly pageInfo: CursorPageInfo;
};

export type MenuOrderImageNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly menuOrder: Maybe<MenuOrder>;
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly image: Maybe<Image>;
};

export type MenuOrderInfo = {
  readonly mealMenuId: Scalars['ID'];
  readonly menuOrderCount: Scalars['Int'];
  readonly mostRecentEditableMenuOrderId: Maybe<Scalars['ID']>;
};

export type MenuOrderItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly forOrder: Scalars['Boolean'];
  readonly excludeLogItems: Maybe<Scalars['Boolean']>;
  readonly status: MenuOrderItemStatus;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MenuOrderItemOption>;
  readonly order: MenuOrder;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly rating: Maybe<Rating>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type MenuOrderItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly options: ReadonlyArray<MenuOrderItemOptionInput>;
};

export type MenuOrderItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MenuOrderItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type MenuOrderItemStatus =
  | 'new'
  | 'cooking'
  | 'done'
  | 'cancelled'
  | '%future added value';

export type MenuOrderItemStatusesPublication = {
  readonly scope: MenuOrderItemStatusesPublisherScope;
  readonly payload: UpdateMenuOrderItemStatusesPayload;
};

export type MenuOrderItemStatusesPublisherScope = {
  readonly mealMenuId: Scalars['ID'];
};

export type MenuOrderItemStatusesSubscriberScope = {
  readonly mealMenuIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type MenuOrderPublication = {
  readonly scope: MenuOrderPublisherScope;
  readonly payload: MenuOrderPublicationPayload;
};

export type MenuOrderPublicationPayload = AddMenuOrderPayload | EditMenuOrderPayload | RemoveMenuOrderPayload | AddMenuOrderCommentPayload | { readonly __typename?: "%other" };

export type MenuOrderPublisherScope = {
  readonly mealMenuId: Scalars['ID'];
};

export type MenuOrderRateLimit = {
  readonly limit: Scalars['Int'];
  readonly excludedTeamIds: ReadonlyArray<Scalars['ID']>;
};

export type MenuOrderRateLimitInput = {
  readonly limit: Scalars['Int'];
  readonly excludedTeamIds: ReadonlyArray<Scalars['ID']>;
};

export type MenuOrderRateLimitRemainder = {
  readonly remaining: Scalars['Int'];
  readonly start: Scalars['DateTime'];
};

export type MenuOrderStatusNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly menuOrder: Maybe<MenuOrder>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type MenuOrderSubscriberScope = {
  readonly mealMenuIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type MenuOrderSuggestion = MenuSelection & {
  readonly items: ReadonlyArray<MenuItemAmount>;
};

export type MenuOrderSuggestionInput = {
  readonly items: ReadonlyArray<MenuItemAmountInput>;
};

export type MenuSelection = {
  readonly items: ReadonlyArray<MenuSelectionItem>;
};

export type MenuSelectionItem = {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MenuSelectionItemOption>;
};

export type MenuSelectionItemOption = {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MessageOption = {
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly teamName: Scalars['String'];
};

export type MessageOptionCursorConnection = {
  readonly edges: ReadonlyArray<MessageOption>;
  readonly pageInfo: CursorPageInfo;
};

export type MetricAnthropometryEntryFormInput = {
  readonly heightInCm: Maybe<Scalars['Float']>;
  readonly weightInKg: Scalars['Float'];
  readonly leanBodyMassInKg: Maybe<Scalars['Float']>;
  readonly bodyFatMassInKg: Maybe<Scalars['Float']>;
  readonly skeletalMuscleMassInKg: Maybe<Scalars['Float']>;
  readonly trunkFatInKg: Maybe<Scalars['Float']>;
  readonly dryLeanMassInKg: Maybe<Scalars['Float']>;
  readonly percentBodyFat: Maybe<Scalars['Float']>;
  readonly visceralFatArea: Maybe<Scalars['Float']>;
  readonly datetime: Scalars['DateTime'];
  readonly sex: SexType;
  readonly type: AnthropometryEntryType;
  readonly comment: Maybe<Scalars['String']>;
  readonly boneMineralDensity: Maybe<Scalars['Float']>;
  readonly boneMass: Maybe<Scalars['Float']>;
  readonly boneMineralContent: Maybe<Scalars['Float']>;
  readonly boneArea: Maybe<Scalars['Float']>;
  readonly boneMineralDensityZScore: Maybe<Scalars['Float']>;
  readonly boneMineralDensityTScore: Maybe<Scalars['Float']>;
};

export type MetricMacroProtocolInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTargetInKg: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
  readonly calorieBudget: Maybe<CalorieBudgetInput>;
  readonly goalId: Maybe<Scalars['ID']>;
  readonly goal: Maybe<GoalFormInput>;
};

export type MobileUpdateType =
  | 'force'
  | 'suggest'
  | '%future added value';

export type MoveMacroMealPlanTemplateToOrgGroupInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
  readonly orgGroupId: Scalars['ID'];
};

export type MoveMacroMealPlanTemplateToOrgGroupPayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type MoveMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly appearance: MenuItemAppearanceInput;
};

export type MoveOrgFoodToOrgGroupInput = {
  readonly id: Scalars['ID'];
  readonly orgGroupId: Scalars['ID'];
};

export type MoveOrgFoodToOrgGroupPayload = {
  readonly food: Food;
};

export type MoveOrgGroupFoodToMyOrgInput = {
  readonly id: Scalars['ID'];
};

export type MoveOrgGroupFoodToMyOrgPayload = {
  readonly food: Food;
};

export type MoveOrgGroupMacroMealPlanTemplateToOrgInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type MoveOrgGroupMacroMealPlanTemplateToOrgPayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type MoveOrgRecipeToOrgGroupInput = {
  readonly id: Scalars['ID'];
  readonly orgGroupId: Scalars['ID'];
};

export type MoveOrgRecipeToOrgGroupPayload = {
  readonly recipe: Recipe;
};

export type Mutation = {
  readonly addAgreementForm: AddAgreementFormPayload;
  readonly addAgreementFormSubmission: AddAgreementFormSubmissionPayload;
  readonly addAndLinkTeamworksTeams: AddAndLinkTeamworksTeamsPayload;
  readonly addAthleteDislikedFood: AddAthleteDislikedFoodPayload;
  readonly addAthleteDislikedFoodGroup: AddAthleteDislikedFoodGroupPayload;
  readonly addAthleteEmail: AddAthleteEmailPayload;
  readonly addAthleteLikedFood: AddAthleteLikedFoodPayload;
  readonly addAthleteLikedFoodGroup: AddAthleteLikedFoodGroupPayload;
  readonly addAthletePhoneNumber: AddAthletePhoneNumberPayload;
  readonly addBulkOrder: AddBulkOrderPayload;
  readonly addDeliveryLocation: AddDeliveryLocationPayload;
  readonly addDevice: AddDevicePayload;
  readonly addEvent: AddEventPayload;
  readonly addFoodItemToGroceryList: EditGroceryListFoodItemPayload;
  readonly addFoodLogActivityWithTemplate: AddFoodLogActivityWithTemplatePayload;
  readonly addFoodLogActivityWithTemplateForAthlete: AddFoodLogActivityWithTemplateForAthletePayload;
  readonly addFoodLogActivityWithoutTemplate: AddFoodLogActivityWithoutTemplatePayload;
  readonly addFoodLogActivityWithoutTemplateForAthlete: AddFoodLogActivityWithoutTemplateForAthletePayload;
  readonly addFoodLogMealComment: AddFoodLogMealCommentPayload;
  readonly addFoodLogMealImage: AddFoodLogMealImagePayload;
  readonly addFoodLogMealWithTemplate: AddFoodLogMealWithTemplatePayload;
  readonly addFoodLogMealWithTemplateForAthlete: AddFoodLogMealWithTemplateForAthletePayload;
  readonly addFoodLogMealWithoutTemplate: AddFoodLogMealWithoutTemplatePayload;
  readonly addFoodLogMealWithoutTemplateForAthlete: AddFoodLogMealWithoutTemplateForAthletePayload;
  readonly addMealMenuLogItem: AddMealMenuLogItemPayload;
  readonly addMealMenuLogItemForAthlete: AddMealMenuLogItemPayload;
  readonly addMealMenuLogItems: AddMealMenuLogItemsPayload;
  readonly addMealMenuLogItemsForAthlete: AddMealMenuLogItemsPayload;
  readonly addMealOption: AddMealOptionPayload;
  readonly addMealOptionLocal: Maybe<Scalars['ID']>;
  readonly addMenuItemHistoryMissingIngredients: AddMenuItemHistoryMissingIngredientsPayload;
  readonly addMenuOrderComment: AddMenuOrderCommentPayload;
  readonly addMenuOrderForAthlete: AddMenuOrderForAthletePayload;
  readonly addMenuOrderImage: AddMenuOrderImagePayload;
  readonly addMenuOrderItemRatings: AddMenuOrderItemRatingsPayload;
  readonly addMenuOrderWithTemplate: AddMenuOrderWithTemplatePayload;
  readonly addMenuOrderWithoutTemplate: AddMenuOrderWithoutTemplatePayload;
  readonly addMyDislikedFood: AddAthleteDislikedFoodPayload;
  readonly addMyDislikedFoodGroup: AddAthleteDislikedFoodGroupPayload;
  readonly addMyLikedFood: AddAthleteLikedFoodPayload;
  readonly addMyLikedFoodGroup: AddAthleteLikedFoodGroupPayload;
  readonly addNixBrandedFood: AddNixBrandedFoodPayload;
  readonly addNixBrandedFoodByUpc: AddNixBrandedFoodByUpcPayload;
  readonly addNixCommonFood: AddNixCommonFoodPayload;
  readonly addPosition: AddPositionPayload;
  readonly addRecipeImage: AddRecipeImagePayload;
  readonly addRestaurantLocation: AddRestaurantLocationPayload;
  readonly addRestaurantLocationContact: AddRestaurantLocationContactPayload;
  readonly addRestaurantMenuLink: AddRestaurantMenuLinkPayload;
  readonly addRestaurantMenuLinkOrder: AddRestaurantMenuLinkOrderPayload;
  readonly addRestaurantMenuLinkOrderForAthlete: AddRestaurantMenuLinkOrderForAthletePayload;
  readonly addRestaurantMenuLinkPlateOrder: AddRestaurantMenuLinkPlateOrderPayload;
  readonly addRestaurantMenuLinkPlateOrderForAthlete: AddRestaurantMenuLinkPlateOrderForAthletePayload;
  readonly addRestaurantMenuLog: AddRestaurantMenuLogPayload;
  readonly addRestaurantMenuLogForAthlete: AddRestaurantMenuLogForAthletePayload;
  readonly addRestaurantMenuLogItem: AddRestaurantMenuLogItemPayload;
  readonly addRestaurantMenuLogItemForAthlete: AddRestaurantMenuLogItemPayload;
  readonly addRestaurantMenuLogItems: AddRestaurantMenuLogItemsPayload;
  readonly addRestaurantMenuLogItemsForAthlete: AddRestaurantMenuLogItemsPayload;
  readonly addSport: AddSportPayload;
  readonly addTeamLabel: AddTeamLabelPayload;
  readonly addTimelineMealCommentForAthlete: AddTimelineMealCommentPayload;
  readonly addTimelineMealCommentForMe: AddTimelineMealCommentPayload;
  readonly addTimelineMealImageForAthlete: AddTimelineMealImagePayload;
  readonly addTimelineMealImageForMe: AddTimelineMealImagePayload;
  readonly addTimelineMealServingAmountForAthlete: AddTimelineMealServingAmountPayload;
  readonly addTimelineMealServingAmountForMe: AddTimelineMealServingAmountPayload;
  readonly archiveAthlete: ArchiveAthletePayload;
  readonly archiveGoalConfiguration: ArchiveGoalConfigurationPayload;
  /** @deprecated Field no longer supported */
  readonly assignMealPlanTemplateToAthlete: AssignMealPlanTemplateToAthletePayload;
  readonly assignMetricMealPlanTemplateToAthlete: AssignMealPlanTemplateToAthletePayload;
  readonly bulkCopyMealPlanNew: BulkCopyMealPlanPayload;
  readonly bulkDeleteMealMenus: BulkDeleteMealMenusPayload;
  readonly bulkEditMealMenus: BulkEditMealMenusPayload;
  readonly bulkImportCreateAndDeprecateGenericFoods: BulkImportCreateAndDeprecateGenericFoodsPayload;
  readonly bulkImportCreateGenericFoods: BulkImportCreateGenericFoodsPayload;
  readonly bulkImportCreateRestaurantBrandedFood: BulkImportCreateRestautantBrandedFoodsPayload;
  readonly bulkImportEditRestaurantBrandedFood: BulkImportEditRestautantBrandedFoodsPayload;
  readonly calculateExchangeTotals: Maybe<Scalars['ID']>;
  readonly changeExchangeTarget: Maybe<Scalars['ID']>;
  readonly changeExchangeTargetTotal: Maybe<Scalars['ID']>;
  readonly changeMacroTarget: Maybe<Scalars['ID']>;
  readonly checkGroceryListCustomItem: CheckGroceryListCustomItemPayload;
  readonly checkGroceryListFoodItem: CheckGroceryListFoodItemPayload;
  readonly copyExchangeSet: CopyExchangeSetPayload;
  readonly copyMacroMealPlanTemplate: CopyMacroMealPlanTemplatePayload;
  readonly copyMealMenus: CopyMealMenusPayload;
  readonly copyMealPlanNew: CopyMealPlanPayload;
  readonly copyPrepPlan: CopyPrepPlanPayload;
  readonly copySharedExchangeSet: CopyExchangeSetPayload;
  readonly copySharedMacroMealPlanTemplate: CopyMacroMealPlanTemplatePayload;
  /** @deprecated Field no longer supported */
  readonly createAthlete: CreateAthletePayload;
  readonly createAthleteTransfer: CreateAthleteTransferPayload;
  /** @deprecated Field no longer supported */
  readonly createAthletes: CreateAthletesPayload;
  readonly createBrand: CreateBrandPayload;
  readonly createBrandedFood: CreateBrandedFoodPayload;
  readonly createBulkAssignMealPlanTemplateJob: BulkAssignMealPlanTemplateJob;
  readonly createClientCredential: CreateClientCredentialPayload;
  readonly createCuisine: CreateCuisinePayload;
  readonly createCustomTag: NamedTag;
  readonly createDiet: CreateDietPayload;
  readonly createDiningStationTemplate: CreateDiningStationTemplatePayload;
  readonly createExchangeSet: CreateExchangeSetPayload;
  readonly createFoodServings: CreateFoodServingsPayload;
  readonly createGenericFood: CreateGenericFoodPayload;
  readonly createGoal: CreateGoalPayload;
  readonly createGoalConfigurationWithDaysOfWeek: CreateGoalConfigurationPayload;
  readonly createGoalConfigurationWithIndividualDates: CreateGoalConfigurationPayload;
  readonly createGroceryListCustomItem: CreateGroceryListCustomItemPayload;
  readonly createMacroMealPlanTemplate: CreateMacroMealPlanTemplatePayload;
  readonly createMealMenus: CreateMealMenusPayload;
  /** @deprecated Field no longer supported */
  readonly createMealPlanFromFoodLogsNew: CreateMealPlanFromFoodLogsPayload;
  /** @deprecated Field no longer supported */
  readonly createMealPlanFromMealPlanTemplate: CreateMealPlanFromMealPlanTemplatePayload;
  /** @deprecated Field no longer supported */
  readonly createMealPlanNew: CreateMealPlanPayload;
  readonly createMemberships: CreateOrgMembershipsPayload;
  readonly createMetricAnthropometryEntries: CreateAnthropometryEntriesPayload;
  readonly createMetricAnthropometryEntry: CreateAnthropometryEntryPayload;
  readonly createMetricAthlete: CreateAthletePayload;
  readonly createMetricAthletes: CreateAthletesPayload;
  readonly createMetricMealPlan: CreateMealPlanPayload;
  readonly createMetricMealPlanFromFoodLogs: CreateMealPlanFromFoodLogsPayload;
  readonly createMetricMealPlanFromMealPlanTemplate: CreateMealPlanFromMealPlanTemplatePayload;
  readonly createNote: CreateNotePayload;
  readonly createNoteType: CreateNoteTypePayload;
  readonly createOrg: CreateOrgPayload;
  readonly createOrgFoodGroup: CreateFoodGroupPayload;
  readonly createOrgFoodServings: CreateFoodServingsPayload;
  readonly createOrgFromTeamworksOrg: CreateOrgFromTeamworksOrgPayload;
  readonly createOrgGenericFood: CreateGenericFoodPayload;
  readonly createOrgGroup: CreateOrgGroupPayload;
  readonly createOrgMembershipForAthlete: CreateOrgMembershipForAthletePayload;
  readonly createPaymentMethod: CreatePaymentMethodPayload;
  readonly createPlannedMenu: CreatePlannedMenuPayload;
  readonly createPrepPlan: CreatePrepPlanPayload;
  readonly createPrepPlanMeal: CreatePrepPlanMealPayload;
  readonly createRecipeFull: CreateRecipeFullPayload;
  readonly createRecipeSimple: CreateRecipeSimplePayload;
  readonly createRestaurant: CreateRestaurantPayload;
  readonly createRestaurantBrandedFood: CreateRestaurantBrandedFoodPayload;
  readonly createRestaurantMenu: CreateRestaurantMenuPayload;
  readonly createRestaurantType: CreateRestaurantTypePayload;
  readonly createReusableMenuItem: CreateReusableMenuItemPayload;
  readonly createSchedule: CreateSchedulePayload;
  readonly createSharedExchangeSet: CreateExchangeSetPayload;
  readonly createSharedFoodGroup: CreateFoodGroupPayload;
  readonly createSharedMacroMealPlanTemplate: CreateMacroMealPlanTemplatePayload;
  readonly createSmartTag: NamedTag;
  readonly createTeam: CreateTeamPayload;
  readonly createTerritory: Territory;
  readonly createUserBrandedFood: CreateBrandedFoodPayload;
  readonly createWeightUnitServingForFood: Maybe<CreateWeightUnitServingForFoodPayload>;
  readonly deleteAnthropometryEntry: DeleteAnthropometryEntryPayload;
  readonly deleteAthlete: DeleteAthletePayload;
  readonly deleteCuisine: DeleteCuisinePayload;
  readonly deleteDiet: DeleteDietPayload;
  readonly deleteDiningStationTemplate: DeleteDiningStationTemplatePayload;
  readonly deleteExchangeSet: DeleteExchangeSetPayload;
  readonly deleteGoalConfiguration: DeleteGoalConfigurationPayload;
  readonly deleteGroceryListCustomItem: DeleteGroceryListCustomItemPayload;
  readonly deleteGroceryListFoodItem: EditGroceryListFoodItemPayload;
  readonly deleteInProgressPrepPlan: DeleteInProgressPrepPlanPayload;
  readonly deleteMacroMealPlanTemplate: DeleteMacroMealPlanTemplatePayload;
  readonly deleteMacroMealPlanTemplatePendingState: DeleteMacroMealPlanTemplatePendingStatePayload;
  readonly deleteMealMenu: DeleteMealMenuPayload;
  readonly deleteMealPlan: DeleteMealPlanPayload;
  readonly deleteMealPlans: DeleteMealPlansPayload;
  readonly deleteNote: DeleteNotePayload;
  readonly deleteNoteType: DeleteNoteTypePayload;
  readonly deleteOrgFood: DeleteFoodPayload;
  readonly deleteOrgGroupMacroMealPlanTemplate: DeleteMacroMealPlanTemplatePayload;
  readonly deleteOrgGroupMacroMealPlanTemplatePendingState: DeleteMacroMealPlanTemplatePendingStatePayload;
  readonly deletePaymentMethod: DeletePaymentMethodPayload;
  readonly deletePlannedMenu: DeletePlannedMenuPayload;
  readonly deleteRecipe: DeleteRecipePayload;
  readonly deleteRestaurantType: DeleteRestaurantTypePayload;
  readonly deleteReusableMenuItem: DeleteReusableMenuItemPayload;
  readonly deleteSharedExchangeSet: DeleteExchangeSetPayload;
  readonly deleteSharedMacroMealPlanTemplate: DeleteMacroMealPlanTemplatePayload;
  readonly deleteSharedMacroMealPlanTemplatePendingState: DeleteMacroMealPlanTemplatePendingStatePayload;
  readonly deleteTag: DeleteTagPayload;
  readonly deleteTeam: DeleteTeamPayload;
  readonly deleteUserBrandedFood: DeleteUserBrandedFoodPayload;
  readonly deprecateRestaurantBrandedFood: DeprecateRestaurantBrandedFoodPayload;
  readonly deprecateSharedFood: DeprecateFoodPayload;
  readonly deselectNegativeFoods: Maybe<Scalars['ID']>;
  readonly distributeExchangeTargets: Maybe<Scalars['ID']>;
  readonly distributeMacroTargets: Maybe<Scalars['ID']>;
  readonly duplicatePlannedMenu: CreatePlannedMenuPayload;
  readonly editAgreementForm: EditAgreementFormPayload;
  /** @deprecated Field no longer supported */
  readonly editAnthropometryEntry: EditAnthropometryEntryPayload;
  readonly editAnthropometryEntryComment: EditAnthropometryEntryCommentPayload;
  readonly editAthlete: EditAthletePayload;
  readonly editAthleteDisplaySettings: EditAthleteDisplaySettingsPayload;
  readonly editAthleteFoodLogNotificationSettings: EditAthleteFoodLogNotificationSettingsPayload;
  /** @deprecated Field no longer supported */
  readonly editAthletes: EditAthletesPayload;
  readonly editBrand: EditBrandPayload;
  readonly editBrandedFood: EditBrandedFoodPayload;
  readonly editBulkOrder: EditBulkOrderPayload;
  readonly editClientCredential: EditClientCredentialPayload;
  readonly editCuisine: EditCuisinePayload;
  readonly editCustomTag: NamedTag;
  readonly editCustomizedPrepPlanMeal: EditCustomizedPrepPlanMealPayload;
  readonly editDeliveryLocation: EditDeliveryLocationPayload;
  readonly editDiet: EditDietPayload;
  readonly editDiningStationTemplate: EditDiningStationTemplatePayload;
  readonly editExchangeMealTemplate: EditExchangeMealTemplatePayload;
  readonly editExchangeMealTemplates: EditExchangeMealTemplatesPayload;
  readonly editExchangeSet: EditExchangeSetPayload;
  readonly editFoodLogActivityRpe: EditFoodLogActivityRpePayload;
  readonly editFoodLogActivityTemplate: EditFoodLogActivityTemplatePayload;
  readonly editFoodLogActivityTimeWithTimezone: EditFoodLogActivityTimeWithTimezonePayload;
  readonly editFoodLogMealServingAmounts: EditFoodLogMealServingAmountsPayload;
  readonly editFoodLogMealTemplate: EditFoodLogMealTemplatePayload;
  readonly editFoodLogMealTimeWithTimezone: EditFoodLogMealTimeWithTimezonePayload;
  readonly editFoodServing: EditFoodServingPayload;
  readonly editGenericFood: EditGenericFoodPayload;
  readonly editGoalConfigurationWithDaysOfWeek: EditGoalConfigurationPayload;
  readonly editGoalConfigurationWithIndividualDates: EditGoalConfigurationPayload;
  readonly editGroceryList: Maybe<EditGroceryListPayload>;
  readonly editGroceryListCustomItem: EditGroceryListCustomItemPayload;
  readonly editGroceryListFoodItem: EditGroceryListFoodItemPayload;
  readonly editMacroMealPlanTemplate: EditMacroMealPlanTemplatePayload;
  readonly editMacroMealTemplate: EditMacroMealTemplatePayload;
  readonly editMacroMealTemplates: EditMacroMealTemplatesPayload;
  readonly editMacroProtocol: EditMacroProtocolPayload;
  readonly editMacroProtocolWithNewGoal: EditMacroProtocolWithNewGoalPayload;
  readonly editMacroProtocolWithOldGoal: EditMacroProtocolWithOldGoalPayload;
  readonly editMealMenu: EditMealMenuPayload;
  readonly editMealMenuLogItem: EditMealMenuLogItemPayload;
  readonly editMealMenuWithIdentity: EditMealMenuWithIdentityPayload;
  readonly editMealOption: EditMealOptionPayload;
  readonly editMealOptionLocal: Maybe<Scalars['ID']>;
  readonly editMealOptionPending: EditMealOptionPendingPayload;
  readonly editMealOptionPendingLocal: Maybe<Scalars['ID']>;
  readonly editMealPlanCalendar: EditMealPlanCalendarPayload;
  readonly editMealPlanDate: EditMealPlanDatePayload;
  readonly editMealPlanDateForAthlete: EditMealPlanDateForAthletePayload;
  readonly editMealPlanExchangeTargets: EditMealPlanExchangeTargetsPayload;
  readonly editMealPlanIsShared: EditMealPlanIsSharedPayload;
  readonly editMealPlanNew: EditMealPlanPayload;
  readonly editMealPlansIsShared: EditMealPlansIsSharedPayload;
  readonly editMemberships: EditOrgMembershipsPayload;
  readonly editMenuItemImage: EditMenuItemImagePayload;
  readonly editMenuOrder: EditMenuOrderPayload;
  readonly editMenuOrderMealTemplate: EditMenuOrderMealTemplatePayload;
  readonly editMetricAnthropometryEntries: EditAnthropometryEntriesPayload;
  readonly editMetricAnthropometryEntry: EditAnthropometryEntryPayload;
  readonly editMetricAnthropometryEntryWeighInForDate: EditAnthropometryEntryWeighInForDatePayload;
  readonly editMetricAnthropometryEntryWeighInForDateForAthlete: EditAnthropometryEntryWeighInForDateForAthletePayload;
  readonly editMetricAthletes: EditAthletesPayload;
  readonly editMetricMacroProtocol: EditMacroProtocolPayload;
  readonly editNote: EditNotePayload;
  readonly editNoteType: EditNoteTypePayload;
  readonly editOrg: EditOrgPayload;
  readonly editOrgAutoShareNewNotemealRecipes: EditOrgAutoShareNewNotemealRecipesPayload;
  readonly editOrgFeatureFlags: EditOrgFeatureFlagsPayload;
  readonly editOrgFoodGroup: EditFoodGroupPayload;
  readonly editOrgFoodServing: EditFoodServingPayload;
  readonly editOrgGenericFood: EditGenericFoodPayload;
  readonly editOrgGroup: EditOrgGroupPayload;
  readonly editOrgGroupMacroMealPlanTemplate: EditMacroMealPlanTemplatePayload;
  readonly editOrgTeamworksProfiles: EditOrgTeamworksProfilesPayload;
  readonly editPaymentMethod: EditPaymentMethodPayload;
  readonly editPlannedMenu: EditPlannedMenuPayload;
  readonly editPrepPlan: EditPrepPlanPayload;
  readonly editPrepPlanMeal: EditPrepPlanMealPayload;
  readonly editRecipeFull: EditRecipeFullPayload;
  readonly editRecipeIsShared: EditRecipeIsSharedPayload;
  readonly editRecipePartial: EditRecipePartialPayload;
  readonly editRecipeSimple: EditRecipeSimplePayload;
  readonly editRestaurant: EditRestaurantPayload;
  readonly editRestaurantBrandedFood: EditRestaurantBrandedFoodPayload;
  readonly editRestaurantLocation: EditRestaurantLocationPayload;
  readonly editRestaurantLocationContact: EditRestaurantLocationContactPayload;
  readonly editRestaurantMenu: EditRestaurantMenuPayload;
  readonly editRestaurantMenuLink: EditRestaurantMenuLinkPayload;
  readonly editRestaurantMenuLinkOrder: EditRestaurantMenuLinkOrderPayload;
  readonly editRestaurantMenuLinkOrderMealTemplate: EditRestaurantMenuLinkOrderMealTemplatePayload;
  readonly editRestaurantMenuLinkPlateOrderMealTemplate: EditRestaurantMenuLinkPlateOrderMealTemplatePayload;
  readonly editRestaurantMenuLog: EditRestaurantMenuLogPayload;
  readonly editRestaurantMenuLogItem: EditRestaurantMenuLogItemPayload;
  readonly editRestaurantMenuLogMealTemplate: EditRestaurantMenuLogMealTemplatePayload;
  readonly editRestaurantType: EditRestaurantTypePayload;
  readonly editReusableMenuItem: EditReusableMenuItemPayload;
  readonly editReusableMenuItemPartial: EditReusableMenuItemPartialPayload;
  readonly editSchedule: EditSchedulePayload;
  readonly editSharedExchangeSet: EditExchangeSetPayload;
  readonly editSharedFoodGroup: EditFoodGroupPayload;
  readonly editSharedMacroMealPlanTemplate: EditMacroMealPlanTemplatePayload;
  readonly editSmartTag: NamedTag;
  readonly editSyncedAthlete: EditAthletePayload;
  /** @deprecated Field no longer supported */
  readonly editSyncedAthletes: EditAthletesPayload;
  readonly editSyncedMetricAthletes: EditAthletesPayload;
  readonly editTeam: EditTeamPayload;
  readonly editTeamDisplaySettings: EditTeamDisplaySettingsPayload;
  readonly editTeamFoodLogNotificationSettings: EditTeamFoodLogNotificationSettingsPayload;
  readonly editTeamForStaff: EditTeamPayload;
  readonly editTeamLabel: EditTeamLabelPayload;
  readonly editTerritory: Territory;
  readonly editTimelineMealDetails: EditTimelineMealDetailsPayload;
  readonly editTimelineMealMealTemplate: EditTimelineMealMealTemplatePayload;
  readonly editTimelineMealServingAmount: EditTimelineMealServingAmountPayload;
  readonly editUserBrandedFood: EditUserBrandedFoodPayload;
  readonly editUserTeamworksIdToOverwrite: EditUserTeamworksIdToOverwritePayload;
  readonly finalizePrepPlan: FinalizePrepPlanPayload;
  readonly forceLockPlannedMenu: ForceLockPlannedMenuPayload;
  readonly getPossibleGPTRecipeByName: GptRecipePayload;
  readonly getPossibleGPTRecipeFromImage: GptRecipePayload;
  /** @deprecated mobile-use only; remove once force-update available in react-native app */
  readonly getPossibleGPTRecipeFromUrl: GptRecipePayload;
  readonly importBonAppetitMenus: ImportBonAppetitMenusPayload;
  readonly importCbordMenus: ImportCbordMenusPayload;
  readonly importCompassMenus: ImportCompassMenusPayload;
  readonly importCompassRecipe: ImportCompassRecipeInputPayload;
  readonly importPlannedMenu: ImportPlannedMenuPayload;
  readonly importSodexoMenus: ImportSodexoMenusPayload;
  readonly linkTeamworksOrg: LinkTeamworksOrgPayload;
  readonly lockPlannedMenu: LockPlannedMenuPayload;
  readonly markAllNotificationsAsViewed: MarkAllNotificationsAsViewedPayload;
  readonly markNotificationsAsViewed: MarkNotificationsAsViewedPayload;
  readonly markNotificationsAsViewedBefore: MarkNotificationsAsViewedBeforePayload;
  readonly moveMacroMealPlanTemplateToOrgGroup: MoveMacroMealPlanTemplateToOrgGroupPayload;
  readonly moveOrgFoodToOrgGroup: MoveOrgFoodToOrgGroupPayload;
  readonly moveOrgGroupFoodToMyOrg: MoveOrgGroupFoodToMyOrgPayload;
  readonly moveOrgGroupMacroMealPlanTemplateToOrg: MoveOrgGroupMacroMealPlanTemplateToOrgPayload;
  readonly moveOrgRecipeToOrgGroup: MoveOrgRecipeToOrgGroupPayload;
  readonly overrideMealMenuIdentity: OverrideMealMenuIdentityPayload;
  readonly publishMacroMealPlanTemplate: PublishMacroMealPlanTemplatePayload;
  readonly publishOrgGroupMacroMealPlanTemplate: PublishMacroMealPlanTemplatePayload;
  readonly publishSharedMacroMealPlanTemplate: PublishMacroMealPlanTemplatePayload;
  readonly recieveAthleteTransfer: RecieveAthleteTransferPayload;
  readonly reclaimAthleteTransfer: ReclaimAthleteTransferPayload;
  readonly recordMenuOrderSuggestionDecision: Scalars['Boolean'];
  readonly recordMenuOrderSuggestionsView: Scalars['Boolean'];
  readonly refreshClientCredentialSecret: RefreshClientCredentialSecretPayload;
  readonly refreshTeamworksSyncStatus: RefreshTeamworksSyncStatusPayload;
  readonly removeAthleteDislikedFood: RemoveAthleteDislikedFoodPayload;
  readonly removeAthleteDislikedFoodGroup: RemoveAthleteDislikedFoodGroupPayload;
  readonly removeAthleteLikedFood: RemoveAthleteLikedFoodPayload;
  readonly removeAthleteLikedFoodGroup: RemoveAthleteLikedFoodGroupPayload;
  readonly removeBulkOrder: RemoveBulkOrderPayload;
  readonly removeFoodLogActivity: RemoveFoodLogActivityPayload;
  readonly removeFoodLogActivityTemplate: RemoveFoodLogActivityTemplatePayload;
  readonly removeFoodLogMeal: RemoveFoodLogMealPayload;
  readonly removeFoodLogMealImage: RemoveFoodLogMealImagePayload;
  readonly removeFoodLogMealTemplate: RemoveFoodLogMealTemplatePayload;
  readonly removeMealMenuLogItem: RemoveMealMenuLogItemPayload;
  readonly removeMealOption: RemoveMealOptionPayload;
  readonly removeMealOptionLocal: Maybe<Scalars['ID']>;
  readonly removeMealOptionPending: RemoveMealOptionPendingPayload;
  readonly removeMealOptionPendingLocal: Maybe<Scalars['ID']>;
  readonly removeMealPlanDate: RemoveMealPlanDatePayload;
  readonly removeMealPlanDateForAthlete: RemoveMealPlanDateForAthletePayload;
  readonly removeMenuOrder: RemoveMenuOrderPayload;
  readonly removeMenuOrderImage: RemoveMenuOrderImagePayload;
  readonly removeMenuOrderMealTemplate: RemoveMenuOrderMealTemplatePayload;
  readonly removeMyDislikedFood: RemoveAthleteDislikedFoodPayload;
  readonly removeMyDislikedFoodGroup: RemoveAthleteDislikedFoodGroupPayload;
  readonly removeMyLikedFood: RemoveAthleteLikedFoodPayload;
  readonly removeMyLikedFoodGroup: RemoveAthleteLikedFoodGroupPayload;
  readonly removePrepPlanMeal: RemovePrepPlanMealPayload;
  readonly removeRecipeImage: RemoveRecipeImagePayload;
  readonly removeRestaurantMenuLink: RemoveRestaurantMenuLinkPayload;
  readonly removeRestaurantMenuLinkOrder: RemoveRestaurantMenuLinkOrderPayload;
  readonly removeRestaurantMenuLinkOrderMealTemplate: RemoveRestaurantMenuLinkOrderMealTemplatePayload;
  readonly removeRestaurantMenuLinkPlateOrder: RemoveRestaurantMenuLinkPlateOrderPayload;
  readonly removeRestaurantMenuLinkPlateOrderMealTemplate: RemoveRestaurantMenuLinkPlateOrderMealTemplatePayload;
  readonly removeRestaurantMenuLog: RemoveRestaurantMenuLogPayload;
  readonly removeRestaurantMenuLogItem: RemoveRestaurantMenuLogItemPayload;
  readonly removeRestaurantMenuLogMealTemplate: RemoveRestaurantMenuLogMealTemplatePayload;
  readonly removeTimelineMealImage: RemoveTimelineMealImagePayload;
  readonly removeTimelineMealServingAmount: RemoveTimelineMealServingAmountPayload;
  readonly runMaintainTeamworksFullySyncedJob: RefreshTeamworksSyncStatusPayload;
  readonly savePlannedMeals: SavePlannedMealsPayload;
  readonly selectMealOption: Maybe<Scalars['ID']>;
  readonly sendMealMenuNotification: Maybe<Scalars['Boolean']>;
  readonly setGoalTrackerGoal: SetGoalTrackerGoalPayload;
  readonly setMealMenuCheckInsForAthlete: SetMealMenuCheckInsPayload;
  readonly setMealMenuLogItemPercentConsumed: SetMealMenuLogItemPercentConsumedPayload;
  readonly setMealOptionsForMealTemplates: SetMealOptionsForMealTemplatesPayload;
  readonly setMenuOrderItemPercentConsumed: SetMenuOrderItemPercentConsumedPayload;
  readonly setPrepPlanGuidedBuilder: SetPrepPlanGuidedBuilderPayload;
  readonly setPrepPlanMealAssignmentSlots: SetPrepPlanMealAssignmentSlotsPayload;
  readonly setRestaurantMenuLinkOrderItemPercentConsumed: SetRestaurantMenuLinkOrderItemPercentConsumedPayload;
  readonly setRestaurantMenuLinkPlateOrderItemPercentConsumed: SetRestaurantMenuLinkPlateOrderItemPercentConsumedPayload;
  readonly setRestaurantMenuLogItemPercentConsumed: SetRestaurantMenuLogItemPercentConsumedPayload;
  readonly setTimelineMealServingAmountsForAthlete: SetTimelineMealServingAmountsPayload;
  readonly setTimelineMealServingAmountsForMe: SetTimelineMealServingAmountsPayload;
  readonly shareMealOptionPending: ShareMealOptionPendingPayload;
  readonly shareMealOptionPendingLocal: Maybe<Scalars['ID']>;
  readonly syncStreamChatDevices: Maybe<Scalars['Boolean']>;
  readonly textMealPlanToAthlete: TextMealPlanToAthletePayload;
  readonly textMealPlansToAthletes: TextMealPlansToAthletesPayload;
  readonly unarchiveAthlete: UnarchiveAthletePayload;
  readonly unlockPlannedMenu: UnlockPlannedMenuPayload;
  readonly updateApnCountForUser: UpdateApnCountForUserPayload;
  readonly updateBulkOrderStatus: UpdateBulkOrderStatusPayload;
  readonly updateMacroMealPlanTemplateAssignScope: UpdateMealPlanTemplateAssignScopePayload;
  readonly updateMacroMealPlanTemplateSharingScope: UpdateMacroMealPlanTemplateSharingScopePayload;
  readonly updateMealMenuPlateItemOrderStatus: UpdateMealMenuPlateItemOrderStatusPayload;
  readonly updateMealMenuPlateOrderStatus: UpdateMealMenuPlateOrderStatusPayload;
  readonly updateMealMenuPlateOrderStatuses: ReadonlyArray<UpdateMealMenuPlateOrderStatusPayload>;
  readonly updateMenuOrderItemStatuses: UpdateMenuOrderItemStatusesPayload;
  readonly updateMultipleMealMenuPlateItemOrderStatuses: UpdateMultipleMealMenuPlateItemOrderStatusesPayload;
  readonly updateMultipleMenuOrderItemStatuses: UpdateMultipleMenuOrderItemStatusesPayload;
  readonly updateSharedMacroMealPlanTemplateSharingScope: UpdateMacroMealPlanTemplateSharingScopePayload;
};


export type MutationAddAgreementFormArgs = {
  input: AddAgreementFormInput;
};


export type MutationAddAgreementFormSubmissionArgs = {
  input: AddAgreementFormSubmissionInput;
};


export type MutationAddAndLinkTeamworksTeamsArgs = {
  input: AddAndLinkTeamworksTeamsInput;
};


export type MutationAddAthleteDislikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationAddAthleteDislikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationAddAthleteEmailArgs = {
  input: AddAthleteEmailInput;
};


export type MutationAddAthleteLikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationAddAthleteLikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationAddAthletePhoneNumberArgs = {
  input: AddAthletePhoneNumberInput;
};


export type MutationAddBulkOrderArgs = {
  input: AddBulkOrderInput;
};


export type MutationAddDeliveryLocationArgs = {
  input: AddDeliveryLocationInput;
};


export type MutationAddDeviceArgs = {
  input: AddDeviceInput;
};


export type MutationAddEventArgs = {
  input: AddEventInput;
};


export type MutationAddFoodItemToGroceryListArgs = {
  input: AddFoodItemToGroceryListInput;
};


export type MutationAddFoodLogActivityWithTemplateArgs = {
  input: AddFoodLogActivityWithTemplateInput;
};


export type MutationAddFoodLogActivityWithTemplateForAthleteArgs = {
  input: AddFoodLogActivityWithTemplateForAthleteInput;
};


export type MutationAddFoodLogActivityWithoutTemplateArgs = {
  input: AddFoodLogActivityWithoutTemplateInput;
};


export type MutationAddFoodLogActivityWithoutTemplateForAthleteArgs = {
  input: AddFoodLogActivityWithoutTemplateForAthleteInput;
};


export type MutationAddFoodLogMealCommentArgs = {
  input: AddFoodLogMealCommentInput;
};


export type MutationAddFoodLogMealImageArgs = {
  input: AddFoodLogMealImageInput;
};


export type MutationAddFoodLogMealWithTemplateArgs = {
  input: AddFoodLogMealWithTemplateInput;
};


export type MutationAddFoodLogMealWithTemplateForAthleteArgs = {
  input: AddFoodLogMealWithTemplateForAthleteInput;
};


export type MutationAddFoodLogMealWithoutTemplateArgs = {
  input: AddFoodLogMealWithoutTemplateInput;
};


export type MutationAddFoodLogMealWithoutTemplateForAthleteArgs = {
  input: AddFoodLogMealWithoutTemplateForAthleteInput;
};


export type MutationAddMealMenuLogItemArgs = {
  input: AddMealMenuLogItemInput;
};


export type MutationAddMealMenuLogItemForAthleteArgs = {
  input: AddMealMenuLogItemForAthleteInput;
};


export type MutationAddMealMenuLogItemsArgs = {
  input: AddMealMenuLogItemsInput;
};


export type MutationAddMealMenuLogItemsForAthleteArgs = {
  input: AddMealMenuLogItemsForAthleteInput;
};


export type MutationAddMealOptionArgs = {
  input: AddMealOptionInput;
};


export type MutationAddMealOptionLocalArgs = {
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
};


export type MutationAddMenuItemHistoryMissingIngredientsArgs = {
  input: AddMenuItemHistoryMissingIngredientsInput;
};


export type MutationAddMenuOrderCommentArgs = {
  input: AddMenuOrderCommentInput;
};


export type MutationAddMenuOrderForAthleteArgs = {
  input: AddMenuOrderForAthleteInput;
};


export type MutationAddMenuOrderImageArgs = {
  input: AddMenuOrderImageInput;
};


export type MutationAddMenuOrderItemRatingsArgs = {
  input: AddMenuOrderItemRatingsInput;
};


export type MutationAddMenuOrderWithTemplateArgs = {
  input: AddMenuOrderWithTemplateInput;
};


export type MutationAddMenuOrderWithoutTemplateArgs = {
  input: AddMenuOrderWithoutTemplateInput;
};


export type MutationAddMyDislikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationAddMyDislikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationAddMyLikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationAddMyLikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationAddNixBrandedFoodArgs = {
  input: AddNixBrandedFoodInput;
};


export type MutationAddNixBrandedFoodByUpcArgs = {
  input: AddNixBrandedFoodByUpcInput;
};


export type MutationAddNixCommonFoodArgs = {
  input: AddNixCommonFoodInput;
};


export type MutationAddPositionArgs = {
  input: AddPositionInput;
};


export type MutationAddRecipeImageArgs = {
  input: AddRecipeImageInput;
};


export type MutationAddRestaurantLocationArgs = {
  input: AddRestaurantLocationInput;
};


export type MutationAddRestaurantLocationContactArgs = {
  input: AddRestaurantLocationContactInput;
};


export type MutationAddRestaurantMenuLinkArgs = {
  input: AddRestaurantMenuLinkInput;
};


export type MutationAddRestaurantMenuLinkOrderArgs = {
  input: AddRestaurantMenuLinkOrderInput;
};


export type MutationAddRestaurantMenuLinkOrderForAthleteArgs = {
  input: AddRestaurantMenuLinkOrderForAthleteInput;
};


export type MutationAddRestaurantMenuLinkPlateOrderArgs = {
  input: AddRestaurantMenuLinkPlateOrderInput;
};


export type MutationAddRestaurantMenuLinkPlateOrderForAthleteArgs = {
  input: AddRestaurantMenuLinkPlateOrderForAthleteInput;
};


export type MutationAddRestaurantMenuLogArgs = {
  input: AddRestaurantMenuLogInput;
};


export type MutationAddRestaurantMenuLogForAthleteArgs = {
  input: AddRestaurantMenuLogForAthleteInput;
};


export type MutationAddRestaurantMenuLogItemArgs = {
  input: AddRestaurantMenuLogItemInput;
};


export type MutationAddRestaurantMenuLogItemForAthleteArgs = {
  input: AddRestaurantMenuLogItemForAthleteInput;
};


export type MutationAddRestaurantMenuLogItemsArgs = {
  input: AddRestaurantMenuLogItemsInput;
};


export type MutationAddRestaurantMenuLogItemsForAthleteArgs = {
  input: AddRestaurantMenuLogItemsForAthleteInput;
};


export type MutationAddSportArgs = {
  input: AddSportInput;
};


export type MutationAddTeamLabelArgs = {
  input: AddTeamLabelInput;
};


export type MutationAddTimelineMealCommentForAthleteArgs = {
  input: AddTimelineMealCommentForAthleteInput;
};


export type MutationAddTimelineMealCommentForMeArgs = {
  input: AddTimelineMealCommentForMeInput;
};


export type MutationAddTimelineMealImageForAthleteArgs = {
  input: AddTimelineMealImageForAthleteInput;
};


export type MutationAddTimelineMealImageForMeArgs = {
  input: AddTimelineMealImageInput;
};


export type MutationAddTimelineMealServingAmountForAthleteArgs = {
  input: AddTimelineMealServingAmountForAthleteInput;
};


export type MutationAddTimelineMealServingAmountForMeArgs = {
  input: AddTimelineMealServingAmountInput;
};


export type MutationArchiveAthleteArgs = {
  input: ArchiveAthleteInput;
};


export type MutationArchiveGoalConfigurationArgs = {
  input: ArchiveGoalConfigurationInput;
};


export type MutationAssignMealPlanTemplateToAthleteArgs = {
  input: AssignMealPlanTemplateToAthleteInput;
};


export type MutationAssignMetricMealPlanTemplateToAthleteArgs = {
  input: AssignMetricMealPlanTemplateToAthleteInput;
};


export type MutationBulkCopyMealPlanNewArgs = {
  input: BulkCopyMealPlanNewInput;
};


export type MutationBulkDeleteMealMenusArgs = {
  input: BulkDeleteMealMenusInput;
};


export type MutationBulkEditMealMenusArgs = {
  input: BulkEditMealMenusInput;
};


export type MutationBulkImportCreateAndDeprecateGenericFoodsArgs = {
  input: BulkImportCreateAndDeprecateGenericFoodsInput;
};


export type MutationBulkImportCreateGenericFoodsArgs = {
  input: BulkImportCreateGenericFoodsInput;
};


export type MutationBulkImportCreateRestaurantBrandedFoodArgs = {
  input: BulkImportCreateRestautantBrandedFoodsInput;
};


export type MutationBulkImportEditRestaurantBrandedFoodArgs = {
  input: BulkImportEditRestautantBrandedFoodsInput;
};


export type MutationCalculateExchangeTotalsArgs = {
  mealPlanId: Scalars['ID'];
};


export type MutationChangeExchangeTargetArgs = {
  mealTemplateId: Scalars['ID'];
  exchangeId: Scalars['ID'];
  target: Scalars['String'];
  pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};


export type MutationChangeExchangeTargetTotalArgs = {
  mealPlanId: Scalars['ID'];
  exchangeId: Scalars['ID'];
  target: Scalars['String'];
};


export type MutationChangeMacroTargetArgs = {
  mealTemplateId: Scalars['ID'];
  macro: MacroType;
  target: Scalars['String'];
};


export type MutationCheckGroceryListCustomItemArgs = {
  input: CheckGroceryListCustomItemInput;
};


export type MutationCheckGroceryListFoodItemArgs = {
  input: CheckGroceryListFoodItemInput;
};


export type MutationCopyExchangeSetArgs = {
  input: CopyExchangeSetInput;
};


export type MutationCopyMacroMealPlanTemplateArgs = {
  input: CopyMacroMealPlanTemplateInput;
};


export type MutationCopyMealMenusArgs = {
  input: CopyMealMenusInput;
};


export type MutationCopyMealPlanNewArgs = {
  input: CopyMealPlanNewInput;
};


export type MutationCopyPrepPlanArgs = {
  input: CopyPrepPlanInput;
};


export type MutationCopySharedExchangeSetArgs = {
  input: CopyExchangeSetInput;
};


export type MutationCopySharedMacroMealPlanTemplateArgs = {
  input: CopyMacroMealPlanTemplateInput;
};


export type MutationCreateAthleteArgs = {
  input: CreateAthleteInput;
};


export type MutationCreateAthleteTransferArgs = {
  input: CreateAthleteTransferInput;
};


export type MutationCreateAthletesArgs = {
  input: ReadonlyArray<CreateAthleteInput>;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateBrandedFoodArgs = {
  input: CreateBrandedFoodInput;
};


export type MutationCreateBulkAssignMealPlanTemplateJobArgs = {
  input: CreateBulkAssignMealPlanTemplateJobInput;
};


export type MutationCreateClientCredentialArgs = {
  input: CreateClientCredentialInput;
};


export type MutationCreateCuisineArgs = {
  input: CreateCuisineInput;
};


export type MutationCreateCustomTagArgs = {
  input: CreateCustomTagInput;
};


export type MutationCreateDietArgs = {
  input: CreateDietInput;
};


export type MutationCreateDiningStationTemplateArgs = {
  input: CreateDiningStationTemplateInput;
};


export type MutationCreateExchangeSetArgs = {
  input: CreateExchangeSetInput;
};


export type MutationCreateFoodServingsArgs = {
  input: CreateFoodServingsInput;
};


export type MutationCreateGenericFoodArgs = {
  input: CreateGenericFoodInput;
};


export type MutationCreateGoalArgs = {
  input: CreateGoalInput;
};


export type MutationCreateGoalConfigurationWithDaysOfWeekArgs = {
  input: CreateGoalConfigurationWithDaysOfWeekInput;
};


export type MutationCreateGoalConfigurationWithIndividualDatesArgs = {
  input: CreateGoalConfigurationWithIndividualDatesInput;
};


export type MutationCreateGroceryListCustomItemArgs = {
  input: CreateGroceryListCustomItemInput;
};


export type MutationCreateMacroMealPlanTemplateArgs = {
  input: CreateMacroMealPlanTemplateInput;
};


export type MutationCreateMealMenusArgs = {
  input: CreateMealMenusInput;
};


export type MutationCreateMealPlanFromFoodLogsNewArgs = {
  input: CreateMealPlanFromFoodLogsNewInput;
};


export type MutationCreateMealPlanFromMealPlanTemplateArgs = {
  input: CreateMealPlanFromMealPlanTemplateInput;
};


export type MutationCreateMealPlanNewArgs = {
  input: CreateMealPlanNewInput;
};


export type MutationCreateMembershipsArgs = {
  input: CreateOrgMembershipsInput;
};


export type MutationCreateMetricAnthropometryEntriesArgs = {
  input: ReadonlyArray<CreateMetricAnthropometryEntryInput>;
};


export type MutationCreateMetricAnthropometryEntryArgs = {
  input: CreateMetricAnthropometryEntryInput;
};


export type MutationCreateMetricAthleteArgs = {
  input: CreateMetricAthleteInput;
};


export type MutationCreateMetricAthletesArgs = {
  input: ReadonlyArray<CreateMetricAthleteInput>;
};


export type MutationCreateMetricMealPlanArgs = {
  input: CreateMetricMealPlanInput;
};


export type MutationCreateMetricMealPlanFromFoodLogsArgs = {
  input: CreateMetricMealPlanFromFoodLogsInput;
};


export type MutationCreateMetricMealPlanFromMealPlanTemplateArgs = {
  input: CreateMetricMealPlanFromMealPlanTemplateInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateNoteTypeArgs = {
  input: CreateNoteTypeInput;
};


export type MutationCreateOrgArgs = {
  input: CreateOrgInput;
};


export type MutationCreateOrgFoodGroupArgs = {
  input: CreateFoodGroupInput;
};


export type MutationCreateOrgFoodServingsArgs = {
  input: CreateFoodServingsInput;
};


export type MutationCreateOrgFromTeamworksOrgArgs = {
  input: CreateOrgFromTeamworksOrgInput;
};


export type MutationCreateOrgGenericFoodArgs = {
  input: CreateGenericFoodInput;
};


export type MutationCreateOrgGroupArgs = {
  input: CreateOrgGroupInput;
};


export type MutationCreateOrgMembershipForAthleteArgs = {
  input: CreateOrgMembershipForAthleteInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePlannedMenuArgs = {
  input: CreatePlannedMenuInput;
};


export type MutationCreatePrepPlanArgs = {
  input: CreatePrepPlanInput;
};


export type MutationCreatePrepPlanMealArgs = {
  input: CreatePrepPlanMealInput;
};


export type MutationCreateRecipeFullArgs = {
  input: CreateRecipeFullInput;
};


export type MutationCreateRecipeSimpleArgs = {
  input: CreateRecipeSimpleInput;
};


export type MutationCreateRestaurantArgs = {
  input: CreateRestaurantInput;
};


export type MutationCreateRestaurantBrandedFoodArgs = {
  input: CreateRestaurantBrandedFoodInput;
};


export type MutationCreateRestaurantMenuArgs = {
  input: CreateRestaurantMenuInput;
};


export type MutationCreateRestaurantTypeArgs = {
  input: CreateRestaurantTypeInput;
};


export type MutationCreateReusableMenuItemArgs = {
  input: CreateReusableMenuItemInput;
};


export type MutationCreateScheduleArgs = {
  input: CreateScheduleInput;
};


export type MutationCreateSharedExchangeSetArgs = {
  input: CreateExchangeSetInput;
};


export type MutationCreateSharedFoodGroupArgs = {
  input: CreateFoodGroupInput;
};


export type MutationCreateSharedMacroMealPlanTemplateArgs = {
  input: CreateMacroMealPlanTemplateInput;
};


export type MutationCreateSmartTagArgs = {
  input: CreateSmartTagInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTerritoryArgs = {
  input: CreateTerritoryInput;
};


export type MutationCreateUserBrandedFoodArgs = {
  input: CreateUserBrandedFoodFormInput;
};


export type MutationCreateWeightUnitServingForFoodArgs = {
  input: CreateWeightUnitServingForFoodInput;
};


export type MutationDeleteAnthropometryEntryArgs = {
  input: DeleteAnthropometryEntryInput;
};


export type MutationDeleteAthleteArgs = {
  input: DeleteAthleteInput;
};


export type MutationDeleteCuisineArgs = {
  input: DeleteCuisineInput;
};


export type MutationDeleteDietArgs = {
  input: DeleteDietInput;
};


export type MutationDeleteDiningStationTemplateArgs = {
  input: DeleteDiningStationTemplateInput;
};


export type MutationDeleteExchangeSetArgs = {
  input: DeleteExchangeSetInput;
};


export type MutationDeleteGoalConfigurationArgs = {
  input: DeleteGoalConfigurationInput;
};


export type MutationDeleteGroceryListCustomItemArgs = {
  input: DeleteGroceryListCustomItemInput;
};


export type MutationDeleteGroceryListFoodItemArgs = {
  input: DeleteGroceryListFoodItemInput;
};


export type MutationDeleteInProgressPrepPlanArgs = {
  input: DeleteInProgressPrepPlanInput;
};


export type MutationDeleteMacroMealPlanTemplateArgs = {
  input: DeleteMacroMealPlanTemplateInput;
};


export type MutationDeleteMacroMealPlanTemplatePendingStateArgs = {
  input: DeleteMacroMealPlanTemplatePendingStateInput;
};


export type MutationDeleteMealMenuArgs = {
  input: DeleteMealMenuInput;
};


export type MutationDeleteMealPlanArgs = {
  input: DeleteMealPlanInput;
};


export type MutationDeleteMealPlansArgs = {
  input: DeleteMealPlansInput;
};


export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};


export type MutationDeleteNoteTypeArgs = {
  input: DeleteNoteTypeInput;
};


export type MutationDeleteOrgFoodArgs = {
  input: DeleteFoodInput;
};


export type MutationDeleteOrgGroupMacroMealPlanTemplateArgs = {
  input: DeleteMacroMealPlanTemplateInput;
};


export type MutationDeleteOrgGroupMacroMealPlanTemplatePendingStateArgs = {
  input: DeleteMacroMealPlanTemplatePendingStateInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationDeletePlannedMenuArgs = {
  input: DeletePlannedMenuInput;
};


export type MutationDeleteRecipeArgs = {
  input: DeleteRecipeInput;
};


export type MutationDeleteRestaurantTypeArgs = {
  input: DeleteRestaurantTypeInput;
};


export type MutationDeleteReusableMenuItemArgs = {
  input: DeleteReusableMenuItemInput;
};


export type MutationDeleteSharedExchangeSetArgs = {
  input: DeleteExchangeSetInput;
};


export type MutationDeleteSharedMacroMealPlanTemplateArgs = {
  input: DeleteMacroMealPlanTemplateInput;
};


export type MutationDeleteSharedMacroMealPlanTemplatePendingStateArgs = {
  input: DeleteMacroMealPlanTemplatePendingStateInput;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationDeleteUserBrandedFoodArgs = {
  input: DeleteUserBrandedFoodInput;
};


export type MutationDeprecateRestaurantBrandedFoodArgs = {
  input: DeprecateRestaurantBrandedFoodInput;
};


export type MutationDeprecateSharedFoodArgs = {
  input: DeprecateFoodInput;
};


export type MutationDeselectNegativeFoodsArgs = {
  mealPlanId: Scalars['ID'];
};


export type MutationDistributeExchangeTargetsArgs = {
  mealPlanId: Scalars['ID'];
};


export type MutationDistributeMacroTargetsArgs = {
  mealPlanId: Scalars['ID'];
};


export type MutationDuplicatePlannedMenuArgs = {
  input: DuplicatePlannedMenuInput;
};


export type MutationEditAgreementFormArgs = {
  input: EditAgreementFormInput;
};


export type MutationEditAnthropometryEntryArgs = {
  input: EditAnthropometryEntryInput;
};


export type MutationEditAnthropometryEntryCommentArgs = {
  input: EditAnthropometryEntryCommentInput;
};


export type MutationEditAthleteArgs = {
  input: EditAthleteInput;
};


export type MutationEditAthleteDisplaySettingsArgs = {
  input: EditAthleteDisplaySettingsInput;
};


export type MutationEditAthleteFoodLogNotificationSettingsArgs = {
  input: EditAthleteFoodLogNotificationSettingsInput;
};


export type MutationEditAthletesArgs = {
  input: ReadonlyArray<EditAthleteAndAnthroInput>;
};


export type MutationEditBrandArgs = {
  input: EditBrandInput;
};


export type MutationEditBrandedFoodArgs = {
  input: EditBrandedFoodInput;
};


export type MutationEditBulkOrderArgs = {
  input: EditBulkOrderInput;
};


export type MutationEditClientCredentialArgs = {
  input: EditClientCredentialInput;
};


export type MutationEditCuisineArgs = {
  input: EditCuisineInput;
};


export type MutationEditCustomTagArgs = {
  input: EditCustomTagInput;
};


export type MutationEditCustomizedPrepPlanMealArgs = {
  input: EditCustomizedPrepPlanMealInput;
};


export type MutationEditDeliveryLocationArgs = {
  input: EditDeliveryLocationInput;
};


export type MutationEditDietArgs = {
  input: EditDietInput;
};


export type MutationEditDiningStationTemplateArgs = {
  input: EditDiningStationTemplateInput;
};


export type MutationEditExchangeMealTemplateArgs = {
  input: EditExchangeMealTemplateInput;
};


export type MutationEditExchangeMealTemplatesArgs = {
  input: ReadonlyArray<EditExchangeMealTemplateInput>;
};


export type MutationEditExchangeSetArgs = {
  input: EditExchangeSetInput;
};


export type MutationEditFoodLogActivityRpeArgs = {
  input: EditFoodLogActivityRpeInput;
};


export type MutationEditFoodLogActivityTemplateArgs = {
  input: EditFoodLogActivityTemplateInput;
};


export type MutationEditFoodLogActivityTimeWithTimezoneArgs = {
  input: EditFoodLogActivityTimeWithTimezoneInput;
};


export type MutationEditFoodLogMealServingAmountsArgs = {
  input: EditFoodLogMealServingAmountsInput;
};


export type MutationEditFoodLogMealTemplateArgs = {
  input: EditFoodLogMealTemplateInput;
};


export type MutationEditFoodLogMealTimeWithTimezoneArgs = {
  input: EditFoodLogMealTimeWithTimezoneInput;
};


export type MutationEditFoodServingArgs = {
  input: EditFoodServingInput;
};


export type MutationEditGenericFoodArgs = {
  input: EditGenericFoodInput;
};


export type MutationEditGoalConfigurationWithDaysOfWeekArgs = {
  input: EditGoalConfigurationWithDaysOfWeekInput;
};


export type MutationEditGoalConfigurationWithIndividualDatesArgs = {
  input: EditGoalConfigurationWithIndividualDatesInput;
};


export type MutationEditGroceryListArgs = {
  input: EditGroceryListInput;
};


export type MutationEditGroceryListCustomItemArgs = {
  input: EditGroceryListCustomItemInput;
};


export type MutationEditGroceryListFoodItemArgs = {
  input: EditGroceryListFoodItemInput;
};


export type MutationEditMacroMealPlanTemplateArgs = {
  input: MacroMealPlanTemplatePendingStateInput;
};


export type MutationEditMacroMealTemplateArgs = {
  input: EditMacroMealTemplateInput;
};


export type MutationEditMacroMealTemplatesArgs = {
  input: ReadonlyArray<EditMacroMealTemplateInput>;
};


export type MutationEditMacroProtocolArgs = {
  input: EditMacroProtocolInput;
};


export type MutationEditMacroProtocolWithNewGoalArgs = {
  input: EditMacroProtocolWithNewGoalInput;
};


export type MutationEditMacroProtocolWithOldGoalArgs = {
  input: EditMacroProtocolWithOldGoalInput;
};


export type MutationEditMealMenuArgs = {
  input: EditMealMenuInput;
};


export type MutationEditMealMenuLogItemArgs = {
  input: EditMealMenuLogItemInput;
};


export type MutationEditMealMenuWithIdentityArgs = {
  input: EditMealMenuWithIdentityInput;
};


export type MutationEditMealOptionArgs = {
  input: EditMealOptionInput;
};


export type MutationEditMealOptionLocalArgs = {
  input: EditMealOptionLocalInput;
};


export type MutationEditMealOptionPendingArgs = {
  input: EditMealOptionPendingInput;
};


export type MutationEditMealOptionPendingLocalArgs = {
  input: EditMealOptionPendingLocalInput;
};


export type MutationEditMealPlanCalendarArgs = {
  input: EditMealPlanCalendarInput;
};


export type MutationEditMealPlanDateArgs = {
  input: EditMealPlanDateInput;
};


export type MutationEditMealPlanDateForAthleteArgs = {
  input: EditMealPlanDateForAthleteInput;
};


export type MutationEditMealPlanExchangeTargetsArgs = {
  input: EditMealPlanExchangeTargetsInput;
};


export type MutationEditMealPlanIsSharedArgs = {
  input: EditMealPlanIsSharedInput;
};


export type MutationEditMealPlanNewArgs = {
  input: EditMealPlanNewInput;
};


export type MutationEditMealPlansIsSharedArgs = {
  input: EditMealPlansIsSharedInput;
};


export type MutationEditMembershipsArgs = {
  input: EditOrgMembershipsInput;
};


export type MutationEditMenuItemImageArgs = {
  input: EditMenuItemImageInput;
};


export type MutationEditMenuOrderArgs = {
  input: EditMenuOrderInput;
};


export type MutationEditMenuOrderMealTemplateArgs = {
  input: EditMenuOrderMealTemplateInput;
};


export type MutationEditMetricAnthropometryEntriesArgs = {
  input: ReadonlyArray<EditMetricAnthropometryEntryInput>;
};


export type MutationEditMetricAnthropometryEntryArgs = {
  input: EditMetricAnthropometryEntryInput;
};


export type MutationEditMetricAnthropometryEntryWeighInForDateArgs = {
  input: EditMetricAnthropometryEntryWeighInForDateInput;
};


export type MutationEditMetricAnthropometryEntryWeighInForDateForAthleteArgs = {
  input: EditMetricAnthropometryEntryWeighInForDateForAthleteInput;
};


export type MutationEditMetricAthletesArgs = {
  input: ReadonlyArray<EditAthleteAndMetricAnthroInput>;
};


export type MutationEditMetricMacroProtocolArgs = {
  input: EditMetricMacroProtocolInput;
};


export type MutationEditNoteArgs = {
  input: EditNoteInput;
};


export type MutationEditNoteTypeArgs = {
  input: EditNoteTypeInput;
};


export type MutationEditOrgArgs = {
  input: EditOrgInput;
};


export type MutationEditOrgAutoShareNewNotemealRecipesArgs = {
  input: EditOrgAutoShareNewNotemealRecipesInput;
};


export type MutationEditOrgFeatureFlagsArgs = {
  input: EditOrgFeatureFlagsInput;
};


export type MutationEditOrgFoodGroupArgs = {
  input: EditFoodGroupInput;
};


export type MutationEditOrgFoodServingArgs = {
  input: EditFoodServingInput;
};


export type MutationEditOrgGenericFoodArgs = {
  input: EditGenericFoodInput;
};


export type MutationEditOrgGroupArgs = {
  input: EditOrgGroupInput;
};


export type MutationEditOrgGroupMacroMealPlanTemplateArgs = {
  input: MacroMealPlanTemplatePendingStateInput;
};


export type MutationEditOrgTeamworksProfilesArgs = {
  input: EditOrgTeamworksProfilesInput;
};


export type MutationEditPaymentMethodArgs = {
  input: EditPaymentMethodInput;
};


export type MutationEditPlannedMenuArgs = {
  input: EditPlannedMenuInput;
};


export type MutationEditPrepPlanArgs = {
  input: EditPrepPlanInput;
};


export type MutationEditPrepPlanMealArgs = {
  input: EditPrepPlanMealInput;
};


export type MutationEditRecipeFullArgs = {
  input: EditRecipeFullInput;
};


export type MutationEditRecipeIsSharedArgs = {
  input: EditRecipeIsSharedInput;
};


export type MutationEditRecipePartialArgs = {
  input: EditRecipePartialInput;
};


export type MutationEditRecipeSimpleArgs = {
  input: EditRecipeSimpleInput;
};


export type MutationEditRestaurantArgs = {
  input: EditRestaurantInput;
};


export type MutationEditRestaurantBrandedFoodArgs = {
  input: EditRestaurantBrandedFoodInput;
};


export type MutationEditRestaurantLocationArgs = {
  input: EditRestaurantLocationInput;
};


export type MutationEditRestaurantLocationContactArgs = {
  input: EditRestaurantLocationContactInput;
};


export type MutationEditRestaurantMenuArgs = {
  input: EditRestaurantMenuInput;
};


export type MutationEditRestaurantMenuLinkArgs = {
  input: EditRestaurantMenuLinkInput;
};


export type MutationEditRestaurantMenuLinkOrderArgs = {
  input: EditRestaurantMenuLinkOrderInput;
};


export type MutationEditRestaurantMenuLinkOrderMealTemplateArgs = {
  input: EditRestaurantMenuLinkOrderMealTemplateInput;
};


export type MutationEditRestaurantMenuLinkPlateOrderMealTemplateArgs = {
  input: EditRestaurantMenuLinkPlateOrderMealTemplateInput;
};


export type MutationEditRestaurantMenuLogArgs = {
  input: EditRestaurantMenuLogInput;
};


export type MutationEditRestaurantMenuLogItemArgs = {
  input: EditRestaurantMenuLogItemInput;
};


export type MutationEditRestaurantMenuLogMealTemplateArgs = {
  input: EditRestaurantMenuLogMealTemplateInput;
};


export type MutationEditRestaurantTypeArgs = {
  input: EditRestaurantTypeInput;
};


export type MutationEditReusableMenuItemArgs = {
  input: EditReusableMenuItemInput;
};


export type MutationEditReusableMenuItemPartialArgs = {
  input: EditReusableMenuItemPartialInput;
};


export type MutationEditScheduleArgs = {
  input: EditScheduleInput;
};


export type MutationEditSharedExchangeSetArgs = {
  input: EditExchangeSetInput;
};


export type MutationEditSharedFoodGroupArgs = {
  input: EditFoodGroupInput;
};


export type MutationEditSharedMacroMealPlanTemplateArgs = {
  input: MacroMealPlanTemplatePendingStateInput;
};


export type MutationEditSmartTagArgs = {
  input: EditSmartTagInput;
};


export type MutationEditSyncedAthleteArgs = {
  input: EditSyncedAthleteInput;
};


export type MutationEditSyncedAthletesArgs = {
  input: ReadonlyArray<EditSyncedAthleteAndAnthroInput>;
};


export type MutationEditSyncedMetricAthletesArgs = {
  input: ReadonlyArray<EditSyncedAthleteAndMetricAnthroInput>;
};


export type MutationEditTeamArgs = {
  input: EditTeamInput;
};


export type MutationEditTeamDisplaySettingsArgs = {
  input: EditTeamDisplaySettingsInput;
};


export type MutationEditTeamFoodLogNotificationSettingsArgs = {
  input: EditTeamFoodLogNotificationSettingsInput;
};


export type MutationEditTeamForStaffArgs = {
  input: EditTeamForStaffInput;
};


export type MutationEditTeamLabelArgs = {
  input: EditTeamLabelInput;
};


export type MutationEditTerritoryArgs = {
  input: EditTerritoryInput;
};


export type MutationEditTimelineMealDetailsArgs = {
  input: EditTimelineMealDetailsInput;
};


export type MutationEditTimelineMealMealTemplateArgs = {
  input: EditTimelineMealMealTemplateInput;
};


export type MutationEditTimelineMealServingAmountArgs = {
  input: EditTimelineMealServingAmountInput;
};


export type MutationEditUserBrandedFoodArgs = {
  input: EditUserBrandedFoodFormInput;
};


export type MutationEditUserTeamworksIdToOverwriteArgs = {
  input: EditUserTeamworksIdToOverwriteInput;
};


export type MutationFinalizePrepPlanArgs = {
  input: FinalizePrepPlanInput;
};


export type MutationForceLockPlannedMenuArgs = {
  input: ForceLockPlannedMenuInput;
};


export type MutationGetPossibleGptRecipeByNameArgs = {
  input: GptRecipeByNameInput;
};


export type MutationGetPossibleGptRecipeFromImageArgs = {
  input: GptRecipeFromImageInput;
};


export type MutationGetPossibleGptRecipeFromUrlArgs = {
  input: GptRecipeFromUrlInput;
};


export type MutationImportBonAppetitMenusArgs = {
  input: ImportBonAppetitMenusInput;
};


export type MutationImportCbordMenusArgs = {
  input: ImportCbordMenusInput;
};


export type MutationImportCompassMenusArgs = {
  input: ImportCompassMenusInput;
};


export type MutationImportCompassRecipeArgs = {
  input: ImportCompassRecipeInput;
};


export type MutationImportPlannedMenuArgs = {
  input: ImportPlannedMenuInput;
};


export type MutationImportSodexoMenusArgs = {
  input: ImportSodexoMenusInput;
};


export type MutationLinkTeamworksOrgArgs = {
  input: LinkTeamworksOrgInput;
};


export type MutationLockPlannedMenuArgs = {
  input: LockPlannedMenuInput;
};


export type MutationMarkNotificationsAsViewedArgs = {
  input: MarkNotificationsAsViewedInput;
};


export type MutationMarkNotificationsAsViewedBeforeArgs = {
  input: MarkNotificationsAsViewedBeforeInput;
};


export type MutationMoveMacroMealPlanTemplateToOrgGroupArgs = {
  input: MoveMacroMealPlanTemplateToOrgGroupInput;
};


export type MutationMoveOrgFoodToOrgGroupArgs = {
  input: MoveOrgFoodToOrgGroupInput;
};


export type MutationMoveOrgGroupFoodToMyOrgArgs = {
  input: MoveOrgGroupFoodToMyOrgInput;
};


export type MutationMoveOrgGroupMacroMealPlanTemplateToOrgArgs = {
  input: MoveOrgGroupMacroMealPlanTemplateToOrgInput;
};


export type MutationMoveOrgRecipeToOrgGroupArgs = {
  input: MoveOrgRecipeToOrgGroupInput;
};


export type MutationOverrideMealMenuIdentityArgs = {
  input: OverrideMealMenuIdentityInput;
};


export type MutationPublishMacroMealPlanTemplateArgs = {
  input: PublishMacroMealPlanTemplateInput;
};


export type MutationPublishOrgGroupMacroMealPlanTemplateArgs = {
  input: PublishMacroMealPlanTemplateInput;
};


export type MutationPublishSharedMacroMealPlanTemplateArgs = {
  input: PublishMacroMealPlanTemplateInput;
};


export type MutationRecieveAthleteTransferArgs = {
  input: RecieveAthleteTransferInput;
};


export type MutationReclaimAthleteTransferArgs = {
  input: ReclaimAthleteTransferInput;
};


export type MutationRecordMenuOrderSuggestionDecisionArgs = {
  input: RecordMenuOrderSuggestionDecisionInput;
};


export type MutationRecordMenuOrderSuggestionsViewArgs = {
  input: RecordMenuOrderSuggestionsViewInput;
};


export type MutationRefreshClientCredentialSecretArgs = {
  input: RefreshClientCredentialSecretInput;
};


export type MutationRefreshTeamworksSyncStatusArgs = {
  input: RefreshTeamworksSyncStatusInput;
};


export type MutationRemoveAthleteDislikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationRemoveAthleteDislikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationRemoveAthleteLikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationRemoveAthleteLikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationRemoveBulkOrderArgs = {
  input: RemoveBulkOrderInput;
};


export type MutationRemoveFoodLogActivityArgs = {
  input: RemoveFoodLogActivityInput;
};


export type MutationRemoveFoodLogActivityTemplateArgs = {
  input: RemoveFoodLogActivityTemplateInput;
};


export type MutationRemoveFoodLogMealArgs = {
  input: RemoveFoodLogMealInput;
};


export type MutationRemoveFoodLogMealImageArgs = {
  input: RemoveFoodLogMealImageInput;
};


export type MutationRemoveFoodLogMealTemplateArgs = {
  input: RemoveFoodLogMealTemplateInput;
};


export type MutationRemoveMealMenuLogItemArgs = {
  input: RemoveMealMenuLogItemInput;
};


export type MutationRemoveMealOptionArgs = {
  input: RemoveMealOptionInput;
};


export type MutationRemoveMealOptionLocalArgs = {
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionId: Scalars['ID'];
};


export type MutationRemoveMealOptionPendingArgs = {
  input: RemoveMealOptionPendingInput;
};


export type MutationRemoveMealOptionPendingLocalArgs = {
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionPendingId: Scalars['ID'];
};


export type MutationRemoveMealPlanDateArgs = {
  input: RemoveMealPlanDateInput;
};


export type MutationRemoveMealPlanDateForAthleteArgs = {
  input: RemoveMealPlanDateForAthleteInput;
};


export type MutationRemoveMenuOrderArgs = {
  input: RemoveMenuOrderInput;
};


export type MutationRemoveMenuOrderImageArgs = {
  input: RemoveMenuOrderImageInput;
};


export type MutationRemoveMenuOrderMealTemplateArgs = {
  input: RemoveMenuOrderMealTemplateInput;
};


export type MutationRemoveMyDislikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationRemoveMyDislikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationRemoveMyLikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationRemoveMyLikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationRemovePrepPlanMealArgs = {
  input: RemovePrepPlanMealInput;
};


export type MutationRemoveRecipeImageArgs = {
  input: RemoveRecipeImageInput;
};


export type MutationRemoveRestaurantMenuLinkArgs = {
  input: RemoveRestaurantMenuLinkInput;
};


export type MutationRemoveRestaurantMenuLinkOrderArgs = {
  input: RemoveRestaurantMenuLinkOrderInput;
};


export type MutationRemoveRestaurantMenuLinkOrderMealTemplateArgs = {
  input: RemoveRestaurantMenuLinkOrderMealTemplateInput;
};


export type MutationRemoveRestaurantMenuLinkPlateOrderArgs = {
  input: RemoveRestaurantMenuLinkPlateOrderInput;
};


export type MutationRemoveRestaurantMenuLinkPlateOrderMealTemplateArgs = {
  input: RemoveRestaurantMenuLinkPlateOrderMealTemplateInput;
};


export type MutationRemoveRestaurantMenuLogArgs = {
  input: RemoveRestaurantMenuLogInput;
};


export type MutationRemoveRestaurantMenuLogItemArgs = {
  input: RemoveRestaurantMenuLogItemInput;
};


export type MutationRemoveRestaurantMenuLogMealTemplateArgs = {
  input: RemoveRestaurantMenuLogMealTemplateInput;
};


export type MutationRemoveTimelineMealImageArgs = {
  input: RemoveTimelineMealImageInput;
};


export type MutationRemoveTimelineMealServingAmountArgs = {
  input: RemoveTimelineMealServingAmountInput;
};


export type MutationRunMaintainTeamworksFullySyncedJobArgs = {
  input: RefreshTeamworksSyncStatusInput;
};


export type MutationSavePlannedMealsArgs = {
  input: SavePlannedMealsInput;
};


export type MutationSelectMealOptionArgs = {
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionId: Scalars['ID'];
};


export type MutationSendMealMenuNotificationArgs = {
  mealMenuId: Scalars['ID'];
};


export type MutationSetGoalTrackerGoalArgs = {
  input: SetGoalTrackerGoalInput;
};


export type MutationSetMealMenuCheckInsForAthleteArgs = {
  input: SetMealMenuCheckInsInput;
};


export type MutationSetMealMenuLogItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetMealOptionsForMealTemplatesArgs = {
  input: SetMealOptionsForMealTemplatesInput;
};


export type MutationSetMenuOrderItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetPrepPlanGuidedBuilderArgs = {
  input: SetPrepPlanGuidedBuilderInput;
};


export type MutationSetPrepPlanMealAssignmentSlotsArgs = {
  input: SetPrepPlanMealAssignmentSlotsInput;
};


export type MutationSetRestaurantMenuLinkOrderItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetRestaurantMenuLinkPlateOrderItemPercentConsumedArgs = {
  input: SetRestaurantMenuLinkPlateOrderItemPercentConsumedInput;
};


export type MutationSetRestaurantMenuLogItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetTimelineMealServingAmountsForAthleteArgs = {
  input: SetTimelineMealServingAmountsForAthleteInput;
};


export type MutationSetTimelineMealServingAmountsForMeArgs = {
  input: SetTimelineMealServingAmountsInput;
};


export type MutationShareMealOptionPendingArgs = {
  input: ShareMealOptionPendingInput;
};


export type MutationShareMealOptionPendingLocalArgs = {
  mealTemplateId: Scalars['ID'];
  mealOptionPendingId: Scalars['ID'];
};


export type MutationTextMealPlanToAthleteArgs = {
  input: TextMealPlanToAthleteInput;
};


export type MutationTextMealPlansToAthletesArgs = {
  input: TextMealPlansToAthletesInput;
};


export type MutationUnarchiveAthleteArgs = {
  input: UnarchiveAthleteInput;
};


export type MutationUnlockPlannedMenuArgs = {
  input: UnlockPlannedMenuInput;
};


export type MutationUpdateBulkOrderStatusArgs = {
  bulkOrderId: Scalars['ID'];
  status: MenuOrderItemStatus;
};


export type MutationUpdateMacroMealPlanTemplateAssignScopeArgs = {
  input: UpdateMealPlanTemplateAssignScopeInput;
};


export type MutationUpdateMacroMealPlanTemplateSharingScopeArgs = {
  input: UpdateMacroMealPlanTemplateSharingScopeInput;
};


export type MutationUpdateMealMenuPlateItemOrderStatusArgs = {
  input: UpdateMealMenuPlateItemOrderStatusInput;
};


export type MutationUpdateMealMenuPlateOrderStatusArgs = {
  mealMenuPlateOrderId: Scalars['String'];
  status: MenuOrderItemStatus;
};


export type MutationUpdateMealMenuPlateOrderStatusesArgs = {
  mealMenuPlateOrderIds: ReadonlyArray<Scalars['String']>;
  status: MenuOrderItemStatus;
};


export type MutationUpdateMenuOrderItemStatusesArgs = {
  input: UpdateMenuOrderItemStatusesInput;
};


export type MutationUpdateMultipleMealMenuPlateItemOrderStatusesArgs = {
  input: UpdateMultipleMealMenuPlateItemOrderStatusesInput;
};


export type MutationUpdateMultipleMenuOrderItemStatusesArgs = {
  input: UpdateMultipleMenuOrderItemStatusesInput;
};


export type MutationUpdateSharedMacroMealPlanTemplateSharingScopeArgs = {
  input: UpdateMacroMealPlanTemplateSharingScopeInput;
};

export type MyFoodGroupPreferenceInput = {
  readonly foodGroupId: Scalars['ID'];
};

export type MyFoodPreferenceInput = {
  readonly foodId: Scalars['ID'];
};

export type MyNamedTagsPreviewPayload = {
  readonly previewNamedTags: ReadonlyArray<NamedTag>;
  readonly total: Scalars['Int'];
  readonly hiddenPreviewText: Maybe<Scalars['String']>;
};

export type MyRecipeCursorConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: CursorPageInfo;
};

export type NamedTag = {
  readonly tag: Tag;
  readonly tagName: TagName;
};

export type NamedTagCursorConnection = {
  readonly edges: ReadonlyArray<NamedTag>;
  readonly pageInfo: CursorPageInfo;
};

export type NamedTagOffsetConnection = {
  readonly edges: ReadonlyArray<NamedTag>;
  readonly pageInfo: OffsetPageInfo;
};

export type NoTargetsDisplaySettings = {
  readonly dailyProgressViewsForMacros: MacroProgressViews;
  readonly mealProgressViewsForMacros: MacroProgressViews;
};

export type NoTargetsDisplaySettingsInput = {
  readonly dailyProgressViewsForMacros: MacroProgressViews;
  readonly mealProgressViewsForMacros: MacroProgressViews;
};

export type NotSharedRecipeCursorConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: CursorPageInfo;
};

export type Note = {
  readonly id: Scalars['ID'];
  readonly datetime: Scalars['DateTime'];
  readonly content: Scalars['String'];
  readonly type: Maybe<NoteType>;
  readonly athlete: Athlete;
};

export type NoteFormInput = {
  readonly datetime: Scalars['DateTime'];
  readonly content: Scalars['String'];
  readonly noteTypeId: Maybe<Scalars['ID']>;
};

export type NoteType = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type NoteTypeFormInput = {
  readonly name: Scalars['String'];
};

export type NotemealAccountType =
  | 'athlete'
  | 'dietitian'
  | 'chef'
  | '%future added value';

export type Notification = {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
};

export type NotificationCursorConnection = {
  readonly edges: ReadonlyArray<Notification>;
  readonly pageInfo: CursorPageInfo;
};

export type NotificationPublication = {
  readonly payload: NotificationPublicationPayload;
  /** @deprecated Pending web notifications overhaul */
  readonly scope: NotificationPublisherScope;
};

export type NotificationPublicationPayload = {
  readonly notification: Notification;
};

export type NotificationPublisherScope = {
  readonly teamId: Maybe<Scalars['ID']>;
  readonly athleteId: Maybe<Scalars['ID']>;
  readonly foodLogMealId: Maybe<Scalars['ID']>;
};

export type Nutrient = {
  readonly id: Scalars['ID'];
  readonly fdcNutrientId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly commonName: Maybe<Scalars['String']>;
  readonly unitName: Scalars['String'];
  readonly category: Scalars['String'];
  readonly defaultDietaryReferenceIntake: Maybe<NutrientDietaryReferenceIntake>;
};


export type NutrientDefaultDietaryReferenceIntakeArgs = {
  age: Maybe<Scalars['Int']>;
  sex: Maybe<SexType>;
};

export type NutrientAmount = {
  /** @deprecated Field no longer supported */
  readonly id: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  readonly footnote: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  readonly dataPoints: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  readonly min: Maybe<Scalars['Float']>;
  /** @deprecated Field no longer supported */
  readonly max: Maybe<Scalars['Float']>;
  /** @deprecated Field no longer supported */
  readonly median: Maybe<Scalars['Float']>;
  /** @deprecated Field no longer supported */
  readonly foodId: Maybe<Scalars['ID']>;
  readonly nutrient: Nutrient;
  readonly amount: Scalars['Float'];
};

export type NutrientDietaryReferenceIntake = {
  readonly id: Scalars['ID'];
  readonly amount: Maybe<Scalars['Float']>;
  readonly type: NutrientDietaryReferenceIntakeType;
};


export type NutrientDietaryReferenceIntakeAmountArgs = {
  kcal: Maybe<Scalars['Float']>;
};

export type NutrientDietaryReferenceIntakeType =
  | 'RDA'
  | 'UL'
  | 'AI'
  | 'DGA'
  | 'unitsPerKcal'
  | '%future added value';

export type NutritionixBrand = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: Scalars['Int'];
};

export type NutritionixBrandedFood = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly nixItemId: Scalars['String'];
  readonly brand: NutritionixBrand;
  readonly thumbnailUrl: Maybe<Scalars['String']>;
};

export type NutritionixBrandedInstantSearchPayload = {
  readonly branded: ReadonlyArray<NutritionixBrandedFood>;
};

export type NutritionixCommonFood = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly nixTagId: Scalars['String'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
};

export type NutritionixCommonInstantSearchPayload = {
  readonly common: ReadonlyArray<NutritionixCommonFood>;
};

export type NutritionixRestaurantLocation = {
  readonly id: Scalars['ID'];
  readonly brandId: Scalars['ID'];
  readonly address: Scalars['String'];
  readonly address2: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly zip: Scalars['String'];
  readonly country: Scalars['String'];
  readonly lat: Maybe<Scalars['String']>;
  readonly long: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
};

export type NutritionixRestaurantLocationSearchData = {
  readonly ll: Scalars['String'];
  readonly distance: Scalars['Int'];
  readonly brandId: Maybe<Scalars['String']>;
};

export type NutritionixRestaurantLocationSearchInput = {
  readonly appId: Scalars['String'];
  readonly appKey: Scalars['String'];
  readonly data: NutritionixRestaurantLocationSearchData;
};

export type NutritionixRestaurantLocationSearchPayload = {
  readonly locations: ReadonlyArray<NutritionixRestaurantLocation>;
};

export type NutritionixV1RestaurantSearchFilter = {
  readonly brand_id: Scalars['String'];
  readonly item_type: Scalars['Int'];
};

export type NutritionixV1RestaurantSearchInput = {
  readonly appId: Scalars['String'];
  readonly appKey: Scalars['String'];
  readonly fields: ReadonlyArray<Scalars['String']>;
  readonly filters: NutritionixV1RestaurantSearchFilter;
  readonly offset: Scalars['Int'];
  readonly limit: Scalars['Int'];
  readonly sort: NutritionixV1RestaurantSearchSort;
};

export type NutritionixV1RestaurantSearchPayload = {
  readonly total: Scalars['Int'];
  readonly branded: ReadonlyArray<NutritionixBrandedFood>;
};

export type NutritionixV1RestaurantSearchSort = {
  readonly field: Scalars['String'];
  readonly order: Scalars['String'];
};

export type OffsetPageInfo = {
  readonly hasNextPage: Scalars['Boolean'];
  readonly endOffset: Scalars['Int'];
  readonly total: Scalars['Int'];
};

export type OffsetPaginationInput = {
  readonly offset: Scalars['Int'];
  readonly limit: Scalars['Int'];
};

export type Org = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly allowsPublicAccess: Scalars['Boolean'];
  readonly memberships: ReadonlyArray<OrgMembership>;
  readonly featureFlags: FeatureFlags;
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly teams: ReadonlyArray<Team>;
  readonly teamworksProfileSyncRules: ReadonlyArray<TeamworksProfileSyncRule>;
  readonly teamworksSyncStatus: Maybe<TeamworksSyncStatus>;
  readonly athletes: ReadonlyArray<Athlete>;
  readonly integrations: ReadonlyArray<Integration>;
  readonly isFullySynced: Scalars['Boolean'];
  readonly isTeamworksLinked: Scalars['Boolean'];
  readonly isNotemealLinked: Scalars['Boolean'];
  readonly agreementForm: Maybe<AgreementForm>;
  readonly scoringSystem: Maybe<ScoringSystem>;
  readonly autoShareNewNotemealRecipes: Scalars['Boolean'];
  readonly localeCode: Scalars['String'];
  readonly hasTwSuperuserPersonId: Maybe<Scalars['Boolean']>;
  readonly territoryName: Maybe<Scalars['String']>;
};


export type OrgMembershipsArgs = {
  filterInactive: Maybe<Scalars['Boolean']>;
};


export type OrgAthletesArgs = {
  isArchived: Maybe<Scalars['Boolean']>;
};

export type OrgForReporting = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly territoryName: Maybe<Scalars['String']>;
  readonly performanceKitchen: Maybe<Scalars['Boolean']>;
};

export type OrgGroup = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly allowsResourceSharing: Scalars['Boolean'];
  readonly allowsAthleteTransfer: Scalars['Boolean'];
  readonly orgs: ReadonlyArray<Org>;
  readonly orgsCount: Scalars['Int'];
  readonly parentOrgGroup: Maybe<OrgGroup>;
  readonly childrenOrgGroups: ReadonlyArray<OrgGroup>;
  readonly createdBy: Maybe<User>;
};

export type OrgInfo = {
  readonly org: OrgResult;
  readonly orgMembership: Maybe<OrgMembership>;
  readonly athlete: Maybe<Athlete>;
};

export type OrgMembership = {
  readonly id: Scalars['ID'];
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly athlete: Maybe<Athlete>;
  readonly isActive: Scalars['Boolean'];
  readonly isAdmin: Scalars['Boolean'];
  readonly isNotemealOnly: Scalars['Boolean'];
  readonly lastRefresh: Maybe<Scalars['DateTime']>;
  readonly isSubscribedToDigest: Scalars['Boolean'];
  readonly user: User;
  readonly roles: ReadonlyArray<Role>;
};

export type OrgResult = {
  readonly name: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type OverrideMealMenuIdentityInput = {
  readonly mealMenuIdentityId: Scalars['ID'];
};

export type OverrideMealMenuIdentityPayload = {
  readonly mealMenuIdentity: MealMenuIdentity;
};

export type OverrideMealPlanDateAssignmentInput = {
  readonly individualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly daysOfWeek: Maybe<ReadonlyArray<DayOfWeek>>;
};

export type PaymentMethod = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type PaymentMethodCursorConnection = {
  readonly edges: ReadonlyArray<PaymentMethod>;
  readonly pageInfo: CursorPageInfo;
};

export type PaymentMethodOffsetConnection = {
  readonly edges: ReadonlyArray<PaymentMethod>;
  readonly pageInfo: OffsetPageInfo;
};

export type PlannedMenu = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly firstOccurrence: Scalars['Date'];
  readonly lastOccurrence: Scalars['Date'];
  readonly occurrence: PlannedMenuOccurrence;
  readonly timezone: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly weeks: ReadonlyArray<PlannedMenuWeek>;
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly createdById: Maybe<Scalars['ID']>;
  readonly createdBy: Maybe<User>;
  readonly lastEditedAt: Maybe<Scalars['DateTime']>;
  readonly lastEditedBy: Maybe<User>;
  readonly athletesWithBirthdaysInRange: ReadonlyArray<Athlete>;
  readonly lockCreatedAt: Maybe<Scalars['DateTime']>;
  readonly lockCreatedBy: Maybe<User>;
};

export type PlannedMenuDisabledDaysInput = {
  readonly plannedMenuWeekId: Scalars['ID'];
  readonly disabledDays: ReadonlyArray<DayOfWeek>;
};

export type PlannedMenuLockTakenPayload = {
  readonly plannedMenu: PlannedMenu;
};

export type PlannedMenuLockTakenPublication = {
  readonly scope: PlannedMenuLockTakenPublisherScope;
  readonly payload: PlannedMenuLockTakenPayload;
};

export type PlannedMenuLockTakenPublisherScope = {
  readonly plannedMenuId: Scalars['ID'];
};

export type PlannedMenuLockTakenSubscriberScope = {
  readonly plannedMenuId: Scalars['ID'];
};

export type PlannedMenuMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly startTime: Scalars['String'];
  readonly endTime: Scalars['String'];
  readonly plannedMenuMealRows: ReadonlyArray<PlannedMenuMealRow>;
  readonly themes: ReadonlyArray<Scalars['String']>;
};

export type PlannedMenuMealInput = {
  readonly id: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly startTime: Scalars['String'];
  readonly endTime: Scalars['String'];
  readonly plannedMenuWeekId: Scalars['ID'];
  readonly plannedMenuMealRows: ReadonlyArray<PlannedMenuMealRowInput>;
  readonly themes: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type PlannedMenuMealRow = {
  readonly id: Scalars['ID'];
  readonly diningStationName: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly foodType: Maybe<Scalars['String']>;
  readonly plannedMenuMealRowItems: ReadonlyArray<PlannedMenuMealRowItem>;
};

export type PlannedMenuMealRowInput = {
  readonly id: Maybe<Scalars['ID']>;
  readonly diningStationName: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly foodType: Maybe<Scalars['String']>;
  readonly plannedMenuMealRowItems: ReadonlyArray<PlannedMenuMealRowItemInput>;
};

export type PlannedMenuMealRowItem = {
  readonly id: Scalars['ID'];
  readonly dayOfWeek: DayOfWeek;
  readonly position: Scalars['Int'];
  readonly menuItem: MenuItem;
};

export type PlannedMenuMealRowItemInput = {
  readonly id: Maybe<Scalars['ID']>;
  readonly dayOfWeek: DayOfWeek;
  readonly position: Scalars['Int'];
  readonly menuItemId: Scalars['ID'];
};

export type PlannedMenuOccurrence =
  | 'daily'
  | 'weekdays'
  | '%future added value';

export type PlannedMenuOffsetConnection = {
  readonly edges: ReadonlyArray<PlannedMenu>;
  readonly pageInfo: OffsetPageInfo;
};

export type PlannedMenuWeek = {
  readonly id: Scalars['ID'];
  readonly week: Scalars['Int'];
  readonly meals: ReadonlyArray<PlannedMenuMeal>;
  readonly disabledDays: ReadonlyArray<DayOfWeek>;
};

export type PlateOrderIdItemIdTuple = {
  readonly mealMenuPlateOrderId: Scalars['ID'];
  readonly mealMenuPlateItemId: Scalars['ID'];
};

export type Position = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly teamworksMappings: ReadonlyArray<TeamworksPositionMapping>;
  readonly sport: Sport;
};

export type PositionBySportCursorConnection = {
  readonly edges: ReadonlyArray<Position>;
  readonly pageInfo: CursorPageInfo;
};

export type PositionTag = Tag & {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
  readonly positions: ReadonlyArray<Position>;
};

export type PositionsMappingInput = {
  readonly teamworksName: Scalars['String'];
  readonly positionId: Scalars['ID'];
};

export type PreferredContactMethodType =
  | 'email'
  | 'phoneNumber'
  | '%future added value';

export type PrepPlan = {
  readonly id: Scalars['ID'];
  readonly start: Scalars['Date'];
  readonly end: Scalars['Date'];
  readonly inProgress: Scalars['Boolean'];
  readonly meals: ReadonlyArray<PrepPlanMeal>;
  readonly mealSlots: ReadonlyArray<PrepPlanMealAssignment>;
  readonly groceryList: Maybe<GroceryList>;
  readonly seedValue: Scalars['Int'];
};

export type PrepPlanCursorConnection = {
  readonly edges: ReadonlyArray<PrepPlan>;
  readonly pageInfo: CursorPageInfo;
};

export type PrepPlanMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly prepPlanMealAssignments: ReadonlyArray<PrepPlanMealAssignment>;
  readonly edited: Scalars['Boolean'];
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly guidedBuilder: Scalars['Boolean'];
  readonly recipe: Maybe<Recipe>;
  readonly mealOption: Maybe<MealOption>;
  readonly primaryImageUrl: Maybe<Scalars['String']>;
  readonly ingredientText: Scalars['String'];
};

export type PrepPlanMealAssignment = {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Int'];
  readonly position: Scalars['Int'];
  readonly date: Maybe<Scalars['Date']>;
  readonly type: Maybe<MealType>;
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly prepPlanMeal: Maybe<PrepPlanMeal>;
};

export type PrepPlanMealAssignmentInput = {
  readonly amount: Scalars['Int'];
  readonly position: Scalars['Int'];
  readonly type: Maybe<MealType>;
  readonly date: Maybe<Scalars['Date']>;
  readonly mealTemplateId: Maybe<Scalars['ID']>;
};

export type PrepPlanMealSlotAssignmentInput = {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Int'];
};

export type PrepPlanMealsForTimelineMealInput = {
  readonly mealType: Maybe<MealType>;
  readonly date: Scalars['Date'];
};

export type PrepPlanMealsForTimelineMealPayload = {
  readonly scheduledMeals: ReadonlyArray<PrepPlanMeal>;
  readonly otherMeals: ReadonlyArray<PrepPlanMeal>;
};

export type PublishMacroMealPlanTemplateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
  readonly macroMealPlanTemplate: EditMacroMealPlanTemplateInput;
};

export type PublishMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type Query = {
  readonly activeAthleteTransferByAthleteId: Maybe<AthleteTransfer>;
  readonly activeAthleteTransferOffsetConnection: ActiveAthleteTransferOffsetConnection;
  readonly activeAthleteTransfers: ReadonlyArray<AthleteTransfer>;
  readonly agreementFormOffsetConnection: AgreementFormOffsetConnection;
  readonly agreementForms: ReadonlyArray<AgreementForm>;
  readonly allDeliveryLocations: DeliveryLocationOffsetConnection;
  readonly allMealPlanTemplatesForDietitianCursorConnection: MealPlanTemplateCursorConnection;
  readonly allRestaurantLocations: RestaurantLocationOffsetConnection;
  readonly allRestaurants: RestaurantOffsetConnection;
  readonly allSchedules: ReadonlyArray<Schedule>;
  readonly allTeamLabels: TeamLabelOffsetConnection;
  readonly appModeRoles: AppModeRoles;
  readonly athlete: Athlete;
  readonly athleteGoalsInRange: ReadonlyArray<AthleteGoalConfigurationAndTracker>;
  readonly athleteSearch: ReadonlyArray<Athlete>;
  readonly athleteSearchCursorConnection: AthleteCursorConnection;
  readonly athleteSummariesInRange: AthleteSummary;
  readonly athleteTransferOrgGroups: ReadonlyArray<OrgGroup>;
  readonly athletes: ReadonlyArray<Athlete>;
  readonly athletesForBulkOrderCursorConnection: AthleteCursorConnectionWithSelectAll;
  readonly athletesForCustomTagCreation: ReadonlyArray<Athlete>;
  readonly athletesForCustomTagCreationCursorConnection: AthleteCursorConnection;
  readonly athletesForMealMenus: ReadonlyArray<Athlete>;
  readonly athletesForMealPlanTemplateAssignmentCursorConnection: AthleteCursorConnection;
  readonly athletesForPendingAdvancedSelection: ReadonlyArray<Athlete>;
  readonly athletesForTag: ReadonlyArray<Athlete>;
  readonly athletesForTagCursorConnection: AthleteCursorConnection;
  readonly athletesForTagOffsetConnection: AthleteOffsetConnection;
  readonly athletesForTeam: ReadonlyArray<Athlete>;
  readonly athletesForTeamOffsetConnection: AthleteOffsetConnection;
  readonly bonAppetitCafes: ReadonlyArray<BonAppetitCafe>;
  readonly bonAppetitMenusPreview: BonAppetitMenusPreview;
  readonly brandWithUsdaManufacturerName: Maybe<Brand>;
  readonly brands: ReadonlyArray<Brand>;
  readonly bulkAssignMealPlanTemplateJob: BulkAssignMealPlanTemplateJob;
  readonly bulkAssignMealPlanTemplateJobOffsetConnection: BulkAssignMealPlanTemplateJobOffsetConnection;
  readonly bulkOrder: BulkOrder;
  readonly bulkOrderStatus: MenuOrderItemStatus;
  readonly bulkOrdersForMealMenu: ReadonlyArray<BulkOrder>;
  readonly bulkOrdersForTeam: ReadonlyArray<BulkOrder>;
  readonly bulkOrdersForUser: ReadonlyArray<BulkOrder>;
  readonly canBeStaff: Scalars['Boolean'];
  readonly cateringRestaurantMenuSearch: RestaurantMenuSearchCursorConnection;
  readonly cbordBusinessUnits: ReadonlyArray<CbordBusinessUnit>;
  readonly cbordServiceMenus: ReadonlyArray<CbordServiceMenu>;
  readonly clientCredentialOffsetConnection: ClientCredentialOffsetConnection;
  readonly compass_locations: ReadonlyArray<CompassLocation>;
  readonly compass_menuItems: ReadonlyArray<CompassMenuItem>;
  readonly compass_mrn_recipe: Maybe<CompassRecipe>;
  readonly compass_recipes: ReadonlyArray<CompassRecipe>;
  readonly convertedServing: Serving;
  readonly cuisineCursorConnection: CuisineCursorConnection;
  readonly cuisineOffsetConnection: CuisineOffsetConnection;
  readonly currentMenuItem: MenuItem;
  readonly daySummaries: ReadonlyArray<DaySummary>;
  readonly deliveryLocationCursorConnection: DeliveryLocationCursorConnection;
  readonly dietCursorConnection: DietCursorConnection;
  readonly dietOffsetConnection: DietOffsetConnection;
  readonly dietitiansICanMessageCursorConnection: MessageOptionCursorConnection;
  readonly digitalDisplaysMealMenu: DigitalDisplaysMealMenu;
  readonly digitalDisplaysMenuItems: ReadonlyArray<Maybe<DigitalDisplaysMenuItem>>;
  readonly diningStationTemplate: DiningStationTemplate;
  readonly diningStationTemplateCursorConnection: DiningStationTemplateCursorConnection;
  readonly diningStationTemplateOffsetConnection: DiningStationTemplateOffsetConnection;
  readonly exchangeSet: ExchangeSet;
  readonly exchangeSets: ReadonlyArray<ExchangeSet>;
  readonly food: Food;
  readonly foodGroup: FoodGroup;
  readonly foodGroups: ReadonlyArray<FoodGroup>;
  readonly foodGroupsById: ReadonlyArray<FoodGroup>;
  readonly foodItem: Maybe<GroceryListFoodItem>;
  readonly foodLogMeal: FoodLogMeal;
  readonly foodOffsetConnection: FoodOffsetConnection;
  readonly foodOrRecipe: FoodOrRecipe;
  readonly foods: ReadonlyArray<Food>;
  readonly foodsById: ReadonlyArray<Food>;
  readonly genericFood: GenericFood;
  /** @deprecated Use general foods query instead */
  readonly genericFoods: ReadonlyArray<GenericFood>;
  readonly getOpenAIImage: Maybe<Scalars['String']>;
  readonly goalCategories: ReadonlyArray<GoalCategory>;
  readonly goalConfiguration: GoalConfiguration;
  readonly goalConfigurationsForAthleteOffsetConnection: GoalConfigurationOffsetConnection;
  readonly goalConfigurationsForTeamOffsetConnection: GoalConfigurationOffsetConnection;
  readonly goalTrackerGoalOffsetConnection: GoalTrackerGoalOffsetConnection;
  readonly goalTrackerGoals: ReadonlyArray<GoalTrackerGoal>;
  readonly goalTypes: ReadonlyArray<GoalType>;
  readonly googleMapsApiKey: Scalars['String'];
  readonly gramUnit: Unit;
  readonly groceryList: Maybe<GroceryList>;
  readonly groceryListCategories: ReadonlyArray<GroceryListCategory>;
  readonly integrations: ReadonlyArray<IntegrationType>;
  readonly isEmailUnique: Scalars['Boolean'];
  readonly isPhoneNumberUnique: Scalars['Boolean'];
  readonly isTagNameUnique: IsTagNameUniquePayload;
  readonly locales: ReadonlyArray<Locale>;
  readonly loggingRestaurantMenuSearch: RestaurantMenuSearchCursorConnection;
  readonly me: Athlete;
  readonly mealEvents: ReadonlyArray<MealEvent>;
  readonly mealMenu: MealMenu;
  readonly mealMenuAttendees: MealMenuAttendeePayload;
  readonly mealMenuPlate: MealMenuPlate;
  readonly mealMenuPlateOrder: MealMenuPlateOrder;
  readonly mealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly mealMenuPlateOrdersForUser: Maybe<ReadonlyArray<MealMenuPlateOrder>>;
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly mealMenusCursorConnection: MealMenusCursorConnection;
  readonly mealMenusInDateRange: ReadonlyArray<MealMenu>;
  readonly mealMenusInRange: ReadonlyArray<MealMenu>;
  readonly mealMenusWithCheckInInRange: ReadonlyArray<MealMenusWithCheckinPayload>;
  readonly mealOptionCursorConnectionForMealTemplate: MealOptionCursorConnection;
  readonly mealOptionSuggestionCursorConnection: ServingAmountGroupCursorConnection;
  readonly mealOptionsForMealPlansCursorConnection: MealOptionCursorConnection;
  readonly mealOptionsForMealPlansCursorConnectionWithRandomSeededSort: MealOptionCursorConnection;
  readonly mealOptionsForMealPlansPreview: MealOptionCursorConnection;
  readonly mealOptionsForMealPlansPreviewWithRandomSeededSort: MealOptionCursorConnection;
  readonly mealPlan: MealPlan;
  readonly mealPlanForCopyCursorConnection: MealPlanCursorConnection;
  readonly mealPlanOffsetConnection: MealPlanOffsetConnection;
  readonly mealPlanTemplate: MealPlanTemplate;
  readonly mealPlanTemplateOffsetConnection: MealPlanTemplateOffsetConnection;
  readonly menuItem: MenuItem;
  readonly menuItemCursorConnection: MenuItemCursorConnection;
  readonly menuItemOffsetConnection: MenuItemOffsetConnection;
  readonly menuItemRatingOffsetConnection: MenuItemRatingOffsetConnection;
  readonly menuItems: ReadonlyArray<MenuItem>;
  readonly menuOrder: MenuOrder;
  readonly menuOrderCursorConnectionForItems: MenuOrderCursorConnection;
  readonly menuOrderCursorConnectionForItemsForAthlete: MenuOrderCursorConnection;
  readonly menuOrderRateLimitRemainders: ReadonlyArray<MenuOrderRateLimitRemainder>;
  readonly menuOrderSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestion>>;
  readonly menuOrders: ReadonlyArray<MenuOrder>;
  readonly mobileUpdateForVersion: Maybe<MobileUpdateType>;
  readonly myCurrentGroceryList: Maybe<GroceryList>;
  readonly myCurrentPrepPlan: Maybe<PrepPlan>;
  readonly myFeatureFlags: FeatureFlags;
  readonly myGoalTrackerGoalsForDate: ReadonlyArray<GoalTrackerGoal>;
  readonly myOrg: Org;
  readonly myOrgForReporting: Maybe<OrgForReporting>;
  readonly myPastPrepPlanCursorConnection: PrepPlanCursorConnection;
  readonly myRecipeCursorConnection: MyRecipeCursorConnection;
  readonly myRecipePreview: MyRecipeCursorConnection;
  readonly myScoringSystem: Maybe<ScoringSystem>;
  readonly myStreamChatToken: Scalars['String'];
  readonly myTimelineMealsInRange: ReadonlyArray<TimelineMeal>;
  readonly myUnfinishedPrepPlan: Maybe<PrepPlan>;
  readonly myUser: User;
  readonly namedTag: NamedTag;
  readonly namedTagCursorConnection: NamedTagCursorConnection;
  readonly namedTagOffsetConnection: NamedTagOffsetConnection;
  readonly namedTagSearch: ReadonlyArray<NamedTag>;
  readonly nixAppId: Scalars['String'];
  readonly nixAppKey: Scalars['String'];
  readonly notSharedRecipeCursorConnection: NotSharedRecipeCursorConnection;
  readonly noteTypes: ReadonlyArray<NoteType>;
  readonly notesForTeam: ReadonlyArray<Note>;
  readonly notification: Maybe<Notification>;
  readonly notificationCount: Scalars['Int'];
  readonly notificationCursorConnection: NotificationCursorConnection;
  /** @deprecated Field no longer supported */
  readonly nutrientAmountsForFoods: ReadonlyArray<NutrientAmount>;
  readonly nutrients: ReadonlyArray<Nutrient>;
  readonly nutritionixBrandedInstantSearch: NutritionixBrandedInstantSearchPayload;
  readonly nutritionixCommonInstantSearch: NutritionixCommonInstantSearchPayload;
  readonly nutritionixRestaurantLocationSearch: NutritionixRestaurantLocationSearchPayload;
  readonly nutritionixV1RestaurantSearch: NutritionixV1RestaurantSearchPayload;
  readonly org: Org;
  readonly orgFoodGroups: ReadonlyArray<FoodGroup>;
  readonly orgFoodGroupsOffsetConnection: FoodGroupsOffsetConnection;
  readonly orgFoodOffsetConnection: FoodOffsetConnection;
  readonly orgFoods: ReadonlyArray<Food>;
  readonly orgGroup: OrgGroup;
  readonly orgGroupFoodOffsetConnection: FoodOffsetConnection;
  readonly orgGroupFoodOwnerCursorConnection: UserCursorConnection;
  readonly orgGroupFoods: ReadonlyArray<Food>;
  readonly orgGroupMealPlanTemplateOffsetConnection: MealPlanTemplateOffsetConnection;
  readonly orgGroupMealPlanTemplateOwnerCursorConnection: UserCursorConnection;
  readonly orgGroupRecipeOffsetConnection: RecipeOffsetConnection;
  readonly orgGroupRecipeOwnerCursorConnection: UserCursorConnection;
  readonly orgGroupRecipes: ReadonlyArray<Recipe>;
  readonly orgGroups: ReadonlyArray<OrgGroup>;
  readonly orgs: ReadonlyArray<Org>;
  readonly ounceUnit: Unit;
  readonly paymentMethodCursorConnection: PaymentMethodCursorConnection;
  readonly paymentMethodOffsetConnection: PaymentMethodOffsetConnection;
  readonly ping: Scalars['Boolean'];
  readonly plannedMenu: PlannedMenu;
  readonly plannedMenuMealsForWeek: ReadonlyArray<PlannedMenuMeal>;
  readonly plannedMenuOffsetConnection: PlannedMenuOffsetConnection;
  readonly plannedMenuWeeks: ReadonlyArray<PlannedMenuWeek>;
  readonly positionBySportCursorConnection: PositionBySportCursorConnection;
  readonly prepPlan: PrepPlan;
  readonly prepPlanMeal: PrepPlanMeal;
  readonly prepPlanMealAssignments: ReadonlyArray<PrepPlanMealAssignment>;
  readonly prepPlanMealsForTimelineMeal: PrepPlanMealsForTimelineMealPayload;
  readonly profileUsers: ReadonlyArray<User>;
  readonly recentMealCursorConnectionForMealTypes: RecentTimelineMealCursorConnection;
  readonly recentMealsForMealTypesPreview: RecentTimelineMealCursorConnection;
  readonly recipe: Recipe;
  readonly recipeCursorConnection: RecipeCursorConnection;
  readonly recipeOffsetConnection: RecipeOffsetConnection;
  readonly recipes: ReadonlyArray<Recipe>;
  readonly recipesById: ReadonlyArray<Recipe>;
  readonly resourceSharingOrgGroups: ReadonlyArray<OrgGroup>;
  readonly restauranMenuLinkSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestion>>;
  readonly restaurant: Restaurant;
  readonly restaurantFoodCursorConnection: BrandedFoodCursorConnection;
  readonly restaurantFoodOffsetConnection: BrandedFoodOffsetConnection;
  readonly restaurantFoods: ReadonlyArray<BrandedFood>;
  readonly restaurantGeoLocationSearch: RestaurantGeoLocationSearchCursorConnection;
  readonly restaurantLocationSearch: RestaurantLocationSearchCursorConnection;
  readonly restaurantMenu: RestaurantMenu;
  readonly restaurantMenuLink: RestaurantMenuLink;
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
  readonly restaurantMenuLog: RestaurantMenuLog;
  readonly restaurantMenuSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestion>>;
  readonly restaurantTypeCursorConnection: RestaurantTypeCursorConnection;
  readonly restaurantTypeOffsetConnection: RestaurantTypeOffsetConnection;
  readonly schedules: ReadonlyArray<Schedule>;
  readonly schedulesForTeam: ReadonlyArray<Schedule>;
  /** @deprecated Field no longer supported */
  readonly schemaVersion: Scalars['String'];
  readonly scoringSystemOffsetConnection: ScoringSystemOffsetConnection;
  readonly scoringSystems: ReadonlyArray<ScoringSystem>;
  readonly selectedAthletesCursorConnection: AthleteCursorConnection;
  /** @deprecated Field no longer supported */
  readonly servingAmountGroupCursorConnectionForFoodLogMeal: ServingAmountGroupCursorConnection;
  readonly servingAmountGroupCursorConnectionForMealType: ServingAmountGroupCursorConnection;
  readonly servings: ReadonlyArray<Serving>;
  readonly sharedExchangeSetOffsetConnection: ExchangeSetOffsetConnection;
  readonly sharedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly sharedFoodGroupsCursorConnection: FoodGroupsCursorConnection;
  readonly sharedFoodGroupsOffsetConnection: FoodGroupsOffsetConnection;
  readonly sharedMealPlanTemplate: MealPlanTemplate;
  readonly sharedMealPlanTemplateOffsetConnection: MealPlanTemplateOffsetConnection;
  readonly sharedRecipeCursorConnection: SharedRecipeCursorConnection;
  readonly sharedRecipePreview: SharedRecipeCursorConnection;
  readonly slackFoodRequestsUrl: Scalars['String'];
  readonly sodexoMealTimesByDay: SodexoMealTimesByDay;
  readonly sodexoMenuSources: ReadonlyArray<SodexoMenuSource>;
  readonly sport: Sport;
  readonly sports: ReadonlyArray<Sport>;
  readonly staffUserSearchOffsetConnection: UserOffsetConnection;
  readonly tag: Tag;
  readonly team: Team;
  readonly teamCursorConnection: TeamCursorConnection;
  readonly teamGoalConfiguration: ReadonlyArray<GoalConfiguration>;
  readonly teamLabelCursorConnection: TeamLabelCursorConnection;
  readonly teamSearch: ReadonlyArray<Team>;
  readonly teams: ReadonlyArray<Team>;
  readonly teamsById: ReadonlyArray<Team>;
  readonly teamworksCalendarForAthleteInRange: TeamworksCalendarPayload;
  readonly teamworksDisabledProfileIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly teamworksOrg: TeamworksOrg;
  readonly teamworksOrgCursorConnection: TeamworksOrgCursorConnection;
  readonly teamworksOrgGroups: ReadonlyArray<TeamworksGroup>;
  readonly teamworksPositionLinks: ReadonlyArray<TeamworksPositionLink>;
  readonly teamworksProfileCursorConnection: TeamworksProfileCursorConnection;
  /** @deprecated Use teamworksScheduleForAthleteInRangeNew instead due to new return type */
  readonly teamworksScheduleForAthleteInRange: ReadonlyArray<ExternalEvent>;
  readonly teamworksSportMappings: ReadonlyArray<TeamworksSportMapping>;
  readonly territories: ReadonlyArray<Territory>;
  readonly territoryOffsetConnection: TerritoryOffsetConnection;
  readonly timelineMeal: TimelineMeal;
  readonly unit: Unit;
  readonly unitConversions: ReadonlyArray<UnitConversion>;
  readonly units: ReadonlyArray<Unit>;
  readonly unsubmittedAgreementFormForUser: Maybe<AgreementForm>;
  /** @deprecated Field no longer supported */
  readonly upcomingMealMenus: ReadonlyArray<MealMenu>;
  readonly usdaFoodDetail: UsdaFoodDetail;
  readonly usdaFoods: ReadonlyArray<UsdaFood>;
  readonly userFoodCursorConnection: FoodCursorConnection;
  readonly userFoods: ReadonlyArray<Food>;
  readonly usersICanMessageCursorConnection: MessageOptionCursorConnection;
  readonly webVersion: Scalars['String'];
};


export type QueryActiveAthleteTransferByAthleteIdArgs = {
  athleteId: Scalars['String'];
};


export type QueryActiveAthleteTransferOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
};


export type QueryAgreementFormOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAllDeliveryLocationsArgs = {
  query: Maybe<Scalars['String']>;
  teamLabelId: Maybe<Scalars['ID']>;
  input: OffsetPaginationInput;
};


export type QueryAllMealPlanTemplatesForDietitianCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};


export type QueryAllRestaurantLocationsArgs = {
  restaurantId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryAllRestaurantsArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryAllTeamLabelsArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryAthleteArgs = {
  id: Scalars['ID'];
};


export type QueryAthleteGoalsInRangeArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryAthleteSearchArgs = {
  query: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryAthleteSearchCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAthleteSummariesInRangeArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryAthletesForBulkOrderCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']>;
  teamId: Scalars['ID'];
  excludeAthleteIds: ReadonlyArray<Scalars['ID']>;
  mealMenuId: Scalars['ID'];
};


export type QueryAthletesForCustomTagCreationArgs = {
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']>;
  teamIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryAthletesForCustomTagCreationCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']>;
  teamIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryAthletesForMealMenusArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryAthletesForMealPlanTemplateAssignmentCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']>;
  teamIds: ReadonlyArray<Scalars['ID']>;
  weightTargetTypeId: Maybe<Scalars['ID']>;
  anthropometryFilter: Maybe<AthleteAnthropometryFilter>;
};


export type QueryAthletesForPendingAdvancedSelectionArgs = {
  input: AdvancedSelectionInput;
};


export type QueryAthletesForTagArgs = {
  tagId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForTagCursorConnectionArgs = {
  tagId: Scalars['ID'];
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForTagOffsetConnectionArgs = {
  tagId: Scalars['ID'];
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForTeamArgs = {
  teamId: Scalars['ID'];
  tagIds: ReadonlyArray<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
};


export type QueryAthletesForTeamOffsetConnectionArgs = {
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  tagIds: ReadonlyArray<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
};


export type QueryBonAppetitMenusPreviewArgs = {
  cafeId: Scalars['String'];
  date: Scalars['Date'];
};


export type QueryBrandWithUsdaManufacturerNameArgs = {
  usdaManufacturerName: Scalars['String'];
};


export type QueryBrandsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryBulkAssignMealPlanTemplateJobArgs = {
  id: Scalars['ID'];
};


export type QueryBulkAssignMealPlanTemplateJobOffsetConnectionArgs = {
  input: OffsetPaginationInput;
};


export type QueryBulkOrderArgs = {
  bulkOrderId: Scalars['ID'];
};


export type QueryBulkOrderStatusArgs = {
  bulkOrderId: Scalars['ID'];
};


export type QueryBulkOrdersForMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryBulkOrdersForTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryBulkOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryCateringRestaurantMenuSearchArgs = {
  query: Maybe<Scalars['String']>;
  restaurantTypeIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  paymentMethodIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  cuisineIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  dietIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryCbordServiceMenusArgs = {
  serviceUnitOid: Scalars['Int'];
  start: Scalars['Date'];
  end: Scalars['Date'];
  facilityOid: Maybe<Scalars['Int']>;
};


export type QueryClientCredentialOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
};


export type QueryCompassMenuItemsArgs = {
  locationId: Scalars['Int'];
  startDate: Scalars['Date'];
  days: Scalars['Int'];
};


export type QueryCompassMrnRecipeArgs = {
  query: Scalars['String'];
};


export type QueryCompassRecipesArgs = {
  query: Scalars['String'];
};


export type QueryConvertedServingArgs = {
  input: ConvertedServingInput;
};


export type QueryCuisineCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryCuisineOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryCurrentMenuItemArgs = {
  menuItemId: Scalars['ID'];
};


export type QueryDaySummariesArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryDeliveryLocationCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  teamLabelId: Maybe<Scalars['ID']>;
  input: CursorPaginationInput;
};


export type QueryDietCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryDietOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryDietitiansICanMessageCursorConnectionArgs = {
  query: Scalars['String'];
  input: CursorPaginationInput;
};


export type QueryDigitalDisplaysMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryDigitalDisplaysMenuItemsArgs = {
  menuItemIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryDiningStationTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryDiningStationTemplateCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryDiningStationTemplateOffsetConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryExchangeSetArgs = {
  id: Scalars['ID'];
};


export type QueryFoodArgs = {
  id: Scalars['ID'];
};


export type QueryFoodGroupArgs = {
  id: Scalars['ID'];
};


export type QueryFoodGroupsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryFoodGroupsByIdArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryFoodItemArgs = {
  id: Scalars['ID'];
};


export type QueryFoodLogMealArgs = {
  id: Scalars['ID'];
};


export type QueryFoodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  localeCodes: Maybe<ReadonlyArray<Scalars['String']>>;
  dataSources: Maybe<ReadonlyArray<FoodDataSource>>;
};


export type QueryFoodOrRecipeArgs = {
  id: Scalars['ID'];
};


export type QueryFoodsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  localeCodes: Maybe<ReadonlyArray<Scalars['String']>>;
  dataSources: Maybe<ReadonlyArray<FoodDataSource>>;
};


export type QueryFoodsByIdArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryGenericFoodArgs = {
  id: Scalars['ID'];
};


export type QueryGenericFoodsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryGetOpenAiImageArgs = {
  servingAmount: Scalars['Float'];
  servingUnit: Scalars['String'];
  foodName: Scalars['String'];
};


export type QueryGoalConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryGoalConfigurationsForAthleteOffsetConnectionArgs = {
  athleteId: Scalars['ID'];
  input: OffsetPaginationInput;
  current: Scalars['Boolean'];
};


export type QueryGoalConfigurationsForTeamOffsetConnectionArgs = {
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  tagIds: ReadonlyArray<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  categoryId: Maybe<Scalars['ID']>;
};


export type QueryGoalTrackerGoalOffsetConnectionArgs = {
  goalConfigurationId: Scalars['ID'];
  input: OffsetPaginationInput;
};


export type QueryGoalTrackerGoalsArgs = {
  goalConfigurationId: Scalars['ID'];
};


export type QueryGroceryListArgs = {
  id: Scalars['ID'];
};


export type QueryIsEmailUniqueArgs = {
  email: Scalars['String'];
};


export type QueryIsPhoneNumberUniqueArgs = {
  phoneNumber: Scalars['String'];
};


export type QueryIsTagNameUniqueArgs = {
  name: Scalars['String'];
};


export type QueryLoggingRestaurantMenuSearchArgs = {
  query: Maybe<Scalars['String']>;
  restaurantTypeIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  paymentMethodIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  cuisineIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  dietIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryMealEventsArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryMealMenuAttendeesArgs = {
  mealMenuId: Scalars['ID'];
  tagIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryMealMenuPlateArgs = {
  id: Scalars['ID'];
};


export type QueryMealMenuPlateOrderArgs = {
  id: Scalars['ID'];
};


export type QueryMealMenuPlateOrdersArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryMealMenuPlateOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryMealMenusArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryMealMenusCursorConnectionArgs = {
  anchorDate: Scalars['DateTime'];
  input: CursorPaginationInput;
};


export type QueryMealMenusInDateRangeArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type QueryMealMenusInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryMealMenusWithCheckInInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  selectedTeamIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryMealOptionCursorConnectionForMealTemplateArgs = {
  mealTemplateId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
};


export type QueryMealOptionSuggestionCursorConnectionArgs = {
  macros: MacrosInput;
  mealType: MealType;
  maxErrorPercent: Scalars['Int'];
  minErrorPercent: Maybe<Scalars['Int']>;
  pagination: Maybe<CursorPaginationInput>;
  avoidFoodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  avoidFoodIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  localeCode: Maybe<Scalars['String']>;
};


export type QueryMealOptionsForMealPlansCursorConnectionArgs = {
  mealPlanIds: ReadonlyArray<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
  input: Maybe<CursorPaginationInput>;
};


export type QueryMealOptionsForMealPlansCursorConnectionWithRandomSeededSortArgs = {
  mealPlanIds: ReadonlyArray<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
  input: Maybe<CursorPaginationInput>;
  sortSeedValue: Scalars['Int'];
};


export type QueryMealOptionsForMealPlansPreviewArgs = {
  mealPlanIds: ReadonlyArray<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
};


export type QueryMealOptionsForMealPlansPreviewWithRandomSeededSortArgs = {
  mealPlanIds: ReadonlyArray<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
  sortSeedValue: Scalars['Int'];
};


export type QueryMealPlanArgs = {
  id: Scalars['ID'];
};


export type QueryMealPlanForCopyCursorConnectionArgs = {
  input: CursorPaginationInput;
  athleteName: Maybe<Scalars['String']>;
  mealPlanName: Maybe<Scalars['String']>;
};


export type QueryMealPlanOffsetConnectionArgs = {
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryMealPlanTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryMealPlanTemplateOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};


export type QueryMenuItemArgs = {
  menuItemId: Scalars['ID'];
};


export type QueryMenuItemCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryMenuItemOffsetConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryMenuItemRatingOffsetConnectionArgs = {
  menuItemHistoryId: Scalars['ID'];
  currentOnly: Scalars['Boolean'];
  input: OffsetPaginationInput;
};


export type QueryMenuItemsArgs = {
  menuItemIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryMenuOrderArgs = {
  id: Scalars['ID'];
};


export type QueryMenuOrderCursorConnectionForItemsArgs = {
  menuItemIds: ReadonlyArray<Scalars['ID']>;
  input: Maybe<CursorPaginationInput>;
};


export type QueryMenuOrderCursorConnectionForItemsForAthleteArgs = {
  menuItemIds: ReadonlyArray<Scalars['ID']>;
  athleteId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
};


export type QueryMenuOrderRateLimitRemaindersArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryMenuOrderSuggestionsArgs = {
  mealMenuId: Scalars['ID'];
  targetMacros: MacrosInput;
  avoidFoodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryMenuOrdersArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryMobileUpdateForVersionArgs = {
  version: Scalars['Int'];
};


export type QueryMyCurrentGroceryListArgs = {
  date: Scalars['Date'];
};


export type QueryMyCurrentPrepPlanArgs = {
  date: Scalars['Date'];
};


export type QueryMyGoalTrackerGoalsForDateArgs = {
  date: Scalars['String'];
};


export type QueryMyPastPrepPlanCursorConnectionArgs = {
  input: CursorPaginationInput;
  date: Scalars['Date'];
};


export type QueryMyRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QueryMyRecipePreviewArgs = {
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QueryMyTimelineMealsInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryNamedTagArgs = {
  id: Scalars['ID'];
};


export type QueryNamedTagCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryNamedTagOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryNamedTagSearchArgs = {
  query: Maybe<Scalars['String']>;
};


export type QueryNotSharedRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
};


export type QueryNotesForTeamArgs = {
  teamId: Scalars['ID'];
  noteTypeId: Maybe<Scalars['ID']>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
};


export type QueryNutrientAmountsForFoodsArgs = {
  foodIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryNutritionixBrandedInstantSearchArgs = {
  query: Scalars['String'];
  region: Scalars['Int'];
};


export type QueryNutritionixCommonInstantSearchArgs = {
  query: Scalars['String'];
  localeCode: Scalars['String'];
};


export type QueryNutritionixRestaurantLocationSearchArgs = {
  input: NutritionixRestaurantLocationSearchInput;
};


export type QueryNutritionixV1RestaurantSearchArgs = {
  input: NutritionixV1RestaurantSearchInput;
};


export type QueryOrgArgs = {
  id: Scalars['ID'];
};


export type QueryOrgFoodGroupsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryOrgFoodGroupsOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  sortOrder: SortOrder;
};


export type QueryOrgFoodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryOrgFoodsArgs = {
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  isRecipeIngredientOnly: Maybe<Scalars['Boolean']>;
};


export type QueryOrgGroupArgs = {
  id: Scalars['ID'];
};


export type QueryOrgGroupFoodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  ownerIds: Maybe<ReadonlyArray<Scalars['String']>>;
};


export type QueryOrgGroupFoodOwnerCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
};


export type QueryOrgGroupFoodsArgs = {
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  isRecipeIngredientOnly: Maybe<Scalars['Boolean']>;
};


export type QueryOrgGroupMealPlanTemplateOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  ownerIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  sortAscending: Scalars['Boolean'];
};


export type QueryOrgGroupMealPlanTemplateOwnerCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
};


export type QueryOrgGroupRecipeOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  sort: Maybe<RecipeSort>;
  query: Maybe<Scalars['String']>;
  createdById: Maybe<Scalars['ID']>;
  ownerIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  orgId: Maybe<Scalars['ID']>;
};


export type QueryOrgGroupRecipeOwnerCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
};


export type QueryOrgGroupRecipesArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  createdById: Maybe<Scalars['ID']>;
};


export type QueryPaymentMethodCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPaymentMethodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPlannedMenuArgs = {
  id: Scalars['ID'];
};


export type QueryPlannedMenuMealsForWeekArgs = {
  plannedMenuWeekId: Scalars['ID'];
};


export type QueryPlannedMenuOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPlannedMenuWeeksArgs = {
  plannedMenuWeekIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryPositionBySportCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPrepPlanArgs = {
  id: Scalars['ID'];
};


export type QueryPrepPlanMealArgs = {
  id: Scalars['ID'];
};


export type QueryPrepPlanMealAssignmentsArgs = {
  prepPlanId: Scalars['ID'];
};


export type QueryPrepPlanMealsForTimelineMealArgs = {
  input: PrepPlanMealsForTimelineMealInput;
};


export type QueryProfileUsersArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryRecentMealCursorConnectionForMealTypesArgs = {
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
  input: Maybe<CursorPaginationInput>;
};


export type QueryRecentMealsForMealTypesPreviewArgs = {
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
};


export type QueryRecipeArgs = {
  id: Scalars['ID'];
};


export type QueryRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  createdById: Maybe<Scalars['ID']>;
};


export type QueryRecipeOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  sort: Maybe<RecipeSort>;
  query: Maybe<Scalars['String']>;
  createdById: Maybe<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  orgId: Maybe<Scalars['ID']>;
};


export type QueryRecipesArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  createdById: Maybe<Scalars['ID']>;
};


export type QueryRecipesByIdArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryRestauranMenuLinkSuggestionsArgs = {
  restaurantMenuLinkId: Scalars['ID'];
  targetMacros: MacrosInput;
  avoidFoodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryRestaurantArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantFoodCursorConnectionArgs = {
  restaurantId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
};


export type QueryRestaurantFoodOffsetConnectionArgs = {
  restaurantId: Scalars['ID'];
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryRestaurantFoodsArgs = {
  restaurantId: Scalars['ID'];
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryRestaurantGeoLocationSearchArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryRestaurantLocationSearchArgs = {
  deliveryLocationId: Scalars['ID'];
  restaurantIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  restaurantTypeIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  paymentMethodIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  cuisineIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  dietIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  query: Maybe<Scalars['String']>;
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryRestaurantMenuArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLinkArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLinkOrderArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLinkPlateOrderArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLogArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuSuggestionsArgs = {
  restaurantMenuId: Scalars['ID'];
  targetMacros: MacrosInput;
  avoidFoodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryRestaurantTypeCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryRestaurantTypeOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QuerySchedulesForTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryScoringSystemOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
};


export type QuerySelectedAthletesCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  athleteIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryServingAmountGroupCursorConnectionForFoodLogMealArgs = {
  foodLogMealId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
};


export type QueryServingAmountGroupCursorConnectionForMealTypeArgs = {
  mealType: MealType;
  input: Maybe<CursorPaginationInput>;
  athleteId: Maybe<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
};


export type QueryServingsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QuerySharedExchangeSetOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QuerySharedFoodGroupsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QuerySharedFoodGroupsCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Maybe<Scalars['String']>;
};


export type QuerySharedFoodGroupsOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  sortOrder: SortOrder;
};


export type QuerySharedMealPlanTemplateArgs = {
  id: Scalars['ID'];
};


export type QuerySharedMealPlanTemplateOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};


export type QuerySharedRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QuerySharedRecipePreviewArgs = {
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QuerySodexoMealTimesByDayArgs = {
  locationId: Scalars['String'];
  menuSourceKey: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QuerySportArgs = {
  id: Scalars['ID'];
};


export type QueryStaffUserSearchOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchParams: Maybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryTeamGoalConfigurationArgs = {
  teamId: Scalars['ID'];
};


export type QueryTeamLabelCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryTeamSearchArgs = {
  query: Maybe<Scalars['String']>;
};


export type QueryTeamsByIdArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryTeamworksCalendarForAthleteInRangeArgs = {
  athleteId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryTeamworksDisabledProfileIdsArgs = {
  teamworksOrgId: Scalars['Int'];
};


export type QueryTeamworksOrgArgs = {
  teamworksId: Scalars['Int'];
};


export type QueryTeamworksOrgCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryTeamworksOrgGroupsArgs = {
  teamworksOrgId: Scalars['Int'];
  type: Maybe<TeamworksGroupType>;
  teamId: Maybe<Scalars['Int']>;
};


export type QueryTeamworksProfileCursorConnectionArgs = {
  teamworksOrgId: Scalars['Int'];
  pagination: CursorPaginationInput;
};


export type QueryTeamworksScheduleForAthleteInRangeArgs = {
  athleteId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryTeamworksSportMappingsArgs = {
  query: Maybe<Scalars['String']>;
};


export type QueryTerritoryOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryTimelineMealArgs = {
  id: Scalars['ID'];
};


export type QueryUnitArgs = {
  id: Scalars['ID'];
};


export type QueryUnsubmittedAgreementFormForUserArgs = {
  orgId: Scalars['ID'];
};


export type QueryUpcomingMealMenusArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryUsdaFoodDetailArgs = {
  usdaFdcId: Scalars['ID'];
  apiKey: Scalars['String'];
};


export type QueryUsdaFoodsArgs = {
  searchTerm: Scalars['String'];
  apiKey: Scalars['String'];
  brandOwner: Scalars['String'];
  includedDataTypes: Scalars['String'];
};


export type QueryUserFoodCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
};


export type QueryUserFoodsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryUsersICanMessageCursorConnectionArgs = {
  role: Role;
  teamId: Maybe<Scalars['String']>;
  query: Scalars['String'];
  input: CursorPaginationInput;
};

export type RmrMethod =
  | 'cunningham'
  | 'mifflin'
  | 'harrisBenedict'
  | 'average'
  | '%future added value';


export type Rating = {
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly value: RatingValue;
  readonly review: Maybe<Scalars['String']>;
};

export type RatingCounts = {
  readonly one: Scalars['Int'];
  readonly two: Scalars['Int'];
  readonly three: Scalars['Int'];
  readonly four: Scalars['Int'];
  readonly five: Scalars['Int'];
};

export type RatingValue =
  | 'one'
  | 'two'
  | 'three'
  | 'four'
  | 'five'
  | '%future added value';

export type RecentTimelineMeal = {
  readonly type: MealType;
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type RecentTimelineMealCursorConnection = {
  readonly edges: ReadonlyArray<RecentTimelineMeal>;
  readonly pageInfo: CursorPageInfo;
};

export type RecieveAthleteTransferInput = {
  readonly id: Scalars['ID'];
  readonly teamId: Scalars['ID'];
};

export type RecieveAthleteTransferPayload = {
  readonly athleteTransfer: AthleteTransfer;
};

export type Recipe = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly prepTimeInMinutes: Maybe<Scalars['Int']>;
  readonly cookTimeInMinutes: Maybe<Scalars['Int']>;
  readonly steps: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly note: Maybe<Scalars['String']>;
  readonly cho: Maybe<Scalars['Float']>;
  readonly pro: Maybe<Scalars['Float']>;
  readonly fat: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly isCurrent: Scalars['Boolean'];
  readonly hasFullAccess: Scalars['Boolean'];
  readonly org: Maybe<Org>;
  readonly orgGroup: Maybe<OrgGroup>;
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly ingredients: ReadonlyArray<ServingAmount>;
  readonly servings: ReadonlyArray<Serving>;
  readonly images: ReadonlyArray<Image>;
  readonly isAiGenerated: Scalars['Boolean'];
  readonly isAiTranscribed: Scalars['Boolean'];
  readonly descendantFoods: ReadonlyArray<Food>;
  readonly descendantRecipes: ReadonlyArray<Recipe>;
  readonly createdBy: User;
  readonly owner: User;
  readonly history: RecipeHistory;
  readonly isShared: Scalars['Boolean'];
  readonly score: Maybe<Score>;
  readonly foodCategory: Maybe<RecipeFoodCategory>;
  readonly copiedCount: Scalars['Int'];
};

export type RecipeCursorConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: CursorPageInfo;
};

export type RecipeFoodCategory = {
  readonly category: FoodCategoryType;
};

export type RecipeFormInput = {
  readonly name: Scalars['String'];
  readonly ingredients: ReadonlyArray<CreateServingAmountInput>;
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly steps: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly note: Maybe<Scalars['String']>;
  readonly macros: Maybe<MacrosInput>;
};

export type RecipeFullInput = {
  readonly name: Scalars['String'];
  readonly ingredients: ReadonlyArray<CreateServingAmountInput>;
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly prepTimeInMinutes: Maybe<Scalars['Int']>;
  readonly cookTimeInMinutes: Maybe<Scalars['Int']>;
  readonly steps: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly note: Maybe<Scalars['String']>;
  readonly macros: Maybe<MacrosInput>;
  readonly images: ReadonlyArray<ImageInput>;
  readonly isShared: Scalars['Boolean'];
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
  readonly isAiGenerated: Maybe<Scalars['Boolean']>;
  readonly isAiTranscribed: Maybe<Scalars['Boolean']>;
};

export type RecipeHistory = {
  readonly id: Scalars['ID'];
  readonly current: Recipe;
  readonly all: ReadonlyArray<Recipe>;
};

export type RecipeOffsetConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: OffsetPageInfo;
};

export type RecipeServingFormInput = {
  readonly units: Scalars['String'];
  readonly perRecipeYield: Scalars['Float'];
  readonly isDefault: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
};

export type RecipeServingFullInput = {
  readonly units: ServingUnitsInput;
  readonly perRecipeYield: Scalars['Float'];
  readonly isDefault: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
};

export type RecipeServingSimpleInput = {
  readonly units: ServingUnitsInput;
  readonly perRecipeYield: Scalars['Float'];
};

export type RecipeSimpleInput = {
  readonly name: Scalars['String'];
  readonly ingredients: ReadonlyArray<CreateServingAmountInput>;
};

export type RecipeSort = {
  readonly key: RecipeSortKey;
  readonly direction: RecipeSortDirection;
};

export type RecipeSortDirection =
  | 'ASC'
  | 'DESC'
  | '%future added value';

export type RecipeSortKey =
  | 'createdAt'
  | '%future added value';

export type ReclaimAthleteTransferInput = {
  readonly id: Scalars['ID'];
};

export type ReclaimAthleteTransferPayload = {
  readonly athleteTransfer: AthleteTransfer;
};

export type RecordMenuOrderSuggestionDecisionInput = {
  readonly userId: Scalars['ID'];
  readonly mealMenuId: Maybe<Scalars['ID']>;
  readonly restaurantMenuLinkId: Maybe<Scalars['ID']>;
  readonly restaurantMenuId: Maybe<Scalars['ID']>;
  readonly targetMacros: MacrosInput;
  readonly suggestion: MenuOrderSuggestionInput;
};

export type RecordMenuOrderSuggestionsViewInput = {
  readonly userId: Scalars['ID'];
  readonly mealMenuId: Maybe<Scalars['ID']>;
  readonly restaurantMenuLinkId: Maybe<Scalars['ID']>;
  readonly restaurantMenuId: Maybe<Scalars['ID']>;
  readonly targetMacros: MacrosInput;
  readonly suggestions: ReadonlyArray<MenuOrderSuggestionInput>;
};

export type RefreshClientCredentialSecretInput = {
  readonly id: Scalars['ID'];
};

export type RefreshClientCredentialSecretPayload = {
  readonly clientCredential: ClientCredential;
  readonly clientSecret: Scalars['String'];
};

export type RefreshTeamworksSyncStatusInput = {
  readonly orgId: Scalars['ID'];
};

export type RefreshTeamworksSyncStatusPayload = {
  readonly org: Org;
};

export type RefundPolicyType =
  | 'full'
  | 'labor'
  | '%future added value';

export type RemoveActivityInput = {
  readonly activityId: Scalars['ID'];
};

export type RemoveAthleteDislikedFoodGroupPayload = {
  readonly athlete: Athlete;
};

export type RemoveAthleteDislikedFoodPayload = {
  readonly athlete: Athlete;
};

export type RemoveAthleteLikedFoodGroupPayload = {
  readonly athlete: Athlete;
};

export type RemoveAthleteLikedFoodPayload = {
  readonly athlete: Athlete;
};

export type RemoveBulkOrderInput = {
  readonly bulkOrderId: Scalars['ID'];
};

export type RemoveBulkOrderPayload = {
  readonly bulkOrderId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
  readonly deliveryLocation: Scalars['String'];
  readonly mealMenuId: Scalars['ID'];
  readonly affectedMealMenuPlateOrderIds: ReadonlyArray<Scalars['ID']>;
};

export type RemoveFoodLogActivityInput = {
  readonly foodLogActivityId: Scalars['ID'];
};

export type RemoveFoodLogActivityPayload = {
  readonly foodLogActivityId: Scalars['ID'];
};

export type RemoveFoodLogActivityTemplateInput = {
  readonly foodLogActivityId: Scalars['ID'];
};

export type RemoveFoodLogActivityTemplatePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type RemoveFoodLogMealImageInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly imageId: Scalars['ID'];
};

export type RemoveFoodLogMealImagePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type RemoveFoodLogMealInput = {
  readonly foodLogMealId: Scalars['ID'];
};

export type RemoveFoodLogMealPayload = {
  readonly foodLogMealId: Scalars['ID'];
};

export type RemoveFoodLogMealTemplateInput = {
  readonly foodLogMealId: Scalars['ID'];
};

export type RemoveFoodLogMealTemplatePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type RemoveMealInput = {
  readonly mealId: Scalars['ID'];
};

export type RemoveMealMenuDiningStationInput = {
  readonly mealMenuDiningStationId: Scalars['ID'];
};

export type RemoveMealMenuLogItemInput = {
  readonly mealMenuLogItemId: Scalars['ID'];
};

export type RemoveMealMenuLogItemPayload = {
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
  readonly mealMenuLogItemId: Scalars['ID'];
};

export type RemoveMealOptionInput = {
  readonly mealOptionId: Scalars['ID'];
};

export type RemoveMealOptionPayload = {
  readonly mealOptionId: Scalars['ID'];
};

export type RemoveMealOptionPendingInput = {
  readonly mealOptionPendingId: Scalars['ID'];
};

export type RemoveMealOptionPendingPayload = {
  readonly mealOptionPendingId: Scalars['ID'];
};

export type RemoveMealPlanDateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
};

export type RemoveMealPlanDateForAthletePayload = {
  readonly date: Scalars['Date'];
};

export type RemoveMealPlanDateInput = {
  readonly date: Scalars['Date'];
};

export type RemoveMealPlanDatePayload = {
  readonly date: Scalars['Date'];
};

export type RemoveMenuItemChoiceInput = {
  readonly menuItemChoiceId: Scalars['ID'];
};

export type RemoveMenuItemChoiceOptionInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type RemoveMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
};

export type RemoveMenuOrderImageInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly imageId: Scalars['ID'];
};

export type RemoveMenuOrderImagePayload = {
  readonly menuOrder: MenuOrder;
};

export type RemoveMenuOrderInput = {
  readonly menuOrderId: Scalars['ID'];
};

export type RemoveMenuOrderMealTemplateInput = {
  readonly menuOrderId: Scalars['ID'];
};

export type RemoveMenuOrderMealTemplatePayload = {
  readonly menuOrder: MenuOrder;
};

export type RemoveMenuOrderPayload = {
  readonly menuOrderId: Scalars['ID'];
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type RemovePrepPlanMealInput = {
  readonly prepPlanMealId: Scalars['ID'];
  readonly guidedFlow: Maybe<Scalars['Boolean']>;
};

export type RemovePrepPlanMealPayload = {
  readonly removedPrepPlanMealId: Scalars['ID'];
};

export type RemoveRecipeImageInput = {
  readonly recipeId: Scalars['ID'];
  readonly imageId: Scalars['ID'];
};

export type RemoveRecipeImagePayload = {
  readonly recipe: Recipe;
};

export type RemoveRestaurantMenuLinkInput = {
  readonly restaurantMenuLinkId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkOrderInput = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkOrderMealTemplateInput = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkOrderMealTemplatePayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
};

export type RemoveRestaurantMenuLinkOrderPayload = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveRestaurantMenuLinkPayload = {
  readonly restaurantMenuLinkId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkPlateOrderInput = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkPlateOrderMealTemplateInput = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkPlateOrderMealTemplatePayload = {
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
};

export type RemoveRestaurantMenuLinkPlateOrderPayload = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveRestaurantMenuLogInput = {
  readonly restaurantMenuLogId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogItemInput = {
  readonly restaurantMenuLogItemId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogItemPayload = {
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
  readonly restaurantMenuLogItemId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogMealTemplateInput = {
  readonly restaurantMenuLogId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogMealTemplatePayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type RemoveRestaurantMenuLogPayload = {
  readonly restaurantMenuLogId: Scalars['ID'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveRestaurantMenuSectionInput = {
  readonly restaurantMenuSectionId: Scalars['ID'];
};

export type RemoveTimelineMealImageInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly imageId: Scalars['ID'];
};

export type RemoveTimelineMealImagePayload = {
  readonly imageId: Scalars['ID'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveTimelineMealServingAmountInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly removeServingAmountId: Scalars['ID'];
};

export type RemoveTimelineMealServingAmountPayload = {
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type Restaurant = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly nutritionixId: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly website: Maybe<Scalars['String']>;
  readonly proteinPortionSizeInOunces: Maybe<Scalars['Int']>;
  readonly firstPartyDelivery: Scalars['Boolean'];
  readonly athleteVisible: Scalars['Boolean'];
  readonly refundPolicy: Maybe<RefundPolicyType>;
  readonly refundDueBeforeInMinutes: Maybe<Scalars['Int']>;
  readonly menu: Maybe<RestaurantMenu>;
  readonly restaurantType: Maybe<RestaurantType>;
  readonly paymentMethods: ReadonlyArray<PaymentMethod>;
  readonly cuisines: ReadonlyArray<Cuisine>;
};

export type RestaurantGeoLocationInfo = {
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
  readonly googlePlaceId: Scalars['String'];
  readonly icon: Maybe<Scalars['String']>;
};

export type RestaurantGeoLocationSearchCursorConnection = {
  readonly edges: ReadonlyArray<RestaurantGeoLocationSearchResult>;
  readonly pageInfo: CursorPageInfo;
};

export type RestaurantGeoLocationSearchResult = {
  readonly restaurant: Restaurant;
  readonly restaurantMenu: RestaurantMenu;
  readonly restaurantLocation: Maybe<RestaurantLocation>;
  readonly locationInfo: RestaurantGeoLocationInfo;
};

export type RestaurantLocation = {
  readonly id: Scalars['ID'];
  readonly restaurant: Maybe<Restaurant>;
  readonly address: Maybe<Address>;
  readonly contacts: ReadonlyArray<RestaurantLocationContact>;
  readonly googlePlaceId: Scalars['String'];
  readonly notes: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly cityInfo: Maybe<Scalars['String']>;
  readonly deliveryLocations: ReadonlyArray<DeliveryLocation>;
};

export type RestaurantLocationContact = {
  readonly id: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly title: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly preferredContactMethod: PreferredContactMethodType;
};

export type RestaurantLocationContactInput = {
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly title: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly preferredContactMethod: PreferredContactMethodType;
};

export type RestaurantLocationOffsetConnection = {
  readonly edges: ReadonlyArray<RestaurantLocation>;
  readonly pageInfo: OffsetPageInfo;
};

export type RestaurantLocationSearchCursorConnection = {
  readonly edges: ReadonlyArray<RestaurantLocationSearchResult>;
  readonly pageInfo: CursorPageInfo;
};

export type RestaurantLocationSearchResult = {
  readonly restaurant: Restaurant;
  readonly restaurantMenu: RestaurantMenu;
  readonly restaurantLocation: RestaurantLocation;
};

export type RestaurantMenu = {
  readonly id: Scalars['ID'];
  readonly sections: ReadonlyArray<RestaurantMenuSection>;
  readonly supportedDiets: ReadonlyArray<Diet>;
};

export type RestaurantMenuLink = {
  readonly id: Scalars['ID'];
  readonly deliveryLocation: Maybe<DeliveryLocation>;
  readonly restaurant: Restaurant;
  readonly restaurantMenu: RestaurantMenu;
  readonly restaurantLocation: Maybe<RestaurantLocation>;
  readonly sections: ReadonlyArray<RestaurantMenuSectionLink>;
  readonly plates: ReadonlyArray<RestaurantMenuLinkPlate>;
  readonly allowCustomOrders: Scalars['Boolean'];
  readonly allowPlateOrders: Scalars['Boolean'];
  readonly orders: ReadonlyArray<RestaurantMenuLinkOrder>;
  readonly myOrder: Maybe<RestaurantMenuLinkOrder>;
  readonly orderForAthlete: Maybe<RestaurantMenuLinkOrder>;
  readonly myPlateOrder: Maybe<RestaurantMenuLinkPlateOrder>;
  readonly plateOrderForAthlete: Maybe<RestaurantMenuLinkPlateOrder>;
};


export type RestaurantMenuLinkOrderForAthleteArgs = {
  athleteId: Scalars['ID'];
};


export type RestaurantMenuLinkPlateOrderForAthleteArgs = {
  athleteId: Scalars['ID'];
};

export type RestaurantMenuLinkInput = {
  readonly restaurantId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly deliveryLocationId: Maybe<Scalars['ID']>;
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly sections: ReadonlyArray<RestaurantMenuSectionLinkInput>;
  readonly plates: ReadonlyArray<AddRestaurantMenuLinkPlateInput>;
  readonly allowCustomOrders: Scalars['Boolean'];
  readonly allowPlateOrders: Scalars['Boolean'];
};

export type RestaurantMenuLinkOrder = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly createdAt: Scalars['DateTime'];
  readonly restaurantMenuLink: RestaurantMenuLink;
  readonly items: ReadonlyArray<RestaurantMenuLinkOrderItem>;
  readonly itemCount: Scalars['Int'];
  readonly athlete: Maybe<Athlete>;
  readonly userFullName: Scalars['String'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly mealMenu: MealMenu;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type RestaurantMenuLinkOrderItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<RestaurantMenuLinkOrderItemOption>;
  readonly order: RestaurantMenuLinkOrder;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type RestaurantMenuLinkOrderItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly options: ReadonlyArray<RestaurantMenuLinkOrderItemOptionInput>;
};

export type RestaurantMenuLinkOrderItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOption: MenuItemChoiceOption;
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type RestaurantMenuLinkOrderItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type RestaurantMenuLinkPlate = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly bulkOrderAmount: Maybe<Scalars['Int']>;
  readonly items: ReadonlyArray<RestaurantMenuLinkPlateItem>;
  readonly orders: ReadonlyArray<RestaurantMenuLinkPlateOrder>;
  readonly isDefault: Scalars['Boolean'];
};

export type RestaurantMenuLinkPlateItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<RestaurantMenuLinkPlateItemOption>;
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type RestaurantMenuLinkPlateItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOption: MenuItemChoiceOption;
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type RestaurantMenuLinkPlateItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type RestaurantMenuLinkPlateOrder = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly createdAt: Scalars['DateTime'];
  readonly restaurantMenuLink: RestaurantMenuLink;
  readonly plate: RestaurantMenuLinkPlate;
  readonly items: ReadonlyArray<RestaurantMenuLinkPlateOrderItem>;
  readonly athlete: Maybe<Athlete>;
  readonly userFullName: Scalars['String'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly mealMenu: MealMenu;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type RestaurantMenuLinkPlateOrderItem = MenuSelectionItem & {
  readonly plateItem: RestaurantMenuLinkPlateItem;
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<RestaurantMenuLinkPlateOrderItemOption>;
};

export type RestaurantMenuLinkPlateOrderItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type RestaurantMenuLog = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly restaurantMenu: RestaurantMenu;
  readonly items: ReadonlyArray<RestaurantMenuLogItem>;
  readonly athlete: Maybe<Athlete>;
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly dateTime: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly type: MealType;
  readonly restaurant: Restaurant;
};

export type RestaurantMenuLogItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<RestaurantMenuLogItemOption>;
};

export type RestaurantMenuLogItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<RestaurantMenuLogItemOptionInput>;
};

export type RestaurantMenuLogItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type RestaurantMenuLogItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type RestaurantMenuSearchCursorConnection = {
  readonly edges: ReadonlyArray<RestaurantMenuSearchResult>;
  readonly pageInfo: CursorPageInfo;
};

export type RestaurantMenuSearchResult = {
  readonly restaurant: Restaurant;
  readonly restaurantMenu: RestaurantMenu;
};

export type RestaurantMenuSection = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly menuItemAppearances: ReadonlyArray<MenuItemAppearance>;
};

export type RestaurantMenuSectionLink = {
  readonly restaurantMenuSection: RestaurantMenuSection;
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly menuItemAppearances: ReadonlyArray<MenuItemAppearance>;
};

export type RestaurantMenuSectionLinkInput = {
  readonly restaurantMenuSectionId: Scalars['ID'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly menuItems: ReadonlyArray<AddMenuItemInput>;
};

export type RestaurantOffsetConnection = {
  readonly edges: ReadonlyArray<Restaurant>;
  readonly pageInfo: OffsetPageInfo;
};

export type RestaurantType = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type RestaurantTypeCursorConnection = {
  readonly edges: ReadonlyArray<RestaurantType>;
  readonly pageInfo: CursorPageInfo;
};

export type RestaurantTypeOffsetConnection = {
  readonly edges: ReadonlyArray<RestaurantType>;
  readonly pageInfo: OffsetPageInfo;
};

export type Role =
  | 'athlete'
  | 'dietitian'
  | 'chef'
  | 'foodmanager'
  | 'kiosk'
  | 'nonRdAdmin'
  | '%future added value';

export type SavePlannedMealsInput = {
  readonly plannedMenuId: Scalars['ID'];
  readonly rowItemsToDelete: ReadonlyArray<Scalars['ID']>;
  readonly rowsToDelete: ReadonlyArray<Scalars['ID']>;
  readonly mealsToDelete: ReadonlyArray<Scalars['ID']>;
  readonly mealsToUpsert: ReadonlyArray<PlannedMenuMealInput>;
  readonly disabledDays: Maybe<ReadonlyArray<PlannedMenuDisabledDaysInput>>;
};

export type SavePlannedMealsPayload = {
  readonly plannedMenu: PlannedMenu;
};

export type Schedule = {
  readonly id: Scalars['ID'];
  readonly activityFactor: Maybe<Scalars['Float']>;
  readonly name: Scalars['String'];
  readonly meals: ReadonlyArray<Meal>;
  readonly activities: ReadonlyArray<Activity>;
  readonly mealPlans: ReadonlyArray<MealPlan>;
  readonly team: Maybe<Team>;
};

export type Score = {
  readonly value: Scalars['Float'];
  readonly scoringSystem: ScoringSystem;
};

export type ScoringSystem = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type ScoringSystemOffsetConnection = {
  readonly edges: ReadonlyArray<ScoringSystem>;
  readonly pageInfo: OffsetPageInfo;
};

export type SelectAllInfo = {
  readonly allIds: ReadonlyArray<Scalars['ID']>;
  readonly total: Scalars['Int'];
};

export type Serving = {
  readonly cho: Scalars['Float'];
  readonly customUnits: Maybe<Scalars['String']>;
  readonly defaultAmount: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly foodOrRecipe: FoodOrRecipe;
  readonly hasOwnNutrients: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly isRecipeServingOnly: Scalars['Boolean'];
  readonly macros: Macros;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  readonly perRecipeYield: Maybe<Scalars['Float']>;
  readonly pro: Scalars['Float'];
  readonly unit: Maybe<Unit>;
  readonly unitPrefix: Maybe<Scalars['String']>;
  readonly unitSuffix: Maybe<Scalars['String']>;
  readonly units: Scalars['String'];
  readonly usdaWeightSeq: Maybe<Scalars['String']>;
  readonly weight: Maybe<Scalars['Float']>;
};

export type ServingAmount = {
  readonly id: Scalars['ID'];
  readonly serving: Serving;
  readonly amount: Scalars['Float'];
  readonly position: Scalars['Int'];
};

export type ServingAmountGroup = {
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type ServingAmountGroupCursorConnection = {
  readonly edges: ReadonlyArray<ServingAmountGroup>;
  readonly pageInfo: CursorPageInfo;
};

export type ServingAmountLocalInput = {
  readonly servingId: Scalars['String'];
  readonly amount: Scalars['Int'];
  readonly position: Scalars['Int'];
};

export type ServingUnitsInput = {
  readonly customUnits: Maybe<Scalars['String']>;
  readonly unitId: Maybe<Scalars['ID']>;
  readonly unitPrefix: Maybe<Scalars['String']>;
  readonly unitSuffix: Maybe<Scalars['String']>;
};

export type SetGoalReminderConfigurationInput = {
  readonly time: Scalars['Time'];
  readonly message: Scalars['String'];
};

export type SetGoalTrackerGoalInput = {
  readonly goalId: Scalars['ID'];
  readonly completed: Scalars['Boolean'];
  readonly value: Maybe<Scalars['Int']>;
};

export type SetGoalTrackerGoalPayload = {
  readonly goalTrackerGoal: GoalTrackerGoal;
};

export type SetMealMenuCheckInsInput = {
  readonly athleteId: Scalars['ID'];
  readonly removeMealMenuIds: ReadonlyArray<Scalars['ID']>;
  readonly addMealMenuIds: ReadonlyArray<Scalars['ID']>;
};

export type SetMealMenuCheckInsPayload = {
  readonly addedMealMenuCheckIns: ReadonlyArray<MealMenuCheckIn>;
  readonly removedMealMenuCheckInIds: ReadonlyArray<Scalars['ID']>;
};

export type SetMealMenuLogItemPercentConsumedPayload = {
  readonly mealMenuLogItem: MealMenuLogItem;
};

export type SetMealOptionsForMealTemplateInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly mealOptions: ReadonlyArray<MealOptionInput>;
};

export type SetMealOptionsForMealTemplatesInput = {
  readonly removeExistingMealOptions: Scalars['Boolean'];
  readonly mealOptionsForMealTemplates: ReadonlyArray<SetMealOptionsForMealTemplateInput>;
};

export type SetMealOptionsForMealTemplatesPayload = {
  readonly mealTemplates: ReadonlyArray<MealTemplate>;
};

export type SetMenuOrderItemPercentConsumedPayload = {
  readonly menuOrderItem: MenuOrderItem;
};

export type SetMenuSelectionItemOptionPercentConsumedInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
  readonly percentConsumed: Scalars['Float'];
};

export type SetMenuSelectionItemPercentConsumedInput = {
  readonly menuSelectionItemId: Scalars['ID'];
  readonly percentConsumed: Scalars['Float'];
  readonly options: ReadonlyArray<SetMenuSelectionItemOptionPercentConsumedInput>;
};

export type SetPrepPlanGuidedBuilderInput = {
  readonly prepPlanId: Scalars['ID'];
};

export type SetPrepPlanGuidedBuilderPayload = {
  readonly prepPlan: PrepPlan;
};

export type SetPrepPlanMealAssignmentSlotsInput = {
  readonly prepPlanId: Scalars['ID'];
  readonly assignmentSlots: ReadonlyArray<PrepPlanMealAssignmentInput>;
};

export type SetPrepPlanMealAssignmentSlotsPayload = {
  readonly prepPlanMealAssignments: ReadonlyArray<PrepPlanMealAssignment>;
};

export type SetRestaurantMenuLinkOrderItemPercentConsumedPayload = {
  readonly restaurantMenuLinkOrderItem: RestaurantMenuLinkOrderItem;
};

export type SetRestaurantMenuLinkPlateOrderItemPercentConsumedInput = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
  readonly restaurantMenuLinkPlateItemId: Scalars['ID'];
  readonly percentConsumed: Scalars['Float'];
  readonly options: ReadonlyArray<SetMenuSelectionItemOptionPercentConsumedInput>;
};

export type SetRestaurantMenuLinkPlateOrderItemPercentConsumedPayload = {
  readonly restaurantMenuLinkPlateOrderItem: RestaurantMenuLinkPlateOrderItem;
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
};

export type SetRestaurantMenuLogItemPercentConsumedPayload = {
  readonly restaurantMenuLogItem: RestaurantMenuLogItem;
};

export type SetTimelineMealServingAmountsForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type SetTimelineMealServingAmountsInput = {
  readonly timelineMeal: TimelineMealInput;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type SetTimelineMealServingAmountsPayload = {
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type SexType =
  | 'male'
  | 'female'
  | '%future added value';

export type ShareMealOptionPendingInput = {
  readonly mealOptionPendingId: Scalars['ID'];
};

export type ShareMealOptionPendingPayload = {
  readonly mealOption: MealOption;
};

export type SharedRecipeCursorConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: CursorPageInfo;
};

export type SkippedPlatePayload = {
  readonly name: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type SkippedUserPayload = {
  readonly name: Scalars['String'];
  readonly userId: Scalars['ID'];
};

export type SodexoDayMealTimes = {
  readonly date: Scalars['String'];
  readonly mealTimes: ReadonlyArray<SodexoMealTime>;
};

export type SodexoMealTime = {
  readonly name: Scalars['String'];
  readonly start: Scalars['String'];
  readonly end: Scalars['String'];
  readonly sortOrder: Scalars['String'];
};

export type SodexoMealTimeInput = {
  readonly name: Scalars['String'];
  readonly start: Scalars['String'];
  readonly end: Scalars['String'];
};

export type SodexoMealTimesByDay = {
  readonly locationId: Scalars['String'];
  readonly menuSourceKey: Scalars['String'];
  readonly mealTimesByDay: ReadonlyArray<SodexoDayMealTimes>;
};

export type SodexoMenuIdentity = MealMenuIdentity & {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type SodexoMenuSource = {
  readonly locationId: Scalars['String'];
  readonly locationName: Scalars['String'];
  readonly menuSourceKey: Scalars['String'];
  readonly menuSourceName: Scalars['String'];
};

export type SortOrder =
  | 'ASC'
  | 'DESC'
  | '%future added value';

export type Sport = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly positions: ReadonlyArray<Position>;
};

export type SportsMappingInput = {
  readonly teamworksName: Scalars['String'];
  readonly sportId: Scalars['ID'];
};

export type StandaloneMealMenu = MealMenu & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStation>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimit>;
  readonly userOrderLimit: Maybe<Scalars['Int']>;
  readonly isLogOnly: Scalars['Boolean'];
  readonly isInHouseMenuOrderable: Scalars['Boolean'];
  readonly isRestaurantMenuOrderable: Scalars['Boolean'];
  readonly isOrderable: Scalars['Boolean'];
  readonly isHubCheckInEnabled: Scalars['Boolean'];
  readonly hubEventUrl: Maybe<Scalars['String']>;
  readonly isOrderAndLogRestricted: Scalars['Boolean'];
  readonly isBulkOrderable: Scalars['Boolean'];
  readonly isViewOnlyForMe: Scalars['Boolean'];
  readonly identity: Maybe<MealMenuIdentity>;
  readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLink>;
  readonly teams: ReadonlyArray<Team>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly athleteCount: Scalars['Int'];
  readonly athletes: ReadonlyArray<Athlete>;
  readonly allOrders: ReadonlyArray<MenuOrder>;
  readonly allMealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  /** @deprecated pending forced update for multiple MenuOrders per menu */
  readonly order: Maybe<MenuOrder>;
  readonly orders: ReadonlyArray<MenuOrder>;
  readonly ordersForUser: ReadonlyArray<MenuOrder>;
  readonly ordersForAthlete: ReadonlyArray<MenuOrder>;
  readonly logs: ReadonlyArray<MealMenuLog>;
  readonly logsForAthlete: ReadonlyArray<MealMenuLog>;
  readonly theme: Maybe<Scalars['String']>;
};


export type StandaloneMealMenuOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type StandaloneMealMenuOrdersForAthleteArgs = {
  athleteId: Scalars['ID'];
};


export type StandaloneMealMenuLogsForAthleteArgs = {
  athleteId: Scalars['ID'];
};

export type Subscription = {
  readonly bulkOrder: BulkOrderPublication;
  readonly bulkOrderStatus: BulkOrderStatusPublication;
  /** @deprecated Remove pending timeline meal cleanup and mobile force update */
  readonly foodLogMeal: FoodLogMealPublication;
  readonly mealMenuPlateItemOrderStatuses: MealMenuPlateItemOrderStatusPublication;
  /** @deprecated Remove 'AddMenuOrderCommentPayload' from 'union MenuOrderPublicationPayload' pending timeline meal cleanup and mobile force update */
  readonly menuOrder: MenuOrderPublication;
  readonly menuOrderItemStatuses: MenuOrderItemStatusesPublication;
  readonly notification: NotificationPublication;
  readonly plannedMenuLockTaken: PlannedMenuLockTakenPublication;
  readonly timelineMeal: TimelineMealPublication;
};


export type SubscriptionBulkOrderArgs = {
  scope: Maybe<BulkOrderSubscriberScope>;
};


export type SubscriptionBulkOrderStatusArgs = {
  scope: Maybe<BulkOrderSubscriberScope>;
};


export type SubscriptionFoodLogMealArgs = {
  scope: Maybe<FoodLogMealSubscriberScope>;
};


export type SubscriptionMealMenuPlateItemOrderStatusesArgs = {
  scope: Maybe<MealMenuPlateItemOrderStatusSubscriberScope>;
};


export type SubscriptionMenuOrderArgs = {
  scope: Maybe<MenuOrderSubscriberScope>;
};


export type SubscriptionMenuOrderItemStatusesArgs = {
  scope: Maybe<MenuOrderItemStatusesSubscriberScope>;
};


export type SubscriptionPlannedMenuLockTakenArgs = {
  scope: PlannedMenuLockTakenSubscriberScope;
};


export type SubscriptionTimelineMealArgs = {
  timelineMealId: Maybe<Scalars['ID']>;
};

export type SyncedAthleteFormInput = {
  readonly sex: Maybe<SexType>;
  readonly inbodyUid: Maybe<Scalars['String']>;
  readonly secaUid: Maybe<Scalars['String']>;
  readonly jerseyNumber: Maybe<Scalars['String']>;
  readonly removedTagIds: ReadonlyArray<Scalars['ID']>;
  readonly addedTagIds: ReadonlyArray<Scalars['ID']>;
  readonly positionId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
};

export type Tag = {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
};

export type TagCombinationMethod =
  | 'union'
  | 'intersection'
  | '%future added value';

export type TagCondition = ComboTag | PositionTag | TeamTag | { readonly __typename?: "%other" };

export type TagConditionType =
  | 'position'
  | 'tag'
  | 'team'
  | '%future added value';

export type TagName = {
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly color: Scalars['String'];
  readonly isPrintable: Maybe<Scalars['Boolean']>;
};

export type Team = {
  readonly id: Scalars['ID'];
  readonly athletes: ReadonlyArray<Athlete>;
  /** @deprecated Pending web notifications overhaul */
  readonly recentNotifications: ReadonlyArray<Notification>;
  readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettings>;
  readonly defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettings>;
  readonly defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettings>;
  readonly defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettings>;
  readonly defaultHideAnthropometry: Maybe<Scalars['Boolean']>;
  readonly schedules: ReadonlyArray<Schedule>;
  readonly gender: Maybe<Scalars['String']>;
  readonly sport: Maybe<Sport>;
  readonly name: Scalars['String'];
  readonly canBeDeleted: Scalars['Boolean'];
  readonly teamworksId: Maybe<Scalars['Int']>;
};

export type TeamCursorConnection = {
  readonly edges: ReadonlyArray<Team>;
  readonly pageInfo: CursorPageInfo;
};

export type TeamLabel = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type TeamLabelCursorConnection = {
  readonly edges: ReadonlyArray<TeamLabel>;
  readonly pageInfo: CursorPageInfo;
};

export type TeamLabelOffsetConnection = {
  readonly edges: ReadonlyArray<TeamLabel>;
  readonly pageInfo: OffsetPageInfo;
};

export type TeamTag = Tag & {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
  readonly teams: ReadonlyArray<Team>;
};

export type TeamworksAthleteStatus = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly active: Maybe<Scalars['Boolean']>;
};

export type TeamworksCalendarPayload = {
  readonly events: ReadonlyArray<ExternalEvent>;
  readonly isPartialSuccess: Scalars['Boolean'];
};

export type TeamworksGender =
  | 'M'
  | 'F'
  | '%future added value';

export type TeamworksGroup = {
  readonly id: Scalars['Int'];
  readonly type: TeamworksGroupType;
  readonly label: Scalars['String'];
  readonly teamId: Maybe<Scalars['Int']>;
};

export type TeamworksGroupType =
  | 'team'
  | 'user_type'
  | 'athlete_status'
  | 'position'
  | 'academic_year'
  | '%future added value';

export type TeamworksMembership = {
  readonly teamId: Scalars['Int'];
  readonly teamName: Scalars['String'];
  readonly athleteStatus: Maybe<TeamworksAthleteStatus>;
  readonly userTypes: Maybe<ReadonlyArray<TeamworksUserType>>;
  readonly positions: Maybe<ReadonlyArray<TeamworksPosition>>;
};

export type TeamworksOrg = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly shortName: Scalars['String'];
  readonly teams: ReadonlyArray<TeamworksTeam>;
};

export type TeamworksOrgCursorConnection = {
  readonly edges: ReadonlyArray<TeamworksOrg>;
  readonly pageInfo: CursorPageInfo;
};

export type TeamworksPosition = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type TeamworksPositionLink = {
  readonly teamworksId: Scalars['Int'];
  readonly teamworksTeamId: Scalars['Int'];
  readonly position: Position;
};

export type TeamworksPositionMapping = {
  readonly teamworksName: Scalars['String'];
  readonly positionId: Scalars['ID'];
};

export type TeamworksProfile = {
  readonly id: Scalars['Int'];
  readonly userId: Maybe<Scalars['Int']>;
  readonly firstName: Maybe<Scalars['String']>;
  readonly lastName: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly cellPhone: Maybe<Scalars['String']>;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly gender: Maybe<TeamworksGender>;
  readonly hasLoginAccess: Maybe<Scalars['Boolean']>;
  readonly active: Maybe<Scalars['Boolean']>;
  readonly memberships: Maybe<ReadonlyArray<TeamworksMembership>>;
};

export type TeamworksProfileCursorConnection = {
  readonly edges: ReadonlyArray<TeamworksProfile>;
  readonly pageInfo: CursorPageInfo;
};

export type TeamworksProfileLinkInput = {
  readonly orgMembershipId: Maybe<Scalars['ID']>;
  readonly athleteId: Maybe<Scalars['ID']>;
  readonly teamId: Maybe<Scalars['ID']>;
  readonly positionId: Maybe<Scalars['ID']>;
  readonly teamworksUserId: Scalars['Int'];
  readonly teamworksProfileId: Scalars['Int'];
  readonly notemealAccountType: NotemealAccountType;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly email: Maybe<Scalars['String']>;
  readonly cellPhone: Maybe<Scalars['String']>;
  readonly sex: Maybe<SexType>;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly hasLoginAccess: Maybe<Scalars['Boolean']>;
  readonly active: Maybe<Scalars['Boolean']>;
};

export type TeamworksProfileSyncRule = {
  readonly id: Scalars['ID'];
  readonly athleteStatusIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly userTypeIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly teamIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly profileIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly notemealAccountType: NotemealAccountType;
  readonly priority: Scalars['Int'];
  readonly positionIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly genders: Maybe<ReadonlyArray<TeamworksGender>>;
  readonly matchNotemealTeamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type TeamworksProfileSyncRuleInput = {
  readonly athleteStatusIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly userTypeIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly teamIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly profileIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly notemealAccountType: NotemealAccountType;
  readonly priority: Scalars['Int'];
  readonly positionIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly genders: Maybe<ReadonlyArray<TeamworksGender>>;
  readonly matchNotemealTeamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type TeamworksSportMapping = {
  readonly teamworksName: Scalars['String'];
  readonly sportId: Scalars['ID'];
  readonly sport: Sport;
};

export type TeamworksSyncStatus = {
  readonly linkedProfileCount: Scalars['Int'];
  readonly unlinkedTeamworksProfileCount: Scalars['Int'];
  readonly unlinkedNotemealProfileCount: Scalars['Int'];
  readonly toDeactivateProfileCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isFullySynced: Scalars['Boolean'];
  readonly isTeamworksLinked: Scalars['Boolean'];
  readonly isNotemealLinked: Scalars['Boolean'];
};

export type TeamworksTeam = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly sport: Scalars['String'];
  readonly sportMappings: ReadonlyArray<TeamworksSportMapping>;
  readonly notemealTeams: ReadonlyArray<Team>;
};

export type TeamworksUserType = {
  readonly userTypeId: Scalars['Int'];
  readonly userTypeName: Scalars['String'];
};

export type Territory = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly orgs: ReadonlyArray<Org>;
};

export type TerritoryOffsetConnection = {
  readonly edges: ReadonlyArray<Territory>;
  readonly pageInfo: OffsetPageInfo;
};

export type TextMealPlanToAthleteInput = {
  readonly mealPlanId: Scalars['ID'];
};

export type TextMealPlanToAthletePayload = {
  readonly result: Scalars['Boolean'];
};

export type TextMealPlansToAthletesInput = {
  readonly mealPlanIds: ReadonlyArray<Scalars['ID']>;
};

export type TextMealPlansToAthletesPayload = {
  readonly result: Scalars['Boolean'];
};


export type TimelineActivityOrTemplate = FoodLogActivity | TimelineActivityTemplate | { readonly __typename?: "%other" };

export type TimelineActivityTemplate = {
  readonly start: Scalars['DateTime'];
  readonly durationInMinutes: Scalars['Int'];
  readonly timezone: Scalars['Timezone'];
  readonly template: ActivityTemplate;
};

export type TimelineDate = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly isMealPlanSelected: Scalars['Boolean'];
  readonly mealPlan: Maybe<MealPlan>;
  readonly activities: ReadonlyArray<TimelineActivityOrTemplate>;
  readonly meals: ReadonlyArray<TimelineMealOrTemplate>;
};

export type TimelineItem = FoodLogMeal | FoodLogActivity | MenuOrder | MealPlanDate | StandaloneMealMenu | RestaurantMenuLog | { readonly __typename?: "%other" };

export type TimelineMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly start: Scalars['DateTime'];
  readonly durationInMinutes: Scalars['Int'];
  readonly timezone: Scalars['Timezone'];
  readonly type: MealType;
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly athlete: Maybe<Athlete>;
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly restaurantMenuLogs: ReadonlyArray<RestaurantMenuLog>;
  readonly restaurantMenuLinkOrders: ReadonlyArray<RestaurantMenuLinkOrder>;
  readonly restaurantMenuLinkPlateOrders: ReadonlyArray<RestaurantMenuLinkPlateOrder>;
  readonly menuOrders: ReadonlyArray<MenuOrder>;
  readonly mealMenuLogs: ReadonlyArray<MealMenuLog>;
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly comments: ReadonlyArray<Comment>;
  readonly images: ReadonlyArray<Image>;
  readonly notifications: ReadonlyArray<Notification>;
};

export type TimelineMealCommentNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type TimelineMealEditNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type TimelineMealImageNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly image: Maybe<Image>;
};

export type TimelineMealInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly addTimelineMeal: Maybe<AddTimelineMealInput>;
};

export type TimelineMealOrTemplate = TimelineMeal | TimelineMealTemplate | { readonly __typename?: "%other" };

export type TimelineMealPublication = {
  readonly payload: TimelineMealPublicationPayload;
};

export type TimelineMealPublicationPayload = AddTimelineMealCommentPayload | { readonly __typename?: "%other" };

export type TimelineMealTemplate = {
  readonly start: Scalars['DateTime'];
  readonly durationInMinutes: Scalars['Int'];
  readonly timezone: Scalars['Timezone'];
  readonly template: MealTemplate;
  readonly mealMenus: ReadonlyArray<MealMenu>;
};


export type UsdaFood = {
  readonly id: Scalars['ID'];
  readonly allHighlightFields: ReadonlyArray<Scalars['String']>;
  readonly brandOwner: Scalars['String'];
  readonly dataType: UsdaFoodDataType;
  readonly description: Scalars['String'];
  readonly gtinUpc: Scalars['String'];
  readonly ingredients: ReadonlyArray<Scalars['String']>;
  readonly publishedDate: Scalars['Date'];
  readonly score: Scalars['Float'];
};

export type UsdaFoodCategory = {
  readonly code: Scalars['String'];
  readonly description: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type UsdaFoodDataType =
  | 'Branded'
  | 'Survey'
  | 'Legacy'
  | 'Foundation'
  | '%future added value';

export type UsdaFoodDetail = {
  readonly id: Scalars['ID'];
  readonly usdaFdcFoodCategoryId: Scalars['Int'];
  readonly usdaFdcDataType: Scalars['String'];
  readonly cho: UsdaFoodNutrient;
  readonly pro: UsdaFoodNutrient;
  readonly fat: UsdaFoodNutrient;
  readonly foodNutrients: ReadonlyArray<UsdaFoodNutrient>;
  readonly servings: ReadonlyArray<UsdaFoodServing>;
};

export type UsdaFoodNutrient = {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly min: Scalars['Float'];
  readonly max: Scalars['Float'];
  readonly median: Scalars['Float'];
  readonly dataPoints: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly unitName: Scalars['String'];
  readonly footnote: Scalars['String'];
};

export type UsdaFoodServing = {
  readonly idx: Scalars['Int'];
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly weight: Scalars['Float'];
  readonly defaultAmount: Scalars['Float'];
  readonly units: Scalars['String'];
  readonly isDefault: Scalars['Boolean'];
  readonly usdaWeightSeq: Scalars['String'];
};

export type UnarchiveAthleteInput = {
  readonly id: Scalars['ID'];
};

export type UnarchiveAthletePayload = {
  readonly athlete: Athlete;
};

export type Unit = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isProductionOnly: Scalars['Boolean'];
  readonly conversionsFrom: ReadonlyArray<UnitConversion>;
  readonly conversionsTo: ReadonlyArray<UnitConversion>;
};

export type UnitConversion = {
  readonly id: Scalars['ID'];
  readonly fromUnit: Unit;
  readonly fromQuantity: Scalars['Float'];
  readonly toUnit: Unit;
  readonly toQuantity: Scalars['Float'];
};

export type UnlinkTeamworksProfileForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly newEmail: Scalars['String'];
  readonly newPhone: Maybe<Scalars['String']>;
  readonly shouldArchive: Maybe<Scalars['Boolean']>;
};

export type UnlinkTeamworksProfileInput = {
  readonly userId: Scalars['ID'];
  readonly orgMembershipId: Maybe<Scalars['ID']>;
  readonly athleteId: Maybe<Scalars['ID']>;
  readonly newEmail: Maybe<Scalars['String']>;
  readonly newPhone: Maybe<Scalars['String']>;
  readonly archiveAthlete: Maybe<Scalars['Boolean']>;
};

export type UnlockPlannedMenuInput = {
  readonly plannedMenuId: Scalars['ID'];
};

export type UnlockPlannedMenuPayload = {
  readonly lockReleased: Scalars['Boolean'];
  readonly plannedMenu: PlannedMenu;
};

export type UnmatchedIngredient = {
  readonly foodName: Scalars['String'];
  readonly amount: Scalars['Float'];
  readonly servingName: Scalars['String'];
};

export type UnmatchedIngredients = {
  readonly unmatchedName: ReadonlyArray<UnmatchedIngredient>;
  readonly unmatchedServings: ReadonlyArray<UnmatchedIngredient>;
};

export type UpdateApnCountForUserPayload = {
  readonly notificationCount: Scalars['Int'];
};

export type UpdateBulkOrderStatusPayload = {
  readonly bulkOrder: BulkOrder;
  readonly status: Scalars['String'];
};

export type UpdateMacroMealPlanTemplateSharingScopeInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
  readonly isShared: Scalars['Boolean'];
};

export type UpdateMacroMealPlanTemplateSharingScopePayload = {
  readonly macroMealPlanTemplate: MealPlanTemplate;
};

export type UpdateMealMenuPlateItemOrderStatusInput = {
  readonly keyTuple: PlateOrderIdItemIdTuple;
  readonly status: MenuOrderItemStatus;
};

export type UpdateMealMenuPlateItemOrderStatusPayload = {
  readonly mealMenuPlateItemOrderStatus: MealMenuPlateItemOrderStatus;
};

export type UpdateMealMenuPlateOrderStatusPayload = {
  readonly mealMenuPlateOrderId: Scalars['String'];
  readonly status: MenuOrderItemStatus;
};

export type UpdateMealPlanTemplateAssignScopeInput = {
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
};

export type UpdateMealPlanTemplateAssignScopePayload = {
  readonly mealPlanTemplate: MealPlanTemplate;
};

export type UpdateMenuOrderItemStatusesInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly ids: ReadonlyArray<Scalars['ID']>;
  readonly status: MenuOrderItemStatus;
};

export type UpdateMenuOrderItemStatusesPayload = {
  readonly menuOrderItems: ReadonlyArray<MenuOrderItem>;
};

export type UpdateMultipleMealMenuPlateItemOrderStatusesInput = {
  readonly keyTuples: ReadonlyArray<PlateOrderIdItemIdTuple>;
  readonly status: MenuOrderItemStatus;
};

export type UpdateMultipleMealMenuPlateItemOrderStatusesPayload = {
  readonly mealMenuPlateItemOrderStatuses: ReadonlyArray<MealMenuPlateItemOrderStatus>;
};

export type UpdateMultipleMenuOrderItemStatusesInput = {
  readonly menuOrderIds: ReadonlyArray<Scalars['ID']>;
  readonly ids: ReadonlyArray<Scalars['ID']>;
  readonly status: MenuOrderItemStatus;
};

export type UpdateMultipleMenuOrderItemStatusesPayload = {
  readonly menuOrderItems: ReadonlyArray<MenuOrderItem>;
};

export type User = {
  readonly id: Scalars['ID'];
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly username: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly orgInfo: ReadonlyArray<OrgInfo>;
  readonly usesKg: Scalars['Boolean'];
  readonly lastLogin: Maybe<Scalars['DateTime']>;
  readonly dateJoined: Scalars['DateTime'];
  readonly locale: Locale;
};

export type UserCursorConnection = {
  readonly edges: ReadonlyArray<User>;
  readonly pageInfo: CursorPageInfo;
};

export type UserOffsetConnection = {
  readonly edges: ReadonlyArray<User>;
  readonly pageInfo: OffsetPageInfo;
};

export type TeamsPickerTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamsPickerTeamsQuery = { readonly teams: ReadonlyArray<TeamsPickerTeamFragment> };

export type TeamsPickerTeamFragment = Pick<Team, 'id' | 'name'>;

export type DisplaySettingsFormMacroMealPlanDisplaySettingsFragment = (
  { readonly __typename: 'MacroMealPlanDisplaySettings' }
  & Pick<MacroMealPlanDisplaySettings, 'dailyProgressViews' | 'mealProgressViews'>
);

export type DisplaySettingsFormExchangeMealPlanDisplaySettingsFragment = (
  { readonly __typename: 'ExchangeMealPlanDisplaySettings' }
  & Pick<ExchangeMealPlanDisplaySettings, 'dailyProgressViewsForExchanges' | 'mealProgressViewsForExchanges' | 'dailyProgressViewsForMacros' | 'mealProgressViewsForMacros'>
);

export type DisplaySettingsFormNoTargetsDisplaySettingsFragment = (
  { readonly __typename: 'NoTargetsDisplaySettings' }
  & Pick<NoTargetsDisplaySettings, 'dailyProgressViewsForMacros' | 'mealProgressViewsForMacros'>
);

export type DeleteExchangeSetMutationVariables = Exact<{
  input: DeleteExchangeSetInput;
}>;


export type DeleteExchangeSetMutation = { readonly deleteExchangeSet: Pick<DeleteExchangeSetPayload, 'id'> };

export type GetOpenAiImageQueryVariables = Exact<{
  servingAmount: Scalars['Float'];
  servingUnit: Scalars['String'];
  foodName: Scalars['String'];
}>;


export type GetOpenAiImageQuery = Pick<Query, 'getOpenAIImage'>;

export type CreateBrandedFoodMutationVariables = Exact<{
  input: CreateBrandedFoodInput;
}>;


export type CreateBrandedFoodMutation = { readonly createBrandedFood: { readonly brandedFood: SharedEditableFoodBrandedFoodFragment } };

export type CreateRestaurantBrandedFoodMutationVariables = Exact<{
  input: CreateRestaurantBrandedFoodInput;
}>;


export type CreateRestaurantBrandedFoodMutation = { readonly createRestaurantBrandedFood: { readonly brandedFood: SharedEditableFoodBrandedFoodFragment } };

export type CreateGenericFoodMutationVariables = Exact<{
  input: CreateGenericFoodInput;
}>;


export type CreateGenericFoodMutation = { readonly createGenericFood: { readonly genericFood: SharedEditableFoodGenericFoodFragment } };

export type CreateOrgGenericFoodMutationVariables = Exact<{
  input: CreateGenericFoodInput;
}>;


export type CreateOrgGenericFoodMutation = { readonly createOrgGenericFood: { readonly genericFood: OrgEditableFoodGenericFoodFragment } };

export type EditBrandedFoodMutationVariables = Exact<{
  input: EditBrandedFoodInput;
}>;


export type EditBrandedFoodMutation = { readonly editBrandedFood: { readonly brandedFood: SharedEditableFoodBrandedFoodFragment } };

export type EditRestaurantBrandedFoodMutationVariables = Exact<{
  input: EditRestaurantBrandedFoodInput;
}>;


export type EditRestaurantBrandedFoodMutation = { readonly editRestaurantBrandedFood: { readonly brandedFood: SharedEditableFoodBrandedFoodFragment } };

export type EditGenericFoodMutationVariables = Exact<{
  input: EditGenericFoodInput;
}>;


export type EditGenericFoodMutation = { readonly editGenericFood: { readonly genericFood: SharedEditableFoodGenericFoodFragment } };

export type EditOrgGenericFoodMutationVariables = Exact<{
  input: EditGenericFoodInput;
}>;


export type EditOrgGenericFoodMutation = { readonly editOrgGenericFood: { readonly genericFood: OrgEditableFoodGenericFoodFragment } };

export type OrgEditableFoodsOffsetConnectionQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
}>;


export type OrgEditableFoodsOffsetConnectionQuery = { readonly orgFoodOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<OrgEditableFoodBrandedFoodFragment | OrgEditableFoodGenericFoodFragment> } };

export type OrgGroupFoodsOffsetConnectionQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
  ownerIds: Maybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
}>;


export type OrgGroupFoodsOffsetConnectionQuery = { readonly orgGroupFoodOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<OrgEditableFoodBrandedFoodFragment | OrgEditableFoodGenericFoodFragment> } };

export type OrgEditableFoodQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrgEditableFoodQuery = { readonly food: OrgEditableFoodBrandedFoodFragment | OrgEditableFoodGenericFoodFragment };

type OrgEditableFoodBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'excludeFromSuggestions' | 'source' | 'locales' | 'updatedAt'>
  & { readonly brand: Maybe<BrandFragment>, readonly servings: ReadonlyArray<(
    Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
    & { readonly unit: Maybe<Pick<Unit, 'id' | 'name'>> }
    & ServingWithFoodOrRecipeFragment
  )>, readonly createdBy: Maybe<Pick<User, 'firstName' | 'lastName'>>, readonly nutrientAmounts: ReadonlyArray<NutrientAmountFragment>, readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>>, readonly foodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>> }
  & FoodBrandedFoodFragment
);

type OrgEditableFoodGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'excludeFromSuggestions' | 'source' | 'locales' | 'updatedAt'>
  & { readonly owner: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>>, readonly orgGroup: Maybe<Pick<OrgGroup, 'id' | 'name'>>, readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly servings: ReadonlyArray<(
    Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
    & { readonly unit: Maybe<Pick<Unit, 'id' | 'name'>> }
    & ServingWithFoodOrRecipeFragment
  )>, readonly createdBy: Maybe<Pick<User, 'firstName' | 'lastName'>>, readonly nutrientAmounts: ReadonlyArray<NutrientAmountFragment>, readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>>, readonly foodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>> }
  & FoodGenericFoodFragment
);

export type OrgEditableFoodFragment = OrgEditableFoodBrandedFoodFragment | OrgEditableFoodGenericFoodFragment;

export type SharedEditableFoodsOffsetConnectionQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
  localeCodes: Maybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  dataSources: Maybe<ReadonlyArray<FoodDataSource> | FoodDataSource>;
}>;


export type SharedEditableFoodsOffsetConnectionQuery = { readonly foodOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<SharedEditableFoodBrandedFoodFragment | SharedEditableFoodGenericFoodFragment> } };

export type RestaurantEditableFoodsOffsetConnectionQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  pagination: OffsetPaginationInput;
  restaurantId: Scalars['ID'];
}>;


export type RestaurantEditableFoodsOffsetConnectionQuery = { readonly restaurantFoodOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<SharedEditableFoodBrandedFoodFragment> } };

type SharedEditableFoodBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'excludeFromSuggestions' | 'source' | 'locales' | 'updatedAt'>
  & { readonly brand: Maybe<BrandFragment>, readonly servings: ReadonlyArray<(
    Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
    & { readonly unit: Maybe<Pick<Unit, 'id' | 'name'>> }
    & ServingWithFoodOrRecipeFragment
  )>, readonly nutrientAmounts: ReadonlyArray<NutrientAmountFragment>, readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>>, readonly foodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>> }
  & FoodBrandedFoodFragment
);

type SharedEditableFoodGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'excludeFromSuggestions' | 'source' | 'locales' | 'updatedAt'>
  & { readonly servings: ReadonlyArray<(
    Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
    & { readonly unit: Maybe<Pick<Unit, 'id' | 'name'>> }
    & ServingWithFoodOrRecipeFragment
  )>, readonly nutrientAmounts: ReadonlyArray<NutrientAmountFragment>, readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>>, readonly foodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>> }
  & FoodGenericFoodFragment
);

export type SharedEditableFoodFragment = SharedEditableFoodBrandedFoodFragment | SharedEditableFoodGenericFoodFragment;

export type DeprecateRestaurantBrandedFoodMutationVariables = Exact<{
  input: DeprecateRestaurantBrandedFoodInput;
}>;


export type DeprecateRestaurantBrandedFoodMutation = { readonly deprecateRestaurantBrandedFood: Pick<DeprecateRestaurantBrandedFoodPayload, 'foodId'> };

export type GramUnitQueryVariables = Exact<{ [key: string]: never; }>;


export type GramUnitQuery = { readonly gramUnit: UnitWithConversionsFragment };

export type MoveOrgFoodToOrgGroupMutationVariables = Exact<{
  input: MoveOrgFoodToOrgGroupInput;
}>;


export type MoveOrgFoodToOrgGroupMutation = { readonly moveOrgFoodToOrgGroup: { readonly food: OrgEditableFoodBrandedFoodFragment | OrgEditableFoodGenericFoodFragment } };

export type MoveOrgGroupFoodToMyOrgMutationVariables = Exact<{
  input: MoveOrgGroupFoodToMyOrgInput;
}>;


export type MoveOrgGroupFoodToMyOrgMutation = { readonly moveOrgGroupFoodToMyOrg: { readonly food: OrgEditableFoodBrandedFoodFragment | OrgEditableFoodGenericFoodFragment } };

export type OrgGroupFoodOwnerCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
}>;


export type OrgGroupFoodOwnerCursorConnectionQuery = { readonly orgGroupFoodOwnerCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<FoodOwnerPreviewFragment> } };

export type FoodOwnerPreviewFragment = Pick<User, 'id' | 'firstName' | 'lastName'>;

export type CompassRecipesQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type CompassRecipesQuery = { readonly compass_recipes: ReadonlyArray<CompassRecipeFragment> };

export type CompassMrnRecipeQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type CompassMrnRecipeQuery = { readonly compass_mrn_recipe: Maybe<CompassRecipeFragment> };

export type CompassRecipeFragment = Pick<CompassRecipe, 'name' | 'shortName' | 'masterRefNumber'>;

export type ImportCompassRecipeMutationVariables = Exact<{
  input: ImportCompassRecipeInput;
}>;


export type ImportCompassRecipeMutation = { readonly importCompassRecipe: { readonly recipe: RecipeSearchFragment } };

export type RecipeSearchFragment = (
  Pick<Recipe, 'hasFullAccess'>
  & { readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly descendantFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
  & RecipeWithServingsFragment
);

export type LocalesQueryVariables = Exact<{ [key: string]: never; }>;


export type LocalesQuery = { readonly locales: ReadonlyArray<LocaleFragment> };

export type LocaleFragment = Pick<Locale, 'id' | 'code'>;

type MealMenuIdentityBonAppetitMenuIdentityFragment = (
  { readonly __typename: 'BonAppetitMenuIdentity' }
  & Pick<BonAppetitMenuIdentity, 'id' | 'isOverridden'>
);

type MealMenuIdentityCbordMenuIdentityFragment = (
  { readonly __typename: 'CbordMenuIdentity' }
  & Pick<CbordMenuIdentity, 'id' | 'isOverridden'>
);

type MealMenuIdentityCompassMenuIdentityFragment = (
  { readonly __typename: 'CompassMenuIdentity' }
  & Pick<CompassMenuIdentity, 'id' | 'isOverridden'>
);

type MealMenuIdentitySodexoMenuIdentityFragment = (
  { readonly __typename: 'SodexoMenuIdentity' }
  & Pick<SodexoMenuIdentity, 'id' | 'isOverridden'>
);

export type MealMenuIdentityFragment = MealMenuIdentityBonAppetitMenuIdentityFragment | MealMenuIdentityCbordMenuIdentityFragment | MealMenuIdentityCompassMenuIdentityFragment | MealMenuIdentitySodexoMenuIdentityFragment;

export type MealOptionSuggestionCursorConnectionQueryVariables = Exact<{
  macros: MacrosInput;
  mealType: MealType;
  maxErrorPercent: Scalars['Int'];
  minErrorPercent: Maybe<Scalars['Int']>;
  avoidFoodIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  avoidFoodGroupIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  pagination: Maybe<CursorPaginationInput>;
  localeCode: Maybe<Scalars['String']>;
}>;


export type MealOptionSuggestionCursorConnectionQuery = { readonly mealOptionSuggestionCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<FullServingAmountGroupFragment> } };

export type FullServingAmountGroupFragment = { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> };

export type SetMealOptionsForMealTemplatesMutationVariables = Exact<{
  input: SetMealOptionsForMealTemplatesInput;
}>;


export type SetMealOptionsForMealTemplatesMutation = { readonly setMealOptionsForMealTemplates: { readonly mealTemplates: ReadonlyArray<MealTemplateWithOptionsExchangeMealTemplateFragment | MealTemplateWithOptionsMacroMealTemplateFragment> } };

type MealTemplateWithOptionsExchangeMealTemplateFragment = (
  Pick<ExchangeMealTemplate, 'id'>
  & { readonly mealOptions: ReadonlyArray<Pick<MealOption, 'id'>> }
);

type MealTemplateWithOptionsMacroMealTemplateFragment = (
  Pick<MacroMealTemplate, 'id'>
  & { readonly mealOptions: ReadonlyArray<Pick<MealOption, 'id'>> }
);

export type MealTemplateWithOptionsFragment = MealTemplateWithOptionsExchangeMealTemplateFragment | MealTemplateWithOptionsMacroMealTemplateFragment;

export type MealPlanTemplateForAssignmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MealPlanTemplateForAssignmentQuery = { readonly mealPlanTemplate: (
    { readonly __typename: 'ExchangeMealPlanTemplate' }
    & Pick<ExchangeMealPlanTemplate, 'id' | 'name'>
  ) | (
    { readonly __typename: 'MacroMealPlanTemplate' }
    & Pick<MacroMealPlanTemplate, 'id' | 'name'>
    & { readonly macroProtocol: { readonly calorieBudget: Maybe<(
        Pick<MealPlanTemplateCalorieBudget, 'rmrMethod'>
        & { readonly goals: ReadonlyArray<(
          Pick<MealPlanTemplateGoal, 'id' | 'name' | 'kcalOffset'>
          & { readonly goalType: Pick<GoalType, 'id' | 'name'> }
        )> }
      )> } }
  ) };

export type AthletesForMealPlanTemplateAssignmentQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  teamIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  weightTargetTypeId: Maybe<Scalars['ID']>;
  anthropometryFilter: Maybe<AthleteAnthropometryFilter>;
  rmrMethod: Maybe<RmrMethod>;
}>;


export type AthletesForMealPlanTemplateAssignmentQuery = { readonly athletesForMealPlanTemplateAssignmentCursorConnection: { readonly edges: ReadonlyArray<AthleteForMealPlanTemplateAssignmentFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type CreateBulkAssignMealPlanTemplateJobMutationVariables = Exact<{
  input: CreateBulkAssignMealPlanTemplateJobInput;
}>;


export type CreateBulkAssignMealPlanTemplateJobMutation = { readonly createBulkAssignMealPlanTemplateJob: Pick<BulkAssignMealPlanTemplateJob, 'id'> };

export type AthleteForMealPlanTemplateAssignmentFragment = (
  Pick<Athlete, 'id' | 'firstName' | 'lastName'>
  & { readonly myNamedTagsOffsetConnection: { readonly edges: ReadonlyArray<NamedTagForSelectionFragment>, readonly pageInfo: Pick<OffsetPageInfo, 'total'> }, readonly team: Pick<Team, 'id' | 'name'>, readonly mostRecentAnthropometryEntryForRmrMethod: Maybe<AnthropometryEntryFragment> }
);

export type AssignMealPlanTemplateJobOffsetConnectionQueryVariables = Exact<{
  input: OffsetPaginationInput;
}>;


export type AssignMealPlanTemplateJobOffsetConnectionQuery = { readonly bulkAssignMealPlanTemplateJobOffsetConnection: { readonly edges: ReadonlyArray<(
      Pick<BulkAssignMealPlanTemplateJob, 'id' | 'createdAt' | 'queuedAt' | 'processingAt' | 'finishedAt' | 'name' | 'athleteCount' | 'athleteSucceededCount' | 'athleteFailedCount'>
      & { readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
    )>, readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'> } };

export type FoodsByIdQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type FoodsByIdQuery = { readonly foodsById: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> };

export type FoodGroupsByIdQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type FoodGroupsByIdQuery = { readonly foodGroupsById: ReadonlyArray<FullFoodGroupWithFoodsFragment> };

export type MealOptionsServingsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type MealOptionsServingsQuery = { readonly servings: ReadonlyArray<FullServingFragment> };

export type StandardSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type StandardSchedulesQuery = { readonly schedules: ReadonlyArray<ScheduleWithTeamFragment> };

export type AllSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSchedulesQuery = { readonly allSchedules: ReadonlyArray<ScheduleWithTeamFragment> };

export type ScheduleWithTeamFragment = (
  Pick<Schedule, 'id' | 'name'>
  & { readonly team: Maybe<Pick<Team, 'id' | 'name'>>, readonly meals: ReadonlyArray<Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'>>, readonly activities: ReadonlyArray<Pick<Activity, 'id' | 'name' | 'start' | 'end' | 'type'>> }
);

export type MenuItemsForPrintableNutrientInfoQueryVariables = Exact<{
  menuItemIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type MenuItemsForPrintableNutrientInfoQuery = { readonly menuItems: ReadonlyArray<PrintableNutrientInfoMenuItemFragment> };

export type PrintableNutrientInfoMenuItemFragment = (
  { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment>, readonly choices: ReadonlyArray<(
    Pick<MenuItemChoice, 'id'>
    & { readonly options: ReadonlyArray<(
      Pick<MenuItemChoiceOption, 'id'>
      & { readonly servingAmounts: ReadonlyArray<Pick<ServingAmount, 'id'>> }
    )> }
  )> }
  & MenuItemPreviewFragment
);

export type MenuItemPreviewSearchFragment = (
  Pick<MenuItem, 'isMissingIngredients'>
  & MenuItemPreviewFragment
);

export type MenuItemSearchQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  pagination: CursorPaginationInput;
}>;


export type MenuItemSearchQuery = { readonly menuItemCursorConnection: { readonly edges: ReadonlyArray<MenuItemPreviewSearchFragment>, readonly pageInfo: Pick<CursorPageInfo, 'endCursor' | 'hasNextPage'> } };

export type MenuItemDialogQueryVariables = Exact<{
  menuItemId: Scalars['ID'];
}>;


export type MenuItemDialogQuery = { readonly currentMenuItem: MenuItemFormFragment };

export type ReadonlyMenuItemDialogQueryVariables = Exact<{
  menuItemId: Scalars['ID'];
}>;


export type ReadonlyMenuItemDialogQuery = { readonly menuItem: MenuItemFormFragment };

export type MenuItemAppearanceFormFragment = (
  Pick<MenuItemAppearance, 'id' | 'position' | 'maxAmount' | 'availableForOrder' | 'allowSpecialRequests'>
  & { readonly menuItem: MenuItemFormFragment }
);

export type MenuOrderFormFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id' | 'pickupTime' | 'code' | 'createdAt'>
  & { readonly mealTemplate: Maybe<MealTemplateForTargetsExchangeMealTemplateFragment | MealTemplateForTargetsMacroMealTemplateFragment>, readonly mealMenu: (
    Pick<StandaloneMealMenu, 'theme'>
    & { readonly mealMenuDiningStations: ReadonlyArray<{ readonly menuItemAppearances: ReadonlyArray<(
        Pick<MenuItemAppearance, 'id' | 'availableForOrder' | 'maxAmount' | 'allowSpecialRequests'>
        & { readonly menuItem: Pick<MenuItem, 'id'> }
      )> }> }
    & MealMenuForOrderFormFragment
  ), readonly items: ReadonlyArray<MenuOrderItemFormFragment> }
);

export type MealMenuForOrderFormFragment = (
  { readonly __typename: 'StandaloneMealMenu' }
  & Pick<StandaloneMealMenu, 'id' | 'timezone' | 'name' | 'start' | 'durationInMinutes' | 'prepTimeInMinutes' | 'lastOrderTimeBeforeEndInMinutes' | 'userOrderLimit' | 'isViewOnlyForMe' | 'theme'>
  & { mealType: StandaloneMealMenu['type'] }
);

export type MealMenuDialogQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MealMenuDialogQuery = { readonly mealMenu: (
    Pick<StandaloneMealMenu, 'id' | 'userOrderLimit'>
    & { readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStationWithItemPreviewsFragment> }
  ) };

export type MenuOrderSuggestionsAndRecentOrdersQueryVariables = Exact<{
  targetMacros: MacrosInput;
  mealMenuId: Scalars['ID'];
  avoidFoodGroupIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  limit: Maybe<Scalars['Int']>;
  menuItemIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  athleteId: Scalars['ID'];
}>;


export type MenuOrderSuggestionsAndRecentOrdersQuery = { readonly menuOrderSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestionFragment>>, readonly menuOrderCursorConnectionForItemsForAthlete: { readonly edges: ReadonlyArray<RecentMenuOrderFragment> } };

export type RecentMenuOrderFragment = { readonly items: ReadonlyArray<(
    Pick<MenuOrderItem, 'amount' | 'percentConsumed'>
    & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<(
      Pick<MenuOrderItemOption, 'amount' | 'percentConsumed'>
      & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
    )> }
  )> };

export type MealPlanPreviewItemFragment = (
  Pick<MealPlan, 'id' | 'name' | 'type' | 'startDate' | 'dates' | 'endDate' | 'isShared' | 'createdAt'>
  & { readonly macroProtocol: FullMacroProtocolFragment, readonly exchangeTargets: Maybe<ReadonlyArray<EditExchangeAmountFragment>> }
);

export type AthleteTimelineFragment = (
  Pick<Athlete, 'id' | 'birthDate' | 'sex'>
  & { readonly sharedMealPlans: ReadonlyArray<MealPlanPreviewItemFragment>, readonly likedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly user: Pick<User, 'id'>, readonly team: Pick<Team, 'id'> }
);

export type MenuItemForSelectionFormQueryVariables = Exact<{
  menuItemId: Scalars['ID'];
}>;


export type MenuItemForSelectionFormQuery = { readonly menuItem: MenuItemFormFragment };

type NotificationStubAthleteSelfOnboardedNotificationFragment = (
  { readonly __typename: 'AthleteSelfOnboardedNotification' }
  & Pick<AthleteSelfOnboardedNotification, 'id' | 'viewedAt'>
);

type NotificationStubBulkOrderStatusNotificationFragment = (
  { readonly __typename: 'BulkOrderStatusNotification' }
  & Pick<BulkOrderStatusNotification, 'id' | 'viewedAt'>
);

type NotificationStubFoodLogMealCommentNotificationFragment = (
  { readonly __typename: 'FoodLogMealCommentNotification' }
  & Pick<FoodLogMealCommentNotification, 'id' | 'viewedAt'>
);

type NotificationStubFoodLogMealEditNotificationFragment = (
  { readonly __typename: 'FoodLogMealEditNotification' }
  & Pick<FoodLogMealEditNotification, 'id' | 'viewedAt'>
);

type NotificationStubFoodLogMealImageNotificationFragment = (
  { readonly __typename: 'FoodLogMealImageNotification' }
  & Pick<FoodLogMealImageNotification, 'id' | 'viewedAt'>
);

type NotificationStubGoalReminderNotificationFragment = (
  { readonly __typename: 'GoalReminderNotification' }
  & Pick<GoalReminderNotification, 'id' | 'viewedAt'>
);

type NotificationStubMealMenuAvailableNotificationFragment = (
  { readonly __typename: 'MealMenuAvailableNotification' }
  & Pick<MealMenuAvailableNotification, 'id' | 'viewedAt'>
);

type NotificationStubMealMenuIsSharedNotificationFragment = (
  { readonly __typename: 'MealMenuIsSharedNotification' }
  & Pick<MealMenuIsSharedNotification, 'id' | 'viewedAt'>
);

type NotificationStubMealMenuOrderDueNotificationFragment = (
  { readonly __typename: 'MealMenuOrderDueNotification' }
  & Pick<MealMenuOrderDueNotification, 'id' | 'viewedAt'>
);

type NotificationStubMealPlanSharedNotificationFragment = (
  { readonly __typename: 'MealPlanSharedNotification' }
  & Pick<MealPlanSharedNotification, 'id' | 'viewedAt'>
);

type NotificationStubMenuOrderCommentNotificationFragment = (
  { readonly __typename: 'MenuOrderCommentNotification' }
  & Pick<MenuOrderCommentNotification, 'id' | 'viewedAt'>
);

type NotificationStubMenuOrderImageNotificationFragment = (
  { readonly __typename: 'MenuOrderImageNotification' }
  & Pick<MenuOrderImageNotification, 'id' | 'viewedAt'>
);

type NotificationStubMenuOrderStatusNotificationFragment = (
  { readonly __typename: 'MenuOrderStatusNotification' }
  & Pick<MenuOrderStatusNotification, 'id' | 'viewedAt'>
);

type NotificationStubTimelineMealCommentNotificationFragment = (
  { readonly __typename: 'TimelineMealCommentNotification' }
  & Pick<TimelineMealCommentNotification, 'id' | 'viewedAt'>
);

type NotificationStubTimelineMealEditNotificationFragment = (
  { readonly __typename: 'TimelineMealEditNotification' }
  & Pick<TimelineMealEditNotification, 'id' | 'viewedAt'>
);

type NotificationStubTimelineMealImageNotificationFragment = (
  { readonly __typename: 'TimelineMealImageNotification' }
  & Pick<TimelineMealImageNotification, 'id' | 'viewedAt'>
);

export type NotificationStubFragment = NotificationStubAthleteSelfOnboardedNotificationFragment | NotificationStubBulkOrderStatusNotificationFragment | NotificationStubFoodLogMealCommentNotificationFragment | NotificationStubFoodLogMealEditNotificationFragment | NotificationStubFoodLogMealImageNotificationFragment | NotificationStubGoalReminderNotificationFragment | NotificationStubMealMenuAvailableNotificationFragment | NotificationStubMealMenuIsSharedNotificationFragment | NotificationStubMealMenuOrderDueNotificationFragment | NotificationStubMealPlanSharedNotificationFragment | NotificationStubMenuOrderCommentNotificationFragment | NotificationStubMenuOrderImageNotificationFragment | NotificationStubMenuOrderStatusNotificationFragment | NotificationStubTimelineMealCommentNotificationFragment | NotificationStubTimelineMealEditNotificationFragment | NotificationStubTimelineMealImageNotificationFragment;

export type RecipeDuplicateDialogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RecipeDuplicateDialogQuery = { readonly recipe: RecipeFullFragment };

export type CreateRecipeMutationVariables = Exact<{
  input: CreateRecipeFullInput;
}>;


export type CreateRecipeMutation = { readonly createRecipeFull: { readonly recipe: RecipeFullFragment } };

export type EditRecipeFullMutationVariables = Exact<{
  input: EditRecipeFullInput;
}>;


export type EditRecipeFullMutation = { readonly editRecipeFull: { readonly recipe: RecipeFullFragment } };

export type EditRecipePartialMutationVariables = Exact<{
  input: EditRecipePartialInput;
}>;


export type EditRecipePartialMutation = { readonly editRecipePartial: { readonly recipe: RecipeFullFragment } };

export type RecipeEditDialogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RecipeEditDialogQuery = { readonly recipe: RecipeFullFragment };

export type RecipeFullFragment = (
  Pick<Recipe, 'hasFullAccess' | 'isAiGenerated' | 'isAiTranscribed'>
  & { readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly descendantFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly descendantRecipes: ReadonlyArray<RecipeWithIngredientsFragment>, readonly ingredients: ReadonlyArray<FullServingAmountFragment>, readonly servings: ReadonlyArray<(
    Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
    & { readonly unit: Maybe<Pick<Unit, 'id' | 'name'>> }
    & FullServingFragment
  )>, readonly owner: Pick<User, 'id'>, readonly orgGroup: Maybe<Pick<OrgGroup, 'id'>> }
  & RecipeFragment
);

export type GetPossibleGptRecipeByNameMutationVariables = Exact<{
  input: GptRecipeByNameInput;
}>;


export type GetPossibleGptRecipeByNameMutation = { readonly getPossibleGPTRecipeByName: GptRecipeFragment };

export type GetPossibleGptRecipeFromImageMutationVariables = Exact<{
  input: GptRecipeFromImageInput;
}>;


export type GetPossibleGptRecipeFromImageMutation = { readonly getPossibleGPTRecipeFromImage: GptRecipeFragment };

export type GptRecipeFragment = (
  Pick<GptRecipePayload, 'name' | 'steps' | 'aiType'>
  & { readonly servingYield: Maybe<Pick<GptServingYield, 'amount' | 'name'>>, readonly ingredients: ReadonlyArray<(
    Pick<GptServingAmount, 'position' | 'amount'>
    & { readonly serving: FullServingFragment }
  )>, readonly unmatchedIngredients: { readonly unmatchedName: ReadonlyArray<UnmatchedIngredientFragment>, readonly unmatchedServings: ReadonlyArray<UnmatchedIngredientFragment> } }
);

export type UnmatchedIngredientFragment = Pick<UnmatchedIngredient, 'amount' | 'foodName' | 'servingName'>;

export type RestaurantLocationSearchQueryVariables = Exact<{
  deliveryLocationId: Scalars['ID'];
  restaurantIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  restaurantTypeIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  paymentMethodIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  cuisineIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  dietIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  pagination: Maybe<CursorPaginationInput>;
}>;


export type RestaurantLocationSearchQuery = { readonly restaurantLocationSearch: { readonly edges: ReadonlyArray<RestaurantLocationSearchResultFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type RestaurantLocationSearchResultFragment = { readonly restaurant: (
    Pick<Restaurant, 'id' | 'name'>
    & { readonly restaurantType: Maybe<Pick<RestaurantType, 'id' | 'name'>> }
  ), readonly restaurantLocation: (
    Pick<RestaurantLocation, 'googlePlaceId'>
    & { readonly address: Maybe<Pick<Address, 'displayName'>> }
    & RestaurantLocationPreviewFragment
  ), readonly restaurantMenu: Pick<RestaurantMenu, 'id'> };

export type RestaurantMenuSearchQueryVariables = Exact<{
  restaurantTypeIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  paymentMethodIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  cuisineIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  dietIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  pagination: Maybe<CursorPaginationInput>;
}>;


export type RestaurantMenuSearchQuery = { readonly restaurantMenuSearch: { readonly edges: ReadonlyArray<RestaurantMenuSearchResultFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type RestaurantMenuSearchResultFragment = { readonly restaurant: (
    Pick<Restaurant, 'id' | 'name'>
    & { readonly restaurantType: Maybe<Pick<RestaurantType, 'id' | 'name'>> }
  ), readonly restaurantMenu: Pick<RestaurantMenu, 'id'> };

export type CuisineCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type CuisineCursorConnectionQuery = { readonly cuisineCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<CuisineFragment> } };

export type DietCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type DietCursorConnectionQuery = { readonly dietCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<DietFragment> } };

export type PaymentMethodCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type PaymentMethodCursorConnectionQuery = { readonly paymentMethodCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<PaymentMethodFragment> } };

export type RestaurantTypeCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type RestaurantTypeCursorConnectionQuery = { readonly restaurantTypeCursorConnection: { readonly edges: ReadonlyArray<RestaurantTypeFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type TeamLabelCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type TeamLabelCursorConnectionQuery = { readonly teamLabelCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<TeamLabelFragment> } };

export type DeliveryLocationCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  teamLabelId: Maybe<Scalars['ID']>;
}>;


export type DeliveryLocationCursorConnectionQuery = { readonly deliveryLocationCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<DeliveryLocationMenuLinkFormFragment> } };

export type ScheduleBasicFragment = (
  Pick<Schedule, 'id' | 'name'>
  & { readonly meals: ReadonlyArray<MealFragment>, readonly activities: ReadonlyArray<ActivityFragment> }
);

export type ServingWithUnitsFragment = (
  Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
  & { readonly unit: Maybe<UnitFragment> }
);

export type UnitConversionFullFragment = (
  Pick<UnitConversion, 'fromQuantity' | 'toQuantity'>
  & { readonly fromUnit: UnitFragment, readonly toUnit: UnitFragment }
);

export type UnitConversionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitConversionsQuery = { readonly unitConversions: ReadonlyArray<UnitConversionFullFragment> };

export type ServingAmountForConversionFragment = (
  { readonly __typename: 'ServingAmount' }
  & Pick<ServingAmount, 'id' | 'amount' | 'position'>
  & { readonly serving: (
    Pick<Serving, 'id' | 'units' | 'customUnits' | 'unitPrefix' | 'unitSuffix' | 'weight'>
    & { readonly foodOrRecipe: Pick<BrandedFood, 'id' | 'name'> | Pick<GenericFood, 'id' | 'name'> | Pick<Recipe, 'id' | 'name'>, readonly unit: Maybe<Pick<Unit, 'id' | 'name'>> }
  ) }
);

export type UnitsForNewServingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsForNewServingsQuery = { readonly gramUnit: UnitFragment, readonly ounceUnit: UnitFragment };

export type CreateWeightUnitServingForFoodMutationVariables = Exact<{
  input: CreateWeightUnitServingForFoodInput;
}>;


export type CreateWeightUnitServingForFoodMutation = { readonly createWeightUnitServingForFood: Maybe<(
    Pick<CreateWeightUnitServingForFoodPayload, 'amount'>
    & { readonly serving: FullServingFragment }
  )> };

export type TerritoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type TerritoriesQuery = { readonly territories: ReadonlyArray<TerritoryFragment> };

export type EditAthletesMutationVariables = Exact<{
  input: ReadonlyArray<EditAthleteAndAnthroInput> | EditAthleteAndAnthroInput;
}>;


export type EditAthletesMutation = { readonly editAthletes: { readonly athletes: ReadonlyArray<AthleteFragment> } };

export type TeamsForImportAthleteQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamsForImportAthleteQuery = { readonly teams: ReadonlyArray<TeamsForImportAthleteTeamFragment> };

export type TeamsForImportAthleteTeamFragment = (
  Pick<Team, 'id' | 'name' | 'gender'>
  & { readonly sport: Maybe<(
    Pick<Sport, 'name'>
    & { readonly positions: ReadonlyArray<Pick<Position, 'id' | 'name'>> }
  )> }
);

export type OrgFoodsQueryVariables = Exact<{
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
}>;


export type OrgFoodsQuery = { readonly orgFoods: ReadonlyArray<BulkImportFoodBrandedFoodFragment | BulkImportFoodGenericFoodFragment> };

export type CreateOrgGenericFoodsMutationVariables = Exact<{
  input: BulkImportCreateGenericFoodsInput;
}>;


export type CreateOrgGenericFoodsMutation = { readonly bulkImportCreateGenericFoods: { readonly genericFoods: ReadonlyArray<BulkImportFoodGenericFoodFragment> } };

export type UpdateOrgGenericFoodsMutationVariables = Exact<{
  input: BulkImportCreateAndDeprecateGenericFoodsInput;
}>;


export type UpdateOrgGenericFoodsMutation = { readonly bulkImportCreateAndDeprecateGenericFoods: { readonly genericFoods: ReadonlyArray<BulkImportFoodGenericFoodFragment> } };

export type RestaurantFoodsQueryVariables = Exact<{
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  restaurantId: Scalars['ID'];
}>;


export type RestaurantFoodsQuery = { readonly restaurantFoods: ReadonlyArray<BulkImportFoodBrandedFoodFragment> };

export type CreateRestaurantFoodsMutationVariables = Exact<{
  input: BulkImportCreateRestautantBrandedFoodsInput;
}>;


export type CreateRestaurantFoodsMutation = { readonly bulkImportCreateRestaurantBrandedFood: { readonly brandedFoods: ReadonlyArray<BulkImportFoodBrandedFoodFragment> } };

export type UpdateRestaurantFoodsMutationVariables = Exact<{
  input: BulkImportEditRestautantBrandedFoodsInput;
}>;


export type UpdateRestaurantFoodsMutation = { readonly bulkImportEditRestaurantBrandedFood: { readonly brandedFoods: ReadonlyArray<BulkImportFoodBrandedFoodFragment> } };

type BulkImportFoodBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'updatedAt'>
  & { readonly brand: Maybe<BrandFragment>, readonly nutrientAmounts: ReadonlyArray<NutrientAmountFragment>, readonly defaultServing: (
    ServingWithUnitsFragment
    & FullServingFragment
  ), readonly servings: ReadonlyArray<Pick<Serving, 'id'>>, readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>>, readonly foodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>> }
  & FoodBrandedFoodFragment
);

type BulkImportFoodGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'updatedAt'>
  & { readonly nutrientAmounts: ReadonlyArray<NutrientAmountFragment>, readonly defaultServing: (
    ServingWithUnitsFragment
    & FullServingFragment
  ), readonly servings: ReadonlyArray<Pick<Serving, 'id'>>, readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>>, readonly foodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>> }
  & FoodGenericFoodFragment
);

export type BulkImportFoodFragment = BulkImportFoodBrandedFoodFragment | BulkImportFoodGenericFoodFragment;

export type MyOrgFullySyncedQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrgFullySyncedQuery = { readonly myOrg: Pick<Org, 'id' | 'isFullySynced' | 'isNotemealLinked'> };

export type ApiCredentialsAndSchemaQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiCredentialsAndSchemaQuery = Pick<Query, 'webVersion' | 'nixAppId' | 'nixAppKey'>;

export type PublishedNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PublishedNotificationSubscription = { readonly notification: { readonly payload: { readonly notification: NotificationWebAthleteSelfOnboardedNotificationFragment | NotificationWebBulkOrderStatusNotificationFragment | NotificationWebFoodLogMealCommentNotificationFragment | NotificationWebFoodLogMealEditNotificationFragment | NotificationWebFoodLogMealImageNotificationFragment | NotificationWebGoalReminderNotificationFragment | NotificationWebMealMenuAvailableNotificationFragment | NotificationWebMealMenuIsSharedNotificationFragment | NotificationWebMealMenuOrderDueNotificationFragment | NotificationWebMealPlanSharedNotificationFragment | NotificationWebMenuOrderCommentNotificationFragment | NotificationWebMenuOrderImageNotificationFragment | NotificationWebMenuOrderStatusNotificationFragment | NotificationWebTimelineMealCommentNotificationFragment | NotificationWebTimelineMealEditNotificationFragment | NotificationWebTimelineMealImageNotificationFragment } } };

type NotificationWebAthleteSelfOnboardedNotificationFragment = (
  { readonly __typename: 'AthleteSelfOnboardedNotification' }
  & NotificationStubAthleteSelfOnboardedNotificationFragment
);

type NotificationWebBulkOrderStatusNotificationFragment = (
  { readonly __typename: 'BulkOrderStatusNotification' }
  & NotificationStubBulkOrderStatusNotificationFragment
);

type NotificationWebFoodLogMealCommentNotificationFragment = (
  { readonly __typename: 'FoodLogMealCommentNotification' }
  & FoodLogMealCommentNotificationWebFragment
  & NotificationStubFoodLogMealCommentNotificationFragment
);

type NotificationWebFoodLogMealEditNotificationFragment = (
  { readonly __typename: 'FoodLogMealEditNotification' }
  & FoodLogMealEditNotificationWebFragment
  & NotificationStubFoodLogMealEditNotificationFragment
);

type NotificationWebFoodLogMealImageNotificationFragment = (
  { readonly __typename: 'FoodLogMealImageNotification' }
  & FoodLogMealImageNotificationWebFragment
  & NotificationStubFoodLogMealImageNotificationFragment
);

type NotificationWebGoalReminderNotificationFragment = (
  { readonly __typename: 'GoalReminderNotification' }
  & NotificationStubGoalReminderNotificationFragment
);

type NotificationWebMealMenuAvailableNotificationFragment = (
  { readonly __typename: 'MealMenuAvailableNotification' }
  & NotificationStubMealMenuAvailableNotificationFragment
);

type NotificationWebMealMenuIsSharedNotificationFragment = (
  { readonly __typename: 'MealMenuIsSharedNotification' }
  & NotificationStubMealMenuIsSharedNotificationFragment
);

type NotificationWebMealMenuOrderDueNotificationFragment = (
  { readonly __typename: 'MealMenuOrderDueNotification' }
  & NotificationStubMealMenuOrderDueNotificationFragment
);

type NotificationWebMealPlanSharedNotificationFragment = (
  { readonly __typename: 'MealPlanSharedNotification' }
  & NotificationStubMealPlanSharedNotificationFragment
);

type NotificationWebMenuOrderCommentNotificationFragment = (
  { readonly __typename: 'MenuOrderCommentNotification' }
  & MenuOrderCommentNotificationWebFragment
  & NotificationStubMenuOrderCommentNotificationFragment
);

type NotificationWebMenuOrderImageNotificationFragment = (
  { readonly __typename: 'MenuOrderImageNotification' }
  & MenuOrderImageNotificationWebFragment
  & NotificationStubMenuOrderImageNotificationFragment
);

type NotificationWebMenuOrderStatusNotificationFragment = (
  { readonly __typename: 'MenuOrderStatusNotification' }
  & NotificationStubMenuOrderStatusNotificationFragment
);

type NotificationWebTimelineMealCommentNotificationFragment = (
  { readonly __typename: 'TimelineMealCommentNotification' }
  & TimelineMealCommentNotificationWebFragment
  & NotificationStubTimelineMealCommentNotificationFragment
);

type NotificationWebTimelineMealEditNotificationFragment = (
  { readonly __typename: 'TimelineMealEditNotification' }
  & TimelineMealEditNotificationWebFragment
  & NotificationStubTimelineMealEditNotificationFragment
);

type NotificationWebTimelineMealImageNotificationFragment = (
  { readonly __typename: 'TimelineMealImageNotification' }
  & TimelineMealImageNotificationWebFragment
  & NotificationStubTimelineMealImageNotificationFragment
);

export type NotificationWebFragment = NotificationWebAthleteSelfOnboardedNotificationFragment | NotificationWebBulkOrderStatusNotificationFragment | NotificationWebFoodLogMealCommentNotificationFragment | NotificationWebFoodLogMealEditNotificationFragment | NotificationWebFoodLogMealImageNotificationFragment | NotificationWebGoalReminderNotificationFragment | NotificationWebMealMenuAvailableNotificationFragment | NotificationWebMealMenuIsSharedNotificationFragment | NotificationWebMealMenuOrderDueNotificationFragment | NotificationWebMealPlanSharedNotificationFragment | NotificationWebMenuOrderCommentNotificationFragment | NotificationWebMenuOrderImageNotificationFragment | NotificationWebMenuOrderStatusNotificationFragment | NotificationWebTimelineMealCommentNotificationFragment | NotificationWebTimelineMealEditNotificationFragment | NotificationWebTimelineMealImageNotificationFragment;

export type FoodLogMealCommentNotificationWebFragment = { readonly timelineMeal: Maybe<(
    { readonly comments: ReadonlyArray<CommentTimelineFragment> }
    & TimelineMealForNotificationWebFragment
  )> };

export type FoodLogMealImageNotificationWebFragment = { readonly timelineMeal: Maybe<(
    { readonly images: ReadonlyArray<Pick<Image, 'id' | 'url' | 'position'>> }
    & TimelineMealForNotificationWebFragment
  )> };

export type MenuOrderCommentNotificationWebFragment = { readonly timelineMeal: Maybe<(
    { readonly comments: ReadonlyArray<CommentTimelineFragment> }
    & TimelineMealForNotificationWebFragment
  )> };

export type MenuOrderImageNotificationWebFragment = { readonly timelineMeal: Maybe<(
    { readonly images: ReadonlyArray<Pick<Image, 'id' | 'url' | 'position'>> }
    & TimelineMealForNotificationWebFragment
  )> };

export type FoodLogMealEditNotificationWebFragment = { readonly timelineMeal: Maybe<TimelineMealForNotificationWebFragment> };

export type TimelineMealCommentNotificationWebFragment = { readonly timelineMeal: Maybe<TimelineMealForNotificationWebFragment> };

export type TimelineMealEditNotificationWebFragment = { readonly timelineMeal: Maybe<TimelineMealForNotificationWebFragment> };

export type TimelineMealImageNotificationWebFragment = { readonly timelineMeal: Maybe<(
    { readonly images: ReadonlyArray<Pick<Image, 'id' | 'url' | 'position'>> }
    & TimelineMealForNotificationWebFragment
  )> };

export type TimelineMealForNotificationWebFragment = (
  Pick<TimelineMeal, 'id'>
  & { readonly athlete: Maybe<(
    Pick<Athlete, 'id'>
    & { readonly team: Pick<Team, 'id'> }
  )> }
);

export type CanBeStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type CanBeStaffQuery = Pick<Query, 'canBeStaff'>;

export type AthleteNavQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AthleteNavQuery = { readonly athlete: NavAthleteFragment };

export type NavAthleteFragment = (
  Pick<Athlete, 'firstName' | 'lastName'>
  & { readonly team: Pick<Team, 'id' | 'name'> }
);

export type MenuAttendanceNavQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MenuAttendanceNavQuery = { readonly mealMenu: NavMealMenuFragment };

export type NavMealMenuFragment = (
  Pick<StandaloneMealMenu, 'name' | 'start' | 'durationInMinutes'>
  & { readonly teams: ReadonlyArray<Pick<Team, 'id' | 'name'>> }
);

export type MenuBuilderNavQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MenuBuilderNavQuery = { readonly plannedMenu: NavPlannedMenuFragment };

export type NavPlannedMenuFragment = Pick<PlannedMenu, 'name'>;

export type MessageOptionFragment = Pick<MessageOption, 'id' | 'userId' | 'firstName' | 'lastName' | 'teamName'>;

export type UsersICanMessageCursorConnectionQueryVariables = Exact<{
  role: Role;
  teamId: Maybe<Scalars['String']>;
  query: Scalars['String'];
  input: CursorPaginationInput;
}>;


export type UsersICanMessageCursorConnectionQuery = { readonly usersICanMessageCursorConnection: { readonly edges: ReadonlyArray<MessageOptionFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type MeForStreamChatQueryVariables = Exact<{ [key: string]: never; }>;


export type MeForStreamChatQuery = (
  Pick<Query, 'myStreamChatToken'>
  & { readonly myUser: Pick<User, 'id' | 'firstName' | 'lastName'> }
);

export type TeamOptionFragment = Pick<Team, 'id' | 'name'>;

export type TeamsForFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamsForFilterQuery = { readonly teams: ReadonlyArray<TeamOptionFragment> };

export type TeamNavQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamNavQuery = { readonly team: NavTeamFragment };

export type NavTeamFragment = Pick<Team, 'name'>;

export type MyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type MyUserQuery = { readonly myUser: Pick<User, 'username'> };

export type MealMenusWithCheckInInRangeQueryVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  selectedTeamIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type MealMenusWithCheckInInRangeQuery = { readonly mealMenusWithCheckInInRange: ReadonlyArray<(
    Pick<MealMenusWithCheckinPayload, 'mealMenuExpectedAttendeeCount' | 'mealMenuActualAttendeeCount' | 'attendeeUnlistedCount' | 'attendeeListedCount'>
    & { readonly mealMenu: (
      Pick<StandaloneMealMenu, 'id' | 'name' | 'type' | 'start'>
      & { readonly teams: ReadonlyArray<Pick<Team, 'id' | 'name'>> }
    ) }
  )> };

export type StaffDashboardRecipePreviewFragment = (
  Pick<Recipe, 'id' | 'name' | 'mealTypes' | 'isShared'>
  & { readonly createdBy: Pick<User, 'firstName' | 'lastName' | 'id'>, readonly servings: ReadonlyArray<Pick<Serving, 'perRecipeYield' | 'isDefault' | 'units'>> }
);

export type StaffDashboardRecipesQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type StaffDashboardRecipesQuery = { readonly recipeOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<StaffDashboardRecipePreviewFragment> } };

export type StaffScoringSystemsQueryVariables = Exact<{
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
}>;


export type StaffScoringSystemsQuery = { readonly scoringSystemOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<ScoringSystemFragment> } };

export type ScoringSystemsQueryVariables = Exact<{ [key: string]: never; }>;


export type ScoringSystemsQuery = { readonly scoringSystems: ReadonlyArray<ScoringSystemFragment> };

export type MyScoringSystemQueryVariables = Exact<{ [key: string]: never; }>;


export type MyScoringSystemQuery = { readonly myScoringSystem: Maybe<ScoringSystemFragment> };

export type ExchangeSetTableQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type ExchangeSetTableQuery = { readonly sharedExchangeSetOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<FullExchangeSetFragment> } };

export type CreateSharedExchangeSetMutationVariables = Exact<{
  input: CreateExchangeSetInput;
}>;


export type CreateSharedExchangeSetMutation = { readonly createSharedExchangeSet: { readonly exchangeSet: FullExchangeSetFragment } };

export type EditSharedExchangeSetMutationVariables = Exact<{
  input: EditExchangeSetInput;
}>;


export type EditSharedExchangeSetMutation = { readonly editSharedExchangeSet: { readonly exchangeSet: FullExchangeSetFragment } };

export type OrgNavQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrgNavQuery = { readonly org: NavOrgFragment };

export type NavOrgFragment = Pick<Org, 'name'>;

export type RestaurantNavQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestaurantNavQuery = { readonly restaurant: NavRestaurantFragment };

export type NavRestaurantFragment = Pick<Restaurant, 'name'>;

export type StaffDashboardCuisinesQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type StaffDashboardCuisinesQuery = { readonly cuisineOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<CuisineFragment> } };

export type CuisineFragment = Pick<Cuisine, 'id' | 'name'>;

export type CreateCuisineMutationVariables = Exact<{
  input: CreateCuisineInput;
}>;


export type CreateCuisineMutation = { readonly createCuisine: { readonly cuisine: CuisineFragment } };

export type EditCuisineMutationVariables = Exact<{
  input: EditCuisineInput;
}>;


export type EditCuisineMutation = { readonly editCuisine: { readonly cuisine: CuisineFragment } };

export type DeleteCuisineMutationVariables = Exact<{
  input: DeleteCuisineInput;
}>;


export type DeleteCuisineMutation = { readonly deleteCuisine: Pick<DeleteCuisinePayload, 'id'> };

export type StaffDashboardPaymentMethodsQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type StaffDashboardPaymentMethodsQuery = { readonly paymentMethodOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<PaymentMethodFragment> } };

export type PaymentMethodFragment = Pick<PaymentMethod, 'id' | 'name'>;

export type CreatePaymentMethodMutationVariables = Exact<{
  input: CreatePaymentMethodInput;
}>;


export type CreatePaymentMethodMutation = { readonly createPaymentMethod: { readonly paymentMethod: PaymentMethodFragment } };

export type EditPaymentMethodMutationVariables = Exact<{
  input: EditPaymentMethodInput;
}>;


export type EditPaymentMethodMutation = { readonly editPaymentMethod: { readonly paymentMethod: PaymentMethodFragment } };

export type DeletePaymentMethodMutationVariables = Exact<{
  input: DeletePaymentMethodInput;
}>;


export type DeletePaymentMethodMutation = { readonly deletePaymentMethod: Pick<DeletePaymentMethodPayload, 'id'> };

export type IntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IntegrationsQuery = Pick<Query, 'integrations'>;

export type MarkNotificationsAsViewedMutationVariables = Exact<{
  input: MarkNotificationsAsViewedInput;
}>;


export type MarkNotificationsAsViewedMutation = { readonly markNotificationsAsViewed: { readonly notifications: ReadonlyArray<Pick<AthleteSelfOnboardedNotification, 'id' | 'viewedAt'> | Pick<BulkOrderStatusNotification, 'id' | 'viewedAt'> | Pick<FoodLogMealCommentNotification, 'id' | 'viewedAt'> | Pick<FoodLogMealEditNotification, 'id' | 'viewedAt'> | Pick<FoodLogMealImageNotification, 'id' | 'viewedAt'> | Pick<GoalReminderNotification, 'id' | 'viewedAt'> | Pick<MealMenuAvailableNotification, 'id' | 'viewedAt'> | Pick<MealMenuIsSharedNotification, 'id' | 'viewedAt'> | Pick<MealMenuOrderDueNotification, 'id' | 'viewedAt'> | Pick<MealPlanSharedNotification, 'id' | 'viewedAt'> | Pick<MenuOrderCommentNotification, 'id' | 'viewedAt'> | Pick<MenuOrderImageNotification, 'id' | 'viewedAt'> | Pick<MenuOrderStatusNotification, 'id' | 'viewedAt'> | Pick<TimelineMealCommentNotification, 'id' | 'viewedAt'> | Pick<TimelineMealEditNotification, 'id' | 'viewedAt'> | Pick<TimelineMealImageNotification, 'id' | 'viewedAt'>> } };

export type UserOrgForReportingQueryVariables = Exact<{ [key: string]: never; }>;


export type UserOrgForReportingQuery = { readonly myUser: ReportingUserFragment, readonly myOrgForReporting: Maybe<ReportingOrgFragment> };

export type ReportingOrgFragment = Pick<OrgForReporting, 'id' | 'name' | 'territoryName' | 'teamworksId' | 'performanceKitchen'>;

export type ReportingUserFragment = Pick<User, 'id' | 'teamworksId' | 'email' | 'firstName' | 'lastName' | 'isActive' | 'dateJoined'>;

export type AthleteTimelineQueryVariables = Exact<{
  athleteId: Scalars['ID'];
}>;


export type AthleteTimelineQuery = { readonly athlete: (
    Pick<Athlete, 'id' | 'sex' | 'birthDate'>
    & { readonly user: Pick<User, 'id'>, readonly team: (
      Pick<Team, 'id'>
      & { readonly sport: Maybe<Pick<Sport, 'name'>> }
    ), readonly likedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly mealPlans: ReadonlyArray<(
      { readonly mealTemplates: ReadonlyArray<{ readonly meal: MealFragment } | { readonly meal: MealFragment }>, readonly activityTemplates: ReadonlyArray<{ readonly activity: ActivityFragment }> }
      & PreviewMealPlanFragment
    )>, readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment>, readonly currentGoal: Maybe<FullGoalFragment> }
  ), readonly goalTypes: ReadonlyArray<GoalTypeFragment>, readonly exchangeSets: ReadonlyArray<ExchangeSetFragment> };

export type AthleteTimelinePreviewQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type AthleteTimelinePreviewQuery = { readonly athlete: (
    Pick<Athlete, 'id'>
    & { readonly timelineMealsInRange: ReadonlyArray<TimelineMealForGroupByDateFragment> }
  ) };

export type AthleteRecentTimelineCalendarDatesWithActivityQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  timezone: Scalars['Timezone'];
}>;


export type AthleteRecentTimelineCalendarDatesWithActivityQuery = { readonly athlete: Pick<Athlete, 'id' | 'recentTimelineCalendarDatesWithActivity'> };

export type MealPlanMacroProtocolQueryVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type MealPlanMacroProtocolQuery = { readonly mealPlan: (
    Pick<MealPlan, 'id'>
    & { readonly macroProtocol: FullMacroProtocolFragment }
  ) };

export type AthleteTimelineForDateQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
}>;


export type AthleteTimelineForDateQuery = { readonly athlete: (
    Pick<Athlete, 'id'>
    & { readonly timelineDate: TimelineDateFragment, readonly mealMenusOnDate: ReadonlyArray<MealMenuTimelineFragment> }
  ) };

export type TimelineDateFragment = (
  Pick<TimelineDate, 'date' | 'timezone'>
  & { readonly mealPlan: Maybe<MealPlanTimelineFragment>, readonly meals: ReadonlyArray<TimelineMealOrTemplateTimelineMealFragment | TimelineMealOrTemplateTimelineMealTemplateFragment>, readonly activities: ReadonlyArray<TimelineActivityOrTemplateFoodLogActivityFragment | TimelineActivityOrTemplateTimelineActivityTemplateFragment> }
);

type TimelineActivityOrTemplateFoodLogActivityFragment = FoodLogActivityTimelineFragment;

type TimelineActivityOrTemplateTimelineActivityTemplateFragment = TimelineActivityTemplateFragment;

export type TimelineActivityOrTemplateFragment = TimelineActivityOrTemplateFoodLogActivityFragment | TimelineActivityOrTemplateTimelineActivityTemplateFragment;

type TimelineMealOrTemplateTimelineMealFragment = TimelineMealFragment;

type TimelineMealOrTemplateTimelineMealTemplateFragment = TimelineMealTemplateFragment;

export type TimelineMealOrTemplateFragment = TimelineMealOrTemplateTimelineMealFragment | TimelineMealOrTemplateTimelineMealTemplateFragment;

export type TimelineMealTemplateFragment = (
  { readonly __typename: 'TimelineMealTemplate' }
  & Pick<TimelineMealTemplate, 'start' | 'durationInMinutes' | 'timezone'>
  & { readonly template: MealTemplateTimelineExchangeMealTemplateFragment | MealTemplateTimelineMacroMealTemplateFragment, readonly mealMenus: ReadonlyArray<MealMenuTimelineFragment> }
);

export type TimelineActivityTemplateFragment = (
  { readonly __typename: 'TimelineActivityTemplate' }
  & Pick<TimelineActivityTemplate, 'start' | 'durationInMinutes' | 'timezone'>
  & { readonly template: ActivityTemplateTimelineFragment }
);

export type TimelineMealBaseFragment = (
  { readonly __typename: 'TimelineMeal' }
  & Pick<TimelineMeal, 'id' | 'name' | 'type' | 'timezone' | 'start' | 'durationInMinutes'>
  & { readonly mealTemplate: Maybe<MealTemplateTimelineExchangeMealTemplateFragment | MealTemplateTimelineMacroMealTemplateFragment>, readonly mealMenus: ReadonlyArray<MealMenuTimelineFragment> }
);

export type TimelineMealFragment = (
  { readonly images: ReadonlyArray<ImageFragment>, readonly comments: ReadonlyArray<CommentTimelineFragment>, readonly notifications: ReadonlyArray<(
    { readonly __typename: 'AthleteSelfOnboardedNotification' }
    & Pick<AthleteSelfOnboardedNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'BulkOrderStatusNotification' }
    & Pick<BulkOrderStatusNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'FoodLogMealCommentNotification' }
    & Pick<FoodLogMealCommentNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'FoodLogMealEditNotification' }
    & Pick<FoodLogMealEditNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'FoodLogMealImageNotification' }
    & Pick<FoodLogMealImageNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'GoalReminderNotification' }
    & Pick<GoalReminderNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'MealMenuAvailableNotification' }
    & Pick<MealMenuAvailableNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'MealMenuIsSharedNotification' }
    & Pick<MealMenuIsSharedNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'MealMenuOrderDueNotification' }
    & Pick<MealMenuOrderDueNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'MealPlanSharedNotification' }
    & Pick<MealPlanSharedNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'MenuOrderCommentNotification' }
    & Pick<MenuOrderCommentNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'MenuOrderImageNotification' }
    & Pick<MenuOrderImageNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'MenuOrderStatusNotification' }
    & Pick<MenuOrderStatusNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'TimelineMealCommentNotification' }
    & Pick<TimelineMealCommentNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'TimelineMealEditNotification' }
    & Pick<TimelineMealEditNotification, 'id' | 'viewedAt'>
  ) | (
    { readonly __typename: 'TimelineMealImageNotification' }
    & Pick<TimelineMealImageNotification, 'id' | 'viewedAt'>
  )>, readonly servingAmounts: ReadonlyArray<FullServingAmountFragment>, readonly restaurantMenuLogs: ReadonlyArray<RestaurantMenuLogTimelineFragment>, readonly restaurantMenuLinkPlateOrders: ReadonlyArray<RestaurantMenuLinkPlateOrderSharedTimelineFragment>, readonly restaurantMenuLinkOrders: ReadonlyArray<RestaurantMenuLinkOrderSharedTimelineFragment>, readonly menuOrders: ReadonlyArray<MenuOrderTimelineFragment>, readonly mealMenuLogs: ReadonlyArray<MealMenuLogTimelineFragment> }
  & TimelineMealBaseFragment
);

export type MealMenuQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MealMenuQuery = { readonly mealMenu: MealMenuWithItemPreviewsAndTimeInfoFragment };

export type MealMenuWithItemPreviewsAndTimeInfoFragment = (
  Pick<StandaloneMealMenu, 'timezone' | 'start' | 'durationInMinutes' | 'prepTimeInMinutes' | 'lastOrderTimeBeforeEndInMinutes' | 'theme'>
  & MealMenuWithItemPreviewsFragment
);

export type RestaurantMenuLogTimelineFragment = (
  { readonly __typename: 'RestaurantMenuLog' }
  & Pick<RestaurantMenuLog, 'id' | 'dateTime' | 'timezone'>
  & { mealType: RestaurantMenuLog['type'] }
  & { readonly mealTemplate: Maybe<Pick<ExchangeMealTemplate, 'id'> | Pick<MacroMealTemplate, 'id'>>, readonly restaurantMenu: Pick<RestaurantMenu, 'id'>, readonly restaurant: Pick<Restaurant, 'name'>, readonly items: ReadonlyArray<(
    Pick<RestaurantMenuLogItem, 'id'>
    & MenuSelectionItemFormRestaurantMenuLogItemFragment
  )> }
);

export type FoodLogActivityTimelineFragment = (
  { readonly __typename: 'FoodLogActivity' }
  & Pick<FoodLogActivity, 'id' | 'name' | 'date' | 'startTime' | 'endTime' | 'timezone' | 'rpe'>
  & { activityType: FoodLogActivity['type'] }
  & { readonly activityTemplate: Maybe<Pick<ActivityTemplate, 'id'>> }
);

export type MenuOrderTimelineFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id' | 'pickupTime' | 'code'>
  & { readonly mealTemplate: Maybe<Pick<ExchangeMealTemplate, 'id'> | Pick<MacroMealTemplate, 'id'>>, readonly mealMenu: Pick<StandaloneMealMenu, 'id' | 'name'>, readonly items: ReadonlyArray<MenuOrderItemFormFragment> }
);

export type MealMenuLogTimelineFragment = (
  { readonly __typename: 'MealMenuLog' }
  & Pick<MealMenuLog, 'id'>
  & { readonly mealMenu: Pick<StandaloneMealMenu, 'id' | 'name'>, readonly items: ReadonlyArray<MealMenuLogItemFormFragment> }
);

export type MealMenuTimelineFragment = (
  Pick<StandaloneMealMenu, 'isOrderable' | 'theme'>
  & { readonly mealMenuDiningStations: ReadonlyArray<Pick<MealMenuDiningStation, 'id'>> }
  & MealMenuSharedTimelineFragment
);

export type MealMenuWithItemPreviewsFragment = (
  Pick<StandaloneMealMenu, 'id' | 'name' | 'isViewOnlyForMe'>
  & { readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStationWithItemPreviewsFragment>, readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLinkWithItemPreviewsFragment> }
);

export type MealPlanDateTimelineFragment = (
  { readonly __typename: 'MealPlanDate' }
  & Pick<MealPlanDate, 'date' | 'timezone'>
  & { readonly mealPlan: MealPlanTimelineFragment }
);

export type MealPlanTimelineFragment = (
  Pick<MealPlan, 'id' | 'name'>
  & { readonly athlete: Pick<Athlete, 'birthDate'>, readonly macroProtocol: FullMacroProtocolFragment, readonly exchangeTargets: Maybe<ReadonlyArray<ExchangeAmountFragment>>, readonly mealTemplates: ReadonlyArray<MealTemplateTimelineExchangeMealTemplateFragment | MealTemplateTimelineMacroMealTemplateFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateTimelineFragment>, readonly promotedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly avoidedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly promotedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly avoidedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment> }
);

type MealTemplateTimelineExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly exchangeTargets: ReadonlyArray<ExchangeAmountFragment>, readonly meal: Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'> }
);

type MealTemplateTimelineMacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'cho' | 'pro' | 'fat' | 'id'>
  & { readonly meal: Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'> }
);

export type MealTemplateTimelineFragment = MealTemplateTimelineExchangeMealTemplateFragment | MealTemplateTimelineMacroMealTemplateFragment;

export type ActivityTemplateTimelineFragment = (
  { readonly __typename: 'ActivityTemplate' }
  & Pick<ActivityTemplate, 'id'>
  & { readonly activity: Pick<Activity, 'id' | 'name' | 'start' | 'end' | 'type'> }
);

export type CommentTimelineFragment = (
  { readonly __typename: 'Comment' }
  & Pick<Comment, 'id' | 'text' | 'at'>
  & { readonly by: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly notification: Maybe<NotificationStubAthleteSelfOnboardedNotificationFragment | NotificationStubBulkOrderStatusNotificationFragment | NotificationStubFoodLogMealCommentNotificationFragment | NotificationStubFoodLogMealEditNotificationFragment | NotificationStubFoodLogMealImageNotificationFragment | NotificationStubGoalReminderNotificationFragment | NotificationStubMealMenuAvailableNotificationFragment | NotificationStubMealMenuIsSharedNotificationFragment | NotificationStubMealMenuOrderDueNotificationFragment | NotificationStubMealPlanSharedNotificationFragment | NotificationStubMenuOrderCommentNotificationFragment | NotificationStubMenuOrderImageNotificationFragment | NotificationStubMenuOrderStatusNotificationFragment | NotificationStubTimelineMealCommentNotificationFragment | NotificationStubTimelineMealEditNotificationFragment | NotificationStubTimelineMealImageNotificationFragment> }
);

export type AddMenuOrderCommentMutationVariables = Exact<{
  input: AddMenuOrderCommentInput;
}>;


export type AddMenuOrderCommentMutation = { readonly addMenuOrderComment: { readonly comment: CommentTimelineFragment } };

export type EditMealPlanDateMutationVariables = Exact<{
  input: EditMealPlanDateForAthleteInput;
}>;


export type EditMealPlanDateMutation = { readonly editMealPlanDateForAthlete: { readonly mealPlanDate: (
      Pick<MealPlanDate, 'date'>
      & MealPlanDateTimelineFragment
    ) } };

export type RemoveMealPlanDateMutationVariables = Exact<{
  input: RemoveMealPlanDateForAthleteInput;
}>;


export type RemoveMealPlanDateMutation = { readonly removeMealPlanDateForAthlete: Pick<RemoveMealPlanDateForAthletePayload, 'date'> };

export type AddFoodLogActivityWithTemplateMutationVariables = Exact<{
  input: AddFoodLogActivityWithTemplateForAthleteInput;
}>;


export type AddFoodLogActivityWithTemplateMutation = { readonly addFoodLogActivityWithTemplateForAthlete: { readonly foodLogActivity: FoodLogActivityTimelineFragment } };

export type AddFoodLogActivityWithoutTemplateMutationVariables = Exact<{
  input: AddFoodLogActivityWithoutTemplateForAthleteInput;
}>;


export type AddFoodLogActivityWithoutTemplateMutation = { readonly addFoodLogActivityWithoutTemplateForAthlete: { readonly foodLogActivity: FoodLogActivityTimelineFragment } };

export type EditFoodLogMealTemplateMutationVariables = Exact<{
  input: EditFoodLogMealTemplateInput;
}>;


export type EditFoodLogMealTemplateMutation = { readonly editFoodLogMealTemplate: { readonly foodLogMeal: (
      Pick<FoodLogMeal, 'id'>
      & { readonly mealTemplate: Maybe<Pick<ExchangeMealTemplate, 'id'> | Pick<MacroMealTemplate, 'id'>> }
    ) } };

export type RemoveFoodLogMealTemplateMutationVariables = Exact<{
  input: RemoveFoodLogMealTemplateInput;
}>;


export type RemoveFoodLogMealTemplateMutation = { readonly removeFoodLogMealTemplate: { readonly foodLogMeal: (
      Pick<FoodLogMeal, 'id'>
      & { readonly mealTemplate: Maybe<Pick<ExchangeMealTemplate, 'id'> | Pick<MacroMealTemplate, 'id'>> }
    ) } };

export type EditFoodLogMealServingAmountsMutationVariables = Exact<{
  input: EditFoodLogMealServingAmountsInput;
}>;


export type EditFoodLogMealServingAmountsMutation = { readonly editFoodLogMealServingAmounts: { readonly foodLogMeal: { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> } } };

export type EditFoodLogMealTimeWithTimezoneMutationVariables = Exact<{
  input: EditFoodLogMealTimeWithTimezoneInput;
}>;


export type EditFoodLogMealTimeWithTimezoneMutation = { readonly editFoodLogMealTimeWithTimezone: { readonly foodLogMeal: Pick<FoodLogMeal, 'id' | 'name' | 'type' | 'startTime' | 'endTime' | 'timezone'> } };

export type AddFoodLogMealCommentMutationVariables = Exact<{
  input: AddFoodLogMealCommentInput;
}>;


export type AddFoodLogMealCommentMutation = { readonly addFoodLogMealComment: { readonly comment: CommentTimelineFragment } };

export type RemoveFoodLogMealMutationVariables = Exact<{
  input: RemoveFoodLogMealInput;
}>;


export type RemoveFoodLogMealMutation = { readonly removeFoodLogMeal: Pick<RemoveFoodLogMealPayload, 'foodLogMealId'> };

export type EditFoodLogActivityTemplateMutationVariables = Exact<{
  input: EditFoodLogActivityTemplateInput;
}>;


export type EditFoodLogActivityTemplateMutation = { readonly editFoodLogActivityTemplate: { readonly foodLogActivity: (
      Pick<FoodLogActivity, 'id'>
      & { readonly activityTemplate: Maybe<Pick<ActivityTemplate, 'id'>> }
    ) } };

export type EditFoodLogActivityTimeWithTimezoneMutationVariables = Exact<{
  input: EditFoodLogActivityTimeWithTimezoneInput;
}>;


export type EditFoodLogActivityTimeWithTimezoneMutation = { readonly editFoodLogActivityTimeWithTimezone: { readonly foodLogActivity: (
      Pick<FoodLogActivity, 'id' | 'name' | 'date' | 'startTime' | 'endTime' | 'timezone'>
      & { activityType: FoodLogActivity['type'] }
    ) } };

export type EditFoodLogActivityRpeMutationVariables = Exact<{
  input: EditFoodLogActivityRpeInput;
}>;


export type EditFoodLogActivityRpeMutation = { readonly editFoodLogActivityRpe: { readonly foodLogActivity: Pick<FoodLogActivity, 'id' | 'rpe'> } };

export type RemoveFoodLogActivityMutationVariables = Exact<{
  input: RemoveFoodLogActivityInput;
}>;


export type RemoveFoodLogActivityMutation = { readonly removeFoodLogActivity: Pick<RemoveFoodLogActivityPayload, 'foodLogActivityId'> };

export type CreateMealPlanFromFoodLogsMutationVariables = Exact<{
  input: CreateMealPlanFromFoodLogsNewInput;
}>;


export type CreateMealPlanFromFoodLogsMutation = { readonly createMealPlanFromFoodLogsNew: { readonly mealPlan: PreviewMealPlanFragment } };

export type CreateMetricMealPlanFromFoodLogsMutationVariables = Exact<{
  input: CreateMetricMealPlanFromFoodLogsInput;
}>;


export type CreateMetricMealPlanFromFoodLogsMutation = { readonly createMetricMealPlanFromFoodLogs: { readonly mealPlan: PreviewMealPlanFragment } };

export type PublishedTimelineMealSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type PublishedTimelineMealSubscription = { readonly timelineMeal: { readonly payload: (
      { readonly __typename: 'AddTimelineMealCommentPayload' }
      & { readonly timelineMeal: Pick<TimelineMeal, 'id'>, readonly comment: CommentTimelineFragment }
    ) } };

export type AddMenuOrderForAthleteMutationVariables = Exact<{
  input: AddMenuOrderForAthleteInput;
  athleteId: Scalars['ID'];
}>;


export type AddMenuOrderForAthleteMutation = { readonly addMenuOrderForAthlete: { readonly menuOrder: (
      { readonly timelineMeal: Maybe<TimelineMealFragment> }
      & MenuOrderTimelineFragment
    ) } };

export type RemoveMenuOrderMutationVariables = Exact<{
  input: RemoveMenuOrderInput;
}>;


export type RemoveMenuOrderMutation = { readonly removeMenuOrder: (
    Pick<RemoveMenuOrderPayload, 'menuOrderId' | 'removedTimelineMealId'>
    & { readonly timelineMeal: Maybe<TimelineMealFragment> }
  ) };

export type MenuOrderForFormQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MenuOrderForFormQuery = { readonly menuOrder: MenuOrderFormFragment };

export type SetTimelineMealServingAmountsForAthleteMutationVariables = Exact<{
  input: SetTimelineMealServingAmountsForAthleteInput;
}>;


export type SetTimelineMealServingAmountsForAthleteMutation = { readonly setTimelineMealServingAmountsForAthlete: { readonly timelineMeal: Maybe<(
      { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
      & TimelineMealFragment
    )> } };

export type AddTimelineMealCommentForAthleteMutationVariables = Exact<{
  input: AddTimelineMealCommentForAthleteInput;
}>;


export type AddTimelineMealCommentForAthleteMutation = { readonly addTimelineMealCommentForAthlete: { readonly timelineMeal: TimelineMealFragment } };

export type AddMealMenuLogItemMutationVariables = Exact<{
  input: AddMealMenuLogItemForAthleteInput;
}>;


export type AddMealMenuLogItemMutation = { readonly addMealMenuLogItemForAthlete: { readonly timelineMeal: (
      Pick<TimelineMeal, 'id'>
      & { readonly mealMenuLogs: ReadonlyArray<MealMenuLogTimelineFragment>, readonly mealMenus: ReadonlyArray<MealMenuTimelineFragment> }
      & TimelineMealFragment
    ) } };

export type EditMealMenuLogItemMutationVariables = Exact<{
  input: EditMealMenuLogItemInput;
}>;


export type EditMealMenuLogItemMutation = { readonly editMealMenuLogItem: { readonly mealMenuLogItem: MealMenuLogItemFormFragment } };

export type RemoveMealMenuLogItemMutationVariables = Exact<{
  input: RemoveMealMenuLogItemInput;
}>;


export type RemoveMealMenuLogItemMutation = { readonly removeMealMenuLogItem: (
    Pick<RemoveMealMenuLogItemPayload, 'mealMenuLogItemId' | 'removedTimelineMealId'>
    & { readonly timelineMeal: Maybe<(
      Pick<TimelineMeal, 'id'>
      & { readonly mealMenuLogs: ReadonlyArray<MealMenuLogTimelineFragment> }
    )> }
  ) };

export type RemoveTimelineMealServingAmountMutationVariables = Exact<{
  input: RemoveTimelineMealServingAmountInput;
}>;


export type RemoveTimelineMealServingAmountMutation = { readonly removeTimelineMealServingAmount: { readonly timelineMeal: Maybe<(
      Pick<TimelineMeal, 'id'>
      & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
    )> } };

export type EditTimelineMealDetailsMutationVariables = Exact<{
  input: EditTimelineMealDetailsInput;
}>;


export type EditTimelineMealDetailsMutation = { readonly editTimelineMealDetails: { readonly timelineMeal: Pick<TimelineMeal, 'id' | 'start' | 'type' | 'name' | 'durationInMinutes'> } };

export type AthleteGoalConfigurationCompliancePageQueryVariables = Exact<{
  goalConfigurationId: Scalars['ID'];
  startDate: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
}>;


export type AthleteGoalConfigurationCompliancePageQuery = { readonly goalConfiguration: AthleteGoalConfigurationForCompliancePageFragment };

export type AthleteGoalConfigurationForCompliancePageFragment = (
  Pick<GoalConfiguration, 'id' | 'name' | 'emoji' | 'timezone' | 'startDate' | 'endDate' | 'completedGoalCount' | 'totalGoalCount'>
  & { readonly category: GoalCategoryFullFragment, readonly target: Maybe<GoalTargetForTableFragment> }
);

export type GoalTrackerGoalOffsetConnectionQueryVariables = Exact<{
  goalConfigurationId: Scalars['ID'];
  input: OffsetPaginationInput;
}>;


export type GoalTrackerGoalOffsetConnectionQuery = { readonly goalTrackerGoalOffsetConnection: { readonly edges: ReadonlyArray<GoalTrackerGoalForComplianceFragment>, readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'> } };

export type GoalTrackerGoalForComplianceFragment = Pick<GoalTrackerGoal, 'id' | 'date' | 'completedAtInUTC' | 'value'>;

export type GoalTrackerGoalsForExportQueryVariables = Exact<{
  goalConfigurationId: Scalars['ID'];
}>;


export type GoalTrackerGoalsForExportQuery = { readonly goalTrackerGoals: ReadonlyArray<GoalTrackerGoalForComplianceFragment> };

export type GoalConfigurationForTableFragment = (
  Pick<GoalConfiguration, 'id' | 'name' | 'emoji' | 'isArchived' | 'startDate' | 'endDate' | 'timezone' | 'completedGoalCount' | 'totalGoalCount' | 'dayOfWeekAssignments' | 'individualDates'>
  & { readonly category: GoalCategoryFullFragment, readonly target: Maybe<GoalTargetForTableFragment>, readonly reminders: ReadonlyArray<Pick<GoalReminderConfiguration, 'id' | 'time' | 'message'>> }
);

export type CreateGoalConfigurationWithDaysOfWeekMutationVariables = Exact<{
  input: CreateGoalConfigurationWithDaysOfWeekInput;
}>;


export type CreateGoalConfigurationWithDaysOfWeekMutation = { readonly createGoalConfigurationWithDaysOfWeek: { readonly goalConfiguration: Pick<GoalConfiguration, 'id' | 'name'> } };

export type CreateGoalConfigurationWithIndividualDatesMutationVariables = Exact<{
  input: CreateGoalConfigurationWithIndividualDatesInput;
}>;


export type CreateGoalConfigurationWithIndividualDatesMutation = { readonly createGoalConfigurationWithIndividualDates: { readonly goalConfiguration: Pick<GoalConfiguration, 'id' | 'name'> } };

export type EditGoalConfigurationWithDaysOfWeekMutationVariables = Exact<{
  input: EditGoalConfigurationWithDaysOfWeekInput;
}>;


export type EditGoalConfigurationWithDaysOfWeekMutation = { readonly editGoalConfigurationWithDaysOfWeek: { readonly goalConfiguration: Pick<GoalConfiguration, 'id' | 'name'> } };

export type EditGoalConfigurationWithIndividualDatesMutationVariables = Exact<{
  input: EditGoalConfigurationWithIndividualDatesInput;
}>;


export type EditGoalConfigurationWithIndividualDatesMutation = { readonly editGoalConfigurationWithIndividualDates: { readonly goalConfiguration: Pick<GoalConfiguration, 'id' | 'name'> } };

export type DeleteGoalConfigurationMutationVariables = Exact<{
  input: DeleteGoalConfigurationInput;
}>;


export type DeleteGoalConfigurationMutation = { readonly deleteGoalConfiguration: Pick<DeleteGoalConfigurationPayload, 'goalConfigurationId'> };

export type ArchiveGoalConfigurationMutationVariables = Exact<{
  input: ArchiveGoalConfigurationInput;
}>;


export type ArchiveGoalConfigurationMutation = { readonly archiveGoalConfiguration: Pick<ArchiveGoalConfigurationPayload, 'goalConfigurationId'> };

export type GoalConfigurationsForAthleteTableQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  input: OffsetPaginationInput;
  current: Scalars['Boolean'];
  startDate: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
}>;


export type GoalConfigurationsForAthleteTableQuery = { readonly goalConfigurationsForAthleteOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<GoalConfigurationForTableFragment> } };

export type GoalCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GoalCategoriesQuery = { readonly goalCategories: ReadonlyArray<GoalCategoryFullFragment> };

export type GoalCategoryFullFragment = Pick<GoalCategory, 'id' | 'name' | 'defaultEmoji'>;

export type GoalTargetForTableFragment = Pick<GoalQuantity, 'id' | 'value' | 'unit'>;

export type AssignFromMealPlanTemplateCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
}>;


export type AssignFromMealPlanTemplateCursorConnectionQuery = { readonly allMealPlanTemplatesForDietitianCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<AssignFromMealPlanTemplateExchangeMealPlanTemplateFragment | AssignFromMealPlanTemplateMacroMealPlanTemplateFragment> } };

export type AssignMetricMealPlanTemplateToAthleteMutationVariables = Exact<{
  input: AssignMetricMealPlanTemplateToAthleteInput;
}>;


export type AssignMetricMealPlanTemplateToAthleteMutation = { readonly assignMetricMealPlanTemplateToAthlete: { readonly mealPlan: (
      Pick<MealPlan, 'id'>
      & { readonly athlete: (
        Pick<Athlete, 'id'>
        & { readonly mealPlans: ReadonlyArray<PreviewMealPlanFragment> }
      ) }
    ) } };

type AssignFromMealPlanTemplateExchangeMealPlanTemplateFragment = (
  Pick<ExchangeMealPlanTemplate, 'id' | 'name' | 'description' | 'orgId' | 'orgGroupId'>
  & { readonly macroProtocol: { readonly calorieBudget: Maybe<(
      Pick<MealPlanTemplateCalorieBudget, 'rmrMethod'>
      & { readonly goals: ReadonlyArray<MealPlanTemplateGoalFragment> }
    )>, readonly anthropometry: AnthropometryTemplateFragment } }
);

type AssignFromMealPlanTemplateMacroMealPlanTemplateFragment = (
  Pick<MacroMealPlanTemplate, 'id' | 'name' | 'description' | 'orgId' | 'orgGroupId'>
  & { readonly macroProtocol: { readonly calorieBudget: Maybe<(
      Pick<MealPlanTemplateCalorieBudget, 'rmrMethod'>
      & { readonly goals: ReadonlyArray<MealPlanTemplateGoalFragment> }
    )>, readonly anthropometry: AnthropometryTemplateFragment } }
);

export type AssignFromMealPlanTemplateFragment = AssignFromMealPlanTemplateExchangeMealPlanTemplateFragment | AssignFromMealPlanTemplateMacroMealPlanTemplateFragment;

export type AssignFromMealPlanTemplateAthleteFragment = (
  Pick<Athlete, 'id' | 'birthDate'>
  & { readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment> }
);

export type AthleteMealPlansQueryVariables = Exact<{
  athleteId: Scalars['ID'];
}>;


export type AthleteMealPlansQuery = { readonly athlete: (
    { readonly team: (
      { readonly schedules: ReadonlyArray<ScheduleFragment> }
      & TeamWithSportFragment
    ), readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly likedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly mealPlans: ReadonlyArray<PreviewMealPlanFragment>, readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment>, readonly currentGoal: Maybe<FullGoalFragment>, readonly macroDisplaySettings: MacroMealPlanDisplaySettingsFragment, readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment }
    & AthleteFragment
  ) };

export type AthleteMostRecentAnthroWithFieldsQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  fields: ReadonlyArray<AnthropometryEntryField> | AnthropometryEntryField;
}>;


export type AthleteMostRecentAnthroWithFieldsQuery = { readonly athlete: (
    Pick<Athlete, 'id'>
    & { readonly mostRecentAnthropometryEntryWith: Maybe<AnthropometryEntryFragment> }
  ) };

export type AthleteMostRecentAnthroForRmrMethodQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  rmrMethod: Maybe<RmrMethod>;
}>;


export type AthleteMostRecentAnthroForRmrMethodQuery = { readonly athlete: (
    Pick<Athlete, 'id'>
    & { readonly mostRecentAnthropometryEntryForRmrMethod: Maybe<AnthropometryEntryFragment> }
  ) };

export type CreateMetricMealPlanMutationVariables = Exact<{
  input: CreateMetricMealPlanInput;
}>;


export type CreateMetricMealPlanMutation = { readonly createMetricMealPlan: { readonly mealPlan: (
      Pick<MealPlan, 'id'>
      & { readonly athlete: (
        Pick<Athlete, 'id'>
        & { readonly mealPlans: ReadonlyArray<PreviewMealPlanFragment> }
      ) }
    ) } };

export type DeleteMealPlanMutationVariables = Exact<{
  input: DeleteMealPlanInput;
}>;


export type DeleteMealPlanMutation = { readonly deleteMealPlan: (
    Pick<DeleteMealPlanPayload, 'mealPlanId'>
    & { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly mealPlans: ReadonlyArray<PreviewMealPlanFragment> }
    ) }
  ) };

export type CopyMealPlanNewMutationVariables = Exact<{
  input: CopyMealPlanNewInput;
}>;


export type CopyMealPlanNewMutation = { readonly copyMealPlanNew: { readonly mealPlan: (
      Pick<MealPlan, 'id'>
      & { readonly athlete: (
        Pick<Athlete, 'id'>
        & { readonly mealPlans: ReadonlyArray<PreviewMealPlanFragment> }
      ) }
    ) } };

export type BulkCopyMealPlanNewMutationVariables = Exact<{
  input: BulkCopyMealPlanNewInput;
}>;


export type BulkCopyMealPlanNewMutation = { readonly bulkCopyMealPlanNew: { readonly mealPlans: ReadonlyArray<FullMealPlanFragment> } };

export type EditMealPlanMutationVariables = Exact<{
  input: EditMealPlanNewInput;
}>;


export type EditMealPlanMutation = { readonly editMealPlanNew: { readonly mealPlan: PreviewMealPlanFragment } };

export type MealPlanDaysOfWeekQueryVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type MealPlanDaysOfWeekQuery = { readonly mealPlan: (
    Pick<MealPlan, 'id'>
    & { readonly daysOfWeek: ReadonlyArray<MealPlanDayOfWeekAssignmentFragment> }
  ) };

export type MealPlanCalendarInRangePreviewQueryVariables = Exact<{
  mealPlanId: Scalars['ID'];
  start: Scalars['Date'];
  end: Scalars['Date'];
}>;


export type MealPlanCalendarInRangePreviewQuery = { readonly mealPlan: (
    Pick<MealPlan, 'id' | 'hasIndividualDateAssignments' | 'individualDatesInRange'>
    & { readonly modificationsInRange: ReadonlyArray<MealPlanDateModificationsFragment> }
  ) };

export type AthleteCalendarInRangePreviewQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  start: Scalars['Date'];
  end: Scalars['Date'];
}>;


export type AthleteCalendarInRangePreviewQuery = { readonly athlete: (
    Pick<Athlete, 'id'>
    & { readonly mealPlanDateAssignmentsInRange: ReadonlyArray<MealPlanDateAssignmentPreviewFragment> }
  ) };

export type MealPlanCalendarQueryVariables = Exact<{
  mealPlanId: Scalars['ID'];
  after: Scalars['Date'];
}>;


export type MealPlanCalendarQuery = { readonly mealPlan: (
    Pick<MealPlan, 'id' | 'isAutoSuggestionsEnabled' | 'startDate' | 'endDate'>
    & { readonly schedule: Maybe<ScheduleBasicFragment>, readonly mealTemplates: ReadonlyArray<MealTemplateScheduleExchangeMealTemplateFragment | MealTemplateScheduleMacroMealTemplateFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateScheduleFragment>, readonly daysOfWeek: ReadonlyArray<MealPlanDayOfWeekAssignmentFragment> }
  ) };

type MealTemplateScheduleExchangeMealTemplateFragment = (
  Pick<ExchangeMealTemplate, 'hasDateModifications'>
  & MealTemplateTimeExchangeMealTemplateFragment
);

type MealTemplateScheduleMacroMealTemplateFragment = (
  Pick<MacroMealTemplate, 'hasDateModifications'>
  & MealTemplateTimeMacroMealTemplateFragment
);

export type MealTemplateScheduleFragment = MealTemplateScheduleExchangeMealTemplateFragment | MealTemplateScheduleMacroMealTemplateFragment;

export type ActivityTemplateScheduleFragment = (
  Pick<ActivityTemplate, 'hasDateModifications'>
  & ActivityTemplateTimeFragment
);

type MealTemplateTimeExchangeMealTemplateFragment = (
  Pick<ExchangeMealTemplate, 'id'>
  & { readonly meal: MealFragment }
);

type MealTemplateTimeMacroMealTemplateFragment = (
  Pick<MacroMealTemplate, 'id'>
  & { readonly meal: MealFragment }
);

export type MealTemplateTimeFragment = MealTemplateTimeExchangeMealTemplateFragment | MealTemplateTimeMacroMealTemplateFragment;

export type ActivityTemplateTimeFragment = (
  Pick<ActivityTemplate, 'id'>
  & { readonly activity: ActivityFragment }
);

export type MealPlanDayOfWeekAssignmentFragment = { readonly dayOfWeekPriority: DayOfWeekPriorityFragment, readonly mealTemplateModifications: ReadonlyArray<MealTemplateModificationFragment>, readonly activityTemplateModifications: ReadonlyArray<ActivityTemplateModificationFragment> };

export type MealPlanCreateCalendarInRangeQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
}>;


export type MealPlanCreateCalendarInRangeQuery = { readonly athlete: (
    Pick<Athlete, 'id'>
    & { readonly mealPlanDateAssignmentsInRange: ReadonlyArray<MealPlanCalendarDateAssignmentFragment>, readonly timelineMealsInRange: ReadonlyArray<Pick<TimelineMeal, 'id' | 'start'>> }
  ) };

export type MealPlanEditCalendarInRangeQueryVariables = Exact<{
  mealPlanId: Scalars['ID'];
  athleteId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
}>;


export type MealPlanEditCalendarInRangeQuery = { readonly mealPlan: (
    Pick<MealPlan, 'id' | 'individualDatesInRange'>
    & { readonly modificationsInRange: ReadonlyArray<MealPlanDateModificationsFragment> }
  ), readonly athlete: (
    Pick<Athlete, 'id'>
    & { readonly mealPlanDateAssignmentsInRange: ReadonlyArray<MealPlanCalendarDateAssignmentFragment>, readonly timelineMealsInRange: ReadonlyArray<Pick<TimelineMeal, 'id' | 'start'>> }
  ) };

export type MealPlanConflictsQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  start: Scalars['Date'];
  end: Maybe<Scalars['Date']>;
}>;


export type MealPlanConflictsQuery = { readonly athlete: (
    Pick<Athlete, 'id'>
    & { readonly priorityMealPlanDaysOfWeekInRange: ReadonlyArray<{ readonly dayOfWeekPriority: DayOfWeekPriorityFragment, readonly mealPlan: Pick<MealPlan, 'id' | 'name'> }>, readonly mealPlanIndividualDatesInRange: ReadonlyArray<(
      Pick<MealPlanIndividualDateAssignment, 'date'>
      & { readonly mealPlan: Pick<MealPlan, 'id' | 'name'> }
    )> }
  ) };

export type MealPlanCalendarDateAssignmentFragment = (
  Pick<MealPlanDateAssignment, 'date'>
  & { readonly mealPlan: (
    Pick<MealPlan, 'id' | 'name'>
    & { readonly mealTemplates: ReadonlyArray<MealTemplateTimeExchangeMealTemplateFragment | MealTemplateTimeMacroMealTemplateFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateTimeFragment>, readonly daysOfWeek: ReadonlyArray<MealPlanDayOfWeekAssignmentFragment> }
  ), readonly dayOfWeekPriority: Maybe<DayOfWeekPriorityFragment>, readonly mealTemplateModifications: ReadonlyArray<MealTemplateModificationFragment>, readonly activityTemplateModifications: ReadonlyArray<ActivityTemplateModificationFragment> }
);

export type MealPlanDateModificationsFragment = (
  Pick<MealPlanDateModifications, 'date'>
  & { readonly mealTemplateModifications: ReadonlyArray<MealTemplateModificationFragment>, readonly activityTemplateModifications: ReadonlyArray<ActivityTemplateModificationFragment> }
);

export type MealPlanDateAssignmentPreviewFragment = (
  Pick<MealPlanDateAssignment, 'date'>
  & { readonly mealPlan: Pick<MealPlan, 'id' | 'name'> }
);

export type MealTemplateModificationFragment = (
  Pick<MealTemplateModification, 'start' | 'end'>
  & { readonly mealTemplate: Pick<ExchangeMealTemplate, 'id'> | Pick<MacroMealTemplate, 'id'> }
);

export type ActivityTemplateModificationFragment = (
  Pick<ActivityTemplateModification, 'start' | 'end'>
  & { readonly activityTemplate: Pick<ActivityTemplate, 'id'> }
);

export type DayOfWeekPriorityFragment = Pick<DayOfWeekPriority, 'dayOfWeek' | 'priority'>;

export type EditMealPlanCalendarMutationVariables = Exact<{
  input: EditMealPlanCalendarInput;
}>;


export type EditMealPlanCalendarMutation = { readonly editMealPlanCalendar: { readonly mealPlan: Pick<MealPlan, 'id'> } };

export type ExternalSchedulesQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  start: Scalars['Date'];
  end: Scalars['Date'];
}>;


export type ExternalSchedulesQuery = { readonly teamworksScheduleForAthleteInRange: ReadonlyArray<ExternalEventFragment> };

export type ExternalCalendarsQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  start: Scalars['Date'];
  end: Scalars['Date'];
}>;


export type ExternalCalendarsQuery = { readonly teamworksCalendarForAthleteInRange: (
    Pick<TeamworksCalendarPayload, 'isPartialSuccess'>
    & { readonly events: ReadonlyArray<ExternalEventFragment> }
  ) };

export type ExternalEventFragment = Pick<ExternalEvent, 'id' | 'name' | 'timezone' | 'startDate' | 'endDate' | 'isAllDay' | 'eventType' | 'location'>;

export type MealPlanCommonQueryVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type MealPlanCommonQuery = { readonly mealPlan: EditFullMealPlanFragment };

export type CommonMealPlanFragment = (
  { readonly __typename: 'MealPlan' }
  & Pick<MealPlan, 'id' | 'name' | 'description' | 'isShared' | 'startDate' | 'endDate' | 'type' | 'updatedAt' | 'isAutosaving'>
  & { readonly exchangeSet: Maybe<ExchangeSetWithExchangesFragment>, readonly exchangeTargets: Maybe<ReadonlyArray<EditExchangeAmountFragment>>, readonly macroProtocol: FullMacroProtocolFragment, readonly schedule: Maybe<ScheduleFragment>, readonly avoidedFoodGroups: ReadonlyArray<FullFoodGroupFragment>, readonly promotedFoodGroups: ReadonlyArray<FullFoodGroupFragment>, readonly avoidedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly promotedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly mostRecentEvent: Maybe<EventFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateFragment>, readonly mealTemplates: ReadonlyArray<EditFullExchangeMealTemplateFragment | EditFullMacroMealTemplateFragment> }
);

export type AthleteSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AthleteSummaryQuery = { readonly athlete: AthleteSummaryAthleteFragment, readonly teams: ReadonlyArray<TeamWithSportFragment> };

export type AthleteSummaryAthleteFragment = (
  Pick<Athlete, 'defaultHideAnthropometry' | 'hideAnthropometry'>
  & { readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettingsFragment>, readonly position: Maybe<PositionFragment>, readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment>, readonly currentGoal: Maybe<FullGoalFragment>, readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly likedFoodGroups: ReadonlyArray<FullFoodGroupFragment>, readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupFragment>, readonly team: (
    Pick<Team, 'defaultHideAnthropometry'>
    & { readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettingsFragment>, readonly defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettingsFragment>, readonly defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettingsFragment>, readonly defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettingsFragment> }
    & TeamWithSportFragment
  ), readonly mealPlans: ReadonlyArray<(
    { readonly events: ReadonlyArray<EventFragment> }
    & MealPlanFragment
  )>, readonly defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettingsFragment>, readonly defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettingsFragment>, readonly defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettingsFragment>, readonly macroDisplaySettings: MacroMealPlanDisplaySettingsFragment, readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment, readonly noTargetsDisplaySettings: NoTargetsDisplaySettingsFragment, readonly user: Pick<User, 'id' | 'firstName' | 'lastName' | 'lastLogin' | 'username'>, readonly orgMembership: Maybe<Pick<OrgMembership, 'id' | 'isActive' | 'teamworksId' | 'isNotemealOnly'>>, readonly allOrderedNamedTags: ReadonlyArray<NamedTagForSelectionFragment>, readonly activeTransfer: Maybe<Pick<AthleteTransfer, 'id'>> }
  & AthleteFragment
);

export type EditAthleteMutationVariables = Exact<{
  input: EditAthleteInput;
}>;


export type EditAthleteMutation = { readonly editAthlete: { readonly athlete: EditedAthleteFragment } };

export type EditSyncedAthleteMutationVariables = Exact<{
  input: EditSyncedAthleteInput;
}>;


export type EditSyncedAthleteMutation = { readonly editSyncedAthlete: { readonly athlete: EditedAthleteFragment } };

export type EditedAthleteFragment = (
  { readonly timelineMealNotifications: ReadonlyArray<NotificationStubAthleteSelfOnboardedNotificationFragment | NotificationStubBulkOrderStatusNotificationFragment | NotificationStubFoodLogMealCommentNotificationFragment | NotificationStubFoodLogMealEditNotificationFragment | NotificationStubFoodLogMealImageNotificationFragment | NotificationStubGoalReminderNotificationFragment | NotificationStubMealMenuAvailableNotificationFragment | NotificationStubMealMenuIsSharedNotificationFragment | NotificationStubMealMenuOrderDueNotificationFragment | NotificationStubMealPlanSharedNotificationFragment | NotificationStubMenuOrderCommentNotificationFragment | NotificationStubMenuOrderImageNotificationFragment | NotificationStubMenuOrderStatusNotificationFragment | NotificationStubTimelineMealCommentNotificationFragment | NotificationStubTimelineMealEditNotificationFragment | NotificationStubTimelineMealImageNotificationFragment>, readonly team: (
    { readonly athletes: ReadonlyArray<Pick<Athlete, 'id'>>, readonly recentNotifications: ReadonlyArray<NotificationStubAthleteSelfOnboardedNotificationFragment | NotificationStubBulkOrderStatusNotificationFragment | NotificationStubFoodLogMealCommentNotificationFragment | NotificationStubFoodLogMealEditNotificationFragment | NotificationStubFoodLogMealImageNotificationFragment | NotificationStubGoalReminderNotificationFragment | NotificationStubMealMenuAvailableNotificationFragment | NotificationStubMealMenuIsSharedNotificationFragment | NotificationStubMealMenuOrderDueNotificationFragment | NotificationStubMealPlanSharedNotificationFragment | NotificationStubMenuOrderCommentNotificationFragment | NotificationStubMenuOrderImageNotificationFragment | NotificationStubMenuOrderStatusNotificationFragment | NotificationStubTimelineMealCommentNotificationFragment | NotificationStubTimelineMealEditNotificationFragment | NotificationStubTimelineMealImageNotificationFragment> }
    & TeamWithSportFragment
  ), readonly position: Maybe<PositionFragment>, readonly allOrderedNamedTags: ReadonlyArray<NamedTagForSelectionFragment> }
  & AthleteFragment
);

export type EditAthleteDisplaySettingsMutationVariables = Exact<{
  input: EditAthleteDisplaySettingsInput;
}>;


export type EditAthleteDisplaySettingsMutation = { readonly editAthleteDisplaySettings: { readonly athlete: (
      Pick<Athlete, 'id' | 'hideAnthropometry'>
      & { readonly defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettingsFragment>, readonly defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettingsFragment>, readonly noTargetsDisplaySettings: NoTargetsDisplaySettingsFragment }
    ) } };

export type EditAthleteFoodLogNotificationSettingsMutationVariables = Exact<{
  input: EditAthleteFoodLogNotificationSettingsInput;
}>;


export type EditAthleteFoodLogNotificationSettingsMutation = { readonly editAthleteFoodLogNotificationSettings: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettingsFragment> }
    ) } };

export type DeleteAthleteMutationVariables = Exact<{
  input: DeleteAthleteInput;
}>;


export type DeleteAthleteMutation = { readonly deleteAthlete: Pick<DeleteAthletePayload, 'athleteId'> };

export type TransferAthleteMutationVariables = Exact<{
  input: CreateAthleteTransferInput;
}>;


export type TransferAthleteMutation = { readonly createAthleteTransfer: { readonly athleteTransfer: (
      Pick<AthleteTransfer, 'id'>
      & { readonly athlete: (
        Pick<Athlete, 'id'>
        & { readonly activeTransfer: Maybe<Pick<AthleteTransfer, 'id'>> }
      ) }
    ) } };

export type ReclaimAthleteMutationVariables = Exact<{
  input: ReclaimAthleteTransferInput;
}>;


export type ReclaimAthleteMutation = { readonly reclaimAthleteTransfer: { readonly athleteTransfer: (
      Pick<AthleteTransfer, 'id'>
      & { readonly athlete: (
        Pick<Athlete, 'id'>
        & { readonly activeTransfer: Maybe<Pick<AthleteTransfer, 'id'>> }
      ) }
    ) } };

export type AthletePageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AthletePageQuery = { readonly athlete: AthletePageAthleteFragment };

export type AthletePageAthleteFragment = (
  { readonly team: Pick<Team, 'id'>, readonly orgMembership: Maybe<Pick<OrgMembership, 'id' | 'isActive'>>, readonly timelineMealNotifications: ReadonlyArray<NotificationStubAthleteSelfOnboardedNotificationFragment | NotificationStubBulkOrderStatusNotificationFragment | NotificationStubFoodLogMealCommentNotificationFragment | NotificationStubFoodLogMealEditNotificationFragment | NotificationStubFoodLogMealImageNotificationFragment | NotificationStubGoalReminderNotificationFragment | NotificationStubMealMenuAvailableNotificationFragment | NotificationStubMealMenuIsSharedNotificationFragment | NotificationStubMealMenuOrderDueNotificationFragment | NotificationStubMealPlanSharedNotificationFragment | NotificationStubMenuOrderCommentNotificationFragment | NotificationStubMenuOrderImageNotificationFragment | NotificationStubMenuOrderStatusNotificationFragment | NotificationStubTimelineMealCommentNotificationFragment | NotificationStubTimelineMealEditNotificationFragment | NotificationStubTimelineMealImageNotificationFragment> }
  & AthleteFragment
);

export type ClientCredentialOffsetConnectionQueryVariables = Exact<{
  pagination: OffsetPaginationInput;
}>;


export type ClientCredentialOffsetConnectionQuery = { readonly clientCredentialOffsetConnection: { readonly edges: ReadonlyArray<ClientCredentialFragment>, readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'> } };

export type ClientCredentialFragment = Pick<ClientCredential, 'id' | 'name' | 'clientId' | 'isActive'>;

export type CreateClientCredentialMutationVariables = Exact<{
  input: CreateClientCredentialInput;
}>;


export type CreateClientCredentialMutation = { readonly createClientCredential: (
    Pick<CreateClientCredentialPayload, 'clientSecret'>
    & { readonly clientCredential: ClientCredentialFragment }
  ) };

export type EditClientCredentialMutationVariables = Exact<{
  input: EditClientCredentialInput;
}>;


export type EditClientCredentialMutation = { readonly editClientCredential: { readonly clientCredential: ClientCredentialFragment } };

export type RefreshClientCredentialSecretMutationVariables = Exact<{
  input: RefreshClientCredentialSecretInput;
}>;


export type RefreshClientCredentialSecretMutation = { readonly refreshClientCredentialSecret: (
    Pick<RefreshClientCredentialSecretPayload, 'clientSecret'>
    & { readonly clientCredential: ClientCredentialFragment }
  ) };

export type DiningStationTemplatesTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
}>;


export type DiningStationTemplatesTableQuery = { readonly diningStationTemplateOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<DiningStationTemplateTableFragment> } };

export type DiningStationTemplateSearchQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  pagination: CursorPaginationInput;
}>;


export type DiningStationTemplateSearchQuery = { readonly diningStationTemplateCursorConnection: { readonly edges: ReadonlyArray<DiningStationTemplatePreviewFragment>, readonly pageInfo: Pick<CursorPageInfo, 'endCursor' | 'hasNextPage'> } };

export type DiningStationTemplateDialogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DiningStationTemplateDialogQuery = { readonly diningStationTemplate: DiningStationTemplateFullFragment };

export type DiningStationTemplateMenuItemsForMenuBuilderQueryVariables = Exact<{
  diningStationId: Scalars['ID'];
}>;


export type DiningStationTemplateMenuItemsForMenuBuilderQuery = { readonly diningStationTemplate: (
    Pick<DiningStationTemplate, 'id'>
    & { readonly menuItemAppearances: ReadonlyArray<MenuItemAppearanceForMenuBuilderFragment> }
  ) };

export type DiningStationTemplatePreviewFragment = (
  { readonly __typename: 'DiningStationTemplate' }
  & Pick<DiningStationTemplate, 'id' | 'name'>
);

export type DiningStationTemplateFullFragment = (
  { readonly __typename: 'DiningStationTemplate' }
  & Pick<DiningStationTemplate, 'id' | 'name' | 'defaultMaxAmount' | 'mealTypes'>
  & { readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
);

export type DiningStationTemplateTableFragment = (
  { readonly __typename: 'DiningStationTemplate' }
  & Pick<DiningStationTemplate, 'id' | 'name' | 'mealTypes'>
  & { readonly menuItemAppearances: ReadonlyArray<(
    Pick<MenuItemAppearance, 'position'>
    & { readonly menuItem: Pick<MenuItem, 'id' | 'isOneOff' | 'isMissingIngredients'> }
  )> }
);

export type MenuItemAppearanceForMenuBuilderFragment = (
  Pick<MenuItemAppearance, 'id' | 'position'>
  & { readonly menuItem: Pick<MenuItem, 'id' | 'name' | 'isMissingIngredients'> }
);

export type CreateDiningStationTemplateMutationVariables = Exact<{
  input: CreateDiningStationTemplateInput;
}>;


export type CreateDiningStationTemplateMutation = { readonly createDiningStationTemplate: { readonly diningStationTemplate: DiningStationTemplateFullFragment } };

export type EditDiningStationTemplateMutationVariables = Exact<{
  input: EditDiningStationTemplateInput;
}>;


export type EditDiningStationTemplateMutation = { readonly editDiningStationTemplate: { readonly diningStationTemplate: DiningStationTemplateFullFragment } };

export type DeleteDiningStationTemplateMutationVariables = Exact<{
  input: DeleteDiningStationTemplateInput;
}>;


export type DeleteDiningStationTemplateMutation = { readonly deleteDiningStationTemplate: Pick<DeleteDiningStationTemplatePayload, 'diningStationTemplateId'> };

export type KdsPageMealMenusInDateRangeQueryVariables = Exact<{
  after: Scalars['Date'];
  before: Scalars['Date'];
  timezone: Scalars['Timezone'];
}>;


export type KdsPageMealMenusInDateRangeQuery = { readonly mealMenusInDateRange: ReadonlyArray<KdsPageMealMenuInDateRangeFragment> };

export type KdsPageMealMenusQueryVariables = Exact<{
  mealMenuIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  diningStationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type KdsPageMealMenusQuery = { readonly mealMenus: ReadonlyArray<KdsPageMealMenuFragment> };

export type KdsPrintAllDialogMenusQueryVariables = Exact<{
  mealMenuIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  diningStationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type KdsPrintAllDialogMenusQuery = { readonly mealMenus: ReadonlyArray<{ readonly allOrders: ReadonlyArray<KdsTicketMenuOrderFragment>, readonly allMealMenuPlateOrders: ReadonlyArray<KdsTicketMealMenuPlateOrderFragment> }> };

export type KdsTicketsMenuOrderQueryVariables = Exact<{
  menuOrderId: Scalars['ID'];
  diningStationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type KdsTicketsMenuOrderQuery = { readonly menuOrder: KdsTicketMenuOrderFragment };

export type KdsTicketsMealMenuPlateOrderQueryVariables = Exact<{
  mealMenuPlateOrderId: Scalars['ID'];
  diningStationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type KdsTicketsMealMenuPlateOrderQuery = { readonly mealMenuPlateOrder: KdsTicketMealMenuPlateOrderFragment };

export type KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables = Exact<{
  menuOrderIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  mealMenuPlateOrderIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  diningStationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery = { readonly menuOrders: ReadonlyArray<KdsTicketMenuOrderFragment>, readonly mealMenuPlateOrders: ReadonlyArray<KdsTicketMealMenuPlateOrderFragment> };

export type KdsAnalyzeDialogMealMenusQueryVariables = Exact<{
  mealMenuIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  diningStationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type KdsAnalyzeDialogMealMenusQuery = { readonly mealMenus: ReadonlyArray<{ readonly allOrders: ReadonlyArray<KdsAnalyzeDialogOrderFragment>, readonly allMealMenuPlateOrders: ReadonlyArray<KdsAnalyzeDialogPlateOrderFragment> }> };

export type KdsPageMealMenuInDateRangeFragment = (
  { readonly mealMenuDiningStations: ReadonlyArray<KdsPageMealMenuDiningStationFragment> }
  & MealMenuSummaryFragment
);

export type MealMenuSummaryFragment = Pick<StandaloneMealMenu, 'id' | 'name' | 'type' | 'start' | 'lastOrderTimeBeforeEndInMinutes' | 'durationInMinutes'>;

export type KdsPageMealMenuFragment = (
  { readonly allOrders: ReadonlyArray<KdsPageMenuOrderPreviewFragment>, readonly allMealMenuPlateOrders: ReadonlyArray<KdsPageMealMenuPlateOrderPreviewFragment> }
  & MealMenuSummaryFragment
);

export type KdsAnalyzeDialogOrderFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id'>
  & { readonly itemsByDiningStation: ReadonlyArray<KdsTicketMenuOrderItemFragment> }
);

export type KdsAnalyzeDialogPlateOrderFragment = (
  { readonly __typename: 'MealMenuPlateOrder' }
  & Pick<MealMenuPlateOrder, 'id'>
  & { readonly itemsByDiningStation: ReadonlyArray<KdsTicketMealMenuPlateOrderItemFragment> }
);

export type KdsPageOrderPreviewAthleteFragment = (
  Pick<Athlete, 'id' | 'firstName' | 'lastName' | 'jerseyNumber'>
  & { readonly position: Maybe<Pick<Position, 'name'>>, readonly team: Pick<Team, 'name'> }
);

export type KdsPageMenuOrderPreviewFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id' | 'pickupTime' | 'createdAt' | 'updatedAt' | 'code' | 'itemCount' | 'userFullName'>
  & { readonly itemsByDiningStation: ReadonlyArray<(
    Pick<MenuOrderItem, 'id' | 'status' | 'updateCount' | 'updatedAt'>
    & { readonly menuItem: Pick<MenuItem, 'id'> }
  )>, readonly mealMenu: Pick<StandaloneMealMenu, 'id'>, readonly athlete: Maybe<KdsPageOrderPreviewAthleteFragment> }
);

export type KdsPageMealMenuPlateOrderPreviewFragment = (
  { readonly __typename: 'MealMenuPlateOrder' }
  & Pick<MealMenuPlateOrder, 'id' | 'createdAt' | 'updatedAt' | 'code' | 'itemCount' | 'userFullName'>
  & { readonly itemsByDiningStation: ReadonlyArray<(
    Pick<MealMenuPlateOrderItem, 'id' | 'status' | 'updateCount' | 'updatedAt'>
    & { readonly menuItem: Pick<MenuItem, 'id'> }
  )>, readonly mealMenu: Pick<StandaloneMealMenu, 'id'>, readonly athlete: Maybe<KdsPageOrderPreviewAthleteFragment>, readonly parentBulkOrder: (
    Pick<BulkOrder, 'pickupTime' | 'deliveryLocation' | 'code'>
    & { readonly team: Pick<Team, 'name'> }
  ) }
);

export type UpdateMenuOrderItemStatusesMutationVariables = Exact<{
  input: UpdateMenuOrderItemStatusesInput;
}>;


export type UpdateMenuOrderItemStatusesMutation = { readonly updateMenuOrderItemStatuses: { readonly menuOrderItems: ReadonlyArray<Pick<MenuOrderItem, 'id' | 'status'>> } };

export type UpdateMultipleMenuOrderItemStatusesMutationVariables = Exact<{
  input: UpdateMultipleMenuOrderItemStatusesInput;
}>;


export type UpdateMultipleMenuOrderItemStatusesMutation = { readonly updateMultipleMenuOrderItemStatuses: { readonly menuOrderItems: ReadonlyArray<Pick<MenuOrderItem, 'id' | 'status'>> } };

export type UpdateMultipleMealMenuPlateItemOrderStatusesMutationVariables = Exact<{
  input: UpdateMultipleMealMenuPlateItemOrderStatusesInput;
}>;


export type UpdateMultipleMealMenuPlateItemOrderStatusesMutation = { readonly updateMultipleMealMenuPlateItemOrderStatuses: { readonly mealMenuPlateItemOrderStatuses: ReadonlyArray<(
      Pick<MealMenuPlateItemOrderStatus, 'status'>
      & { readonly mealMenuPlateOrder: Pick<MealMenuPlateOrder, 'id'>, readonly mealMenuPlateItem: Pick<MealMenuPlateItem, 'id'> }
    )> } };

export type PublishedMenuOrderSubscriptionVariables = Exact<{
  scope: Maybe<MenuOrderSubscriberScope>;
  diningStationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type PublishedMenuOrderSubscription = { readonly menuOrder: { readonly payload: (
      { readonly __typename: 'AddMenuOrderPayload' }
      & { readonly menuOrder: (
        { readonly __typename: 'MenuOrder' }
        & KdsTicketMenuOrderFragment
      ) }
    ) | (
      { readonly __typename: 'EditMenuOrderPayload' }
      & { readonly menuOrder: (
        { readonly __typename: 'MenuOrder' }
        & KdsTicketMenuOrderFragment
      ) }
    ) | (
      { readonly __typename: 'RemoveMenuOrderPayload' }
      & Pick<RemoveMenuOrderPayload, 'menuOrderId'>
    ) | { readonly __typename: 'AddMenuOrderCommentPayload' }, readonly scope: MenuOrderPublisherScopeFragment } };

export type PublishedBulkOrderSubscriptionVariables = Exact<{
  scope: Maybe<BulkOrderSubscriberScope>;
  diningStationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type PublishedBulkOrderSubscription = { readonly bulkOrder: { readonly payload: (
      { readonly __typename: 'AddBulkOrderPayload' }
      & { readonly bulkOrder: (
        { readonly __typename: 'BulkOrder' }
        & Pick<BulkOrder, 'id' | 'code'>
        & { readonly mealMenuPlateOrders: ReadonlyArray<KdsTicketMealMenuPlateOrderFragment> }
      ) }
    ) | (
      { readonly __typename: 'EditBulkOrderPayload' }
      & Pick<EditBulkOrderPayload, 'removedMealMenuPlateOrderIds'>
      & { readonly bulkOrder: (
        { readonly __typename: 'BulkOrder' }
        & Pick<BulkOrder, 'id' | 'code'>
      ), readonly addedMealMenuPlateOrders: ReadonlyArray<KdsTicketMealMenuPlateOrderFragment>, readonly editedMealMenuPlateOrders: ReadonlyArray<KdsTicketMealMenuPlateOrderFragment> }
    ) | (
      { readonly __typename: 'RemoveBulkOrderPayload' }
      & Pick<RemoveBulkOrderPayload, 'bulkOrderId' | 'affectedMealMenuPlateOrderIds'>
    ), readonly scope: BulkOrderPublisherScopeFragment } };

export type PublishedMenuOrderItemStatusesSubscriptionVariables = Exact<{
  scope: Maybe<MenuOrderItemStatusesSubscriberScope>;
}>;


export type PublishedMenuOrderItemStatusesSubscription = { readonly menuOrderItemStatuses: { readonly payload: (
      { readonly __typename: 'UpdateMenuOrderItemStatusesPayload' }
      & { readonly menuOrderItems: ReadonlyArray<(
        { readonly __typename: 'MenuOrderItem' }
        & Pick<MenuOrderItem, 'id' | 'status'>
      )> }
    ), readonly scope: MenuOrderItemStatusesPublisherScopeFragment } };

export type PublishedMealMenuPlateOrderItemStatusesSubscriptionVariables = Exact<{
  scope: Maybe<MealMenuPlateItemOrderStatusSubscriberScope>;
  diningStationIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type PublishedMealMenuPlateOrderItemStatusesSubscription = { readonly mealMenuPlateItemOrderStatuses: { readonly payload: (
      { readonly __typename: 'UpdateMultipleMealMenuPlateItemOrderStatusesPayload' }
      & { readonly mealMenuPlateItemOrderStatuses: ReadonlyArray<(
        Pick<MealMenuPlateItemOrderStatus, 'status'>
        & { readonly mealMenuPlateOrder: KdsTicketMealMenuPlateOrderFragment, readonly mealMenuPlateItem: Pick<MealMenuPlateItem, 'id'> }
      )> }
    ), readonly scope: MealMenuPlateItemOrderStatusPublisherScopeFragment } };

export type MenuOrderItemStatusesPublisherScopeFragment = Pick<MenuOrderItemStatusesPublisherScope, 'mealMenuId'>;

export type MealMenuPlateItemOrderStatusPublisherScopeFragment = Pick<MealMenuPlateItemOrderStatusPublisherScope, 'mealMenuId'>;

export type MenuOrderPublisherScopeFragment = Pick<MenuOrderPublisherScope, 'mealMenuId'>;

export type BulkOrderPublisherScopeFragment = Pick<BulkOrderPublisherScope, 'mealMenuId'>;

export type KdsTicketOrderAthleteFragment = (
  Pick<Athlete, 'id' | 'firstName' | 'lastName' | 'jerseyNumber'>
  & { readonly team: Pick<Team, 'name'>, readonly position: Maybe<Pick<Position, 'name'>>, readonly dislikedFoods: ReadonlyArray<Pick<BrandedFood, 'id' | 'name'> | Pick<GenericFood, 'id' | 'name'>>, readonly dislikedFoodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>>, readonly printableTagsForAthlete: ReadonlyArray<NamedTagForSelectionFragment> }
);

export type KdsTicketMenuOrderFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id' | 'pickupTime' | 'createdAt' | 'updatedAt' | 'code' | 'itemCount' | 'userFullName'>
  & { readonly mealMenu: Pick<StandaloneMealMenu, 'id'>, readonly itemsByDiningStation: ReadonlyArray<KdsTicketMenuOrderItemFragment>, readonly athlete: Maybe<KdsTicketOrderAthleteFragment> }
);

export type KdsTicketMealMenuPlateOrderFragment = (
  { readonly __typename: 'MealMenuPlateOrder' }
  & Pick<MealMenuPlateOrder, 'id' | 'createdAt' | 'updatedAt' | 'code' | 'itemCount' | 'userFullName'>
  & { readonly mealMenu: Pick<StandaloneMealMenu, 'id'>, readonly itemsByDiningStation: ReadonlyArray<KdsTicketMealMenuPlateOrderItemFragment>, readonly athlete: Maybe<KdsTicketOrderAthleteFragment>, readonly parentBulkOrder: (
    Pick<BulkOrder, 'pickupTime' | 'deliveryLocation' | 'code'>
    & { readonly team: Pick<Team, 'name'> }
  ) }
);

export type KdsTicketMenuOrderItemFragment = (
  Pick<MenuOrderItem, 'id' | 'amount' | 'percentConsumed' | 'status' | 'specialRequests' | 'updateCount' | 'updatedAt'>
  & { readonly menuItem: KdsPageMenuItemFragment, readonly options: ReadonlyArray<KdsTicketMenuOrderItemOptionFragment> }
);

export type KdsTicketMealMenuPlateOrderItemFragment = (
  Pick<MealMenuPlateOrderItem, 'id' | 'amount' | 'percentConsumed' | 'status' | 'specialRequests' | 'updateCount' | 'updatedAt'>
  & { readonly menuItem: KdsPageMenuItemFragment, readonly options: ReadonlyArray<KdsTicketMealMenuPlateOrderItemOptionFragment> }
);

export type KdsPageMenuItemFragment = (
  Pick<MenuItem, 'id' | 'name' | 'servingName'>
  & { readonly choices: ReadonlyArray<(
    Pick<MenuItemChoice, 'id' | 'name' | 'position'>
    & { readonly options: ReadonlyArray<Pick<MenuItemChoiceOption, 'id' | 'name' | 'position'>> }
  )> }
);

export type KdsTicketMenuOrderItemOptionFragment = (
  Pick<MenuOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: KdsPageMenuItemChoiceOptionFragment }
);

export type KdsTicketMealMenuPlateOrderItemOptionFragment = (
  Pick<MealMenuPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: KdsPageMenuItemChoiceOptionFragment }
);

export type KdsPageMenuItemChoiceOptionFragment = Pick<MenuItemChoiceOption, 'id' | 'name'>;

export type KdsPageMealMenuDiningStationFragment = (
  Pick<MealMenuDiningStation, 'id' | 'name' | 'position'>
  & { readonly menuItemAppearances: ReadonlyArray<{ readonly menuItem: Pick<MenuItem, 'id'> }> }
);

export type AddMenuOrderKioskMutationVariables = Exact<{
  input: AddMenuOrderForAthleteInput;
}>;


export type AddMenuOrderKioskMutation = { readonly addMenuOrderForAthlete: { readonly menuOrder: (
      { readonly timelineMeal: Maybe<TimelineMealFragment> }
      & MenuOrderFormFragment
    ) } };

export type EditMenuOrderMutationVariables = Exact<{
  input: EditMenuOrderInput;
}>;


export type EditMenuOrderMutation = { readonly editMenuOrder: { readonly menuOrder: MenuOrderFormFragment } };

export type SetMealMenuCheckInsMutationVariables = Exact<{
  input: SetMealMenuCheckInsInput;
}>;


export type SetMealMenuCheckInsMutation = { readonly setMealMenuCheckInsForAthlete: (
    Pick<SetMealMenuCheckInsPayload, 'removedMealMenuCheckInIds'>
    & { readonly addedMealMenuCheckIns: ReadonlyArray<CheckInFragment> }
  ) };

export type KioskConfigurationQueryVariables = Exact<{
  after: Scalars['Date'];
  before: Scalars['Date'];
  clientTimezone: Scalars['Timezone'];
}>;


export type KioskConfigurationQuery = { readonly mealMenusInDateRange: ReadonlyArray<KioskMealMenuPreviewFragment>, readonly teams: ReadonlyArray<KioskTeamPreviewFragment> };

export type MealMenuAdvancedSelectionSharingInfoQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MealMenuAdvancedSelectionSharingInfoQuery = { readonly mealMenu: { readonly advancedSelection: Maybe<AdvancedSelectionFragment> } };

export type KioskTeamPreviewFragment = Pick<Team, 'id' | 'name'>;

export type KioskMealMenuPreviewFragment = (
  Pick<StandaloneMealMenu, 'hasAdvancedSelection' | 'userOrderLimit' | 'isHubCheckInEnabled' | 'hubEventUrl' | 'isOrderable' | 'theme'>
  & { readonly teams: ReadonlyArray<Pick<Team, 'id' | 'name'>>, readonly athletes: ReadonlyArray<Pick<Athlete, 'id'>>, readonly mealMenuDiningStations: ReadonlyArray<(
    Pick<MealMenuDiningStation, 'name' | 'id'>
    & { readonly menuItemAppearances: ReadonlyArray<{ readonly menuItem: Pick<MenuItem, 'id' | 'name' | 'imageUrl'> }> }
  )> }
  & MealMenuSharedTimelineFragment
);

export type OrderKioskAthletesQueryVariables = Exact<{
  mealMenuIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type OrderKioskAthletesQuery = { readonly athletesForMealMenus: ReadonlyArray<OrderKioskAthletePreviewFragment> };

export type OrderKioskAthletePreviewFragment = (
  { readonly kioskPreviewOrderInfo: ReadonlyArray<MenuOrderInfoFragment> }
  & KioskAthleteFragment
);

export type MenuOrderInfoFragment = Pick<MenuOrderInfo, 'mealMenuId' | 'menuOrderCount' | 'mostRecentEditableMenuOrderId'>;

export type OrdersForAthleteFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id' | 'createdAt' | 'code' | 'pickupTime'>
  & { readonly items: ReadonlyArray<Pick<MenuOrderItem, 'id' | 'status'>>, readonly mealMenu: Pick<StandaloneMealMenu, 'id'> }
);

export type CheckInKioskAthletesQueryVariables = Exact<{
  mealMenuIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type CheckInKioskAthletesQuery = { readonly athletesForMealMenus: ReadonlyArray<CheckInKioskAthletePreviewFragment> };

export type CheckInKioskAthletePreviewFragment = (
  { readonly checkInsForMealMenus: ReadonlyArray<CheckInFragment> }
  & KioskAthleteFragment
);

export type CheckInFragment = Pick<MealMenuCheckIn, 'id' | 'mealMenuId' | 'athleteId'>;

export type KioskAthleteFragment = (
  Pick<Athlete, 'id' | 'firstName' | 'lastName'>
  & { readonly team: Pick<Team, 'id' | 'name'>, readonly user: Pick<User, 'id'> }
);

export type KioskAthleteQueryVariables = Exact<{
  athleteId: Scalars['ID'];
  mealMenuId: Scalars['ID'];
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
}>;


export type KioskAthleteQuery = { readonly athlete: AthleteForKioskAthleteFragment };

export type AthleteForKioskAthleteFragment = (
  { readonly mealPlans: ReadonlyArray<(
    Pick<MealPlan, 'createdAt'>
    & PreviewMealPlanFragment
  )>, readonly macroDisplaySettings: Pick<MacroMealPlanDisplaySettings, 'mealProgressViews'>, readonly timelineDate: TimelineDateForKioskFragment, readonly likedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly ordersForMenu: ReadonlyArray<MenuOrderFormFragment> }
  & KioskAthleteFragment
);

export type TimelineDateForKioskFragment = (
  Pick<TimelineDate, 'date' | 'timezone'>
  & { readonly mealPlan: Maybe<MealPlanForKioskFragment>, readonly meals: ReadonlyArray<TimelineMealOrTemplateForKioskTimelineMealFragment | TimelineMealOrTemplateForKioskTimelineMealTemplateFragment>, readonly activities: ReadonlyArray<TimelineActivityOrTemplateForKioskFoodLogActivityFragment | TimelineActivityOrTemplateForKioskTimelineActivityTemplateFragment> }
);

type TimelineActivityOrTemplateForKioskFoodLogActivityFragment = FoodLogActivityTimelineFragment;

type TimelineActivityOrTemplateForKioskTimelineActivityTemplateFragment = TimelineActivityTemplateFragment;

export type TimelineActivityOrTemplateForKioskFragment = TimelineActivityOrTemplateForKioskFoodLogActivityFragment | TimelineActivityOrTemplateForKioskTimelineActivityTemplateFragment;

type TimelineMealOrTemplateForKioskTimelineMealFragment = TimelineMealForKioskFragment;

type TimelineMealOrTemplateForKioskTimelineMealTemplateFragment = TimelineMealTemplateForKioskFragment;

export type TimelineMealOrTemplateForKioskFragment = TimelineMealOrTemplateForKioskTimelineMealFragment | TimelineMealOrTemplateForKioskTimelineMealTemplateFragment;

export type TimelineMealTemplateForKioskFragment = (
  { readonly __typename: 'TimelineMealTemplate' }
  & Pick<TimelineMealTemplate, 'start' | 'durationInMinutes' | 'timezone'>
  & { readonly template: (
    MealTemplateSharedTimelineExchangeMealTemplateFragment
    & MealTemplateForTargetsExchangeMealTemplateFragment
  ) | (
    MealTemplateSharedTimelineMacroMealTemplateFragment
    & MealTemplateForTargetsMacroMealTemplateFragment
  ), readonly mealMenus: ReadonlyArray<MealMenuSharedTimelineFragment> }
);

export type TimelineMealForKioskFragment = (
  { readonly __typename: 'TimelineMeal' }
  & Pick<TimelineMeal, 'id' | 'name' | 'type' | 'timezone' | 'start' | 'durationInMinutes'>
  & { readonly mealMenus: ReadonlyArray<MealMenuSharedTimelineFragment>, readonly servingAmounts: ReadonlyArray<FullServingAmountFragment>, readonly mealTemplate: Maybe<(
    MealTemplateSharedTimelineExchangeMealTemplateFragment
    & MealTemplateForTargetsExchangeMealTemplateFragment
  ) | (
    MealTemplateSharedTimelineMacroMealTemplateFragment
    & MealTemplateForTargetsMacroMealTemplateFragment
  )>, readonly restaurantMenuLogs: ReadonlyArray<RestaurantMenuLogTimelineFragment>, readonly restaurantMenuLinkPlateOrders: ReadonlyArray<RestaurantMenuLinkPlateOrderSharedTimelineFragment>, readonly restaurantMenuLinkOrders: ReadonlyArray<RestaurantMenuLinkOrderSharedTimelineFragment>, readonly menuOrders: ReadonlyArray<MenuOrderTimelineFragment>, readonly mealMenuLogs: ReadonlyArray<MealMenuLogTimelineFragment> }
);

export type MealPlanForKioskFragment = (
  Pick<MealPlan, 'id'>
  & { readonly mealTemplates: ReadonlyArray<(
    MealTemplateSharedTimelineExchangeMealTemplateFragment
    & MealTemplateForTargetsExchangeMealTemplateFragment
  ) | (
    MealTemplateSharedTimelineMacroMealTemplateFragment
    & MealTemplateForTargetsMacroMealTemplateFragment
  )>, readonly activityTemplates: ReadonlyArray<ActivityTemplateTimelineFragment>, readonly promotedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly avoidedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly promotedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly avoidedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment> }
);

export type MealPlanTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MealPlanTemplateQuery = { readonly mealPlanTemplate: FullMealPlanTemplateExchangeMealPlanTemplateFragment | FullMealPlanTemplateMacroMealPlanTemplateFragment };

export type DietitianMealPlanTemplateOffsetConnectionQueryVariables = Exact<{
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
}>;


export type DietitianMealPlanTemplateOffsetConnectionQuery = { readonly mealPlanTemplateOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<DietitianMealPlanTemplateListItemExchangeMealPlanTemplateFragment | DietitianMealPlanTemplateListItemMacroMealPlanTemplateFragment> } };

export type DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryVariables = Exact<{
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
  ownerIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
}>;


export type DietitianOrgGroupMealPlanTemplateOffsetConnectionQuery = { readonly orgGroupMealPlanTemplateOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<DietitianOrgGroupMealPlanTemplateListItemExchangeMealPlanTemplateFragment | DietitianOrgGroupMealPlanTemplateListItemMacroMealPlanTemplateFragment> } };

export type DietitianSharedMealPlanTemplateOffsetConnectionQueryVariables = Exact<{
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
}>;


export type DietitianSharedMealPlanTemplateOffsetConnectionQuery = { readonly sharedMealPlanTemplateOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<DietitianStaffMealPlanTemplateListItemExchangeMealPlanTemplateFragment | DietitianStaffMealPlanTemplateListItemMacroMealPlanTemplateFragment> } };

export type OrgGroupMealPlanTemplateOwnerCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
}>;


export type OrgGroupMealPlanTemplateOwnerCursorConnectionQuery = { readonly orgGroupMealPlanTemplateOwnerCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<MealPlanTemplateOwnerPreviewFragment> } };

export type CreateMacroMealPlanTemplateMutationVariables = Exact<{
  input: CreateMacroMealPlanTemplateInput;
}>;


export type CreateMacroMealPlanTemplateMutation = { readonly createMacroMealPlanTemplate: { readonly macroMealPlanTemplate: FullMealPlanTemplateMacroMealPlanTemplateFragment } };

export type EditMacroMealPlanTemplateMutationVariables = Exact<{
  input: MacroMealPlanTemplatePendingStateInput;
}>;


export type EditMacroMealPlanTemplateMutation = { readonly editMacroMealPlanTemplate: { readonly macroMealPlanTemplate: FullMealPlanTemplateMacroMealPlanTemplateFragment } };

export type PublishMacroMealPlanTemplateMutationVariables = Exact<{
  input: PublishMacroMealPlanTemplateInput;
}>;


export type PublishMacroMealPlanTemplateMutation = { readonly publishMacroMealPlanTemplate: { readonly macroMealPlanTemplate: FullMealPlanTemplateMacroMealPlanTemplateFragment } };

export type CopyMacroMealPlanTemplateMutationVariables = Exact<{
  input: CopyMacroMealPlanTemplateInput;
}>;


export type CopyMacroMealPlanTemplateMutation = { readonly copyMacroMealPlanTemplate: { readonly macroMealPlanTemplate: DietitianMealPlanTemplateListItemMacroMealPlanTemplateFragment } };

export type DeleteMacroMealPlanTemplateMutationVariables = Exact<{
  input: DeleteMacroMealPlanTemplateInput;
}>;


export type DeleteMacroMealPlanTemplateMutation = { readonly deleteMacroMealPlanTemplate: Pick<DeleteMacroMealPlanTemplatePayload, 'macroMealPlanTemplateId'> };

export type DeleteMacroMealPlanTemplatePendingStateMutationVariables = Exact<{
  input: DeleteMacroMealPlanTemplatePendingStateInput;
}>;


export type DeleteMacroMealPlanTemplatePendingStateMutation = { readonly deleteMacroMealPlanTemplatePendingState: { readonly macroMealPlanTemplate: DietitianMealPlanTemplatePendingStateExchangeMealPlanTemplateFragment | DietitianMealPlanTemplatePendingStateMacroMealPlanTemplateFragment } };

export type UpdateMacroMealPlanTemplateSharingScopeMutationVariables = Exact<{
  input: UpdateMacroMealPlanTemplateSharingScopeInput;
}>;


export type UpdateMacroMealPlanTemplateSharingScopeMutation = { readonly updateMacroMealPlanTemplateSharingScope: { readonly macroMealPlanTemplate: DietitianMealPlanTemplateIsSharedExchangeMealPlanTemplateFragment | DietitianMealPlanTemplateIsSharedMacroMealPlanTemplateFragment } };

export type UpdateMacroMealPlanTemplateAssignScopeMutationVariables = Exact<{
  input: UpdateMealPlanTemplateAssignScopeInput;
}>;


export type UpdateMacroMealPlanTemplateAssignScopeMutation = { readonly updateMacroMealPlanTemplateAssignScope: { readonly mealPlanTemplate: DietitianMealPlanTemplateAssignExchangeMealPlanTemplateFragment | DietitianMealPlanTemplateAssignMacroMealPlanTemplateFragment } };

export type EditOrgGroupMacroMealPlanTemplateMutationVariables = Exact<{
  input: MacroMealPlanTemplatePendingStateInput;
}>;


export type EditOrgGroupMacroMealPlanTemplateMutation = { readonly editOrgGroupMacroMealPlanTemplate: { readonly macroMealPlanTemplate: FullMealPlanTemplateMacroMealPlanTemplateFragment } };

export type PublishOrgGroupMacroMealPlanTemplateMutationVariables = Exact<{
  input: PublishMacroMealPlanTemplateInput;
}>;


export type PublishOrgGroupMacroMealPlanTemplateMutation = { readonly publishOrgGroupMacroMealPlanTemplate: { readonly macroMealPlanTemplate: FullMealPlanTemplateMacroMealPlanTemplateFragment } };

export type DeleteOrgGroupMacroMealPlanTemplateMutationVariables = Exact<{
  input: DeleteMacroMealPlanTemplateInput;
}>;


export type DeleteOrgGroupMacroMealPlanTemplateMutation = { readonly deleteOrgGroupMacroMealPlanTemplate: Pick<DeleteMacroMealPlanTemplatePayload, 'macroMealPlanTemplateId'> };

export type DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationVariables = Exact<{
  input: DeleteMacroMealPlanTemplatePendingStateInput;
}>;


export type DeleteOrgGroupMacroMealPlanTemplatePendingStateMutation = { readonly deleteOrgGroupMacroMealPlanTemplatePendingState: { readonly macroMealPlanTemplate: DietitianMealPlanTemplatePendingStateExchangeMealPlanTemplateFragment | DietitianMealPlanTemplatePendingStateMacroMealPlanTemplateFragment } };

export type MoveMacroMealPlanTemplateToOrgGroupMutationVariables = Exact<{
  input: MoveMacroMealPlanTemplateToOrgGroupInput;
}>;


export type MoveMacroMealPlanTemplateToOrgGroupMutation = { readonly moveMacroMealPlanTemplateToOrgGroup: { readonly macroMealPlanTemplate: Pick<MacroMealPlanTemplate, 'id'> } };

export type MoveOrgGroupMacroMealPlanTemplateToOrgMutationVariables = Exact<{
  input: MoveOrgGroupMacroMealPlanTemplateToOrgInput;
}>;


export type MoveOrgGroupMacroMealPlanTemplateToOrgMutation = { readonly moveOrgGroupMacroMealPlanTemplateToOrg: { readonly macroMealPlanTemplate: Pick<MacroMealPlanTemplate, 'id'> } };

export type ResourceSharingOrgGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ResourceSharingOrgGroupsQuery = { readonly resourceSharingOrgGroups: ReadonlyArray<Pick<OrgGroup, 'id' | 'name'>> };

type DietitianMealPlanTemplatePendingStateExchangeMealPlanTemplateFragment = Pick<ExchangeMealPlanTemplate, 'id' | 'pendingState'>;

type DietitianMealPlanTemplatePendingStateMacroMealPlanTemplateFragment = Pick<MacroMealPlanTemplate, 'id' | 'pendingState'>;

export type DietitianMealPlanTemplatePendingStateFragment = DietitianMealPlanTemplatePendingStateExchangeMealPlanTemplateFragment | DietitianMealPlanTemplatePendingStateMacroMealPlanTemplateFragment;

type DietitianMealPlanTemplateIsSharedExchangeMealPlanTemplateFragment = Pick<ExchangeMealPlanTemplate, 'id' | 'isShared'>;

type DietitianMealPlanTemplateIsSharedMacroMealPlanTemplateFragment = Pick<MacroMealPlanTemplate, 'id' | 'isShared'>;

export type DietitianMealPlanTemplateIsSharedFragment = DietitianMealPlanTemplateIsSharedExchangeMealPlanTemplateFragment | DietitianMealPlanTemplateIsSharedMacroMealPlanTemplateFragment;

type DietitianMealPlanTemplateAssignExchangeMealPlanTemplateFragment = (
  Pick<ExchangeMealPlanTemplate, 'id' | 'hasAdvancedSelection'>
  & { readonly teams: Maybe<ReadonlyArray<Pick<Team, 'id' | 'name'>>>, readonly advancedSelection: Maybe<AdvancedSelectionFragment> }
);

type DietitianMealPlanTemplateAssignMacroMealPlanTemplateFragment = (
  Pick<MacroMealPlanTemplate, 'id' | 'hasAdvancedSelection'>
  & { readonly teams: Maybe<ReadonlyArray<Pick<Team, 'id' | 'name'>>>, readonly advancedSelection: Maybe<AdvancedSelectionFragment> }
);

export type DietitianMealPlanTemplateAssignFragment = DietitianMealPlanTemplateAssignExchangeMealPlanTemplateFragment | DietitianMealPlanTemplateAssignMacroMealPlanTemplateFragment;

type DietitianMealPlanTemplateListItemExchangeMealPlanTemplateFragment = (
  Pick<ExchangeMealPlanTemplate, 'id' | 'name' | 'isShared' | 'pendingState' | 'hasAdvancedSelection'>
  & { readonly teams: Maybe<ReadonlyArray<Pick<Team, 'id' | 'name'>>>, readonly advancedSelection: Maybe<AdvancedSelectionFragment> }
);

type DietitianMealPlanTemplateListItemMacroMealPlanTemplateFragment = (
  Pick<MacroMealPlanTemplate, 'id' | 'name' | 'isShared' | 'pendingState' | 'hasAdvancedSelection'>
  & { readonly teams: Maybe<ReadonlyArray<Pick<Team, 'id' | 'name'>>>, readonly advancedSelection: Maybe<AdvancedSelectionFragment> }
);

export type DietitianMealPlanTemplateListItemFragment = DietitianMealPlanTemplateListItemExchangeMealPlanTemplateFragment | DietitianMealPlanTemplateListItemMacroMealPlanTemplateFragment;

export type MealPlanTemplateOwnerPreviewFragment = Pick<User, 'id' | 'firstName' | 'lastName'>;

type DietitianOrgGroupMealPlanTemplateListItemExchangeMealPlanTemplateFragment = (
  Pick<ExchangeMealPlanTemplate, 'id' | 'name' | 'description' | 'pendingState' | 'updatedAt' | 'copiedCount'>
  & { readonly owner: MealPlanTemplateOwnerPreviewFragment }
);

type DietitianOrgGroupMealPlanTemplateListItemMacroMealPlanTemplateFragment = (
  Pick<MacroMealPlanTemplate, 'id' | 'name' | 'description' | 'pendingState' | 'updatedAt' | 'copiedCount'>
  & { readonly owner: MealPlanTemplateOwnerPreviewFragment }
);

export type DietitianOrgGroupMealPlanTemplateListItemFragment = DietitianOrgGroupMealPlanTemplateListItemExchangeMealPlanTemplateFragment | DietitianOrgGroupMealPlanTemplateListItemMacroMealPlanTemplateFragment;

type DietitianStaffMealPlanTemplateListItemExchangeMealPlanTemplateFragment = (
  Pick<ExchangeMealPlanTemplate, 'id' | 'name' | 'isShared' | 'hasAdvancedSelection'>
  & { readonly teams: Maybe<ReadonlyArray<Pick<Team, 'id' | 'name'>>>, readonly advancedSelection: Maybe<AdvancedSelectionFragment> }
);

type DietitianStaffMealPlanTemplateListItemMacroMealPlanTemplateFragment = (
  Pick<MacroMealPlanTemplate, 'id' | 'name' | 'isShared' | 'hasAdvancedSelection'>
  & { readonly teams: Maybe<ReadonlyArray<Pick<Team, 'id' | 'name'>>>, readonly advancedSelection: Maybe<AdvancedSelectionFragment> }
);

export type DietitianStaffMealPlanTemplateListItemFragment = DietitianStaffMealPlanTemplateListItemExchangeMealPlanTemplateFragment | DietitianStaffMealPlanTemplateListItemMacroMealPlanTemplateFragment;

export type MealMenuAttendeesQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
  tagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type MealMenuAttendeesQuery = { readonly mealMenuAttendees: (
    Pick<MealMenuAttendeePayload, 'queryCompletedAt'>
    & { readonly notemealAttendees: ReadonlyArray<(
      Pick<MealMenuAttendeeNotemealAthlete, 'checkedIn' | 'checkInDisplayDate' | 'hasAccess'>
      & { readonly athlete: AthleteForMenuAttendanceFragment }
    )>, readonly nonNotemealAttendees: ReadonlyArray<Pick<MealMenuAttendeeNonNotemealAthlete, 'firstName' | 'lastName' | 'checkedIn' | 'checkInDisplayDate' | 'hasAccess'>> }
  ) };

export type AthleteForMenuAttendanceFragment = (
  { readonly team: Pick<Team, 'id' | 'name'>, readonly position: Maybe<PositionFragment> }
  & AthleteFragment
);

export type PlannedMenuGridMealFragment = (
  Pick<PlannedMenuMeal, 'id' | 'name' | 'type' | 'startTime' | 'endTime' | 'themes'>
  & { readonly plannedMenuMealRows: ReadonlyArray<PlannedMenuMealGridRowFragment> }
);

export type PlannedMenuSummaryFragment = (
  Pick<PlannedMenu, 'id' | 'name' | 'firstOccurrence' | 'lastOccurrence' | 'startDate' | 'endDate' | 'description' | 'occurrence' | 'timezone' | 'lastEditedAt' | 'lockCreatedAt'>
  & { readonly lockCreatedBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>>, readonly weeks: ReadonlyArray<PlannedMenuWeekSummaryFragment> }
);

export type PlannedMenuWeekSummaryFragment = Pick<PlannedMenuWeek, 'id' | 'week' | 'disabledDays'>;

export type PlannedMenuMealGridRowFragment = (
  Pick<PlannedMenuMealRow, 'id' | 'diningStationName' | 'position' | 'foodType'>
  & { readonly plannedMenuMealRowItems: ReadonlyArray<PlannedMenuMealRowGridItemFragment> }
);

export type PlannedMenuMealRowGridItemFragment = (
  Pick<PlannedMenuMealRowItem, 'id' | 'dayOfWeek' | 'position'>
  & { readonly menuItem: Pick<MenuItem, 'id' | 'name' | 'isMissingIngredients'> }
);

export type AthletesBirthdaysFragment = (
  Pick<Athlete, 'id' | 'birthDate' | 'firstName' | 'lastName'>
  & { readonly team: Pick<Team, 'id' | 'name'> }
);

export type PlannedMenuHeaderQueryVariables = Exact<{
  plannedMenuId: Scalars['ID'];
}>;


export type PlannedMenuHeaderQuery = { readonly plannedMenu: (
    { readonly athletesWithBirthdaysInRange: ReadonlyArray<AthletesBirthdaysFragment> }
    & PlannedMenuSummaryFragment
  ) };

export type PlannedMenuGridQueryVariables = Exact<{
  plannedMenuWeekId: Scalars['ID'];
}>;


export type PlannedMenuGridQuery = { readonly plannedMenuMealsForWeek: ReadonlyArray<PlannedMenuGridMealFragment> };

export type SavePlannedMealsMutationVariables = Exact<{
  input: SavePlannedMealsInput;
}>;


export type SavePlannedMealsMutation = { readonly savePlannedMeals: { readonly plannedMenu: Pick<PlannedMenu, 'id'> } };

export type ImportPlannedMenuMutationVariables = Exact<{
  input: ImportPlannedMenuInput;
}>;


export type ImportPlannedMenuMutation = { readonly importPlannedMenu: { readonly mealMenus: ReadonlyArray<MealMenuCalendarFragment> } };

export type LockPlannedMenuMutationVariables = Exact<{
  input: LockPlannedMenuInput;
}>;


export type LockPlannedMenuMutation = { readonly lockPlannedMenu: (
    Pick<LockPlannedMenuPayload, 'lockAcquired'>
    & { readonly plannedMenu: (
      Pick<PlannedMenu, 'id' | 'lockCreatedAt'>
      & { readonly lockCreatedBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
    ) }
  ) };

export type ForceLockPlannedMenuMutationVariables = Exact<{
  input: ForceLockPlannedMenuInput;
}>;


export type ForceLockPlannedMenuMutation = { readonly forceLockPlannedMenu: Pick<ForceLockPlannedMenuPayload, 'plannedMenuId'> };

export type UnlockPlannedMenuMutationVariables = Exact<{
  input: UnlockPlannedMenuInput;
}>;


export type UnlockPlannedMenuMutation = { readonly unlockPlannedMenu: Pick<UnlockPlannedMenuPayload, 'lockReleased'> };

export type PlannedMenuLockTakenSubscriptionVariables = Exact<{
  plannedMenuId: Scalars['ID'];
}>;


export type PlannedMenuLockTakenSubscription = { readonly plannedMenuLockTaken: { readonly payload: { readonly plannedMenu: (
        Pick<PlannedMenu, 'id' | 'lockCreatedAt'>
        & { readonly lockCreatedBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
      ) } } };

export type MenuItemForDigitalDisplayFragment = (
  Pick<MenuItemAppearance, 'id'>
  & { readonly menuItem: (
    Pick<MenuItem, 'name' | 'description'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment>, readonly choices: ReadonlyArray<Pick<MenuItemChoice, 'id'>> }
  ) }
);

export type MealMenuDiningStationForDigitalDisplayFragment = (
  Pick<MealMenuDiningStation, 'id' | 'name' | 'position'>
  & { readonly menuItemAppearances: ReadonlyArray<MenuItemForDigitalDisplayFragment> }
);

export type DigitalDisplaysDialogQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type DigitalDisplaysDialogQuery = { readonly mealMenu: DigiDisplaysMealMenuFragment };

export type DigiDisplaysMealMenuFragment = (
  Pick<StandaloneMealMenu, 'id' | 'name' | 'start' | 'durationInMinutes'>
  & { readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStationForDigitalDisplayFragment> }
);

export type PlannedMenuTableItemFragment = (
  Pick<PlannedMenu, 'id' | 'name' | 'description' | 'firstOccurrence' | 'lastOccurrence' | 'occurrence' | 'timezone' | 'startDate' | 'endDate' | 'mealTypes' | 'lastEditedAt'>
  & { readonly weeks: ReadonlyArray<Pick<PlannedMenuWeek, 'id' | 'week' | 'disabledDays'>>, readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>>, readonly lastEditedBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
);

export type PlannedMenuTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
}>;


export type PlannedMenuTableQuery = { readonly plannedMenuOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<PlannedMenuTableItemFragment> } };

export type CreatePlannedMenuMutationVariables = Exact<{
  input: CreatePlannedMenuInput;
}>;


export type CreatePlannedMenuMutation = { readonly createPlannedMenu: { readonly plannedMenu: PlannedMenuTableItemFragment } };

export type EditPlannedMenuMutationVariables = Exact<{
  input: EditPlannedMenuInput;
}>;


export type EditPlannedMenuMutation = { readonly editPlannedMenu: { readonly plannedMenu: PlannedMenuTableItemFragment } };

export type DeletePlannedMenuMutationVariables = Exact<{
  input: DeletePlannedMenuInput;
}>;


export type DeletePlannedMenuMutation = { readonly deletePlannedMenu: Pick<DeletePlannedMenuPayload, 'plannedMenuId'> };

export type DuplicatePlannedMenuMutationVariables = Exact<{
  input: DuplicatePlannedMenuInput;
}>;


export type DuplicatePlannedMenuMutation = { readonly duplicatePlannedMenu: { readonly plannedMenu: PlannedMenuTableItemFragment } };

export type PlannedMenuFormFragment = Pick<PlannedMenu, 'id' | 'name' | 'description' | 'startDate' | 'endDate' | 'occurrence' | 'timezone'>;

export type MenuItemsTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
}>;


export type MenuItemsTableQuery = { readonly menuItemOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<MenuItemTableFragment> } };

export type MenuItemTableFragment = (
  Pick<MenuItem, 'id' | 'name' | 'servingName' | 'isMissingIngredients'>
  & { readonly history: (
    Pick<MenuItemHistory, 'id'>
    & { readonly ratingCounts: RatingCountValuesFragment }
  ), readonly choices: ReadonlyArray<Pick<MenuItemChoice, 'id' | 'name'>>, readonly ratingCounts: RatingCountValuesFragment, readonly score: Maybe<ScoreAssignmentFragment>, readonly foodCategory: Maybe<FoodCategoryNameFragment>, readonly aiAugmented: Pick<AiAugmented, 'isAiGenerated' | 'isAiTranscribed'> }
);

export type CreateReusableMenuItemMutationVariables = Exact<{
  input: CreateReusableMenuItemInput;
}>;


export type CreateReusableMenuItemMutation = { readonly createReusableMenuItem: { readonly menuItem: Pick<MenuItem, 'id' | 'name' | 'isMissingIngredients'> } };

export type EditReusableMenuItemMutationVariables = Exact<{
  input: EditReusableMenuItemInput;
}>;


export type EditReusableMenuItemMutation = { readonly editReusableMenuItem: { readonly menuItem: Pick<MenuItem, 'id' | 'name' | 'isMissingIngredients'> } };

export type EditReusableMenuItemPartialMutationVariables = Exact<{
  input: EditReusableMenuItemPartialInput;
}>;


export type EditReusableMenuItemPartialMutation = { readonly editReusableMenuItemPartial: { readonly menuItem: (
      Pick<MenuItem, 'id' | 'name' | 'servingName' | 'description' | 'imageUrl' | 'defaultMaxAmount' | 'defaultAvailableForOrder' | 'defaultAllowSpecialRequests' | 'suggestionCategory' | 'isMissingIngredients'>
      & { readonly score: Maybe<ScoreAssignmentFragment>, readonly foodCategory: Maybe<FoodCategoryNameFragment> }
    ) } };

export type DeleteReusableMenuItemMutationVariables = Exact<{
  input: DeleteReusableMenuItemInput;
}>;


export type DeleteReusableMenuItemMutation = { readonly deleteReusableMenuItem: Pick<DeleteReusableMenuItemPayload, 'menuItemId'> };

export type MenuItemRatingsQueryVariables = Exact<{
  menuItemHistoryId: Scalars['ID'];
  input: OffsetPaginationInput;
}>;


export type MenuItemRatingsQuery = { readonly currentReviews: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<RatingForMenuItemFragment> }, readonly allReviews: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<RatingForMenuItemFragment> } };

export type RatingForMenuItemFragment = (
  Pick<MenuItemRating, 'id' | 'review' | 'value' | 'createdAt'>
  & { readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>>, readonly menuOrderItem: Maybe<(
    Pick<MenuOrderItem, 'id'>
    & { readonly order: Pick<MenuOrder, 'id' | 'pickupTime'> }
  )>, readonly menuItem: Pick<MenuItem, 'id' | 'createdAt' | 'isCurrent'> }
);

export type RatingCountValuesFragment = Pick<RatingCounts, 'one' | 'two' | 'three' | 'four' | 'five'>;

export type BonAppetitCafesQueryVariables = Exact<{ [key: string]: never; }>;


export type BonAppetitCafesQuery = { readonly bonAppetitCafes: ReadonlyArray<BonAppetitCafeFragment> };

export type BonAppetitMenusPreviewQueryVariables = Exact<{
  cafeId: Scalars['String'];
  date: Scalars['Date'];
}>;


export type BonAppetitMenusPreviewQuery = { readonly bonAppetitMenusPreview: BonAppetitMenusPreviewFragment };

export type BonAppetitCafeFragment = Pick<BonAppetitCafe, 'id' | 'name'>;

export type BonAppetitMenusPreviewFragment = (
  Pick<BonAppetitMenusPreview, 'cafeId' | 'name'>
  & { readonly days: ReadonlyArray<(
    Pick<BonAppetitMenusPreviewDay, 'date'>
    & { readonly dayParts: ReadonlyArray<Pick<BonAppetitMenusPreviewDayPart, 'id' | 'label' | 'startTime' | 'endTime'>> }
  )> }
);

export type ImportBonAppetitMenusMutationVariables = Exact<{
  input: ImportBonAppetitMenusInput;
}>;


export type ImportBonAppetitMenusMutation = { readonly importBonAppetitMenus: { readonly mealMenus: ReadonlyArray<MealMenuCalendarFragment> } };

export type CbordBusinessUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type CbordBusinessUnitsQuery = { readonly cbordBusinessUnits: ReadonlyArray<CbordBusinessUnitCbordFacilityFragment | CbordBusinessUnitCbordServiceUnitFragment> };

type CbordBusinessUnitCbordFacilityFragment = CbordFacilityFragment;

type CbordBusinessUnitCbordServiceUnitFragment = CbordServiceUnitFragment;

export type CbordBusinessUnitFragment = CbordBusinessUnitCbordFacilityFragment | CbordBusinessUnitCbordServiceUnitFragment;

export type CbordFacilityFragment = (
  { readonly __typename: 'CbordFacility' }
  & Pick<CbordFacility, 'oid' | 'name'>
  & { readonly businessUnits: Maybe<ReadonlyArray<(
    { readonly __typename: 'CbordFacility' }
    & Pick<CbordFacility, 'oid' | 'name'>
  ) | CbordServiceUnitFragment>> }
);

export type CbordServiceUnitFragment = (
  { readonly __typename: 'CbordServiceUnit' }
  & Pick<CbordServiceUnit, 'oid' | 'name' | 'facilityOid'>
);

export type CbordServiceMenusQueryVariables = Exact<{
  serviceUnitOid: Scalars['Int'];
  start: Scalars['Date'];
  end: Scalars['Date'];
  facilityOid: Maybe<Scalars['Int']>;
}>;


export type CbordServiceMenusQuery = { readonly cbordServiceMenus: ReadonlyArray<CbordServiceMenuFragment> };

export type CbordServiceMenuFragment = (
  Pick<CbordServiceMenu, 'oid' | 'meal' | 'serviceDate'>
  & { readonly menuItems: ReadonlyArray<CbordMenuItemFragment> }
);

export type CbordMenuItemFragment = Pick<CbordMenuItem, 'itemOid' | 'serviceName' | 'servedUofmName' | 'serviceStationOid' | 'serviceStationSort' | 'serviceStationName'>;

export type ImportCbordMenusMutationVariables = Exact<{
  input: ImportCbordMenusInput;
}>;


export type ImportCbordMenusMutation = { readonly importCbordMenus: { readonly mealMenus: ReadonlyArray<MealMenuCalendarFragment> } };

export type CompassLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompassLocationsQuery = { readonly compass_locations: ReadonlyArray<CompassLocationFragment> };

export type CompassLocationFragment = (
  Pick<CompassLocation, 'locationId' | 'name'>
  & { readonly mealPeriods: ReadonlyArray<(
    Pick<CompassMealPeriod, 'displayOrder' | 'mealPeriodId' | 'name'>
    & { readonly stations: ReadonlyArray<Pick<CompassStation, 'displayOrder' | 'stationId' | 'name'>> }
  )> }
);

export type CompassMenuItemsQueryVariables = Exact<{
  locationId: Scalars['Int'];
  startDate: Scalars['Date'];
  days: Scalars['Int'];
}>;


export type CompassMenuItemsQuery = { readonly compass_menuItems: ReadonlyArray<CompassMenuItemFragment> };

export type CompassMenuItemFragment = Pick<CompassMenuItem, 'id' | 'date' | 'displayOrder' | 'stationId' | 'mealPeriodId' | 'locationId' | 'portion' | 'name'>;

export type ImportCompassMenusMutationVariables = Exact<{
  input: ImportCompassMenusInput;
}>;


export type ImportCompassMenusMutation = { readonly importCompassMenus: { readonly mealMenus: ReadonlyArray<MealMenuCalendarFragment> } };

export type PlannedMenuWeeksQueryVariables = Exact<{
  plannedMenuId: Scalars['ID'];
}>;


export type PlannedMenuWeeksQuery = { readonly plannedMenu: PlannedMenuWithWeeksFragment };

export type PlannedMenuWithWeeksFragment = (
  Pick<PlannedMenu, 'id' | 'name' | 'timezone' | 'occurrence' | 'firstOccurrence' | 'lastOccurrence'>
  & { readonly weeks: ReadonlyArray<Pick<PlannedMenuWeek, 'id' | 'week' | 'disabledDays'>> }
);

export type PlannedMenuWeeksForImportQueryVariables = Exact<{
  plannedMenuWeekIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type PlannedMenuWeeksForImportQuery = { readonly plannedMenuWeeks: ReadonlyArray<PlannedMenuWeekForImportFragment> };

export type PlannedMenuWeekForImportFragment = (
  Pick<PlannedMenuWeek, 'id' | 'week'>
  & { readonly meals: ReadonlyArray<PlannedMenuMealForImportFragment> }
);

export type PlannedMenuMealForImportFragment = (
  Pick<PlannedMenuMeal, 'id' | 'name' | 'type' | 'startTime' | 'endTime' | 'themes'>
  & { readonly plannedMenuMealRows: ReadonlyArray<PlannedMenuMealRowForImportFragment> }
);

export type PlannedMenuMealRowForImportFragment = (
  Pick<PlannedMenuMealRow, 'id' | 'diningStationName' | 'position' | 'foodType'>
  & { readonly plannedMenuMealRowItems: ReadonlyArray<PlannedMenuMealRowItemForImportFragment> }
);

export type PlannedMenuMealRowItemForImportFragment = (
  Pick<PlannedMenuMealRowItem, 'id' | 'dayOfWeek' | 'position'>
  & { readonly menuItem: Pick<MenuItem, 'id' | 'name' | 'defaultAvailableForOrder' | 'defaultAllowSpecialRequests'> }
);

export type SodexoMenuSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type SodexoMenuSourcesQuery = { readonly sodexoMenuSources: ReadonlyArray<SodexoMenuSourceFragment> };

export type SodexoMealTimesByDayQueryVariables = Exact<{
  locationId: Scalars['String'];
  menuSourceKey: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;


export type SodexoMealTimesByDayQuery = { readonly sodexoMealTimesByDay: SodexoMealTimesByDayFragment };

export type SodexoMenuSourceFragment = Pick<SodexoMenuSource, 'locationId' | 'menuSourceKey' | 'menuSourceName'>;

export type SodexoMealTimesByDayFragment = (
  Pick<SodexoMealTimesByDay, 'locationId' | 'menuSourceKey'>
  & { readonly mealTimesByDay: ReadonlyArray<(
    Pick<SodexoDayMealTimes, 'date'>
    & { readonly mealTimes: ReadonlyArray<Pick<SodexoMealTime, 'start' | 'end' | 'name' | 'sortOrder'>> }
  )> }
);

export type ImportSodexoMenusMutationVariables = Exact<{
  input: ImportSodexoMenusInput;
}>;


export type ImportSodexoMenusMutation = { readonly importSodexoMenus: { readonly mealMenus: ReadonlyArray<MealMenuCalendarFragment> } };

export type MealMenuIngredientsAnalyzerFragment = (
  Pick<StandaloneMealMenu, 'id'>
  & { readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStationIngredientsAnalyzerFragment> }
);

export type MealMenuDiningStationIngredientsAnalyzerFragment = (
  Pick<MealMenuDiningStation, 'id' | 'name' | 'position'>
  & { readonly diningStationTemplate: Maybe<Pick<DiningStationTemplate, 'id'>>, readonly menuItemAppearances: ReadonlyArray<(
    Pick<MenuItemAppearance, 'position'>
    & { readonly menuItem: MenuItemIngredientsAnalyzerFragment }
  )> }
);

export type MealMenuIngredientsAnalyzerQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MealMenuIngredientsAnalyzerQuery = { readonly mealMenu: MealMenuIngredientsAnalyzerFragment };

export type MenuItemIngredientsAnalyzerFragment = (
  Pick<MenuItem, 'id' | 'name' | 'servingName'>
  & { readonly servingAmounts: ReadonlyArray<ExcessiveServingAmountFragment>, readonly choices: ReadonlyArray<(
    Pick<MenuItemChoice, 'id' | 'name' | 'position'>
    & { readonly options: ReadonlyArray<(
      Pick<MenuItemChoiceOption, 'id' | 'name' | 'position'>
      & { readonly servingAmounts: ReadonlyArray<ExcessiveServingAmountFragment> }
    )> }
  )> }
);

export type MealMenuMissingOrdersDialogQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MealMenuMissingOrdersDialogQuery = { readonly mealMenu: MealMenuMissingOrdersFragment };

export type MealMenuMissingOrdersFragment = { readonly allOrders: ReadonlyArray<MenuOrderMissingOrdersFragment>, readonly athletes: ReadonlyArray<AthleteMissingOrdersFragment>, readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLinkMissingOrdersFragment> };

export type MenuOrderMissingOrdersFragment = (
  Pick<MenuOrder, 'id'>
  & { readonly athlete: Maybe<Pick<Athlete, 'id' | 'firstName' | 'lastName'>>, readonly items: ReadonlyArray<Pick<MenuOrderItem, 'id' | 'forOrder'>>, readonly user: Pick<User, 'id'> }
);

export type AthleteMissingOrdersFragment = (
  Pick<Athlete, 'id' | 'firstName' | 'lastName'>
  & { readonly team: Pick<Team, 'id' | 'name'> }
);

export type RestaurantMenuLinkMissingOrdersFragment = { readonly orders: ReadonlyArray<(
    Pick<RestaurantMenuLinkOrder, 'id'>
    & { readonly athlete: Maybe<Pick<Athlete, 'id' | 'firstName' | 'lastName'>>, readonly items: ReadonlyArray<Pick<RestaurantMenuLinkOrderItem, 'id'>>, readonly user: Pick<User, 'id'> }
  )>, readonly plates: ReadonlyArray<(
    Pick<RestaurantMenuLinkPlate, 'id'>
    & { readonly orders: ReadonlyArray<(
      Pick<RestaurantMenuLinkPlateOrder, 'id'>
      & { readonly athlete: Maybe<Pick<Athlete, 'id' | 'firstName' | 'lastName'>>, readonly user: Pick<User, 'id'> }
    )> }
  )> };

export type MealMenuPlateItemOptionFragment = (
  Pick<MealMenuPlateItemOption, 'amount'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id' | 'name'> }
);

export type MealMenuPlateItemFragment = (
  Pick<MealMenuPlateItem, 'id' | 'amount'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MealMenuPlateItemOptionFragment> }
);

export type MealMenuPlateFragment = (
  Pick<MealMenuPlate, 'id'>
  & { readonly mealMenuPlateOrders: ReadonlyArray<{ readonly user: Pick<User, 'id'> }>, readonly plateItems: ReadonlyArray<MealMenuPlateItemFragment> }
);

export type BulkOrderFragment = (
  Pick<BulkOrder, 'id' | 'pickupTime' | 'status'>
  & { readonly createdBy: Pick<User, 'firstName' | 'lastName'>, readonly team: Pick<Team, 'name'>, readonly childMealMenuPlates: ReadonlyArray<MealMenuPlateFragment> }
);

export type BulkOrdersForMealMenuQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type BulkOrdersForMealMenuQuery = { readonly bulkOrdersForMealMenu: ReadonlyArray<BulkOrderFragment> };

export type AthleteMenuSuggestionsPreviewFragment = (
  Pick<Athlete, 'id' | 'firstName' | 'lastName'>
  & { readonly dislikedFoodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>>, readonly mealPlans: ReadonlyArray<(
    Pick<MealPlan, 'id' | 'name'>
    & { readonly avoidedFoodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>>, readonly mealTemplates: ReadonlyArray<(
      { readonly __typename: 'ExchangeMealTemplate' }
      & Pick<ExchangeMealTemplate, 'id'>
      & { readonly exchangeTargets: ReadonlyArray<ExchangeAmountFragment>, readonly meal: Pick<Meal, 'id' | 'type'> }
    ) | (
      { readonly __typename: 'MacroMealTemplate' }
      & Pick<MacroMealTemplate, 'cho' | 'pro' | 'fat' | 'id'>
      & { readonly meal: Pick<Meal, 'id' | 'type'> }
    )> }
  )> }
);

export type MenuSuggestionsPreviewQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MenuSuggestionsPreviewQuery = { readonly mealMenu: (
    Pick<StandaloneMealMenu, 'id' | 'type'>
    & { readonly athletes: ReadonlyArray<(
      Pick<Athlete, 'id' | 'firstName' | 'lastName'>
      & { readonly mealPlans: ReadonlyArray<Pick<MealPlan, 'id'>>, readonly team: Pick<Team, 'id' | 'name'> }
    )> }
  ) };

export type AthleteForMenuSuggestionPreviewQueryVariables = Exact<{
  athleteId: Scalars['ID'];
}>;


export type AthleteForMenuSuggestionPreviewQuery = { readonly athlete: AthleteMenuSuggestionsPreviewFragment };

export type MealMenusInDateRangeQueryVariables = Exact<{
  after: Scalars['Date'];
  before: Scalars['Date'];
  clientTimezone: Scalars['Timezone'];
}>;


export type MealMenusInDateRangeQuery = { readonly mealMenusInDateRange: ReadonlyArray<MealMenuCalendarFragment> };

export type MealMenusByIdsQueryVariables = Exact<{
  mealMenuIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type MealMenusByIdsQuery = { readonly mealMenus: ReadonlyArray<MealMenuCalendarFragment> };

export type MenuCalendarQueryVariables = Exact<{ [key: string]: never; }>;


export type MenuCalendarQuery = { readonly teams: ReadonlyArray<TeamMealMenuPreviewFragment> };

export type MealMenuCalendarBaseFragment = (
  { readonly __typename: 'StandaloneMealMenu' }
  & Pick<StandaloneMealMenu, 'id' | 'name' | 'type' | 'start' | 'timezone' | 'durationInMinutes' | 'prepTimeInMinutes' | 'lastOrderTimeBeforeEndInMinutes' | 'notificationSentBeforeOrderDueInMinutes' | 'userOrderLimit' | 'isHubCheckInEnabled' | 'isOrderAndLogRestricted' | 'hasAdvancedSelection' | 'athleteCount' | 'theme'>
  & { readonly identity: Maybe<MealMenuIdentityBonAppetitMenuIdentityFragment | MealMenuIdentityCbordMenuIdentityFragment | MealMenuIdentityCompassMenuIdentityFragment | MealMenuIdentitySodexoMenuIdentityFragment>, readonly orderRateLimit: Maybe<MenuOrderRateLimitFragment>, readonly allOrders: ReadonlyArray<MealMenuOrdersPlacedOrderFragment>, readonly teams: ReadonlyArray<TeamMealMenuPreviewFragment> }
);

export type MealMenuCalendarFragment = (
  { readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStationPreviewFragment>, readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLinkPreviewFragment> }
  & MealMenuCalendarBaseFragment
);

export type MealMenuPaperQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MealMenuPaperQuery = { readonly mealMenu: MealMenuModalFragment };

export type MealMenuModalFragment = (
  { readonly advancedSelection: Maybe<AdvancedSelectionFragment>, readonly allOrders: ReadonlyArray<MenuOrderCountsFragment>, readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStationFormFragment>, readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLinkFormFragment> }
  & MealMenuCalendarBaseFragment
);

export type FinishedMealMenuPaperQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type FinishedMealMenuPaperQuery = { readonly mealMenu: CurrentMealMenuFragment };

export type CurrentMealMenuFragment = (
  Pick<StandaloneMealMenu, 'id'>
  & { readonly mealMenuDiningStations: ReadonlyArray<(
    Pick<MealMenuDiningStation, 'id' | 'name' | 'position' | 'maxAmount'>
    & { readonly diningStationTemplate: Maybe<Pick<DiningStationTemplate, 'id'>>, readonly menuItemAppearances: ReadonlyArray<(
      Pick<MenuItemAppearance, 'id' | 'maxAmount' | 'position' | 'availableForOrder' | 'allowSpecialRequests'>
      & { readonly menuItem: { readonly history: { readonly current: MenuItemPreviewFragment } } }
    )> }
  )>, readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLinkFormFragment>, readonly advancedSelection: Maybe<AdvancedSelectionFragment> }
);

export type MealMenuDiningStationPreviewFragment = Pick<MealMenuDiningStation, 'id' | 'name' | 'position'>;

export type MealMenuDiningStationFormFragment = (
  Pick<MealMenuDiningStation, 'id' | 'name' | 'position' | 'maxAmount'>
  & { readonly diningStationTemplate: Maybe<Pick<DiningStationTemplate, 'id'>>, readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
);

export type RestaurantMenuLinkPreviewFragment = (
  Pick<RestaurantMenuLink, 'id'>
  & { readonly restaurant: Pick<Restaurant, 'id' | 'name'>, readonly orders: ReadonlyArray<MealMenuRmlOrderPlacedOrderFragment>, readonly plates: ReadonlyArray<MealMenuRmlPlateWithOrdersFragment> }
);

export type MealMenuRmlOrderPlacedOrderFragment = (
  Pick<RestaurantMenuLinkOrder, 'id'>
  & { readonly items: ReadonlyArray<Pick<RestaurantMenuLinkOrderItem, 'id'>>, readonly athlete: Maybe<Pick<Athlete, 'id'>>, readonly user: Pick<User, 'id'> }
);

export type MealMenuRmlPlateWithOrdersFragment = (
  Pick<RestaurantMenuLinkPlate, 'id'>
  & { readonly orders: ReadonlyArray<(
    Pick<RestaurantMenuLinkPlateOrder, 'id'>
    & { readonly athlete: Maybe<Pick<Athlete, 'id'>>, readonly user: Pick<User, 'id'> }
  )> }
);

export type MealMenuOrdersPlacedOrderFragment = (
  Pick<MenuOrder, 'id'>
  & { readonly items: ReadonlyArray<Pick<MenuOrderItem, 'id' | 'forOrder'>>, readonly athlete: Maybe<Pick<Athlete, 'id'>>, readonly user: Pick<User, 'id'> }
);

export type RestaurantMenuLinkFormFragment = (
  Pick<RestaurantMenuLink, 'id' | 'allowCustomOrders' | 'allowPlateOrders'>
  & { readonly restaurant: RestaurantPreviewFragment, readonly restaurantMenu: RestaurantMenuFormFragment, readonly restaurantLocation: Maybe<RestaurantLocationPreviewFragment>, readonly deliveryLocation: Maybe<DeliveryLocationMenuLinkFormFragment>, readonly sections: ReadonlyArray<(
    Pick<RestaurantMenuSectionLink, 'maxAmount'>
    & { readonly restaurantMenuSection: Pick<RestaurantMenuSection, 'id'>, readonly menuItemAppearances: ReadonlyArray<(
      Pick<MenuItemAppearance, 'id' | 'maxAmount' | 'availableForOrder' | 'allowSpecialRequests'>
      & { readonly menuItem: Pick<MenuItem, 'id'> }
    )> }
  )>, readonly plates: ReadonlyArray<RestaurantMenuLinkPlateFormFragment> }
);

export type RestaurantMenuLinkPlateFormFragment = (
  Pick<RestaurantMenuLinkPlate, 'id' | 'position' | 'bulkOrderAmount' | 'isDefault'>
  & { readonly items: ReadonlyArray<RestaurantMenuLinkPlateItemFormFragment>, readonly orders: ReadonlyArray<Pick<RestaurantMenuLinkPlateOrder, 'id'>> }
);

export type DeliveryLocationMenuLinkFormFragment = (
  Pick<DeliveryLocation, 'id' | 'name'>
  & { readonly address: Maybe<Pick<Address, 'displayName'>> }
);

export type RestaurantMenuFormFragment = (
  Pick<RestaurantMenu, 'id'>
  & { readonly sections: ReadonlyArray<RestaurantMenuSectionPreviewFragment> }
);

export type RestaurantMenuSectionPreviewFragment = (
  Pick<RestaurantMenuSection, 'id' | 'name' | 'position'>
  & { readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
);

export type RestaurantPreviewFragment = Pick<Restaurant, 'id' | 'name'>;

export type RestaurantLocationPreviewFragment = Pick<RestaurantLocation, 'id' | 'email' | 'phoneNumber' | 'notes'>;

export type TeamMealMenuPreviewFragment = (
  Pick<Team, 'id' | 'name'>
  & { readonly athletes: ReadonlyArray<Pick<Athlete, 'id'>> }
);

export type MenuOrderCountsFragment = (
  Pick<MenuOrder, 'id'>
  & { readonly items: ReadonlyArray<(
    Pick<MenuOrderItem, 'id' | 'amount' | 'forOrder'>
    & { readonly menuItem: Pick<MenuItem, 'id'> }
  )>, readonly athlete: Maybe<Pick<Athlete, 'id'>>, readonly user: Pick<User, 'id'> }
);

export type RestaurantMenuQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestaurantMenuQuery = { readonly restaurantMenu: RestaurantMenuFormFragment };

export type CreateMealMenusMutationVariables = Exact<{
  input: CreateMealMenusInput;
}>;


export type CreateMealMenusMutation = { readonly createMealMenus: { readonly mealMenus: ReadonlyArray<MealMenuModalFragment> } };

export type EditMealMenuMutationVariables = Exact<{
  input: EditMealMenuInput;
}>;


export type EditMealMenuMutation = { readonly editMealMenu: { readonly mealMenu: MealMenuModalFragment } };

export type EditMealMenuWithIdentityMutationVariables = Exact<{
  input: EditMealMenuWithIdentityInput;
}>;


export type EditMealMenuWithIdentityMutation = { readonly editMealMenuWithIdentity: { readonly mealMenu: MealMenuModalFragment } };

export type BulkEditMealMenusMutationVariables = Exact<{
  input: BulkEditMealMenusInput;
}>;


export type BulkEditMealMenusMutation = { readonly bulkEditMealMenus: { readonly mealMenus: ReadonlyArray<MealMenuModalFragment> } };

export type DeleteMealMenuMutationVariables = Exact<{
  input: DeleteMealMenuInput;
}>;


export type DeleteMealMenuMutation = { readonly deleteMealMenu: Pick<DeleteMealMenuPayload, 'mealMenuId'> };

export type BulkDeleteMealMenusMutationVariables = Exact<{
  input: BulkDeleteMealMenusInput;
}>;


export type BulkDeleteMealMenusMutation = { readonly bulkDeleteMealMenus: Pick<BulkDeleteMealMenusPayload, 'mealMenuIds'> };

export type SendMealMenuNotificationMutationVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type SendMealMenuNotificationMutation = Pick<Mutation, 'sendMealMenuNotification'>;

export type BulkCopyMealMenusMutationVariables = Exact<{
  input: CopyMealMenusInput;
}>;


export type BulkCopyMealMenusMutation = { readonly copyMealMenus: { readonly newMealMenus: ReadonlyArray<Pick<StandaloneMealMenu, 'id'>> } };

export type MenuOrderRateLimitFragment = Pick<MenuOrderRateLimit, 'limit' | 'excludedTeamIds'>;

export type OverrideMealMenuIdentityMutationVariables = Exact<{
  input: OverrideMealMenuIdentityInput;
}>;


export type OverrideMealMenuIdentityMutation = { readonly overrideMealMenuIdentity: { readonly mealMenuIdentity: Pick<BonAppetitMenuIdentity, 'id' | 'isOverridden'> | Pick<CbordMenuIdentity, 'id' | 'isOverridden'> | Pick<CompassMenuIdentity, 'id' | 'isOverridden'> | Pick<SodexoMenuIdentity, 'id' | 'isOverridden'> } };

export type OrderPageMealMenuQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type OrderPageMealMenuQuery = { readonly mealMenu: OrderPageMealMenuFragment };

export type OrderPageMealMenuFragment = (
  Pick<StandaloneMealMenu, 'id' | 'name'>
  & { readonly allOrders: ReadonlyArray<OrderPageMenuOrderFragment>, readonly mealMenuDiningStations: ReadonlyArray<OrderPageMealMenuDiningStationFragment>, readonly restaurantMenuLinks: ReadonlyArray<OrderPageRestaurantMenuLinkPreviewFragment> }
);

export type OrderPageRestaurantMenuLinkQueryVariables = Exact<{
  restaurantMenuLinkId: Scalars['ID'];
}>;


export type OrderPageRestaurantMenuLinkQuery = { readonly restaurantMenuLink: OrderPageRestaurantMenuLinkFragment };

export type OrderPageRestaurantMenuLinkPreviewFragment = (
  Pick<RestaurantMenuLink, 'id'>
  & { readonly restaurant: Pick<Restaurant, 'id' | 'name'>, readonly deliveryLocation: Maybe<OrderPageRestaurantMenuLinkDeliveryLocationFragment> }
);

export type OrderPageRestaurantMenuLinkFragment = (
  Pick<RestaurantMenuLink, 'id'>
  & { readonly restaurant: Pick<Restaurant, 'id' | 'name'>, readonly deliveryLocation: Maybe<OrderPageRestaurantMenuLinkDeliveryLocationFragment>, readonly plates: ReadonlyArray<OrderPageRestaurantMenuLinkPlateFragment>, readonly orders: ReadonlyArray<OrderPageRestaurantMenuLinkOrderFragment> }
);

export type OrderPageRestaurantMenuLinkDeliveryLocationFragment = (
  Pick<DeliveryLocation, 'id' | 'name'>
  & { readonly address: Maybe<Pick<Address, 'displayName'>> }
);

export type OrderPageRestaurantMenuLinkOrderFragment = (
  Pick<RestaurantMenuLinkOrder, 'id' | 'userFullName'>
  & { readonly athlete: Maybe<Pick<Athlete, 'firstName' | 'lastName' | 'jerseyNumber'>>, readonly items: ReadonlyArray<OrderPageRestaurantMenuLinkOrderItemFragment> }
);

export type OrderPageRestaurantMenuLinkOrderItemFragment = (
  Pick<RestaurantMenuLinkOrderItem, 'id' | 'amount' | 'percentConsumed' | 'specialRequests'>
  & { readonly menuItem: OrderPageMenuItemFragment, readonly options: ReadonlyArray<OrderPageRestaurantMenuLinkOrderItemOptionFragment> }
);

export type OrderPageRestaurantMenuLinkOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: OrderPageMenuItemChoiceOptionFragment }
);

export type OrderPageRestaurantMenuLinkPlateFragment = (
  Pick<RestaurantMenuLinkPlate, 'id' | 'position' | 'bulkOrderAmount'>
  & { readonly items: ReadonlyArray<OrderPageRestaurantMenuLinkPlateItemFragment>, readonly orders: ReadonlyArray<OrderPageRestaurantMenuLinkPlateOrderFragment> }
);

export type OrderPageRestaurantMenuLinkPlateItemFragment = (
  Pick<RestaurantMenuLinkPlateItem, 'id' | 'position' | 'amount' | 'percentConsumed' | 'specialRequests'>
  & { readonly menuItem: OrderPageMenuItemFragment, readonly options: ReadonlyArray<OrderPageRestaurantMenuLinkPlateItemOptionFragment> }
);

export type OrderPageRestaurantMenuLinkPlateItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: OrderPageMenuItemChoiceOptionFragment }
);

export type OrderPageRestaurantMenuLinkPlateOrderFragment = (
  Pick<RestaurantMenuLinkPlateOrder, 'id' | 'userFullName'>
  & { readonly athlete: Maybe<Pick<Athlete, 'firstName' | 'lastName' | 'jerseyNumber'>> }
);

export type OrderPageMenuOrderFragment = (
  Pick<MenuOrder, 'id' | 'pickupTime' | 'code'>
  & { readonly items: ReadonlyArray<OrderPageMenuOrderItemFragment>, readonly mealMenu: Pick<StandaloneMealMenu, 'id'> }
);

export type OrderPageMenuOrderItemFragment = (
  Pick<MenuOrderItem, 'id' | 'amount' | 'percentConsumed' | 'forOrder' | 'specialRequests'>
  & { readonly menuItem: OrderPageMenuItemFragment, readonly options: ReadonlyArray<OrderPageMenuOrderItemOptionFragment>, readonly order: (
    Pick<MenuOrder, 'id' | 'pickupTime' | 'code' | 'userFullName'>
    & { readonly athlete: Maybe<(
      Pick<Athlete, 'id' | 'firstName' | 'lastName' | 'jerseyNumber'>
      & { readonly team: Pick<Team, 'id' | 'name'> }
    )> }
  ) }
);

export type OrderPageMenuItemFragment = (
  Pick<MenuItem, 'id' | 'name'>
  & { readonly choices: ReadonlyArray<OrderPageMenuItemChoiceFragment> }
);

export type OrderPageMenuItemChoiceFragment = (
  Pick<MenuItemChoice, 'id' | 'name' | 'position'>
  & { readonly options: ReadonlyArray<Pick<MenuItemChoiceOption, 'id' | 'name' | 'position' | 'canEditAmount'>> }
);

export type OrderPageMenuOrderItemOptionFragment = (
  Pick<MenuOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: OrderPageMenuItemChoiceOptionFragment }
);

export type OrderPageMenuItemChoiceOptionFragment = Pick<MenuItemChoiceOption, 'id' | 'name'>;

export type OrderPageMealMenuDiningStationFragment = (
  Pick<MealMenuDiningStation, 'id' | 'name' | 'position'>
  & { readonly menuItemAppearances: ReadonlyArray<{ readonly menuItem: Pick<MenuItem, 'id'> }> }
);

export type TableRecipeFragment = (
  Pick<Recipe, 'createdAt' | 'isShared' | 'copiedCount' | 'isAiGenerated' | 'isAiTranscribed'>
  & { readonly org: Maybe<Pick<Org, 'id'>>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly servings: ReadonlyArray<ServingFragment>, readonly score: Maybe<ScoreAssignmentFragment>, readonly owner: Pick<User, 'firstName' | 'lastName' | 'id'>, readonly orgGroup: Maybe<Pick<OrgGroup, 'name'>> }
  & RecipeWithIngredientsFragment
);

export type OrgRecipesTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  createdById: Maybe<Scalars['ID']>;
  input: OffsetPaginationInput;
  sort: Maybe<RecipeSort>;
  mealTypes: Maybe<ReadonlyArray<MealType> | MealType>;
  orgId: Maybe<Scalars['ID']>;
}>;


export type OrgRecipesTableQuery = { readonly recipeOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<TableRecipeFragment> } };

export type OrgGroupRecipesTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  ownerIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
  input: OffsetPaginationInput;
  sort: Maybe<RecipeSort>;
  mealTypes: Maybe<ReadonlyArray<MealType> | MealType>;
}>;


export type OrgGroupRecipesTableQuery = { readonly orgGroupRecipeOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<TableRecipeFragment> } };

export type NotemealRecipesTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  createdById: Maybe<Scalars['ID']>;
  input: OffsetPaginationInput;
  sort: Maybe<RecipeSort>;
  mealTypes: Maybe<ReadonlyArray<MealType> | MealType>;
  orgId: Maybe<Scalars['ID']>;
}>;


export type NotemealRecipesTableQuery = { readonly recipeOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<TableRecipeFragment> } };

export type AutoShareNewNotemealRecipesQueryVariables = Exact<{ [key: string]: never; }>;


export type AutoShareNewNotemealRecipesQuery = { readonly myOrg: Pick<Org, 'autoShareNewNotemealRecipes'> };

export type DeleteRecipeMutationVariables = Exact<{
  input: DeleteRecipeInput;
}>;


export type DeleteRecipeMutation = { readonly deleteRecipe: Pick<DeleteRecipePayload, 'recipeId'> };

export type EditRecipeIsSharedMutationVariables = Exact<{
  input: EditRecipeIsSharedInput;
}>;


export type EditRecipeIsSharedMutation = { readonly editRecipeIsShared: { readonly recipe: Pick<Recipe, 'id'> } };

export type EditAutoShareNewNotemealRecipesMutationVariables = Exact<{
  input: EditOrgAutoShareNewNotemealRecipesInput;
}>;


export type EditAutoShareNewNotemealRecipesMutation = { readonly editOrgAutoShareNewNotemealRecipes: { readonly org: Pick<Org, 'autoShareNewNotemealRecipes'> } };

export type MoveRecipeToOrgGroupMutationVariables = Exact<{
  input: MoveOrgRecipeToOrgGroupInput;
}>;


export type MoveRecipeToOrgGroupMutation = { readonly moveOrgRecipeToOrgGroup: { readonly recipe: Pick<Recipe, 'id'> } };

export type OrgGroupRecipeOwnerCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
}>;


export type OrgGroupRecipeOwnerCursorConnectionQuery = { readonly orgGroupRecipeOwnerCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<RecipeOwnerPreviewFragment> } };

export type RecipeOwnerPreviewFragment = Pick<User, 'id' | 'firstName' | 'lastName'>;

export type StaffDashboardAgreementFormsQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type StaffDashboardAgreementFormsQuery = { readonly agreementFormOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<AgreementFormFragment> } };

export type AgreementFormsQueryVariables = Exact<{ [key: string]: never; }>;


export type AgreementFormsQuery = { readonly agreementForms: ReadonlyArray<AgreementFormFragment> };

export type AddAgreementFormMutationVariables = Exact<{
  input: AddAgreementFormInput;
}>;


export type AddAgreementFormMutation = { readonly addAgreementForm: { readonly agreementForm: AgreementFormFragment } };

export type EditAgreementFormMutationVariables = Exact<{
  input: EditAgreementFormInput;
}>;


export type EditAgreementFormMutation = { readonly editAgreementForm: { readonly agreementForm: AgreementFormFragment } };

export type DeliveryLocationsQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type DeliveryLocationsQuery = { readonly allDeliveryLocations: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<DeliveryLocationWithTeamsFragment> } };

export type TeamLabelSearchQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
}>;


export type TeamLabelSearchQuery = { readonly allTeamLabels: { readonly edges: ReadonlyArray<TeamLabelFragment> } };

export type DeliveryLocationWithTeamsFragment = (
  Pick<DeliveryLocation, 'id' | 'name' | 'isActive' | 'deliveryInstructions' | 'cityInfo' | 'googlePlaceId'>
  & { readonly address: Maybe<Pick<Address, 'displayName' | 'lat' | 'long'>>, readonly teamLabels: ReadonlyArray<TeamLabelFragment> }
);

export type AddDeliveryLocationMutationVariables = Exact<{
  input: AddDeliveryLocationInput;
}>;


export type AddDeliveryLocationMutation = { readonly addDeliveryLocation: { readonly deliveryLocation: DeliveryLocationWithTeamsFragment } };

export type EditDeliveryLocationMutationVariables = Exact<{
  input: EditDeliveryLocationInput;
}>;


export type EditDeliveryLocationMutation = { readonly editDeliveryLocation: { readonly deliveryLocation: DeliveryLocationWithTeamsFragment } };

export type StaffDashboardDietsQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type StaffDashboardDietsQuery = { readonly dietOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<DietFragment> } };

export type DietFragment = Pick<Diet, 'id' | 'name'>;

export type CreateDietMutationVariables = Exact<{
  input: CreateDietInput;
}>;


export type CreateDietMutation = { readonly createDiet: { readonly diet: DietFragment } };

export type EditDietMutationVariables = Exact<{
  input: EditDietInput;
}>;


export type EditDietMutation = { readonly editDiet: { readonly diet: DietFragment } };

export type DeleteDietMutationVariables = Exact<{
  input: DeleteDietInput;
}>;


export type DeleteDietMutation = { readonly deleteDiet: Pick<DeleteDietPayload, 'id'> };

export type SharedMealPlanTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SharedMealPlanTemplateQuery = { readonly sharedMealPlanTemplate: FullMealPlanTemplateExchangeMealPlanTemplateFragment | FullMealPlanTemplateMacroMealPlanTemplateFragment };

export type StaffSharedMealPlanTemplateOffsetConnectionQueryVariables = Exact<{
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
}>;


export type StaffSharedMealPlanTemplateOffsetConnectionQuery = { readonly sharedMealPlanTemplateOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<StaffMealPlanTemplateListItemExchangeMealPlanTemplateFragment | StaffMealPlanTemplateListItemMacroMealPlanTemplateFragment> } };

export type CreateSharedMacroMealPlanTemplateMutationVariables = Exact<{
  input: CreateMacroMealPlanTemplateInput;
}>;


export type CreateSharedMacroMealPlanTemplateMutation = { readonly createSharedMacroMealPlanTemplate: { readonly macroMealPlanTemplate: FullMealPlanTemplateMacroMealPlanTemplateFragment } };

export type EditSharedMacroMealPlanTemplateMutationVariables = Exact<{
  input: MacroMealPlanTemplatePendingStateInput;
}>;


export type EditSharedMacroMealPlanTemplateMutation = { readonly editSharedMacroMealPlanTemplate: { readonly macroMealPlanTemplate: FullMealPlanTemplateMacroMealPlanTemplateFragment } };

export type PublishSharedMacroMealPlanTemplateMutationVariables = Exact<{
  input: PublishMacroMealPlanTemplateInput;
}>;


export type PublishSharedMacroMealPlanTemplateMutation = { readonly publishSharedMacroMealPlanTemplate: { readonly macroMealPlanTemplate: FullMealPlanTemplateMacroMealPlanTemplateFragment } };

export type CopySharedMacroMealPlanTemplateMutationVariables = Exact<{
  input: CopyMacroMealPlanTemplateInput;
}>;


export type CopySharedMacroMealPlanTemplateMutation = { readonly copySharedMacroMealPlanTemplate: { readonly macroMealPlanTemplate: FullMealPlanTemplateMacroMealPlanTemplateFragment } };

export type DeleteSharedMacroMealPlanTemplateMutationVariables = Exact<{
  input: DeleteMacroMealPlanTemplateInput;
}>;


export type DeleteSharedMacroMealPlanTemplateMutation = { readonly deleteSharedMacroMealPlanTemplate: Pick<DeleteMacroMealPlanTemplatePayload, 'macroMealPlanTemplateId'> };

export type DeleteSharedMacroMealPlanTemplatePendingStateMutationVariables = Exact<{
  input: DeleteMacroMealPlanTemplatePendingStateInput;
}>;


export type DeleteSharedMacroMealPlanTemplatePendingStateMutation = { readonly deleteSharedMacroMealPlanTemplatePendingState: { readonly macroMealPlanTemplate: StaffMealPlanTemplatePendingStateExchangeMealPlanTemplateFragment | StaffMealPlanTemplatePendingStateMacroMealPlanTemplateFragment } };

export type UpdateSharedMacroMealPlanTemplateSharingScopeMutationVariables = Exact<{
  input: UpdateMacroMealPlanTemplateSharingScopeInput;
}>;


export type UpdateSharedMacroMealPlanTemplateSharingScopeMutation = { readonly updateSharedMacroMealPlanTemplateSharingScope: { readonly macroMealPlanTemplate: StaffMealPlanTemplateIsSharedExchangeMealPlanTemplateFragment | StaffMealPlanTemplateIsSharedMacroMealPlanTemplateFragment } };

type StaffMealPlanTemplateListItemExchangeMealPlanTemplateFragment = Pick<ExchangeMealPlanTemplate, 'id' | 'name' | 'isShared' | 'pendingState'>;

type StaffMealPlanTemplateListItemMacroMealPlanTemplateFragment = Pick<MacroMealPlanTemplate, 'id' | 'name' | 'isShared' | 'pendingState'>;

export type StaffMealPlanTemplateListItemFragment = StaffMealPlanTemplateListItemExchangeMealPlanTemplateFragment | StaffMealPlanTemplateListItemMacroMealPlanTemplateFragment;

type StaffMealPlanTemplatePendingStateExchangeMealPlanTemplateFragment = Pick<ExchangeMealPlanTemplate, 'id' | 'pendingState'>;

type StaffMealPlanTemplatePendingStateMacroMealPlanTemplateFragment = Pick<MacroMealPlanTemplate, 'id' | 'pendingState'>;

export type StaffMealPlanTemplatePendingStateFragment = StaffMealPlanTemplatePendingStateExchangeMealPlanTemplateFragment | StaffMealPlanTemplatePendingStateMacroMealPlanTemplateFragment;

type StaffMealPlanTemplateIsSharedExchangeMealPlanTemplateFragment = Pick<ExchangeMealPlanTemplate, 'id' | 'isShared'>;

type StaffMealPlanTemplateIsSharedMacroMealPlanTemplateFragment = Pick<MacroMealPlanTemplate, 'id' | 'isShared'>;

export type StaffMealPlanTemplateIsSharedFragment = StaffMealPlanTemplateIsSharedExchangeMealPlanTemplateFragment | StaffMealPlanTemplateIsSharedMacroMealPlanTemplateFragment;

type FullMealPlanTemplateExchangeMealPlanTemplateFragment = (
  { readonly __typename: 'ExchangeMealPlanTemplate' }
  & Pick<ExchangeMealPlanTemplate, 'id' | 'name' | 'description' | 'additionalState' | 'isShared' | 'pendingState'>
  & { readonly teams: Maybe<ReadonlyArray<TeamFragment>> }
);

type FullMealPlanTemplateMacroMealPlanTemplateFragment = (
  { readonly __typename: 'MacroMealPlanTemplate' }
  & Pick<MacroMealPlanTemplate, 'id' | 'name' | 'description' | 'additionalState' | 'isShared' | 'pendingState'>
  & { readonly meals: ReadonlyArray<MacroMealPlanTemplateMealFragment>, readonly avoidedFoodGroups: ReadonlyArray<FullFoodGroupFragment>, readonly promotedFoodGroups: ReadonlyArray<FullFoodGroupFragment>, readonly avoidedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly promotedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly activities: ReadonlyArray<MealPlanTemplateActivityFragment>, readonly macroProtocol: MealPlanTemplateMacroProtocolFragment, readonly teams: Maybe<ReadonlyArray<TeamFragment>> }
);

export type FullMealPlanTemplateFragment = FullMealPlanTemplateExchangeMealPlanTemplateFragment | FullMealPlanTemplateMacroMealPlanTemplateFragment;

export type MealPlanTemplateActivityFragment = Pick<MealPlanTemplateActivity, 'id' | 'name' | 'type' | 'start' | 'end'>;

export type MacroMealPlanTemplateMealFragment = (
  Pick<MacroMealPlanTemplateMeal, 'id' | 'name' | 'type' | 'start' | 'end' | 'choRatio' | 'proRatio' | 'fatRatio'>
  & { readonly mealOptions: ReadonlyArray<MealPlanTemplateMealOptionFragment> }
);

export type MealPlanTemplateMealOptionFragment = (
  Pick<MealPlanTemplateMealOption, 'id' | 'position' | 'note' | 'name'>
  & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
);

export type MealPlanTemplateMacroProtocolFragment = (
  Pick<MealPlanTemplateMacroProtocol, 'id' | 'useExistingAnthropometry'>
  & { readonly anthropometry: MealPlanTemplateAnthropometryAnthropometryTemplateFragment | MealPlanTemplateAnthropometryAnthropometryFormFragment, readonly calorieBudget: Maybe<MealPlanTemplateCalorieBudgetFragment>, readonly cho: MacroMathFragment, readonly pro: MacroMathFragment, readonly fat: MacroMathFragment }
);

type MealPlanTemplateAnthropometryAnthropometryTemplateFragment = AnthropometryTemplateFragment;

type MealPlanTemplateAnthropometryAnthropometryFormFragment = AnthropometryFormFragment;

export type MealPlanTemplateAnthropometryFragment = MealPlanTemplateAnthropometryAnthropometryTemplateFragment | MealPlanTemplateAnthropometryAnthropometryFormFragment;

export type AnthropometryTemplateFragment = (
  { readonly __typename: 'AnthropometryTemplate' }
  & Pick<AnthropometryTemplate, 'id' | 'height' | 'weight' | 'percentBodyFat'>
);

export type AnthropometryFormFragment = (
  { readonly __typename: 'AnthropometryForm' }
  & { readonly questions: ReadonlyArray<Pick<AnthropometryFormQuestion, 'field'>> }
);

export type MealPlanTemplateCalorieBudgetFragment = (
  Pick<MealPlanTemplateCalorieBudget, 'id' | 'rmrMethod' | 'activityFactor'>
  & { readonly goals: ReadonlyArray<MealPlanTemplateGoalFragment> }
);

export type MealPlanTemplateGoalFragment = (
  Pick<MealPlanTemplateGoal, 'id' | 'name' | 'kcalOffset'>
  & { readonly goalType: Pick<GoalType, 'id' | 'name'> }
);

export type OrgGroupOrgFragment = Pick<Org, 'id' | 'name'>;

export type EditableOrgGroupFragment = (
  Pick<OrgGroup, 'id' | 'name' | 'allowsAthleteTransfer' | 'allowsResourceSharing'>
  & { readonly orgs: ReadonlyArray<OrgGroupOrgFragment>, readonly parentOrgGroup: Maybe<ParentOrgGroupOptionFragment> }
);

export type OrgGroupOrgOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgGroupOrgOptionsQuery = { readonly orgs: ReadonlyArray<OrgGroupOrgFragment> };

export type EditableOrgGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EditableOrgGroupQuery = { readonly orgGroup: EditableOrgGroupFragment };

export type ParentOrgGroupOptionFragment = Pick<OrgGroup, 'id' | 'name'>;

export type ParentOrgGroupOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ParentOrgGroupOptionsQuery = { readonly orgGroups: ReadonlyArray<ParentOrgGroupOptionFragment> };

export type StaffDashboardOrgGroupFragment = (
  Pick<OrgGroup, 'id' | 'name' | 'orgsCount'>
  & { readonly parentOrgGroup: Maybe<Pick<OrgGroup, 'id' | 'name'>>, readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'username'>> }
);

export type StaffDashboardOrgGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffDashboardOrgGroupsQuery = { readonly orgGroups: ReadonlyArray<StaffDashboardOrgGroupFragment> };

export type StaffDashboardCreateOrgGroupMutationVariables = Exact<{
  input: CreateOrgGroupInput;
}>;


export type StaffDashboardCreateOrgGroupMutation = { readonly createOrgGroup: { readonly orgGroup: StaffDashboardOrgGroupFragment } };

export type StaffDashboardEditOrgGroupMutationVariables = Exact<{
  input: EditOrgGroupInput;
}>;


export type StaffDashboardEditOrgGroupMutation = { readonly editOrgGroup: { readonly orgGroup: StaffDashboardOrgGroupFragment } };

export type OrgsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgsQuery = { readonly orgs: ReadonlyArray<StaffDashboardOrgPreviewFragment> };

export type OrgsForOrgLinkerQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgsForOrgLinkerQuery = { readonly orgs: ReadonlyArray<OrgLinkerOrgFragment> };

export type OrgForLinkingQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type OrgForLinkingQuery = { readonly org: OrgForLinkingFragment };

export type PositionWithMappingsFragment = (
  Pick<Position, 'id' | 'name'>
  & { readonly teamworksMappings: ReadonlyArray<Pick<TeamworksPositionMapping, 'teamworksName'>> }
);

export type SportsWithPositionMappingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SportsWithPositionMappingsQuery = { readonly sports: ReadonlyArray<SportWithPositionMappingsFragment> };

export type SportWithPositionMappingsFragment = (
  Pick<Sport, 'id' | 'name'>
  & { readonly positions: ReadonlyArray<PositionWithMappingsFragment> }
);

export type TeamworksSportMappingFragment = (
  Pick<TeamworksSportMapping, 'teamworksName'>
  & { readonly sport: SportWithPositionMappingsFragment }
);

export type TeamLinkPreviewFragment = (
  Pick<Team, 'id' | 'gender' | 'name' | 'teamworksId'>
  & { readonly sport: Maybe<SportWithPositionMappingsFragment> }
);

export type OrgLinkerOrgFragment = (
  Pick<Org, 'id' | 'name' | 'teamworksId'>
  & { readonly teams: ReadonlyArray<Pick<Team, 'name'>> }
);

export type OrgForLinkingFragment = (
  Pick<Org, 'id' | 'name' | 'teamworksId' | 'isFullySynced' | 'isNotemealLinked' | 'isTeamworksLinked'>
  & { readonly teams: ReadonlyArray<TeamLinkPreviewFragment>, readonly teamworksProfileSyncRules: ReadonlyArray<TeamworksProfileSyncRuleFragment>, readonly memberships: ReadonlyArray<OrgMembershipForLinkingFragment>, readonly athletes: ReadonlyArray<AthleteForLinkingFragment> }
);

export type StaffDashboardOrgPreviewFragment = (
  Pick<Org, 'id' | 'name' | 'isActive' | 'allowsPublicAccess' | 'teamworksId' | 'isFullySynced' | 'isNotemealLinked' | 'isTeamworksLinked' | 'localeCode' | 'territoryName'>
  & { readonly integrations: ReadonlyArray<IntegrationFragment>, readonly agreementForm: Maybe<AgreementFormFragment>, readonly scoringSystem: Maybe<ScoringSystemFragment> }
);

export type IntegrationFragment = Pick<Integration, 'externalOrgId' | 'type'>;

export type TeamworksTeamFragment = (
  Pick<TeamworksTeam, 'id' | 'name' | 'sport'>
  & { readonly sportMappings: ReadonlyArray<TeamworksSportMappingFragment>, readonly notemealTeams: ReadonlyArray<Pick<Team, 'id' | 'name'>> }
);

export type TeamworksOrgFragment = (
  Pick<TeamworksOrg, 'id' | 'name' | 'shortName'>
  & { readonly teams: ReadonlyArray<TeamworksTeamFragment> }
);

export type TeamworksProfileFragment = (
  Pick<TeamworksProfile, 'id' | 'userId' | 'firstName' | 'lastName' | 'email' | 'cellPhone' | 'birthDate' | 'gender' | 'hasLoginAccess' | 'active'>
  & { readonly memberships: Maybe<ReadonlyArray<TeamworksProfileMembershipFragment>> }
);

export type TeamworksProfileMembershipFragment = (
  Pick<TeamworksMembership, 'teamId' | 'teamName'>
  & { readonly athleteStatus: Maybe<Pick<TeamworksAthleteStatus, 'id' | 'name' | 'active'>>, readonly userTypes: Maybe<ReadonlyArray<Pick<TeamworksUserType, 'userTypeId' | 'userTypeName'>>>, readonly positions: Maybe<ReadonlyArray<Pick<TeamworksPosition, 'id' | 'name'>>> }
);

export type TeamworksOrgsQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type TeamworksOrgsQuery = { readonly teamworksOrgCursorConnection: { readonly edges: ReadonlyArray<TeamworksOrgFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type TeamworksOrgQueryVariables = Exact<{
  teamworksId: Scalars['Int'];
}>;


export type TeamworksOrgQuery = { readonly teamworksOrg: TeamworksOrgFragment, readonly teamworksOrgGroups: ReadonlyArray<TeamworksGroupFragment> };

export type TeamworksDisabledProfileIdsQueryVariables = Exact<{
  teamworksOrgId: Scalars['Int'];
}>;


export type TeamworksDisabledProfileIdsQuery = Pick<Query, 'teamworksDisabledProfileIds'>;

export type TeamworksProfileCursorConnectionQueryVariables = Exact<{
  teamworksOrgId: Scalars['Int'];
  pagination: CursorPaginationInput;
}>;


export type TeamworksProfileCursorConnectionQuery = { readonly teamworksProfileCursorConnection: { readonly edges: ReadonlyArray<TeamworksProfileFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type TeamworksPositionFragment = Pick<TeamworksGroup, 'id' | 'label' | 'teamId' | 'type'>;

export type TeamworksPositionsQueryVariables = Exact<{
  teamworksOrgId: Scalars['Int'];
  teamId: Scalars['Int'];
}>;


export type TeamworksPositionsQuery = { readonly teamworksOrgGroups: ReadonlyArray<TeamworksPositionFragment> };

export type TeamworksPositionLinkFragment = (
  Pick<TeamworksPositionLink, 'teamworksId' | 'teamworksTeamId'>
  & { readonly position: PositionWithMappingsFragment }
);

export type TeamworksPositionLinksQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamworksPositionLinksQuery = { readonly teamworksPositionLinks: ReadonlyArray<TeamworksPositionLinkFragment> };

export type TeamworksSportMappingsQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
}>;


export type TeamworksSportMappingsQuery = { readonly teamworksSportMappings: ReadonlyArray<TeamworksSportMappingFragment> };

export type CreateOrgFromTeamworksOrgMutationVariables = Exact<{
  input: CreateOrgFromTeamworksOrgInput;
}>;


export type CreateOrgFromTeamworksOrgMutation = { readonly createOrgFromTeamworksOrg: { readonly org: OrgLinkerOrgFragment } };

export type LinkTeamworksOrgMutationVariables = Exact<{
  input: LinkTeamworksOrgInput;
}>;


export type LinkTeamworksOrgMutation = { readonly linkTeamworksOrg: { readonly org: OrgLinkerOrgFragment } };

export type AddAndLinkTeamworksTeamsMutationVariables = Exact<{
  input: AddAndLinkTeamworksTeamsInput;
}>;


export type AddAndLinkTeamworksTeamsMutation = { readonly addAndLinkTeamworksTeams: { readonly org: (
      Pick<Org, 'id'>
      & { readonly teams: ReadonlyArray<TeamLinkPreviewFragment> }
    ) } };

export type EditOrgTeamworksProfilesMutationVariables = Exact<{
  input: EditOrgTeamworksProfilesInput;
}>;


export type EditOrgTeamworksProfilesMutation = { readonly editOrgTeamworksProfiles: { readonly org: OrgForLinkingFragment, readonly skippedProfiles: ReadonlyArray<Pick<EditOrgTeamworksSkippedProfile, 'teamworksUserId' | 'name' | 'reason' | 'conflictedUserIds'>> } };

export type TeamworksProfileSyncRuleFragment = Pick<TeamworksProfileSyncRule, 'id' | 'profileIds' | 'teamIds' | 'athleteStatusIds' | 'userTypeIds' | 'notemealAccountType' | 'priority' | 'matchNotemealTeamIds' | 'positionIds' | 'genders'>;

export type TeamworksGroupFragment = Pick<TeamworksGroup, 'id' | 'label' | 'type' | 'teamId'>;

export type OrgMembershipForLinkingFragment = (
  { readonly __typename: 'OrgMembership' }
  & Pick<OrgMembership, 'id' | 'isActive' | 'teamworksId' | 'isNotemealOnly' | 'roles'>
  & { readonly user: Pick<User, 'id' | 'teamworksId' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'username'>, readonly athlete: Maybe<Pick<Athlete, 'id' | 'isArchived' | 'sex'>> }
);

export type AthleteForLinkingFragment = (
  { readonly __typename: 'Athlete' }
  & Pick<Athlete, 'id' | 'isArchived' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'birthDate' | 'sex' | 'isNotemealOnly'>
  & { readonly user: Pick<User, 'id'>, readonly team: Pick<Team, 'teamworksId' | 'id'> }
);

export type OrgsStatusSyncQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgsStatusSyncQuery = { readonly orgs: ReadonlyArray<OrgStatusSyncFragment> };

export type OrgStatusSyncFragment = (
  Pick<Org, 'id' | 'name' | 'teamworksId'>
  & { readonly teamworksSyncStatus: Maybe<TeamworksSyncStatusFragment> }
);

export type RefreshTeamworksSyncStatusMutationVariables = Exact<{
  input: RefreshTeamworksSyncStatusInput;
}>;


export type RefreshTeamworksSyncStatusMutation = { readonly refreshTeamworksSyncStatus: { readonly org: (
      Pick<Org, 'id'>
      & { readonly teamworksSyncStatus: Maybe<TeamworksSyncStatusFragment> }
    ) } };

export type RunMaintainTeamworksFullySyncedJobMutationVariables = Exact<{
  input: RefreshTeamworksSyncStatusInput;
}>;


export type RunMaintainTeamworksFullySyncedJobMutation = { readonly runMaintainTeamworksFullySyncedJob: { readonly org: Pick<Org, 'id'> } };

export type EditTeamForStaffMutationVariables = Exact<{
  input: EditTeamForStaffInput;
}>;


export type EditTeamForStaffMutation = { readonly editTeamForStaff: { readonly team: TeamLinkPreviewFragment } };

export type TeamworksSyncStatusFragment = Pick<TeamworksSyncStatus, 'linkedProfileCount' | 'toDeactivateProfileCount' | 'unlinkedNotemealProfileCount' | 'unlinkedTeamworksProfileCount' | 'updatedAt' | 'isFullySynced' | 'isNotemealLinked' | 'isTeamworksLinked'>;

export type IsEmailUniqueQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type IsEmailUniqueQuery = Pick<Query, 'isEmailUnique'>;

export type IsPhoneNumberUniqueQueryVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type IsPhoneNumberUniqueQuery = Pick<Query, 'isPhoneNumberUnique'>;

export type ContactFragment = Pick<RestaurantLocationContact, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'phoneNumber' | 'preferredContactMethod'>;

export type AddRestaurantLocationContactMutationVariables = Exact<{
  input: AddRestaurantLocationContactInput;
}>;


export type AddRestaurantLocationContactMutation = { readonly addRestaurantLocationContact: { readonly restaurantLocationContact: ContactFragment } };

export type EditRestaurantLocationContactMutationVariables = Exact<{
  input: EditRestaurantLocationContactInput;
}>;


export type EditRestaurantLocationContactMutation = { readonly editRestaurantLocationContact: { readonly restaurantLocationContact: ContactFragment } };

export type GoogleMapsApiKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type GoogleMapsApiKeyQuery = Pick<Query, 'googleMapsApiKey'>;

export type RestaurantLocationsQueryVariables = Exact<{
  input: OffsetPaginationInput;
  restaurantId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
}>;


export type RestaurantLocationsQuery = { readonly allRestaurantLocations: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<RestaurantLocationFragment> } };

export type RestaurantLocationFragment = (
  Pick<RestaurantLocation, 'id' | 'cityInfo' | 'googlePlaceId' | 'email' | 'phoneNumber' | 'notes' | 'isActive'>
  & { readonly address: Maybe<Pick<Address, 'displayName' | 'lat' | 'long'>>, readonly contacts: ReadonlyArray<ContactFragment>, readonly deliveryLocations: ReadonlyArray<DeliveryLocationMenuLinkFormFragment> }
);

export type AddRestaurantLocationMutationVariables = Exact<{
  input: AddRestaurantLocationInput;
}>;


export type AddRestaurantLocationMutation = { readonly addRestaurantLocation: { readonly restaurantLocation: RestaurantLocationFragment } };

export type EditRestaurantLocationMutationVariables = Exact<{
  input: EditRestaurantLocationInput;
}>;


export type EditRestaurantLocationMutation = { readonly editRestaurantLocation: { readonly restaurantLocation: RestaurantLocationFragment } };

export type DeliveryLocationSearchQueryVariables = Exact<{
  teamLabelId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
}>;


export type DeliveryLocationSearchQuery = { readonly allDeliveryLocations: { readonly edges: ReadonlyArray<DeliveryLocationMenuLinkFormFragment> } };

export type RestaurantQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestaurantQuery = { readonly restaurant: StaffDashboardRestaurantFragment };

export type StaffDashboardRestaurantFragment = (
  Pick<Restaurant, 'id' | 'name' | 'nutritionixId'>
  & { readonly menu: Maybe<RestaurantMenuFragment> }
);

export type RestaurantMenuFragment = (
  Pick<RestaurantMenu, 'id'>
  & { readonly sections: ReadonlyArray<StaffDashboardRestaurantMenuSectionFragment>, readonly supportedDiets: ReadonlyArray<DietFragment> }
);

export type StaffDashboardRestaurantMenuSectionFragment = (
  Pick<RestaurantMenuSection, 'id' | 'name' | 'position'>
  & { readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
);

export type CreateRestaurantMenuMutationVariables = Exact<{
  input: CreateRestaurantMenuInput;
}>;


export type CreateRestaurantMenuMutation = { readonly createRestaurantMenu: { readonly restaurantMenu: RestaurantMenuFragment } };

export type EditRestaurantMenuMutationVariables = Exact<{
  input: EditRestaurantMenuInput;
}>;


export type EditRestaurantMenuMutation = { readonly editRestaurantMenu: { readonly restaurantMenu: RestaurantMenuFragment } };

export type StaffDashboardRestaurantTypesQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type StaffDashboardRestaurantTypesQuery = { readonly restaurantTypeOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<RestaurantTypeFragment> } };

export type RestaurantTypeFragment = Pick<RestaurantType, 'id' | 'name'>;

export type CreateRestaurantTypeMutationVariables = Exact<{
  input: CreateRestaurantTypeInput;
}>;


export type CreateRestaurantTypeMutation = { readonly createRestaurantType: { readonly restaurantType: RestaurantTypeFragment } };

export type EditRestaurantTypeMutationVariables = Exact<{
  input: EditRestaurantTypeInput;
}>;


export type EditRestaurantTypeMutation = { readonly editRestaurantType: { readonly restaurantType: RestaurantTypeFragment } };

export type DeleteRestaurantTypeMutationVariables = Exact<{
  input: DeleteRestaurantTypeInput;
}>;


export type DeleteRestaurantTypeMutation = { readonly deleteRestaurantType: Pick<DeleteRestaurantTypePayload, 'id'> };

export type AllRestaurantsQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type AllRestaurantsQuery = { readonly allRestaurants: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<StaffDashboardRestaurantPreviewFragment> } };

export type RestaurantFullQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestaurantFullQuery = { readonly restaurant: StaffDashboardRestaurantFullFragment };

export type StaffDashboardRestaurantPreviewFragment = (
  Pick<Restaurant, 'id' | 'name' | 'nutritionixId' | 'isActive' | 'athleteVisible'>
  & { readonly menu: Maybe<Pick<RestaurantMenu, 'id'>> }
);

export type StaffDashboardRestaurantFullFragment = (
  Pick<Restaurant, 'id' | 'name' | 'nutritionixId' | 'isActive' | 'website' | 'proteinPortionSizeInOunces' | 'firstPartyDelivery' | 'athleteVisible' | 'refundPolicy' | 'refundDueBeforeInMinutes'>
  & { readonly restaurantType: Maybe<RestaurantTypeFragment>, readonly paymentMethods: ReadonlyArray<PaymentMethodFragment>, readonly cuisines: ReadonlyArray<CuisineFragment> }
);

export type EditRestaurantMutationVariables = Exact<{
  input: EditRestaurantInput;
}>;


export type EditRestaurantMutation = { readonly editRestaurant: { readonly restaurant: StaffDashboardRestaurantFullFragment } };

export type CreateRestaurantMutationVariables = Exact<{
  input: CreateRestaurantInput;
}>;


export type CreateRestaurantMutation = { readonly createRestaurant: { readonly restaurant: StaffDashboardRestaurantFullFragment } };

export type TeamLabelsQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type TeamLabelsQuery = { readonly allTeamLabels: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<TeamLabelFragment> } };

export type TeamLabelFragment = Pick<TeamLabel, 'id' | 'name' | 'isActive'>;

export type AddTeamLabelMutationVariables = Exact<{
  input: AddTeamLabelInput;
}>;


export type AddTeamLabelMutation = { readonly addTeamLabel: { readonly teamLabel: TeamLabelFragment } };

export type EditTeamLabelMutationVariables = Exact<{
  input: EditTeamLabelInput;
}>;


export type EditTeamLabelMutation = { readonly editTeamLabel: { readonly teamLabel: TeamLabelFragment } };

export type TerritoryFragment = (
  Pick<Territory, 'id' | 'name'>
  & { readonly orgs: ReadonlyArray<Pick<Org, 'id'>> }
);

export type TerritoryOffsetConnectionQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type TerritoryOffsetConnectionQuery = { readonly territoryOffsetConnection: { readonly edges: ReadonlyArray<TerritoryFragment>, readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'> } };

export type CreateTerritoryMutationVariables = Exact<{
  input: CreateTerritoryInput;
}>;


export type CreateTerritoryMutation = { readonly createTerritory: TerritoryFragment };

export type EditTerritoryMutationVariables = Exact<{
  input: EditTerritoryInput;
}>;


export type EditTerritoryMutation = { readonly editTerritory: TerritoryFragment };

export type OrgsForTerritoryQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgsForTerritoryQuery = { readonly orgs: ReadonlyArray<OrgForTerritoryFragment> };

export type OrgForTerritoryFragment = Pick<Org, 'id' | 'name'>;

export type StaffDashboardUserPreviewFragment = (
  Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'email' | 'phoneNumber' | 'isActive' | 'teamworksId'>
  & { readonly orgInfo: ReadonlyArray<{ readonly org: Pick<OrgResult, 'id' | 'name'>, readonly orgMembership: Maybe<Pick<OrgMembership, 'id' | 'isActive' | 'roles' | 'teamworksId'>>, readonly athlete: Maybe<Pick<Athlete, 'id' | 'firstName' | 'lastName'>> }> }
);

export type StaffDashboardUsersQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type StaffDashboardUsersQuery = { readonly staffUserSearchOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<StaffDashboardUserPreviewFragment> } };

export type OrgQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrgQuery = { readonly org: StaffDashboardOrgFragment };

export type ProfileUsersQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type ProfileUsersQuery = { readonly profileUsers: ReadonlyArray<(
    Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    & { readonly orgInfo: ReadonlyArray<{ readonly org: Pick<OrgResult, 'name'> }> }
  )> };

export type EditOrgMutationVariables = Exact<{
  input: EditOrgInput;
}>;


export type EditOrgMutation = { readonly editOrg: { readonly org: StaffDashboardOrgFragment } };

export type CreateOrgMutationVariables = Exact<{
  input: CreateOrgInput;
}>;


export type CreateOrgMutation = { readonly createOrg: { readonly org: StaffDashboardOrgFragment } };

export type EditOrgFeatureFlagsMutationVariables = Exact<{
  input: EditOrgFeatureFlagsInput;
}>;


export type EditOrgFeatureFlagsMutation = { readonly editOrgFeatureFlags: { readonly featureFlags: FeatureFlagsFragment } };

export type AddPositionMutationVariables = Exact<{
  input: AddPositionInput;
}>;


export type AddPositionMutation = { readonly addPosition: { readonly position: PositionFragment } };

export type AddSportMutationVariables = Exact<{
  input: AddSportInput;
}>;


export type AddSportMutation = { readonly addSport: { readonly sport: SportFragment } };

export type EditUserTeamworksIdToOverwriteMutationVariables = Exact<{
  input: EditUserTeamworksIdToOverwriteInput;
}>;


export type EditUserTeamworksIdToOverwriteMutation = { readonly editUserTeamworksIdToOverwrite: Pick<EditUserTeamworksIdToOverwritePayload, 'userIds'> };

export type StaffDashboardOrgFragment = (
  Pick<Org, 'id' | 'name' | 'isActive' | 'allowsPublicAccess' | 'teamworksId' | 'isFullySynced' | 'isNotemealLinked' | 'isTeamworksLinked' | 'hasTwSuperuserPersonId' | 'localeCode' | 'territoryName'>
  & { readonly memberships: ReadonlyArray<StaffDashboardOrgMembershipFragment>, readonly featureFlags: FeatureFlagsFragment, readonly integrations: ReadonlyArray<IntegrationFragment>, readonly agreementForm: Maybe<AgreementFormFragment>, readonly scoringSystem: Maybe<ScoringSystemFragment> }
);

export type StaffDashboardOrgMembershipFragment = (
  Pick<OrgMembership, 'id' | 'roles' | 'isActive' | 'isAdmin' | 'lastRefresh' | 'teamworksId'>
  & { readonly user: (
    Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'lastLogin' | 'phoneNumber' | 'email'>
    & { userTeamworksId: User['teamworksId'] }
  ) }
);

export type TagTableQueryVariables = Exact<{
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type TagTableQuery = { readonly namedTagOffsetConnection: { readonly edges: ReadonlyArray<NamedTagPreviewFragment>, readonly pageInfo: Pick<OffsetPageInfo, 'endOffset' | 'hasNextPage' | 'total'> } };

export type NamedTagPreviewFragment = { readonly tag: (
    { readonly __typename: 'AthleteTag' }
    & Pick<AthleteTag, 'id' | 'athleteCount'>
    & { readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
  ) | (
    { readonly __typename: 'ComboTag' }
    & Pick<ComboTag, 'id' | 'athleteCount'>
    & { readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
  ) | (
    { readonly __typename: 'PositionTag' }
    & Pick<PositionTag, 'id' | 'athleteCount'>
    & { readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
  ) | (
    { readonly __typename: 'TeamTag' }
    & Pick<TeamTag, 'id' | 'athleteCount'>
    & { readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
  ), readonly tagName: Pick<TagName, 'name' | 'description' | 'color' | 'isPrintable'> };

export type NamedTagForEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NamedTagForEditQuery = { readonly namedTag: NamedTagForEditFragment };

export type NamedTagForEditFragment = { readonly tagName: Pick<TagName, 'name' | 'description' | 'isPrintable'>, readonly tag: (
    { readonly __typename: 'AthleteTag' }
    & Pick<AthleteTag, 'id' | 'isComplement'>
    & { readonly athletes: ReadonlyArray<AthleteForCustomTagFragment> }
  ) | (
    { readonly __typename: 'ComboTag' }
    & Pick<ComboTag, 'combinationMethod' | 'id' | 'isComplement'>
    & { readonly namedChildTags: ReadonlyArray<NamedTagForSelectionFragment> }
  ) | (
    { readonly __typename: 'PositionTag' }
    & Pick<PositionTag, 'id' | 'isComplement'>
    & { readonly positions: ReadonlyArray<PositionWitSportFragment> }
  ) | (
    { readonly __typename: 'TeamTag' }
    & Pick<TeamTag, 'id' | 'isComplement'>
    & { readonly teams: ReadonlyArray<TeamPreviewFragment> }
  ) };

export type NamedTagForSelectionFragment = { readonly tagName: Pick<TagName, 'name' | 'color'>, readonly tag: (
    { readonly __typename: 'AthleteTag' }
    & Pick<AthleteTag, 'id'>
  ) | (
    { readonly __typename: 'ComboTag' }
    & Pick<ComboTag, 'id'>
  ) | (
    { readonly __typename: 'PositionTag' }
    & Pick<PositionTag, 'id'>
  ) | (
    { readonly __typename: 'TeamTag' }
    & Pick<TeamTag, 'id'>
  ) };

export type PositionBySportCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type PositionBySportCursorConnectionQuery = { readonly positionBySportCursorConnection: { readonly edges: ReadonlyArray<PositionWitSportFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type PositionWitSportFragment = (
  Pick<Position, 'id' | 'name'>
  & { readonly sport: Pick<Sport, 'name'> }
);

export type TeamCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type TeamCursorConnectionQuery = { readonly teamCursorConnection: { readonly edges: ReadonlyArray<TeamPreviewFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type TeamPreviewFragment = Pick<Team, 'id' | 'name'>;

export type NamedTagCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type NamedTagCursorConnectionQuery = { readonly namedTagCursorConnection: { readonly edges: ReadonlyArray<NamedTagForSelectionFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type AthletesForCustomTagCreateQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  teamIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type AthletesForCustomTagCreateQuery = { readonly athletesForCustomTagCreationCursorConnection: { readonly edges: ReadonlyArray<AthleteForCustomTagFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type SelectedAthletesForCustomTagCreateQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  teamIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type SelectedAthletesForCustomTagCreateQuery = { readonly athletesForCustomTagCreation: ReadonlyArray<AthleteForCustomTagFragment> };

export type AthleteSearchCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type AthleteSearchCursorConnectionQuery = { readonly athleteSearchCursorConnection: { readonly edges: ReadonlyArray<AthleteForCustomTagFragment>, readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'> } };

export type AthleteForCustomTagFragment = (
  Pick<Athlete, 'id' | 'firstName' | 'lastName'>
  & { readonly myNamedTagsOffsetConnection: { readonly edges: ReadonlyArray<NamedTagForSelectionFragment>, readonly pageInfo: Pick<OffsetPageInfo, 'total'> }, readonly team: Pick<Team, 'id' | 'name'> }
);

export type AthletesForPendingAdvancedSelectionQueryVariables = Exact<{
  input: AdvancedSelectionInput;
}>;


export type AthletesForPendingAdvancedSelectionQuery = { readonly athletesForPendingAdvancedSelection: ReadonlyArray<AthleteForCustomTagFragment> };

export type IsTagNameUniqueQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type IsTagNameUniqueQuery = { readonly isTagNameUnique: Pick<IsTagNameUniquePayload, 'isUnique'> };

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTagMutation = { readonly deleteTag: Pick<DeleteTagPayload, 'tagId'> };

export type CreateCustomTagMutationVariables = Exact<{
  input: CreateCustomTagInput;
}>;


export type CreateCustomTagMutation = { readonly createCustomTag: NamedTagForEditFragment };

export type EditCustomTagMutationVariables = Exact<{
  input: EditCustomTagInput;
}>;


export type EditCustomTagMutation = { readonly editCustomTag: NamedTagForEditFragment };

export type CreateSmartTagMutationVariables = Exact<{
  input: CreateSmartTagInput;
}>;


export type CreateSmartTagMutation = { readonly createSmartTag: NamedTagForEditFragment };

export type EditSmartTagMutationVariables = Exact<{
  input: EditSmartTagInput;
}>;


export type EditSmartTagMutation = { readonly editSmartTag: NamedTagForEditFragment };

export type AdvancedSelectionFragment = (
  Pick<AdvancedSelectionType, 'id'>
  & { readonly includeAthletes: ReadonlyArray<AthleteForCustomTagFragment>, readonly excludeAthletes: ReadonlyArray<AthleteForCustomTagFragment>, readonly groups: ReadonlyArray<AdvancedSelectionGroupFragment> }
);

export type AdvancedSelectionGroupFragment = { readonly conditions: ReadonlyArray<AdvancedSelectionConditionComboTagFragment | AdvancedSelectionConditionPositionTagFragment | AdvancedSelectionConditionTeamTagFragment> };

type AdvancedSelectionConditionComboTagFragment = (
  { readonly __typename: 'ComboTag' }
  & Pick<ComboTag, 'id' | 'isComplement' | 'combinationMethod'>
  & { readonly namedChildTags: ReadonlyArray<NamedTagForSelectionFragment> }
);

type AdvancedSelectionConditionPositionTagFragment = (
  { readonly __typename: 'PositionTag' }
  & Pick<PositionTag, 'id' | 'isComplement'>
  & { readonly positions: ReadonlyArray<PositionWitSportFragment> }
);

type AdvancedSelectionConditionTeamTagFragment = (
  { readonly __typename: 'TeamTag' }
  & Pick<TeamTag, 'id' | 'isComplement'>
  & { readonly teams: ReadonlyArray<TeamPreviewFragment> }
);

export type AdvancedSelectionConditionFragment = AdvancedSelectionConditionComboTagFragment | AdvancedSelectionConditionPositionTagFragment | AdvancedSelectionConditionTeamTagFragment;

export type TeamAthletesMealPlansQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type TeamAthletesMealPlansQuery = { readonly team: (
    Pick<Team, 'id'>
    & { readonly athletes: ReadonlyArray<(
      Pick<Athlete, 'id'>
      & { readonly mealPlans: ReadonlyArray<TeamAthletesMealPlanFragment> }
    )> }
  ) };

export type MealPlansForTeamQueryVariables = Exact<{
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
}>;


export type MealPlansForTeamQuery = { readonly mealPlanOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<TeamAthletesMealPlanFragment> } };

export type TeamAthletesMealPlanFragment = (
  Pick<MealPlan, 'wasCreatedFromMealPlanTemplate'>
  & { readonly exchangeSet: Maybe<ExchangeSetWithExchangesFragment>, readonly macroProtocol: (
    { readonly calorieBudget: Maybe<FullCalorieBudgetFragment> }
    & MacroProtocolFragment
  ), readonly schedule: Maybe<ScheduleFragment>, readonly athlete: AthleteFragment, readonly mostRecentEvent: Maybe<EventFragment>, readonly events: ReadonlyArray<EventFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateFragment>, readonly mealTemplates: ReadonlyArray<MealTemplateExchangeMealTemplateFragment | MealTemplateMacroMealTemplateFragment>, readonly avoidedFoodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>>, readonly promotedFoodGroups: ReadonlyArray<Pick<FoodGroup, 'id' | 'name'>> }
  & MealPlanFragment
);

export type RecipesByIdQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type RecipesByIdQuery = { readonly recipesById: ReadonlyArray<RecipeWithServingsFragment> };

export type TeamAthletesNotesQueryVariables = Exact<{
  teamId: Scalars['ID'];
  noteTypeId: Maybe<Scalars['ID']>;
}>;


export type TeamAthletesNotesQuery = { readonly notesForTeam: ReadonlyArray<TeamAthleteNoteFragment>, readonly noteTypes: ReadonlyArray<Pick<NoteType, 'id' | 'name'>> };

export type TeamAthleteNoteFragment = (
  Pick<Note, 'id' | 'datetime' | 'content'>
  & { readonly type: Maybe<Pick<NoteType, 'id' | 'name'>>, readonly athlete: Pick<Athlete, 'id' | 'firstName' | 'lastName'> }
);

export type TeamEditableRosterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamEditableRosterQuery = { readonly team: (
    { readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettingsFragment> }
    & TeamWithSportFragment
  ) };

export type AthletesForTeamOffsetConnectionQueryVariables = Exact<{
  teamId: Scalars['ID'];
  tagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
  priorityTagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type AthletesForTeamOffsetConnectionQuery = { readonly athletesForTeamOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<ProfileTableAthleteFragment> } };

export type AthletesForTeamProfilesExportQueryVariables = Exact<{
  teamId: Scalars['ID'];
  tagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
  priorityTagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type AthletesForTeamProfilesExportQuery = { readonly athletesForTeam: ReadonlyArray<ProfileTableAthleteFragment> };

export type AthletesForTeamEngagementOffsetConnectionQueryVariables = Exact<{
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  tagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
  inLastDays: Maybe<Scalars['Int']>;
}>;


export type AthletesForTeamEngagementOffsetConnectionQuery = { readonly athletesForTeamOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<EngagementTableAthleteFragment> } };

export type AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables = Exact<{
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  tagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
}>;


export type AthletesForTeamFoodPreferencesOffsetConnectionQuery = { readonly athletesForTeamOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<PreferencesTableAthleteFragment> } };

export type AthletesForTeamFoodPreferencesQueryVariables = Exact<{
  teamId: Scalars['ID'];
  tagIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
}>;


export type AthletesForTeamFoodPreferencesQuery = { readonly athletesForTeam: ReadonlyArray<PreferencesTableAthleteFragment> };

export type TeamRosterAthleteFragment = (
  { readonly team: TeamFragment, readonly user: EditableRosterAthleteUserFragment, readonly orgMembership: Maybe<EditableRosterAthleteOrgMembershipFragment>, readonly position: Maybe<PositionFragment>, readonly currentGoal: Maybe<FullGoalFragment>, readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment>, readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> }
  & AthleteFragment
);

export type AllTableViewsAthleteFragment = (
  { readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettingsFragment>, readonly user: EditableRosterAthleteUserFragment, readonly orgMembership: Maybe<EditableRosterAthleteOrgMembershipFragment>, readonly position: Maybe<PositionFragment> }
  & AthleteFragment
);

export type EngagementTableAthleteFragment = (
  Pick<Athlete, 'imageUploadCount' | 'mealPlanViewCount'>
  & { mealsLoggedCount: Athlete['timelineMealCount'], mealsOrderedCount: Athlete['timelineMealCount'], totalMealsCount: Athlete['timelineMealCount'] }
  & AllTableViewsAthleteFragment
);

export type PreferencesTableAthleteFragment = (
  { readonly dislikedFoodGroups: ReadonlyArray<Pick<FoodGroup, 'name'>> }
  & AllTableViewsAthleteFragment
);

export type MyNamedTagsPreviewFragment = (
  Pick<MyNamedTagsPreviewPayload, 'total' | 'hiddenPreviewText'>
  & { readonly previewNamedTags: ReadonlyArray<NamedTagForSelectionFragment> }
);

export type ProfileTableAthleteFragment = (
  { readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment>, readonly currentGoal: Maybe<FullGoalFragment>, readonly myNamedTagsPreview: MyNamedTagsPreviewFragment }
  & AllTableViewsAthleteFragment
);

export type EditableRosterAthleteUserFragment = (
  { readonly __typename: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastLogin' | 'lastName' | 'username' | 'dateJoined'>
);

export type EditableRosterAthleteOrgMembershipFragment = Pick<OrgMembership, 'id' | 'isActive' | 'teamworksId'>;

export type TeamDashboardQueryVariables = Exact<{
  teamId: Scalars['ID'];
  endDate: Scalars['String'];
}>;


export type TeamDashboardQuery = { readonly team: (
    Pick<Team, 'id' | 'defaultHideAnthropometry'>
    & { readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettingsFragment>, readonly defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettingsFragment>, readonly defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettingsFragment>, readonly defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettingsFragment>, readonly schedules: ReadonlyArray<TeamDashboardScheduleFragment> }
  ), readonly teamGoalConfiguration: ReadonlyArray<TeamGoalConfigurationFragment> };

export type EditTeamDisplaySettingsMutationVariables = Exact<{
  input: EditTeamDisplaySettingsInput;
}>;


export type EditTeamDisplaySettingsMutation = { readonly editTeamDisplaySettings: { readonly team: (
      Pick<Team, 'id' | 'defaultHideAnthropometry'>
      & { readonly defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettingsFragment>, readonly defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettingsFragment>, readonly defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettingsFragment> }
    ) } };

export type CreateScheduleMutationVariables = Exact<{
  input: CreateScheduleInput;
}>;


export type CreateScheduleMutation = { readonly createSchedule: { readonly schedule: TeamDashboardScheduleFragment } };

export type EditScheduleMutationVariables = Exact<{
  input: EditScheduleInput;
}>;


export type EditScheduleMutation = { readonly editSchedule: { readonly schedule: TeamDashboardScheduleFragment } };

export type TeamDashboardScheduleFragment = (
  Pick<Schedule, 'id' | 'name'>
  & { readonly meals: ReadonlyArray<MealFragment>, readonly activities: ReadonlyArray<ActivityFragment>, readonly mealPlans: ReadonlyArray<Pick<MealPlan, 'id'>> }
);

export type TeamGoalConfigurationFragment = (
  Pick<GoalConfiguration, 'id' | 'name' | 'emoji' | 'startDate' | 'endDate' | 'completedGoalCount' | 'totalGoalCount'>
  & { readonly category: Pick<GoalCategory, 'name' | 'defaultEmoji'>, readonly athlete: Maybe<(
    Pick<Athlete, 'id' | 'firstName' | 'lastName'>
    & { readonly position: Maybe<Pick<Position, 'name'>> }
  )> }
);

export type EditTeamFoodLogNotificationSettingsMutationVariables = Exact<{
  input: EditTeamFoodLogNotificationSettingsInput;
}>;


export type EditTeamFoodLogNotificationSettingsMutation = { readonly editTeamFoodLogNotificationSettings: { readonly team: (
      Pick<Team, 'id'>
      & { readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettingsFragment> }
    ) } };

export type TeamPageTeamFragment = (
  { readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettingsFragment>, readonly sport: Maybe<(
    { readonly positions: ReadonlyArray<PositionFragment> }
    & SportFragment
  )>, readonly athletes: ReadonlyArray<TeamPageAthleteFragment> }
  & TeamFragment
);

export type TeamPageQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type TeamPageQuery = { readonly team: TeamPageTeamFragment };

export type TeamPageAthleteFragment = (
  { readonly timelineMealNotifications: ReadonlyArray<NotificationStubAthleteSelfOnboardedNotificationFragment | NotificationStubBulkOrderStatusNotificationFragment | NotificationStubFoodLogMealCommentNotificationFragment | NotificationStubFoodLogMealEditNotificationFragment | NotificationStubFoodLogMealImageNotificationFragment | NotificationStubGoalReminderNotificationFragment | NotificationStubMealMenuAvailableNotificationFragment | NotificationStubMealMenuIsSharedNotificationFragment | NotificationStubMealMenuOrderDueNotificationFragment | NotificationStubMealPlanSharedNotificationFragment | NotificationStubMenuOrderCommentNotificationFragment | NotificationStubMenuOrderImageNotificationFragment | NotificationStubMenuOrderStatusNotificationFragment | NotificationStubTimelineMealCommentNotificationFragment | NotificationStubTimelineMealEditNotificationFragment | NotificationStubTimelineMealImageNotificationFragment> }
  & AthleteFragment
);

export type CreateMetricAthleteMutationVariables = Exact<{
  input: CreateMetricAthleteInput;
}>;


export type CreateMetricAthleteMutation = { readonly createMetricAthlete: { readonly athlete: (
      { readonly position: Maybe<PositionFragment>, readonly currentGoal: Maybe<FullGoalFragment>, readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment>, readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> }
      & AthleteFragment
    ) } };

export type TeamPageEditTeamMutationVariables = Exact<{
  input: EditTeamInput;
}>;


export type TeamPageEditTeamMutation = { readonly editTeam: { readonly team: (
      { readonly sport: Maybe<(
        { readonly positions: ReadonlyArray<PositionFragment> }
        & SportFragment
      )> }
      & TeamFragment
    ) } };

export type TeamsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamsPageQuery = { readonly teams: ReadonlyArray<TeamsTableTeamFragment> };

export type TeamsTableTeamFragment = (
  { readonly __typename: 'Team' }
  & Pick<Team, 'id' | 'name' | 'gender' | 'canBeDeleted'>
  & { readonly sport: Maybe<Pick<Sport, 'id' | 'name'>>, readonly athletes: ReadonlyArray<(
    Pick<Athlete, 'id' | 'activationLinkSent' | 'isArchived'>
    & { readonly orgMembership: Maybe<Pick<OrgMembership, 'id' | 'isActive'>> }
  )>, readonly recentNotifications: ReadonlyArray<NotificationStubAthleteSelfOnboardedNotificationFragment | NotificationStubBulkOrderStatusNotificationFragment | NotificationStubFoodLogMealCommentNotificationFragment | NotificationStubFoodLogMealEditNotificationFragment | NotificationStubFoodLogMealImageNotificationFragment | NotificationStubGoalReminderNotificationFragment | NotificationStubMealMenuAvailableNotificationFragment | NotificationStubMealMenuIsSharedNotificationFragment | NotificationStubMealMenuOrderDueNotificationFragment | NotificationStubMealPlanSharedNotificationFragment | NotificationStubMenuOrderCommentNotificationFragment | NotificationStubMenuOrderImageNotificationFragment | NotificationStubMenuOrderStatusNotificationFragment | NotificationStubTimelineMealCommentNotificationFragment | NotificationStubTimelineMealEditNotificationFragment | NotificationStubTimelineMealImageNotificationFragment>, readonly foodLogNotificationSettings: Maybe<Pick<FoodLogNotificationSettings, 'edits' | 'messages' | 'imageUploads'>> }
);

export type TeamsPageCreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;


export type TeamsPageCreateTeamMutation = { readonly createTeam: { readonly team: (
      { readonly sport: Maybe<(
        { readonly positions: ReadonlyArray<PositionFragment> }
        & SportFragment
      )> }
      & TeamFragment
    ) } };

export type TeamsPageDeleteTeamMutationVariables = Exact<{
  input: DeleteTeamInput;
}>;


export type TeamsPageDeleteTeamMutation = { readonly deleteTeam: Pick<DeleteTeamPayload, 'teamId'> };

export type ActiveTransferFragment = (
  Pick<AthleteTransfer, 'id' | 'createdAt' | 'note'>
  & { readonly releasedByOrg: Pick<Org, 'id' | 'name'>, readonly orgGroup: Pick<OrgGroup, 'id' | 'name'>, readonly athlete: (
    Pick<Athlete, 'firstName' | 'lastName' | 'birthDate'>
    & { readonly activeTransfer: Maybe<Pick<AthleteTransfer, 'id'>> }
  ) }
);

export type ActiveTransfersOffsetConnectionQueryVariables = Exact<{
  input: OffsetPaginationInput;
}>;


export type ActiveTransfersOffsetConnectionQuery = { readonly activeAthleteTransferOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<ActiveTransferFragment> } };

export type ActiveTransfersTeamsOptionFragment = Pick<Team, 'id' | 'name'>;

export type ActiveTransfersTeamsOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveTransfersTeamsOptionsQuery = { readonly teams: ReadonlyArray<ActiveTransfersTeamsOptionFragment> };

export type RecieveAthleteMutationVariables = Exact<{
  input: RecieveAthleteTransferInput;
}>;


export type RecieveAthleteMutation = { readonly recieveAthleteTransfer: { readonly athleteTransfer: (
      Pick<AthleteTransfer, 'id'>
      & { readonly athlete: (
        Pick<Athlete, 'id'>
        & { readonly activeTransfer: Maybe<Pick<AthleteTransfer, 'id'>> }
      ) }
    ) } };

export type AthleteTransferOrgGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type AthleteTransferOrgGroupsQuery = { readonly athleteTransferOrgGroups: ReadonlyArray<Pick<OrgGroup, 'id' | 'name'>> };

export type UnsubmittedAgreementFormForUserQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type UnsubmittedAgreementFormForUserQuery = { readonly unsubmittedAgreementFormForUser: Maybe<AgreementFormFragment> };

export type AddAgreementFormSubmissionMutationVariables = Exact<{
  input: AddAgreementFormSubmissionInput;
}>;


export type AddAgreementFormSubmissionMutation = { readonly addAgreementFormSubmission: { readonly agreementForm: AgreementFormFragment } };

export type AgreementFormFragment = Pick<AgreementForm, 'id' | 'title' | 'url' | 'acceptAgreementText'>;

export type CommentWithNotificationFragment = (
  Pick<Comment, 'id'>
  & { readonly notification: Maybe<Pick<AthleteSelfOnboardedNotification, 'id' | 'viewedAt'> | Pick<BulkOrderStatusNotification, 'id' | 'viewedAt'> | Pick<FoodLogMealCommentNotification, 'id' | 'viewedAt'> | Pick<FoodLogMealEditNotification, 'id' | 'viewedAt'> | Pick<FoodLogMealImageNotification, 'id' | 'viewedAt'> | Pick<GoalReminderNotification, 'id' | 'viewedAt'> | Pick<MealMenuAvailableNotification, 'id' | 'viewedAt'> | Pick<MealMenuIsSharedNotification, 'id' | 'viewedAt'> | Pick<MealMenuOrderDueNotification, 'id' | 'viewedAt'> | Pick<MealPlanSharedNotification, 'id' | 'viewedAt'> | Pick<MenuOrderCommentNotification, 'id' | 'viewedAt'> | Pick<MenuOrderImageNotification, 'id' | 'viewedAt'> | Pick<MenuOrderStatusNotification, 'id' | 'viewedAt'> | Pick<TimelineMealCommentNotification, 'id' | 'viewedAt'> | Pick<TimelineMealEditNotification, 'id' | 'viewedAt'> | Pick<TimelineMealImageNotification, 'id' | 'viewedAt'>> }
);

export type MacroMealPlanDisplaySettingsFragment = (
  { readonly __typename: 'MacroMealPlanDisplaySettings' }
  & Pick<MacroMealPlanDisplaySettings, 'dailyProgressViews' | 'mealProgressViews'>
);

export type ExchangeMealPlanDisplaySettingsFragment = (
  { readonly __typename: 'ExchangeMealPlanDisplaySettings' }
  & Pick<ExchangeMealPlanDisplaySettings, 'dailyProgressViewsForExchanges' | 'mealProgressViewsForExchanges' | 'dailyProgressViewsForMacros' | 'mealProgressViewsForMacros'>
);

export type NoTargetsDisplaySettingsFragment = Pick<NoTargetsDisplaySettings, 'dailyProgressViewsForMacros' | 'mealProgressViewsForMacros'>;

export type ExchangeSummaryLabelFragment = (
  { readonly __typename: 'ExchangeAmount' }
  & Pick<ExchangeAmount, 'id' | 'amount'>
  & { readonly exchange: (
    { readonly __typename: 'Exchange' }
    & Pick<Exchange, 'id' | 'name' | 'shortName' | 'type' | 'cho' | 'pro' | 'fat' | 'kcal'>
  ) }
);

export type RecipesByIdWithIngredientsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type RecipesByIdWithIngredientsQuery = { readonly recipesById: ReadonlyArray<RecipeWithServingsFragment> };

export type MyFeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyFeatureFlagsQuery = { readonly myFeatureFlags: FeatureFlagsFragment };

export type FeatureFlagsFragment = Pick<FeatureFlags, 'athleteMenuSuggestions' | 'clientCredentials' | 'hasRestaurantMenus' | 'restaurantLogging' | 'teamworksSchedules' | 'mealPlanSuggestions' | 'prepPlan' | 'foodCategory' | 'pk2MenuBuilder' | 'orgGroupRecipes' | 'orgGroupFoods' | 'performanceKitchen' | 'printableTags' | 'hubMenuCheckIn' | 'goalTracker' | 'dietitianOnlyMenus' | 'bulkOrdering' | 'ukMealPlanOptionSuggestions' | 'digitalDisplays' | 'selfServiceOnboarding' | 'messaging' | 'eatingDisorderScreens' | 'profilesV3Api' | 'mealPlanTemplateBulkAssignments'>;

export type RecipeFoodCategoryFragment = Pick<RecipeFoodCategory, 'category'>;

export type MenuItemFoodCategoryFragment = Pick<MenuItemFoodCategory, 'category'>;

export type MealMenuWithTimesFragment = Pick<StandaloneMealMenu, 'id' | 'timezone' | 'name' | 'start' | 'durationInMinutes' | 'prepTimeInMinutes' | 'lastOrderTimeBeforeEndInMinutes' | 'isViewOnlyForMe' | 'theme'>;

export type MealMenuDiningStationWithItemPreviewsFragment = (
  Pick<MealMenuDiningStation, 'id' | 'name' | 'maxAmount' | 'position'>
  & { readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
);

export type MenuItemAppearancePreviewFragment = (
  Pick<MenuItemAppearance, 'id' | 'position' | 'maxAmount' | 'availableForOrder' | 'allowSpecialRequests'>
  & { readonly menuItem: MenuItemPreviewFragment }
);

export type MenuItemPreviewFragment = (
  { readonly __typename: 'MenuItem' }
  & Pick<MenuItem, 'id' | 'name' | 'servingName' | 'description' | 'imageUrl' | 'isOneOff' | 'defaultAvailableForOrder' | 'defaultAllowSpecialRequests' | 'defaultMaxAmount' | 'isDeleted'>
  & { readonly servingAmounts: ReadonlyArray<(
    Pick<ServingAmount, 'id'>
    & { readonly serving: (
      Pick<Serving, 'id'>
      & { readonly foodOrRecipe: FoodOrRecipePreferencesBrandedFoodFragment | FoodOrRecipePreferencesGenericFoodFragment | FoodOrRecipePreferencesRecipeFragment }
    ) }
  )>, readonly score: Maybe<ScoreAssignmentFragment>, readonly foodCategory: Maybe<FoodCategoryNameFragment> }
);

export type MealMenuOrderRateLimitQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MealMenuOrderRateLimitQuery = { readonly mealMenu: (
    Pick<StandaloneMealMenu, 'id'>
    & { readonly orderRateLimit: Maybe<Pick<MenuOrderRateLimit, 'limit' | 'excludedTeamIds'>> }
  ), readonly menuOrderRateLimitRemainders: ReadonlyArray<MenuOrderRateLimitRemainderFragment> };

export type MenuOrderRateLimitRemainderFragment = Pick<MenuOrderRateLimitRemainder, 'start' | 'remaining'>;

export type MealMenuLogItemFormFragment = (
  Pick<MealMenuLogItem, 'id'>
  & MenuSelectionItemFormMealMenuLogItemFragment
);

export type ViewMealOptionFragment = (
  { readonly __typename: 'MealOption' }
  & Pick<MealOption, 'id' | 'note' | 'position'>
  & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
);

type MealTemplateForTargetsExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly exchangeTargets: ReadonlyArray<ExchangeAmountFragment> }
);

type MealTemplateForTargetsMacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'cho' | 'pro' | 'fat' | 'id'>
);

export type MealTemplateForTargetsFragment = MealTemplateForTargetsExchangeMealTemplateFragment | MealTemplateForTargetsMacroMealTemplateFragment;

export type ExchangeMealTemplateViewFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly mealOptions: ReadonlyArray<ViewMealOptionFragment>, readonly exchangeTargets: ReadonlyArray<ExchangeAmountFragment>, readonly meal: MealFragment }
);

export type MacroMealTemplateViewFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'id' | 'cho' | 'pro' | 'fat' | 'kcal'>
  & { readonly mealOptions: ReadonlyArray<ViewMealOptionFragment>, readonly meal: MealFragment }
);

export type MenuItemWithIdsFragment = (
  Pick<MenuItem, 'id'>
  & { readonly choices: ReadonlyArray<(
    Pick<MenuItemChoice, 'id'>
    & { readonly options: ReadonlyArray<Pick<MenuItemChoiceOption, 'id'>> }
  )> }
);

type MenuSelectionItemOptionWithIdsMealMenuLogItemOptionFragment = (
  Pick<MealMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsMealMenuPlateOrderItemOptionFragment = (
  Pick<MealMenuPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsMenuItemChoiceOptionAmountFragment = (
  Pick<MenuItemChoiceOptionAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsMenuOrderItemOptionFragment = (
  Pick<MenuOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsRestaurantMenuLinkOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsRestaurantMenuLinkPlateItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsRestaurantMenuLinkPlateOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsRestaurantMenuLogItemOptionFragment = (
  Pick<RestaurantMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

export type MenuSelectionItemOptionWithIdsFragment = MenuSelectionItemOptionWithIdsMealMenuLogItemOptionFragment | MenuSelectionItemOptionWithIdsMealMenuPlateOrderItemOptionFragment | MenuSelectionItemOptionWithIdsMenuItemChoiceOptionAmountFragment | MenuSelectionItemOptionWithIdsMenuOrderItemOptionFragment | MenuSelectionItemOptionWithIdsRestaurantMenuLinkOrderItemOptionFragment | MenuSelectionItemOptionWithIdsRestaurantMenuLinkPlateItemOptionFragment | MenuSelectionItemOptionWithIdsRestaurantMenuLinkPlateOrderItemOptionFragment | MenuSelectionItemOptionWithIdsRestaurantMenuLogItemOptionFragment;

export type MenuItemFormFragment = (
  { readonly __typename: 'MenuItem' }
  & Pick<MenuItem, 'id' | 'name' | 'servingName' | 'description' | 'isOneOff' | 'imageUrl' | 'suggestionCategory' | 'defaultMaxAmount' | 'defaultAvailableForOrder' | 'defaultAllowSpecialRequests' | 'isDeleted'>
  & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment>, readonly choices: ReadonlyArray<MenuItemChoiceFormFragment>, readonly score: Maybe<ScoreAssignmentFragment>, readonly foodCategory: Maybe<FoodCategoryNameFragment> }
);

export type MenuItemChoiceFormFragment = (
  Pick<MenuItemChoice, 'id' | 'name' | 'position' | 'required' | 'maxOptionsCount'>
  & { readonly options: ReadonlyArray<MenuItemChoiceOptionFormFragment> }
);

export type MenuItemChoiceOptionFormFragment = (
  Pick<MenuItemChoiceOption, 'id' | 'name' | 'position' | 'canEditAmount' | 'defaultAmount' | 'maxAmount'>
  & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
);

export type FoodCategoryNameFragment = Pick<MenuItemFoodCategory, 'category'>;

export type MenuOrderItemFormFragment = (
  Pick<MenuOrderItem, 'id' | 'forOrder' | 'status' | 'specialRequests'>
  & { readonly rating: Maybe<RatingFragment> }
  & MenuSelectionItemFormMenuOrderItemFragment
);

export type RatingFragment = Pick<MenuItemRating, 'id' | 'review' | 'value'>;

type MenuSelectionFormMealMenuLogFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormMealMenuLogItemFragment> };

type MenuSelectionFormMealMenuPlateOrderFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormMealMenuPlateOrderItemFragment> };

type MenuSelectionFormMenuOrderFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormMenuOrderItemFragment> };

type MenuSelectionFormMenuOrderSuggestionFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormMenuItemAmountFragment> };

type MenuSelectionFormRestaurantMenuLinkOrderFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormRestaurantMenuLinkOrderItemFragment> };

type MenuSelectionFormRestaurantMenuLinkPlateFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormRestaurantMenuLinkPlateItemFragment> };

type MenuSelectionFormRestaurantMenuLinkPlateOrderFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormRestaurantMenuLinkPlateOrderItemFragment> };

type MenuSelectionFormRestaurantMenuLogFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormRestaurantMenuLogItemFragment> };

export type MenuSelectionFormFragment = MenuSelectionFormMealMenuLogFragment | MenuSelectionFormMealMenuPlateOrderFragment | MenuSelectionFormMenuOrderFragment | MenuSelectionFormMenuOrderSuggestionFragment | MenuSelectionFormRestaurantMenuLinkOrderFragment | MenuSelectionFormRestaurantMenuLinkPlateFragment | MenuSelectionFormRestaurantMenuLinkPlateOrderFragment | MenuSelectionFormRestaurantMenuLogFragment;

type MenuSelectionItemWithMacrosMealMenuLogItemFragment = (
  Pick<MealMenuLogItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosMealMenuLogItemOptionFragment> }
);

type MenuSelectionItemWithMacrosMealMenuPlateOrderItemFragment = (
  Pick<MealMenuPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosMealMenuPlateOrderItemOptionFragment> }
);

type MenuSelectionItemWithMacrosMenuItemAmountFragment = (
  Pick<MenuItemAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosMenuItemChoiceOptionAmountFragment> }
);

type MenuSelectionItemWithMacrosMenuOrderItemFragment = (
  Pick<MenuOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosMenuOrderItemOptionFragment> }
);

type MenuSelectionItemWithMacrosRestaurantMenuLinkOrderItemFragment = (
  Pick<RestaurantMenuLinkOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosRestaurantMenuLinkOrderItemOptionFragment> }
);

type MenuSelectionItemWithMacrosRestaurantMenuLinkPlateItemFragment = (
  Pick<RestaurantMenuLinkPlateItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateItemOptionFragment> }
);

type MenuSelectionItemWithMacrosRestaurantMenuLinkPlateOrderItemFragment = (
  Pick<RestaurantMenuLinkPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateOrderItemOptionFragment> }
);

type MenuSelectionItemWithMacrosRestaurantMenuLogItemFragment = (
  Pick<RestaurantMenuLogItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosRestaurantMenuLogItemOptionFragment> }
);

export type MenuSelectionItemWithMacrosFragment = MenuSelectionItemWithMacrosMealMenuLogItemFragment | MenuSelectionItemWithMacrosMealMenuPlateOrderItemFragment | MenuSelectionItemWithMacrosMenuItemAmountFragment | MenuSelectionItemWithMacrosMenuOrderItemFragment | MenuSelectionItemWithMacrosRestaurantMenuLinkOrderItemFragment | MenuSelectionItemWithMacrosRestaurantMenuLinkPlateItemFragment | MenuSelectionItemWithMacrosRestaurantMenuLinkPlateOrderItemFragment | MenuSelectionItemWithMacrosRestaurantMenuLogItemFragment;

type MenuSelectItemOptionWithMacrosMealMenuLogItemOptionFragment = (
  Pick<MealMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosMealMenuPlateOrderItemOptionFragment = (
  Pick<MealMenuPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosMenuItemChoiceOptionAmountFragment = (
  Pick<MenuItemChoiceOptionAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosMenuOrderItemOptionFragment = (
  Pick<MenuOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosRestaurantMenuLinkOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosRestaurantMenuLogItemOptionFragment = (
  Pick<RestaurantMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

export type MenuSelectItemOptionWithMacrosFragment = MenuSelectItemOptionWithMacrosMealMenuLogItemOptionFragment | MenuSelectItemOptionWithMacrosMealMenuPlateOrderItemOptionFragment | MenuSelectItemOptionWithMacrosMenuItemChoiceOptionAmountFragment | MenuSelectItemOptionWithMacrosMenuOrderItemOptionFragment | MenuSelectItemOptionWithMacrosRestaurantMenuLinkOrderItemOptionFragment | MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateItemOptionFragment | MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateOrderItemOptionFragment | MenuSelectItemOptionWithMacrosRestaurantMenuLogItemOptionFragment;

type MenuSelectionItemFormMealMenuLogItemFragment = (
  Pick<MealMenuLogItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormMealMenuLogItemOptionFragment> }
);

type MenuSelectionItemFormMealMenuPlateOrderItemFragment = (
  Pick<MealMenuPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormMealMenuPlateOrderItemOptionFragment> }
);

type MenuSelectionItemFormMenuItemAmountFragment = (
  Pick<MenuItemAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormMenuItemChoiceOptionAmountFragment> }
);

type MenuSelectionItemFormMenuOrderItemFragment = (
  Pick<MenuOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormMenuOrderItemOptionFragment> }
);

type MenuSelectionItemFormRestaurantMenuLinkOrderItemFragment = (
  Pick<RestaurantMenuLinkOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormRestaurantMenuLinkOrderItemOptionFragment> }
);

type MenuSelectionItemFormRestaurantMenuLinkPlateItemFragment = (
  Pick<RestaurantMenuLinkPlateItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormRestaurantMenuLinkPlateItemOptionFragment> }
);

type MenuSelectionItemFormRestaurantMenuLinkPlateOrderItemFragment = (
  Pick<RestaurantMenuLinkPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormRestaurantMenuLinkPlateOrderItemOptionFragment> }
);

type MenuSelectionItemFormRestaurantMenuLogItemFragment = (
  Pick<RestaurantMenuLogItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormRestaurantMenuLogItemOptionFragment> }
);

export type MenuSelectionItemFormFragment = MenuSelectionItemFormMealMenuLogItemFragment | MenuSelectionItemFormMealMenuPlateOrderItemFragment | MenuSelectionItemFormMenuItemAmountFragment | MenuSelectionItemFormMenuOrderItemFragment | MenuSelectionItemFormRestaurantMenuLinkOrderItemFragment | MenuSelectionItemFormRestaurantMenuLinkPlateItemFragment | MenuSelectionItemFormRestaurantMenuLinkPlateOrderItemFragment | MenuSelectionItemFormRestaurantMenuLogItemFragment;

type MenuSelectionItemOptionFormMealMenuLogItemOptionFragment = (
  Pick<MealMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormMealMenuPlateOrderItemOptionFragment = (
  Pick<MealMenuPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormMenuItemChoiceOptionAmountFragment = (
  Pick<MenuItemChoiceOptionAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormMenuOrderItemOptionFragment = (
  Pick<MenuOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormRestaurantMenuLinkOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormRestaurantMenuLinkPlateItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormRestaurantMenuLinkPlateOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormRestaurantMenuLogItemOptionFragment = (
  Pick<RestaurantMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

export type MenuSelectionItemOptionFormFragment = MenuSelectionItemOptionFormMealMenuLogItemOptionFragment | MenuSelectionItemOptionFormMealMenuPlateOrderItemOptionFragment | MenuSelectionItemOptionFormMenuItemChoiceOptionAmountFragment | MenuSelectionItemOptionFormMenuOrderItemOptionFragment | MenuSelectionItemOptionFormRestaurantMenuLinkOrderItemOptionFragment | MenuSelectionItemOptionFormRestaurantMenuLinkPlateItemOptionFragment | MenuSelectionItemOptionFormRestaurantMenuLinkPlateOrderItemOptionFragment | MenuSelectionItemOptionFormRestaurantMenuLogItemOptionFragment;

export type MenuOrderSuggestionsQueryVariables = Exact<{
  targetMacros: MacrosInput;
  mealMenuId: Scalars['ID'];
  avoidFoodGroupIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  limit: Maybe<Scalars['Int']>;
}>;


export type MenuOrderSuggestionsQuery = { readonly menuOrderSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestionFragment>> };

export type RestaurantMenuLinkSuggestionsQueryVariables = Exact<{
  targetMacros: MacrosInput;
  restaurantMenuLinkId: Scalars['ID'];
  avoidFoodGroupIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  limit: Maybe<Scalars['Int']>;
}>;


export type RestaurantMenuLinkSuggestionsQuery = { readonly restauranMenuLinkSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestionFragment>> };

export type RestaurantMenuSuggestionsQueryVariables = Exact<{
  targetMacros: MacrosInput;
  restaurantMenuId: Scalars['ID'];
  avoidFoodGroupIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  limit: Maybe<Scalars['Int']>;
}>;


export type RestaurantMenuSuggestionsQuery = { readonly restaurantMenuSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestionFragment>> };

export type MenuOrderSuggestionFragment = { readonly items: ReadonlyArray<(
    Pick<MenuItemAmount, 'amount' | 'percentConsumed'>
    & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<(
      Pick<MenuItemChoiceOptionAmount, 'amount' | 'percentConsumed'>
      & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
    )> }
  )> };

export type RecordMenuOrderSuggestionsViewMutationVariables = Exact<{
  input: RecordMenuOrderSuggestionsViewInput;
}>;


export type RecordMenuOrderSuggestionsViewMutation = Pick<Mutation, 'recordMenuOrderSuggestionsView'>;

export type RecordMenuOrderSuggestionDecisionMutationVariables = Exact<{
  input: RecordMenuOrderSuggestionDecisionInput;
}>;


export type RecordMenuOrderSuggestionDecisionMutation = Pick<Mutation, 'recordMenuOrderSuggestionDecision'>;

export type NutrientsWithDriQueryVariables = Exact<{
  age: Maybe<Scalars['Int']>;
  sex: Maybe<SexType>;
  kcal: Maybe<Scalars['Float']>;
}>;


export type NutrientsWithDriQuery = { readonly nutrients: ReadonlyArray<NutrientWithDriFragment> };

export type ServingsWithNutrientAmountsQueryVariables = Exact<{
  servingIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type ServingsWithNutrientAmountsQuery = { readonly servings: ReadonlyArray<ServingWithNutrientAmountsFragment> };

export type ServingWithNutrientAmountsFragment = (
  Pick<Serving, 'id'>
  & { readonly nutrientAmounts: ReadonlyArray<NutrientAmountFragment>, readonly foodOrRecipe: Pick<BrandedFood, 'id' | 'name'> | Pick<GenericFood, 'id' | 'name'> | Pick<Recipe, 'id' | 'name'> }
);

export type NutrientAmountFragment = (
  Pick<NutrientAmount, 'amount'>
  & { readonly nutrient: Pick<Nutrient, 'id' | 'name'> }
);

export type NutrientWithDriFragment = (
  Pick<Nutrient, 'id' | 'name' | 'unitName'>
  & { readonly defaultDietaryReferenceIntake: Maybe<Pick<NutrientDietaryReferenceIntake, 'id' | 'type' | 'amount'>> }
);

export type RestaurantMenuWithItemPreviewsFragment = (
  Pick<RestaurantMenu, 'id'>
  & { readonly sections: ReadonlyArray<(
    Pick<RestaurantMenuSection, 'id' | 'name' | 'position'>
    & { readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
  )> }
);

export type RestaurantMenuLinkWithMyOrdersFragment = (
  Pick<RestaurantMenuLink, 'id' | 'allowCustomOrders' | 'allowPlateOrders'>
  & { readonly restaurant: Pick<Restaurant, 'id' | 'name'>, readonly order: Maybe<RestaurantMenuLinkOrderFragment>, readonly plateOrder: Maybe<RestaurantMenuLinkPlateOrderFragment> }
);

export type RestaurantMenuLinkWithOrdersForAthleteFragment = (
  Pick<RestaurantMenuLink, 'id' | 'allowCustomOrders' | 'allowPlateOrders'>
  & { readonly restaurant: Pick<Restaurant, 'id' | 'name'>, readonly order: Maybe<RestaurantMenuLinkOrderFragment>, readonly plateOrder: Maybe<RestaurantMenuLinkPlateOrderFragment> }
);

export type RestaurantMenuLinkWithItemPreviewsFragment = (
  Pick<RestaurantMenuLink, 'id' | 'allowCustomOrders' | 'allowPlateOrders'>
  & { readonly restaurant: Pick<Restaurant, 'id' | 'name'>, readonly sections: ReadonlyArray<(
    Pick<RestaurantMenuSectionLink, 'maxAmount'>
    & { readonly restaurantMenuSection: Pick<RestaurantMenuSection, 'id' | 'name' | 'position'>, readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
  )>, readonly plates: ReadonlyArray<RestaurantMenuLinkPlateFragment> }
);

export type RestaurantMenuLinkPlateFragment = (
  Pick<RestaurantMenuLinkPlate, 'id' | 'position'>
  & { readonly items: ReadonlyArray<RestaurantMenuLinkPlateItemFormFragment> }
);

export type RestaurantMenuLinkOrderFragment = (
  Pick<RestaurantMenuLinkOrder, 'id'>
  & { readonly items: ReadonlyArray<RestaurantMenuLinkOrderItemFormFragment> }
);

export type RestaurantMenuLinkOrderItemFormFragment = (
  Pick<RestaurantMenuLinkOrderItem, 'id' | 'specialRequests'>
  & MenuSelectionItemFormRestaurantMenuLinkOrderItemFragment
);

export type RestaurantMenuLinkPlateItemFormFragment = (
  Pick<RestaurantMenuLinkPlateItem, 'id' | 'position' | 'specialRequests'>
  & MenuSelectionItemFormRestaurantMenuLinkPlateItemFragment
);

export type RestaurantMenuLinkPlateOrderFragment = (
  Pick<RestaurantMenuLinkPlateOrder, 'id' | 'userFullName'>
  & { readonly items: ReadonlyArray<RestaurantMenuLinkPlateOrderItemFormFragment>, readonly plate: (
    Pick<RestaurantMenuLinkPlate, 'id' | 'position'>
    & { readonly items: ReadonlyArray<RestaurantMenuLinkPlateItemFormFragment> }
  ), readonly restaurantMenuLink: Pick<RestaurantMenuLink, 'id'> }
);

export type RestaurantMenuLinkPlateOrderItemFormFragment = (
  Pick<RestaurantMenuLinkPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly plateItem: Pick<RestaurantMenuLinkPlateItem, 'id'>, readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<RestaurantMenuLinkPlateOrderItemOptionFormFragment> }
);

export type RestaurantMenuLinkPlateOrderItemOptionFormFragment = (
  Pick<RestaurantMenuLinkPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

export type RestaurantMenuLogFragment = (
  Pick<RestaurantMenuLog, 'id' | 'dateTime' | 'timezone' | 'type'>
  & { readonly mealTemplate: Maybe<Pick<ExchangeMealTemplate, 'id'> | Pick<MacroMealTemplate, 'id'>>, readonly items: ReadonlyArray<RestaurantMenuLogItemFormFragment>, readonly restaurantMenu: Pick<RestaurantMenu, 'id'> }
);

export type RestaurantMenuLogItemFormFragment = (
  Pick<RestaurantMenuLogItem, 'id'>
  & MenuSelectionItemFormRestaurantMenuLogItemFragment
);

export type ScoreAssignmentFragment = (
  Pick<Score, 'value'>
  & { readonly scoringSystem: ScoringSystemAssignmentFragment }
);

export type ScoringSystemAssignmentFragment = Pick<ScoringSystem, 'name' | 'id'>;

export type ScoringSystemFragment = Pick<ScoringSystem, 'id' | 'name'>;

export type ConvertedServingQueryVariables = Exact<{
  input: ConvertedServingInput;
}>;


export type ConvertedServingQuery = { readonly convertedServing: ServingWithFoodOrRecipeFragment };

export type ServingWithFoodOrRecipeMacrosFragment = (
  { readonly __typename: 'Serving' }
  & Pick<Serving, 'id'>
  & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
    { readonly __typename: 'BrandedFood' }
    & Pick<BrandedFood, 'id' | 'name' | 'usdaFdcDescription'>
  ) | (
    { readonly __typename: 'GenericFood' }
    & Pick<GenericFood, 'id' | 'name' | 'usdaFdcDescription'>
  ) | (
    { readonly __typename: 'Recipe' }
    & Pick<Recipe, 'id' | 'name'>
    & { readonly createdBy: Pick<User, 'firstName' | 'lastName'> }
  ) }
);

export type RecipeIngredientServingAmountFragment = (
  Pick<ServingAmount, 'id' | 'amount' | 'position'>
  & { readonly serving: RecipeIngredientServingFragment }
);

type TimelineItemForGroupByDateFoodLogMealFragment = (
  { readonly __typename: 'FoodLogMeal' }
  & Pick<FoodLogMeal, 'id'>
);

type TimelineItemForGroupByDateFoodLogActivityFragment = (
  { readonly __typename: 'FoodLogActivity' }
  & Pick<FoodLogActivity, 'id' | 'date' | 'startTime' | 'timezone'>
);

type TimelineItemForGroupByDateMenuOrderFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id'>
);

type TimelineItemForGroupByDateMealPlanDateFragment = (
  { readonly __typename: 'MealPlanDate' }
  & Pick<MealPlanDate, 'date' | 'timezone'>
);

type TimelineItemForGroupByDateStandaloneMealMenuFragment = (
  { readonly __typename: 'StandaloneMealMenu' }
  & Pick<StandaloneMealMenu, 'id'>
);

type TimelineItemForGroupByDateRestaurantMenuLogFragment = (
  { readonly __typename: 'RestaurantMenuLog' }
  & Pick<RestaurantMenuLog, 'id'>
);

export type TimelineItemForGroupByDateFragment = TimelineItemForGroupByDateFoodLogMealFragment | TimelineItemForGroupByDateFoodLogActivityFragment | TimelineItemForGroupByDateMenuOrderFragment | TimelineItemForGroupByDateMealPlanDateFragment | TimelineItemForGroupByDateStandaloneMealMenuFragment | TimelineItemForGroupByDateRestaurantMenuLogFragment;

export type TimelineMealForGroupByDateFragment = (
  Pick<TimelineMeal, 'id' | 'start' | 'timezone'>
  & { readonly notifications: ReadonlyArray<NotificationTimelineAthleteSelfOnboardedNotificationFragment | NotificationTimelineBulkOrderStatusNotificationFragment | NotificationTimelineFoodLogMealCommentNotificationFragment | NotificationTimelineFoodLogMealEditNotificationFragment | NotificationTimelineFoodLogMealImageNotificationFragment | NotificationTimelineGoalReminderNotificationFragment | NotificationTimelineMealMenuAvailableNotificationFragment | NotificationTimelineMealMenuIsSharedNotificationFragment | NotificationTimelineMealMenuOrderDueNotificationFragment | NotificationTimelineMealPlanSharedNotificationFragment | NotificationTimelineMenuOrderCommentNotificationFragment | NotificationTimelineMenuOrderImageNotificationFragment | NotificationTimelineMenuOrderStatusNotificationFragment | NotificationTimelineTimelineMealCommentNotificationFragment | NotificationTimelineTimelineMealEditNotificationFragment | NotificationTimelineTimelineMealImageNotificationFragment> }
);

type NotificationTimelineAthleteSelfOnboardedNotificationFragment = (
  { readonly __typename: 'AthleteSelfOnboardedNotification' }
  & Pick<AthleteSelfOnboardedNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineBulkOrderStatusNotificationFragment = (
  { readonly __typename: 'BulkOrderStatusNotification' }
  & Pick<BulkOrderStatusNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineFoodLogMealCommentNotificationFragment = (
  { readonly __typename: 'FoodLogMealCommentNotification' }
  & Pick<FoodLogMealCommentNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineFoodLogMealEditNotificationFragment = (
  { readonly __typename: 'FoodLogMealEditNotification' }
  & Pick<FoodLogMealEditNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineFoodLogMealImageNotificationFragment = (
  { readonly __typename: 'FoodLogMealImageNotification' }
  & Pick<FoodLogMealImageNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineGoalReminderNotificationFragment = (
  { readonly __typename: 'GoalReminderNotification' }
  & Pick<GoalReminderNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMealMenuAvailableNotificationFragment = (
  { readonly __typename: 'MealMenuAvailableNotification' }
  & Pick<MealMenuAvailableNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMealMenuIsSharedNotificationFragment = (
  { readonly __typename: 'MealMenuIsSharedNotification' }
  & Pick<MealMenuIsSharedNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMealMenuOrderDueNotificationFragment = (
  { readonly __typename: 'MealMenuOrderDueNotification' }
  & Pick<MealMenuOrderDueNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMealPlanSharedNotificationFragment = (
  { readonly __typename: 'MealPlanSharedNotification' }
  & Pick<MealPlanSharedNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMenuOrderCommentNotificationFragment = (
  { readonly __typename: 'MenuOrderCommentNotification' }
  & Pick<MenuOrderCommentNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMenuOrderImageNotificationFragment = (
  { readonly __typename: 'MenuOrderImageNotification' }
  & Pick<MenuOrderImageNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMenuOrderStatusNotificationFragment = (
  { readonly __typename: 'MenuOrderStatusNotification' }
  & Pick<MenuOrderStatusNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineTimelineMealCommentNotificationFragment = (
  { readonly __typename: 'TimelineMealCommentNotification' }
  & Pick<TimelineMealCommentNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineTimelineMealEditNotificationFragment = (
  { readonly __typename: 'TimelineMealEditNotification' }
  & Pick<TimelineMealEditNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineTimelineMealImageNotificationFragment = (
  { readonly __typename: 'TimelineMealImageNotification' }
  & Pick<TimelineMealImageNotification, 'id' | 'viewedAt'>
);

export type NotificationTimelineFragment = NotificationTimelineAthleteSelfOnboardedNotificationFragment | NotificationTimelineBulkOrderStatusNotificationFragment | NotificationTimelineFoodLogMealCommentNotificationFragment | NotificationTimelineFoodLogMealEditNotificationFragment | NotificationTimelineFoodLogMealImageNotificationFragment | NotificationTimelineGoalReminderNotificationFragment | NotificationTimelineMealMenuAvailableNotificationFragment | NotificationTimelineMealMenuIsSharedNotificationFragment | NotificationTimelineMealMenuOrderDueNotificationFragment | NotificationTimelineMealPlanSharedNotificationFragment | NotificationTimelineMenuOrderCommentNotificationFragment | NotificationTimelineMenuOrderImageNotificationFragment | NotificationTimelineMenuOrderStatusNotificationFragment | NotificationTimelineTimelineMealCommentNotificationFragment | NotificationTimelineTimelineMealEditNotificationFragment | NotificationTimelineTimelineMealImageNotificationFragment;

export type MealMenuSharedTimelineFragment = (
  { readonly __typename: 'StandaloneMealMenu' }
  & Pick<StandaloneMealMenu, 'id' | 'timezone' | 'name' | 'start' | 'durationInMinutes' | 'prepTimeInMinutes' | 'lastOrderTimeBeforeEndInMinutes' | 'isLogOnly' | 'isInHouseMenuOrderable' | 'isRestaurantMenuOrderable' | 'isViewOnlyForMe' | 'isBulkOrderable'>
  & { mealType: StandaloneMealMenu['type'] }
  & { readonly restaurantMenuLinks: ReadonlyArray<(
    Pick<RestaurantMenuLink, 'id' | 'allowCustomOrders' | 'allowPlateOrders'>
    & { readonly restaurant: Pick<Restaurant, 'id' | 'name'> }
  )> }
);

export type RestaurantMenuLinkOrderSharedTimelineFragment = (
  { readonly mealMenu: Pick<StandaloneMealMenu, 'id'>, readonly restaurantMenuLink: (
    Pick<RestaurantMenuLink, 'id'>
    & { readonly restaurant: Pick<Restaurant, 'id' | 'name'> }
  ) }
  & RestaurantMenuLinkOrderFragment
);

export type RestaurantMenuLinkPlateOrderSharedTimelineFragment = (
  { readonly mealMenu: MealMenuWithTimesFragment, readonly restaurantMenuLink: (
    Pick<RestaurantMenuLink, 'id'>
    & { readonly restaurant: Pick<Restaurant, 'id' | 'name'> }
  ) }
  & RestaurantMenuLinkPlateOrderFragment
);

export type MenuOrderSharedTimelineFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id'>
  & { readonly mealMenu: Pick<StandaloneMealMenu, 'id'> }
);

export type MealMenuLogSharedTimelineFragment = (
  { readonly __typename: 'MealMenuLog' }
  & Pick<MealMenuLog, 'id'>
  & { readonly mealMenu: Pick<StandaloneMealMenu, 'id'> }
);

export type MealPlanDateSharedTimelineFragment = (
  { readonly __typename: 'MealPlanDate' }
  & Pick<MealPlanDate, 'date' | 'timezone'>
  & { readonly mealPlan: MealPlanSharedTimelineFragment }
);

export type MealPlanSharedTimelineFragment = (
  Pick<MealPlan, 'id'>
  & { readonly mealTemplates: ReadonlyArray<MealTemplateSharedTimelineExchangeMealTemplateFragment | MealTemplateSharedTimelineMacroMealTemplateFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateSharedTimelineFragment> }
);

type MealTemplateSharedTimelineExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly meal: Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'> }
);

type MealTemplateSharedTimelineMacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'id'>
  & { readonly meal: Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'> }
);

export type MealTemplateSharedTimelineFragment = MealTemplateSharedTimelineExchangeMealTemplateFragment | MealTemplateSharedTimelineMacroMealTemplateFragment;

export type ActivityTemplateSharedTimelineFragment = (
  { readonly __typename: 'ActivityTemplate' }
  & Pick<ActivityTemplate, 'id'>
  & { readonly activity: Pick<Activity, 'id' | 'name' | 'start' | 'end' | 'type'> }
);

export type TimelineMealSharedTimelineFragment = (
  { readonly __typename: 'TimelineMeal' }
  & Pick<TimelineMeal, 'id' | 'name' | 'type' | 'timezone' | 'start' | 'durationInMinutes'>
  & { readonly mealTemplate: Maybe<Pick<ExchangeMealTemplate, 'id'> | Pick<MacroMealTemplate, 'id'>>, readonly mealMenus: ReadonlyArray<MealMenuSharedTimelineFragment>, readonly servingAmounts: ReadonlyArray<FullServingAmountFragment>, readonly restaurantMenuLogs: ReadonlyArray<RestaurantMenuLogSharedTimelineFragment>, readonly restaurantMenuLinkPlateOrders: ReadonlyArray<RestaurantMenuLinkPlateOrderSharedTimelineFragment>, readonly restaurantMenuLinkOrders: ReadonlyArray<RestaurantMenuLinkOrderSharedTimelineFragment>, readonly menuOrders: ReadonlyArray<MenuOrderSharedTimelineFragment>, readonly mealMenuLogs: ReadonlyArray<MealMenuLogSharedTimelineFragment> }
);

export type FoodLogActivitySharedTimelineFragment = (
  { readonly __typename: 'FoodLogActivity' }
  & Pick<FoodLogActivity, 'id' | 'name' | 'startTime' | 'endTime' | 'timezone'>
  & { activityType: FoodLogActivity['type'] }
  & { readonly activityTemplate: Maybe<Pick<ActivityTemplate, 'id'>> }
);

export type RestaurantMenuLogSharedTimelineFragment = (
  { readonly __typename: 'RestaurantMenuLog' }
  & Pick<RestaurantMenuLog, 'id' | 'dateTime' | 'timezone'>
  & { mealType: RestaurantMenuLog['type'] }
  & { readonly items: ReadonlyArray<(
    Pick<RestaurantMenuLogItem, 'id'>
    & MenuSelectionItemFormRestaurantMenuLogItemFragment
  )> }
);

export type UnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsQuery = { readonly units: ReadonlyArray<UnitFragment> };

export type UnitFragment = Pick<Unit, 'id' | 'name'>;

export type UnitConversionsFromQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnitConversionsFromQuery = { readonly unit: UnitWithConversionsFragment };

export type UnitsWithConversionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsWithConversionsQuery = { readonly units: ReadonlyArray<UnitWithConversionsFragment> };

export type UnitWithConversionsFragment = (
  Pick<Unit, 'id' | 'name'>
  & { readonly conversionsFrom: ReadonlyArray<(
    Pick<UnitConversion, 'id' | 'fromQuantity' | 'toQuantity'>
    & { readonly toUnit: (
      Pick<Unit, 'isProductionOnly'>
      & UnitFragment
    ) }
  )> }
);

export type GetUserLocaleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserLocaleQuery = { readonly myUser: (
    Pick<User, 'id'>
    & { readonly locale: Pick<Locale, 'id' | 'code'> }
  ) };

export type ActivityFragment = (
  { readonly __typename: 'Activity' }
  & Pick<Activity, 'id' | 'name' | 'start' | 'end' | 'type'>
);

export type ActivityTemplateFragment = (
  { readonly __typename: 'ActivityTemplate' }
  & Pick<ActivityTemplate, 'id'>
  & { readonly activity: ActivityFragment }
);

export type AnthropometryEntryFragment = (
  { readonly __typename: 'AnthropometryEntry' }
  & Pick<AnthropometryEntry, 'id' | 'height' | 'weight' | 'leanBodyMass' | 'bodyFatMass' | 'percentBodyFat' | 'datetime' | 'type' | 'sex' | 'comment' | 'boneMineralDensity' | 'boneMineralDensityZScore' | 'boneMineralDensityTScore' | 'boneMineralContent' | 'boneArea' | 'boneMass' | 'dryLeanMass' | 'skeletalMuscleMass' | 'trunkFat' | 'visceralFatArea' | 'heightInCm' | 'weightInKg' | 'leanBodyMassInKg' | 'bodyFatMassInKg' | 'skeletalMuscleMassInKg' | 'trunkFatInKg' | 'dryLeanMassInKg'>
);

export type AthleteFragment = (
  { readonly __typename: 'Athlete' }
  & Pick<Athlete, 'id' | 'firstName' | 'lastName' | 'sex' | 'birthDate' | 'phoneNumber' | 'email' | 'headshotUrl' | 'secaUid' | 'inbodyUid' | 'isArchived' | 'activationLinkSent' | 'jerseyNumber' | 'isProfileNotemealOnly'>
);

export type AthleteWithMealPlansFragment = (
  { readonly mealPlans: ReadonlyArray<MealPlanSearchFormFragment> }
  & AthleteFragment
);

export type AthleteWithGoalAndAnthroFragment = (
  { readonly position: Maybe<PositionFragment>, readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment>, readonly currentGoal: Maybe<FullGoalFragment> }
  & AthleteFragment
);

export type AthleteWithFoodPrefsFragment = (
  { readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly likedFoodGroups: ReadonlyArray<FullFoodGroupFragment>, readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
  & AthleteFragment
);

export type BrandFragment = (
  { readonly __typename: 'Brand' }
  & Pick<Brand, 'id' | 'name' | 'usdaManufacturerName' | 'foodCount'>
);

export type CalorieBudgetFragment = (
  { readonly __typename: 'CalorieBudget' }
  & Pick<CalorieBudget, 'id' | 'rmrMethod' | 'rmrCalories' | 'activityFactor' | 'kcalOffset'>
);

export type FullCalorieBudgetFragment = (
  { readonly goalSnapshot: FullGoalFragment }
  & CalorieBudgetFragment
);

export type ExchangeFragment = (
  { readonly __typename: 'Exchange' }
  & Pick<Exchange, 'id' | 'name' | 'shortName' | 'type' | 'cho' | 'pro' | 'fat' | 'kcal'>
);

export type FullExchangeFragment = (
  { readonly exchangeServingList: FullExchangeServingListFragment }
  & ExchangeFragment
);

export type ExchangeAmountFragment = (
  { readonly __typename: 'ExchangeAmount' }
  & Pick<ExchangeAmount, 'id' | 'amount' | 'pickListServingIds'>
  & { readonly exchange: ExchangeFragment }
);

export type EditExchangeAmountFragment = (
  Pick<ExchangeAmount, 'amountInput'>
  & ExchangeAmountFragment
);

export type ExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & MealTemplateExchangeMealTemplateFragment
);

export type FullExchangeMealTemplateFragment = (
  { readonly exchangeTargets: ReadonlyArray<ExchangeAmountFragment> }
  & FullMealTemplateExchangeMealTemplateFragment
  & ExchangeMealTemplateFragment
);

export type EditExchangeMealTemplateFragment = (
  { readonly exchangeTargets: ReadonlyArray<EditExchangeAmountFragment> }
  & EditMealTemplateExchangeMealTemplateFragment
);

export type EditFullExchangeMealTemplateFragment = (
  EditFullMealTemplateExchangeMealTemplateFragment
  & ExchangeMealTemplateFragment
  & EditExchangeMealTemplateFragment
);

export type ExchangeRatioFragment = (
  { readonly __typename: 'ExchangeRatio' }
  & Pick<ExchangeRatio, 'ratio'>
  & { readonly exchange: ExchangeFragment }
);

export type FoodOrRecipeExchangeRatiosFragment = (
  { readonly __typename: 'FoodOrRecipeExchangeRatios' }
  & Pick<FoodOrRecipeExchangeRatios, 'id' | 'foodOrRecipeId'>
  & { readonly exchangeRatios: ReadonlyArray<ExchangeRatioFragment> }
);

export type ExchangeServingListFragment = (
  { readonly __typename: 'ExchangeServingList' }
  & Pick<ExchangeServingList, 'id' | 'name'>
);

export type FullExchangeServingListFragment = (
  { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  & ExchangeServingListFragment
);

export type ExchangeSetFragment = (
  { readonly __typename: 'ExchangeSet' }
  & Pick<ExchangeSet, 'id' | 'name'>
  & { readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
);

export type ExchangeSetWithExchangesFragment = (
  { readonly exchanges: ReadonlyArray<ExchangeFragment> }
  & ExchangeSetFragment
);

export type FullExchangeSetWithoutExchangeRatiosFragment = (
  { readonly exchanges: ReadonlyArray<FullExchangeFragment> }
  & ExchangeSetFragment
);

export type FullExchangeSetFragment = (
  { readonly foodOrRecipeExchangeRatios: ReadonlyArray<(
    { readonly __typename: 'FoodOrRecipeExchangeRatios' }
    & Pick<FoodOrRecipeExchangeRatios, 'id' | 'foodOrRecipeId'>
    & { readonly exchangeRatios: ReadonlyArray<(
      { readonly __typename: 'ExchangeRatio' }
      & Pick<ExchangeRatio, 'ratio'>
      & { readonly exchange: ExchangeFragment }
    )> }
  )> }
  & FullExchangeSetWithoutExchangeRatiosFragment
);

type FoodBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'id' | 'name' | 'exchangeTypes' | 'nixItemId' | 'nixTagId' | 'usdaFdcDescription' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType' | 'usdaFdcId' | 'isRecipeIngredientOnly' | 'choPer100g' | 'proPer100g' | 'fatPer100g' | 'kcalPer100g' | 'thumbnailUrl' | 'locales'>
);

type FoodGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'id' | 'name' | 'exchangeTypes' | 'nixItemId' | 'nixTagId' | 'usdaFdcDescription' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType' | 'usdaFdcId' | 'isRecipeIngredientOnly' | 'choPer100g' | 'proPer100g' | 'fatPer100g' | 'kcalPer100g' | 'thumbnailUrl' | 'locales'>
);

export type FoodFragment = FoodBrandedFoodFragment | FoodGenericFoodFragment;

type FoodLimitedAccessBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'id' | 'name' | 'usdaFdcDescription' | 'exchangeTypes' | 'thumbnailUrl' | 'hasFullAccess' | 'nixItemId' | 'nixTagId'>
  & { readonly brand: Maybe<Pick<Brand, 'id' | 'name'>>, readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
);

type FoodLimitedAccessGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'id' | 'name' | 'usdaFdcDescription' | 'exchangeTypes' | 'thumbnailUrl' | 'hasFullAccess' | 'nixItemId' | 'nixTagId'>
  & { readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
);

export type FoodLimitedAccessFragment = FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment;

type FoodPreviewBrandedFoodFragment = Pick<BrandedFood, 'id' | 'name'>;

type FoodPreviewGenericFoodFragment = Pick<GenericFood, 'id' | 'name'>;

export type FoodPreviewFragment = FoodPreviewBrandedFoodFragment | FoodPreviewGenericFoodFragment;

export type BrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'id' | 'name' | 'exchangeTypes' | 'usdaFdcDescription' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType' | 'usdaFdcId' | 'isRecipeIngredientOnly' | 'nixItemId' | 'nixTagId' | 'choPer100g' | 'proPer100g' | 'fatPer100g' | 'kcalPer100g' | 'thumbnailUrl'>
  & { readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>> }
);

export type GenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'id' | 'name' | 'exchangeTypes' | 'nixItemId' | 'nixTagId' | 'usdaFdcDescription' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType' | 'usdaFdcId' | 'isRecipeIngredientOnly' | 'choPer100g' | 'proPer100g' | 'fatPer100g' | 'kcalPer100g' | 'thumbnailUrl'>
  & { readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>>, readonly owner: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>>, readonly orgGroup: Maybe<Pick<OrgGroup, 'id' | 'name'>> }
);

type FoodWithDefaultServingBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'hasFullAccess'>
  & { readonly brand: Maybe<BrandFragment>, readonly defaultServing: FullServingFragment, readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
  & FoodBrandedFoodFragment
);

type FoodWithDefaultServingGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'hasFullAccess'>
  & { readonly orgGroup: Maybe<Pick<OrgGroup, 'id' | 'name'>>, readonly defaultServing: FullServingFragment, readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
  & FoodGenericFoodFragment
);

export type FoodWithDefaultServingFragment = FoodWithDefaultServingBrandedFoodFragment | FoodWithDefaultServingGenericFoodFragment;

type FoodWithServingsBrandedFoodFragment = (
  Pick<BrandedFood, 'showGramServing' | 'showOunceServing'>
  & { readonly servings: ReadonlyArray<ServingWithFoodOrRecipeFragment> }
  & FoodBrandedFoodFragment
);

type FoodWithServingsGenericFoodFragment = (
  Pick<GenericFood, 'showGramServing' | 'showOunceServing'>
  & { readonly servings: ReadonlyArray<ServingWithFoodOrRecipeFragment> }
  & FoodGenericFoodFragment
);

export type FoodWithServingsFragment = FoodWithServingsBrandedFoodFragment | FoodWithServingsGenericFoodFragment;

export type FullFoodGroupFragment = (
  { readonly __typename: 'FoodGroup' }
  & Pick<FoodGroup, 'id' | 'name' | 'source' | 'foodsCount'>
);

export type FullFoodGroupWithFoodsFragment = (
  { readonly __typename: 'FoodGroup' }
  & Pick<FoodGroup, 'id' | 'name' | 'source' | 'foodsCount'>
  & { readonly foods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment> }
);

export type FoodLogNotificationSettingsFragment = Pick<FoodLogNotificationSettings, 'edits' | 'imageUploads' | 'messages'>;

export type GoalFragment = (
  { readonly __typename: 'Goal' }
  & Pick<Goal, 'id' | 'kcalOffset' | 'start' | 'end'>
);

export type FullGoalFragment = (
  { readonly type: GoalTypeFragment }
  & GoalFragment
);

export type GoalTypeFragment = (
  { readonly __typename: 'GoalType' }
  & Pick<GoalType, 'id' | 'name' | 'defaultKcalOffset'>
);

export type ImageFragment = Pick<Image, 'id' | 'position' | 'url'>;

export type MacroMathFragment = Pick<MacroMath, 'gPerKg' | 'leftOverRatio'>;

export type MacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'cho' | 'pro' | 'fat' | 'kcal'>
  & MealTemplateMacroMealTemplateFragment
);

export type FullMacroMealTemplateFragment = (
  FullMealTemplateMacroMealTemplateFragment
  & MacroMealTemplateFragment
);

export type EditMacroMealTemplateFragment = (
  Pick<MacroMealTemplate, 'choInput' | 'proInput' | 'fatInput'>
  & EditMealTemplateMacroMealTemplateFragment
);

export type EditFullMacroMealTemplateFragment = (
  EditFullMealTemplateMacroMealTemplateFragment
  & MacroMealTemplateFragment
  & EditMacroMealTemplateFragment
);

export type MacroProtocolFragment = (
  { readonly __typename: 'MacroProtocol' }
  & Pick<MacroProtocol, 'id' | 'weightTarget' | 'weightTargetInKg'>
  & { readonly cho: MacroMathFragment, readonly pro: MacroMathFragment, readonly fat: MacroMathFragment }
);

export type FullMacroProtocolFragment = (
  { readonly anthropometrySnapshot: AnthropometryEntryFragment, readonly calorieBudget: Maybe<FullCalorieBudgetFragment> }
  & MacroProtocolFragment
);

export type MealFragment = (
  { readonly __typename: 'Meal' }
  & Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'>
);

export type MealOptionFragment = (
  { readonly __typename: 'MealOption' }
  & Pick<MealOption, 'id' | 'note' | 'position' | 'name'>
);

export type EditMealOptionFragment = Pick<MealOption, 'id' | 'isAutosaving'>;

export type FullMealOptionFragment = (
  { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  & MealOptionFragment
);

export type EditFullMealOptionFragment = (
  FullMealOptionFragment
  & EditMealOptionFragment
);

export type MealOptionPendingFragment = (
  { readonly __typename: 'MealOptionPending' }
  & Pick<MealOptionPending, 'id' | 'note' | 'name'>
);

export type EditMealOptionPendingFragment = Pick<MealOptionPending, 'id' | 'isAutosaving'>;

export type FullMealOptionPendingFragment = (
  { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  & MealOptionPendingFragment
);

export type EditFullMealOptionPendingFragment = (
  FullMealOptionPendingFragment
  & EditMealOptionPendingFragment
);

export type MealPlanFragment = (
  { readonly __typename: 'MealPlan' }
  & Pick<MealPlan, 'id' | 'name' | 'isShared' | 'isAutoSuggestionsEnabled' | 'description' | 'usesDateRange' | 'dates' | 'startDate' | 'endDate' | 'type' | 'createdAt' | 'updatedAt'>
  & { readonly exchangeSet: Maybe<Pick<ExchangeSet, 'id'>> }
);

export type EditMealPlanFragment = (
  Pick<MealPlan, 'id' | 'isAutosaving'>
  & { readonly exchangeTargets: Maybe<ReadonlyArray<EditExchangeAmountFragment>> }
);

export type MealPlanSearchFormFragment = (
  { readonly macroProtocol: FullMacroProtocolFragment }
  & MealPlanFragment
);

export type BasicMealPlanFragment = (
  { readonly exchangeSet: Maybe<ExchangeSetWithExchangesFragment> }
  & MealPlanFragment
);

export type PreviewMealPlanFragment = (
  { readonly macroProtocol: FullMacroProtocolFragment, readonly schedule: Maybe<ScheduleFragment>, readonly avoidedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly promotedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly avoidedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly promotedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly mostRecentEvent: Maybe<EventFragment>, readonly exchangeTargets: Maybe<ReadonlyArray<EditExchangeAmountFragment>> }
  & BasicMealPlanFragment
);

export type CopyableMealPlanFragment = (
  { readonly macroProtocol: (
    { readonly calorieBudget: Maybe<CalorieBudgetFragment> }
    & MacroProtocolFragment
  ) }
  & BasicMealPlanFragment
);

export type EditFullMealPlanFragment = (
  { readonly athlete: (
    { readonly macroDisplaySettings: MacroMealPlanDisplaySettingsFragment, readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment }
    & AthleteWithFoodPrefsFragment
  ), readonly exchangeSet: Maybe<FullExchangeSetFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateFragment>, readonly events: ReadonlyArray<EventFragment>, readonly mealTemplates: ReadonlyArray<EditFullExchangeMealTemplateFragment | EditFullMacroMealTemplateFragment> }
  & PreviewMealPlanFragment
  & EditMealPlanFragment
);

export type FullMealPlanFragment = (
  { readonly athlete: (
    { readonly macroDisplaySettings: MacroMealPlanDisplaySettingsFragment, readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment }
    & AthleteWithFoodPrefsFragment
  ), readonly exchangeSet: Maybe<FullExchangeSetFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateFragment>, readonly events: ReadonlyArray<EventFragment>, readonly mealTemplates: ReadonlyArray<FullExchangeMealTemplateFragment | FullMacroMealTemplateFragment> }
  & PreviewMealPlanFragment
);

export type EventFragment = (
  { readonly __typename: 'Event' }
  & Pick<Event, 'type' | 'datetime'>
);

type MealTemplateExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly meal: MealFragment }
);

type MealTemplateMacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'id'>
  & { readonly meal: MealFragment }
);

export type MealTemplateFragment = MealTemplateExchangeMealTemplateFragment | MealTemplateMacroMealTemplateFragment;

type EditMealTemplateExchangeMealTemplateFragment = Pick<ExchangeMealTemplate, 'id' | 'isAutosaving' | 'selectedMealOptionId' | 'newMealOptionIds'>;

type EditMealTemplateMacroMealTemplateFragment = Pick<MacroMealTemplate, 'id' | 'isAutosaving' | 'selectedMealOptionId' | 'newMealOptionIds'>;

export type EditMealTemplateFragment = EditMealTemplateExchangeMealTemplateFragment | EditMealTemplateMacroMealTemplateFragment;

type FullMealTemplateExchangeMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<FullMealOptionFragment>, readonly pendingMealOptions: ReadonlyArray<FullMealOptionPendingFragment> }
  & MealTemplateExchangeMealTemplateFragment
);

type FullMealTemplateMacroMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<FullMealOptionFragment>, readonly pendingMealOptions: ReadonlyArray<FullMealOptionPendingFragment> }
  & MealTemplateMacroMealTemplateFragment
);

export type FullMealTemplateFragment = FullMealTemplateExchangeMealTemplateFragment | FullMealTemplateMacroMealTemplateFragment;

type ExportMealTemplateExchangeMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<ViewMealOptionFragment> }
  & MealTemplateExchangeMealTemplateFragment
);

type ExportMealTemplateMacroMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<ViewMealOptionFragment> }
  & MealTemplateMacroMealTemplateFragment
);

export type ExportMealTemplateFragment = ExportMealTemplateExchangeMealTemplateFragment | ExportMealTemplateMacroMealTemplateFragment;

type EditFullMealTemplateExchangeMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<EditFullMealOptionFragment>, readonly pendingMealOptions: ReadonlyArray<EditFullMealOptionPendingFragment> }
  & MealTemplateExchangeMealTemplateFragment
  & EditMealTemplateExchangeMealTemplateFragment
);

type EditFullMealTemplateMacroMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<EditFullMealOptionFragment>, readonly pendingMealOptions: ReadonlyArray<EditFullMealOptionPendingFragment> }
  & MealTemplateMacroMealTemplateFragment
  & EditMealTemplateMacroMealTemplateFragment
);

export type EditFullMealTemplateFragment = EditFullMealTemplateExchangeMealTemplateFragment | EditFullMealTemplateMacroMealTemplateFragment;

export type NoteFragment = (
  { readonly __typename: 'Note' }
  & Pick<Note, 'id' | 'datetime' | 'content'>
);

export type NoteWithTypeFragment = (
  { readonly type: Maybe<NoteTypeFragment> }
  & NoteFragment
);

export type FullNoteFragment = (
  { readonly athlete: AthleteFragment }
  & NoteWithTypeFragment
);

export type NoteTypeFragment = (
  { readonly __typename: 'NoteType' }
  & Pick<NoteType, 'id' | 'name'>
);

export type NutrientDietaryReferenceIntakeFragment = Pick<NutrientDietaryReferenceIntake, 'id' | 'type'>;

export type PositionFragment = (
  { readonly __typename: 'Position' }
  & Pick<Position, 'id' | 'name'>
);

export type RecipeFragment = (
  { readonly __typename: 'Recipe' }
  & Pick<Recipe, 'id' | 'name' | 'steps' | 'note' | 'exchangeTypes' | 'cho' | 'pro' | 'fat' | 'prepTimeInMinutes' | 'cookTimeInMinutes' | 'isCurrent' | 'isShared' | 'mealTypes'>
  & { readonly images: ReadonlyArray<ImageFragment>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly score: Maybe<ScoreAssignmentFragment>, readonly foodCategory: Maybe<RecipeFoodCategoryFragment> }
);

export type RecipeLimitedAccessFragment = (
  { readonly __typename: 'Recipe' }
  & Pick<Recipe, 'id' | 'name' | 'exchangeTypes' | 'isCurrent' | 'hasFullAccess'>
  & { readonly images: ReadonlyArray<ImageFragment>, readonly descendantFoods: ReadonlyArray<Pick<BrandedFood, 'id' | 'name' | 'nixItemId' | 'nixTagId'> | Pick<GenericFood, 'id' | 'name' | 'nixItemId' | 'nixTagId'>>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly score: Maybe<ScoreAssignmentFragment>, readonly ingredients: ReadonlyArray<RecipeIngredientServingAmountFragment> }
);

export type RecipeWithIngredientsFragment = (
  { readonly ingredients: ReadonlyArray<ServingAmountWithFoodOrRecipeFragment> }
  & RecipeFragment
);

export type RecipeWithServingsFragment = (
  Pick<Recipe, 'hasFullAccess'>
  & { readonly descendantRecipes: ReadonlyArray<RecipeWithIngredientsFragment>, readonly servings: ReadonlyArray<ServingWithFoodOrRecipeFragment> }
  & RecipeWithIngredientsFragment
);

export type RecipeWithFullIngredientsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RecipeWithFullIngredientsQuery = { readonly recipe: (
    Pick<Recipe, 'id'>
    & { readonly ingredients: ReadonlyArray<FullServingAmountFragment> }
  ) };

export type ScheduleFragment = (
  { readonly __typename: 'Schedule' }
  & Pick<Schedule, 'id' | 'name' | 'activityFactor'>
);

export type FullScheduleFragment = (
  { readonly meals: ReadonlyArray<MealFragment>, readonly activities: ReadonlyArray<ActivityFragment>, readonly mealPlans: ReadonlyArray<MealPlanFragment> }
  & ScheduleFragment
);

export type ServingFragment = (
  { readonly __typename: 'Serving' }
  & Pick<Serving, 'id' | 'units' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield' | 'isRecipeServingOnly' | 'hasOwnNutrients'>
);

export type ServingWithMacrosFragment = (
  { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'> }
  & ServingFragment
);

export type ServingWithFoodOrRecipeFragment = (
  Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
  & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly unit: Maybe<Pick<Unit, 'id' | 'name'>>, readonly foodOrRecipe: (
    Pick<BrandedFood, 'hasFullAccess'>
    & { readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly brand: Maybe<Pick<Brand, 'id' | 'name'>> }
    & FoodBrandedFoodFragment
  ) | (
    Pick<GenericFood, 'hasFullAccess'>
    & { readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
    & FoodGenericFoodFragment
  ) | RecipeLimitedAccessFragment }
  & ServingFragment
);

export type RecipeIngredientServingFragment = (
  { readonly __typename: 'Serving' }
  & Pick<Serving, 'id' | 'defaultAmount' | 'isDefault' | 'weight' | 'perRecipeYield' | 'hasOwnNutrients' | 'units'>
  & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly unit: Maybe<Pick<Unit, 'id' | 'name'>>, readonly foodOrRecipe: FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment | (
    { readonly __typename: 'Recipe' }
    & Pick<Recipe, 'id' | 'name' | 'exchangeTypes' | 'isCurrent' | 'hasFullAccess'>
    & { readonly images: ReadonlyArray<ImageFragment>, readonly descendantFoods: ReadonlyArray<Pick<BrandedFood, 'id'> | Pick<GenericFood, 'id'>>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly score: Maybe<ScoreAssignmentFragment> }
  ) }
);

export type FullServingFragment = (
  { readonly __typename: 'Serving' }
  & Pick<Serving, 'id' | 'units' | 'defaultAmount' | 'isDefault' | 'weight' | 'perRecipeYield' | 'hasOwnNutrients'>
  & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly unit: Maybe<Pick<Unit, 'id' | 'name'>>, readonly foodOrRecipe: FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment | RecipeLimitedAccessFragment }
);

export type ExcessiveServingFragment = (
  Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
  & { readonly unit: Maybe<Pick<Unit, 'id' | 'name'>>, readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
    { readonly brand: Maybe<Pick<Brand, 'id' | 'name'>> }
    & FoodBrandedFoodFragment
  ) | FoodGenericFoodFragment | (
    { readonly descendantFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly descendantRecipes: ReadonlyArray<RecipeWithIngredientsFragment> }
    & RecipeWithIngredientsFragment
  ) }
  & ServingFragment
);

export type ServingAmountWithFoodOrRecipeFragment = (
  { readonly __typename: 'ServingAmount' }
  & Pick<ServingAmount, 'id' | 'amount' | 'position'>
  & { readonly serving: ServingWithFoodOrRecipeFragment }
);

export type FullServingAmountFragment = (
  { readonly __typename: 'ServingAmount' }
  & Pick<ServingAmount, 'id' | 'amount' | 'position'>
  & { readonly serving: FullServingFragment }
);

export type ExcessiveServingAmountFragment = (
  { readonly __typename: 'ServingAmount' }
  & Pick<ServingAmount, 'id' | 'amount' | 'position'>
  & { readonly serving: ExcessiveServingFragment }
);

export type SportFragment = (
  { readonly __typename: 'Sport' }
  & Pick<Sport, 'id' | 'name'>
);

export type FullSportFragment = (
  { readonly positions: ReadonlyArray<PositionFragment> }
  & SportFragment
);

export type TeamFragment = (
  { readonly __typename: 'Team' }
  & Pick<Team, 'id' | 'gender' | 'name'>
);

export type TeamWithSportFragment = (
  { readonly sport: Maybe<FullSportFragment> }
  & TeamFragment
);

export type UsdaFoodFragment = (
  { readonly __typename: 'USDAFood' }
  & Pick<UsdaFood, 'id' | 'allHighlightFields' | 'brandOwner' | 'dataType' | 'description' | 'gtinUpc' | 'ingredients' | 'publishedDate' | 'score'>
);

export type UsdaFoodDetailFragment = (
  { readonly __typename: 'USDAFoodDetail' }
  & Pick<UsdaFoodDetail, 'id' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType'>
  & { readonly cho: UsdaFoodNutrientFragment, readonly pro: UsdaFoodNutrientFragment, readonly fat: UsdaFoodNutrientFragment, readonly foodNutrients: ReadonlyArray<UsdaFoodNutrientFragment>, readonly servings: ReadonlyArray<UsdaFoodServingFragment> }
);

export type UsdaFoodNutrientFragment = (
  { readonly __typename: 'USDAFoodNutrient' }
  & Pick<UsdaFoodNutrient, 'id' | 'amount' | 'dataPoints' | 'unitName' | 'max' | 'min' | 'median' | 'name' | 'footnote'>
);

export type UsdaFoodServingFragment = (
  { readonly __typename: 'USDAFoodServing' }
  & Pick<UsdaFoodServing, 'idx' | 'cho' | 'pro' | 'fat' | 'weight' | 'defaultAmount' | 'units' | 'isDefault' | 'usdaWeightSeq'>
);

export type AddAthleteDislikedFoodMutationVariables = Exact<{
  input: AthleteFoodPreferenceInput;
}>;


export type AddAthleteDislikedFoodMutation = { readonly addAthleteDislikedFood: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    ) } };

export type AddAthleteDislikedFoodGroupMutationVariables = Exact<{
  input: AthleteFoodGroupPreferenceInput;
}>;


export type AddAthleteDislikedFoodGroupMutation = { readonly addAthleteDislikedFoodGroup: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
    ) } };

export type AddAthleteEmailMutationVariables = Exact<{
  input: AddAthleteEmailInput;
}>;


export type AddAthleteEmailMutation = { readonly addAthleteEmail: { readonly athlete: AthleteFragment } };

export type AddAthleteLikedFoodMutationVariables = Exact<{
  input: AthleteFoodPreferenceInput;
}>;


export type AddAthleteLikedFoodMutation = { readonly addAthleteLikedFood: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    ) } };

export type AddAthleteLikedFoodGroupMutationVariables = Exact<{
  input: AthleteFoodGroupPreferenceInput;
}>;


export type AddAthleteLikedFoodGroupMutation = { readonly addAthleteLikedFoodGroup: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly likedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
    ) } };

export type AddAthletePhoneNumberMutationVariables = Exact<{
  input: AddAthletePhoneNumberInput;
}>;


export type AddAthletePhoneNumberMutation = { readonly addAthletePhoneNumber: { readonly athlete: AthleteFragment } };

export type AddEventMutationVariables = Exact<{
  input: AddEventInput;
}>;


export type AddEventMutation = { readonly addEvent: { readonly event: EventFragment } };

export type AddMealOptionMutationVariables = Exact<{
  input: AddMealOptionInput;
}>;


export type AddMealOptionMutation = { readonly addMealOption: { readonly mealOption: FullMealOptionFragment } };

export type ArchiveAthleteMutationVariables = Exact<{
  input: ArchiveAthleteInput;
}>;


export type ArchiveAthleteMutation = { readonly archiveAthlete: { readonly athlete: Pick<Athlete, 'id'> } };

export type CopyExchangeSetMutationVariables = Exact<{
  input: CopyExchangeSetInput;
}>;


export type CopyExchangeSetMutation = { readonly copyExchangeSet: { readonly exchangeSet: FullExchangeSetFragment } };

export type CreateOrgMembershipForAthleteMutationVariables = Exact<{
  input: CreateOrgMembershipForAthleteInput;
}>;


export type CreateOrgMembershipForAthleteMutation = { readonly createOrgMembershipForAthlete: (
    Pick<CreateOrgMembershipForAthletePayload, 'generatedSelfServiceActivationLink'>
    & { readonly athlete: (
      Pick<Athlete, 'id' | 'activationLinkSent'>
      & { readonly user: Pick<User, 'id' | 'username'>, readonly orgMembership: Maybe<Pick<OrgMembership, 'id' | 'isActive'>> }
    ) }
  ) };

export type CreateAthletesMutationVariables = Exact<{
  input: ReadonlyArray<CreateAthleteInput> | CreateAthleteInput;
}>;


export type CreateAthletesMutation = { readonly createAthletes: { readonly athletes: ReadonlyArray<AthleteFragment> } };

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;


export type CreateBrandMutation = { readonly createBrand: { readonly brand: BrandFragment } };

export type CreateFoodServingsMutationVariables = Exact<{
  input: CreateFoodServingsInput;
}>;


export type CreateFoodServingsMutation = { readonly createFoodServings: { readonly servings: ReadonlyArray<ServingFragment> } };

export type CreateGoalMutationVariables = Exact<{
  input: CreateGoalInput;
}>;


export type CreateGoalMutation = { readonly createGoal: { readonly goal: FullGoalFragment } };

export type CreateMetricAnthropometryEntriesMutationVariables = Exact<{
  input: ReadonlyArray<CreateMetricAnthropometryEntryInput> | CreateMetricAnthropometryEntryInput;
}>;


export type CreateMetricAnthropometryEntriesMutation = { readonly createMetricAnthropometryEntries: { readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> } };

export type CreateMetricAnthropometryEntryMutationVariables = Exact<{
  input: CreateMetricAnthropometryEntryInput;
}>;


export type CreateMetricAnthropometryEntryMutation = { readonly createMetricAnthropometryEntry: { readonly anthropometryEntry: AnthropometryEntryFragment } };

export type CreateMetricAthletesMutationVariables = Exact<{
  input: ReadonlyArray<CreateMetricAthleteInput> | CreateMetricAthleteInput;
}>;


export type CreateMetricAthletesMutation = { readonly createMetricAthletes: { readonly athletes: ReadonlyArray<AthleteFragment> } };

export type CreateNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;


export type CreateNoteMutation = { readonly createNote: { readonly note: NoteWithTypeFragment } };

export type CreateNoteTypeMutationVariables = Exact<{
  input: CreateNoteTypeInput;
}>;


export type CreateNoteTypeMutation = { readonly createNoteType: { readonly noteType: NoteTypeFragment } };

export type CreateOrgFoodGroupMutationVariables = Exact<{
  input: CreateFoodGroupInput;
}>;


export type CreateOrgFoodGroupMutation = { readonly createOrgFoodGroup: { readonly foodGroup: FullFoodGroupFragment } };

export type CreateOrgFoodServingsMutationVariables = Exact<{
  input: CreateFoodServingsInput;
}>;


export type CreateOrgFoodServingsMutation = { readonly createOrgFoodServings: { readonly servings: ReadonlyArray<ServingFragment> } };

export type CreateSharedFoodGroupMutationVariables = Exact<{
  input: CreateFoodGroupInput;
}>;


export type CreateSharedFoodGroupMutation = { readonly createSharedFoodGroup: { readonly foodGroup: FullFoodGroupFragment } };

export type DeleteAnthropometryEntryMutationVariables = Exact<{
  input: DeleteAnthropometryEntryInput;
}>;


export type DeleteAnthropometryEntryMutation = { readonly deleteAnthropometryEntry: Pick<DeleteAnthropometryEntryPayload, 'anthropometryEntryId'> };

export type DeleteMealPlansMutationVariables = Exact<{
  input: DeleteMealPlansInput;
}>;


export type DeleteMealPlansMutation = { readonly deleteMealPlans: Pick<DeleteMealPlansPayload, 'mealPlanIds'> };

export type DeleteNoteMutationVariables = Exact<{
  input: DeleteNoteInput;
}>;


export type DeleteNoteMutation = { readonly deleteNote: Pick<DeleteNotePayload, 'noteId'> };

export type DeleteOrgFoodMutationVariables = Exact<{
  input: DeleteFoodInput;
}>;


export type DeleteOrgFoodMutation = { readonly deleteOrgFood: Pick<DeleteFoodPayload, 'foodId'> };

export type DeprecateSharedFoodMutationVariables = Exact<{
  input: DeprecateFoodInput;
}>;


export type DeprecateSharedFoodMutation = { readonly deprecateSharedFood: Pick<DeprecateFoodPayload, 'foodId'> };

export type EditAnthropometryEntryMutationVariables = Exact<{
  input: EditAnthropometryEntryInput;
}>;


export type EditAnthropometryEntryMutation = { readonly editAnthropometryEntry: { readonly anthropometryEntry: AnthropometryEntryFragment } };

export type EditAnthropometryEntryCommentMutationVariables = Exact<{
  input: EditAnthropometryEntryCommentInput;
}>;


export type EditAnthropometryEntryCommentMutation = { readonly editAnthropometryEntryComment: { readonly anthropometryEntry: AnthropometryEntryFragment } };

export type EditBrandMutationVariables = Exact<{
  input: EditBrandInput;
}>;


export type EditBrandMutation = { readonly editBrand: { readonly brand: BrandFragment } };

export type EditExchangeMealTemplateMutationVariables = Exact<{
  input: EditExchangeMealTemplateInput;
}>;


export type EditExchangeMealTemplateMutation = { readonly editExchangeMealTemplate: { readonly exchangeMealTemplate: EditFullExchangeMealTemplateFragment } };

export type EditExchangeMealTemplatesMutationVariables = Exact<{
  input: ReadonlyArray<EditExchangeMealTemplateInput> | EditExchangeMealTemplateInput;
}>;


export type EditExchangeMealTemplatesMutation = { readonly editExchangeMealTemplates: { readonly exchangeMealTemplates: ReadonlyArray<EditFullExchangeMealTemplateFragment> } };

export type EditFoodServingMutationVariables = Exact<{
  input: EditFoodServingInput;
}>;


export type EditFoodServingMutation = { readonly editFoodServing: { readonly serving: ServingFragment } };

export type EditMacroMealTemplateMutationVariables = Exact<{
  input: EditMacroMealTemplateInput;
}>;


export type EditMacroMealTemplateMutation = { readonly editMacroMealTemplate: { readonly macroMealTemplate: MacroMealTemplateFragment } };

export type EditMacroMealTemplatesMutationVariables = Exact<{
  input: ReadonlyArray<EditMacroMealTemplateInput> | EditMacroMealTemplateInput;
}>;


export type EditMacroMealTemplatesMutation = { readonly editMacroMealTemplates: { readonly macroMealTemplates: ReadonlyArray<MacroMealTemplateFragment> } };

export type EditMacroProtocolMutationVariables = Exact<{
  input: EditMacroProtocolInput;
}>;


export type EditMacroProtocolMutation = { readonly editMacroProtocol: { readonly macroProtocol: FullMacroProtocolFragment } };

export type EditMacroProtocolWithNewGoalMutationVariables = Exact<{
  input: EditMacroProtocolWithNewGoalInput;
}>;


export type EditMacroProtocolWithNewGoalMutation = { readonly editMacroProtocolWithNewGoal: { readonly macroProtocol: FullMacroProtocolFragment } };

export type EditMacroProtocolWithOldGoalMutationVariables = Exact<{
  input: EditMacroProtocolWithOldGoalInput;
}>;


export type EditMacroProtocolWithOldGoalMutation = { readonly editMacroProtocolWithOldGoal: { readonly macroProtocol: FullMacroProtocolFragment } };

export type EditMealOptionMutationVariables = Exact<{
  input: EditMealOptionInput;
}>;


export type EditMealOptionMutation = { readonly editMealOption: { readonly mealOption: FullMealOptionFragment } };

export type EditMealOptionPendingMutationVariables = Exact<{
  input: EditMealOptionPendingInput;
}>;


export type EditMealOptionPendingMutation = { readonly editMealOptionPending: { readonly mealOptionPending: FullMealOptionPendingFragment } };

export type EditMealPlanExchangeTargetsMutationVariables = Exact<{
  input: EditMealPlanExchangeTargetsInput;
}>;


export type EditMealPlanExchangeTargetsMutation = { readonly editMealPlanExchangeTargets: { readonly mealPlan: (
      Pick<MealPlan, 'id'>
      & { readonly exchangeTargets: Maybe<ReadonlyArray<EditExchangeAmountFragment>> }
    ) } };

export type EditMealPlanIsSharedMutationVariables = Exact<{
  input: EditMealPlanIsSharedInput;
}>;


export type EditMealPlanIsSharedMutation = { readonly editMealPlanIsShared: { readonly mealPlan: BasicMealPlanFragment } };

export type EditMealPlansIsSharedMutationVariables = Exact<{
  input: EditMealPlansIsSharedInput;
}>;


export type EditMealPlansIsSharedMutation = { readonly editMealPlansIsShared: { readonly mealPlans: ReadonlyArray<Pick<MealPlan, 'id' | 'isShared'>> } };

export type EditMetricAnthropometryEntriesMutationVariables = Exact<{
  input: ReadonlyArray<EditMetricAnthropometryEntryInput> | EditMetricAnthropometryEntryInput;
}>;


export type EditMetricAnthropometryEntriesMutation = { readonly editMetricAnthropometryEntries: { readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> } };

export type EditMetricAnthropometryEntryMutationVariables = Exact<{
  input: EditMetricAnthropometryEntryInput;
}>;


export type EditMetricAnthropometryEntryMutation = { readonly editMetricAnthropometryEntry: { readonly anthropometryEntry: AnthropometryEntryFragment } };

export type EditMetricAthletesMutationVariables = Exact<{
  input: ReadonlyArray<EditAthleteAndMetricAnthroInput> | EditAthleteAndMetricAnthroInput;
}>;


export type EditMetricAthletesMutation = { readonly editMetricAthletes: { readonly athletes: ReadonlyArray<AthleteFragment> } };

export type EditMetricMacroProtocolMutationVariables = Exact<{
  input: EditMetricMacroProtocolInput;
}>;


export type EditMetricMacroProtocolMutation = { readonly editMetricMacroProtocol: { readonly macroProtocol: FullMacroProtocolFragment } };

export type EditNoteMutationVariables = Exact<{
  input: EditNoteInput;
}>;


export type EditNoteMutation = { readonly editNote: { readonly note: NoteWithTypeFragment } };

export type EditNoteTypeMutationVariables = Exact<{
  input: EditNoteTypeInput;
}>;


export type EditNoteTypeMutation = { readonly editNoteType: { readonly noteType: NoteTypeFragment } };

export type EditOrgFoodGroupMutationVariables = Exact<{
  input: EditFoodGroupInput;
}>;


export type EditOrgFoodGroupMutation = { readonly editOrgFoodGroup: { readonly foodGroup: FullFoodGroupFragment } };

export type EditOrgFoodServingMutationVariables = Exact<{
  input: EditFoodServingInput;
}>;


export type EditOrgFoodServingMutation = { readonly editOrgFoodServing: { readonly serving: ServingFragment } };

export type EditSharedFoodGroupMutationVariables = Exact<{
  input: EditFoodGroupInput;
}>;


export type EditSharedFoodGroupMutation = { readonly editSharedFoodGroup: { readonly foodGroup: FullFoodGroupFragment } };

export type CreateExchangeSetMutationVariables = Exact<{
  input: CreateExchangeSetInput;
}>;


export type CreateExchangeSetMutation = { readonly createExchangeSet: { readonly exchangeSet: FullExchangeSetFragment } };

export type EditExchangeSetMutationVariables = Exact<{
  input: EditExchangeSetInput;
}>;


export type EditExchangeSetMutation = { readonly editExchangeSet: { readonly exchangeSet: FullExchangeSetFragment } };

export type AddNixCommonFoodMutationVariables = Exact<{
  input: AddNixCommonFoodInput;
}>;


export type AddNixCommonFoodMutation = { readonly addNixCommonFood: { readonly food: Maybe<FoodWithDefaultServingBrandedFoodFragment | FoodWithDefaultServingGenericFoodFragment> } };

export type AddNixBrandedFoodMutationVariables = Exact<{
  input: AddNixBrandedFoodInput;
}>;


export type AddNixBrandedFoodMutation = { readonly addNixBrandedFood: { readonly food: Maybe<FoodWithDefaultServingBrandedFoodFragment | FoodWithDefaultServingGenericFoodFragment> } };

export type RemoveAthleteDislikedFoodMutationVariables = Exact<{
  input: AthleteFoodPreferenceInput;
}>;


export type RemoveAthleteDislikedFoodMutation = { readonly removeAthleteDislikedFood: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    ) } };

export type RemoveAthleteDislikedFoodGroupMutationVariables = Exact<{
  input: AthleteFoodGroupPreferenceInput;
}>;


export type RemoveAthleteDislikedFoodGroupMutation = { readonly removeAthleteDislikedFoodGroup: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
    ) } };

export type RemoveAthleteLikedFoodMutationVariables = Exact<{
  input: AthleteFoodPreferenceInput;
}>;


export type RemoveAthleteLikedFoodMutation = { readonly removeAthleteLikedFood: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    ) } };

export type RemoveAthleteLikedFoodGroupMutationVariables = Exact<{
  input: AthleteFoodGroupPreferenceInput;
}>;


export type RemoveAthleteLikedFoodGroupMutation = { readonly removeAthleteLikedFoodGroup: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly likedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
    ) } };

export type RemoveMealOptionMutationVariables = Exact<{
  input: RemoveMealOptionInput;
}>;


export type RemoveMealOptionMutation = { readonly removeMealOption: Pick<RemoveMealOptionPayload, 'mealOptionId'> };

export type RemoveMealOptionPendingMutationVariables = Exact<{
  input: RemoveMealOptionPendingInput;
}>;


export type RemoveMealOptionPendingMutation = { readonly removeMealOptionPending: Pick<RemoveMealOptionPendingPayload, 'mealOptionPendingId'> };

export type ShareMealOptionPendingMutationVariables = Exact<{
  input: ShareMealOptionPendingInput;
}>;


export type ShareMealOptionPendingMutation = { readonly shareMealOptionPending: { readonly mealOption: FullMealOptionFragment } };

export type TextMealPlanToAthleteMutationVariables = Exact<{
  input: TextMealPlanToAthleteInput;
}>;


export type TextMealPlanToAthleteMutation = { readonly textMealPlanToAthlete: Pick<TextMealPlanToAthletePayload, 'result'> };

export type TextMealPlansToAthletesMutationVariables = Exact<{
  input: TextMealPlansToAthletesInput;
}>;


export type TextMealPlansToAthletesMutation = { readonly textMealPlansToAthletes: Pick<TextMealPlansToAthletesPayload, 'result'> };

export type UnarchiveAthleteMutationVariables = Exact<{
  input: UnarchiveAthleteInput;
}>;


export type UnarchiveAthleteMutation = { readonly unarchiveAthlete: { readonly athlete: (
      { readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment>, readonly orgMembership: Maybe<Pick<OrgMembership, 'id' | 'isActive'>> }
      & AthleteWithGoalAndAnthroFragment
    ) } };

export type AddMealOptionLocalMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
}>;


export type AddMealOptionLocalMutation = Pick<Mutation, 'addMealOptionLocal'>;

export type CalculateExchangeTotalsMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type CalculateExchangeTotalsMutation = Pick<Mutation, 'calculateExchangeTotals'>;

export type ChangeExchangeTargetMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  exchangeId: Scalars['ID'];
  target: Scalars['String'];
  pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
}>;


export type ChangeExchangeTargetMutation = Pick<Mutation, 'changeExchangeTarget'>;

export type ChangeExchangeTargetTotalMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
  exchangeId: Scalars['ID'];
  target: Scalars['String'];
}>;


export type ChangeExchangeTargetTotalMutation = Pick<Mutation, 'changeExchangeTargetTotal'>;

export type ChangeMacroTargetMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  macro: MacroType;
  target: Scalars['String'];
}>;


export type ChangeMacroTargetMutation = Pick<Mutation, 'changeMacroTarget'>;

export type DeselectNegativeFoodsMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type DeselectNegativeFoodsMutation = Pick<Mutation, 'deselectNegativeFoods'>;

export type DistributeExchangeTargetsMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type DistributeExchangeTargetsMutation = Pick<Mutation, 'distributeExchangeTargets'>;

export type DistributeMacroTargetsMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type DistributeMacroTargetsMutation = Pick<Mutation, 'distributeMacroTargets'>;

export type EditMealOptionLocalMutationVariables = Exact<{
  input: EditMealOptionLocalInput;
}>;


export type EditMealOptionLocalMutation = Pick<Mutation, 'editMealOptionLocal'>;

export type EditMealOptionPendingLocalMutationVariables = Exact<{
  input: EditMealOptionPendingLocalInput;
}>;


export type EditMealOptionPendingLocalMutation = Pick<Mutation, 'editMealOptionPendingLocal'>;

export type RemoveMealOptionLocalMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionId: Scalars['ID'];
}>;


export type RemoveMealOptionLocalMutation = Pick<Mutation, 'removeMealOptionLocal'>;

export type RemoveMealOptionPendingLocalMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionPendingId: Scalars['ID'];
}>;


export type RemoveMealOptionPendingLocalMutation = Pick<Mutation, 'removeMealOptionPendingLocal'>;

export type SelectMealOptionMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionId: Scalars['ID'];
}>;


export type SelectMealOptionMutation = Pick<Mutation, 'selectMealOption'>;

export type ShareMealOptionPendingLocalMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  mealOptionPendingId: Scalars['ID'];
}>;


export type ShareMealOptionPendingLocalMutation = Pick<Mutation, 'shareMealOptionPendingLocal'>;

export type AllAthletesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAthletesQuery = { readonly athletes: ReadonlyArray<(
    { readonly team: TeamWithSportFragment, readonly position: Maybe<PositionFragment> }
    & AthleteWithAnthroFragment
  )> };

export type AthleteWithAnthroFragment = (
  { readonly position: Maybe<PositionFragment>, readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment> }
  & AthleteFragment
);

export type AllAthletesAndAnthropometryEntriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAthletesAndAnthropometryEntriesQuery = { readonly athletes: ReadonlyArray<(
    { readonly mostRecentAnthropometryEntry: Maybe<Pick<AnthropometryEntry, 'id' | 'height'>>, readonly team: TeamWithSportFragment, readonly position: Maybe<PositionFragment>, readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> }
    & AthleteFragment
  )> };

export type AthleteAnthropometryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AthleteAnthropometryQuery = { readonly athlete: (
    { readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> }
    & AthleteFragment
  ) };

export type AthleteNotesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AthleteNotesQuery = { readonly athlete: (
    { readonly notes: ReadonlyArray<NoteWithTypeFragment> }
    & AthleteFragment
  ) };

export type AthletesMealPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type AthletesMealPlansQuery = { readonly athletes: ReadonlyArray<AthleteWithMealPlansFragment> };

export type MealPlanWithAthleteFragment = (
  { readonly athlete: AthleteFragment }
  & MealPlanSearchFormFragment
);

export type MealPlanForCopyCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  athleteName: Maybe<Scalars['String']>;
  mealPlanName: Maybe<Scalars['String']>;
}>;


export type MealPlanForCopyCursorConnectionQuery = { readonly mealPlanForCopyCursorConnection: { readonly edges: ReadonlyArray<MealPlanWithAthleteFragment>, readonly pageInfo: Pick<CursorPageInfo, 'endCursor' | 'hasNextPage'> } };

export type AthletesPreviewQueryVariables = Exact<{ [key: string]: never; }>;


export type AthletesPreviewQuery = { readonly athletes: ReadonlyArray<AthleteFragment> };

export type AthleteSearchQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type AthleteSearchQuery = { readonly athleteSearch: ReadonlyArray<AthleteFragment> };

export type TeamAthletesSelectFieldQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type TeamAthletesSelectFieldQuery = { readonly team: { readonly athletes: ReadonlyArray<AthleteFragment> } };

export type BrandsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type BrandsQuery = { readonly brands: ReadonlyArray<BrandFragment> };

export type GetEditMealPlanQueryVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type GetEditMealPlanQuery = { readonly mealPlan: EditFullMealPlanFragment };

export type ExchangeSetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ExchangeSetQuery = { readonly exchangeSet: FullExchangeSetFragment };

export type ExchangeSetsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExchangeSetsQuery = { readonly exchangeSets: ReadonlyArray<ExchangeSetWithExchangesFragment> };

export type FoodGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FoodGroupQuery = { readonly foodGroup: FullFoodGroupWithFoodsFragment };

export type FoodGroupsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type FoodGroupsQuery = { readonly foodGroups: ReadonlyArray<SimpleFoodGroupFragment> };

export type SharedFoodGroupsTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
  sortOrder: SortOrder;
}>;


export type SharedFoodGroupsTableQuery = { readonly sharedFoodGroupsOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<FullFoodGroupFragment> } };

export type OrgFoodGroupsTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
  sortOrder: SortOrder;
}>;


export type OrgFoodGroupsTableQuery = { readonly orgFoodGroupsOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<FullFoodGroupFragment> } };

export type SharedFoodGroupsCursorConnectionQueryVariables = Exact<{
  searchText: Scalars['String'];
  pagination: CursorPaginationInput;
}>;


export type SharedFoodGroupsCursorConnectionQuery = { readonly sharedFoodGroupsCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<SimpleFoodGroupFragment> } };

export type SimpleFoodGroupFragment = Pick<FoodGroup, 'id' | 'name'>;

export type FoodOrRecipeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FoodOrRecipeQuery = { readonly foodOrRecipe: FoodWithServingsBrandedFoodFragment | FoodWithServingsGenericFoodFragment | RecipeWithServingsFragment };

export type FoodsWithDefaultServingOnlyQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  localeCodes: Maybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  dataSources: Maybe<ReadonlyArray<FoodDataSource> | FoodDataSource>;
}>;


export type FoodsWithDefaultServingOnlyQuery = { readonly foods: ReadonlyArray<FoodWithDefaultServingBrandedFoodFragment | FoodWithDefaultServingGenericFoodFragment> };

export type RestaurantFoodsWithDefaultServingOnlyQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  restaurantId: Scalars['ID'];
}>;


export type RestaurantFoodsWithDefaultServingOnlyQuery = { readonly restaurantFoods: ReadonlyArray<FoodWithDefaultServingBrandedFoodFragment> };

export type GenericFoodsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type GenericFoodsQuery = { readonly genericFoods: ReadonlyArray<FoodGenericFoodFragment> };

export type GoalTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GoalTypesQuery = { readonly goalTypes: ReadonlyArray<GoalTypeFragment> };

export type GroceryListCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GroceryListCategoriesQuery = { readonly groceryListCategories: ReadonlyArray<Pick<GroceryListCategory, 'id' | 'name'>> };

export type MealPlanBulkCopyChooseAthletesContainerQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type MealPlanBulkCopyChooseAthletesContainerQuery = { readonly team: (
    { readonly athletes: ReadonlyArray<AthleteWithGoalAndAnthroFragment> }
    & TeamFragment
  ), readonly exchangeSets: ReadonlyArray<ExchangeSetFragment> };

export type MealPlanBulkCopyQueryVariables = Exact<{ [key: string]: never; }>;


export type MealPlanBulkCopyQuery = { readonly teams: ReadonlyArray<MealPlanBulkCopyTeamPreviewFragment>, readonly exchangeSets: ReadonlyArray<ExchangeSetFragment> };

export type MealPlanBulkCopyTeamPreviewFragment = Pick<Team, 'name' | 'id'>;

export type NoteTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type NoteTypesQuery = { readonly noteTypes: ReadonlyArray<NoteTypeFragment> };

export type OrgFoodGroupsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type OrgFoodGroupsQuery = { readonly orgFoodGroups: ReadonlyArray<FullFoodGroupFragment> };

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = Pick<Query, 'ping'>;

export type RecipesQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type RecipesQuery = { readonly recipes: ReadonlyArray<(
    Pick<Recipe, 'hasFullAccess'>
    & { readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly descendantFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    & RecipeWithServingsFragment
  )>, readonly orgGroupRecipes: ReadonlyArray<(
    Pick<Recipe, 'hasFullAccess'>
    & { readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly descendantFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly orgGroup: Maybe<Pick<OrgGroup, 'name'>> }
    & RecipeWithServingsFragment
  )> };

export type SchedulesDefaultAndTeamQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type SchedulesDefaultAndTeamQuery = { readonly schedules: ReadonlyArray<FullScheduleFragment>, readonly schedulesForTeam: ReadonlyArray<FullScheduleFragment> };

export type SharedFoodGroupsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type SharedFoodGroupsQuery = { readonly sharedFoodGroups: ReadonlyArray<FullFoodGroupFragment> };

export type SimpleFoodsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  localeCodes: Maybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  dataSources: Maybe<ReadonlyArray<FoodDataSource> | FoodDataSource>;
}>;


export type SimpleFoodsQuery = { readonly foods: ReadonlyArray<(
    { readonly __typename: 'BrandedFood' }
    & FoodBrandedFoodFragment
  ) | (
    { readonly __typename: 'GenericFood' }
    & FoodGenericFoodFragment
  )> };

export type SlackFoodRequestsUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type SlackFoodRequestsUrlQuery = Pick<Query, 'slackFoodRequestsUrl'>;

export type SportsQueryVariables = Exact<{ [key: string]: never; }>;


export type SportsQuery = { readonly sports: ReadonlyArray<FullSportFragment> };

export type TeamAnthropometryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamAnthropometryQuery = { readonly team: (
    { readonly athletes: ReadonlyArray<(
      { readonly position: Maybe<PositionFragment>, readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> }
      & AthleteFragment
    )> }
    & TeamWithSportFragment
  ) };

export type TeamRosterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamRosterQuery = { readonly team: (
    { readonly athletes: ReadonlyArray<(
      { readonly position: Maybe<PositionFragment> }
      & AthleteFragment
    )> }
    & TeamWithSportFragment
  ) };

export type NutritionixInstantSearchQueryVariables = Exact<{
  query: Scalars['String'];
  region: Scalars['Int'];
  localeCode: Scalars['String'];
}>;


export type NutritionixInstantSearchQuery = { readonly nutritionixBrandedInstantSearch: { readonly branded: ReadonlyArray<NutritionixBrandedFoodFragment> }, readonly nutritionixCommonInstantSearch: { readonly common: ReadonlyArray<NutritionixCommonFoodFragment> } };

export type NutritionixBrandedInstantSearchQueryVariables = Exact<{
  query: Scalars['String'];
  region: Scalars['Int'];
}>;


export type NutritionixBrandedInstantSearchQuery = { readonly nutritionixBrandedInstantSearch: { readonly branded: ReadonlyArray<NutritionixBrandedFoodFragment> } };

export type NutritionixV1RestaurantSearchQueryVariables = Exact<{
  input: NutritionixV1RestaurantSearchInput;
}>;


export type NutritionixV1RestaurantSearchQuery = { readonly nutritionixV1RestaurantSearch: (
    Pick<NutritionixV1RestaurantSearchPayload, 'total'>
    & { readonly branded: ReadonlyArray<NutritionixBrandedFoodFragment> }
  ) };

export type NutritionixCommonFoodFragment = (
  { readonly __typename: 'NutritionixCommonFood' }
  & Pick<NutritionixCommonFood, 'id' | 'name' | 'nixTagId' | 'thumbnailUrl'>
);

export type NutritionixBrandedFoodFragment = (
  { readonly __typename: 'NutritionixBrandedFood' }
  & Pick<NutritionixBrandedFood, 'id' | 'name' | 'nixItemId' | 'thumbnailUrl'>
  & { readonly brand: (
    { readonly __typename: 'NutritionixBrand' }
    & Pick<NutritionixBrand, 'id' | 'name' | 'type'>
  ) }
);

export type UsdaFoodDetailsQueryVariables = Exact<{
  usdaFdcId: Scalars['ID'];
  apiKey: Scalars['String'];
}>;


export type UsdaFoodDetailsQuery = { readonly usdaFoodDetail: UsdaFoodDetailFragment };

export type UsdaFoodsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  apiKey: Scalars['String'];
  brandOwner: Scalars['String'];
  includedDataTypes: Scalars['String'];
}>;


export type UsdaFoodsQuery = { readonly usdaFoods: ReadonlyArray<UsdaFoodFragment> };

type FoodOrRecipePreferencesBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'id' | 'name'>
);

type FoodOrRecipePreferencesGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'id' | 'name'>
);

type FoodOrRecipePreferencesRecipeFragment = (
  { readonly __typename: 'Recipe' }
  & Pick<Recipe, 'id' | 'name'>
  & { readonly descendantFoods: ReadonlyArray<Pick<BrandedFood, 'id'> | Pick<GenericFood, 'id'>> }
);

export type FoodOrRecipePreferencesFragment = FoodOrRecipePreferencesBrandedFoodFragment | FoodOrRecipePreferencesGenericFoodFragment | FoodOrRecipePreferencesRecipeFragment;

export type PublicMealPlanQueryVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type PublicMealPlanQuery = { readonly mealPlan: PublicMealPlanFragment };

export type PublicMealPlanFragment = (
  Pick<MealPlan, 'id' | 'name' | 'type'>
  & { readonly athlete: (
    Pick<Athlete, 'id' | 'birthDate'>
    & { readonly macroDisplaySettings: (
      { readonly __typename: 'MacroMealPlanDisplaySettings' }
      & Pick<MacroMealPlanDisplaySettings, 'dailyProgressViews' | 'mealProgressViews'>
    ), readonly exchangeDisplaySettings: (
      { readonly __typename: 'ExchangeMealPlanDisplaySettings' }
      & Pick<ExchangeMealPlanDisplaySettings, 'dailyProgressViewsForExchanges' | 'mealProgressViewsForExchanges' | 'dailyProgressViewsForMacros' | 'mealProgressViewsForMacros'>
    ) }
  ), readonly macroProtocol: (
    { readonly __typename: 'MacroProtocol' }
    & Pick<MacroProtocol, 'id' | 'weightTarget'>
    & { readonly cho: Pick<MacroMath, 'gPerKg' | 'leftOverRatio'>, readonly pro: Pick<MacroMath, 'gPerKg' | 'leftOverRatio'>, readonly fat: Pick<MacroMath, 'gPerKg' | 'leftOverRatio'>, readonly anthropometrySnapshot: (
      { readonly __typename: 'AnthropometryEntry' }
      & Pick<AnthropometryEntry, 'id' | 'height' | 'weight' | 'leanBodyMass' | 'bodyFatMass' | 'boneMineralDensityZScore' | 'percentBodyFat' | 'datetime' | 'type' | 'sex' | 'comment' | 'boneMineralDensity' | 'boneMineralDensityTScore' | 'boneMineralContent' | 'boneArea' | 'boneMass' | 'dryLeanMass' | 'skeletalMuscleMass' | 'trunkFat' | 'visceralFatArea'>
    ), readonly calorieBudget: Maybe<(
      { readonly __typename: 'CalorieBudget' }
      & Pick<CalorieBudget, 'id' | 'rmrMethod' | 'rmrCalories' | 'activityFactor' | 'kcalOffset'>
      & { readonly goalSnapshot: (
        { readonly __typename: 'Goal' }
        & Pick<Goal, 'id' | 'kcalOffset' | 'start' | 'end'>
        & { readonly type: (
          { readonly __typename: 'GoalType' }
          & Pick<GoalType, 'id' | 'name' | 'defaultKcalOffset'>
        ) }
      ) }
    )> }
  ), readonly exchangeTargets: Maybe<ReadonlyArray<(
    { readonly __typename: 'ExchangeAmount' }
    & Pick<ExchangeAmount, 'id' | 'amount'>
    & { readonly exchange: (
      { readonly __typename: 'Exchange' }
      & Pick<Exchange, 'id' | 'name' | 'shortName' | 'type' | 'cho' | 'pro' | 'fat'>
    ) }
  )>>, readonly exchangeSet: Maybe<(
    Pick<ExchangeSet, 'id' | 'name'>
    & { readonly exchanges: ReadonlyArray<(
      { readonly __typename: 'Exchange' }
      & Pick<Exchange, 'id' | 'type' | 'name' | 'shortName' | 'cho' | 'pro' | 'fat'>
      & { readonly exchangeServingList: (
        Pick<ExchangeServingList, 'id' | 'name'>
        & { readonly servingAmounts: ReadonlyArray<(
          Pick<ServingAmount, 'id' | 'amount' | 'position'>
          & { readonly serving: (
            { readonly __typename: 'Serving' }
            & Pick<Serving, 'id' | 'isRecipeServingOnly' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield' | 'units' | 'customUnits' | 'unitPrefix' | 'unitSuffix'>
            & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
              { readonly __typename: 'BrandedFood' }
              & Pick<BrandedFood, 'id' | 'name'>
            ) | (
              { readonly __typename: 'GenericFood' }
              & Pick<GenericFood, 'id' | 'name'>
            ) | (
              { readonly __typename: 'Recipe' }
              & Pick<Recipe, 'id' | 'name' | 'steps' | 'note' | 'prepTimeInMinutes' | 'cookTimeInMinutes' | 'isCurrent' | 'isShared' | 'mealTypes'>
              & { readonly images: ReadonlyArray<Pick<Image, 'id' | 'position' | 'url'>>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly descendantFoods: ReadonlyArray<Pick<BrandedFood, 'id'> | Pick<GenericFood, 'id'>>, readonly descendantRecipes: ReadonlyArray<(
                Pick<Recipe, 'id'>
                & { readonly ingredients: ReadonlyArray<(
                  Pick<ServingAmount, 'id' | 'amount' | 'position'>
                  & { readonly serving: (
                    Pick<Serving, 'id' | 'units' | 'isRecipeServingOnly' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield'>
                    & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
                      { readonly __typename: 'BrandedFood' }
                      & Pick<BrandedFood, 'id' | 'name'>
                    ) | (
                      { readonly __typename: 'GenericFood' }
                      & Pick<GenericFood, 'id' | 'name'>
                    ) | (
                      { readonly __typename: 'Recipe' }
                      & Pick<Recipe, 'id' | 'name'>
                    ) }
                  ) }
                )> }
              )>, readonly ingredients: ReadonlyArray<(
                Pick<ServingAmount, 'id' | 'amount' | 'position'>
                & { readonly serving: (
                  Pick<Serving, 'id' | 'units' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield'>
                  & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
                    { readonly __typename: 'BrandedFood' }
                    & Pick<BrandedFood, 'id' | 'name'>
                  ) | (
                    { readonly __typename: 'GenericFood' }
                    & Pick<GenericFood, 'id' | 'name'>
                  ) | (
                    { readonly __typename: 'Recipe' }
                    & Pick<Recipe, 'id' | 'name'>
                  ) }
                ) }
              )> }
            ) }
          ) }
        )> }
      ) }
    )> }
  )>, readonly mealTemplates: ReadonlyArray<PublicMealTemplateExchangeMealTemplateFragment | PublicMealTemplateMacroMealTemplateFragment>, readonly activityTemplates: ReadonlyArray<(
    { readonly __typename: 'ActivityTemplate' }
    & Pick<ActivityTemplate, 'id'>
    & { readonly activity: (
      { readonly __typename: 'Activity' }
      & Pick<Activity, 'id' | 'name' | 'start' | 'end' | 'type'>
    ) }
  )> }
);

type PublicMealTemplateExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly meal: (
    { readonly __typename: 'Meal' }
    & Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'>
  ), readonly exchangeTargets: ReadonlyArray<(
    { readonly __typename: 'ExchangeAmount' }
    & Pick<ExchangeAmount, 'id' | 'amount' | 'pickListServingIds'>
    & { readonly exchange: (
      { readonly __typename: 'Exchange' }
      & Pick<Exchange, 'id' | 'type' | 'name' | 'shortName' | 'cho' | 'pro' | 'fat'>
    ) }
  )>, readonly mealOptions: ReadonlyArray<(
    { readonly __typename: 'MealOption' }
    & Pick<MealOption, 'id' | 'position' | 'note'>
    & { readonly servingAmounts: ReadonlyArray<(
      Pick<ServingAmount, 'id' | 'amount' | 'position'>
      & { readonly serving: (
        { readonly __typename: 'Serving' }
        & Pick<Serving, 'id' | 'units' | 'isRecipeServingOnly' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield'>
        & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
          { readonly __typename: 'BrandedFood' }
          & Pick<BrandedFood, 'id' | 'name' | 'usdaFdcDescription'>
        ) | (
          { readonly __typename: 'GenericFood' }
          & Pick<GenericFood, 'id' | 'name' | 'usdaFdcDescription'>
        ) | (
          { readonly __typename: 'Recipe' }
          & Pick<Recipe, 'id' | 'name' | 'steps' | 'note' | 'exchangeTypes' | 'prepTimeInMinutes' | 'cookTimeInMinutes' | 'isCurrent' | 'isShared' | 'mealTypes'>
          & { readonly images: ReadonlyArray<Pick<Image, 'id' | 'position' | 'url'>>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly descendantFoods: ReadonlyArray<Pick<BrandedFood, 'id'> | Pick<GenericFood, 'id'>>, readonly descendantRecipes: ReadonlyArray<(
            Pick<Recipe, 'id'>
            & { readonly ingredients: ReadonlyArray<(
              Pick<ServingAmount, 'id' | 'amount' | 'position'>
              & { readonly serving: (
                Pick<Serving, 'id' | 'units' | 'isRecipeServingOnly' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield'>
                & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
                  { readonly __typename: 'BrandedFood' }
                  & Pick<BrandedFood, 'id' | 'name'>
                ) | (
                  { readonly __typename: 'GenericFood' }
                  & Pick<GenericFood, 'id' | 'name'>
                ) | (
                  { readonly __typename: 'Recipe' }
                  & Pick<Recipe, 'id' | 'name'>
                ) }
              ) }
            )> }
          )>, readonly ingredients: ReadonlyArray<(
            Pick<ServingAmount, 'id' | 'amount' | 'position'>
            & { readonly serving: (
              { readonly __typename: 'Serving' }
              & Pick<Serving, 'id' | 'isRecipeServingOnly' | 'units' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield'>
              & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
                { readonly __typename: 'BrandedFood' }
                & Pick<BrandedFood, 'id' | 'name'>
              ) | (
                { readonly __typename: 'GenericFood' }
                & Pick<GenericFood, 'id' | 'name'>
              ) | (
                { readonly __typename: 'Recipe' }
                & Pick<Recipe, 'id' | 'name'>
              ) }
            ) }
          )> }
        ) }
      ) }
    )> }
  )> }
);

type PublicMealTemplateMacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'id' | 'cho' | 'pro' | 'fat' | 'kcal'>
  & { readonly meal: (
    { readonly __typename: 'Meal' }
    & Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'>
  ), readonly mealOptions: ReadonlyArray<(
    { readonly __typename: 'MealOption' }
    & Pick<MealOption, 'id' | 'position' | 'note'>
    & { readonly servingAmounts: ReadonlyArray<(
      Pick<ServingAmount, 'id' | 'amount' | 'position'>
      & { readonly serving: (
        { readonly __typename: 'Serving' }
        & Pick<Serving, 'id' | 'units' | 'isRecipeServingOnly' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield'>
        & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
          { readonly __typename: 'BrandedFood' }
          & Pick<BrandedFood, 'id' | 'name' | 'usdaFdcDescription'>
        ) | (
          { readonly __typename: 'GenericFood' }
          & Pick<GenericFood, 'id' | 'name' | 'usdaFdcDescription'>
        ) | (
          { readonly __typename: 'Recipe' }
          & Pick<Recipe, 'id' | 'name' | 'steps' | 'note' | 'exchangeTypes' | 'prepTimeInMinutes' | 'cookTimeInMinutes' | 'isCurrent' | 'isShared' | 'mealTypes'>
          & { readonly images: ReadonlyArray<Pick<Image, 'id' | 'position' | 'url'>>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly descendantFoods: ReadonlyArray<Pick<BrandedFood, 'id'> | Pick<GenericFood, 'id'>>, readonly descendantRecipes: ReadonlyArray<(
            Pick<Recipe, 'id'>
            & { readonly ingredients: ReadonlyArray<(
              Pick<ServingAmount, 'id' | 'amount' | 'position'>
              & { readonly serving: (
                Pick<Serving, 'id' | 'units' | 'isRecipeServingOnly' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield'>
                & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
                  { readonly __typename: 'BrandedFood' }
                  & Pick<BrandedFood, 'id' | 'name'>
                ) | (
                  { readonly __typename: 'GenericFood' }
                  & Pick<GenericFood, 'id' | 'name'>
                ) | (
                  { readonly __typename: 'Recipe' }
                  & Pick<Recipe, 'id' | 'name'>
                ) }
              ) }
            )> }
          )>, readonly ingredients: ReadonlyArray<(
            Pick<ServingAmount, 'id' | 'amount' | 'position'>
            & { readonly serving: (
              { readonly __typename: 'Serving' }
              & Pick<Serving, 'id' | 'isRecipeServingOnly' | 'units' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield'>
              & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
                { readonly __typename: 'BrandedFood' }
                & Pick<BrandedFood, 'id' | 'name'>
              ) | (
                { readonly __typename: 'GenericFood' }
                & Pick<GenericFood, 'id' | 'name'>
              ) | (
                { readonly __typename: 'Recipe' }
                & Pick<Recipe, 'id' | 'name'>
              ) }
            ) }
          )> }
        ) }
      ) }
    )> }
  )> }
);

export type PublicMealTemplateFragment = PublicMealTemplateExchangeMealTemplateFragment | PublicMealTemplateMacroMealTemplateFragment;

export const TeamsPickerTeamFragmentDoc = gql`
    fragment TeamsPickerTeam on Team {
  id
  name
}
    `;
export const DisplaySettingsFormMacroMealPlanDisplaySettingsFragmentDoc = gql`
    fragment DisplaySettingsFormMacroMealPlanDisplaySettings on MacroMealPlanDisplaySettings {
  __typename
  dailyProgressViews
  mealProgressViews
}
    `;
export const DisplaySettingsFormExchangeMealPlanDisplaySettingsFragmentDoc = gql`
    fragment DisplaySettingsFormExchangeMealPlanDisplaySettings on ExchangeMealPlanDisplaySettings {
  __typename
  dailyProgressViewsForExchanges
  mealProgressViewsForExchanges
  dailyProgressViewsForMacros
  mealProgressViewsForMacros
}
    `;
export const DisplaySettingsFormNoTargetsDisplaySettingsFragmentDoc = gql`
    fragment DisplaySettingsFormNoTargetsDisplaySettings on NoTargetsDisplaySettings {
  __typename
  dailyProgressViewsForMacros
  mealProgressViewsForMacros
}
    `;
export const FoodFragmentDoc = gql`
    fragment Food on Food {
  id
  __typename
  name
  exchangeTypes
  nixItemId
  nixTagId
  usdaFdcDescription
  usdaFdcFoodCategoryId
  usdaFdcDataType
  usdaFdcId
  isRecipeIngredientOnly
  choPer100g
  proPer100g
  fatPer100g
  kcalPer100g
  thumbnailUrl
  locales
}
    `;
export const BrandFragmentDoc = gql`
    fragment Brand on Brand {
  id
  __typename
  name
  usdaManufacturerName
  foodCount
}
    `;
export const ServingFragmentDoc = gql`
    fragment Serving on Serving {
  id
  __typename
  units
  defaultAmount
  isDefault
  weight
  usdaWeightSeq
  perRecipeYield
  isRecipeServingOnly
  hasOwnNutrients
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  id
  position
  url
}
    `;
export const ScoringSystemAssignmentFragmentDoc = gql`
    fragment ScoringSystemAssignment on ScoringSystem {
  name
  id
}
    `;
export const ScoreAssignmentFragmentDoc = gql`
    fragment ScoreAssignment on Score {
  value
  scoringSystem {
    ...ScoringSystemAssignment
  }
}
    ${ScoringSystemAssignmentFragmentDoc}`;
export const FoodLimitedAccessFragmentDoc = gql`
    fragment FoodLimitedAccess on Food {
  id
  __typename
  name
  usdaFdcDescription
  exchangeTypes
  thumbnailUrl
  hasFullAccess
  nixItemId
  nixTagId
  org {
    id
    name
  }
  ... on BrandedFood {
    brand {
      id
      name
    }
  }
}
    `;
export const RecipeIngredientServingFragmentDoc = gql`
    fragment RecipeIngredientServing on Serving {
  id
  __typename
  defaultAmount
  isDefault
  weight
  perRecipeYield
  hasOwnNutrients
  macros {
    cho
    pro
    fat
    kcal
  }
  units
  unit {
    id
    name
  }
  foodOrRecipe {
    ... on Food {
      ...FoodLimitedAccess
    }
    ... on Recipe {
      __typename
      id
      name
      exchangeTypes
      isCurrent
      images {
        ...Image
      }
      descendantFoods {
        id
      }
      createdBy {
        id
        firstName
        lastName
      }
      hasFullAccess
      org {
        id
        name
      }
      score {
        ...ScoreAssignment
      }
    }
  }
}
    ${FoodLimitedAccessFragmentDoc}
${ImageFragmentDoc}
${ScoreAssignmentFragmentDoc}`;
export const RecipeIngredientServingAmountFragmentDoc = gql`
    fragment RecipeIngredientServingAmount on ServingAmount {
  id
  amount
  position
  serving {
    ...RecipeIngredientServing
  }
}
    ${RecipeIngredientServingFragmentDoc}`;
export const RecipeLimitedAccessFragmentDoc = gql`
    fragment RecipeLimitedAccess on Recipe {
  id
  __typename
  name
  exchangeTypes
  isCurrent
  images {
    ...Image
  }
  descendantFoods {
    id
    name
    nixItemId
    nixTagId
  }
  createdBy {
    id
    firstName
    lastName
  }
  hasFullAccess
  org {
    id
    name
  }
  score {
    ...ScoreAssignment
  }
  ingredients {
    ...RecipeIngredientServingAmount
  }
}
    ${ImageFragmentDoc}
${ScoreAssignmentFragmentDoc}
${RecipeIngredientServingAmountFragmentDoc}`;
export const ServingWithFoodOrRecipeFragmentDoc = gql`
    fragment ServingWithFoodOrRecipe on Serving {
  ...Serving
  macros {
    cho
    pro
    fat
    kcal
  }
  customUnits
  unitPrefix
  unitSuffix
  unit {
    id
    name
  }
  foodOrRecipe {
    ... on Food {
      ...Food
      hasFullAccess
      org {
        id
        name
      }
      ... on BrandedFood {
        brand {
          id
          name
        }
      }
    }
    ... on Recipe {
      ...RecipeLimitedAccess
    }
  }
}
    ${ServingFragmentDoc}
${FoodFragmentDoc}
${RecipeLimitedAccessFragmentDoc}`;
export const NutrientAmountFragmentDoc = gql`
    fragment NutrientAmount on NutrientAmount {
  amount
  nutrient {
    id
    name
  }
}
    `;
export const OrgEditableFoodFragmentDoc = gql`
    fragment OrgEditableFood on Food {
  ...Food
  ... on BrandedFood {
    __typename
    brand {
      ...Brand
    }
  }
  ... on GenericFood {
    __typename
    owner {
      id
      firstName
      lastName
    }
    orgGroup {
      id
      name
    }
    org {
      id
      name
    }
  }
  servings {
    ...ServingWithFoodOrRecipe
    customUnits
    unit {
      id
      name
    }
    unitPrefix
    unitSuffix
  }
  createdBy {
    firstName
    lastName
  }
  nutrientAmounts {
    ...NutrientAmount
  }
  groceryListCategory {
    id
    name
  }
  excludeFromSuggestions
  source
  locales
  updatedAt
  foodGroups {
    id
    name
  }
}
    ${FoodFragmentDoc}
${BrandFragmentDoc}
${ServingWithFoodOrRecipeFragmentDoc}
${NutrientAmountFragmentDoc}`;
export const SharedEditableFoodFragmentDoc = gql`
    fragment SharedEditableFood on Food {
  ...Food
  ... on BrandedFood {
    __typename
    brand {
      ...Brand
    }
  }
  ... on GenericFood {
    __typename
  }
  servings {
    ...ServingWithFoodOrRecipe
    customUnits
    unit {
      id
      name
    }
    unitPrefix
    unitSuffix
  }
  nutrientAmounts {
    ...NutrientAmount
  }
  groceryListCategory {
    id
    name
  }
  excludeFromSuggestions
  source
  locales
  updatedAt
  foodGroups {
    id
    name
  }
}
    ${FoodFragmentDoc}
${BrandFragmentDoc}
${ServingWithFoodOrRecipeFragmentDoc}
${NutrientAmountFragmentDoc}`;
export const FoodOwnerPreviewFragmentDoc = gql`
    fragment FoodOwnerPreview on User {
  id
  firstName
  lastName
}
    `;
export const CompassRecipeFragmentDoc = gql`
    fragment CompassRecipe on Compass_Recipe {
  name
  shortName
  masterRefNumber
}
    `;
export const RecipeFoodCategoryFragmentDoc = gql`
    fragment RecipeFoodCategory on RecipeFoodCategory {
  category
}
    `;
export const RecipeFragmentDoc = gql`
    fragment Recipe on Recipe {
  id
  __typename
  name
  steps
  note
  exchangeTypes
  cho
  pro
  fat
  prepTimeInMinutes
  cookTimeInMinutes
  isCurrent
  images {
    ...Image
  }
  isShared
  createdBy {
    id
    firstName
    lastName
  }
  mealTypes
  score {
    ...ScoreAssignment
  }
  foodCategory {
    ...RecipeFoodCategory
  }
}
    ${ImageFragmentDoc}
${ScoreAssignmentFragmentDoc}
${RecipeFoodCategoryFragmentDoc}`;
export const ServingAmountWithFoodOrRecipeFragmentDoc = gql`
    fragment ServingAmountWithFoodOrRecipe on ServingAmount {
  id
  __typename
  amount
  position
  serving {
    ...ServingWithFoodOrRecipe
  }
}
    ${ServingWithFoodOrRecipeFragmentDoc}`;
export const RecipeWithIngredientsFragmentDoc = gql`
    fragment RecipeWithIngredients on Recipe {
  ...Recipe
  ingredients {
    ...ServingAmountWithFoodOrRecipe
  }
}
    ${RecipeFragmentDoc}
${ServingAmountWithFoodOrRecipeFragmentDoc}`;
export const RecipeWithServingsFragmentDoc = gql`
    fragment RecipeWithServings on Recipe {
  ...RecipeWithIngredients
  hasFullAccess
  descendantRecipes {
    ...RecipeWithIngredients
  }
  servings {
    ...ServingWithFoodOrRecipe
  }
}
    ${RecipeWithIngredientsFragmentDoc}
${ServingWithFoodOrRecipeFragmentDoc}`;
export const RecipeSearchFragmentDoc = gql`
    fragment RecipeSearch on Recipe {
  ...RecipeWithServings
  hasFullAccess
  org {
    id
    name
  }
  descendantFoods {
    ...Food
  }
}
    ${RecipeWithServingsFragmentDoc}
${FoodFragmentDoc}`;
export const LocaleFragmentDoc = gql`
    fragment Locale on Locale {
  id
  code
}
    `;
export const FullServingFragmentDoc = gql`
    fragment FullServing on Serving {
  id
  __typename
  units
  defaultAmount
  isDefault
  weight
  perRecipeYield
  hasOwnNutrients
  macros {
    cho
    pro
    fat
    kcal
  }
  unit {
    id
    name
  }
  foodOrRecipe {
    ... on Food {
      ...FoodLimitedAccess
    }
    ... on Recipe {
      ...RecipeLimitedAccess
    }
  }
}
    ${FoodLimitedAccessFragmentDoc}
${RecipeLimitedAccessFragmentDoc}`;
export const FullServingAmountFragmentDoc = gql`
    fragment FullServingAmount on ServingAmount {
  id
  __typename
  amount
  position
  serving {
    ...FullServing
  }
}
    ${FullServingFragmentDoc}`;
export const FullServingAmountGroupFragmentDoc = gql`
    fragment FullServingAmountGroup on ServingAmountGroup {
  servingAmounts {
    ...FullServingAmount
  }
}
    ${FullServingAmountFragmentDoc}`;
export const MealTemplateWithOptionsFragmentDoc = gql`
    fragment MealTemplateWithOptions on MealTemplate {
  id
  mealOptions {
    id
  }
}
    `;
export const NamedTagForSelectionFragmentDoc = gql`
    fragment NamedTagForSelection on NamedTag {
  tagName {
    name
    color
  }
  tag {
    id
    __typename
  }
}
    `;
export const AnthropometryEntryFragmentDoc = gql`
    fragment AnthropometryEntry on AnthropometryEntry {
  id
  __typename
  height
  weight
  leanBodyMass
  bodyFatMass
  percentBodyFat
  datetime
  type
  sex
  comment
  boneMineralDensity
  boneMineralDensityZScore
  boneMineralDensityTScore
  boneMineralContent
  boneArea
  boneMass
  dryLeanMass
  skeletalMuscleMass
  trunkFat
  visceralFatArea
  heightInCm
  weightInKg
  leanBodyMassInKg
  bodyFatMassInKg
  skeletalMuscleMassInKg
  trunkFatInKg
  dryLeanMassInKg
}
    `;
export const AthleteForMealPlanTemplateAssignmentFragmentDoc = gql`
    fragment AthleteForMealPlanTemplateAssignment on Athlete {
  id
  firstName
  lastName
  myNamedTagsOffsetConnection(input: {limit: 2, offset: 0}) {
    edges {
      ...NamedTagForSelection
    }
    pageInfo {
      total
    }
  }
  team {
    id
    name
  }
  mostRecentAnthropometryEntryForRmrMethod(rmrMethod: $rmrMethod) {
    ...AnthropometryEntry
  }
}
    ${NamedTagForSelectionFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export const ScheduleWithTeamFragmentDoc = gql`
    fragment ScheduleWithTeam on Schedule {
  id
  name
  team {
    id
    name
  }
  meals {
    id
    name
    start
    end
    type
  }
  activities {
    id
    name
    start
    end
    type
  }
}
    `;
export const FoodOrRecipePreferencesFragmentDoc = gql`
    fragment FoodOrRecipePreferences on FoodOrRecipe {
  ... on Food {
    __typename
    id
    name
  }
  ... on Recipe {
    __typename
    id
    name
    descendantFoods {
      id
    }
  }
}
    `;
export const FoodCategoryNameFragmentDoc = gql`
    fragment FoodCategoryName on MenuItemFoodCategory {
  category
}
    `;
export const MenuItemPreviewFragmentDoc = gql`
    fragment MenuItemPreview on MenuItem {
  id
  __typename
  name
  servingName
  description
  imageUrl
  isOneOff
  defaultAvailableForOrder
  defaultAllowSpecialRequests
  defaultMaxAmount
  isDeleted
  servingAmounts {
    id
    serving {
      id
      foodOrRecipe {
        ...FoodOrRecipePreferences
      }
    }
  }
  score {
    ...ScoreAssignment
  }
  foodCategory {
    ...FoodCategoryName
  }
}
    ${FoodOrRecipePreferencesFragmentDoc}
${ScoreAssignmentFragmentDoc}
${FoodCategoryNameFragmentDoc}`;
export const PrintableNutrientInfoMenuItemFragmentDoc = gql`
    fragment PrintableNutrientInfoMenuItem on MenuItem {
  ...MenuItemPreview
  servingAmounts {
    ...FullServingAmount
  }
  choices {
    id
    options {
      id
      servingAmounts {
        id
      }
    }
  }
}
    ${MenuItemPreviewFragmentDoc}
${FullServingAmountFragmentDoc}`;
export const MenuItemPreviewSearchFragmentDoc = gql`
    fragment MenuItemPreviewSearch on MenuItem {
  ...MenuItemPreview
  isMissingIngredients
}
    ${MenuItemPreviewFragmentDoc}`;
export const MenuItemChoiceOptionFormFragmentDoc = gql`
    fragment MenuItemChoiceOptionForm on MenuItemChoiceOption {
  id
  name
  position
  canEditAmount
  defaultAmount
  maxAmount
  servingAmounts {
    ...FullServingAmount
  }
}
    ${FullServingAmountFragmentDoc}`;
export const MenuItemChoiceFormFragmentDoc = gql`
    fragment MenuItemChoiceForm on MenuItemChoice {
  id
  name
  position
  required
  maxOptionsCount
  options {
    ...MenuItemChoiceOptionForm
  }
}
    ${MenuItemChoiceOptionFormFragmentDoc}`;
export const MenuItemFormFragmentDoc = gql`
    fragment MenuItemForm on MenuItem {
  id
  __typename
  name
  servingName
  description
  isOneOff
  servingAmounts {
    ...FullServingAmount
  }
  choices {
    ...MenuItemChoiceForm
  }
  imageUrl
  suggestionCategory
  defaultMaxAmount
  defaultAvailableForOrder
  defaultAllowSpecialRequests
  isDeleted
  score {
    ...ScoreAssignment
  }
  foodCategory {
    ...FoodCategoryName
  }
}
    ${FullServingAmountFragmentDoc}
${MenuItemChoiceFormFragmentDoc}
${ScoreAssignmentFragmentDoc}
${FoodCategoryNameFragmentDoc}`;
export const MenuItemAppearanceFormFragmentDoc = gql`
    fragment MenuItemAppearanceForm on MenuItemAppearance {
  id
  position
  maxAmount
  availableForOrder
  allowSpecialRequests
  menuItem {
    ...MenuItemForm
  }
}
    ${MenuItemFormFragmentDoc}`;
export const RecentMenuOrderFragmentDoc = gql`
    fragment RecentMenuOrder on MenuOrder {
  items {
    amount
    percentConsumed
    menuItem {
      ...MenuItemForm
    }
    options {
      amount
      percentConsumed
      menuItemChoiceOption {
        ...MenuItemChoiceOptionForm
      }
    }
  }
}
    ${MenuItemFormFragmentDoc}
${MenuItemChoiceOptionFormFragmentDoc}`;
export const MacroMathFragmentDoc = gql`
    fragment MacroMath on MacroMath {
  gPerKg
  leftOverRatio
}
    `;
export const MacroProtocolFragmentDoc = gql`
    fragment MacroProtocol on MacroProtocol {
  id
  __typename
  cho {
    ...MacroMath
  }
  pro {
    ...MacroMath
  }
  fat {
    ...MacroMath
  }
  weightTarget
  weightTargetInKg
}
    ${MacroMathFragmentDoc}`;
export const CalorieBudgetFragmentDoc = gql`
    fragment CalorieBudget on CalorieBudget {
  id
  __typename
  rmrMethod
  rmrCalories
  activityFactor
  kcalOffset
}
    `;
export const GoalFragmentDoc = gql`
    fragment Goal on Goal {
  id
  __typename
  kcalOffset
  start
  end
}
    `;
export const GoalTypeFragmentDoc = gql`
    fragment GoalType on GoalType {
  id
  __typename
  name
  defaultKcalOffset
}
    `;
export const FullGoalFragmentDoc = gql`
    fragment FullGoal on Goal {
  ...Goal
  type {
    ...GoalType
  }
}
    ${GoalFragmentDoc}
${GoalTypeFragmentDoc}`;
export const FullCalorieBudgetFragmentDoc = gql`
    fragment FullCalorieBudget on CalorieBudget {
  ...CalorieBudget
  goalSnapshot {
    ...FullGoal
  }
}
    ${CalorieBudgetFragmentDoc}
${FullGoalFragmentDoc}`;
export const FullMacroProtocolFragmentDoc = gql`
    fragment FullMacroProtocol on MacroProtocol {
  ...MacroProtocol
  anthropometrySnapshot {
    ...AnthropometryEntry
  }
  calorieBudget {
    ...FullCalorieBudget
  }
}
    ${MacroProtocolFragmentDoc}
${AnthropometryEntryFragmentDoc}
${FullCalorieBudgetFragmentDoc}`;
export const ExchangeFragmentDoc = gql`
    fragment Exchange on Exchange {
  id
  __typename
  name
  shortName
  type
  cho
  pro
  fat
  kcal
}
    `;
export const ExchangeAmountFragmentDoc = gql`
    fragment ExchangeAmount on ExchangeAmount {
  id
  __typename
  amount
  pickListServingIds
  exchange {
    ...Exchange
  }
}
    ${ExchangeFragmentDoc}`;
export const EditExchangeAmountFragmentDoc = gql`
    fragment EditExchangeAmount on ExchangeAmount {
  ...ExchangeAmount
  amountInput @client
}
    ${ExchangeAmountFragmentDoc}`;
export const MealPlanPreviewItemFragmentDoc = gql`
    fragment MealPlanPreviewItem on MealPlan {
  id
  name
  type
  startDate
  dates
  endDate
  isShared
  createdAt
  macroProtocol {
    ...FullMacroProtocol
  }
  exchangeTargets {
    ...EditExchangeAmount
  }
}
    ${FullMacroProtocolFragmentDoc}
${EditExchangeAmountFragmentDoc}`;
export const FullFoodGroupWithFoodsFragmentDoc = gql`
    fragment FullFoodGroupWithFoods on FoodGroup {
  id
  __typename
  name
  source
  foodsCount
  foods {
    ...FoodLimitedAccess
  }
}
    ${FoodLimitedAccessFragmentDoc}`;
export const AthleteTimelineFragmentDoc = gql`
    fragment AthleteTimeline on Athlete {
  id
  birthDate
  sex
  sharedMealPlans {
    ...MealPlanPreviewItem
  }
  likedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  dislikedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  likedFoods {
    ...Food
  }
  dislikedFoods {
    ...Food
  }
  user {
    id
  }
  team {
    id
  }
}
    ${MealPlanPreviewItemFragmentDoc}
${FullFoodGroupWithFoodsFragmentDoc}
${FoodFragmentDoc}`;
export const RecipeFullFragmentDoc = gql`
    fragment RecipeFull on Recipe {
  ...Recipe
  hasFullAccess
  org {
    id
    name
  }
  descendantFoods {
    ...Food
  }
  descendantRecipes {
    ...RecipeWithIngredients
  }
  ingredients {
    ...FullServingAmount
  }
  servings {
    ...FullServing
    customUnits
    unit {
      id
      name
    }
    unitPrefix
    unitSuffix
  }
  owner {
    id
  }
  orgGroup {
    id
  }
  isAiGenerated
  isAiTranscribed
}
    ${RecipeFragmentDoc}
${FoodFragmentDoc}
${RecipeWithIngredientsFragmentDoc}
${FullServingAmountFragmentDoc}
${FullServingFragmentDoc}`;
export const UnmatchedIngredientFragmentDoc = gql`
    fragment UnmatchedIngredient on UnmatchedIngredient {
  amount
  foodName
  servingName
}
    `;
export const GptRecipeFragmentDoc = gql`
    fragment GptRecipe on GPTRecipePayload {
  name
  servingYield {
    amount
    name
  }
  steps
  ingredients {
    serving {
      ...FullServing
    }
    position
    amount
  }
  unmatchedIngredients {
    unmatchedName {
      ...UnmatchedIngredient
    }
    unmatchedServings {
      ...UnmatchedIngredient
    }
  }
  aiType
}
    ${FullServingFragmentDoc}
${UnmatchedIngredientFragmentDoc}`;
export const RestaurantLocationPreviewFragmentDoc = gql`
    fragment RestaurantLocationPreview on RestaurantLocation {
  id
  email
  phoneNumber
  notes
}
    `;
export const RestaurantLocationSearchResultFragmentDoc = gql`
    fragment RestaurantLocationSearchResult on RestaurantLocationSearchResult {
  restaurant {
    id
    name
    restaurantType {
      id
      name
    }
  }
  restaurantLocation {
    ...RestaurantLocationPreview
    googlePlaceId
    address {
      displayName
    }
  }
  restaurantMenu {
    id
  }
}
    ${RestaurantLocationPreviewFragmentDoc}`;
export const RestaurantMenuSearchResultFragmentDoc = gql`
    fragment RestaurantMenuSearchResult on RestaurantMenuSearchResult {
  restaurant {
    id
    name
    restaurantType {
      id
      name
    }
  }
  restaurantMenu {
    id
  }
}
    `;
export const MealFragmentDoc = gql`
    fragment Meal on Meal {
  id
  __typename
  name
  start
  end
  type
}
    `;
export const ActivityFragmentDoc = gql`
    fragment Activity on Activity {
  id
  __typename
  name
  start
  end
  type
}
    `;
export const ScheduleBasicFragmentDoc = gql`
    fragment ScheduleBasic on Schedule {
  id
  name
  meals {
    ...Meal
  }
  activities {
    ...Activity
  }
}
    ${MealFragmentDoc}
${ActivityFragmentDoc}`;
export const UnitFragmentDoc = gql`
    fragment Unit on Unit {
  id
  name
}
    `;
export const UnitConversionFullFragmentDoc = gql`
    fragment UnitConversionFull on UnitConversion {
  fromQuantity
  fromUnit {
    ...Unit
  }
  toQuantity
  toUnit {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;
export const ServingAmountForConversionFragmentDoc = gql`
    fragment ServingAmountForConversion on ServingAmount {
  __typename
  id
  amount
  position
  serving {
    id
    foodOrRecipe {
      ... on Food {
        id
        name
      }
      ... on Recipe {
        id
        name
      }
    }
    units
    customUnits
    unit {
      id
      name
    }
    unitPrefix
    unitSuffix
    weight
  }
}
    `;
export const TeamsForImportAthleteTeamFragmentDoc = gql`
    fragment TeamsForImportAthleteTeam on Team {
  id
  name
  gender
  sport {
    name
    positions {
      id
      name
    }
  }
}
    `;
export const ServingWithUnitsFragmentDoc = gql`
    fragment ServingWithUnits on Serving {
  customUnits
  unit {
    ...Unit
  }
  unitPrefix
  unitSuffix
}
    ${UnitFragmentDoc}`;
export const BulkImportFoodFragmentDoc = gql`
    fragment BulkImportFood on Food {
  ...Food
  ... on GenericFood {
    __typename
  }
  ... on BrandedFood {
    __typename
    brand {
      ...Brand
    }
  }
  nutrientAmounts {
    ...NutrientAmount
  }
  defaultServing {
    ...ServingWithUnits
    ...FullServing
  }
  servings {
    id
  }
  updatedAt
  groceryListCategory {
    id
    name
  }
  foodGroups {
    id
    name
  }
}
    ${FoodFragmentDoc}
${BrandFragmentDoc}
${NutrientAmountFragmentDoc}
${ServingWithUnitsFragmentDoc}
${FullServingFragmentDoc}`;
export const NotificationStubFragmentDoc = gql`
    fragment NotificationStub on Notification {
  id
  __typename
  viewedAt
}
    `;
export const TimelineMealForNotificationWebFragmentDoc = gql`
    fragment TimelineMealForNotificationWeb on TimelineMeal {
  id
  athlete {
    id
    team {
      id
    }
  }
}
    `;
export const CommentTimelineFragmentDoc = gql`
    fragment CommentTimeline on Comment {
  id
  __typename
  text
  at
  by {
    id
    firstName
    lastName
  }
  notification {
    ...NotificationStub
  }
}
    ${NotificationStubFragmentDoc}`;
export const FoodLogMealCommentNotificationWebFragmentDoc = gql`
    fragment FoodLogMealCommentNotificationWeb on FoodLogMealCommentNotification {
  timelineMeal {
    ...TimelineMealForNotificationWeb
    comments {
      ...CommentTimeline
    }
  }
}
    ${TimelineMealForNotificationWebFragmentDoc}
${CommentTimelineFragmentDoc}`;
export const FoodLogMealImageNotificationWebFragmentDoc = gql`
    fragment FoodLogMealImageNotificationWeb on FoodLogMealImageNotification {
  timelineMeal {
    ...TimelineMealForNotificationWeb
    images {
      id
      url
      position
    }
  }
}
    ${TimelineMealForNotificationWebFragmentDoc}`;
export const FoodLogMealEditNotificationWebFragmentDoc = gql`
    fragment FoodLogMealEditNotificationWeb on FoodLogMealEditNotification {
  timelineMeal {
    ...TimelineMealForNotificationWeb
  }
}
    ${TimelineMealForNotificationWebFragmentDoc}`;
export const MenuOrderCommentNotificationWebFragmentDoc = gql`
    fragment MenuOrderCommentNotificationWeb on MenuOrderCommentNotification {
  timelineMeal {
    ...TimelineMealForNotificationWeb
    comments {
      ...CommentTimeline
    }
  }
}
    ${TimelineMealForNotificationWebFragmentDoc}
${CommentTimelineFragmentDoc}`;
export const MenuOrderImageNotificationWebFragmentDoc = gql`
    fragment MenuOrderImageNotificationWeb on MenuOrderImageNotification {
  timelineMeal {
    ...TimelineMealForNotificationWeb
    images {
      id
      url
      position
    }
  }
}
    ${TimelineMealForNotificationWebFragmentDoc}`;
export const TimelineMealCommentNotificationWebFragmentDoc = gql`
    fragment TimelineMealCommentNotificationWeb on TimelineMealCommentNotification {
  timelineMeal {
    ...TimelineMealForNotificationWeb
  }
}
    ${TimelineMealForNotificationWebFragmentDoc}`;
export const TimelineMealImageNotificationWebFragmentDoc = gql`
    fragment TimelineMealImageNotificationWeb on TimelineMealImageNotification {
  timelineMeal {
    ...TimelineMealForNotificationWeb
    images {
      id
      url
      position
    }
  }
}
    ${TimelineMealForNotificationWebFragmentDoc}`;
export const TimelineMealEditNotificationWebFragmentDoc = gql`
    fragment TimelineMealEditNotificationWeb on TimelineMealEditNotification {
  timelineMeal {
    ...TimelineMealForNotificationWeb
  }
}
    ${TimelineMealForNotificationWebFragmentDoc}`;
export const NotificationWebFragmentDoc = gql`
    fragment NotificationWeb on Notification {
  ...NotificationStub
  __typename
  ... on FoodLogMealCommentNotification {
    ...FoodLogMealCommentNotificationWeb
  }
  ... on FoodLogMealImageNotification {
    ...FoodLogMealImageNotificationWeb
  }
  ... on FoodLogMealEditNotification {
    ...FoodLogMealEditNotificationWeb
  }
  ... on MenuOrderCommentNotification {
    ...MenuOrderCommentNotificationWeb
  }
  ... on MenuOrderImageNotification {
    ...MenuOrderImageNotificationWeb
  }
  ... on TimelineMealCommentNotification {
    ...TimelineMealCommentNotificationWeb
  }
  ... on TimelineMealImageNotification {
    ...TimelineMealImageNotificationWeb
  }
  ... on TimelineMealEditNotification {
    ...TimelineMealEditNotificationWeb
  }
}
    ${NotificationStubFragmentDoc}
${FoodLogMealCommentNotificationWebFragmentDoc}
${FoodLogMealImageNotificationWebFragmentDoc}
${FoodLogMealEditNotificationWebFragmentDoc}
${MenuOrderCommentNotificationWebFragmentDoc}
${MenuOrderImageNotificationWebFragmentDoc}
${TimelineMealCommentNotificationWebFragmentDoc}
${TimelineMealImageNotificationWebFragmentDoc}
${TimelineMealEditNotificationWebFragmentDoc}`;
export const NavAthleteFragmentDoc = gql`
    fragment NavAthlete on Athlete {
  firstName
  lastName
  team {
    id
    name
  }
}
    `;
export const NavMealMenuFragmentDoc = gql`
    fragment NavMealMenu on MealMenu {
  name
  start
  durationInMinutes
  teams {
    id
    name
  }
}
    `;
export const NavPlannedMenuFragmentDoc = gql`
    fragment NavPlannedMenu on PlannedMenu {
  name
}
    `;
export const MessageOptionFragmentDoc = gql`
    fragment MessageOption on MessageOption {
  id
  userId
  firstName
  lastName
  teamName
}
    `;
export const TeamOptionFragmentDoc = gql`
    fragment TeamOption on Team {
  id
  name
}
    `;
export const NavTeamFragmentDoc = gql`
    fragment NavTeam on Team {
  name
}
    `;
export const StaffDashboardRecipePreviewFragmentDoc = gql`
    fragment StaffDashboardRecipePreview on Recipe {
  id
  name
  mealTypes
  isShared
  createdBy {
    firstName
    lastName
    id
  }
  servings {
    perRecipeYield
    isDefault
    units
  }
}
    `;
export const NavOrgFragmentDoc = gql`
    fragment NavOrg on Org {
  name
}
    `;
export const NavRestaurantFragmentDoc = gql`
    fragment NavRestaurant on Restaurant {
  name
}
    `;
export const ReportingOrgFragmentDoc = gql`
    fragment ReportingOrg on OrgForReporting {
  id
  name
  territoryName
  teamworksId
  performanceKitchen
}
    `;
export const ReportingUserFragmentDoc = gql`
    fragment ReportingUser on User {
  id
  teamworksId
  email
  firstName
  lastName
  isActive
  dateJoined
}
    `;
export const MealTemplateTimelineFragmentDoc = gql`
    fragment MealTemplateTimeline on MealTemplate {
  id
  meal {
    id
    name
    start
    end
    type
  }
  ... on MacroMealTemplate {
    __typename
    cho
    pro
    fat
  }
  ... on ExchangeMealTemplate {
    __typename
    exchangeTargets {
      ...ExchangeAmount
    }
  }
}
    ${ExchangeAmountFragmentDoc}`;
export const ActivityTemplateTimelineFragmentDoc = gql`
    fragment ActivityTemplateTimeline on ActivityTemplate {
  id
  __typename
  activity {
    id
    name
    start
    end
    type
  }
}
    `;
export const MealPlanTimelineFragmentDoc = gql`
    fragment MealPlanTimeline on MealPlan {
  id
  name
  athlete {
    birthDate
  }
  macroProtocol {
    ...FullMacroProtocol
  }
  exchangeTargets {
    ...ExchangeAmount
  }
  mealTemplates {
    ...MealTemplateTimeline
  }
  activityTemplates {
    ...ActivityTemplateTimeline
  }
  promotedFoods {
    ...FoodLimitedAccess
  }
  avoidedFoods {
    ...FoodLimitedAccess
  }
  promotedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  avoidedFoodGroups {
    ...FullFoodGroupWithFoods
  }
}
    ${FullMacroProtocolFragmentDoc}
${ExchangeAmountFragmentDoc}
${MealTemplateTimelineFragmentDoc}
${ActivityTemplateTimelineFragmentDoc}
${FoodLimitedAccessFragmentDoc}
${FullFoodGroupWithFoodsFragmentDoc}`;
export const MealMenuSharedTimelineFragmentDoc = gql`
    fragment MealMenuSharedTimeline on MealMenu {
  id
  __typename
  mealType: type
  timezone
  name
  start
  durationInMinutes
  prepTimeInMinutes
  lastOrderTimeBeforeEndInMinutes
  isLogOnly
  isInHouseMenuOrderable
  isRestaurantMenuOrderable
  isViewOnlyForMe
  isBulkOrderable
  restaurantMenuLinks {
    id
    allowCustomOrders
    allowPlateOrders
    restaurant {
      id
      name
    }
  }
}
    `;
export const MealMenuTimelineFragmentDoc = gql`
    fragment MealMenuTimeline on MealMenu {
  ...MealMenuSharedTimeline
  isOrderable
  theme
  mealMenuDiningStations {
    id
  }
}
    ${MealMenuSharedTimelineFragmentDoc}`;
export const TimelineMealBaseFragmentDoc = gql`
    fragment TimelineMealBase on TimelineMeal {
  id
  __typename
  name
  type
  timezone
  start
  durationInMinutes
  mealTemplate {
    ...MealTemplateTimeline
  }
  mealMenus {
    ...MealMenuTimeline
  }
}
    ${MealTemplateTimelineFragmentDoc}
${MealMenuTimelineFragmentDoc}`;
export const MenuSelectionItemOptionFormFragmentDoc = gql`
    fragment MenuSelectionItemOptionForm on MenuSelectionItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    ...MenuItemChoiceOptionForm
  }
}
    ${MenuItemChoiceOptionFormFragmentDoc}`;
export const MenuSelectionItemFormFragmentDoc = gql`
    fragment MenuSelectionItemForm on MenuSelectionItem {
  amount
  percentConsumed
  menuItem {
    ...MenuItemForm
  }
  options {
    ...MenuSelectionItemOptionForm
  }
}
    ${MenuItemFormFragmentDoc}
${MenuSelectionItemOptionFormFragmentDoc}`;
export const RestaurantMenuLogTimelineFragmentDoc = gql`
    fragment RestaurantMenuLogTimeline on RestaurantMenuLog {
  id
  __typename
  mealType: type
  mealTemplate {
    id
  }
  restaurantMenu {
    id
  }
  restaurant {
    name
  }
  dateTime
  timezone
  items {
    id
    ...MenuSelectionItemForm
  }
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const RestaurantMenuLinkPlateOrderItemOptionFormFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateOrderItemOptionForm on RestaurantMenuLinkPlateOrderItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    ...MenuItemChoiceOptionForm
  }
}
    ${MenuItemChoiceOptionFormFragmentDoc}`;
export const RestaurantMenuLinkPlateOrderItemFormFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateOrderItemForm on RestaurantMenuLinkPlateOrderItem {
  amount
  percentConsumed
  plateItem {
    id
  }
  menuItem {
    ...MenuItemForm
  }
  options {
    ...RestaurantMenuLinkPlateOrderItemOptionForm
  }
}
    ${MenuItemFormFragmentDoc}
${RestaurantMenuLinkPlateOrderItemOptionFormFragmentDoc}`;
export const RestaurantMenuLinkPlateItemFormFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateItemForm on RestaurantMenuLinkPlateItem {
  id
  position
  specialRequests
  ...MenuSelectionItemForm
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const RestaurantMenuLinkPlateOrderFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateOrder on RestaurantMenuLinkPlateOrder {
  id
  userFullName
  items {
    ...RestaurantMenuLinkPlateOrderItemForm
  }
  plate {
    id
    items {
      ...RestaurantMenuLinkPlateItemForm
    }
    position
  }
  restaurantMenuLink {
    id
  }
}
    ${RestaurantMenuLinkPlateOrderItemFormFragmentDoc}
${RestaurantMenuLinkPlateItemFormFragmentDoc}`;
export const MealMenuWithTimesFragmentDoc = gql`
    fragment MealMenuWithTimes on MealMenu {
  id
  timezone
  name
  start
  durationInMinutes
  prepTimeInMinutes
  lastOrderTimeBeforeEndInMinutes
  isViewOnlyForMe
  theme
}
    `;
export const RestaurantMenuLinkPlateOrderSharedTimelineFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateOrderSharedTimeline on RestaurantMenuLinkPlateOrder {
  ...RestaurantMenuLinkPlateOrder
  mealMenu {
    ...MealMenuWithTimes
  }
  restaurantMenuLink {
    id
    restaurant {
      id
      name
    }
  }
}
    ${RestaurantMenuLinkPlateOrderFragmentDoc}
${MealMenuWithTimesFragmentDoc}`;
export const RestaurantMenuLinkOrderItemFormFragmentDoc = gql`
    fragment RestaurantMenuLinkOrderItemForm on RestaurantMenuLinkOrderItem {
  id
  specialRequests
  ...MenuSelectionItemForm
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const RestaurantMenuLinkOrderFragmentDoc = gql`
    fragment RestaurantMenuLinkOrder on RestaurantMenuLinkOrder {
  id
  items {
    ...RestaurantMenuLinkOrderItemForm
  }
}
    ${RestaurantMenuLinkOrderItemFormFragmentDoc}`;
export const RestaurantMenuLinkOrderSharedTimelineFragmentDoc = gql`
    fragment RestaurantMenuLinkOrderSharedTimeline on RestaurantMenuLinkOrder {
  ...RestaurantMenuLinkOrder
  mealMenu {
    id
  }
  restaurantMenuLink {
    id
    restaurant {
      id
      name
    }
  }
}
    ${RestaurantMenuLinkOrderFragmentDoc}`;
export const RatingFragmentDoc = gql`
    fragment Rating on MenuItemRating {
  id
  review
  value
}
    `;
export const MenuOrderItemFormFragmentDoc = gql`
    fragment MenuOrderItemForm on MenuOrderItem {
  id
  forOrder
  status
  specialRequests
  rating {
    ...Rating
  }
  ...MenuSelectionItemForm
}
    ${RatingFragmentDoc}
${MenuSelectionItemFormFragmentDoc}`;
export const MenuOrderTimelineFragmentDoc = gql`
    fragment MenuOrderTimeline on MenuOrder {
  id
  __typename
  mealTemplate {
    id
  }
  mealMenu {
    id
    name
  }
  pickupTime
  code
  items {
    ...MenuOrderItemForm
  }
}
    ${MenuOrderItemFormFragmentDoc}`;
export const MealMenuLogItemFormFragmentDoc = gql`
    fragment MealMenuLogItemForm on MealMenuLogItem {
  id
  ...MenuSelectionItemForm
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const MealMenuLogTimelineFragmentDoc = gql`
    fragment MealMenuLogTimeline on MealMenuLog {
  id
  __typename
  mealMenu {
    id
    name
  }
  items {
    ...MealMenuLogItemForm
  }
}
    ${MealMenuLogItemFormFragmentDoc}`;
export const TimelineMealFragmentDoc = gql`
    fragment TimelineMeal on TimelineMeal {
  ...TimelineMealBase
  images {
    ...Image
  }
  comments {
    ...CommentTimeline
  }
  notifications {
    id
    __typename
    viewedAt
  }
  servingAmounts {
    ...FullServingAmount
  }
  restaurantMenuLogs {
    ...RestaurantMenuLogTimeline
  }
  restaurantMenuLinkPlateOrders {
    ...RestaurantMenuLinkPlateOrderSharedTimeline
  }
  restaurantMenuLinkOrders {
    ...RestaurantMenuLinkOrderSharedTimeline
  }
  menuOrders {
    ...MenuOrderTimeline
  }
  mealMenuLogs {
    ...MealMenuLogTimeline
  }
}
    ${TimelineMealBaseFragmentDoc}
${ImageFragmentDoc}
${CommentTimelineFragmentDoc}
${FullServingAmountFragmentDoc}
${RestaurantMenuLogTimelineFragmentDoc}
${RestaurantMenuLinkPlateOrderSharedTimelineFragmentDoc}
${RestaurantMenuLinkOrderSharedTimelineFragmentDoc}
${MenuOrderTimelineFragmentDoc}
${MealMenuLogTimelineFragmentDoc}`;
export const TimelineMealTemplateFragmentDoc = gql`
    fragment TimelineMealTemplate on TimelineMealTemplate {
  __typename
  start
  durationInMinutes
  timezone
  template {
    ...MealTemplateTimeline
  }
  mealMenus {
    ...MealMenuTimeline
  }
}
    ${MealTemplateTimelineFragmentDoc}
${MealMenuTimelineFragmentDoc}`;
export const TimelineMealOrTemplateFragmentDoc = gql`
    fragment TimelineMealOrTemplate on TimelineMealOrTemplate {
  ... on TimelineMeal {
    ...TimelineMeal
  }
  ... on TimelineMealTemplate {
    ...TimelineMealTemplate
  }
}
    ${TimelineMealFragmentDoc}
${TimelineMealTemplateFragmentDoc}`;
export const FoodLogActivityTimelineFragmentDoc = gql`
    fragment FoodLogActivityTimeline on FoodLogActivity {
  id
  __typename
  name
  activityType: type
  date
  startTime
  endTime
  timezone
  rpe
  activityTemplate {
    id
  }
}
    `;
export const TimelineActivityTemplateFragmentDoc = gql`
    fragment TimelineActivityTemplate on TimelineActivityTemplate {
  __typename
  start
  durationInMinutes
  timezone
  template {
    ...ActivityTemplateTimeline
  }
}
    ${ActivityTemplateTimelineFragmentDoc}`;
export const TimelineActivityOrTemplateFragmentDoc = gql`
    fragment TimelineActivityOrTemplate on TimelineActivityOrTemplate {
  ... on FoodLogActivity {
    ...FoodLogActivityTimeline
  }
  ... on TimelineActivityTemplate {
    ...TimelineActivityTemplate
  }
}
    ${FoodLogActivityTimelineFragmentDoc}
${TimelineActivityTemplateFragmentDoc}`;
export const TimelineDateFragmentDoc = gql`
    fragment TimelineDate on TimelineDate {
  date
  timezone
  mealPlan {
    ...MealPlanTimeline
  }
  meals {
    ...TimelineMealOrTemplate
  }
  activities {
    ...TimelineActivityOrTemplate
  }
}
    ${MealPlanTimelineFragmentDoc}
${TimelineMealOrTemplateFragmentDoc}
${TimelineActivityOrTemplateFragmentDoc}`;
export const MenuItemAppearancePreviewFragmentDoc = gql`
    fragment MenuItemAppearancePreview on MenuItemAppearance {
  id
  position
  maxAmount
  availableForOrder
  allowSpecialRequests
  menuItem {
    ...MenuItemPreview
  }
}
    ${MenuItemPreviewFragmentDoc}`;
export const MealMenuDiningStationWithItemPreviewsFragmentDoc = gql`
    fragment MealMenuDiningStationWithItemPreviews on MealMenuDiningStation {
  id
  name
  maxAmount
  position
  menuItemAppearances {
    ...MenuItemAppearancePreview
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}`;
export const RestaurantMenuLinkPlateFragmentDoc = gql`
    fragment RestaurantMenuLinkPlate on RestaurantMenuLinkPlate {
  id
  items {
    ...RestaurantMenuLinkPlateItemForm
  }
  position
}
    ${RestaurantMenuLinkPlateItemFormFragmentDoc}`;
export const RestaurantMenuLinkWithItemPreviewsFragmentDoc = gql`
    fragment RestaurantMenuLinkWithItemPreviews on RestaurantMenuLink {
  id
  allowCustomOrders
  allowPlateOrders
  restaurant {
    id
    name
  }
  sections {
    restaurantMenuSection {
      id
      name
      position
    }
    maxAmount
    menuItemAppearances {
      ...MenuItemAppearancePreview
    }
  }
  plates {
    ...RestaurantMenuLinkPlate
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}
${RestaurantMenuLinkPlateFragmentDoc}`;
export const MealMenuWithItemPreviewsFragmentDoc = gql`
    fragment MealMenuWithItemPreviews on MealMenu {
  id
  name
  mealMenuDiningStations {
    ...MealMenuDiningStationWithItemPreviews
  }
  restaurantMenuLinks {
    ...RestaurantMenuLinkWithItemPreviews
  }
  isViewOnlyForMe
}
    ${MealMenuDiningStationWithItemPreviewsFragmentDoc}
${RestaurantMenuLinkWithItemPreviewsFragmentDoc}`;
export const MealMenuWithItemPreviewsAndTimeInfoFragmentDoc = gql`
    fragment MealMenuWithItemPreviewsAndTimeInfo on MealMenu {
  timezone
  start
  durationInMinutes
  prepTimeInMinutes
  lastOrderTimeBeforeEndInMinutes
  ...MealMenuWithItemPreviews
  theme
}
    ${MealMenuWithItemPreviewsFragmentDoc}`;
export const MealPlanDateTimelineFragmentDoc = gql`
    fragment MealPlanDateTimeline on MealPlanDate {
  __typename
  date
  timezone
  mealPlan {
    ...MealPlanTimeline
  }
}
    ${MealPlanTimelineFragmentDoc}`;
export const GoalCategoryFullFragmentDoc = gql`
    fragment GoalCategoryFull on GoalCategory {
  id
  name
  defaultEmoji
}
    `;
export const GoalTargetForTableFragmentDoc = gql`
    fragment GoalTargetForTable on GoalQuantity {
  id
  value
  unit
}
    `;
export const AthleteGoalConfigurationForCompliancePageFragmentDoc = gql`
    fragment AthleteGoalConfigurationForCompliancePage on GoalConfiguration {
  id
  name
  emoji
  timezone
  category {
    ...GoalCategoryFull
  }
  startDate
  endDate
  target {
    ...GoalTargetForTable
  }
  completedGoalCount(startDate: $startDate, endDate: $endDate)
  totalGoalCount(startDate: $startDate, endDate: $endDate)
}
    ${GoalCategoryFullFragmentDoc}
${GoalTargetForTableFragmentDoc}`;
export const GoalTrackerGoalForComplianceFragmentDoc = gql`
    fragment GoalTrackerGoalForCompliance on GoalTrackerGoal {
  id
  date
  completedAtInUTC
  value
}
    `;
export const GoalConfigurationForTableFragmentDoc = gql`
    fragment GoalConfigurationForTable on GoalConfiguration {
  id
  name
  emoji
  category {
    ...GoalCategoryFull
  }
  isArchived
  startDate
  endDate
  target {
    ...GoalTargetForTable
  }
  timezone
  completedGoalCount(startDate: $startDate, endDate: $endDate)
  totalGoalCount(startDate: $startDate, endDate: $endDate)
  dayOfWeekAssignments
  individualDates
  reminders {
    id
    time
    message
  }
}
    ${GoalCategoryFullFragmentDoc}
${GoalTargetForTableFragmentDoc}`;
export const MealPlanTemplateGoalFragmentDoc = gql`
    fragment MealPlanTemplateGoal on MealPlanTemplateGoal {
  id
  name
  kcalOffset
  goalType {
    id
    name
  }
}
    `;
export const AnthropometryTemplateFragmentDoc = gql`
    fragment AnthropometryTemplate on AnthropometryTemplate {
  __typename
  id
  height
  weight
  percentBodyFat
}
    `;
export const AssignFromMealPlanTemplateFragmentDoc = gql`
    fragment AssignFromMealPlanTemplate on MealPlanTemplate {
  id
  name
  description
  orgId
  orgGroupId
  macroProtocol {
    calorieBudget {
      rmrMethod
      goals {
        ...MealPlanTemplateGoal
      }
    }
    anthropometry {
      ... on AnthropometryTemplate {
        ...AnthropometryTemplate
      }
    }
  }
}
    ${MealPlanTemplateGoalFragmentDoc}
${AnthropometryTemplateFragmentDoc}`;
export const AssignFromMealPlanTemplateAthleteFragmentDoc = gql`
    fragment AssignFromMealPlanTemplateAthlete on Athlete {
  id
  birthDate
  mostRecentAnthropometryEntry {
    ...AnthropometryEntry
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export const MealTemplateTimeFragmentDoc = gql`
    fragment MealTemplateTime on MealTemplate {
  id
  meal {
    ...Meal
  }
}
    ${MealFragmentDoc}`;
export const MealTemplateScheduleFragmentDoc = gql`
    fragment MealTemplateSchedule on MealTemplate {
  ...MealTemplateTime
  hasDateModifications(after: $after)
}
    ${MealTemplateTimeFragmentDoc}`;
export const ActivityTemplateTimeFragmentDoc = gql`
    fragment ActivityTemplateTime on ActivityTemplate {
  id
  activity {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;
export const ActivityTemplateScheduleFragmentDoc = gql`
    fragment ActivityTemplateSchedule on ActivityTemplate {
  ...ActivityTemplateTime
  hasDateModifications(after: $after)
}
    ${ActivityTemplateTimeFragmentDoc}`;
export const DayOfWeekPriorityFragmentDoc = gql`
    fragment DayOfWeekPriority on DayOfWeekPriority {
  dayOfWeek
  priority
}
    `;
export const MealTemplateModificationFragmentDoc = gql`
    fragment MealTemplateModification on MealTemplateModification {
  mealTemplate {
    id
  }
  start
  end
}
    `;
export const ActivityTemplateModificationFragmentDoc = gql`
    fragment ActivityTemplateModification on ActivityTemplateModification {
  activityTemplate {
    id
  }
  start
  end
}
    `;
export const MealPlanDayOfWeekAssignmentFragmentDoc = gql`
    fragment MealPlanDayOfWeekAssignment on MealPlanDayOfWeekAssignment {
  dayOfWeekPriority {
    ...DayOfWeekPriority
  }
  mealTemplateModifications {
    ...MealTemplateModification
  }
  activityTemplateModifications {
    ...ActivityTemplateModification
  }
}
    ${DayOfWeekPriorityFragmentDoc}
${MealTemplateModificationFragmentDoc}
${ActivityTemplateModificationFragmentDoc}`;
export const MealPlanCalendarDateAssignmentFragmentDoc = gql`
    fragment MealPlanCalendarDateAssignment on MealPlanDateAssignment {
  date
  mealPlan {
    id
    name
    mealTemplates {
      ...MealTemplateTime
    }
    activityTemplates {
      ...ActivityTemplateTime
    }
    daysOfWeek {
      ...MealPlanDayOfWeekAssignment
    }
  }
  dayOfWeekPriority {
    ...DayOfWeekPriority
  }
  mealTemplateModifications {
    ...MealTemplateModification
  }
  activityTemplateModifications {
    ...ActivityTemplateModification
  }
}
    ${MealTemplateTimeFragmentDoc}
${ActivityTemplateTimeFragmentDoc}
${MealPlanDayOfWeekAssignmentFragmentDoc}
${DayOfWeekPriorityFragmentDoc}
${MealTemplateModificationFragmentDoc}
${ActivityTemplateModificationFragmentDoc}`;
export const MealPlanDateModificationsFragmentDoc = gql`
    fragment MealPlanDateModifications on MealPlanDateModifications {
  date
  mealTemplateModifications {
    ...MealTemplateModification
  }
  activityTemplateModifications {
    ...ActivityTemplateModification
  }
}
    ${MealTemplateModificationFragmentDoc}
${ActivityTemplateModificationFragmentDoc}`;
export const MealPlanDateAssignmentPreviewFragmentDoc = gql`
    fragment MealPlanDateAssignmentPreview on MealPlanDateAssignment {
  date
  mealPlan {
    id
    name
  }
}
    `;
export const ExternalEventFragmentDoc = gql`
    fragment ExternalEvent on ExternalEvent {
  id
  name
  timezone
  startDate
  endDate
  isAllDay
  eventType
  location
}
    `;
export const ExchangeSetFragmentDoc = gql`
    fragment ExchangeSet on ExchangeSet {
  id
  __typename
  name
  createdBy {
    id
    firstName
    lastName
  }
}
    `;
export const ExchangeSetWithExchangesFragmentDoc = gql`
    fragment ExchangeSetWithExchanges on ExchangeSet {
  ...ExchangeSet
  exchanges {
    ...Exchange
  }
}
    ${ExchangeSetFragmentDoc}
${ExchangeFragmentDoc}`;
export const ScheduleFragmentDoc = gql`
    fragment Schedule on Schedule {
  id
  __typename
  name
  activityFactor
}
    `;
export const FullFoodGroupFragmentDoc = gql`
    fragment FullFoodGroup on FoodGroup {
  id
  __typename
  name
  source
  foodsCount
}
    `;
export const EventFragmentDoc = gql`
    fragment Event on Event {
  __typename
  type
  datetime
}
    `;
export const ActivityTemplateFragmentDoc = gql`
    fragment ActivityTemplate on ActivityTemplate {
  id
  __typename
  activity {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;
export const MealTemplateFragmentDoc = gql`
    fragment MealTemplate on MealTemplate {
  id
  __typename
  meal {
    ...Meal
  }
}
    ${MealFragmentDoc}`;
export const EditMealTemplateFragmentDoc = gql`
    fragment EditMealTemplate on MealTemplate {
  id
  isAutosaving @client
  selectedMealOptionId @client
  newMealOptionIds @client
}
    `;
export const MealOptionFragmentDoc = gql`
    fragment MealOption on MealOption {
  id
  __typename
  note
  position
  name
}
    `;
export const FullMealOptionFragmentDoc = gql`
    fragment FullMealOption on MealOption {
  ...MealOption
  servingAmounts {
    ...FullServingAmount
  }
}
    ${MealOptionFragmentDoc}
${FullServingAmountFragmentDoc}`;
export const EditMealOptionFragmentDoc = gql`
    fragment EditMealOption on MealOption {
  id
  isAutosaving @client
}
    `;
export const EditFullMealOptionFragmentDoc = gql`
    fragment EditFullMealOption on MealOption {
  ...FullMealOption
  ...EditMealOption
}
    ${FullMealOptionFragmentDoc}
${EditMealOptionFragmentDoc}`;
export const MealOptionPendingFragmentDoc = gql`
    fragment MealOptionPending on MealOptionPending {
  id
  __typename
  note
  name
}
    `;
export const FullMealOptionPendingFragmentDoc = gql`
    fragment FullMealOptionPending on MealOptionPending {
  ...MealOptionPending
  servingAmounts {
    ...FullServingAmount
  }
}
    ${MealOptionPendingFragmentDoc}
${FullServingAmountFragmentDoc}`;
export const EditMealOptionPendingFragmentDoc = gql`
    fragment EditMealOptionPending on MealOptionPending {
  id
  isAutosaving @client
}
    `;
export const EditFullMealOptionPendingFragmentDoc = gql`
    fragment EditFullMealOptionPending on MealOptionPending {
  ...FullMealOptionPending
  ...EditMealOptionPending
}
    ${FullMealOptionPendingFragmentDoc}
${EditMealOptionPendingFragmentDoc}`;
export const EditFullMealTemplateFragmentDoc = gql`
    fragment EditFullMealTemplate on MealTemplate {
  ...MealTemplate
  ...EditMealTemplate
  mealOptions {
    ...EditFullMealOption
  }
  pendingMealOptions {
    ...EditFullMealOptionPending
  }
}
    ${MealTemplateFragmentDoc}
${EditMealTemplateFragmentDoc}
${EditFullMealOptionFragmentDoc}
${EditFullMealOptionPendingFragmentDoc}`;
export const MacroMealTemplateFragmentDoc = gql`
    fragment MacroMealTemplate on MacroMealTemplate {
  ...MealTemplate
  __typename
  cho
  pro
  fat
  kcal
}
    ${MealTemplateFragmentDoc}`;
export const EditMacroMealTemplateFragmentDoc = gql`
    fragment EditMacroMealTemplate on MacroMealTemplate {
  ...EditMealTemplate
  choInput @client
  proInput @client
  fatInput @client
}
    ${EditMealTemplateFragmentDoc}`;
export const EditFullMacroMealTemplateFragmentDoc = gql`
    fragment EditFullMacroMealTemplate on MacroMealTemplate {
  ...EditFullMealTemplate
  ...MacroMealTemplate
  ...EditMacroMealTemplate
}
    ${EditFullMealTemplateFragmentDoc}
${MacroMealTemplateFragmentDoc}
${EditMacroMealTemplateFragmentDoc}`;
export const ExchangeMealTemplateFragmentDoc = gql`
    fragment ExchangeMealTemplate on ExchangeMealTemplate {
  ...MealTemplate
  __typename
}
    ${MealTemplateFragmentDoc}`;
export const EditExchangeMealTemplateFragmentDoc = gql`
    fragment EditExchangeMealTemplate on ExchangeMealTemplate {
  ...EditMealTemplate
  exchangeTargets {
    ...EditExchangeAmount
  }
}
    ${EditMealTemplateFragmentDoc}
${EditExchangeAmountFragmentDoc}`;
export const EditFullExchangeMealTemplateFragmentDoc = gql`
    fragment EditFullExchangeMealTemplate on ExchangeMealTemplate {
  ...EditFullMealTemplate
  ...ExchangeMealTemplate
  ...EditExchangeMealTemplate
}
    ${EditFullMealTemplateFragmentDoc}
${ExchangeMealTemplateFragmentDoc}
${EditExchangeMealTemplateFragmentDoc}`;
export const CommonMealPlanFragmentDoc = gql`
    fragment CommonMealPlan on MealPlan {
  id
  __typename
  name
  description
  isShared
  startDate
  endDate
  type
  updatedAt
  exchangeSet {
    ...ExchangeSetWithExchanges
  }
  isAutosaving @client
  exchangeTargets {
    ...EditExchangeAmount
  }
  macroProtocol {
    ...FullMacroProtocol
  }
  schedule {
    ...Schedule
  }
  avoidedFoodGroups {
    ...FullFoodGroup
  }
  promotedFoodGroups {
    ...FullFoodGroup
  }
  avoidedFoods {
    ...FoodLimitedAccess
  }
  promotedFoods {
    ...FoodLimitedAccess
  }
  mostRecentEvent {
    ...Event
  }
  activityTemplates {
    ...ActivityTemplate
  }
  mealTemplates {
    ... on MacroMealTemplate {
      ...EditFullMacroMealTemplate
    }
    ... on ExchangeMealTemplate {
      ...EditFullExchangeMealTemplate
    }
  }
}
    ${ExchangeSetWithExchangesFragmentDoc}
${EditExchangeAmountFragmentDoc}
${FullMacroProtocolFragmentDoc}
${ScheduleFragmentDoc}
${FullFoodGroupFragmentDoc}
${FoodLimitedAccessFragmentDoc}
${EventFragmentDoc}
${ActivityTemplateFragmentDoc}
${EditFullMacroMealTemplateFragmentDoc}
${EditFullExchangeMealTemplateFragmentDoc}`;
export const AthleteFragmentDoc = gql`
    fragment Athlete on Athlete {
  id
  __typename
  firstName
  lastName
  sex
  birthDate
  phoneNumber
  email
  headshotUrl
  secaUid
  inbodyUid
  isArchived
  activationLinkSent
  jerseyNumber
  isProfileNotemealOnly
}
    `;
export const FoodLogNotificationSettingsFragmentDoc = gql`
    fragment FoodLogNotificationSettings on FoodLogNotificationSettings {
  edits
  imageUploads
  messages
}
    `;
export const PositionFragmentDoc = gql`
    fragment Position on Position {
  id
  __typename
  name
}
    `;
export const TeamFragmentDoc = gql`
    fragment Team on Team {
  id
  __typename
  gender
  name
}
    `;
export const SportFragmentDoc = gql`
    fragment Sport on Sport {
  id
  __typename
  name
}
    `;
export const FullSportFragmentDoc = gql`
    fragment FullSport on Sport {
  ...Sport
  positions {
    ...Position
  }
}
    ${SportFragmentDoc}
${PositionFragmentDoc}`;
export const TeamWithSportFragmentDoc = gql`
    fragment TeamWithSport on Team {
  ...Team
  sport {
    ...FullSport
  }
}
    ${TeamFragmentDoc}
${FullSportFragmentDoc}`;
export const MacroMealPlanDisplaySettingsFragmentDoc = gql`
    fragment MacroMealPlanDisplaySettings on MacroMealPlanDisplaySettings {
  __typename
  dailyProgressViews
  mealProgressViews
}
    `;
export const ExchangeMealPlanDisplaySettingsFragmentDoc = gql`
    fragment ExchangeMealPlanDisplaySettings on ExchangeMealPlanDisplaySettings {
  __typename
  dailyProgressViewsForExchanges
  mealProgressViewsForExchanges
  dailyProgressViewsForMacros
  mealProgressViewsForMacros
}
    `;
export const NoTargetsDisplaySettingsFragmentDoc = gql`
    fragment NoTargetsDisplaySettings on NoTargetsDisplaySettings {
  dailyProgressViewsForMacros
  mealProgressViewsForMacros
}
    `;
export const MealPlanFragmentDoc = gql`
    fragment MealPlan on MealPlan {
  id
  __typename
  name
  isShared
  isAutoSuggestionsEnabled
  description
  usesDateRange
  dates
  startDate
  endDate
  type
  createdAt
  updatedAt
  exchangeSet {
    id
  }
}
    `;
export const AthleteSummaryAthleteFragmentDoc = gql`
    fragment AthleteSummaryAthlete on Athlete {
  ...Athlete
  foodLogNotificationSettings {
    ...FoodLogNotificationSettings
  }
  position {
    ...Position
  }
  mostRecentAnthropometryEntry {
    ...AnthropometryEntry
  }
  currentGoal {
    ...FullGoal
  }
  likedFoods {
    ...Food
  }
  dislikedFoods {
    ...Food
  }
  likedFoodGroups {
    ...FullFoodGroup
  }
  dislikedFoodGroups {
    ...FullFoodGroup
  }
  team {
    ...TeamWithSport
    foodLogNotificationSettings {
      ...FoodLogNotificationSettings
    }
    defaultHideAnthropometry
    defaultMacroDisplaySettings {
      ...MacroMealPlanDisplaySettings
    }
    defaultExchangeDisplaySettings {
      ...ExchangeMealPlanDisplaySettings
    }
    defaultNoTargetsDisplaySettings {
      ...NoTargetsDisplaySettings
    }
  }
  mealPlans {
    ...MealPlan
    events {
      ...Event
    }
  }
  defaultHideAnthropometry
  defaultMacroDisplaySettings {
    ...MacroMealPlanDisplaySettings
  }
  defaultExchangeDisplaySettings {
    ...ExchangeMealPlanDisplaySettings
  }
  defaultNoTargetsDisplaySettings {
    ...NoTargetsDisplaySettings
  }
  macroDisplaySettings {
    ...MacroMealPlanDisplaySettings
  }
  exchangeDisplaySettings {
    ...ExchangeMealPlanDisplaySettings
  }
  hideAnthropometry
  noTargetsDisplaySettings {
    ...NoTargetsDisplaySettings
  }
  user {
    id
    firstName
    lastName
    lastLogin
    username
  }
  orgMembership {
    id
    isActive
    teamworksId
    isNotemealOnly
  }
  allOrderedNamedTags {
    ...NamedTagForSelection
  }
  activeTransfer {
    id
  }
}
    ${AthleteFragmentDoc}
${FoodLogNotificationSettingsFragmentDoc}
${PositionFragmentDoc}
${AnthropometryEntryFragmentDoc}
${FullGoalFragmentDoc}
${FoodFragmentDoc}
${FullFoodGroupFragmentDoc}
${TeamWithSportFragmentDoc}
${MacroMealPlanDisplaySettingsFragmentDoc}
${ExchangeMealPlanDisplaySettingsFragmentDoc}
${NoTargetsDisplaySettingsFragmentDoc}
${MealPlanFragmentDoc}
${EventFragmentDoc}
${NamedTagForSelectionFragmentDoc}`;
export const EditedAthleteFragmentDoc = gql`
    fragment EditedAthlete on Athlete {
  ...Athlete
  timelineMealNotifications {
    ...NotificationStub
  }
  team {
    ...TeamWithSport
    athletes {
      id
    }
    recentNotifications {
      ...NotificationStub
    }
  }
  position {
    ...Position
  }
  allOrderedNamedTags {
    ...NamedTagForSelection
  }
}
    ${AthleteFragmentDoc}
${NotificationStubFragmentDoc}
${TeamWithSportFragmentDoc}
${PositionFragmentDoc}
${NamedTagForSelectionFragmentDoc}`;
export const AthletePageAthleteFragmentDoc = gql`
    fragment AthletePageAthlete on Athlete {
  ...Athlete
  team {
    id
  }
  orgMembership {
    id
    isActive
  }
  timelineMealNotifications {
    ...NotificationStub
  }
}
    ${AthleteFragmentDoc}
${NotificationStubFragmentDoc}`;
export const ClientCredentialFragmentDoc = gql`
    fragment ClientCredential on ClientCredential {
  id
  name
  clientId
  isActive
}
    `;
export const DiningStationTemplatePreviewFragmentDoc = gql`
    fragment DiningStationTemplatePreview on DiningStationTemplate {
  id
  name
  __typename
}
    `;
export const DiningStationTemplateFullFragmentDoc = gql`
    fragment DiningStationTemplateFull on DiningStationTemplate {
  id
  __typename
  name
  defaultMaxAmount
  mealTypes
  menuItemAppearances {
    ...MenuItemAppearancePreview
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}`;
export const DiningStationTemplateTableFragmentDoc = gql`
    fragment DiningStationTemplateTable on DiningStationTemplate {
  id
  __typename
  name
  mealTypes
  menuItemAppearances {
    position
    menuItem {
      id
      isOneOff
      isMissingIngredients
    }
  }
}
    `;
export const MenuItemAppearanceForMenuBuilderFragmentDoc = gql`
    fragment MenuItemAppearanceForMenuBuilder on MenuItemAppearance {
  id
  position
  menuItem {
    id
    name
    isMissingIngredients
  }
}
    `;
export const MealMenuSummaryFragmentDoc = gql`
    fragment MealMenuSummary on MealMenu {
  id
  name
  type
  start
  lastOrderTimeBeforeEndInMinutes
  durationInMinutes
}
    `;
export const KdsPageMealMenuDiningStationFragmentDoc = gql`
    fragment KdsPageMealMenuDiningStation on MealMenuDiningStation {
  id
  name
  position
  menuItemAppearances {
    menuItem {
      id
    }
  }
}
    `;
export const KdsPageMealMenuInDateRangeFragmentDoc = gql`
    fragment KdsPageMealMenuInDateRange on MealMenu {
  ...MealMenuSummary
  mealMenuDiningStations {
    ...KdsPageMealMenuDiningStation
  }
}
    ${MealMenuSummaryFragmentDoc}
${KdsPageMealMenuDiningStationFragmentDoc}`;
export const KdsPageOrderPreviewAthleteFragmentDoc = gql`
    fragment KdsPageOrderPreviewAthlete on Athlete {
  id
  firstName
  lastName
  position {
    name
  }
  team {
    name
  }
  jerseyNumber
}
    `;
export const KdsPageMenuOrderPreviewFragmentDoc = gql`
    fragment KdsPageMenuOrderPreview on MenuOrder {
  __typename
  id
  pickupTime
  createdAt
  updatedAt
  code
  itemCount
  itemsByDiningStation(diningStationIds: $diningStationIds) {
    id
    status
    updateCount
    updatedAt
    menuItem {
      id
    }
  }
  mealMenu {
    id
  }
  userFullName
  athlete {
    ...KdsPageOrderPreviewAthlete
  }
}
    ${KdsPageOrderPreviewAthleteFragmentDoc}`;
export const KdsPageMealMenuPlateOrderPreviewFragmentDoc = gql`
    fragment KdsPageMealMenuPlateOrderPreview on MealMenuPlateOrder {
  __typename
  id
  createdAt
  updatedAt
  code
  itemCount
  itemsByDiningStation(diningStationIds: $diningStationIds) {
    id
    status
    updateCount
    updatedAt
    menuItem {
      id
    }
  }
  mealMenu {
    id
  }
  userFullName
  athlete {
    ...KdsPageOrderPreviewAthlete
  }
  parentBulkOrder {
    pickupTime
    deliveryLocation
    code
    team {
      name
    }
  }
}
    ${KdsPageOrderPreviewAthleteFragmentDoc}`;
export const KdsPageMealMenuFragmentDoc = gql`
    fragment KdsPageMealMenu on MealMenu {
  ...MealMenuSummary
  allOrders {
    ...KdsPageMenuOrderPreview
  }
  allMealMenuPlateOrders {
    ...KdsPageMealMenuPlateOrderPreview
  }
}
    ${MealMenuSummaryFragmentDoc}
${KdsPageMenuOrderPreviewFragmentDoc}
${KdsPageMealMenuPlateOrderPreviewFragmentDoc}`;
export const KdsPageMenuItemFragmentDoc = gql`
    fragment KdsPageMenuItem on MenuItem {
  id
  name
  servingName
  choices {
    id
    name
    position
    options {
      id
      name
      position
    }
  }
}
    `;
export const KdsPageMenuItemChoiceOptionFragmentDoc = gql`
    fragment KdsPageMenuItemChoiceOption on MenuItemChoiceOption {
  id
  name
}
    `;
export const KdsTicketMenuOrderItemOptionFragmentDoc = gql`
    fragment KdsTicketMenuOrderItemOption on MenuOrderItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    ...KdsPageMenuItemChoiceOption
  }
}
    ${KdsPageMenuItemChoiceOptionFragmentDoc}`;
export const KdsTicketMenuOrderItemFragmentDoc = gql`
    fragment KdsTicketMenuOrderItem on MenuOrderItem {
  id
  amount
  percentConsumed
  status
  specialRequests
  updateCount
  updatedAt
  menuItem {
    ...KdsPageMenuItem
  }
  options {
    ...KdsTicketMenuOrderItemOption
  }
}
    ${KdsPageMenuItemFragmentDoc}
${KdsTicketMenuOrderItemOptionFragmentDoc}`;
export const KdsAnalyzeDialogOrderFragmentDoc = gql`
    fragment KdsAnalyzeDialogOrder on MenuOrder {
  id
  __typename
  itemsByDiningStation(diningStationIds: $diningStationIds) {
    ...KdsTicketMenuOrderItem
  }
}
    ${KdsTicketMenuOrderItemFragmentDoc}`;
export const KdsTicketMealMenuPlateOrderItemOptionFragmentDoc = gql`
    fragment KdsTicketMealMenuPlateOrderItemOption on MealMenuPlateOrderItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    ...KdsPageMenuItemChoiceOption
  }
}
    ${KdsPageMenuItemChoiceOptionFragmentDoc}`;
export const KdsTicketMealMenuPlateOrderItemFragmentDoc = gql`
    fragment KdsTicketMealMenuPlateOrderItem on MealMenuPlateOrderItem {
  id
  amount
  percentConsumed
  status
  specialRequests
  updateCount
  updatedAt
  menuItem {
    ...KdsPageMenuItem
  }
  options {
    ...KdsTicketMealMenuPlateOrderItemOption
  }
}
    ${KdsPageMenuItemFragmentDoc}
${KdsTicketMealMenuPlateOrderItemOptionFragmentDoc}`;
export const KdsAnalyzeDialogPlateOrderFragmentDoc = gql`
    fragment KdsAnalyzeDialogPlateOrder on MealMenuPlateOrder {
  id
  __typename
  itemsByDiningStation(diningStationIds: $diningStationIds) {
    ...KdsTicketMealMenuPlateOrderItem
  }
}
    ${KdsTicketMealMenuPlateOrderItemFragmentDoc}`;
export const MenuOrderItemStatusesPublisherScopeFragmentDoc = gql`
    fragment MenuOrderItemStatusesPublisherScope on MenuOrderItemStatusesPublisherScope {
  mealMenuId
}
    `;
export const MealMenuPlateItemOrderStatusPublisherScopeFragmentDoc = gql`
    fragment MealMenuPlateItemOrderStatusPublisherScope on MealMenuPlateItemOrderStatusPublisherScope {
  mealMenuId
}
    `;
export const MenuOrderPublisherScopeFragmentDoc = gql`
    fragment MenuOrderPublisherScope on MenuOrderPublisherScope {
  mealMenuId
}
    `;
export const BulkOrderPublisherScopeFragmentDoc = gql`
    fragment BulkOrderPublisherScope on BulkOrderPublisherScope {
  mealMenuId
}
    `;
export const KdsTicketOrderAthleteFragmentDoc = gql`
    fragment KdsTicketOrderAthlete on Athlete {
  id
  firstName
  lastName
  jerseyNumber
  team {
    name
  }
  position {
    name
  }
  dislikedFoods {
    id
    name
  }
  dislikedFoodGroups {
    id
    name
  }
  printableTagsForAthlete {
    ...NamedTagForSelection
  }
}
    ${NamedTagForSelectionFragmentDoc}`;
export const KdsTicketMenuOrderFragmentDoc = gql`
    fragment KdsTicketMenuOrder on MenuOrder {
  __typename
  id
  mealMenu {
    id
  }
  pickupTime
  createdAt
  updatedAt
  code
  itemsByDiningStation(diningStationIds: $diningStationIds) {
    ...KdsTicketMenuOrderItem
  }
  itemCount
  userFullName
  athlete {
    ...KdsTicketOrderAthlete
  }
}
    ${KdsTicketMenuOrderItemFragmentDoc}
${KdsTicketOrderAthleteFragmentDoc}`;
export const KdsTicketMealMenuPlateOrderFragmentDoc = gql`
    fragment KdsTicketMealMenuPlateOrder on MealMenuPlateOrder {
  __typename
  id
  mealMenu {
    id
  }
  createdAt
  updatedAt
  code
  itemsByDiningStation(diningStationIds: $diningStationIds) {
    ...KdsTicketMealMenuPlateOrderItem
  }
  itemCount
  userFullName
  athlete {
    ...KdsTicketOrderAthlete
  }
  parentBulkOrder {
    pickupTime
    deliveryLocation
    code
    team {
      name
    }
  }
}
    ${KdsTicketMealMenuPlateOrderItemFragmentDoc}
${KdsTicketOrderAthleteFragmentDoc}`;
export const KioskTeamPreviewFragmentDoc = gql`
    fragment KioskTeamPreview on Team {
  id
  name
}
    `;
export const KioskMealMenuPreviewFragmentDoc = gql`
    fragment KioskMealMenuPreview on MealMenu {
  ...MealMenuSharedTimeline
  teams {
    id
    name
  }
  athletes {
    id
  }
  hasAdvancedSelection
  userOrderLimit
  isHubCheckInEnabled
  hubEventUrl
  isOrderable
  mealMenuDiningStations {
    name
    id
    menuItemAppearances {
      menuItem {
        id
        name
        imageUrl
      }
    }
  }
  theme
}
    ${MealMenuSharedTimelineFragmentDoc}`;
export const KioskAthleteFragmentDoc = gql`
    fragment KioskAthlete on Athlete {
  id
  firstName
  lastName
  team {
    id
    name
  }
  user {
    id
  }
}
    `;
export const MenuOrderInfoFragmentDoc = gql`
    fragment MenuOrderInfo on MenuOrderInfo {
  mealMenuId
  menuOrderCount
  mostRecentEditableMenuOrderId
}
    `;
export const OrderKioskAthletePreviewFragmentDoc = gql`
    fragment OrderKioskAthletePreview on Athlete {
  ...KioskAthlete
  kioskPreviewOrderInfo(mealMenuIds: $mealMenuIds) {
    ...MenuOrderInfo
  }
}
    ${KioskAthleteFragmentDoc}
${MenuOrderInfoFragmentDoc}`;
export const OrdersForAthleteFragmentDoc = gql`
    fragment OrdersForAthlete on MenuOrder {
  id
  __typename
  items {
    id
    status
  }
  createdAt
  code
  pickupTime
  mealMenu {
    id
  }
}
    `;
export const CheckInFragmentDoc = gql`
    fragment CheckIn on MealMenuCheckIn {
  id
  mealMenuId
  athleteId
}
    `;
export const CheckInKioskAthletePreviewFragmentDoc = gql`
    fragment CheckInKioskAthletePreview on Athlete {
  ...KioskAthlete
  checkInsForMealMenus(mealMenuIds: $mealMenuIds) {
    ...CheckIn
  }
}
    ${KioskAthleteFragmentDoc}
${CheckInFragmentDoc}`;
export const BasicMealPlanFragmentDoc = gql`
    fragment BasicMealPlan on MealPlan {
  ...MealPlan
  exchangeSet {
    ...ExchangeSetWithExchanges
  }
}
    ${MealPlanFragmentDoc}
${ExchangeSetWithExchangesFragmentDoc}`;
export const PreviewMealPlanFragmentDoc = gql`
    fragment PreviewMealPlan on MealPlan {
  ...BasicMealPlan
  macroProtocol {
    ...FullMacroProtocol
  }
  schedule {
    ...Schedule
  }
  avoidedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  promotedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  avoidedFoods {
    ...FoodLimitedAccess
  }
  promotedFoods {
    ...FoodLimitedAccess
  }
  mostRecentEvent {
    ...Event
  }
  exchangeTargets {
    ...EditExchangeAmount
  }
}
    ${BasicMealPlanFragmentDoc}
${FullMacroProtocolFragmentDoc}
${ScheduleFragmentDoc}
${FullFoodGroupWithFoodsFragmentDoc}
${FoodLimitedAccessFragmentDoc}
${EventFragmentDoc}
${EditExchangeAmountFragmentDoc}`;
export const MealTemplateSharedTimelineFragmentDoc = gql`
    fragment MealTemplateSharedTimeline on MealTemplate {
  id
  meal {
    id
    name
    start
    end
    type
  }
  ... on MacroMealTemplate {
    __typename
  }
  ... on ExchangeMealTemplate {
    __typename
  }
}
    `;
export const MealTemplateForTargetsFragmentDoc = gql`
    fragment MealTemplateForTargets on MealTemplate {
  id
  ... on MacroMealTemplate {
    __typename
    cho
    pro
    fat
  }
  ... on ExchangeMealTemplate {
    __typename
    exchangeTargets {
      ...ExchangeAmount
    }
  }
}
    ${ExchangeAmountFragmentDoc}`;
export const MealPlanForKioskFragmentDoc = gql`
    fragment MealPlanForKiosk on MealPlan {
  id
  mealTemplates {
    ...MealTemplateSharedTimeline
    ...MealTemplateForTargets
  }
  activityTemplates {
    ...ActivityTemplateTimeline
  }
  promotedFoods {
    ...FoodLimitedAccess
  }
  avoidedFoods {
    ...FoodLimitedAccess
  }
  promotedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  avoidedFoodGroups {
    ...FullFoodGroupWithFoods
  }
}
    ${MealTemplateSharedTimelineFragmentDoc}
${MealTemplateForTargetsFragmentDoc}
${ActivityTemplateTimelineFragmentDoc}
${FoodLimitedAccessFragmentDoc}
${FullFoodGroupWithFoodsFragmentDoc}`;
export const TimelineMealForKioskFragmentDoc = gql`
    fragment TimelineMealForKiosk on TimelineMeal {
  id
  __typename
  name
  type
  timezone
  start
  durationInMinutes
  mealMenus {
    ...MealMenuSharedTimeline
  }
  servingAmounts {
    ...FullServingAmount
  }
  mealTemplate {
    ...MealTemplateSharedTimeline
    ...MealTemplateForTargets
  }
  restaurantMenuLogs {
    ...RestaurantMenuLogTimeline
  }
  restaurantMenuLinkPlateOrders {
    ...RestaurantMenuLinkPlateOrderSharedTimeline
  }
  restaurantMenuLinkOrders {
    ...RestaurantMenuLinkOrderSharedTimeline
  }
  menuOrders {
    ...MenuOrderTimeline
  }
  mealMenuLogs {
    ...MealMenuLogTimeline
  }
}
    ${MealMenuSharedTimelineFragmentDoc}
${FullServingAmountFragmentDoc}
${MealTemplateSharedTimelineFragmentDoc}
${MealTemplateForTargetsFragmentDoc}
${RestaurantMenuLogTimelineFragmentDoc}
${RestaurantMenuLinkPlateOrderSharedTimelineFragmentDoc}
${RestaurantMenuLinkOrderSharedTimelineFragmentDoc}
${MenuOrderTimelineFragmentDoc}
${MealMenuLogTimelineFragmentDoc}`;
export const TimelineMealTemplateForKioskFragmentDoc = gql`
    fragment TimelineMealTemplateForKiosk on TimelineMealTemplate {
  __typename
  start
  durationInMinutes
  timezone
  template {
    ...MealTemplateSharedTimeline
    ...MealTemplateForTargets
  }
  mealMenus {
    ...MealMenuSharedTimeline
  }
}
    ${MealTemplateSharedTimelineFragmentDoc}
${MealTemplateForTargetsFragmentDoc}
${MealMenuSharedTimelineFragmentDoc}`;
export const TimelineMealOrTemplateForKioskFragmentDoc = gql`
    fragment TimelineMealOrTemplateForKiosk on TimelineMealOrTemplate {
  ... on TimelineMeal {
    ...TimelineMealForKiosk
  }
  ... on TimelineMealTemplate {
    ...TimelineMealTemplateForKiosk
  }
}
    ${TimelineMealForKioskFragmentDoc}
${TimelineMealTemplateForKioskFragmentDoc}`;
export const TimelineActivityOrTemplateForKioskFragmentDoc = gql`
    fragment TimelineActivityOrTemplateForKiosk on TimelineActivityOrTemplate {
  ... on FoodLogActivity {
    ...FoodLogActivityTimeline
  }
  ... on TimelineActivityTemplate {
    ...TimelineActivityTemplate
  }
}
    ${FoodLogActivityTimelineFragmentDoc}
${TimelineActivityTemplateFragmentDoc}`;
export const TimelineDateForKioskFragmentDoc = gql`
    fragment TimelineDateForKiosk on TimelineDate {
  date
  timezone
  mealPlan {
    ...MealPlanForKiosk
  }
  meals {
    ...TimelineMealOrTemplateForKiosk
  }
  activities {
    ...TimelineActivityOrTemplateForKiosk
  }
}
    ${MealPlanForKioskFragmentDoc}
${TimelineMealOrTemplateForKioskFragmentDoc}
${TimelineActivityOrTemplateForKioskFragmentDoc}`;
export const MealMenuForOrderFormFragmentDoc = gql`
    fragment MealMenuForOrderForm on MealMenu {
  id
  __typename
  mealType: type
  timezone
  name
  start
  durationInMinutes
  prepTimeInMinutes
  lastOrderTimeBeforeEndInMinutes
  userOrderLimit
  isViewOnlyForMe
  theme
}
    `;
export const MenuOrderFormFragmentDoc = gql`
    fragment MenuOrderForm on MenuOrder {
  id
  __typename
  mealTemplate {
    ...MealTemplateForTargets
  }
  mealMenu {
    ...MealMenuForOrderForm
    theme
    mealMenuDiningStations {
      menuItemAppearances {
        id
        availableForOrder
        maxAmount
        allowSpecialRequests
        menuItem {
          id
        }
      }
    }
  }
  pickupTime
  code
  createdAt
  items {
    ...MenuOrderItemForm
  }
}
    ${MealTemplateForTargetsFragmentDoc}
${MealMenuForOrderFormFragmentDoc}
${MenuOrderItemFormFragmentDoc}`;
export const AthleteForKioskAthleteFragmentDoc = gql`
    fragment AthleteForKioskAthlete on Athlete {
  ...KioskAthlete
  mealPlans {
    ...PreviewMealPlan
    createdAt
  }
  macroDisplaySettings {
    mealProgressViews
  }
  timelineDate(date: $date, timezone: $timezone) {
    ...TimelineDateForKiosk
  }
  likedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  dislikedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  likedFoods {
    ...Food
  }
  dislikedFoods {
    ...Food
  }
  ordersForMenu(mealMenuId: $mealMenuId) {
    ...MenuOrderForm
  }
}
    ${KioskAthleteFragmentDoc}
${PreviewMealPlanFragmentDoc}
${TimelineDateForKioskFragmentDoc}
${FullFoodGroupWithFoodsFragmentDoc}
${FoodFragmentDoc}
${MenuOrderFormFragmentDoc}`;
export const DietitianMealPlanTemplatePendingStateFragmentDoc = gql`
    fragment DietitianMealPlanTemplatePendingState on MealPlanTemplate {
  id
  pendingState
}
    `;
export const DietitianMealPlanTemplateIsSharedFragmentDoc = gql`
    fragment DietitianMealPlanTemplateIsShared on MealPlanTemplate {
  id
  isShared
}
    `;
export const AthleteForCustomTagFragmentDoc = gql`
    fragment AthleteForCustomTag on Athlete {
  id
  firstName
  lastName
  myNamedTagsOffsetConnection(input: {limit: 2, offset: 0}) {
    edges {
      ...NamedTagForSelection
    }
    pageInfo {
      total
    }
  }
  team {
    id
    name
  }
}
    ${NamedTagForSelectionFragmentDoc}`;
export const TeamPreviewFragmentDoc = gql`
    fragment TeamPreview on Team {
  id
  name
}
    `;
export const PositionWitSportFragmentDoc = gql`
    fragment PositionWitSport on Position {
  id
  name
  sport {
    name
  }
}
    `;
export const AdvancedSelectionConditionFragmentDoc = gql`
    fragment AdvancedSelectionCondition on TagCondition {
  __typename
  ... on TeamTag {
    id
    isComplement
    teams {
      ...TeamPreview
    }
  }
  ... on PositionTag {
    id
    isComplement
    positions {
      ...PositionWitSport
    }
  }
  ... on ComboTag {
    id
    isComplement
    combinationMethod
    namedChildTags {
      ...NamedTagForSelection
    }
  }
}
    ${TeamPreviewFragmentDoc}
${PositionWitSportFragmentDoc}
${NamedTagForSelectionFragmentDoc}`;
export const AdvancedSelectionGroupFragmentDoc = gql`
    fragment AdvancedSelectionGroup on AdvancedSelectionGroup {
  conditions {
    ...AdvancedSelectionCondition
  }
}
    ${AdvancedSelectionConditionFragmentDoc}`;
export const AdvancedSelectionFragmentDoc = gql`
    fragment AdvancedSelection on AdvancedSelectionType {
  id
  includeAthletes {
    ...AthleteForCustomTag
  }
  excludeAthletes {
    ...AthleteForCustomTag
  }
  groups {
    ...AdvancedSelectionGroup
  }
}
    ${AthleteForCustomTagFragmentDoc}
${AdvancedSelectionGroupFragmentDoc}`;
export const DietitianMealPlanTemplateAssignFragmentDoc = gql`
    fragment DietitianMealPlanTemplateAssign on MealPlanTemplate {
  id
  teams {
    id
    name
  }
  hasAdvancedSelection
  advancedSelection {
    ...AdvancedSelection
  }
}
    ${AdvancedSelectionFragmentDoc}`;
export const DietitianMealPlanTemplateListItemFragmentDoc = gql`
    fragment DietitianMealPlanTemplateListItem on MealPlanTemplate {
  id
  name
  isShared
  pendingState
  teams {
    id
    name
  }
  hasAdvancedSelection
  advancedSelection {
    ...AdvancedSelection
  }
}
    ${AdvancedSelectionFragmentDoc}`;
export const MealPlanTemplateOwnerPreviewFragmentDoc = gql`
    fragment MealPlanTemplateOwnerPreview on User {
  id
  firstName
  lastName
}
    `;
export const DietitianOrgGroupMealPlanTemplateListItemFragmentDoc = gql`
    fragment DietitianOrgGroupMealPlanTemplateListItem on MealPlanTemplate {
  id
  name
  description
  pendingState
  owner {
    ...MealPlanTemplateOwnerPreview
  }
  updatedAt
  copiedCount
}
    ${MealPlanTemplateOwnerPreviewFragmentDoc}`;
export const DietitianStaffMealPlanTemplateListItemFragmentDoc = gql`
    fragment DietitianStaffMealPlanTemplateListItem on MealPlanTemplate {
  id
  name
  isShared
  teams {
    id
    name
  }
  hasAdvancedSelection
  advancedSelection {
    ...AdvancedSelection
  }
}
    ${AdvancedSelectionFragmentDoc}`;
export const AthleteForMenuAttendanceFragmentDoc = gql`
    fragment AthleteForMenuAttendance on Athlete {
  ...Athlete
  team {
    id
    name
  }
  position {
    ...Position
  }
}
    ${AthleteFragmentDoc}
${PositionFragmentDoc}`;
export const PlannedMenuMealRowGridItemFragmentDoc = gql`
    fragment PlannedMenuMealRowGridItem on PlannedMenuMealRowItem {
  id
  dayOfWeek
  position
  menuItem {
    id
    name
    isMissingIngredients
  }
}
    `;
export const PlannedMenuMealGridRowFragmentDoc = gql`
    fragment PlannedMenuMealGridRow on PlannedMenuMealRow {
  id
  diningStationName
  position
  foodType
  plannedMenuMealRowItems {
    ...PlannedMenuMealRowGridItem
  }
}
    ${PlannedMenuMealRowGridItemFragmentDoc}`;
export const PlannedMenuGridMealFragmentDoc = gql`
    fragment PlannedMenuGridMeal on PlannedMenuMeal {
  id
  name
  type
  startTime
  endTime
  themes
  plannedMenuMealRows {
    ...PlannedMenuMealGridRow
  }
}
    ${PlannedMenuMealGridRowFragmentDoc}`;
export const PlannedMenuWeekSummaryFragmentDoc = gql`
    fragment PlannedMenuWeekSummary on PlannedMenuWeek {
  id
  week
  disabledDays
}
    `;
export const PlannedMenuSummaryFragmentDoc = gql`
    fragment PlannedMenuSummary on PlannedMenu {
  id
  name
  firstOccurrence
  lastOccurrence
  startDate
  endDate
  description
  occurrence
  timezone
  lastEditedAt
  lockCreatedAt
  lockCreatedBy {
    id
    firstName
    lastName
  }
  weeks {
    ...PlannedMenuWeekSummary
  }
}
    ${PlannedMenuWeekSummaryFragmentDoc}`;
export const AthletesBirthdaysFragmentDoc = gql`
    fragment AthletesBirthdays on Athlete {
  id
  birthDate
  firstName
  lastName
  team {
    id
    name
  }
}
    `;
export const MenuItemForDigitalDisplayFragmentDoc = gql`
    fragment MenuItemForDigitalDisplay on MenuItemAppearance {
  id
  menuItem {
    name
    description
    servingAmounts {
      ...FullServingAmount
    }
    choices {
      id
    }
  }
}
    ${FullServingAmountFragmentDoc}`;
export const MealMenuDiningStationForDigitalDisplayFragmentDoc = gql`
    fragment MealMenuDiningStationForDigitalDisplay on MealMenuDiningStation {
  id
  name
  position
  menuItemAppearances {
    ...MenuItemForDigitalDisplay
  }
}
    ${MenuItemForDigitalDisplayFragmentDoc}`;
export const DigiDisplaysMealMenuFragmentDoc = gql`
    fragment DigiDisplaysMealMenu on MealMenu {
  id
  name
  start
  durationInMinutes
  mealMenuDiningStations {
    ...MealMenuDiningStationForDigitalDisplay
  }
}
    ${MealMenuDiningStationForDigitalDisplayFragmentDoc}`;
export const PlannedMenuTableItemFragmentDoc = gql`
    fragment PlannedMenuTableItem on PlannedMenu {
  id
  name
  description
  firstOccurrence
  lastOccurrence
  occurrence
  timezone
  startDate
  endDate
  weeks {
    id
    week
    disabledDays
  }
  mealTypes
  createdBy {
    id
    firstName
    lastName
  }
  lastEditedAt
  lastEditedBy {
    id
    firstName
    lastName
  }
}
    `;
export const PlannedMenuFormFragmentDoc = gql`
    fragment PlannedMenuForm on PlannedMenu {
  id
  name
  description
  startDate
  endDate
  occurrence
  timezone
}
    `;
export const RatingCountValuesFragmentDoc = gql`
    fragment RatingCountValues on RatingCounts {
  one
  two
  three
  four
  five
}
    `;
export const MenuItemTableFragmentDoc = gql`
    fragment MenuItemTable on MenuItem {
  id
  name
  servingName
  isMissingIngredients
  history {
    id
    ratingCounts {
      ...RatingCountValues
    }
  }
  choices {
    id
    name
  }
  ratingCounts {
    ...RatingCountValues
  }
  score {
    ...ScoreAssignment
  }
  foodCategory {
    ...FoodCategoryName
  }
  aiAugmented {
    isAiGenerated
    isAiTranscribed
  }
}
    ${RatingCountValuesFragmentDoc}
${ScoreAssignmentFragmentDoc}
${FoodCategoryNameFragmentDoc}`;
export const RatingForMenuItemFragmentDoc = gql`
    fragment RatingForMenuItem on MenuItemRating {
  id
  review
  value
  createdAt
  createdBy {
    id
    firstName
    lastName
  }
  menuOrderItem {
    id
    order {
      id
      pickupTime
    }
  }
  menuItem {
    id
    createdAt
    isCurrent
  }
}
    `;
export const BonAppetitCafeFragmentDoc = gql`
    fragment BonAppetitCafe on BonAppetitCafe {
  id
  name
}
    `;
export const BonAppetitMenusPreviewFragmentDoc = gql`
    fragment BonAppetitMenusPreview on BonAppetitMenusPreview {
  cafeId
  name
  days {
    date
    dayParts {
      id
      label
      startTime
      endTime
    }
  }
}
    `;
export const CbordServiceUnitFragmentDoc = gql`
    fragment CbordServiceUnit on CbordServiceUnit {
  __typename
  oid
  name
  facilityOid
}
    `;
export const CbordFacilityFragmentDoc = gql`
    fragment CbordFacility on CbordFacility {
  __typename
  oid
  name
  businessUnits {
    ... on CbordFacility {
      __typename
      oid
      name
    }
    ... on CbordServiceUnit {
      ...CbordServiceUnit
    }
  }
}
    ${CbordServiceUnitFragmentDoc}`;
export const CbordBusinessUnitFragmentDoc = gql`
    fragment CbordBusinessUnit on CbordBusinessUnit {
  ... on CbordFacility {
    ...CbordFacility
  }
  ... on CbordServiceUnit {
    ...CbordServiceUnit
  }
}
    ${CbordFacilityFragmentDoc}
${CbordServiceUnitFragmentDoc}`;
export const CbordMenuItemFragmentDoc = gql`
    fragment CbordMenuItem on CbordMenuItem {
  itemOid
  serviceName
  servedUofmName
  serviceStationOid
  serviceStationSort
  serviceStationName
}
    `;
export const CbordServiceMenuFragmentDoc = gql`
    fragment CbordServiceMenu on CbordServiceMenu {
  oid
  meal
  serviceDate
  menuItems {
    ...CbordMenuItem
  }
}
    ${CbordMenuItemFragmentDoc}`;
export const CompassLocationFragmentDoc = gql`
    fragment CompassLocation on Compass_Location {
  locationId
  name
  mealPeriods {
    displayOrder
    mealPeriodId
    name
    stations {
      displayOrder
      stationId
      name
    }
  }
}
    `;
export const CompassMenuItemFragmentDoc = gql`
    fragment CompassMenuItem on Compass_MenuItem {
  id
  date
  displayOrder
  stationId
  mealPeriodId
  locationId
  portion
  name
}
    `;
export const PlannedMenuWithWeeksFragmentDoc = gql`
    fragment PlannedMenuWithWeeks on PlannedMenu {
  id
  name
  timezone
  occurrence
  firstOccurrence
  lastOccurrence
  weeks {
    id
    week
    disabledDays
  }
}
    `;
export const PlannedMenuMealRowItemForImportFragmentDoc = gql`
    fragment PlannedMenuMealRowItemForImport on PlannedMenuMealRowItem {
  id
  dayOfWeek
  position
  menuItem {
    id
    name
    defaultAvailableForOrder
    defaultAllowSpecialRequests
  }
}
    `;
export const PlannedMenuMealRowForImportFragmentDoc = gql`
    fragment PlannedMenuMealRowForImport on PlannedMenuMealRow {
  id
  diningStationName
  position
  foodType
  plannedMenuMealRowItems {
    ...PlannedMenuMealRowItemForImport
  }
}
    ${PlannedMenuMealRowItemForImportFragmentDoc}`;
export const PlannedMenuMealForImportFragmentDoc = gql`
    fragment PlannedMenuMealForImport on PlannedMenuMeal {
  id
  name
  type
  startTime
  endTime
  themes
  plannedMenuMealRows {
    ...PlannedMenuMealRowForImport
  }
}
    ${PlannedMenuMealRowForImportFragmentDoc}`;
export const PlannedMenuWeekForImportFragmentDoc = gql`
    fragment PlannedMenuWeekForImport on PlannedMenuWeek {
  id
  week
  meals {
    ...PlannedMenuMealForImport
  }
}
    ${PlannedMenuMealForImportFragmentDoc}`;
export const SodexoMenuSourceFragmentDoc = gql`
    fragment SodexoMenuSource on SodexoMenuSource {
  locationId
  menuSourceKey
  menuSourceName
}
    `;
export const SodexoMealTimesByDayFragmentDoc = gql`
    fragment SodexoMealTimesByDay on SodexoMealTimesByDay {
  locationId
  menuSourceKey
  mealTimesByDay {
    date
    mealTimes {
      start
      end
      name
      sortOrder
    }
  }
}
    `;
export const ExcessiveServingFragmentDoc = gql`
    fragment ExcessiveServing on Serving {
  ...Serving
  customUnits
  unitPrefix
  unitSuffix
  unit {
    id
    name
  }
  macros {
    cho
    pro
    fat
    kcal
  }
  foodOrRecipe {
    ... on Food {
      ...Food
      ... on BrandedFood {
        brand {
          id
          name
        }
      }
    }
    ... on Recipe {
      ...RecipeWithIngredients
      descendantFoods {
        ...Food
      }
      descendantRecipes {
        ...RecipeWithIngredients
      }
    }
  }
}
    ${ServingFragmentDoc}
${FoodFragmentDoc}
${RecipeWithIngredientsFragmentDoc}`;
export const ExcessiveServingAmountFragmentDoc = gql`
    fragment ExcessiveServingAmount on ServingAmount {
  id
  __typename
  amount
  position
  serving {
    ...ExcessiveServing
  }
}
    ${ExcessiveServingFragmentDoc}`;
export const MenuItemIngredientsAnalyzerFragmentDoc = gql`
    fragment MenuItemIngredientsAnalyzer on MenuItem {
  id
  name
  servingName
  servingAmounts {
    ...ExcessiveServingAmount
  }
  choices {
    id
    name
    position
    options {
      id
      name
      position
      servingAmounts {
        ...ExcessiveServingAmount
      }
    }
  }
}
    ${ExcessiveServingAmountFragmentDoc}`;
export const MealMenuDiningStationIngredientsAnalyzerFragmentDoc = gql`
    fragment MealMenuDiningStationIngredientsAnalyzer on MealMenuDiningStation {
  id
  name
  position
  diningStationTemplate {
    id
  }
  menuItemAppearances {
    position
    menuItem {
      ...MenuItemIngredientsAnalyzer
    }
  }
}
    ${MenuItemIngredientsAnalyzerFragmentDoc}`;
export const MealMenuIngredientsAnalyzerFragmentDoc = gql`
    fragment MealMenuIngredientsAnalyzer on MealMenu {
  id
  mealMenuDiningStations {
    ...MealMenuDiningStationIngredientsAnalyzer
  }
}
    ${MealMenuDiningStationIngredientsAnalyzerFragmentDoc}`;
export const MenuOrderMissingOrdersFragmentDoc = gql`
    fragment MenuOrderMissingOrders on MenuOrder {
  id
  athlete {
    id
    firstName
    lastName
  }
  items {
    id
    forOrder
  }
  user {
    id
  }
}
    `;
export const AthleteMissingOrdersFragmentDoc = gql`
    fragment AthleteMissingOrders on Athlete {
  id
  firstName
  lastName
  team {
    id
    name
  }
}
    `;
export const RestaurantMenuLinkMissingOrdersFragmentDoc = gql`
    fragment RestaurantMenuLinkMissingOrders on RestaurantMenuLink {
  orders {
    id
    athlete {
      id
      firstName
      lastName
    }
    items {
      id
    }
    user {
      id
    }
  }
  plates {
    id
    orders {
      id
      athlete {
        id
        firstName
        lastName
      }
      user {
        id
      }
    }
  }
}
    `;
export const MealMenuMissingOrdersFragmentDoc = gql`
    fragment MealMenuMissingOrders on MealMenu {
  allOrders {
    ...MenuOrderMissingOrders
  }
  athletes {
    ...AthleteMissingOrders
  }
  restaurantMenuLinks {
    ...RestaurantMenuLinkMissingOrders
  }
}
    ${MenuOrderMissingOrdersFragmentDoc}
${AthleteMissingOrdersFragmentDoc}
${RestaurantMenuLinkMissingOrdersFragmentDoc}`;
export const MealMenuPlateItemOptionFragmentDoc = gql`
    fragment MealMenuPlateItemOption on MealMenuPlateItemOption {
  amount
  menuItemChoiceOption {
    id
    name
  }
}
    `;
export const MealMenuPlateItemFragmentDoc = gql`
    fragment MealMenuPlateItem on MealMenuPlateItem {
  id
  amount
  menuItem {
    ...MenuItemForm
  }
  options {
    ...MealMenuPlateItemOption
  }
}
    ${MenuItemFormFragmentDoc}
${MealMenuPlateItemOptionFragmentDoc}`;
export const MealMenuPlateFragmentDoc = gql`
    fragment MealMenuPlate on MealMenuPlate {
  id
  mealMenuPlateOrders {
    user {
      id
    }
  }
  plateItems {
    ...MealMenuPlateItem
  }
}
    ${MealMenuPlateItemFragmentDoc}`;
export const BulkOrderFragmentDoc = gql`
    fragment BulkOrder on BulkOrder {
  id
  createdBy {
    firstName
    lastName
  }
  team {
    name
  }
  pickupTime
  childMealMenuPlates {
    ...MealMenuPlate
  }
  status
}
    ${MealMenuPlateFragmentDoc}`;
export const AthleteMenuSuggestionsPreviewFragmentDoc = gql`
    fragment AthleteMenuSuggestionsPreview on Athlete {
  id
  firstName
  lastName
  dislikedFoodGroups {
    id
    name
  }
  mealPlans {
    id
    name
    avoidedFoodGroups {
      id
      name
    }
    mealTemplates {
      id
      meal {
        id
        type
      }
      ... on MacroMealTemplate {
        __typename
        cho
        pro
        fat
      }
      ... on ExchangeMealTemplate {
        __typename
        exchangeTargets {
          ...ExchangeAmount
        }
      }
    }
  }
}
    ${ExchangeAmountFragmentDoc}`;
export const MealMenuIdentityFragmentDoc = gql`
    fragment MealMenuIdentity on MealMenuIdentity {
  __typename
  id
  isOverridden
}
    `;
export const MenuOrderRateLimitFragmentDoc = gql`
    fragment MenuOrderRateLimit on MenuOrderRateLimit {
  limit
  excludedTeamIds
}
    `;
export const MealMenuOrdersPlacedOrderFragmentDoc = gql`
    fragment MealMenuOrdersPlacedOrder on MenuOrder {
  id
  items {
    id
    forOrder
  }
  athlete {
    id
  }
  user {
    id
  }
}
    `;
export const TeamMealMenuPreviewFragmentDoc = gql`
    fragment TeamMealMenuPreview on Team {
  id
  name
  athletes {
    id
  }
}
    `;
export const MealMenuCalendarBaseFragmentDoc = gql`
    fragment MealMenuCalendarBase on MealMenu {
  id
  __typename
  name
  type
  start
  timezone
  durationInMinutes
  prepTimeInMinutes
  lastOrderTimeBeforeEndInMinutes
  notificationSentBeforeOrderDueInMinutes
  userOrderLimit
  isHubCheckInEnabled
  isOrderAndLogRestricted
  identity {
    ...MealMenuIdentity
  }
  orderRateLimit {
    ...MenuOrderRateLimit
  }
  allOrders {
    ...MealMenuOrdersPlacedOrder
  }
  teams {
    ...TeamMealMenuPreview
  }
  hasAdvancedSelection
  athleteCount
  theme
}
    ${MealMenuIdentityFragmentDoc}
${MenuOrderRateLimitFragmentDoc}
${MealMenuOrdersPlacedOrderFragmentDoc}
${TeamMealMenuPreviewFragmentDoc}`;
export const MealMenuDiningStationPreviewFragmentDoc = gql`
    fragment MealMenuDiningStationPreview on MealMenuDiningStation {
  id
  name
  position
}
    `;
export const MealMenuRmlOrderPlacedOrderFragmentDoc = gql`
    fragment MealMenuRmlOrderPlacedOrder on RestaurantMenuLinkOrder {
  id
  items {
    id
  }
  athlete {
    id
  }
  user {
    id
  }
}
    `;
export const MealMenuRmlPlateWithOrdersFragmentDoc = gql`
    fragment MealMenuRmlPlateWithOrders on RestaurantMenuLinkPlate {
  id
  orders {
    id
    athlete {
      id
    }
    user {
      id
    }
  }
}
    `;
export const RestaurantMenuLinkPreviewFragmentDoc = gql`
    fragment RestaurantMenuLinkPreview on RestaurantMenuLink {
  id
  restaurant {
    id
    name
  }
  orders {
    ...MealMenuRmlOrderPlacedOrder
  }
  plates {
    ...MealMenuRmlPlateWithOrders
  }
}
    ${MealMenuRmlOrderPlacedOrderFragmentDoc}
${MealMenuRmlPlateWithOrdersFragmentDoc}`;
export const MealMenuCalendarFragmentDoc = gql`
    fragment MealMenuCalendar on MealMenu {
  ...MealMenuCalendarBase
  mealMenuDiningStations {
    ...MealMenuDiningStationPreview
  }
  restaurantMenuLinks {
    ...RestaurantMenuLinkPreview
  }
}
    ${MealMenuCalendarBaseFragmentDoc}
${MealMenuDiningStationPreviewFragmentDoc}
${RestaurantMenuLinkPreviewFragmentDoc}`;
export const MenuOrderCountsFragmentDoc = gql`
    fragment MenuOrderCounts on MenuOrder {
  id
  items {
    id
    amount
    forOrder
    menuItem {
      id
    }
  }
  athlete {
    id
  }
  user {
    id
  }
}
    `;
export const MealMenuDiningStationFormFragmentDoc = gql`
    fragment MealMenuDiningStationForm on MealMenuDiningStation {
  id
  name
  position
  maxAmount
  diningStationTemplate {
    id
  }
  menuItemAppearances {
    ...MenuItemAppearancePreview
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}`;
export const RestaurantPreviewFragmentDoc = gql`
    fragment RestaurantPreview on Restaurant {
  id
  name
}
    `;
export const RestaurantMenuSectionPreviewFragmentDoc = gql`
    fragment RestaurantMenuSectionPreview on RestaurantMenuSection {
  id
  name
  position
  menuItemAppearances {
    ...MenuItemAppearancePreview
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}`;
export const RestaurantMenuFormFragmentDoc = gql`
    fragment RestaurantMenuForm on RestaurantMenu {
  id
  sections {
    ...RestaurantMenuSectionPreview
  }
}
    ${RestaurantMenuSectionPreviewFragmentDoc}`;
export const DeliveryLocationMenuLinkFormFragmentDoc = gql`
    fragment DeliveryLocationMenuLinkForm on DeliveryLocation {
  id
  address {
    displayName
  }
  name
}
    `;
export const RestaurantMenuLinkPlateFormFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateForm on RestaurantMenuLinkPlate {
  id
  position
  bulkOrderAmount
  isDefault
  items {
    ...RestaurantMenuLinkPlateItemForm
  }
  orders {
    id
  }
}
    ${RestaurantMenuLinkPlateItemFormFragmentDoc}`;
export const RestaurantMenuLinkFormFragmentDoc = gql`
    fragment RestaurantMenuLinkForm on RestaurantMenuLink {
  id
  allowCustomOrders
  allowPlateOrders
  restaurant {
    ...RestaurantPreview
  }
  restaurantMenu {
    ...RestaurantMenuForm
  }
  restaurantLocation {
    ...RestaurantLocationPreview
  }
  deliveryLocation {
    ...DeliveryLocationMenuLinkForm
  }
  sections {
    maxAmount
    restaurantMenuSection {
      id
    }
    menuItemAppearances {
      id
      maxAmount
      availableForOrder
      allowSpecialRequests
      menuItem {
        id
      }
    }
  }
  plates {
    ...RestaurantMenuLinkPlateForm
  }
}
    ${RestaurantPreviewFragmentDoc}
${RestaurantMenuFormFragmentDoc}
${RestaurantLocationPreviewFragmentDoc}
${DeliveryLocationMenuLinkFormFragmentDoc}
${RestaurantMenuLinkPlateFormFragmentDoc}`;
export const MealMenuModalFragmentDoc = gql`
    fragment MealMenuModal on MealMenu {
  ...MealMenuCalendarBase
  advancedSelection {
    ...AdvancedSelection
  }
  allOrders {
    ...MenuOrderCounts
  }
  mealMenuDiningStations {
    ...MealMenuDiningStationForm
  }
  restaurantMenuLinks {
    ...RestaurantMenuLinkForm
  }
}
    ${MealMenuCalendarBaseFragmentDoc}
${AdvancedSelectionFragmentDoc}
${MenuOrderCountsFragmentDoc}
${MealMenuDiningStationFormFragmentDoc}
${RestaurantMenuLinkFormFragmentDoc}`;
export const CurrentMealMenuFragmentDoc = gql`
    fragment CurrentMealMenu on MealMenu {
  id
  mealMenuDiningStations {
    id
    name
    position
    maxAmount
    diningStationTemplate {
      id
    }
    menuItemAppearances {
      id
      maxAmount
      position
      availableForOrder
      allowSpecialRequests
      menuItem {
        history {
          current {
            ...MenuItemPreview
          }
        }
      }
    }
  }
  restaurantMenuLinks {
    ...RestaurantMenuLinkForm
  }
  advancedSelection {
    ...AdvancedSelection
  }
}
    ${MenuItemPreviewFragmentDoc}
${RestaurantMenuLinkFormFragmentDoc}
${AdvancedSelectionFragmentDoc}`;
export const OrderPageMenuItemChoiceFragmentDoc = gql`
    fragment OrderPageMenuItemChoice on MenuItemChoice {
  id
  name
  position
  options {
    id
    name
    position
    canEditAmount
  }
}
    `;
export const OrderPageMenuItemFragmentDoc = gql`
    fragment OrderPageMenuItem on MenuItem {
  id
  name
  choices {
    ...OrderPageMenuItemChoice
  }
}
    ${OrderPageMenuItemChoiceFragmentDoc}`;
export const OrderPageMenuItemChoiceOptionFragmentDoc = gql`
    fragment OrderPageMenuItemChoiceOption on MenuItemChoiceOption {
  id
  name
}
    `;
export const OrderPageMenuOrderItemOptionFragmentDoc = gql`
    fragment OrderPageMenuOrderItemOption on MenuOrderItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    ...OrderPageMenuItemChoiceOption
  }
}
    ${OrderPageMenuItemChoiceOptionFragmentDoc}`;
export const OrderPageMenuOrderItemFragmentDoc = gql`
    fragment OrderPageMenuOrderItem on MenuOrderItem {
  id
  amount
  percentConsumed
  forOrder
  specialRequests
  menuItem {
    ...OrderPageMenuItem
  }
  options {
    ...OrderPageMenuOrderItemOption
  }
  order {
    id
    pickupTime
    code
    userFullName
    athlete {
      id
      firstName
      lastName
      jerseyNumber
      team {
        id
        name
      }
    }
  }
}
    ${OrderPageMenuItemFragmentDoc}
${OrderPageMenuOrderItemOptionFragmentDoc}`;
export const OrderPageMenuOrderFragmentDoc = gql`
    fragment OrderPageMenuOrder on MenuOrder {
  id
  pickupTime
  code
  items {
    ...OrderPageMenuOrderItem
  }
  mealMenu {
    id
  }
}
    ${OrderPageMenuOrderItemFragmentDoc}`;
export const OrderPageMealMenuDiningStationFragmentDoc = gql`
    fragment OrderPageMealMenuDiningStation on MealMenuDiningStation {
  id
  name
  position
  menuItemAppearances {
    menuItem {
      id
    }
  }
}
    `;
export const OrderPageRestaurantMenuLinkDeliveryLocationFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLinkDeliveryLocation on DeliveryLocation {
  id
  address {
    displayName
  }
  name
}
    `;
export const OrderPageRestaurantMenuLinkPreviewFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLinkPreview on RestaurantMenuLink {
  id
  restaurant {
    id
    name
  }
  deliveryLocation {
    ...OrderPageRestaurantMenuLinkDeliveryLocation
  }
}
    ${OrderPageRestaurantMenuLinkDeliveryLocationFragmentDoc}`;
export const OrderPageMealMenuFragmentDoc = gql`
    fragment OrderPageMealMenu on MealMenu {
  id
  name
  allOrders {
    ...OrderPageMenuOrder
  }
  mealMenuDiningStations {
    ...OrderPageMealMenuDiningStation
  }
  restaurantMenuLinks {
    ...OrderPageRestaurantMenuLinkPreview
  }
}
    ${OrderPageMenuOrderFragmentDoc}
${OrderPageMealMenuDiningStationFragmentDoc}
${OrderPageRestaurantMenuLinkPreviewFragmentDoc}`;
export const OrderPageRestaurantMenuLinkPlateItemOptionFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLinkPlateItemOption on RestaurantMenuLinkPlateItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    ...OrderPageMenuItemChoiceOption
  }
}
    ${OrderPageMenuItemChoiceOptionFragmentDoc}`;
export const OrderPageRestaurantMenuLinkPlateItemFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLinkPlateItem on RestaurantMenuLinkPlateItem {
  id
  position
  amount
  percentConsumed
  menuItem {
    ...OrderPageMenuItem
  }
  options {
    ...OrderPageRestaurantMenuLinkPlateItemOption
  }
  specialRequests
}
    ${OrderPageMenuItemFragmentDoc}
${OrderPageRestaurantMenuLinkPlateItemOptionFragmentDoc}`;
export const OrderPageRestaurantMenuLinkPlateOrderFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLinkPlateOrder on RestaurantMenuLinkPlateOrder {
  id
  userFullName
  athlete {
    firstName
    lastName
    jerseyNumber
  }
}
    `;
export const OrderPageRestaurantMenuLinkPlateFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLinkPlate on RestaurantMenuLinkPlate {
  id
  position
  bulkOrderAmount
  items {
    ...OrderPageRestaurantMenuLinkPlateItem
  }
  orders {
    ...OrderPageRestaurantMenuLinkPlateOrder
  }
}
    ${OrderPageRestaurantMenuLinkPlateItemFragmentDoc}
${OrderPageRestaurantMenuLinkPlateOrderFragmentDoc}`;
export const OrderPageRestaurantMenuLinkOrderItemOptionFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLinkOrderItemOption on RestaurantMenuLinkOrderItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    ...OrderPageMenuItemChoiceOption
  }
}
    ${OrderPageMenuItemChoiceOptionFragmentDoc}`;
export const OrderPageRestaurantMenuLinkOrderItemFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLinkOrderItem on RestaurantMenuLinkOrderItem {
  id
  amount
  percentConsumed
  menuItem {
    ...OrderPageMenuItem
  }
  options {
    ...OrderPageRestaurantMenuLinkOrderItemOption
  }
  specialRequests
}
    ${OrderPageMenuItemFragmentDoc}
${OrderPageRestaurantMenuLinkOrderItemOptionFragmentDoc}`;
export const OrderPageRestaurantMenuLinkOrderFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLinkOrder on RestaurantMenuLinkOrder {
  id
  userFullName
  athlete {
    firstName
    lastName
    jerseyNumber
  }
  items {
    ...OrderPageRestaurantMenuLinkOrderItem
  }
}
    ${OrderPageRestaurantMenuLinkOrderItemFragmentDoc}`;
export const OrderPageRestaurantMenuLinkFragmentDoc = gql`
    fragment OrderPageRestaurantMenuLink on RestaurantMenuLink {
  id
  restaurant {
    id
    name
  }
  deliveryLocation {
    ...OrderPageRestaurantMenuLinkDeliveryLocation
  }
  plates {
    ...OrderPageRestaurantMenuLinkPlate
  }
  orders {
    ...OrderPageRestaurantMenuLinkOrder
  }
}
    ${OrderPageRestaurantMenuLinkDeliveryLocationFragmentDoc}
${OrderPageRestaurantMenuLinkPlateFragmentDoc}
${OrderPageRestaurantMenuLinkOrderFragmentDoc}`;
export const TableRecipeFragmentDoc = gql`
    fragment TableRecipe on Recipe {
  ...RecipeWithIngredients
  createdAt
  org {
    id
  }
  createdBy {
    id
    firstName
    lastName
  }
  servings {
    ...Serving
  }
  isShared
  score {
    ...ScoreAssignment
  }
  owner {
    firstName
    lastName
    id
  }
  orgGroup {
    name
  }
  copiedCount
  isAiGenerated
  isAiTranscribed
}
    ${RecipeWithIngredientsFragmentDoc}
${ServingFragmentDoc}
${ScoreAssignmentFragmentDoc}`;
export const RecipeOwnerPreviewFragmentDoc = gql`
    fragment RecipeOwnerPreview on User {
  id
  firstName
  lastName
}
    `;
export const TeamLabelFragmentDoc = gql`
    fragment TeamLabel on TeamLabel {
  id
  name
  isActive
}
    `;
export const DeliveryLocationWithTeamsFragmentDoc = gql`
    fragment DeliveryLocationWithTeams on DeliveryLocation {
  id
  name
  isActive
  deliveryInstructions
  address {
    displayName
    lat
    long
  }
  cityInfo
  googlePlaceId
  teamLabels {
    ...TeamLabel
  }
}
    ${TeamLabelFragmentDoc}`;
export const StaffMealPlanTemplateListItemFragmentDoc = gql`
    fragment StaffMealPlanTemplateListItem on MealPlanTemplate {
  id
  name
  isShared
  pendingState
}
    `;
export const StaffMealPlanTemplatePendingStateFragmentDoc = gql`
    fragment StaffMealPlanTemplatePendingState on MealPlanTemplate {
  id
  pendingState
}
    `;
export const StaffMealPlanTemplateIsSharedFragmentDoc = gql`
    fragment StaffMealPlanTemplateIsShared on MealPlanTemplate {
  id
  isShared
}
    `;
export const MealPlanTemplateMealOptionFragmentDoc = gql`
    fragment MealPlanTemplateMealOption on MealPlanTemplateMealOption {
  id
  position
  note
  name
  servingAmounts {
    ...FullServingAmount
  }
}
    ${FullServingAmountFragmentDoc}`;
export const MacroMealPlanTemplateMealFragmentDoc = gql`
    fragment MacroMealPlanTemplateMeal on MacroMealPlanTemplateMeal {
  id
  name
  type
  start
  end
  mealOptions {
    ...MealPlanTemplateMealOption
  }
  choRatio
  proRatio
  fatRatio
}
    ${MealPlanTemplateMealOptionFragmentDoc}`;
export const MealPlanTemplateActivityFragmentDoc = gql`
    fragment MealPlanTemplateActivity on MealPlanTemplateActivity {
  id
  name
  type
  start
  end
}
    `;
export const AnthropometryFormFragmentDoc = gql`
    fragment AnthropometryForm on AnthropometryForm {
  __typename
  questions {
    field
  }
}
    `;
export const MealPlanTemplateAnthropometryFragmentDoc = gql`
    fragment MealPlanTemplateAnthropometry on MealPlanTemplateAnthropometry {
  ... on AnthropometryTemplate {
    ...AnthropometryTemplate
  }
  ... on AnthropometryForm {
    ...AnthropometryForm
  }
}
    ${AnthropometryTemplateFragmentDoc}
${AnthropometryFormFragmentDoc}`;
export const MealPlanTemplateCalorieBudgetFragmentDoc = gql`
    fragment MealPlanTemplateCalorieBudget on MealPlanTemplateCalorieBudget {
  id
  rmrMethod
  goals {
    ...MealPlanTemplateGoal
  }
  activityFactor
}
    ${MealPlanTemplateGoalFragmentDoc}`;
export const MealPlanTemplateMacroProtocolFragmentDoc = gql`
    fragment MealPlanTemplateMacroProtocol on MealPlanTemplateMacroProtocol {
  id
  useExistingAnthropometry
  anthropometry {
    ...MealPlanTemplateAnthropometry
  }
  calorieBudget {
    ...MealPlanTemplateCalorieBudget
  }
  cho {
    ...MacroMath
  }
  pro {
    ...MacroMath
  }
  fat {
    ...MacroMath
  }
}
    ${MealPlanTemplateAnthropometryFragmentDoc}
${MealPlanTemplateCalorieBudgetFragmentDoc}
${MacroMathFragmentDoc}`;
export const FullMealPlanTemplateFragmentDoc = gql`
    fragment FullMealPlanTemplate on MealPlanTemplate {
  id
  __typename
  name
  description
  additionalState
  isShared
  pendingState
  teams {
    ...Team
  }
  ... on MacroMealPlanTemplate {
    meals {
      ...MacroMealPlanTemplateMeal
    }
    avoidedFoodGroups {
      ...FullFoodGroup
    }
    promotedFoodGroups {
      ...FullFoodGroup
    }
    avoidedFoods {
      ...FoodLimitedAccess
    }
    promotedFoods {
      ...FoodLimitedAccess
    }
    activities {
      ...MealPlanTemplateActivity
    }
    macroProtocol {
      ...MealPlanTemplateMacroProtocol
    }
  }
}
    ${TeamFragmentDoc}
${MacroMealPlanTemplateMealFragmentDoc}
${FullFoodGroupFragmentDoc}
${FoodLimitedAccessFragmentDoc}
${MealPlanTemplateActivityFragmentDoc}
${MealPlanTemplateMacroProtocolFragmentDoc}`;
export const OrgGroupOrgFragmentDoc = gql`
    fragment OrgGroupOrg on Org {
  id
  name
}
    `;
export const ParentOrgGroupOptionFragmentDoc = gql`
    fragment ParentOrgGroupOption on OrgGroup {
  id
  name
}
    `;
export const EditableOrgGroupFragmentDoc = gql`
    fragment EditableOrgGroup on OrgGroup {
  id
  name
  allowsAthleteTransfer
  allowsResourceSharing
  orgs {
    ...OrgGroupOrg
  }
  parentOrgGroup {
    ...ParentOrgGroupOption
  }
}
    ${OrgGroupOrgFragmentDoc}
${ParentOrgGroupOptionFragmentDoc}`;
export const StaffDashboardOrgGroupFragmentDoc = gql`
    fragment StaffDashboardOrgGroup on OrgGroup {
  id
  name
  orgsCount
  parentOrgGroup {
    id
    name
  }
  createdBy {
    id
    firstName
    lastName
    username
  }
}
    `;
export const OrgLinkerOrgFragmentDoc = gql`
    fragment OrgLinkerOrg on Org {
  id
  name
  teamworksId
  teams {
    name
  }
}
    `;
export const PositionWithMappingsFragmentDoc = gql`
    fragment PositionWithMappings on Position {
  id
  name
  teamworksMappings {
    teamworksName
  }
}
    `;
export const SportWithPositionMappingsFragmentDoc = gql`
    fragment SportWithPositionMappings on Sport {
  id
  name
  positions {
    ...PositionWithMappings
  }
}
    ${PositionWithMappingsFragmentDoc}`;
export const TeamLinkPreviewFragmentDoc = gql`
    fragment TeamLinkPreview on Team {
  id
  gender
  sport {
    ...SportWithPositionMappings
  }
  name
  teamworksId
}
    ${SportWithPositionMappingsFragmentDoc}`;
export const TeamworksProfileSyncRuleFragmentDoc = gql`
    fragment TeamworksProfileSyncRule on TeamworksProfileSyncRule {
  id
  profileIds
  teamIds
  athleteStatusIds
  userTypeIds
  notemealAccountType
  priority
  matchNotemealTeamIds
  positionIds
  genders
}
    `;
export const OrgMembershipForLinkingFragmentDoc = gql`
    fragment OrgMembershipForLinking on OrgMembership {
  __typename
  id
  isActive
  teamworksId
  user {
    id
    teamworksId
    firstName
    lastName
    email
    phoneNumber
    username
  }
  athlete {
    id
    isArchived
    sex
  }
  isNotemealOnly
  roles
}
    `;
export const AthleteForLinkingFragmentDoc = gql`
    fragment AthleteForLinking on Athlete {
  __typename
  id
  isArchived
  firstName
  lastName
  email
  phoneNumber
  birthDate
  user {
    id
  }
  team {
    teamworksId
    id
  }
  sex
  isNotemealOnly
}
    `;
export const OrgForLinkingFragmentDoc = gql`
    fragment OrgForLinking on Org {
  id
  name
  teamworksId
  isFullySynced
  isNotemealLinked
  isTeamworksLinked
  teams {
    ...TeamLinkPreview
  }
  teamworksProfileSyncRules {
    ...TeamworksProfileSyncRule
  }
  memberships(filterInactive: true) {
    ...OrgMembershipForLinking
  }
  athletes(isArchived: false) {
    ...AthleteForLinking
  }
}
    ${TeamLinkPreviewFragmentDoc}
${TeamworksProfileSyncRuleFragmentDoc}
${OrgMembershipForLinkingFragmentDoc}
${AthleteForLinkingFragmentDoc}`;
export const IntegrationFragmentDoc = gql`
    fragment Integration on Integration {
  externalOrgId
  type
}
    `;
export const AgreementFormFragmentDoc = gql`
    fragment AgreementForm on AgreementForm {
  id
  title
  url
  acceptAgreementText
}
    `;
export const ScoringSystemFragmentDoc = gql`
    fragment ScoringSystem on ScoringSystem {
  id
  name
}
    `;
export const StaffDashboardOrgPreviewFragmentDoc = gql`
    fragment StaffDashboardOrgPreview on Org {
  id
  name
  isActive
  allowsPublicAccess
  teamworksId
  integrations {
    ...Integration
  }
  isFullySynced
  isNotemealLinked
  isTeamworksLinked
  agreementForm {
    ...AgreementForm
  }
  scoringSystem {
    ...ScoringSystem
  }
  localeCode
  territoryName
}
    ${IntegrationFragmentDoc}
${AgreementFormFragmentDoc}
${ScoringSystemFragmentDoc}`;
export const TeamworksSportMappingFragmentDoc = gql`
    fragment TeamworksSportMapping on TeamworksSportMapping {
  teamworksName
  sport {
    ...SportWithPositionMappings
  }
}
    ${SportWithPositionMappingsFragmentDoc}`;
export const TeamworksTeamFragmentDoc = gql`
    fragment TeamworksTeam on TeamworksTeam {
  id
  name
  sport
  sportMappings {
    ...TeamworksSportMapping
  }
  notemealTeams {
    id
    name
  }
}
    ${TeamworksSportMappingFragmentDoc}`;
export const TeamworksOrgFragmentDoc = gql`
    fragment TeamworksOrg on TeamworksOrg {
  id
  name
  shortName
  teams {
    ...TeamworksTeam
  }
}
    ${TeamworksTeamFragmentDoc}`;
export const TeamworksProfileMembershipFragmentDoc = gql`
    fragment TeamworksProfileMembership on TeamworksMembership {
  teamId
  teamName
  athleteStatus {
    id
    name
    active
  }
  userTypes {
    userTypeId
    userTypeName
  }
  positions {
    id
    name
  }
}
    `;
export const TeamworksProfileFragmentDoc = gql`
    fragment TeamworksProfile on TeamworksProfile {
  id
  userId
  firstName
  lastName
  email
  cellPhone
  birthDate
  gender
  hasLoginAccess
  active
  memberships {
    ...TeamworksProfileMembership
  }
}
    ${TeamworksProfileMembershipFragmentDoc}`;
export const TeamworksPositionFragmentDoc = gql`
    fragment TeamworksPosition on TeamworksGroup {
  id
  label
  teamId
  type
}
    `;
export const TeamworksPositionLinkFragmentDoc = gql`
    fragment TeamworksPositionLink on TeamworksPositionLink {
  teamworksId
  teamworksTeamId
  position {
    ...PositionWithMappings
  }
}
    ${PositionWithMappingsFragmentDoc}`;
export const TeamworksGroupFragmentDoc = gql`
    fragment TeamworksGroup on TeamworksGroup {
  id
  label
  type
  teamId
}
    `;
export const TeamworksSyncStatusFragmentDoc = gql`
    fragment TeamworksSyncStatus on TeamworksSyncStatus {
  linkedProfileCount
  toDeactivateProfileCount
  unlinkedNotemealProfileCount
  unlinkedTeamworksProfileCount
  updatedAt
  isFullySynced
  isNotemealLinked
  isTeamworksLinked
}
    `;
export const OrgStatusSyncFragmentDoc = gql`
    fragment OrgStatusSync on Org {
  id
  name
  teamworksId
  teamworksSyncStatus {
    ...TeamworksSyncStatus
  }
}
    ${TeamworksSyncStatusFragmentDoc}`;
export const ContactFragmentDoc = gql`
    fragment Contact on RestaurantLocationContact {
  id
  firstName
  lastName
  title
  email
  phoneNumber
  preferredContactMethod
}
    `;
export const RestaurantLocationFragmentDoc = gql`
    fragment RestaurantLocation on RestaurantLocation {
  id
  address {
    displayName
    lat
    long
  }
  cityInfo
  googlePlaceId
  email
  phoneNumber
  notes
  isActive
  contacts {
    ...Contact
  }
  deliveryLocations {
    ...DeliveryLocationMenuLinkForm
  }
}
    ${ContactFragmentDoc}
${DeliveryLocationMenuLinkFormFragmentDoc}`;
export const StaffDashboardRestaurantMenuSectionFragmentDoc = gql`
    fragment StaffDashboardRestaurantMenuSection on RestaurantMenuSection {
  id
  name
  position
  menuItemAppearances {
    ...MenuItemAppearancePreview
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}`;
export const DietFragmentDoc = gql`
    fragment Diet on Diet {
  id
  name
}
    `;
export const RestaurantMenuFragmentDoc = gql`
    fragment RestaurantMenu on RestaurantMenu {
  id
  sections {
    ...StaffDashboardRestaurantMenuSection
  }
  supportedDiets {
    ...Diet
  }
}
    ${StaffDashboardRestaurantMenuSectionFragmentDoc}
${DietFragmentDoc}`;
export const StaffDashboardRestaurantFragmentDoc = gql`
    fragment StaffDashboardRestaurant on Restaurant {
  id
  name
  nutritionixId
  menu {
    ...RestaurantMenu
  }
}
    ${RestaurantMenuFragmentDoc}`;
export const StaffDashboardRestaurantPreviewFragmentDoc = gql`
    fragment StaffDashboardRestaurantPreview on Restaurant {
  id
  menu {
    id
  }
  name
  nutritionixId
  isActive
  athleteVisible
}
    `;
export const RestaurantTypeFragmentDoc = gql`
    fragment RestaurantType on RestaurantType {
  id
  name
}
    `;
export const PaymentMethodFragmentDoc = gql`
    fragment PaymentMethod on PaymentMethod {
  id
  name
}
    `;
export const CuisineFragmentDoc = gql`
    fragment Cuisine on Cuisine {
  id
  name
}
    `;
export const StaffDashboardRestaurantFullFragmentDoc = gql`
    fragment StaffDashboardRestaurantFull on Restaurant {
  id
  name
  nutritionixId
  isActive
  website
  proteinPortionSizeInOunces
  firstPartyDelivery
  athleteVisible
  refundPolicy
  refundDueBeforeInMinutes
  restaurantType {
    ...RestaurantType
  }
  paymentMethods {
    ...PaymentMethod
  }
  cuisines {
    ...Cuisine
  }
}
    ${RestaurantTypeFragmentDoc}
${PaymentMethodFragmentDoc}
${CuisineFragmentDoc}`;
export const TerritoryFragmentDoc = gql`
    fragment Territory on Territory {
  id
  name
  orgs {
    id
  }
}
    `;
export const OrgForTerritoryFragmentDoc = gql`
    fragment OrgForTerritory on Org {
  id
  name
}
    `;
export const StaffDashboardUserPreviewFragmentDoc = gql`
    fragment StaffDashboardUserPreview on User {
  id
  firstName
  lastName
  username
  email
  phoneNumber
  isActive
  teamworksId
  orgInfo {
    org {
      id
      name
    }
    orgMembership {
      id
      isActive
      roles
      teamworksId
    }
    athlete {
      id
      firstName
      lastName
    }
  }
}
    `;
export const StaffDashboardOrgMembershipFragmentDoc = gql`
    fragment StaffDashboardOrgMembership on OrgMembership {
  id
  roles
  isActive
  isAdmin
  lastRefresh
  user {
    id
    firstName
    lastName
    username
    lastLogin
    phoneNumber
    email
    userTeamworksId: teamworksId
  }
  teamworksId
}
    `;
export const FeatureFlagsFragmentDoc = gql`
    fragment FeatureFlags on FeatureFlags {
  athleteMenuSuggestions
  clientCredentials
  hasRestaurantMenus
  restaurantLogging
  teamworksSchedules
  mealPlanSuggestions
  prepPlan
  foodCategory
  pk2MenuBuilder
  orgGroupRecipes
  orgGroupFoods
  performanceKitchen
  printableTags
  hubMenuCheckIn
  goalTracker
  dietitianOnlyMenus
  bulkOrdering
  ukMealPlanOptionSuggestions
  digitalDisplays
  selfServiceOnboarding
  messaging
  eatingDisorderScreens
  profilesV3Api
  mealPlanTemplateBulkAssignments
}
    `;
export const StaffDashboardOrgFragmentDoc = gql`
    fragment StaffDashboardOrg on Org {
  id
  name
  isActive
  allowsPublicAccess
  teamworksId
  memberships {
    ...StaffDashboardOrgMembership
  }
  featureFlags {
    ...FeatureFlags
  }
  integrations {
    ...Integration
  }
  isFullySynced
  isNotemealLinked
  isTeamworksLinked
  hasTwSuperuserPersonId
  agreementForm {
    ...AgreementForm
  }
  scoringSystem {
    ...ScoringSystem
  }
  localeCode
  territoryName
}
    ${StaffDashboardOrgMembershipFragmentDoc}
${FeatureFlagsFragmentDoc}
${IntegrationFragmentDoc}
${AgreementFormFragmentDoc}
${ScoringSystemFragmentDoc}`;
export const NamedTagPreviewFragmentDoc = gql`
    fragment NamedTagPreview on NamedTag {
  tag {
    id
    __typename
    athleteCount
    createdBy {
      id
      firstName
      lastName
    }
  }
  tagName {
    name
    description
    color
    isPrintable
  }
}
    `;
export const NamedTagForEditFragmentDoc = gql`
    fragment NamedTagForEdit on NamedTag {
  tagName {
    name
    description
    isPrintable
  }
  tag {
    id
    isComplement
    __typename
    ... on TeamTag {
      teams {
        ...TeamPreview
      }
    }
    ... on PositionTag {
      positions {
        ...PositionWitSport
      }
    }
    ... on AthleteTag {
      athletes {
        ...AthleteForCustomTag
      }
    }
    ... on ComboTag {
      combinationMethod
      namedChildTags {
        ...NamedTagForSelection
      }
    }
  }
}
    ${TeamPreviewFragmentDoc}
${PositionWitSportFragmentDoc}
${AthleteForCustomTagFragmentDoc}
${NamedTagForSelectionFragmentDoc}`;
export const TeamAthletesMealPlanFragmentDoc = gql`
    fragment TeamAthletesMealPlan on MealPlan {
  ...MealPlan
  wasCreatedFromMealPlanTemplate
  exchangeSet {
    ...ExchangeSetWithExchanges
  }
  macroProtocol {
    ...MacroProtocol
    calorieBudget {
      ...FullCalorieBudget
    }
  }
  schedule {
    ...Schedule
  }
  athlete {
    ...Athlete
  }
  mostRecentEvent {
    ...Event
  }
  events {
    ...Event
  }
  activityTemplates {
    ...ActivityTemplate
  }
  mealTemplates {
    ...MealTemplate
  }
  avoidedFoodGroups {
    id
    name
  }
  promotedFoodGroups {
    id
    name
  }
}
    ${MealPlanFragmentDoc}
${ExchangeSetWithExchangesFragmentDoc}
${MacroProtocolFragmentDoc}
${FullCalorieBudgetFragmentDoc}
${ScheduleFragmentDoc}
${AthleteFragmentDoc}
${EventFragmentDoc}
${ActivityTemplateFragmentDoc}
${MealTemplateFragmentDoc}`;
export const TeamAthleteNoteFragmentDoc = gql`
    fragment TeamAthleteNote on Note {
  id
  datetime
  content
  type {
    id
    name
  }
  athlete {
    id
    firstName
    lastName
  }
}
    `;
export const EditableRosterAthleteUserFragmentDoc = gql`
    fragment EditableRosterAthleteUser on User {
  __typename
  id
  firstName
  lastLogin
  lastName
  username
  dateJoined
}
    `;
export const EditableRosterAthleteOrgMembershipFragmentDoc = gql`
    fragment EditableRosterAthleteOrgMembership on OrgMembership {
  id
  isActive
  teamworksId
}
    `;
export const TeamRosterAthleteFragmentDoc = gql`
    fragment TeamRosterAthlete on Athlete {
  ...Athlete
  team {
    ...Team
  }
  user {
    ...EditableRosterAthleteUser
  }
  orgMembership {
    ...EditableRosterAthleteOrgMembership
  }
  position {
    ...Position
  }
  currentGoal {
    ...FullGoal
  }
  mostRecentAnthropometryEntry {
    ...AnthropometryEntry
  }
  anthropometryEntries {
    ...AnthropometryEntry
  }
}
    ${AthleteFragmentDoc}
${TeamFragmentDoc}
${EditableRosterAthleteUserFragmentDoc}
${EditableRosterAthleteOrgMembershipFragmentDoc}
${PositionFragmentDoc}
${FullGoalFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export const AllTableViewsAthleteFragmentDoc = gql`
    fragment AllTableViewsAthlete on Athlete {
  ...Athlete
  foodLogNotificationSettings {
    ...FoodLogNotificationSettings
  }
  user {
    ...EditableRosterAthleteUser
  }
  orgMembership {
    ...EditableRosterAthleteOrgMembership
  }
  position {
    ...Position
  }
}
    ${AthleteFragmentDoc}
${FoodLogNotificationSettingsFragmentDoc}
${EditableRosterAthleteUserFragmentDoc}
${EditableRosterAthleteOrgMembershipFragmentDoc}
${PositionFragmentDoc}`;
export const EngagementTableAthleteFragmentDoc = gql`
    fragment EngagementTableAthlete on Athlete {
  ...AllTableViewsAthlete
  mealsLoggedCount: timelineMealCount(inLastDays: $inLastDays, requireLog: true)
  mealsOrderedCount: timelineMealCount(
    inLastDays: $inLastDays
    requireOrder: true
  )
  totalMealsCount: timelineMealCount(inLastDays: $inLastDays)
  imageUploadCount(inLastDays: $inLastDays)
  mealPlanViewCount(inLastDays: $inLastDays)
}
    ${AllTableViewsAthleteFragmentDoc}`;
export const PreferencesTableAthleteFragmentDoc = gql`
    fragment PreferencesTableAthlete on Athlete {
  ...AllTableViewsAthlete
  dislikedFoodGroups {
    name
  }
}
    ${AllTableViewsAthleteFragmentDoc}`;
export const MyNamedTagsPreviewFragmentDoc = gql`
    fragment MyNamedTagsPreview on MyNamedTagsPreviewPayload {
  previewNamedTags {
    ...NamedTagForSelection
  }
  total
  hiddenPreviewText
}
    ${NamedTagForSelectionFragmentDoc}`;
export const ProfileTableAthleteFragmentDoc = gql`
    fragment ProfileTableAthlete on Athlete {
  ...AllTableViewsAthlete
  mostRecentAnthropometryEntry {
    ...AnthropometryEntry
  }
  currentGoal {
    ...FullGoal
  }
  myNamedTagsPreview(
    limit: 2
    priorityTagIds: $priorityTagIds
    smartTagsFirst: null
  ) {
    ...MyNamedTagsPreview
  }
}
    ${AllTableViewsAthleteFragmentDoc}
${AnthropometryEntryFragmentDoc}
${FullGoalFragmentDoc}
${MyNamedTagsPreviewFragmentDoc}`;
export const TeamDashboardScheduleFragmentDoc = gql`
    fragment TeamDashboardSchedule on Schedule {
  id
  name
  meals {
    ...Meal
  }
  activities {
    ...Activity
  }
  mealPlans {
    id
  }
}
    ${MealFragmentDoc}
${ActivityFragmentDoc}`;
export const TeamGoalConfigurationFragmentDoc = gql`
    fragment TeamGoalConfiguration on GoalConfiguration {
  id
  name
  emoji
  category {
    name
    defaultEmoji
  }
  startDate
  endDate
  completedGoalCount(endDate: $endDate)
  totalGoalCount(endDate: $endDate)
  athlete {
    id
    firstName
    lastName
    position {
      name
    }
  }
}
    `;
export const TeamPageAthleteFragmentDoc = gql`
    fragment TeamPageAthlete on Athlete {
  ...Athlete
  timelineMealNotifications {
    ...NotificationStub
  }
}
    ${AthleteFragmentDoc}
${NotificationStubFragmentDoc}`;
export const TeamPageTeamFragmentDoc = gql`
    fragment TeamPageTeam on Team {
  ...Team
  foodLogNotificationSettings {
    ...FoodLogNotificationSettings
  }
  sport {
    ...Sport
    positions {
      ...Position
    }
  }
  athletes {
    ...TeamPageAthlete
  }
}
    ${TeamFragmentDoc}
${FoodLogNotificationSettingsFragmentDoc}
${SportFragmentDoc}
${PositionFragmentDoc}
${TeamPageAthleteFragmentDoc}`;
export const TeamsTableTeamFragmentDoc = gql`
    fragment TeamsTableTeam on Team {
  id
  __typename
  name
  sport {
    id
    name
  }
  gender
  athletes {
    id
    activationLinkSent
    isArchived
    orgMembership {
      id
      isActive
    }
  }
  recentNotifications {
    ...NotificationStub
  }
  foodLogNotificationSettings {
    edits
    messages
    imageUploads
  }
  canBeDeleted
}
    ${NotificationStubFragmentDoc}`;
export const ActiveTransferFragmentDoc = gql`
    fragment ActiveTransfer on AthleteTransfer {
  id
  createdAt
  note
  releasedByOrg {
    id
    name
  }
  orgGroup {
    id
    name
  }
  athlete {
    firstName
    lastName
    birthDate
    activeTransfer {
      id
    }
  }
}
    `;
export const ActiveTransfersTeamsOptionFragmentDoc = gql`
    fragment ActiveTransfersTeamsOption on Team {
  id
  name
}
    `;
export const CommentWithNotificationFragmentDoc = gql`
    fragment CommentWithNotification on Comment {
  id
  notification {
    id
    viewedAt
  }
}
    `;
export const ExchangeSummaryLabelFragmentDoc = gql`
    fragment ExchangeSummaryLabel on ExchangeAmount {
  id
  __typename
  amount
  exchange {
    __typename
    id
    name
    shortName
    type
    cho
    pro
    fat
    kcal
  }
}
    `;
export const MenuItemFoodCategoryFragmentDoc = gql`
    fragment MenuItemFoodCategory on MenuItemFoodCategory {
  category
}
    `;
export const MenuOrderRateLimitRemainderFragmentDoc = gql`
    fragment MenuOrderRateLimitRemainder on MenuOrderRateLimitRemainder {
  start
  remaining
}
    `;
export const ViewMealOptionFragmentDoc = gql`
    fragment ViewMealOption on MealOption {
  id
  __typename
  note
  position
  servingAmounts {
    ...FullServingAmount
  }
}
    ${FullServingAmountFragmentDoc}`;
export const ExchangeMealTemplateViewFragmentDoc = gql`
    fragment ExchangeMealTemplateView on ExchangeMealTemplate {
  id
  __typename
  mealOptions {
    ...ViewMealOption
  }
  exchangeTargets {
    ...ExchangeAmount
  }
  meal {
    ...Meal
  }
}
    ${ViewMealOptionFragmentDoc}
${ExchangeAmountFragmentDoc}
${MealFragmentDoc}`;
export const MacroMealTemplateViewFragmentDoc = gql`
    fragment MacroMealTemplateView on MacroMealTemplate {
  id
  __typename
  mealOptions {
    ...ViewMealOption
  }
  meal {
    ...Meal
  }
  cho
  pro
  fat
  kcal
}
    ${ViewMealOptionFragmentDoc}
${MealFragmentDoc}`;
export const MenuItemWithIdsFragmentDoc = gql`
    fragment MenuItemWithIds on MenuItem {
  id
  choices {
    id
    options {
      id
    }
  }
}
    `;
export const MenuSelectionItemOptionWithIdsFragmentDoc = gql`
    fragment MenuSelectionItemOptionWithIds on MenuSelectionItemOption {
  amount
  menuItemChoiceOption {
    id
  }
  percentConsumed
}
    `;
export const MenuSelectionFormFragmentDoc = gql`
    fragment MenuSelectionForm on MenuSelection {
  items {
    ...MenuSelectionItemForm
  }
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const MenuSelectItemOptionWithMacrosFragmentDoc = gql`
    fragment MenuSelectItemOptionWithMacros on MenuSelectionItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    id
    servingAmounts {
      ...FullServingAmount
    }
  }
}
    ${FullServingAmountFragmentDoc}`;
export const MenuSelectionItemWithMacrosFragmentDoc = gql`
    fragment MenuSelectionItemWithMacros on MenuSelectionItem {
  amount
  percentConsumed
  menuItem {
    id
    servingAmounts {
      ...FullServingAmount
    }
  }
  options {
    ...MenuSelectItemOptionWithMacros
  }
}
    ${FullServingAmountFragmentDoc}
${MenuSelectItemOptionWithMacrosFragmentDoc}`;
export const MenuOrderSuggestionFragmentDoc = gql`
    fragment MenuOrderSuggestion on MenuOrderSuggestion {
  items {
    amount
    percentConsumed
    menuItem {
      ...MenuItemForm
    }
    options {
      amount
      percentConsumed
      menuItemChoiceOption {
        ...MenuItemChoiceOptionForm
      }
    }
  }
}
    ${MenuItemFormFragmentDoc}
${MenuItemChoiceOptionFormFragmentDoc}`;
export const ServingWithNutrientAmountsFragmentDoc = gql`
    fragment ServingWithNutrientAmounts on Serving {
  id
  nutrientAmounts {
    ...NutrientAmount
  }
  foodOrRecipe {
    ... on Food {
      id
      name
    }
    ... on Recipe {
      id
      name
    }
  }
}
    ${NutrientAmountFragmentDoc}`;
export const NutrientWithDriFragmentDoc = gql`
    fragment NutrientWithDri on Nutrient {
  id
  name
  unitName
  defaultDietaryReferenceIntake(age: $age, sex: $sex) {
    id
    type
    amount(kcal: $kcal)
  }
}
    `;
export const RestaurantMenuWithItemPreviewsFragmentDoc = gql`
    fragment RestaurantMenuWithItemPreviews on RestaurantMenu {
  id
  sections {
    id
    name
    position
    menuItemAppearances {
      ...MenuItemAppearancePreview
    }
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}`;
export const RestaurantMenuLinkWithMyOrdersFragmentDoc = gql`
    fragment RestaurantMenuLinkWithMyOrders on RestaurantMenuLink {
  id
  allowCustomOrders
  allowPlateOrders
  restaurant {
    id
    name
  }
  order: myOrder {
    ...RestaurantMenuLinkOrder
  }
  plateOrder: myPlateOrder {
    ...RestaurantMenuLinkPlateOrder
  }
}
    ${RestaurantMenuLinkOrderFragmentDoc}
${RestaurantMenuLinkPlateOrderFragmentDoc}`;
export const RestaurantMenuLinkWithOrdersForAthleteFragmentDoc = gql`
    fragment RestaurantMenuLinkWithOrdersForAthlete on RestaurantMenuLink {
  id
  allowCustomOrders
  allowPlateOrders
  restaurant {
    id
    name
  }
  order: orderForAthlete(athleteId: $athleteId) {
    ...RestaurantMenuLinkOrder
  }
  plateOrder: plateOrderForAthlete(athleteId: $athleteId) {
    ...RestaurantMenuLinkPlateOrder
  }
}
    ${RestaurantMenuLinkOrderFragmentDoc}
${RestaurantMenuLinkPlateOrderFragmentDoc}`;
export const RestaurantMenuLogItemFormFragmentDoc = gql`
    fragment RestaurantMenuLogItemForm on RestaurantMenuLogItem {
  id
  ...MenuSelectionItemForm
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const RestaurantMenuLogFragmentDoc = gql`
    fragment RestaurantMenuLog on RestaurantMenuLog {
  id
  mealTemplate {
    id
  }
  items {
    ...RestaurantMenuLogItemForm
  }
  restaurantMenu {
    id
  }
  dateTime
  timezone
  type
}
    ${RestaurantMenuLogItemFormFragmentDoc}`;
export const ServingWithFoodOrRecipeMacrosFragmentDoc = gql`
    fragment ServingWithFoodOrRecipeMacros on Serving {
  id
  __typename
  macros {
    cho
    pro
    fat
    kcal
  }
  foodOrRecipe {
    __typename
    ... on Food {
      id
      name
      usdaFdcDescription
    }
    ... on Recipe {
      id
      name
      createdBy {
        firstName
        lastName
      }
    }
  }
}
    `;
export const TimelineItemForGroupByDateFragmentDoc = gql`
    fragment TimelineItemForGroupByDate on TimelineItem {
  ... on MealPlanDate {
    date
    timezone
    __typename
  }
  ... on FoodLogMeal {
    id
    __typename
  }
  ... on FoodLogActivity {
    id
    __typename
    date
    startTime
    timezone
  }
  ... on MenuOrder {
    id
    __typename
  }
  ... on MealMenu {
    id
    __typename
  }
  ... on RestaurantMenuLog {
    id
    __typename
  }
}
    `;
export const NotificationTimelineFragmentDoc = gql`
    fragment NotificationTimeline on Notification {
  id
  __typename
  viewedAt
}
    `;
export const TimelineMealForGroupByDateFragmentDoc = gql`
    fragment TimelineMealForGroupByDate on TimelineMeal {
  id
  start
  timezone
  notifications {
    ...NotificationTimeline
  }
}
    ${NotificationTimelineFragmentDoc}`;
export const ActivityTemplateSharedTimelineFragmentDoc = gql`
    fragment ActivityTemplateSharedTimeline on ActivityTemplate {
  id
  __typename
  activity {
    id
    name
    start
    end
    type
  }
}
    `;
export const MealPlanSharedTimelineFragmentDoc = gql`
    fragment MealPlanSharedTimeline on MealPlan {
  id
  mealTemplates {
    ...MealTemplateSharedTimeline
  }
  activityTemplates {
    ...ActivityTemplateSharedTimeline
  }
}
    ${MealTemplateSharedTimelineFragmentDoc}
${ActivityTemplateSharedTimelineFragmentDoc}`;
export const MealPlanDateSharedTimelineFragmentDoc = gql`
    fragment MealPlanDateSharedTimeline on MealPlanDate {
  __typename
  date
  timezone
  mealPlan {
    ...MealPlanSharedTimeline
  }
}
    ${MealPlanSharedTimelineFragmentDoc}`;
export const RestaurantMenuLogSharedTimelineFragmentDoc = gql`
    fragment RestaurantMenuLogSharedTimeline on RestaurantMenuLog {
  id
  __typename
  mealType: type
  dateTime
  timezone
  items {
    id
    ...MenuSelectionItemForm
  }
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const MenuOrderSharedTimelineFragmentDoc = gql`
    fragment MenuOrderSharedTimeline on MenuOrder {
  id
  __typename
  mealMenu {
    id
  }
}
    `;
export const MealMenuLogSharedTimelineFragmentDoc = gql`
    fragment MealMenuLogSharedTimeline on MealMenuLog {
  id
  __typename
  mealMenu {
    id
  }
}
    `;
export const TimelineMealSharedTimelineFragmentDoc = gql`
    fragment TimelineMealSharedTimeline on TimelineMeal {
  id
  __typename
  name
  type
  timezone
  start
  durationInMinutes
  mealTemplate {
    id
  }
  mealMenus {
    ...MealMenuSharedTimeline
  }
  servingAmounts {
    ...FullServingAmount
  }
  restaurantMenuLogs {
    ...RestaurantMenuLogSharedTimeline
  }
  restaurantMenuLinkPlateOrders {
    ...RestaurantMenuLinkPlateOrderSharedTimeline
  }
  restaurantMenuLinkOrders {
    ...RestaurantMenuLinkOrderSharedTimeline
  }
  menuOrders {
    ...MenuOrderSharedTimeline
  }
  mealMenuLogs {
    ...MealMenuLogSharedTimeline
  }
}
    ${MealMenuSharedTimelineFragmentDoc}
${FullServingAmountFragmentDoc}
${RestaurantMenuLogSharedTimelineFragmentDoc}
${RestaurantMenuLinkPlateOrderSharedTimelineFragmentDoc}
${RestaurantMenuLinkOrderSharedTimelineFragmentDoc}
${MenuOrderSharedTimelineFragmentDoc}
${MealMenuLogSharedTimelineFragmentDoc}`;
export const FoodLogActivitySharedTimelineFragmentDoc = gql`
    fragment FoodLogActivitySharedTimeline on FoodLogActivity {
  id
  __typename
  name
  activityType: type
  startTime
  endTime
  timezone
  activityTemplate {
    id
  }
}
    `;
export const UnitWithConversionsFragmentDoc = gql`
    fragment UnitWithConversions on Unit {
  id
  name
  conversionsFrom {
    id
    fromQuantity
    toQuantity
    toUnit {
      ...Unit
      isProductionOnly
    }
  }
}
    ${UnitFragmentDoc}`;
export const MealPlanSearchFormFragmentDoc = gql`
    fragment MealPlanSearchForm on MealPlan {
  ...MealPlan
  macroProtocol {
    ...FullMacroProtocol
  }
}
    ${MealPlanFragmentDoc}
${FullMacroProtocolFragmentDoc}`;
export const AthleteWithMealPlansFragmentDoc = gql`
    fragment AthleteWithMealPlans on Athlete {
  ...Athlete
  mealPlans {
    ...MealPlanSearchForm
  }
}
    ${AthleteFragmentDoc}
${MealPlanSearchFormFragmentDoc}`;
export const AthleteWithGoalAndAnthroFragmentDoc = gql`
    fragment AthleteWithGoalAndAnthro on Athlete {
  ...Athlete
  position {
    ...Position
  }
  mostRecentAnthropometryEntry {
    ...AnthropometryEntry
  }
  currentGoal {
    ...FullGoal
  }
}
    ${AthleteFragmentDoc}
${PositionFragmentDoc}
${AnthropometryEntryFragmentDoc}
${FullGoalFragmentDoc}`;
export const ExchangeRatioFragmentDoc = gql`
    fragment ExchangeRatio on ExchangeRatio {
  __typename
  ratio
  exchange {
    ...Exchange
  }
}
    ${ExchangeFragmentDoc}`;
export const FoodOrRecipeExchangeRatiosFragmentDoc = gql`
    fragment FoodOrRecipeExchangeRatios on FoodOrRecipeExchangeRatios {
  id
  __typename
  foodOrRecipeId
  exchangeRatios {
    ...ExchangeRatio
  }
}
    ${ExchangeRatioFragmentDoc}`;
export const FoodPreviewFragmentDoc = gql`
    fragment FoodPreview on Food {
  id
  name
}
    `;
export const BrandedFoodFragmentDoc = gql`
    fragment BrandedFood on BrandedFood {
  id
  __typename
  name
  exchangeTypes
  usdaFdcDescription
  usdaFdcFoodCategoryId
  usdaFdcDataType
  usdaFdcId
  isRecipeIngredientOnly
  nixItemId
  nixTagId
  choPer100g
  proPer100g
  fatPer100g
  kcalPer100g
  thumbnailUrl
  groceryListCategory {
    id
    name
  }
}
    `;
export const GenericFoodFragmentDoc = gql`
    fragment GenericFood on GenericFood {
  id
  __typename
  name
  exchangeTypes
  nixItemId
  nixTagId
  usdaFdcDescription
  usdaFdcFoodCategoryId
  usdaFdcDataType
  usdaFdcId
  isRecipeIngredientOnly
  choPer100g
  proPer100g
  fatPer100g
  kcalPer100g
  thumbnailUrl
  groceryListCategory {
    id
    name
  }
  owner {
    id
    firstName
    lastName
  }
  orgGroup {
    id
    name
  }
}
    `;
export const FoodWithDefaultServingFragmentDoc = gql`
    fragment FoodWithDefaultServing on Food {
  ...Food
  defaultServing {
    ...FullServing
  }
  hasFullAccess
  org {
    id
    name
  }
  ... on BrandedFood {
    __typename
    brand {
      ...Brand
    }
  }
  ... on GenericFood {
    __typename
    orgGroup {
      id
      name
    }
  }
}
    ${FoodFragmentDoc}
${FullServingFragmentDoc}
${BrandFragmentDoc}`;
export const FoodWithServingsFragmentDoc = gql`
    fragment FoodWithServings on Food {
  ...Food
  showGramServing
  showOunceServing
  servings {
    ...ServingWithFoodOrRecipe
  }
}
    ${FoodFragmentDoc}
${ServingWithFoodOrRecipeFragmentDoc}`;
export const CopyableMealPlanFragmentDoc = gql`
    fragment CopyableMealPlan on MealPlan {
  ...BasicMealPlan
  macroProtocol {
    ...MacroProtocol
    calorieBudget {
      ...CalorieBudget
    }
  }
}
    ${BasicMealPlanFragmentDoc}
${MacroProtocolFragmentDoc}
${CalorieBudgetFragmentDoc}`;
export const EditMealPlanFragmentDoc = gql`
    fragment EditMealPlan on MealPlan {
  id
  isAutosaving @client
  exchangeTargets {
    ...EditExchangeAmount
  }
}
    ${EditExchangeAmountFragmentDoc}`;
export const AthleteWithFoodPrefsFragmentDoc = gql`
    fragment AthleteWithFoodPrefs on Athlete {
  ...Athlete
  likedFoods {
    ...Food
  }
  dislikedFoods {
    ...Food
  }
  likedFoodGroups {
    ...FullFoodGroup
  }
  dislikedFoodGroups {
    ...FullFoodGroup
  }
}
    ${AthleteFragmentDoc}
${FoodFragmentDoc}
${FullFoodGroupFragmentDoc}`;
export const ExchangeServingListFragmentDoc = gql`
    fragment ExchangeServingList on ExchangeServingList {
  id
  __typename
  name
}
    `;
export const FullExchangeServingListFragmentDoc = gql`
    fragment FullExchangeServingList on ExchangeServingList {
  ...ExchangeServingList
  servingAmounts {
    ...FullServingAmount
  }
}
    ${ExchangeServingListFragmentDoc}
${FullServingAmountFragmentDoc}`;
export const FullExchangeFragmentDoc = gql`
    fragment FullExchange on Exchange {
  ...Exchange
  exchangeServingList {
    ...FullExchangeServingList
  }
}
    ${ExchangeFragmentDoc}
${FullExchangeServingListFragmentDoc}`;
export const FullExchangeSetWithoutExchangeRatiosFragmentDoc = gql`
    fragment FullExchangeSetWithoutExchangeRatios on ExchangeSet {
  ...ExchangeSet
  exchanges {
    ...FullExchange
  }
}
    ${ExchangeSetFragmentDoc}
${FullExchangeFragmentDoc}`;
export const FullExchangeSetFragmentDoc = gql`
    fragment FullExchangeSet on ExchangeSet {
  ...FullExchangeSetWithoutExchangeRatios
  foodOrRecipeExchangeRatios @client {
    id
    foodOrRecipeId
    __typename
    exchangeRatios {
      __typename
      ratio
      exchange {
        ...Exchange
      }
    }
  }
}
    ${FullExchangeSetWithoutExchangeRatiosFragmentDoc}
${ExchangeFragmentDoc}`;
export const EditFullMealPlanFragmentDoc = gql`
    fragment EditFullMealPlan on MealPlan {
  ...PreviewMealPlan
  ...EditMealPlan
  athlete {
    ...AthleteWithFoodPrefs
    macroDisplaySettings {
      ...MacroMealPlanDisplaySettings
    }
    exchangeDisplaySettings {
      ...ExchangeMealPlanDisplaySettings
    }
  }
  exchangeSet {
    ...FullExchangeSet
  }
  activityTemplates {
    ...ActivityTemplate
  }
  events {
    ...Event
  }
  mealTemplates {
    ... on MacroMealTemplate {
      ...EditFullMacroMealTemplate
    }
    ... on ExchangeMealTemplate {
      ...EditFullExchangeMealTemplate
    }
  }
}
    ${PreviewMealPlanFragmentDoc}
${EditMealPlanFragmentDoc}
${AthleteWithFoodPrefsFragmentDoc}
${MacroMealPlanDisplaySettingsFragmentDoc}
${ExchangeMealPlanDisplaySettingsFragmentDoc}
${FullExchangeSetFragmentDoc}
${ActivityTemplateFragmentDoc}
${EventFragmentDoc}
${EditFullMacroMealTemplateFragmentDoc}
${EditFullExchangeMealTemplateFragmentDoc}`;
export const FullMealTemplateFragmentDoc = gql`
    fragment FullMealTemplate on MealTemplate {
  ...MealTemplate
  mealOptions {
    ...FullMealOption
  }
  pendingMealOptions {
    ...FullMealOptionPending
  }
}
    ${MealTemplateFragmentDoc}
${FullMealOptionFragmentDoc}
${FullMealOptionPendingFragmentDoc}`;
export const FullMacroMealTemplateFragmentDoc = gql`
    fragment FullMacroMealTemplate on MacroMealTemplate {
  ...FullMealTemplate
  ...MacroMealTemplate
}
    ${FullMealTemplateFragmentDoc}
${MacroMealTemplateFragmentDoc}`;
export const FullExchangeMealTemplateFragmentDoc = gql`
    fragment FullExchangeMealTemplate on ExchangeMealTemplate {
  ...FullMealTemplate
  ...ExchangeMealTemplate
  exchangeTargets {
    ...ExchangeAmount
  }
}
    ${FullMealTemplateFragmentDoc}
${ExchangeMealTemplateFragmentDoc}
${ExchangeAmountFragmentDoc}`;
export const FullMealPlanFragmentDoc = gql`
    fragment FullMealPlan on MealPlan {
  ...PreviewMealPlan
  athlete {
    ...AthleteWithFoodPrefs
    macroDisplaySettings {
      ...MacroMealPlanDisplaySettings
    }
    exchangeDisplaySettings {
      ...ExchangeMealPlanDisplaySettings
    }
  }
  exchangeSet {
    ...FullExchangeSet
  }
  activityTemplates {
    ...ActivityTemplate
  }
  events {
    ...Event
  }
  mealTemplates {
    ... on MacroMealTemplate {
      ...FullMacroMealTemplate
    }
    ... on ExchangeMealTemplate {
      ...FullExchangeMealTemplate
    }
  }
}
    ${PreviewMealPlanFragmentDoc}
${AthleteWithFoodPrefsFragmentDoc}
${MacroMealPlanDisplaySettingsFragmentDoc}
${ExchangeMealPlanDisplaySettingsFragmentDoc}
${FullExchangeSetFragmentDoc}
${ActivityTemplateFragmentDoc}
${EventFragmentDoc}
${FullMacroMealTemplateFragmentDoc}
${FullExchangeMealTemplateFragmentDoc}`;
export const ExportMealTemplateFragmentDoc = gql`
    fragment ExportMealTemplate on MealTemplate {
  ...MealTemplate
  mealOptions {
    ...ViewMealOption
  }
}
    ${MealTemplateFragmentDoc}
${ViewMealOptionFragmentDoc}`;
export const NoteFragmentDoc = gql`
    fragment Note on Note {
  id
  __typename
  datetime
  content
}
    `;
export const NoteTypeFragmentDoc = gql`
    fragment NoteType on NoteType {
  id
  __typename
  name
}
    `;
export const NoteWithTypeFragmentDoc = gql`
    fragment NoteWithType on Note {
  ...Note
  type {
    ...NoteType
  }
}
    ${NoteFragmentDoc}
${NoteTypeFragmentDoc}`;
export const FullNoteFragmentDoc = gql`
    fragment FullNote on Note {
  ...NoteWithType
  athlete {
    ...Athlete
  }
}
    ${NoteWithTypeFragmentDoc}
${AthleteFragmentDoc}`;
export const NutrientDietaryReferenceIntakeFragmentDoc = gql`
    fragment NutrientDietaryReferenceIntake on NutrientDietaryReferenceIntake {
  id
  type
}
    `;
export const FullScheduleFragmentDoc = gql`
    fragment FullSchedule on Schedule {
  ...Schedule
  meals {
    ...Meal
  }
  activities {
    ...Activity
  }
  mealPlans {
    ...MealPlan
  }
}
    ${ScheduleFragmentDoc}
${MealFragmentDoc}
${ActivityFragmentDoc}
${MealPlanFragmentDoc}`;
export const ServingWithMacrosFragmentDoc = gql`
    fragment ServingWithMacros on Serving {
  ...Serving
  macros {
    cho
    pro
    fat
    kcal
  }
}
    ${ServingFragmentDoc}`;
export const UsdaFoodFragmentDoc = gql`
    fragment USDAFood on USDAFood {
  id
  __typename
  allHighlightFields
  brandOwner
  dataType
  description
  gtinUpc
  ingredients
  publishedDate
  score
}
    `;
export const UsdaFoodNutrientFragmentDoc = gql`
    fragment USDAFoodNutrient on USDAFoodNutrient {
  id
  amount
  dataPoints
  unitName
  max
  min
  median
  name
  footnote
  __typename
}
    `;
export const UsdaFoodServingFragmentDoc = gql`
    fragment USDAFoodServing on USDAFoodServing {
  __typename
  idx
  cho
  pro
  fat
  weight
  defaultAmount
  units
  isDefault
  usdaWeightSeq
}
    `;
export const UsdaFoodDetailFragmentDoc = gql`
    fragment USDAFoodDetail on USDAFoodDetail {
  __typename
  id
  usdaFdcFoodCategoryId
  usdaFdcDataType
  cho {
    ...USDAFoodNutrient
  }
  pro {
    ...USDAFoodNutrient
  }
  fat {
    ...USDAFoodNutrient
  }
  foodNutrients {
    ...USDAFoodNutrient
  }
  servings {
    ...USDAFoodServing
  }
}
    ${UsdaFoodNutrientFragmentDoc}
${UsdaFoodServingFragmentDoc}`;
export const AthleteWithAnthroFragmentDoc = gql`
    fragment AthleteWithAnthro on Athlete {
  ...Athlete
  position {
    ...Position
  }
  mostRecentAnthropometryEntry {
    ...AnthropometryEntry
  }
}
    ${AthleteFragmentDoc}
${PositionFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export const MealPlanWithAthleteFragmentDoc = gql`
    fragment MealPlanWithAthlete on MealPlan {
  ...MealPlanSearchForm
  athlete {
    ...Athlete
  }
}
    ${MealPlanSearchFormFragmentDoc}
${AthleteFragmentDoc}`;
export const SimpleFoodGroupFragmentDoc = gql`
    fragment SimpleFoodGroup on FoodGroup {
  id
  name
}
    `;
export const MealPlanBulkCopyTeamPreviewFragmentDoc = gql`
    fragment MealPlanBulkCopyTeamPreview on Team {
  name
  id
}
    `;
export const NutritionixCommonFoodFragmentDoc = gql`
    fragment NutritionixCommonFood on NutritionixCommonFood {
  id
  __typename
  name
  nixTagId
  thumbnailUrl
}
    `;
export const NutritionixBrandedFoodFragmentDoc = gql`
    fragment NutritionixBrandedFood on NutritionixBrandedFood {
  id
  __typename
  name
  nixItemId
  brand {
    id
    __typename
    name
    type
  }
  thumbnailUrl
}
    `;
export const PublicMealTemplateFragmentDoc = gql`
    fragment PublicMealTemplate on MealTemplate {
  __typename
  ... on MacroMealTemplate {
    id
    meal {
      __typename
      id
      name
      start
      end
      type
    }
    cho
    pro
    fat
    kcal
  }
  ... on ExchangeMealTemplate {
    id
    meal {
      __typename
      id
      name
      start
      end
      type
    }
    exchangeTargets {
      __typename
      id
      amount
      pickListServingIds
      exchange {
        __typename
        id
        type
        name
        shortName
        cho
        pro
        fat
      }
    }
  }
  mealOptions {
    id
    __typename
    position
    note
    servingAmounts {
      id
      amount
      position
      serving {
        id
        __typename
        units
        isRecipeServingOnly
        defaultAmount
        isDefault
        weight
        usdaWeightSeq
        perRecipeYield
        macros {
          cho
          pro
          fat
          kcal
        }
        foodOrRecipe {
          ... on Food {
            id
            __typename
            name
            usdaFdcDescription
          }
          ... on Recipe {
            id
            __typename
            name
            steps
            note
            exchangeTypes
            prepTimeInMinutes
            cookTimeInMinutes
            isCurrent
            images {
              id
              position
              url
            }
            isShared
            createdBy {
              id
              firstName
              lastName
            }
            mealTypes
            descendantFoods {
              id
            }
            descendantRecipes {
              id
              ingredients {
                id
                amount
                position
                serving {
                  id
                  units
                  isRecipeServingOnly
                  defaultAmount
                  isDefault
                  weight
                  usdaWeightSeq
                  perRecipeYield
                  macros {
                    cho
                    pro
                    fat
                    kcal
                  }
                  foodOrRecipe {
                    ... on Food {
                      id
                      __typename
                      name
                    }
                    ... on Recipe {
                      id
                      __typename
                      name
                    }
                  }
                }
              }
            }
            ingredients {
              id
              amount
              position
              serving {
                id
                __typename
                isRecipeServingOnly
                units
                defaultAmount
                isDefault
                weight
                usdaWeightSeq
                perRecipeYield
                macros {
                  cho
                  pro
                  fat
                  kcal
                }
                foodOrRecipe {
                  ... on Food {
                    id
                    __typename
                    name
                  }
                  ... on Recipe {
                    id
                    __typename
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const PublicMealPlanFragmentDoc = gql`
    fragment PublicMealPlan on MealPlan {
  id
  name
  type
  athlete {
    id
    birthDate
    macroDisplaySettings {
      __typename
      dailyProgressViews
      mealProgressViews
    }
    exchangeDisplaySettings {
      __typename
      dailyProgressViewsForExchanges
      mealProgressViewsForExchanges
      dailyProgressViewsForMacros
      mealProgressViewsForMacros
    }
  }
  macroProtocol {
    id
    __typename
    cho {
      gPerKg
      leftOverRatio
    }
    pro {
      gPerKg
      leftOverRatio
    }
    fat {
      gPerKg
      leftOverRatio
    }
    weightTarget
    anthropometrySnapshot {
      id
      __typename
      height
      weight
      leanBodyMass
      bodyFatMass
      boneMineralDensityZScore
      percentBodyFat
      datetime
      type
      sex
      comment
      boneMineralDensity
      boneMineralDensityTScore
      boneMineralContent
      boneArea
      boneMass
      dryLeanMass
      skeletalMuscleMass
      trunkFat
      visceralFatArea
    }
    calorieBudget {
      id
      __typename
      rmrMethod
      rmrCalories
      activityFactor
      kcalOffset
      goalSnapshot {
        id
        __typename
        kcalOffset
        start
        end
        type {
          id
          __typename
          name
          defaultKcalOffset
        }
      }
    }
  }
  exchangeTargets {
    id
    __typename
    amount
    exchange {
      __typename
      id
      name
      shortName
      type
      cho
      pro
      fat
    }
  }
  exchangeSet {
    id
    name
    exchanges {
      id
      __typename
      type
      name
      shortName
      cho
      pro
      fat
      exchangeServingList {
        id
        name
        servingAmounts {
          id
          amount
          position
          serving {
            id
            __typename
            isRecipeServingOnly
            defaultAmount
            isDefault
            weight
            usdaWeightSeq
            perRecipeYield
            macros {
              cho
              pro
              fat
              kcal
            }
            foodOrRecipe {
              ... on Food {
                id
                __typename
                name
              }
              ... on Recipe {
                id
                __typename
                name
                steps
                note
                prepTimeInMinutes
                cookTimeInMinutes
                isCurrent
                images {
                  id
                  position
                  url
                }
                isShared
                createdBy {
                  id
                  firstName
                  lastName
                }
                mealTypes
                descendantFoods {
                  id
                }
                descendantRecipes {
                  id
                  ingredients {
                    id
                    amount
                    position
                    serving {
                      id
                      units
                      isRecipeServingOnly
                      defaultAmount
                      isDefault
                      weight
                      usdaWeightSeq
                      perRecipeYield
                      macros {
                        cho
                        pro
                        fat
                        kcal
                      }
                      foodOrRecipe {
                        ... on Food {
                          id
                          __typename
                          name
                        }
                        ... on Recipe {
                          id
                          __typename
                          name
                        }
                      }
                    }
                  }
                }
                steps
                note
                ingredients {
                  id
                  amount
                  position
                  serving {
                    id
                    units
                    defaultAmount
                    isDefault
                    weight
                    usdaWeightSeq
                    perRecipeYield
                    macros {
                      cho
                      pro
                      fat
                      kcal
                    }
                    foodOrRecipe {
                      ... on Food {
                        id
                        __typename
                        name
                      }
                      ... on Recipe {
                        id
                        __typename
                        name
                      }
                    }
                  }
                }
              }
            }
            units
            customUnits
            unitPrefix
            unitSuffix
          }
        }
      }
    }
  }
  mealTemplates {
    ...PublicMealTemplate
  }
  activityTemplates {
    id
    __typename
    activity {
      __typename
      id
      name
      start
      end
      type
    }
  }
}
    ${PublicMealTemplateFragmentDoc}`;
export const TeamsPickerTeamsDocument = gql`
    query TeamsPickerTeams {
  teams {
    ...TeamsPickerTeam
  }
}
    ${TeamsPickerTeamFragmentDoc}`;
export type TeamsPickerTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamsPickerTeamsQuery, TeamsPickerTeamsQueryVariables>, 'query'>;

    export const TeamsPickerTeamsComponent = (props: TeamsPickerTeamsComponentProps) => (
      <ApolloReactComponents.Query<TeamsPickerTeamsQuery, TeamsPickerTeamsQueryVariables> query={TeamsPickerTeamsDocument} {...props} />
    );
    

/**
 * __useTeamsPickerTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsPickerTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsPickerTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsPickerTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsPickerTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamsPickerTeamsQuery, TeamsPickerTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamsPickerTeamsQuery, TeamsPickerTeamsQueryVariables>(TeamsPickerTeamsDocument, baseOptions);
      }
export function useTeamsPickerTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamsPickerTeamsQuery, TeamsPickerTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamsPickerTeamsQuery, TeamsPickerTeamsQueryVariables>(TeamsPickerTeamsDocument, baseOptions);
        }
export type TeamsPickerTeamsQueryHookResult = ReturnType<typeof useTeamsPickerTeamsQuery>;
export type TeamsPickerTeamsLazyQueryHookResult = ReturnType<typeof useTeamsPickerTeamsLazyQuery>;
export type TeamsPickerTeamsQueryResult = ApolloReactCommon.QueryResult<TeamsPickerTeamsQuery, TeamsPickerTeamsQueryVariables>;
export const DeleteExchangeSetDocument = gql`
    mutation DeleteExchangeSet($input: DeleteExchangeSetInput!) {
  deleteExchangeSet(input: $input) {
    id
  }
}
    `;
export type DeleteExchangeSetMutationFn = ApolloReactCommon.MutationFunction<DeleteExchangeSetMutation, DeleteExchangeSetMutationVariables>;
export type DeleteExchangeSetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteExchangeSetMutation, DeleteExchangeSetMutationVariables>, 'mutation'>;

    export const DeleteExchangeSetComponent = (props: DeleteExchangeSetComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteExchangeSetMutation, DeleteExchangeSetMutationVariables> mutation={DeleteExchangeSetDocument} {...props} />
    );
    

/**
 * __useDeleteExchangeSetMutation__
 *
 * To run a mutation, you first call `useDeleteExchangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExchangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExchangeSetMutation, { data, loading, error }] = useDeleteExchangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExchangeSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteExchangeSetMutation, DeleteExchangeSetMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteExchangeSetMutation, DeleteExchangeSetMutationVariables>(DeleteExchangeSetDocument, baseOptions);
      }
export type DeleteExchangeSetMutationHookResult = ReturnType<typeof useDeleteExchangeSetMutation>;
export type DeleteExchangeSetMutationResult = ApolloReactCommon.MutationResult<DeleteExchangeSetMutation>;
export type DeleteExchangeSetMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteExchangeSetMutation, DeleteExchangeSetMutationVariables>;
export const GetOpenAiImageDocument = gql`
    query GetOpenAIImage($servingAmount: Float!, $servingUnit: String!, $foodName: String!) {
  getOpenAIImage(
    servingAmount: $servingAmount
    servingUnit: $servingUnit
    foodName: $foodName
  )
}
    `;
export type GetOpenAiImageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOpenAiImageQuery, GetOpenAiImageQueryVariables>, 'query'> & ({ variables: GetOpenAiImageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOpenAiImageComponent = (props: GetOpenAiImageComponentProps) => (
      <ApolloReactComponents.Query<GetOpenAiImageQuery, GetOpenAiImageQueryVariables> query={GetOpenAiImageDocument} {...props} />
    );
    

/**
 * __useGetOpenAiImageQuery__
 *
 * To run a query within a React component, call `useGetOpenAiImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenAiImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenAiImageQuery({
 *   variables: {
 *      servingAmount: // value for 'servingAmount'
 *      servingUnit: // value for 'servingUnit'
 *      foodName: // value for 'foodName'
 *   },
 * });
 */
export function useGetOpenAiImageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpenAiImageQuery, GetOpenAiImageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpenAiImageQuery, GetOpenAiImageQueryVariables>(GetOpenAiImageDocument, baseOptions);
      }
export function useGetOpenAiImageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpenAiImageQuery, GetOpenAiImageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpenAiImageQuery, GetOpenAiImageQueryVariables>(GetOpenAiImageDocument, baseOptions);
        }
export type GetOpenAiImageQueryHookResult = ReturnType<typeof useGetOpenAiImageQuery>;
export type GetOpenAiImageLazyQueryHookResult = ReturnType<typeof useGetOpenAiImageLazyQuery>;
export type GetOpenAiImageQueryResult = ApolloReactCommon.QueryResult<GetOpenAiImageQuery, GetOpenAiImageQueryVariables>;
export const CreateBrandedFoodDocument = gql`
    mutation CreateBrandedFood($input: CreateBrandedFoodInput!) {
  createBrandedFood(input: $input) {
    brandedFood {
      ...SharedEditableFood
    }
  }
}
    ${SharedEditableFoodFragmentDoc}`;
export type CreateBrandedFoodMutationFn = ApolloReactCommon.MutationFunction<CreateBrandedFoodMutation, CreateBrandedFoodMutationVariables>;
export type CreateBrandedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBrandedFoodMutation, CreateBrandedFoodMutationVariables>, 'mutation'>;

    export const CreateBrandedFoodComponent = (props: CreateBrandedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBrandedFoodMutation, CreateBrandedFoodMutationVariables> mutation={CreateBrandedFoodDocument} {...props} />
    );
    

/**
 * __useCreateBrandedFoodMutation__
 *
 * To run a mutation, you first call `useCreateBrandedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandedFoodMutation, { data, loading, error }] = useCreateBrandedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBrandedFoodMutation, CreateBrandedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBrandedFoodMutation, CreateBrandedFoodMutationVariables>(CreateBrandedFoodDocument, baseOptions);
      }
export type CreateBrandedFoodMutationHookResult = ReturnType<typeof useCreateBrandedFoodMutation>;
export type CreateBrandedFoodMutationResult = ApolloReactCommon.MutationResult<CreateBrandedFoodMutation>;
export type CreateBrandedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBrandedFoodMutation, CreateBrandedFoodMutationVariables>;
export const CreateRestaurantBrandedFoodDocument = gql`
    mutation CreateRestaurantBrandedFood($input: CreateRestaurantBrandedFoodInput!) {
  createRestaurantBrandedFood(input: $input) {
    brandedFood {
      ...SharedEditableFood
    }
  }
}
    ${SharedEditableFoodFragmentDoc}`;
export type CreateRestaurantBrandedFoodMutationFn = ApolloReactCommon.MutationFunction<CreateRestaurantBrandedFoodMutation, CreateRestaurantBrandedFoodMutationVariables>;
export type CreateRestaurantBrandedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRestaurantBrandedFoodMutation, CreateRestaurantBrandedFoodMutationVariables>, 'mutation'>;

    export const CreateRestaurantBrandedFoodComponent = (props: CreateRestaurantBrandedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRestaurantBrandedFoodMutation, CreateRestaurantBrandedFoodMutationVariables> mutation={CreateRestaurantBrandedFoodDocument} {...props} />
    );
    

/**
 * __useCreateRestaurantBrandedFoodMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantBrandedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantBrandedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantBrandedFoodMutation, { data, loading, error }] = useCreateRestaurantBrandedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRestaurantBrandedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRestaurantBrandedFoodMutation, CreateRestaurantBrandedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRestaurantBrandedFoodMutation, CreateRestaurantBrandedFoodMutationVariables>(CreateRestaurantBrandedFoodDocument, baseOptions);
      }
export type CreateRestaurantBrandedFoodMutationHookResult = ReturnType<typeof useCreateRestaurantBrandedFoodMutation>;
export type CreateRestaurantBrandedFoodMutationResult = ApolloReactCommon.MutationResult<CreateRestaurantBrandedFoodMutation>;
export type CreateRestaurantBrandedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRestaurantBrandedFoodMutation, CreateRestaurantBrandedFoodMutationVariables>;
export const CreateGenericFoodDocument = gql`
    mutation CreateGenericFood($input: CreateGenericFoodInput!) {
  createGenericFood(input: $input) {
    genericFood {
      ...SharedEditableFood
    }
  }
}
    ${SharedEditableFoodFragmentDoc}`;
export type CreateGenericFoodMutationFn = ApolloReactCommon.MutationFunction<CreateGenericFoodMutation, CreateGenericFoodMutationVariables>;
export type CreateGenericFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGenericFoodMutation, CreateGenericFoodMutationVariables>, 'mutation'>;

    export const CreateGenericFoodComponent = (props: CreateGenericFoodComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGenericFoodMutation, CreateGenericFoodMutationVariables> mutation={CreateGenericFoodDocument} {...props} />
    );
    

/**
 * __useCreateGenericFoodMutation__
 *
 * To run a mutation, you first call `useCreateGenericFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGenericFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGenericFoodMutation, { data, loading, error }] = useCreateGenericFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGenericFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGenericFoodMutation, CreateGenericFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGenericFoodMutation, CreateGenericFoodMutationVariables>(CreateGenericFoodDocument, baseOptions);
      }
export type CreateGenericFoodMutationHookResult = ReturnType<typeof useCreateGenericFoodMutation>;
export type CreateGenericFoodMutationResult = ApolloReactCommon.MutationResult<CreateGenericFoodMutation>;
export type CreateGenericFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGenericFoodMutation, CreateGenericFoodMutationVariables>;
export const CreateOrgGenericFoodDocument = gql`
    mutation CreateOrgGenericFood($input: CreateGenericFoodInput!) {
  createOrgGenericFood(input: $input) {
    genericFood {
      ...OrgEditableFood
    }
  }
}
    ${OrgEditableFoodFragmentDoc}`;
export type CreateOrgGenericFoodMutationFn = ApolloReactCommon.MutationFunction<CreateOrgGenericFoodMutation, CreateOrgGenericFoodMutationVariables>;
export type CreateOrgGenericFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrgGenericFoodMutation, CreateOrgGenericFoodMutationVariables>, 'mutation'>;

    export const CreateOrgGenericFoodComponent = (props: CreateOrgGenericFoodComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrgGenericFoodMutation, CreateOrgGenericFoodMutationVariables> mutation={CreateOrgGenericFoodDocument} {...props} />
    );
    

/**
 * __useCreateOrgGenericFoodMutation__
 *
 * To run a mutation, you first call `useCreateOrgGenericFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgGenericFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgGenericFoodMutation, { data, loading, error }] = useCreateOrgGenericFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgGenericFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgGenericFoodMutation, CreateOrgGenericFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgGenericFoodMutation, CreateOrgGenericFoodMutationVariables>(CreateOrgGenericFoodDocument, baseOptions);
      }
export type CreateOrgGenericFoodMutationHookResult = ReturnType<typeof useCreateOrgGenericFoodMutation>;
export type CreateOrgGenericFoodMutationResult = ApolloReactCommon.MutationResult<CreateOrgGenericFoodMutation>;
export type CreateOrgGenericFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgGenericFoodMutation, CreateOrgGenericFoodMutationVariables>;
export const EditBrandedFoodDocument = gql`
    mutation EditBrandedFood($input: EditBrandedFoodInput!) {
  editBrandedFood(input: $input) {
    brandedFood {
      ...SharedEditableFood
    }
  }
}
    ${SharedEditableFoodFragmentDoc}`;
export type EditBrandedFoodMutationFn = ApolloReactCommon.MutationFunction<EditBrandedFoodMutation, EditBrandedFoodMutationVariables>;
export type EditBrandedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditBrandedFoodMutation, EditBrandedFoodMutationVariables>, 'mutation'>;

    export const EditBrandedFoodComponent = (props: EditBrandedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<EditBrandedFoodMutation, EditBrandedFoodMutationVariables> mutation={EditBrandedFoodDocument} {...props} />
    );
    

/**
 * __useEditBrandedFoodMutation__
 *
 * To run a mutation, you first call `useEditBrandedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBrandedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBrandedFoodMutation, { data, loading, error }] = useEditBrandedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditBrandedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditBrandedFoodMutation, EditBrandedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<EditBrandedFoodMutation, EditBrandedFoodMutationVariables>(EditBrandedFoodDocument, baseOptions);
      }
export type EditBrandedFoodMutationHookResult = ReturnType<typeof useEditBrandedFoodMutation>;
export type EditBrandedFoodMutationResult = ApolloReactCommon.MutationResult<EditBrandedFoodMutation>;
export type EditBrandedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<EditBrandedFoodMutation, EditBrandedFoodMutationVariables>;
export const EditRestaurantBrandedFoodDocument = gql`
    mutation EditRestaurantBrandedFood($input: EditRestaurantBrandedFoodInput!) {
  editRestaurantBrandedFood(input: $input) {
    brandedFood {
      ...SharedEditableFood
    }
  }
}
    ${SharedEditableFoodFragmentDoc}`;
export type EditRestaurantBrandedFoodMutationFn = ApolloReactCommon.MutationFunction<EditRestaurantBrandedFoodMutation, EditRestaurantBrandedFoodMutationVariables>;
export type EditRestaurantBrandedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRestaurantBrandedFoodMutation, EditRestaurantBrandedFoodMutationVariables>, 'mutation'>;

    export const EditRestaurantBrandedFoodComponent = (props: EditRestaurantBrandedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<EditRestaurantBrandedFoodMutation, EditRestaurantBrandedFoodMutationVariables> mutation={EditRestaurantBrandedFoodDocument} {...props} />
    );
    

/**
 * __useEditRestaurantBrandedFoodMutation__
 *
 * To run a mutation, you first call `useEditRestaurantBrandedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRestaurantBrandedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRestaurantBrandedFoodMutation, { data, loading, error }] = useEditRestaurantBrandedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRestaurantBrandedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRestaurantBrandedFoodMutation, EditRestaurantBrandedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRestaurantBrandedFoodMutation, EditRestaurantBrandedFoodMutationVariables>(EditRestaurantBrandedFoodDocument, baseOptions);
      }
export type EditRestaurantBrandedFoodMutationHookResult = ReturnType<typeof useEditRestaurantBrandedFoodMutation>;
export type EditRestaurantBrandedFoodMutationResult = ApolloReactCommon.MutationResult<EditRestaurantBrandedFoodMutation>;
export type EditRestaurantBrandedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRestaurantBrandedFoodMutation, EditRestaurantBrandedFoodMutationVariables>;
export const EditGenericFoodDocument = gql`
    mutation EditGenericFood($input: EditGenericFoodInput!) {
  editGenericFood(input: $input) {
    genericFood {
      ...SharedEditableFood
    }
  }
}
    ${SharedEditableFoodFragmentDoc}`;
export type EditGenericFoodMutationFn = ApolloReactCommon.MutationFunction<EditGenericFoodMutation, EditGenericFoodMutationVariables>;
export type EditGenericFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditGenericFoodMutation, EditGenericFoodMutationVariables>, 'mutation'>;

    export const EditGenericFoodComponent = (props: EditGenericFoodComponentProps) => (
      <ApolloReactComponents.Mutation<EditGenericFoodMutation, EditGenericFoodMutationVariables> mutation={EditGenericFoodDocument} {...props} />
    );
    

/**
 * __useEditGenericFoodMutation__
 *
 * To run a mutation, you first call `useEditGenericFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGenericFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGenericFoodMutation, { data, loading, error }] = useEditGenericFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGenericFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditGenericFoodMutation, EditGenericFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<EditGenericFoodMutation, EditGenericFoodMutationVariables>(EditGenericFoodDocument, baseOptions);
      }
export type EditGenericFoodMutationHookResult = ReturnType<typeof useEditGenericFoodMutation>;
export type EditGenericFoodMutationResult = ApolloReactCommon.MutationResult<EditGenericFoodMutation>;
export type EditGenericFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<EditGenericFoodMutation, EditGenericFoodMutationVariables>;
export const EditOrgGenericFoodDocument = gql`
    mutation EditOrgGenericFood($input: EditGenericFoodInput!) {
  editOrgGenericFood(input: $input) {
    genericFood {
      ...OrgEditableFood
    }
  }
}
    ${OrgEditableFoodFragmentDoc}`;
export type EditOrgGenericFoodMutationFn = ApolloReactCommon.MutationFunction<EditOrgGenericFoodMutation, EditOrgGenericFoodMutationVariables>;
export type EditOrgGenericFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditOrgGenericFoodMutation, EditOrgGenericFoodMutationVariables>, 'mutation'>;

    export const EditOrgGenericFoodComponent = (props: EditOrgGenericFoodComponentProps) => (
      <ApolloReactComponents.Mutation<EditOrgGenericFoodMutation, EditOrgGenericFoodMutationVariables> mutation={EditOrgGenericFoodDocument} {...props} />
    );
    

/**
 * __useEditOrgGenericFoodMutation__
 *
 * To run a mutation, you first call `useEditOrgGenericFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgGenericFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgGenericFoodMutation, { data, loading, error }] = useEditOrgGenericFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgGenericFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrgGenericFoodMutation, EditOrgGenericFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<EditOrgGenericFoodMutation, EditOrgGenericFoodMutationVariables>(EditOrgGenericFoodDocument, baseOptions);
      }
export type EditOrgGenericFoodMutationHookResult = ReturnType<typeof useEditOrgGenericFoodMutation>;
export type EditOrgGenericFoodMutationResult = ApolloReactCommon.MutationResult<EditOrgGenericFoodMutation>;
export type EditOrgGenericFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrgGenericFoodMutation, EditOrgGenericFoodMutationVariables>;
export const OrgEditableFoodsOffsetConnectionDocument = gql`
    query OrgEditableFoodsOffsetConnection($query: String, $pagination: OffsetPaginationInput!) {
  orgFoodOffsetConnection(query: $query, pagination: $pagination) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...OrgEditableFood
    }
  }
}
    ${OrgEditableFoodFragmentDoc}`;
export type OrgEditableFoodsOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgEditableFoodsOffsetConnectionQuery, OrgEditableFoodsOffsetConnectionQueryVariables>, 'query'> & ({ variables: OrgEditableFoodsOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgEditableFoodsOffsetConnectionComponent = (props: OrgEditableFoodsOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<OrgEditableFoodsOffsetConnectionQuery, OrgEditableFoodsOffsetConnectionQueryVariables> query={OrgEditableFoodsOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useOrgEditableFoodsOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useOrgEditableFoodsOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgEditableFoodsOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgEditableFoodsOffsetConnectionQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrgEditableFoodsOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgEditableFoodsOffsetConnectionQuery, OrgEditableFoodsOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgEditableFoodsOffsetConnectionQuery, OrgEditableFoodsOffsetConnectionQueryVariables>(OrgEditableFoodsOffsetConnectionDocument, baseOptions);
      }
export function useOrgEditableFoodsOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgEditableFoodsOffsetConnectionQuery, OrgEditableFoodsOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgEditableFoodsOffsetConnectionQuery, OrgEditableFoodsOffsetConnectionQueryVariables>(OrgEditableFoodsOffsetConnectionDocument, baseOptions);
        }
export type OrgEditableFoodsOffsetConnectionQueryHookResult = ReturnType<typeof useOrgEditableFoodsOffsetConnectionQuery>;
export type OrgEditableFoodsOffsetConnectionLazyQueryHookResult = ReturnType<typeof useOrgEditableFoodsOffsetConnectionLazyQuery>;
export type OrgEditableFoodsOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<OrgEditableFoodsOffsetConnectionQuery, OrgEditableFoodsOffsetConnectionQueryVariables>;
export const OrgGroupFoodsOffsetConnectionDocument = gql`
    query OrgGroupFoodsOffsetConnection($query: String, $pagination: OffsetPaginationInput!, $ownerIds: [String!]) {
  orgGroupFoodOffsetConnection(
    query: $query
    pagination: $pagination
    ownerIds: $ownerIds
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...OrgEditableFood
    }
  }
}
    ${OrgEditableFoodFragmentDoc}`;
export type OrgGroupFoodsOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgGroupFoodsOffsetConnectionQuery, OrgGroupFoodsOffsetConnectionQueryVariables>, 'query'> & ({ variables: OrgGroupFoodsOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgGroupFoodsOffsetConnectionComponent = (props: OrgGroupFoodsOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<OrgGroupFoodsOffsetConnectionQuery, OrgGroupFoodsOffsetConnectionQueryVariables> query={OrgGroupFoodsOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useOrgGroupFoodsOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useOrgGroupFoodsOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgGroupFoodsOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgGroupFoodsOffsetConnectionQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      ownerIds: // value for 'ownerIds'
 *   },
 * });
 */
export function useOrgGroupFoodsOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgGroupFoodsOffsetConnectionQuery, OrgGroupFoodsOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgGroupFoodsOffsetConnectionQuery, OrgGroupFoodsOffsetConnectionQueryVariables>(OrgGroupFoodsOffsetConnectionDocument, baseOptions);
      }
export function useOrgGroupFoodsOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgGroupFoodsOffsetConnectionQuery, OrgGroupFoodsOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgGroupFoodsOffsetConnectionQuery, OrgGroupFoodsOffsetConnectionQueryVariables>(OrgGroupFoodsOffsetConnectionDocument, baseOptions);
        }
export type OrgGroupFoodsOffsetConnectionQueryHookResult = ReturnType<typeof useOrgGroupFoodsOffsetConnectionQuery>;
export type OrgGroupFoodsOffsetConnectionLazyQueryHookResult = ReturnType<typeof useOrgGroupFoodsOffsetConnectionLazyQuery>;
export type OrgGroupFoodsOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<OrgGroupFoodsOffsetConnectionQuery, OrgGroupFoodsOffsetConnectionQueryVariables>;
export const OrgEditableFoodDocument = gql`
    query OrgEditableFood($id: ID!) {
  food(id: $id) {
    ...OrgEditableFood
  }
}
    ${OrgEditableFoodFragmentDoc}`;
export type OrgEditableFoodComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgEditableFoodQuery, OrgEditableFoodQueryVariables>, 'query'> & ({ variables: OrgEditableFoodQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgEditableFoodComponent = (props: OrgEditableFoodComponentProps) => (
      <ApolloReactComponents.Query<OrgEditableFoodQuery, OrgEditableFoodQueryVariables> query={OrgEditableFoodDocument} {...props} />
    );
    

/**
 * __useOrgEditableFoodQuery__
 *
 * To run a query within a React component, call `useOrgEditableFoodQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgEditableFoodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgEditableFoodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgEditableFoodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgEditableFoodQuery, OrgEditableFoodQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgEditableFoodQuery, OrgEditableFoodQueryVariables>(OrgEditableFoodDocument, baseOptions);
      }
export function useOrgEditableFoodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgEditableFoodQuery, OrgEditableFoodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgEditableFoodQuery, OrgEditableFoodQueryVariables>(OrgEditableFoodDocument, baseOptions);
        }
export type OrgEditableFoodQueryHookResult = ReturnType<typeof useOrgEditableFoodQuery>;
export type OrgEditableFoodLazyQueryHookResult = ReturnType<typeof useOrgEditableFoodLazyQuery>;
export type OrgEditableFoodQueryResult = ApolloReactCommon.QueryResult<OrgEditableFoodQuery, OrgEditableFoodQueryVariables>;
export const SharedEditableFoodsOffsetConnectionDocument = gql`
    query SharedEditableFoodsOffsetConnection($query: String, $pagination: OffsetPaginationInput!, $localeCodes: [String!], $dataSources: [FoodDataSource!]) {
  foodOffsetConnection(
    query: $query
    pagination: $pagination
    localeCodes: $localeCodes
    dataSources: $dataSources
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...SharedEditableFood
    }
  }
}
    ${SharedEditableFoodFragmentDoc}`;
export type SharedEditableFoodsOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SharedEditableFoodsOffsetConnectionQuery, SharedEditableFoodsOffsetConnectionQueryVariables>, 'query'> & ({ variables: SharedEditableFoodsOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SharedEditableFoodsOffsetConnectionComponent = (props: SharedEditableFoodsOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<SharedEditableFoodsOffsetConnectionQuery, SharedEditableFoodsOffsetConnectionQueryVariables> query={SharedEditableFoodsOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useSharedEditableFoodsOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useSharedEditableFoodsOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedEditableFoodsOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedEditableFoodsOffsetConnectionQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      localeCodes: // value for 'localeCodes'
 *      dataSources: // value for 'dataSources'
 *   },
 * });
 */
export function useSharedEditableFoodsOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedEditableFoodsOffsetConnectionQuery, SharedEditableFoodsOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedEditableFoodsOffsetConnectionQuery, SharedEditableFoodsOffsetConnectionQueryVariables>(SharedEditableFoodsOffsetConnectionDocument, baseOptions);
      }
export function useSharedEditableFoodsOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedEditableFoodsOffsetConnectionQuery, SharedEditableFoodsOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedEditableFoodsOffsetConnectionQuery, SharedEditableFoodsOffsetConnectionQueryVariables>(SharedEditableFoodsOffsetConnectionDocument, baseOptions);
        }
export type SharedEditableFoodsOffsetConnectionQueryHookResult = ReturnType<typeof useSharedEditableFoodsOffsetConnectionQuery>;
export type SharedEditableFoodsOffsetConnectionLazyQueryHookResult = ReturnType<typeof useSharedEditableFoodsOffsetConnectionLazyQuery>;
export type SharedEditableFoodsOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<SharedEditableFoodsOffsetConnectionQuery, SharedEditableFoodsOffsetConnectionQueryVariables>;
export const RestaurantEditableFoodsOffsetConnectionDocument = gql`
    query RestaurantEditableFoodsOffsetConnection($query: String, $pagination: OffsetPaginationInput!, $restaurantId: ID!) {
  restaurantFoodOffsetConnection(
    query: $query
    pagination: $pagination
    restaurantId: $restaurantId
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...SharedEditableFood
    }
  }
}
    ${SharedEditableFoodFragmentDoc}`;
export type RestaurantEditableFoodsOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantEditableFoodsOffsetConnectionQuery, RestaurantEditableFoodsOffsetConnectionQueryVariables>, 'query'> & ({ variables: RestaurantEditableFoodsOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantEditableFoodsOffsetConnectionComponent = (props: RestaurantEditableFoodsOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<RestaurantEditableFoodsOffsetConnectionQuery, RestaurantEditableFoodsOffsetConnectionQueryVariables> query={RestaurantEditableFoodsOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useRestaurantEditableFoodsOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useRestaurantEditableFoodsOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantEditableFoodsOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantEditableFoodsOffsetConnectionQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useRestaurantEditableFoodsOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantEditableFoodsOffsetConnectionQuery, RestaurantEditableFoodsOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantEditableFoodsOffsetConnectionQuery, RestaurantEditableFoodsOffsetConnectionQueryVariables>(RestaurantEditableFoodsOffsetConnectionDocument, baseOptions);
      }
export function useRestaurantEditableFoodsOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantEditableFoodsOffsetConnectionQuery, RestaurantEditableFoodsOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantEditableFoodsOffsetConnectionQuery, RestaurantEditableFoodsOffsetConnectionQueryVariables>(RestaurantEditableFoodsOffsetConnectionDocument, baseOptions);
        }
export type RestaurantEditableFoodsOffsetConnectionQueryHookResult = ReturnType<typeof useRestaurantEditableFoodsOffsetConnectionQuery>;
export type RestaurantEditableFoodsOffsetConnectionLazyQueryHookResult = ReturnType<typeof useRestaurantEditableFoodsOffsetConnectionLazyQuery>;
export type RestaurantEditableFoodsOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<RestaurantEditableFoodsOffsetConnectionQuery, RestaurantEditableFoodsOffsetConnectionQueryVariables>;
export const DeprecateRestaurantBrandedFoodDocument = gql`
    mutation DeprecateRestaurantBrandedFood($input: DeprecateRestaurantBrandedFoodInput!) {
  deprecateRestaurantBrandedFood(input: $input) {
    foodId
  }
}
    `;
export type DeprecateRestaurantBrandedFoodMutationFn = ApolloReactCommon.MutationFunction<DeprecateRestaurantBrandedFoodMutation, DeprecateRestaurantBrandedFoodMutationVariables>;
export type DeprecateRestaurantBrandedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeprecateRestaurantBrandedFoodMutation, DeprecateRestaurantBrandedFoodMutationVariables>, 'mutation'>;

    export const DeprecateRestaurantBrandedFoodComponent = (props: DeprecateRestaurantBrandedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<DeprecateRestaurantBrandedFoodMutation, DeprecateRestaurantBrandedFoodMutationVariables> mutation={DeprecateRestaurantBrandedFoodDocument} {...props} />
    );
    

/**
 * __useDeprecateRestaurantBrandedFoodMutation__
 *
 * To run a mutation, you first call `useDeprecateRestaurantBrandedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeprecateRestaurantBrandedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deprecateRestaurantBrandedFoodMutation, { data, loading, error }] = useDeprecateRestaurantBrandedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeprecateRestaurantBrandedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeprecateRestaurantBrandedFoodMutation, DeprecateRestaurantBrandedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<DeprecateRestaurantBrandedFoodMutation, DeprecateRestaurantBrandedFoodMutationVariables>(DeprecateRestaurantBrandedFoodDocument, baseOptions);
      }
export type DeprecateRestaurantBrandedFoodMutationHookResult = ReturnType<typeof useDeprecateRestaurantBrandedFoodMutation>;
export type DeprecateRestaurantBrandedFoodMutationResult = ApolloReactCommon.MutationResult<DeprecateRestaurantBrandedFoodMutation>;
export type DeprecateRestaurantBrandedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<DeprecateRestaurantBrandedFoodMutation, DeprecateRestaurantBrandedFoodMutationVariables>;
export const GramUnitDocument = gql`
    query GramUnit {
  gramUnit {
    ...UnitWithConversions
  }
}
    ${UnitWithConversionsFragmentDoc}`;
export type GramUnitComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GramUnitQuery, GramUnitQueryVariables>, 'query'>;

    export const GramUnitComponent = (props: GramUnitComponentProps) => (
      <ApolloReactComponents.Query<GramUnitQuery, GramUnitQueryVariables> query={GramUnitDocument} {...props} />
    );
    

/**
 * __useGramUnitQuery__
 *
 * To run a query within a React component, call `useGramUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGramUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGramUnitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGramUnitQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GramUnitQuery, GramUnitQueryVariables>) {
        return ApolloReactHooks.useQuery<GramUnitQuery, GramUnitQueryVariables>(GramUnitDocument, baseOptions);
      }
export function useGramUnitLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GramUnitQuery, GramUnitQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GramUnitQuery, GramUnitQueryVariables>(GramUnitDocument, baseOptions);
        }
export type GramUnitQueryHookResult = ReturnType<typeof useGramUnitQuery>;
export type GramUnitLazyQueryHookResult = ReturnType<typeof useGramUnitLazyQuery>;
export type GramUnitQueryResult = ApolloReactCommon.QueryResult<GramUnitQuery, GramUnitQueryVariables>;
export const MoveOrgFoodToOrgGroupDocument = gql`
    mutation MoveOrgFoodToOrgGroup($input: MoveOrgFoodToOrgGroupInput!) {
  moveOrgFoodToOrgGroup(input: $input) {
    food {
      ...OrgEditableFood
    }
  }
}
    ${OrgEditableFoodFragmentDoc}`;
export type MoveOrgFoodToOrgGroupMutationFn = ApolloReactCommon.MutationFunction<MoveOrgFoodToOrgGroupMutation, MoveOrgFoodToOrgGroupMutationVariables>;
export type MoveOrgFoodToOrgGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveOrgFoodToOrgGroupMutation, MoveOrgFoodToOrgGroupMutationVariables>, 'mutation'>;

    export const MoveOrgFoodToOrgGroupComponent = (props: MoveOrgFoodToOrgGroupComponentProps) => (
      <ApolloReactComponents.Mutation<MoveOrgFoodToOrgGroupMutation, MoveOrgFoodToOrgGroupMutationVariables> mutation={MoveOrgFoodToOrgGroupDocument} {...props} />
    );
    

/**
 * __useMoveOrgFoodToOrgGroupMutation__
 *
 * To run a mutation, you first call `useMoveOrgFoodToOrgGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveOrgFoodToOrgGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveOrgFoodToOrgGroupMutation, { data, loading, error }] = useMoveOrgFoodToOrgGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveOrgFoodToOrgGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveOrgFoodToOrgGroupMutation, MoveOrgFoodToOrgGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveOrgFoodToOrgGroupMutation, MoveOrgFoodToOrgGroupMutationVariables>(MoveOrgFoodToOrgGroupDocument, baseOptions);
      }
export type MoveOrgFoodToOrgGroupMutationHookResult = ReturnType<typeof useMoveOrgFoodToOrgGroupMutation>;
export type MoveOrgFoodToOrgGroupMutationResult = ApolloReactCommon.MutationResult<MoveOrgFoodToOrgGroupMutation>;
export type MoveOrgFoodToOrgGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveOrgFoodToOrgGroupMutation, MoveOrgFoodToOrgGroupMutationVariables>;
export const MoveOrgGroupFoodToMyOrgDocument = gql`
    mutation MoveOrgGroupFoodToMyOrg($input: MoveOrgGroupFoodToMyOrgInput!) {
  moveOrgGroupFoodToMyOrg(input: $input) {
    food {
      ...OrgEditableFood
    }
  }
}
    ${OrgEditableFoodFragmentDoc}`;
export type MoveOrgGroupFoodToMyOrgMutationFn = ApolloReactCommon.MutationFunction<MoveOrgGroupFoodToMyOrgMutation, MoveOrgGroupFoodToMyOrgMutationVariables>;
export type MoveOrgGroupFoodToMyOrgComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveOrgGroupFoodToMyOrgMutation, MoveOrgGroupFoodToMyOrgMutationVariables>, 'mutation'>;

    export const MoveOrgGroupFoodToMyOrgComponent = (props: MoveOrgGroupFoodToMyOrgComponentProps) => (
      <ApolloReactComponents.Mutation<MoveOrgGroupFoodToMyOrgMutation, MoveOrgGroupFoodToMyOrgMutationVariables> mutation={MoveOrgGroupFoodToMyOrgDocument} {...props} />
    );
    

/**
 * __useMoveOrgGroupFoodToMyOrgMutation__
 *
 * To run a mutation, you first call `useMoveOrgGroupFoodToMyOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveOrgGroupFoodToMyOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveOrgGroupFoodToMyOrgMutation, { data, loading, error }] = useMoveOrgGroupFoodToMyOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveOrgGroupFoodToMyOrgMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveOrgGroupFoodToMyOrgMutation, MoveOrgGroupFoodToMyOrgMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveOrgGroupFoodToMyOrgMutation, MoveOrgGroupFoodToMyOrgMutationVariables>(MoveOrgGroupFoodToMyOrgDocument, baseOptions);
      }
export type MoveOrgGroupFoodToMyOrgMutationHookResult = ReturnType<typeof useMoveOrgGroupFoodToMyOrgMutation>;
export type MoveOrgGroupFoodToMyOrgMutationResult = ApolloReactCommon.MutationResult<MoveOrgGroupFoodToMyOrgMutation>;
export type MoveOrgGroupFoodToMyOrgMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveOrgGroupFoodToMyOrgMutation, MoveOrgGroupFoodToMyOrgMutationVariables>;
export const OrgGroupFoodOwnerCursorConnectionDocument = gql`
    query OrgGroupFoodOwnerCursorConnection($pagination: CursorPaginationInput!, $searchText: String!) {
  orgGroupFoodOwnerCursorConnection(
    pagination: $pagination
    searchText: $searchText
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...FoodOwnerPreview
    }
  }
}
    ${FoodOwnerPreviewFragmentDoc}`;
export type OrgGroupFoodOwnerCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgGroupFoodOwnerCursorConnectionQuery, OrgGroupFoodOwnerCursorConnectionQueryVariables>, 'query'> & ({ variables: OrgGroupFoodOwnerCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgGroupFoodOwnerCursorConnectionComponent = (props: OrgGroupFoodOwnerCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<OrgGroupFoodOwnerCursorConnectionQuery, OrgGroupFoodOwnerCursorConnectionQueryVariables> query={OrgGroupFoodOwnerCursorConnectionDocument} {...props} />
    );
    

/**
 * __useOrgGroupFoodOwnerCursorConnectionQuery__
 *
 * To run a query within a React component, call `useOrgGroupFoodOwnerCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgGroupFoodOwnerCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgGroupFoodOwnerCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useOrgGroupFoodOwnerCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgGroupFoodOwnerCursorConnectionQuery, OrgGroupFoodOwnerCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgGroupFoodOwnerCursorConnectionQuery, OrgGroupFoodOwnerCursorConnectionQueryVariables>(OrgGroupFoodOwnerCursorConnectionDocument, baseOptions);
      }
export function useOrgGroupFoodOwnerCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgGroupFoodOwnerCursorConnectionQuery, OrgGroupFoodOwnerCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgGroupFoodOwnerCursorConnectionQuery, OrgGroupFoodOwnerCursorConnectionQueryVariables>(OrgGroupFoodOwnerCursorConnectionDocument, baseOptions);
        }
export type OrgGroupFoodOwnerCursorConnectionQueryHookResult = ReturnType<typeof useOrgGroupFoodOwnerCursorConnectionQuery>;
export type OrgGroupFoodOwnerCursorConnectionLazyQueryHookResult = ReturnType<typeof useOrgGroupFoodOwnerCursorConnectionLazyQuery>;
export type OrgGroupFoodOwnerCursorConnectionQueryResult = ApolloReactCommon.QueryResult<OrgGroupFoodOwnerCursorConnectionQuery, OrgGroupFoodOwnerCursorConnectionQueryVariables>;
export const CompassRecipesDocument = gql`
    query CompassRecipes($query: String!) {
  compass_recipes(query: $query) {
    ...CompassRecipe
  }
}
    ${CompassRecipeFragmentDoc}`;
export type CompassRecipesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompassRecipesQuery, CompassRecipesQueryVariables>, 'query'> & ({ variables: CompassRecipesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompassRecipesComponent = (props: CompassRecipesComponentProps) => (
      <ApolloReactComponents.Query<CompassRecipesQuery, CompassRecipesQueryVariables> query={CompassRecipesDocument} {...props} />
    );
    

/**
 * __useCompassRecipesQuery__
 *
 * To run a query within a React component, call `useCompassRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompassRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompassRecipesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCompassRecipesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompassRecipesQuery, CompassRecipesQueryVariables>) {
        return ApolloReactHooks.useQuery<CompassRecipesQuery, CompassRecipesQueryVariables>(CompassRecipesDocument, baseOptions);
      }
export function useCompassRecipesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompassRecipesQuery, CompassRecipesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompassRecipesQuery, CompassRecipesQueryVariables>(CompassRecipesDocument, baseOptions);
        }
export type CompassRecipesQueryHookResult = ReturnType<typeof useCompassRecipesQuery>;
export type CompassRecipesLazyQueryHookResult = ReturnType<typeof useCompassRecipesLazyQuery>;
export type CompassRecipesQueryResult = ApolloReactCommon.QueryResult<CompassRecipesQuery, CompassRecipesQueryVariables>;
export const CompassMrnRecipeDocument = gql`
    query CompassMrnRecipe($query: String!) {
  compass_mrn_recipe(query: $query) {
    ...CompassRecipe
  }
}
    ${CompassRecipeFragmentDoc}`;
export type CompassMrnRecipeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompassMrnRecipeQuery, CompassMrnRecipeQueryVariables>, 'query'> & ({ variables: CompassMrnRecipeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompassMrnRecipeComponent = (props: CompassMrnRecipeComponentProps) => (
      <ApolloReactComponents.Query<CompassMrnRecipeQuery, CompassMrnRecipeQueryVariables> query={CompassMrnRecipeDocument} {...props} />
    );
    

/**
 * __useCompassMrnRecipeQuery__
 *
 * To run a query within a React component, call `useCompassMrnRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompassMrnRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompassMrnRecipeQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCompassMrnRecipeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompassMrnRecipeQuery, CompassMrnRecipeQueryVariables>) {
        return ApolloReactHooks.useQuery<CompassMrnRecipeQuery, CompassMrnRecipeQueryVariables>(CompassMrnRecipeDocument, baseOptions);
      }
export function useCompassMrnRecipeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompassMrnRecipeQuery, CompassMrnRecipeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompassMrnRecipeQuery, CompassMrnRecipeQueryVariables>(CompassMrnRecipeDocument, baseOptions);
        }
export type CompassMrnRecipeQueryHookResult = ReturnType<typeof useCompassMrnRecipeQuery>;
export type CompassMrnRecipeLazyQueryHookResult = ReturnType<typeof useCompassMrnRecipeLazyQuery>;
export type CompassMrnRecipeQueryResult = ApolloReactCommon.QueryResult<CompassMrnRecipeQuery, CompassMrnRecipeQueryVariables>;
export const ImportCompassRecipeDocument = gql`
    mutation ImportCompassRecipe($input: ImportCompassRecipeInput!) {
  importCompassRecipe(input: $input) {
    recipe {
      ...RecipeSearch
    }
  }
}
    ${RecipeSearchFragmentDoc}`;
export type ImportCompassRecipeMutationFn = ApolloReactCommon.MutationFunction<ImportCompassRecipeMutation, ImportCompassRecipeMutationVariables>;
export type ImportCompassRecipeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportCompassRecipeMutation, ImportCompassRecipeMutationVariables>, 'mutation'>;

    export const ImportCompassRecipeComponent = (props: ImportCompassRecipeComponentProps) => (
      <ApolloReactComponents.Mutation<ImportCompassRecipeMutation, ImportCompassRecipeMutationVariables> mutation={ImportCompassRecipeDocument} {...props} />
    );
    

/**
 * __useImportCompassRecipeMutation__
 *
 * To run a mutation, you first call `useImportCompassRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCompassRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCompassRecipeMutation, { data, loading, error }] = useImportCompassRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCompassRecipeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportCompassRecipeMutation, ImportCompassRecipeMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportCompassRecipeMutation, ImportCompassRecipeMutationVariables>(ImportCompassRecipeDocument, baseOptions);
      }
export type ImportCompassRecipeMutationHookResult = ReturnType<typeof useImportCompassRecipeMutation>;
export type ImportCompassRecipeMutationResult = ApolloReactCommon.MutationResult<ImportCompassRecipeMutation>;
export type ImportCompassRecipeMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportCompassRecipeMutation, ImportCompassRecipeMutationVariables>;
export const LocalesDocument = gql`
    query Locales {
  locales {
    ...Locale
  }
}
    ${LocaleFragmentDoc}`;
export type LocalesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LocalesQuery, LocalesQueryVariables>, 'query'>;

    export const LocalesComponent = (props: LocalesComponentProps) => (
      <ApolloReactComponents.Query<LocalesQuery, LocalesQueryVariables> query={LocalesDocument} {...props} />
    );
    

/**
 * __useLocalesQuery__
 *
 * To run a query within a React component, call `useLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocalesQuery, LocalesQueryVariables>) {
        return ApolloReactHooks.useQuery<LocalesQuery, LocalesQueryVariables>(LocalesDocument, baseOptions);
      }
export function useLocalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocalesQuery, LocalesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocalesQuery, LocalesQueryVariables>(LocalesDocument, baseOptions);
        }
export type LocalesQueryHookResult = ReturnType<typeof useLocalesQuery>;
export type LocalesLazyQueryHookResult = ReturnType<typeof useLocalesLazyQuery>;
export type LocalesQueryResult = ApolloReactCommon.QueryResult<LocalesQuery, LocalesQueryVariables>;
export const MealOptionSuggestionCursorConnectionDocument = gql`
    query MealOptionSuggestionCursorConnection($macros: MacrosInput!, $mealType: MealType!, $maxErrorPercent: Int!, $minErrorPercent: Int, $avoidFoodIds: [ID!], $avoidFoodGroupIds: [ID!], $pagination: CursorPaginationInput, $localeCode: String) {
  mealOptionSuggestionCursorConnection(
    macros: $macros
    mealType: $mealType
    maxErrorPercent: $maxErrorPercent
    minErrorPercent: $minErrorPercent
    avoidFoodIds: $avoidFoodIds
    avoidFoodGroupIds: $avoidFoodGroupIds
    pagination: $pagination
    localeCode: $localeCode
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...FullServingAmountGroup
    }
  }
}
    ${FullServingAmountGroupFragmentDoc}`;
export type MealOptionSuggestionCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealOptionSuggestionCursorConnectionQuery, MealOptionSuggestionCursorConnectionQueryVariables>, 'query'> & ({ variables: MealOptionSuggestionCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealOptionSuggestionCursorConnectionComponent = (props: MealOptionSuggestionCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<MealOptionSuggestionCursorConnectionQuery, MealOptionSuggestionCursorConnectionQueryVariables> query={MealOptionSuggestionCursorConnectionDocument} {...props} />
    );
    

/**
 * __useMealOptionSuggestionCursorConnectionQuery__
 *
 * To run a query within a React component, call `useMealOptionSuggestionCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealOptionSuggestionCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealOptionSuggestionCursorConnectionQuery({
 *   variables: {
 *      macros: // value for 'macros'
 *      mealType: // value for 'mealType'
 *      maxErrorPercent: // value for 'maxErrorPercent'
 *      minErrorPercent: // value for 'minErrorPercent'
 *      avoidFoodIds: // value for 'avoidFoodIds'
 *      avoidFoodGroupIds: // value for 'avoidFoodGroupIds'
 *      pagination: // value for 'pagination'
 *      localeCode: // value for 'localeCode'
 *   },
 * });
 */
export function useMealOptionSuggestionCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealOptionSuggestionCursorConnectionQuery, MealOptionSuggestionCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<MealOptionSuggestionCursorConnectionQuery, MealOptionSuggestionCursorConnectionQueryVariables>(MealOptionSuggestionCursorConnectionDocument, baseOptions);
      }
export function useMealOptionSuggestionCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealOptionSuggestionCursorConnectionQuery, MealOptionSuggestionCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealOptionSuggestionCursorConnectionQuery, MealOptionSuggestionCursorConnectionQueryVariables>(MealOptionSuggestionCursorConnectionDocument, baseOptions);
        }
export type MealOptionSuggestionCursorConnectionQueryHookResult = ReturnType<typeof useMealOptionSuggestionCursorConnectionQuery>;
export type MealOptionSuggestionCursorConnectionLazyQueryHookResult = ReturnType<typeof useMealOptionSuggestionCursorConnectionLazyQuery>;
export type MealOptionSuggestionCursorConnectionQueryResult = ApolloReactCommon.QueryResult<MealOptionSuggestionCursorConnectionQuery, MealOptionSuggestionCursorConnectionQueryVariables>;
export const SetMealOptionsForMealTemplatesDocument = gql`
    mutation SetMealOptionsForMealTemplates($input: SetMealOptionsForMealTemplatesInput!) {
  setMealOptionsForMealTemplates(input: $input) {
    mealTemplates {
      ...MealTemplateWithOptions
    }
  }
}
    ${MealTemplateWithOptionsFragmentDoc}`;
export type SetMealOptionsForMealTemplatesMutationFn = ApolloReactCommon.MutationFunction<SetMealOptionsForMealTemplatesMutation, SetMealOptionsForMealTemplatesMutationVariables>;
export type SetMealOptionsForMealTemplatesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetMealOptionsForMealTemplatesMutation, SetMealOptionsForMealTemplatesMutationVariables>, 'mutation'>;

    export const SetMealOptionsForMealTemplatesComponent = (props: SetMealOptionsForMealTemplatesComponentProps) => (
      <ApolloReactComponents.Mutation<SetMealOptionsForMealTemplatesMutation, SetMealOptionsForMealTemplatesMutationVariables> mutation={SetMealOptionsForMealTemplatesDocument} {...props} />
    );
    

/**
 * __useSetMealOptionsForMealTemplatesMutation__
 *
 * To run a mutation, you first call `useSetMealOptionsForMealTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMealOptionsForMealTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMealOptionsForMealTemplatesMutation, { data, loading, error }] = useSetMealOptionsForMealTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMealOptionsForMealTemplatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetMealOptionsForMealTemplatesMutation, SetMealOptionsForMealTemplatesMutationVariables>) {
        return ApolloReactHooks.useMutation<SetMealOptionsForMealTemplatesMutation, SetMealOptionsForMealTemplatesMutationVariables>(SetMealOptionsForMealTemplatesDocument, baseOptions);
      }
export type SetMealOptionsForMealTemplatesMutationHookResult = ReturnType<typeof useSetMealOptionsForMealTemplatesMutation>;
export type SetMealOptionsForMealTemplatesMutationResult = ApolloReactCommon.MutationResult<SetMealOptionsForMealTemplatesMutation>;
export type SetMealOptionsForMealTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<SetMealOptionsForMealTemplatesMutation, SetMealOptionsForMealTemplatesMutationVariables>;
export const MealPlanTemplateForAssignmentDocument = gql`
    query MealPlanTemplateForAssignment($id: ID!) {
  mealPlanTemplate(id: $id) {
    __typename
    id
    name
    ... on MacroMealPlanTemplate {
      macroProtocol {
        calorieBudget {
          rmrMethod
          goals {
            id
            name
            kcalOffset
            goalType {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type MealPlanTemplateForAssignmentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanTemplateForAssignmentQuery, MealPlanTemplateForAssignmentQueryVariables>, 'query'> & ({ variables: MealPlanTemplateForAssignmentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanTemplateForAssignmentComponent = (props: MealPlanTemplateForAssignmentComponentProps) => (
      <ApolloReactComponents.Query<MealPlanTemplateForAssignmentQuery, MealPlanTemplateForAssignmentQueryVariables> query={MealPlanTemplateForAssignmentDocument} {...props} />
    );
    

/**
 * __useMealPlanTemplateForAssignmentQuery__
 *
 * To run a query within a React component, call `useMealPlanTemplateForAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanTemplateForAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanTemplateForAssignmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMealPlanTemplateForAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanTemplateForAssignmentQuery, MealPlanTemplateForAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanTemplateForAssignmentQuery, MealPlanTemplateForAssignmentQueryVariables>(MealPlanTemplateForAssignmentDocument, baseOptions);
      }
export function useMealPlanTemplateForAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanTemplateForAssignmentQuery, MealPlanTemplateForAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanTemplateForAssignmentQuery, MealPlanTemplateForAssignmentQueryVariables>(MealPlanTemplateForAssignmentDocument, baseOptions);
        }
export type MealPlanTemplateForAssignmentQueryHookResult = ReturnType<typeof useMealPlanTemplateForAssignmentQuery>;
export type MealPlanTemplateForAssignmentLazyQueryHookResult = ReturnType<typeof useMealPlanTemplateForAssignmentLazyQuery>;
export type MealPlanTemplateForAssignmentQueryResult = ApolloReactCommon.QueryResult<MealPlanTemplateForAssignmentQuery, MealPlanTemplateForAssignmentQueryVariables>;
export const AthletesForMealPlanTemplateAssignmentDocument = gql`
    query AthletesForMealPlanTemplateAssignment($pagination: CursorPaginationInput!, $query: String, $tagIds: [ID!]!, $teamIds: [ID!]!, $weightTargetTypeId: ID, $anthropometryFilter: AthleteAnthropometryFilter, $rmrMethod: RMRMethod) {
  athletesForMealPlanTemplateAssignmentCursorConnection(
    input: $pagination
    query: $query
    tagIds: $tagIds
    teamIds: $teamIds
    weightTargetTypeId: $weightTargetTypeId
    anthropometryFilter: $anthropometryFilter
  ) {
    edges {
      ...AthleteForMealPlanTemplateAssignment
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${AthleteForMealPlanTemplateAssignmentFragmentDoc}`;
export type AthletesForMealPlanTemplateAssignmentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesForMealPlanTemplateAssignmentQuery, AthletesForMealPlanTemplateAssignmentQueryVariables>, 'query'> & ({ variables: AthletesForMealPlanTemplateAssignmentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthletesForMealPlanTemplateAssignmentComponent = (props: AthletesForMealPlanTemplateAssignmentComponentProps) => (
      <ApolloReactComponents.Query<AthletesForMealPlanTemplateAssignmentQuery, AthletesForMealPlanTemplateAssignmentQueryVariables> query={AthletesForMealPlanTemplateAssignmentDocument} {...props} />
    );
    

/**
 * __useAthletesForMealPlanTemplateAssignmentQuery__
 *
 * To run a query within a React component, call `useAthletesForMealPlanTemplateAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesForMealPlanTemplateAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesForMealPlanTemplateAssignmentQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *      tagIds: // value for 'tagIds'
 *      teamIds: // value for 'teamIds'
 *      weightTargetTypeId: // value for 'weightTargetTypeId'
 *      anthropometryFilter: // value for 'anthropometryFilter'
 *      rmrMethod: // value for 'rmrMethod'
 *   },
 * });
 */
export function useAthletesForMealPlanTemplateAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesForMealPlanTemplateAssignmentQuery, AthletesForMealPlanTemplateAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesForMealPlanTemplateAssignmentQuery, AthletesForMealPlanTemplateAssignmentQueryVariables>(AthletesForMealPlanTemplateAssignmentDocument, baseOptions);
      }
export function useAthletesForMealPlanTemplateAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesForMealPlanTemplateAssignmentQuery, AthletesForMealPlanTemplateAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesForMealPlanTemplateAssignmentQuery, AthletesForMealPlanTemplateAssignmentQueryVariables>(AthletesForMealPlanTemplateAssignmentDocument, baseOptions);
        }
export type AthletesForMealPlanTemplateAssignmentQueryHookResult = ReturnType<typeof useAthletesForMealPlanTemplateAssignmentQuery>;
export type AthletesForMealPlanTemplateAssignmentLazyQueryHookResult = ReturnType<typeof useAthletesForMealPlanTemplateAssignmentLazyQuery>;
export type AthletesForMealPlanTemplateAssignmentQueryResult = ApolloReactCommon.QueryResult<AthletesForMealPlanTemplateAssignmentQuery, AthletesForMealPlanTemplateAssignmentQueryVariables>;
export const CreateBulkAssignMealPlanTemplateJobDocument = gql`
    mutation CreateBulkAssignMealPlanTemplateJob($input: CreateBulkAssignMealPlanTemplateJobInput!) {
  createBulkAssignMealPlanTemplateJob(input: $input) {
    id
  }
}
    `;
export type CreateBulkAssignMealPlanTemplateJobMutationFn = ApolloReactCommon.MutationFunction<CreateBulkAssignMealPlanTemplateJobMutation, CreateBulkAssignMealPlanTemplateJobMutationVariables>;
export type CreateBulkAssignMealPlanTemplateJobComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBulkAssignMealPlanTemplateJobMutation, CreateBulkAssignMealPlanTemplateJobMutationVariables>, 'mutation'>;

    export const CreateBulkAssignMealPlanTemplateJobComponent = (props: CreateBulkAssignMealPlanTemplateJobComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBulkAssignMealPlanTemplateJobMutation, CreateBulkAssignMealPlanTemplateJobMutationVariables> mutation={CreateBulkAssignMealPlanTemplateJobDocument} {...props} />
    );
    

/**
 * __useCreateBulkAssignMealPlanTemplateJobMutation__
 *
 * To run a mutation, you first call `useCreateBulkAssignMealPlanTemplateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkAssignMealPlanTemplateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkAssignMealPlanTemplateJobMutation, { data, loading, error }] = useCreateBulkAssignMealPlanTemplateJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBulkAssignMealPlanTemplateJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBulkAssignMealPlanTemplateJobMutation, CreateBulkAssignMealPlanTemplateJobMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBulkAssignMealPlanTemplateJobMutation, CreateBulkAssignMealPlanTemplateJobMutationVariables>(CreateBulkAssignMealPlanTemplateJobDocument, baseOptions);
      }
export type CreateBulkAssignMealPlanTemplateJobMutationHookResult = ReturnType<typeof useCreateBulkAssignMealPlanTemplateJobMutation>;
export type CreateBulkAssignMealPlanTemplateJobMutationResult = ApolloReactCommon.MutationResult<CreateBulkAssignMealPlanTemplateJobMutation>;
export type CreateBulkAssignMealPlanTemplateJobMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBulkAssignMealPlanTemplateJobMutation, CreateBulkAssignMealPlanTemplateJobMutationVariables>;
export const AssignMealPlanTemplateJobOffsetConnectionDocument = gql`
    query AssignMealPlanTemplateJobOffsetConnection($input: OffsetPaginationInput!) {
  bulkAssignMealPlanTemplateJobOffsetConnection(input: $input) {
    edges {
      id
      createdAt
      queuedAt
      processingAt
      finishedAt
      name
      createdBy {
        id
        firstName
        lastName
      }
      athleteCount
      athleteSucceededCount
      athleteFailedCount
    }
    pageInfo {
      hasNextPage
      total
      endOffset
    }
  }
}
    `;
export type AssignMealPlanTemplateJobOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AssignMealPlanTemplateJobOffsetConnectionQuery, AssignMealPlanTemplateJobOffsetConnectionQueryVariables>, 'query'> & ({ variables: AssignMealPlanTemplateJobOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AssignMealPlanTemplateJobOffsetConnectionComponent = (props: AssignMealPlanTemplateJobOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<AssignMealPlanTemplateJobOffsetConnectionQuery, AssignMealPlanTemplateJobOffsetConnectionQueryVariables> query={AssignMealPlanTemplateJobOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useAssignMealPlanTemplateJobOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useAssignMealPlanTemplateJobOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignMealPlanTemplateJobOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignMealPlanTemplateJobOffsetConnectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignMealPlanTemplateJobOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssignMealPlanTemplateJobOffsetConnectionQuery, AssignMealPlanTemplateJobOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<AssignMealPlanTemplateJobOffsetConnectionQuery, AssignMealPlanTemplateJobOffsetConnectionQueryVariables>(AssignMealPlanTemplateJobOffsetConnectionDocument, baseOptions);
      }
export function useAssignMealPlanTemplateJobOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignMealPlanTemplateJobOffsetConnectionQuery, AssignMealPlanTemplateJobOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssignMealPlanTemplateJobOffsetConnectionQuery, AssignMealPlanTemplateJobOffsetConnectionQueryVariables>(AssignMealPlanTemplateJobOffsetConnectionDocument, baseOptions);
        }
export type AssignMealPlanTemplateJobOffsetConnectionQueryHookResult = ReturnType<typeof useAssignMealPlanTemplateJobOffsetConnectionQuery>;
export type AssignMealPlanTemplateJobOffsetConnectionLazyQueryHookResult = ReturnType<typeof useAssignMealPlanTemplateJobOffsetConnectionLazyQuery>;
export type AssignMealPlanTemplateJobOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<AssignMealPlanTemplateJobOffsetConnectionQuery, AssignMealPlanTemplateJobOffsetConnectionQueryVariables>;
export const FoodsByIdDocument = gql`
    query FoodsById($ids: [ID!]!) {
  foodsById(ids: $ids) {
    ...Food
  }
}
    ${FoodFragmentDoc}`;
export type FoodsByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FoodsByIdQuery, FoodsByIdQueryVariables>, 'query'> & ({ variables: FoodsByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FoodsByIdComponent = (props: FoodsByIdComponentProps) => (
      <ApolloReactComponents.Query<FoodsByIdQuery, FoodsByIdQueryVariables> query={FoodsByIdDocument} {...props} />
    );
    

/**
 * __useFoodsByIdQuery__
 *
 * To run a query within a React component, call `useFoodsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodsByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFoodsByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodsByIdQuery, FoodsByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodsByIdQuery, FoodsByIdQueryVariables>(FoodsByIdDocument, baseOptions);
      }
export function useFoodsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodsByIdQuery, FoodsByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodsByIdQuery, FoodsByIdQueryVariables>(FoodsByIdDocument, baseOptions);
        }
export type FoodsByIdQueryHookResult = ReturnType<typeof useFoodsByIdQuery>;
export type FoodsByIdLazyQueryHookResult = ReturnType<typeof useFoodsByIdLazyQuery>;
export type FoodsByIdQueryResult = ApolloReactCommon.QueryResult<FoodsByIdQuery, FoodsByIdQueryVariables>;
export const FoodGroupsByIdDocument = gql`
    query FoodGroupsById($ids: [ID!]!) {
  foodGroupsById(ids: $ids) {
    ...FullFoodGroupWithFoods
  }
}
    ${FullFoodGroupWithFoodsFragmentDoc}`;
export type FoodGroupsByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FoodGroupsByIdQuery, FoodGroupsByIdQueryVariables>, 'query'> & ({ variables: FoodGroupsByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FoodGroupsByIdComponent = (props: FoodGroupsByIdComponentProps) => (
      <ApolloReactComponents.Query<FoodGroupsByIdQuery, FoodGroupsByIdQueryVariables> query={FoodGroupsByIdDocument} {...props} />
    );
    

/**
 * __useFoodGroupsByIdQuery__
 *
 * To run a query within a React component, call `useFoodGroupsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodGroupsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodGroupsByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFoodGroupsByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodGroupsByIdQuery, FoodGroupsByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodGroupsByIdQuery, FoodGroupsByIdQueryVariables>(FoodGroupsByIdDocument, baseOptions);
      }
export function useFoodGroupsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodGroupsByIdQuery, FoodGroupsByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodGroupsByIdQuery, FoodGroupsByIdQueryVariables>(FoodGroupsByIdDocument, baseOptions);
        }
export type FoodGroupsByIdQueryHookResult = ReturnType<typeof useFoodGroupsByIdQuery>;
export type FoodGroupsByIdLazyQueryHookResult = ReturnType<typeof useFoodGroupsByIdLazyQuery>;
export type FoodGroupsByIdQueryResult = ApolloReactCommon.QueryResult<FoodGroupsByIdQuery, FoodGroupsByIdQueryVariables>;
export const MealOptionsServingsDocument = gql`
    query MealOptionsServings($ids: [ID!]!) {
  servings(ids: $ids) {
    ...FullServing
  }
}
    ${FullServingFragmentDoc}`;
export type MealOptionsServingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealOptionsServingsQuery, MealOptionsServingsQueryVariables>, 'query'> & ({ variables: MealOptionsServingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealOptionsServingsComponent = (props: MealOptionsServingsComponentProps) => (
      <ApolloReactComponents.Query<MealOptionsServingsQuery, MealOptionsServingsQueryVariables> query={MealOptionsServingsDocument} {...props} />
    );
    

/**
 * __useMealOptionsServingsQuery__
 *
 * To run a query within a React component, call `useMealOptionsServingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealOptionsServingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealOptionsServingsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMealOptionsServingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealOptionsServingsQuery, MealOptionsServingsQueryVariables>) {
        return ApolloReactHooks.useQuery<MealOptionsServingsQuery, MealOptionsServingsQueryVariables>(MealOptionsServingsDocument, baseOptions);
      }
export function useMealOptionsServingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealOptionsServingsQuery, MealOptionsServingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealOptionsServingsQuery, MealOptionsServingsQueryVariables>(MealOptionsServingsDocument, baseOptions);
        }
export type MealOptionsServingsQueryHookResult = ReturnType<typeof useMealOptionsServingsQuery>;
export type MealOptionsServingsLazyQueryHookResult = ReturnType<typeof useMealOptionsServingsLazyQuery>;
export type MealOptionsServingsQueryResult = ApolloReactCommon.QueryResult<MealOptionsServingsQuery, MealOptionsServingsQueryVariables>;
export const StandardSchedulesDocument = gql`
    query StandardSchedules {
  schedules {
    ...ScheduleWithTeam
  }
}
    ${ScheduleWithTeamFragmentDoc}`;
export type StandardSchedulesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StandardSchedulesQuery, StandardSchedulesQueryVariables>, 'query'>;

    export const StandardSchedulesComponent = (props: StandardSchedulesComponentProps) => (
      <ApolloReactComponents.Query<StandardSchedulesQuery, StandardSchedulesQueryVariables> query={StandardSchedulesDocument} {...props} />
    );
    

/**
 * __useStandardSchedulesQuery__
 *
 * To run a query within a React component, call `useStandardSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStandardSchedulesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StandardSchedulesQuery, StandardSchedulesQueryVariables>) {
        return ApolloReactHooks.useQuery<StandardSchedulesQuery, StandardSchedulesQueryVariables>(StandardSchedulesDocument, baseOptions);
      }
export function useStandardSchedulesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StandardSchedulesQuery, StandardSchedulesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StandardSchedulesQuery, StandardSchedulesQueryVariables>(StandardSchedulesDocument, baseOptions);
        }
export type StandardSchedulesQueryHookResult = ReturnType<typeof useStandardSchedulesQuery>;
export type StandardSchedulesLazyQueryHookResult = ReturnType<typeof useStandardSchedulesLazyQuery>;
export type StandardSchedulesQueryResult = ApolloReactCommon.QueryResult<StandardSchedulesQuery, StandardSchedulesQueryVariables>;
export const AllSchedulesDocument = gql`
    query AllSchedules {
  allSchedules {
    ...ScheduleWithTeam
  }
}
    ${ScheduleWithTeamFragmentDoc}`;
export type AllSchedulesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllSchedulesQuery, AllSchedulesQueryVariables>, 'query'>;

    export const AllSchedulesComponent = (props: AllSchedulesComponentProps) => (
      <ApolloReactComponents.Query<AllSchedulesQuery, AllSchedulesQueryVariables> query={AllSchedulesDocument} {...props} />
    );
    

/**
 * __useAllSchedulesQuery__
 *
 * To run a query within a React component, call `useAllSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSchedulesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllSchedulesQuery, AllSchedulesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllSchedulesQuery, AllSchedulesQueryVariables>(AllSchedulesDocument, baseOptions);
      }
export function useAllSchedulesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSchedulesQuery, AllSchedulesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllSchedulesQuery, AllSchedulesQueryVariables>(AllSchedulesDocument, baseOptions);
        }
export type AllSchedulesQueryHookResult = ReturnType<typeof useAllSchedulesQuery>;
export type AllSchedulesLazyQueryHookResult = ReturnType<typeof useAllSchedulesLazyQuery>;
export type AllSchedulesQueryResult = ApolloReactCommon.QueryResult<AllSchedulesQuery, AllSchedulesQueryVariables>;
export const MenuItemsForPrintableNutrientInfoDocument = gql`
    query MenuItemsForPrintableNutrientInfo($menuItemIds: [ID!]!) {
  menuItems(menuItemIds: $menuItemIds) {
    ...PrintableNutrientInfoMenuItem
  }
}
    ${PrintableNutrientInfoMenuItemFragmentDoc}`;
export type MenuItemsForPrintableNutrientInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuItemsForPrintableNutrientInfoQuery, MenuItemsForPrintableNutrientInfoQueryVariables>, 'query'> & ({ variables: MenuItemsForPrintableNutrientInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuItemsForPrintableNutrientInfoComponent = (props: MenuItemsForPrintableNutrientInfoComponentProps) => (
      <ApolloReactComponents.Query<MenuItemsForPrintableNutrientInfoQuery, MenuItemsForPrintableNutrientInfoQueryVariables> query={MenuItemsForPrintableNutrientInfoDocument} {...props} />
    );
    

/**
 * __useMenuItemsForPrintableNutrientInfoQuery__
 *
 * To run a query within a React component, call `useMenuItemsForPrintableNutrientInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuItemsForPrintableNutrientInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuItemsForPrintableNutrientInfoQuery({
 *   variables: {
 *      menuItemIds: // value for 'menuItemIds'
 *   },
 * });
 */
export function useMenuItemsForPrintableNutrientInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuItemsForPrintableNutrientInfoQuery, MenuItemsForPrintableNutrientInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuItemsForPrintableNutrientInfoQuery, MenuItemsForPrintableNutrientInfoQueryVariables>(MenuItemsForPrintableNutrientInfoDocument, baseOptions);
      }
export function useMenuItemsForPrintableNutrientInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuItemsForPrintableNutrientInfoQuery, MenuItemsForPrintableNutrientInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuItemsForPrintableNutrientInfoQuery, MenuItemsForPrintableNutrientInfoQueryVariables>(MenuItemsForPrintableNutrientInfoDocument, baseOptions);
        }
export type MenuItemsForPrintableNutrientInfoQueryHookResult = ReturnType<typeof useMenuItemsForPrintableNutrientInfoQuery>;
export type MenuItemsForPrintableNutrientInfoLazyQueryHookResult = ReturnType<typeof useMenuItemsForPrintableNutrientInfoLazyQuery>;
export type MenuItemsForPrintableNutrientInfoQueryResult = ApolloReactCommon.QueryResult<MenuItemsForPrintableNutrientInfoQuery, MenuItemsForPrintableNutrientInfoQueryVariables>;
export const MenuItemSearchDocument = gql`
    query MenuItemSearch($query: String, $pagination: CursorPaginationInput!) {
  menuItemCursorConnection(query: $query, input: $pagination) {
    edges {
      ...MenuItemPreviewSearch
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${MenuItemPreviewSearchFragmentDoc}`;
export type MenuItemSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuItemSearchQuery, MenuItemSearchQueryVariables>, 'query'> & ({ variables: MenuItemSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuItemSearchComponent = (props: MenuItemSearchComponentProps) => (
      <ApolloReactComponents.Query<MenuItemSearchQuery, MenuItemSearchQueryVariables> query={MenuItemSearchDocument} {...props} />
    );
    

/**
 * __useMenuItemSearchQuery__
 *
 * To run a query within a React component, call `useMenuItemSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuItemSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuItemSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMenuItemSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuItemSearchQuery, MenuItemSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuItemSearchQuery, MenuItemSearchQueryVariables>(MenuItemSearchDocument, baseOptions);
      }
export function useMenuItemSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuItemSearchQuery, MenuItemSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuItemSearchQuery, MenuItemSearchQueryVariables>(MenuItemSearchDocument, baseOptions);
        }
export type MenuItemSearchQueryHookResult = ReturnType<typeof useMenuItemSearchQuery>;
export type MenuItemSearchLazyQueryHookResult = ReturnType<typeof useMenuItemSearchLazyQuery>;
export type MenuItemSearchQueryResult = ApolloReactCommon.QueryResult<MenuItemSearchQuery, MenuItemSearchQueryVariables>;
export const MenuItemDialogDocument = gql`
    query MenuItemDialog($menuItemId: ID!) {
  currentMenuItem(menuItemId: $menuItemId) {
    ...MenuItemForm
  }
}
    ${MenuItemFormFragmentDoc}`;
export type MenuItemDialogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuItemDialogQuery, MenuItemDialogQueryVariables>, 'query'> & ({ variables: MenuItemDialogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuItemDialogComponent = (props: MenuItemDialogComponentProps) => (
      <ApolloReactComponents.Query<MenuItemDialogQuery, MenuItemDialogQueryVariables> query={MenuItemDialogDocument} {...props} />
    );
    

/**
 * __useMenuItemDialogQuery__
 *
 * To run a query within a React component, call `useMenuItemDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuItemDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuItemDialogQuery({
 *   variables: {
 *      menuItemId: // value for 'menuItemId'
 *   },
 * });
 */
export function useMenuItemDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuItemDialogQuery, MenuItemDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuItemDialogQuery, MenuItemDialogQueryVariables>(MenuItemDialogDocument, baseOptions);
      }
export function useMenuItemDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuItemDialogQuery, MenuItemDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuItemDialogQuery, MenuItemDialogQueryVariables>(MenuItemDialogDocument, baseOptions);
        }
export type MenuItemDialogQueryHookResult = ReturnType<typeof useMenuItemDialogQuery>;
export type MenuItemDialogLazyQueryHookResult = ReturnType<typeof useMenuItemDialogLazyQuery>;
export type MenuItemDialogQueryResult = ApolloReactCommon.QueryResult<MenuItemDialogQuery, MenuItemDialogQueryVariables>;
export const ReadonlyMenuItemDialogDocument = gql`
    query ReadonlyMenuItemDialog($menuItemId: ID!) {
  menuItem(menuItemId: $menuItemId) {
    ...MenuItemForm
  }
}
    ${MenuItemFormFragmentDoc}`;
export type ReadonlyMenuItemDialogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReadonlyMenuItemDialogQuery, ReadonlyMenuItemDialogQueryVariables>, 'query'> & ({ variables: ReadonlyMenuItemDialogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ReadonlyMenuItemDialogComponent = (props: ReadonlyMenuItemDialogComponentProps) => (
      <ApolloReactComponents.Query<ReadonlyMenuItemDialogQuery, ReadonlyMenuItemDialogQueryVariables> query={ReadonlyMenuItemDialogDocument} {...props} />
    );
    

/**
 * __useReadonlyMenuItemDialogQuery__
 *
 * To run a query within a React component, call `useReadonlyMenuItemDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadonlyMenuItemDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadonlyMenuItemDialogQuery({
 *   variables: {
 *      menuItemId: // value for 'menuItemId'
 *   },
 * });
 */
export function useReadonlyMenuItemDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReadonlyMenuItemDialogQuery, ReadonlyMenuItemDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<ReadonlyMenuItemDialogQuery, ReadonlyMenuItemDialogQueryVariables>(ReadonlyMenuItemDialogDocument, baseOptions);
      }
export function useReadonlyMenuItemDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReadonlyMenuItemDialogQuery, ReadonlyMenuItemDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReadonlyMenuItemDialogQuery, ReadonlyMenuItemDialogQueryVariables>(ReadonlyMenuItemDialogDocument, baseOptions);
        }
export type ReadonlyMenuItemDialogQueryHookResult = ReturnType<typeof useReadonlyMenuItemDialogQuery>;
export type ReadonlyMenuItemDialogLazyQueryHookResult = ReturnType<typeof useReadonlyMenuItemDialogLazyQuery>;
export type ReadonlyMenuItemDialogQueryResult = ApolloReactCommon.QueryResult<ReadonlyMenuItemDialogQuery, ReadonlyMenuItemDialogQueryVariables>;
export const MealMenuDialogDocument = gql`
    query MealMenuDialog($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    id
    userOrderLimit
    mealMenuDiningStations {
      ...MealMenuDiningStationWithItemPreviews
    }
  }
}
    ${MealMenuDiningStationWithItemPreviewsFragmentDoc}`;
export type MealMenuDialogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenuDialogQuery, MealMenuDialogQueryVariables>, 'query'> & ({ variables: MealMenuDialogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenuDialogComponent = (props: MealMenuDialogComponentProps) => (
      <ApolloReactComponents.Query<MealMenuDialogQuery, MealMenuDialogQueryVariables> query={MealMenuDialogDocument} {...props} />
    );
    

/**
 * __useMealMenuDialogQuery__
 *
 * To run a query within a React component, call `useMealMenuDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuDialogQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMealMenuDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenuDialogQuery, MealMenuDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenuDialogQuery, MealMenuDialogQueryVariables>(MealMenuDialogDocument, baseOptions);
      }
export function useMealMenuDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenuDialogQuery, MealMenuDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenuDialogQuery, MealMenuDialogQueryVariables>(MealMenuDialogDocument, baseOptions);
        }
export type MealMenuDialogQueryHookResult = ReturnType<typeof useMealMenuDialogQuery>;
export type MealMenuDialogLazyQueryHookResult = ReturnType<typeof useMealMenuDialogLazyQuery>;
export type MealMenuDialogQueryResult = ApolloReactCommon.QueryResult<MealMenuDialogQuery, MealMenuDialogQueryVariables>;
export const MenuOrderSuggestionsAndRecentOrdersDocument = gql`
    query MenuOrderSuggestionsAndRecentOrders($targetMacros: MacrosInput!, $mealMenuId: ID!, $avoidFoodGroupIds: [ID!]!, $limit: Int, $menuItemIds: [ID!]!, $athleteId: ID!) {
  menuOrderSuggestions(
    targetMacros: $targetMacros
    mealMenuId: $mealMenuId
    limit: $limit
    avoidFoodGroupIds: $avoidFoodGroupIds
  ) {
    ...MenuOrderSuggestion
  }
  menuOrderCursorConnectionForItemsForAthlete(
    menuItemIds: $menuItemIds
    input: {limit: $limit, cursor: null}
    athleteId: $athleteId
  ) {
    edges {
      ...RecentMenuOrder
    }
  }
}
    ${MenuOrderSuggestionFragmentDoc}
${RecentMenuOrderFragmentDoc}`;
export type MenuOrderSuggestionsAndRecentOrdersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuOrderSuggestionsAndRecentOrdersQuery, MenuOrderSuggestionsAndRecentOrdersQueryVariables>, 'query'> & ({ variables: MenuOrderSuggestionsAndRecentOrdersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuOrderSuggestionsAndRecentOrdersComponent = (props: MenuOrderSuggestionsAndRecentOrdersComponentProps) => (
      <ApolloReactComponents.Query<MenuOrderSuggestionsAndRecentOrdersQuery, MenuOrderSuggestionsAndRecentOrdersQueryVariables> query={MenuOrderSuggestionsAndRecentOrdersDocument} {...props} />
    );
    

/**
 * __useMenuOrderSuggestionsAndRecentOrdersQuery__
 *
 * To run a query within a React component, call `useMenuOrderSuggestionsAndRecentOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuOrderSuggestionsAndRecentOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuOrderSuggestionsAndRecentOrdersQuery({
 *   variables: {
 *      targetMacros: // value for 'targetMacros'
 *      mealMenuId: // value for 'mealMenuId'
 *      avoidFoodGroupIds: // value for 'avoidFoodGroupIds'
 *      limit: // value for 'limit'
 *      menuItemIds: // value for 'menuItemIds'
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useMenuOrderSuggestionsAndRecentOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuOrderSuggestionsAndRecentOrdersQuery, MenuOrderSuggestionsAndRecentOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuOrderSuggestionsAndRecentOrdersQuery, MenuOrderSuggestionsAndRecentOrdersQueryVariables>(MenuOrderSuggestionsAndRecentOrdersDocument, baseOptions);
      }
export function useMenuOrderSuggestionsAndRecentOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuOrderSuggestionsAndRecentOrdersQuery, MenuOrderSuggestionsAndRecentOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuOrderSuggestionsAndRecentOrdersQuery, MenuOrderSuggestionsAndRecentOrdersQueryVariables>(MenuOrderSuggestionsAndRecentOrdersDocument, baseOptions);
        }
export type MenuOrderSuggestionsAndRecentOrdersQueryHookResult = ReturnType<typeof useMenuOrderSuggestionsAndRecentOrdersQuery>;
export type MenuOrderSuggestionsAndRecentOrdersLazyQueryHookResult = ReturnType<typeof useMenuOrderSuggestionsAndRecentOrdersLazyQuery>;
export type MenuOrderSuggestionsAndRecentOrdersQueryResult = ApolloReactCommon.QueryResult<MenuOrderSuggestionsAndRecentOrdersQuery, MenuOrderSuggestionsAndRecentOrdersQueryVariables>;
export const MenuItemForSelectionFormDocument = gql`
    query MenuItemForSelectionForm($menuItemId: ID!) {
  menuItem(menuItemId: $menuItemId) {
    ...MenuItemForm
  }
}
    ${MenuItemFormFragmentDoc}`;
export type MenuItemForSelectionFormComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuItemForSelectionFormQuery, MenuItemForSelectionFormQueryVariables>, 'query'> & ({ variables: MenuItemForSelectionFormQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuItemForSelectionFormComponent = (props: MenuItemForSelectionFormComponentProps) => (
      <ApolloReactComponents.Query<MenuItemForSelectionFormQuery, MenuItemForSelectionFormQueryVariables> query={MenuItemForSelectionFormDocument} {...props} />
    );
    

/**
 * __useMenuItemForSelectionFormQuery__
 *
 * To run a query within a React component, call `useMenuItemForSelectionFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuItemForSelectionFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuItemForSelectionFormQuery({
 *   variables: {
 *      menuItemId: // value for 'menuItemId'
 *   },
 * });
 */
export function useMenuItemForSelectionFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuItemForSelectionFormQuery, MenuItemForSelectionFormQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuItemForSelectionFormQuery, MenuItemForSelectionFormQueryVariables>(MenuItemForSelectionFormDocument, baseOptions);
      }
export function useMenuItemForSelectionFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuItemForSelectionFormQuery, MenuItemForSelectionFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuItemForSelectionFormQuery, MenuItemForSelectionFormQueryVariables>(MenuItemForSelectionFormDocument, baseOptions);
        }
export type MenuItemForSelectionFormQueryHookResult = ReturnType<typeof useMenuItemForSelectionFormQuery>;
export type MenuItemForSelectionFormLazyQueryHookResult = ReturnType<typeof useMenuItemForSelectionFormLazyQuery>;
export type MenuItemForSelectionFormQueryResult = ApolloReactCommon.QueryResult<MenuItemForSelectionFormQuery, MenuItemForSelectionFormQueryVariables>;
export const RecipeDuplicateDialogDocument = gql`
    query RecipeDuplicateDialog($id: ID!) {
  recipe(id: $id) {
    ...RecipeFull
  }
}
    ${RecipeFullFragmentDoc}`;
export type RecipeDuplicateDialogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecipeDuplicateDialogQuery, RecipeDuplicateDialogQueryVariables>, 'query'> & ({ variables: RecipeDuplicateDialogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecipeDuplicateDialogComponent = (props: RecipeDuplicateDialogComponentProps) => (
      <ApolloReactComponents.Query<RecipeDuplicateDialogQuery, RecipeDuplicateDialogQueryVariables> query={RecipeDuplicateDialogDocument} {...props} />
    );
    

/**
 * __useRecipeDuplicateDialogQuery__
 *
 * To run a query within a React component, call `useRecipeDuplicateDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeDuplicateDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeDuplicateDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecipeDuplicateDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipeDuplicateDialogQuery, RecipeDuplicateDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipeDuplicateDialogQuery, RecipeDuplicateDialogQueryVariables>(RecipeDuplicateDialogDocument, baseOptions);
      }
export function useRecipeDuplicateDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipeDuplicateDialogQuery, RecipeDuplicateDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipeDuplicateDialogQuery, RecipeDuplicateDialogQueryVariables>(RecipeDuplicateDialogDocument, baseOptions);
        }
export type RecipeDuplicateDialogQueryHookResult = ReturnType<typeof useRecipeDuplicateDialogQuery>;
export type RecipeDuplicateDialogLazyQueryHookResult = ReturnType<typeof useRecipeDuplicateDialogLazyQuery>;
export type RecipeDuplicateDialogQueryResult = ApolloReactCommon.QueryResult<RecipeDuplicateDialogQuery, RecipeDuplicateDialogQueryVariables>;
export const CreateRecipeDocument = gql`
    mutation CreateRecipe($input: CreateRecipeFullInput!) {
  createRecipeFull(input: $input) {
    recipe {
      ...RecipeFull
    }
  }
}
    ${RecipeFullFragmentDoc}`;
export type CreateRecipeMutationFn = ApolloReactCommon.MutationFunction<CreateRecipeMutation, CreateRecipeMutationVariables>;
export type CreateRecipeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRecipeMutation, CreateRecipeMutationVariables>, 'mutation'>;

    export const CreateRecipeComponent = (props: CreateRecipeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRecipeMutation, CreateRecipeMutationVariables> mutation={CreateRecipeDocument} {...props} />
    );
    

/**
 * __useCreateRecipeMutation__
 *
 * To run a mutation, you first call `useCreateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeMutation, { data, loading, error }] = useCreateRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecipeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRecipeMutation, CreateRecipeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRecipeMutation, CreateRecipeMutationVariables>(CreateRecipeDocument, baseOptions);
      }
export type CreateRecipeMutationHookResult = ReturnType<typeof useCreateRecipeMutation>;
export type CreateRecipeMutationResult = ApolloReactCommon.MutationResult<CreateRecipeMutation>;
export type CreateRecipeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRecipeMutation, CreateRecipeMutationVariables>;
export const EditRecipeFullDocument = gql`
    mutation EditRecipeFull($input: EditRecipeFullInput!) {
  editRecipeFull(input: $input) {
    recipe {
      ...RecipeFull
    }
  }
}
    ${RecipeFullFragmentDoc}`;
export type EditRecipeFullMutationFn = ApolloReactCommon.MutationFunction<EditRecipeFullMutation, EditRecipeFullMutationVariables>;
export type EditRecipeFullComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRecipeFullMutation, EditRecipeFullMutationVariables>, 'mutation'>;

    export const EditRecipeFullComponent = (props: EditRecipeFullComponentProps) => (
      <ApolloReactComponents.Mutation<EditRecipeFullMutation, EditRecipeFullMutationVariables> mutation={EditRecipeFullDocument} {...props} />
    );
    

/**
 * __useEditRecipeFullMutation__
 *
 * To run a mutation, you first call `useEditRecipeFullMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRecipeFullMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRecipeFullMutation, { data, loading, error }] = useEditRecipeFullMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRecipeFullMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRecipeFullMutation, EditRecipeFullMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRecipeFullMutation, EditRecipeFullMutationVariables>(EditRecipeFullDocument, baseOptions);
      }
export type EditRecipeFullMutationHookResult = ReturnType<typeof useEditRecipeFullMutation>;
export type EditRecipeFullMutationResult = ApolloReactCommon.MutationResult<EditRecipeFullMutation>;
export type EditRecipeFullMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRecipeFullMutation, EditRecipeFullMutationVariables>;
export const EditRecipePartialDocument = gql`
    mutation EditRecipePartial($input: EditRecipePartialInput!) {
  editRecipePartial(input: $input) {
    recipe {
      ...RecipeFull
    }
  }
}
    ${RecipeFullFragmentDoc}`;
export type EditRecipePartialMutationFn = ApolloReactCommon.MutationFunction<EditRecipePartialMutation, EditRecipePartialMutationVariables>;
export type EditRecipePartialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRecipePartialMutation, EditRecipePartialMutationVariables>, 'mutation'>;

    export const EditRecipePartialComponent = (props: EditRecipePartialComponentProps) => (
      <ApolloReactComponents.Mutation<EditRecipePartialMutation, EditRecipePartialMutationVariables> mutation={EditRecipePartialDocument} {...props} />
    );
    

/**
 * __useEditRecipePartialMutation__
 *
 * To run a mutation, you first call `useEditRecipePartialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRecipePartialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRecipePartialMutation, { data, loading, error }] = useEditRecipePartialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRecipePartialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRecipePartialMutation, EditRecipePartialMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRecipePartialMutation, EditRecipePartialMutationVariables>(EditRecipePartialDocument, baseOptions);
      }
export type EditRecipePartialMutationHookResult = ReturnType<typeof useEditRecipePartialMutation>;
export type EditRecipePartialMutationResult = ApolloReactCommon.MutationResult<EditRecipePartialMutation>;
export type EditRecipePartialMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRecipePartialMutation, EditRecipePartialMutationVariables>;
export const RecipeEditDialogDocument = gql`
    query RecipeEditDialog($id: ID!) {
  recipe(id: $id) {
    ...RecipeFull
  }
}
    ${RecipeFullFragmentDoc}`;
export type RecipeEditDialogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecipeEditDialogQuery, RecipeEditDialogQueryVariables>, 'query'> & ({ variables: RecipeEditDialogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecipeEditDialogComponent = (props: RecipeEditDialogComponentProps) => (
      <ApolloReactComponents.Query<RecipeEditDialogQuery, RecipeEditDialogQueryVariables> query={RecipeEditDialogDocument} {...props} />
    );
    

/**
 * __useRecipeEditDialogQuery__
 *
 * To run a query within a React component, call `useRecipeEditDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeEditDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeEditDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecipeEditDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipeEditDialogQuery, RecipeEditDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipeEditDialogQuery, RecipeEditDialogQueryVariables>(RecipeEditDialogDocument, baseOptions);
      }
export function useRecipeEditDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipeEditDialogQuery, RecipeEditDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipeEditDialogQuery, RecipeEditDialogQueryVariables>(RecipeEditDialogDocument, baseOptions);
        }
export type RecipeEditDialogQueryHookResult = ReturnType<typeof useRecipeEditDialogQuery>;
export type RecipeEditDialogLazyQueryHookResult = ReturnType<typeof useRecipeEditDialogLazyQuery>;
export type RecipeEditDialogQueryResult = ApolloReactCommon.QueryResult<RecipeEditDialogQuery, RecipeEditDialogQueryVariables>;
export const GetPossibleGptRecipeByNameDocument = gql`
    mutation GetPossibleGptRecipeByName($input: GPTRecipeByNameInput!) {
  getPossibleGPTRecipeByName(input: $input) {
    ...GptRecipe
  }
}
    ${GptRecipeFragmentDoc}`;
export type GetPossibleGptRecipeByNameMutationFn = ApolloReactCommon.MutationFunction<GetPossibleGptRecipeByNameMutation, GetPossibleGptRecipeByNameMutationVariables>;
export type GetPossibleGptRecipeByNameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GetPossibleGptRecipeByNameMutation, GetPossibleGptRecipeByNameMutationVariables>, 'mutation'>;

    export const GetPossibleGptRecipeByNameComponent = (props: GetPossibleGptRecipeByNameComponentProps) => (
      <ApolloReactComponents.Mutation<GetPossibleGptRecipeByNameMutation, GetPossibleGptRecipeByNameMutationVariables> mutation={GetPossibleGptRecipeByNameDocument} {...props} />
    );
    

/**
 * __useGetPossibleGptRecipeByNameMutation__
 *
 * To run a mutation, you first call `useGetPossibleGptRecipeByNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPossibleGptRecipeByNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPossibleGptRecipeByNameMutation, { data, loading, error }] = useGetPossibleGptRecipeByNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPossibleGptRecipeByNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetPossibleGptRecipeByNameMutation, GetPossibleGptRecipeByNameMutationVariables>) {
        return ApolloReactHooks.useMutation<GetPossibleGptRecipeByNameMutation, GetPossibleGptRecipeByNameMutationVariables>(GetPossibleGptRecipeByNameDocument, baseOptions);
      }
export type GetPossibleGptRecipeByNameMutationHookResult = ReturnType<typeof useGetPossibleGptRecipeByNameMutation>;
export type GetPossibleGptRecipeByNameMutationResult = ApolloReactCommon.MutationResult<GetPossibleGptRecipeByNameMutation>;
export type GetPossibleGptRecipeByNameMutationOptions = ApolloReactCommon.BaseMutationOptions<GetPossibleGptRecipeByNameMutation, GetPossibleGptRecipeByNameMutationVariables>;
export const GetPossibleGptRecipeFromImageDocument = gql`
    mutation GetPossibleGptRecipeFromImage($input: GPTRecipeFromImageInput!) {
  getPossibleGPTRecipeFromImage(input: $input) {
    ...GptRecipe
  }
}
    ${GptRecipeFragmentDoc}`;
export type GetPossibleGptRecipeFromImageMutationFn = ApolloReactCommon.MutationFunction<GetPossibleGptRecipeFromImageMutation, GetPossibleGptRecipeFromImageMutationVariables>;
export type GetPossibleGptRecipeFromImageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GetPossibleGptRecipeFromImageMutation, GetPossibleGptRecipeFromImageMutationVariables>, 'mutation'>;

    export const GetPossibleGptRecipeFromImageComponent = (props: GetPossibleGptRecipeFromImageComponentProps) => (
      <ApolloReactComponents.Mutation<GetPossibleGptRecipeFromImageMutation, GetPossibleGptRecipeFromImageMutationVariables> mutation={GetPossibleGptRecipeFromImageDocument} {...props} />
    );
    

/**
 * __useGetPossibleGptRecipeFromImageMutation__
 *
 * To run a mutation, you first call `useGetPossibleGptRecipeFromImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPossibleGptRecipeFromImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPossibleGptRecipeFromImageMutation, { data, loading, error }] = useGetPossibleGptRecipeFromImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPossibleGptRecipeFromImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetPossibleGptRecipeFromImageMutation, GetPossibleGptRecipeFromImageMutationVariables>) {
        return ApolloReactHooks.useMutation<GetPossibleGptRecipeFromImageMutation, GetPossibleGptRecipeFromImageMutationVariables>(GetPossibleGptRecipeFromImageDocument, baseOptions);
      }
export type GetPossibleGptRecipeFromImageMutationHookResult = ReturnType<typeof useGetPossibleGptRecipeFromImageMutation>;
export type GetPossibleGptRecipeFromImageMutationResult = ApolloReactCommon.MutationResult<GetPossibleGptRecipeFromImageMutation>;
export type GetPossibleGptRecipeFromImageMutationOptions = ApolloReactCommon.BaseMutationOptions<GetPossibleGptRecipeFromImageMutation, GetPossibleGptRecipeFromImageMutationVariables>;
export const RestaurantLocationSearchDocument = gql`
    query RestaurantLocationSearch($deliveryLocationId: ID!, $restaurantIds: [ID!], $restaurantTypeIds: [ID!], $paymentMethodIds: [ID!], $cuisineIds: [ID!], $dietIds: [ID!], $query: String, $pagination: CursorPaginationInput) {
  restaurantLocationSearch(
    deliveryLocationId: $deliveryLocationId
    restaurantIds: $restaurantIds
    restaurantTypeIds: $restaurantTypeIds
    paymentMethodIds: $paymentMethodIds
    cuisineIds: $cuisineIds
    dietIds: $dietIds
    query: $query
    pagination: $pagination
  ) {
    edges {
      ...RestaurantLocationSearchResult
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${RestaurantLocationSearchResultFragmentDoc}`;
export type RestaurantLocationSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantLocationSearchQuery, RestaurantLocationSearchQueryVariables>, 'query'> & ({ variables: RestaurantLocationSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantLocationSearchComponent = (props: RestaurantLocationSearchComponentProps) => (
      <ApolloReactComponents.Query<RestaurantLocationSearchQuery, RestaurantLocationSearchQueryVariables> query={RestaurantLocationSearchDocument} {...props} />
    );
    

/**
 * __useRestaurantLocationSearchQuery__
 *
 * To run a query within a React component, call `useRestaurantLocationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantLocationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantLocationSearchQuery({
 *   variables: {
 *      deliveryLocationId: // value for 'deliveryLocationId'
 *      restaurantIds: // value for 'restaurantIds'
 *      restaurantTypeIds: // value for 'restaurantTypeIds'
 *      paymentMethodIds: // value for 'paymentMethodIds'
 *      cuisineIds: // value for 'cuisineIds'
 *      dietIds: // value for 'dietIds'
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRestaurantLocationSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantLocationSearchQuery, RestaurantLocationSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantLocationSearchQuery, RestaurantLocationSearchQueryVariables>(RestaurantLocationSearchDocument, baseOptions);
      }
export function useRestaurantLocationSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantLocationSearchQuery, RestaurantLocationSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantLocationSearchQuery, RestaurantLocationSearchQueryVariables>(RestaurantLocationSearchDocument, baseOptions);
        }
export type RestaurantLocationSearchQueryHookResult = ReturnType<typeof useRestaurantLocationSearchQuery>;
export type RestaurantLocationSearchLazyQueryHookResult = ReturnType<typeof useRestaurantLocationSearchLazyQuery>;
export type RestaurantLocationSearchQueryResult = ApolloReactCommon.QueryResult<RestaurantLocationSearchQuery, RestaurantLocationSearchQueryVariables>;
export const RestaurantMenuSearchDocument = gql`
    query RestaurantMenuSearch($restaurantTypeIds: [ID!], $paymentMethodIds: [ID!], $cuisineIds: [ID!], $dietIds: [ID!], $query: String, $pagination: CursorPaginationInput) {
  restaurantMenuSearch: cateringRestaurantMenuSearch(
    restaurantTypeIds: $restaurantTypeIds
    paymentMethodIds: $paymentMethodIds
    cuisineIds: $cuisineIds
    dietIds: $dietIds
    query: $query
    pagination: $pagination
  ) {
    edges {
      ...RestaurantMenuSearchResult
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${RestaurantMenuSearchResultFragmentDoc}`;
export type RestaurantMenuSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantMenuSearchQuery, RestaurantMenuSearchQueryVariables>, 'query'>;

    export const RestaurantMenuSearchComponent = (props: RestaurantMenuSearchComponentProps) => (
      <ApolloReactComponents.Query<RestaurantMenuSearchQuery, RestaurantMenuSearchQueryVariables> query={RestaurantMenuSearchDocument} {...props} />
    );
    

/**
 * __useRestaurantMenuSearchQuery__
 *
 * To run a query within a React component, call `useRestaurantMenuSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantMenuSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantMenuSearchQuery({
 *   variables: {
 *      restaurantTypeIds: // value for 'restaurantTypeIds'
 *      paymentMethodIds: // value for 'paymentMethodIds'
 *      cuisineIds: // value for 'cuisineIds'
 *      dietIds: // value for 'dietIds'
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useRestaurantMenuSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantMenuSearchQuery, RestaurantMenuSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantMenuSearchQuery, RestaurantMenuSearchQueryVariables>(RestaurantMenuSearchDocument, baseOptions);
      }
export function useRestaurantMenuSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantMenuSearchQuery, RestaurantMenuSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantMenuSearchQuery, RestaurantMenuSearchQueryVariables>(RestaurantMenuSearchDocument, baseOptions);
        }
export type RestaurantMenuSearchQueryHookResult = ReturnType<typeof useRestaurantMenuSearchQuery>;
export type RestaurantMenuSearchLazyQueryHookResult = ReturnType<typeof useRestaurantMenuSearchLazyQuery>;
export type RestaurantMenuSearchQueryResult = ApolloReactCommon.QueryResult<RestaurantMenuSearchQuery, RestaurantMenuSearchQueryVariables>;
export const CuisineCursorConnectionDocument = gql`
    query CuisineCursorConnection($pagination: CursorPaginationInput!, $query: String) {
  cuisineCursorConnection(input: $pagination, query: $query) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...Cuisine
    }
  }
}
    ${CuisineFragmentDoc}`;
export type CuisineCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CuisineCursorConnectionQuery, CuisineCursorConnectionQueryVariables>, 'query'> & ({ variables: CuisineCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CuisineCursorConnectionComponent = (props: CuisineCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<CuisineCursorConnectionQuery, CuisineCursorConnectionQueryVariables> query={CuisineCursorConnectionDocument} {...props} />
    );
    

/**
 * __useCuisineCursorConnectionQuery__
 *
 * To run a query within a React component, call `useCuisineCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCuisineCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCuisineCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCuisineCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CuisineCursorConnectionQuery, CuisineCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<CuisineCursorConnectionQuery, CuisineCursorConnectionQueryVariables>(CuisineCursorConnectionDocument, baseOptions);
      }
export function useCuisineCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CuisineCursorConnectionQuery, CuisineCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CuisineCursorConnectionQuery, CuisineCursorConnectionQueryVariables>(CuisineCursorConnectionDocument, baseOptions);
        }
export type CuisineCursorConnectionQueryHookResult = ReturnType<typeof useCuisineCursorConnectionQuery>;
export type CuisineCursorConnectionLazyQueryHookResult = ReturnType<typeof useCuisineCursorConnectionLazyQuery>;
export type CuisineCursorConnectionQueryResult = ApolloReactCommon.QueryResult<CuisineCursorConnectionQuery, CuisineCursorConnectionQueryVariables>;
export const DietCursorConnectionDocument = gql`
    query DietCursorConnection($pagination: CursorPaginationInput!, $query: String) {
  dietCursorConnection(input: $pagination, query: $query) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...Diet
    }
  }
}
    ${DietFragmentDoc}`;
export type DietCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DietCursorConnectionQuery, DietCursorConnectionQueryVariables>, 'query'> & ({ variables: DietCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DietCursorConnectionComponent = (props: DietCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<DietCursorConnectionQuery, DietCursorConnectionQueryVariables> query={DietCursorConnectionDocument} {...props} />
    );
    

/**
 * __useDietCursorConnectionQuery__
 *
 * To run a query within a React component, call `useDietCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDietCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDietCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDietCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DietCursorConnectionQuery, DietCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<DietCursorConnectionQuery, DietCursorConnectionQueryVariables>(DietCursorConnectionDocument, baseOptions);
      }
export function useDietCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DietCursorConnectionQuery, DietCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DietCursorConnectionQuery, DietCursorConnectionQueryVariables>(DietCursorConnectionDocument, baseOptions);
        }
export type DietCursorConnectionQueryHookResult = ReturnType<typeof useDietCursorConnectionQuery>;
export type DietCursorConnectionLazyQueryHookResult = ReturnType<typeof useDietCursorConnectionLazyQuery>;
export type DietCursorConnectionQueryResult = ApolloReactCommon.QueryResult<DietCursorConnectionQuery, DietCursorConnectionQueryVariables>;
export const PaymentMethodCursorConnectionDocument = gql`
    query PaymentMethodCursorConnection($pagination: CursorPaginationInput!, $query: String) {
  paymentMethodCursorConnection(query: $query, input: $pagination) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...PaymentMethod
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export type PaymentMethodCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PaymentMethodCursorConnectionQuery, PaymentMethodCursorConnectionQueryVariables>, 'query'> & ({ variables: PaymentMethodCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PaymentMethodCursorConnectionComponent = (props: PaymentMethodCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<PaymentMethodCursorConnectionQuery, PaymentMethodCursorConnectionQueryVariables> query={PaymentMethodCursorConnectionDocument} {...props} />
    );
    

/**
 * __usePaymentMethodCursorConnectionQuery__
 *
 * To run a query within a React component, call `usePaymentMethodCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePaymentMethodCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentMethodCursorConnectionQuery, PaymentMethodCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentMethodCursorConnectionQuery, PaymentMethodCursorConnectionQueryVariables>(PaymentMethodCursorConnectionDocument, baseOptions);
      }
export function usePaymentMethodCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentMethodCursorConnectionQuery, PaymentMethodCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentMethodCursorConnectionQuery, PaymentMethodCursorConnectionQueryVariables>(PaymentMethodCursorConnectionDocument, baseOptions);
        }
export type PaymentMethodCursorConnectionQueryHookResult = ReturnType<typeof usePaymentMethodCursorConnectionQuery>;
export type PaymentMethodCursorConnectionLazyQueryHookResult = ReturnType<typeof usePaymentMethodCursorConnectionLazyQuery>;
export type PaymentMethodCursorConnectionQueryResult = ApolloReactCommon.QueryResult<PaymentMethodCursorConnectionQuery, PaymentMethodCursorConnectionQueryVariables>;
export const RestaurantTypeCursorConnectionDocument = gql`
    query RestaurantTypeCursorConnection($pagination: CursorPaginationInput!, $query: String) {
  restaurantTypeCursorConnection(input: $pagination, query: $query) {
    edges {
      ...RestaurantType
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${RestaurantTypeFragmentDoc}`;
export type RestaurantTypeCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantTypeCursorConnectionQuery, RestaurantTypeCursorConnectionQueryVariables>, 'query'> & ({ variables: RestaurantTypeCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantTypeCursorConnectionComponent = (props: RestaurantTypeCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<RestaurantTypeCursorConnectionQuery, RestaurantTypeCursorConnectionQueryVariables> query={RestaurantTypeCursorConnectionDocument} {...props} />
    );
    

/**
 * __useRestaurantTypeCursorConnectionQuery__
 *
 * To run a query within a React component, call `useRestaurantTypeCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantTypeCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantTypeCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRestaurantTypeCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantTypeCursorConnectionQuery, RestaurantTypeCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantTypeCursorConnectionQuery, RestaurantTypeCursorConnectionQueryVariables>(RestaurantTypeCursorConnectionDocument, baseOptions);
      }
export function useRestaurantTypeCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantTypeCursorConnectionQuery, RestaurantTypeCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantTypeCursorConnectionQuery, RestaurantTypeCursorConnectionQueryVariables>(RestaurantTypeCursorConnectionDocument, baseOptions);
        }
export type RestaurantTypeCursorConnectionQueryHookResult = ReturnType<typeof useRestaurantTypeCursorConnectionQuery>;
export type RestaurantTypeCursorConnectionLazyQueryHookResult = ReturnType<typeof useRestaurantTypeCursorConnectionLazyQuery>;
export type RestaurantTypeCursorConnectionQueryResult = ApolloReactCommon.QueryResult<RestaurantTypeCursorConnectionQuery, RestaurantTypeCursorConnectionQueryVariables>;
export const TeamLabelCursorConnectionDocument = gql`
    query TeamLabelCursorConnection($pagination: CursorPaginationInput!, $query: String) {
  teamLabelCursorConnection(input: $pagination, query: $query) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...TeamLabel
    }
  }
}
    ${TeamLabelFragmentDoc}`;
export type TeamLabelCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamLabelCursorConnectionQuery, TeamLabelCursorConnectionQueryVariables>, 'query'> & ({ variables: TeamLabelCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamLabelCursorConnectionComponent = (props: TeamLabelCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<TeamLabelCursorConnectionQuery, TeamLabelCursorConnectionQueryVariables> query={TeamLabelCursorConnectionDocument} {...props} />
    );
    

/**
 * __useTeamLabelCursorConnectionQuery__
 *
 * To run a query within a React component, call `useTeamLabelCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamLabelCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamLabelCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTeamLabelCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamLabelCursorConnectionQuery, TeamLabelCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamLabelCursorConnectionQuery, TeamLabelCursorConnectionQueryVariables>(TeamLabelCursorConnectionDocument, baseOptions);
      }
export function useTeamLabelCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamLabelCursorConnectionQuery, TeamLabelCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamLabelCursorConnectionQuery, TeamLabelCursorConnectionQueryVariables>(TeamLabelCursorConnectionDocument, baseOptions);
        }
export type TeamLabelCursorConnectionQueryHookResult = ReturnType<typeof useTeamLabelCursorConnectionQuery>;
export type TeamLabelCursorConnectionLazyQueryHookResult = ReturnType<typeof useTeamLabelCursorConnectionLazyQuery>;
export type TeamLabelCursorConnectionQueryResult = ApolloReactCommon.QueryResult<TeamLabelCursorConnectionQuery, TeamLabelCursorConnectionQueryVariables>;
export const DeliveryLocationCursorConnectionDocument = gql`
    query DeliveryLocationCursorConnection($pagination: CursorPaginationInput!, $query: String, $teamLabelId: ID) {
  deliveryLocationCursorConnection(
    input: $pagination
    query: $query
    teamLabelId: $teamLabelId
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...DeliveryLocationMenuLinkForm
    }
  }
}
    ${DeliveryLocationMenuLinkFormFragmentDoc}`;
export type DeliveryLocationCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DeliveryLocationCursorConnectionQuery, DeliveryLocationCursorConnectionQueryVariables>, 'query'> & ({ variables: DeliveryLocationCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DeliveryLocationCursorConnectionComponent = (props: DeliveryLocationCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<DeliveryLocationCursorConnectionQuery, DeliveryLocationCursorConnectionQueryVariables> query={DeliveryLocationCursorConnectionDocument} {...props} />
    );
    

/**
 * __useDeliveryLocationCursorConnectionQuery__
 *
 * To run a query within a React component, call `useDeliveryLocationCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryLocationCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryLocationCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *      teamLabelId: // value for 'teamLabelId'
 *   },
 * });
 */
export function useDeliveryLocationCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveryLocationCursorConnectionQuery, DeliveryLocationCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveryLocationCursorConnectionQuery, DeliveryLocationCursorConnectionQueryVariables>(DeliveryLocationCursorConnectionDocument, baseOptions);
      }
export function useDeliveryLocationCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveryLocationCursorConnectionQuery, DeliveryLocationCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveryLocationCursorConnectionQuery, DeliveryLocationCursorConnectionQueryVariables>(DeliveryLocationCursorConnectionDocument, baseOptions);
        }
export type DeliveryLocationCursorConnectionQueryHookResult = ReturnType<typeof useDeliveryLocationCursorConnectionQuery>;
export type DeliveryLocationCursorConnectionLazyQueryHookResult = ReturnType<typeof useDeliveryLocationCursorConnectionLazyQuery>;
export type DeliveryLocationCursorConnectionQueryResult = ApolloReactCommon.QueryResult<DeliveryLocationCursorConnectionQuery, DeliveryLocationCursorConnectionQueryVariables>;
export const UnitConversionsDocument = gql`
    query UnitConversions {
  unitConversions {
    ...UnitConversionFull
  }
}
    ${UnitConversionFullFragmentDoc}`;
export type UnitConversionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnitConversionsQuery, UnitConversionsQueryVariables>, 'query'>;

    export const UnitConversionsComponent = (props: UnitConversionsComponentProps) => (
      <ApolloReactComponents.Query<UnitConversionsQuery, UnitConversionsQueryVariables> query={UnitConversionsDocument} {...props} />
    );
    

/**
 * __useUnitConversionsQuery__
 *
 * To run a query within a React component, call `useUnitConversionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitConversionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitConversionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitConversionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnitConversionsQuery, UnitConversionsQueryVariables>) {
        return ApolloReactHooks.useQuery<UnitConversionsQuery, UnitConversionsQueryVariables>(UnitConversionsDocument, baseOptions);
      }
export function useUnitConversionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitConversionsQuery, UnitConversionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnitConversionsQuery, UnitConversionsQueryVariables>(UnitConversionsDocument, baseOptions);
        }
export type UnitConversionsQueryHookResult = ReturnType<typeof useUnitConversionsQuery>;
export type UnitConversionsLazyQueryHookResult = ReturnType<typeof useUnitConversionsLazyQuery>;
export type UnitConversionsQueryResult = ApolloReactCommon.QueryResult<UnitConversionsQuery, UnitConversionsQueryVariables>;
export const UnitsForNewServingsDocument = gql`
    query UnitsForNewServings {
  gramUnit {
    ...Unit
  }
  ounceUnit {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;
export type UnitsForNewServingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnitsForNewServingsQuery, UnitsForNewServingsQueryVariables>, 'query'>;

    export const UnitsForNewServingsComponent = (props: UnitsForNewServingsComponentProps) => (
      <ApolloReactComponents.Query<UnitsForNewServingsQuery, UnitsForNewServingsQueryVariables> query={UnitsForNewServingsDocument} {...props} />
    );
    

/**
 * __useUnitsForNewServingsQuery__
 *
 * To run a query within a React component, call `useUnitsForNewServingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsForNewServingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsForNewServingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitsForNewServingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnitsForNewServingsQuery, UnitsForNewServingsQueryVariables>) {
        return ApolloReactHooks.useQuery<UnitsForNewServingsQuery, UnitsForNewServingsQueryVariables>(UnitsForNewServingsDocument, baseOptions);
      }
export function useUnitsForNewServingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitsForNewServingsQuery, UnitsForNewServingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnitsForNewServingsQuery, UnitsForNewServingsQueryVariables>(UnitsForNewServingsDocument, baseOptions);
        }
export type UnitsForNewServingsQueryHookResult = ReturnType<typeof useUnitsForNewServingsQuery>;
export type UnitsForNewServingsLazyQueryHookResult = ReturnType<typeof useUnitsForNewServingsLazyQuery>;
export type UnitsForNewServingsQueryResult = ApolloReactCommon.QueryResult<UnitsForNewServingsQuery, UnitsForNewServingsQueryVariables>;
export const CreateWeightUnitServingForFoodDocument = gql`
    mutation CreateWeightUnitServingForFood($input: CreateWeightUnitServingForFoodInput!) {
  createWeightUnitServingForFood(input: $input) {
    serving {
      ...FullServing
    }
    amount
  }
}
    ${FullServingFragmentDoc}`;
export type CreateWeightUnitServingForFoodMutationFn = ApolloReactCommon.MutationFunction<CreateWeightUnitServingForFoodMutation, CreateWeightUnitServingForFoodMutationVariables>;
export type CreateWeightUnitServingForFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateWeightUnitServingForFoodMutation, CreateWeightUnitServingForFoodMutationVariables>, 'mutation'>;

    export const CreateWeightUnitServingForFoodComponent = (props: CreateWeightUnitServingForFoodComponentProps) => (
      <ApolloReactComponents.Mutation<CreateWeightUnitServingForFoodMutation, CreateWeightUnitServingForFoodMutationVariables> mutation={CreateWeightUnitServingForFoodDocument} {...props} />
    );
    

/**
 * __useCreateWeightUnitServingForFoodMutation__
 *
 * To run a mutation, you first call `useCreateWeightUnitServingForFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWeightUnitServingForFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWeightUnitServingForFoodMutation, { data, loading, error }] = useCreateWeightUnitServingForFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWeightUnitServingForFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWeightUnitServingForFoodMutation, CreateWeightUnitServingForFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWeightUnitServingForFoodMutation, CreateWeightUnitServingForFoodMutationVariables>(CreateWeightUnitServingForFoodDocument, baseOptions);
      }
export type CreateWeightUnitServingForFoodMutationHookResult = ReturnType<typeof useCreateWeightUnitServingForFoodMutation>;
export type CreateWeightUnitServingForFoodMutationResult = ApolloReactCommon.MutationResult<CreateWeightUnitServingForFoodMutation>;
export type CreateWeightUnitServingForFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWeightUnitServingForFoodMutation, CreateWeightUnitServingForFoodMutationVariables>;
export const TerritoriesDocument = gql`
    query Territories {
  territories {
    ...Territory
  }
}
    ${TerritoryFragmentDoc}`;
export type TerritoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TerritoriesQuery, TerritoriesQueryVariables>, 'query'>;

    export const TerritoriesComponent = (props: TerritoriesComponentProps) => (
      <ApolloReactComponents.Query<TerritoriesQuery, TerritoriesQueryVariables> query={TerritoriesDocument} {...props} />
    );
    

/**
 * __useTerritoriesQuery__
 *
 * To run a query within a React component, call `useTerritoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerritoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerritoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTerritoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TerritoriesQuery, TerritoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<TerritoriesQuery, TerritoriesQueryVariables>(TerritoriesDocument, baseOptions);
      }
export function useTerritoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TerritoriesQuery, TerritoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TerritoriesQuery, TerritoriesQueryVariables>(TerritoriesDocument, baseOptions);
        }
export type TerritoriesQueryHookResult = ReturnType<typeof useTerritoriesQuery>;
export type TerritoriesLazyQueryHookResult = ReturnType<typeof useTerritoriesLazyQuery>;
export type TerritoriesQueryResult = ApolloReactCommon.QueryResult<TerritoriesQuery, TerritoriesQueryVariables>;
export const EditAthletesDocument = gql`
    mutation EditAthletes($input: [EditAthleteAndAnthroInput!]!) {
  editAthletes(input: $input) {
    athletes {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type EditAthletesMutationFn = ApolloReactCommon.MutationFunction<EditAthletesMutation, EditAthletesMutationVariables>;
export type EditAthletesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAthletesMutation, EditAthletesMutationVariables>, 'mutation'>;

    export const EditAthletesComponent = (props: EditAthletesComponentProps) => (
      <ApolloReactComponents.Mutation<EditAthletesMutation, EditAthletesMutationVariables> mutation={EditAthletesDocument} {...props} />
    );
    

/**
 * __useEditAthletesMutation__
 *
 * To run a mutation, you first call `useEditAthletesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAthletesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAthletesMutation, { data, loading, error }] = useEditAthletesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAthletesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAthletesMutation, EditAthletesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAthletesMutation, EditAthletesMutationVariables>(EditAthletesDocument, baseOptions);
      }
export type EditAthletesMutationHookResult = ReturnType<typeof useEditAthletesMutation>;
export type EditAthletesMutationResult = ApolloReactCommon.MutationResult<EditAthletesMutation>;
export type EditAthletesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAthletesMutation, EditAthletesMutationVariables>;
export const TeamsForImportAthleteDocument = gql`
    query TeamsForImportAthlete {
  teams {
    ...TeamsForImportAthleteTeam
  }
}
    ${TeamsForImportAthleteTeamFragmentDoc}`;
export type TeamsForImportAthleteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamsForImportAthleteQuery, TeamsForImportAthleteQueryVariables>, 'query'>;

    export const TeamsForImportAthleteComponent = (props: TeamsForImportAthleteComponentProps) => (
      <ApolloReactComponents.Query<TeamsForImportAthleteQuery, TeamsForImportAthleteQueryVariables> query={TeamsForImportAthleteDocument} {...props} />
    );
    

/**
 * __useTeamsForImportAthleteQuery__
 *
 * To run a query within a React component, call `useTeamsForImportAthleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsForImportAthleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsForImportAthleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsForImportAthleteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamsForImportAthleteQuery, TeamsForImportAthleteQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamsForImportAthleteQuery, TeamsForImportAthleteQueryVariables>(TeamsForImportAthleteDocument, baseOptions);
      }
export function useTeamsForImportAthleteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamsForImportAthleteQuery, TeamsForImportAthleteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamsForImportAthleteQuery, TeamsForImportAthleteQueryVariables>(TeamsForImportAthleteDocument, baseOptions);
        }
export type TeamsForImportAthleteQueryHookResult = ReturnType<typeof useTeamsForImportAthleteQuery>;
export type TeamsForImportAthleteLazyQueryHookResult = ReturnType<typeof useTeamsForImportAthleteLazyQuery>;
export type TeamsForImportAthleteQueryResult = ApolloReactCommon.QueryResult<TeamsForImportAthleteQuery, TeamsForImportAthleteQueryVariables>;
export const OrgFoodsDocument = gql`
    query OrgFoods($searchTerm: String, $limit: Int) {
  orgFoods(searchTerm: $searchTerm, limit: $limit) {
    ...BulkImportFood
  }
}
    ${BulkImportFoodFragmentDoc}`;
export type OrgFoodsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgFoodsQuery, OrgFoodsQueryVariables>, 'query'>;

    export const OrgFoodsComponent = (props: OrgFoodsComponentProps) => (
      <ApolloReactComponents.Query<OrgFoodsQuery, OrgFoodsQueryVariables> query={OrgFoodsDocument} {...props} />
    );
    

/**
 * __useOrgFoodsQuery__
 *
 * To run a query within a React component, call `useOrgFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgFoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgFoodsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOrgFoodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgFoodsQuery, OrgFoodsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgFoodsQuery, OrgFoodsQueryVariables>(OrgFoodsDocument, baseOptions);
      }
export function useOrgFoodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgFoodsQuery, OrgFoodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgFoodsQuery, OrgFoodsQueryVariables>(OrgFoodsDocument, baseOptions);
        }
export type OrgFoodsQueryHookResult = ReturnType<typeof useOrgFoodsQuery>;
export type OrgFoodsLazyQueryHookResult = ReturnType<typeof useOrgFoodsLazyQuery>;
export type OrgFoodsQueryResult = ApolloReactCommon.QueryResult<OrgFoodsQuery, OrgFoodsQueryVariables>;
export const CreateOrgGenericFoodsDocument = gql`
    mutation CreateOrgGenericFoods($input: BulkImportCreateGenericFoodsInput!) {
  bulkImportCreateGenericFoods(input: $input) {
    genericFoods {
      ...BulkImportFood
    }
  }
}
    ${BulkImportFoodFragmentDoc}`;
export type CreateOrgGenericFoodsMutationFn = ApolloReactCommon.MutationFunction<CreateOrgGenericFoodsMutation, CreateOrgGenericFoodsMutationVariables>;
export type CreateOrgGenericFoodsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrgGenericFoodsMutation, CreateOrgGenericFoodsMutationVariables>, 'mutation'>;

    export const CreateOrgGenericFoodsComponent = (props: CreateOrgGenericFoodsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrgGenericFoodsMutation, CreateOrgGenericFoodsMutationVariables> mutation={CreateOrgGenericFoodsDocument} {...props} />
    );
    

/**
 * __useCreateOrgGenericFoodsMutation__
 *
 * To run a mutation, you first call `useCreateOrgGenericFoodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgGenericFoodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgGenericFoodsMutation, { data, loading, error }] = useCreateOrgGenericFoodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgGenericFoodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgGenericFoodsMutation, CreateOrgGenericFoodsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgGenericFoodsMutation, CreateOrgGenericFoodsMutationVariables>(CreateOrgGenericFoodsDocument, baseOptions);
      }
export type CreateOrgGenericFoodsMutationHookResult = ReturnType<typeof useCreateOrgGenericFoodsMutation>;
export type CreateOrgGenericFoodsMutationResult = ApolloReactCommon.MutationResult<CreateOrgGenericFoodsMutation>;
export type CreateOrgGenericFoodsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgGenericFoodsMutation, CreateOrgGenericFoodsMutationVariables>;
export const UpdateOrgGenericFoodsDocument = gql`
    mutation UpdateOrgGenericFoods($input: BulkImportCreateAndDeprecateGenericFoodsInput!) {
  bulkImportCreateAndDeprecateGenericFoods(input: $input) {
    genericFoods {
      ...BulkImportFood
    }
  }
}
    ${BulkImportFoodFragmentDoc}`;
export type UpdateOrgGenericFoodsMutationFn = ApolloReactCommon.MutationFunction<UpdateOrgGenericFoodsMutation, UpdateOrgGenericFoodsMutationVariables>;
export type UpdateOrgGenericFoodsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrgGenericFoodsMutation, UpdateOrgGenericFoodsMutationVariables>, 'mutation'>;

    export const UpdateOrgGenericFoodsComponent = (props: UpdateOrgGenericFoodsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrgGenericFoodsMutation, UpdateOrgGenericFoodsMutationVariables> mutation={UpdateOrgGenericFoodsDocument} {...props} />
    );
    

/**
 * __useUpdateOrgGenericFoodsMutation__
 *
 * To run a mutation, you first call `useUpdateOrgGenericFoodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgGenericFoodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgGenericFoodsMutation, { data, loading, error }] = useUpdateOrgGenericFoodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgGenericFoodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrgGenericFoodsMutation, UpdateOrgGenericFoodsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrgGenericFoodsMutation, UpdateOrgGenericFoodsMutationVariables>(UpdateOrgGenericFoodsDocument, baseOptions);
      }
export type UpdateOrgGenericFoodsMutationHookResult = ReturnType<typeof useUpdateOrgGenericFoodsMutation>;
export type UpdateOrgGenericFoodsMutationResult = ApolloReactCommon.MutationResult<UpdateOrgGenericFoodsMutation>;
export type UpdateOrgGenericFoodsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrgGenericFoodsMutation, UpdateOrgGenericFoodsMutationVariables>;
export const RestaurantFoodsDocument = gql`
    query RestaurantFoods($searchTerm: String, $limit: Int, $restaurantId: ID!) {
  restaurantFoods(
    searchTerm: $searchTerm
    limit: $limit
    restaurantId: $restaurantId
  ) {
    ...BulkImportFood
  }
}
    ${BulkImportFoodFragmentDoc}`;
export type RestaurantFoodsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantFoodsQuery, RestaurantFoodsQueryVariables>, 'query'> & ({ variables: RestaurantFoodsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantFoodsComponent = (props: RestaurantFoodsComponentProps) => (
      <ApolloReactComponents.Query<RestaurantFoodsQuery, RestaurantFoodsQueryVariables> query={RestaurantFoodsDocument} {...props} />
    );
    

/**
 * __useRestaurantFoodsQuery__
 *
 * To run a query within a React component, call `useRestaurantFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantFoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantFoodsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useRestaurantFoodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantFoodsQuery, RestaurantFoodsQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantFoodsQuery, RestaurantFoodsQueryVariables>(RestaurantFoodsDocument, baseOptions);
      }
export function useRestaurantFoodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantFoodsQuery, RestaurantFoodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantFoodsQuery, RestaurantFoodsQueryVariables>(RestaurantFoodsDocument, baseOptions);
        }
export type RestaurantFoodsQueryHookResult = ReturnType<typeof useRestaurantFoodsQuery>;
export type RestaurantFoodsLazyQueryHookResult = ReturnType<typeof useRestaurantFoodsLazyQuery>;
export type RestaurantFoodsQueryResult = ApolloReactCommon.QueryResult<RestaurantFoodsQuery, RestaurantFoodsQueryVariables>;
export const CreateRestaurantFoodsDocument = gql`
    mutation CreateRestaurantFoods($input: BulkImportCreateRestautantBrandedFoodsInput!) {
  bulkImportCreateRestaurantBrandedFood(input: $input) {
    brandedFoods {
      ...BulkImportFood
    }
  }
}
    ${BulkImportFoodFragmentDoc}`;
export type CreateRestaurantFoodsMutationFn = ApolloReactCommon.MutationFunction<CreateRestaurantFoodsMutation, CreateRestaurantFoodsMutationVariables>;
export type CreateRestaurantFoodsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRestaurantFoodsMutation, CreateRestaurantFoodsMutationVariables>, 'mutation'>;

    export const CreateRestaurantFoodsComponent = (props: CreateRestaurantFoodsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRestaurantFoodsMutation, CreateRestaurantFoodsMutationVariables> mutation={CreateRestaurantFoodsDocument} {...props} />
    );
    

/**
 * __useCreateRestaurantFoodsMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantFoodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantFoodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantFoodsMutation, { data, loading, error }] = useCreateRestaurantFoodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRestaurantFoodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRestaurantFoodsMutation, CreateRestaurantFoodsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRestaurantFoodsMutation, CreateRestaurantFoodsMutationVariables>(CreateRestaurantFoodsDocument, baseOptions);
      }
export type CreateRestaurantFoodsMutationHookResult = ReturnType<typeof useCreateRestaurantFoodsMutation>;
export type CreateRestaurantFoodsMutationResult = ApolloReactCommon.MutationResult<CreateRestaurantFoodsMutation>;
export type CreateRestaurantFoodsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRestaurantFoodsMutation, CreateRestaurantFoodsMutationVariables>;
export const UpdateRestaurantFoodsDocument = gql`
    mutation UpdateRestaurantFoods($input: BulkImportEditRestautantBrandedFoodsInput!) {
  bulkImportEditRestaurantBrandedFood(input: $input) {
    brandedFoods {
      ...BulkImportFood
    }
  }
}
    ${BulkImportFoodFragmentDoc}`;
export type UpdateRestaurantFoodsMutationFn = ApolloReactCommon.MutationFunction<UpdateRestaurantFoodsMutation, UpdateRestaurantFoodsMutationVariables>;
export type UpdateRestaurantFoodsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRestaurantFoodsMutation, UpdateRestaurantFoodsMutationVariables>, 'mutation'>;

    export const UpdateRestaurantFoodsComponent = (props: UpdateRestaurantFoodsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRestaurantFoodsMutation, UpdateRestaurantFoodsMutationVariables> mutation={UpdateRestaurantFoodsDocument} {...props} />
    );
    

/**
 * __useUpdateRestaurantFoodsMutation__
 *
 * To run a mutation, you first call `useUpdateRestaurantFoodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantFoodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestaurantFoodsMutation, { data, loading, error }] = useUpdateRestaurantFoodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRestaurantFoodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRestaurantFoodsMutation, UpdateRestaurantFoodsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRestaurantFoodsMutation, UpdateRestaurantFoodsMutationVariables>(UpdateRestaurantFoodsDocument, baseOptions);
      }
export type UpdateRestaurantFoodsMutationHookResult = ReturnType<typeof useUpdateRestaurantFoodsMutation>;
export type UpdateRestaurantFoodsMutationResult = ApolloReactCommon.MutationResult<UpdateRestaurantFoodsMutation>;
export type UpdateRestaurantFoodsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRestaurantFoodsMutation, UpdateRestaurantFoodsMutationVariables>;
export const MyOrgFullySyncedDocument = gql`
    query MyOrgFullySynced {
  myOrg {
    id
    isFullySynced
    isNotemealLinked
  }
}
    `;
export type MyOrgFullySyncedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyOrgFullySyncedQuery, MyOrgFullySyncedQueryVariables>, 'query'>;

    export const MyOrgFullySyncedComponent = (props: MyOrgFullySyncedComponentProps) => (
      <ApolloReactComponents.Query<MyOrgFullySyncedQuery, MyOrgFullySyncedQueryVariables> query={MyOrgFullySyncedDocument} {...props} />
    );
    

/**
 * __useMyOrgFullySyncedQuery__
 *
 * To run a query within a React component, call `useMyOrgFullySyncedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrgFullySyncedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrgFullySyncedQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOrgFullySyncedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyOrgFullySyncedQuery, MyOrgFullySyncedQueryVariables>) {
        return ApolloReactHooks.useQuery<MyOrgFullySyncedQuery, MyOrgFullySyncedQueryVariables>(MyOrgFullySyncedDocument, baseOptions);
      }
export function useMyOrgFullySyncedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyOrgFullySyncedQuery, MyOrgFullySyncedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyOrgFullySyncedQuery, MyOrgFullySyncedQueryVariables>(MyOrgFullySyncedDocument, baseOptions);
        }
export type MyOrgFullySyncedQueryHookResult = ReturnType<typeof useMyOrgFullySyncedQuery>;
export type MyOrgFullySyncedLazyQueryHookResult = ReturnType<typeof useMyOrgFullySyncedLazyQuery>;
export type MyOrgFullySyncedQueryResult = ApolloReactCommon.QueryResult<MyOrgFullySyncedQuery, MyOrgFullySyncedQueryVariables>;
export const ApiCredentialsAndSchemaDocument = gql`
    query ApiCredentialsAndSchema {
  webVersion
  nixAppId
  nixAppKey
}
    `;
export type ApiCredentialsAndSchemaComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApiCredentialsAndSchemaQuery, ApiCredentialsAndSchemaQueryVariables>, 'query'>;

    export const ApiCredentialsAndSchemaComponent = (props: ApiCredentialsAndSchemaComponentProps) => (
      <ApolloReactComponents.Query<ApiCredentialsAndSchemaQuery, ApiCredentialsAndSchemaQueryVariables> query={ApiCredentialsAndSchemaDocument} {...props} />
    );
    

/**
 * __useApiCredentialsAndSchemaQuery__
 *
 * To run a query within a React component, call `useApiCredentialsAndSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiCredentialsAndSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiCredentialsAndSchemaQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiCredentialsAndSchemaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApiCredentialsAndSchemaQuery, ApiCredentialsAndSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<ApiCredentialsAndSchemaQuery, ApiCredentialsAndSchemaQueryVariables>(ApiCredentialsAndSchemaDocument, baseOptions);
      }
export function useApiCredentialsAndSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApiCredentialsAndSchemaQuery, ApiCredentialsAndSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApiCredentialsAndSchemaQuery, ApiCredentialsAndSchemaQueryVariables>(ApiCredentialsAndSchemaDocument, baseOptions);
        }
export type ApiCredentialsAndSchemaQueryHookResult = ReturnType<typeof useApiCredentialsAndSchemaQuery>;
export type ApiCredentialsAndSchemaLazyQueryHookResult = ReturnType<typeof useApiCredentialsAndSchemaLazyQuery>;
export type ApiCredentialsAndSchemaQueryResult = ApolloReactCommon.QueryResult<ApiCredentialsAndSchemaQuery, ApiCredentialsAndSchemaQueryVariables>;
export const PublishedNotificationDocument = gql`
    subscription PublishedNotification {
  notification {
    payload {
      notification {
        ...NotificationWeb
      }
    }
  }
}
    ${NotificationWebFragmentDoc}`;
export type PublishedNotificationComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PublishedNotificationSubscription, PublishedNotificationSubscriptionVariables>, 'subscription'>;

    export const PublishedNotificationComponent = (props: PublishedNotificationComponentProps) => (
      <ApolloReactComponents.Subscription<PublishedNotificationSubscription, PublishedNotificationSubscriptionVariables> subscription={PublishedNotificationDocument} {...props} />
    );
    

/**
 * __usePublishedNotificationSubscription__
 *
 * To run a query within a React component, call `usePublishedNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishedNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePublishedNotificationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PublishedNotificationSubscription, PublishedNotificationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<PublishedNotificationSubscription, PublishedNotificationSubscriptionVariables>(PublishedNotificationDocument, baseOptions);
      }
export type PublishedNotificationSubscriptionHookResult = ReturnType<typeof usePublishedNotificationSubscription>;
export type PublishedNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<PublishedNotificationSubscription>;
export const CanBeStaffDocument = gql`
    query CanBeStaff {
  canBeStaff
}
    `;
export type CanBeStaffComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CanBeStaffQuery, CanBeStaffQueryVariables>, 'query'>;

    export const CanBeStaffComponent = (props: CanBeStaffComponentProps) => (
      <ApolloReactComponents.Query<CanBeStaffQuery, CanBeStaffQueryVariables> query={CanBeStaffDocument} {...props} />
    );
    

/**
 * __useCanBeStaffQuery__
 *
 * To run a query within a React component, call `useCanBeStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanBeStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanBeStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanBeStaffQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CanBeStaffQuery, CanBeStaffQueryVariables>) {
        return ApolloReactHooks.useQuery<CanBeStaffQuery, CanBeStaffQueryVariables>(CanBeStaffDocument, baseOptions);
      }
export function useCanBeStaffLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CanBeStaffQuery, CanBeStaffQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CanBeStaffQuery, CanBeStaffQueryVariables>(CanBeStaffDocument, baseOptions);
        }
export type CanBeStaffQueryHookResult = ReturnType<typeof useCanBeStaffQuery>;
export type CanBeStaffLazyQueryHookResult = ReturnType<typeof useCanBeStaffLazyQuery>;
export type CanBeStaffQueryResult = ApolloReactCommon.QueryResult<CanBeStaffQuery, CanBeStaffQueryVariables>;
export const AthleteNavDocument = gql`
    query AthleteNav($id: ID!) {
  athlete(id: $id) {
    ...NavAthlete
  }
}
    ${NavAthleteFragmentDoc}`;
export type AthleteNavComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteNavQuery, AthleteNavQueryVariables>, 'query'> & ({ variables: AthleteNavQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteNavComponent = (props: AthleteNavComponentProps) => (
      <ApolloReactComponents.Query<AthleteNavQuery, AthleteNavQueryVariables> query={AthleteNavDocument} {...props} />
    );
    

/**
 * __useAthleteNavQuery__
 *
 * To run a query within a React component, call `useAthleteNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteNavQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteNavQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteNavQuery, AthleteNavQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteNavQuery, AthleteNavQueryVariables>(AthleteNavDocument, baseOptions);
      }
export function useAthleteNavLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteNavQuery, AthleteNavQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteNavQuery, AthleteNavQueryVariables>(AthleteNavDocument, baseOptions);
        }
export type AthleteNavQueryHookResult = ReturnType<typeof useAthleteNavQuery>;
export type AthleteNavLazyQueryHookResult = ReturnType<typeof useAthleteNavLazyQuery>;
export type AthleteNavQueryResult = ApolloReactCommon.QueryResult<AthleteNavQuery, AthleteNavQueryVariables>;
export const MenuAttendanceNavDocument = gql`
    query MenuAttendanceNav($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    ...NavMealMenu
  }
}
    ${NavMealMenuFragmentDoc}`;
export type MenuAttendanceNavComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuAttendanceNavQuery, MenuAttendanceNavQueryVariables>, 'query'> & ({ variables: MenuAttendanceNavQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuAttendanceNavComponent = (props: MenuAttendanceNavComponentProps) => (
      <ApolloReactComponents.Query<MenuAttendanceNavQuery, MenuAttendanceNavQueryVariables> query={MenuAttendanceNavDocument} {...props} />
    );
    

/**
 * __useMenuAttendanceNavQuery__
 *
 * To run a query within a React component, call `useMenuAttendanceNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuAttendanceNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuAttendanceNavQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMenuAttendanceNavQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuAttendanceNavQuery, MenuAttendanceNavQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuAttendanceNavQuery, MenuAttendanceNavQueryVariables>(MenuAttendanceNavDocument, baseOptions);
      }
export function useMenuAttendanceNavLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuAttendanceNavQuery, MenuAttendanceNavQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuAttendanceNavQuery, MenuAttendanceNavQueryVariables>(MenuAttendanceNavDocument, baseOptions);
        }
export type MenuAttendanceNavQueryHookResult = ReturnType<typeof useMenuAttendanceNavQuery>;
export type MenuAttendanceNavLazyQueryHookResult = ReturnType<typeof useMenuAttendanceNavLazyQuery>;
export type MenuAttendanceNavQueryResult = ApolloReactCommon.QueryResult<MenuAttendanceNavQuery, MenuAttendanceNavQueryVariables>;
export const MenuBuilderNavDocument = gql`
    query MenuBuilderNav($id: ID!) {
  plannedMenu(id: $id) {
    ...NavPlannedMenu
  }
}
    ${NavPlannedMenuFragmentDoc}`;
export type MenuBuilderNavComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuBuilderNavQuery, MenuBuilderNavQueryVariables>, 'query'> & ({ variables: MenuBuilderNavQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuBuilderNavComponent = (props: MenuBuilderNavComponentProps) => (
      <ApolloReactComponents.Query<MenuBuilderNavQuery, MenuBuilderNavQueryVariables> query={MenuBuilderNavDocument} {...props} />
    );
    

/**
 * __useMenuBuilderNavQuery__
 *
 * To run a query within a React component, call `useMenuBuilderNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuBuilderNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuBuilderNavQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMenuBuilderNavQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuBuilderNavQuery, MenuBuilderNavQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuBuilderNavQuery, MenuBuilderNavQueryVariables>(MenuBuilderNavDocument, baseOptions);
      }
export function useMenuBuilderNavLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuBuilderNavQuery, MenuBuilderNavQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuBuilderNavQuery, MenuBuilderNavQueryVariables>(MenuBuilderNavDocument, baseOptions);
        }
export type MenuBuilderNavQueryHookResult = ReturnType<typeof useMenuBuilderNavQuery>;
export type MenuBuilderNavLazyQueryHookResult = ReturnType<typeof useMenuBuilderNavLazyQuery>;
export type MenuBuilderNavQueryResult = ApolloReactCommon.QueryResult<MenuBuilderNavQuery, MenuBuilderNavQueryVariables>;
export const UsersICanMessageCursorConnectionDocument = gql`
    query UsersICanMessageCursorConnection($role: Role!, $teamId: String, $query: String!, $input: CursorPaginationInput!) {
  usersICanMessageCursorConnection(
    role: $role
    teamId: $teamId
    query: $query
    input: $input
  ) {
    edges {
      ...MessageOption
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${MessageOptionFragmentDoc}`;
export type UsersICanMessageCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsersICanMessageCursorConnectionQuery, UsersICanMessageCursorConnectionQueryVariables>, 'query'> & ({ variables: UsersICanMessageCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UsersICanMessageCursorConnectionComponent = (props: UsersICanMessageCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<UsersICanMessageCursorConnectionQuery, UsersICanMessageCursorConnectionQueryVariables> query={UsersICanMessageCursorConnectionDocument} {...props} />
    );
    

/**
 * __useUsersICanMessageCursorConnectionQuery__
 *
 * To run a query within a React component, call `useUsersICanMessageCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersICanMessageCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersICanMessageCursorConnectionQuery({
 *   variables: {
 *      role: // value for 'role'
 *      teamId: // value for 'teamId'
 *      query: // value for 'query'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersICanMessageCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersICanMessageCursorConnectionQuery, UsersICanMessageCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersICanMessageCursorConnectionQuery, UsersICanMessageCursorConnectionQueryVariables>(UsersICanMessageCursorConnectionDocument, baseOptions);
      }
export function useUsersICanMessageCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersICanMessageCursorConnectionQuery, UsersICanMessageCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersICanMessageCursorConnectionQuery, UsersICanMessageCursorConnectionQueryVariables>(UsersICanMessageCursorConnectionDocument, baseOptions);
        }
export type UsersICanMessageCursorConnectionQueryHookResult = ReturnType<typeof useUsersICanMessageCursorConnectionQuery>;
export type UsersICanMessageCursorConnectionLazyQueryHookResult = ReturnType<typeof useUsersICanMessageCursorConnectionLazyQuery>;
export type UsersICanMessageCursorConnectionQueryResult = ApolloReactCommon.QueryResult<UsersICanMessageCursorConnectionQuery, UsersICanMessageCursorConnectionQueryVariables>;
export const MeForStreamChatDocument = gql`
    query MeForStreamChat {
  myUser {
    id
    firstName
    lastName
  }
  myStreamChatToken
}
    `;
export type MeForStreamChatComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MeForStreamChatQuery, MeForStreamChatQueryVariables>, 'query'>;

    export const MeForStreamChatComponent = (props: MeForStreamChatComponentProps) => (
      <ApolloReactComponents.Query<MeForStreamChatQuery, MeForStreamChatQueryVariables> query={MeForStreamChatDocument} {...props} />
    );
    

/**
 * __useMeForStreamChatQuery__
 *
 * To run a query within a React component, call `useMeForStreamChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeForStreamChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeForStreamChatQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeForStreamChatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeForStreamChatQuery, MeForStreamChatQueryVariables>) {
        return ApolloReactHooks.useQuery<MeForStreamChatQuery, MeForStreamChatQueryVariables>(MeForStreamChatDocument, baseOptions);
      }
export function useMeForStreamChatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeForStreamChatQuery, MeForStreamChatQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeForStreamChatQuery, MeForStreamChatQueryVariables>(MeForStreamChatDocument, baseOptions);
        }
export type MeForStreamChatQueryHookResult = ReturnType<typeof useMeForStreamChatQuery>;
export type MeForStreamChatLazyQueryHookResult = ReturnType<typeof useMeForStreamChatLazyQuery>;
export type MeForStreamChatQueryResult = ApolloReactCommon.QueryResult<MeForStreamChatQuery, MeForStreamChatQueryVariables>;
export const TeamsForFilterDocument = gql`
    query TeamsForFilter {
  teams {
    ...TeamOption
  }
}
    ${TeamOptionFragmentDoc}`;
export type TeamsForFilterComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamsForFilterQuery, TeamsForFilterQueryVariables>, 'query'>;

    export const TeamsForFilterComponent = (props: TeamsForFilterComponentProps) => (
      <ApolloReactComponents.Query<TeamsForFilterQuery, TeamsForFilterQueryVariables> query={TeamsForFilterDocument} {...props} />
    );
    

/**
 * __useTeamsForFilterQuery__
 *
 * To run a query within a React component, call `useTeamsForFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsForFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsForFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsForFilterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamsForFilterQuery, TeamsForFilterQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamsForFilterQuery, TeamsForFilterQueryVariables>(TeamsForFilterDocument, baseOptions);
      }
export function useTeamsForFilterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamsForFilterQuery, TeamsForFilterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamsForFilterQuery, TeamsForFilterQueryVariables>(TeamsForFilterDocument, baseOptions);
        }
export type TeamsForFilterQueryHookResult = ReturnType<typeof useTeamsForFilterQuery>;
export type TeamsForFilterLazyQueryHookResult = ReturnType<typeof useTeamsForFilterLazyQuery>;
export type TeamsForFilterQueryResult = ApolloReactCommon.QueryResult<TeamsForFilterQuery, TeamsForFilterQueryVariables>;
export const TeamNavDocument = gql`
    query TeamNav($id: ID!) {
  team(id: $id) {
    ...NavTeam
  }
}
    ${NavTeamFragmentDoc}`;
export type TeamNavComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamNavQuery, TeamNavQueryVariables>, 'query'> & ({ variables: TeamNavQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamNavComponent = (props: TeamNavComponentProps) => (
      <ApolloReactComponents.Query<TeamNavQuery, TeamNavQueryVariables> query={TeamNavDocument} {...props} />
    );
    

/**
 * __useTeamNavQuery__
 *
 * To run a query within a React component, call `useTeamNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamNavQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamNavQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamNavQuery, TeamNavQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamNavQuery, TeamNavQueryVariables>(TeamNavDocument, baseOptions);
      }
export function useTeamNavLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamNavQuery, TeamNavQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamNavQuery, TeamNavQueryVariables>(TeamNavDocument, baseOptions);
        }
export type TeamNavQueryHookResult = ReturnType<typeof useTeamNavQuery>;
export type TeamNavLazyQueryHookResult = ReturnType<typeof useTeamNavLazyQuery>;
export type TeamNavQueryResult = ApolloReactCommon.QueryResult<TeamNavQuery, TeamNavQueryVariables>;
export const MyUserDocument = gql`
    query MyUser {
  myUser {
    username
  }
}
    `;
export type MyUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyUserQuery, MyUserQueryVariables>, 'query'>;

    export const MyUserComponent = (props: MyUserComponentProps) => (
      <ApolloReactComponents.Query<MyUserQuery, MyUserQueryVariables> query={MyUserDocument} {...props} />
    );
    

/**
 * __useMyUserQuery__
 *
 * To run a query within a React component, call `useMyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyUserQuery, MyUserQueryVariables>) {
        return ApolloReactHooks.useQuery<MyUserQuery, MyUserQueryVariables>(MyUserDocument, baseOptions);
      }
export function useMyUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyUserQuery, MyUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyUserQuery, MyUserQueryVariables>(MyUserDocument, baseOptions);
        }
export type MyUserQueryHookResult = ReturnType<typeof useMyUserQuery>;
export type MyUserLazyQueryHookResult = ReturnType<typeof useMyUserLazyQuery>;
export type MyUserQueryResult = ApolloReactCommon.QueryResult<MyUserQuery, MyUserQueryVariables>;
export const MealMenusWithCheckInInRangeDocument = gql`
    query MealMenusWithCheckInInRange($start: DateTime!, $end: DateTime!, $selectedTeamIds: [ID!]!) {
  mealMenusWithCheckInInRange(
    start: $start
    end: $end
    selectedTeamIds: $selectedTeamIds
  ) {
    mealMenu {
      id
      name
      type
      start
      teams {
        id
        name
      }
    }
    mealMenuExpectedAttendeeCount
    mealMenuActualAttendeeCount
    attendeeUnlistedCount
    attendeeListedCount
  }
}
    `;
export type MealMenusWithCheckInInRangeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenusWithCheckInInRangeQuery, MealMenusWithCheckInInRangeQueryVariables>, 'query'> & ({ variables: MealMenusWithCheckInInRangeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenusWithCheckInInRangeComponent = (props: MealMenusWithCheckInInRangeComponentProps) => (
      <ApolloReactComponents.Query<MealMenusWithCheckInInRangeQuery, MealMenusWithCheckInInRangeQueryVariables> query={MealMenusWithCheckInInRangeDocument} {...props} />
    );
    

/**
 * __useMealMenusWithCheckInInRangeQuery__
 *
 * To run a query within a React component, call `useMealMenusWithCheckInInRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenusWithCheckInInRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenusWithCheckInInRangeQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      selectedTeamIds: // value for 'selectedTeamIds'
 *   },
 * });
 */
export function useMealMenusWithCheckInInRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenusWithCheckInInRangeQuery, MealMenusWithCheckInInRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenusWithCheckInInRangeQuery, MealMenusWithCheckInInRangeQueryVariables>(MealMenusWithCheckInInRangeDocument, baseOptions);
      }
export function useMealMenusWithCheckInInRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenusWithCheckInInRangeQuery, MealMenusWithCheckInInRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenusWithCheckInInRangeQuery, MealMenusWithCheckInInRangeQueryVariables>(MealMenusWithCheckInInRangeDocument, baseOptions);
        }
export type MealMenusWithCheckInInRangeQueryHookResult = ReturnType<typeof useMealMenusWithCheckInInRangeQuery>;
export type MealMenusWithCheckInInRangeLazyQueryHookResult = ReturnType<typeof useMealMenusWithCheckInInRangeLazyQuery>;
export type MealMenusWithCheckInInRangeQueryResult = ApolloReactCommon.QueryResult<MealMenusWithCheckInInRangeQuery, MealMenusWithCheckInInRangeQueryVariables>;
export const StaffDashboardRecipesDocument = gql`
    query StaffDashboardRecipes($input: OffsetPaginationInput!, $query: String) {
  recipeOffsetConnection(input: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...StaffDashboardRecipePreview
    }
  }
}
    ${StaffDashboardRecipePreviewFragmentDoc}`;
export type StaffDashboardRecipesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffDashboardRecipesQuery, StaffDashboardRecipesQueryVariables>, 'query'> & ({ variables: StaffDashboardRecipesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffDashboardRecipesComponent = (props: StaffDashboardRecipesComponentProps) => (
      <ApolloReactComponents.Query<StaffDashboardRecipesQuery, StaffDashboardRecipesQueryVariables> query={StaffDashboardRecipesDocument} {...props} />
    );
    

/**
 * __useStaffDashboardRecipesQuery__
 *
 * To run a query within a React component, call `useStaffDashboardRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffDashboardRecipesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useStaffDashboardRecipesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffDashboardRecipesQuery, StaffDashboardRecipesQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffDashboardRecipesQuery, StaffDashboardRecipesQueryVariables>(StaffDashboardRecipesDocument, baseOptions);
      }
export function useStaffDashboardRecipesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffDashboardRecipesQuery, StaffDashboardRecipesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffDashboardRecipesQuery, StaffDashboardRecipesQueryVariables>(StaffDashboardRecipesDocument, baseOptions);
        }
export type StaffDashboardRecipesQueryHookResult = ReturnType<typeof useStaffDashboardRecipesQuery>;
export type StaffDashboardRecipesLazyQueryHookResult = ReturnType<typeof useStaffDashboardRecipesLazyQuery>;
export type StaffDashboardRecipesQueryResult = ApolloReactCommon.QueryResult<StaffDashboardRecipesQuery, StaffDashboardRecipesQueryVariables>;
export const StaffScoringSystemsDocument = gql`
    query StaffScoringSystems($pagination: OffsetPaginationInput!, $searchText: String!) {
  scoringSystemOffsetConnection(pagination: $pagination, searchText: $searchText) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...ScoringSystem
    }
  }
}
    ${ScoringSystemFragmentDoc}`;
export type StaffScoringSystemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffScoringSystemsQuery, StaffScoringSystemsQueryVariables>, 'query'> & ({ variables: StaffScoringSystemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffScoringSystemsComponent = (props: StaffScoringSystemsComponentProps) => (
      <ApolloReactComponents.Query<StaffScoringSystemsQuery, StaffScoringSystemsQueryVariables> query={StaffScoringSystemsDocument} {...props} />
    );
    

/**
 * __useStaffScoringSystemsQuery__
 *
 * To run a query within a React component, call `useStaffScoringSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffScoringSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffScoringSystemsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useStaffScoringSystemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffScoringSystemsQuery, StaffScoringSystemsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffScoringSystemsQuery, StaffScoringSystemsQueryVariables>(StaffScoringSystemsDocument, baseOptions);
      }
export function useStaffScoringSystemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffScoringSystemsQuery, StaffScoringSystemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffScoringSystemsQuery, StaffScoringSystemsQueryVariables>(StaffScoringSystemsDocument, baseOptions);
        }
export type StaffScoringSystemsQueryHookResult = ReturnType<typeof useStaffScoringSystemsQuery>;
export type StaffScoringSystemsLazyQueryHookResult = ReturnType<typeof useStaffScoringSystemsLazyQuery>;
export type StaffScoringSystemsQueryResult = ApolloReactCommon.QueryResult<StaffScoringSystemsQuery, StaffScoringSystemsQueryVariables>;
export const ScoringSystemsDocument = gql`
    query ScoringSystems {
  scoringSystems {
    ...ScoringSystem
  }
}
    ${ScoringSystemFragmentDoc}`;
export type ScoringSystemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ScoringSystemsQuery, ScoringSystemsQueryVariables>, 'query'>;

    export const ScoringSystemsComponent = (props: ScoringSystemsComponentProps) => (
      <ApolloReactComponents.Query<ScoringSystemsQuery, ScoringSystemsQueryVariables> query={ScoringSystemsDocument} {...props} />
    );
    

/**
 * __useScoringSystemsQuery__
 *
 * To run a query within a React component, call `useScoringSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoringSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoringSystemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useScoringSystemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScoringSystemsQuery, ScoringSystemsQueryVariables>) {
        return ApolloReactHooks.useQuery<ScoringSystemsQuery, ScoringSystemsQueryVariables>(ScoringSystemsDocument, baseOptions);
      }
export function useScoringSystemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScoringSystemsQuery, ScoringSystemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScoringSystemsQuery, ScoringSystemsQueryVariables>(ScoringSystemsDocument, baseOptions);
        }
export type ScoringSystemsQueryHookResult = ReturnType<typeof useScoringSystemsQuery>;
export type ScoringSystemsLazyQueryHookResult = ReturnType<typeof useScoringSystemsLazyQuery>;
export type ScoringSystemsQueryResult = ApolloReactCommon.QueryResult<ScoringSystemsQuery, ScoringSystemsQueryVariables>;
export const MyScoringSystemDocument = gql`
    query myScoringSystem {
  myScoringSystem {
    ...ScoringSystem
  }
}
    ${ScoringSystemFragmentDoc}`;
export type MyScoringSystemComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyScoringSystemQuery, MyScoringSystemQueryVariables>, 'query'>;

    export const MyScoringSystemComponent = (props: MyScoringSystemComponentProps) => (
      <ApolloReactComponents.Query<MyScoringSystemQuery, MyScoringSystemQueryVariables> query={MyScoringSystemDocument} {...props} />
    );
    

/**
 * __useMyScoringSystemQuery__
 *
 * To run a query within a React component, call `useMyScoringSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyScoringSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyScoringSystemQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyScoringSystemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyScoringSystemQuery, MyScoringSystemQueryVariables>) {
        return ApolloReactHooks.useQuery<MyScoringSystemQuery, MyScoringSystemQueryVariables>(MyScoringSystemDocument, baseOptions);
      }
export function useMyScoringSystemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyScoringSystemQuery, MyScoringSystemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyScoringSystemQuery, MyScoringSystemQueryVariables>(MyScoringSystemDocument, baseOptions);
        }
export type MyScoringSystemQueryHookResult = ReturnType<typeof useMyScoringSystemQuery>;
export type MyScoringSystemLazyQueryHookResult = ReturnType<typeof useMyScoringSystemLazyQuery>;
export type MyScoringSystemQueryResult = ApolloReactCommon.QueryResult<MyScoringSystemQuery, MyScoringSystemQueryVariables>;
export const ExchangeSetTableDocument = gql`
    query ExchangeSetTable($input: OffsetPaginationInput!, $query: String) {
  sharedExchangeSetOffsetConnection(input: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...FullExchangeSet
    }
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type ExchangeSetTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExchangeSetTableQuery, ExchangeSetTableQueryVariables>, 'query'> & ({ variables: ExchangeSetTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExchangeSetTableComponent = (props: ExchangeSetTableComponentProps) => (
      <ApolloReactComponents.Query<ExchangeSetTableQuery, ExchangeSetTableQueryVariables> query={ExchangeSetTableDocument} {...props} />
    );
    

/**
 * __useExchangeSetTableQuery__
 *
 * To run a query within a React component, call `useExchangeSetTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeSetTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeSetTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useExchangeSetTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExchangeSetTableQuery, ExchangeSetTableQueryVariables>) {
        return ApolloReactHooks.useQuery<ExchangeSetTableQuery, ExchangeSetTableQueryVariables>(ExchangeSetTableDocument, baseOptions);
      }
export function useExchangeSetTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExchangeSetTableQuery, ExchangeSetTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExchangeSetTableQuery, ExchangeSetTableQueryVariables>(ExchangeSetTableDocument, baseOptions);
        }
export type ExchangeSetTableQueryHookResult = ReturnType<typeof useExchangeSetTableQuery>;
export type ExchangeSetTableLazyQueryHookResult = ReturnType<typeof useExchangeSetTableLazyQuery>;
export type ExchangeSetTableQueryResult = ApolloReactCommon.QueryResult<ExchangeSetTableQuery, ExchangeSetTableQueryVariables>;
export const CreateSharedExchangeSetDocument = gql`
    mutation CreateSharedExchangeSet($input: CreateExchangeSetInput!) {
  createSharedExchangeSet(input: $input) {
    exchangeSet {
      ...FullExchangeSet
    }
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type CreateSharedExchangeSetMutationFn = ApolloReactCommon.MutationFunction<CreateSharedExchangeSetMutation, CreateSharedExchangeSetMutationVariables>;
export type CreateSharedExchangeSetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSharedExchangeSetMutation, CreateSharedExchangeSetMutationVariables>, 'mutation'>;

    export const CreateSharedExchangeSetComponent = (props: CreateSharedExchangeSetComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSharedExchangeSetMutation, CreateSharedExchangeSetMutationVariables> mutation={CreateSharedExchangeSetDocument} {...props} />
    );
    

/**
 * __useCreateSharedExchangeSetMutation__
 *
 * To run a mutation, you first call `useCreateSharedExchangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedExchangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedExchangeSetMutation, { data, loading, error }] = useCreateSharedExchangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSharedExchangeSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSharedExchangeSetMutation, CreateSharedExchangeSetMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSharedExchangeSetMutation, CreateSharedExchangeSetMutationVariables>(CreateSharedExchangeSetDocument, baseOptions);
      }
export type CreateSharedExchangeSetMutationHookResult = ReturnType<typeof useCreateSharedExchangeSetMutation>;
export type CreateSharedExchangeSetMutationResult = ApolloReactCommon.MutationResult<CreateSharedExchangeSetMutation>;
export type CreateSharedExchangeSetMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSharedExchangeSetMutation, CreateSharedExchangeSetMutationVariables>;
export const EditSharedExchangeSetDocument = gql`
    mutation EditSharedExchangeSet($input: EditExchangeSetInput!) {
  editSharedExchangeSet(input: $input) {
    exchangeSet {
      ...FullExchangeSet
    }
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type EditSharedExchangeSetMutationFn = ApolloReactCommon.MutationFunction<EditSharedExchangeSetMutation, EditSharedExchangeSetMutationVariables>;
export type EditSharedExchangeSetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditSharedExchangeSetMutation, EditSharedExchangeSetMutationVariables>, 'mutation'>;

    export const EditSharedExchangeSetComponent = (props: EditSharedExchangeSetComponentProps) => (
      <ApolloReactComponents.Mutation<EditSharedExchangeSetMutation, EditSharedExchangeSetMutationVariables> mutation={EditSharedExchangeSetDocument} {...props} />
    );
    

/**
 * __useEditSharedExchangeSetMutation__
 *
 * To run a mutation, you first call `useEditSharedExchangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSharedExchangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSharedExchangeSetMutation, { data, loading, error }] = useEditSharedExchangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSharedExchangeSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSharedExchangeSetMutation, EditSharedExchangeSetMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSharedExchangeSetMutation, EditSharedExchangeSetMutationVariables>(EditSharedExchangeSetDocument, baseOptions);
      }
export type EditSharedExchangeSetMutationHookResult = ReturnType<typeof useEditSharedExchangeSetMutation>;
export type EditSharedExchangeSetMutationResult = ApolloReactCommon.MutationResult<EditSharedExchangeSetMutation>;
export type EditSharedExchangeSetMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSharedExchangeSetMutation, EditSharedExchangeSetMutationVariables>;
export const OrgNavDocument = gql`
    query OrgNav($id: ID!) {
  org(id: $id) {
    ...NavOrg
  }
}
    ${NavOrgFragmentDoc}`;
export type OrgNavComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgNavQuery, OrgNavQueryVariables>, 'query'> & ({ variables: OrgNavQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgNavComponent = (props: OrgNavComponentProps) => (
      <ApolloReactComponents.Query<OrgNavQuery, OrgNavQueryVariables> query={OrgNavDocument} {...props} />
    );
    

/**
 * __useOrgNavQuery__
 *
 * To run a query within a React component, call `useOrgNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgNavQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgNavQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgNavQuery, OrgNavQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgNavQuery, OrgNavQueryVariables>(OrgNavDocument, baseOptions);
      }
export function useOrgNavLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgNavQuery, OrgNavQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgNavQuery, OrgNavQueryVariables>(OrgNavDocument, baseOptions);
        }
export type OrgNavQueryHookResult = ReturnType<typeof useOrgNavQuery>;
export type OrgNavLazyQueryHookResult = ReturnType<typeof useOrgNavLazyQuery>;
export type OrgNavQueryResult = ApolloReactCommon.QueryResult<OrgNavQuery, OrgNavQueryVariables>;
export const RestaurantNavDocument = gql`
    query RestaurantNav($id: ID!) {
  restaurant(id: $id) {
    ...NavRestaurant
  }
}
    ${NavRestaurantFragmentDoc}`;
export type RestaurantNavComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantNavQuery, RestaurantNavQueryVariables>, 'query'> & ({ variables: RestaurantNavQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantNavComponent = (props: RestaurantNavComponentProps) => (
      <ApolloReactComponents.Query<RestaurantNavQuery, RestaurantNavQueryVariables> query={RestaurantNavDocument} {...props} />
    );
    

/**
 * __useRestaurantNavQuery__
 *
 * To run a query within a React component, call `useRestaurantNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantNavQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestaurantNavQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantNavQuery, RestaurantNavQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantNavQuery, RestaurantNavQueryVariables>(RestaurantNavDocument, baseOptions);
      }
export function useRestaurantNavLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantNavQuery, RestaurantNavQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantNavQuery, RestaurantNavQueryVariables>(RestaurantNavDocument, baseOptions);
        }
export type RestaurantNavQueryHookResult = ReturnType<typeof useRestaurantNavQuery>;
export type RestaurantNavLazyQueryHookResult = ReturnType<typeof useRestaurantNavLazyQuery>;
export type RestaurantNavQueryResult = ApolloReactCommon.QueryResult<RestaurantNavQuery, RestaurantNavQueryVariables>;
export const StaffDashboardCuisinesDocument = gql`
    query StaffDashboardCuisines($input: OffsetPaginationInput!, $query: String) {
  cuisineOffsetConnection(pagination: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...Cuisine
    }
  }
}
    ${CuisineFragmentDoc}`;
export type StaffDashboardCuisinesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffDashboardCuisinesQuery, StaffDashboardCuisinesQueryVariables>, 'query'> & ({ variables: StaffDashboardCuisinesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffDashboardCuisinesComponent = (props: StaffDashboardCuisinesComponentProps) => (
      <ApolloReactComponents.Query<StaffDashboardCuisinesQuery, StaffDashboardCuisinesQueryVariables> query={StaffDashboardCuisinesDocument} {...props} />
    );
    

/**
 * __useStaffDashboardCuisinesQuery__
 *
 * To run a query within a React component, call `useStaffDashboardCuisinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardCuisinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffDashboardCuisinesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useStaffDashboardCuisinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffDashboardCuisinesQuery, StaffDashboardCuisinesQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffDashboardCuisinesQuery, StaffDashboardCuisinesQueryVariables>(StaffDashboardCuisinesDocument, baseOptions);
      }
export function useStaffDashboardCuisinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffDashboardCuisinesQuery, StaffDashboardCuisinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffDashboardCuisinesQuery, StaffDashboardCuisinesQueryVariables>(StaffDashboardCuisinesDocument, baseOptions);
        }
export type StaffDashboardCuisinesQueryHookResult = ReturnType<typeof useStaffDashboardCuisinesQuery>;
export type StaffDashboardCuisinesLazyQueryHookResult = ReturnType<typeof useStaffDashboardCuisinesLazyQuery>;
export type StaffDashboardCuisinesQueryResult = ApolloReactCommon.QueryResult<StaffDashboardCuisinesQuery, StaffDashboardCuisinesQueryVariables>;
export const CreateCuisineDocument = gql`
    mutation CreateCuisine($input: CreateCuisineInput!) {
  createCuisine(input: $input) {
    cuisine {
      ...Cuisine
    }
  }
}
    ${CuisineFragmentDoc}`;
export type CreateCuisineMutationFn = ApolloReactCommon.MutationFunction<CreateCuisineMutation, CreateCuisineMutationVariables>;
export type CreateCuisineComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCuisineMutation, CreateCuisineMutationVariables>, 'mutation'>;

    export const CreateCuisineComponent = (props: CreateCuisineComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCuisineMutation, CreateCuisineMutationVariables> mutation={CreateCuisineDocument} {...props} />
    );
    

/**
 * __useCreateCuisineMutation__
 *
 * To run a mutation, you first call `useCreateCuisineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCuisineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCuisineMutation, { data, loading, error }] = useCreateCuisineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCuisineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCuisineMutation, CreateCuisineMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCuisineMutation, CreateCuisineMutationVariables>(CreateCuisineDocument, baseOptions);
      }
export type CreateCuisineMutationHookResult = ReturnType<typeof useCreateCuisineMutation>;
export type CreateCuisineMutationResult = ApolloReactCommon.MutationResult<CreateCuisineMutation>;
export type CreateCuisineMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCuisineMutation, CreateCuisineMutationVariables>;
export const EditCuisineDocument = gql`
    mutation EditCuisine($input: EditCuisineInput!) {
  editCuisine(input: $input) {
    cuisine {
      ...Cuisine
    }
  }
}
    ${CuisineFragmentDoc}`;
export type EditCuisineMutationFn = ApolloReactCommon.MutationFunction<EditCuisineMutation, EditCuisineMutationVariables>;
export type EditCuisineComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditCuisineMutation, EditCuisineMutationVariables>, 'mutation'>;

    export const EditCuisineComponent = (props: EditCuisineComponentProps) => (
      <ApolloReactComponents.Mutation<EditCuisineMutation, EditCuisineMutationVariables> mutation={EditCuisineDocument} {...props} />
    );
    

/**
 * __useEditCuisineMutation__
 *
 * To run a mutation, you first call `useEditCuisineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCuisineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCuisineMutation, { data, loading, error }] = useEditCuisineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCuisineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCuisineMutation, EditCuisineMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCuisineMutation, EditCuisineMutationVariables>(EditCuisineDocument, baseOptions);
      }
export type EditCuisineMutationHookResult = ReturnType<typeof useEditCuisineMutation>;
export type EditCuisineMutationResult = ApolloReactCommon.MutationResult<EditCuisineMutation>;
export type EditCuisineMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCuisineMutation, EditCuisineMutationVariables>;
export const DeleteCuisineDocument = gql`
    mutation DeleteCuisine($input: DeleteCuisineInput!) {
  deleteCuisine(input: $input) {
    id
  }
}
    `;
export type DeleteCuisineMutationFn = ApolloReactCommon.MutationFunction<DeleteCuisineMutation, DeleteCuisineMutationVariables>;
export type DeleteCuisineComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCuisineMutation, DeleteCuisineMutationVariables>, 'mutation'>;

    export const DeleteCuisineComponent = (props: DeleteCuisineComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCuisineMutation, DeleteCuisineMutationVariables> mutation={DeleteCuisineDocument} {...props} />
    );
    

/**
 * __useDeleteCuisineMutation__
 *
 * To run a mutation, you first call `useDeleteCuisineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCuisineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCuisineMutation, { data, loading, error }] = useDeleteCuisineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCuisineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCuisineMutation, DeleteCuisineMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCuisineMutation, DeleteCuisineMutationVariables>(DeleteCuisineDocument, baseOptions);
      }
export type DeleteCuisineMutationHookResult = ReturnType<typeof useDeleteCuisineMutation>;
export type DeleteCuisineMutationResult = ApolloReactCommon.MutationResult<DeleteCuisineMutation>;
export type DeleteCuisineMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCuisineMutation, DeleteCuisineMutationVariables>;
export const StaffDashboardPaymentMethodsDocument = gql`
    query StaffDashboardPaymentMethods($input: OffsetPaginationInput!, $query: String) {
  paymentMethodOffsetConnection(pagination: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...PaymentMethod
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export type StaffDashboardPaymentMethodsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffDashboardPaymentMethodsQuery, StaffDashboardPaymentMethodsQueryVariables>, 'query'> & ({ variables: StaffDashboardPaymentMethodsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffDashboardPaymentMethodsComponent = (props: StaffDashboardPaymentMethodsComponentProps) => (
      <ApolloReactComponents.Query<StaffDashboardPaymentMethodsQuery, StaffDashboardPaymentMethodsQueryVariables> query={StaffDashboardPaymentMethodsDocument} {...props} />
    );
    

/**
 * __useStaffDashboardPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useStaffDashboardPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffDashboardPaymentMethodsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useStaffDashboardPaymentMethodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffDashboardPaymentMethodsQuery, StaffDashboardPaymentMethodsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffDashboardPaymentMethodsQuery, StaffDashboardPaymentMethodsQueryVariables>(StaffDashboardPaymentMethodsDocument, baseOptions);
      }
export function useStaffDashboardPaymentMethodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffDashboardPaymentMethodsQuery, StaffDashboardPaymentMethodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffDashboardPaymentMethodsQuery, StaffDashboardPaymentMethodsQueryVariables>(StaffDashboardPaymentMethodsDocument, baseOptions);
        }
export type StaffDashboardPaymentMethodsQueryHookResult = ReturnType<typeof useStaffDashboardPaymentMethodsQuery>;
export type StaffDashboardPaymentMethodsLazyQueryHookResult = ReturnType<typeof useStaffDashboardPaymentMethodsLazyQuery>;
export type StaffDashboardPaymentMethodsQueryResult = ApolloReactCommon.QueryResult<StaffDashboardPaymentMethodsQuery, StaffDashboardPaymentMethodsQueryVariables>;
export const CreatePaymentMethodDocument = gql`
    mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
  createPaymentMethod(input: $input) {
    paymentMethod {
      ...PaymentMethod
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export type CreatePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export type CreatePaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>, 'mutation'>;

    export const CreatePaymentMethodComponent = (props: CreatePaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables> mutation={CreatePaymentMethodDocument} {...props} />
    );
    

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, baseOptions);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = ApolloReactCommon.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export const EditPaymentMethodDocument = gql`
    mutation EditPaymentMethod($input: EditPaymentMethodInput!) {
  editPaymentMethod(input: $input) {
    paymentMethod {
      ...PaymentMethod
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export type EditPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<EditPaymentMethodMutation, EditPaymentMethodMutationVariables>;
export type EditPaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditPaymentMethodMutation, EditPaymentMethodMutationVariables>, 'mutation'>;

    export const EditPaymentMethodComponent = (props: EditPaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<EditPaymentMethodMutation, EditPaymentMethodMutationVariables> mutation={EditPaymentMethodDocument} {...props} />
    );
    

/**
 * __useEditPaymentMethodMutation__
 *
 * To run a mutation, you first call `useEditPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPaymentMethodMutation, { data, loading, error }] = useEditPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPaymentMethodMutation, EditPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<EditPaymentMethodMutation, EditPaymentMethodMutationVariables>(EditPaymentMethodDocument, baseOptions);
      }
export type EditPaymentMethodMutationHookResult = ReturnType<typeof useEditPaymentMethodMutation>;
export type EditPaymentMethodMutationResult = ApolloReactCommon.MutationResult<EditPaymentMethodMutation>;
export type EditPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<EditPaymentMethodMutation, EditPaymentMethodMutationVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation DeletePaymentMethod($input: DeletePaymentMethodInput!) {
  deletePaymentMethod(input: $input) {
    id
  }
}
    `;
export type DeletePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export type DeletePaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>, 'mutation'>;

    export const DeletePaymentMethodComponent = (props: DeletePaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables> mutation={DeletePaymentMethodDocument} {...props} />
    );
    

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, baseOptions);
      }
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = ApolloReactCommon.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const IntegrationsDocument = gql`
    query Integrations {
  integrations
}
    `;
export type IntegrationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IntegrationsQuery, IntegrationsQueryVariables>, 'query'>;

    export const IntegrationsComponent = (props: IntegrationsComponentProps) => (
      <ApolloReactComponents.Query<IntegrationsQuery, IntegrationsQueryVariables> query={IntegrationsDocument} {...props} />
    );
    

/**
 * __useIntegrationsQuery__
 *
 * To run a query within a React component, call `useIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
        return ApolloReactHooks.useQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, baseOptions);
      }
export function useIntegrationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, baseOptions);
        }
export type IntegrationsQueryHookResult = ReturnType<typeof useIntegrationsQuery>;
export type IntegrationsLazyQueryHookResult = ReturnType<typeof useIntegrationsLazyQuery>;
export type IntegrationsQueryResult = ApolloReactCommon.QueryResult<IntegrationsQuery, IntegrationsQueryVariables>;
export const MarkNotificationsAsViewedDocument = gql`
    mutation MarkNotificationsAsViewed($input: MarkNotificationsAsViewedInput!) {
  markNotificationsAsViewed(input: $input) {
    notifications {
      id
      viewedAt
    }
  }
}
    `;
export type MarkNotificationsAsViewedMutationFn = ApolloReactCommon.MutationFunction<MarkNotificationsAsViewedMutation, MarkNotificationsAsViewedMutationVariables>;
export type MarkNotificationsAsViewedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkNotificationsAsViewedMutation, MarkNotificationsAsViewedMutationVariables>, 'mutation'>;

    export const MarkNotificationsAsViewedComponent = (props: MarkNotificationsAsViewedComponentProps) => (
      <ApolloReactComponents.Mutation<MarkNotificationsAsViewedMutation, MarkNotificationsAsViewedMutationVariables> mutation={MarkNotificationsAsViewedDocument} {...props} />
    );
    

/**
 * __useMarkNotificationsAsViewedMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsViewedMutation, { data, loading, error }] = useMarkNotificationsAsViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNotificationsAsViewedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkNotificationsAsViewedMutation, MarkNotificationsAsViewedMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkNotificationsAsViewedMutation, MarkNotificationsAsViewedMutationVariables>(MarkNotificationsAsViewedDocument, baseOptions);
      }
export type MarkNotificationsAsViewedMutationHookResult = ReturnType<typeof useMarkNotificationsAsViewedMutation>;
export type MarkNotificationsAsViewedMutationResult = ApolloReactCommon.MutationResult<MarkNotificationsAsViewedMutation>;
export type MarkNotificationsAsViewedMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkNotificationsAsViewedMutation, MarkNotificationsAsViewedMutationVariables>;
export const UserOrgForReportingDocument = gql`
    query UserOrgForReporting {
  myUser {
    ...ReportingUser
  }
  myOrgForReporting {
    ...ReportingOrg
  }
}
    ${ReportingUserFragmentDoc}
${ReportingOrgFragmentDoc}`;
export type UserOrgForReportingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserOrgForReportingQuery, UserOrgForReportingQueryVariables>, 'query'>;

    export const UserOrgForReportingComponent = (props: UserOrgForReportingComponentProps) => (
      <ApolloReactComponents.Query<UserOrgForReportingQuery, UserOrgForReportingQueryVariables> query={UserOrgForReportingDocument} {...props} />
    );
    

/**
 * __useUserOrgForReportingQuery__
 *
 * To run a query within a React component, call `useUserOrgForReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrgForReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrgForReportingQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrgForReportingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserOrgForReportingQuery, UserOrgForReportingQueryVariables>) {
        return ApolloReactHooks.useQuery<UserOrgForReportingQuery, UserOrgForReportingQueryVariables>(UserOrgForReportingDocument, baseOptions);
      }
export function useUserOrgForReportingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserOrgForReportingQuery, UserOrgForReportingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserOrgForReportingQuery, UserOrgForReportingQueryVariables>(UserOrgForReportingDocument, baseOptions);
        }
export type UserOrgForReportingQueryHookResult = ReturnType<typeof useUserOrgForReportingQuery>;
export type UserOrgForReportingLazyQueryHookResult = ReturnType<typeof useUserOrgForReportingLazyQuery>;
export type UserOrgForReportingQueryResult = ApolloReactCommon.QueryResult<UserOrgForReportingQuery, UserOrgForReportingQueryVariables>;
export const AthleteTimelineDocument = gql`
    query AthleteTimeline($athleteId: ID!) {
  athlete(id: $athleteId) {
    id
    sex
    birthDate
    user {
      id
    }
    team {
      id
      sport {
        name
      }
    }
    likedFoodGroups {
      ...FullFoodGroupWithFoods
    }
    dislikedFoodGroups {
      ...FullFoodGroupWithFoods
    }
    likedFoods {
      ...Food
    }
    dislikedFoods {
      ...Food
    }
    mealPlans {
      ...PreviewMealPlan
      mealTemplates {
        meal {
          ...Meal
        }
      }
      activityTemplates {
        activity {
          ...Activity
        }
      }
    }
    mostRecentAnthropometryEntry {
      ...AnthropometryEntry
    }
    currentGoal {
      ...FullGoal
    }
  }
  goalTypes {
    ...GoalType
  }
  exchangeSets {
    ...ExchangeSet
  }
}
    ${FullFoodGroupWithFoodsFragmentDoc}
${FoodFragmentDoc}
${PreviewMealPlanFragmentDoc}
${MealFragmentDoc}
${ActivityFragmentDoc}
${AnthropometryEntryFragmentDoc}
${FullGoalFragmentDoc}
${GoalTypeFragmentDoc}
${ExchangeSetFragmentDoc}`;
export type AthleteTimelineComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteTimelineQuery, AthleteTimelineQueryVariables>, 'query'> & ({ variables: AthleteTimelineQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteTimelineComponent = (props: AthleteTimelineComponentProps) => (
      <ApolloReactComponents.Query<AthleteTimelineQuery, AthleteTimelineQueryVariables> query={AthleteTimelineDocument} {...props} />
    );
    

/**
 * __useAthleteTimelineQuery__
 *
 * To run a query within a React component, call `useAthleteTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteTimelineQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useAthleteTimelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteTimelineQuery, AthleteTimelineQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteTimelineQuery, AthleteTimelineQueryVariables>(AthleteTimelineDocument, baseOptions);
      }
export function useAthleteTimelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteTimelineQuery, AthleteTimelineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteTimelineQuery, AthleteTimelineQueryVariables>(AthleteTimelineDocument, baseOptions);
        }
export type AthleteTimelineQueryHookResult = ReturnType<typeof useAthleteTimelineQuery>;
export type AthleteTimelineLazyQueryHookResult = ReturnType<typeof useAthleteTimelineLazyQuery>;
export type AthleteTimelineQueryResult = ApolloReactCommon.QueryResult<AthleteTimelineQuery, AthleteTimelineQueryVariables>;
export const AthleteTimelinePreviewDocument = gql`
    query AthleteTimelinePreview($athleteId: ID!, $start: DateTime!, $end: DateTime!) {
  athlete(id: $athleteId) {
    id
    timelineMealsInRange(start: $start, end: $end) {
      ...TimelineMealForGroupByDate
    }
  }
}
    ${TimelineMealForGroupByDateFragmentDoc}`;
export type AthleteTimelinePreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteTimelinePreviewQuery, AthleteTimelinePreviewQueryVariables>, 'query'> & ({ variables: AthleteTimelinePreviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteTimelinePreviewComponent = (props: AthleteTimelinePreviewComponentProps) => (
      <ApolloReactComponents.Query<AthleteTimelinePreviewQuery, AthleteTimelinePreviewQueryVariables> query={AthleteTimelinePreviewDocument} {...props} />
    );
    

/**
 * __useAthleteTimelinePreviewQuery__
 *
 * To run a query within a React component, call `useAthleteTimelinePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteTimelinePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteTimelinePreviewQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAthleteTimelinePreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteTimelinePreviewQuery, AthleteTimelinePreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteTimelinePreviewQuery, AthleteTimelinePreviewQueryVariables>(AthleteTimelinePreviewDocument, baseOptions);
      }
export function useAthleteTimelinePreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteTimelinePreviewQuery, AthleteTimelinePreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteTimelinePreviewQuery, AthleteTimelinePreviewQueryVariables>(AthleteTimelinePreviewDocument, baseOptions);
        }
export type AthleteTimelinePreviewQueryHookResult = ReturnType<typeof useAthleteTimelinePreviewQuery>;
export type AthleteTimelinePreviewLazyQueryHookResult = ReturnType<typeof useAthleteTimelinePreviewLazyQuery>;
export type AthleteTimelinePreviewQueryResult = ApolloReactCommon.QueryResult<AthleteTimelinePreviewQuery, AthleteTimelinePreviewQueryVariables>;
export const AthleteRecentTimelineCalendarDatesWithActivityDocument = gql`
    query AthleteRecentTimelineCalendarDatesWithActivity($athleteId: ID!, $timezone: Timezone!) {
  athlete(id: $athleteId) {
    id
    recentTimelineCalendarDatesWithActivity(limit: 5, timezone: $timezone)
  }
}
    `;
export type AthleteRecentTimelineCalendarDatesWithActivityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteRecentTimelineCalendarDatesWithActivityQuery, AthleteRecentTimelineCalendarDatesWithActivityQueryVariables>, 'query'> & ({ variables: AthleteRecentTimelineCalendarDatesWithActivityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteRecentTimelineCalendarDatesWithActivityComponent = (props: AthleteRecentTimelineCalendarDatesWithActivityComponentProps) => (
      <ApolloReactComponents.Query<AthleteRecentTimelineCalendarDatesWithActivityQuery, AthleteRecentTimelineCalendarDatesWithActivityQueryVariables> query={AthleteRecentTimelineCalendarDatesWithActivityDocument} {...props} />
    );
    

/**
 * __useAthleteRecentTimelineCalendarDatesWithActivityQuery__
 *
 * To run a query within a React component, call `useAthleteRecentTimelineCalendarDatesWithActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteRecentTimelineCalendarDatesWithActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteRecentTimelineCalendarDatesWithActivityQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useAthleteRecentTimelineCalendarDatesWithActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteRecentTimelineCalendarDatesWithActivityQuery, AthleteRecentTimelineCalendarDatesWithActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteRecentTimelineCalendarDatesWithActivityQuery, AthleteRecentTimelineCalendarDatesWithActivityQueryVariables>(AthleteRecentTimelineCalendarDatesWithActivityDocument, baseOptions);
      }
export function useAthleteRecentTimelineCalendarDatesWithActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteRecentTimelineCalendarDatesWithActivityQuery, AthleteRecentTimelineCalendarDatesWithActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteRecentTimelineCalendarDatesWithActivityQuery, AthleteRecentTimelineCalendarDatesWithActivityQueryVariables>(AthleteRecentTimelineCalendarDatesWithActivityDocument, baseOptions);
        }
export type AthleteRecentTimelineCalendarDatesWithActivityQueryHookResult = ReturnType<typeof useAthleteRecentTimelineCalendarDatesWithActivityQuery>;
export type AthleteRecentTimelineCalendarDatesWithActivityLazyQueryHookResult = ReturnType<typeof useAthleteRecentTimelineCalendarDatesWithActivityLazyQuery>;
export type AthleteRecentTimelineCalendarDatesWithActivityQueryResult = ApolloReactCommon.QueryResult<AthleteRecentTimelineCalendarDatesWithActivityQuery, AthleteRecentTimelineCalendarDatesWithActivityQueryVariables>;
export const MealPlanMacroProtocolDocument = gql`
    query MealPlanMacroProtocol($mealPlanId: ID!) {
  mealPlan(id: $mealPlanId) {
    id
    macroProtocol {
      ...FullMacroProtocol
    }
  }
}
    ${FullMacroProtocolFragmentDoc}`;
export type MealPlanMacroProtocolComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanMacroProtocolQuery, MealPlanMacroProtocolQueryVariables>, 'query'> & ({ variables: MealPlanMacroProtocolQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanMacroProtocolComponent = (props: MealPlanMacroProtocolComponentProps) => (
      <ApolloReactComponents.Query<MealPlanMacroProtocolQuery, MealPlanMacroProtocolQueryVariables> query={MealPlanMacroProtocolDocument} {...props} />
    );
    

/**
 * __useMealPlanMacroProtocolQuery__
 *
 * To run a query within a React component, call `useMealPlanMacroProtocolQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanMacroProtocolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanMacroProtocolQuery({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useMealPlanMacroProtocolQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanMacroProtocolQuery, MealPlanMacroProtocolQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanMacroProtocolQuery, MealPlanMacroProtocolQueryVariables>(MealPlanMacroProtocolDocument, baseOptions);
      }
export function useMealPlanMacroProtocolLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanMacroProtocolQuery, MealPlanMacroProtocolQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanMacroProtocolQuery, MealPlanMacroProtocolQueryVariables>(MealPlanMacroProtocolDocument, baseOptions);
        }
export type MealPlanMacroProtocolQueryHookResult = ReturnType<typeof useMealPlanMacroProtocolQuery>;
export type MealPlanMacroProtocolLazyQueryHookResult = ReturnType<typeof useMealPlanMacroProtocolLazyQuery>;
export type MealPlanMacroProtocolQueryResult = ApolloReactCommon.QueryResult<MealPlanMacroProtocolQuery, MealPlanMacroProtocolQueryVariables>;
export const AthleteTimelineForDateDocument = gql`
    query AthleteTimelineForDate($athleteId: ID!, $date: Date!, $timezone: Timezone!) {
  athlete(id: $athleteId) {
    id
    timelineDate(date: $date, timezone: $timezone) {
      ...TimelineDate
    }
    mealMenusOnDate(date: $date, timezone: $timezone) {
      ...MealMenuTimeline
    }
  }
}
    ${TimelineDateFragmentDoc}
${MealMenuTimelineFragmentDoc}`;
export type AthleteTimelineForDateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteTimelineForDateQuery, AthleteTimelineForDateQueryVariables>, 'query'> & ({ variables: AthleteTimelineForDateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteTimelineForDateComponent = (props: AthleteTimelineForDateComponentProps) => (
      <ApolloReactComponents.Query<AthleteTimelineForDateQuery, AthleteTimelineForDateQueryVariables> query={AthleteTimelineForDateDocument} {...props} />
    );
    

/**
 * __useAthleteTimelineForDateQuery__
 *
 * To run a query within a React component, call `useAthleteTimelineForDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteTimelineForDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteTimelineForDateQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useAthleteTimelineForDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteTimelineForDateQuery, AthleteTimelineForDateQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteTimelineForDateQuery, AthleteTimelineForDateQueryVariables>(AthleteTimelineForDateDocument, baseOptions);
      }
export function useAthleteTimelineForDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteTimelineForDateQuery, AthleteTimelineForDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteTimelineForDateQuery, AthleteTimelineForDateQueryVariables>(AthleteTimelineForDateDocument, baseOptions);
        }
export type AthleteTimelineForDateQueryHookResult = ReturnType<typeof useAthleteTimelineForDateQuery>;
export type AthleteTimelineForDateLazyQueryHookResult = ReturnType<typeof useAthleteTimelineForDateLazyQuery>;
export type AthleteTimelineForDateQueryResult = ApolloReactCommon.QueryResult<AthleteTimelineForDateQuery, AthleteTimelineForDateQueryVariables>;
export const MealMenuDocument = gql`
    query MealMenu($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    ...MealMenuWithItemPreviewsAndTimeInfo
  }
}
    ${MealMenuWithItemPreviewsAndTimeInfoFragmentDoc}`;
export type MealMenuComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenuQuery, MealMenuQueryVariables>, 'query'> & ({ variables: MealMenuQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenuComponent = (props: MealMenuComponentProps) => (
      <ApolloReactComponents.Query<MealMenuQuery, MealMenuQueryVariables> query={MealMenuDocument} {...props} />
    );
    

/**
 * __useMealMenuQuery__
 *
 * To run a query within a React component, call `useMealMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMealMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenuQuery, MealMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenuQuery, MealMenuQueryVariables>(MealMenuDocument, baseOptions);
      }
export function useMealMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenuQuery, MealMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenuQuery, MealMenuQueryVariables>(MealMenuDocument, baseOptions);
        }
export type MealMenuQueryHookResult = ReturnType<typeof useMealMenuQuery>;
export type MealMenuLazyQueryHookResult = ReturnType<typeof useMealMenuLazyQuery>;
export type MealMenuQueryResult = ApolloReactCommon.QueryResult<MealMenuQuery, MealMenuQueryVariables>;
export const AddMenuOrderCommentDocument = gql`
    mutation AddMenuOrderComment($input: AddMenuOrderCommentInput!) {
  addMenuOrderComment(input: $input) {
    comment {
      ...CommentTimeline
    }
  }
}
    ${CommentTimelineFragmentDoc}`;
export type AddMenuOrderCommentMutationFn = ApolloReactCommon.MutationFunction<AddMenuOrderCommentMutation, AddMenuOrderCommentMutationVariables>;
export type AddMenuOrderCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMenuOrderCommentMutation, AddMenuOrderCommentMutationVariables>, 'mutation'>;

    export const AddMenuOrderCommentComponent = (props: AddMenuOrderCommentComponentProps) => (
      <ApolloReactComponents.Mutation<AddMenuOrderCommentMutation, AddMenuOrderCommentMutationVariables> mutation={AddMenuOrderCommentDocument} {...props} />
    );
    

/**
 * __useAddMenuOrderCommentMutation__
 *
 * To run a mutation, you first call `useAddMenuOrderCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMenuOrderCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMenuOrderCommentMutation, { data, loading, error }] = useAddMenuOrderCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMenuOrderCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMenuOrderCommentMutation, AddMenuOrderCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMenuOrderCommentMutation, AddMenuOrderCommentMutationVariables>(AddMenuOrderCommentDocument, baseOptions);
      }
export type AddMenuOrderCommentMutationHookResult = ReturnType<typeof useAddMenuOrderCommentMutation>;
export type AddMenuOrderCommentMutationResult = ApolloReactCommon.MutationResult<AddMenuOrderCommentMutation>;
export type AddMenuOrderCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMenuOrderCommentMutation, AddMenuOrderCommentMutationVariables>;
export const EditMealPlanDateDocument = gql`
    mutation EditMealPlanDate($input: EditMealPlanDateForAthleteInput!) {
  editMealPlanDateForAthlete(input: $input) {
    mealPlanDate {
      date
      ...MealPlanDateTimeline
    }
  }
}
    ${MealPlanDateTimelineFragmentDoc}`;
export type EditMealPlanDateMutationFn = ApolloReactCommon.MutationFunction<EditMealPlanDateMutation, EditMealPlanDateMutationVariables>;
export type EditMealPlanDateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealPlanDateMutation, EditMealPlanDateMutationVariables>, 'mutation'>;

    export const EditMealPlanDateComponent = (props: EditMealPlanDateComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealPlanDateMutation, EditMealPlanDateMutationVariables> mutation={EditMealPlanDateDocument} {...props} />
    );
    

/**
 * __useEditMealPlanDateMutation__
 *
 * To run a mutation, you first call `useEditMealPlanDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealPlanDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealPlanDateMutation, { data, loading, error }] = useEditMealPlanDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealPlanDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealPlanDateMutation, EditMealPlanDateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealPlanDateMutation, EditMealPlanDateMutationVariables>(EditMealPlanDateDocument, baseOptions);
      }
export type EditMealPlanDateMutationHookResult = ReturnType<typeof useEditMealPlanDateMutation>;
export type EditMealPlanDateMutationResult = ApolloReactCommon.MutationResult<EditMealPlanDateMutation>;
export type EditMealPlanDateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealPlanDateMutation, EditMealPlanDateMutationVariables>;
export const RemoveMealPlanDateDocument = gql`
    mutation RemoveMealPlanDate($input: RemoveMealPlanDateForAthleteInput!) {
  removeMealPlanDateForAthlete(input: $input) {
    date
  }
}
    `;
export type RemoveMealPlanDateMutationFn = ApolloReactCommon.MutationFunction<RemoveMealPlanDateMutation, RemoveMealPlanDateMutationVariables>;
export type RemoveMealPlanDateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveMealPlanDateMutation, RemoveMealPlanDateMutationVariables>, 'mutation'>;

    export const RemoveMealPlanDateComponent = (props: RemoveMealPlanDateComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveMealPlanDateMutation, RemoveMealPlanDateMutationVariables> mutation={RemoveMealPlanDateDocument} {...props} />
    );
    

/**
 * __useRemoveMealPlanDateMutation__
 *
 * To run a mutation, you first call `useRemoveMealPlanDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealPlanDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealPlanDateMutation, { data, loading, error }] = useRemoveMealPlanDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMealPlanDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealPlanDateMutation, RemoveMealPlanDateMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealPlanDateMutation, RemoveMealPlanDateMutationVariables>(RemoveMealPlanDateDocument, baseOptions);
      }
export type RemoveMealPlanDateMutationHookResult = ReturnType<typeof useRemoveMealPlanDateMutation>;
export type RemoveMealPlanDateMutationResult = ApolloReactCommon.MutationResult<RemoveMealPlanDateMutation>;
export type RemoveMealPlanDateMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealPlanDateMutation, RemoveMealPlanDateMutationVariables>;
export const AddFoodLogActivityWithTemplateDocument = gql`
    mutation AddFoodLogActivityWithTemplate($input: AddFoodLogActivityWithTemplateForAthleteInput!) {
  addFoodLogActivityWithTemplateForAthlete(input: $input) {
    foodLogActivity {
      ...FoodLogActivityTimeline
    }
  }
}
    ${FoodLogActivityTimelineFragmentDoc}`;
export type AddFoodLogActivityWithTemplateMutationFn = ApolloReactCommon.MutationFunction<AddFoodLogActivityWithTemplateMutation, AddFoodLogActivityWithTemplateMutationVariables>;
export type AddFoodLogActivityWithTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddFoodLogActivityWithTemplateMutation, AddFoodLogActivityWithTemplateMutationVariables>, 'mutation'>;

    export const AddFoodLogActivityWithTemplateComponent = (props: AddFoodLogActivityWithTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<AddFoodLogActivityWithTemplateMutation, AddFoodLogActivityWithTemplateMutationVariables> mutation={AddFoodLogActivityWithTemplateDocument} {...props} />
    );
    

/**
 * __useAddFoodLogActivityWithTemplateMutation__
 *
 * To run a mutation, you first call `useAddFoodLogActivityWithTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFoodLogActivityWithTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFoodLogActivityWithTemplateMutation, { data, loading, error }] = useAddFoodLogActivityWithTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFoodLogActivityWithTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFoodLogActivityWithTemplateMutation, AddFoodLogActivityWithTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFoodLogActivityWithTemplateMutation, AddFoodLogActivityWithTemplateMutationVariables>(AddFoodLogActivityWithTemplateDocument, baseOptions);
      }
export type AddFoodLogActivityWithTemplateMutationHookResult = ReturnType<typeof useAddFoodLogActivityWithTemplateMutation>;
export type AddFoodLogActivityWithTemplateMutationResult = ApolloReactCommon.MutationResult<AddFoodLogActivityWithTemplateMutation>;
export type AddFoodLogActivityWithTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFoodLogActivityWithTemplateMutation, AddFoodLogActivityWithTemplateMutationVariables>;
export const AddFoodLogActivityWithoutTemplateDocument = gql`
    mutation AddFoodLogActivityWithoutTemplate($input: AddFoodLogActivityWithoutTemplateForAthleteInput!) {
  addFoodLogActivityWithoutTemplateForAthlete(input: $input) {
    foodLogActivity {
      ...FoodLogActivityTimeline
    }
  }
}
    ${FoodLogActivityTimelineFragmentDoc}`;
export type AddFoodLogActivityWithoutTemplateMutationFn = ApolloReactCommon.MutationFunction<AddFoodLogActivityWithoutTemplateMutation, AddFoodLogActivityWithoutTemplateMutationVariables>;
export type AddFoodLogActivityWithoutTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddFoodLogActivityWithoutTemplateMutation, AddFoodLogActivityWithoutTemplateMutationVariables>, 'mutation'>;

    export const AddFoodLogActivityWithoutTemplateComponent = (props: AddFoodLogActivityWithoutTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<AddFoodLogActivityWithoutTemplateMutation, AddFoodLogActivityWithoutTemplateMutationVariables> mutation={AddFoodLogActivityWithoutTemplateDocument} {...props} />
    );
    

/**
 * __useAddFoodLogActivityWithoutTemplateMutation__
 *
 * To run a mutation, you first call `useAddFoodLogActivityWithoutTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFoodLogActivityWithoutTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFoodLogActivityWithoutTemplateMutation, { data, loading, error }] = useAddFoodLogActivityWithoutTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFoodLogActivityWithoutTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFoodLogActivityWithoutTemplateMutation, AddFoodLogActivityWithoutTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFoodLogActivityWithoutTemplateMutation, AddFoodLogActivityWithoutTemplateMutationVariables>(AddFoodLogActivityWithoutTemplateDocument, baseOptions);
      }
export type AddFoodLogActivityWithoutTemplateMutationHookResult = ReturnType<typeof useAddFoodLogActivityWithoutTemplateMutation>;
export type AddFoodLogActivityWithoutTemplateMutationResult = ApolloReactCommon.MutationResult<AddFoodLogActivityWithoutTemplateMutation>;
export type AddFoodLogActivityWithoutTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFoodLogActivityWithoutTemplateMutation, AddFoodLogActivityWithoutTemplateMutationVariables>;
export const EditFoodLogMealTemplateDocument = gql`
    mutation EditFoodLogMealTemplate($input: EditFoodLogMealTemplateInput!) {
  editFoodLogMealTemplate(input: $input) {
    foodLogMeal {
      id
      mealTemplate {
        id
      }
    }
  }
}
    `;
export type EditFoodLogMealTemplateMutationFn = ApolloReactCommon.MutationFunction<EditFoodLogMealTemplateMutation, EditFoodLogMealTemplateMutationVariables>;
export type EditFoodLogMealTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditFoodLogMealTemplateMutation, EditFoodLogMealTemplateMutationVariables>, 'mutation'>;

    export const EditFoodLogMealTemplateComponent = (props: EditFoodLogMealTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<EditFoodLogMealTemplateMutation, EditFoodLogMealTemplateMutationVariables> mutation={EditFoodLogMealTemplateDocument} {...props} />
    );
    

/**
 * __useEditFoodLogMealTemplateMutation__
 *
 * To run a mutation, you first call `useEditFoodLogMealTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFoodLogMealTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFoodLogMealTemplateMutation, { data, loading, error }] = useEditFoodLogMealTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFoodLogMealTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFoodLogMealTemplateMutation, EditFoodLogMealTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditFoodLogMealTemplateMutation, EditFoodLogMealTemplateMutationVariables>(EditFoodLogMealTemplateDocument, baseOptions);
      }
export type EditFoodLogMealTemplateMutationHookResult = ReturnType<typeof useEditFoodLogMealTemplateMutation>;
export type EditFoodLogMealTemplateMutationResult = ApolloReactCommon.MutationResult<EditFoodLogMealTemplateMutation>;
export type EditFoodLogMealTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFoodLogMealTemplateMutation, EditFoodLogMealTemplateMutationVariables>;
export const RemoveFoodLogMealTemplateDocument = gql`
    mutation RemoveFoodLogMealTemplate($input: RemoveFoodLogMealTemplateInput!) {
  removeFoodLogMealTemplate(input: $input) {
    foodLogMeal {
      id
      mealTemplate {
        id
      }
    }
  }
}
    `;
export type RemoveFoodLogMealTemplateMutationFn = ApolloReactCommon.MutationFunction<RemoveFoodLogMealTemplateMutation, RemoveFoodLogMealTemplateMutationVariables>;
export type RemoveFoodLogMealTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveFoodLogMealTemplateMutation, RemoveFoodLogMealTemplateMutationVariables>, 'mutation'>;

    export const RemoveFoodLogMealTemplateComponent = (props: RemoveFoodLogMealTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveFoodLogMealTemplateMutation, RemoveFoodLogMealTemplateMutationVariables> mutation={RemoveFoodLogMealTemplateDocument} {...props} />
    );
    

/**
 * __useRemoveFoodLogMealTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveFoodLogMealTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFoodLogMealTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFoodLogMealTemplateMutation, { data, loading, error }] = useRemoveFoodLogMealTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFoodLogMealTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFoodLogMealTemplateMutation, RemoveFoodLogMealTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFoodLogMealTemplateMutation, RemoveFoodLogMealTemplateMutationVariables>(RemoveFoodLogMealTemplateDocument, baseOptions);
      }
export type RemoveFoodLogMealTemplateMutationHookResult = ReturnType<typeof useRemoveFoodLogMealTemplateMutation>;
export type RemoveFoodLogMealTemplateMutationResult = ApolloReactCommon.MutationResult<RemoveFoodLogMealTemplateMutation>;
export type RemoveFoodLogMealTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFoodLogMealTemplateMutation, RemoveFoodLogMealTemplateMutationVariables>;
export const EditFoodLogMealServingAmountsDocument = gql`
    mutation EditFoodLogMealServingAmounts($input: EditFoodLogMealServingAmountsInput!) {
  editFoodLogMealServingAmounts(input: $input) {
    foodLogMeal {
      servingAmounts {
        ...FullServingAmount
      }
    }
  }
}
    ${FullServingAmountFragmentDoc}`;
export type EditFoodLogMealServingAmountsMutationFn = ApolloReactCommon.MutationFunction<EditFoodLogMealServingAmountsMutation, EditFoodLogMealServingAmountsMutationVariables>;
export type EditFoodLogMealServingAmountsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditFoodLogMealServingAmountsMutation, EditFoodLogMealServingAmountsMutationVariables>, 'mutation'>;

    export const EditFoodLogMealServingAmountsComponent = (props: EditFoodLogMealServingAmountsComponentProps) => (
      <ApolloReactComponents.Mutation<EditFoodLogMealServingAmountsMutation, EditFoodLogMealServingAmountsMutationVariables> mutation={EditFoodLogMealServingAmountsDocument} {...props} />
    );
    

/**
 * __useEditFoodLogMealServingAmountsMutation__
 *
 * To run a mutation, you first call `useEditFoodLogMealServingAmountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFoodLogMealServingAmountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFoodLogMealServingAmountsMutation, { data, loading, error }] = useEditFoodLogMealServingAmountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFoodLogMealServingAmountsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFoodLogMealServingAmountsMutation, EditFoodLogMealServingAmountsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditFoodLogMealServingAmountsMutation, EditFoodLogMealServingAmountsMutationVariables>(EditFoodLogMealServingAmountsDocument, baseOptions);
      }
export type EditFoodLogMealServingAmountsMutationHookResult = ReturnType<typeof useEditFoodLogMealServingAmountsMutation>;
export type EditFoodLogMealServingAmountsMutationResult = ApolloReactCommon.MutationResult<EditFoodLogMealServingAmountsMutation>;
export type EditFoodLogMealServingAmountsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFoodLogMealServingAmountsMutation, EditFoodLogMealServingAmountsMutationVariables>;
export const EditFoodLogMealTimeWithTimezoneDocument = gql`
    mutation EditFoodLogMealTimeWithTimezone($input: EditFoodLogMealTimeWithTimezoneInput!) {
  editFoodLogMealTimeWithTimezone(input: $input) {
    foodLogMeal {
      id
      name
      type
      startTime
      endTime
      timezone
    }
  }
}
    `;
export type EditFoodLogMealTimeWithTimezoneMutationFn = ApolloReactCommon.MutationFunction<EditFoodLogMealTimeWithTimezoneMutation, EditFoodLogMealTimeWithTimezoneMutationVariables>;
export type EditFoodLogMealTimeWithTimezoneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditFoodLogMealTimeWithTimezoneMutation, EditFoodLogMealTimeWithTimezoneMutationVariables>, 'mutation'>;

    export const EditFoodLogMealTimeWithTimezoneComponent = (props: EditFoodLogMealTimeWithTimezoneComponentProps) => (
      <ApolloReactComponents.Mutation<EditFoodLogMealTimeWithTimezoneMutation, EditFoodLogMealTimeWithTimezoneMutationVariables> mutation={EditFoodLogMealTimeWithTimezoneDocument} {...props} />
    );
    

/**
 * __useEditFoodLogMealTimeWithTimezoneMutation__
 *
 * To run a mutation, you first call `useEditFoodLogMealTimeWithTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFoodLogMealTimeWithTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFoodLogMealTimeWithTimezoneMutation, { data, loading, error }] = useEditFoodLogMealTimeWithTimezoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFoodLogMealTimeWithTimezoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFoodLogMealTimeWithTimezoneMutation, EditFoodLogMealTimeWithTimezoneMutationVariables>) {
        return ApolloReactHooks.useMutation<EditFoodLogMealTimeWithTimezoneMutation, EditFoodLogMealTimeWithTimezoneMutationVariables>(EditFoodLogMealTimeWithTimezoneDocument, baseOptions);
      }
export type EditFoodLogMealTimeWithTimezoneMutationHookResult = ReturnType<typeof useEditFoodLogMealTimeWithTimezoneMutation>;
export type EditFoodLogMealTimeWithTimezoneMutationResult = ApolloReactCommon.MutationResult<EditFoodLogMealTimeWithTimezoneMutation>;
export type EditFoodLogMealTimeWithTimezoneMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFoodLogMealTimeWithTimezoneMutation, EditFoodLogMealTimeWithTimezoneMutationVariables>;
export const AddFoodLogMealCommentDocument = gql`
    mutation AddFoodLogMealComment($input: AddFoodLogMealCommentInput!) {
  addFoodLogMealComment(input: $input) {
    comment {
      ...CommentTimeline
    }
  }
}
    ${CommentTimelineFragmentDoc}`;
export type AddFoodLogMealCommentMutationFn = ApolloReactCommon.MutationFunction<AddFoodLogMealCommentMutation, AddFoodLogMealCommentMutationVariables>;
export type AddFoodLogMealCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddFoodLogMealCommentMutation, AddFoodLogMealCommentMutationVariables>, 'mutation'>;

    export const AddFoodLogMealCommentComponent = (props: AddFoodLogMealCommentComponentProps) => (
      <ApolloReactComponents.Mutation<AddFoodLogMealCommentMutation, AddFoodLogMealCommentMutationVariables> mutation={AddFoodLogMealCommentDocument} {...props} />
    );
    

/**
 * __useAddFoodLogMealCommentMutation__
 *
 * To run a mutation, you first call `useAddFoodLogMealCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFoodLogMealCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFoodLogMealCommentMutation, { data, loading, error }] = useAddFoodLogMealCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFoodLogMealCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFoodLogMealCommentMutation, AddFoodLogMealCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFoodLogMealCommentMutation, AddFoodLogMealCommentMutationVariables>(AddFoodLogMealCommentDocument, baseOptions);
      }
export type AddFoodLogMealCommentMutationHookResult = ReturnType<typeof useAddFoodLogMealCommentMutation>;
export type AddFoodLogMealCommentMutationResult = ApolloReactCommon.MutationResult<AddFoodLogMealCommentMutation>;
export type AddFoodLogMealCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFoodLogMealCommentMutation, AddFoodLogMealCommentMutationVariables>;
export const RemoveFoodLogMealDocument = gql`
    mutation RemoveFoodLogMeal($input: RemoveFoodLogMealInput!) {
  removeFoodLogMeal(input: $input) {
    foodLogMealId
  }
}
    `;
export type RemoveFoodLogMealMutationFn = ApolloReactCommon.MutationFunction<RemoveFoodLogMealMutation, RemoveFoodLogMealMutationVariables>;
export type RemoveFoodLogMealComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveFoodLogMealMutation, RemoveFoodLogMealMutationVariables>, 'mutation'>;

    export const RemoveFoodLogMealComponent = (props: RemoveFoodLogMealComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveFoodLogMealMutation, RemoveFoodLogMealMutationVariables> mutation={RemoveFoodLogMealDocument} {...props} />
    );
    

/**
 * __useRemoveFoodLogMealMutation__
 *
 * To run a mutation, you first call `useRemoveFoodLogMealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFoodLogMealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFoodLogMealMutation, { data, loading, error }] = useRemoveFoodLogMealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFoodLogMealMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFoodLogMealMutation, RemoveFoodLogMealMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFoodLogMealMutation, RemoveFoodLogMealMutationVariables>(RemoveFoodLogMealDocument, baseOptions);
      }
export type RemoveFoodLogMealMutationHookResult = ReturnType<typeof useRemoveFoodLogMealMutation>;
export type RemoveFoodLogMealMutationResult = ApolloReactCommon.MutationResult<RemoveFoodLogMealMutation>;
export type RemoveFoodLogMealMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFoodLogMealMutation, RemoveFoodLogMealMutationVariables>;
export const EditFoodLogActivityTemplateDocument = gql`
    mutation EditFoodLogActivityTemplate($input: EditFoodLogActivityTemplateInput!) {
  editFoodLogActivityTemplate(input: $input) {
    foodLogActivity {
      id
      activityTemplate {
        id
      }
    }
  }
}
    `;
export type EditFoodLogActivityTemplateMutationFn = ApolloReactCommon.MutationFunction<EditFoodLogActivityTemplateMutation, EditFoodLogActivityTemplateMutationVariables>;
export type EditFoodLogActivityTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditFoodLogActivityTemplateMutation, EditFoodLogActivityTemplateMutationVariables>, 'mutation'>;

    export const EditFoodLogActivityTemplateComponent = (props: EditFoodLogActivityTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<EditFoodLogActivityTemplateMutation, EditFoodLogActivityTemplateMutationVariables> mutation={EditFoodLogActivityTemplateDocument} {...props} />
    );
    

/**
 * __useEditFoodLogActivityTemplateMutation__
 *
 * To run a mutation, you first call `useEditFoodLogActivityTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFoodLogActivityTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFoodLogActivityTemplateMutation, { data, loading, error }] = useEditFoodLogActivityTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFoodLogActivityTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFoodLogActivityTemplateMutation, EditFoodLogActivityTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditFoodLogActivityTemplateMutation, EditFoodLogActivityTemplateMutationVariables>(EditFoodLogActivityTemplateDocument, baseOptions);
      }
export type EditFoodLogActivityTemplateMutationHookResult = ReturnType<typeof useEditFoodLogActivityTemplateMutation>;
export type EditFoodLogActivityTemplateMutationResult = ApolloReactCommon.MutationResult<EditFoodLogActivityTemplateMutation>;
export type EditFoodLogActivityTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFoodLogActivityTemplateMutation, EditFoodLogActivityTemplateMutationVariables>;
export const EditFoodLogActivityTimeWithTimezoneDocument = gql`
    mutation EditFoodLogActivityTimeWithTimezone($input: EditFoodLogActivityTimeWithTimezoneInput!) {
  editFoodLogActivityTimeWithTimezone(input: $input) {
    foodLogActivity {
      id
      name
      activityType: type
      date
      startTime
      endTime
      timezone
    }
  }
}
    `;
export type EditFoodLogActivityTimeWithTimezoneMutationFn = ApolloReactCommon.MutationFunction<EditFoodLogActivityTimeWithTimezoneMutation, EditFoodLogActivityTimeWithTimezoneMutationVariables>;
export type EditFoodLogActivityTimeWithTimezoneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditFoodLogActivityTimeWithTimezoneMutation, EditFoodLogActivityTimeWithTimezoneMutationVariables>, 'mutation'>;

    export const EditFoodLogActivityTimeWithTimezoneComponent = (props: EditFoodLogActivityTimeWithTimezoneComponentProps) => (
      <ApolloReactComponents.Mutation<EditFoodLogActivityTimeWithTimezoneMutation, EditFoodLogActivityTimeWithTimezoneMutationVariables> mutation={EditFoodLogActivityTimeWithTimezoneDocument} {...props} />
    );
    

/**
 * __useEditFoodLogActivityTimeWithTimezoneMutation__
 *
 * To run a mutation, you first call `useEditFoodLogActivityTimeWithTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFoodLogActivityTimeWithTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFoodLogActivityTimeWithTimezoneMutation, { data, loading, error }] = useEditFoodLogActivityTimeWithTimezoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFoodLogActivityTimeWithTimezoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFoodLogActivityTimeWithTimezoneMutation, EditFoodLogActivityTimeWithTimezoneMutationVariables>) {
        return ApolloReactHooks.useMutation<EditFoodLogActivityTimeWithTimezoneMutation, EditFoodLogActivityTimeWithTimezoneMutationVariables>(EditFoodLogActivityTimeWithTimezoneDocument, baseOptions);
      }
export type EditFoodLogActivityTimeWithTimezoneMutationHookResult = ReturnType<typeof useEditFoodLogActivityTimeWithTimezoneMutation>;
export type EditFoodLogActivityTimeWithTimezoneMutationResult = ApolloReactCommon.MutationResult<EditFoodLogActivityTimeWithTimezoneMutation>;
export type EditFoodLogActivityTimeWithTimezoneMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFoodLogActivityTimeWithTimezoneMutation, EditFoodLogActivityTimeWithTimezoneMutationVariables>;
export const EditFoodLogActivityRpeDocument = gql`
    mutation EditFoodLogActivityRpe($input: EditFoodLogActivityRpeInput!) {
  editFoodLogActivityRpe(input: $input) {
    foodLogActivity {
      id
      rpe
    }
  }
}
    `;
export type EditFoodLogActivityRpeMutationFn = ApolloReactCommon.MutationFunction<EditFoodLogActivityRpeMutation, EditFoodLogActivityRpeMutationVariables>;
export type EditFoodLogActivityRpeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditFoodLogActivityRpeMutation, EditFoodLogActivityRpeMutationVariables>, 'mutation'>;

    export const EditFoodLogActivityRpeComponent = (props: EditFoodLogActivityRpeComponentProps) => (
      <ApolloReactComponents.Mutation<EditFoodLogActivityRpeMutation, EditFoodLogActivityRpeMutationVariables> mutation={EditFoodLogActivityRpeDocument} {...props} />
    );
    

/**
 * __useEditFoodLogActivityRpeMutation__
 *
 * To run a mutation, you first call `useEditFoodLogActivityRpeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFoodLogActivityRpeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFoodLogActivityRpeMutation, { data, loading, error }] = useEditFoodLogActivityRpeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFoodLogActivityRpeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFoodLogActivityRpeMutation, EditFoodLogActivityRpeMutationVariables>) {
        return ApolloReactHooks.useMutation<EditFoodLogActivityRpeMutation, EditFoodLogActivityRpeMutationVariables>(EditFoodLogActivityRpeDocument, baseOptions);
      }
export type EditFoodLogActivityRpeMutationHookResult = ReturnType<typeof useEditFoodLogActivityRpeMutation>;
export type EditFoodLogActivityRpeMutationResult = ApolloReactCommon.MutationResult<EditFoodLogActivityRpeMutation>;
export type EditFoodLogActivityRpeMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFoodLogActivityRpeMutation, EditFoodLogActivityRpeMutationVariables>;
export const RemoveFoodLogActivityDocument = gql`
    mutation RemoveFoodLogActivity($input: RemoveFoodLogActivityInput!) {
  removeFoodLogActivity(input: $input) {
    foodLogActivityId
  }
}
    `;
export type RemoveFoodLogActivityMutationFn = ApolloReactCommon.MutationFunction<RemoveFoodLogActivityMutation, RemoveFoodLogActivityMutationVariables>;
export type RemoveFoodLogActivityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveFoodLogActivityMutation, RemoveFoodLogActivityMutationVariables>, 'mutation'>;

    export const RemoveFoodLogActivityComponent = (props: RemoveFoodLogActivityComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveFoodLogActivityMutation, RemoveFoodLogActivityMutationVariables> mutation={RemoveFoodLogActivityDocument} {...props} />
    );
    

/**
 * __useRemoveFoodLogActivityMutation__
 *
 * To run a mutation, you first call `useRemoveFoodLogActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFoodLogActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFoodLogActivityMutation, { data, loading, error }] = useRemoveFoodLogActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFoodLogActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFoodLogActivityMutation, RemoveFoodLogActivityMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFoodLogActivityMutation, RemoveFoodLogActivityMutationVariables>(RemoveFoodLogActivityDocument, baseOptions);
      }
export type RemoveFoodLogActivityMutationHookResult = ReturnType<typeof useRemoveFoodLogActivityMutation>;
export type RemoveFoodLogActivityMutationResult = ApolloReactCommon.MutationResult<RemoveFoodLogActivityMutation>;
export type RemoveFoodLogActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFoodLogActivityMutation, RemoveFoodLogActivityMutationVariables>;
export const CreateMealPlanFromFoodLogsDocument = gql`
    mutation CreateMealPlanFromFoodLogs($input: CreateMealPlanFromFoodLogsNewInput!) {
  createMealPlanFromFoodLogsNew(input: $input) {
    mealPlan {
      ...PreviewMealPlan
    }
  }
}
    ${PreviewMealPlanFragmentDoc}`;
export type CreateMealPlanFromFoodLogsMutationFn = ApolloReactCommon.MutationFunction<CreateMealPlanFromFoodLogsMutation, CreateMealPlanFromFoodLogsMutationVariables>;
export type CreateMealPlanFromFoodLogsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMealPlanFromFoodLogsMutation, CreateMealPlanFromFoodLogsMutationVariables>, 'mutation'>;

    export const CreateMealPlanFromFoodLogsComponent = (props: CreateMealPlanFromFoodLogsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMealPlanFromFoodLogsMutation, CreateMealPlanFromFoodLogsMutationVariables> mutation={CreateMealPlanFromFoodLogsDocument} {...props} />
    );
    

/**
 * __useCreateMealPlanFromFoodLogsMutation__
 *
 * To run a mutation, you first call `useCreateMealPlanFromFoodLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMealPlanFromFoodLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMealPlanFromFoodLogsMutation, { data, loading, error }] = useCreateMealPlanFromFoodLogsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMealPlanFromFoodLogsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMealPlanFromFoodLogsMutation, CreateMealPlanFromFoodLogsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMealPlanFromFoodLogsMutation, CreateMealPlanFromFoodLogsMutationVariables>(CreateMealPlanFromFoodLogsDocument, baseOptions);
      }
export type CreateMealPlanFromFoodLogsMutationHookResult = ReturnType<typeof useCreateMealPlanFromFoodLogsMutation>;
export type CreateMealPlanFromFoodLogsMutationResult = ApolloReactCommon.MutationResult<CreateMealPlanFromFoodLogsMutation>;
export type CreateMealPlanFromFoodLogsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMealPlanFromFoodLogsMutation, CreateMealPlanFromFoodLogsMutationVariables>;
export const CreateMetricMealPlanFromFoodLogsDocument = gql`
    mutation CreateMetricMealPlanFromFoodLogs($input: CreateMetricMealPlanFromFoodLogsInput!) {
  createMetricMealPlanFromFoodLogs(input: $input) {
    mealPlan {
      ...PreviewMealPlan
    }
  }
}
    ${PreviewMealPlanFragmentDoc}`;
export type CreateMetricMealPlanFromFoodLogsMutationFn = ApolloReactCommon.MutationFunction<CreateMetricMealPlanFromFoodLogsMutation, CreateMetricMealPlanFromFoodLogsMutationVariables>;
export type CreateMetricMealPlanFromFoodLogsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMetricMealPlanFromFoodLogsMutation, CreateMetricMealPlanFromFoodLogsMutationVariables>, 'mutation'>;

    export const CreateMetricMealPlanFromFoodLogsComponent = (props: CreateMetricMealPlanFromFoodLogsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMetricMealPlanFromFoodLogsMutation, CreateMetricMealPlanFromFoodLogsMutationVariables> mutation={CreateMetricMealPlanFromFoodLogsDocument} {...props} />
    );
    

/**
 * __useCreateMetricMealPlanFromFoodLogsMutation__
 *
 * To run a mutation, you first call `useCreateMetricMealPlanFromFoodLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricMealPlanFromFoodLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricMealPlanFromFoodLogsMutation, { data, loading, error }] = useCreateMetricMealPlanFromFoodLogsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricMealPlanFromFoodLogsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMetricMealPlanFromFoodLogsMutation, CreateMetricMealPlanFromFoodLogsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMetricMealPlanFromFoodLogsMutation, CreateMetricMealPlanFromFoodLogsMutationVariables>(CreateMetricMealPlanFromFoodLogsDocument, baseOptions);
      }
export type CreateMetricMealPlanFromFoodLogsMutationHookResult = ReturnType<typeof useCreateMetricMealPlanFromFoodLogsMutation>;
export type CreateMetricMealPlanFromFoodLogsMutationResult = ApolloReactCommon.MutationResult<CreateMetricMealPlanFromFoodLogsMutation>;
export type CreateMetricMealPlanFromFoodLogsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMetricMealPlanFromFoodLogsMutation, CreateMetricMealPlanFromFoodLogsMutationVariables>;
export const PublishedTimelineMealDocument = gql`
    subscription PublishedTimelineMeal {
  timelineMeal {
    payload {
      __typename
      ... on AddTimelineMealCommentPayload {
        timelineMeal {
          id
        }
        comment {
          ...CommentTimeline
        }
      }
    }
  }
}
    ${CommentTimelineFragmentDoc}`;
export type PublishedTimelineMealComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PublishedTimelineMealSubscription, PublishedTimelineMealSubscriptionVariables>, 'subscription'>;

    export const PublishedTimelineMealComponent = (props: PublishedTimelineMealComponentProps) => (
      <ApolloReactComponents.Subscription<PublishedTimelineMealSubscription, PublishedTimelineMealSubscriptionVariables> subscription={PublishedTimelineMealDocument} {...props} />
    );
    

/**
 * __usePublishedTimelineMealSubscription__
 *
 * To run a query within a React component, call `usePublishedTimelineMealSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishedTimelineMealSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedTimelineMealSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePublishedTimelineMealSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PublishedTimelineMealSubscription, PublishedTimelineMealSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<PublishedTimelineMealSubscription, PublishedTimelineMealSubscriptionVariables>(PublishedTimelineMealDocument, baseOptions);
      }
export type PublishedTimelineMealSubscriptionHookResult = ReturnType<typeof usePublishedTimelineMealSubscription>;
export type PublishedTimelineMealSubscriptionResult = ApolloReactCommon.SubscriptionResult<PublishedTimelineMealSubscription>;
export const AddMenuOrderForAthleteDocument = gql`
    mutation AddMenuOrderForAthlete($input: AddMenuOrderForAthleteInput!, $athleteId: ID!) {
  addMenuOrderForAthlete(input: $input) {
    menuOrder {
      ...MenuOrderTimeline
      timelineMeal {
        ...TimelineMeal
      }
    }
  }
}
    ${MenuOrderTimelineFragmentDoc}
${TimelineMealFragmentDoc}`;
export type AddMenuOrderForAthleteMutationFn = ApolloReactCommon.MutationFunction<AddMenuOrderForAthleteMutation, AddMenuOrderForAthleteMutationVariables>;
export type AddMenuOrderForAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMenuOrderForAthleteMutation, AddMenuOrderForAthleteMutationVariables>, 'mutation'>;

    export const AddMenuOrderForAthleteComponent = (props: AddMenuOrderForAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<AddMenuOrderForAthleteMutation, AddMenuOrderForAthleteMutationVariables> mutation={AddMenuOrderForAthleteDocument} {...props} />
    );
    

/**
 * __useAddMenuOrderForAthleteMutation__
 *
 * To run a mutation, you first call `useAddMenuOrderForAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMenuOrderForAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMenuOrderForAthleteMutation, { data, loading, error }] = useAddMenuOrderForAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useAddMenuOrderForAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMenuOrderForAthleteMutation, AddMenuOrderForAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMenuOrderForAthleteMutation, AddMenuOrderForAthleteMutationVariables>(AddMenuOrderForAthleteDocument, baseOptions);
      }
export type AddMenuOrderForAthleteMutationHookResult = ReturnType<typeof useAddMenuOrderForAthleteMutation>;
export type AddMenuOrderForAthleteMutationResult = ApolloReactCommon.MutationResult<AddMenuOrderForAthleteMutation>;
export type AddMenuOrderForAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMenuOrderForAthleteMutation, AddMenuOrderForAthleteMutationVariables>;
export const RemoveMenuOrderDocument = gql`
    mutation RemoveMenuOrder($input: RemoveMenuOrderInput!) {
  removeMenuOrder(input: $input) {
    menuOrderId
    timelineMeal {
      ...TimelineMeal
    }
    removedTimelineMealId
  }
}
    ${TimelineMealFragmentDoc}`;
export type RemoveMenuOrderMutationFn = ApolloReactCommon.MutationFunction<RemoveMenuOrderMutation, RemoveMenuOrderMutationVariables>;
export type RemoveMenuOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveMenuOrderMutation, RemoveMenuOrderMutationVariables>, 'mutation'>;

    export const RemoveMenuOrderComponent = (props: RemoveMenuOrderComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveMenuOrderMutation, RemoveMenuOrderMutationVariables> mutation={RemoveMenuOrderDocument} {...props} />
    );
    

/**
 * __useRemoveMenuOrderMutation__
 *
 * To run a mutation, you first call `useRemoveMenuOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMenuOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMenuOrderMutation, { data, loading, error }] = useRemoveMenuOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMenuOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMenuOrderMutation, RemoveMenuOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMenuOrderMutation, RemoveMenuOrderMutationVariables>(RemoveMenuOrderDocument, baseOptions);
      }
export type RemoveMenuOrderMutationHookResult = ReturnType<typeof useRemoveMenuOrderMutation>;
export type RemoveMenuOrderMutationResult = ApolloReactCommon.MutationResult<RemoveMenuOrderMutation>;
export type RemoveMenuOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMenuOrderMutation, RemoveMenuOrderMutationVariables>;
export const MenuOrderForFormDocument = gql`
    query MenuOrderForForm($id: ID!) {
  menuOrder(id: $id) {
    ...MenuOrderForm
  }
}
    ${MenuOrderFormFragmentDoc}`;
export type MenuOrderForFormComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuOrderForFormQuery, MenuOrderForFormQueryVariables>, 'query'> & ({ variables: MenuOrderForFormQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuOrderForFormComponent = (props: MenuOrderForFormComponentProps) => (
      <ApolloReactComponents.Query<MenuOrderForFormQuery, MenuOrderForFormQueryVariables> query={MenuOrderForFormDocument} {...props} />
    );
    

/**
 * __useMenuOrderForFormQuery__
 *
 * To run a query within a React component, call `useMenuOrderForFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuOrderForFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuOrderForFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMenuOrderForFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuOrderForFormQuery, MenuOrderForFormQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuOrderForFormQuery, MenuOrderForFormQueryVariables>(MenuOrderForFormDocument, baseOptions);
      }
export function useMenuOrderForFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuOrderForFormQuery, MenuOrderForFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuOrderForFormQuery, MenuOrderForFormQueryVariables>(MenuOrderForFormDocument, baseOptions);
        }
export type MenuOrderForFormQueryHookResult = ReturnType<typeof useMenuOrderForFormQuery>;
export type MenuOrderForFormLazyQueryHookResult = ReturnType<typeof useMenuOrderForFormLazyQuery>;
export type MenuOrderForFormQueryResult = ApolloReactCommon.QueryResult<MenuOrderForFormQuery, MenuOrderForFormQueryVariables>;
export const SetTimelineMealServingAmountsForAthleteDocument = gql`
    mutation SetTimelineMealServingAmountsForAthlete($input: SetTimelineMealServingAmountsForAthleteInput!) {
  setTimelineMealServingAmountsForAthlete(input: $input) {
    timelineMeal {
      ...TimelineMeal
      servingAmounts {
        ...FullServingAmount
      }
    }
  }
}
    ${TimelineMealFragmentDoc}
${FullServingAmountFragmentDoc}`;
export type SetTimelineMealServingAmountsForAthleteMutationFn = ApolloReactCommon.MutationFunction<SetTimelineMealServingAmountsForAthleteMutation, SetTimelineMealServingAmountsForAthleteMutationVariables>;
export type SetTimelineMealServingAmountsForAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetTimelineMealServingAmountsForAthleteMutation, SetTimelineMealServingAmountsForAthleteMutationVariables>, 'mutation'>;

    export const SetTimelineMealServingAmountsForAthleteComponent = (props: SetTimelineMealServingAmountsForAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<SetTimelineMealServingAmountsForAthleteMutation, SetTimelineMealServingAmountsForAthleteMutationVariables> mutation={SetTimelineMealServingAmountsForAthleteDocument} {...props} />
    );
    

/**
 * __useSetTimelineMealServingAmountsForAthleteMutation__
 *
 * To run a mutation, you first call `useSetTimelineMealServingAmountsForAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTimelineMealServingAmountsForAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTimelineMealServingAmountsForAthleteMutation, { data, loading, error }] = useSetTimelineMealServingAmountsForAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTimelineMealServingAmountsForAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetTimelineMealServingAmountsForAthleteMutation, SetTimelineMealServingAmountsForAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<SetTimelineMealServingAmountsForAthleteMutation, SetTimelineMealServingAmountsForAthleteMutationVariables>(SetTimelineMealServingAmountsForAthleteDocument, baseOptions);
      }
export type SetTimelineMealServingAmountsForAthleteMutationHookResult = ReturnType<typeof useSetTimelineMealServingAmountsForAthleteMutation>;
export type SetTimelineMealServingAmountsForAthleteMutationResult = ApolloReactCommon.MutationResult<SetTimelineMealServingAmountsForAthleteMutation>;
export type SetTimelineMealServingAmountsForAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<SetTimelineMealServingAmountsForAthleteMutation, SetTimelineMealServingAmountsForAthleteMutationVariables>;
export const AddTimelineMealCommentForAthleteDocument = gql`
    mutation AddTimelineMealCommentForAthlete($input: AddTimelineMealCommentForAthleteInput!) {
  addTimelineMealCommentForAthlete(input: $input) {
    timelineMeal {
      ...TimelineMeal
    }
  }
}
    ${TimelineMealFragmentDoc}`;
export type AddTimelineMealCommentForAthleteMutationFn = ApolloReactCommon.MutationFunction<AddTimelineMealCommentForAthleteMutation, AddTimelineMealCommentForAthleteMutationVariables>;
export type AddTimelineMealCommentForAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddTimelineMealCommentForAthleteMutation, AddTimelineMealCommentForAthleteMutationVariables>, 'mutation'>;

    export const AddTimelineMealCommentForAthleteComponent = (props: AddTimelineMealCommentForAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<AddTimelineMealCommentForAthleteMutation, AddTimelineMealCommentForAthleteMutationVariables> mutation={AddTimelineMealCommentForAthleteDocument} {...props} />
    );
    

/**
 * __useAddTimelineMealCommentForAthleteMutation__
 *
 * To run a mutation, you first call `useAddTimelineMealCommentForAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTimelineMealCommentForAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTimelineMealCommentForAthleteMutation, { data, loading, error }] = useAddTimelineMealCommentForAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTimelineMealCommentForAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTimelineMealCommentForAthleteMutation, AddTimelineMealCommentForAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTimelineMealCommentForAthleteMutation, AddTimelineMealCommentForAthleteMutationVariables>(AddTimelineMealCommentForAthleteDocument, baseOptions);
      }
export type AddTimelineMealCommentForAthleteMutationHookResult = ReturnType<typeof useAddTimelineMealCommentForAthleteMutation>;
export type AddTimelineMealCommentForAthleteMutationResult = ApolloReactCommon.MutationResult<AddTimelineMealCommentForAthleteMutation>;
export type AddTimelineMealCommentForAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTimelineMealCommentForAthleteMutation, AddTimelineMealCommentForAthleteMutationVariables>;
export const AddMealMenuLogItemDocument = gql`
    mutation AddMealMenuLogItem($input: AddMealMenuLogItemForAthleteInput!) {
  addMealMenuLogItemForAthlete(input: $input) {
    timelineMeal {
      id
      ...TimelineMeal
      mealMenuLogs {
        ...MealMenuLogTimeline
      }
      mealMenus {
        ...MealMenuTimeline
      }
    }
  }
}
    ${TimelineMealFragmentDoc}
${MealMenuLogTimelineFragmentDoc}
${MealMenuTimelineFragmentDoc}`;
export type AddMealMenuLogItemMutationFn = ApolloReactCommon.MutationFunction<AddMealMenuLogItemMutation, AddMealMenuLogItemMutationVariables>;
export type AddMealMenuLogItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMealMenuLogItemMutation, AddMealMenuLogItemMutationVariables>, 'mutation'>;

    export const AddMealMenuLogItemComponent = (props: AddMealMenuLogItemComponentProps) => (
      <ApolloReactComponents.Mutation<AddMealMenuLogItemMutation, AddMealMenuLogItemMutationVariables> mutation={AddMealMenuLogItemDocument} {...props} />
    );
    

/**
 * __useAddMealMenuLogItemMutation__
 *
 * To run a mutation, you first call `useAddMealMenuLogItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMealMenuLogItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMealMenuLogItemMutation, { data, loading, error }] = useAddMealMenuLogItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMealMenuLogItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMealMenuLogItemMutation, AddMealMenuLogItemMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMealMenuLogItemMutation, AddMealMenuLogItemMutationVariables>(AddMealMenuLogItemDocument, baseOptions);
      }
export type AddMealMenuLogItemMutationHookResult = ReturnType<typeof useAddMealMenuLogItemMutation>;
export type AddMealMenuLogItemMutationResult = ApolloReactCommon.MutationResult<AddMealMenuLogItemMutation>;
export type AddMealMenuLogItemMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMealMenuLogItemMutation, AddMealMenuLogItemMutationVariables>;
export const EditMealMenuLogItemDocument = gql`
    mutation EditMealMenuLogItem($input: EditMealMenuLogItemInput!) {
  editMealMenuLogItem(input: $input) {
    mealMenuLogItem {
      ...MealMenuLogItemForm
    }
  }
}
    ${MealMenuLogItemFormFragmentDoc}`;
export type EditMealMenuLogItemMutationFn = ApolloReactCommon.MutationFunction<EditMealMenuLogItemMutation, EditMealMenuLogItemMutationVariables>;
export type EditMealMenuLogItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealMenuLogItemMutation, EditMealMenuLogItemMutationVariables>, 'mutation'>;

    export const EditMealMenuLogItemComponent = (props: EditMealMenuLogItemComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealMenuLogItemMutation, EditMealMenuLogItemMutationVariables> mutation={EditMealMenuLogItemDocument} {...props} />
    );
    

/**
 * __useEditMealMenuLogItemMutation__
 *
 * To run a mutation, you first call `useEditMealMenuLogItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealMenuLogItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealMenuLogItemMutation, { data, loading, error }] = useEditMealMenuLogItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealMenuLogItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealMenuLogItemMutation, EditMealMenuLogItemMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealMenuLogItemMutation, EditMealMenuLogItemMutationVariables>(EditMealMenuLogItemDocument, baseOptions);
      }
export type EditMealMenuLogItemMutationHookResult = ReturnType<typeof useEditMealMenuLogItemMutation>;
export type EditMealMenuLogItemMutationResult = ApolloReactCommon.MutationResult<EditMealMenuLogItemMutation>;
export type EditMealMenuLogItemMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealMenuLogItemMutation, EditMealMenuLogItemMutationVariables>;
export const RemoveMealMenuLogItemDocument = gql`
    mutation RemoveMealMenuLogItem($input: RemoveMealMenuLogItemInput!) {
  removeMealMenuLogItem(input: $input) {
    mealMenuLogItemId
    timelineMeal {
      id
      mealMenuLogs {
        ...MealMenuLogTimeline
      }
    }
    removedTimelineMealId
  }
}
    ${MealMenuLogTimelineFragmentDoc}`;
export type RemoveMealMenuLogItemMutationFn = ApolloReactCommon.MutationFunction<RemoveMealMenuLogItemMutation, RemoveMealMenuLogItemMutationVariables>;
export type RemoveMealMenuLogItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveMealMenuLogItemMutation, RemoveMealMenuLogItemMutationVariables>, 'mutation'>;

    export const RemoveMealMenuLogItemComponent = (props: RemoveMealMenuLogItemComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveMealMenuLogItemMutation, RemoveMealMenuLogItemMutationVariables> mutation={RemoveMealMenuLogItemDocument} {...props} />
    );
    

/**
 * __useRemoveMealMenuLogItemMutation__
 *
 * To run a mutation, you first call `useRemoveMealMenuLogItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealMenuLogItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealMenuLogItemMutation, { data, loading, error }] = useRemoveMealMenuLogItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMealMenuLogItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealMenuLogItemMutation, RemoveMealMenuLogItemMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealMenuLogItemMutation, RemoveMealMenuLogItemMutationVariables>(RemoveMealMenuLogItemDocument, baseOptions);
      }
export type RemoveMealMenuLogItemMutationHookResult = ReturnType<typeof useRemoveMealMenuLogItemMutation>;
export type RemoveMealMenuLogItemMutationResult = ApolloReactCommon.MutationResult<RemoveMealMenuLogItemMutation>;
export type RemoveMealMenuLogItemMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealMenuLogItemMutation, RemoveMealMenuLogItemMutationVariables>;
export const RemoveTimelineMealServingAmountDocument = gql`
    mutation RemoveTimelineMealServingAmount($input: RemoveTimelineMealServingAmountInput!) {
  removeTimelineMealServingAmount(input: $input) {
    timelineMeal {
      id
      servingAmounts {
        ...FullServingAmount
      }
    }
  }
}
    ${FullServingAmountFragmentDoc}`;
export type RemoveTimelineMealServingAmountMutationFn = ApolloReactCommon.MutationFunction<RemoveTimelineMealServingAmountMutation, RemoveTimelineMealServingAmountMutationVariables>;
export type RemoveTimelineMealServingAmountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveTimelineMealServingAmountMutation, RemoveTimelineMealServingAmountMutationVariables>, 'mutation'>;

    export const RemoveTimelineMealServingAmountComponent = (props: RemoveTimelineMealServingAmountComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveTimelineMealServingAmountMutation, RemoveTimelineMealServingAmountMutationVariables> mutation={RemoveTimelineMealServingAmountDocument} {...props} />
    );
    

/**
 * __useRemoveTimelineMealServingAmountMutation__
 *
 * To run a mutation, you first call `useRemoveTimelineMealServingAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTimelineMealServingAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTimelineMealServingAmountMutation, { data, loading, error }] = useRemoveTimelineMealServingAmountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTimelineMealServingAmountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTimelineMealServingAmountMutation, RemoveTimelineMealServingAmountMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTimelineMealServingAmountMutation, RemoveTimelineMealServingAmountMutationVariables>(RemoveTimelineMealServingAmountDocument, baseOptions);
      }
export type RemoveTimelineMealServingAmountMutationHookResult = ReturnType<typeof useRemoveTimelineMealServingAmountMutation>;
export type RemoveTimelineMealServingAmountMutationResult = ApolloReactCommon.MutationResult<RemoveTimelineMealServingAmountMutation>;
export type RemoveTimelineMealServingAmountMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTimelineMealServingAmountMutation, RemoveTimelineMealServingAmountMutationVariables>;
export const EditTimelineMealDetailsDocument = gql`
    mutation EditTimelineMealDetails($input: EditTimelineMealDetailsInput!) {
  editTimelineMealDetails(input: $input) {
    timelineMeal {
      id
      start
      type
      name
      durationInMinutes
    }
  }
}
    `;
export type EditTimelineMealDetailsMutationFn = ApolloReactCommon.MutationFunction<EditTimelineMealDetailsMutation, EditTimelineMealDetailsMutationVariables>;
export type EditTimelineMealDetailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditTimelineMealDetailsMutation, EditTimelineMealDetailsMutationVariables>, 'mutation'>;

    export const EditTimelineMealDetailsComponent = (props: EditTimelineMealDetailsComponentProps) => (
      <ApolloReactComponents.Mutation<EditTimelineMealDetailsMutation, EditTimelineMealDetailsMutationVariables> mutation={EditTimelineMealDetailsDocument} {...props} />
    );
    

/**
 * __useEditTimelineMealDetailsMutation__
 *
 * To run a mutation, you first call `useEditTimelineMealDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTimelineMealDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTimelineMealDetailsMutation, { data, loading, error }] = useEditTimelineMealDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTimelineMealDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTimelineMealDetailsMutation, EditTimelineMealDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTimelineMealDetailsMutation, EditTimelineMealDetailsMutationVariables>(EditTimelineMealDetailsDocument, baseOptions);
      }
export type EditTimelineMealDetailsMutationHookResult = ReturnType<typeof useEditTimelineMealDetailsMutation>;
export type EditTimelineMealDetailsMutationResult = ApolloReactCommon.MutationResult<EditTimelineMealDetailsMutation>;
export type EditTimelineMealDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTimelineMealDetailsMutation, EditTimelineMealDetailsMutationVariables>;
export const AthleteGoalConfigurationCompliancePageDocument = gql`
    query AthleteGoalConfigurationCompliancePage($goalConfigurationId: ID!, $startDate: String, $endDate: String!) {
  goalConfiguration(id: $goalConfigurationId) {
    ...AthleteGoalConfigurationForCompliancePage
  }
}
    ${AthleteGoalConfigurationForCompliancePageFragmentDoc}`;
export type AthleteGoalConfigurationCompliancePageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteGoalConfigurationCompliancePageQuery, AthleteGoalConfigurationCompliancePageQueryVariables>, 'query'> & ({ variables: AthleteGoalConfigurationCompliancePageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteGoalConfigurationCompliancePageComponent = (props: AthleteGoalConfigurationCompliancePageComponentProps) => (
      <ApolloReactComponents.Query<AthleteGoalConfigurationCompliancePageQuery, AthleteGoalConfigurationCompliancePageQueryVariables> query={AthleteGoalConfigurationCompliancePageDocument} {...props} />
    );
    

/**
 * __useAthleteGoalConfigurationCompliancePageQuery__
 *
 * To run a query within a React component, call `useAthleteGoalConfigurationCompliancePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteGoalConfigurationCompliancePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteGoalConfigurationCompliancePageQuery({
 *   variables: {
 *      goalConfigurationId: // value for 'goalConfigurationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAthleteGoalConfigurationCompliancePageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteGoalConfigurationCompliancePageQuery, AthleteGoalConfigurationCompliancePageQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteGoalConfigurationCompliancePageQuery, AthleteGoalConfigurationCompliancePageQueryVariables>(AthleteGoalConfigurationCompliancePageDocument, baseOptions);
      }
export function useAthleteGoalConfigurationCompliancePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteGoalConfigurationCompliancePageQuery, AthleteGoalConfigurationCompliancePageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteGoalConfigurationCompliancePageQuery, AthleteGoalConfigurationCompliancePageQueryVariables>(AthleteGoalConfigurationCompliancePageDocument, baseOptions);
        }
export type AthleteGoalConfigurationCompliancePageQueryHookResult = ReturnType<typeof useAthleteGoalConfigurationCompliancePageQuery>;
export type AthleteGoalConfigurationCompliancePageLazyQueryHookResult = ReturnType<typeof useAthleteGoalConfigurationCompliancePageLazyQuery>;
export type AthleteGoalConfigurationCompliancePageQueryResult = ApolloReactCommon.QueryResult<AthleteGoalConfigurationCompliancePageQuery, AthleteGoalConfigurationCompliancePageQueryVariables>;
export const GoalTrackerGoalOffsetConnectionDocument = gql`
    query GoalTrackerGoalOffsetConnection($goalConfigurationId: ID!, $input: OffsetPaginationInput!) {
  goalTrackerGoalOffsetConnection(
    goalConfigurationId: $goalConfigurationId
    input: $input
  ) {
    edges {
      ...GoalTrackerGoalForCompliance
    }
    pageInfo {
      hasNextPage
      total
      endOffset
    }
  }
}
    ${GoalTrackerGoalForComplianceFragmentDoc}`;
export type GoalTrackerGoalOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GoalTrackerGoalOffsetConnectionQuery, GoalTrackerGoalOffsetConnectionQueryVariables>, 'query'> & ({ variables: GoalTrackerGoalOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GoalTrackerGoalOffsetConnectionComponent = (props: GoalTrackerGoalOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<GoalTrackerGoalOffsetConnectionQuery, GoalTrackerGoalOffsetConnectionQueryVariables> query={GoalTrackerGoalOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useGoalTrackerGoalOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useGoalTrackerGoalOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalTrackerGoalOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalTrackerGoalOffsetConnectionQuery({
 *   variables: {
 *      goalConfigurationId: // value for 'goalConfigurationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoalTrackerGoalOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GoalTrackerGoalOffsetConnectionQuery, GoalTrackerGoalOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<GoalTrackerGoalOffsetConnectionQuery, GoalTrackerGoalOffsetConnectionQueryVariables>(GoalTrackerGoalOffsetConnectionDocument, baseOptions);
      }
export function useGoalTrackerGoalOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoalTrackerGoalOffsetConnectionQuery, GoalTrackerGoalOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GoalTrackerGoalOffsetConnectionQuery, GoalTrackerGoalOffsetConnectionQueryVariables>(GoalTrackerGoalOffsetConnectionDocument, baseOptions);
        }
export type GoalTrackerGoalOffsetConnectionQueryHookResult = ReturnType<typeof useGoalTrackerGoalOffsetConnectionQuery>;
export type GoalTrackerGoalOffsetConnectionLazyQueryHookResult = ReturnType<typeof useGoalTrackerGoalOffsetConnectionLazyQuery>;
export type GoalTrackerGoalOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<GoalTrackerGoalOffsetConnectionQuery, GoalTrackerGoalOffsetConnectionQueryVariables>;
export const GoalTrackerGoalsForExportDocument = gql`
    query GoalTrackerGoalsForExport($goalConfigurationId: ID!) {
  goalTrackerGoals(goalConfigurationId: $goalConfigurationId) {
    ...GoalTrackerGoalForCompliance
  }
}
    ${GoalTrackerGoalForComplianceFragmentDoc}`;
export type GoalTrackerGoalsForExportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GoalTrackerGoalsForExportQuery, GoalTrackerGoalsForExportQueryVariables>, 'query'> & ({ variables: GoalTrackerGoalsForExportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GoalTrackerGoalsForExportComponent = (props: GoalTrackerGoalsForExportComponentProps) => (
      <ApolloReactComponents.Query<GoalTrackerGoalsForExportQuery, GoalTrackerGoalsForExportQueryVariables> query={GoalTrackerGoalsForExportDocument} {...props} />
    );
    

/**
 * __useGoalTrackerGoalsForExportQuery__
 *
 * To run a query within a React component, call `useGoalTrackerGoalsForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalTrackerGoalsForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalTrackerGoalsForExportQuery({
 *   variables: {
 *      goalConfigurationId: // value for 'goalConfigurationId'
 *   },
 * });
 */
export function useGoalTrackerGoalsForExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GoalTrackerGoalsForExportQuery, GoalTrackerGoalsForExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GoalTrackerGoalsForExportQuery, GoalTrackerGoalsForExportQueryVariables>(GoalTrackerGoalsForExportDocument, baseOptions);
      }
export function useGoalTrackerGoalsForExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoalTrackerGoalsForExportQuery, GoalTrackerGoalsForExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GoalTrackerGoalsForExportQuery, GoalTrackerGoalsForExportQueryVariables>(GoalTrackerGoalsForExportDocument, baseOptions);
        }
export type GoalTrackerGoalsForExportQueryHookResult = ReturnType<typeof useGoalTrackerGoalsForExportQuery>;
export type GoalTrackerGoalsForExportLazyQueryHookResult = ReturnType<typeof useGoalTrackerGoalsForExportLazyQuery>;
export type GoalTrackerGoalsForExportQueryResult = ApolloReactCommon.QueryResult<GoalTrackerGoalsForExportQuery, GoalTrackerGoalsForExportQueryVariables>;
export const CreateGoalConfigurationWithDaysOfWeekDocument = gql`
    mutation CreateGoalConfigurationWithDaysOfWeek($input: CreateGoalConfigurationWithDaysOfWeekInput!) {
  createGoalConfigurationWithDaysOfWeek(input: $input) {
    goalConfiguration {
      id
      name
    }
  }
}
    `;
export type CreateGoalConfigurationWithDaysOfWeekMutationFn = ApolloReactCommon.MutationFunction<CreateGoalConfigurationWithDaysOfWeekMutation, CreateGoalConfigurationWithDaysOfWeekMutationVariables>;
export type CreateGoalConfigurationWithDaysOfWeekComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGoalConfigurationWithDaysOfWeekMutation, CreateGoalConfigurationWithDaysOfWeekMutationVariables>, 'mutation'>;

    export const CreateGoalConfigurationWithDaysOfWeekComponent = (props: CreateGoalConfigurationWithDaysOfWeekComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGoalConfigurationWithDaysOfWeekMutation, CreateGoalConfigurationWithDaysOfWeekMutationVariables> mutation={CreateGoalConfigurationWithDaysOfWeekDocument} {...props} />
    );
    

/**
 * __useCreateGoalConfigurationWithDaysOfWeekMutation__
 *
 * To run a mutation, you first call `useCreateGoalConfigurationWithDaysOfWeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalConfigurationWithDaysOfWeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalConfigurationWithDaysOfWeekMutation, { data, loading, error }] = useCreateGoalConfigurationWithDaysOfWeekMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGoalConfigurationWithDaysOfWeekMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGoalConfigurationWithDaysOfWeekMutation, CreateGoalConfigurationWithDaysOfWeekMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGoalConfigurationWithDaysOfWeekMutation, CreateGoalConfigurationWithDaysOfWeekMutationVariables>(CreateGoalConfigurationWithDaysOfWeekDocument, baseOptions);
      }
export type CreateGoalConfigurationWithDaysOfWeekMutationHookResult = ReturnType<typeof useCreateGoalConfigurationWithDaysOfWeekMutation>;
export type CreateGoalConfigurationWithDaysOfWeekMutationResult = ApolloReactCommon.MutationResult<CreateGoalConfigurationWithDaysOfWeekMutation>;
export type CreateGoalConfigurationWithDaysOfWeekMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGoalConfigurationWithDaysOfWeekMutation, CreateGoalConfigurationWithDaysOfWeekMutationVariables>;
export const CreateGoalConfigurationWithIndividualDatesDocument = gql`
    mutation CreateGoalConfigurationWithIndividualDates($input: CreateGoalConfigurationWithIndividualDatesInput!) {
  createGoalConfigurationWithIndividualDates(input: $input) {
    goalConfiguration {
      id
      name
    }
  }
}
    `;
export type CreateGoalConfigurationWithIndividualDatesMutationFn = ApolloReactCommon.MutationFunction<CreateGoalConfigurationWithIndividualDatesMutation, CreateGoalConfigurationWithIndividualDatesMutationVariables>;
export type CreateGoalConfigurationWithIndividualDatesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGoalConfigurationWithIndividualDatesMutation, CreateGoalConfigurationWithIndividualDatesMutationVariables>, 'mutation'>;

    export const CreateGoalConfigurationWithIndividualDatesComponent = (props: CreateGoalConfigurationWithIndividualDatesComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGoalConfigurationWithIndividualDatesMutation, CreateGoalConfigurationWithIndividualDatesMutationVariables> mutation={CreateGoalConfigurationWithIndividualDatesDocument} {...props} />
    );
    

/**
 * __useCreateGoalConfigurationWithIndividualDatesMutation__
 *
 * To run a mutation, you first call `useCreateGoalConfigurationWithIndividualDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalConfigurationWithIndividualDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalConfigurationWithIndividualDatesMutation, { data, loading, error }] = useCreateGoalConfigurationWithIndividualDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGoalConfigurationWithIndividualDatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGoalConfigurationWithIndividualDatesMutation, CreateGoalConfigurationWithIndividualDatesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGoalConfigurationWithIndividualDatesMutation, CreateGoalConfigurationWithIndividualDatesMutationVariables>(CreateGoalConfigurationWithIndividualDatesDocument, baseOptions);
      }
export type CreateGoalConfigurationWithIndividualDatesMutationHookResult = ReturnType<typeof useCreateGoalConfigurationWithIndividualDatesMutation>;
export type CreateGoalConfigurationWithIndividualDatesMutationResult = ApolloReactCommon.MutationResult<CreateGoalConfigurationWithIndividualDatesMutation>;
export type CreateGoalConfigurationWithIndividualDatesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGoalConfigurationWithIndividualDatesMutation, CreateGoalConfigurationWithIndividualDatesMutationVariables>;
export const EditGoalConfigurationWithDaysOfWeekDocument = gql`
    mutation EditGoalConfigurationWithDaysOfWeek($input: EditGoalConfigurationWithDaysOfWeekInput!) {
  editGoalConfigurationWithDaysOfWeek(input: $input) {
    goalConfiguration {
      id
      name
    }
  }
}
    `;
export type EditGoalConfigurationWithDaysOfWeekMutationFn = ApolloReactCommon.MutationFunction<EditGoalConfigurationWithDaysOfWeekMutation, EditGoalConfigurationWithDaysOfWeekMutationVariables>;
export type EditGoalConfigurationWithDaysOfWeekComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditGoalConfigurationWithDaysOfWeekMutation, EditGoalConfigurationWithDaysOfWeekMutationVariables>, 'mutation'>;

    export const EditGoalConfigurationWithDaysOfWeekComponent = (props: EditGoalConfigurationWithDaysOfWeekComponentProps) => (
      <ApolloReactComponents.Mutation<EditGoalConfigurationWithDaysOfWeekMutation, EditGoalConfigurationWithDaysOfWeekMutationVariables> mutation={EditGoalConfigurationWithDaysOfWeekDocument} {...props} />
    );
    

/**
 * __useEditGoalConfigurationWithDaysOfWeekMutation__
 *
 * To run a mutation, you first call `useEditGoalConfigurationWithDaysOfWeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGoalConfigurationWithDaysOfWeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGoalConfigurationWithDaysOfWeekMutation, { data, loading, error }] = useEditGoalConfigurationWithDaysOfWeekMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGoalConfigurationWithDaysOfWeekMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditGoalConfigurationWithDaysOfWeekMutation, EditGoalConfigurationWithDaysOfWeekMutationVariables>) {
        return ApolloReactHooks.useMutation<EditGoalConfigurationWithDaysOfWeekMutation, EditGoalConfigurationWithDaysOfWeekMutationVariables>(EditGoalConfigurationWithDaysOfWeekDocument, baseOptions);
      }
export type EditGoalConfigurationWithDaysOfWeekMutationHookResult = ReturnType<typeof useEditGoalConfigurationWithDaysOfWeekMutation>;
export type EditGoalConfigurationWithDaysOfWeekMutationResult = ApolloReactCommon.MutationResult<EditGoalConfigurationWithDaysOfWeekMutation>;
export type EditGoalConfigurationWithDaysOfWeekMutationOptions = ApolloReactCommon.BaseMutationOptions<EditGoalConfigurationWithDaysOfWeekMutation, EditGoalConfigurationWithDaysOfWeekMutationVariables>;
export const EditGoalConfigurationWithIndividualDatesDocument = gql`
    mutation EditGoalConfigurationWithIndividualDates($input: EditGoalConfigurationWithIndividualDatesInput!) {
  editGoalConfigurationWithIndividualDates(input: $input) {
    goalConfiguration {
      id
      name
    }
  }
}
    `;
export type EditGoalConfigurationWithIndividualDatesMutationFn = ApolloReactCommon.MutationFunction<EditGoalConfigurationWithIndividualDatesMutation, EditGoalConfigurationWithIndividualDatesMutationVariables>;
export type EditGoalConfigurationWithIndividualDatesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditGoalConfigurationWithIndividualDatesMutation, EditGoalConfigurationWithIndividualDatesMutationVariables>, 'mutation'>;

    export const EditGoalConfigurationWithIndividualDatesComponent = (props: EditGoalConfigurationWithIndividualDatesComponentProps) => (
      <ApolloReactComponents.Mutation<EditGoalConfigurationWithIndividualDatesMutation, EditGoalConfigurationWithIndividualDatesMutationVariables> mutation={EditGoalConfigurationWithIndividualDatesDocument} {...props} />
    );
    

/**
 * __useEditGoalConfigurationWithIndividualDatesMutation__
 *
 * To run a mutation, you first call `useEditGoalConfigurationWithIndividualDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGoalConfigurationWithIndividualDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGoalConfigurationWithIndividualDatesMutation, { data, loading, error }] = useEditGoalConfigurationWithIndividualDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGoalConfigurationWithIndividualDatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditGoalConfigurationWithIndividualDatesMutation, EditGoalConfigurationWithIndividualDatesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditGoalConfigurationWithIndividualDatesMutation, EditGoalConfigurationWithIndividualDatesMutationVariables>(EditGoalConfigurationWithIndividualDatesDocument, baseOptions);
      }
export type EditGoalConfigurationWithIndividualDatesMutationHookResult = ReturnType<typeof useEditGoalConfigurationWithIndividualDatesMutation>;
export type EditGoalConfigurationWithIndividualDatesMutationResult = ApolloReactCommon.MutationResult<EditGoalConfigurationWithIndividualDatesMutation>;
export type EditGoalConfigurationWithIndividualDatesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditGoalConfigurationWithIndividualDatesMutation, EditGoalConfigurationWithIndividualDatesMutationVariables>;
export const DeleteGoalConfigurationDocument = gql`
    mutation DeleteGoalConfiguration($input: DeleteGoalConfigurationInput!) {
  deleteGoalConfiguration(input: $input) {
    goalConfigurationId
  }
}
    `;
export type DeleteGoalConfigurationMutationFn = ApolloReactCommon.MutationFunction<DeleteGoalConfigurationMutation, DeleteGoalConfigurationMutationVariables>;
export type DeleteGoalConfigurationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteGoalConfigurationMutation, DeleteGoalConfigurationMutationVariables>, 'mutation'>;

    export const DeleteGoalConfigurationComponent = (props: DeleteGoalConfigurationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteGoalConfigurationMutation, DeleteGoalConfigurationMutationVariables> mutation={DeleteGoalConfigurationDocument} {...props} />
    );
    

/**
 * __useDeleteGoalConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteGoalConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGoalConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoalConfigurationMutation, { data, loading, error }] = useDeleteGoalConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGoalConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGoalConfigurationMutation, DeleteGoalConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGoalConfigurationMutation, DeleteGoalConfigurationMutationVariables>(DeleteGoalConfigurationDocument, baseOptions);
      }
export type DeleteGoalConfigurationMutationHookResult = ReturnType<typeof useDeleteGoalConfigurationMutation>;
export type DeleteGoalConfigurationMutationResult = ApolloReactCommon.MutationResult<DeleteGoalConfigurationMutation>;
export type DeleteGoalConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGoalConfigurationMutation, DeleteGoalConfigurationMutationVariables>;
export const ArchiveGoalConfigurationDocument = gql`
    mutation ArchiveGoalConfiguration($input: ArchiveGoalConfigurationInput!) {
  archiveGoalConfiguration(input: $input) {
    goalConfigurationId
  }
}
    `;
export type ArchiveGoalConfigurationMutationFn = ApolloReactCommon.MutationFunction<ArchiveGoalConfigurationMutation, ArchiveGoalConfigurationMutationVariables>;
export type ArchiveGoalConfigurationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveGoalConfigurationMutation, ArchiveGoalConfigurationMutationVariables>, 'mutation'>;

    export const ArchiveGoalConfigurationComponent = (props: ArchiveGoalConfigurationComponentProps) => (
      <ApolloReactComponents.Mutation<ArchiveGoalConfigurationMutation, ArchiveGoalConfigurationMutationVariables> mutation={ArchiveGoalConfigurationDocument} {...props} />
    );
    

/**
 * __useArchiveGoalConfigurationMutation__
 *
 * To run a mutation, you first call `useArchiveGoalConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveGoalConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveGoalConfigurationMutation, { data, loading, error }] = useArchiveGoalConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveGoalConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveGoalConfigurationMutation, ArchiveGoalConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<ArchiveGoalConfigurationMutation, ArchiveGoalConfigurationMutationVariables>(ArchiveGoalConfigurationDocument, baseOptions);
      }
export type ArchiveGoalConfigurationMutationHookResult = ReturnType<typeof useArchiveGoalConfigurationMutation>;
export type ArchiveGoalConfigurationMutationResult = ApolloReactCommon.MutationResult<ArchiveGoalConfigurationMutation>;
export type ArchiveGoalConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveGoalConfigurationMutation, ArchiveGoalConfigurationMutationVariables>;
export const GoalConfigurationsForAthleteTableDocument = gql`
    query GoalConfigurationsForAthleteTable($athleteId: ID!, $input: OffsetPaginationInput!, $current: Boolean!, $startDate: String, $endDate: String!) {
  goalConfigurationsForAthleteOffsetConnection(
    athleteId: $athleteId
    input: $input
    current: $current
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...GoalConfigurationForTable
    }
  }
}
    ${GoalConfigurationForTableFragmentDoc}`;
export type GoalConfigurationsForAthleteTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GoalConfigurationsForAthleteTableQuery, GoalConfigurationsForAthleteTableQueryVariables>, 'query'> & ({ variables: GoalConfigurationsForAthleteTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GoalConfigurationsForAthleteTableComponent = (props: GoalConfigurationsForAthleteTableComponentProps) => (
      <ApolloReactComponents.Query<GoalConfigurationsForAthleteTableQuery, GoalConfigurationsForAthleteTableQueryVariables> query={GoalConfigurationsForAthleteTableDocument} {...props} />
    );
    

/**
 * __useGoalConfigurationsForAthleteTableQuery__
 *
 * To run a query within a React component, call `useGoalConfigurationsForAthleteTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalConfigurationsForAthleteTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalConfigurationsForAthleteTableQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      input: // value for 'input'
 *      current: // value for 'current'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGoalConfigurationsForAthleteTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GoalConfigurationsForAthleteTableQuery, GoalConfigurationsForAthleteTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GoalConfigurationsForAthleteTableQuery, GoalConfigurationsForAthleteTableQueryVariables>(GoalConfigurationsForAthleteTableDocument, baseOptions);
      }
export function useGoalConfigurationsForAthleteTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoalConfigurationsForAthleteTableQuery, GoalConfigurationsForAthleteTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GoalConfigurationsForAthleteTableQuery, GoalConfigurationsForAthleteTableQueryVariables>(GoalConfigurationsForAthleteTableDocument, baseOptions);
        }
export type GoalConfigurationsForAthleteTableQueryHookResult = ReturnType<typeof useGoalConfigurationsForAthleteTableQuery>;
export type GoalConfigurationsForAthleteTableLazyQueryHookResult = ReturnType<typeof useGoalConfigurationsForAthleteTableLazyQuery>;
export type GoalConfigurationsForAthleteTableQueryResult = ApolloReactCommon.QueryResult<GoalConfigurationsForAthleteTableQuery, GoalConfigurationsForAthleteTableQueryVariables>;
export const GoalCategoriesDocument = gql`
    query GoalCategories {
  goalCategories {
    ...GoalCategoryFull
  }
}
    ${GoalCategoryFullFragmentDoc}`;
export type GoalCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GoalCategoriesQuery, GoalCategoriesQueryVariables>, 'query'>;

    export const GoalCategoriesComponent = (props: GoalCategoriesComponentProps) => (
      <ApolloReactComponents.Query<GoalCategoriesQuery, GoalCategoriesQueryVariables> query={GoalCategoriesDocument} {...props} />
    );
    

/**
 * __useGoalCategoriesQuery__
 *
 * To run a query within a React component, call `useGoalCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoalCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GoalCategoriesQuery, GoalCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GoalCategoriesQuery, GoalCategoriesQueryVariables>(GoalCategoriesDocument, baseOptions);
      }
export function useGoalCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoalCategoriesQuery, GoalCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GoalCategoriesQuery, GoalCategoriesQueryVariables>(GoalCategoriesDocument, baseOptions);
        }
export type GoalCategoriesQueryHookResult = ReturnType<typeof useGoalCategoriesQuery>;
export type GoalCategoriesLazyQueryHookResult = ReturnType<typeof useGoalCategoriesLazyQuery>;
export type GoalCategoriesQueryResult = ApolloReactCommon.QueryResult<GoalCategoriesQuery, GoalCategoriesQueryVariables>;
export const AssignFromMealPlanTemplateCursorConnectionDocument = gql`
    query AssignFromMealPlanTemplateCursorConnection($pagination: CursorPaginationInput!, $searchText: String!, $sortAscending: Boolean!) {
  allMealPlanTemplatesForDietitianCursorConnection(
    pagination: $pagination
    searchText: $searchText
    sortAscending: $sortAscending
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...AssignFromMealPlanTemplate
    }
  }
}
    ${AssignFromMealPlanTemplateFragmentDoc}`;
export type AssignFromMealPlanTemplateCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AssignFromMealPlanTemplateCursorConnectionQuery, AssignFromMealPlanTemplateCursorConnectionQueryVariables>, 'query'> & ({ variables: AssignFromMealPlanTemplateCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AssignFromMealPlanTemplateCursorConnectionComponent = (props: AssignFromMealPlanTemplateCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<AssignFromMealPlanTemplateCursorConnectionQuery, AssignFromMealPlanTemplateCursorConnectionQueryVariables> query={AssignFromMealPlanTemplateCursorConnectionDocument} {...props} />
    );
    

/**
 * __useAssignFromMealPlanTemplateCursorConnectionQuery__
 *
 * To run a query within a React component, call `useAssignFromMealPlanTemplateCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignFromMealPlanTemplateCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignFromMealPlanTemplateCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *      sortAscending: // value for 'sortAscending'
 *   },
 * });
 */
export function useAssignFromMealPlanTemplateCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssignFromMealPlanTemplateCursorConnectionQuery, AssignFromMealPlanTemplateCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<AssignFromMealPlanTemplateCursorConnectionQuery, AssignFromMealPlanTemplateCursorConnectionQueryVariables>(AssignFromMealPlanTemplateCursorConnectionDocument, baseOptions);
      }
export function useAssignFromMealPlanTemplateCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignFromMealPlanTemplateCursorConnectionQuery, AssignFromMealPlanTemplateCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssignFromMealPlanTemplateCursorConnectionQuery, AssignFromMealPlanTemplateCursorConnectionQueryVariables>(AssignFromMealPlanTemplateCursorConnectionDocument, baseOptions);
        }
export type AssignFromMealPlanTemplateCursorConnectionQueryHookResult = ReturnType<typeof useAssignFromMealPlanTemplateCursorConnectionQuery>;
export type AssignFromMealPlanTemplateCursorConnectionLazyQueryHookResult = ReturnType<typeof useAssignFromMealPlanTemplateCursorConnectionLazyQuery>;
export type AssignFromMealPlanTemplateCursorConnectionQueryResult = ApolloReactCommon.QueryResult<AssignFromMealPlanTemplateCursorConnectionQuery, AssignFromMealPlanTemplateCursorConnectionQueryVariables>;
export const AssignMetricMealPlanTemplateToAthleteDocument = gql`
    mutation AssignMetricMealPlanTemplateToAthlete($input: AssignMetricMealPlanTemplateToAthleteInput!) {
  assignMetricMealPlanTemplateToAthlete(input: $input) {
    mealPlan {
      id
      athlete {
        id
        mealPlans {
          ...PreviewMealPlan
        }
      }
    }
  }
}
    ${PreviewMealPlanFragmentDoc}`;
export type AssignMetricMealPlanTemplateToAthleteMutationFn = ApolloReactCommon.MutationFunction<AssignMetricMealPlanTemplateToAthleteMutation, AssignMetricMealPlanTemplateToAthleteMutationVariables>;
export type AssignMetricMealPlanTemplateToAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignMetricMealPlanTemplateToAthleteMutation, AssignMetricMealPlanTemplateToAthleteMutationVariables>, 'mutation'>;

    export const AssignMetricMealPlanTemplateToAthleteComponent = (props: AssignMetricMealPlanTemplateToAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<AssignMetricMealPlanTemplateToAthleteMutation, AssignMetricMealPlanTemplateToAthleteMutationVariables> mutation={AssignMetricMealPlanTemplateToAthleteDocument} {...props} />
    );
    

/**
 * __useAssignMetricMealPlanTemplateToAthleteMutation__
 *
 * To run a mutation, you first call `useAssignMetricMealPlanTemplateToAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignMetricMealPlanTemplateToAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignMetricMealPlanTemplateToAthleteMutation, { data, loading, error }] = useAssignMetricMealPlanTemplateToAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignMetricMealPlanTemplateToAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignMetricMealPlanTemplateToAthleteMutation, AssignMetricMealPlanTemplateToAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignMetricMealPlanTemplateToAthleteMutation, AssignMetricMealPlanTemplateToAthleteMutationVariables>(AssignMetricMealPlanTemplateToAthleteDocument, baseOptions);
      }
export type AssignMetricMealPlanTemplateToAthleteMutationHookResult = ReturnType<typeof useAssignMetricMealPlanTemplateToAthleteMutation>;
export type AssignMetricMealPlanTemplateToAthleteMutationResult = ApolloReactCommon.MutationResult<AssignMetricMealPlanTemplateToAthleteMutation>;
export type AssignMetricMealPlanTemplateToAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignMetricMealPlanTemplateToAthleteMutation, AssignMetricMealPlanTemplateToAthleteMutationVariables>;
export const AthleteMealPlansDocument = gql`
    query AthleteMealPlans($athleteId: ID!) {
  athlete(id: $athleteId) {
    ...Athlete
    team {
      ...TeamWithSport
      schedules {
        ...Schedule
      }
    }
    likedFoods {
      ...Food
    }
    dislikedFoods {
      ...Food
    }
    likedFoodGroups {
      ...FullFoodGroupWithFoods
    }
    dislikedFoodGroups {
      ...FullFoodGroupWithFoods
    }
    mealPlans {
      ...PreviewMealPlan
    }
    mostRecentAnthropometryEntry {
      ...AnthropometryEntry
    }
    currentGoal {
      ...FullGoal
    }
    macroDisplaySettings {
      ...MacroMealPlanDisplaySettings
    }
    exchangeDisplaySettings {
      ...ExchangeMealPlanDisplaySettings
    }
  }
}
    ${AthleteFragmentDoc}
${TeamWithSportFragmentDoc}
${ScheduleFragmentDoc}
${FoodFragmentDoc}
${FullFoodGroupWithFoodsFragmentDoc}
${PreviewMealPlanFragmentDoc}
${AnthropometryEntryFragmentDoc}
${FullGoalFragmentDoc}
${MacroMealPlanDisplaySettingsFragmentDoc}
${ExchangeMealPlanDisplaySettingsFragmentDoc}`;
export type AthleteMealPlansComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteMealPlansQuery, AthleteMealPlansQueryVariables>, 'query'> & ({ variables: AthleteMealPlansQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteMealPlansComponent = (props: AthleteMealPlansComponentProps) => (
      <ApolloReactComponents.Query<AthleteMealPlansQuery, AthleteMealPlansQueryVariables> query={AthleteMealPlansDocument} {...props} />
    );
    

/**
 * __useAthleteMealPlansQuery__
 *
 * To run a query within a React component, call `useAthleteMealPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteMealPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteMealPlansQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useAthleteMealPlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteMealPlansQuery, AthleteMealPlansQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteMealPlansQuery, AthleteMealPlansQueryVariables>(AthleteMealPlansDocument, baseOptions);
      }
export function useAthleteMealPlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteMealPlansQuery, AthleteMealPlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteMealPlansQuery, AthleteMealPlansQueryVariables>(AthleteMealPlansDocument, baseOptions);
        }
export type AthleteMealPlansQueryHookResult = ReturnType<typeof useAthleteMealPlansQuery>;
export type AthleteMealPlansLazyQueryHookResult = ReturnType<typeof useAthleteMealPlansLazyQuery>;
export type AthleteMealPlansQueryResult = ApolloReactCommon.QueryResult<AthleteMealPlansQuery, AthleteMealPlansQueryVariables>;
export const AthleteMostRecentAnthroWithFieldsDocument = gql`
    query AthleteMostRecentAnthroWithFields($athleteId: ID!, $fields: [AnthropometryEntryField!]!) {
  athlete(id: $athleteId) {
    id
    mostRecentAnthropometryEntryWith(fields: $fields) {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type AthleteMostRecentAnthroWithFieldsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteMostRecentAnthroWithFieldsQuery, AthleteMostRecentAnthroWithFieldsQueryVariables>, 'query'> & ({ variables: AthleteMostRecentAnthroWithFieldsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteMostRecentAnthroWithFieldsComponent = (props: AthleteMostRecentAnthroWithFieldsComponentProps) => (
      <ApolloReactComponents.Query<AthleteMostRecentAnthroWithFieldsQuery, AthleteMostRecentAnthroWithFieldsQueryVariables> query={AthleteMostRecentAnthroWithFieldsDocument} {...props} />
    );
    

/**
 * __useAthleteMostRecentAnthroWithFieldsQuery__
 *
 * To run a query within a React component, call `useAthleteMostRecentAnthroWithFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteMostRecentAnthroWithFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteMostRecentAnthroWithFieldsQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useAthleteMostRecentAnthroWithFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteMostRecentAnthroWithFieldsQuery, AthleteMostRecentAnthroWithFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteMostRecentAnthroWithFieldsQuery, AthleteMostRecentAnthroWithFieldsQueryVariables>(AthleteMostRecentAnthroWithFieldsDocument, baseOptions);
      }
export function useAthleteMostRecentAnthroWithFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteMostRecentAnthroWithFieldsQuery, AthleteMostRecentAnthroWithFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteMostRecentAnthroWithFieldsQuery, AthleteMostRecentAnthroWithFieldsQueryVariables>(AthleteMostRecentAnthroWithFieldsDocument, baseOptions);
        }
export type AthleteMostRecentAnthroWithFieldsQueryHookResult = ReturnType<typeof useAthleteMostRecentAnthroWithFieldsQuery>;
export type AthleteMostRecentAnthroWithFieldsLazyQueryHookResult = ReturnType<typeof useAthleteMostRecentAnthroWithFieldsLazyQuery>;
export type AthleteMostRecentAnthroWithFieldsQueryResult = ApolloReactCommon.QueryResult<AthleteMostRecentAnthroWithFieldsQuery, AthleteMostRecentAnthroWithFieldsQueryVariables>;
export const AthleteMostRecentAnthroForRmrMethodDocument = gql`
    query AthleteMostRecentAnthroForRmrMethod($athleteId: ID!, $rmrMethod: RMRMethod) {
  athlete(id: $athleteId) {
    id
    mostRecentAnthropometryEntryForRmrMethod(rmrMethod: $rmrMethod) {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type AthleteMostRecentAnthroForRmrMethodComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteMostRecentAnthroForRmrMethodQuery, AthleteMostRecentAnthroForRmrMethodQueryVariables>, 'query'> & ({ variables: AthleteMostRecentAnthroForRmrMethodQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteMostRecentAnthroForRmrMethodComponent = (props: AthleteMostRecentAnthroForRmrMethodComponentProps) => (
      <ApolloReactComponents.Query<AthleteMostRecentAnthroForRmrMethodQuery, AthleteMostRecentAnthroForRmrMethodQueryVariables> query={AthleteMostRecentAnthroForRmrMethodDocument} {...props} />
    );
    

/**
 * __useAthleteMostRecentAnthroForRmrMethodQuery__
 *
 * To run a query within a React component, call `useAthleteMostRecentAnthroForRmrMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteMostRecentAnthroForRmrMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteMostRecentAnthroForRmrMethodQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      rmrMethod: // value for 'rmrMethod'
 *   },
 * });
 */
export function useAthleteMostRecentAnthroForRmrMethodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteMostRecentAnthroForRmrMethodQuery, AthleteMostRecentAnthroForRmrMethodQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteMostRecentAnthroForRmrMethodQuery, AthleteMostRecentAnthroForRmrMethodQueryVariables>(AthleteMostRecentAnthroForRmrMethodDocument, baseOptions);
      }
export function useAthleteMostRecentAnthroForRmrMethodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteMostRecentAnthroForRmrMethodQuery, AthleteMostRecentAnthroForRmrMethodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteMostRecentAnthroForRmrMethodQuery, AthleteMostRecentAnthroForRmrMethodQueryVariables>(AthleteMostRecentAnthroForRmrMethodDocument, baseOptions);
        }
export type AthleteMostRecentAnthroForRmrMethodQueryHookResult = ReturnType<typeof useAthleteMostRecentAnthroForRmrMethodQuery>;
export type AthleteMostRecentAnthroForRmrMethodLazyQueryHookResult = ReturnType<typeof useAthleteMostRecentAnthroForRmrMethodLazyQuery>;
export type AthleteMostRecentAnthroForRmrMethodQueryResult = ApolloReactCommon.QueryResult<AthleteMostRecentAnthroForRmrMethodQuery, AthleteMostRecentAnthroForRmrMethodQueryVariables>;
export const CreateMetricMealPlanDocument = gql`
    mutation CreateMetricMealPlan($input: CreateMetricMealPlanInput!) {
  createMetricMealPlan(input: $input) {
    mealPlan {
      id
      athlete {
        id
        mealPlans {
          ...PreviewMealPlan
        }
      }
    }
  }
}
    ${PreviewMealPlanFragmentDoc}`;
export type CreateMetricMealPlanMutationFn = ApolloReactCommon.MutationFunction<CreateMetricMealPlanMutation, CreateMetricMealPlanMutationVariables>;
export type CreateMetricMealPlanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMetricMealPlanMutation, CreateMetricMealPlanMutationVariables>, 'mutation'>;

    export const CreateMetricMealPlanComponent = (props: CreateMetricMealPlanComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMetricMealPlanMutation, CreateMetricMealPlanMutationVariables> mutation={CreateMetricMealPlanDocument} {...props} />
    );
    

/**
 * __useCreateMetricMealPlanMutation__
 *
 * To run a mutation, you first call `useCreateMetricMealPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricMealPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricMealPlanMutation, { data, loading, error }] = useCreateMetricMealPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricMealPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMetricMealPlanMutation, CreateMetricMealPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMetricMealPlanMutation, CreateMetricMealPlanMutationVariables>(CreateMetricMealPlanDocument, baseOptions);
      }
export type CreateMetricMealPlanMutationHookResult = ReturnType<typeof useCreateMetricMealPlanMutation>;
export type CreateMetricMealPlanMutationResult = ApolloReactCommon.MutationResult<CreateMetricMealPlanMutation>;
export type CreateMetricMealPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMetricMealPlanMutation, CreateMetricMealPlanMutationVariables>;
export const DeleteMealPlanDocument = gql`
    mutation DeleteMealPlan($input: DeleteMealPlanInput!) {
  deleteMealPlan(input: $input) {
    mealPlanId
    athlete {
      id
      mealPlans {
        ...PreviewMealPlan
      }
    }
  }
}
    ${PreviewMealPlanFragmentDoc}`;
export type DeleteMealPlanMutationFn = ApolloReactCommon.MutationFunction<DeleteMealPlanMutation, DeleteMealPlanMutationVariables>;
export type DeleteMealPlanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteMealPlanMutation, DeleteMealPlanMutationVariables>, 'mutation'>;

    export const DeleteMealPlanComponent = (props: DeleteMealPlanComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteMealPlanMutation, DeleteMealPlanMutationVariables> mutation={DeleteMealPlanDocument} {...props} />
    );
    

/**
 * __useDeleteMealPlanMutation__
 *
 * To run a mutation, you first call `useDeleteMealPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMealPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMealPlanMutation, { data, loading, error }] = useDeleteMealPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMealPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMealPlanMutation, DeleteMealPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMealPlanMutation, DeleteMealPlanMutationVariables>(DeleteMealPlanDocument, baseOptions);
      }
export type DeleteMealPlanMutationHookResult = ReturnType<typeof useDeleteMealPlanMutation>;
export type DeleteMealPlanMutationResult = ApolloReactCommon.MutationResult<DeleteMealPlanMutation>;
export type DeleteMealPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMealPlanMutation, DeleteMealPlanMutationVariables>;
export const CopyMealPlanNewDocument = gql`
    mutation CopyMealPlanNew($input: CopyMealPlanNewInput!) {
  copyMealPlanNew(input: $input) {
    mealPlan {
      id
      athlete {
        id
        mealPlans {
          ...PreviewMealPlan
        }
      }
    }
  }
}
    ${PreviewMealPlanFragmentDoc}`;
export type CopyMealPlanNewMutationFn = ApolloReactCommon.MutationFunction<CopyMealPlanNewMutation, CopyMealPlanNewMutationVariables>;
export type CopyMealPlanNewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyMealPlanNewMutation, CopyMealPlanNewMutationVariables>, 'mutation'>;

    export const CopyMealPlanNewComponent = (props: CopyMealPlanNewComponentProps) => (
      <ApolloReactComponents.Mutation<CopyMealPlanNewMutation, CopyMealPlanNewMutationVariables> mutation={CopyMealPlanNewDocument} {...props} />
    );
    

/**
 * __useCopyMealPlanNewMutation__
 *
 * To run a mutation, you first call `useCopyMealPlanNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyMealPlanNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyMealPlanNewMutation, { data, loading, error }] = useCopyMealPlanNewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyMealPlanNewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyMealPlanNewMutation, CopyMealPlanNewMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyMealPlanNewMutation, CopyMealPlanNewMutationVariables>(CopyMealPlanNewDocument, baseOptions);
      }
export type CopyMealPlanNewMutationHookResult = ReturnType<typeof useCopyMealPlanNewMutation>;
export type CopyMealPlanNewMutationResult = ApolloReactCommon.MutationResult<CopyMealPlanNewMutation>;
export type CopyMealPlanNewMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyMealPlanNewMutation, CopyMealPlanNewMutationVariables>;
export const BulkCopyMealPlanNewDocument = gql`
    mutation BulkCopyMealPlanNew($input: BulkCopyMealPlanNewInput!) {
  bulkCopyMealPlanNew(input: $input) {
    mealPlans {
      ...FullMealPlan
    }
  }
}
    ${FullMealPlanFragmentDoc}`;
export type BulkCopyMealPlanNewMutationFn = ApolloReactCommon.MutationFunction<BulkCopyMealPlanNewMutation, BulkCopyMealPlanNewMutationVariables>;
export type BulkCopyMealPlanNewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BulkCopyMealPlanNewMutation, BulkCopyMealPlanNewMutationVariables>, 'mutation'>;

    export const BulkCopyMealPlanNewComponent = (props: BulkCopyMealPlanNewComponentProps) => (
      <ApolloReactComponents.Mutation<BulkCopyMealPlanNewMutation, BulkCopyMealPlanNewMutationVariables> mutation={BulkCopyMealPlanNewDocument} {...props} />
    );
    

/**
 * __useBulkCopyMealPlanNewMutation__
 *
 * To run a mutation, you first call `useBulkCopyMealPlanNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCopyMealPlanNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCopyMealPlanNewMutation, { data, loading, error }] = useBulkCopyMealPlanNewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCopyMealPlanNewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkCopyMealPlanNewMutation, BulkCopyMealPlanNewMutationVariables>) {
        return ApolloReactHooks.useMutation<BulkCopyMealPlanNewMutation, BulkCopyMealPlanNewMutationVariables>(BulkCopyMealPlanNewDocument, baseOptions);
      }
export type BulkCopyMealPlanNewMutationHookResult = ReturnType<typeof useBulkCopyMealPlanNewMutation>;
export type BulkCopyMealPlanNewMutationResult = ApolloReactCommon.MutationResult<BulkCopyMealPlanNewMutation>;
export type BulkCopyMealPlanNewMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkCopyMealPlanNewMutation, BulkCopyMealPlanNewMutationVariables>;
export const EditMealPlanDocument = gql`
    mutation EditMealPlan($input: EditMealPlanNewInput!) {
  editMealPlanNew(input: $input) {
    mealPlan {
      ...PreviewMealPlan
    }
  }
}
    ${PreviewMealPlanFragmentDoc}`;
export type EditMealPlanMutationFn = ApolloReactCommon.MutationFunction<EditMealPlanMutation, EditMealPlanMutationVariables>;
export type EditMealPlanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealPlanMutation, EditMealPlanMutationVariables>, 'mutation'>;

    export const EditMealPlanComponent = (props: EditMealPlanComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealPlanMutation, EditMealPlanMutationVariables> mutation={EditMealPlanDocument} {...props} />
    );
    

/**
 * __useEditMealPlanMutation__
 *
 * To run a mutation, you first call `useEditMealPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealPlanMutation, { data, loading, error }] = useEditMealPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealPlanMutation, EditMealPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealPlanMutation, EditMealPlanMutationVariables>(EditMealPlanDocument, baseOptions);
      }
export type EditMealPlanMutationHookResult = ReturnType<typeof useEditMealPlanMutation>;
export type EditMealPlanMutationResult = ApolloReactCommon.MutationResult<EditMealPlanMutation>;
export type EditMealPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealPlanMutation, EditMealPlanMutationVariables>;
export const MealPlanDaysOfWeekDocument = gql`
    query MealPlanDaysOfWeek($mealPlanId: ID!) {
  mealPlan(id: $mealPlanId) {
    id
    daysOfWeek {
      ...MealPlanDayOfWeekAssignment
    }
  }
}
    ${MealPlanDayOfWeekAssignmentFragmentDoc}`;
export type MealPlanDaysOfWeekComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanDaysOfWeekQuery, MealPlanDaysOfWeekQueryVariables>, 'query'> & ({ variables: MealPlanDaysOfWeekQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanDaysOfWeekComponent = (props: MealPlanDaysOfWeekComponentProps) => (
      <ApolloReactComponents.Query<MealPlanDaysOfWeekQuery, MealPlanDaysOfWeekQueryVariables> query={MealPlanDaysOfWeekDocument} {...props} />
    );
    

/**
 * __useMealPlanDaysOfWeekQuery__
 *
 * To run a query within a React component, call `useMealPlanDaysOfWeekQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanDaysOfWeekQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanDaysOfWeekQuery({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useMealPlanDaysOfWeekQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanDaysOfWeekQuery, MealPlanDaysOfWeekQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanDaysOfWeekQuery, MealPlanDaysOfWeekQueryVariables>(MealPlanDaysOfWeekDocument, baseOptions);
      }
export function useMealPlanDaysOfWeekLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanDaysOfWeekQuery, MealPlanDaysOfWeekQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanDaysOfWeekQuery, MealPlanDaysOfWeekQueryVariables>(MealPlanDaysOfWeekDocument, baseOptions);
        }
export type MealPlanDaysOfWeekQueryHookResult = ReturnType<typeof useMealPlanDaysOfWeekQuery>;
export type MealPlanDaysOfWeekLazyQueryHookResult = ReturnType<typeof useMealPlanDaysOfWeekLazyQuery>;
export type MealPlanDaysOfWeekQueryResult = ApolloReactCommon.QueryResult<MealPlanDaysOfWeekQuery, MealPlanDaysOfWeekQueryVariables>;
export const MealPlanCalendarInRangePreviewDocument = gql`
    query MealPlanCalendarInRangePreview($mealPlanId: ID!, $start: Date!, $end: Date!) {
  mealPlan(id: $mealPlanId) {
    id
    hasIndividualDateAssignments
    individualDatesInRange(start: $start, end: $end)
    modificationsInRange(start: $start, end: $end) {
      ...MealPlanDateModifications
    }
  }
}
    ${MealPlanDateModificationsFragmentDoc}`;
export type MealPlanCalendarInRangePreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanCalendarInRangePreviewQuery, MealPlanCalendarInRangePreviewQueryVariables>, 'query'> & ({ variables: MealPlanCalendarInRangePreviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanCalendarInRangePreviewComponent = (props: MealPlanCalendarInRangePreviewComponentProps) => (
      <ApolloReactComponents.Query<MealPlanCalendarInRangePreviewQuery, MealPlanCalendarInRangePreviewQueryVariables> query={MealPlanCalendarInRangePreviewDocument} {...props} />
    );
    

/**
 * __useMealPlanCalendarInRangePreviewQuery__
 *
 * To run a query within a React component, call `useMealPlanCalendarInRangePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanCalendarInRangePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanCalendarInRangePreviewQuery({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useMealPlanCalendarInRangePreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanCalendarInRangePreviewQuery, MealPlanCalendarInRangePreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanCalendarInRangePreviewQuery, MealPlanCalendarInRangePreviewQueryVariables>(MealPlanCalendarInRangePreviewDocument, baseOptions);
      }
export function useMealPlanCalendarInRangePreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanCalendarInRangePreviewQuery, MealPlanCalendarInRangePreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanCalendarInRangePreviewQuery, MealPlanCalendarInRangePreviewQueryVariables>(MealPlanCalendarInRangePreviewDocument, baseOptions);
        }
export type MealPlanCalendarInRangePreviewQueryHookResult = ReturnType<typeof useMealPlanCalendarInRangePreviewQuery>;
export type MealPlanCalendarInRangePreviewLazyQueryHookResult = ReturnType<typeof useMealPlanCalendarInRangePreviewLazyQuery>;
export type MealPlanCalendarInRangePreviewQueryResult = ApolloReactCommon.QueryResult<MealPlanCalendarInRangePreviewQuery, MealPlanCalendarInRangePreviewQueryVariables>;
export const AthleteCalendarInRangePreviewDocument = gql`
    query AthleteCalendarInRangePreview($athleteId: ID!, $start: Date!, $end: Date!) {
  athlete(id: $athleteId) {
    id
    mealPlanDateAssignmentsInRange(start: $start, end: $end) {
      ...MealPlanDateAssignmentPreview
    }
  }
}
    ${MealPlanDateAssignmentPreviewFragmentDoc}`;
export type AthleteCalendarInRangePreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteCalendarInRangePreviewQuery, AthleteCalendarInRangePreviewQueryVariables>, 'query'> & ({ variables: AthleteCalendarInRangePreviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteCalendarInRangePreviewComponent = (props: AthleteCalendarInRangePreviewComponentProps) => (
      <ApolloReactComponents.Query<AthleteCalendarInRangePreviewQuery, AthleteCalendarInRangePreviewQueryVariables> query={AthleteCalendarInRangePreviewDocument} {...props} />
    );
    

/**
 * __useAthleteCalendarInRangePreviewQuery__
 *
 * To run a query within a React component, call `useAthleteCalendarInRangePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteCalendarInRangePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteCalendarInRangePreviewQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAthleteCalendarInRangePreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteCalendarInRangePreviewQuery, AthleteCalendarInRangePreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteCalendarInRangePreviewQuery, AthleteCalendarInRangePreviewQueryVariables>(AthleteCalendarInRangePreviewDocument, baseOptions);
      }
export function useAthleteCalendarInRangePreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteCalendarInRangePreviewQuery, AthleteCalendarInRangePreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteCalendarInRangePreviewQuery, AthleteCalendarInRangePreviewQueryVariables>(AthleteCalendarInRangePreviewDocument, baseOptions);
        }
export type AthleteCalendarInRangePreviewQueryHookResult = ReturnType<typeof useAthleteCalendarInRangePreviewQuery>;
export type AthleteCalendarInRangePreviewLazyQueryHookResult = ReturnType<typeof useAthleteCalendarInRangePreviewLazyQuery>;
export type AthleteCalendarInRangePreviewQueryResult = ApolloReactCommon.QueryResult<AthleteCalendarInRangePreviewQuery, AthleteCalendarInRangePreviewQueryVariables>;
export const MealPlanCalendarDocument = gql`
    query MealPlanCalendar($mealPlanId: ID!, $after: Date!) {
  mealPlan(id: $mealPlanId) {
    id
    isAutoSuggestionsEnabled
    startDate
    endDate
    schedule {
      ...ScheduleBasic
    }
    mealTemplates {
      ...MealTemplateSchedule
    }
    activityTemplates {
      ...ActivityTemplateSchedule
    }
    daysOfWeek {
      ...MealPlanDayOfWeekAssignment
    }
  }
}
    ${ScheduleBasicFragmentDoc}
${MealTemplateScheduleFragmentDoc}
${ActivityTemplateScheduleFragmentDoc}
${MealPlanDayOfWeekAssignmentFragmentDoc}`;
export type MealPlanCalendarComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanCalendarQuery, MealPlanCalendarQueryVariables>, 'query'> & ({ variables: MealPlanCalendarQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanCalendarComponent = (props: MealPlanCalendarComponentProps) => (
      <ApolloReactComponents.Query<MealPlanCalendarQuery, MealPlanCalendarQueryVariables> query={MealPlanCalendarDocument} {...props} />
    );
    

/**
 * __useMealPlanCalendarQuery__
 *
 * To run a query within a React component, call `useMealPlanCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanCalendarQuery({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMealPlanCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanCalendarQuery, MealPlanCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanCalendarQuery, MealPlanCalendarQueryVariables>(MealPlanCalendarDocument, baseOptions);
      }
export function useMealPlanCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanCalendarQuery, MealPlanCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanCalendarQuery, MealPlanCalendarQueryVariables>(MealPlanCalendarDocument, baseOptions);
        }
export type MealPlanCalendarQueryHookResult = ReturnType<typeof useMealPlanCalendarQuery>;
export type MealPlanCalendarLazyQueryHookResult = ReturnType<typeof useMealPlanCalendarLazyQuery>;
export type MealPlanCalendarQueryResult = ApolloReactCommon.QueryResult<MealPlanCalendarQuery, MealPlanCalendarQueryVariables>;
export const MealPlanCreateCalendarInRangeDocument = gql`
    query MealPlanCreateCalendarInRange($athleteId: ID!, $startDate: Date!, $endDate: Date!, $startDateTime: DateTime!, $endDateTime: DateTime!) {
  athlete(id: $athleteId) {
    id
    mealPlanDateAssignmentsInRange(start: $startDate, end: $endDate) {
      ...MealPlanCalendarDateAssignment
    }
    timelineMealsInRange(start: $startDateTime, end: $endDateTime) {
      id
      start
    }
  }
}
    ${MealPlanCalendarDateAssignmentFragmentDoc}`;
export type MealPlanCreateCalendarInRangeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanCreateCalendarInRangeQuery, MealPlanCreateCalendarInRangeQueryVariables>, 'query'> & ({ variables: MealPlanCreateCalendarInRangeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanCreateCalendarInRangeComponent = (props: MealPlanCreateCalendarInRangeComponentProps) => (
      <ApolloReactComponents.Query<MealPlanCreateCalendarInRangeQuery, MealPlanCreateCalendarInRangeQueryVariables> query={MealPlanCreateCalendarInRangeDocument} {...props} />
    );
    

/**
 * __useMealPlanCreateCalendarInRangeQuery__
 *
 * To run a query within a React component, call `useMealPlanCreateCalendarInRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanCreateCalendarInRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanCreateCalendarInRangeQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *   },
 * });
 */
export function useMealPlanCreateCalendarInRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanCreateCalendarInRangeQuery, MealPlanCreateCalendarInRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanCreateCalendarInRangeQuery, MealPlanCreateCalendarInRangeQueryVariables>(MealPlanCreateCalendarInRangeDocument, baseOptions);
      }
export function useMealPlanCreateCalendarInRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanCreateCalendarInRangeQuery, MealPlanCreateCalendarInRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanCreateCalendarInRangeQuery, MealPlanCreateCalendarInRangeQueryVariables>(MealPlanCreateCalendarInRangeDocument, baseOptions);
        }
export type MealPlanCreateCalendarInRangeQueryHookResult = ReturnType<typeof useMealPlanCreateCalendarInRangeQuery>;
export type MealPlanCreateCalendarInRangeLazyQueryHookResult = ReturnType<typeof useMealPlanCreateCalendarInRangeLazyQuery>;
export type MealPlanCreateCalendarInRangeQueryResult = ApolloReactCommon.QueryResult<MealPlanCreateCalendarInRangeQuery, MealPlanCreateCalendarInRangeQueryVariables>;
export const MealPlanEditCalendarInRangeDocument = gql`
    query MealPlanEditCalendarInRange($mealPlanId: ID!, $athleteId: ID!, $startDate: Date!, $endDate: Date!, $startDateTime: DateTime!, $endDateTime: DateTime!) {
  mealPlan(id: $mealPlanId) {
    id
    individualDatesInRange(start: $startDate, end: $endDate)
    modificationsInRange(start: $startDate, end: $endDate) {
      ...MealPlanDateModifications
    }
  }
  athlete(id: $athleteId) {
    id
    mealPlanDateAssignmentsInRange(start: $startDate, end: $endDate) {
      ...MealPlanCalendarDateAssignment
    }
    timelineMealsInRange(start: $startDateTime, end: $endDateTime) {
      id
      start
    }
  }
}
    ${MealPlanDateModificationsFragmentDoc}
${MealPlanCalendarDateAssignmentFragmentDoc}`;
export type MealPlanEditCalendarInRangeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanEditCalendarInRangeQuery, MealPlanEditCalendarInRangeQueryVariables>, 'query'> & ({ variables: MealPlanEditCalendarInRangeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanEditCalendarInRangeComponent = (props: MealPlanEditCalendarInRangeComponentProps) => (
      <ApolloReactComponents.Query<MealPlanEditCalendarInRangeQuery, MealPlanEditCalendarInRangeQueryVariables> query={MealPlanEditCalendarInRangeDocument} {...props} />
    );
    

/**
 * __useMealPlanEditCalendarInRangeQuery__
 *
 * To run a query within a React component, call `useMealPlanEditCalendarInRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanEditCalendarInRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanEditCalendarInRangeQuery({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *      athleteId: // value for 'athleteId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *   },
 * });
 */
export function useMealPlanEditCalendarInRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanEditCalendarInRangeQuery, MealPlanEditCalendarInRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanEditCalendarInRangeQuery, MealPlanEditCalendarInRangeQueryVariables>(MealPlanEditCalendarInRangeDocument, baseOptions);
      }
export function useMealPlanEditCalendarInRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanEditCalendarInRangeQuery, MealPlanEditCalendarInRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanEditCalendarInRangeQuery, MealPlanEditCalendarInRangeQueryVariables>(MealPlanEditCalendarInRangeDocument, baseOptions);
        }
export type MealPlanEditCalendarInRangeQueryHookResult = ReturnType<typeof useMealPlanEditCalendarInRangeQuery>;
export type MealPlanEditCalendarInRangeLazyQueryHookResult = ReturnType<typeof useMealPlanEditCalendarInRangeLazyQuery>;
export type MealPlanEditCalendarInRangeQueryResult = ApolloReactCommon.QueryResult<MealPlanEditCalendarInRangeQuery, MealPlanEditCalendarInRangeQueryVariables>;
export const MealPlanConflictsDocument = gql`
    query MealPlanConflicts($athleteId: ID!, $start: Date!, $end: Date) {
  athlete(id: $athleteId) {
    id
    priorityMealPlanDaysOfWeekInRange(start: $start, end: $end) {
      dayOfWeekPriority {
        ...DayOfWeekPriority
      }
      mealPlan {
        id
        name
      }
    }
    mealPlanIndividualDatesInRange(start: $start, end: $end) {
      date
      mealPlan {
        id
        name
      }
    }
  }
}
    ${DayOfWeekPriorityFragmentDoc}`;
export type MealPlanConflictsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanConflictsQuery, MealPlanConflictsQueryVariables>, 'query'> & ({ variables: MealPlanConflictsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanConflictsComponent = (props: MealPlanConflictsComponentProps) => (
      <ApolloReactComponents.Query<MealPlanConflictsQuery, MealPlanConflictsQueryVariables> query={MealPlanConflictsDocument} {...props} />
    );
    

/**
 * __useMealPlanConflictsQuery__
 *
 * To run a query within a React component, call `useMealPlanConflictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanConflictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanConflictsQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useMealPlanConflictsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanConflictsQuery, MealPlanConflictsQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanConflictsQuery, MealPlanConflictsQueryVariables>(MealPlanConflictsDocument, baseOptions);
      }
export function useMealPlanConflictsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanConflictsQuery, MealPlanConflictsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanConflictsQuery, MealPlanConflictsQueryVariables>(MealPlanConflictsDocument, baseOptions);
        }
export type MealPlanConflictsQueryHookResult = ReturnType<typeof useMealPlanConflictsQuery>;
export type MealPlanConflictsLazyQueryHookResult = ReturnType<typeof useMealPlanConflictsLazyQuery>;
export type MealPlanConflictsQueryResult = ApolloReactCommon.QueryResult<MealPlanConflictsQuery, MealPlanConflictsQueryVariables>;
export const EditMealPlanCalendarDocument = gql`
    mutation EditMealPlanCalendar($input: EditMealPlanCalendarInput!) {
  editMealPlanCalendar(input: $input) {
    mealPlan {
      id
    }
  }
}
    `;
export type EditMealPlanCalendarMutationFn = ApolloReactCommon.MutationFunction<EditMealPlanCalendarMutation, EditMealPlanCalendarMutationVariables>;
export type EditMealPlanCalendarComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealPlanCalendarMutation, EditMealPlanCalendarMutationVariables>, 'mutation'>;

    export const EditMealPlanCalendarComponent = (props: EditMealPlanCalendarComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealPlanCalendarMutation, EditMealPlanCalendarMutationVariables> mutation={EditMealPlanCalendarDocument} {...props} />
    );
    

/**
 * __useEditMealPlanCalendarMutation__
 *
 * To run a mutation, you first call `useEditMealPlanCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealPlanCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealPlanCalendarMutation, { data, loading, error }] = useEditMealPlanCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealPlanCalendarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealPlanCalendarMutation, EditMealPlanCalendarMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealPlanCalendarMutation, EditMealPlanCalendarMutationVariables>(EditMealPlanCalendarDocument, baseOptions);
      }
export type EditMealPlanCalendarMutationHookResult = ReturnType<typeof useEditMealPlanCalendarMutation>;
export type EditMealPlanCalendarMutationResult = ApolloReactCommon.MutationResult<EditMealPlanCalendarMutation>;
export type EditMealPlanCalendarMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealPlanCalendarMutation, EditMealPlanCalendarMutationVariables>;
export const ExternalSchedulesDocument = gql`
    query ExternalSchedules($athleteId: ID!, $start: Date!, $end: Date!) {
  teamworksScheduleForAthleteInRange(
    athleteId: $athleteId
    startDate: $start
    endDate: $end
  ) {
    ...ExternalEvent
  }
}
    ${ExternalEventFragmentDoc}`;
export type ExternalSchedulesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExternalSchedulesQuery, ExternalSchedulesQueryVariables>, 'query'> & ({ variables: ExternalSchedulesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExternalSchedulesComponent = (props: ExternalSchedulesComponentProps) => (
      <ApolloReactComponents.Query<ExternalSchedulesQuery, ExternalSchedulesQueryVariables> query={ExternalSchedulesDocument} {...props} />
    );
    

/**
 * __useExternalSchedulesQuery__
 *
 * To run a query within a React component, call `useExternalSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalSchedulesQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useExternalSchedulesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExternalSchedulesQuery, ExternalSchedulesQueryVariables>) {
        return ApolloReactHooks.useQuery<ExternalSchedulesQuery, ExternalSchedulesQueryVariables>(ExternalSchedulesDocument, baseOptions);
      }
export function useExternalSchedulesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExternalSchedulesQuery, ExternalSchedulesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExternalSchedulesQuery, ExternalSchedulesQueryVariables>(ExternalSchedulesDocument, baseOptions);
        }
export type ExternalSchedulesQueryHookResult = ReturnType<typeof useExternalSchedulesQuery>;
export type ExternalSchedulesLazyQueryHookResult = ReturnType<typeof useExternalSchedulesLazyQuery>;
export type ExternalSchedulesQueryResult = ApolloReactCommon.QueryResult<ExternalSchedulesQuery, ExternalSchedulesQueryVariables>;
export const ExternalCalendarsDocument = gql`
    query ExternalCalendars($athleteId: ID!, $start: Date!, $end: Date!) {
  teamworksCalendarForAthleteInRange(
    athleteId: $athleteId
    startDate: $start
    endDate: $end
  ) {
    events {
      ...ExternalEvent
    }
    isPartialSuccess
  }
}
    ${ExternalEventFragmentDoc}`;
export type ExternalCalendarsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExternalCalendarsQuery, ExternalCalendarsQueryVariables>, 'query'> & ({ variables: ExternalCalendarsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExternalCalendarsComponent = (props: ExternalCalendarsComponentProps) => (
      <ApolloReactComponents.Query<ExternalCalendarsQuery, ExternalCalendarsQueryVariables> query={ExternalCalendarsDocument} {...props} />
    );
    

/**
 * __useExternalCalendarsQuery__
 *
 * To run a query within a React component, call `useExternalCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalCalendarsQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useExternalCalendarsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExternalCalendarsQuery, ExternalCalendarsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExternalCalendarsQuery, ExternalCalendarsQueryVariables>(ExternalCalendarsDocument, baseOptions);
      }
export function useExternalCalendarsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExternalCalendarsQuery, ExternalCalendarsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExternalCalendarsQuery, ExternalCalendarsQueryVariables>(ExternalCalendarsDocument, baseOptions);
        }
export type ExternalCalendarsQueryHookResult = ReturnType<typeof useExternalCalendarsQuery>;
export type ExternalCalendarsLazyQueryHookResult = ReturnType<typeof useExternalCalendarsLazyQuery>;
export type ExternalCalendarsQueryResult = ApolloReactCommon.QueryResult<ExternalCalendarsQuery, ExternalCalendarsQueryVariables>;
export const MealPlanCommonDocument = gql`
    query MealPlanCommon($mealPlanId: ID!) {
  mealPlan(id: $mealPlanId) {
    ...EditFullMealPlan
  }
}
    ${EditFullMealPlanFragmentDoc}`;
export type MealPlanCommonComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanCommonQuery, MealPlanCommonQueryVariables>, 'query'> & ({ variables: MealPlanCommonQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanCommonComponent = (props: MealPlanCommonComponentProps) => (
      <ApolloReactComponents.Query<MealPlanCommonQuery, MealPlanCommonQueryVariables> query={MealPlanCommonDocument} {...props} />
    );
    

/**
 * __useMealPlanCommonQuery__
 *
 * To run a query within a React component, call `useMealPlanCommonQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanCommonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanCommonQuery({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useMealPlanCommonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanCommonQuery, MealPlanCommonQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanCommonQuery, MealPlanCommonQueryVariables>(MealPlanCommonDocument, baseOptions);
      }
export function useMealPlanCommonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanCommonQuery, MealPlanCommonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanCommonQuery, MealPlanCommonQueryVariables>(MealPlanCommonDocument, baseOptions);
        }
export type MealPlanCommonQueryHookResult = ReturnType<typeof useMealPlanCommonQuery>;
export type MealPlanCommonLazyQueryHookResult = ReturnType<typeof useMealPlanCommonLazyQuery>;
export type MealPlanCommonQueryResult = ApolloReactCommon.QueryResult<MealPlanCommonQuery, MealPlanCommonQueryVariables>;
export const AthleteSummaryDocument = gql`
    query AthleteSummary($id: ID!) {
  athlete(id: $id) {
    ...AthleteSummaryAthlete
  }
  teams {
    ...TeamWithSport
  }
}
    ${AthleteSummaryAthleteFragmentDoc}
${TeamWithSportFragmentDoc}`;
export type AthleteSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteSummaryQuery, AthleteSummaryQueryVariables>, 'query'> & ({ variables: AthleteSummaryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteSummaryComponent = (props: AthleteSummaryComponentProps) => (
      <ApolloReactComponents.Query<AthleteSummaryQuery, AthleteSummaryQueryVariables> query={AthleteSummaryDocument} {...props} />
    );
    

/**
 * __useAthleteSummaryQuery__
 *
 * To run a query within a React component, call `useAthleteSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteSummaryQuery, AthleteSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteSummaryQuery, AthleteSummaryQueryVariables>(AthleteSummaryDocument, baseOptions);
      }
export function useAthleteSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteSummaryQuery, AthleteSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteSummaryQuery, AthleteSummaryQueryVariables>(AthleteSummaryDocument, baseOptions);
        }
export type AthleteSummaryQueryHookResult = ReturnType<typeof useAthleteSummaryQuery>;
export type AthleteSummaryLazyQueryHookResult = ReturnType<typeof useAthleteSummaryLazyQuery>;
export type AthleteSummaryQueryResult = ApolloReactCommon.QueryResult<AthleteSummaryQuery, AthleteSummaryQueryVariables>;
export const EditAthleteDocument = gql`
    mutation EditAthlete($input: EditAthleteInput!) {
  editAthlete(input: $input) {
    athlete {
      ...EditedAthlete
    }
  }
}
    ${EditedAthleteFragmentDoc}`;
export type EditAthleteMutationFn = ApolloReactCommon.MutationFunction<EditAthleteMutation, EditAthleteMutationVariables>;
export type EditAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAthleteMutation, EditAthleteMutationVariables>, 'mutation'>;

    export const EditAthleteComponent = (props: EditAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<EditAthleteMutation, EditAthleteMutationVariables> mutation={EditAthleteDocument} {...props} />
    );
    

/**
 * __useEditAthleteMutation__
 *
 * To run a mutation, you first call `useEditAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAthleteMutation, { data, loading, error }] = useEditAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAthleteMutation, EditAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAthleteMutation, EditAthleteMutationVariables>(EditAthleteDocument, baseOptions);
      }
export type EditAthleteMutationHookResult = ReturnType<typeof useEditAthleteMutation>;
export type EditAthleteMutationResult = ApolloReactCommon.MutationResult<EditAthleteMutation>;
export type EditAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAthleteMutation, EditAthleteMutationVariables>;
export const EditSyncedAthleteDocument = gql`
    mutation EditSyncedAthlete($input: EditSyncedAthleteInput!) {
  editSyncedAthlete(input: $input) {
    athlete {
      ...EditedAthlete
    }
  }
}
    ${EditedAthleteFragmentDoc}`;
export type EditSyncedAthleteMutationFn = ApolloReactCommon.MutationFunction<EditSyncedAthleteMutation, EditSyncedAthleteMutationVariables>;
export type EditSyncedAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditSyncedAthleteMutation, EditSyncedAthleteMutationVariables>, 'mutation'>;

    export const EditSyncedAthleteComponent = (props: EditSyncedAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<EditSyncedAthleteMutation, EditSyncedAthleteMutationVariables> mutation={EditSyncedAthleteDocument} {...props} />
    );
    

/**
 * __useEditSyncedAthleteMutation__
 *
 * To run a mutation, you first call `useEditSyncedAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSyncedAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSyncedAthleteMutation, { data, loading, error }] = useEditSyncedAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSyncedAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSyncedAthleteMutation, EditSyncedAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSyncedAthleteMutation, EditSyncedAthleteMutationVariables>(EditSyncedAthleteDocument, baseOptions);
      }
export type EditSyncedAthleteMutationHookResult = ReturnType<typeof useEditSyncedAthleteMutation>;
export type EditSyncedAthleteMutationResult = ApolloReactCommon.MutationResult<EditSyncedAthleteMutation>;
export type EditSyncedAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSyncedAthleteMutation, EditSyncedAthleteMutationVariables>;
export const EditAthleteDisplaySettingsDocument = gql`
    mutation EditAthleteDisplaySettings($input: EditAthleteDisplaySettingsInput!) {
  editAthleteDisplaySettings(input: $input) {
    athlete {
      id
      hideAnthropometry
      defaultMacroDisplaySettings {
        ...MacroMealPlanDisplaySettings
      }
      defaultExchangeDisplaySettings {
        ...ExchangeMealPlanDisplaySettings
      }
      noTargetsDisplaySettings {
        ...NoTargetsDisplaySettings
      }
    }
  }
}
    ${MacroMealPlanDisplaySettingsFragmentDoc}
${ExchangeMealPlanDisplaySettingsFragmentDoc}
${NoTargetsDisplaySettingsFragmentDoc}`;
export type EditAthleteDisplaySettingsMutationFn = ApolloReactCommon.MutationFunction<EditAthleteDisplaySettingsMutation, EditAthleteDisplaySettingsMutationVariables>;
export type EditAthleteDisplaySettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAthleteDisplaySettingsMutation, EditAthleteDisplaySettingsMutationVariables>, 'mutation'>;

    export const EditAthleteDisplaySettingsComponent = (props: EditAthleteDisplaySettingsComponentProps) => (
      <ApolloReactComponents.Mutation<EditAthleteDisplaySettingsMutation, EditAthleteDisplaySettingsMutationVariables> mutation={EditAthleteDisplaySettingsDocument} {...props} />
    );
    

/**
 * __useEditAthleteDisplaySettingsMutation__
 *
 * To run a mutation, you first call `useEditAthleteDisplaySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAthleteDisplaySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAthleteDisplaySettingsMutation, { data, loading, error }] = useEditAthleteDisplaySettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAthleteDisplaySettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAthleteDisplaySettingsMutation, EditAthleteDisplaySettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAthleteDisplaySettingsMutation, EditAthleteDisplaySettingsMutationVariables>(EditAthleteDisplaySettingsDocument, baseOptions);
      }
export type EditAthleteDisplaySettingsMutationHookResult = ReturnType<typeof useEditAthleteDisplaySettingsMutation>;
export type EditAthleteDisplaySettingsMutationResult = ApolloReactCommon.MutationResult<EditAthleteDisplaySettingsMutation>;
export type EditAthleteDisplaySettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAthleteDisplaySettingsMutation, EditAthleteDisplaySettingsMutationVariables>;
export const EditAthleteFoodLogNotificationSettingsDocument = gql`
    mutation EditAthleteFoodLogNotificationSettings($input: EditAthleteFoodLogNotificationSettingsInput!) {
  editAthleteFoodLogNotificationSettings(input: $input) {
    athlete {
      id
      foodLogNotificationSettings {
        ...FoodLogNotificationSettings
      }
    }
  }
}
    ${FoodLogNotificationSettingsFragmentDoc}`;
export type EditAthleteFoodLogNotificationSettingsMutationFn = ApolloReactCommon.MutationFunction<EditAthleteFoodLogNotificationSettingsMutation, EditAthleteFoodLogNotificationSettingsMutationVariables>;
export type EditAthleteFoodLogNotificationSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAthleteFoodLogNotificationSettingsMutation, EditAthleteFoodLogNotificationSettingsMutationVariables>, 'mutation'>;

    export const EditAthleteFoodLogNotificationSettingsComponent = (props: EditAthleteFoodLogNotificationSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<EditAthleteFoodLogNotificationSettingsMutation, EditAthleteFoodLogNotificationSettingsMutationVariables> mutation={EditAthleteFoodLogNotificationSettingsDocument} {...props} />
    );
    

/**
 * __useEditAthleteFoodLogNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useEditAthleteFoodLogNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAthleteFoodLogNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAthleteFoodLogNotificationSettingsMutation, { data, loading, error }] = useEditAthleteFoodLogNotificationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAthleteFoodLogNotificationSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAthleteFoodLogNotificationSettingsMutation, EditAthleteFoodLogNotificationSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAthleteFoodLogNotificationSettingsMutation, EditAthleteFoodLogNotificationSettingsMutationVariables>(EditAthleteFoodLogNotificationSettingsDocument, baseOptions);
      }
export type EditAthleteFoodLogNotificationSettingsMutationHookResult = ReturnType<typeof useEditAthleteFoodLogNotificationSettingsMutation>;
export type EditAthleteFoodLogNotificationSettingsMutationResult = ApolloReactCommon.MutationResult<EditAthleteFoodLogNotificationSettingsMutation>;
export type EditAthleteFoodLogNotificationSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAthleteFoodLogNotificationSettingsMutation, EditAthleteFoodLogNotificationSettingsMutationVariables>;
export const DeleteAthleteDocument = gql`
    mutation DeleteAthlete($input: DeleteAthleteInput!) {
  deleteAthlete(input: $input) {
    athleteId
  }
}
    `;
export type DeleteAthleteMutationFn = ApolloReactCommon.MutationFunction<DeleteAthleteMutation, DeleteAthleteMutationVariables>;
export type DeleteAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAthleteMutation, DeleteAthleteMutationVariables>, 'mutation'>;

    export const DeleteAthleteComponent = (props: DeleteAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAthleteMutation, DeleteAthleteMutationVariables> mutation={DeleteAthleteDocument} {...props} />
    );
    

/**
 * __useDeleteAthleteMutation__
 *
 * To run a mutation, you first call `useDeleteAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAthleteMutation, { data, loading, error }] = useDeleteAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAthleteMutation, DeleteAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAthleteMutation, DeleteAthleteMutationVariables>(DeleteAthleteDocument, baseOptions);
      }
export type DeleteAthleteMutationHookResult = ReturnType<typeof useDeleteAthleteMutation>;
export type DeleteAthleteMutationResult = ApolloReactCommon.MutationResult<DeleteAthleteMutation>;
export type DeleteAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAthleteMutation, DeleteAthleteMutationVariables>;
export const TransferAthleteDocument = gql`
    mutation TransferAthlete($input: CreateAthleteTransferInput!) {
  createAthleteTransfer(input: $input) {
    athleteTransfer {
      id
      athlete {
        id
        activeTransfer {
          id
        }
      }
    }
  }
}
    `;
export type TransferAthleteMutationFn = ApolloReactCommon.MutationFunction<TransferAthleteMutation, TransferAthleteMutationVariables>;
export type TransferAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TransferAthleteMutation, TransferAthleteMutationVariables>, 'mutation'>;

    export const TransferAthleteComponent = (props: TransferAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<TransferAthleteMutation, TransferAthleteMutationVariables> mutation={TransferAthleteDocument} {...props} />
    );
    

/**
 * __useTransferAthleteMutation__
 *
 * To run a mutation, you first call `useTransferAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferAthleteMutation, { data, loading, error }] = useTransferAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferAthleteMutation, TransferAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TransferAthleteMutation, TransferAthleteMutationVariables>(TransferAthleteDocument, baseOptions);
      }
export type TransferAthleteMutationHookResult = ReturnType<typeof useTransferAthleteMutation>;
export type TransferAthleteMutationResult = ApolloReactCommon.MutationResult<TransferAthleteMutation>;
export type TransferAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferAthleteMutation, TransferAthleteMutationVariables>;
export const ReclaimAthleteDocument = gql`
    mutation ReclaimAthlete($input: ReclaimAthleteTransferInput!) {
  reclaimAthleteTransfer(input: $input) {
    athleteTransfer {
      id
      athlete {
        id
        activeTransfer {
          id
        }
      }
    }
  }
}
    `;
export type ReclaimAthleteMutationFn = ApolloReactCommon.MutationFunction<ReclaimAthleteMutation, ReclaimAthleteMutationVariables>;
export type ReclaimAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReclaimAthleteMutation, ReclaimAthleteMutationVariables>, 'mutation'>;

    export const ReclaimAthleteComponent = (props: ReclaimAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<ReclaimAthleteMutation, ReclaimAthleteMutationVariables> mutation={ReclaimAthleteDocument} {...props} />
    );
    

/**
 * __useReclaimAthleteMutation__
 *
 * To run a mutation, you first call `useReclaimAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReclaimAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reclaimAthleteMutation, { data, loading, error }] = useReclaimAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReclaimAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReclaimAthleteMutation, ReclaimAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReclaimAthleteMutation, ReclaimAthleteMutationVariables>(ReclaimAthleteDocument, baseOptions);
      }
export type ReclaimAthleteMutationHookResult = ReturnType<typeof useReclaimAthleteMutation>;
export type ReclaimAthleteMutationResult = ApolloReactCommon.MutationResult<ReclaimAthleteMutation>;
export type ReclaimAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReclaimAthleteMutation, ReclaimAthleteMutationVariables>;
export const AthletePageDocument = gql`
    query AthletePage($id: ID!) {
  athlete(id: $id) {
    ...AthletePageAthlete
  }
}
    ${AthletePageAthleteFragmentDoc}`;
export type AthletePageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletePageQuery, AthletePageQueryVariables>, 'query'> & ({ variables: AthletePageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthletePageComponent = (props: AthletePageComponentProps) => (
      <ApolloReactComponents.Query<AthletePageQuery, AthletePageQueryVariables> query={AthletePageDocument} {...props} />
    );
    

/**
 * __useAthletePageQuery__
 *
 * To run a query within a React component, call `useAthletePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthletePageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletePageQuery, AthletePageQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletePageQuery, AthletePageQueryVariables>(AthletePageDocument, baseOptions);
      }
export function useAthletePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletePageQuery, AthletePageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletePageQuery, AthletePageQueryVariables>(AthletePageDocument, baseOptions);
        }
export type AthletePageQueryHookResult = ReturnType<typeof useAthletePageQuery>;
export type AthletePageLazyQueryHookResult = ReturnType<typeof useAthletePageLazyQuery>;
export type AthletePageQueryResult = ApolloReactCommon.QueryResult<AthletePageQuery, AthletePageQueryVariables>;
export const ClientCredentialOffsetConnectionDocument = gql`
    query ClientCredentialOffsetConnection($pagination: OffsetPaginationInput!) {
  clientCredentialOffsetConnection(pagination: $pagination) {
    edges {
      ...ClientCredential
    }
    pageInfo {
      hasNextPage
      total
      endOffset
    }
  }
}
    ${ClientCredentialFragmentDoc}`;
export type ClientCredentialOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ClientCredentialOffsetConnectionQuery, ClientCredentialOffsetConnectionQueryVariables>, 'query'> & ({ variables: ClientCredentialOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ClientCredentialOffsetConnectionComponent = (props: ClientCredentialOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<ClientCredentialOffsetConnectionQuery, ClientCredentialOffsetConnectionQueryVariables> query={ClientCredentialOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useClientCredentialOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useClientCredentialOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientCredentialOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientCredentialOffsetConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useClientCredentialOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClientCredentialOffsetConnectionQuery, ClientCredentialOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<ClientCredentialOffsetConnectionQuery, ClientCredentialOffsetConnectionQueryVariables>(ClientCredentialOffsetConnectionDocument, baseOptions);
      }
export function useClientCredentialOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientCredentialOffsetConnectionQuery, ClientCredentialOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClientCredentialOffsetConnectionQuery, ClientCredentialOffsetConnectionQueryVariables>(ClientCredentialOffsetConnectionDocument, baseOptions);
        }
export type ClientCredentialOffsetConnectionQueryHookResult = ReturnType<typeof useClientCredentialOffsetConnectionQuery>;
export type ClientCredentialOffsetConnectionLazyQueryHookResult = ReturnType<typeof useClientCredentialOffsetConnectionLazyQuery>;
export type ClientCredentialOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<ClientCredentialOffsetConnectionQuery, ClientCredentialOffsetConnectionQueryVariables>;
export const CreateClientCredentialDocument = gql`
    mutation CreateClientCredential($input: CreateClientCredentialInput!) {
  createClientCredential(input: $input) {
    clientCredential {
      ...ClientCredential
    }
    clientSecret
  }
}
    ${ClientCredentialFragmentDoc}`;
export type CreateClientCredentialMutationFn = ApolloReactCommon.MutationFunction<CreateClientCredentialMutation, CreateClientCredentialMutationVariables>;
export type CreateClientCredentialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateClientCredentialMutation, CreateClientCredentialMutationVariables>, 'mutation'>;

    export const CreateClientCredentialComponent = (props: CreateClientCredentialComponentProps) => (
      <ApolloReactComponents.Mutation<CreateClientCredentialMutation, CreateClientCredentialMutationVariables> mutation={CreateClientCredentialDocument} {...props} />
    );
    

/**
 * __useCreateClientCredentialMutation__
 *
 * To run a mutation, you first call `useCreateClientCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientCredentialMutation, { data, loading, error }] = useCreateClientCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientCredentialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClientCredentialMutation, CreateClientCredentialMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateClientCredentialMutation, CreateClientCredentialMutationVariables>(CreateClientCredentialDocument, baseOptions);
      }
export type CreateClientCredentialMutationHookResult = ReturnType<typeof useCreateClientCredentialMutation>;
export type CreateClientCredentialMutationResult = ApolloReactCommon.MutationResult<CreateClientCredentialMutation>;
export type CreateClientCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClientCredentialMutation, CreateClientCredentialMutationVariables>;
export const EditClientCredentialDocument = gql`
    mutation EditClientCredential($input: EditClientCredentialInput!) {
  editClientCredential(input: $input) {
    clientCredential {
      ...ClientCredential
    }
  }
}
    ${ClientCredentialFragmentDoc}`;
export type EditClientCredentialMutationFn = ApolloReactCommon.MutationFunction<EditClientCredentialMutation, EditClientCredentialMutationVariables>;
export type EditClientCredentialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditClientCredentialMutation, EditClientCredentialMutationVariables>, 'mutation'>;

    export const EditClientCredentialComponent = (props: EditClientCredentialComponentProps) => (
      <ApolloReactComponents.Mutation<EditClientCredentialMutation, EditClientCredentialMutationVariables> mutation={EditClientCredentialDocument} {...props} />
    );
    

/**
 * __useEditClientCredentialMutation__
 *
 * To run a mutation, you first call `useEditClientCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditClientCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editClientCredentialMutation, { data, loading, error }] = useEditClientCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditClientCredentialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditClientCredentialMutation, EditClientCredentialMutationVariables>) {
        return ApolloReactHooks.useMutation<EditClientCredentialMutation, EditClientCredentialMutationVariables>(EditClientCredentialDocument, baseOptions);
      }
export type EditClientCredentialMutationHookResult = ReturnType<typeof useEditClientCredentialMutation>;
export type EditClientCredentialMutationResult = ApolloReactCommon.MutationResult<EditClientCredentialMutation>;
export type EditClientCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<EditClientCredentialMutation, EditClientCredentialMutationVariables>;
export const RefreshClientCredentialSecretDocument = gql`
    mutation RefreshClientCredentialSecret($input: RefreshClientCredentialSecretInput!) {
  refreshClientCredentialSecret(input: $input) {
    clientCredential {
      ...ClientCredential
    }
    clientSecret
  }
}
    ${ClientCredentialFragmentDoc}`;
export type RefreshClientCredentialSecretMutationFn = ApolloReactCommon.MutationFunction<RefreshClientCredentialSecretMutation, RefreshClientCredentialSecretMutationVariables>;
export type RefreshClientCredentialSecretComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RefreshClientCredentialSecretMutation, RefreshClientCredentialSecretMutationVariables>, 'mutation'>;

    export const RefreshClientCredentialSecretComponent = (props: RefreshClientCredentialSecretComponentProps) => (
      <ApolloReactComponents.Mutation<RefreshClientCredentialSecretMutation, RefreshClientCredentialSecretMutationVariables> mutation={RefreshClientCredentialSecretDocument} {...props} />
    );
    

/**
 * __useRefreshClientCredentialSecretMutation__
 *
 * To run a mutation, you first call `useRefreshClientCredentialSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshClientCredentialSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshClientCredentialSecretMutation, { data, loading, error }] = useRefreshClientCredentialSecretMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshClientCredentialSecretMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshClientCredentialSecretMutation, RefreshClientCredentialSecretMutationVariables>) {
        return ApolloReactHooks.useMutation<RefreshClientCredentialSecretMutation, RefreshClientCredentialSecretMutationVariables>(RefreshClientCredentialSecretDocument, baseOptions);
      }
export type RefreshClientCredentialSecretMutationHookResult = ReturnType<typeof useRefreshClientCredentialSecretMutation>;
export type RefreshClientCredentialSecretMutationResult = ApolloReactCommon.MutationResult<RefreshClientCredentialSecretMutation>;
export type RefreshClientCredentialSecretMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshClientCredentialSecretMutation, RefreshClientCredentialSecretMutationVariables>;
export const DiningStationTemplatesTableDocument = gql`
    query DiningStationTemplatesTable($query: String, $input: OffsetPaginationInput!) {
  diningStationTemplateOffsetConnection(query: $query, input: $input) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...DiningStationTemplateTable
    }
  }
}
    ${DiningStationTemplateTableFragmentDoc}`;
export type DiningStationTemplatesTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiningStationTemplatesTableQuery, DiningStationTemplatesTableQueryVariables>, 'query'> & ({ variables: DiningStationTemplatesTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DiningStationTemplatesTableComponent = (props: DiningStationTemplatesTableComponentProps) => (
      <ApolloReactComponents.Query<DiningStationTemplatesTableQuery, DiningStationTemplatesTableQueryVariables> query={DiningStationTemplatesTableDocument} {...props} />
    );
    

/**
 * __useDiningStationTemplatesTableQuery__
 *
 * To run a query within a React component, call `useDiningStationTemplatesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiningStationTemplatesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiningStationTemplatesTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiningStationTemplatesTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiningStationTemplatesTableQuery, DiningStationTemplatesTableQueryVariables>) {
        return ApolloReactHooks.useQuery<DiningStationTemplatesTableQuery, DiningStationTemplatesTableQueryVariables>(DiningStationTemplatesTableDocument, baseOptions);
      }
export function useDiningStationTemplatesTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiningStationTemplatesTableQuery, DiningStationTemplatesTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiningStationTemplatesTableQuery, DiningStationTemplatesTableQueryVariables>(DiningStationTemplatesTableDocument, baseOptions);
        }
export type DiningStationTemplatesTableQueryHookResult = ReturnType<typeof useDiningStationTemplatesTableQuery>;
export type DiningStationTemplatesTableLazyQueryHookResult = ReturnType<typeof useDiningStationTemplatesTableLazyQuery>;
export type DiningStationTemplatesTableQueryResult = ApolloReactCommon.QueryResult<DiningStationTemplatesTableQuery, DiningStationTemplatesTableQueryVariables>;
export const DiningStationTemplateSearchDocument = gql`
    query DiningStationTemplateSearch($query: String, $pagination: CursorPaginationInput!) {
  diningStationTemplateCursorConnection(query: $query, input: $pagination) {
    edges {
      ...DiningStationTemplatePreview
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${DiningStationTemplatePreviewFragmentDoc}`;
export type DiningStationTemplateSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiningStationTemplateSearchQuery, DiningStationTemplateSearchQueryVariables>, 'query'> & ({ variables: DiningStationTemplateSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DiningStationTemplateSearchComponent = (props: DiningStationTemplateSearchComponentProps) => (
      <ApolloReactComponents.Query<DiningStationTemplateSearchQuery, DiningStationTemplateSearchQueryVariables> query={DiningStationTemplateSearchDocument} {...props} />
    );
    

/**
 * __useDiningStationTemplateSearchQuery__
 *
 * To run a query within a React component, call `useDiningStationTemplateSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiningStationTemplateSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiningStationTemplateSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDiningStationTemplateSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiningStationTemplateSearchQuery, DiningStationTemplateSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<DiningStationTemplateSearchQuery, DiningStationTemplateSearchQueryVariables>(DiningStationTemplateSearchDocument, baseOptions);
      }
export function useDiningStationTemplateSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiningStationTemplateSearchQuery, DiningStationTemplateSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiningStationTemplateSearchQuery, DiningStationTemplateSearchQueryVariables>(DiningStationTemplateSearchDocument, baseOptions);
        }
export type DiningStationTemplateSearchQueryHookResult = ReturnType<typeof useDiningStationTemplateSearchQuery>;
export type DiningStationTemplateSearchLazyQueryHookResult = ReturnType<typeof useDiningStationTemplateSearchLazyQuery>;
export type DiningStationTemplateSearchQueryResult = ApolloReactCommon.QueryResult<DiningStationTemplateSearchQuery, DiningStationTemplateSearchQueryVariables>;
export const DiningStationTemplateDialogDocument = gql`
    query DiningStationTemplateDialog($id: ID!) {
  diningStationTemplate(id: $id) {
    ...DiningStationTemplateFull
  }
}
    ${DiningStationTemplateFullFragmentDoc}`;
export type DiningStationTemplateDialogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiningStationTemplateDialogQuery, DiningStationTemplateDialogQueryVariables>, 'query'> & ({ variables: DiningStationTemplateDialogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DiningStationTemplateDialogComponent = (props: DiningStationTemplateDialogComponentProps) => (
      <ApolloReactComponents.Query<DiningStationTemplateDialogQuery, DiningStationTemplateDialogQueryVariables> query={DiningStationTemplateDialogDocument} {...props} />
    );
    

/**
 * __useDiningStationTemplateDialogQuery__
 *
 * To run a query within a React component, call `useDiningStationTemplateDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiningStationTemplateDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiningStationTemplateDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiningStationTemplateDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiningStationTemplateDialogQuery, DiningStationTemplateDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<DiningStationTemplateDialogQuery, DiningStationTemplateDialogQueryVariables>(DiningStationTemplateDialogDocument, baseOptions);
      }
export function useDiningStationTemplateDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiningStationTemplateDialogQuery, DiningStationTemplateDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiningStationTemplateDialogQuery, DiningStationTemplateDialogQueryVariables>(DiningStationTemplateDialogDocument, baseOptions);
        }
export type DiningStationTemplateDialogQueryHookResult = ReturnType<typeof useDiningStationTemplateDialogQuery>;
export type DiningStationTemplateDialogLazyQueryHookResult = ReturnType<typeof useDiningStationTemplateDialogLazyQuery>;
export type DiningStationTemplateDialogQueryResult = ApolloReactCommon.QueryResult<DiningStationTemplateDialogQuery, DiningStationTemplateDialogQueryVariables>;
export const DiningStationTemplateMenuItemsForMenuBuilderDocument = gql`
    query DiningStationTemplateMenuItemsForMenuBuilder($diningStationId: ID!) {
  diningStationTemplate(id: $diningStationId) {
    id
    menuItemAppearances {
      ...MenuItemAppearanceForMenuBuilder
    }
  }
}
    ${MenuItemAppearanceForMenuBuilderFragmentDoc}`;
export type DiningStationTemplateMenuItemsForMenuBuilderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiningStationTemplateMenuItemsForMenuBuilderQuery, DiningStationTemplateMenuItemsForMenuBuilderQueryVariables>, 'query'> & ({ variables: DiningStationTemplateMenuItemsForMenuBuilderQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DiningStationTemplateMenuItemsForMenuBuilderComponent = (props: DiningStationTemplateMenuItemsForMenuBuilderComponentProps) => (
      <ApolloReactComponents.Query<DiningStationTemplateMenuItemsForMenuBuilderQuery, DiningStationTemplateMenuItemsForMenuBuilderQueryVariables> query={DiningStationTemplateMenuItemsForMenuBuilderDocument} {...props} />
    );
    

/**
 * __useDiningStationTemplateMenuItemsForMenuBuilderQuery__
 *
 * To run a query within a React component, call `useDiningStationTemplateMenuItemsForMenuBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiningStationTemplateMenuItemsForMenuBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiningStationTemplateMenuItemsForMenuBuilderQuery({
 *   variables: {
 *      diningStationId: // value for 'diningStationId'
 *   },
 * });
 */
export function useDiningStationTemplateMenuItemsForMenuBuilderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiningStationTemplateMenuItemsForMenuBuilderQuery, DiningStationTemplateMenuItemsForMenuBuilderQueryVariables>) {
        return ApolloReactHooks.useQuery<DiningStationTemplateMenuItemsForMenuBuilderQuery, DiningStationTemplateMenuItemsForMenuBuilderQueryVariables>(DiningStationTemplateMenuItemsForMenuBuilderDocument, baseOptions);
      }
export function useDiningStationTemplateMenuItemsForMenuBuilderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiningStationTemplateMenuItemsForMenuBuilderQuery, DiningStationTemplateMenuItemsForMenuBuilderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiningStationTemplateMenuItemsForMenuBuilderQuery, DiningStationTemplateMenuItemsForMenuBuilderQueryVariables>(DiningStationTemplateMenuItemsForMenuBuilderDocument, baseOptions);
        }
export type DiningStationTemplateMenuItemsForMenuBuilderQueryHookResult = ReturnType<typeof useDiningStationTemplateMenuItemsForMenuBuilderQuery>;
export type DiningStationTemplateMenuItemsForMenuBuilderLazyQueryHookResult = ReturnType<typeof useDiningStationTemplateMenuItemsForMenuBuilderLazyQuery>;
export type DiningStationTemplateMenuItemsForMenuBuilderQueryResult = ApolloReactCommon.QueryResult<DiningStationTemplateMenuItemsForMenuBuilderQuery, DiningStationTemplateMenuItemsForMenuBuilderQueryVariables>;
export const CreateDiningStationTemplateDocument = gql`
    mutation CreateDiningStationTemplate($input: CreateDiningStationTemplateInput!) {
  createDiningStationTemplate(input: $input) {
    diningStationTemplate {
      ...DiningStationTemplateFull
    }
  }
}
    ${DiningStationTemplateFullFragmentDoc}`;
export type CreateDiningStationTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateDiningStationTemplateMutation, CreateDiningStationTemplateMutationVariables>;
export type CreateDiningStationTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDiningStationTemplateMutation, CreateDiningStationTemplateMutationVariables>, 'mutation'>;

    export const CreateDiningStationTemplateComponent = (props: CreateDiningStationTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDiningStationTemplateMutation, CreateDiningStationTemplateMutationVariables> mutation={CreateDiningStationTemplateDocument} {...props} />
    );
    

/**
 * __useCreateDiningStationTemplateMutation__
 *
 * To run a mutation, you first call `useCreateDiningStationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiningStationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiningStationTemplateMutation, { data, loading, error }] = useCreateDiningStationTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDiningStationTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDiningStationTemplateMutation, CreateDiningStationTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDiningStationTemplateMutation, CreateDiningStationTemplateMutationVariables>(CreateDiningStationTemplateDocument, baseOptions);
      }
export type CreateDiningStationTemplateMutationHookResult = ReturnType<typeof useCreateDiningStationTemplateMutation>;
export type CreateDiningStationTemplateMutationResult = ApolloReactCommon.MutationResult<CreateDiningStationTemplateMutation>;
export type CreateDiningStationTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDiningStationTemplateMutation, CreateDiningStationTemplateMutationVariables>;
export const EditDiningStationTemplateDocument = gql`
    mutation EditDiningStationTemplate($input: EditDiningStationTemplateInput!) {
  editDiningStationTemplate(input: $input) {
    diningStationTemplate {
      ...DiningStationTemplateFull
    }
  }
}
    ${DiningStationTemplateFullFragmentDoc}`;
export type EditDiningStationTemplateMutationFn = ApolloReactCommon.MutationFunction<EditDiningStationTemplateMutation, EditDiningStationTemplateMutationVariables>;
export type EditDiningStationTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditDiningStationTemplateMutation, EditDiningStationTemplateMutationVariables>, 'mutation'>;

    export const EditDiningStationTemplateComponent = (props: EditDiningStationTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<EditDiningStationTemplateMutation, EditDiningStationTemplateMutationVariables> mutation={EditDiningStationTemplateDocument} {...props} />
    );
    

/**
 * __useEditDiningStationTemplateMutation__
 *
 * To run a mutation, you first call `useEditDiningStationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDiningStationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDiningStationTemplateMutation, { data, loading, error }] = useEditDiningStationTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDiningStationTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDiningStationTemplateMutation, EditDiningStationTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditDiningStationTemplateMutation, EditDiningStationTemplateMutationVariables>(EditDiningStationTemplateDocument, baseOptions);
      }
export type EditDiningStationTemplateMutationHookResult = ReturnType<typeof useEditDiningStationTemplateMutation>;
export type EditDiningStationTemplateMutationResult = ApolloReactCommon.MutationResult<EditDiningStationTemplateMutation>;
export type EditDiningStationTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditDiningStationTemplateMutation, EditDiningStationTemplateMutationVariables>;
export const DeleteDiningStationTemplateDocument = gql`
    mutation DeleteDiningStationTemplate($input: DeleteDiningStationTemplateInput!) {
  deleteDiningStationTemplate(input: $input) {
    diningStationTemplateId
  }
}
    `;
export type DeleteDiningStationTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteDiningStationTemplateMutation, DeleteDiningStationTemplateMutationVariables>;
export type DeleteDiningStationTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDiningStationTemplateMutation, DeleteDiningStationTemplateMutationVariables>, 'mutation'>;

    export const DeleteDiningStationTemplateComponent = (props: DeleteDiningStationTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDiningStationTemplateMutation, DeleteDiningStationTemplateMutationVariables> mutation={DeleteDiningStationTemplateDocument} {...props} />
    );
    

/**
 * __useDeleteDiningStationTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteDiningStationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiningStationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiningStationTemplateMutation, { data, loading, error }] = useDeleteDiningStationTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDiningStationTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDiningStationTemplateMutation, DeleteDiningStationTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDiningStationTemplateMutation, DeleteDiningStationTemplateMutationVariables>(DeleteDiningStationTemplateDocument, baseOptions);
      }
export type DeleteDiningStationTemplateMutationHookResult = ReturnType<typeof useDeleteDiningStationTemplateMutation>;
export type DeleteDiningStationTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteDiningStationTemplateMutation>;
export type DeleteDiningStationTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDiningStationTemplateMutation, DeleteDiningStationTemplateMutationVariables>;
export const KdsPageMealMenusInDateRangeDocument = gql`
    query KdsPageMealMenusInDateRange($after: Date!, $before: Date!, $timezone: Timezone!) {
  mealMenusInDateRange(after: $after, before: $before, timezone: $timezone) {
    ...KdsPageMealMenuInDateRange
  }
}
    ${KdsPageMealMenuInDateRangeFragmentDoc}`;
export type KdsPageMealMenusInDateRangeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KdsPageMealMenusInDateRangeQuery, KdsPageMealMenusInDateRangeQueryVariables>, 'query'> & ({ variables: KdsPageMealMenusInDateRangeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KdsPageMealMenusInDateRangeComponent = (props: KdsPageMealMenusInDateRangeComponentProps) => (
      <ApolloReactComponents.Query<KdsPageMealMenusInDateRangeQuery, KdsPageMealMenusInDateRangeQueryVariables> query={KdsPageMealMenusInDateRangeDocument} {...props} />
    );
    

/**
 * __useKdsPageMealMenusInDateRangeQuery__
 *
 * To run a query within a React component, call `useKdsPageMealMenusInDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsPageMealMenusInDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsPageMealMenusInDateRangeQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useKdsPageMealMenusInDateRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KdsPageMealMenusInDateRangeQuery, KdsPageMealMenusInDateRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<KdsPageMealMenusInDateRangeQuery, KdsPageMealMenusInDateRangeQueryVariables>(KdsPageMealMenusInDateRangeDocument, baseOptions);
      }
export function useKdsPageMealMenusInDateRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KdsPageMealMenusInDateRangeQuery, KdsPageMealMenusInDateRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KdsPageMealMenusInDateRangeQuery, KdsPageMealMenusInDateRangeQueryVariables>(KdsPageMealMenusInDateRangeDocument, baseOptions);
        }
export type KdsPageMealMenusInDateRangeQueryHookResult = ReturnType<typeof useKdsPageMealMenusInDateRangeQuery>;
export type KdsPageMealMenusInDateRangeLazyQueryHookResult = ReturnType<typeof useKdsPageMealMenusInDateRangeLazyQuery>;
export type KdsPageMealMenusInDateRangeQueryResult = ApolloReactCommon.QueryResult<KdsPageMealMenusInDateRangeQuery, KdsPageMealMenusInDateRangeQueryVariables>;
export const KdsPageMealMenusDocument = gql`
    query KdsPageMealMenus($mealMenuIds: [ID!]!, $diningStationIds: [ID!]!) {
  mealMenus(mealMenuIds: $mealMenuIds) {
    ...KdsPageMealMenu
  }
}
    ${KdsPageMealMenuFragmentDoc}`;
export type KdsPageMealMenusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>, 'query'> & ({ variables: KdsPageMealMenusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KdsPageMealMenusComponent = (props: KdsPageMealMenusComponentProps) => (
      <ApolloReactComponents.Query<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables> query={KdsPageMealMenusDocument} {...props} />
    );
    

/**
 * __useKdsPageMealMenusQuery__
 *
 * To run a query within a React component, call `useKdsPageMealMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsPageMealMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsPageMealMenusQuery({
 *   variables: {
 *      mealMenuIds: // value for 'mealMenuIds'
 *      diningStationIds: // value for 'diningStationIds'
 *   },
 * });
 */
export function useKdsPageMealMenusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>) {
        return ApolloReactHooks.useQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>(KdsPageMealMenusDocument, baseOptions);
      }
export function useKdsPageMealMenusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>(KdsPageMealMenusDocument, baseOptions);
        }
export type KdsPageMealMenusQueryHookResult = ReturnType<typeof useKdsPageMealMenusQuery>;
export type KdsPageMealMenusLazyQueryHookResult = ReturnType<typeof useKdsPageMealMenusLazyQuery>;
export type KdsPageMealMenusQueryResult = ApolloReactCommon.QueryResult<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>;
export const KdsPrintAllDialogMenusDocument = gql`
    query KdsPrintAllDialogMenus($mealMenuIds: [ID!]!, $diningStationIds: [ID!]!) {
  mealMenus(mealMenuIds: $mealMenuIds) {
    allOrders {
      ...KdsTicketMenuOrder
    }
    allMealMenuPlateOrders {
      ...KdsTicketMealMenuPlateOrder
    }
  }
}
    ${KdsTicketMenuOrderFragmentDoc}
${KdsTicketMealMenuPlateOrderFragmentDoc}`;
export type KdsPrintAllDialogMenusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KdsPrintAllDialogMenusQuery, KdsPrintAllDialogMenusQueryVariables>, 'query'> & ({ variables: KdsPrintAllDialogMenusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KdsPrintAllDialogMenusComponent = (props: KdsPrintAllDialogMenusComponentProps) => (
      <ApolloReactComponents.Query<KdsPrintAllDialogMenusQuery, KdsPrintAllDialogMenusQueryVariables> query={KdsPrintAllDialogMenusDocument} {...props} />
    );
    

/**
 * __useKdsPrintAllDialogMenusQuery__
 *
 * To run a query within a React component, call `useKdsPrintAllDialogMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsPrintAllDialogMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsPrintAllDialogMenusQuery({
 *   variables: {
 *      mealMenuIds: // value for 'mealMenuIds'
 *      diningStationIds: // value for 'diningStationIds'
 *   },
 * });
 */
export function useKdsPrintAllDialogMenusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KdsPrintAllDialogMenusQuery, KdsPrintAllDialogMenusQueryVariables>) {
        return ApolloReactHooks.useQuery<KdsPrintAllDialogMenusQuery, KdsPrintAllDialogMenusQueryVariables>(KdsPrintAllDialogMenusDocument, baseOptions);
      }
export function useKdsPrintAllDialogMenusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KdsPrintAllDialogMenusQuery, KdsPrintAllDialogMenusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KdsPrintAllDialogMenusQuery, KdsPrintAllDialogMenusQueryVariables>(KdsPrintAllDialogMenusDocument, baseOptions);
        }
export type KdsPrintAllDialogMenusQueryHookResult = ReturnType<typeof useKdsPrintAllDialogMenusQuery>;
export type KdsPrintAllDialogMenusLazyQueryHookResult = ReturnType<typeof useKdsPrintAllDialogMenusLazyQuery>;
export type KdsPrintAllDialogMenusQueryResult = ApolloReactCommon.QueryResult<KdsPrintAllDialogMenusQuery, KdsPrintAllDialogMenusQueryVariables>;
export const KdsTicketsMenuOrderDocument = gql`
    query KdsTicketsMenuOrder($menuOrderId: ID!, $diningStationIds: [ID!]!) {
  menuOrder(id: $menuOrderId) {
    ...KdsTicketMenuOrder
  }
}
    ${KdsTicketMenuOrderFragmentDoc}`;
export type KdsTicketsMenuOrderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KdsTicketsMenuOrderQuery, KdsTicketsMenuOrderQueryVariables>, 'query'> & ({ variables: KdsTicketsMenuOrderQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KdsTicketsMenuOrderComponent = (props: KdsTicketsMenuOrderComponentProps) => (
      <ApolloReactComponents.Query<KdsTicketsMenuOrderQuery, KdsTicketsMenuOrderQueryVariables> query={KdsTicketsMenuOrderDocument} {...props} />
    );
    

/**
 * __useKdsTicketsMenuOrderQuery__
 *
 * To run a query within a React component, call `useKdsTicketsMenuOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsTicketsMenuOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsTicketsMenuOrderQuery({
 *   variables: {
 *      menuOrderId: // value for 'menuOrderId'
 *      diningStationIds: // value for 'diningStationIds'
 *   },
 * });
 */
export function useKdsTicketsMenuOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KdsTicketsMenuOrderQuery, KdsTicketsMenuOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<KdsTicketsMenuOrderQuery, KdsTicketsMenuOrderQueryVariables>(KdsTicketsMenuOrderDocument, baseOptions);
      }
export function useKdsTicketsMenuOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KdsTicketsMenuOrderQuery, KdsTicketsMenuOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KdsTicketsMenuOrderQuery, KdsTicketsMenuOrderQueryVariables>(KdsTicketsMenuOrderDocument, baseOptions);
        }
export type KdsTicketsMenuOrderQueryHookResult = ReturnType<typeof useKdsTicketsMenuOrderQuery>;
export type KdsTicketsMenuOrderLazyQueryHookResult = ReturnType<typeof useKdsTicketsMenuOrderLazyQuery>;
export type KdsTicketsMenuOrderQueryResult = ApolloReactCommon.QueryResult<KdsTicketsMenuOrderQuery, KdsTicketsMenuOrderQueryVariables>;
export const KdsTicketsMealMenuPlateOrderDocument = gql`
    query KdsTicketsMealMenuPlateOrder($mealMenuPlateOrderId: ID!, $diningStationIds: [ID!]!) {
  mealMenuPlateOrder(id: $mealMenuPlateOrderId) {
    ...KdsTicketMealMenuPlateOrder
  }
}
    ${KdsTicketMealMenuPlateOrderFragmentDoc}`;
export type KdsTicketsMealMenuPlateOrderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KdsTicketsMealMenuPlateOrderQuery, KdsTicketsMealMenuPlateOrderQueryVariables>, 'query'> & ({ variables: KdsTicketsMealMenuPlateOrderQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KdsTicketsMealMenuPlateOrderComponent = (props: KdsTicketsMealMenuPlateOrderComponentProps) => (
      <ApolloReactComponents.Query<KdsTicketsMealMenuPlateOrderQuery, KdsTicketsMealMenuPlateOrderQueryVariables> query={KdsTicketsMealMenuPlateOrderDocument} {...props} />
    );
    

/**
 * __useKdsTicketsMealMenuPlateOrderQuery__
 *
 * To run a query within a React component, call `useKdsTicketsMealMenuPlateOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsTicketsMealMenuPlateOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsTicketsMealMenuPlateOrderQuery({
 *   variables: {
 *      mealMenuPlateOrderId: // value for 'mealMenuPlateOrderId'
 *      diningStationIds: // value for 'diningStationIds'
 *   },
 * });
 */
export function useKdsTicketsMealMenuPlateOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KdsTicketsMealMenuPlateOrderQuery, KdsTicketsMealMenuPlateOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<KdsTicketsMealMenuPlateOrderQuery, KdsTicketsMealMenuPlateOrderQueryVariables>(KdsTicketsMealMenuPlateOrderDocument, baseOptions);
      }
export function useKdsTicketsMealMenuPlateOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KdsTicketsMealMenuPlateOrderQuery, KdsTicketsMealMenuPlateOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KdsTicketsMealMenuPlateOrderQuery, KdsTicketsMealMenuPlateOrderQueryVariables>(KdsTicketsMealMenuPlateOrderDocument, baseOptions);
        }
export type KdsTicketsMealMenuPlateOrderQueryHookResult = ReturnType<typeof useKdsTicketsMealMenuPlateOrderQuery>;
export type KdsTicketsMealMenuPlateOrderLazyQueryHookResult = ReturnType<typeof useKdsTicketsMealMenuPlateOrderLazyQuery>;
export type KdsTicketsMealMenuPlateOrderQueryResult = ApolloReactCommon.QueryResult<KdsTicketsMealMenuPlateOrderQuery, KdsTicketsMealMenuPlateOrderQueryVariables>;
export const KdsTicketsMultipleMenuOrdersAndPlateOrdersDocument = gql`
    query KdsTicketsMultipleMenuOrdersAndPlateOrders($menuOrderIds: [ID!]!, $mealMenuPlateOrderIds: [ID!]!, $diningStationIds: [ID!]!) {
  menuOrders(ids: $menuOrderIds) {
    ...KdsTicketMenuOrder
  }
  mealMenuPlateOrders(ids: $mealMenuPlateOrderIds) {
    ...KdsTicketMealMenuPlateOrder
  }
}
    ${KdsTicketMenuOrderFragmentDoc}
${KdsTicketMealMenuPlateOrderFragmentDoc}`;
export type KdsTicketsMultipleMenuOrdersAndPlateOrdersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery, KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables>, 'query'> & ({ variables: KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KdsTicketsMultipleMenuOrdersAndPlateOrdersComponent = (props: KdsTicketsMultipleMenuOrdersAndPlateOrdersComponentProps) => (
      <ApolloReactComponents.Query<KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery, KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables> query={KdsTicketsMultipleMenuOrdersAndPlateOrdersDocument} {...props} />
    );
    

/**
 * __useKdsTicketsMultipleMenuOrdersAndPlateOrdersQuery__
 *
 * To run a query within a React component, call `useKdsTicketsMultipleMenuOrdersAndPlateOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsTicketsMultipleMenuOrdersAndPlateOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsTicketsMultipleMenuOrdersAndPlateOrdersQuery({
 *   variables: {
 *      menuOrderIds: // value for 'menuOrderIds'
 *      mealMenuPlateOrderIds: // value for 'mealMenuPlateOrderIds'
 *      diningStationIds: // value for 'diningStationIds'
 *   },
 * });
 */
export function useKdsTicketsMultipleMenuOrdersAndPlateOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery, KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery, KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables>(KdsTicketsMultipleMenuOrdersAndPlateOrdersDocument, baseOptions);
      }
export function useKdsTicketsMultipleMenuOrdersAndPlateOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery, KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery, KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables>(KdsTicketsMultipleMenuOrdersAndPlateOrdersDocument, baseOptions);
        }
export type KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryHookResult = ReturnType<typeof useKdsTicketsMultipleMenuOrdersAndPlateOrdersQuery>;
export type KdsTicketsMultipleMenuOrdersAndPlateOrdersLazyQueryHookResult = ReturnType<typeof useKdsTicketsMultipleMenuOrdersAndPlateOrdersLazyQuery>;
export type KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryResult = ApolloReactCommon.QueryResult<KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery, KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables>;
export const KdsAnalyzeDialogMealMenusDocument = gql`
    query KdsAnalyzeDialogMealMenus($mealMenuIds: [ID!]!, $diningStationIds: [ID!]!) {
  mealMenus(mealMenuIds: $mealMenuIds) {
    allOrders {
      ...KdsAnalyzeDialogOrder
    }
    allMealMenuPlateOrders {
      ...KdsAnalyzeDialogPlateOrder
    }
  }
}
    ${KdsAnalyzeDialogOrderFragmentDoc}
${KdsAnalyzeDialogPlateOrderFragmentDoc}`;
export type KdsAnalyzeDialogMealMenusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KdsAnalyzeDialogMealMenusQuery, KdsAnalyzeDialogMealMenusQueryVariables>, 'query'> & ({ variables: KdsAnalyzeDialogMealMenusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KdsAnalyzeDialogMealMenusComponent = (props: KdsAnalyzeDialogMealMenusComponentProps) => (
      <ApolloReactComponents.Query<KdsAnalyzeDialogMealMenusQuery, KdsAnalyzeDialogMealMenusQueryVariables> query={KdsAnalyzeDialogMealMenusDocument} {...props} />
    );
    

/**
 * __useKdsAnalyzeDialogMealMenusQuery__
 *
 * To run a query within a React component, call `useKdsAnalyzeDialogMealMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useKdsAnalyzeDialogMealMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKdsAnalyzeDialogMealMenusQuery({
 *   variables: {
 *      mealMenuIds: // value for 'mealMenuIds'
 *      diningStationIds: // value for 'diningStationIds'
 *   },
 * });
 */
export function useKdsAnalyzeDialogMealMenusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KdsAnalyzeDialogMealMenusQuery, KdsAnalyzeDialogMealMenusQueryVariables>) {
        return ApolloReactHooks.useQuery<KdsAnalyzeDialogMealMenusQuery, KdsAnalyzeDialogMealMenusQueryVariables>(KdsAnalyzeDialogMealMenusDocument, baseOptions);
      }
export function useKdsAnalyzeDialogMealMenusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KdsAnalyzeDialogMealMenusQuery, KdsAnalyzeDialogMealMenusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KdsAnalyzeDialogMealMenusQuery, KdsAnalyzeDialogMealMenusQueryVariables>(KdsAnalyzeDialogMealMenusDocument, baseOptions);
        }
export type KdsAnalyzeDialogMealMenusQueryHookResult = ReturnType<typeof useKdsAnalyzeDialogMealMenusQuery>;
export type KdsAnalyzeDialogMealMenusLazyQueryHookResult = ReturnType<typeof useKdsAnalyzeDialogMealMenusLazyQuery>;
export type KdsAnalyzeDialogMealMenusQueryResult = ApolloReactCommon.QueryResult<KdsAnalyzeDialogMealMenusQuery, KdsAnalyzeDialogMealMenusQueryVariables>;
export const UpdateMenuOrderItemStatusesDocument = gql`
    mutation UpdateMenuOrderItemStatuses($input: UpdateMenuOrderItemStatusesInput!) {
  updateMenuOrderItemStatuses(input: $input) {
    menuOrderItems {
      id
      status
    }
  }
}
    `;
export type UpdateMenuOrderItemStatusesMutationFn = ApolloReactCommon.MutationFunction<UpdateMenuOrderItemStatusesMutation, UpdateMenuOrderItemStatusesMutationVariables>;
export type UpdateMenuOrderItemStatusesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMenuOrderItemStatusesMutation, UpdateMenuOrderItemStatusesMutationVariables>, 'mutation'>;

    export const UpdateMenuOrderItemStatusesComponent = (props: UpdateMenuOrderItemStatusesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMenuOrderItemStatusesMutation, UpdateMenuOrderItemStatusesMutationVariables> mutation={UpdateMenuOrderItemStatusesDocument} {...props} />
    );
    

/**
 * __useUpdateMenuOrderItemStatusesMutation__
 *
 * To run a mutation, you first call `useUpdateMenuOrderItemStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuOrderItemStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuOrderItemStatusesMutation, { data, loading, error }] = useUpdateMenuOrderItemStatusesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMenuOrderItemStatusesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMenuOrderItemStatusesMutation, UpdateMenuOrderItemStatusesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMenuOrderItemStatusesMutation, UpdateMenuOrderItemStatusesMutationVariables>(UpdateMenuOrderItemStatusesDocument, baseOptions);
      }
export type UpdateMenuOrderItemStatusesMutationHookResult = ReturnType<typeof useUpdateMenuOrderItemStatusesMutation>;
export type UpdateMenuOrderItemStatusesMutationResult = ApolloReactCommon.MutationResult<UpdateMenuOrderItemStatusesMutation>;
export type UpdateMenuOrderItemStatusesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMenuOrderItemStatusesMutation, UpdateMenuOrderItemStatusesMutationVariables>;
export const UpdateMultipleMenuOrderItemStatusesDocument = gql`
    mutation UpdateMultipleMenuOrderItemStatuses($input: UpdateMultipleMenuOrderItemStatusesInput!) {
  updateMultipleMenuOrderItemStatuses(input: $input) {
    menuOrderItems {
      id
      status
    }
  }
}
    `;
export type UpdateMultipleMenuOrderItemStatusesMutationFn = ApolloReactCommon.MutationFunction<UpdateMultipleMenuOrderItemStatusesMutation, UpdateMultipleMenuOrderItemStatusesMutationVariables>;
export type UpdateMultipleMenuOrderItemStatusesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMultipleMenuOrderItemStatusesMutation, UpdateMultipleMenuOrderItemStatusesMutationVariables>, 'mutation'>;

    export const UpdateMultipleMenuOrderItemStatusesComponent = (props: UpdateMultipleMenuOrderItemStatusesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMultipleMenuOrderItemStatusesMutation, UpdateMultipleMenuOrderItemStatusesMutationVariables> mutation={UpdateMultipleMenuOrderItemStatusesDocument} {...props} />
    );
    

/**
 * __useUpdateMultipleMenuOrderItemStatusesMutation__
 *
 * To run a mutation, you first call `useUpdateMultipleMenuOrderItemStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultipleMenuOrderItemStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultipleMenuOrderItemStatusesMutation, { data, loading, error }] = useUpdateMultipleMenuOrderItemStatusesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMultipleMenuOrderItemStatusesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMultipleMenuOrderItemStatusesMutation, UpdateMultipleMenuOrderItemStatusesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMultipleMenuOrderItemStatusesMutation, UpdateMultipleMenuOrderItemStatusesMutationVariables>(UpdateMultipleMenuOrderItemStatusesDocument, baseOptions);
      }
export type UpdateMultipleMenuOrderItemStatusesMutationHookResult = ReturnType<typeof useUpdateMultipleMenuOrderItemStatusesMutation>;
export type UpdateMultipleMenuOrderItemStatusesMutationResult = ApolloReactCommon.MutationResult<UpdateMultipleMenuOrderItemStatusesMutation>;
export type UpdateMultipleMenuOrderItemStatusesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMultipleMenuOrderItemStatusesMutation, UpdateMultipleMenuOrderItemStatusesMutationVariables>;
export const UpdateMultipleMealMenuPlateItemOrderStatusesDocument = gql`
    mutation UpdateMultipleMealMenuPlateItemOrderStatuses($input: UpdateMultipleMealMenuPlateItemOrderStatusesInput!) {
  updateMultipleMealMenuPlateItemOrderStatuses(input: $input) {
    mealMenuPlateItemOrderStatuses {
      status
      mealMenuPlateOrder {
        id
      }
      mealMenuPlateItem {
        id
      }
    }
  }
}
    `;
export type UpdateMultipleMealMenuPlateItemOrderStatusesMutationFn = ApolloReactCommon.MutationFunction<UpdateMultipleMealMenuPlateItemOrderStatusesMutation, UpdateMultipleMealMenuPlateItemOrderStatusesMutationVariables>;
export type UpdateMultipleMealMenuPlateItemOrderStatusesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMultipleMealMenuPlateItemOrderStatusesMutation, UpdateMultipleMealMenuPlateItemOrderStatusesMutationVariables>, 'mutation'>;

    export const UpdateMultipleMealMenuPlateItemOrderStatusesComponent = (props: UpdateMultipleMealMenuPlateItemOrderStatusesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMultipleMealMenuPlateItemOrderStatusesMutation, UpdateMultipleMealMenuPlateItemOrderStatusesMutationVariables> mutation={UpdateMultipleMealMenuPlateItemOrderStatusesDocument} {...props} />
    );
    

/**
 * __useUpdateMultipleMealMenuPlateItemOrderStatusesMutation__
 *
 * To run a mutation, you first call `useUpdateMultipleMealMenuPlateItemOrderStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultipleMealMenuPlateItemOrderStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultipleMealMenuPlateItemOrderStatusesMutation, { data, loading, error }] = useUpdateMultipleMealMenuPlateItemOrderStatusesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMultipleMealMenuPlateItemOrderStatusesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMultipleMealMenuPlateItemOrderStatusesMutation, UpdateMultipleMealMenuPlateItemOrderStatusesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMultipleMealMenuPlateItemOrderStatusesMutation, UpdateMultipleMealMenuPlateItemOrderStatusesMutationVariables>(UpdateMultipleMealMenuPlateItemOrderStatusesDocument, baseOptions);
      }
export type UpdateMultipleMealMenuPlateItemOrderStatusesMutationHookResult = ReturnType<typeof useUpdateMultipleMealMenuPlateItemOrderStatusesMutation>;
export type UpdateMultipleMealMenuPlateItemOrderStatusesMutationResult = ApolloReactCommon.MutationResult<UpdateMultipleMealMenuPlateItemOrderStatusesMutation>;
export type UpdateMultipleMealMenuPlateItemOrderStatusesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMultipleMealMenuPlateItemOrderStatusesMutation, UpdateMultipleMealMenuPlateItemOrderStatusesMutationVariables>;
export const PublishedMenuOrderDocument = gql`
    subscription PublishedMenuOrder($scope: MenuOrderSubscriberScope, $diningStationIds: [ID!]!) {
  menuOrder(scope: $scope) {
    payload {
      __typename
      ... on AddMenuOrderPayload {
        menuOrder {
          __typename
          ...KdsTicketMenuOrder
        }
      }
      ... on EditMenuOrderPayload {
        menuOrder {
          __typename
          ...KdsTicketMenuOrder
        }
      }
      ... on RemoveMenuOrderPayload {
        menuOrderId
      }
    }
    scope {
      ...MenuOrderPublisherScope
    }
  }
}
    ${KdsTicketMenuOrderFragmentDoc}
${MenuOrderPublisherScopeFragmentDoc}`;
export type PublishedMenuOrderComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PublishedMenuOrderSubscription, PublishedMenuOrderSubscriptionVariables>, 'subscription'>;

    export const PublishedMenuOrderComponent = (props: PublishedMenuOrderComponentProps) => (
      <ApolloReactComponents.Subscription<PublishedMenuOrderSubscription, PublishedMenuOrderSubscriptionVariables> subscription={PublishedMenuOrderDocument} {...props} />
    );
    

/**
 * __usePublishedMenuOrderSubscription__
 *
 * To run a query within a React component, call `usePublishedMenuOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMenuOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMenuOrderSubscription({
 *   variables: {
 *      scope: // value for 'scope'
 *      diningStationIds: // value for 'diningStationIds'
 *   },
 * });
 */
export function usePublishedMenuOrderSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PublishedMenuOrderSubscription, PublishedMenuOrderSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<PublishedMenuOrderSubscription, PublishedMenuOrderSubscriptionVariables>(PublishedMenuOrderDocument, baseOptions);
      }
export type PublishedMenuOrderSubscriptionHookResult = ReturnType<typeof usePublishedMenuOrderSubscription>;
export type PublishedMenuOrderSubscriptionResult = ApolloReactCommon.SubscriptionResult<PublishedMenuOrderSubscription>;
export const PublishedBulkOrderDocument = gql`
    subscription PublishedBulkOrder($scope: BulkOrderSubscriberScope, $diningStationIds: [ID!]!) {
  bulkOrder(scope: $scope) {
    payload {
      __typename
      ... on AddBulkOrderPayload {
        bulkOrder {
          id
          code
          __typename
          mealMenuPlateOrders {
            ...KdsTicketMealMenuPlateOrder
          }
        }
      }
      ... on EditBulkOrderPayload {
        bulkOrder {
          id
          code
          __typename
        }
        addedMealMenuPlateOrders {
          ...KdsTicketMealMenuPlateOrder
        }
        editedMealMenuPlateOrders {
          ...KdsTicketMealMenuPlateOrder
        }
        removedMealMenuPlateOrderIds
      }
      ... on RemoveBulkOrderPayload {
        bulkOrderId
        affectedMealMenuPlateOrderIds
      }
    }
    scope {
      ...BulkOrderPublisherScope
    }
  }
}
    ${KdsTicketMealMenuPlateOrderFragmentDoc}
${BulkOrderPublisherScopeFragmentDoc}`;
export type PublishedBulkOrderComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PublishedBulkOrderSubscription, PublishedBulkOrderSubscriptionVariables>, 'subscription'>;

    export const PublishedBulkOrderComponent = (props: PublishedBulkOrderComponentProps) => (
      <ApolloReactComponents.Subscription<PublishedBulkOrderSubscription, PublishedBulkOrderSubscriptionVariables> subscription={PublishedBulkOrderDocument} {...props} />
    );
    

/**
 * __usePublishedBulkOrderSubscription__
 *
 * To run a query within a React component, call `usePublishedBulkOrderSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishedBulkOrderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedBulkOrderSubscription({
 *   variables: {
 *      scope: // value for 'scope'
 *      diningStationIds: // value for 'diningStationIds'
 *   },
 * });
 */
export function usePublishedBulkOrderSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PublishedBulkOrderSubscription, PublishedBulkOrderSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<PublishedBulkOrderSubscription, PublishedBulkOrderSubscriptionVariables>(PublishedBulkOrderDocument, baseOptions);
      }
export type PublishedBulkOrderSubscriptionHookResult = ReturnType<typeof usePublishedBulkOrderSubscription>;
export type PublishedBulkOrderSubscriptionResult = ApolloReactCommon.SubscriptionResult<PublishedBulkOrderSubscription>;
export const PublishedMenuOrderItemStatusesDocument = gql`
    subscription PublishedMenuOrderItemStatuses($scope: MenuOrderItemStatusesSubscriberScope) {
  menuOrderItemStatuses(scope: $scope) {
    payload {
      __typename
      menuOrderItems {
        __typename
        id
        status
      }
    }
    scope {
      ...MenuOrderItemStatusesPublisherScope
    }
  }
}
    ${MenuOrderItemStatusesPublisherScopeFragmentDoc}`;
export type PublishedMenuOrderItemStatusesComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PublishedMenuOrderItemStatusesSubscription, PublishedMenuOrderItemStatusesSubscriptionVariables>, 'subscription'>;

    export const PublishedMenuOrderItemStatusesComponent = (props: PublishedMenuOrderItemStatusesComponentProps) => (
      <ApolloReactComponents.Subscription<PublishedMenuOrderItemStatusesSubscription, PublishedMenuOrderItemStatusesSubscriptionVariables> subscription={PublishedMenuOrderItemStatusesDocument} {...props} />
    );
    

/**
 * __usePublishedMenuOrderItemStatusesSubscription__
 *
 * To run a query within a React component, call `usePublishedMenuOrderItemStatusesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMenuOrderItemStatusesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMenuOrderItemStatusesSubscription({
 *   variables: {
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function usePublishedMenuOrderItemStatusesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PublishedMenuOrderItemStatusesSubscription, PublishedMenuOrderItemStatusesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<PublishedMenuOrderItemStatusesSubscription, PublishedMenuOrderItemStatusesSubscriptionVariables>(PublishedMenuOrderItemStatusesDocument, baseOptions);
      }
export type PublishedMenuOrderItemStatusesSubscriptionHookResult = ReturnType<typeof usePublishedMenuOrderItemStatusesSubscription>;
export type PublishedMenuOrderItemStatusesSubscriptionResult = ApolloReactCommon.SubscriptionResult<PublishedMenuOrderItemStatusesSubscription>;
export const PublishedMealMenuPlateOrderItemStatusesDocument = gql`
    subscription PublishedMealMenuPlateOrderItemStatuses($scope: MealMenuPlateItemOrderStatusSubscriberScope, $diningStationIds: [ID!]!) {
  mealMenuPlateItemOrderStatuses(scope: $scope) {
    payload {
      __typename
      mealMenuPlateItemOrderStatuses {
        mealMenuPlateOrder {
          ...KdsTicketMealMenuPlateOrder
        }
        mealMenuPlateItem {
          id
        }
        status
      }
    }
    scope {
      ...MealMenuPlateItemOrderStatusPublisherScope
    }
  }
}
    ${KdsTicketMealMenuPlateOrderFragmentDoc}
${MealMenuPlateItemOrderStatusPublisherScopeFragmentDoc}`;
export type PublishedMealMenuPlateOrderItemStatusesComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PublishedMealMenuPlateOrderItemStatusesSubscription, PublishedMealMenuPlateOrderItemStatusesSubscriptionVariables>, 'subscription'>;

    export const PublishedMealMenuPlateOrderItemStatusesComponent = (props: PublishedMealMenuPlateOrderItemStatusesComponentProps) => (
      <ApolloReactComponents.Subscription<PublishedMealMenuPlateOrderItemStatusesSubscription, PublishedMealMenuPlateOrderItemStatusesSubscriptionVariables> subscription={PublishedMealMenuPlateOrderItemStatusesDocument} {...props} />
    );
    

/**
 * __usePublishedMealMenuPlateOrderItemStatusesSubscription__
 *
 * To run a query within a React component, call `usePublishedMealMenuPlateOrderItemStatusesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePublishedMealMenuPlateOrderItemStatusesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedMealMenuPlateOrderItemStatusesSubscription({
 *   variables: {
 *      scope: // value for 'scope'
 *      diningStationIds: // value for 'diningStationIds'
 *   },
 * });
 */
export function usePublishedMealMenuPlateOrderItemStatusesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PublishedMealMenuPlateOrderItemStatusesSubscription, PublishedMealMenuPlateOrderItemStatusesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<PublishedMealMenuPlateOrderItemStatusesSubscription, PublishedMealMenuPlateOrderItemStatusesSubscriptionVariables>(PublishedMealMenuPlateOrderItemStatusesDocument, baseOptions);
      }
export type PublishedMealMenuPlateOrderItemStatusesSubscriptionHookResult = ReturnType<typeof usePublishedMealMenuPlateOrderItemStatusesSubscription>;
export type PublishedMealMenuPlateOrderItemStatusesSubscriptionResult = ApolloReactCommon.SubscriptionResult<PublishedMealMenuPlateOrderItemStatusesSubscription>;
export const AddMenuOrderKioskDocument = gql`
    mutation AddMenuOrderKiosk($input: AddMenuOrderForAthleteInput!) {
  addMenuOrderForAthlete(input: $input) {
    menuOrder {
      ...MenuOrderForm
      timelineMeal {
        ...TimelineMeal
      }
    }
  }
}
    ${MenuOrderFormFragmentDoc}
${TimelineMealFragmentDoc}`;
export type AddMenuOrderKioskMutationFn = ApolloReactCommon.MutationFunction<AddMenuOrderKioskMutation, AddMenuOrderKioskMutationVariables>;
export type AddMenuOrderKioskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMenuOrderKioskMutation, AddMenuOrderKioskMutationVariables>, 'mutation'>;

    export const AddMenuOrderKioskComponent = (props: AddMenuOrderKioskComponentProps) => (
      <ApolloReactComponents.Mutation<AddMenuOrderKioskMutation, AddMenuOrderKioskMutationVariables> mutation={AddMenuOrderKioskDocument} {...props} />
    );
    

/**
 * __useAddMenuOrderKioskMutation__
 *
 * To run a mutation, you first call `useAddMenuOrderKioskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMenuOrderKioskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMenuOrderKioskMutation, { data, loading, error }] = useAddMenuOrderKioskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMenuOrderKioskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMenuOrderKioskMutation, AddMenuOrderKioskMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMenuOrderKioskMutation, AddMenuOrderKioskMutationVariables>(AddMenuOrderKioskDocument, baseOptions);
      }
export type AddMenuOrderKioskMutationHookResult = ReturnType<typeof useAddMenuOrderKioskMutation>;
export type AddMenuOrderKioskMutationResult = ApolloReactCommon.MutationResult<AddMenuOrderKioskMutation>;
export type AddMenuOrderKioskMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMenuOrderKioskMutation, AddMenuOrderKioskMutationVariables>;
export const EditMenuOrderDocument = gql`
    mutation EditMenuOrder($input: EditMenuOrderInput!) {
  editMenuOrder(input: $input) {
    menuOrder {
      ...MenuOrderForm
    }
  }
}
    ${MenuOrderFormFragmentDoc}`;
export type EditMenuOrderMutationFn = ApolloReactCommon.MutationFunction<EditMenuOrderMutation, EditMenuOrderMutationVariables>;
export type EditMenuOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMenuOrderMutation, EditMenuOrderMutationVariables>, 'mutation'>;

    export const EditMenuOrderComponent = (props: EditMenuOrderComponentProps) => (
      <ApolloReactComponents.Mutation<EditMenuOrderMutation, EditMenuOrderMutationVariables> mutation={EditMenuOrderDocument} {...props} />
    );
    

/**
 * __useEditMenuOrderMutation__
 *
 * To run a mutation, you first call `useEditMenuOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuOrderMutation, { data, loading, error }] = useEditMenuOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMenuOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMenuOrderMutation, EditMenuOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMenuOrderMutation, EditMenuOrderMutationVariables>(EditMenuOrderDocument, baseOptions);
      }
export type EditMenuOrderMutationHookResult = ReturnType<typeof useEditMenuOrderMutation>;
export type EditMenuOrderMutationResult = ApolloReactCommon.MutationResult<EditMenuOrderMutation>;
export type EditMenuOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMenuOrderMutation, EditMenuOrderMutationVariables>;
export const SetMealMenuCheckInsDocument = gql`
    mutation SetMealMenuCheckIns($input: SetMealMenuCheckInsInput!) {
  setMealMenuCheckInsForAthlete(input: $input) {
    addedMealMenuCheckIns {
      ...CheckIn
    }
    removedMealMenuCheckInIds
  }
}
    ${CheckInFragmentDoc}`;
export type SetMealMenuCheckInsMutationFn = ApolloReactCommon.MutationFunction<SetMealMenuCheckInsMutation, SetMealMenuCheckInsMutationVariables>;
export type SetMealMenuCheckInsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetMealMenuCheckInsMutation, SetMealMenuCheckInsMutationVariables>, 'mutation'>;

    export const SetMealMenuCheckInsComponent = (props: SetMealMenuCheckInsComponentProps) => (
      <ApolloReactComponents.Mutation<SetMealMenuCheckInsMutation, SetMealMenuCheckInsMutationVariables> mutation={SetMealMenuCheckInsDocument} {...props} />
    );
    

/**
 * __useSetMealMenuCheckInsMutation__
 *
 * To run a mutation, you first call `useSetMealMenuCheckInsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMealMenuCheckInsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMealMenuCheckInsMutation, { data, loading, error }] = useSetMealMenuCheckInsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMealMenuCheckInsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetMealMenuCheckInsMutation, SetMealMenuCheckInsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetMealMenuCheckInsMutation, SetMealMenuCheckInsMutationVariables>(SetMealMenuCheckInsDocument, baseOptions);
      }
export type SetMealMenuCheckInsMutationHookResult = ReturnType<typeof useSetMealMenuCheckInsMutation>;
export type SetMealMenuCheckInsMutationResult = ApolloReactCommon.MutationResult<SetMealMenuCheckInsMutation>;
export type SetMealMenuCheckInsMutationOptions = ApolloReactCommon.BaseMutationOptions<SetMealMenuCheckInsMutation, SetMealMenuCheckInsMutationVariables>;
export const KioskConfigurationDocument = gql`
    query KioskConfiguration($after: Date!, $before: Date!, $clientTimezone: Timezone!) {
  mealMenusInDateRange(after: $after, before: $before, timezone: $clientTimezone) {
    ...KioskMealMenuPreview
  }
  teams {
    ...KioskTeamPreview
  }
}
    ${KioskMealMenuPreviewFragmentDoc}
${KioskTeamPreviewFragmentDoc}`;
export type KioskConfigurationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KioskConfigurationQuery, KioskConfigurationQueryVariables>, 'query'> & ({ variables: KioskConfigurationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KioskConfigurationComponent = (props: KioskConfigurationComponentProps) => (
      <ApolloReactComponents.Query<KioskConfigurationQuery, KioskConfigurationQueryVariables> query={KioskConfigurationDocument} {...props} />
    );
    

/**
 * __useKioskConfigurationQuery__
 *
 * To run a query within a React component, call `useKioskConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useKioskConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKioskConfigurationQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      clientTimezone: // value for 'clientTimezone'
 *   },
 * });
 */
export function useKioskConfigurationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KioskConfigurationQuery, KioskConfigurationQueryVariables>) {
        return ApolloReactHooks.useQuery<KioskConfigurationQuery, KioskConfigurationQueryVariables>(KioskConfigurationDocument, baseOptions);
      }
export function useKioskConfigurationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KioskConfigurationQuery, KioskConfigurationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KioskConfigurationQuery, KioskConfigurationQueryVariables>(KioskConfigurationDocument, baseOptions);
        }
export type KioskConfigurationQueryHookResult = ReturnType<typeof useKioskConfigurationQuery>;
export type KioskConfigurationLazyQueryHookResult = ReturnType<typeof useKioskConfigurationLazyQuery>;
export type KioskConfigurationQueryResult = ApolloReactCommon.QueryResult<KioskConfigurationQuery, KioskConfigurationQueryVariables>;
export const MealMenuAdvancedSelectionSharingInfoDocument = gql`
    query MealMenuAdvancedSelectionSharingInfo($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    advancedSelection {
      ...AdvancedSelection
    }
  }
}
    ${AdvancedSelectionFragmentDoc}`;
export type MealMenuAdvancedSelectionSharingInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenuAdvancedSelectionSharingInfoQuery, MealMenuAdvancedSelectionSharingInfoQueryVariables>, 'query'> & ({ variables: MealMenuAdvancedSelectionSharingInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenuAdvancedSelectionSharingInfoComponent = (props: MealMenuAdvancedSelectionSharingInfoComponentProps) => (
      <ApolloReactComponents.Query<MealMenuAdvancedSelectionSharingInfoQuery, MealMenuAdvancedSelectionSharingInfoQueryVariables> query={MealMenuAdvancedSelectionSharingInfoDocument} {...props} />
    );
    

/**
 * __useMealMenuAdvancedSelectionSharingInfoQuery__
 *
 * To run a query within a React component, call `useMealMenuAdvancedSelectionSharingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuAdvancedSelectionSharingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuAdvancedSelectionSharingInfoQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMealMenuAdvancedSelectionSharingInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenuAdvancedSelectionSharingInfoQuery, MealMenuAdvancedSelectionSharingInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenuAdvancedSelectionSharingInfoQuery, MealMenuAdvancedSelectionSharingInfoQueryVariables>(MealMenuAdvancedSelectionSharingInfoDocument, baseOptions);
      }
export function useMealMenuAdvancedSelectionSharingInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenuAdvancedSelectionSharingInfoQuery, MealMenuAdvancedSelectionSharingInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenuAdvancedSelectionSharingInfoQuery, MealMenuAdvancedSelectionSharingInfoQueryVariables>(MealMenuAdvancedSelectionSharingInfoDocument, baseOptions);
        }
export type MealMenuAdvancedSelectionSharingInfoQueryHookResult = ReturnType<typeof useMealMenuAdvancedSelectionSharingInfoQuery>;
export type MealMenuAdvancedSelectionSharingInfoLazyQueryHookResult = ReturnType<typeof useMealMenuAdvancedSelectionSharingInfoLazyQuery>;
export type MealMenuAdvancedSelectionSharingInfoQueryResult = ApolloReactCommon.QueryResult<MealMenuAdvancedSelectionSharingInfoQuery, MealMenuAdvancedSelectionSharingInfoQueryVariables>;
export const OrderKioskAthletesDocument = gql`
    query OrderKioskAthletes($mealMenuIds: [ID!]!) {
  athletesForMealMenus(mealMenuIds: $mealMenuIds) {
    ...OrderKioskAthletePreview
  }
}
    ${OrderKioskAthletePreviewFragmentDoc}`;
export type OrderKioskAthletesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrderKioskAthletesQuery, OrderKioskAthletesQueryVariables>, 'query'> & ({ variables: OrderKioskAthletesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrderKioskAthletesComponent = (props: OrderKioskAthletesComponentProps) => (
      <ApolloReactComponents.Query<OrderKioskAthletesQuery, OrderKioskAthletesQueryVariables> query={OrderKioskAthletesDocument} {...props} />
    );
    

/**
 * __useOrderKioskAthletesQuery__
 *
 * To run a query within a React component, call `useOrderKioskAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderKioskAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderKioskAthletesQuery({
 *   variables: {
 *      mealMenuIds: // value for 'mealMenuIds'
 *   },
 * });
 */
export function useOrderKioskAthletesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderKioskAthletesQuery, OrderKioskAthletesQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderKioskAthletesQuery, OrderKioskAthletesQueryVariables>(OrderKioskAthletesDocument, baseOptions);
      }
export function useOrderKioskAthletesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderKioskAthletesQuery, OrderKioskAthletesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderKioskAthletesQuery, OrderKioskAthletesQueryVariables>(OrderKioskAthletesDocument, baseOptions);
        }
export type OrderKioskAthletesQueryHookResult = ReturnType<typeof useOrderKioskAthletesQuery>;
export type OrderKioskAthletesLazyQueryHookResult = ReturnType<typeof useOrderKioskAthletesLazyQuery>;
export type OrderKioskAthletesQueryResult = ApolloReactCommon.QueryResult<OrderKioskAthletesQuery, OrderKioskAthletesQueryVariables>;
export const CheckInKioskAthletesDocument = gql`
    query CheckInKioskAthletes($mealMenuIds: [ID!]!) {
  athletesForMealMenus(mealMenuIds: $mealMenuIds) {
    ...CheckInKioskAthletePreview
  }
}
    ${CheckInKioskAthletePreviewFragmentDoc}`;
export type CheckInKioskAthletesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables>, 'query'> & ({ variables: CheckInKioskAthletesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckInKioskAthletesComponent = (props: CheckInKioskAthletesComponentProps) => (
      <ApolloReactComponents.Query<CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables> query={CheckInKioskAthletesDocument} {...props} />
    );
    

/**
 * __useCheckInKioskAthletesQuery__
 *
 * To run a query within a React component, call `useCheckInKioskAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInKioskAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInKioskAthletesQuery({
 *   variables: {
 *      mealMenuIds: // value for 'mealMenuIds'
 *   },
 * });
 */
export function useCheckInKioskAthletesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables>(CheckInKioskAthletesDocument, baseOptions);
      }
export function useCheckInKioskAthletesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables>(CheckInKioskAthletesDocument, baseOptions);
        }
export type CheckInKioskAthletesQueryHookResult = ReturnType<typeof useCheckInKioskAthletesQuery>;
export type CheckInKioskAthletesLazyQueryHookResult = ReturnType<typeof useCheckInKioskAthletesLazyQuery>;
export type CheckInKioskAthletesQueryResult = ApolloReactCommon.QueryResult<CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables>;
export const KioskAthleteDocument = gql`
    query KioskAthlete($athleteId: ID!, $mealMenuId: ID!, $date: Date!, $timezone: Timezone!) {
  athlete(id: $athleteId) {
    ...AthleteForKioskAthlete
  }
}
    ${AthleteForKioskAthleteFragmentDoc}`;
export type KioskAthleteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KioskAthleteQuery, KioskAthleteQueryVariables>, 'query'> & ({ variables: KioskAthleteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KioskAthleteComponent = (props: KioskAthleteComponentProps) => (
      <ApolloReactComponents.Query<KioskAthleteQuery, KioskAthleteQueryVariables> query={KioskAthleteDocument} {...props} />
    );
    

/**
 * __useKioskAthleteQuery__
 *
 * To run a query within a React component, call `useKioskAthleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useKioskAthleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKioskAthleteQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *      mealMenuId: // value for 'mealMenuId'
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useKioskAthleteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KioskAthleteQuery, KioskAthleteQueryVariables>) {
        return ApolloReactHooks.useQuery<KioskAthleteQuery, KioskAthleteQueryVariables>(KioskAthleteDocument, baseOptions);
      }
export function useKioskAthleteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KioskAthleteQuery, KioskAthleteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KioskAthleteQuery, KioskAthleteQueryVariables>(KioskAthleteDocument, baseOptions);
        }
export type KioskAthleteQueryHookResult = ReturnType<typeof useKioskAthleteQuery>;
export type KioskAthleteLazyQueryHookResult = ReturnType<typeof useKioskAthleteLazyQuery>;
export type KioskAthleteQueryResult = ApolloReactCommon.QueryResult<KioskAthleteQuery, KioskAthleteQueryVariables>;
export const MealPlanTemplateDocument = gql`
    query MealPlanTemplate($id: ID!) {
  mealPlanTemplate(id: $id) {
    ...FullMealPlanTemplate
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type MealPlanTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanTemplateQuery, MealPlanTemplateQueryVariables>, 'query'> & ({ variables: MealPlanTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanTemplateComponent = (props: MealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Query<MealPlanTemplateQuery, MealPlanTemplateQueryVariables> query={MealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useMealPlanTemplateQuery__
 *
 * To run a query within a React component, call `useMealPlanTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMealPlanTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanTemplateQuery, MealPlanTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanTemplateQuery, MealPlanTemplateQueryVariables>(MealPlanTemplateDocument, baseOptions);
      }
export function useMealPlanTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanTemplateQuery, MealPlanTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanTemplateQuery, MealPlanTemplateQueryVariables>(MealPlanTemplateDocument, baseOptions);
        }
export type MealPlanTemplateQueryHookResult = ReturnType<typeof useMealPlanTemplateQuery>;
export type MealPlanTemplateLazyQueryHookResult = ReturnType<typeof useMealPlanTemplateLazyQuery>;
export type MealPlanTemplateQueryResult = ApolloReactCommon.QueryResult<MealPlanTemplateQuery, MealPlanTemplateQueryVariables>;
export const DietitianMealPlanTemplateOffsetConnectionDocument = gql`
    query DietitianMealPlanTemplateOffsetConnection($pagination: OffsetPaginationInput!, $searchText: String!, $sortAscending: Boolean!) {
  mealPlanTemplateOffsetConnection(
    pagination: $pagination
    searchText: $searchText
    sortAscending: $sortAscending
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...DietitianMealPlanTemplateListItem
    }
  }
}
    ${DietitianMealPlanTemplateListItemFragmentDoc}`;
export type DietitianMealPlanTemplateOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DietitianMealPlanTemplateOffsetConnectionQuery, DietitianMealPlanTemplateOffsetConnectionQueryVariables>, 'query'> & ({ variables: DietitianMealPlanTemplateOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DietitianMealPlanTemplateOffsetConnectionComponent = (props: DietitianMealPlanTemplateOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<DietitianMealPlanTemplateOffsetConnectionQuery, DietitianMealPlanTemplateOffsetConnectionQueryVariables> query={DietitianMealPlanTemplateOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useDietitianMealPlanTemplateOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useDietitianMealPlanTemplateOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDietitianMealPlanTemplateOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDietitianMealPlanTemplateOffsetConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *      sortAscending: // value for 'sortAscending'
 *   },
 * });
 */
export function useDietitianMealPlanTemplateOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DietitianMealPlanTemplateOffsetConnectionQuery, DietitianMealPlanTemplateOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<DietitianMealPlanTemplateOffsetConnectionQuery, DietitianMealPlanTemplateOffsetConnectionQueryVariables>(DietitianMealPlanTemplateOffsetConnectionDocument, baseOptions);
      }
export function useDietitianMealPlanTemplateOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DietitianMealPlanTemplateOffsetConnectionQuery, DietitianMealPlanTemplateOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DietitianMealPlanTemplateOffsetConnectionQuery, DietitianMealPlanTemplateOffsetConnectionQueryVariables>(DietitianMealPlanTemplateOffsetConnectionDocument, baseOptions);
        }
export type DietitianMealPlanTemplateOffsetConnectionQueryHookResult = ReturnType<typeof useDietitianMealPlanTemplateOffsetConnectionQuery>;
export type DietitianMealPlanTemplateOffsetConnectionLazyQueryHookResult = ReturnType<typeof useDietitianMealPlanTemplateOffsetConnectionLazyQuery>;
export type DietitianMealPlanTemplateOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<DietitianMealPlanTemplateOffsetConnectionQuery, DietitianMealPlanTemplateOffsetConnectionQueryVariables>;
export const DietitianOrgGroupMealPlanTemplateOffsetConnectionDocument = gql`
    query DietitianOrgGroupMealPlanTemplateOffsetConnection($pagination: OffsetPaginationInput!, $searchText: String!, $sortAscending: Boolean!, $ownerIds: [ID!]) {
  orgGroupMealPlanTemplateOffsetConnection(
    pagination: $pagination
    searchText: $searchText
    sortAscending: $sortAscending
    ownerIds: $ownerIds
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...DietitianOrgGroupMealPlanTemplateListItem
    }
  }
}
    ${DietitianOrgGroupMealPlanTemplateListItemFragmentDoc}`;
export type DietitianOrgGroupMealPlanTemplateOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DietitianOrgGroupMealPlanTemplateOffsetConnectionQuery, DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryVariables>, 'query'> & ({ variables: DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DietitianOrgGroupMealPlanTemplateOffsetConnectionComponent = (props: DietitianOrgGroupMealPlanTemplateOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<DietitianOrgGroupMealPlanTemplateOffsetConnectionQuery, DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryVariables> query={DietitianOrgGroupMealPlanTemplateOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useDietitianOrgGroupMealPlanTemplateOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useDietitianOrgGroupMealPlanTemplateOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDietitianOrgGroupMealPlanTemplateOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDietitianOrgGroupMealPlanTemplateOffsetConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *      sortAscending: // value for 'sortAscending'
 *      ownerIds: // value for 'ownerIds'
 *   },
 * });
 */
export function useDietitianOrgGroupMealPlanTemplateOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DietitianOrgGroupMealPlanTemplateOffsetConnectionQuery, DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<DietitianOrgGroupMealPlanTemplateOffsetConnectionQuery, DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryVariables>(DietitianOrgGroupMealPlanTemplateOffsetConnectionDocument, baseOptions);
      }
export function useDietitianOrgGroupMealPlanTemplateOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DietitianOrgGroupMealPlanTemplateOffsetConnectionQuery, DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DietitianOrgGroupMealPlanTemplateOffsetConnectionQuery, DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryVariables>(DietitianOrgGroupMealPlanTemplateOffsetConnectionDocument, baseOptions);
        }
export type DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryHookResult = ReturnType<typeof useDietitianOrgGroupMealPlanTemplateOffsetConnectionQuery>;
export type DietitianOrgGroupMealPlanTemplateOffsetConnectionLazyQueryHookResult = ReturnType<typeof useDietitianOrgGroupMealPlanTemplateOffsetConnectionLazyQuery>;
export type DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<DietitianOrgGroupMealPlanTemplateOffsetConnectionQuery, DietitianOrgGroupMealPlanTemplateOffsetConnectionQueryVariables>;
export const DietitianSharedMealPlanTemplateOffsetConnectionDocument = gql`
    query DietitianSharedMealPlanTemplateOffsetConnection($pagination: OffsetPaginationInput!, $searchText: String!, $sortAscending: Boolean!) {
  sharedMealPlanTemplateOffsetConnection(
    pagination: $pagination
    searchText: $searchText
    sortAscending: $sortAscending
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...DietitianStaffMealPlanTemplateListItem
    }
  }
}
    ${DietitianStaffMealPlanTemplateListItemFragmentDoc}`;
export type DietitianSharedMealPlanTemplateOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DietitianSharedMealPlanTemplateOffsetConnectionQuery, DietitianSharedMealPlanTemplateOffsetConnectionQueryVariables>, 'query'> & ({ variables: DietitianSharedMealPlanTemplateOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DietitianSharedMealPlanTemplateOffsetConnectionComponent = (props: DietitianSharedMealPlanTemplateOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<DietitianSharedMealPlanTemplateOffsetConnectionQuery, DietitianSharedMealPlanTemplateOffsetConnectionQueryVariables> query={DietitianSharedMealPlanTemplateOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useDietitianSharedMealPlanTemplateOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useDietitianSharedMealPlanTemplateOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDietitianSharedMealPlanTemplateOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDietitianSharedMealPlanTemplateOffsetConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *      sortAscending: // value for 'sortAscending'
 *   },
 * });
 */
export function useDietitianSharedMealPlanTemplateOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DietitianSharedMealPlanTemplateOffsetConnectionQuery, DietitianSharedMealPlanTemplateOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<DietitianSharedMealPlanTemplateOffsetConnectionQuery, DietitianSharedMealPlanTemplateOffsetConnectionQueryVariables>(DietitianSharedMealPlanTemplateOffsetConnectionDocument, baseOptions);
      }
export function useDietitianSharedMealPlanTemplateOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DietitianSharedMealPlanTemplateOffsetConnectionQuery, DietitianSharedMealPlanTemplateOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DietitianSharedMealPlanTemplateOffsetConnectionQuery, DietitianSharedMealPlanTemplateOffsetConnectionQueryVariables>(DietitianSharedMealPlanTemplateOffsetConnectionDocument, baseOptions);
        }
export type DietitianSharedMealPlanTemplateOffsetConnectionQueryHookResult = ReturnType<typeof useDietitianSharedMealPlanTemplateOffsetConnectionQuery>;
export type DietitianSharedMealPlanTemplateOffsetConnectionLazyQueryHookResult = ReturnType<typeof useDietitianSharedMealPlanTemplateOffsetConnectionLazyQuery>;
export type DietitianSharedMealPlanTemplateOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<DietitianSharedMealPlanTemplateOffsetConnectionQuery, DietitianSharedMealPlanTemplateOffsetConnectionQueryVariables>;
export const OrgGroupMealPlanTemplateOwnerCursorConnectionDocument = gql`
    query OrgGroupMealPlanTemplateOwnerCursorConnection($pagination: CursorPaginationInput!, $searchText: String!) {
  orgGroupMealPlanTemplateOwnerCursorConnection(
    pagination: $pagination
    searchText: $searchText
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...MealPlanTemplateOwnerPreview
    }
  }
}
    ${MealPlanTemplateOwnerPreviewFragmentDoc}`;
export type OrgGroupMealPlanTemplateOwnerCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgGroupMealPlanTemplateOwnerCursorConnectionQuery, OrgGroupMealPlanTemplateOwnerCursorConnectionQueryVariables>, 'query'> & ({ variables: OrgGroupMealPlanTemplateOwnerCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgGroupMealPlanTemplateOwnerCursorConnectionComponent = (props: OrgGroupMealPlanTemplateOwnerCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<OrgGroupMealPlanTemplateOwnerCursorConnectionQuery, OrgGroupMealPlanTemplateOwnerCursorConnectionQueryVariables> query={OrgGroupMealPlanTemplateOwnerCursorConnectionDocument} {...props} />
    );
    

/**
 * __useOrgGroupMealPlanTemplateOwnerCursorConnectionQuery__
 *
 * To run a query within a React component, call `useOrgGroupMealPlanTemplateOwnerCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgGroupMealPlanTemplateOwnerCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgGroupMealPlanTemplateOwnerCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useOrgGroupMealPlanTemplateOwnerCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgGroupMealPlanTemplateOwnerCursorConnectionQuery, OrgGroupMealPlanTemplateOwnerCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgGroupMealPlanTemplateOwnerCursorConnectionQuery, OrgGroupMealPlanTemplateOwnerCursorConnectionQueryVariables>(OrgGroupMealPlanTemplateOwnerCursorConnectionDocument, baseOptions);
      }
export function useOrgGroupMealPlanTemplateOwnerCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgGroupMealPlanTemplateOwnerCursorConnectionQuery, OrgGroupMealPlanTemplateOwnerCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgGroupMealPlanTemplateOwnerCursorConnectionQuery, OrgGroupMealPlanTemplateOwnerCursorConnectionQueryVariables>(OrgGroupMealPlanTemplateOwnerCursorConnectionDocument, baseOptions);
        }
export type OrgGroupMealPlanTemplateOwnerCursorConnectionQueryHookResult = ReturnType<typeof useOrgGroupMealPlanTemplateOwnerCursorConnectionQuery>;
export type OrgGroupMealPlanTemplateOwnerCursorConnectionLazyQueryHookResult = ReturnType<typeof useOrgGroupMealPlanTemplateOwnerCursorConnectionLazyQuery>;
export type OrgGroupMealPlanTemplateOwnerCursorConnectionQueryResult = ApolloReactCommon.QueryResult<OrgGroupMealPlanTemplateOwnerCursorConnectionQuery, OrgGroupMealPlanTemplateOwnerCursorConnectionQueryVariables>;
export const CreateMacroMealPlanTemplateDocument = gql`
    mutation CreateMacroMealPlanTemplate($input: CreateMacroMealPlanTemplateInput!) {
  createMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...FullMealPlanTemplate
    }
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type CreateMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateMacroMealPlanTemplateMutation, CreateMacroMealPlanTemplateMutationVariables>;
export type CreateMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMacroMealPlanTemplateMutation, CreateMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const CreateMacroMealPlanTemplateComponent = (props: CreateMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMacroMealPlanTemplateMutation, CreateMacroMealPlanTemplateMutationVariables> mutation={CreateMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useCreateMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMacroMealPlanTemplateMutation, { data, loading, error }] = useCreateMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMacroMealPlanTemplateMutation, CreateMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMacroMealPlanTemplateMutation, CreateMacroMealPlanTemplateMutationVariables>(CreateMacroMealPlanTemplateDocument, baseOptions);
      }
export type CreateMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useCreateMacroMealPlanTemplateMutation>;
export type CreateMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<CreateMacroMealPlanTemplateMutation>;
export type CreateMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMacroMealPlanTemplateMutation, CreateMacroMealPlanTemplateMutationVariables>;
export const EditMacroMealPlanTemplateDocument = gql`
    mutation EditMacroMealPlanTemplate($input: MacroMealPlanTemplatePendingStateInput!) {
  editMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...FullMealPlanTemplate
    }
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type EditMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<EditMacroMealPlanTemplateMutation, EditMacroMealPlanTemplateMutationVariables>;
export type EditMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMacroMealPlanTemplateMutation, EditMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const EditMacroMealPlanTemplateComponent = (props: EditMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<EditMacroMealPlanTemplateMutation, EditMacroMealPlanTemplateMutationVariables> mutation={EditMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useEditMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useEditMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroMealPlanTemplateMutation, { data, loading, error }] = useEditMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroMealPlanTemplateMutation, EditMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroMealPlanTemplateMutation, EditMacroMealPlanTemplateMutationVariables>(EditMacroMealPlanTemplateDocument, baseOptions);
      }
export type EditMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useEditMacroMealPlanTemplateMutation>;
export type EditMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<EditMacroMealPlanTemplateMutation>;
export type EditMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroMealPlanTemplateMutation, EditMacroMealPlanTemplateMutationVariables>;
export const PublishMacroMealPlanTemplateDocument = gql`
    mutation PublishMacroMealPlanTemplate($input: PublishMacroMealPlanTemplateInput!) {
  publishMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...FullMealPlanTemplate
    }
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type PublishMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<PublishMacroMealPlanTemplateMutation, PublishMacroMealPlanTemplateMutationVariables>;
export type PublishMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PublishMacroMealPlanTemplateMutation, PublishMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const PublishMacroMealPlanTemplateComponent = (props: PublishMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<PublishMacroMealPlanTemplateMutation, PublishMacroMealPlanTemplateMutationVariables> mutation={PublishMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __usePublishMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `usePublishMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMacroMealPlanTemplateMutation, { data, loading, error }] = usePublishMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishMacroMealPlanTemplateMutation, PublishMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishMacroMealPlanTemplateMutation, PublishMacroMealPlanTemplateMutationVariables>(PublishMacroMealPlanTemplateDocument, baseOptions);
      }
export type PublishMacroMealPlanTemplateMutationHookResult = ReturnType<typeof usePublishMacroMealPlanTemplateMutation>;
export type PublishMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<PublishMacroMealPlanTemplateMutation>;
export type PublishMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishMacroMealPlanTemplateMutation, PublishMacroMealPlanTemplateMutationVariables>;
export const CopyMacroMealPlanTemplateDocument = gql`
    mutation CopyMacroMealPlanTemplate($input: CopyMacroMealPlanTemplateInput!) {
  copyMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...DietitianMealPlanTemplateListItem
    }
  }
}
    ${DietitianMealPlanTemplateListItemFragmentDoc}`;
export type CopyMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<CopyMacroMealPlanTemplateMutation, CopyMacroMealPlanTemplateMutationVariables>;
export type CopyMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyMacroMealPlanTemplateMutation, CopyMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const CopyMacroMealPlanTemplateComponent = (props: CopyMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CopyMacroMealPlanTemplateMutation, CopyMacroMealPlanTemplateMutationVariables> mutation={CopyMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useCopyMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useCopyMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyMacroMealPlanTemplateMutation, { data, loading, error }] = useCopyMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyMacroMealPlanTemplateMutation, CopyMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyMacroMealPlanTemplateMutation, CopyMacroMealPlanTemplateMutationVariables>(CopyMacroMealPlanTemplateDocument, baseOptions);
      }
export type CopyMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useCopyMacroMealPlanTemplateMutation>;
export type CopyMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<CopyMacroMealPlanTemplateMutation>;
export type CopyMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyMacroMealPlanTemplateMutation, CopyMacroMealPlanTemplateMutationVariables>;
export const DeleteMacroMealPlanTemplateDocument = gql`
    mutation DeleteMacroMealPlanTemplate($input: DeleteMacroMealPlanTemplateInput!) {
  deleteMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplateId
  }
}
    `;
export type DeleteMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteMacroMealPlanTemplateMutation, DeleteMacroMealPlanTemplateMutationVariables>;
export type DeleteMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteMacroMealPlanTemplateMutation, DeleteMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const DeleteMacroMealPlanTemplateComponent = (props: DeleteMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteMacroMealPlanTemplateMutation, DeleteMacroMealPlanTemplateMutationVariables> mutation={DeleteMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useDeleteMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMacroMealPlanTemplateMutation, { data, loading, error }] = useDeleteMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMacroMealPlanTemplateMutation, DeleteMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMacroMealPlanTemplateMutation, DeleteMacroMealPlanTemplateMutationVariables>(DeleteMacroMealPlanTemplateDocument, baseOptions);
      }
export type DeleteMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useDeleteMacroMealPlanTemplateMutation>;
export type DeleteMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteMacroMealPlanTemplateMutation>;
export type DeleteMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMacroMealPlanTemplateMutation, DeleteMacroMealPlanTemplateMutationVariables>;
export const DeleteMacroMealPlanTemplatePendingStateDocument = gql`
    mutation DeleteMacroMealPlanTemplatePendingState($input: DeleteMacroMealPlanTemplatePendingStateInput!) {
  deleteMacroMealPlanTemplatePendingState(input: $input) {
    macroMealPlanTemplate {
      ...DietitianMealPlanTemplatePendingState
    }
  }
}
    ${DietitianMealPlanTemplatePendingStateFragmentDoc}`;
export type DeleteMacroMealPlanTemplatePendingStateMutationFn = ApolloReactCommon.MutationFunction<DeleteMacroMealPlanTemplatePendingStateMutation, DeleteMacroMealPlanTemplatePendingStateMutationVariables>;
export type DeleteMacroMealPlanTemplatePendingStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteMacroMealPlanTemplatePendingStateMutation, DeleteMacroMealPlanTemplatePendingStateMutationVariables>, 'mutation'>;

    export const DeleteMacroMealPlanTemplatePendingStateComponent = (props: DeleteMacroMealPlanTemplatePendingStateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteMacroMealPlanTemplatePendingStateMutation, DeleteMacroMealPlanTemplatePendingStateMutationVariables> mutation={DeleteMacroMealPlanTemplatePendingStateDocument} {...props} />
    );
    

/**
 * __useDeleteMacroMealPlanTemplatePendingStateMutation__
 *
 * To run a mutation, you first call `useDeleteMacroMealPlanTemplatePendingStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMacroMealPlanTemplatePendingStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMacroMealPlanTemplatePendingStateMutation, { data, loading, error }] = useDeleteMacroMealPlanTemplatePendingStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMacroMealPlanTemplatePendingStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMacroMealPlanTemplatePendingStateMutation, DeleteMacroMealPlanTemplatePendingStateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMacroMealPlanTemplatePendingStateMutation, DeleteMacroMealPlanTemplatePendingStateMutationVariables>(DeleteMacroMealPlanTemplatePendingStateDocument, baseOptions);
      }
export type DeleteMacroMealPlanTemplatePendingStateMutationHookResult = ReturnType<typeof useDeleteMacroMealPlanTemplatePendingStateMutation>;
export type DeleteMacroMealPlanTemplatePendingStateMutationResult = ApolloReactCommon.MutationResult<DeleteMacroMealPlanTemplatePendingStateMutation>;
export type DeleteMacroMealPlanTemplatePendingStateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMacroMealPlanTemplatePendingStateMutation, DeleteMacroMealPlanTemplatePendingStateMutationVariables>;
export const UpdateMacroMealPlanTemplateSharingScopeDocument = gql`
    mutation UpdateMacroMealPlanTemplateSharingScope($input: UpdateMacroMealPlanTemplateSharingScopeInput!) {
  updateMacroMealPlanTemplateSharingScope(input: $input) {
    macroMealPlanTemplate {
      ...DietitianMealPlanTemplateIsShared
    }
  }
}
    ${DietitianMealPlanTemplateIsSharedFragmentDoc}`;
export type UpdateMacroMealPlanTemplateSharingScopeMutationFn = ApolloReactCommon.MutationFunction<UpdateMacroMealPlanTemplateSharingScopeMutation, UpdateMacroMealPlanTemplateSharingScopeMutationVariables>;
export type UpdateMacroMealPlanTemplateSharingScopeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMacroMealPlanTemplateSharingScopeMutation, UpdateMacroMealPlanTemplateSharingScopeMutationVariables>, 'mutation'>;

    export const UpdateMacroMealPlanTemplateSharingScopeComponent = (props: UpdateMacroMealPlanTemplateSharingScopeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMacroMealPlanTemplateSharingScopeMutation, UpdateMacroMealPlanTemplateSharingScopeMutationVariables> mutation={UpdateMacroMealPlanTemplateSharingScopeDocument} {...props} />
    );
    

/**
 * __useUpdateMacroMealPlanTemplateSharingScopeMutation__
 *
 * To run a mutation, you first call `useUpdateMacroMealPlanTemplateSharingScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMacroMealPlanTemplateSharingScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMacroMealPlanTemplateSharingScopeMutation, { data, loading, error }] = useUpdateMacroMealPlanTemplateSharingScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMacroMealPlanTemplateSharingScopeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMacroMealPlanTemplateSharingScopeMutation, UpdateMacroMealPlanTemplateSharingScopeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMacroMealPlanTemplateSharingScopeMutation, UpdateMacroMealPlanTemplateSharingScopeMutationVariables>(UpdateMacroMealPlanTemplateSharingScopeDocument, baseOptions);
      }
export type UpdateMacroMealPlanTemplateSharingScopeMutationHookResult = ReturnType<typeof useUpdateMacroMealPlanTemplateSharingScopeMutation>;
export type UpdateMacroMealPlanTemplateSharingScopeMutationResult = ApolloReactCommon.MutationResult<UpdateMacroMealPlanTemplateSharingScopeMutation>;
export type UpdateMacroMealPlanTemplateSharingScopeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMacroMealPlanTemplateSharingScopeMutation, UpdateMacroMealPlanTemplateSharingScopeMutationVariables>;
export const UpdateMacroMealPlanTemplateAssignScopeDocument = gql`
    mutation UpdateMacroMealPlanTemplateAssignScope($input: UpdateMealPlanTemplateAssignScopeInput!) {
  updateMacroMealPlanTemplateAssignScope(input: $input) {
    mealPlanTemplate {
      ...DietitianMealPlanTemplateAssign
    }
  }
}
    ${DietitianMealPlanTemplateAssignFragmentDoc}`;
export type UpdateMacroMealPlanTemplateAssignScopeMutationFn = ApolloReactCommon.MutationFunction<UpdateMacroMealPlanTemplateAssignScopeMutation, UpdateMacroMealPlanTemplateAssignScopeMutationVariables>;
export type UpdateMacroMealPlanTemplateAssignScopeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMacroMealPlanTemplateAssignScopeMutation, UpdateMacroMealPlanTemplateAssignScopeMutationVariables>, 'mutation'>;

    export const UpdateMacroMealPlanTemplateAssignScopeComponent = (props: UpdateMacroMealPlanTemplateAssignScopeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMacroMealPlanTemplateAssignScopeMutation, UpdateMacroMealPlanTemplateAssignScopeMutationVariables> mutation={UpdateMacroMealPlanTemplateAssignScopeDocument} {...props} />
    );
    

/**
 * __useUpdateMacroMealPlanTemplateAssignScopeMutation__
 *
 * To run a mutation, you first call `useUpdateMacroMealPlanTemplateAssignScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMacroMealPlanTemplateAssignScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMacroMealPlanTemplateAssignScopeMutation, { data, loading, error }] = useUpdateMacroMealPlanTemplateAssignScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMacroMealPlanTemplateAssignScopeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMacroMealPlanTemplateAssignScopeMutation, UpdateMacroMealPlanTemplateAssignScopeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMacroMealPlanTemplateAssignScopeMutation, UpdateMacroMealPlanTemplateAssignScopeMutationVariables>(UpdateMacroMealPlanTemplateAssignScopeDocument, baseOptions);
      }
export type UpdateMacroMealPlanTemplateAssignScopeMutationHookResult = ReturnType<typeof useUpdateMacroMealPlanTemplateAssignScopeMutation>;
export type UpdateMacroMealPlanTemplateAssignScopeMutationResult = ApolloReactCommon.MutationResult<UpdateMacroMealPlanTemplateAssignScopeMutation>;
export type UpdateMacroMealPlanTemplateAssignScopeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMacroMealPlanTemplateAssignScopeMutation, UpdateMacroMealPlanTemplateAssignScopeMutationVariables>;
export const EditOrgGroupMacroMealPlanTemplateDocument = gql`
    mutation EditOrgGroupMacroMealPlanTemplate($input: MacroMealPlanTemplatePendingStateInput!) {
  editOrgGroupMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...FullMealPlanTemplate
    }
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type EditOrgGroupMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<EditOrgGroupMacroMealPlanTemplateMutation, EditOrgGroupMacroMealPlanTemplateMutationVariables>;
export type EditOrgGroupMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditOrgGroupMacroMealPlanTemplateMutation, EditOrgGroupMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const EditOrgGroupMacroMealPlanTemplateComponent = (props: EditOrgGroupMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<EditOrgGroupMacroMealPlanTemplateMutation, EditOrgGroupMacroMealPlanTemplateMutationVariables> mutation={EditOrgGroupMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useEditOrgGroupMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useEditOrgGroupMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgGroupMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgGroupMacroMealPlanTemplateMutation, { data, loading, error }] = useEditOrgGroupMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgGroupMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrgGroupMacroMealPlanTemplateMutation, EditOrgGroupMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditOrgGroupMacroMealPlanTemplateMutation, EditOrgGroupMacroMealPlanTemplateMutationVariables>(EditOrgGroupMacroMealPlanTemplateDocument, baseOptions);
      }
export type EditOrgGroupMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useEditOrgGroupMacroMealPlanTemplateMutation>;
export type EditOrgGroupMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<EditOrgGroupMacroMealPlanTemplateMutation>;
export type EditOrgGroupMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrgGroupMacroMealPlanTemplateMutation, EditOrgGroupMacroMealPlanTemplateMutationVariables>;
export const PublishOrgGroupMacroMealPlanTemplateDocument = gql`
    mutation PublishOrgGroupMacroMealPlanTemplate($input: PublishMacroMealPlanTemplateInput!) {
  publishOrgGroupMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...FullMealPlanTemplate
    }
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type PublishOrgGroupMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<PublishOrgGroupMacroMealPlanTemplateMutation, PublishOrgGroupMacroMealPlanTemplateMutationVariables>;
export type PublishOrgGroupMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PublishOrgGroupMacroMealPlanTemplateMutation, PublishOrgGroupMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const PublishOrgGroupMacroMealPlanTemplateComponent = (props: PublishOrgGroupMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<PublishOrgGroupMacroMealPlanTemplateMutation, PublishOrgGroupMacroMealPlanTemplateMutationVariables> mutation={PublishOrgGroupMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __usePublishOrgGroupMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `usePublishOrgGroupMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishOrgGroupMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishOrgGroupMacroMealPlanTemplateMutation, { data, loading, error }] = usePublishOrgGroupMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishOrgGroupMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishOrgGroupMacroMealPlanTemplateMutation, PublishOrgGroupMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishOrgGroupMacroMealPlanTemplateMutation, PublishOrgGroupMacroMealPlanTemplateMutationVariables>(PublishOrgGroupMacroMealPlanTemplateDocument, baseOptions);
      }
export type PublishOrgGroupMacroMealPlanTemplateMutationHookResult = ReturnType<typeof usePublishOrgGroupMacroMealPlanTemplateMutation>;
export type PublishOrgGroupMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<PublishOrgGroupMacroMealPlanTemplateMutation>;
export type PublishOrgGroupMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishOrgGroupMacroMealPlanTemplateMutation, PublishOrgGroupMacroMealPlanTemplateMutationVariables>;
export const DeleteOrgGroupMacroMealPlanTemplateDocument = gql`
    mutation DeleteOrgGroupMacroMealPlanTemplate($input: DeleteMacroMealPlanTemplateInput!) {
  deleteOrgGroupMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplateId
  }
}
    `;
export type DeleteOrgGroupMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteOrgGroupMacroMealPlanTemplateMutation, DeleteOrgGroupMacroMealPlanTemplateMutationVariables>;
export type DeleteOrgGroupMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOrgGroupMacroMealPlanTemplateMutation, DeleteOrgGroupMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const DeleteOrgGroupMacroMealPlanTemplateComponent = (props: DeleteOrgGroupMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOrgGroupMacroMealPlanTemplateMutation, DeleteOrgGroupMacroMealPlanTemplateMutationVariables> mutation={DeleteOrgGroupMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useDeleteOrgGroupMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteOrgGroupMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgGroupMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgGroupMacroMealPlanTemplateMutation, { data, loading, error }] = useDeleteOrgGroupMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrgGroupMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrgGroupMacroMealPlanTemplateMutation, DeleteOrgGroupMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrgGroupMacroMealPlanTemplateMutation, DeleteOrgGroupMacroMealPlanTemplateMutationVariables>(DeleteOrgGroupMacroMealPlanTemplateDocument, baseOptions);
      }
export type DeleteOrgGroupMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useDeleteOrgGroupMacroMealPlanTemplateMutation>;
export type DeleteOrgGroupMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteOrgGroupMacroMealPlanTemplateMutation>;
export type DeleteOrgGroupMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrgGroupMacroMealPlanTemplateMutation, DeleteOrgGroupMacroMealPlanTemplateMutationVariables>;
export const DeleteOrgGroupMacroMealPlanTemplatePendingStateDocument = gql`
    mutation DeleteOrgGroupMacroMealPlanTemplatePendingState($input: DeleteMacroMealPlanTemplatePendingStateInput!) {
  deleteOrgGroupMacroMealPlanTemplatePendingState(input: $input) {
    macroMealPlanTemplate {
      ...DietitianMealPlanTemplatePendingState
    }
  }
}
    ${DietitianMealPlanTemplatePendingStateFragmentDoc}`;
export type DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationFn = ApolloReactCommon.MutationFunction<DeleteOrgGroupMacroMealPlanTemplatePendingStateMutation, DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationVariables>;
export type DeleteOrgGroupMacroMealPlanTemplatePendingStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOrgGroupMacroMealPlanTemplatePendingStateMutation, DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationVariables>, 'mutation'>;

    export const DeleteOrgGroupMacroMealPlanTemplatePendingStateComponent = (props: DeleteOrgGroupMacroMealPlanTemplatePendingStateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOrgGroupMacroMealPlanTemplatePendingStateMutation, DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationVariables> mutation={DeleteOrgGroupMacroMealPlanTemplatePendingStateDocument} {...props} />
    );
    

/**
 * __useDeleteOrgGroupMacroMealPlanTemplatePendingStateMutation__
 *
 * To run a mutation, you first call `useDeleteOrgGroupMacroMealPlanTemplatePendingStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgGroupMacroMealPlanTemplatePendingStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgGroupMacroMealPlanTemplatePendingStateMutation, { data, loading, error }] = useDeleteOrgGroupMacroMealPlanTemplatePendingStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrgGroupMacroMealPlanTemplatePendingStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrgGroupMacroMealPlanTemplatePendingStateMutation, DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrgGroupMacroMealPlanTemplatePendingStateMutation, DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationVariables>(DeleteOrgGroupMacroMealPlanTemplatePendingStateDocument, baseOptions);
      }
export type DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationHookResult = ReturnType<typeof useDeleteOrgGroupMacroMealPlanTemplatePendingStateMutation>;
export type DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationResult = ApolloReactCommon.MutationResult<DeleteOrgGroupMacroMealPlanTemplatePendingStateMutation>;
export type DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrgGroupMacroMealPlanTemplatePendingStateMutation, DeleteOrgGroupMacroMealPlanTemplatePendingStateMutationVariables>;
export const MoveMacroMealPlanTemplateToOrgGroupDocument = gql`
    mutation MoveMacroMealPlanTemplateToOrgGroup($input: MoveMacroMealPlanTemplateToOrgGroupInput!) {
  moveMacroMealPlanTemplateToOrgGroup(input: $input) {
    macroMealPlanTemplate {
      id
    }
  }
}
    `;
export type MoveMacroMealPlanTemplateToOrgGroupMutationFn = ApolloReactCommon.MutationFunction<MoveMacroMealPlanTemplateToOrgGroupMutation, MoveMacroMealPlanTemplateToOrgGroupMutationVariables>;
export type MoveMacroMealPlanTemplateToOrgGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveMacroMealPlanTemplateToOrgGroupMutation, MoveMacroMealPlanTemplateToOrgGroupMutationVariables>, 'mutation'>;

    export const MoveMacroMealPlanTemplateToOrgGroupComponent = (props: MoveMacroMealPlanTemplateToOrgGroupComponentProps) => (
      <ApolloReactComponents.Mutation<MoveMacroMealPlanTemplateToOrgGroupMutation, MoveMacroMealPlanTemplateToOrgGroupMutationVariables> mutation={MoveMacroMealPlanTemplateToOrgGroupDocument} {...props} />
    );
    

/**
 * __useMoveMacroMealPlanTemplateToOrgGroupMutation__
 *
 * To run a mutation, you first call `useMoveMacroMealPlanTemplateToOrgGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMacroMealPlanTemplateToOrgGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMacroMealPlanTemplateToOrgGroupMutation, { data, loading, error }] = useMoveMacroMealPlanTemplateToOrgGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveMacroMealPlanTemplateToOrgGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveMacroMealPlanTemplateToOrgGroupMutation, MoveMacroMealPlanTemplateToOrgGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveMacroMealPlanTemplateToOrgGroupMutation, MoveMacroMealPlanTemplateToOrgGroupMutationVariables>(MoveMacroMealPlanTemplateToOrgGroupDocument, baseOptions);
      }
export type MoveMacroMealPlanTemplateToOrgGroupMutationHookResult = ReturnType<typeof useMoveMacroMealPlanTemplateToOrgGroupMutation>;
export type MoveMacroMealPlanTemplateToOrgGroupMutationResult = ApolloReactCommon.MutationResult<MoveMacroMealPlanTemplateToOrgGroupMutation>;
export type MoveMacroMealPlanTemplateToOrgGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveMacroMealPlanTemplateToOrgGroupMutation, MoveMacroMealPlanTemplateToOrgGroupMutationVariables>;
export const MoveOrgGroupMacroMealPlanTemplateToOrgDocument = gql`
    mutation MoveOrgGroupMacroMealPlanTemplateToOrg($input: MoveOrgGroupMacroMealPlanTemplateToOrgInput!) {
  moveOrgGroupMacroMealPlanTemplateToOrg(input: $input) {
    macroMealPlanTemplate {
      id
    }
  }
}
    `;
export type MoveOrgGroupMacroMealPlanTemplateToOrgMutationFn = ApolloReactCommon.MutationFunction<MoveOrgGroupMacroMealPlanTemplateToOrgMutation, MoveOrgGroupMacroMealPlanTemplateToOrgMutationVariables>;
export type MoveOrgGroupMacroMealPlanTemplateToOrgComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveOrgGroupMacroMealPlanTemplateToOrgMutation, MoveOrgGroupMacroMealPlanTemplateToOrgMutationVariables>, 'mutation'>;

    export const MoveOrgGroupMacroMealPlanTemplateToOrgComponent = (props: MoveOrgGroupMacroMealPlanTemplateToOrgComponentProps) => (
      <ApolloReactComponents.Mutation<MoveOrgGroupMacroMealPlanTemplateToOrgMutation, MoveOrgGroupMacroMealPlanTemplateToOrgMutationVariables> mutation={MoveOrgGroupMacroMealPlanTemplateToOrgDocument} {...props} />
    );
    

/**
 * __useMoveOrgGroupMacroMealPlanTemplateToOrgMutation__
 *
 * To run a mutation, you first call `useMoveOrgGroupMacroMealPlanTemplateToOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveOrgGroupMacroMealPlanTemplateToOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveOrgGroupMacroMealPlanTemplateToOrgMutation, { data, loading, error }] = useMoveOrgGroupMacroMealPlanTemplateToOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveOrgGroupMacroMealPlanTemplateToOrgMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveOrgGroupMacroMealPlanTemplateToOrgMutation, MoveOrgGroupMacroMealPlanTemplateToOrgMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveOrgGroupMacroMealPlanTemplateToOrgMutation, MoveOrgGroupMacroMealPlanTemplateToOrgMutationVariables>(MoveOrgGroupMacroMealPlanTemplateToOrgDocument, baseOptions);
      }
export type MoveOrgGroupMacroMealPlanTemplateToOrgMutationHookResult = ReturnType<typeof useMoveOrgGroupMacroMealPlanTemplateToOrgMutation>;
export type MoveOrgGroupMacroMealPlanTemplateToOrgMutationResult = ApolloReactCommon.MutationResult<MoveOrgGroupMacroMealPlanTemplateToOrgMutation>;
export type MoveOrgGroupMacroMealPlanTemplateToOrgMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveOrgGroupMacroMealPlanTemplateToOrgMutation, MoveOrgGroupMacroMealPlanTemplateToOrgMutationVariables>;
export const ResourceSharingOrgGroupsDocument = gql`
    query ResourceSharingOrgGroups {
  resourceSharingOrgGroups {
    id
    name
  }
}
    `;
export type ResourceSharingOrgGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResourceSharingOrgGroupsQuery, ResourceSharingOrgGroupsQueryVariables>, 'query'>;

    export const ResourceSharingOrgGroupsComponent = (props: ResourceSharingOrgGroupsComponentProps) => (
      <ApolloReactComponents.Query<ResourceSharingOrgGroupsQuery, ResourceSharingOrgGroupsQueryVariables> query={ResourceSharingOrgGroupsDocument} {...props} />
    );
    

/**
 * __useResourceSharingOrgGroupsQuery__
 *
 * To run a query within a React component, call `useResourceSharingOrgGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceSharingOrgGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceSharingOrgGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useResourceSharingOrgGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResourceSharingOrgGroupsQuery, ResourceSharingOrgGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<ResourceSharingOrgGroupsQuery, ResourceSharingOrgGroupsQueryVariables>(ResourceSharingOrgGroupsDocument, baseOptions);
      }
export function useResourceSharingOrgGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResourceSharingOrgGroupsQuery, ResourceSharingOrgGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResourceSharingOrgGroupsQuery, ResourceSharingOrgGroupsQueryVariables>(ResourceSharingOrgGroupsDocument, baseOptions);
        }
export type ResourceSharingOrgGroupsQueryHookResult = ReturnType<typeof useResourceSharingOrgGroupsQuery>;
export type ResourceSharingOrgGroupsLazyQueryHookResult = ReturnType<typeof useResourceSharingOrgGroupsLazyQuery>;
export type ResourceSharingOrgGroupsQueryResult = ApolloReactCommon.QueryResult<ResourceSharingOrgGroupsQuery, ResourceSharingOrgGroupsQueryVariables>;
export const MealMenuAttendeesDocument = gql`
    query MealMenuAttendees($mealMenuId: ID!, $tagIds: [ID!]!) {
  mealMenuAttendees(mealMenuId: $mealMenuId, tagIds: $tagIds) {
    notemealAttendees {
      athlete {
        ...AthleteForMenuAttendance
      }
      checkedIn
      checkInDisplayDate
      hasAccess
    }
    nonNotemealAttendees {
      firstName
      lastName
      checkedIn
      checkInDisplayDate
      hasAccess
    }
    queryCompletedAt
  }
}
    ${AthleteForMenuAttendanceFragmentDoc}`;
export type MealMenuAttendeesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenuAttendeesQuery, MealMenuAttendeesQueryVariables>, 'query'> & ({ variables: MealMenuAttendeesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenuAttendeesComponent = (props: MealMenuAttendeesComponentProps) => (
      <ApolloReactComponents.Query<MealMenuAttendeesQuery, MealMenuAttendeesQueryVariables> query={MealMenuAttendeesDocument} {...props} />
    );
    

/**
 * __useMealMenuAttendeesQuery__
 *
 * To run a query within a React component, call `useMealMenuAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuAttendeesQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useMealMenuAttendeesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenuAttendeesQuery, MealMenuAttendeesQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenuAttendeesQuery, MealMenuAttendeesQueryVariables>(MealMenuAttendeesDocument, baseOptions);
      }
export function useMealMenuAttendeesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenuAttendeesQuery, MealMenuAttendeesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenuAttendeesQuery, MealMenuAttendeesQueryVariables>(MealMenuAttendeesDocument, baseOptions);
        }
export type MealMenuAttendeesQueryHookResult = ReturnType<typeof useMealMenuAttendeesQuery>;
export type MealMenuAttendeesLazyQueryHookResult = ReturnType<typeof useMealMenuAttendeesLazyQuery>;
export type MealMenuAttendeesQueryResult = ApolloReactCommon.QueryResult<MealMenuAttendeesQuery, MealMenuAttendeesQueryVariables>;
export const PlannedMenuHeaderDocument = gql`
    query PlannedMenuHeader($plannedMenuId: ID!) {
  plannedMenu(id: $plannedMenuId) {
    ...PlannedMenuSummary
    athletesWithBirthdaysInRange {
      ...AthletesBirthdays
    }
  }
}
    ${PlannedMenuSummaryFragmentDoc}
${AthletesBirthdaysFragmentDoc}`;
export type PlannedMenuHeaderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlannedMenuHeaderQuery, PlannedMenuHeaderQueryVariables>, 'query'> & ({ variables: PlannedMenuHeaderQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlannedMenuHeaderComponent = (props: PlannedMenuHeaderComponentProps) => (
      <ApolloReactComponents.Query<PlannedMenuHeaderQuery, PlannedMenuHeaderQueryVariables> query={PlannedMenuHeaderDocument} {...props} />
    );
    

/**
 * __usePlannedMenuHeaderQuery__
 *
 * To run a query within a React component, call `usePlannedMenuHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannedMenuHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannedMenuHeaderQuery({
 *   variables: {
 *      plannedMenuId: // value for 'plannedMenuId'
 *   },
 * });
 */
export function usePlannedMenuHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlannedMenuHeaderQuery, PlannedMenuHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<PlannedMenuHeaderQuery, PlannedMenuHeaderQueryVariables>(PlannedMenuHeaderDocument, baseOptions);
      }
export function usePlannedMenuHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlannedMenuHeaderQuery, PlannedMenuHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlannedMenuHeaderQuery, PlannedMenuHeaderQueryVariables>(PlannedMenuHeaderDocument, baseOptions);
        }
export type PlannedMenuHeaderQueryHookResult = ReturnType<typeof usePlannedMenuHeaderQuery>;
export type PlannedMenuHeaderLazyQueryHookResult = ReturnType<typeof usePlannedMenuHeaderLazyQuery>;
export type PlannedMenuHeaderQueryResult = ApolloReactCommon.QueryResult<PlannedMenuHeaderQuery, PlannedMenuHeaderQueryVariables>;
export const PlannedMenuGridDocument = gql`
    query PlannedMenuGrid($plannedMenuWeekId: ID!) {
  plannedMenuMealsForWeek(plannedMenuWeekId: $plannedMenuWeekId) {
    ...PlannedMenuGridMeal
  }
}
    ${PlannedMenuGridMealFragmentDoc}`;
export type PlannedMenuGridComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlannedMenuGridQuery, PlannedMenuGridQueryVariables>, 'query'> & ({ variables: PlannedMenuGridQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlannedMenuGridComponent = (props: PlannedMenuGridComponentProps) => (
      <ApolloReactComponents.Query<PlannedMenuGridQuery, PlannedMenuGridQueryVariables> query={PlannedMenuGridDocument} {...props} />
    );
    

/**
 * __usePlannedMenuGridQuery__
 *
 * To run a query within a React component, call `usePlannedMenuGridQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannedMenuGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannedMenuGridQuery({
 *   variables: {
 *      plannedMenuWeekId: // value for 'plannedMenuWeekId'
 *   },
 * });
 */
export function usePlannedMenuGridQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlannedMenuGridQuery, PlannedMenuGridQueryVariables>) {
        return ApolloReactHooks.useQuery<PlannedMenuGridQuery, PlannedMenuGridQueryVariables>(PlannedMenuGridDocument, baseOptions);
      }
export function usePlannedMenuGridLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlannedMenuGridQuery, PlannedMenuGridQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlannedMenuGridQuery, PlannedMenuGridQueryVariables>(PlannedMenuGridDocument, baseOptions);
        }
export type PlannedMenuGridQueryHookResult = ReturnType<typeof usePlannedMenuGridQuery>;
export type PlannedMenuGridLazyQueryHookResult = ReturnType<typeof usePlannedMenuGridLazyQuery>;
export type PlannedMenuGridQueryResult = ApolloReactCommon.QueryResult<PlannedMenuGridQuery, PlannedMenuGridQueryVariables>;
export const SavePlannedMealsDocument = gql`
    mutation SavePlannedMeals($input: SavePlannedMealsInput!) {
  savePlannedMeals(input: $input) {
    plannedMenu {
      id
    }
  }
}
    `;
export type SavePlannedMealsMutationFn = ApolloReactCommon.MutationFunction<SavePlannedMealsMutation, SavePlannedMealsMutationVariables>;
export type SavePlannedMealsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SavePlannedMealsMutation, SavePlannedMealsMutationVariables>, 'mutation'>;

    export const SavePlannedMealsComponent = (props: SavePlannedMealsComponentProps) => (
      <ApolloReactComponents.Mutation<SavePlannedMealsMutation, SavePlannedMealsMutationVariables> mutation={SavePlannedMealsDocument} {...props} />
    );
    

/**
 * __useSavePlannedMealsMutation__
 *
 * To run a mutation, you first call `useSavePlannedMealsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePlannedMealsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePlannedMealsMutation, { data, loading, error }] = useSavePlannedMealsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePlannedMealsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SavePlannedMealsMutation, SavePlannedMealsMutationVariables>) {
        return ApolloReactHooks.useMutation<SavePlannedMealsMutation, SavePlannedMealsMutationVariables>(SavePlannedMealsDocument, baseOptions);
      }
export type SavePlannedMealsMutationHookResult = ReturnType<typeof useSavePlannedMealsMutation>;
export type SavePlannedMealsMutationResult = ApolloReactCommon.MutationResult<SavePlannedMealsMutation>;
export type SavePlannedMealsMutationOptions = ApolloReactCommon.BaseMutationOptions<SavePlannedMealsMutation, SavePlannedMealsMutationVariables>;
export const ImportPlannedMenuDocument = gql`
    mutation ImportPlannedMenu($input: ImportPlannedMenuInput!) {
  importPlannedMenu(input: $input) {
    mealMenus {
      ...MealMenuCalendar
    }
  }
}
    ${MealMenuCalendarFragmentDoc}`;
export type ImportPlannedMenuMutationFn = ApolloReactCommon.MutationFunction<ImportPlannedMenuMutation, ImportPlannedMenuMutationVariables>;
export type ImportPlannedMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportPlannedMenuMutation, ImportPlannedMenuMutationVariables>, 'mutation'>;

    export const ImportPlannedMenuComponent = (props: ImportPlannedMenuComponentProps) => (
      <ApolloReactComponents.Mutation<ImportPlannedMenuMutation, ImportPlannedMenuMutationVariables> mutation={ImportPlannedMenuDocument} {...props} />
    );
    

/**
 * __useImportPlannedMenuMutation__
 *
 * To run a mutation, you first call `useImportPlannedMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPlannedMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPlannedMenuMutation, { data, loading, error }] = useImportPlannedMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportPlannedMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportPlannedMenuMutation, ImportPlannedMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportPlannedMenuMutation, ImportPlannedMenuMutationVariables>(ImportPlannedMenuDocument, baseOptions);
      }
export type ImportPlannedMenuMutationHookResult = ReturnType<typeof useImportPlannedMenuMutation>;
export type ImportPlannedMenuMutationResult = ApolloReactCommon.MutationResult<ImportPlannedMenuMutation>;
export type ImportPlannedMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportPlannedMenuMutation, ImportPlannedMenuMutationVariables>;
export const LockPlannedMenuDocument = gql`
    mutation LockPlannedMenu($input: LockPlannedMenuInput!) {
  lockPlannedMenu(input: $input) {
    lockAcquired
    plannedMenu {
      id
      lockCreatedAt
      lockCreatedBy {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type LockPlannedMenuMutationFn = ApolloReactCommon.MutationFunction<LockPlannedMenuMutation, LockPlannedMenuMutationVariables>;
export type LockPlannedMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LockPlannedMenuMutation, LockPlannedMenuMutationVariables>, 'mutation'>;

    export const LockPlannedMenuComponent = (props: LockPlannedMenuComponentProps) => (
      <ApolloReactComponents.Mutation<LockPlannedMenuMutation, LockPlannedMenuMutationVariables> mutation={LockPlannedMenuDocument} {...props} />
    );
    

/**
 * __useLockPlannedMenuMutation__
 *
 * To run a mutation, you first call `useLockPlannedMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockPlannedMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockPlannedMenuMutation, { data, loading, error }] = useLockPlannedMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLockPlannedMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LockPlannedMenuMutation, LockPlannedMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<LockPlannedMenuMutation, LockPlannedMenuMutationVariables>(LockPlannedMenuDocument, baseOptions);
      }
export type LockPlannedMenuMutationHookResult = ReturnType<typeof useLockPlannedMenuMutation>;
export type LockPlannedMenuMutationResult = ApolloReactCommon.MutationResult<LockPlannedMenuMutation>;
export type LockPlannedMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<LockPlannedMenuMutation, LockPlannedMenuMutationVariables>;
export const ForceLockPlannedMenuDocument = gql`
    mutation ForceLockPlannedMenu($input: ForceLockPlannedMenuInput!) {
  forceLockPlannedMenu(input: $input) {
    plannedMenuId
  }
}
    `;
export type ForceLockPlannedMenuMutationFn = ApolloReactCommon.MutationFunction<ForceLockPlannedMenuMutation, ForceLockPlannedMenuMutationVariables>;
export type ForceLockPlannedMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ForceLockPlannedMenuMutation, ForceLockPlannedMenuMutationVariables>, 'mutation'>;

    export const ForceLockPlannedMenuComponent = (props: ForceLockPlannedMenuComponentProps) => (
      <ApolloReactComponents.Mutation<ForceLockPlannedMenuMutation, ForceLockPlannedMenuMutationVariables> mutation={ForceLockPlannedMenuDocument} {...props} />
    );
    

/**
 * __useForceLockPlannedMenuMutation__
 *
 * To run a mutation, you first call `useForceLockPlannedMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceLockPlannedMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceLockPlannedMenuMutation, { data, loading, error }] = useForceLockPlannedMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForceLockPlannedMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceLockPlannedMenuMutation, ForceLockPlannedMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceLockPlannedMenuMutation, ForceLockPlannedMenuMutationVariables>(ForceLockPlannedMenuDocument, baseOptions);
      }
export type ForceLockPlannedMenuMutationHookResult = ReturnType<typeof useForceLockPlannedMenuMutation>;
export type ForceLockPlannedMenuMutationResult = ApolloReactCommon.MutationResult<ForceLockPlannedMenuMutation>;
export type ForceLockPlannedMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceLockPlannedMenuMutation, ForceLockPlannedMenuMutationVariables>;
export const UnlockPlannedMenuDocument = gql`
    mutation UnlockPlannedMenu($input: UnlockPlannedMenuInput!) {
  unlockPlannedMenu(input: $input) {
    lockReleased
  }
}
    `;
export type UnlockPlannedMenuMutationFn = ApolloReactCommon.MutationFunction<UnlockPlannedMenuMutation, UnlockPlannedMenuMutationVariables>;
export type UnlockPlannedMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnlockPlannedMenuMutation, UnlockPlannedMenuMutationVariables>, 'mutation'>;

    export const UnlockPlannedMenuComponent = (props: UnlockPlannedMenuComponentProps) => (
      <ApolloReactComponents.Mutation<UnlockPlannedMenuMutation, UnlockPlannedMenuMutationVariables> mutation={UnlockPlannedMenuDocument} {...props} />
    );
    

/**
 * __useUnlockPlannedMenuMutation__
 *
 * To run a mutation, you first call `useUnlockPlannedMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockPlannedMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockPlannedMenuMutation, { data, loading, error }] = useUnlockPlannedMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnlockPlannedMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnlockPlannedMenuMutation, UnlockPlannedMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<UnlockPlannedMenuMutation, UnlockPlannedMenuMutationVariables>(UnlockPlannedMenuDocument, baseOptions);
      }
export type UnlockPlannedMenuMutationHookResult = ReturnType<typeof useUnlockPlannedMenuMutation>;
export type UnlockPlannedMenuMutationResult = ApolloReactCommon.MutationResult<UnlockPlannedMenuMutation>;
export type UnlockPlannedMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<UnlockPlannedMenuMutation, UnlockPlannedMenuMutationVariables>;
export const PlannedMenuLockTakenDocument = gql`
    subscription PlannedMenuLockTaken($plannedMenuId: ID!) {
  plannedMenuLockTaken(scope: {plannedMenuId: $plannedMenuId}) {
    payload {
      plannedMenu {
        id
        lockCreatedAt
        lockCreatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;
export type PlannedMenuLockTakenComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PlannedMenuLockTakenSubscription, PlannedMenuLockTakenSubscriptionVariables>, 'subscription'>;

    export const PlannedMenuLockTakenComponent = (props: PlannedMenuLockTakenComponentProps) => (
      <ApolloReactComponents.Subscription<PlannedMenuLockTakenSubscription, PlannedMenuLockTakenSubscriptionVariables> subscription={PlannedMenuLockTakenDocument} {...props} />
    );
    

/**
 * __usePlannedMenuLockTakenSubscription__
 *
 * To run a query within a React component, call `usePlannedMenuLockTakenSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePlannedMenuLockTakenSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannedMenuLockTakenSubscription({
 *   variables: {
 *      plannedMenuId: // value for 'plannedMenuId'
 *   },
 * });
 */
export function usePlannedMenuLockTakenSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PlannedMenuLockTakenSubscription, PlannedMenuLockTakenSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<PlannedMenuLockTakenSubscription, PlannedMenuLockTakenSubscriptionVariables>(PlannedMenuLockTakenDocument, baseOptions);
      }
export type PlannedMenuLockTakenSubscriptionHookResult = ReturnType<typeof usePlannedMenuLockTakenSubscription>;
export type PlannedMenuLockTakenSubscriptionResult = ApolloReactCommon.SubscriptionResult<PlannedMenuLockTakenSubscription>;
export const DigitalDisplaysDialogDocument = gql`
    query DigitalDisplaysDialog($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    ...DigiDisplaysMealMenu
  }
}
    ${DigiDisplaysMealMenuFragmentDoc}`;
export type DigitalDisplaysDialogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DigitalDisplaysDialogQuery, DigitalDisplaysDialogQueryVariables>, 'query'> & ({ variables: DigitalDisplaysDialogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DigitalDisplaysDialogComponent = (props: DigitalDisplaysDialogComponentProps) => (
      <ApolloReactComponents.Query<DigitalDisplaysDialogQuery, DigitalDisplaysDialogQueryVariables> query={DigitalDisplaysDialogDocument} {...props} />
    );
    

/**
 * __useDigitalDisplaysDialogQuery__
 *
 * To run a query within a React component, call `useDigitalDisplaysDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalDisplaysDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalDisplaysDialogQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useDigitalDisplaysDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DigitalDisplaysDialogQuery, DigitalDisplaysDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<DigitalDisplaysDialogQuery, DigitalDisplaysDialogQueryVariables>(DigitalDisplaysDialogDocument, baseOptions);
      }
export function useDigitalDisplaysDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DigitalDisplaysDialogQuery, DigitalDisplaysDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DigitalDisplaysDialogQuery, DigitalDisplaysDialogQueryVariables>(DigitalDisplaysDialogDocument, baseOptions);
        }
export type DigitalDisplaysDialogQueryHookResult = ReturnType<typeof useDigitalDisplaysDialogQuery>;
export type DigitalDisplaysDialogLazyQueryHookResult = ReturnType<typeof useDigitalDisplaysDialogLazyQuery>;
export type DigitalDisplaysDialogQueryResult = ApolloReactCommon.QueryResult<DigitalDisplaysDialogQuery, DigitalDisplaysDialogQueryVariables>;
export const PlannedMenuTableDocument = gql`
    query PlannedMenuTable($query: String, $input: OffsetPaginationInput!) {
  plannedMenuOffsetConnection(query: $query, input: $input) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...PlannedMenuTableItem
    }
  }
}
    ${PlannedMenuTableItemFragmentDoc}`;
export type PlannedMenuTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlannedMenuTableQuery, PlannedMenuTableQueryVariables>, 'query'> & ({ variables: PlannedMenuTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlannedMenuTableComponent = (props: PlannedMenuTableComponentProps) => (
      <ApolloReactComponents.Query<PlannedMenuTableQuery, PlannedMenuTableQueryVariables> query={PlannedMenuTableDocument} {...props} />
    );
    

/**
 * __usePlannedMenuTableQuery__
 *
 * To run a query within a React component, call `usePlannedMenuTableQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannedMenuTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannedMenuTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlannedMenuTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlannedMenuTableQuery, PlannedMenuTableQueryVariables>) {
        return ApolloReactHooks.useQuery<PlannedMenuTableQuery, PlannedMenuTableQueryVariables>(PlannedMenuTableDocument, baseOptions);
      }
export function usePlannedMenuTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlannedMenuTableQuery, PlannedMenuTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlannedMenuTableQuery, PlannedMenuTableQueryVariables>(PlannedMenuTableDocument, baseOptions);
        }
export type PlannedMenuTableQueryHookResult = ReturnType<typeof usePlannedMenuTableQuery>;
export type PlannedMenuTableLazyQueryHookResult = ReturnType<typeof usePlannedMenuTableLazyQuery>;
export type PlannedMenuTableQueryResult = ApolloReactCommon.QueryResult<PlannedMenuTableQuery, PlannedMenuTableQueryVariables>;
export const CreatePlannedMenuDocument = gql`
    mutation CreatePlannedMenu($input: CreatePlannedMenuInput!) {
  createPlannedMenu(input: $input) {
    plannedMenu {
      ...PlannedMenuTableItem
    }
  }
}
    ${PlannedMenuTableItemFragmentDoc}`;
export type CreatePlannedMenuMutationFn = ApolloReactCommon.MutationFunction<CreatePlannedMenuMutation, CreatePlannedMenuMutationVariables>;
export type CreatePlannedMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreatePlannedMenuMutation, CreatePlannedMenuMutationVariables>, 'mutation'>;

    export const CreatePlannedMenuComponent = (props: CreatePlannedMenuComponentProps) => (
      <ApolloReactComponents.Mutation<CreatePlannedMenuMutation, CreatePlannedMenuMutationVariables> mutation={CreatePlannedMenuDocument} {...props} />
    );
    

/**
 * __useCreatePlannedMenuMutation__
 *
 * To run a mutation, you first call `useCreatePlannedMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlannedMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlannedMenuMutation, { data, loading, error }] = useCreatePlannedMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlannedMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlannedMenuMutation, CreatePlannedMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlannedMenuMutation, CreatePlannedMenuMutationVariables>(CreatePlannedMenuDocument, baseOptions);
      }
export type CreatePlannedMenuMutationHookResult = ReturnType<typeof useCreatePlannedMenuMutation>;
export type CreatePlannedMenuMutationResult = ApolloReactCommon.MutationResult<CreatePlannedMenuMutation>;
export type CreatePlannedMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlannedMenuMutation, CreatePlannedMenuMutationVariables>;
export const EditPlannedMenuDocument = gql`
    mutation EditPlannedMenu($input: EditPlannedMenuInput!) {
  editPlannedMenu(input: $input) {
    plannedMenu {
      ...PlannedMenuTableItem
    }
  }
}
    ${PlannedMenuTableItemFragmentDoc}`;
export type EditPlannedMenuMutationFn = ApolloReactCommon.MutationFunction<EditPlannedMenuMutation, EditPlannedMenuMutationVariables>;
export type EditPlannedMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditPlannedMenuMutation, EditPlannedMenuMutationVariables>, 'mutation'>;

    export const EditPlannedMenuComponent = (props: EditPlannedMenuComponentProps) => (
      <ApolloReactComponents.Mutation<EditPlannedMenuMutation, EditPlannedMenuMutationVariables> mutation={EditPlannedMenuDocument} {...props} />
    );
    

/**
 * __useEditPlannedMenuMutation__
 *
 * To run a mutation, you first call `useEditPlannedMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlannedMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlannedMenuMutation, { data, loading, error }] = useEditPlannedMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlannedMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPlannedMenuMutation, EditPlannedMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<EditPlannedMenuMutation, EditPlannedMenuMutationVariables>(EditPlannedMenuDocument, baseOptions);
      }
export type EditPlannedMenuMutationHookResult = ReturnType<typeof useEditPlannedMenuMutation>;
export type EditPlannedMenuMutationResult = ApolloReactCommon.MutationResult<EditPlannedMenuMutation>;
export type EditPlannedMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<EditPlannedMenuMutation, EditPlannedMenuMutationVariables>;
export const DeletePlannedMenuDocument = gql`
    mutation DeletePlannedMenu($input: DeletePlannedMenuInput!) {
  deletePlannedMenu(input: $input) {
    plannedMenuId
  }
}
    `;
export type DeletePlannedMenuMutationFn = ApolloReactCommon.MutationFunction<DeletePlannedMenuMutation, DeletePlannedMenuMutationVariables>;
export type DeletePlannedMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePlannedMenuMutation, DeletePlannedMenuMutationVariables>, 'mutation'>;

    export const DeletePlannedMenuComponent = (props: DeletePlannedMenuComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePlannedMenuMutation, DeletePlannedMenuMutationVariables> mutation={DeletePlannedMenuDocument} {...props} />
    );
    

/**
 * __useDeletePlannedMenuMutation__
 *
 * To run a mutation, you first call `useDeletePlannedMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlannedMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlannedMenuMutation, { data, loading, error }] = useDeletePlannedMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlannedMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePlannedMenuMutation, DeletePlannedMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePlannedMenuMutation, DeletePlannedMenuMutationVariables>(DeletePlannedMenuDocument, baseOptions);
      }
export type DeletePlannedMenuMutationHookResult = ReturnType<typeof useDeletePlannedMenuMutation>;
export type DeletePlannedMenuMutationResult = ApolloReactCommon.MutationResult<DeletePlannedMenuMutation>;
export type DeletePlannedMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlannedMenuMutation, DeletePlannedMenuMutationVariables>;
export const DuplicatePlannedMenuDocument = gql`
    mutation DuplicatePlannedMenu($input: DuplicatePlannedMenuInput!) {
  duplicatePlannedMenu(input: $input) {
    plannedMenu {
      ...PlannedMenuTableItem
    }
  }
}
    ${PlannedMenuTableItemFragmentDoc}`;
export type DuplicatePlannedMenuMutationFn = ApolloReactCommon.MutationFunction<DuplicatePlannedMenuMutation, DuplicatePlannedMenuMutationVariables>;
export type DuplicatePlannedMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DuplicatePlannedMenuMutation, DuplicatePlannedMenuMutationVariables>, 'mutation'>;

    export const DuplicatePlannedMenuComponent = (props: DuplicatePlannedMenuComponentProps) => (
      <ApolloReactComponents.Mutation<DuplicatePlannedMenuMutation, DuplicatePlannedMenuMutationVariables> mutation={DuplicatePlannedMenuDocument} {...props} />
    );
    

/**
 * __useDuplicatePlannedMenuMutation__
 *
 * To run a mutation, you first call `useDuplicatePlannedMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePlannedMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePlannedMenuMutation, { data, loading, error }] = useDuplicatePlannedMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicatePlannedMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicatePlannedMenuMutation, DuplicatePlannedMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicatePlannedMenuMutation, DuplicatePlannedMenuMutationVariables>(DuplicatePlannedMenuDocument, baseOptions);
      }
export type DuplicatePlannedMenuMutationHookResult = ReturnType<typeof useDuplicatePlannedMenuMutation>;
export type DuplicatePlannedMenuMutationResult = ApolloReactCommon.MutationResult<DuplicatePlannedMenuMutation>;
export type DuplicatePlannedMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicatePlannedMenuMutation, DuplicatePlannedMenuMutationVariables>;
export const MenuItemsTableDocument = gql`
    query MenuItemsTable($query: String, $input: OffsetPaginationInput!) {
  menuItemOffsetConnection(query: $query, input: $input) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...MenuItemTable
    }
  }
}
    ${MenuItemTableFragmentDoc}`;
export type MenuItemsTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuItemsTableQuery, MenuItemsTableQueryVariables>, 'query'> & ({ variables: MenuItemsTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuItemsTableComponent = (props: MenuItemsTableComponentProps) => (
      <ApolloReactComponents.Query<MenuItemsTableQuery, MenuItemsTableQueryVariables> query={MenuItemsTableDocument} {...props} />
    );
    

/**
 * __useMenuItemsTableQuery__
 *
 * To run a query within a React component, call `useMenuItemsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuItemsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuItemsTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMenuItemsTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuItemsTableQuery, MenuItemsTableQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuItemsTableQuery, MenuItemsTableQueryVariables>(MenuItemsTableDocument, baseOptions);
      }
export function useMenuItemsTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuItemsTableQuery, MenuItemsTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuItemsTableQuery, MenuItemsTableQueryVariables>(MenuItemsTableDocument, baseOptions);
        }
export type MenuItemsTableQueryHookResult = ReturnType<typeof useMenuItemsTableQuery>;
export type MenuItemsTableLazyQueryHookResult = ReturnType<typeof useMenuItemsTableLazyQuery>;
export type MenuItemsTableQueryResult = ApolloReactCommon.QueryResult<MenuItemsTableQuery, MenuItemsTableQueryVariables>;
export const CreateReusableMenuItemDocument = gql`
    mutation CreateReusableMenuItem($input: CreateReusableMenuItemInput!) {
  createReusableMenuItem(input: $input) {
    menuItem {
      id
      name
      isMissingIngredients
    }
  }
}
    `;
export type CreateReusableMenuItemMutationFn = ApolloReactCommon.MutationFunction<CreateReusableMenuItemMutation, CreateReusableMenuItemMutationVariables>;
export type CreateReusableMenuItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateReusableMenuItemMutation, CreateReusableMenuItemMutationVariables>, 'mutation'>;

    export const CreateReusableMenuItemComponent = (props: CreateReusableMenuItemComponentProps) => (
      <ApolloReactComponents.Mutation<CreateReusableMenuItemMutation, CreateReusableMenuItemMutationVariables> mutation={CreateReusableMenuItemDocument} {...props} />
    );
    

/**
 * __useCreateReusableMenuItemMutation__
 *
 * To run a mutation, you first call `useCreateReusableMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReusableMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReusableMenuItemMutation, { data, loading, error }] = useCreateReusableMenuItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReusableMenuItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReusableMenuItemMutation, CreateReusableMenuItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReusableMenuItemMutation, CreateReusableMenuItemMutationVariables>(CreateReusableMenuItemDocument, baseOptions);
      }
export type CreateReusableMenuItemMutationHookResult = ReturnType<typeof useCreateReusableMenuItemMutation>;
export type CreateReusableMenuItemMutationResult = ApolloReactCommon.MutationResult<CreateReusableMenuItemMutation>;
export type CreateReusableMenuItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReusableMenuItemMutation, CreateReusableMenuItemMutationVariables>;
export const EditReusableMenuItemDocument = gql`
    mutation EditReusableMenuItem($input: EditReusableMenuItemInput!) {
  editReusableMenuItem(input: $input) {
    menuItem {
      id
      name
      isMissingIngredients
    }
  }
}
    `;
export type EditReusableMenuItemMutationFn = ApolloReactCommon.MutationFunction<EditReusableMenuItemMutation, EditReusableMenuItemMutationVariables>;
export type EditReusableMenuItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditReusableMenuItemMutation, EditReusableMenuItemMutationVariables>, 'mutation'>;

    export const EditReusableMenuItemComponent = (props: EditReusableMenuItemComponentProps) => (
      <ApolloReactComponents.Mutation<EditReusableMenuItemMutation, EditReusableMenuItemMutationVariables> mutation={EditReusableMenuItemDocument} {...props} />
    );
    

/**
 * __useEditReusableMenuItemMutation__
 *
 * To run a mutation, you first call `useEditReusableMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReusableMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReusableMenuItemMutation, { data, loading, error }] = useEditReusableMenuItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditReusableMenuItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditReusableMenuItemMutation, EditReusableMenuItemMutationVariables>) {
        return ApolloReactHooks.useMutation<EditReusableMenuItemMutation, EditReusableMenuItemMutationVariables>(EditReusableMenuItemDocument, baseOptions);
      }
export type EditReusableMenuItemMutationHookResult = ReturnType<typeof useEditReusableMenuItemMutation>;
export type EditReusableMenuItemMutationResult = ApolloReactCommon.MutationResult<EditReusableMenuItemMutation>;
export type EditReusableMenuItemMutationOptions = ApolloReactCommon.BaseMutationOptions<EditReusableMenuItemMutation, EditReusableMenuItemMutationVariables>;
export const EditReusableMenuItemPartialDocument = gql`
    mutation EditReusableMenuItemPartial($input: EditReusableMenuItemPartialInput!) {
  editReusableMenuItemPartial(input: $input) {
    menuItem {
      id
      name
      servingName
      description
      imageUrl
      defaultMaxAmount
      defaultAvailableForOrder
      defaultAllowSpecialRequests
      suggestionCategory
      isMissingIngredients
      score {
        ...ScoreAssignment
      }
      foodCategory {
        ...FoodCategoryName
      }
    }
  }
}
    ${ScoreAssignmentFragmentDoc}
${FoodCategoryNameFragmentDoc}`;
export type EditReusableMenuItemPartialMutationFn = ApolloReactCommon.MutationFunction<EditReusableMenuItemPartialMutation, EditReusableMenuItemPartialMutationVariables>;
export type EditReusableMenuItemPartialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditReusableMenuItemPartialMutation, EditReusableMenuItemPartialMutationVariables>, 'mutation'>;

    export const EditReusableMenuItemPartialComponent = (props: EditReusableMenuItemPartialComponentProps) => (
      <ApolloReactComponents.Mutation<EditReusableMenuItemPartialMutation, EditReusableMenuItemPartialMutationVariables> mutation={EditReusableMenuItemPartialDocument} {...props} />
    );
    

/**
 * __useEditReusableMenuItemPartialMutation__
 *
 * To run a mutation, you first call `useEditReusableMenuItemPartialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReusableMenuItemPartialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReusableMenuItemPartialMutation, { data, loading, error }] = useEditReusableMenuItemPartialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditReusableMenuItemPartialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditReusableMenuItemPartialMutation, EditReusableMenuItemPartialMutationVariables>) {
        return ApolloReactHooks.useMutation<EditReusableMenuItemPartialMutation, EditReusableMenuItemPartialMutationVariables>(EditReusableMenuItemPartialDocument, baseOptions);
      }
export type EditReusableMenuItemPartialMutationHookResult = ReturnType<typeof useEditReusableMenuItemPartialMutation>;
export type EditReusableMenuItemPartialMutationResult = ApolloReactCommon.MutationResult<EditReusableMenuItemPartialMutation>;
export type EditReusableMenuItemPartialMutationOptions = ApolloReactCommon.BaseMutationOptions<EditReusableMenuItemPartialMutation, EditReusableMenuItemPartialMutationVariables>;
export const DeleteReusableMenuItemDocument = gql`
    mutation DeleteReusableMenuItem($input: DeleteReusableMenuItemInput!) {
  deleteReusableMenuItem(input: $input) {
    menuItemId
  }
}
    `;
export type DeleteReusableMenuItemMutationFn = ApolloReactCommon.MutationFunction<DeleteReusableMenuItemMutation, DeleteReusableMenuItemMutationVariables>;
export type DeleteReusableMenuItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteReusableMenuItemMutation, DeleteReusableMenuItemMutationVariables>, 'mutation'>;

    export const DeleteReusableMenuItemComponent = (props: DeleteReusableMenuItemComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteReusableMenuItemMutation, DeleteReusableMenuItemMutationVariables> mutation={DeleteReusableMenuItemDocument} {...props} />
    );
    

/**
 * __useDeleteReusableMenuItemMutation__
 *
 * To run a mutation, you first call `useDeleteReusableMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReusableMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReusableMenuItemMutation, { data, loading, error }] = useDeleteReusableMenuItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReusableMenuItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteReusableMenuItemMutation, DeleteReusableMenuItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteReusableMenuItemMutation, DeleteReusableMenuItemMutationVariables>(DeleteReusableMenuItemDocument, baseOptions);
      }
export type DeleteReusableMenuItemMutationHookResult = ReturnType<typeof useDeleteReusableMenuItemMutation>;
export type DeleteReusableMenuItemMutationResult = ApolloReactCommon.MutationResult<DeleteReusableMenuItemMutation>;
export type DeleteReusableMenuItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReusableMenuItemMutation, DeleteReusableMenuItemMutationVariables>;
export const MenuItemRatingsDocument = gql`
    query MenuItemRatings($menuItemHistoryId: ID!, $input: OffsetPaginationInput!) {
  currentReviews: menuItemRatingOffsetConnection(
    menuItemHistoryId: $menuItemHistoryId
    input: $input
    currentOnly: true
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...RatingForMenuItem
    }
  }
  allReviews: menuItemRatingOffsetConnection(
    menuItemHistoryId: $menuItemHistoryId
    input: $input
    currentOnly: false
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...RatingForMenuItem
    }
  }
}
    ${RatingForMenuItemFragmentDoc}`;
export type MenuItemRatingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuItemRatingsQuery, MenuItemRatingsQueryVariables>, 'query'> & ({ variables: MenuItemRatingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuItemRatingsComponent = (props: MenuItemRatingsComponentProps) => (
      <ApolloReactComponents.Query<MenuItemRatingsQuery, MenuItemRatingsQueryVariables> query={MenuItemRatingsDocument} {...props} />
    );
    

/**
 * __useMenuItemRatingsQuery__
 *
 * To run a query within a React component, call `useMenuItemRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuItemRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuItemRatingsQuery({
 *   variables: {
 *      menuItemHistoryId: // value for 'menuItemHistoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMenuItemRatingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuItemRatingsQuery, MenuItemRatingsQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuItemRatingsQuery, MenuItemRatingsQueryVariables>(MenuItemRatingsDocument, baseOptions);
      }
export function useMenuItemRatingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuItemRatingsQuery, MenuItemRatingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuItemRatingsQuery, MenuItemRatingsQueryVariables>(MenuItemRatingsDocument, baseOptions);
        }
export type MenuItemRatingsQueryHookResult = ReturnType<typeof useMenuItemRatingsQuery>;
export type MenuItemRatingsLazyQueryHookResult = ReturnType<typeof useMenuItemRatingsLazyQuery>;
export type MenuItemRatingsQueryResult = ApolloReactCommon.QueryResult<MenuItemRatingsQuery, MenuItemRatingsQueryVariables>;
export const BonAppetitCafesDocument = gql`
    query BonAppetitCafes {
  bonAppetitCafes {
    ...BonAppetitCafe
  }
}
    ${BonAppetitCafeFragmentDoc}`;
export type BonAppetitCafesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BonAppetitCafesQuery, BonAppetitCafesQueryVariables>, 'query'>;

    export const BonAppetitCafesComponent = (props: BonAppetitCafesComponentProps) => (
      <ApolloReactComponents.Query<BonAppetitCafesQuery, BonAppetitCafesQueryVariables> query={BonAppetitCafesDocument} {...props} />
    );
    

/**
 * __useBonAppetitCafesQuery__
 *
 * To run a query within a React component, call `useBonAppetitCafesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonAppetitCafesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonAppetitCafesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBonAppetitCafesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BonAppetitCafesQuery, BonAppetitCafesQueryVariables>) {
        return ApolloReactHooks.useQuery<BonAppetitCafesQuery, BonAppetitCafesQueryVariables>(BonAppetitCafesDocument, baseOptions);
      }
export function useBonAppetitCafesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BonAppetitCafesQuery, BonAppetitCafesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BonAppetitCafesQuery, BonAppetitCafesQueryVariables>(BonAppetitCafesDocument, baseOptions);
        }
export type BonAppetitCafesQueryHookResult = ReturnType<typeof useBonAppetitCafesQuery>;
export type BonAppetitCafesLazyQueryHookResult = ReturnType<typeof useBonAppetitCafesLazyQuery>;
export type BonAppetitCafesQueryResult = ApolloReactCommon.QueryResult<BonAppetitCafesQuery, BonAppetitCafesQueryVariables>;
export const BonAppetitMenusPreviewDocument = gql`
    query BonAppetitMenusPreview($cafeId: String!, $date: Date!) {
  bonAppetitMenusPreview(cafeId: $cafeId, date: $date) {
    ...BonAppetitMenusPreview
  }
}
    ${BonAppetitMenusPreviewFragmentDoc}`;
export type BonAppetitMenusPreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BonAppetitMenusPreviewQuery, BonAppetitMenusPreviewQueryVariables>, 'query'> & ({ variables: BonAppetitMenusPreviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BonAppetitMenusPreviewComponent = (props: BonAppetitMenusPreviewComponentProps) => (
      <ApolloReactComponents.Query<BonAppetitMenusPreviewQuery, BonAppetitMenusPreviewQueryVariables> query={BonAppetitMenusPreviewDocument} {...props} />
    );
    

/**
 * __useBonAppetitMenusPreviewQuery__
 *
 * To run a query within a React component, call `useBonAppetitMenusPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonAppetitMenusPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonAppetitMenusPreviewQuery({
 *   variables: {
 *      cafeId: // value for 'cafeId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useBonAppetitMenusPreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BonAppetitMenusPreviewQuery, BonAppetitMenusPreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<BonAppetitMenusPreviewQuery, BonAppetitMenusPreviewQueryVariables>(BonAppetitMenusPreviewDocument, baseOptions);
      }
export function useBonAppetitMenusPreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BonAppetitMenusPreviewQuery, BonAppetitMenusPreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BonAppetitMenusPreviewQuery, BonAppetitMenusPreviewQueryVariables>(BonAppetitMenusPreviewDocument, baseOptions);
        }
export type BonAppetitMenusPreviewQueryHookResult = ReturnType<typeof useBonAppetitMenusPreviewQuery>;
export type BonAppetitMenusPreviewLazyQueryHookResult = ReturnType<typeof useBonAppetitMenusPreviewLazyQuery>;
export type BonAppetitMenusPreviewQueryResult = ApolloReactCommon.QueryResult<BonAppetitMenusPreviewQuery, BonAppetitMenusPreviewQueryVariables>;
export const ImportBonAppetitMenusDocument = gql`
    mutation ImportBonAppetitMenus($input: ImportBonAppetitMenusInput!) {
  importBonAppetitMenus(input: $input) {
    mealMenus {
      ...MealMenuCalendar
    }
  }
}
    ${MealMenuCalendarFragmentDoc}`;
export type ImportBonAppetitMenusMutationFn = ApolloReactCommon.MutationFunction<ImportBonAppetitMenusMutation, ImportBonAppetitMenusMutationVariables>;
export type ImportBonAppetitMenusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportBonAppetitMenusMutation, ImportBonAppetitMenusMutationVariables>, 'mutation'>;

    export const ImportBonAppetitMenusComponent = (props: ImportBonAppetitMenusComponentProps) => (
      <ApolloReactComponents.Mutation<ImportBonAppetitMenusMutation, ImportBonAppetitMenusMutationVariables> mutation={ImportBonAppetitMenusDocument} {...props} />
    );
    

/**
 * __useImportBonAppetitMenusMutation__
 *
 * To run a mutation, you first call `useImportBonAppetitMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportBonAppetitMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importBonAppetitMenusMutation, { data, loading, error }] = useImportBonAppetitMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportBonAppetitMenusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportBonAppetitMenusMutation, ImportBonAppetitMenusMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportBonAppetitMenusMutation, ImportBonAppetitMenusMutationVariables>(ImportBonAppetitMenusDocument, baseOptions);
      }
export type ImportBonAppetitMenusMutationHookResult = ReturnType<typeof useImportBonAppetitMenusMutation>;
export type ImportBonAppetitMenusMutationResult = ApolloReactCommon.MutationResult<ImportBonAppetitMenusMutation>;
export type ImportBonAppetitMenusMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportBonAppetitMenusMutation, ImportBonAppetitMenusMutationVariables>;
export const CbordBusinessUnitsDocument = gql`
    query CbordBusinessUnits {
  cbordBusinessUnits {
    ...CbordBusinessUnit
  }
}
    ${CbordBusinessUnitFragmentDoc}`;
export type CbordBusinessUnitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CbordBusinessUnitsQuery, CbordBusinessUnitsQueryVariables>, 'query'>;

    export const CbordBusinessUnitsComponent = (props: CbordBusinessUnitsComponentProps) => (
      <ApolloReactComponents.Query<CbordBusinessUnitsQuery, CbordBusinessUnitsQueryVariables> query={CbordBusinessUnitsDocument} {...props} />
    );
    

/**
 * __useCbordBusinessUnitsQuery__
 *
 * To run a query within a React component, call `useCbordBusinessUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCbordBusinessUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCbordBusinessUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCbordBusinessUnitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CbordBusinessUnitsQuery, CbordBusinessUnitsQueryVariables>) {
        return ApolloReactHooks.useQuery<CbordBusinessUnitsQuery, CbordBusinessUnitsQueryVariables>(CbordBusinessUnitsDocument, baseOptions);
      }
export function useCbordBusinessUnitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CbordBusinessUnitsQuery, CbordBusinessUnitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CbordBusinessUnitsQuery, CbordBusinessUnitsQueryVariables>(CbordBusinessUnitsDocument, baseOptions);
        }
export type CbordBusinessUnitsQueryHookResult = ReturnType<typeof useCbordBusinessUnitsQuery>;
export type CbordBusinessUnitsLazyQueryHookResult = ReturnType<typeof useCbordBusinessUnitsLazyQuery>;
export type CbordBusinessUnitsQueryResult = ApolloReactCommon.QueryResult<CbordBusinessUnitsQuery, CbordBusinessUnitsQueryVariables>;
export const CbordServiceMenusDocument = gql`
    query CbordServiceMenus($serviceUnitOid: Int!, $start: Date!, $end: Date!, $facilityOid: Int) {
  cbordServiceMenus(
    end: $end
    start: $start
    serviceUnitOid: $serviceUnitOid
    facilityOid: $facilityOid
  ) {
    ...CbordServiceMenu
  }
}
    ${CbordServiceMenuFragmentDoc}`;
export type CbordServiceMenusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CbordServiceMenusQuery, CbordServiceMenusQueryVariables>, 'query'> & ({ variables: CbordServiceMenusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CbordServiceMenusComponent = (props: CbordServiceMenusComponentProps) => (
      <ApolloReactComponents.Query<CbordServiceMenusQuery, CbordServiceMenusQueryVariables> query={CbordServiceMenusDocument} {...props} />
    );
    

/**
 * __useCbordServiceMenusQuery__
 *
 * To run a query within a React component, call `useCbordServiceMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCbordServiceMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCbordServiceMenusQuery({
 *   variables: {
 *      serviceUnitOid: // value for 'serviceUnitOid'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      facilityOid: // value for 'facilityOid'
 *   },
 * });
 */
export function useCbordServiceMenusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CbordServiceMenusQuery, CbordServiceMenusQueryVariables>) {
        return ApolloReactHooks.useQuery<CbordServiceMenusQuery, CbordServiceMenusQueryVariables>(CbordServiceMenusDocument, baseOptions);
      }
export function useCbordServiceMenusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CbordServiceMenusQuery, CbordServiceMenusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CbordServiceMenusQuery, CbordServiceMenusQueryVariables>(CbordServiceMenusDocument, baseOptions);
        }
export type CbordServiceMenusQueryHookResult = ReturnType<typeof useCbordServiceMenusQuery>;
export type CbordServiceMenusLazyQueryHookResult = ReturnType<typeof useCbordServiceMenusLazyQuery>;
export type CbordServiceMenusQueryResult = ApolloReactCommon.QueryResult<CbordServiceMenusQuery, CbordServiceMenusQueryVariables>;
export const ImportCbordMenusDocument = gql`
    mutation ImportCbordMenus($input: ImportCbordMenusInput!) {
  importCbordMenus(input: $input) {
    mealMenus {
      ...MealMenuCalendar
    }
  }
}
    ${MealMenuCalendarFragmentDoc}`;
export type ImportCbordMenusMutationFn = ApolloReactCommon.MutationFunction<ImportCbordMenusMutation, ImportCbordMenusMutationVariables>;
export type ImportCbordMenusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportCbordMenusMutation, ImportCbordMenusMutationVariables>, 'mutation'>;

    export const ImportCbordMenusComponent = (props: ImportCbordMenusComponentProps) => (
      <ApolloReactComponents.Mutation<ImportCbordMenusMutation, ImportCbordMenusMutationVariables> mutation={ImportCbordMenusDocument} {...props} />
    );
    

/**
 * __useImportCbordMenusMutation__
 *
 * To run a mutation, you first call `useImportCbordMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCbordMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCbordMenusMutation, { data, loading, error }] = useImportCbordMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCbordMenusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportCbordMenusMutation, ImportCbordMenusMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportCbordMenusMutation, ImportCbordMenusMutationVariables>(ImportCbordMenusDocument, baseOptions);
      }
export type ImportCbordMenusMutationHookResult = ReturnType<typeof useImportCbordMenusMutation>;
export type ImportCbordMenusMutationResult = ApolloReactCommon.MutationResult<ImportCbordMenusMutation>;
export type ImportCbordMenusMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportCbordMenusMutation, ImportCbordMenusMutationVariables>;
export const CompassLocationsDocument = gql`
    query CompassLocations {
  compass_locations {
    ...CompassLocation
  }
}
    ${CompassLocationFragmentDoc}`;
export type CompassLocationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompassLocationsQuery, CompassLocationsQueryVariables>, 'query'>;

    export const CompassLocationsComponent = (props: CompassLocationsComponentProps) => (
      <ApolloReactComponents.Query<CompassLocationsQuery, CompassLocationsQueryVariables> query={CompassLocationsDocument} {...props} />
    );
    

/**
 * __useCompassLocationsQuery__
 *
 * To run a query within a React component, call `useCompassLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompassLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompassLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompassLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompassLocationsQuery, CompassLocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompassLocationsQuery, CompassLocationsQueryVariables>(CompassLocationsDocument, baseOptions);
      }
export function useCompassLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompassLocationsQuery, CompassLocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompassLocationsQuery, CompassLocationsQueryVariables>(CompassLocationsDocument, baseOptions);
        }
export type CompassLocationsQueryHookResult = ReturnType<typeof useCompassLocationsQuery>;
export type CompassLocationsLazyQueryHookResult = ReturnType<typeof useCompassLocationsLazyQuery>;
export type CompassLocationsQueryResult = ApolloReactCommon.QueryResult<CompassLocationsQuery, CompassLocationsQueryVariables>;
export const CompassMenuItemsDocument = gql`
    query CompassMenuItems($locationId: Int!, $startDate: Date!, $days: Int!) {
  compass_menuItems(locationId: $locationId, startDate: $startDate, days: $days) {
    ...CompassMenuItem
  }
}
    ${CompassMenuItemFragmentDoc}`;
export type CompassMenuItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompassMenuItemsQuery, CompassMenuItemsQueryVariables>, 'query'> & ({ variables: CompassMenuItemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompassMenuItemsComponent = (props: CompassMenuItemsComponentProps) => (
      <ApolloReactComponents.Query<CompassMenuItemsQuery, CompassMenuItemsQueryVariables> query={CompassMenuItemsDocument} {...props} />
    );
    

/**
 * __useCompassMenuItemsQuery__
 *
 * To run a query within a React component, call `useCompassMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompassMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompassMenuItemsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startDate: // value for 'startDate'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useCompassMenuItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompassMenuItemsQuery, CompassMenuItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompassMenuItemsQuery, CompassMenuItemsQueryVariables>(CompassMenuItemsDocument, baseOptions);
      }
export function useCompassMenuItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompassMenuItemsQuery, CompassMenuItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompassMenuItemsQuery, CompassMenuItemsQueryVariables>(CompassMenuItemsDocument, baseOptions);
        }
export type CompassMenuItemsQueryHookResult = ReturnType<typeof useCompassMenuItemsQuery>;
export type CompassMenuItemsLazyQueryHookResult = ReturnType<typeof useCompassMenuItemsLazyQuery>;
export type CompassMenuItemsQueryResult = ApolloReactCommon.QueryResult<CompassMenuItemsQuery, CompassMenuItemsQueryVariables>;
export const ImportCompassMenusDocument = gql`
    mutation ImportCompassMenus($input: ImportCompassMenusInput!) {
  importCompassMenus(input: $input) {
    mealMenus {
      ...MealMenuCalendar
    }
  }
}
    ${MealMenuCalendarFragmentDoc}`;
export type ImportCompassMenusMutationFn = ApolloReactCommon.MutationFunction<ImportCompassMenusMutation, ImportCompassMenusMutationVariables>;
export type ImportCompassMenusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportCompassMenusMutation, ImportCompassMenusMutationVariables>, 'mutation'>;

    export const ImportCompassMenusComponent = (props: ImportCompassMenusComponentProps) => (
      <ApolloReactComponents.Mutation<ImportCompassMenusMutation, ImportCompassMenusMutationVariables> mutation={ImportCompassMenusDocument} {...props} />
    );
    

/**
 * __useImportCompassMenusMutation__
 *
 * To run a mutation, you first call `useImportCompassMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCompassMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCompassMenusMutation, { data, loading, error }] = useImportCompassMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCompassMenusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportCompassMenusMutation, ImportCompassMenusMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportCompassMenusMutation, ImportCompassMenusMutationVariables>(ImportCompassMenusDocument, baseOptions);
      }
export type ImportCompassMenusMutationHookResult = ReturnType<typeof useImportCompassMenusMutation>;
export type ImportCompassMenusMutationResult = ApolloReactCommon.MutationResult<ImportCompassMenusMutation>;
export type ImportCompassMenusMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportCompassMenusMutation, ImportCompassMenusMutationVariables>;
export const PlannedMenuWeeksDocument = gql`
    query PlannedMenuWeeks($plannedMenuId: ID!) {
  plannedMenu(id: $plannedMenuId) {
    ...PlannedMenuWithWeeks
  }
}
    ${PlannedMenuWithWeeksFragmentDoc}`;
export type PlannedMenuWeeksComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlannedMenuWeeksQuery, PlannedMenuWeeksQueryVariables>, 'query'> & ({ variables: PlannedMenuWeeksQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlannedMenuWeeksComponent = (props: PlannedMenuWeeksComponentProps) => (
      <ApolloReactComponents.Query<PlannedMenuWeeksQuery, PlannedMenuWeeksQueryVariables> query={PlannedMenuWeeksDocument} {...props} />
    );
    

/**
 * __usePlannedMenuWeeksQuery__
 *
 * To run a query within a React component, call `usePlannedMenuWeeksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannedMenuWeeksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannedMenuWeeksQuery({
 *   variables: {
 *      plannedMenuId: // value for 'plannedMenuId'
 *   },
 * });
 */
export function usePlannedMenuWeeksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlannedMenuWeeksQuery, PlannedMenuWeeksQueryVariables>) {
        return ApolloReactHooks.useQuery<PlannedMenuWeeksQuery, PlannedMenuWeeksQueryVariables>(PlannedMenuWeeksDocument, baseOptions);
      }
export function usePlannedMenuWeeksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlannedMenuWeeksQuery, PlannedMenuWeeksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlannedMenuWeeksQuery, PlannedMenuWeeksQueryVariables>(PlannedMenuWeeksDocument, baseOptions);
        }
export type PlannedMenuWeeksQueryHookResult = ReturnType<typeof usePlannedMenuWeeksQuery>;
export type PlannedMenuWeeksLazyQueryHookResult = ReturnType<typeof usePlannedMenuWeeksLazyQuery>;
export type PlannedMenuWeeksQueryResult = ApolloReactCommon.QueryResult<PlannedMenuWeeksQuery, PlannedMenuWeeksQueryVariables>;
export const PlannedMenuWeeksForImportDocument = gql`
    query PlannedMenuWeeksForImport($plannedMenuWeekIds: [ID!]!) {
  plannedMenuWeeks(plannedMenuWeekIds: $plannedMenuWeekIds) {
    ...PlannedMenuWeekForImport
  }
}
    ${PlannedMenuWeekForImportFragmentDoc}`;
export type PlannedMenuWeeksForImportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlannedMenuWeeksForImportQuery, PlannedMenuWeeksForImportQueryVariables>, 'query'> & ({ variables: PlannedMenuWeeksForImportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlannedMenuWeeksForImportComponent = (props: PlannedMenuWeeksForImportComponentProps) => (
      <ApolloReactComponents.Query<PlannedMenuWeeksForImportQuery, PlannedMenuWeeksForImportQueryVariables> query={PlannedMenuWeeksForImportDocument} {...props} />
    );
    

/**
 * __usePlannedMenuWeeksForImportQuery__
 *
 * To run a query within a React component, call `usePlannedMenuWeeksForImportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannedMenuWeeksForImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannedMenuWeeksForImportQuery({
 *   variables: {
 *      plannedMenuWeekIds: // value for 'plannedMenuWeekIds'
 *   },
 * });
 */
export function usePlannedMenuWeeksForImportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlannedMenuWeeksForImportQuery, PlannedMenuWeeksForImportQueryVariables>) {
        return ApolloReactHooks.useQuery<PlannedMenuWeeksForImportQuery, PlannedMenuWeeksForImportQueryVariables>(PlannedMenuWeeksForImportDocument, baseOptions);
      }
export function usePlannedMenuWeeksForImportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlannedMenuWeeksForImportQuery, PlannedMenuWeeksForImportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlannedMenuWeeksForImportQuery, PlannedMenuWeeksForImportQueryVariables>(PlannedMenuWeeksForImportDocument, baseOptions);
        }
export type PlannedMenuWeeksForImportQueryHookResult = ReturnType<typeof usePlannedMenuWeeksForImportQuery>;
export type PlannedMenuWeeksForImportLazyQueryHookResult = ReturnType<typeof usePlannedMenuWeeksForImportLazyQuery>;
export type PlannedMenuWeeksForImportQueryResult = ApolloReactCommon.QueryResult<PlannedMenuWeeksForImportQuery, PlannedMenuWeeksForImportQueryVariables>;
export const SodexoMenuSourcesDocument = gql`
    query SodexoMenuSources {
  sodexoMenuSources {
    ...SodexoMenuSource
  }
}
    ${SodexoMenuSourceFragmentDoc}`;
export type SodexoMenuSourcesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SodexoMenuSourcesQuery, SodexoMenuSourcesQueryVariables>, 'query'>;

    export const SodexoMenuSourcesComponent = (props: SodexoMenuSourcesComponentProps) => (
      <ApolloReactComponents.Query<SodexoMenuSourcesQuery, SodexoMenuSourcesQueryVariables> query={SodexoMenuSourcesDocument} {...props} />
    );
    

/**
 * __useSodexoMenuSourcesQuery__
 *
 * To run a query within a React component, call `useSodexoMenuSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSodexoMenuSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSodexoMenuSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSodexoMenuSourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SodexoMenuSourcesQuery, SodexoMenuSourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<SodexoMenuSourcesQuery, SodexoMenuSourcesQueryVariables>(SodexoMenuSourcesDocument, baseOptions);
      }
export function useSodexoMenuSourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SodexoMenuSourcesQuery, SodexoMenuSourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SodexoMenuSourcesQuery, SodexoMenuSourcesQueryVariables>(SodexoMenuSourcesDocument, baseOptions);
        }
export type SodexoMenuSourcesQueryHookResult = ReturnType<typeof useSodexoMenuSourcesQuery>;
export type SodexoMenuSourcesLazyQueryHookResult = ReturnType<typeof useSodexoMenuSourcesLazyQuery>;
export type SodexoMenuSourcesQueryResult = ApolloReactCommon.QueryResult<SodexoMenuSourcesQuery, SodexoMenuSourcesQueryVariables>;
export const SodexoMealTimesByDayDocument = gql`
    query SodexoMealTimesByDay($locationId: String!, $menuSourceKey: String!, $startDate: Date!, $endDate: Date!) {
  sodexoMealTimesByDay(
    locationId: $locationId
    menuSourceKey: $menuSourceKey
    startDate: $startDate
    endDate: $endDate
  ) {
    ...SodexoMealTimesByDay
  }
}
    ${SodexoMealTimesByDayFragmentDoc}`;
export type SodexoMealTimesByDayComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SodexoMealTimesByDayQuery, SodexoMealTimesByDayQueryVariables>, 'query'> & ({ variables: SodexoMealTimesByDayQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SodexoMealTimesByDayComponent = (props: SodexoMealTimesByDayComponentProps) => (
      <ApolloReactComponents.Query<SodexoMealTimesByDayQuery, SodexoMealTimesByDayQueryVariables> query={SodexoMealTimesByDayDocument} {...props} />
    );
    

/**
 * __useSodexoMealTimesByDayQuery__
 *
 * To run a query within a React component, call `useSodexoMealTimesByDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useSodexoMealTimesByDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSodexoMealTimesByDayQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      menuSourceKey: // value for 'menuSourceKey'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useSodexoMealTimesByDayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SodexoMealTimesByDayQuery, SodexoMealTimesByDayQueryVariables>) {
        return ApolloReactHooks.useQuery<SodexoMealTimesByDayQuery, SodexoMealTimesByDayQueryVariables>(SodexoMealTimesByDayDocument, baseOptions);
      }
export function useSodexoMealTimesByDayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SodexoMealTimesByDayQuery, SodexoMealTimesByDayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SodexoMealTimesByDayQuery, SodexoMealTimesByDayQueryVariables>(SodexoMealTimesByDayDocument, baseOptions);
        }
export type SodexoMealTimesByDayQueryHookResult = ReturnType<typeof useSodexoMealTimesByDayQuery>;
export type SodexoMealTimesByDayLazyQueryHookResult = ReturnType<typeof useSodexoMealTimesByDayLazyQuery>;
export type SodexoMealTimesByDayQueryResult = ApolloReactCommon.QueryResult<SodexoMealTimesByDayQuery, SodexoMealTimesByDayQueryVariables>;
export const ImportSodexoMenusDocument = gql`
    mutation ImportSodexoMenus($input: ImportSodexoMenusInput!) {
  importSodexoMenus(input: $input) {
    mealMenus {
      ...MealMenuCalendar
    }
  }
}
    ${MealMenuCalendarFragmentDoc}`;
export type ImportSodexoMenusMutationFn = ApolloReactCommon.MutationFunction<ImportSodexoMenusMutation, ImportSodexoMenusMutationVariables>;
export type ImportSodexoMenusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportSodexoMenusMutation, ImportSodexoMenusMutationVariables>, 'mutation'>;

    export const ImportSodexoMenusComponent = (props: ImportSodexoMenusComponentProps) => (
      <ApolloReactComponents.Mutation<ImportSodexoMenusMutation, ImportSodexoMenusMutationVariables> mutation={ImportSodexoMenusDocument} {...props} />
    );
    

/**
 * __useImportSodexoMenusMutation__
 *
 * To run a mutation, you first call `useImportSodexoMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSodexoMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSodexoMenusMutation, { data, loading, error }] = useImportSodexoMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportSodexoMenusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportSodexoMenusMutation, ImportSodexoMenusMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportSodexoMenusMutation, ImportSodexoMenusMutationVariables>(ImportSodexoMenusDocument, baseOptions);
      }
export type ImportSodexoMenusMutationHookResult = ReturnType<typeof useImportSodexoMenusMutation>;
export type ImportSodexoMenusMutationResult = ApolloReactCommon.MutationResult<ImportSodexoMenusMutation>;
export type ImportSodexoMenusMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportSodexoMenusMutation, ImportSodexoMenusMutationVariables>;
export const MealMenuIngredientsAnalyzerDocument = gql`
    query MealMenuIngredientsAnalyzer($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    ...MealMenuIngredientsAnalyzer
  }
}
    ${MealMenuIngredientsAnalyzerFragmentDoc}`;
export type MealMenuIngredientsAnalyzerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenuIngredientsAnalyzerQuery, MealMenuIngredientsAnalyzerQueryVariables>, 'query'> & ({ variables: MealMenuIngredientsAnalyzerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenuIngredientsAnalyzerComponent = (props: MealMenuIngredientsAnalyzerComponentProps) => (
      <ApolloReactComponents.Query<MealMenuIngredientsAnalyzerQuery, MealMenuIngredientsAnalyzerQueryVariables> query={MealMenuIngredientsAnalyzerDocument} {...props} />
    );
    

/**
 * __useMealMenuIngredientsAnalyzerQuery__
 *
 * To run a query within a React component, call `useMealMenuIngredientsAnalyzerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuIngredientsAnalyzerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuIngredientsAnalyzerQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMealMenuIngredientsAnalyzerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenuIngredientsAnalyzerQuery, MealMenuIngredientsAnalyzerQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenuIngredientsAnalyzerQuery, MealMenuIngredientsAnalyzerQueryVariables>(MealMenuIngredientsAnalyzerDocument, baseOptions);
      }
export function useMealMenuIngredientsAnalyzerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenuIngredientsAnalyzerQuery, MealMenuIngredientsAnalyzerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenuIngredientsAnalyzerQuery, MealMenuIngredientsAnalyzerQueryVariables>(MealMenuIngredientsAnalyzerDocument, baseOptions);
        }
export type MealMenuIngredientsAnalyzerQueryHookResult = ReturnType<typeof useMealMenuIngredientsAnalyzerQuery>;
export type MealMenuIngredientsAnalyzerLazyQueryHookResult = ReturnType<typeof useMealMenuIngredientsAnalyzerLazyQuery>;
export type MealMenuIngredientsAnalyzerQueryResult = ApolloReactCommon.QueryResult<MealMenuIngredientsAnalyzerQuery, MealMenuIngredientsAnalyzerQueryVariables>;
export const MealMenuMissingOrdersDialogDocument = gql`
    query MealMenuMissingOrdersDialog($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    ...MealMenuMissingOrders
  }
}
    ${MealMenuMissingOrdersFragmentDoc}`;
export type MealMenuMissingOrdersDialogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenuMissingOrdersDialogQuery, MealMenuMissingOrdersDialogQueryVariables>, 'query'> & ({ variables: MealMenuMissingOrdersDialogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenuMissingOrdersDialogComponent = (props: MealMenuMissingOrdersDialogComponentProps) => (
      <ApolloReactComponents.Query<MealMenuMissingOrdersDialogQuery, MealMenuMissingOrdersDialogQueryVariables> query={MealMenuMissingOrdersDialogDocument} {...props} />
    );
    

/**
 * __useMealMenuMissingOrdersDialogQuery__
 *
 * To run a query within a React component, call `useMealMenuMissingOrdersDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuMissingOrdersDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuMissingOrdersDialogQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMealMenuMissingOrdersDialogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenuMissingOrdersDialogQuery, MealMenuMissingOrdersDialogQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenuMissingOrdersDialogQuery, MealMenuMissingOrdersDialogQueryVariables>(MealMenuMissingOrdersDialogDocument, baseOptions);
      }
export function useMealMenuMissingOrdersDialogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenuMissingOrdersDialogQuery, MealMenuMissingOrdersDialogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenuMissingOrdersDialogQuery, MealMenuMissingOrdersDialogQueryVariables>(MealMenuMissingOrdersDialogDocument, baseOptions);
        }
export type MealMenuMissingOrdersDialogQueryHookResult = ReturnType<typeof useMealMenuMissingOrdersDialogQuery>;
export type MealMenuMissingOrdersDialogLazyQueryHookResult = ReturnType<typeof useMealMenuMissingOrdersDialogLazyQuery>;
export type MealMenuMissingOrdersDialogQueryResult = ApolloReactCommon.QueryResult<MealMenuMissingOrdersDialogQuery, MealMenuMissingOrdersDialogQueryVariables>;
export const BulkOrdersForMealMenuDocument = gql`
    query BulkOrdersForMealMenu($mealMenuId: ID!) {
  bulkOrdersForMealMenu(mealMenuId: $mealMenuId) {
    ...BulkOrder
  }
}
    ${BulkOrderFragmentDoc}`;
export type BulkOrdersForMealMenuComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BulkOrdersForMealMenuQuery, BulkOrdersForMealMenuQueryVariables>, 'query'> & ({ variables: BulkOrdersForMealMenuQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BulkOrdersForMealMenuComponent = (props: BulkOrdersForMealMenuComponentProps) => (
      <ApolloReactComponents.Query<BulkOrdersForMealMenuQuery, BulkOrdersForMealMenuQueryVariables> query={BulkOrdersForMealMenuDocument} {...props} />
    );
    

/**
 * __useBulkOrdersForMealMenuQuery__
 *
 * To run a query within a React component, call `useBulkOrdersForMealMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkOrdersForMealMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkOrdersForMealMenuQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useBulkOrdersForMealMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BulkOrdersForMealMenuQuery, BulkOrdersForMealMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<BulkOrdersForMealMenuQuery, BulkOrdersForMealMenuQueryVariables>(BulkOrdersForMealMenuDocument, baseOptions);
      }
export function useBulkOrdersForMealMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BulkOrdersForMealMenuQuery, BulkOrdersForMealMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BulkOrdersForMealMenuQuery, BulkOrdersForMealMenuQueryVariables>(BulkOrdersForMealMenuDocument, baseOptions);
        }
export type BulkOrdersForMealMenuQueryHookResult = ReturnType<typeof useBulkOrdersForMealMenuQuery>;
export type BulkOrdersForMealMenuLazyQueryHookResult = ReturnType<typeof useBulkOrdersForMealMenuLazyQuery>;
export type BulkOrdersForMealMenuQueryResult = ApolloReactCommon.QueryResult<BulkOrdersForMealMenuQuery, BulkOrdersForMealMenuQueryVariables>;
export const MenuSuggestionsPreviewDocument = gql`
    query MenuSuggestionsPreview($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    id
    type
    athletes {
      id
      firstName
      lastName
      mealPlans {
        id
      }
      team {
        id
        name
      }
    }
  }
}
    `;
export type MenuSuggestionsPreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuSuggestionsPreviewQuery, MenuSuggestionsPreviewQueryVariables>, 'query'> & ({ variables: MenuSuggestionsPreviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuSuggestionsPreviewComponent = (props: MenuSuggestionsPreviewComponentProps) => (
      <ApolloReactComponents.Query<MenuSuggestionsPreviewQuery, MenuSuggestionsPreviewQueryVariables> query={MenuSuggestionsPreviewDocument} {...props} />
    );
    

/**
 * __useMenuSuggestionsPreviewQuery__
 *
 * To run a query within a React component, call `useMenuSuggestionsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuSuggestionsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuSuggestionsPreviewQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMenuSuggestionsPreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuSuggestionsPreviewQuery, MenuSuggestionsPreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuSuggestionsPreviewQuery, MenuSuggestionsPreviewQueryVariables>(MenuSuggestionsPreviewDocument, baseOptions);
      }
export function useMenuSuggestionsPreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuSuggestionsPreviewQuery, MenuSuggestionsPreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuSuggestionsPreviewQuery, MenuSuggestionsPreviewQueryVariables>(MenuSuggestionsPreviewDocument, baseOptions);
        }
export type MenuSuggestionsPreviewQueryHookResult = ReturnType<typeof useMenuSuggestionsPreviewQuery>;
export type MenuSuggestionsPreviewLazyQueryHookResult = ReturnType<typeof useMenuSuggestionsPreviewLazyQuery>;
export type MenuSuggestionsPreviewQueryResult = ApolloReactCommon.QueryResult<MenuSuggestionsPreviewQuery, MenuSuggestionsPreviewQueryVariables>;
export const AthleteForMenuSuggestionPreviewDocument = gql`
    query AthleteForMenuSuggestionPreview($athleteId: ID!) {
  athlete(id: $athleteId) {
    ...AthleteMenuSuggestionsPreview
  }
}
    ${AthleteMenuSuggestionsPreviewFragmentDoc}`;
export type AthleteForMenuSuggestionPreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteForMenuSuggestionPreviewQuery, AthleteForMenuSuggestionPreviewQueryVariables>, 'query'> & ({ variables: AthleteForMenuSuggestionPreviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteForMenuSuggestionPreviewComponent = (props: AthleteForMenuSuggestionPreviewComponentProps) => (
      <ApolloReactComponents.Query<AthleteForMenuSuggestionPreviewQuery, AthleteForMenuSuggestionPreviewQueryVariables> query={AthleteForMenuSuggestionPreviewDocument} {...props} />
    );
    

/**
 * __useAthleteForMenuSuggestionPreviewQuery__
 *
 * To run a query within a React component, call `useAthleteForMenuSuggestionPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteForMenuSuggestionPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteForMenuSuggestionPreviewQuery({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useAthleteForMenuSuggestionPreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteForMenuSuggestionPreviewQuery, AthleteForMenuSuggestionPreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteForMenuSuggestionPreviewQuery, AthleteForMenuSuggestionPreviewQueryVariables>(AthleteForMenuSuggestionPreviewDocument, baseOptions);
      }
export function useAthleteForMenuSuggestionPreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteForMenuSuggestionPreviewQuery, AthleteForMenuSuggestionPreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteForMenuSuggestionPreviewQuery, AthleteForMenuSuggestionPreviewQueryVariables>(AthleteForMenuSuggestionPreviewDocument, baseOptions);
        }
export type AthleteForMenuSuggestionPreviewQueryHookResult = ReturnType<typeof useAthleteForMenuSuggestionPreviewQuery>;
export type AthleteForMenuSuggestionPreviewLazyQueryHookResult = ReturnType<typeof useAthleteForMenuSuggestionPreviewLazyQuery>;
export type AthleteForMenuSuggestionPreviewQueryResult = ApolloReactCommon.QueryResult<AthleteForMenuSuggestionPreviewQuery, AthleteForMenuSuggestionPreviewQueryVariables>;
export const MealMenusInDateRangeDocument = gql`
    query MealMenusInDateRange($after: Date!, $before: Date!, $clientTimezone: Timezone!) {
  mealMenusInDateRange(after: $after, before: $before, timezone: $clientTimezone) {
    ...MealMenuCalendar
  }
}
    ${MealMenuCalendarFragmentDoc}`;
export type MealMenusInDateRangeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>, 'query'> & ({ variables: MealMenusInDateRangeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenusInDateRangeComponent = (props: MealMenusInDateRangeComponentProps) => (
      <ApolloReactComponents.Query<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables> query={MealMenusInDateRangeDocument} {...props} />
    );
    

/**
 * __useMealMenusInDateRangeQuery__
 *
 * To run a query within a React component, call `useMealMenusInDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenusInDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenusInDateRangeQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      clientTimezone: // value for 'clientTimezone'
 *   },
 * });
 */
export function useMealMenusInDateRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>(MealMenusInDateRangeDocument, baseOptions);
      }
export function useMealMenusInDateRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>(MealMenusInDateRangeDocument, baseOptions);
        }
export type MealMenusInDateRangeQueryHookResult = ReturnType<typeof useMealMenusInDateRangeQuery>;
export type MealMenusInDateRangeLazyQueryHookResult = ReturnType<typeof useMealMenusInDateRangeLazyQuery>;
export type MealMenusInDateRangeQueryResult = ApolloReactCommon.QueryResult<MealMenusInDateRangeQuery, MealMenusInDateRangeQueryVariables>;
export const MealMenusByIdsDocument = gql`
    query MealMenusByIds($mealMenuIds: [ID!]!) {
  mealMenus(mealMenuIds: $mealMenuIds) {
    ...MealMenuCalendar
  }
}
    ${MealMenuCalendarFragmentDoc}`;
export type MealMenusByIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenusByIdsQuery, MealMenusByIdsQueryVariables>, 'query'> & ({ variables: MealMenusByIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenusByIdsComponent = (props: MealMenusByIdsComponentProps) => (
      <ApolloReactComponents.Query<MealMenusByIdsQuery, MealMenusByIdsQueryVariables> query={MealMenusByIdsDocument} {...props} />
    );
    

/**
 * __useMealMenusByIdsQuery__
 *
 * To run a query within a React component, call `useMealMenusByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenusByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenusByIdsQuery({
 *   variables: {
 *      mealMenuIds: // value for 'mealMenuIds'
 *   },
 * });
 */
export function useMealMenusByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenusByIdsQuery, MealMenusByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenusByIdsQuery, MealMenusByIdsQueryVariables>(MealMenusByIdsDocument, baseOptions);
      }
export function useMealMenusByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenusByIdsQuery, MealMenusByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenusByIdsQuery, MealMenusByIdsQueryVariables>(MealMenusByIdsDocument, baseOptions);
        }
export type MealMenusByIdsQueryHookResult = ReturnType<typeof useMealMenusByIdsQuery>;
export type MealMenusByIdsLazyQueryHookResult = ReturnType<typeof useMealMenusByIdsLazyQuery>;
export type MealMenusByIdsQueryResult = ApolloReactCommon.QueryResult<MealMenusByIdsQuery, MealMenusByIdsQueryVariables>;
export const MenuCalendarDocument = gql`
    query MenuCalendar {
  teams {
    ...TeamMealMenuPreview
  }
}
    ${TeamMealMenuPreviewFragmentDoc}`;
export type MenuCalendarComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuCalendarQuery, MenuCalendarQueryVariables>, 'query'>;

    export const MenuCalendarComponent = (props: MenuCalendarComponentProps) => (
      <ApolloReactComponents.Query<MenuCalendarQuery, MenuCalendarQueryVariables> query={MenuCalendarDocument} {...props} />
    );
    

/**
 * __useMenuCalendarQuery__
 *
 * To run a query within a React component, call `useMenuCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuCalendarQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuCalendarQuery, MenuCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuCalendarQuery, MenuCalendarQueryVariables>(MenuCalendarDocument, baseOptions);
      }
export function useMenuCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuCalendarQuery, MenuCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuCalendarQuery, MenuCalendarQueryVariables>(MenuCalendarDocument, baseOptions);
        }
export type MenuCalendarQueryHookResult = ReturnType<typeof useMenuCalendarQuery>;
export type MenuCalendarLazyQueryHookResult = ReturnType<typeof useMenuCalendarLazyQuery>;
export type MenuCalendarQueryResult = ApolloReactCommon.QueryResult<MenuCalendarQuery, MenuCalendarQueryVariables>;
export const MealMenuPaperDocument = gql`
    query MealMenuPaper($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    ...MealMenuModal
  }
}
    ${MealMenuModalFragmentDoc}`;
export type MealMenuPaperComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenuPaperQuery, MealMenuPaperQueryVariables>, 'query'> & ({ variables: MealMenuPaperQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenuPaperComponent = (props: MealMenuPaperComponentProps) => (
      <ApolloReactComponents.Query<MealMenuPaperQuery, MealMenuPaperQueryVariables> query={MealMenuPaperDocument} {...props} />
    );
    

/**
 * __useMealMenuPaperQuery__
 *
 * To run a query within a React component, call `useMealMenuPaperQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuPaperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuPaperQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMealMenuPaperQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenuPaperQuery, MealMenuPaperQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenuPaperQuery, MealMenuPaperQueryVariables>(MealMenuPaperDocument, baseOptions);
      }
export function useMealMenuPaperLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenuPaperQuery, MealMenuPaperQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenuPaperQuery, MealMenuPaperQueryVariables>(MealMenuPaperDocument, baseOptions);
        }
export type MealMenuPaperQueryHookResult = ReturnType<typeof useMealMenuPaperQuery>;
export type MealMenuPaperLazyQueryHookResult = ReturnType<typeof useMealMenuPaperLazyQuery>;
export type MealMenuPaperQueryResult = ApolloReactCommon.QueryResult<MealMenuPaperQuery, MealMenuPaperQueryVariables>;
export const FinishedMealMenuPaperDocument = gql`
    query FinishedMealMenuPaper($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    ...CurrentMealMenu
  }
}
    ${CurrentMealMenuFragmentDoc}`;
export type FinishedMealMenuPaperComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FinishedMealMenuPaperQuery, FinishedMealMenuPaperQueryVariables>, 'query'> & ({ variables: FinishedMealMenuPaperQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FinishedMealMenuPaperComponent = (props: FinishedMealMenuPaperComponentProps) => (
      <ApolloReactComponents.Query<FinishedMealMenuPaperQuery, FinishedMealMenuPaperQueryVariables> query={FinishedMealMenuPaperDocument} {...props} />
    );
    

/**
 * __useFinishedMealMenuPaperQuery__
 *
 * To run a query within a React component, call `useFinishedMealMenuPaperQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinishedMealMenuPaperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinishedMealMenuPaperQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useFinishedMealMenuPaperQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FinishedMealMenuPaperQuery, FinishedMealMenuPaperQueryVariables>) {
        return ApolloReactHooks.useQuery<FinishedMealMenuPaperQuery, FinishedMealMenuPaperQueryVariables>(FinishedMealMenuPaperDocument, baseOptions);
      }
export function useFinishedMealMenuPaperLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FinishedMealMenuPaperQuery, FinishedMealMenuPaperQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FinishedMealMenuPaperQuery, FinishedMealMenuPaperQueryVariables>(FinishedMealMenuPaperDocument, baseOptions);
        }
export type FinishedMealMenuPaperQueryHookResult = ReturnType<typeof useFinishedMealMenuPaperQuery>;
export type FinishedMealMenuPaperLazyQueryHookResult = ReturnType<typeof useFinishedMealMenuPaperLazyQuery>;
export type FinishedMealMenuPaperQueryResult = ApolloReactCommon.QueryResult<FinishedMealMenuPaperQuery, FinishedMealMenuPaperQueryVariables>;
export const RestaurantMenuDocument = gql`
    query RestaurantMenu($id: ID!) {
  restaurantMenu(id: $id) {
    ...RestaurantMenuForm
  }
}
    ${RestaurantMenuFormFragmentDoc}`;
export type RestaurantMenuComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantMenuQuery, RestaurantMenuQueryVariables>, 'query'> & ({ variables: RestaurantMenuQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantMenuComponent = (props: RestaurantMenuComponentProps) => (
      <ApolloReactComponents.Query<RestaurantMenuQuery, RestaurantMenuQueryVariables> query={RestaurantMenuDocument} {...props} />
    );
    

/**
 * __useRestaurantMenuQuery__
 *
 * To run a query within a React component, call `useRestaurantMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantMenuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestaurantMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantMenuQuery, RestaurantMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantMenuQuery, RestaurantMenuQueryVariables>(RestaurantMenuDocument, baseOptions);
      }
export function useRestaurantMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantMenuQuery, RestaurantMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantMenuQuery, RestaurantMenuQueryVariables>(RestaurantMenuDocument, baseOptions);
        }
export type RestaurantMenuQueryHookResult = ReturnType<typeof useRestaurantMenuQuery>;
export type RestaurantMenuLazyQueryHookResult = ReturnType<typeof useRestaurantMenuLazyQuery>;
export type RestaurantMenuQueryResult = ApolloReactCommon.QueryResult<RestaurantMenuQuery, RestaurantMenuQueryVariables>;
export const CreateMealMenusDocument = gql`
    mutation CreateMealMenus($input: CreateMealMenusInput!) {
  createMealMenus(input: $input) {
    mealMenus {
      ...MealMenuModal
    }
  }
}
    ${MealMenuModalFragmentDoc}`;
export type CreateMealMenusMutationFn = ApolloReactCommon.MutationFunction<CreateMealMenusMutation, CreateMealMenusMutationVariables>;
export type CreateMealMenusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMealMenusMutation, CreateMealMenusMutationVariables>, 'mutation'>;

    export const CreateMealMenusComponent = (props: CreateMealMenusComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMealMenusMutation, CreateMealMenusMutationVariables> mutation={CreateMealMenusDocument} {...props} />
    );
    

/**
 * __useCreateMealMenusMutation__
 *
 * To run a mutation, you first call `useCreateMealMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMealMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMealMenusMutation, { data, loading, error }] = useCreateMealMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMealMenusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMealMenusMutation, CreateMealMenusMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMealMenusMutation, CreateMealMenusMutationVariables>(CreateMealMenusDocument, baseOptions);
      }
export type CreateMealMenusMutationHookResult = ReturnType<typeof useCreateMealMenusMutation>;
export type CreateMealMenusMutationResult = ApolloReactCommon.MutationResult<CreateMealMenusMutation>;
export type CreateMealMenusMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMealMenusMutation, CreateMealMenusMutationVariables>;
export const EditMealMenuDocument = gql`
    mutation EditMealMenu($input: EditMealMenuInput!) {
  editMealMenu(input: $input) {
    mealMenu {
      ...MealMenuModal
    }
  }
}
    ${MealMenuModalFragmentDoc}`;
export type EditMealMenuMutationFn = ApolloReactCommon.MutationFunction<EditMealMenuMutation, EditMealMenuMutationVariables>;
export type EditMealMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealMenuMutation, EditMealMenuMutationVariables>, 'mutation'>;

    export const EditMealMenuComponent = (props: EditMealMenuComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealMenuMutation, EditMealMenuMutationVariables> mutation={EditMealMenuDocument} {...props} />
    );
    

/**
 * __useEditMealMenuMutation__
 *
 * To run a mutation, you first call `useEditMealMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealMenuMutation, { data, loading, error }] = useEditMealMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealMenuMutation, EditMealMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealMenuMutation, EditMealMenuMutationVariables>(EditMealMenuDocument, baseOptions);
      }
export type EditMealMenuMutationHookResult = ReturnType<typeof useEditMealMenuMutation>;
export type EditMealMenuMutationResult = ApolloReactCommon.MutationResult<EditMealMenuMutation>;
export type EditMealMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealMenuMutation, EditMealMenuMutationVariables>;
export const EditMealMenuWithIdentityDocument = gql`
    mutation EditMealMenuWithIdentity($input: EditMealMenuWithIdentityInput!) {
  editMealMenuWithIdentity(input: $input) {
    mealMenu {
      ...MealMenuModal
    }
  }
}
    ${MealMenuModalFragmentDoc}`;
export type EditMealMenuWithIdentityMutationFn = ApolloReactCommon.MutationFunction<EditMealMenuWithIdentityMutation, EditMealMenuWithIdentityMutationVariables>;
export type EditMealMenuWithIdentityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealMenuWithIdentityMutation, EditMealMenuWithIdentityMutationVariables>, 'mutation'>;

    export const EditMealMenuWithIdentityComponent = (props: EditMealMenuWithIdentityComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealMenuWithIdentityMutation, EditMealMenuWithIdentityMutationVariables> mutation={EditMealMenuWithIdentityDocument} {...props} />
    );
    

/**
 * __useEditMealMenuWithIdentityMutation__
 *
 * To run a mutation, you first call `useEditMealMenuWithIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealMenuWithIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealMenuWithIdentityMutation, { data, loading, error }] = useEditMealMenuWithIdentityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealMenuWithIdentityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealMenuWithIdentityMutation, EditMealMenuWithIdentityMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealMenuWithIdentityMutation, EditMealMenuWithIdentityMutationVariables>(EditMealMenuWithIdentityDocument, baseOptions);
      }
export type EditMealMenuWithIdentityMutationHookResult = ReturnType<typeof useEditMealMenuWithIdentityMutation>;
export type EditMealMenuWithIdentityMutationResult = ApolloReactCommon.MutationResult<EditMealMenuWithIdentityMutation>;
export type EditMealMenuWithIdentityMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealMenuWithIdentityMutation, EditMealMenuWithIdentityMutationVariables>;
export const BulkEditMealMenusDocument = gql`
    mutation BulkEditMealMenus($input: BulkEditMealMenusInput!) {
  bulkEditMealMenus(input: $input) {
    mealMenus {
      ...MealMenuModal
    }
  }
}
    ${MealMenuModalFragmentDoc}`;
export type BulkEditMealMenusMutationFn = ApolloReactCommon.MutationFunction<BulkEditMealMenusMutation, BulkEditMealMenusMutationVariables>;
export type BulkEditMealMenusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BulkEditMealMenusMutation, BulkEditMealMenusMutationVariables>, 'mutation'>;

    export const BulkEditMealMenusComponent = (props: BulkEditMealMenusComponentProps) => (
      <ApolloReactComponents.Mutation<BulkEditMealMenusMutation, BulkEditMealMenusMutationVariables> mutation={BulkEditMealMenusDocument} {...props} />
    );
    

/**
 * __useBulkEditMealMenusMutation__
 *
 * To run a mutation, you first call `useBulkEditMealMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditMealMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditMealMenusMutation, { data, loading, error }] = useBulkEditMealMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditMealMenusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkEditMealMenusMutation, BulkEditMealMenusMutationVariables>) {
        return ApolloReactHooks.useMutation<BulkEditMealMenusMutation, BulkEditMealMenusMutationVariables>(BulkEditMealMenusDocument, baseOptions);
      }
export type BulkEditMealMenusMutationHookResult = ReturnType<typeof useBulkEditMealMenusMutation>;
export type BulkEditMealMenusMutationResult = ApolloReactCommon.MutationResult<BulkEditMealMenusMutation>;
export type BulkEditMealMenusMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkEditMealMenusMutation, BulkEditMealMenusMutationVariables>;
export const DeleteMealMenuDocument = gql`
    mutation DeleteMealMenu($input: DeleteMealMenuInput!) {
  deleteMealMenu(input: $input) {
    mealMenuId
  }
}
    `;
export type DeleteMealMenuMutationFn = ApolloReactCommon.MutationFunction<DeleteMealMenuMutation, DeleteMealMenuMutationVariables>;
export type DeleteMealMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteMealMenuMutation, DeleteMealMenuMutationVariables>, 'mutation'>;

    export const DeleteMealMenuComponent = (props: DeleteMealMenuComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteMealMenuMutation, DeleteMealMenuMutationVariables> mutation={DeleteMealMenuDocument} {...props} />
    );
    

/**
 * __useDeleteMealMenuMutation__
 *
 * To run a mutation, you first call `useDeleteMealMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMealMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMealMenuMutation, { data, loading, error }] = useDeleteMealMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMealMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMealMenuMutation, DeleteMealMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMealMenuMutation, DeleteMealMenuMutationVariables>(DeleteMealMenuDocument, baseOptions);
      }
export type DeleteMealMenuMutationHookResult = ReturnType<typeof useDeleteMealMenuMutation>;
export type DeleteMealMenuMutationResult = ApolloReactCommon.MutationResult<DeleteMealMenuMutation>;
export type DeleteMealMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMealMenuMutation, DeleteMealMenuMutationVariables>;
export const BulkDeleteMealMenusDocument = gql`
    mutation BulkDeleteMealMenus($input: BulkDeleteMealMenusInput!) {
  bulkDeleteMealMenus(input: $input) {
    mealMenuIds
  }
}
    `;
export type BulkDeleteMealMenusMutationFn = ApolloReactCommon.MutationFunction<BulkDeleteMealMenusMutation, BulkDeleteMealMenusMutationVariables>;
export type BulkDeleteMealMenusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BulkDeleteMealMenusMutation, BulkDeleteMealMenusMutationVariables>, 'mutation'>;

    export const BulkDeleteMealMenusComponent = (props: BulkDeleteMealMenusComponentProps) => (
      <ApolloReactComponents.Mutation<BulkDeleteMealMenusMutation, BulkDeleteMealMenusMutationVariables> mutation={BulkDeleteMealMenusDocument} {...props} />
    );
    

/**
 * __useBulkDeleteMealMenusMutation__
 *
 * To run a mutation, you first call `useBulkDeleteMealMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteMealMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteMealMenusMutation, { data, loading, error }] = useBulkDeleteMealMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkDeleteMealMenusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkDeleteMealMenusMutation, BulkDeleteMealMenusMutationVariables>) {
        return ApolloReactHooks.useMutation<BulkDeleteMealMenusMutation, BulkDeleteMealMenusMutationVariables>(BulkDeleteMealMenusDocument, baseOptions);
      }
export type BulkDeleteMealMenusMutationHookResult = ReturnType<typeof useBulkDeleteMealMenusMutation>;
export type BulkDeleteMealMenusMutationResult = ApolloReactCommon.MutationResult<BulkDeleteMealMenusMutation>;
export type BulkDeleteMealMenusMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkDeleteMealMenusMutation, BulkDeleteMealMenusMutationVariables>;
export const SendMealMenuNotificationDocument = gql`
    mutation SendMealMenuNotification($mealMenuId: ID!) {
  sendMealMenuNotification(mealMenuId: $mealMenuId)
}
    `;
export type SendMealMenuNotificationMutationFn = ApolloReactCommon.MutationFunction<SendMealMenuNotificationMutation, SendMealMenuNotificationMutationVariables>;
export type SendMealMenuNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendMealMenuNotificationMutation, SendMealMenuNotificationMutationVariables>, 'mutation'>;

    export const SendMealMenuNotificationComponent = (props: SendMealMenuNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<SendMealMenuNotificationMutation, SendMealMenuNotificationMutationVariables> mutation={SendMealMenuNotificationDocument} {...props} />
    );
    

/**
 * __useSendMealMenuNotificationMutation__
 *
 * To run a mutation, you first call `useSendMealMenuNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMealMenuNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMealMenuNotificationMutation, { data, loading, error }] = useSendMealMenuNotificationMutation({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useSendMealMenuNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMealMenuNotificationMutation, SendMealMenuNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMealMenuNotificationMutation, SendMealMenuNotificationMutationVariables>(SendMealMenuNotificationDocument, baseOptions);
      }
export type SendMealMenuNotificationMutationHookResult = ReturnType<typeof useSendMealMenuNotificationMutation>;
export type SendMealMenuNotificationMutationResult = ApolloReactCommon.MutationResult<SendMealMenuNotificationMutation>;
export type SendMealMenuNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMealMenuNotificationMutation, SendMealMenuNotificationMutationVariables>;
export const BulkCopyMealMenusDocument = gql`
    mutation BulkCopyMealMenus($input: CopyMealMenusInput!) {
  copyMealMenus(input: $input) {
    newMealMenus {
      id
    }
  }
}
    `;
export type BulkCopyMealMenusMutationFn = ApolloReactCommon.MutationFunction<BulkCopyMealMenusMutation, BulkCopyMealMenusMutationVariables>;
export type BulkCopyMealMenusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BulkCopyMealMenusMutation, BulkCopyMealMenusMutationVariables>, 'mutation'>;

    export const BulkCopyMealMenusComponent = (props: BulkCopyMealMenusComponentProps) => (
      <ApolloReactComponents.Mutation<BulkCopyMealMenusMutation, BulkCopyMealMenusMutationVariables> mutation={BulkCopyMealMenusDocument} {...props} />
    );
    

/**
 * __useBulkCopyMealMenusMutation__
 *
 * To run a mutation, you first call `useBulkCopyMealMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCopyMealMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCopyMealMenusMutation, { data, loading, error }] = useBulkCopyMealMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCopyMealMenusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkCopyMealMenusMutation, BulkCopyMealMenusMutationVariables>) {
        return ApolloReactHooks.useMutation<BulkCopyMealMenusMutation, BulkCopyMealMenusMutationVariables>(BulkCopyMealMenusDocument, baseOptions);
      }
export type BulkCopyMealMenusMutationHookResult = ReturnType<typeof useBulkCopyMealMenusMutation>;
export type BulkCopyMealMenusMutationResult = ApolloReactCommon.MutationResult<BulkCopyMealMenusMutation>;
export type BulkCopyMealMenusMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkCopyMealMenusMutation, BulkCopyMealMenusMutationVariables>;
export const OverrideMealMenuIdentityDocument = gql`
    mutation OverrideMealMenuIdentity($input: OverrideMealMenuIdentityInput!) {
  overrideMealMenuIdentity(input: $input) {
    mealMenuIdentity {
      id
      isOverridden
    }
  }
}
    `;
export type OverrideMealMenuIdentityMutationFn = ApolloReactCommon.MutationFunction<OverrideMealMenuIdentityMutation, OverrideMealMenuIdentityMutationVariables>;
export type OverrideMealMenuIdentityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OverrideMealMenuIdentityMutation, OverrideMealMenuIdentityMutationVariables>, 'mutation'>;

    export const OverrideMealMenuIdentityComponent = (props: OverrideMealMenuIdentityComponentProps) => (
      <ApolloReactComponents.Mutation<OverrideMealMenuIdentityMutation, OverrideMealMenuIdentityMutationVariables> mutation={OverrideMealMenuIdentityDocument} {...props} />
    );
    

/**
 * __useOverrideMealMenuIdentityMutation__
 *
 * To run a mutation, you first call `useOverrideMealMenuIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverrideMealMenuIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overrideMealMenuIdentityMutation, { data, loading, error }] = useOverrideMealMenuIdentityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverrideMealMenuIdentityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OverrideMealMenuIdentityMutation, OverrideMealMenuIdentityMutationVariables>) {
        return ApolloReactHooks.useMutation<OverrideMealMenuIdentityMutation, OverrideMealMenuIdentityMutationVariables>(OverrideMealMenuIdentityDocument, baseOptions);
      }
export type OverrideMealMenuIdentityMutationHookResult = ReturnType<typeof useOverrideMealMenuIdentityMutation>;
export type OverrideMealMenuIdentityMutationResult = ApolloReactCommon.MutationResult<OverrideMealMenuIdentityMutation>;
export type OverrideMealMenuIdentityMutationOptions = ApolloReactCommon.BaseMutationOptions<OverrideMealMenuIdentityMutation, OverrideMealMenuIdentityMutationVariables>;
export const OrderPageMealMenuDocument = gql`
    query OrderPageMealMenu($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    ...OrderPageMealMenu
  }
}
    ${OrderPageMealMenuFragmentDoc}`;
export type OrderPageMealMenuComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrderPageMealMenuQuery, OrderPageMealMenuQueryVariables>, 'query'> & ({ variables: OrderPageMealMenuQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrderPageMealMenuComponent = (props: OrderPageMealMenuComponentProps) => (
      <ApolloReactComponents.Query<OrderPageMealMenuQuery, OrderPageMealMenuQueryVariables> query={OrderPageMealMenuDocument} {...props} />
    );
    

/**
 * __useOrderPageMealMenuQuery__
 *
 * To run a query within a React component, call `useOrderPageMealMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderPageMealMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderPageMealMenuQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useOrderPageMealMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderPageMealMenuQuery, OrderPageMealMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderPageMealMenuQuery, OrderPageMealMenuQueryVariables>(OrderPageMealMenuDocument, baseOptions);
      }
export function useOrderPageMealMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderPageMealMenuQuery, OrderPageMealMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderPageMealMenuQuery, OrderPageMealMenuQueryVariables>(OrderPageMealMenuDocument, baseOptions);
        }
export type OrderPageMealMenuQueryHookResult = ReturnType<typeof useOrderPageMealMenuQuery>;
export type OrderPageMealMenuLazyQueryHookResult = ReturnType<typeof useOrderPageMealMenuLazyQuery>;
export type OrderPageMealMenuQueryResult = ApolloReactCommon.QueryResult<OrderPageMealMenuQuery, OrderPageMealMenuQueryVariables>;
export const OrderPageRestaurantMenuLinkDocument = gql`
    query OrderPageRestaurantMenuLink($restaurantMenuLinkId: ID!) {
  restaurantMenuLink(id: $restaurantMenuLinkId) {
    ...OrderPageRestaurantMenuLink
  }
}
    ${OrderPageRestaurantMenuLinkFragmentDoc}`;
export type OrderPageRestaurantMenuLinkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrderPageRestaurantMenuLinkQuery, OrderPageRestaurantMenuLinkQueryVariables>, 'query'> & ({ variables: OrderPageRestaurantMenuLinkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrderPageRestaurantMenuLinkComponent = (props: OrderPageRestaurantMenuLinkComponentProps) => (
      <ApolloReactComponents.Query<OrderPageRestaurantMenuLinkQuery, OrderPageRestaurantMenuLinkQueryVariables> query={OrderPageRestaurantMenuLinkDocument} {...props} />
    );
    

/**
 * __useOrderPageRestaurantMenuLinkQuery__
 *
 * To run a query within a React component, call `useOrderPageRestaurantMenuLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderPageRestaurantMenuLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderPageRestaurantMenuLinkQuery({
 *   variables: {
 *      restaurantMenuLinkId: // value for 'restaurantMenuLinkId'
 *   },
 * });
 */
export function useOrderPageRestaurantMenuLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderPageRestaurantMenuLinkQuery, OrderPageRestaurantMenuLinkQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderPageRestaurantMenuLinkQuery, OrderPageRestaurantMenuLinkQueryVariables>(OrderPageRestaurantMenuLinkDocument, baseOptions);
      }
export function useOrderPageRestaurantMenuLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderPageRestaurantMenuLinkQuery, OrderPageRestaurantMenuLinkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderPageRestaurantMenuLinkQuery, OrderPageRestaurantMenuLinkQueryVariables>(OrderPageRestaurantMenuLinkDocument, baseOptions);
        }
export type OrderPageRestaurantMenuLinkQueryHookResult = ReturnType<typeof useOrderPageRestaurantMenuLinkQuery>;
export type OrderPageRestaurantMenuLinkLazyQueryHookResult = ReturnType<typeof useOrderPageRestaurantMenuLinkLazyQuery>;
export type OrderPageRestaurantMenuLinkQueryResult = ApolloReactCommon.QueryResult<OrderPageRestaurantMenuLinkQuery, OrderPageRestaurantMenuLinkQueryVariables>;
export const OrgRecipesTableDocument = gql`
    query OrgRecipesTable($query: String, $createdById: ID, $input: OffsetPaginationInput!, $sort: RecipeSort, $mealTypes: [MealType!], $orgId: ID) {
  recipeOffsetConnection(
    query: $query
    createdById: $createdById
    input: $input
    sort: $sort
    mealTypes: $mealTypes
    orgId: $orgId
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...TableRecipe
    }
  }
}
    ${TableRecipeFragmentDoc}`;
export type OrgRecipesTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgRecipesTableQuery, OrgRecipesTableQueryVariables>, 'query'> & ({ variables: OrgRecipesTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgRecipesTableComponent = (props: OrgRecipesTableComponentProps) => (
      <ApolloReactComponents.Query<OrgRecipesTableQuery, OrgRecipesTableQueryVariables> query={OrgRecipesTableDocument} {...props} />
    );
    

/**
 * __useOrgRecipesTableQuery__
 *
 * To run a query within a React component, call `useOrgRecipesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgRecipesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgRecipesTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      createdById: // value for 'createdById'
 *      input: // value for 'input'
 *      sort: // value for 'sort'
 *      mealTypes: // value for 'mealTypes'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useOrgRecipesTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgRecipesTableQuery, OrgRecipesTableQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgRecipesTableQuery, OrgRecipesTableQueryVariables>(OrgRecipesTableDocument, baseOptions);
      }
export function useOrgRecipesTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgRecipesTableQuery, OrgRecipesTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgRecipesTableQuery, OrgRecipesTableQueryVariables>(OrgRecipesTableDocument, baseOptions);
        }
export type OrgRecipesTableQueryHookResult = ReturnType<typeof useOrgRecipesTableQuery>;
export type OrgRecipesTableLazyQueryHookResult = ReturnType<typeof useOrgRecipesTableLazyQuery>;
export type OrgRecipesTableQueryResult = ApolloReactCommon.QueryResult<OrgRecipesTableQuery, OrgRecipesTableQueryVariables>;
export const OrgGroupRecipesTableDocument = gql`
    query OrgGroupRecipesTable($query: String, $ownerIds: [ID!], $input: OffsetPaginationInput!, $sort: RecipeSort, $mealTypes: [MealType!]) {
  orgGroupRecipeOffsetConnection(
    query: $query
    ownerIds: $ownerIds
    input: $input
    sort: $sort
    mealTypes: $mealTypes
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...TableRecipe
    }
  }
}
    ${TableRecipeFragmentDoc}`;
export type OrgGroupRecipesTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgGroupRecipesTableQuery, OrgGroupRecipesTableQueryVariables>, 'query'> & ({ variables: OrgGroupRecipesTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgGroupRecipesTableComponent = (props: OrgGroupRecipesTableComponentProps) => (
      <ApolloReactComponents.Query<OrgGroupRecipesTableQuery, OrgGroupRecipesTableQueryVariables> query={OrgGroupRecipesTableDocument} {...props} />
    );
    

/**
 * __useOrgGroupRecipesTableQuery__
 *
 * To run a query within a React component, call `useOrgGroupRecipesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgGroupRecipesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgGroupRecipesTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      ownerIds: // value for 'ownerIds'
 *      input: // value for 'input'
 *      sort: // value for 'sort'
 *      mealTypes: // value for 'mealTypes'
 *   },
 * });
 */
export function useOrgGroupRecipesTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgGroupRecipesTableQuery, OrgGroupRecipesTableQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgGroupRecipesTableQuery, OrgGroupRecipesTableQueryVariables>(OrgGroupRecipesTableDocument, baseOptions);
      }
export function useOrgGroupRecipesTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgGroupRecipesTableQuery, OrgGroupRecipesTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgGroupRecipesTableQuery, OrgGroupRecipesTableQueryVariables>(OrgGroupRecipesTableDocument, baseOptions);
        }
export type OrgGroupRecipesTableQueryHookResult = ReturnType<typeof useOrgGroupRecipesTableQuery>;
export type OrgGroupRecipesTableLazyQueryHookResult = ReturnType<typeof useOrgGroupRecipesTableLazyQuery>;
export type OrgGroupRecipesTableQueryResult = ApolloReactCommon.QueryResult<OrgGroupRecipesTableQuery, OrgGroupRecipesTableQueryVariables>;
export const NotemealRecipesTableDocument = gql`
    query NotemealRecipesTable($query: String, $createdById: ID, $input: OffsetPaginationInput!, $sort: RecipeSort, $mealTypes: [MealType!], $orgId: ID) {
  recipeOffsetConnection(
    query: $query
    createdById: $createdById
    input: $input
    sort: $sort
    mealTypes: $mealTypes
    orgId: $orgId
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...TableRecipe
    }
  }
}
    ${TableRecipeFragmentDoc}`;
export type NotemealRecipesTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NotemealRecipesTableQuery, NotemealRecipesTableQueryVariables>, 'query'> & ({ variables: NotemealRecipesTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NotemealRecipesTableComponent = (props: NotemealRecipesTableComponentProps) => (
      <ApolloReactComponents.Query<NotemealRecipesTableQuery, NotemealRecipesTableQueryVariables> query={NotemealRecipesTableDocument} {...props} />
    );
    

/**
 * __useNotemealRecipesTableQuery__
 *
 * To run a query within a React component, call `useNotemealRecipesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotemealRecipesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotemealRecipesTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      createdById: // value for 'createdById'
 *      input: // value for 'input'
 *      sort: // value for 'sort'
 *      mealTypes: // value for 'mealTypes'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useNotemealRecipesTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotemealRecipesTableQuery, NotemealRecipesTableQueryVariables>) {
        return ApolloReactHooks.useQuery<NotemealRecipesTableQuery, NotemealRecipesTableQueryVariables>(NotemealRecipesTableDocument, baseOptions);
      }
export function useNotemealRecipesTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotemealRecipesTableQuery, NotemealRecipesTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotemealRecipesTableQuery, NotemealRecipesTableQueryVariables>(NotemealRecipesTableDocument, baseOptions);
        }
export type NotemealRecipesTableQueryHookResult = ReturnType<typeof useNotemealRecipesTableQuery>;
export type NotemealRecipesTableLazyQueryHookResult = ReturnType<typeof useNotemealRecipesTableLazyQuery>;
export type NotemealRecipesTableQueryResult = ApolloReactCommon.QueryResult<NotemealRecipesTableQuery, NotemealRecipesTableQueryVariables>;
export const AutoShareNewNotemealRecipesDocument = gql`
    query AutoShareNewNotemealRecipes {
  myOrg {
    autoShareNewNotemealRecipes
  }
}
    `;
export type AutoShareNewNotemealRecipesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AutoShareNewNotemealRecipesQuery, AutoShareNewNotemealRecipesQueryVariables>, 'query'>;

    export const AutoShareNewNotemealRecipesComponent = (props: AutoShareNewNotemealRecipesComponentProps) => (
      <ApolloReactComponents.Query<AutoShareNewNotemealRecipesQuery, AutoShareNewNotemealRecipesQueryVariables> query={AutoShareNewNotemealRecipesDocument} {...props} />
    );
    

/**
 * __useAutoShareNewNotemealRecipesQuery__
 *
 * To run a query within a React component, call `useAutoShareNewNotemealRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoShareNewNotemealRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoShareNewNotemealRecipesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutoShareNewNotemealRecipesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AutoShareNewNotemealRecipesQuery, AutoShareNewNotemealRecipesQueryVariables>) {
        return ApolloReactHooks.useQuery<AutoShareNewNotemealRecipesQuery, AutoShareNewNotemealRecipesQueryVariables>(AutoShareNewNotemealRecipesDocument, baseOptions);
      }
export function useAutoShareNewNotemealRecipesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AutoShareNewNotemealRecipesQuery, AutoShareNewNotemealRecipesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AutoShareNewNotemealRecipesQuery, AutoShareNewNotemealRecipesQueryVariables>(AutoShareNewNotemealRecipesDocument, baseOptions);
        }
export type AutoShareNewNotemealRecipesQueryHookResult = ReturnType<typeof useAutoShareNewNotemealRecipesQuery>;
export type AutoShareNewNotemealRecipesLazyQueryHookResult = ReturnType<typeof useAutoShareNewNotemealRecipesLazyQuery>;
export type AutoShareNewNotemealRecipesQueryResult = ApolloReactCommon.QueryResult<AutoShareNewNotemealRecipesQuery, AutoShareNewNotemealRecipesQueryVariables>;
export const DeleteRecipeDocument = gql`
    mutation DeleteRecipe($input: DeleteRecipeInput!) {
  deleteRecipe(input: $input) {
    recipeId
  }
}
    `;
export type DeleteRecipeMutationFn = ApolloReactCommon.MutationFunction<DeleteRecipeMutation, DeleteRecipeMutationVariables>;
export type DeleteRecipeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>, 'mutation'>;

    export const DeleteRecipeComponent = (props: DeleteRecipeComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRecipeMutation, DeleteRecipeMutationVariables> mutation={DeleteRecipeDocument} {...props} />
    );
    

/**
 * __useDeleteRecipeMutation__
 *
 * To run a mutation, you first call `useDeleteRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecipeMutation, { data, loading, error }] = useDeleteRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRecipeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRecipeMutation, DeleteRecipeMutationVariables>(DeleteRecipeDocument, baseOptions);
      }
export type DeleteRecipeMutationHookResult = ReturnType<typeof useDeleteRecipeMutation>;
export type DeleteRecipeMutationResult = ApolloReactCommon.MutationResult<DeleteRecipeMutation>;
export type DeleteRecipeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>;
export const EditRecipeIsSharedDocument = gql`
    mutation EditRecipeIsShared($input: EditRecipeIsSharedInput!) {
  editRecipeIsShared(input: $input) {
    recipe {
      id
    }
  }
}
    `;
export type EditRecipeIsSharedMutationFn = ApolloReactCommon.MutationFunction<EditRecipeIsSharedMutation, EditRecipeIsSharedMutationVariables>;
export type EditRecipeIsSharedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRecipeIsSharedMutation, EditRecipeIsSharedMutationVariables>, 'mutation'>;

    export const EditRecipeIsSharedComponent = (props: EditRecipeIsSharedComponentProps) => (
      <ApolloReactComponents.Mutation<EditRecipeIsSharedMutation, EditRecipeIsSharedMutationVariables> mutation={EditRecipeIsSharedDocument} {...props} />
    );
    

/**
 * __useEditRecipeIsSharedMutation__
 *
 * To run a mutation, you first call `useEditRecipeIsSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRecipeIsSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRecipeIsSharedMutation, { data, loading, error }] = useEditRecipeIsSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRecipeIsSharedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRecipeIsSharedMutation, EditRecipeIsSharedMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRecipeIsSharedMutation, EditRecipeIsSharedMutationVariables>(EditRecipeIsSharedDocument, baseOptions);
      }
export type EditRecipeIsSharedMutationHookResult = ReturnType<typeof useEditRecipeIsSharedMutation>;
export type EditRecipeIsSharedMutationResult = ApolloReactCommon.MutationResult<EditRecipeIsSharedMutation>;
export type EditRecipeIsSharedMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRecipeIsSharedMutation, EditRecipeIsSharedMutationVariables>;
export const EditAutoShareNewNotemealRecipesDocument = gql`
    mutation EditAutoShareNewNotemealRecipes($input: EditOrgAutoShareNewNotemealRecipesInput!) {
  editOrgAutoShareNewNotemealRecipes(input: $input) {
    org {
      autoShareNewNotemealRecipes
    }
  }
}
    `;
export type EditAutoShareNewNotemealRecipesMutationFn = ApolloReactCommon.MutationFunction<EditAutoShareNewNotemealRecipesMutation, EditAutoShareNewNotemealRecipesMutationVariables>;
export type EditAutoShareNewNotemealRecipesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAutoShareNewNotemealRecipesMutation, EditAutoShareNewNotemealRecipesMutationVariables>, 'mutation'>;

    export const EditAutoShareNewNotemealRecipesComponent = (props: EditAutoShareNewNotemealRecipesComponentProps) => (
      <ApolloReactComponents.Mutation<EditAutoShareNewNotemealRecipesMutation, EditAutoShareNewNotemealRecipesMutationVariables> mutation={EditAutoShareNewNotemealRecipesDocument} {...props} />
    );
    

/**
 * __useEditAutoShareNewNotemealRecipesMutation__
 *
 * To run a mutation, you first call `useEditAutoShareNewNotemealRecipesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAutoShareNewNotemealRecipesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAutoShareNewNotemealRecipesMutation, { data, loading, error }] = useEditAutoShareNewNotemealRecipesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAutoShareNewNotemealRecipesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAutoShareNewNotemealRecipesMutation, EditAutoShareNewNotemealRecipesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAutoShareNewNotemealRecipesMutation, EditAutoShareNewNotemealRecipesMutationVariables>(EditAutoShareNewNotemealRecipesDocument, baseOptions);
      }
export type EditAutoShareNewNotemealRecipesMutationHookResult = ReturnType<typeof useEditAutoShareNewNotemealRecipesMutation>;
export type EditAutoShareNewNotemealRecipesMutationResult = ApolloReactCommon.MutationResult<EditAutoShareNewNotemealRecipesMutation>;
export type EditAutoShareNewNotemealRecipesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAutoShareNewNotemealRecipesMutation, EditAutoShareNewNotemealRecipesMutationVariables>;
export const MoveRecipeToOrgGroupDocument = gql`
    mutation MoveRecipeToOrgGroup($input: MoveOrgRecipeToOrgGroupInput!) {
  moveOrgRecipeToOrgGroup(input: $input) {
    recipe {
      id
    }
  }
}
    `;
export type MoveRecipeToOrgGroupMutationFn = ApolloReactCommon.MutationFunction<MoveRecipeToOrgGroupMutation, MoveRecipeToOrgGroupMutationVariables>;
export type MoveRecipeToOrgGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveRecipeToOrgGroupMutation, MoveRecipeToOrgGroupMutationVariables>, 'mutation'>;

    export const MoveRecipeToOrgGroupComponent = (props: MoveRecipeToOrgGroupComponentProps) => (
      <ApolloReactComponents.Mutation<MoveRecipeToOrgGroupMutation, MoveRecipeToOrgGroupMutationVariables> mutation={MoveRecipeToOrgGroupDocument} {...props} />
    );
    

/**
 * __useMoveRecipeToOrgGroupMutation__
 *
 * To run a mutation, you first call `useMoveRecipeToOrgGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveRecipeToOrgGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveRecipeToOrgGroupMutation, { data, loading, error }] = useMoveRecipeToOrgGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveRecipeToOrgGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveRecipeToOrgGroupMutation, MoveRecipeToOrgGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveRecipeToOrgGroupMutation, MoveRecipeToOrgGroupMutationVariables>(MoveRecipeToOrgGroupDocument, baseOptions);
      }
export type MoveRecipeToOrgGroupMutationHookResult = ReturnType<typeof useMoveRecipeToOrgGroupMutation>;
export type MoveRecipeToOrgGroupMutationResult = ApolloReactCommon.MutationResult<MoveRecipeToOrgGroupMutation>;
export type MoveRecipeToOrgGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveRecipeToOrgGroupMutation, MoveRecipeToOrgGroupMutationVariables>;
export const OrgGroupRecipeOwnerCursorConnectionDocument = gql`
    query OrgGroupRecipeOwnerCursorConnection($pagination: CursorPaginationInput!, $searchText: String!) {
  orgGroupRecipeOwnerCursorConnection(
    pagination: $pagination
    searchText: $searchText
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...RecipeOwnerPreview
    }
  }
}
    ${RecipeOwnerPreviewFragmentDoc}`;
export type OrgGroupRecipeOwnerCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgGroupRecipeOwnerCursorConnectionQuery, OrgGroupRecipeOwnerCursorConnectionQueryVariables>, 'query'> & ({ variables: OrgGroupRecipeOwnerCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgGroupRecipeOwnerCursorConnectionComponent = (props: OrgGroupRecipeOwnerCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<OrgGroupRecipeOwnerCursorConnectionQuery, OrgGroupRecipeOwnerCursorConnectionQueryVariables> query={OrgGroupRecipeOwnerCursorConnectionDocument} {...props} />
    );
    

/**
 * __useOrgGroupRecipeOwnerCursorConnectionQuery__
 *
 * To run a query within a React component, call `useOrgGroupRecipeOwnerCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgGroupRecipeOwnerCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgGroupRecipeOwnerCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useOrgGroupRecipeOwnerCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgGroupRecipeOwnerCursorConnectionQuery, OrgGroupRecipeOwnerCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgGroupRecipeOwnerCursorConnectionQuery, OrgGroupRecipeOwnerCursorConnectionQueryVariables>(OrgGroupRecipeOwnerCursorConnectionDocument, baseOptions);
      }
export function useOrgGroupRecipeOwnerCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgGroupRecipeOwnerCursorConnectionQuery, OrgGroupRecipeOwnerCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgGroupRecipeOwnerCursorConnectionQuery, OrgGroupRecipeOwnerCursorConnectionQueryVariables>(OrgGroupRecipeOwnerCursorConnectionDocument, baseOptions);
        }
export type OrgGroupRecipeOwnerCursorConnectionQueryHookResult = ReturnType<typeof useOrgGroupRecipeOwnerCursorConnectionQuery>;
export type OrgGroupRecipeOwnerCursorConnectionLazyQueryHookResult = ReturnType<typeof useOrgGroupRecipeOwnerCursorConnectionLazyQuery>;
export type OrgGroupRecipeOwnerCursorConnectionQueryResult = ApolloReactCommon.QueryResult<OrgGroupRecipeOwnerCursorConnectionQuery, OrgGroupRecipeOwnerCursorConnectionQueryVariables>;
export const StaffDashboardAgreementFormsDocument = gql`
    query StaffDashboardAgreementForms($input: OffsetPaginationInput!, $query: String) {
  agreementFormOffsetConnection(pagination: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...AgreementForm
    }
  }
}
    ${AgreementFormFragmentDoc}`;
export type StaffDashboardAgreementFormsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffDashboardAgreementFormsQuery, StaffDashboardAgreementFormsQueryVariables>, 'query'> & ({ variables: StaffDashboardAgreementFormsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffDashboardAgreementFormsComponent = (props: StaffDashboardAgreementFormsComponentProps) => (
      <ApolloReactComponents.Query<StaffDashboardAgreementFormsQuery, StaffDashboardAgreementFormsQueryVariables> query={StaffDashboardAgreementFormsDocument} {...props} />
    );
    

/**
 * __useStaffDashboardAgreementFormsQuery__
 *
 * To run a query within a React component, call `useStaffDashboardAgreementFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardAgreementFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffDashboardAgreementFormsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useStaffDashboardAgreementFormsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffDashboardAgreementFormsQuery, StaffDashboardAgreementFormsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffDashboardAgreementFormsQuery, StaffDashboardAgreementFormsQueryVariables>(StaffDashboardAgreementFormsDocument, baseOptions);
      }
export function useStaffDashboardAgreementFormsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffDashboardAgreementFormsQuery, StaffDashboardAgreementFormsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffDashboardAgreementFormsQuery, StaffDashboardAgreementFormsQueryVariables>(StaffDashboardAgreementFormsDocument, baseOptions);
        }
export type StaffDashboardAgreementFormsQueryHookResult = ReturnType<typeof useStaffDashboardAgreementFormsQuery>;
export type StaffDashboardAgreementFormsLazyQueryHookResult = ReturnType<typeof useStaffDashboardAgreementFormsLazyQuery>;
export type StaffDashboardAgreementFormsQueryResult = ApolloReactCommon.QueryResult<StaffDashboardAgreementFormsQuery, StaffDashboardAgreementFormsQueryVariables>;
export const AgreementFormsDocument = gql`
    query AgreementForms {
  agreementForms {
    ...AgreementForm
  }
}
    ${AgreementFormFragmentDoc}`;
export type AgreementFormsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AgreementFormsQuery, AgreementFormsQueryVariables>, 'query'>;

    export const AgreementFormsComponent = (props: AgreementFormsComponentProps) => (
      <ApolloReactComponents.Query<AgreementFormsQuery, AgreementFormsQueryVariables> query={AgreementFormsDocument} {...props} />
    );
    

/**
 * __useAgreementFormsQuery__
 *
 * To run a query within a React component, call `useAgreementFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgreementFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgreementFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgreementFormsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AgreementFormsQuery, AgreementFormsQueryVariables>) {
        return ApolloReactHooks.useQuery<AgreementFormsQuery, AgreementFormsQueryVariables>(AgreementFormsDocument, baseOptions);
      }
export function useAgreementFormsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgreementFormsQuery, AgreementFormsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AgreementFormsQuery, AgreementFormsQueryVariables>(AgreementFormsDocument, baseOptions);
        }
export type AgreementFormsQueryHookResult = ReturnType<typeof useAgreementFormsQuery>;
export type AgreementFormsLazyQueryHookResult = ReturnType<typeof useAgreementFormsLazyQuery>;
export type AgreementFormsQueryResult = ApolloReactCommon.QueryResult<AgreementFormsQuery, AgreementFormsQueryVariables>;
export const AddAgreementFormDocument = gql`
    mutation AddAgreementForm($input: AddAgreementFormInput!) {
  addAgreementForm(input: $input) {
    agreementForm {
      ...AgreementForm
    }
  }
}
    ${AgreementFormFragmentDoc}`;
export type AddAgreementFormMutationFn = ApolloReactCommon.MutationFunction<AddAgreementFormMutation, AddAgreementFormMutationVariables>;
export type AddAgreementFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAgreementFormMutation, AddAgreementFormMutationVariables>, 'mutation'>;

    export const AddAgreementFormComponent = (props: AddAgreementFormComponentProps) => (
      <ApolloReactComponents.Mutation<AddAgreementFormMutation, AddAgreementFormMutationVariables> mutation={AddAgreementFormDocument} {...props} />
    );
    

/**
 * __useAddAgreementFormMutation__
 *
 * To run a mutation, you first call `useAddAgreementFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAgreementFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAgreementFormMutation, { data, loading, error }] = useAddAgreementFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAgreementFormMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAgreementFormMutation, AddAgreementFormMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAgreementFormMutation, AddAgreementFormMutationVariables>(AddAgreementFormDocument, baseOptions);
      }
export type AddAgreementFormMutationHookResult = ReturnType<typeof useAddAgreementFormMutation>;
export type AddAgreementFormMutationResult = ApolloReactCommon.MutationResult<AddAgreementFormMutation>;
export type AddAgreementFormMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAgreementFormMutation, AddAgreementFormMutationVariables>;
export const EditAgreementFormDocument = gql`
    mutation EditAgreementForm($input: EditAgreementFormInput!) {
  editAgreementForm(input: $input) {
    agreementForm {
      ...AgreementForm
    }
  }
}
    ${AgreementFormFragmentDoc}`;
export type EditAgreementFormMutationFn = ApolloReactCommon.MutationFunction<EditAgreementFormMutation, EditAgreementFormMutationVariables>;
export type EditAgreementFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAgreementFormMutation, EditAgreementFormMutationVariables>, 'mutation'>;

    export const EditAgreementFormComponent = (props: EditAgreementFormComponentProps) => (
      <ApolloReactComponents.Mutation<EditAgreementFormMutation, EditAgreementFormMutationVariables> mutation={EditAgreementFormDocument} {...props} />
    );
    

/**
 * __useEditAgreementFormMutation__
 *
 * To run a mutation, you first call `useEditAgreementFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAgreementFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAgreementFormMutation, { data, loading, error }] = useEditAgreementFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAgreementFormMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAgreementFormMutation, EditAgreementFormMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAgreementFormMutation, EditAgreementFormMutationVariables>(EditAgreementFormDocument, baseOptions);
      }
export type EditAgreementFormMutationHookResult = ReturnType<typeof useEditAgreementFormMutation>;
export type EditAgreementFormMutationResult = ApolloReactCommon.MutationResult<EditAgreementFormMutation>;
export type EditAgreementFormMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAgreementFormMutation, EditAgreementFormMutationVariables>;
export const DeliveryLocationsDocument = gql`
    query DeliveryLocations($input: OffsetPaginationInput!, $query: String) {
  allDeliveryLocations(input: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...DeliveryLocationWithTeams
    }
  }
}
    ${DeliveryLocationWithTeamsFragmentDoc}`;
export type DeliveryLocationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DeliveryLocationsQuery, DeliveryLocationsQueryVariables>, 'query'> & ({ variables: DeliveryLocationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DeliveryLocationsComponent = (props: DeliveryLocationsComponentProps) => (
      <ApolloReactComponents.Query<DeliveryLocationsQuery, DeliveryLocationsQueryVariables> query={DeliveryLocationsDocument} {...props} />
    );
    

/**
 * __useDeliveryLocationsQuery__
 *
 * To run a query within a React component, call `useDeliveryLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDeliveryLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveryLocationsQuery, DeliveryLocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveryLocationsQuery, DeliveryLocationsQueryVariables>(DeliveryLocationsDocument, baseOptions);
      }
export function useDeliveryLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveryLocationsQuery, DeliveryLocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveryLocationsQuery, DeliveryLocationsQueryVariables>(DeliveryLocationsDocument, baseOptions);
        }
export type DeliveryLocationsQueryHookResult = ReturnType<typeof useDeliveryLocationsQuery>;
export type DeliveryLocationsLazyQueryHookResult = ReturnType<typeof useDeliveryLocationsLazyQuery>;
export type DeliveryLocationsQueryResult = ApolloReactCommon.QueryResult<DeliveryLocationsQuery, DeliveryLocationsQueryVariables>;
export const TeamLabelSearchDocument = gql`
    query TeamLabelSearch($query: String) {
  allTeamLabels(query: $query, input: {offset: 0, limit: 25}) {
    edges {
      ...TeamLabel
    }
  }
}
    ${TeamLabelFragmentDoc}`;
export type TeamLabelSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamLabelSearchQuery, TeamLabelSearchQueryVariables>, 'query'>;

    export const TeamLabelSearchComponent = (props: TeamLabelSearchComponentProps) => (
      <ApolloReactComponents.Query<TeamLabelSearchQuery, TeamLabelSearchQueryVariables> query={TeamLabelSearchDocument} {...props} />
    );
    

/**
 * __useTeamLabelSearchQuery__
 *
 * To run a query within a React component, call `useTeamLabelSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamLabelSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamLabelSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTeamLabelSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamLabelSearchQuery, TeamLabelSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamLabelSearchQuery, TeamLabelSearchQueryVariables>(TeamLabelSearchDocument, baseOptions);
      }
export function useTeamLabelSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamLabelSearchQuery, TeamLabelSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamLabelSearchQuery, TeamLabelSearchQueryVariables>(TeamLabelSearchDocument, baseOptions);
        }
export type TeamLabelSearchQueryHookResult = ReturnType<typeof useTeamLabelSearchQuery>;
export type TeamLabelSearchLazyQueryHookResult = ReturnType<typeof useTeamLabelSearchLazyQuery>;
export type TeamLabelSearchQueryResult = ApolloReactCommon.QueryResult<TeamLabelSearchQuery, TeamLabelSearchQueryVariables>;
export const AddDeliveryLocationDocument = gql`
    mutation AddDeliveryLocation($input: AddDeliveryLocationInput!) {
  addDeliveryLocation(input: $input) {
    deliveryLocation {
      ...DeliveryLocationWithTeams
    }
  }
}
    ${DeliveryLocationWithTeamsFragmentDoc}`;
export type AddDeliveryLocationMutationFn = ApolloReactCommon.MutationFunction<AddDeliveryLocationMutation, AddDeliveryLocationMutationVariables>;
export type AddDeliveryLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddDeliveryLocationMutation, AddDeliveryLocationMutationVariables>, 'mutation'>;

    export const AddDeliveryLocationComponent = (props: AddDeliveryLocationComponentProps) => (
      <ApolloReactComponents.Mutation<AddDeliveryLocationMutation, AddDeliveryLocationMutationVariables> mutation={AddDeliveryLocationDocument} {...props} />
    );
    

/**
 * __useAddDeliveryLocationMutation__
 *
 * To run a mutation, you first call `useAddDeliveryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeliveryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeliveryLocationMutation, { data, loading, error }] = useAddDeliveryLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDeliveryLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDeliveryLocationMutation, AddDeliveryLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDeliveryLocationMutation, AddDeliveryLocationMutationVariables>(AddDeliveryLocationDocument, baseOptions);
      }
export type AddDeliveryLocationMutationHookResult = ReturnType<typeof useAddDeliveryLocationMutation>;
export type AddDeliveryLocationMutationResult = ApolloReactCommon.MutationResult<AddDeliveryLocationMutation>;
export type AddDeliveryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDeliveryLocationMutation, AddDeliveryLocationMutationVariables>;
export const EditDeliveryLocationDocument = gql`
    mutation EditDeliveryLocation($input: EditDeliveryLocationInput!) {
  editDeliveryLocation(input: $input) {
    deliveryLocation {
      ...DeliveryLocationWithTeams
    }
  }
}
    ${DeliveryLocationWithTeamsFragmentDoc}`;
export type EditDeliveryLocationMutationFn = ApolloReactCommon.MutationFunction<EditDeliveryLocationMutation, EditDeliveryLocationMutationVariables>;
export type EditDeliveryLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditDeliveryLocationMutation, EditDeliveryLocationMutationVariables>, 'mutation'>;

    export const EditDeliveryLocationComponent = (props: EditDeliveryLocationComponentProps) => (
      <ApolloReactComponents.Mutation<EditDeliveryLocationMutation, EditDeliveryLocationMutationVariables> mutation={EditDeliveryLocationDocument} {...props} />
    );
    

/**
 * __useEditDeliveryLocationMutation__
 *
 * To run a mutation, you first call `useEditDeliveryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDeliveryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDeliveryLocationMutation, { data, loading, error }] = useEditDeliveryLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDeliveryLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDeliveryLocationMutation, EditDeliveryLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<EditDeliveryLocationMutation, EditDeliveryLocationMutationVariables>(EditDeliveryLocationDocument, baseOptions);
      }
export type EditDeliveryLocationMutationHookResult = ReturnType<typeof useEditDeliveryLocationMutation>;
export type EditDeliveryLocationMutationResult = ApolloReactCommon.MutationResult<EditDeliveryLocationMutation>;
export type EditDeliveryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<EditDeliveryLocationMutation, EditDeliveryLocationMutationVariables>;
export const StaffDashboardDietsDocument = gql`
    query StaffDashboardDiets($input: OffsetPaginationInput!, $query: String) {
  dietOffsetConnection(pagination: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...Diet
    }
  }
}
    ${DietFragmentDoc}`;
export type StaffDashboardDietsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffDashboardDietsQuery, StaffDashboardDietsQueryVariables>, 'query'> & ({ variables: StaffDashboardDietsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffDashboardDietsComponent = (props: StaffDashboardDietsComponentProps) => (
      <ApolloReactComponents.Query<StaffDashboardDietsQuery, StaffDashboardDietsQueryVariables> query={StaffDashboardDietsDocument} {...props} />
    );
    

/**
 * __useStaffDashboardDietsQuery__
 *
 * To run a query within a React component, call `useStaffDashboardDietsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardDietsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffDashboardDietsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useStaffDashboardDietsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffDashboardDietsQuery, StaffDashboardDietsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffDashboardDietsQuery, StaffDashboardDietsQueryVariables>(StaffDashboardDietsDocument, baseOptions);
      }
export function useStaffDashboardDietsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffDashboardDietsQuery, StaffDashboardDietsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffDashboardDietsQuery, StaffDashboardDietsQueryVariables>(StaffDashboardDietsDocument, baseOptions);
        }
export type StaffDashboardDietsQueryHookResult = ReturnType<typeof useStaffDashboardDietsQuery>;
export type StaffDashboardDietsLazyQueryHookResult = ReturnType<typeof useStaffDashboardDietsLazyQuery>;
export type StaffDashboardDietsQueryResult = ApolloReactCommon.QueryResult<StaffDashboardDietsQuery, StaffDashboardDietsQueryVariables>;
export const CreateDietDocument = gql`
    mutation CreateDiet($input: CreateDietInput!) {
  createDiet(input: $input) {
    diet {
      ...Diet
    }
  }
}
    ${DietFragmentDoc}`;
export type CreateDietMutationFn = ApolloReactCommon.MutationFunction<CreateDietMutation, CreateDietMutationVariables>;
export type CreateDietComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDietMutation, CreateDietMutationVariables>, 'mutation'>;

    export const CreateDietComponent = (props: CreateDietComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDietMutation, CreateDietMutationVariables> mutation={CreateDietDocument} {...props} />
    );
    

/**
 * __useCreateDietMutation__
 *
 * To run a mutation, you first call `useCreateDietMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDietMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDietMutation, { data, loading, error }] = useCreateDietMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDietMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDietMutation, CreateDietMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDietMutation, CreateDietMutationVariables>(CreateDietDocument, baseOptions);
      }
export type CreateDietMutationHookResult = ReturnType<typeof useCreateDietMutation>;
export type CreateDietMutationResult = ApolloReactCommon.MutationResult<CreateDietMutation>;
export type CreateDietMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDietMutation, CreateDietMutationVariables>;
export const EditDietDocument = gql`
    mutation EditDiet($input: EditDietInput!) {
  editDiet(input: $input) {
    diet {
      ...Diet
    }
  }
}
    ${DietFragmentDoc}`;
export type EditDietMutationFn = ApolloReactCommon.MutationFunction<EditDietMutation, EditDietMutationVariables>;
export type EditDietComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditDietMutation, EditDietMutationVariables>, 'mutation'>;

    export const EditDietComponent = (props: EditDietComponentProps) => (
      <ApolloReactComponents.Mutation<EditDietMutation, EditDietMutationVariables> mutation={EditDietDocument} {...props} />
    );
    

/**
 * __useEditDietMutation__
 *
 * To run a mutation, you first call `useEditDietMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDietMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDietMutation, { data, loading, error }] = useEditDietMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDietMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDietMutation, EditDietMutationVariables>) {
        return ApolloReactHooks.useMutation<EditDietMutation, EditDietMutationVariables>(EditDietDocument, baseOptions);
      }
export type EditDietMutationHookResult = ReturnType<typeof useEditDietMutation>;
export type EditDietMutationResult = ApolloReactCommon.MutationResult<EditDietMutation>;
export type EditDietMutationOptions = ApolloReactCommon.BaseMutationOptions<EditDietMutation, EditDietMutationVariables>;
export const DeleteDietDocument = gql`
    mutation DeleteDiet($input: DeleteDietInput!) {
  deleteDiet(input: $input) {
    id
  }
}
    `;
export type DeleteDietMutationFn = ApolloReactCommon.MutationFunction<DeleteDietMutation, DeleteDietMutationVariables>;
export type DeleteDietComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDietMutation, DeleteDietMutationVariables>, 'mutation'>;

    export const DeleteDietComponent = (props: DeleteDietComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDietMutation, DeleteDietMutationVariables> mutation={DeleteDietDocument} {...props} />
    );
    

/**
 * __useDeleteDietMutation__
 *
 * To run a mutation, you first call `useDeleteDietMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDietMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDietMutation, { data, loading, error }] = useDeleteDietMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDietMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDietMutation, DeleteDietMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDietMutation, DeleteDietMutationVariables>(DeleteDietDocument, baseOptions);
      }
export type DeleteDietMutationHookResult = ReturnType<typeof useDeleteDietMutation>;
export type DeleteDietMutationResult = ApolloReactCommon.MutationResult<DeleteDietMutation>;
export type DeleteDietMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDietMutation, DeleteDietMutationVariables>;
export const SharedMealPlanTemplateDocument = gql`
    query SharedMealPlanTemplate($id: ID!) {
  sharedMealPlanTemplate(id: $id) {
    ...FullMealPlanTemplate
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type SharedMealPlanTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SharedMealPlanTemplateQuery, SharedMealPlanTemplateQueryVariables>, 'query'> & ({ variables: SharedMealPlanTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SharedMealPlanTemplateComponent = (props: SharedMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Query<SharedMealPlanTemplateQuery, SharedMealPlanTemplateQueryVariables> query={SharedMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useSharedMealPlanTemplateQuery__
 *
 * To run a query within a React component, call `useSharedMealPlanTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedMealPlanTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedMealPlanTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSharedMealPlanTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedMealPlanTemplateQuery, SharedMealPlanTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedMealPlanTemplateQuery, SharedMealPlanTemplateQueryVariables>(SharedMealPlanTemplateDocument, baseOptions);
      }
export function useSharedMealPlanTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedMealPlanTemplateQuery, SharedMealPlanTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedMealPlanTemplateQuery, SharedMealPlanTemplateQueryVariables>(SharedMealPlanTemplateDocument, baseOptions);
        }
export type SharedMealPlanTemplateQueryHookResult = ReturnType<typeof useSharedMealPlanTemplateQuery>;
export type SharedMealPlanTemplateLazyQueryHookResult = ReturnType<typeof useSharedMealPlanTemplateLazyQuery>;
export type SharedMealPlanTemplateQueryResult = ApolloReactCommon.QueryResult<SharedMealPlanTemplateQuery, SharedMealPlanTemplateQueryVariables>;
export const StaffSharedMealPlanTemplateOffsetConnectionDocument = gql`
    query StaffSharedMealPlanTemplateOffsetConnection($pagination: OffsetPaginationInput!, $searchText: String!, $sortAscending: Boolean!) {
  sharedMealPlanTemplateOffsetConnection(
    pagination: $pagination
    searchText: $searchText
    sortAscending: $sortAscending
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...StaffMealPlanTemplateListItem
    }
  }
}
    ${StaffMealPlanTemplateListItemFragmentDoc}`;
export type StaffSharedMealPlanTemplateOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffSharedMealPlanTemplateOffsetConnectionQuery, StaffSharedMealPlanTemplateOffsetConnectionQueryVariables>, 'query'> & ({ variables: StaffSharedMealPlanTemplateOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffSharedMealPlanTemplateOffsetConnectionComponent = (props: StaffSharedMealPlanTemplateOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<StaffSharedMealPlanTemplateOffsetConnectionQuery, StaffSharedMealPlanTemplateOffsetConnectionQueryVariables> query={StaffSharedMealPlanTemplateOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useStaffSharedMealPlanTemplateOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useStaffSharedMealPlanTemplateOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffSharedMealPlanTemplateOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffSharedMealPlanTemplateOffsetConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchText: // value for 'searchText'
 *      sortAscending: // value for 'sortAscending'
 *   },
 * });
 */
export function useStaffSharedMealPlanTemplateOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffSharedMealPlanTemplateOffsetConnectionQuery, StaffSharedMealPlanTemplateOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffSharedMealPlanTemplateOffsetConnectionQuery, StaffSharedMealPlanTemplateOffsetConnectionQueryVariables>(StaffSharedMealPlanTemplateOffsetConnectionDocument, baseOptions);
      }
export function useStaffSharedMealPlanTemplateOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffSharedMealPlanTemplateOffsetConnectionQuery, StaffSharedMealPlanTemplateOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffSharedMealPlanTemplateOffsetConnectionQuery, StaffSharedMealPlanTemplateOffsetConnectionQueryVariables>(StaffSharedMealPlanTemplateOffsetConnectionDocument, baseOptions);
        }
export type StaffSharedMealPlanTemplateOffsetConnectionQueryHookResult = ReturnType<typeof useStaffSharedMealPlanTemplateOffsetConnectionQuery>;
export type StaffSharedMealPlanTemplateOffsetConnectionLazyQueryHookResult = ReturnType<typeof useStaffSharedMealPlanTemplateOffsetConnectionLazyQuery>;
export type StaffSharedMealPlanTemplateOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<StaffSharedMealPlanTemplateOffsetConnectionQuery, StaffSharedMealPlanTemplateOffsetConnectionQueryVariables>;
export const CreateSharedMacroMealPlanTemplateDocument = gql`
    mutation CreateSharedMacroMealPlanTemplate($input: CreateMacroMealPlanTemplateInput!) {
  createSharedMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...FullMealPlanTemplate
    }
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type CreateSharedMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateSharedMacroMealPlanTemplateMutation, CreateSharedMacroMealPlanTemplateMutationVariables>;
export type CreateSharedMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSharedMacroMealPlanTemplateMutation, CreateSharedMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const CreateSharedMacroMealPlanTemplateComponent = (props: CreateSharedMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSharedMacroMealPlanTemplateMutation, CreateSharedMacroMealPlanTemplateMutationVariables> mutation={CreateSharedMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useCreateSharedMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useCreateSharedMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedMacroMealPlanTemplateMutation, { data, loading, error }] = useCreateSharedMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSharedMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSharedMacroMealPlanTemplateMutation, CreateSharedMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSharedMacroMealPlanTemplateMutation, CreateSharedMacroMealPlanTemplateMutationVariables>(CreateSharedMacroMealPlanTemplateDocument, baseOptions);
      }
export type CreateSharedMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useCreateSharedMacroMealPlanTemplateMutation>;
export type CreateSharedMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<CreateSharedMacroMealPlanTemplateMutation>;
export type CreateSharedMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSharedMacroMealPlanTemplateMutation, CreateSharedMacroMealPlanTemplateMutationVariables>;
export const EditSharedMacroMealPlanTemplateDocument = gql`
    mutation EditSharedMacroMealPlanTemplate($input: MacroMealPlanTemplatePendingStateInput!) {
  editSharedMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...FullMealPlanTemplate
    }
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type EditSharedMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<EditSharedMacroMealPlanTemplateMutation, EditSharedMacroMealPlanTemplateMutationVariables>;
export type EditSharedMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditSharedMacroMealPlanTemplateMutation, EditSharedMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const EditSharedMacroMealPlanTemplateComponent = (props: EditSharedMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<EditSharedMacroMealPlanTemplateMutation, EditSharedMacroMealPlanTemplateMutationVariables> mutation={EditSharedMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useEditSharedMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useEditSharedMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSharedMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSharedMacroMealPlanTemplateMutation, { data, loading, error }] = useEditSharedMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSharedMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSharedMacroMealPlanTemplateMutation, EditSharedMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSharedMacroMealPlanTemplateMutation, EditSharedMacroMealPlanTemplateMutationVariables>(EditSharedMacroMealPlanTemplateDocument, baseOptions);
      }
export type EditSharedMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useEditSharedMacroMealPlanTemplateMutation>;
export type EditSharedMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<EditSharedMacroMealPlanTemplateMutation>;
export type EditSharedMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSharedMacroMealPlanTemplateMutation, EditSharedMacroMealPlanTemplateMutationVariables>;
export const PublishSharedMacroMealPlanTemplateDocument = gql`
    mutation PublishSharedMacroMealPlanTemplate($input: PublishMacroMealPlanTemplateInput!) {
  publishSharedMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...FullMealPlanTemplate
    }
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type PublishSharedMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<PublishSharedMacroMealPlanTemplateMutation, PublishSharedMacroMealPlanTemplateMutationVariables>;
export type PublishSharedMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PublishSharedMacroMealPlanTemplateMutation, PublishSharedMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const PublishSharedMacroMealPlanTemplateComponent = (props: PublishSharedMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<PublishSharedMacroMealPlanTemplateMutation, PublishSharedMacroMealPlanTemplateMutationVariables> mutation={PublishSharedMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __usePublishSharedMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `usePublishSharedMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishSharedMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishSharedMacroMealPlanTemplateMutation, { data, loading, error }] = usePublishSharedMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishSharedMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishSharedMacroMealPlanTemplateMutation, PublishSharedMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishSharedMacroMealPlanTemplateMutation, PublishSharedMacroMealPlanTemplateMutationVariables>(PublishSharedMacroMealPlanTemplateDocument, baseOptions);
      }
export type PublishSharedMacroMealPlanTemplateMutationHookResult = ReturnType<typeof usePublishSharedMacroMealPlanTemplateMutation>;
export type PublishSharedMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<PublishSharedMacroMealPlanTemplateMutation>;
export type PublishSharedMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishSharedMacroMealPlanTemplateMutation, PublishSharedMacroMealPlanTemplateMutationVariables>;
export const CopySharedMacroMealPlanTemplateDocument = gql`
    mutation CopySharedMacroMealPlanTemplate($input: CopyMacroMealPlanTemplateInput!) {
  copySharedMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplate {
      ...FullMealPlanTemplate
    }
  }
}
    ${FullMealPlanTemplateFragmentDoc}`;
export type CopySharedMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<CopySharedMacroMealPlanTemplateMutation, CopySharedMacroMealPlanTemplateMutationVariables>;
export type CopySharedMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopySharedMacroMealPlanTemplateMutation, CopySharedMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const CopySharedMacroMealPlanTemplateComponent = (props: CopySharedMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CopySharedMacroMealPlanTemplateMutation, CopySharedMacroMealPlanTemplateMutationVariables> mutation={CopySharedMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useCopySharedMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useCopySharedMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySharedMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySharedMacroMealPlanTemplateMutation, { data, loading, error }] = useCopySharedMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopySharedMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopySharedMacroMealPlanTemplateMutation, CopySharedMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CopySharedMacroMealPlanTemplateMutation, CopySharedMacroMealPlanTemplateMutationVariables>(CopySharedMacroMealPlanTemplateDocument, baseOptions);
      }
export type CopySharedMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useCopySharedMacroMealPlanTemplateMutation>;
export type CopySharedMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<CopySharedMacroMealPlanTemplateMutation>;
export type CopySharedMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CopySharedMacroMealPlanTemplateMutation, CopySharedMacroMealPlanTemplateMutationVariables>;
export const DeleteSharedMacroMealPlanTemplateDocument = gql`
    mutation DeleteSharedMacroMealPlanTemplate($input: DeleteMacroMealPlanTemplateInput!) {
  deleteSharedMacroMealPlanTemplate(input: $input) {
    macroMealPlanTemplateId
  }
}
    `;
export type DeleteSharedMacroMealPlanTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteSharedMacroMealPlanTemplateMutation, DeleteSharedMacroMealPlanTemplateMutationVariables>;
export type DeleteSharedMacroMealPlanTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSharedMacroMealPlanTemplateMutation, DeleteSharedMacroMealPlanTemplateMutationVariables>, 'mutation'>;

    export const DeleteSharedMacroMealPlanTemplateComponent = (props: DeleteSharedMacroMealPlanTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSharedMacroMealPlanTemplateMutation, DeleteSharedMacroMealPlanTemplateMutationVariables> mutation={DeleteSharedMacroMealPlanTemplateDocument} {...props} />
    );
    

/**
 * __useDeleteSharedMacroMealPlanTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteSharedMacroMealPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSharedMacroMealPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSharedMacroMealPlanTemplateMutation, { data, loading, error }] = useDeleteSharedMacroMealPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSharedMacroMealPlanTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSharedMacroMealPlanTemplateMutation, DeleteSharedMacroMealPlanTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSharedMacroMealPlanTemplateMutation, DeleteSharedMacroMealPlanTemplateMutationVariables>(DeleteSharedMacroMealPlanTemplateDocument, baseOptions);
      }
export type DeleteSharedMacroMealPlanTemplateMutationHookResult = ReturnType<typeof useDeleteSharedMacroMealPlanTemplateMutation>;
export type DeleteSharedMacroMealPlanTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteSharedMacroMealPlanTemplateMutation>;
export type DeleteSharedMacroMealPlanTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSharedMacroMealPlanTemplateMutation, DeleteSharedMacroMealPlanTemplateMutationVariables>;
export const DeleteSharedMacroMealPlanTemplatePendingStateDocument = gql`
    mutation DeleteSharedMacroMealPlanTemplatePendingState($input: DeleteMacroMealPlanTemplatePendingStateInput!) {
  deleteSharedMacroMealPlanTemplatePendingState(input: $input) {
    macroMealPlanTemplate {
      ...StaffMealPlanTemplatePendingState
    }
  }
}
    ${StaffMealPlanTemplatePendingStateFragmentDoc}`;
export type DeleteSharedMacroMealPlanTemplatePendingStateMutationFn = ApolloReactCommon.MutationFunction<DeleteSharedMacroMealPlanTemplatePendingStateMutation, DeleteSharedMacroMealPlanTemplatePendingStateMutationVariables>;
export type DeleteSharedMacroMealPlanTemplatePendingStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSharedMacroMealPlanTemplatePendingStateMutation, DeleteSharedMacroMealPlanTemplatePendingStateMutationVariables>, 'mutation'>;

    export const DeleteSharedMacroMealPlanTemplatePendingStateComponent = (props: DeleteSharedMacroMealPlanTemplatePendingStateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSharedMacroMealPlanTemplatePendingStateMutation, DeleteSharedMacroMealPlanTemplatePendingStateMutationVariables> mutation={DeleteSharedMacroMealPlanTemplatePendingStateDocument} {...props} />
    );
    

/**
 * __useDeleteSharedMacroMealPlanTemplatePendingStateMutation__
 *
 * To run a mutation, you first call `useDeleteSharedMacroMealPlanTemplatePendingStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSharedMacroMealPlanTemplatePendingStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSharedMacroMealPlanTemplatePendingStateMutation, { data, loading, error }] = useDeleteSharedMacroMealPlanTemplatePendingStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSharedMacroMealPlanTemplatePendingStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSharedMacroMealPlanTemplatePendingStateMutation, DeleteSharedMacroMealPlanTemplatePendingStateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSharedMacroMealPlanTemplatePendingStateMutation, DeleteSharedMacroMealPlanTemplatePendingStateMutationVariables>(DeleteSharedMacroMealPlanTemplatePendingStateDocument, baseOptions);
      }
export type DeleteSharedMacroMealPlanTemplatePendingStateMutationHookResult = ReturnType<typeof useDeleteSharedMacroMealPlanTemplatePendingStateMutation>;
export type DeleteSharedMacroMealPlanTemplatePendingStateMutationResult = ApolloReactCommon.MutationResult<DeleteSharedMacroMealPlanTemplatePendingStateMutation>;
export type DeleteSharedMacroMealPlanTemplatePendingStateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSharedMacroMealPlanTemplatePendingStateMutation, DeleteSharedMacroMealPlanTemplatePendingStateMutationVariables>;
export const UpdateSharedMacroMealPlanTemplateSharingScopeDocument = gql`
    mutation UpdateSharedMacroMealPlanTemplateSharingScope($input: UpdateMacroMealPlanTemplateSharingScopeInput!) {
  updateSharedMacroMealPlanTemplateSharingScope(input: $input) {
    macroMealPlanTemplate {
      ...StaffMealPlanTemplateIsShared
    }
  }
}
    ${StaffMealPlanTemplateIsSharedFragmentDoc}`;
export type UpdateSharedMacroMealPlanTemplateSharingScopeMutationFn = ApolloReactCommon.MutationFunction<UpdateSharedMacroMealPlanTemplateSharingScopeMutation, UpdateSharedMacroMealPlanTemplateSharingScopeMutationVariables>;
export type UpdateSharedMacroMealPlanTemplateSharingScopeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSharedMacroMealPlanTemplateSharingScopeMutation, UpdateSharedMacroMealPlanTemplateSharingScopeMutationVariables>, 'mutation'>;

    export const UpdateSharedMacroMealPlanTemplateSharingScopeComponent = (props: UpdateSharedMacroMealPlanTemplateSharingScopeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSharedMacroMealPlanTemplateSharingScopeMutation, UpdateSharedMacroMealPlanTemplateSharingScopeMutationVariables> mutation={UpdateSharedMacroMealPlanTemplateSharingScopeDocument} {...props} />
    );
    

/**
 * __useUpdateSharedMacroMealPlanTemplateSharingScopeMutation__
 *
 * To run a mutation, you first call `useUpdateSharedMacroMealPlanTemplateSharingScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSharedMacroMealPlanTemplateSharingScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSharedMacroMealPlanTemplateSharingScopeMutation, { data, loading, error }] = useUpdateSharedMacroMealPlanTemplateSharingScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSharedMacroMealPlanTemplateSharingScopeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSharedMacroMealPlanTemplateSharingScopeMutation, UpdateSharedMacroMealPlanTemplateSharingScopeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSharedMacroMealPlanTemplateSharingScopeMutation, UpdateSharedMacroMealPlanTemplateSharingScopeMutationVariables>(UpdateSharedMacroMealPlanTemplateSharingScopeDocument, baseOptions);
      }
export type UpdateSharedMacroMealPlanTemplateSharingScopeMutationHookResult = ReturnType<typeof useUpdateSharedMacroMealPlanTemplateSharingScopeMutation>;
export type UpdateSharedMacroMealPlanTemplateSharingScopeMutationResult = ApolloReactCommon.MutationResult<UpdateSharedMacroMealPlanTemplateSharingScopeMutation>;
export type UpdateSharedMacroMealPlanTemplateSharingScopeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSharedMacroMealPlanTemplateSharingScopeMutation, UpdateSharedMacroMealPlanTemplateSharingScopeMutationVariables>;
export const OrgGroupOrgOptionsDocument = gql`
    query OrgGroupOrgOptions {
  orgs {
    ...OrgGroupOrg
  }
}
    ${OrgGroupOrgFragmentDoc}`;
export type OrgGroupOrgOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgGroupOrgOptionsQuery, OrgGroupOrgOptionsQueryVariables>, 'query'>;

    export const OrgGroupOrgOptionsComponent = (props: OrgGroupOrgOptionsComponentProps) => (
      <ApolloReactComponents.Query<OrgGroupOrgOptionsQuery, OrgGroupOrgOptionsQueryVariables> query={OrgGroupOrgOptionsDocument} {...props} />
    );
    

/**
 * __useOrgGroupOrgOptionsQuery__
 *
 * To run a query within a React component, call `useOrgGroupOrgOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgGroupOrgOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgGroupOrgOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgGroupOrgOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgGroupOrgOptionsQuery, OrgGroupOrgOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgGroupOrgOptionsQuery, OrgGroupOrgOptionsQueryVariables>(OrgGroupOrgOptionsDocument, baseOptions);
      }
export function useOrgGroupOrgOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgGroupOrgOptionsQuery, OrgGroupOrgOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgGroupOrgOptionsQuery, OrgGroupOrgOptionsQueryVariables>(OrgGroupOrgOptionsDocument, baseOptions);
        }
export type OrgGroupOrgOptionsQueryHookResult = ReturnType<typeof useOrgGroupOrgOptionsQuery>;
export type OrgGroupOrgOptionsLazyQueryHookResult = ReturnType<typeof useOrgGroupOrgOptionsLazyQuery>;
export type OrgGroupOrgOptionsQueryResult = ApolloReactCommon.QueryResult<OrgGroupOrgOptionsQuery, OrgGroupOrgOptionsQueryVariables>;
export const EditableOrgGroupDocument = gql`
    query EditableOrgGroup($id: ID!) {
  orgGroup(id: $id) {
    ...EditableOrgGroup
  }
}
    ${EditableOrgGroupFragmentDoc}`;
export type EditableOrgGroupComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EditableOrgGroupQuery, EditableOrgGroupQueryVariables>, 'query'> & ({ variables: EditableOrgGroupQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EditableOrgGroupComponent = (props: EditableOrgGroupComponentProps) => (
      <ApolloReactComponents.Query<EditableOrgGroupQuery, EditableOrgGroupQueryVariables> query={EditableOrgGroupDocument} {...props} />
    );
    

/**
 * __useEditableOrgGroupQuery__
 *
 * To run a query within a React component, call `useEditableOrgGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditableOrgGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditableOrgGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditableOrgGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EditableOrgGroupQuery, EditableOrgGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<EditableOrgGroupQuery, EditableOrgGroupQueryVariables>(EditableOrgGroupDocument, baseOptions);
      }
export function useEditableOrgGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditableOrgGroupQuery, EditableOrgGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EditableOrgGroupQuery, EditableOrgGroupQueryVariables>(EditableOrgGroupDocument, baseOptions);
        }
export type EditableOrgGroupQueryHookResult = ReturnType<typeof useEditableOrgGroupQuery>;
export type EditableOrgGroupLazyQueryHookResult = ReturnType<typeof useEditableOrgGroupLazyQuery>;
export type EditableOrgGroupQueryResult = ApolloReactCommon.QueryResult<EditableOrgGroupQuery, EditableOrgGroupQueryVariables>;
export const ParentOrgGroupOptionsDocument = gql`
    query ParentOrgGroupOptions {
  orgGroups {
    ...ParentOrgGroupOption
  }
}
    ${ParentOrgGroupOptionFragmentDoc}`;
export type ParentOrgGroupOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ParentOrgGroupOptionsQuery, ParentOrgGroupOptionsQueryVariables>, 'query'>;

    export const ParentOrgGroupOptionsComponent = (props: ParentOrgGroupOptionsComponentProps) => (
      <ApolloReactComponents.Query<ParentOrgGroupOptionsQuery, ParentOrgGroupOptionsQueryVariables> query={ParentOrgGroupOptionsDocument} {...props} />
    );
    

/**
 * __useParentOrgGroupOptionsQuery__
 *
 * To run a query within a React component, call `useParentOrgGroupOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentOrgGroupOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentOrgGroupOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useParentOrgGroupOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParentOrgGroupOptionsQuery, ParentOrgGroupOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ParentOrgGroupOptionsQuery, ParentOrgGroupOptionsQueryVariables>(ParentOrgGroupOptionsDocument, baseOptions);
      }
export function useParentOrgGroupOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParentOrgGroupOptionsQuery, ParentOrgGroupOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParentOrgGroupOptionsQuery, ParentOrgGroupOptionsQueryVariables>(ParentOrgGroupOptionsDocument, baseOptions);
        }
export type ParentOrgGroupOptionsQueryHookResult = ReturnType<typeof useParentOrgGroupOptionsQuery>;
export type ParentOrgGroupOptionsLazyQueryHookResult = ReturnType<typeof useParentOrgGroupOptionsLazyQuery>;
export type ParentOrgGroupOptionsQueryResult = ApolloReactCommon.QueryResult<ParentOrgGroupOptionsQuery, ParentOrgGroupOptionsQueryVariables>;
export const StaffDashboardOrgGroupsDocument = gql`
    query StaffDashboardOrgGroups {
  orgGroups {
    ...StaffDashboardOrgGroup
  }
}
    ${StaffDashboardOrgGroupFragmentDoc}`;
export type StaffDashboardOrgGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffDashboardOrgGroupsQuery, StaffDashboardOrgGroupsQueryVariables>, 'query'>;

    export const StaffDashboardOrgGroupsComponent = (props: StaffDashboardOrgGroupsComponentProps) => (
      <ApolloReactComponents.Query<StaffDashboardOrgGroupsQuery, StaffDashboardOrgGroupsQueryVariables> query={StaffDashboardOrgGroupsDocument} {...props} />
    );
    

/**
 * __useStaffDashboardOrgGroupsQuery__
 *
 * To run a query within a React component, call `useStaffDashboardOrgGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardOrgGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffDashboardOrgGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffDashboardOrgGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffDashboardOrgGroupsQuery, StaffDashboardOrgGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffDashboardOrgGroupsQuery, StaffDashboardOrgGroupsQueryVariables>(StaffDashboardOrgGroupsDocument, baseOptions);
      }
export function useStaffDashboardOrgGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffDashboardOrgGroupsQuery, StaffDashboardOrgGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffDashboardOrgGroupsQuery, StaffDashboardOrgGroupsQueryVariables>(StaffDashboardOrgGroupsDocument, baseOptions);
        }
export type StaffDashboardOrgGroupsQueryHookResult = ReturnType<typeof useStaffDashboardOrgGroupsQuery>;
export type StaffDashboardOrgGroupsLazyQueryHookResult = ReturnType<typeof useStaffDashboardOrgGroupsLazyQuery>;
export type StaffDashboardOrgGroupsQueryResult = ApolloReactCommon.QueryResult<StaffDashboardOrgGroupsQuery, StaffDashboardOrgGroupsQueryVariables>;
export const StaffDashboardCreateOrgGroupDocument = gql`
    mutation StaffDashboardCreateOrgGroup($input: CreateOrgGroupInput!) {
  createOrgGroup(input: $input) {
    orgGroup {
      ...StaffDashboardOrgGroup
    }
  }
}
    ${StaffDashboardOrgGroupFragmentDoc}`;
export type StaffDashboardCreateOrgGroupMutationFn = ApolloReactCommon.MutationFunction<StaffDashboardCreateOrgGroupMutation, StaffDashboardCreateOrgGroupMutationVariables>;
export type StaffDashboardCreateOrgGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StaffDashboardCreateOrgGroupMutation, StaffDashboardCreateOrgGroupMutationVariables>, 'mutation'>;

    export const StaffDashboardCreateOrgGroupComponent = (props: StaffDashboardCreateOrgGroupComponentProps) => (
      <ApolloReactComponents.Mutation<StaffDashboardCreateOrgGroupMutation, StaffDashboardCreateOrgGroupMutationVariables> mutation={StaffDashboardCreateOrgGroupDocument} {...props} />
    );
    

/**
 * __useStaffDashboardCreateOrgGroupMutation__
 *
 * To run a mutation, you first call `useStaffDashboardCreateOrgGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardCreateOrgGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffDashboardCreateOrgGroupMutation, { data, loading, error }] = useStaffDashboardCreateOrgGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStaffDashboardCreateOrgGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffDashboardCreateOrgGroupMutation, StaffDashboardCreateOrgGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffDashboardCreateOrgGroupMutation, StaffDashboardCreateOrgGroupMutationVariables>(StaffDashboardCreateOrgGroupDocument, baseOptions);
      }
export type StaffDashboardCreateOrgGroupMutationHookResult = ReturnType<typeof useStaffDashboardCreateOrgGroupMutation>;
export type StaffDashboardCreateOrgGroupMutationResult = ApolloReactCommon.MutationResult<StaffDashboardCreateOrgGroupMutation>;
export type StaffDashboardCreateOrgGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffDashboardCreateOrgGroupMutation, StaffDashboardCreateOrgGroupMutationVariables>;
export const StaffDashboardEditOrgGroupDocument = gql`
    mutation StaffDashboardEditOrgGroup($input: EditOrgGroupInput!) {
  editOrgGroup(input: $input) {
    orgGroup {
      ...StaffDashboardOrgGroup
    }
  }
}
    ${StaffDashboardOrgGroupFragmentDoc}`;
export type StaffDashboardEditOrgGroupMutationFn = ApolloReactCommon.MutationFunction<StaffDashboardEditOrgGroupMutation, StaffDashboardEditOrgGroupMutationVariables>;
export type StaffDashboardEditOrgGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StaffDashboardEditOrgGroupMutation, StaffDashboardEditOrgGroupMutationVariables>, 'mutation'>;

    export const StaffDashboardEditOrgGroupComponent = (props: StaffDashboardEditOrgGroupComponentProps) => (
      <ApolloReactComponents.Mutation<StaffDashboardEditOrgGroupMutation, StaffDashboardEditOrgGroupMutationVariables> mutation={StaffDashboardEditOrgGroupDocument} {...props} />
    );
    

/**
 * __useStaffDashboardEditOrgGroupMutation__
 *
 * To run a mutation, you first call `useStaffDashboardEditOrgGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardEditOrgGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffDashboardEditOrgGroupMutation, { data, loading, error }] = useStaffDashboardEditOrgGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStaffDashboardEditOrgGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffDashboardEditOrgGroupMutation, StaffDashboardEditOrgGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffDashboardEditOrgGroupMutation, StaffDashboardEditOrgGroupMutationVariables>(StaffDashboardEditOrgGroupDocument, baseOptions);
      }
export type StaffDashboardEditOrgGroupMutationHookResult = ReturnType<typeof useStaffDashboardEditOrgGroupMutation>;
export type StaffDashboardEditOrgGroupMutationResult = ApolloReactCommon.MutationResult<StaffDashboardEditOrgGroupMutation>;
export type StaffDashboardEditOrgGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffDashboardEditOrgGroupMutation, StaffDashboardEditOrgGroupMutationVariables>;
export const OrgsDocument = gql`
    query Orgs {
  orgs {
    ...StaffDashboardOrgPreview
  }
}
    ${StaffDashboardOrgPreviewFragmentDoc}`;
export type OrgsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgsQuery, OrgsQueryVariables>, 'query'>;

    export const OrgsComponent = (props: OrgsComponentProps) => (
      <ApolloReactComponents.Query<OrgsQuery, OrgsQueryVariables> query={OrgsDocument} {...props} />
    );
    

/**
 * __useOrgsQuery__
 *
 * To run a query within a React component, call `useOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgsQuery, OrgsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgsQuery, OrgsQueryVariables>(OrgsDocument, baseOptions);
      }
export function useOrgsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgsQuery, OrgsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgsQuery, OrgsQueryVariables>(OrgsDocument, baseOptions);
        }
export type OrgsQueryHookResult = ReturnType<typeof useOrgsQuery>;
export type OrgsLazyQueryHookResult = ReturnType<typeof useOrgsLazyQuery>;
export type OrgsQueryResult = ApolloReactCommon.QueryResult<OrgsQuery, OrgsQueryVariables>;
export const OrgsForOrgLinkerDocument = gql`
    query OrgsForOrgLinker {
  orgs {
    ...OrgLinkerOrg
  }
}
    ${OrgLinkerOrgFragmentDoc}`;
export type OrgsForOrgLinkerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgsForOrgLinkerQuery, OrgsForOrgLinkerQueryVariables>, 'query'>;

    export const OrgsForOrgLinkerComponent = (props: OrgsForOrgLinkerComponentProps) => (
      <ApolloReactComponents.Query<OrgsForOrgLinkerQuery, OrgsForOrgLinkerQueryVariables> query={OrgsForOrgLinkerDocument} {...props} />
    );
    

/**
 * __useOrgsForOrgLinkerQuery__
 *
 * To run a query within a React component, call `useOrgsForOrgLinkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgsForOrgLinkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgsForOrgLinkerQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgsForOrgLinkerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgsForOrgLinkerQuery, OrgsForOrgLinkerQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgsForOrgLinkerQuery, OrgsForOrgLinkerQueryVariables>(OrgsForOrgLinkerDocument, baseOptions);
      }
export function useOrgsForOrgLinkerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgsForOrgLinkerQuery, OrgsForOrgLinkerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgsForOrgLinkerQuery, OrgsForOrgLinkerQueryVariables>(OrgsForOrgLinkerDocument, baseOptions);
        }
export type OrgsForOrgLinkerQueryHookResult = ReturnType<typeof useOrgsForOrgLinkerQuery>;
export type OrgsForOrgLinkerLazyQueryHookResult = ReturnType<typeof useOrgsForOrgLinkerLazyQuery>;
export type OrgsForOrgLinkerQueryResult = ApolloReactCommon.QueryResult<OrgsForOrgLinkerQuery, OrgsForOrgLinkerQueryVariables>;
export const OrgForLinkingDocument = gql`
    query OrgForLinking($orgId: ID!) {
  org(id: $orgId) {
    ...OrgForLinking
  }
}
    ${OrgForLinkingFragmentDoc}`;
export type OrgForLinkingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgForLinkingQuery, OrgForLinkingQueryVariables>, 'query'> & ({ variables: OrgForLinkingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgForLinkingComponent = (props: OrgForLinkingComponentProps) => (
      <ApolloReactComponents.Query<OrgForLinkingQuery, OrgForLinkingQueryVariables> query={OrgForLinkingDocument} {...props} />
    );
    

/**
 * __useOrgForLinkingQuery__
 *
 * To run a query within a React component, call `useOrgForLinkingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgForLinkingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgForLinkingQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useOrgForLinkingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgForLinkingQuery, OrgForLinkingQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgForLinkingQuery, OrgForLinkingQueryVariables>(OrgForLinkingDocument, baseOptions);
      }
export function useOrgForLinkingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgForLinkingQuery, OrgForLinkingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgForLinkingQuery, OrgForLinkingQueryVariables>(OrgForLinkingDocument, baseOptions);
        }
export type OrgForLinkingQueryHookResult = ReturnType<typeof useOrgForLinkingQuery>;
export type OrgForLinkingLazyQueryHookResult = ReturnType<typeof useOrgForLinkingLazyQuery>;
export type OrgForLinkingQueryResult = ApolloReactCommon.QueryResult<OrgForLinkingQuery, OrgForLinkingQueryVariables>;
export const SportsWithPositionMappingsDocument = gql`
    query SportsWithPositionMappings {
  sports {
    ...SportWithPositionMappings
  }
}
    ${SportWithPositionMappingsFragmentDoc}`;
export type SportsWithPositionMappingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SportsWithPositionMappingsQuery, SportsWithPositionMappingsQueryVariables>, 'query'>;

    export const SportsWithPositionMappingsComponent = (props: SportsWithPositionMappingsComponentProps) => (
      <ApolloReactComponents.Query<SportsWithPositionMappingsQuery, SportsWithPositionMappingsQueryVariables> query={SportsWithPositionMappingsDocument} {...props} />
    );
    

/**
 * __useSportsWithPositionMappingsQuery__
 *
 * To run a query within a React component, call `useSportsWithPositionMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportsWithPositionMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsWithPositionMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSportsWithPositionMappingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportsWithPositionMappingsQuery, SportsWithPositionMappingsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportsWithPositionMappingsQuery, SportsWithPositionMappingsQueryVariables>(SportsWithPositionMappingsDocument, baseOptions);
      }
export function useSportsWithPositionMappingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportsWithPositionMappingsQuery, SportsWithPositionMappingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportsWithPositionMappingsQuery, SportsWithPositionMappingsQueryVariables>(SportsWithPositionMappingsDocument, baseOptions);
        }
export type SportsWithPositionMappingsQueryHookResult = ReturnType<typeof useSportsWithPositionMappingsQuery>;
export type SportsWithPositionMappingsLazyQueryHookResult = ReturnType<typeof useSportsWithPositionMappingsLazyQuery>;
export type SportsWithPositionMappingsQueryResult = ApolloReactCommon.QueryResult<SportsWithPositionMappingsQuery, SportsWithPositionMappingsQueryVariables>;
export const TeamworksOrgsDocument = gql`
    query TeamworksOrgs($pagination: CursorPaginationInput!, $query: String) {
  teamworksOrgCursorConnection(pagination: $pagination, query: $query) {
    edges {
      ...TeamworksOrg
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${TeamworksOrgFragmentDoc}`;
export type TeamworksOrgsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamworksOrgsQuery, TeamworksOrgsQueryVariables>, 'query'> & ({ variables: TeamworksOrgsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamworksOrgsComponent = (props: TeamworksOrgsComponentProps) => (
      <ApolloReactComponents.Query<TeamworksOrgsQuery, TeamworksOrgsQueryVariables> query={TeamworksOrgsDocument} {...props} />
    );
    

/**
 * __useTeamworksOrgsQuery__
 *
 * To run a query within a React component, call `useTeamworksOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamworksOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamworksOrgsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTeamworksOrgsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamworksOrgsQuery, TeamworksOrgsQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamworksOrgsQuery, TeamworksOrgsQueryVariables>(TeamworksOrgsDocument, baseOptions);
      }
export function useTeamworksOrgsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamworksOrgsQuery, TeamworksOrgsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamworksOrgsQuery, TeamworksOrgsQueryVariables>(TeamworksOrgsDocument, baseOptions);
        }
export type TeamworksOrgsQueryHookResult = ReturnType<typeof useTeamworksOrgsQuery>;
export type TeamworksOrgsLazyQueryHookResult = ReturnType<typeof useTeamworksOrgsLazyQuery>;
export type TeamworksOrgsQueryResult = ApolloReactCommon.QueryResult<TeamworksOrgsQuery, TeamworksOrgsQueryVariables>;
export const TeamworksOrgDocument = gql`
    query TeamworksOrg($teamworksId: Int!) {
  teamworksOrg(teamworksId: $teamworksId) {
    ...TeamworksOrg
  }
  teamworksOrgGroups(teamworksOrgId: $teamworksId) {
    ...TeamworksGroup
  }
}
    ${TeamworksOrgFragmentDoc}
${TeamworksGroupFragmentDoc}`;
export type TeamworksOrgComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamworksOrgQuery, TeamworksOrgQueryVariables>, 'query'> & ({ variables: TeamworksOrgQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamworksOrgComponent = (props: TeamworksOrgComponentProps) => (
      <ApolloReactComponents.Query<TeamworksOrgQuery, TeamworksOrgQueryVariables> query={TeamworksOrgDocument} {...props} />
    );
    

/**
 * __useTeamworksOrgQuery__
 *
 * To run a query within a React component, call `useTeamworksOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamworksOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamworksOrgQuery({
 *   variables: {
 *      teamworksId: // value for 'teamworksId'
 *   },
 * });
 */
export function useTeamworksOrgQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamworksOrgQuery, TeamworksOrgQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamworksOrgQuery, TeamworksOrgQueryVariables>(TeamworksOrgDocument, baseOptions);
      }
export function useTeamworksOrgLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamworksOrgQuery, TeamworksOrgQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamworksOrgQuery, TeamworksOrgQueryVariables>(TeamworksOrgDocument, baseOptions);
        }
export type TeamworksOrgQueryHookResult = ReturnType<typeof useTeamworksOrgQuery>;
export type TeamworksOrgLazyQueryHookResult = ReturnType<typeof useTeamworksOrgLazyQuery>;
export type TeamworksOrgQueryResult = ApolloReactCommon.QueryResult<TeamworksOrgQuery, TeamworksOrgQueryVariables>;
export const TeamworksDisabledProfileIdsDocument = gql`
    query TeamworksDisabledProfileIds($teamworksOrgId: Int!) {
  teamworksDisabledProfileIds(teamworksOrgId: $teamworksOrgId)
}
    `;
export type TeamworksDisabledProfileIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamworksDisabledProfileIdsQuery, TeamworksDisabledProfileIdsQueryVariables>, 'query'> & ({ variables: TeamworksDisabledProfileIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamworksDisabledProfileIdsComponent = (props: TeamworksDisabledProfileIdsComponentProps) => (
      <ApolloReactComponents.Query<TeamworksDisabledProfileIdsQuery, TeamworksDisabledProfileIdsQueryVariables> query={TeamworksDisabledProfileIdsDocument} {...props} />
    );
    

/**
 * __useTeamworksDisabledProfileIdsQuery__
 *
 * To run a query within a React component, call `useTeamworksDisabledProfileIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamworksDisabledProfileIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamworksDisabledProfileIdsQuery({
 *   variables: {
 *      teamworksOrgId: // value for 'teamworksOrgId'
 *   },
 * });
 */
export function useTeamworksDisabledProfileIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamworksDisabledProfileIdsQuery, TeamworksDisabledProfileIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamworksDisabledProfileIdsQuery, TeamworksDisabledProfileIdsQueryVariables>(TeamworksDisabledProfileIdsDocument, baseOptions);
      }
export function useTeamworksDisabledProfileIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamworksDisabledProfileIdsQuery, TeamworksDisabledProfileIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamworksDisabledProfileIdsQuery, TeamworksDisabledProfileIdsQueryVariables>(TeamworksDisabledProfileIdsDocument, baseOptions);
        }
export type TeamworksDisabledProfileIdsQueryHookResult = ReturnType<typeof useTeamworksDisabledProfileIdsQuery>;
export type TeamworksDisabledProfileIdsLazyQueryHookResult = ReturnType<typeof useTeamworksDisabledProfileIdsLazyQuery>;
export type TeamworksDisabledProfileIdsQueryResult = ApolloReactCommon.QueryResult<TeamworksDisabledProfileIdsQuery, TeamworksDisabledProfileIdsQueryVariables>;
export const TeamworksProfileCursorConnectionDocument = gql`
    query TeamworksProfileCursorConnection($teamworksOrgId: Int!, $pagination: CursorPaginationInput!) {
  teamworksProfileCursorConnection(
    teamworksOrgId: $teamworksOrgId
    pagination: $pagination
  ) {
    edges {
      ...TeamworksProfile
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${TeamworksProfileFragmentDoc}`;
export type TeamworksProfileCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamworksProfileCursorConnectionQuery, TeamworksProfileCursorConnectionQueryVariables>, 'query'> & ({ variables: TeamworksProfileCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamworksProfileCursorConnectionComponent = (props: TeamworksProfileCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<TeamworksProfileCursorConnectionQuery, TeamworksProfileCursorConnectionQueryVariables> query={TeamworksProfileCursorConnectionDocument} {...props} />
    );
    

/**
 * __useTeamworksProfileCursorConnectionQuery__
 *
 * To run a query within a React component, call `useTeamworksProfileCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamworksProfileCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamworksProfileCursorConnectionQuery({
 *   variables: {
 *      teamworksOrgId: // value for 'teamworksOrgId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTeamworksProfileCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamworksProfileCursorConnectionQuery, TeamworksProfileCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamworksProfileCursorConnectionQuery, TeamworksProfileCursorConnectionQueryVariables>(TeamworksProfileCursorConnectionDocument, baseOptions);
      }
export function useTeamworksProfileCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamworksProfileCursorConnectionQuery, TeamworksProfileCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamworksProfileCursorConnectionQuery, TeamworksProfileCursorConnectionQueryVariables>(TeamworksProfileCursorConnectionDocument, baseOptions);
        }
export type TeamworksProfileCursorConnectionQueryHookResult = ReturnType<typeof useTeamworksProfileCursorConnectionQuery>;
export type TeamworksProfileCursorConnectionLazyQueryHookResult = ReturnType<typeof useTeamworksProfileCursorConnectionLazyQuery>;
export type TeamworksProfileCursorConnectionQueryResult = ApolloReactCommon.QueryResult<TeamworksProfileCursorConnectionQuery, TeamworksProfileCursorConnectionQueryVariables>;
export const TeamworksPositionsDocument = gql`
    query TeamworksPositions($teamworksOrgId: Int!, $teamId: Int!) {
  teamworksOrgGroups(
    teamworksOrgId: $teamworksOrgId
    teamId: $teamId
    type: position
  ) {
    ...TeamworksPosition
  }
}
    ${TeamworksPositionFragmentDoc}`;
export type TeamworksPositionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamworksPositionsQuery, TeamworksPositionsQueryVariables>, 'query'> & ({ variables: TeamworksPositionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamworksPositionsComponent = (props: TeamworksPositionsComponentProps) => (
      <ApolloReactComponents.Query<TeamworksPositionsQuery, TeamworksPositionsQueryVariables> query={TeamworksPositionsDocument} {...props} />
    );
    

/**
 * __useTeamworksPositionsQuery__
 *
 * To run a query within a React component, call `useTeamworksPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamworksPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamworksPositionsQuery({
 *   variables: {
 *      teamworksOrgId: // value for 'teamworksOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamworksPositionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamworksPositionsQuery, TeamworksPositionsQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamworksPositionsQuery, TeamworksPositionsQueryVariables>(TeamworksPositionsDocument, baseOptions);
      }
export function useTeamworksPositionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamworksPositionsQuery, TeamworksPositionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamworksPositionsQuery, TeamworksPositionsQueryVariables>(TeamworksPositionsDocument, baseOptions);
        }
export type TeamworksPositionsQueryHookResult = ReturnType<typeof useTeamworksPositionsQuery>;
export type TeamworksPositionsLazyQueryHookResult = ReturnType<typeof useTeamworksPositionsLazyQuery>;
export type TeamworksPositionsQueryResult = ApolloReactCommon.QueryResult<TeamworksPositionsQuery, TeamworksPositionsQueryVariables>;
export const TeamworksPositionLinksDocument = gql`
    query TeamworksPositionLinks {
  teamworksPositionLinks {
    ...TeamworksPositionLink
  }
}
    ${TeamworksPositionLinkFragmentDoc}`;
export type TeamworksPositionLinksComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamworksPositionLinksQuery, TeamworksPositionLinksQueryVariables>, 'query'>;

    export const TeamworksPositionLinksComponent = (props: TeamworksPositionLinksComponentProps) => (
      <ApolloReactComponents.Query<TeamworksPositionLinksQuery, TeamworksPositionLinksQueryVariables> query={TeamworksPositionLinksDocument} {...props} />
    );
    

/**
 * __useTeamworksPositionLinksQuery__
 *
 * To run a query within a React component, call `useTeamworksPositionLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamworksPositionLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamworksPositionLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamworksPositionLinksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamworksPositionLinksQuery, TeamworksPositionLinksQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamworksPositionLinksQuery, TeamworksPositionLinksQueryVariables>(TeamworksPositionLinksDocument, baseOptions);
      }
export function useTeamworksPositionLinksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamworksPositionLinksQuery, TeamworksPositionLinksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamworksPositionLinksQuery, TeamworksPositionLinksQueryVariables>(TeamworksPositionLinksDocument, baseOptions);
        }
export type TeamworksPositionLinksQueryHookResult = ReturnType<typeof useTeamworksPositionLinksQuery>;
export type TeamworksPositionLinksLazyQueryHookResult = ReturnType<typeof useTeamworksPositionLinksLazyQuery>;
export type TeamworksPositionLinksQueryResult = ApolloReactCommon.QueryResult<TeamworksPositionLinksQuery, TeamworksPositionLinksQueryVariables>;
export const TeamworksSportMappingsDocument = gql`
    query TeamworksSportMappings($query: String) {
  teamworksSportMappings(query: $query) {
    ...TeamworksSportMapping
  }
}
    ${TeamworksSportMappingFragmentDoc}`;
export type TeamworksSportMappingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamworksSportMappingsQuery, TeamworksSportMappingsQueryVariables>, 'query'>;

    export const TeamworksSportMappingsComponent = (props: TeamworksSportMappingsComponentProps) => (
      <ApolloReactComponents.Query<TeamworksSportMappingsQuery, TeamworksSportMappingsQueryVariables> query={TeamworksSportMappingsDocument} {...props} />
    );
    

/**
 * __useTeamworksSportMappingsQuery__
 *
 * To run a query within a React component, call `useTeamworksSportMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamworksSportMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamworksSportMappingsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTeamworksSportMappingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamworksSportMappingsQuery, TeamworksSportMappingsQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamworksSportMappingsQuery, TeamworksSportMappingsQueryVariables>(TeamworksSportMappingsDocument, baseOptions);
      }
export function useTeamworksSportMappingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamworksSportMappingsQuery, TeamworksSportMappingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamworksSportMappingsQuery, TeamworksSportMappingsQueryVariables>(TeamworksSportMappingsDocument, baseOptions);
        }
export type TeamworksSportMappingsQueryHookResult = ReturnType<typeof useTeamworksSportMappingsQuery>;
export type TeamworksSportMappingsLazyQueryHookResult = ReturnType<typeof useTeamworksSportMappingsLazyQuery>;
export type TeamworksSportMappingsQueryResult = ApolloReactCommon.QueryResult<TeamworksSportMappingsQuery, TeamworksSportMappingsQueryVariables>;
export const CreateOrgFromTeamworksOrgDocument = gql`
    mutation CreateOrgFromTeamworksOrg($input: CreateOrgFromTeamworksOrgInput!) {
  createOrgFromTeamworksOrg(input: $input) {
    org {
      ...OrgLinkerOrg
    }
  }
}
    ${OrgLinkerOrgFragmentDoc}`;
export type CreateOrgFromTeamworksOrgMutationFn = ApolloReactCommon.MutationFunction<CreateOrgFromTeamworksOrgMutation, CreateOrgFromTeamworksOrgMutationVariables>;
export type CreateOrgFromTeamworksOrgComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrgFromTeamworksOrgMutation, CreateOrgFromTeamworksOrgMutationVariables>, 'mutation'>;

    export const CreateOrgFromTeamworksOrgComponent = (props: CreateOrgFromTeamworksOrgComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrgFromTeamworksOrgMutation, CreateOrgFromTeamworksOrgMutationVariables> mutation={CreateOrgFromTeamworksOrgDocument} {...props} />
    );
    

/**
 * __useCreateOrgFromTeamworksOrgMutation__
 *
 * To run a mutation, you first call `useCreateOrgFromTeamworksOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgFromTeamworksOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgFromTeamworksOrgMutation, { data, loading, error }] = useCreateOrgFromTeamworksOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgFromTeamworksOrgMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgFromTeamworksOrgMutation, CreateOrgFromTeamworksOrgMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgFromTeamworksOrgMutation, CreateOrgFromTeamworksOrgMutationVariables>(CreateOrgFromTeamworksOrgDocument, baseOptions);
      }
export type CreateOrgFromTeamworksOrgMutationHookResult = ReturnType<typeof useCreateOrgFromTeamworksOrgMutation>;
export type CreateOrgFromTeamworksOrgMutationResult = ApolloReactCommon.MutationResult<CreateOrgFromTeamworksOrgMutation>;
export type CreateOrgFromTeamworksOrgMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgFromTeamworksOrgMutation, CreateOrgFromTeamworksOrgMutationVariables>;
export const LinkTeamworksOrgDocument = gql`
    mutation LinkTeamworksOrg($input: LinkTeamworksOrgInput!) {
  linkTeamworksOrg(input: $input) {
    org {
      ...OrgLinkerOrg
    }
  }
}
    ${OrgLinkerOrgFragmentDoc}`;
export type LinkTeamworksOrgMutationFn = ApolloReactCommon.MutationFunction<LinkTeamworksOrgMutation, LinkTeamworksOrgMutationVariables>;
export type LinkTeamworksOrgComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LinkTeamworksOrgMutation, LinkTeamworksOrgMutationVariables>, 'mutation'>;

    export const LinkTeamworksOrgComponent = (props: LinkTeamworksOrgComponentProps) => (
      <ApolloReactComponents.Mutation<LinkTeamworksOrgMutation, LinkTeamworksOrgMutationVariables> mutation={LinkTeamworksOrgDocument} {...props} />
    );
    

/**
 * __useLinkTeamworksOrgMutation__
 *
 * To run a mutation, you first call `useLinkTeamworksOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkTeamworksOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkTeamworksOrgMutation, { data, loading, error }] = useLinkTeamworksOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkTeamworksOrgMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LinkTeamworksOrgMutation, LinkTeamworksOrgMutationVariables>) {
        return ApolloReactHooks.useMutation<LinkTeamworksOrgMutation, LinkTeamworksOrgMutationVariables>(LinkTeamworksOrgDocument, baseOptions);
      }
export type LinkTeamworksOrgMutationHookResult = ReturnType<typeof useLinkTeamworksOrgMutation>;
export type LinkTeamworksOrgMutationResult = ApolloReactCommon.MutationResult<LinkTeamworksOrgMutation>;
export type LinkTeamworksOrgMutationOptions = ApolloReactCommon.BaseMutationOptions<LinkTeamworksOrgMutation, LinkTeamworksOrgMutationVariables>;
export const AddAndLinkTeamworksTeamsDocument = gql`
    mutation AddAndLinkTeamworksTeams($input: AddAndLinkTeamworksTeamsInput!) {
  addAndLinkTeamworksTeams(input: $input) {
    org {
      id
      teams {
        ...TeamLinkPreview
      }
    }
  }
}
    ${TeamLinkPreviewFragmentDoc}`;
export type AddAndLinkTeamworksTeamsMutationFn = ApolloReactCommon.MutationFunction<AddAndLinkTeamworksTeamsMutation, AddAndLinkTeamworksTeamsMutationVariables>;
export type AddAndLinkTeamworksTeamsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAndLinkTeamworksTeamsMutation, AddAndLinkTeamworksTeamsMutationVariables>, 'mutation'>;

    export const AddAndLinkTeamworksTeamsComponent = (props: AddAndLinkTeamworksTeamsComponentProps) => (
      <ApolloReactComponents.Mutation<AddAndLinkTeamworksTeamsMutation, AddAndLinkTeamworksTeamsMutationVariables> mutation={AddAndLinkTeamworksTeamsDocument} {...props} />
    );
    

/**
 * __useAddAndLinkTeamworksTeamsMutation__
 *
 * To run a mutation, you first call `useAddAndLinkTeamworksTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAndLinkTeamworksTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAndLinkTeamworksTeamsMutation, { data, loading, error }] = useAddAndLinkTeamworksTeamsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAndLinkTeamworksTeamsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAndLinkTeamworksTeamsMutation, AddAndLinkTeamworksTeamsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAndLinkTeamworksTeamsMutation, AddAndLinkTeamworksTeamsMutationVariables>(AddAndLinkTeamworksTeamsDocument, baseOptions);
      }
export type AddAndLinkTeamworksTeamsMutationHookResult = ReturnType<typeof useAddAndLinkTeamworksTeamsMutation>;
export type AddAndLinkTeamworksTeamsMutationResult = ApolloReactCommon.MutationResult<AddAndLinkTeamworksTeamsMutation>;
export type AddAndLinkTeamworksTeamsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAndLinkTeamworksTeamsMutation, AddAndLinkTeamworksTeamsMutationVariables>;
export const EditOrgTeamworksProfilesDocument = gql`
    mutation EditOrgTeamworksProfiles($input: EditOrgTeamworksProfilesInput!) {
  editOrgTeamworksProfiles(input: $input) {
    org {
      ...OrgForLinking
    }
    skippedProfiles {
      teamworksUserId
      name
      reason
      conflictedUserIds
    }
  }
}
    ${OrgForLinkingFragmentDoc}`;
export type EditOrgTeamworksProfilesMutationFn = ApolloReactCommon.MutationFunction<EditOrgTeamworksProfilesMutation, EditOrgTeamworksProfilesMutationVariables>;
export type EditOrgTeamworksProfilesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditOrgTeamworksProfilesMutation, EditOrgTeamworksProfilesMutationVariables>, 'mutation'>;

    export const EditOrgTeamworksProfilesComponent = (props: EditOrgTeamworksProfilesComponentProps) => (
      <ApolloReactComponents.Mutation<EditOrgTeamworksProfilesMutation, EditOrgTeamworksProfilesMutationVariables> mutation={EditOrgTeamworksProfilesDocument} {...props} />
    );
    

/**
 * __useEditOrgTeamworksProfilesMutation__
 *
 * To run a mutation, you first call `useEditOrgTeamworksProfilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgTeamworksProfilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgTeamworksProfilesMutation, { data, loading, error }] = useEditOrgTeamworksProfilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgTeamworksProfilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrgTeamworksProfilesMutation, EditOrgTeamworksProfilesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditOrgTeamworksProfilesMutation, EditOrgTeamworksProfilesMutationVariables>(EditOrgTeamworksProfilesDocument, baseOptions);
      }
export type EditOrgTeamworksProfilesMutationHookResult = ReturnType<typeof useEditOrgTeamworksProfilesMutation>;
export type EditOrgTeamworksProfilesMutationResult = ApolloReactCommon.MutationResult<EditOrgTeamworksProfilesMutation>;
export type EditOrgTeamworksProfilesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrgTeamworksProfilesMutation, EditOrgTeamworksProfilesMutationVariables>;
export const OrgsStatusSyncDocument = gql`
    query OrgsStatusSync {
  orgs {
    ...OrgStatusSync
  }
}
    ${OrgStatusSyncFragmentDoc}`;
export type OrgsStatusSyncComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgsStatusSyncQuery, OrgsStatusSyncQueryVariables>, 'query'>;

    export const OrgsStatusSyncComponent = (props: OrgsStatusSyncComponentProps) => (
      <ApolloReactComponents.Query<OrgsStatusSyncQuery, OrgsStatusSyncQueryVariables> query={OrgsStatusSyncDocument} {...props} />
    );
    

/**
 * __useOrgsStatusSyncQuery__
 *
 * To run a query within a React component, call `useOrgsStatusSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgsStatusSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgsStatusSyncQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgsStatusSyncQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgsStatusSyncQuery, OrgsStatusSyncQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgsStatusSyncQuery, OrgsStatusSyncQueryVariables>(OrgsStatusSyncDocument, baseOptions);
      }
export function useOrgsStatusSyncLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgsStatusSyncQuery, OrgsStatusSyncQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgsStatusSyncQuery, OrgsStatusSyncQueryVariables>(OrgsStatusSyncDocument, baseOptions);
        }
export type OrgsStatusSyncQueryHookResult = ReturnType<typeof useOrgsStatusSyncQuery>;
export type OrgsStatusSyncLazyQueryHookResult = ReturnType<typeof useOrgsStatusSyncLazyQuery>;
export type OrgsStatusSyncQueryResult = ApolloReactCommon.QueryResult<OrgsStatusSyncQuery, OrgsStatusSyncQueryVariables>;
export const RefreshTeamworksSyncStatusDocument = gql`
    mutation RefreshTeamworksSyncStatus($input: RefreshTeamworksSyncStatusInput!) {
  refreshTeamworksSyncStatus(input: $input) {
    org {
      id
      teamworksSyncStatus {
        ...TeamworksSyncStatus
      }
    }
  }
}
    ${TeamworksSyncStatusFragmentDoc}`;
export type RefreshTeamworksSyncStatusMutationFn = ApolloReactCommon.MutationFunction<RefreshTeamworksSyncStatusMutation, RefreshTeamworksSyncStatusMutationVariables>;
export type RefreshTeamworksSyncStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RefreshTeamworksSyncStatusMutation, RefreshTeamworksSyncStatusMutationVariables>, 'mutation'>;

    export const RefreshTeamworksSyncStatusComponent = (props: RefreshTeamworksSyncStatusComponentProps) => (
      <ApolloReactComponents.Mutation<RefreshTeamworksSyncStatusMutation, RefreshTeamworksSyncStatusMutationVariables> mutation={RefreshTeamworksSyncStatusDocument} {...props} />
    );
    

/**
 * __useRefreshTeamworksSyncStatusMutation__
 *
 * To run a mutation, you first call `useRefreshTeamworksSyncStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTeamworksSyncStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTeamworksSyncStatusMutation, { data, loading, error }] = useRefreshTeamworksSyncStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshTeamworksSyncStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshTeamworksSyncStatusMutation, RefreshTeamworksSyncStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<RefreshTeamworksSyncStatusMutation, RefreshTeamworksSyncStatusMutationVariables>(RefreshTeamworksSyncStatusDocument, baseOptions);
      }
export type RefreshTeamworksSyncStatusMutationHookResult = ReturnType<typeof useRefreshTeamworksSyncStatusMutation>;
export type RefreshTeamworksSyncStatusMutationResult = ApolloReactCommon.MutationResult<RefreshTeamworksSyncStatusMutation>;
export type RefreshTeamworksSyncStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshTeamworksSyncStatusMutation, RefreshTeamworksSyncStatusMutationVariables>;
export const RunMaintainTeamworksFullySyncedJobDocument = gql`
    mutation RunMaintainTeamworksFullySyncedJob($input: RefreshTeamworksSyncStatusInput!) {
  runMaintainTeamworksFullySyncedJob(input: $input) {
    org {
      id
    }
  }
}
    `;
export type RunMaintainTeamworksFullySyncedJobMutationFn = ApolloReactCommon.MutationFunction<RunMaintainTeamworksFullySyncedJobMutation, RunMaintainTeamworksFullySyncedJobMutationVariables>;
export type RunMaintainTeamworksFullySyncedJobComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RunMaintainTeamworksFullySyncedJobMutation, RunMaintainTeamworksFullySyncedJobMutationVariables>, 'mutation'>;

    export const RunMaintainTeamworksFullySyncedJobComponent = (props: RunMaintainTeamworksFullySyncedJobComponentProps) => (
      <ApolloReactComponents.Mutation<RunMaintainTeamworksFullySyncedJobMutation, RunMaintainTeamworksFullySyncedJobMutationVariables> mutation={RunMaintainTeamworksFullySyncedJobDocument} {...props} />
    );
    

/**
 * __useRunMaintainTeamworksFullySyncedJobMutation__
 *
 * To run a mutation, you first call `useRunMaintainTeamworksFullySyncedJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunMaintainTeamworksFullySyncedJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runMaintainTeamworksFullySyncedJobMutation, { data, loading, error }] = useRunMaintainTeamworksFullySyncedJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunMaintainTeamworksFullySyncedJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RunMaintainTeamworksFullySyncedJobMutation, RunMaintainTeamworksFullySyncedJobMutationVariables>) {
        return ApolloReactHooks.useMutation<RunMaintainTeamworksFullySyncedJobMutation, RunMaintainTeamworksFullySyncedJobMutationVariables>(RunMaintainTeamworksFullySyncedJobDocument, baseOptions);
      }
export type RunMaintainTeamworksFullySyncedJobMutationHookResult = ReturnType<typeof useRunMaintainTeamworksFullySyncedJobMutation>;
export type RunMaintainTeamworksFullySyncedJobMutationResult = ApolloReactCommon.MutationResult<RunMaintainTeamworksFullySyncedJobMutation>;
export type RunMaintainTeamworksFullySyncedJobMutationOptions = ApolloReactCommon.BaseMutationOptions<RunMaintainTeamworksFullySyncedJobMutation, RunMaintainTeamworksFullySyncedJobMutationVariables>;
export const EditTeamForStaffDocument = gql`
    mutation EditTeamForStaff($input: EditTeamForStaffInput!) {
  editTeamForStaff(input: $input) {
    team {
      ...TeamLinkPreview
    }
  }
}
    ${TeamLinkPreviewFragmentDoc}`;
export type EditTeamForStaffMutationFn = ApolloReactCommon.MutationFunction<EditTeamForStaffMutation, EditTeamForStaffMutationVariables>;
export type EditTeamForStaffComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditTeamForStaffMutation, EditTeamForStaffMutationVariables>, 'mutation'>;

    export const EditTeamForStaffComponent = (props: EditTeamForStaffComponentProps) => (
      <ApolloReactComponents.Mutation<EditTeamForStaffMutation, EditTeamForStaffMutationVariables> mutation={EditTeamForStaffDocument} {...props} />
    );
    

/**
 * __useEditTeamForStaffMutation__
 *
 * To run a mutation, you first call `useEditTeamForStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamForStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamForStaffMutation, { data, loading, error }] = useEditTeamForStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTeamForStaffMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTeamForStaffMutation, EditTeamForStaffMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTeamForStaffMutation, EditTeamForStaffMutationVariables>(EditTeamForStaffDocument, baseOptions);
      }
export type EditTeamForStaffMutationHookResult = ReturnType<typeof useEditTeamForStaffMutation>;
export type EditTeamForStaffMutationResult = ApolloReactCommon.MutationResult<EditTeamForStaffMutation>;
export type EditTeamForStaffMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTeamForStaffMutation, EditTeamForStaffMutationVariables>;
export const IsEmailUniqueDocument = gql`
    query IsEmailUnique($email: String!) {
  isEmailUnique(email: $email)
}
    `;
export type IsEmailUniqueComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>, 'query'> & ({ variables: IsEmailUniqueQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const IsEmailUniqueComponent = (props: IsEmailUniqueComponentProps) => (
      <ApolloReactComponents.Query<IsEmailUniqueQuery, IsEmailUniqueQueryVariables> query={IsEmailUniqueDocument} {...props} />
    );
    

/**
 * __useIsEmailUniqueQuery__
 *
 * To run a query within a React component, call `useIsEmailUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailUniqueQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailUniqueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>) {
        return ApolloReactHooks.useQuery<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>(IsEmailUniqueDocument, baseOptions);
      }
export function useIsEmailUniqueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>(IsEmailUniqueDocument, baseOptions);
        }
export type IsEmailUniqueQueryHookResult = ReturnType<typeof useIsEmailUniqueQuery>;
export type IsEmailUniqueLazyQueryHookResult = ReturnType<typeof useIsEmailUniqueLazyQuery>;
export type IsEmailUniqueQueryResult = ApolloReactCommon.QueryResult<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>;
export const IsPhoneNumberUniqueDocument = gql`
    query IsPhoneNumberUnique($phoneNumber: String!) {
  isPhoneNumberUnique(phoneNumber: $phoneNumber)
}
    `;
export type IsPhoneNumberUniqueComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsPhoneNumberUniqueQuery, IsPhoneNumberUniqueQueryVariables>, 'query'> & ({ variables: IsPhoneNumberUniqueQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const IsPhoneNumberUniqueComponent = (props: IsPhoneNumberUniqueComponentProps) => (
      <ApolloReactComponents.Query<IsPhoneNumberUniqueQuery, IsPhoneNumberUniqueQueryVariables> query={IsPhoneNumberUniqueDocument} {...props} />
    );
    

/**
 * __useIsPhoneNumberUniqueQuery__
 *
 * To run a query within a React component, call `useIsPhoneNumberUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPhoneNumberUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPhoneNumberUniqueQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useIsPhoneNumberUniqueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsPhoneNumberUniqueQuery, IsPhoneNumberUniqueQueryVariables>) {
        return ApolloReactHooks.useQuery<IsPhoneNumberUniqueQuery, IsPhoneNumberUniqueQueryVariables>(IsPhoneNumberUniqueDocument, baseOptions);
      }
export function useIsPhoneNumberUniqueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsPhoneNumberUniqueQuery, IsPhoneNumberUniqueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsPhoneNumberUniqueQuery, IsPhoneNumberUniqueQueryVariables>(IsPhoneNumberUniqueDocument, baseOptions);
        }
export type IsPhoneNumberUniqueQueryHookResult = ReturnType<typeof useIsPhoneNumberUniqueQuery>;
export type IsPhoneNumberUniqueLazyQueryHookResult = ReturnType<typeof useIsPhoneNumberUniqueLazyQuery>;
export type IsPhoneNumberUniqueQueryResult = ApolloReactCommon.QueryResult<IsPhoneNumberUniqueQuery, IsPhoneNumberUniqueQueryVariables>;
export const AddRestaurantLocationContactDocument = gql`
    mutation AddRestaurantLocationContact($input: AddRestaurantLocationContactInput!) {
  addRestaurantLocationContact(input: $input) {
    restaurantLocationContact {
      ...Contact
    }
  }
}
    ${ContactFragmentDoc}`;
export type AddRestaurantLocationContactMutationFn = ApolloReactCommon.MutationFunction<AddRestaurantLocationContactMutation, AddRestaurantLocationContactMutationVariables>;
export type AddRestaurantLocationContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddRestaurantLocationContactMutation, AddRestaurantLocationContactMutationVariables>, 'mutation'>;

    export const AddRestaurantLocationContactComponent = (props: AddRestaurantLocationContactComponentProps) => (
      <ApolloReactComponents.Mutation<AddRestaurantLocationContactMutation, AddRestaurantLocationContactMutationVariables> mutation={AddRestaurantLocationContactDocument} {...props} />
    );
    

/**
 * __useAddRestaurantLocationContactMutation__
 *
 * To run a mutation, you first call `useAddRestaurantLocationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRestaurantLocationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRestaurantLocationContactMutation, { data, loading, error }] = useAddRestaurantLocationContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRestaurantLocationContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRestaurantLocationContactMutation, AddRestaurantLocationContactMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRestaurantLocationContactMutation, AddRestaurantLocationContactMutationVariables>(AddRestaurantLocationContactDocument, baseOptions);
      }
export type AddRestaurantLocationContactMutationHookResult = ReturnType<typeof useAddRestaurantLocationContactMutation>;
export type AddRestaurantLocationContactMutationResult = ApolloReactCommon.MutationResult<AddRestaurantLocationContactMutation>;
export type AddRestaurantLocationContactMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRestaurantLocationContactMutation, AddRestaurantLocationContactMutationVariables>;
export const EditRestaurantLocationContactDocument = gql`
    mutation EditRestaurantLocationContact($input: EditRestaurantLocationContactInput!) {
  editRestaurantLocationContact(input: $input) {
    restaurantLocationContact {
      ...Contact
    }
  }
}
    ${ContactFragmentDoc}`;
export type EditRestaurantLocationContactMutationFn = ApolloReactCommon.MutationFunction<EditRestaurantLocationContactMutation, EditRestaurantLocationContactMutationVariables>;
export type EditRestaurantLocationContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRestaurantLocationContactMutation, EditRestaurantLocationContactMutationVariables>, 'mutation'>;

    export const EditRestaurantLocationContactComponent = (props: EditRestaurantLocationContactComponentProps) => (
      <ApolloReactComponents.Mutation<EditRestaurantLocationContactMutation, EditRestaurantLocationContactMutationVariables> mutation={EditRestaurantLocationContactDocument} {...props} />
    );
    

/**
 * __useEditRestaurantLocationContactMutation__
 *
 * To run a mutation, you first call `useEditRestaurantLocationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRestaurantLocationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRestaurantLocationContactMutation, { data, loading, error }] = useEditRestaurantLocationContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRestaurantLocationContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRestaurantLocationContactMutation, EditRestaurantLocationContactMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRestaurantLocationContactMutation, EditRestaurantLocationContactMutationVariables>(EditRestaurantLocationContactDocument, baseOptions);
      }
export type EditRestaurantLocationContactMutationHookResult = ReturnType<typeof useEditRestaurantLocationContactMutation>;
export type EditRestaurantLocationContactMutationResult = ApolloReactCommon.MutationResult<EditRestaurantLocationContactMutation>;
export type EditRestaurantLocationContactMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRestaurantLocationContactMutation, EditRestaurantLocationContactMutationVariables>;
export const GoogleMapsApiKeyDocument = gql`
    query GoogleMapsApiKey {
  googleMapsApiKey
}
    `;
export type GoogleMapsApiKeyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GoogleMapsApiKeyQuery, GoogleMapsApiKeyQueryVariables>, 'query'>;

    export const GoogleMapsApiKeyComponent = (props: GoogleMapsApiKeyComponentProps) => (
      <ApolloReactComponents.Query<GoogleMapsApiKeyQuery, GoogleMapsApiKeyQueryVariables> query={GoogleMapsApiKeyDocument} {...props} />
    );
    

/**
 * __useGoogleMapsApiKeyQuery__
 *
 * To run a query within a React component, call `useGoogleMapsApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleMapsApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleMapsApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoogleMapsApiKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GoogleMapsApiKeyQuery, GoogleMapsApiKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<GoogleMapsApiKeyQuery, GoogleMapsApiKeyQueryVariables>(GoogleMapsApiKeyDocument, baseOptions);
      }
export function useGoogleMapsApiKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoogleMapsApiKeyQuery, GoogleMapsApiKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GoogleMapsApiKeyQuery, GoogleMapsApiKeyQueryVariables>(GoogleMapsApiKeyDocument, baseOptions);
        }
export type GoogleMapsApiKeyQueryHookResult = ReturnType<typeof useGoogleMapsApiKeyQuery>;
export type GoogleMapsApiKeyLazyQueryHookResult = ReturnType<typeof useGoogleMapsApiKeyLazyQuery>;
export type GoogleMapsApiKeyQueryResult = ApolloReactCommon.QueryResult<GoogleMapsApiKeyQuery, GoogleMapsApiKeyQueryVariables>;
export const RestaurantLocationsDocument = gql`
    query RestaurantLocations($input: OffsetPaginationInput!, $restaurantId: ID!, $query: String) {
  allRestaurantLocations(
    input: $input
    restaurantId: $restaurantId
    query: $query
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...RestaurantLocation
    }
  }
}
    ${RestaurantLocationFragmentDoc}`;
export type RestaurantLocationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantLocationsQuery, RestaurantLocationsQueryVariables>, 'query'> & ({ variables: RestaurantLocationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantLocationsComponent = (props: RestaurantLocationsComponentProps) => (
      <ApolloReactComponents.Query<RestaurantLocationsQuery, RestaurantLocationsQueryVariables> query={RestaurantLocationsDocument} {...props} />
    );
    

/**
 * __useRestaurantLocationsQuery__
 *
 * To run a query within a React component, call `useRestaurantLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      restaurantId: // value for 'restaurantId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRestaurantLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantLocationsQuery, RestaurantLocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantLocationsQuery, RestaurantLocationsQueryVariables>(RestaurantLocationsDocument, baseOptions);
      }
export function useRestaurantLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantLocationsQuery, RestaurantLocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantLocationsQuery, RestaurantLocationsQueryVariables>(RestaurantLocationsDocument, baseOptions);
        }
export type RestaurantLocationsQueryHookResult = ReturnType<typeof useRestaurantLocationsQuery>;
export type RestaurantLocationsLazyQueryHookResult = ReturnType<typeof useRestaurantLocationsLazyQuery>;
export type RestaurantLocationsQueryResult = ApolloReactCommon.QueryResult<RestaurantLocationsQuery, RestaurantLocationsQueryVariables>;
export const AddRestaurantLocationDocument = gql`
    mutation AddRestaurantLocation($input: AddRestaurantLocationInput!) {
  addRestaurantLocation(input: $input) {
    restaurantLocation {
      ...RestaurantLocation
    }
  }
}
    ${RestaurantLocationFragmentDoc}`;
export type AddRestaurantLocationMutationFn = ApolloReactCommon.MutationFunction<AddRestaurantLocationMutation, AddRestaurantLocationMutationVariables>;
export type AddRestaurantLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddRestaurantLocationMutation, AddRestaurantLocationMutationVariables>, 'mutation'>;

    export const AddRestaurantLocationComponent = (props: AddRestaurantLocationComponentProps) => (
      <ApolloReactComponents.Mutation<AddRestaurantLocationMutation, AddRestaurantLocationMutationVariables> mutation={AddRestaurantLocationDocument} {...props} />
    );
    

/**
 * __useAddRestaurantLocationMutation__
 *
 * To run a mutation, you first call `useAddRestaurantLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRestaurantLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRestaurantLocationMutation, { data, loading, error }] = useAddRestaurantLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRestaurantLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRestaurantLocationMutation, AddRestaurantLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRestaurantLocationMutation, AddRestaurantLocationMutationVariables>(AddRestaurantLocationDocument, baseOptions);
      }
export type AddRestaurantLocationMutationHookResult = ReturnType<typeof useAddRestaurantLocationMutation>;
export type AddRestaurantLocationMutationResult = ApolloReactCommon.MutationResult<AddRestaurantLocationMutation>;
export type AddRestaurantLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRestaurantLocationMutation, AddRestaurantLocationMutationVariables>;
export const EditRestaurantLocationDocument = gql`
    mutation EditRestaurantLocation($input: EditRestaurantLocationInput!) {
  editRestaurantLocation(input: $input) {
    restaurantLocation {
      ...RestaurantLocation
    }
  }
}
    ${RestaurantLocationFragmentDoc}`;
export type EditRestaurantLocationMutationFn = ApolloReactCommon.MutationFunction<EditRestaurantLocationMutation, EditRestaurantLocationMutationVariables>;
export type EditRestaurantLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRestaurantLocationMutation, EditRestaurantLocationMutationVariables>, 'mutation'>;

    export const EditRestaurantLocationComponent = (props: EditRestaurantLocationComponentProps) => (
      <ApolloReactComponents.Mutation<EditRestaurantLocationMutation, EditRestaurantLocationMutationVariables> mutation={EditRestaurantLocationDocument} {...props} />
    );
    

/**
 * __useEditRestaurantLocationMutation__
 *
 * To run a mutation, you first call `useEditRestaurantLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRestaurantLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRestaurantLocationMutation, { data, loading, error }] = useEditRestaurantLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRestaurantLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRestaurantLocationMutation, EditRestaurantLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRestaurantLocationMutation, EditRestaurantLocationMutationVariables>(EditRestaurantLocationDocument, baseOptions);
      }
export type EditRestaurantLocationMutationHookResult = ReturnType<typeof useEditRestaurantLocationMutation>;
export type EditRestaurantLocationMutationResult = ApolloReactCommon.MutationResult<EditRestaurantLocationMutation>;
export type EditRestaurantLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRestaurantLocationMutation, EditRestaurantLocationMutationVariables>;
export const DeliveryLocationSearchDocument = gql`
    query DeliveryLocationSearch($teamLabelId: ID!, $query: String) {
  allDeliveryLocations(
    teamLabelId: $teamLabelId
    query: $query
    input: {offset: 0, limit: 25}
  ) {
    edges {
      ...DeliveryLocationMenuLinkForm
    }
  }
}
    ${DeliveryLocationMenuLinkFormFragmentDoc}`;
export type DeliveryLocationSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DeliveryLocationSearchQuery, DeliveryLocationSearchQueryVariables>, 'query'> & ({ variables: DeliveryLocationSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DeliveryLocationSearchComponent = (props: DeliveryLocationSearchComponentProps) => (
      <ApolloReactComponents.Query<DeliveryLocationSearchQuery, DeliveryLocationSearchQueryVariables> query={DeliveryLocationSearchDocument} {...props} />
    );
    

/**
 * __useDeliveryLocationSearchQuery__
 *
 * To run a query within a React component, call `useDeliveryLocationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryLocationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryLocationSearchQuery({
 *   variables: {
 *      teamLabelId: // value for 'teamLabelId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDeliveryLocationSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveryLocationSearchQuery, DeliveryLocationSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveryLocationSearchQuery, DeliveryLocationSearchQueryVariables>(DeliveryLocationSearchDocument, baseOptions);
      }
export function useDeliveryLocationSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveryLocationSearchQuery, DeliveryLocationSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveryLocationSearchQuery, DeliveryLocationSearchQueryVariables>(DeliveryLocationSearchDocument, baseOptions);
        }
export type DeliveryLocationSearchQueryHookResult = ReturnType<typeof useDeliveryLocationSearchQuery>;
export type DeliveryLocationSearchLazyQueryHookResult = ReturnType<typeof useDeliveryLocationSearchLazyQuery>;
export type DeliveryLocationSearchQueryResult = ApolloReactCommon.QueryResult<DeliveryLocationSearchQuery, DeliveryLocationSearchQueryVariables>;
export const RestaurantDocument = gql`
    query Restaurant($id: ID!) {
  restaurant(id: $id) {
    ...StaffDashboardRestaurant
  }
}
    ${StaffDashboardRestaurantFragmentDoc}`;
export type RestaurantComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantQuery, RestaurantQueryVariables>, 'query'> & ({ variables: RestaurantQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantComponent = (props: RestaurantComponentProps) => (
      <ApolloReactComponents.Query<RestaurantQuery, RestaurantQueryVariables> query={RestaurantDocument} {...props} />
    );
    

/**
 * __useRestaurantQuery__
 *
 * To run a query within a React component, call `useRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestaurantQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantQuery, RestaurantQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantQuery, RestaurantQueryVariables>(RestaurantDocument, baseOptions);
      }
export function useRestaurantLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantQuery, RestaurantQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantQuery, RestaurantQueryVariables>(RestaurantDocument, baseOptions);
        }
export type RestaurantQueryHookResult = ReturnType<typeof useRestaurantQuery>;
export type RestaurantLazyQueryHookResult = ReturnType<typeof useRestaurantLazyQuery>;
export type RestaurantQueryResult = ApolloReactCommon.QueryResult<RestaurantQuery, RestaurantQueryVariables>;
export const CreateRestaurantMenuDocument = gql`
    mutation CreateRestaurantMenu($input: CreateRestaurantMenuInput!) {
  createRestaurantMenu(input: $input) {
    restaurantMenu {
      ...RestaurantMenu
    }
  }
}
    ${RestaurantMenuFragmentDoc}`;
export type CreateRestaurantMenuMutationFn = ApolloReactCommon.MutationFunction<CreateRestaurantMenuMutation, CreateRestaurantMenuMutationVariables>;
export type CreateRestaurantMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRestaurantMenuMutation, CreateRestaurantMenuMutationVariables>, 'mutation'>;

    export const CreateRestaurantMenuComponent = (props: CreateRestaurantMenuComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRestaurantMenuMutation, CreateRestaurantMenuMutationVariables> mutation={CreateRestaurantMenuDocument} {...props} />
    );
    

/**
 * __useCreateRestaurantMenuMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantMenuMutation, { data, loading, error }] = useCreateRestaurantMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRestaurantMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRestaurantMenuMutation, CreateRestaurantMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRestaurantMenuMutation, CreateRestaurantMenuMutationVariables>(CreateRestaurantMenuDocument, baseOptions);
      }
export type CreateRestaurantMenuMutationHookResult = ReturnType<typeof useCreateRestaurantMenuMutation>;
export type CreateRestaurantMenuMutationResult = ApolloReactCommon.MutationResult<CreateRestaurantMenuMutation>;
export type CreateRestaurantMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRestaurantMenuMutation, CreateRestaurantMenuMutationVariables>;
export const EditRestaurantMenuDocument = gql`
    mutation EditRestaurantMenu($input: EditRestaurantMenuInput!) {
  editRestaurantMenu(input: $input) {
    restaurantMenu {
      ...RestaurantMenu
    }
  }
}
    ${RestaurantMenuFragmentDoc}`;
export type EditRestaurantMenuMutationFn = ApolloReactCommon.MutationFunction<EditRestaurantMenuMutation, EditRestaurantMenuMutationVariables>;
export type EditRestaurantMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRestaurantMenuMutation, EditRestaurantMenuMutationVariables>, 'mutation'>;

    export const EditRestaurantMenuComponent = (props: EditRestaurantMenuComponentProps) => (
      <ApolloReactComponents.Mutation<EditRestaurantMenuMutation, EditRestaurantMenuMutationVariables> mutation={EditRestaurantMenuDocument} {...props} />
    );
    

/**
 * __useEditRestaurantMenuMutation__
 *
 * To run a mutation, you first call `useEditRestaurantMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRestaurantMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRestaurantMenuMutation, { data, loading, error }] = useEditRestaurantMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRestaurantMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRestaurantMenuMutation, EditRestaurantMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRestaurantMenuMutation, EditRestaurantMenuMutationVariables>(EditRestaurantMenuDocument, baseOptions);
      }
export type EditRestaurantMenuMutationHookResult = ReturnType<typeof useEditRestaurantMenuMutation>;
export type EditRestaurantMenuMutationResult = ApolloReactCommon.MutationResult<EditRestaurantMenuMutation>;
export type EditRestaurantMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRestaurantMenuMutation, EditRestaurantMenuMutationVariables>;
export const StaffDashboardRestaurantTypesDocument = gql`
    query StaffDashboardRestaurantTypes($input: OffsetPaginationInput!, $query: String) {
  restaurantTypeOffsetConnection(pagination: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...RestaurantType
    }
  }
}
    ${RestaurantTypeFragmentDoc}`;
export type StaffDashboardRestaurantTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffDashboardRestaurantTypesQuery, StaffDashboardRestaurantTypesQueryVariables>, 'query'> & ({ variables: StaffDashboardRestaurantTypesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffDashboardRestaurantTypesComponent = (props: StaffDashboardRestaurantTypesComponentProps) => (
      <ApolloReactComponents.Query<StaffDashboardRestaurantTypesQuery, StaffDashboardRestaurantTypesQueryVariables> query={StaffDashboardRestaurantTypesDocument} {...props} />
    );
    

/**
 * __useStaffDashboardRestaurantTypesQuery__
 *
 * To run a query within a React component, call `useStaffDashboardRestaurantTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardRestaurantTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffDashboardRestaurantTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useStaffDashboardRestaurantTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffDashboardRestaurantTypesQuery, StaffDashboardRestaurantTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffDashboardRestaurantTypesQuery, StaffDashboardRestaurantTypesQueryVariables>(StaffDashboardRestaurantTypesDocument, baseOptions);
      }
export function useStaffDashboardRestaurantTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffDashboardRestaurantTypesQuery, StaffDashboardRestaurantTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffDashboardRestaurantTypesQuery, StaffDashboardRestaurantTypesQueryVariables>(StaffDashboardRestaurantTypesDocument, baseOptions);
        }
export type StaffDashboardRestaurantTypesQueryHookResult = ReturnType<typeof useStaffDashboardRestaurantTypesQuery>;
export type StaffDashboardRestaurantTypesLazyQueryHookResult = ReturnType<typeof useStaffDashboardRestaurantTypesLazyQuery>;
export type StaffDashboardRestaurantTypesQueryResult = ApolloReactCommon.QueryResult<StaffDashboardRestaurantTypesQuery, StaffDashboardRestaurantTypesQueryVariables>;
export const CreateRestaurantTypeDocument = gql`
    mutation CreateRestaurantType($input: CreateRestaurantTypeInput!) {
  createRestaurantType(input: $input) {
    restaurantType {
      ...RestaurantType
    }
  }
}
    ${RestaurantTypeFragmentDoc}`;
export type CreateRestaurantTypeMutationFn = ApolloReactCommon.MutationFunction<CreateRestaurantTypeMutation, CreateRestaurantTypeMutationVariables>;
export type CreateRestaurantTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRestaurantTypeMutation, CreateRestaurantTypeMutationVariables>, 'mutation'>;

    export const CreateRestaurantTypeComponent = (props: CreateRestaurantTypeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRestaurantTypeMutation, CreateRestaurantTypeMutationVariables> mutation={CreateRestaurantTypeDocument} {...props} />
    );
    

/**
 * __useCreateRestaurantTypeMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantTypeMutation, { data, loading, error }] = useCreateRestaurantTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRestaurantTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRestaurantTypeMutation, CreateRestaurantTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRestaurantTypeMutation, CreateRestaurantTypeMutationVariables>(CreateRestaurantTypeDocument, baseOptions);
      }
export type CreateRestaurantTypeMutationHookResult = ReturnType<typeof useCreateRestaurantTypeMutation>;
export type CreateRestaurantTypeMutationResult = ApolloReactCommon.MutationResult<CreateRestaurantTypeMutation>;
export type CreateRestaurantTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRestaurantTypeMutation, CreateRestaurantTypeMutationVariables>;
export const EditRestaurantTypeDocument = gql`
    mutation EditRestaurantType($input: EditRestaurantTypeInput!) {
  editRestaurantType(input: $input) {
    restaurantType {
      ...RestaurantType
    }
  }
}
    ${RestaurantTypeFragmentDoc}`;
export type EditRestaurantTypeMutationFn = ApolloReactCommon.MutationFunction<EditRestaurantTypeMutation, EditRestaurantTypeMutationVariables>;
export type EditRestaurantTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRestaurantTypeMutation, EditRestaurantTypeMutationVariables>, 'mutation'>;

    export const EditRestaurantTypeComponent = (props: EditRestaurantTypeComponentProps) => (
      <ApolloReactComponents.Mutation<EditRestaurantTypeMutation, EditRestaurantTypeMutationVariables> mutation={EditRestaurantTypeDocument} {...props} />
    );
    

/**
 * __useEditRestaurantTypeMutation__
 *
 * To run a mutation, you first call `useEditRestaurantTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRestaurantTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRestaurantTypeMutation, { data, loading, error }] = useEditRestaurantTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRestaurantTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRestaurantTypeMutation, EditRestaurantTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRestaurantTypeMutation, EditRestaurantTypeMutationVariables>(EditRestaurantTypeDocument, baseOptions);
      }
export type EditRestaurantTypeMutationHookResult = ReturnType<typeof useEditRestaurantTypeMutation>;
export type EditRestaurantTypeMutationResult = ApolloReactCommon.MutationResult<EditRestaurantTypeMutation>;
export type EditRestaurantTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRestaurantTypeMutation, EditRestaurantTypeMutationVariables>;
export const DeleteRestaurantTypeDocument = gql`
    mutation DeleteRestaurantType($input: DeleteRestaurantTypeInput!) {
  deleteRestaurantType(input: $input) {
    id
  }
}
    `;
export type DeleteRestaurantTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteRestaurantTypeMutation, DeleteRestaurantTypeMutationVariables>;
export type DeleteRestaurantTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRestaurantTypeMutation, DeleteRestaurantTypeMutationVariables>, 'mutation'>;

    export const DeleteRestaurantTypeComponent = (props: DeleteRestaurantTypeComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRestaurantTypeMutation, DeleteRestaurantTypeMutationVariables> mutation={DeleteRestaurantTypeDocument} {...props} />
    );
    

/**
 * __useDeleteRestaurantTypeMutation__
 *
 * To run a mutation, you first call `useDeleteRestaurantTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestaurantTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestaurantTypeMutation, { data, loading, error }] = useDeleteRestaurantTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRestaurantTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRestaurantTypeMutation, DeleteRestaurantTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRestaurantTypeMutation, DeleteRestaurantTypeMutationVariables>(DeleteRestaurantTypeDocument, baseOptions);
      }
export type DeleteRestaurantTypeMutationHookResult = ReturnType<typeof useDeleteRestaurantTypeMutation>;
export type DeleteRestaurantTypeMutationResult = ApolloReactCommon.MutationResult<DeleteRestaurantTypeMutation>;
export type DeleteRestaurantTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRestaurantTypeMutation, DeleteRestaurantTypeMutationVariables>;
export const AllRestaurantsDocument = gql`
    query AllRestaurants($input: OffsetPaginationInput!, $query: String) {
  allRestaurants(input: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...StaffDashboardRestaurantPreview
    }
  }
}
    ${StaffDashboardRestaurantPreviewFragmentDoc}`;
export type AllRestaurantsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllRestaurantsQuery, AllRestaurantsQueryVariables>, 'query'> & ({ variables: AllRestaurantsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AllRestaurantsComponent = (props: AllRestaurantsComponentProps) => (
      <ApolloReactComponents.Query<AllRestaurantsQuery, AllRestaurantsQueryVariables> query={AllRestaurantsDocument} {...props} />
    );
    

/**
 * __useAllRestaurantsQuery__
 *
 * To run a query within a React component, call `useAllRestaurantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRestaurantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRestaurantsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllRestaurantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllRestaurantsQuery, AllRestaurantsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllRestaurantsQuery, AllRestaurantsQueryVariables>(AllRestaurantsDocument, baseOptions);
      }
export function useAllRestaurantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllRestaurantsQuery, AllRestaurantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllRestaurantsQuery, AllRestaurantsQueryVariables>(AllRestaurantsDocument, baseOptions);
        }
export type AllRestaurantsQueryHookResult = ReturnType<typeof useAllRestaurantsQuery>;
export type AllRestaurantsLazyQueryHookResult = ReturnType<typeof useAllRestaurantsLazyQuery>;
export type AllRestaurantsQueryResult = ApolloReactCommon.QueryResult<AllRestaurantsQuery, AllRestaurantsQueryVariables>;
export const RestaurantFullDocument = gql`
    query RestaurantFull($id: ID!) {
  restaurant(id: $id) {
    ...StaffDashboardRestaurantFull
  }
}
    ${StaffDashboardRestaurantFullFragmentDoc}`;
export type RestaurantFullComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantFullQuery, RestaurantFullQueryVariables>, 'query'> & ({ variables: RestaurantFullQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantFullComponent = (props: RestaurantFullComponentProps) => (
      <ApolloReactComponents.Query<RestaurantFullQuery, RestaurantFullQueryVariables> query={RestaurantFullDocument} {...props} />
    );
    

/**
 * __useRestaurantFullQuery__
 *
 * To run a query within a React component, call `useRestaurantFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantFullQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestaurantFullQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantFullQuery, RestaurantFullQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantFullQuery, RestaurantFullQueryVariables>(RestaurantFullDocument, baseOptions);
      }
export function useRestaurantFullLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantFullQuery, RestaurantFullQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantFullQuery, RestaurantFullQueryVariables>(RestaurantFullDocument, baseOptions);
        }
export type RestaurantFullQueryHookResult = ReturnType<typeof useRestaurantFullQuery>;
export type RestaurantFullLazyQueryHookResult = ReturnType<typeof useRestaurantFullLazyQuery>;
export type RestaurantFullQueryResult = ApolloReactCommon.QueryResult<RestaurantFullQuery, RestaurantFullQueryVariables>;
export const EditRestaurantDocument = gql`
    mutation EditRestaurant($input: EditRestaurantInput!) {
  editRestaurant(input: $input) {
    restaurant {
      ...StaffDashboardRestaurantFull
    }
  }
}
    ${StaffDashboardRestaurantFullFragmentDoc}`;
export type EditRestaurantMutationFn = ApolloReactCommon.MutationFunction<EditRestaurantMutation, EditRestaurantMutationVariables>;
export type EditRestaurantComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditRestaurantMutation, EditRestaurantMutationVariables>, 'mutation'>;

    export const EditRestaurantComponent = (props: EditRestaurantComponentProps) => (
      <ApolloReactComponents.Mutation<EditRestaurantMutation, EditRestaurantMutationVariables> mutation={EditRestaurantDocument} {...props} />
    );
    

/**
 * __useEditRestaurantMutation__
 *
 * To run a mutation, you first call `useEditRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRestaurantMutation, { data, loading, error }] = useEditRestaurantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRestaurantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRestaurantMutation, EditRestaurantMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRestaurantMutation, EditRestaurantMutationVariables>(EditRestaurantDocument, baseOptions);
      }
export type EditRestaurantMutationHookResult = ReturnType<typeof useEditRestaurantMutation>;
export type EditRestaurantMutationResult = ApolloReactCommon.MutationResult<EditRestaurantMutation>;
export type EditRestaurantMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRestaurantMutation, EditRestaurantMutationVariables>;
export const CreateRestaurantDocument = gql`
    mutation CreateRestaurant($input: CreateRestaurantInput!) {
  createRestaurant(input: $input) {
    restaurant {
      ...StaffDashboardRestaurantFull
    }
  }
}
    ${StaffDashboardRestaurantFullFragmentDoc}`;
export type CreateRestaurantMutationFn = ApolloReactCommon.MutationFunction<CreateRestaurantMutation, CreateRestaurantMutationVariables>;
export type CreateRestaurantComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRestaurantMutation, CreateRestaurantMutationVariables>, 'mutation'>;

    export const CreateRestaurantComponent = (props: CreateRestaurantComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRestaurantMutation, CreateRestaurantMutationVariables> mutation={CreateRestaurantDocument} {...props} />
    );
    

/**
 * __useCreateRestaurantMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantMutation, { data, loading, error }] = useCreateRestaurantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRestaurantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRestaurantMutation, CreateRestaurantMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRestaurantMutation, CreateRestaurantMutationVariables>(CreateRestaurantDocument, baseOptions);
      }
export type CreateRestaurantMutationHookResult = ReturnType<typeof useCreateRestaurantMutation>;
export type CreateRestaurantMutationResult = ApolloReactCommon.MutationResult<CreateRestaurantMutation>;
export type CreateRestaurantMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRestaurantMutation, CreateRestaurantMutationVariables>;
export const TeamLabelsDocument = gql`
    query TeamLabels($input: OffsetPaginationInput!, $query: String) {
  allTeamLabels(input: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...TeamLabel
    }
  }
}
    ${TeamLabelFragmentDoc}`;
export type TeamLabelsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamLabelsQuery, TeamLabelsQueryVariables>, 'query'> & ({ variables: TeamLabelsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamLabelsComponent = (props: TeamLabelsComponentProps) => (
      <ApolloReactComponents.Query<TeamLabelsQuery, TeamLabelsQueryVariables> query={TeamLabelsDocument} {...props} />
    );
    

/**
 * __useTeamLabelsQuery__
 *
 * To run a query within a React component, call `useTeamLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamLabelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTeamLabelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamLabelsQuery, TeamLabelsQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamLabelsQuery, TeamLabelsQueryVariables>(TeamLabelsDocument, baseOptions);
      }
export function useTeamLabelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamLabelsQuery, TeamLabelsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamLabelsQuery, TeamLabelsQueryVariables>(TeamLabelsDocument, baseOptions);
        }
export type TeamLabelsQueryHookResult = ReturnType<typeof useTeamLabelsQuery>;
export type TeamLabelsLazyQueryHookResult = ReturnType<typeof useTeamLabelsLazyQuery>;
export type TeamLabelsQueryResult = ApolloReactCommon.QueryResult<TeamLabelsQuery, TeamLabelsQueryVariables>;
export const AddTeamLabelDocument = gql`
    mutation AddTeamLabel($input: AddTeamLabelInput!) {
  addTeamLabel(input: $input) {
    teamLabel {
      ...TeamLabel
    }
  }
}
    ${TeamLabelFragmentDoc}`;
export type AddTeamLabelMutationFn = ApolloReactCommon.MutationFunction<AddTeamLabelMutation, AddTeamLabelMutationVariables>;
export type AddTeamLabelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddTeamLabelMutation, AddTeamLabelMutationVariables>, 'mutation'>;

    export const AddTeamLabelComponent = (props: AddTeamLabelComponentProps) => (
      <ApolloReactComponents.Mutation<AddTeamLabelMutation, AddTeamLabelMutationVariables> mutation={AddTeamLabelDocument} {...props} />
    );
    

/**
 * __useAddTeamLabelMutation__
 *
 * To run a mutation, you first call `useAddTeamLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamLabelMutation, { data, loading, error }] = useAddTeamLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTeamLabelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTeamLabelMutation, AddTeamLabelMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTeamLabelMutation, AddTeamLabelMutationVariables>(AddTeamLabelDocument, baseOptions);
      }
export type AddTeamLabelMutationHookResult = ReturnType<typeof useAddTeamLabelMutation>;
export type AddTeamLabelMutationResult = ApolloReactCommon.MutationResult<AddTeamLabelMutation>;
export type AddTeamLabelMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTeamLabelMutation, AddTeamLabelMutationVariables>;
export const EditTeamLabelDocument = gql`
    mutation EditTeamLabel($input: EditTeamLabelInput!) {
  editTeamLabel(input: $input) {
    teamLabel {
      ...TeamLabel
    }
  }
}
    ${TeamLabelFragmentDoc}`;
export type EditTeamLabelMutationFn = ApolloReactCommon.MutationFunction<EditTeamLabelMutation, EditTeamLabelMutationVariables>;
export type EditTeamLabelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditTeamLabelMutation, EditTeamLabelMutationVariables>, 'mutation'>;

    export const EditTeamLabelComponent = (props: EditTeamLabelComponentProps) => (
      <ApolloReactComponents.Mutation<EditTeamLabelMutation, EditTeamLabelMutationVariables> mutation={EditTeamLabelDocument} {...props} />
    );
    

/**
 * __useEditTeamLabelMutation__
 *
 * To run a mutation, you first call `useEditTeamLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamLabelMutation, { data, loading, error }] = useEditTeamLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTeamLabelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTeamLabelMutation, EditTeamLabelMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTeamLabelMutation, EditTeamLabelMutationVariables>(EditTeamLabelDocument, baseOptions);
      }
export type EditTeamLabelMutationHookResult = ReturnType<typeof useEditTeamLabelMutation>;
export type EditTeamLabelMutationResult = ApolloReactCommon.MutationResult<EditTeamLabelMutation>;
export type EditTeamLabelMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTeamLabelMutation, EditTeamLabelMutationVariables>;
export const TerritoryOffsetConnectionDocument = gql`
    query TerritoryOffsetConnection($input: OffsetPaginationInput!, $query: String) {
  territoryOffsetConnection(input: $input, query: $query) {
    edges {
      ...Territory
    }
    pageInfo {
      hasNextPage
      total
      endOffset
    }
  }
}
    ${TerritoryFragmentDoc}`;
export type TerritoryOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TerritoryOffsetConnectionQuery, TerritoryOffsetConnectionQueryVariables>, 'query'> & ({ variables: TerritoryOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TerritoryOffsetConnectionComponent = (props: TerritoryOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<TerritoryOffsetConnectionQuery, TerritoryOffsetConnectionQueryVariables> query={TerritoryOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useTerritoryOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useTerritoryOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerritoryOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerritoryOffsetConnectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTerritoryOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TerritoryOffsetConnectionQuery, TerritoryOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<TerritoryOffsetConnectionQuery, TerritoryOffsetConnectionQueryVariables>(TerritoryOffsetConnectionDocument, baseOptions);
      }
export function useTerritoryOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TerritoryOffsetConnectionQuery, TerritoryOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TerritoryOffsetConnectionQuery, TerritoryOffsetConnectionQueryVariables>(TerritoryOffsetConnectionDocument, baseOptions);
        }
export type TerritoryOffsetConnectionQueryHookResult = ReturnType<typeof useTerritoryOffsetConnectionQuery>;
export type TerritoryOffsetConnectionLazyQueryHookResult = ReturnType<typeof useTerritoryOffsetConnectionLazyQuery>;
export type TerritoryOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<TerritoryOffsetConnectionQuery, TerritoryOffsetConnectionQueryVariables>;
export const CreateTerritoryDocument = gql`
    mutation CreateTerritory($input: CreateTerritoryInput!) {
  createTerritory(input: $input) {
    ...Territory
  }
}
    ${TerritoryFragmentDoc}`;
export type CreateTerritoryMutationFn = ApolloReactCommon.MutationFunction<CreateTerritoryMutation, CreateTerritoryMutationVariables>;
export type CreateTerritoryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTerritoryMutation, CreateTerritoryMutationVariables>, 'mutation'>;

    export const CreateTerritoryComponent = (props: CreateTerritoryComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTerritoryMutation, CreateTerritoryMutationVariables> mutation={CreateTerritoryDocument} {...props} />
    );
    

/**
 * __useCreateTerritoryMutation__
 *
 * To run a mutation, you first call `useCreateTerritoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTerritoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTerritoryMutation, { data, loading, error }] = useCreateTerritoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTerritoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTerritoryMutation, CreateTerritoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTerritoryMutation, CreateTerritoryMutationVariables>(CreateTerritoryDocument, baseOptions);
      }
export type CreateTerritoryMutationHookResult = ReturnType<typeof useCreateTerritoryMutation>;
export type CreateTerritoryMutationResult = ApolloReactCommon.MutationResult<CreateTerritoryMutation>;
export type CreateTerritoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTerritoryMutation, CreateTerritoryMutationVariables>;
export const EditTerritoryDocument = gql`
    mutation EditTerritory($input: EditTerritoryInput!) {
  editTerritory(input: $input) {
    ...Territory
  }
}
    ${TerritoryFragmentDoc}`;
export type EditTerritoryMutationFn = ApolloReactCommon.MutationFunction<EditTerritoryMutation, EditTerritoryMutationVariables>;
export type EditTerritoryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditTerritoryMutation, EditTerritoryMutationVariables>, 'mutation'>;

    export const EditTerritoryComponent = (props: EditTerritoryComponentProps) => (
      <ApolloReactComponents.Mutation<EditTerritoryMutation, EditTerritoryMutationVariables> mutation={EditTerritoryDocument} {...props} />
    );
    

/**
 * __useEditTerritoryMutation__
 *
 * To run a mutation, you first call `useEditTerritoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTerritoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTerritoryMutation, { data, loading, error }] = useEditTerritoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTerritoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTerritoryMutation, EditTerritoryMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTerritoryMutation, EditTerritoryMutationVariables>(EditTerritoryDocument, baseOptions);
      }
export type EditTerritoryMutationHookResult = ReturnType<typeof useEditTerritoryMutation>;
export type EditTerritoryMutationResult = ApolloReactCommon.MutationResult<EditTerritoryMutation>;
export type EditTerritoryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTerritoryMutation, EditTerritoryMutationVariables>;
export const OrgsForTerritoryDocument = gql`
    query OrgsForTerritory {
  orgs {
    ...OrgForTerritory
  }
}
    ${OrgForTerritoryFragmentDoc}`;
export type OrgsForTerritoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgsForTerritoryQuery, OrgsForTerritoryQueryVariables>, 'query'>;

    export const OrgsForTerritoryComponent = (props: OrgsForTerritoryComponentProps) => (
      <ApolloReactComponents.Query<OrgsForTerritoryQuery, OrgsForTerritoryQueryVariables> query={OrgsForTerritoryDocument} {...props} />
    );
    

/**
 * __useOrgsForTerritoryQuery__
 *
 * To run a query within a React component, call `useOrgsForTerritoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgsForTerritoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgsForTerritoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgsForTerritoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgsForTerritoryQuery, OrgsForTerritoryQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgsForTerritoryQuery, OrgsForTerritoryQueryVariables>(OrgsForTerritoryDocument, baseOptions);
      }
export function useOrgsForTerritoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgsForTerritoryQuery, OrgsForTerritoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgsForTerritoryQuery, OrgsForTerritoryQueryVariables>(OrgsForTerritoryDocument, baseOptions);
        }
export type OrgsForTerritoryQueryHookResult = ReturnType<typeof useOrgsForTerritoryQuery>;
export type OrgsForTerritoryLazyQueryHookResult = ReturnType<typeof useOrgsForTerritoryLazyQuery>;
export type OrgsForTerritoryQueryResult = ApolloReactCommon.QueryResult<OrgsForTerritoryQuery, OrgsForTerritoryQueryVariables>;
export const StaffDashboardUsersDocument = gql`
    query StaffDashboardUsers($input: OffsetPaginationInput!, $query: String) {
  staffUserSearchOffsetConnection(pagination: $input, searchParams: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...StaffDashboardUserPreview
    }
  }
}
    ${StaffDashboardUserPreviewFragmentDoc}`;
export type StaffDashboardUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffDashboardUsersQuery, StaffDashboardUsersQueryVariables>, 'query'> & ({ variables: StaffDashboardUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffDashboardUsersComponent = (props: StaffDashboardUsersComponentProps) => (
      <ApolloReactComponents.Query<StaffDashboardUsersQuery, StaffDashboardUsersQueryVariables> query={StaffDashboardUsersDocument} {...props} />
    );
    

/**
 * __useStaffDashboardUsersQuery__
 *
 * To run a query within a React component, call `useStaffDashboardUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffDashboardUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffDashboardUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useStaffDashboardUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffDashboardUsersQuery, StaffDashboardUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffDashboardUsersQuery, StaffDashboardUsersQueryVariables>(StaffDashboardUsersDocument, baseOptions);
      }
export function useStaffDashboardUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffDashboardUsersQuery, StaffDashboardUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffDashboardUsersQuery, StaffDashboardUsersQueryVariables>(StaffDashboardUsersDocument, baseOptions);
        }
export type StaffDashboardUsersQueryHookResult = ReturnType<typeof useStaffDashboardUsersQuery>;
export type StaffDashboardUsersLazyQueryHookResult = ReturnType<typeof useStaffDashboardUsersLazyQuery>;
export type StaffDashboardUsersQueryResult = ApolloReactCommon.QueryResult<StaffDashboardUsersQuery, StaffDashboardUsersQueryVariables>;
export const OrgDocument = gql`
    query Org($id: ID!) {
  org(id: $id) {
    ...StaffDashboardOrg
  }
}
    ${StaffDashboardOrgFragmentDoc}`;
export type OrgComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgQuery, OrgQueryVariables>, 'query'> & ({ variables: OrgQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgComponent = (props: OrgComponentProps) => (
      <ApolloReactComponents.Query<OrgQuery, OrgQueryVariables> query={OrgDocument} {...props} />
    );
    

/**
 * __useOrgQuery__
 *
 * To run a query within a React component, call `useOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgQuery, OrgQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgQuery, OrgQueryVariables>(OrgDocument, baseOptions);
      }
export function useOrgLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgQuery, OrgQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgQuery, OrgQueryVariables>(OrgDocument, baseOptions);
        }
export type OrgQueryHookResult = ReturnType<typeof useOrgQuery>;
export type OrgLazyQueryHookResult = ReturnType<typeof useOrgLazyQuery>;
export type OrgQueryResult = ApolloReactCommon.QueryResult<OrgQuery, OrgQueryVariables>;
export const ProfileUsersDocument = gql`
    query ProfileUsers($ids: [ID!]!) {
  profileUsers(ids: $ids) {
    id
    email
    firstName
    lastName
    orgInfo {
      org {
        name
      }
    }
  }
}
    `;
export type ProfileUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfileUsersQuery, ProfileUsersQueryVariables>, 'query'> & ({ variables: ProfileUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProfileUsersComponent = (props: ProfileUsersComponentProps) => (
      <ApolloReactComponents.Query<ProfileUsersQuery, ProfileUsersQueryVariables> query={ProfileUsersDocument} {...props} />
    );
    

/**
 * __useProfileUsersQuery__
 *
 * To run a query within a React component, call `useProfileUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUsersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProfileUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileUsersQuery, ProfileUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfileUsersQuery, ProfileUsersQueryVariables>(ProfileUsersDocument, baseOptions);
      }
export function useProfileUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileUsersQuery, ProfileUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfileUsersQuery, ProfileUsersQueryVariables>(ProfileUsersDocument, baseOptions);
        }
export type ProfileUsersQueryHookResult = ReturnType<typeof useProfileUsersQuery>;
export type ProfileUsersLazyQueryHookResult = ReturnType<typeof useProfileUsersLazyQuery>;
export type ProfileUsersQueryResult = ApolloReactCommon.QueryResult<ProfileUsersQuery, ProfileUsersQueryVariables>;
export const EditOrgDocument = gql`
    mutation EditOrg($input: EditOrgInput!) {
  editOrg(input: $input) {
    org {
      ...StaffDashboardOrg
    }
  }
}
    ${StaffDashboardOrgFragmentDoc}`;
export type EditOrgMutationFn = ApolloReactCommon.MutationFunction<EditOrgMutation, EditOrgMutationVariables>;
export type EditOrgComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditOrgMutation, EditOrgMutationVariables>, 'mutation'>;

    export const EditOrgComponent = (props: EditOrgComponentProps) => (
      <ApolloReactComponents.Mutation<EditOrgMutation, EditOrgMutationVariables> mutation={EditOrgDocument} {...props} />
    );
    

/**
 * __useEditOrgMutation__
 *
 * To run a mutation, you first call `useEditOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgMutation, { data, loading, error }] = useEditOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrgMutation, EditOrgMutationVariables>) {
        return ApolloReactHooks.useMutation<EditOrgMutation, EditOrgMutationVariables>(EditOrgDocument, baseOptions);
      }
export type EditOrgMutationHookResult = ReturnType<typeof useEditOrgMutation>;
export type EditOrgMutationResult = ApolloReactCommon.MutationResult<EditOrgMutation>;
export type EditOrgMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrgMutation, EditOrgMutationVariables>;
export const CreateOrgDocument = gql`
    mutation CreateOrg($input: CreateOrgInput!) {
  createOrg(input: $input) {
    org {
      ...StaffDashboardOrg
    }
  }
}
    ${StaffDashboardOrgFragmentDoc}`;
export type CreateOrgMutationFn = ApolloReactCommon.MutationFunction<CreateOrgMutation, CreateOrgMutationVariables>;
export type CreateOrgComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrgMutation, CreateOrgMutationVariables>, 'mutation'>;

    export const CreateOrgComponent = (props: CreateOrgComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrgMutation, CreateOrgMutationVariables> mutation={CreateOrgDocument} {...props} />
    );
    

/**
 * __useCreateOrgMutation__
 *
 * To run a mutation, you first call `useCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMutation, { data, loading, error }] = useCreateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgMutation, CreateOrgMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgMutation, CreateOrgMutationVariables>(CreateOrgDocument, baseOptions);
      }
export type CreateOrgMutationHookResult = ReturnType<typeof useCreateOrgMutation>;
export type CreateOrgMutationResult = ApolloReactCommon.MutationResult<CreateOrgMutation>;
export type CreateOrgMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgMutation, CreateOrgMutationVariables>;
export const EditOrgFeatureFlagsDocument = gql`
    mutation EditOrgFeatureFlags($input: EditOrgFeatureFlagsInput!) {
  editOrgFeatureFlags(input: $input) {
    featureFlags {
      ...FeatureFlags
    }
  }
}
    ${FeatureFlagsFragmentDoc}`;
export type EditOrgFeatureFlagsMutationFn = ApolloReactCommon.MutationFunction<EditOrgFeatureFlagsMutation, EditOrgFeatureFlagsMutationVariables>;
export type EditOrgFeatureFlagsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditOrgFeatureFlagsMutation, EditOrgFeatureFlagsMutationVariables>, 'mutation'>;

    export const EditOrgFeatureFlagsComponent = (props: EditOrgFeatureFlagsComponentProps) => (
      <ApolloReactComponents.Mutation<EditOrgFeatureFlagsMutation, EditOrgFeatureFlagsMutationVariables> mutation={EditOrgFeatureFlagsDocument} {...props} />
    );
    

/**
 * __useEditOrgFeatureFlagsMutation__
 *
 * To run a mutation, you first call `useEditOrgFeatureFlagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgFeatureFlagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgFeatureFlagsMutation, { data, loading, error }] = useEditOrgFeatureFlagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgFeatureFlagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrgFeatureFlagsMutation, EditOrgFeatureFlagsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditOrgFeatureFlagsMutation, EditOrgFeatureFlagsMutationVariables>(EditOrgFeatureFlagsDocument, baseOptions);
      }
export type EditOrgFeatureFlagsMutationHookResult = ReturnType<typeof useEditOrgFeatureFlagsMutation>;
export type EditOrgFeatureFlagsMutationResult = ApolloReactCommon.MutationResult<EditOrgFeatureFlagsMutation>;
export type EditOrgFeatureFlagsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrgFeatureFlagsMutation, EditOrgFeatureFlagsMutationVariables>;
export const AddPositionDocument = gql`
    mutation AddPosition($input: AddPositionInput!) {
  addPosition(input: $input) {
    position {
      ...Position
    }
  }
}
    ${PositionFragmentDoc}`;
export type AddPositionMutationFn = ApolloReactCommon.MutationFunction<AddPositionMutation, AddPositionMutationVariables>;
export type AddPositionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddPositionMutation, AddPositionMutationVariables>, 'mutation'>;

    export const AddPositionComponent = (props: AddPositionComponentProps) => (
      <ApolloReactComponents.Mutation<AddPositionMutation, AddPositionMutationVariables> mutation={AddPositionDocument} {...props} />
    );
    

/**
 * __useAddPositionMutation__
 *
 * To run a mutation, you first call `useAddPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPositionMutation, { data, loading, error }] = useAddPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPositionMutation, AddPositionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPositionMutation, AddPositionMutationVariables>(AddPositionDocument, baseOptions);
      }
export type AddPositionMutationHookResult = ReturnType<typeof useAddPositionMutation>;
export type AddPositionMutationResult = ApolloReactCommon.MutationResult<AddPositionMutation>;
export type AddPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPositionMutation, AddPositionMutationVariables>;
export const AddSportDocument = gql`
    mutation AddSport($input: AddSportInput!) {
  addSport(input: $input) {
    sport {
      ...Sport
    }
  }
}
    ${SportFragmentDoc}`;
export type AddSportMutationFn = ApolloReactCommon.MutationFunction<AddSportMutation, AddSportMutationVariables>;
export type AddSportComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddSportMutation, AddSportMutationVariables>, 'mutation'>;

    export const AddSportComponent = (props: AddSportComponentProps) => (
      <ApolloReactComponents.Mutation<AddSportMutation, AddSportMutationVariables> mutation={AddSportDocument} {...props} />
    );
    

/**
 * __useAddSportMutation__
 *
 * To run a mutation, you first call `useAddSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSportMutation, { data, loading, error }] = useAddSportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSportMutation, AddSportMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSportMutation, AddSportMutationVariables>(AddSportDocument, baseOptions);
      }
export type AddSportMutationHookResult = ReturnType<typeof useAddSportMutation>;
export type AddSportMutationResult = ApolloReactCommon.MutationResult<AddSportMutation>;
export type AddSportMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSportMutation, AddSportMutationVariables>;
export const EditUserTeamworksIdToOverwriteDocument = gql`
    mutation EditUserTeamworksIdToOverwrite($input: EditUserTeamworksIdToOverwriteInput!) {
  editUserTeamworksIdToOverwrite(input: $input) {
    userIds
  }
}
    `;
export type EditUserTeamworksIdToOverwriteMutationFn = ApolloReactCommon.MutationFunction<EditUserTeamworksIdToOverwriteMutation, EditUserTeamworksIdToOverwriteMutationVariables>;
export type EditUserTeamworksIdToOverwriteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditUserTeamworksIdToOverwriteMutation, EditUserTeamworksIdToOverwriteMutationVariables>, 'mutation'>;

    export const EditUserTeamworksIdToOverwriteComponent = (props: EditUserTeamworksIdToOverwriteComponentProps) => (
      <ApolloReactComponents.Mutation<EditUserTeamworksIdToOverwriteMutation, EditUserTeamworksIdToOverwriteMutationVariables> mutation={EditUserTeamworksIdToOverwriteDocument} {...props} />
    );
    

/**
 * __useEditUserTeamworksIdToOverwriteMutation__
 *
 * To run a mutation, you first call `useEditUserTeamworksIdToOverwriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserTeamworksIdToOverwriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserTeamworksIdToOverwriteMutation, { data, loading, error }] = useEditUserTeamworksIdToOverwriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserTeamworksIdToOverwriteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserTeamworksIdToOverwriteMutation, EditUserTeamworksIdToOverwriteMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserTeamworksIdToOverwriteMutation, EditUserTeamworksIdToOverwriteMutationVariables>(EditUserTeamworksIdToOverwriteDocument, baseOptions);
      }
export type EditUserTeamworksIdToOverwriteMutationHookResult = ReturnType<typeof useEditUserTeamworksIdToOverwriteMutation>;
export type EditUserTeamworksIdToOverwriteMutationResult = ApolloReactCommon.MutationResult<EditUserTeamworksIdToOverwriteMutation>;
export type EditUserTeamworksIdToOverwriteMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserTeamworksIdToOverwriteMutation, EditUserTeamworksIdToOverwriteMutationVariables>;
export const TagTableDocument = gql`
    query TagTable($input: OffsetPaginationInput!, $query: String) {
  namedTagOffsetConnection(input: $input, query: $query) {
    edges {
      ...NamedTagPreview
    }
    pageInfo {
      endOffset
      hasNextPage
      total
    }
  }
}
    ${NamedTagPreviewFragmentDoc}`;
export type TagTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TagTableQuery, TagTableQueryVariables>, 'query'> & ({ variables: TagTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TagTableComponent = (props: TagTableComponentProps) => (
      <ApolloReactComponents.Query<TagTableQuery, TagTableQueryVariables> query={TagTableDocument} {...props} />
    );
    

/**
 * __useTagTableQuery__
 *
 * To run a query within a React component, call `useTagTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTagTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagTableQuery, TagTableQueryVariables>) {
        return ApolloReactHooks.useQuery<TagTableQuery, TagTableQueryVariables>(TagTableDocument, baseOptions);
      }
export function useTagTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagTableQuery, TagTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagTableQuery, TagTableQueryVariables>(TagTableDocument, baseOptions);
        }
export type TagTableQueryHookResult = ReturnType<typeof useTagTableQuery>;
export type TagTableLazyQueryHookResult = ReturnType<typeof useTagTableLazyQuery>;
export type TagTableQueryResult = ApolloReactCommon.QueryResult<TagTableQuery, TagTableQueryVariables>;
export const NamedTagForEditDocument = gql`
    query NamedTagForEdit($id: ID!) {
  namedTag(id: $id) {
    ...NamedTagForEdit
  }
}
    ${NamedTagForEditFragmentDoc}`;
export type NamedTagForEditComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NamedTagForEditQuery, NamedTagForEditQueryVariables>, 'query'> & ({ variables: NamedTagForEditQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NamedTagForEditComponent = (props: NamedTagForEditComponentProps) => (
      <ApolloReactComponents.Query<NamedTagForEditQuery, NamedTagForEditQueryVariables> query={NamedTagForEditDocument} {...props} />
    );
    

/**
 * __useNamedTagForEditQuery__
 *
 * To run a query within a React component, call `useNamedTagForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useNamedTagForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNamedTagForEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNamedTagForEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NamedTagForEditQuery, NamedTagForEditQueryVariables>) {
        return ApolloReactHooks.useQuery<NamedTagForEditQuery, NamedTagForEditQueryVariables>(NamedTagForEditDocument, baseOptions);
      }
export function useNamedTagForEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NamedTagForEditQuery, NamedTagForEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NamedTagForEditQuery, NamedTagForEditQueryVariables>(NamedTagForEditDocument, baseOptions);
        }
export type NamedTagForEditQueryHookResult = ReturnType<typeof useNamedTagForEditQuery>;
export type NamedTagForEditLazyQueryHookResult = ReturnType<typeof useNamedTagForEditLazyQuery>;
export type NamedTagForEditQueryResult = ApolloReactCommon.QueryResult<NamedTagForEditQuery, NamedTagForEditQueryVariables>;
export const PositionBySportCursorConnectionDocument = gql`
    query PositionBySportCursorConnection($pagination: CursorPaginationInput!, $query: String) {
  positionBySportCursorConnection(input: $pagination, query: $query) {
    edges {
      ...PositionWitSport
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${PositionWitSportFragmentDoc}`;
export type PositionBySportCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PositionBySportCursorConnectionQuery, PositionBySportCursorConnectionQueryVariables>, 'query'> & ({ variables: PositionBySportCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PositionBySportCursorConnectionComponent = (props: PositionBySportCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<PositionBySportCursorConnectionQuery, PositionBySportCursorConnectionQueryVariables> query={PositionBySportCursorConnectionDocument} {...props} />
    );
    

/**
 * __usePositionBySportCursorConnectionQuery__
 *
 * To run a query within a React component, call `usePositionBySportCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionBySportCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionBySportCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePositionBySportCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PositionBySportCursorConnectionQuery, PositionBySportCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<PositionBySportCursorConnectionQuery, PositionBySportCursorConnectionQueryVariables>(PositionBySportCursorConnectionDocument, baseOptions);
      }
export function usePositionBySportCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PositionBySportCursorConnectionQuery, PositionBySportCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PositionBySportCursorConnectionQuery, PositionBySportCursorConnectionQueryVariables>(PositionBySportCursorConnectionDocument, baseOptions);
        }
export type PositionBySportCursorConnectionQueryHookResult = ReturnType<typeof usePositionBySportCursorConnectionQuery>;
export type PositionBySportCursorConnectionLazyQueryHookResult = ReturnType<typeof usePositionBySportCursorConnectionLazyQuery>;
export type PositionBySportCursorConnectionQueryResult = ApolloReactCommon.QueryResult<PositionBySportCursorConnectionQuery, PositionBySportCursorConnectionQueryVariables>;
export const TeamCursorConnectionDocument = gql`
    query TeamCursorConnection($pagination: CursorPaginationInput!, $query: String) {
  teamCursorConnection(input: $pagination, query: $query) {
    edges {
      ...TeamPreview
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${TeamPreviewFragmentDoc}`;
export type TeamCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamCursorConnectionQuery, TeamCursorConnectionQueryVariables>, 'query'> & ({ variables: TeamCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamCursorConnectionComponent = (props: TeamCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<TeamCursorConnectionQuery, TeamCursorConnectionQueryVariables> query={TeamCursorConnectionDocument} {...props} />
    );
    

/**
 * __useTeamCursorConnectionQuery__
 *
 * To run a query within a React component, call `useTeamCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTeamCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamCursorConnectionQuery, TeamCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamCursorConnectionQuery, TeamCursorConnectionQueryVariables>(TeamCursorConnectionDocument, baseOptions);
      }
export function useTeamCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamCursorConnectionQuery, TeamCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamCursorConnectionQuery, TeamCursorConnectionQueryVariables>(TeamCursorConnectionDocument, baseOptions);
        }
export type TeamCursorConnectionQueryHookResult = ReturnType<typeof useTeamCursorConnectionQuery>;
export type TeamCursorConnectionLazyQueryHookResult = ReturnType<typeof useTeamCursorConnectionLazyQuery>;
export type TeamCursorConnectionQueryResult = ApolloReactCommon.QueryResult<TeamCursorConnectionQuery, TeamCursorConnectionQueryVariables>;
export const NamedTagCursorConnectionDocument = gql`
    query NamedTagCursorConnection($pagination: CursorPaginationInput!, $query: String) {
  namedTagCursorConnection(input: $pagination, query: $query) {
    edges {
      ...NamedTagForSelection
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${NamedTagForSelectionFragmentDoc}`;
export type NamedTagCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NamedTagCursorConnectionQuery, NamedTagCursorConnectionQueryVariables>, 'query'> & ({ variables: NamedTagCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NamedTagCursorConnectionComponent = (props: NamedTagCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<NamedTagCursorConnectionQuery, NamedTagCursorConnectionQueryVariables> query={NamedTagCursorConnectionDocument} {...props} />
    );
    

/**
 * __useNamedTagCursorConnectionQuery__
 *
 * To run a query within a React component, call `useNamedTagCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNamedTagCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNamedTagCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useNamedTagCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NamedTagCursorConnectionQuery, NamedTagCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<NamedTagCursorConnectionQuery, NamedTagCursorConnectionQueryVariables>(NamedTagCursorConnectionDocument, baseOptions);
      }
export function useNamedTagCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NamedTagCursorConnectionQuery, NamedTagCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NamedTagCursorConnectionQuery, NamedTagCursorConnectionQueryVariables>(NamedTagCursorConnectionDocument, baseOptions);
        }
export type NamedTagCursorConnectionQueryHookResult = ReturnType<typeof useNamedTagCursorConnectionQuery>;
export type NamedTagCursorConnectionLazyQueryHookResult = ReturnType<typeof useNamedTagCursorConnectionLazyQuery>;
export type NamedTagCursorConnectionQueryResult = ApolloReactCommon.QueryResult<NamedTagCursorConnectionQuery, NamedTagCursorConnectionQueryVariables>;
export const AthletesForCustomTagCreateDocument = gql`
    query AthletesForCustomTagCreate($pagination: CursorPaginationInput!, $query: String, $tagIds: [ID!]!, $teamIds: [ID!]!) {
  athletesForCustomTagCreationCursorConnection(
    input: $pagination
    query: $query
    tagIds: $tagIds
    teamIds: $teamIds
  ) {
    edges {
      ...AthleteForCustomTag
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${AthleteForCustomTagFragmentDoc}`;
export type AthletesForCustomTagCreateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesForCustomTagCreateQuery, AthletesForCustomTagCreateQueryVariables>, 'query'> & ({ variables: AthletesForCustomTagCreateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthletesForCustomTagCreateComponent = (props: AthletesForCustomTagCreateComponentProps) => (
      <ApolloReactComponents.Query<AthletesForCustomTagCreateQuery, AthletesForCustomTagCreateQueryVariables> query={AthletesForCustomTagCreateDocument} {...props} />
    );
    

/**
 * __useAthletesForCustomTagCreateQuery__
 *
 * To run a query within a React component, call `useAthletesForCustomTagCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesForCustomTagCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesForCustomTagCreateQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *      tagIds: // value for 'tagIds'
 *      teamIds: // value for 'teamIds'
 *   },
 * });
 */
export function useAthletesForCustomTagCreateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesForCustomTagCreateQuery, AthletesForCustomTagCreateQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesForCustomTagCreateQuery, AthletesForCustomTagCreateQueryVariables>(AthletesForCustomTagCreateDocument, baseOptions);
      }
export function useAthletesForCustomTagCreateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesForCustomTagCreateQuery, AthletesForCustomTagCreateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesForCustomTagCreateQuery, AthletesForCustomTagCreateQueryVariables>(AthletesForCustomTagCreateDocument, baseOptions);
        }
export type AthletesForCustomTagCreateQueryHookResult = ReturnType<typeof useAthletesForCustomTagCreateQuery>;
export type AthletesForCustomTagCreateLazyQueryHookResult = ReturnType<typeof useAthletesForCustomTagCreateLazyQuery>;
export type AthletesForCustomTagCreateQueryResult = ApolloReactCommon.QueryResult<AthletesForCustomTagCreateQuery, AthletesForCustomTagCreateQueryVariables>;
export const SelectedAthletesForCustomTagCreateDocument = gql`
    query SelectedAthletesForCustomTagCreate($query: String, $tagIds: [ID!]!, $teamIds: [ID!]!) {
  athletesForCustomTagCreation(query: $query, tagIds: $tagIds, teamIds: $teamIds) {
    ...AthleteForCustomTag
  }
}
    ${AthleteForCustomTagFragmentDoc}`;
export type SelectedAthletesForCustomTagCreateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SelectedAthletesForCustomTagCreateQuery, SelectedAthletesForCustomTagCreateQueryVariables>, 'query'> & ({ variables: SelectedAthletesForCustomTagCreateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SelectedAthletesForCustomTagCreateComponent = (props: SelectedAthletesForCustomTagCreateComponentProps) => (
      <ApolloReactComponents.Query<SelectedAthletesForCustomTagCreateQuery, SelectedAthletesForCustomTagCreateQueryVariables> query={SelectedAthletesForCustomTagCreateDocument} {...props} />
    );
    

/**
 * __useSelectedAthletesForCustomTagCreateQuery__
 *
 * To run a query within a React component, call `useSelectedAthletesForCustomTagCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedAthletesForCustomTagCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedAthletesForCustomTagCreateQuery({
 *   variables: {
 *      query: // value for 'query'
 *      tagIds: // value for 'tagIds'
 *      teamIds: // value for 'teamIds'
 *   },
 * });
 */
export function useSelectedAthletesForCustomTagCreateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectedAthletesForCustomTagCreateQuery, SelectedAthletesForCustomTagCreateQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectedAthletesForCustomTagCreateQuery, SelectedAthletesForCustomTagCreateQueryVariables>(SelectedAthletesForCustomTagCreateDocument, baseOptions);
      }
export function useSelectedAthletesForCustomTagCreateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectedAthletesForCustomTagCreateQuery, SelectedAthletesForCustomTagCreateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectedAthletesForCustomTagCreateQuery, SelectedAthletesForCustomTagCreateQueryVariables>(SelectedAthletesForCustomTagCreateDocument, baseOptions);
        }
export type SelectedAthletesForCustomTagCreateQueryHookResult = ReturnType<typeof useSelectedAthletesForCustomTagCreateQuery>;
export type SelectedAthletesForCustomTagCreateLazyQueryHookResult = ReturnType<typeof useSelectedAthletesForCustomTagCreateLazyQuery>;
export type SelectedAthletesForCustomTagCreateQueryResult = ApolloReactCommon.QueryResult<SelectedAthletesForCustomTagCreateQuery, SelectedAthletesForCustomTagCreateQueryVariables>;
export const AthleteSearchCursorConnectionDocument = gql`
    query AthleteSearchCursorConnection($pagination: CursorPaginationInput!, $query: String) {
  athleteSearchCursorConnection(input: $pagination, query: $query) {
    edges {
      ...AthleteForCustomTag
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${AthleteForCustomTagFragmentDoc}`;
export type AthleteSearchCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteSearchCursorConnectionQuery, AthleteSearchCursorConnectionQueryVariables>, 'query'> & ({ variables: AthleteSearchCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteSearchCursorConnectionComponent = (props: AthleteSearchCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<AthleteSearchCursorConnectionQuery, AthleteSearchCursorConnectionQueryVariables> query={AthleteSearchCursorConnectionDocument} {...props} />
    );
    

/**
 * __useAthleteSearchCursorConnectionQuery__
 *
 * To run a query within a React component, call `useAthleteSearchCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteSearchCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteSearchCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAthleteSearchCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteSearchCursorConnectionQuery, AthleteSearchCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteSearchCursorConnectionQuery, AthleteSearchCursorConnectionQueryVariables>(AthleteSearchCursorConnectionDocument, baseOptions);
      }
export function useAthleteSearchCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteSearchCursorConnectionQuery, AthleteSearchCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteSearchCursorConnectionQuery, AthleteSearchCursorConnectionQueryVariables>(AthleteSearchCursorConnectionDocument, baseOptions);
        }
export type AthleteSearchCursorConnectionQueryHookResult = ReturnType<typeof useAthleteSearchCursorConnectionQuery>;
export type AthleteSearchCursorConnectionLazyQueryHookResult = ReturnType<typeof useAthleteSearchCursorConnectionLazyQuery>;
export type AthleteSearchCursorConnectionQueryResult = ApolloReactCommon.QueryResult<AthleteSearchCursorConnectionQuery, AthleteSearchCursorConnectionQueryVariables>;
export const AthletesForPendingAdvancedSelectionDocument = gql`
    query AthletesForPendingAdvancedSelection($input: AdvancedSelectionInput!) {
  athletesForPendingAdvancedSelection(input: $input) {
    ...AthleteForCustomTag
  }
}
    ${AthleteForCustomTagFragmentDoc}`;
export type AthletesForPendingAdvancedSelectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesForPendingAdvancedSelectionQuery, AthletesForPendingAdvancedSelectionQueryVariables>, 'query'> & ({ variables: AthletesForPendingAdvancedSelectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthletesForPendingAdvancedSelectionComponent = (props: AthletesForPendingAdvancedSelectionComponentProps) => (
      <ApolloReactComponents.Query<AthletesForPendingAdvancedSelectionQuery, AthletesForPendingAdvancedSelectionQueryVariables> query={AthletesForPendingAdvancedSelectionDocument} {...props} />
    );
    

/**
 * __useAthletesForPendingAdvancedSelectionQuery__
 *
 * To run a query within a React component, call `useAthletesForPendingAdvancedSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesForPendingAdvancedSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesForPendingAdvancedSelectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAthletesForPendingAdvancedSelectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesForPendingAdvancedSelectionQuery, AthletesForPendingAdvancedSelectionQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesForPendingAdvancedSelectionQuery, AthletesForPendingAdvancedSelectionQueryVariables>(AthletesForPendingAdvancedSelectionDocument, baseOptions);
      }
export function useAthletesForPendingAdvancedSelectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesForPendingAdvancedSelectionQuery, AthletesForPendingAdvancedSelectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesForPendingAdvancedSelectionQuery, AthletesForPendingAdvancedSelectionQueryVariables>(AthletesForPendingAdvancedSelectionDocument, baseOptions);
        }
export type AthletesForPendingAdvancedSelectionQueryHookResult = ReturnType<typeof useAthletesForPendingAdvancedSelectionQuery>;
export type AthletesForPendingAdvancedSelectionLazyQueryHookResult = ReturnType<typeof useAthletesForPendingAdvancedSelectionLazyQuery>;
export type AthletesForPendingAdvancedSelectionQueryResult = ApolloReactCommon.QueryResult<AthletesForPendingAdvancedSelectionQuery, AthletesForPendingAdvancedSelectionQueryVariables>;
export const IsTagNameUniqueDocument = gql`
    query IsTagNameUnique($name: String!) {
  isTagNameUnique(name: $name) {
    isUnique
  }
}
    `;
export type IsTagNameUniqueComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsTagNameUniqueQuery, IsTagNameUniqueQueryVariables>, 'query'> & ({ variables: IsTagNameUniqueQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const IsTagNameUniqueComponent = (props: IsTagNameUniqueComponentProps) => (
      <ApolloReactComponents.Query<IsTagNameUniqueQuery, IsTagNameUniqueQueryVariables> query={IsTagNameUniqueDocument} {...props} />
    );
    

/**
 * __useIsTagNameUniqueQuery__
 *
 * To run a query within a React component, call `useIsTagNameUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTagNameUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTagNameUniqueQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useIsTagNameUniqueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsTagNameUniqueQuery, IsTagNameUniqueQueryVariables>) {
        return ApolloReactHooks.useQuery<IsTagNameUniqueQuery, IsTagNameUniqueQueryVariables>(IsTagNameUniqueDocument, baseOptions);
      }
export function useIsTagNameUniqueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsTagNameUniqueQuery, IsTagNameUniqueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsTagNameUniqueQuery, IsTagNameUniqueQueryVariables>(IsTagNameUniqueDocument, baseOptions);
        }
export type IsTagNameUniqueQueryHookResult = ReturnType<typeof useIsTagNameUniqueQuery>;
export type IsTagNameUniqueLazyQueryHookResult = ReturnType<typeof useIsTagNameUniqueLazyQuery>;
export type IsTagNameUniqueQueryResult = ApolloReactCommon.QueryResult<IsTagNameUniqueQuery, IsTagNameUniqueQueryVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: ID!) {
  deleteTag(id: $id) {
    tagId
  }
}
    `;
export type DeleteTagMutationFn = ApolloReactCommon.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;
export type DeleteTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTagMutation, DeleteTagMutationVariables>, 'mutation'>;

    export const DeleteTagComponent = (props: DeleteTagComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTagMutation, DeleteTagMutationVariables> mutation={DeleteTagDocument} {...props} />
    );
    

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, baseOptions);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = ApolloReactCommon.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const CreateCustomTagDocument = gql`
    mutation CreateCustomTag($input: CreateCustomTagInput!) {
  createCustomTag(input: $input) {
    ...NamedTagForEdit
  }
}
    ${NamedTagForEditFragmentDoc}`;
export type CreateCustomTagMutationFn = ApolloReactCommon.MutationFunction<CreateCustomTagMutation, CreateCustomTagMutationVariables>;
export type CreateCustomTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomTagMutation, CreateCustomTagMutationVariables>, 'mutation'>;

    export const CreateCustomTagComponent = (props: CreateCustomTagComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomTagMutation, CreateCustomTagMutationVariables> mutation={CreateCustomTagDocument} {...props} />
    );
    

/**
 * __useCreateCustomTagMutation__
 *
 * To run a mutation, you first call `useCreateCustomTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomTagMutation, { data, loading, error }] = useCreateCustomTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomTagMutation, CreateCustomTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomTagMutation, CreateCustomTagMutationVariables>(CreateCustomTagDocument, baseOptions);
      }
export type CreateCustomTagMutationHookResult = ReturnType<typeof useCreateCustomTagMutation>;
export type CreateCustomTagMutationResult = ApolloReactCommon.MutationResult<CreateCustomTagMutation>;
export type CreateCustomTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomTagMutation, CreateCustomTagMutationVariables>;
export const EditCustomTagDocument = gql`
    mutation EditCustomTag($input: EditCustomTagInput!) {
  editCustomTag(input: $input) {
    ...NamedTagForEdit
  }
}
    ${NamedTagForEditFragmentDoc}`;
export type EditCustomTagMutationFn = ApolloReactCommon.MutationFunction<EditCustomTagMutation, EditCustomTagMutationVariables>;
export type EditCustomTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditCustomTagMutation, EditCustomTagMutationVariables>, 'mutation'>;

    export const EditCustomTagComponent = (props: EditCustomTagComponentProps) => (
      <ApolloReactComponents.Mutation<EditCustomTagMutation, EditCustomTagMutationVariables> mutation={EditCustomTagDocument} {...props} />
    );
    

/**
 * __useEditCustomTagMutation__
 *
 * To run a mutation, you first call `useEditCustomTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomTagMutation, { data, loading, error }] = useEditCustomTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCustomTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCustomTagMutation, EditCustomTagMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCustomTagMutation, EditCustomTagMutationVariables>(EditCustomTagDocument, baseOptions);
      }
export type EditCustomTagMutationHookResult = ReturnType<typeof useEditCustomTagMutation>;
export type EditCustomTagMutationResult = ApolloReactCommon.MutationResult<EditCustomTagMutation>;
export type EditCustomTagMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCustomTagMutation, EditCustomTagMutationVariables>;
export const CreateSmartTagDocument = gql`
    mutation CreateSmartTag($input: CreateSmartTagInput!) {
  createSmartTag(input: $input) {
    ...NamedTagForEdit
  }
}
    ${NamedTagForEditFragmentDoc}`;
export type CreateSmartTagMutationFn = ApolloReactCommon.MutationFunction<CreateSmartTagMutation, CreateSmartTagMutationVariables>;
export type CreateSmartTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSmartTagMutation, CreateSmartTagMutationVariables>, 'mutation'>;

    export const CreateSmartTagComponent = (props: CreateSmartTagComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSmartTagMutation, CreateSmartTagMutationVariables> mutation={CreateSmartTagDocument} {...props} />
    );
    

/**
 * __useCreateSmartTagMutation__
 *
 * To run a mutation, you first call `useCreateSmartTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmartTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmartTagMutation, { data, loading, error }] = useCreateSmartTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSmartTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSmartTagMutation, CreateSmartTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSmartTagMutation, CreateSmartTagMutationVariables>(CreateSmartTagDocument, baseOptions);
      }
export type CreateSmartTagMutationHookResult = ReturnType<typeof useCreateSmartTagMutation>;
export type CreateSmartTagMutationResult = ApolloReactCommon.MutationResult<CreateSmartTagMutation>;
export type CreateSmartTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSmartTagMutation, CreateSmartTagMutationVariables>;
export const EditSmartTagDocument = gql`
    mutation EditSmartTag($input: EditSmartTagInput!) {
  editSmartTag(input: $input) {
    ...NamedTagForEdit
  }
}
    ${NamedTagForEditFragmentDoc}`;
export type EditSmartTagMutationFn = ApolloReactCommon.MutationFunction<EditSmartTagMutation, EditSmartTagMutationVariables>;
export type EditSmartTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditSmartTagMutation, EditSmartTagMutationVariables>, 'mutation'>;

    export const EditSmartTagComponent = (props: EditSmartTagComponentProps) => (
      <ApolloReactComponents.Mutation<EditSmartTagMutation, EditSmartTagMutationVariables> mutation={EditSmartTagDocument} {...props} />
    );
    

/**
 * __useEditSmartTagMutation__
 *
 * To run a mutation, you first call `useEditSmartTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSmartTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSmartTagMutation, { data, loading, error }] = useEditSmartTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSmartTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSmartTagMutation, EditSmartTagMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSmartTagMutation, EditSmartTagMutationVariables>(EditSmartTagDocument, baseOptions);
      }
export type EditSmartTagMutationHookResult = ReturnType<typeof useEditSmartTagMutation>;
export type EditSmartTagMutationResult = ApolloReactCommon.MutationResult<EditSmartTagMutation>;
export type EditSmartTagMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSmartTagMutation, EditSmartTagMutationVariables>;
export const TeamAthletesMealPlansDocument = gql`
    query TeamAthletesMealPlans($teamId: ID!) {
  team(id: $teamId) {
    id
    athletes {
      id
      mealPlans {
        ...TeamAthletesMealPlan
      }
    }
  }
}
    ${TeamAthletesMealPlanFragmentDoc}`;
export type TeamAthletesMealPlansComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamAthletesMealPlansQuery, TeamAthletesMealPlansQueryVariables>, 'query'> & ({ variables: TeamAthletesMealPlansQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamAthletesMealPlansComponent = (props: TeamAthletesMealPlansComponentProps) => (
      <ApolloReactComponents.Query<TeamAthletesMealPlansQuery, TeamAthletesMealPlansQueryVariables> query={TeamAthletesMealPlansDocument} {...props} />
    );
    

/**
 * __useTeamAthletesMealPlansQuery__
 *
 * To run a query within a React component, call `useTeamAthletesMealPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamAthletesMealPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamAthletesMealPlansQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamAthletesMealPlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamAthletesMealPlansQuery, TeamAthletesMealPlansQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamAthletesMealPlansQuery, TeamAthletesMealPlansQueryVariables>(TeamAthletesMealPlansDocument, baseOptions);
      }
export function useTeamAthletesMealPlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamAthletesMealPlansQuery, TeamAthletesMealPlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamAthletesMealPlansQuery, TeamAthletesMealPlansQueryVariables>(TeamAthletesMealPlansDocument, baseOptions);
        }
export type TeamAthletesMealPlansQueryHookResult = ReturnType<typeof useTeamAthletesMealPlansQuery>;
export type TeamAthletesMealPlansLazyQueryHookResult = ReturnType<typeof useTeamAthletesMealPlansLazyQuery>;
export type TeamAthletesMealPlansQueryResult = ApolloReactCommon.QueryResult<TeamAthletesMealPlansQuery, TeamAthletesMealPlansQueryVariables>;
export const MealPlansForTeamDocument = gql`
    query MealPlansForTeam($teamId: ID!, $input: OffsetPaginationInput!, $query: String) {
  mealPlanOffsetConnection(teamId: $teamId, input: $input, query: $query) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...TeamAthletesMealPlan
    }
  }
}
    ${TeamAthletesMealPlanFragmentDoc}`;
export type MealPlansForTeamComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlansForTeamQuery, MealPlansForTeamQueryVariables>, 'query'> & ({ variables: MealPlansForTeamQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlansForTeamComponent = (props: MealPlansForTeamComponentProps) => (
      <ApolloReactComponents.Query<MealPlansForTeamQuery, MealPlansForTeamQueryVariables> query={MealPlansForTeamDocument} {...props} />
    );
    

/**
 * __useMealPlansForTeamQuery__
 *
 * To run a query within a React component, call `useMealPlansForTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlansForTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlansForTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      input: // value for 'input'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMealPlansForTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlansForTeamQuery, MealPlansForTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlansForTeamQuery, MealPlansForTeamQueryVariables>(MealPlansForTeamDocument, baseOptions);
      }
export function useMealPlansForTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlansForTeamQuery, MealPlansForTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlansForTeamQuery, MealPlansForTeamQueryVariables>(MealPlansForTeamDocument, baseOptions);
        }
export type MealPlansForTeamQueryHookResult = ReturnType<typeof useMealPlansForTeamQuery>;
export type MealPlansForTeamLazyQueryHookResult = ReturnType<typeof useMealPlansForTeamLazyQuery>;
export type MealPlansForTeamQueryResult = ApolloReactCommon.QueryResult<MealPlansForTeamQuery, MealPlansForTeamQueryVariables>;
export const RecipesByIdDocument = gql`
    query RecipesById($ids: [ID!]!) {
  recipesById(ids: $ids) {
    ...RecipeWithServings
  }
}
    ${RecipeWithServingsFragmentDoc}`;
export type RecipesByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecipesByIdQuery, RecipesByIdQueryVariables>, 'query'> & ({ variables: RecipesByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecipesByIdComponent = (props: RecipesByIdComponentProps) => (
      <ApolloReactComponents.Query<RecipesByIdQuery, RecipesByIdQueryVariables> query={RecipesByIdDocument} {...props} />
    );
    

/**
 * __useRecipesByIdQuery__
 *
 * To run a query within a React component, call `useRecipesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecipesByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipesByIdQuery, RecipesByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipesByIdQuery, RecipesByIdQueryVariables>(RecipesByIdDocument, baseOptions);
      }
export function useRecipesByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipesByIdQuery, RecipesByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipesByIdQuery, RecipesByIdQueryVariables>(RecipesByIdDocument, baseOptions);
        }
export type RecipesByIdQueryHookResult = ReturnType<typeof useRecipesByIdQuery>;
export type RecipesByIdLazyQueryHookResult = ReturnType<typeof useRecipesByIdLazyQuery>;
export type RecipesByIdQueryResult = ApolloReactCommon.QueryResult<RecipesByIdQuery, RecipesByIdQueryVariables>;
export const TeamAthletesNotesDocument = gql`
    query TeamAthletesNotes($teamId: ID!, $noteTypeId: ID) {
  notesForTeam(teamId: $teamId, noteTypeId: $noteTypeId) {
    ...TeamAthleteNote
  }
  noteTypes {
    id
    name
  }
}
    ${TeamAthleteNoteFragmentDoc}`;
export type TeamAthletesNotesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamAthletesNotesQuery, TeamAthletesNotesQueryVariables>, 'query'> & ({ variables: TeamAthletesNotesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamAthletesNotesComponent = (props: TeamAthletesNotesComponentProps) => (
      <ApolloReactComponents.Query<TeamAthletesNotesQuery, TeamAthletesNotesQueryVariables> query={TeamAthletesNotesDocument} {...props} />
    );
    

/**
 * __useTeamAthletesNotesQuery__
 *
 * To run a query within a React component, call `useTeamAthletesNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamAthletesNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamAthletesNotesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      noteTypeId: // value for 'noteTypeId'
 *   },
 * });
 */
export function useTeamAthletesNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamAthletesNotesQuery, TeamAthletesNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamAthletesNotesQuery, TeamAthletesNotesQueryVariables>(TeamAthletesNotesDocument, baseOptions);
      }
export function useTeamAthletesNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamAthletesNotesQuery, TeamAthletesNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamAthletesNotesQuery, TeamAthletesNotesQueryVariables>(TeamAthletesNotesDocument, baseOptions);
        }
export type TeamAthletesNotesQueryHookResult = ReturnType<typeof useTeamAthletesNotesQuery>;
export type TeamAthletesNotesLazyQueryHookResult = ReturnType<typeof useTeamAthletesNotesLazyQuery>;
export type TeamAthletesNotesQueryResult = ApolloReactCommon.QueryResult<TeamAthletesNotesQuery, TeamAthletesNotesQueryVariables>;
export const TeamEditableRosterDocument = gql`
    query TeamEditableRoster($id: ID!) {
  team(id: $id) {
    ...TeamWithSport
    foodLogNotificationSettings {
      ...FoodLogNotificationSettings
    }
  }
}
    ${TeamWithSportFragmentDoc}
${FoodLogNotificationSettingsFragmentDoc}`;
export type TeamEditableRosterComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamEditableRosterQuery, TeamEditableRosterQueryVariables>, 'query'> & ({ variables: TeamEditableRosterQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamEditableRosterComponent = (props: TeamEditableRosterComponentProps) => (
      <ApolloReactComponents.Query<TeamEditableRosterQuery, TeamEditableRosterQueryVariables> query={TeamEditableRosterDocument} {...props} />
    );
    

/**
 * __useTeamEditableRosterQuery__
 *
 * To run a query within a React component, call `useTeamEditableRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamEditableRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamEditableRosterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamEditableRosterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamEditableRosterQuery, TeamEditableRosterQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamEditableRosterQuery, TeamEditableRosterQueryVariables>(TeamEditableRosterDocument, baseOptions);
      }
export function useTeamEditableRosterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamEditableRosterQuery, TeamEditableRosterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamEditableRosterQuery, TeamEditableRosterQueryVariables>(TeamEditableRosterDocument, baseOptions);
        }
export type TeamEditableRosterQueryHookResult = ReturnType<typeof useTeamEditableRosterQuery>;
export type TeamEditableRosterLazyQueryHookResult = ReturnType<typeof useTeamEditableRosterLazyQuery>;
export type TeamEditableRosterQueryResult = ApolloReactCommon.QueryResult<TeamEditableRosterQuery, TeamEditableRosterQueryVariables>;
export const AthletesForTeamOffsetConnectionDocument = gql`
    query AthletesForTeamOffsetConnection($teamId: ID!, $tagIds: [ID!]!, $input: OffsetPaginationInput!, $query: String, $filter: AthleteFilter, $priorityTagIds: [ID!]!) {
  athletesForTeamOffsetConnection(
    input: $input
    teamId: $teamId
    tagIds: $tagIds
    query: $query
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...ProfileTableAthlete
    }
  }
}
    ${ProfileTableAthleteFragmentDoc}`;
export type AthletesForTeamOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesForTeamOffsetConnectionQuery, AthletesForTeamOffsetConnectionQueryVariables>, 'query'> & ({ variables: AthletesForTeamOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthletesForTeamOffsetConnectionComponent = (props: AthletesForTeamOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<AthletesForTeamOffsetConnectionQuery, AthletesForTeamOffsetConnectionQueryVariables> query={AthletesForTeamOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useAthletesForTeamOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useAthletesForTeamOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesForTeamOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesForTeamOffsetConnectionQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      tagIds: // value for 'tagIds'
 *      input: // value for 'input'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      priorityTagIds: // value for 'priorityTagIds'
 *   },
 * });
 */
export function useAthletesForTeamOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesForTeamOffsetConnectionQuery, AthletesForTeamOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesForTeamOffsetConnectionQuery, AthletesForTeamOffsetConnectionQueryVariables>(AthletesForTeamOffsetConnectionDocument, baseOptions);
      }
export function useAthletesForTeamOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesForTeamOffsetConnectionQuery, AthletesForTeamOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesForTeamOffsetConnectionQuery, AthletesForTeamOffsetConnectionQueryVariables>(AthletesForTeamOffsetConnectionDocument, baseOptions);
        }
export type AthletesForTeamOffsetConnectionQueryHookResult = ReturnType<typeof useAthletesForTeamOffsetConnectionQuery>;
export type AthletesForTeamOffsetConnectionLazyQueryHookResult = ReturnType<typeof useAthletesForTeamOffsetConnectionLazyQuery>;
export type AthletesForTeamOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<AthletesForTeamOffsetConnectionQuery, AthletesForTeamOffsetConnectionQueryVariables>;
export const AthletesForTeamProfilesExportDocument = gql`
    query AthletesForTeamProfilesExport($teamId: ID!, $tagIds: [ID!]!, $query: String, $filter: AthleteFilter, $priorityTagIds: [ID!]!) {
  athletesForTeam(
    teamId: $teamId
    tagIds: $tagIds
    query: $query
    filter: $filter
  ) {
    ...ProfileTableAthlete
  }
}
    ${ProfileTableAthleteFragmentDoc}`;
export type AthletesForTeamProfilesExportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesForTeamProfilesExportQuery, AthletesForTeamProfilesExportQueryVariables>, 'query'> & ({ variables: AthletesForTeamProfilesExportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthletesForTeamProfilesExportComponent = (props: AthletesForTeamProfilesExportComponentProps) => (
      <ApolloReactComponents.Query<AthletesForTeamProfilesExportQuery, AthletesForTeamProfilesExportQueryVariables> query={AthletesForTeamProfilesExportDocument} {...props} />
    );
    

/**
 * __useAthletesForTeamProfilesExportQuery__
 *
 * To run a query within a React component, call `useAthletesForTeamProfilesExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesForTeamProfilesExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesForTeamProfilesExportQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      tagIds: // value for 'tagIds'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      priorityTagIds: // value for 'priorityTagIds'
 *   },
 * });
 */
export function useAthletesForTeamProfilesExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesForTeamProfilesExportQuery, AthletesForTeamProfilesExportQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesForTeamProfilesExportQuery, AthletesForTeamProfilesExportQueryVariables>(AthletesForTeamProfilesExportDocument, baseOptions);
      }
export function useAthletesForTeamProfilesExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesForTeamProfilesExportQuery, AthletesForTeamProfilesExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesForTeamProfilesExportQuery, AthletesForTeamProfilesExportQueryVariables>(AthletesForTeamProfilesExportDocument, baseOptions);
        }
export type AthletesForTeamProfilesExportQueryHookResult = ReturnType<typeof useAthletesForTeamProfilesExportQuery>;
export type AthletesForTeamProfilesExportLazyQueryHookResult = ReturnType<typeof useAthletesForTeamProfilesExportLazyQuery>;
export type AthletesForTeamProfilesExportQueryResult = ApolloReactCommon.QueryResult<AthletesForTeamProfilesExportQuery, AthletesForTeamProfilesExportQueryVariables>;
export const AthletesForTeamEngagementOffsetConnectionDocument = gql`
    query AthletesForTeamEngagementOffsetConnection($teamId: ID!, $input: OffsetPaginationInput!, $tagIds: [ID!]!, $query: String, $filter: AthleteFilter, $inLastDays: Int) {
  athletesForTeamOffsetConnection(
    input: $input
    teamId: $teamId
    tagIds: $tagIds
    query: $query
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...EngagementTableAthlete
    }
  }
}
    ${EngagementTableAthleteFragmentDoc}`;
export type AthletesForTeamEngagementOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesForTeamEngagementOffsetConnectionQuery, AthletesForTeamEngagementOffsetConnectionQueryVariables>, 'query'> & ({ variables: AthletesForTeamEngagementOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthletesForTeamEngagementOffsetConnectionComponent = (props: AthletesForTeamEngagementOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<AthletesForTeamEngagementOffsetConnectionQuery, AthletesForTeamEngagementOffsetConnectionQueryVariables> query={AthletesForTeamEngagementOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useAthletesForTeamEngagementOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useAthletesForTeamEngagementOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesForTeamEngagementOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesForTeamEngagementOffsetConnectionQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      input: // value for 'input'
 *      tagIds: // value for 'tagIds'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      inLastDays: // value for 'inLastDays'
 *   },
 * });
 */
export function useAthletesForTeamEngagementOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesForTeamEngagementOffsetConnectionQuery, AthletesForTeamEngagementOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesForTeamEngagementOffsetConnectionQuery, AthletesForTeamEngagementOffsetConnectionQueryVariables>(AthletesForTeamEngagementOffsetConnectionDocument, baseOptions);
      }
export function useAthletesForTeamEngagementOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesForTeamEngagementOffsetConnectionQuery, AthletesForTeamEngagementOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesForTeamEngagementOffsetConnectionQuery, AthletesForTeamEngagementOffsetConnectionQueryVariables>(AthletesForTeamEngagementOffsetConnectionDocument, baseOptions);
        }
export type AthletesForTeamEngagementOffsetConnectionQueryHookResult = ReturnType<typeof useAthletesForTeamEngagementOffsetConnectionQuery>;
export type AthletesForTeamEngagementOffsetConnectionLazyQueryHookResult = ReturnType<typeof useAthletesForTeamEngagementOffsetConnectionLazyQuery>;
export type AthletesForTeamEngagementOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<AthletesForTeamEngagementOffsetConnectionQuery, AthletesForTeamEngagementOffsetConnectionQueryVariables>;
export const AthletesForTeamFoodPreferencesOffsetConnectionDocument = gql`
    query AthletesForTeamFoodPreferencesOffsetConnection($teamId: ID!, $input: OffsetPaginationInput!, $tagIds: [ID!]!, $query: String, $filter: AthleteFilter) {
  athletesForTeamOffsetConnection(
    input: $input
    teamId: $teamId
    tagIds: $tagIds
    query: $query
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...PreferencesTableAthlete
    }
  }
}
    ${PreferencesTableAthleteFragmentDoc}`;
export type AthletesForTeamFoodPreferencesOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesForTeamFoodPreferencesOffsetConnectionQuery, AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables>, 'query'> & ({ variables: AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthletesForTeamFoodPreferencesOffsetConnectionComponent = (props: AthletesForTeamFoodPreferencesOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<AthletesForTeamFoodPreferencesOffsetConnectionQuery, AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables> query={AthletesForTeamFoodPreferencesOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useAthletesForTeamFoodPreferencesOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useAthletesForTeamFoodPreferencesOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesForTeamFoodPreferencesOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesForTeamFoodPreferencesOffsetConnectionQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      input: // value for 'input'
 *      tagIds: // value for 'tagIds'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesForTeamFoodPreferencesOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesForTeamFoodPreferencesOffsetConnectionQuery, AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesForTeamFoodPreferencesOffsetConnectionQuery, AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables>(AthletesForTeamFoodPreferencesOffsetConnectionDocument, baseOptions);
      }
export function useAthletesForTeamFoodPreferencesOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesForTeamFoodPreferencesOffsetConnectionQuery, AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesForTeamFoodPreferencesOffsetConnectionQuery, AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables>(AthletesForTeamFoodPreferencesOffsetConnectionDocument, baseOptions);
        }
export type AthletesForTeamFoodPreferencesOffsetConnectionQueryHookResult = ReturnType<typeof useAthletesForTeamFoodPreferencesOffsetConnectionQuery>;
export type AthletesForTeamFoodPreferencesOffsetConnectionLazyQueryHookResult = ReturnType<typeof useAthletesForTeamFoodPreferencesOffsetConnectionLazyQuery>;
export type AthletesForTeamFoodPreferencesOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<AthletesForTeamFoodPreferencesOffsetConnectionQuery, AthletesForTeamFoodPreferencesOffsetConnectionQueryVariables>;
export const AthletesForTeamFoodPreferencesDocument = gql`
    query AthletesForTeamFoodPreferences($teamId: ID!, $tagIds: [ID!]!, $query: String, $filter: AthleteFilter) {
  athletesForTeam(
    teamId: $teamId
    tagIds: $tagIds
    query: $query
    filter: $filter
  ) {
    ...PreferencesTableAthlete
  }
}
    ${PreferencesTableAthleteFragmentDoc}`;
export type AthletesForTeamFoodPreferencesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesForTeamFoodPreferencesQuery, AthletesForTeamFoodPreferencesQueryVariables>, 'query'> & ({ variables: AthletesForTeamFoodPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthletesForTeamFoodPreferencesComponent = (props: AthletesForTeamFoodPreferencesComponentProps) => (
      <ApolloReactComponents.Query<AthletesForTeamFoodPreferencesQuery, AthletesForTeamFoodPreferencesQueryVariables> query={AthletesForTeamFoodPreferencesDocument} {...props} />
    );
    

/**
 * __useAthletesForTeamFoodPreferencesQuery__
 *
 * To run a query within a React component, call `useAthletesForTeamFoodPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesForTeamFoodPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesForTeamFoodPreferencesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      tagIds: // value for 'tagIds'
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesForTeamFoodPreferencesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesForTeamFoodPreferencesQuery, AthletesForTeamFoodPreferencesQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesForTeamFoodPreferencesQuery, AthletesForTeamFoodPreferencesQueryVariables>(AthletesForTeamFoodPreferencesDocument, baseOptions);
      }
export function useAthletesForTeamFoodPreferencesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesForTeamFoodPreferencesQuery, AthletesForTeamFoodPreferencesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesForTeamFoodPreferencesQuery, AthletesForTeamFoodPreferencesQueryVariables>(AthletesForTeamFoodPreferencesDocument, baseOptions);
        }
export type AthletesForTeamFoodPreferencesQueryHookResult = ReturnType<typeof useAthletesForTeamFoodPreferencesQuery>;
export type AthletesForTeamFoodPreferencesLazyQueryHookResult = ReturnType<typeof useAthletesForTeamFoodPreferencesLazyQuery>;
export type AthletesForTeamFoodPreferencesQueryResult = ApolloReactCommon.QueryResult<AthletesForTeamFoodPreferencesQuery, AthletesForTeamFoodPreferencesQueryVariables>;
export const TeamDashboardDocument = gql`
    query TeamDashboard($teamId: ID!, $endDate: String!) {
  team(id: $teamId) {
    id
    foodLogNotificationSettings {
      ...FoodLogNotificationSettings
    }
    defaultHideAnthropometry
    defaultMacroDisplaySettings {
      ...MacroMealPlanDisplaySettings
    }
    defaultExchangeDisplaySettings {
      ...ExchangeMealPlanDisplaySettings
    }
    defaultNoTargetsDisplaySettings {
      ...NoTargetsDisplaySettings
    }
    schedules {
      ...TeamDashboardSchedule
    }
  }
  teamGoalConfiguration(teamId: $teamId) {
    ...TeamGoalConfiguration
  }
}
    ${FoodLogNotificationSettingsFragmentDoc}
${MacroMealPlanDisplaySettingsFragmentDoc}
${ExchangeMealPlanDisplaySettingsFragmentDoc}
${NoTargetsDisplaySettingsFragmentDoc}
${TeamDashboardScheduleFragmentDoc}
${TeamGoalConfigurationFragmentDoc}`;
export type TeamDashboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamDashboardQuery, TeamDashboardQueryVariables>, 'query'> & ({ variables: TeamDashboardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamDashboardComponent = (props: TeamDashboardComponentProps) => (
      <ApolloReactComponents.Query<TeamDashboardQuery, TeamDashboardQueryVariables> query={TeamDashboardDocument} {...props} />
    );
    

/**
 * __useTeamDashboardQuery__
 *
 * To run a query within a React component, call `useTeamDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamDashboardQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTeamDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamDashboardQuery, TeamDashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamDashboardQuery, TeamDashboardQueryVariables>(TeamDashboardDocument, baseOptions);
      }
export function useTeamDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamDashboardQuery, TeamDashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamDashboardQuery, TeamDashboardQueryVariables>(TeamDashboardDocument, baseOptions);
        }
export type TeamDashboardQueryHookResult = ReturnType<typeof useTeamDashboardQuery>;
export type TeamDashboardLazyQueryHookResult = ReturnType<typeof useTeamDashboardLazyQuery>;
export type TeamDashboardQueryResult = ApolloReactCommon.QueryResult<TeamDashboardQuery, TeamDashboardQueryVariables>;
export const EditTeamDisplaySettingsDocument = gql`
    mutation EditTeamDisplaySettings($input: EditTeamDisplaySettingsInput!) {
  editTeamDisplaySettings(input: $input) {
    team {
      id
      defaultHideAnthropometry
      defaultMacroDisplaySettings {
        ...MacroMealPlanDisplaySettings
      }
      defaultExchangeDisplaySettings {
        ...ExchangeMealPlanDisplaySettings
      }
      defaultNoTargetsDisplaySettings {
        ...NoTargetsDisplaySettings
      }
    }
  }
}
    ${MacroMealPlanDisplaySettingsFragmentDoc}
${ExchangeMealPlanDisplaySettingsFragmentDoc}
${NoTargetsDisplaySettingsFragmentDoc}`;
export type EditTeamDisplaySettingsMutationFn = ApolloReactCommon.MutationFunction<EditTeamDisplaySettingsMutation, EditTeamDisplaySettingsMutationVariables>;
export type EditTeamDisplaySettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditTeamDisplaySettingsMutation, EditTeamDisplaySettingsMutationVariables>, 'mutation'>;

    export const EditTeamDisplaySettingsComponent = (props: EditTeamDisplaySettingsComponentProps) => (
      <ApolloReactComponents.Mutation<EditTeamDisplaySettingsMutation, EditTeamDisplaySettingsMutationVariables> mutation={EditTeamDisplaySettingsDocument} {...props} />
    );
    

/**
 * __useEditTeamDisplaySettingsMutation__
 *
 * To run a mutation, you first call `useEditTeamDisplaySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamDisplaySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamDisplaySettingsMutation, { data, loading, error }] = useEditTeamDisplaySettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTeamDisplaySettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTeamDisplaySettingsMutation, EditTeamDisplaySettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTeamDisplaySettingsMutation, EditTeamDisplaySettingsMutationVariables>(EditTeamDisplaySettingsDocument, baseOptions);
      }
export type EditTeamDisplaySettingsMutationHookResult = ReturnType<typeof useEditTeamDisplaySettingsMutation>;
export type EditTeamDisplaySettingsMutationResult = ApolloReactCommon.MutationResult<EditTeamDisplaySettingsMutation>;
export type EditTeamDisplaySettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTeamDisplaySettingsMutation, EditTeamDisplaySettingsMutationVariables>;
export const CreateScheduleDocument = gql`
    mutation CreateSchedule($input: CreateScheduleInput!) {
  createSchedule(input: $input) {
    schedule {
      ...TeamDashboardSchedule
    }
  }
}
    ${TeamDashboardScheduleFragmentDoc}`;
export type CreateScheduleMutationFn = ApolloReactCommon.MutationFunction<CreateScheduleMutation, CreateScheduleMutationVariables>;
export type CreateScheduleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateScheduleMutation, CreateScheduleMutationVariables>, 'mutation'>;

    export const CreateScheduleComponent = (props: CreateScheduleComponentProps) => (
      <ApolloReactComponents.Mutation<CreateScheduleMutation, CreateScheduleMutationVariables> mutation={CreateScheduleDocument} {...props} />
    );
    

/**
 * __useCreateScheduleMutation__
 *
 * To run a mutation, you first call `useCreateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleMutation, { data, loading, error }] = useCreateScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateScheduleMutation, CreateScheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateScheduleMutation, CreateScheduleMutationVariables>(CreateScheduleDocument, baseOptions);
      }
export type CreateScheduleMutationHookResult = ReturnType<typeof useCreateScheduleMutation>;
export type CreateScheduleMutationResult = ApolloReactCommon.MutationResult<CreateScheduleMutation>;
export type CreateScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateScheduleMutation, CreateScheduleMutationVariables>;
export const EditScheduleDocument = gql`
    mutation EditSchedule($input: EditScheduleInput!) {
  editSchedule(input: $input) {
    schedule {
      ...TeamDashboardSchedule
    }
  }
}
    ${TeamDashboardScheduleFragmentDoc}`;
export type EditScheduleMutationFn = ApolloReactCommon.MutationFunction<EditScheduleMutation, EditScheduleMutationVariables>;
export type EditScheduleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditScheduleMutation, EditScheduleMutationVariables>, 'mutation'>;

    export const EditScheduleComponent = (props: EditScheduleComponentProps) => (
      <ApolloReactComponents.Mutation<EditScheduleMutation, EditScheduleMutationVariables> mutation={EditScheduleDocument} {...props} />
    );
    

/**
 * __useEditScheduleMutation__
 *
 * To run a mutation, you first call `useEditScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScheduleMutation, { data, loading, error }] = useEditScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditScheduleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditScheduleMutation, EditScheduleMutationVariables>) {
        return ApolloReactHooks.useMutation<EditScheduleMutation, EditScheduleMutationVariables>(EditScheduleDocument, baseOptions);
      }
export type EditScheduleMutationHookResult = ReturnType<typeof useEditScheduleMutation>;
export type EditScheduleMutationResult = ApolloReactCommon.MutationResult<EditScheduleMutation>;
export type EditScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<EditScheduleMutation, EditScheduleMutationVariables>;
export const EditTeamFoodLogNotificationSettingsDocument = gql`
    mutation EditTeamFoodLogNotificationSettings($input: EditTeamFoodLogNotificationSettingsInput!) {
  editTeamFoodLogNotificationSettings(input: $input) {
    team {
      id
      foodLogNotificationSettings {
        ...FoodLogNotificationSettings
      }
    }
  }
}
    ${FoodLogNotificationSettingsFragmentDoc}`;
export type EditTeamFoodLogNotificationSettingsMutationFn = ApolloReactCommon.MutationFunction<EditTeamFoodLogNotificationSettingsMutation, EditTeamFoodLogNotificationSettingsMutationVariables>;
export type EditTeamFoodLogNotificationSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditTeamFoodLogNotificationSettingsMutation, EditTeamFoodLogNotificationSettingsMutationVariables>, 'mutation'>;

    export const EditTeamFoodLogNotificationSettingsComponent = (props: EditTeamFoodLogNotificationSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<EditTeamFoodLogNotificationSettingsMutation, EditTeamFoodLogNotificationSettingsMutationVariables> mutation={EditTeamFoodLogNotificationSettingsDocument} {...props} />
    );
    

/**
 * __useEditTeamFoodLogNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useEditTeamFoodLogNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamFoodLogNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamFoodLogNotificationSettingsMutation, { data, loading, error }] = useEditTeamFoodLogNotificationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTeamFoodLogNotificationSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTeamFoodLogNotificationSettingsMutation, EditTeamFoodLogNotificationSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTeamFoodLogNotificationSettingsMutation, EditTeamFoodLogNotificationSettingsMutationVariables>(EditTeamFoodLogNotificationSettingsDocument, baseOptions);
      }
export type EditTeamFoodLogNotificationSettingsMutationHookResult = ReturnType<typeof useEditTeamFoodLogNotificationSettingsMutation>;
export type EditTeamFoodLogNotificationSettingsMutationResult = ApolloReactCommon.MutationResult<EditTeamFoodLogNotificationSettingsMutation>;
export type EditTeamFoodLogNotificationSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTeamFoodLogNotificationSettingsMutation, EditTeamFoodLogNotificationSettingsMutationVariables>;
export const TeamPageDocument = gql`
    query TeamPage($teamId: ID!) {
  team(id: $teamId) {
    ...TeamPageTeam
  }
}
    ${TeamPageTeamFragmentDoc}`;
export type TeamPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamPageQuery, TeamPageQueryVariables>, 'query'> & ({ variables: TeamPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamPageComponent = (props: TeamPageComponentProps) => (
      <ApolloReactComponents.Query<TeamPageQuery, TeamPageQueryVariables> query={TeamPageDocument} {...props} />
    );
    

/**
 * __useTeamPageQuery__
 *
 * To run a query within a React component, call `useTeamPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPageQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamPageQuery, TeamPageQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamPageQuery, TeamPageQueryVariables>(TeamPageDocument, baseOptions);
      }
export function useTeamPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamPageQuery, TeamPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamPageQuery, TeamPageQueryVariables>(TeamPageDocument, baseOptions);
        }
export type TeamPageQueryHookResult = ReturnType<typeof useTeamPageQuery>;
export type TeamPageLazyQueryHookResult = ReturnType<typeof useTeamPageLazyQuery>;
export type TeamPageQueryResult = ApolloReactCommon.QueryResult<TeamPageQuery, TeamPageQueryVariables>;
export const CreateMetricAthleteDocument = gql`
    mutation CreateMetricAthlete($input: CreateMetricAthleteInput!) {
  createMetricAthlete(input: $input) {
    athlete {
      ...Athlete
      position {
        ...Position
      }
      currentGoal {
        ...FullGoal
      }
      mostRecentAnthropometryEntry {
        ...AnthropometryEntry
      }
      anthropometryEntries {
        ...AnthropometryEntry
      }
    }
  }
}
    ${AthleteFragmentDoc}
${PositionFragmentDoc}
${FullGoalFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export type CreateMetricAthleteMutationFn = ApolloReactCommon.MutationFunction<CreateMetricAthleteMutation, CreateMetricAthleteMutationVariables>;
export type CreateMetricAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMetricAthleteMutation, CreateMetricAthleteMutationVariables>, 'mutation'>;

    export const CreateMetricAthleteComponent = (props: CreateMetricAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMetricAthleteMutation, CreateMetricAthleteMutationVariables> mutation={CreateMetricAthleteDocument} {...props} />
    );
    

/**
 * __useCreateMetricAthleteMutation__
 *
 * To run a mutation, you first call `useCreateMetricAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricAthleteMutation, { data, loading, error }] = useCreateMetricAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMetricAthleteMutation, CreateMetricAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMetricAthleteMutation, CreateMetricAthleteMutationVariables>(CreateMetricAthleteDocument, baseOptions);
      }
export type CreateMetricAthleteMutationHookResult = ReturnType<typeof useCreateMetricAthleteMutation>;
export type CreateMetricAthleteMutationResult = ApolloReactCommon.MutationResult<CreateMetricAthleteMutation>;
export type CreateMetricAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMetricAthleteMutation, CreateMetricAthleteMutationVariables>;
export const TeamPageEditTeamDocument = gql`
    mutation TeamPageEditTeam($input: EditTeamInput!) {
  editTeam(input: $input) {
    team {
      ...Team
      sport {
        ...Sport
        positions {
          ...Position
        }
      }
    }
  }
}
    ${TeamFragmentDoc}
${SportFragmentDoc}
${PositionFragmentDoc}`;
export type TeamPageEditTeamMutationFn = ApolloReactCommon.MutationFunction<TeamPageEditTeamMutation, TeamPageEditTeamMutationVariables>;
export type TeamPageEditTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TeamPageEditTeamMutation, TeamPageEditTeamMutationVariables>, 'mutation'>;

    export const TeamPageEditTeamComponent = (props: TeamPageEditTeamComponentProps) => (
      <ApolloReactComponents.Mutation<TeamPageEditTeamMutation, TeamPageEditTeamMutationVariables> mutation={TeamPageEditTeamDocument} {...props} />
    );
    

/**
 * __useTeamPageEditTeamMutation__
 *
 * To run a mutation, you first call `useTeamPageEditTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamPageEditTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamPageEditTeamMutation, { data, loading, error }] = useTeamPageEditTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamPageEditTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeamPageEditTeamMutation, TeamPageEditTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<TeamPageEditTeamMutation, TeamPageEditTeamMutationVariables>(TeamPageEditTeamDocument, baseOptions);
      }
export type TeamPageEditTeamMutationHookResult = ReturnType<typeof useTeamPageEditTeamMutation>;
export type TeamPageEditTeamMutationResult = ApolloReactCommon.MutationResult<TeamPageEditTeamMutation>;
export type TeamPageEditTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<TeamPageEditTeamMutation, TeamPageEditTeamMutationVariables>;
export const TeamsPageDocument = gql`
    query TeamsPage {
  teams {
    ...TeamsTableTeam
  }
}
    ${TeamsTableTeamFragmentDoc}`;
export type TeamsPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamsPageQuery, TeamsPageQueryVariables>, 'query'>;

    export const TeamsPageComponent = (props: TeamsPageComponentProps) => (
      <ApolloReactComponents.Query<TeamsPageQuery, TeamsPageQueryVariables> query={TeamsPageDocument} {...props} />
    );
    

/**
 * __useTeamsPageQuery__
 *
 * To run a query within a React component, call `useTeamsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamsPageQuery, TeamsPageQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamsPageQuery, TeamsPageQueryVariables>(TeamsPageDocument, baseOptions);
      }
export function useTeamsPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamsPageQuery, TeamsPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamsPageQuery, TeamsPageQueryVariables>(TeamsPageDocument, baseOptions);
        }
export type TeamsPageQueryHookResult = ReturnType<typeof useTeamsPageQuery>;
export type TeamsPageLazyQueryHookResult = ReturnType<typeof useTeamsPageLazyQuery>;
export type TeamsPageQueryResult = ApolloReactCommon.QueryResult<TeamsPageQuery, TeamsPageQueryVariables>;
export const TeamsPageCreateTeamDocument = gql`
    mutation TeamsPageCreateTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    team {
      ...Team
      sport {
        ...Sport
        positions {
          ...Position
        }
      }
    }
  }
}
    ${TeamFragmentDoc}
${SportFragmentDoc}
${PositionFragmentDoc}`;
export type TeamsPageCreateTeamMutationFn = ApolloReactCommon.MutationFunction<TeamsPageCreateTeamMutation, TeamsPageCreateTeamMutationVariables>;
export type TeamsPageCreateTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TeamsPageCreateTeamMutation, TeamsPageCreateTeamMutationVariables>, 'mutation'>;

    export const TeamsPageCreateTeamComponent = (props: TeamsPageCreateTeamComponentProps) => (
      <ApolloReactComponents.Mutation<TeamsPageCreateTeamMutation, TeamsPageCreateTeamMutationVariables> mutation={TeamsPageCreateTeamDocument} {...props} />
    );
    

/**
 * __useTeamsPageCreateTeamMutation__
 *
 * To run a mutation, you first call `useTeamsPageCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamsPageCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamsPageCreateTeamMutation, { data, loading, error }] = useTeamsPageCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamsPageCreateTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeamsPageCreateTeamMutation, TeamsPageCreateTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<TeamsPageCreateTeamMutation, TeamsPageCreateTeamMutationVariables>(TeamsPageCreateTeamDocument, baseOptions);
      }
export type TeamsPageCreateTeamMutationHookResult = ReturnType<typeof useTeamsPageCreateTeamMutation>;
export type TeamsPageCreateTeamMutationResult = ApolloReactCommon.MutationResult<TeamsPageCreateTeamMutation>;
export type TeamsPageCreateTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<TeamsPageCreateTeamMutation, TeamsPageCreateTeamMutationVariables>;
export const TeamsPageDeleteTeamDocument = gql`
    mutation TeamsPageDeleteTeam($input: DeleteTeamInput!) {
  deleteTeam(input: $input) {
    teamId
  }
}
    `;
export type TeamsPageDeleteTeamMutationFn = ApolloReactCommon.MutationFunction<TeamsPageDeleteTeamMutation, TeamsPageDeleteTeamMutationVariables>;
export type TeamsPageDeleteTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TeamsPageDeleteTeamMutation, TeamsPageDeleteTeamMutationVariables>, 'mutation'>;

    export const TeamsPageDeleteTeamComponent = (props: TeamsPageDeleteTeamComponentProps) => (
      <ApolloReactComponents.Mutation<TeamsPageDeleteTeamMutation, TeamsPageDeleteTeamMutationVariables> mutation={TeamsPageDeleteTeamDocument} {...props} />
    );
    

/**
 * __useTeamsPageDeleteTeamMutation__
 *
 * To run a mutation, you first call `useTeamsPageDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamsPageDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamsPageDeleteTeamMutation, { data, loading, error }] = useTeamsPageDeleteTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamsPageDeleteTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeamsPageDeleteTeamMutation, TeamsPageDeleteTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<TeamsPageDeleteTeamMutation, TeamsPageDeleteTeamMutationVariables>(TeamsPageDeleteTeamDocument, baseOptions);
      }
export type TeamsPageDeleteTeamMutationHookResult = ReturnType<typeof useTeamsPageDeleteTeamMutation>;
export type TeamsPageDeleteTeamMutationResult = ApolloReactCommon.MutationResult<TeamsPageDeleteTeamMutation>;
export type TeamsPageDeleteTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<TeamsPageDeleteTeamMutation, TeamsPageDeleteTeamMutationVariables>;
export const ActiveTransfersOffsetConnectionDocument = gql`
    query ActiveTransfersOffsetConnection($input: OffsetPaginationInput!) {
  activeAthleteTransferOffsetConnection(pagination: $input) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...ActiveTransfer
    }
  }
}
    ${ActiveTransferFragmentDoc}`;
export type ActiveTransfersOffsetConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActiveTransfersOffsetConnectionQuery, ActiveTransfersOffsetConnectionQueryVariables>, 'query'> & ({ variables: ActiveTransfersOffsetConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ActiveTransfersOffsetConnectionComponent = (props: ActiveTransfersOffsetConnectionComponentProps) => (
      <ApolloReactComponents.Query<ActiveTransfersOffsetConnectionQuery, ActiveTransfersOffsetConnectionQueryVariables> query={ActiveTransfersOffsetConnectionDocument} {...props} />
    );
    

/**
 * __useActiveTransfersOffsetConnectionQuery__
 *
 * To run a query within a React component, call `useActiveTransfersOffsetConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveTransfersOffsetConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveTransfersOffsetConnectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActiveTransfersOffsetConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActiveTransfersOffsetConnectionQuery, ActiveTransfersOffsetConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<ActiveTransfersOffsetConnectionQuery, ActiveTransfersOffsetConnectionQueryVariables>(ActiveTransfersOffsetConnectionDocument, baseOptions);
      }
export function useActiveTransfersOffsetConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActiveTransfersOffsetConnectionQuery, ActiveTransfersOffsetConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActiveTransfersOffsetConnectionQuery, ActiveTransfersOffsetConnectionQueryVariables>(ActiveTransfersOffsetConnectionDocument, baseOptions);
        }
export type ActiveTransfersOffsetConnectionQueryHookResult = ReturnType<typeof useActiveTransfersOffsetConnectionQuery>;
export type ActiveTransfersOffsetConnectionLazyQueryHookResult = ReturnType<typeof useActiveTransfersOffsetConnectionLazyQuery>;
export type ActiveTransfersOffsetConnectionQueryResult = ApolloReactCommon.QueryResult<ActiveTransfersOffsetConnectionQuery, ActiveTransfersOffsetConnectionQueryVariables>;
export const ActiveTransfersTeamsOptionsDocument = gql`
    query ActiveTransfersTeamsOptions {
  teams {
    ...ActiveTransfersTeamsOption
  }
}
    ${ActiveTransfersTeamsOptionFragmentDoc}`;
export type ActiveTransfersTeamsOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActiveTransfersTeamsOptionsQuery, ActiveTransfersTeamsOptionsQueryVariables>, 'query'>;

    export const ActiveTransfersTeamsOptionsComponent = (props: ActiveTransfersTeamsOptionsComponentProps) => (
      <ApolloReactComponents.Query<ActiveTransfersTeamsOptionsQuery, ActiveTransfersTeamsOptionsQueryVariables> query={ActiveTransfersTeamsOptionsDocument} {...props} />
    );
    

/**
 * __useActiveTransfersTeamsOptionsQuery__
 *
 * To run a query within a React component, call `useActiveTransfersTeamsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveTransfersTeamsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveTransfersTeamsOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveTransfersTeamsOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActiveTransfersTeamsOptionsQuery, ActiveTransfersTeamsOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ActiveTransfersTeamsOptionsQuery, ActiveTransfersTeamsOptionsQueryVariables>(ActiveTransfersTeamsOptionsDocument, baseOptions);
      }
export function useActiveTransfersTeamsOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActiveTransfersTeamsOptionsQuery, ActiveTransfersTeamsOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActiveTransfersTeamsOptionsQuery, ActiveTransfersTeamsOptionsQueryVariables>(ActiveTransfersTeamsOptionsDocument, baseOptions);
        }
export type ActiveTransfersTeamsOptionsQueryHookResult = ReturnType<typeof useActiveTransfersTeamsOptionsQuery>;
export type ActiveTransfersTeamsOptionsLazyQueryHookResult = ReturnType<typeof useActiveTransfersTeamsOptionsLazyQuery>;
export type ActiveTransfersTeamsOptionsQueryResult = ApolloReactCommon.QueryResult<ActiveTransfersTeamsOptionsQuery, ActiveTransfersTeamsOptionsQueryVariables>;
export const RecieveAthleteDocument = gql`
    mutation RecieveAthlete($input: RecieveAthleteTransferInput!) {
  recieveAthleteTransfer(input: $input) {
    athleteTransfer {
      id
      athlete {
        id
        activeTransfer {
          id
        }
      }
    }
  }
}
    `;
export type RecieveAthleteMutationFn = ApolloReactCommon.MutationFunction<RecieveAthleteMutation, RecieveAthleteMutationVariables>;
export type RecieveAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecieveAthleteMutation, RecieveAthleteMutationVariables>, 'mutation'>;

    export const RecieveAthleteComponent = (props: RecieveAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<RecieveAthleteMutation, RecieveAthleteMutationVariables> mutation={RecieveAthleteDocument} {...props} />
    );
    

/**
 * __useRecieveAthleteMutation__
 *
 * To run a mutation, you first call `useRecieveAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecieveAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recieveAthleteMutation, { data, loading, error }] = useRecieveAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecieveAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecieveAthleteMutation, RecieveAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RecieveAthleteMutation, RecieveAthleteMutationVariables>(RecieveAthleteDocument, baseOptions);
      }
export type RecieveAthleteMutationHookResult = ReturnType<typeof useRecieveAthleteMutation>;
export type RecieveAthleteMutationResult = ApolloReactCommon.MutationResult<RecieveAthleteMutation>;
export type RecieveAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RecieveAthleteMutation, RecieveAthleteMutationVariables>;
export const AthleteTransferOrgGroupsDocument = gql`
    query AthleteTransferOrgGroups {
  athleteTransferOrgGroups {
    id
    name
  }
}
    `;
export type AthleteTransferOrgGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteTransferOrgGroupsQuery, AthleteTransferOrgGroupsQueryVariables>, 'query'>;

    export const AthleteTransferOrgGroupsComponent = (props: AthleteTransferOrgGroupsComponentProps) => (
      <ApolloReactComponents.Query<AthleteTransferOrgGroupsQuery, AthleteTransferOrgGroupsQueryVariables> query={AthleteTransferOrgGroupsDocument} {...props} />
    );
    

/**
 * __useAthleteTransferOrgGroupsQuery__
 *
 * To run a query within a React component, call `useAthleteTransferOrgGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteTransferOrgGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteTransferOrgGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAthleteTransferOrgGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteTransferOrgGroupsQuery, AthleteTransferOrgGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteTransferOrgGroupsQuery, AthleteTransferOrgGroupsQueryVariables>(AthleteTransferOrgGroupsDocument, baseOptions);
      }
export function useAthleteTransferOrgGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteTransferOrgGroupsQuery, AthleteTransferOrgGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteTransferOrgGroupsQuery, AthleteTransferOrgGroupsQueryVariables>(AthleteTransferOrgGroupsDocument, baseOptions);
        }
export type AthleteTransferOrgGroupsQueryHookResult = ReturnType<typeof useAthleteTransferOrgGroupsQuery>;
export type AthleteTransferOrgGroupsLazyQueryHookResult = ReturnType<typeof useAthleteTransferOrgGroupsLazyQuery>;
export type AthleteTransferOrgGroupsQueryResult = ApolloReactCommon.QueryResult<AthleteTransferOrgGroupsQuery, AthleteTransferOrgGroupsQueryVariables>;
export const UnsubmittedAgreementFormForUserDocument = gql`
    query UnsubmittedAgreementFormForUser($orgId: ID!) {
  unsubmittedAgreementFormForUser(orgId: $orgId) {
    ...AgreementForm
  }
}
    ${AgreementFormFragmentDoc}`;
export type UnsubmittedAgreementFormForUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>, 'query'> & ({ variables: UnsubmittedAgreementFormForUserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UnsubmittedAgreementFormForUserComponent = (props: UnsubmittedAgreementFormForUserComponentProps) => (
      <ApolloReactComponents.Query<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables> query={UnsubmittedAgreementFormForUserDocument} {...props} />
    );
    

/**
 * __useUnsubmittedAgreementFormForUserQuery__
 *
 * To run a query within a React component, call `useUnsubmittedAgreementFormForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnsubmittedAgreementFormForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnsubmittedAgreementFormForUserQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useUnsubmittedAgreementFormForUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>) {
        return ApolloReactHooks.useQuery<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>(UnsubmittedAgreementFormForUserDocument, baseOptions);
      }
export function useUnsubmittedAgreementFormForUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>(UnsubmittedAgreementFormForUserDocument, baseOptions);
        }
export type UnsubmittedAgreementFormForUserQueryHookResult = ReturnType<typeof useUnsubmittedAgreementFormForUserQuery>;
export type UnsubmittedAgreementFormForUserLazyQueryHookResult = ReturnType<typeof useUnsubmittedAgreementFormForUserLazyQuery>;
export type UnsubmittedAgreementFormForUserQueryResult = ApolloReactCommon.QueryResult<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>;
export const AddAgreementFormSubmissionDocument = gql`
    mutation AddAgreementFormSubmission($input: AddAgreementFormSubmissionInput!) {
  addAgreementFormSubmission(input: $input) {
    agreementForm {
      ...AgreementForm
    }
  }
}
    ${AgreementFormFragmentDoc}`;
export type AddAgreementFormSubmissionMutationFn = ApolloReactCommon.MutationFunction<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables>;
export type AddAgreementFormSubmissionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables>, 'mutation'>;

    export const AddAgreementFormSubmissionComponent = (props: AddAgreementFormSubmissionComponentProps) => (
      <ApolloReactComponents.Mutation<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables> mutation={AddAgreementFormSubmissionDocument} {...props} />
    );
    

/**
 * __useAddAgreementFormSubmissionMutation__
 *
 * To run a mutation, you first call `useAddAgreementFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAgreementFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAgreementFormSubmissionMutation, { data, loading, error }] = useAddAgreementFormSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAgreementFormSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables>(AddAgreementFormSubmissionDocument, baseOptions);
      }
export type AddAgreementFormSubmissionMutationHookResult = ReturnType<typeof useAddAgreementFormSubmissionMutation>;
export type AddAgreementFormSubmissionMutationResult = ApolloReactCommon.MutationResult<AddAgreementFormSubmissionMutation>;
export type AddAgreementFormSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables>;
export const RecipesByIdWithIngredientsDocument = gql`
    query RecipesByIdWithIngredients($ids: [ID!]!) {
  recipesById(ids: $ids) {
    ...RecipeWithServings
  }
}
    ${RecipeWithServingsFragmentDoc}`;
export type RecipesByIdWithIngredientsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>, 'query'> & ({ variables: RecipesByIdWithIngredientsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecipesByIdWithIngredientsComponent = (props: RecipesByIdWithIngredientsComponentProps) => (
      <ApolloReactComponents.Query<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables> query={RecipesByIdWithIngredientsDocument} {...props} />
    );
    

/**
 * __useRecipesByIdWithIngredientsQuery__
 *
 * To run a query within a React component, call `useRecipesByIdWithIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesByIdWithIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesByIdWithIngredientsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecipesByIdWithIngredientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>(RecipesByIdWithIngredientsDocument, baseOptions);
      }
export function useRecipesByIdWithIngredientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>(RecipesByIdWithIngredientsDocument, baseOptions);
        }
export type RecipesByIdWithIngredientsQueryHookResult = ReturnType<typeof useRecipesByIdWithIngredientsQuery>;
export type RecipesByIdWithIngredientsLazyQueryHookResult = ReturnType<typeof useRecipesByIdWithIngredientsLazyQuery>;
export type RecipesByIdWithIngredientsQueryResult = ApolloReactCommon.QueryResult<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>;
export const MyFeatureFlagsDocument = gql`
    query MyFeatureFlags {
  myFeatureFlags {
    ...FeatureFlags
  }
}
    ${FeatureFlagsFragmentDoc}`;
export type MyFeatureFlagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>, 'query'>;

    export const MyFeatureFlagsComponent = (props: MyFeatureFlagsComponentProps) => (
      <ApolloReactComponents.Query<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables> query={MyFeatureFlagsDocument} {...props} />
    );
    

/**
 * __useMyFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useMyFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyFeatureFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>(MyFeatureFlagsDocument, baseOptions);
      }
export function useMyFeatureFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>(MyFeatureFlagsDocument, baseOptions);
        }
export type MyFeatureFlagsQueryHookResult = ReturnType<typeof useMyFeatureFlagsQuery>;
export type MyFeatureFlagsLazyQueryHookResult = ReturnType<typeof useMyFeatureFlagsLazyQuery>;
export type MyFeatureFlagsQueryResult = ApolloReactCommon.QueryResult<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>;
export const MealMenuOrderRateLimitDocument = gql`
    query MealMenuOrderRateLimit($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    id
    orderRateLimit {
      limit
      excludedTeamIds
    }
  }
  menuOrderRateLimitRemainders(mealMenuId: $mealMenuId) {
    ...MenuOrderRateLimitRemainder
  }
}
    ${MenuOrderRateLimitRemainderFragmentDoc}`;
export type MealMenuOrderRateLimitComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>, 'query'> & ({ variables: MealMenuOrderRateLimitQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealMenuOrderRateLimitComponent = (props: MealMenuOrderRateLimitComponentProps) => (
      <ApolloReactComponents.Query<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables> query={MealMenuOrderRateLimitDocument} {...props} />
    );
    

/**
 * __useMealMenuOrderRateLimitQuery__
 *
 * To run a query within a React component, call `useMealMenuOrderRateLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuOrderRateLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuOrderRateLimitQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMealMenuOrderRateLimitQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>(MealMenuOrderRateLimitDocument, baseOptions);
      }
export function useMealMenuOrderRateLimitLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>(MealMenuOrderRateLimitDocument, baseOptions);
        }
export type MealMenuOrderRateLimitQueryHookResult = ReturnType<typeof useMealMenuOrderRateLimitQuery>;
export type MealMenuOrderRateLimitLazyQueryHookResult = ReturnType<typeof useMealMenuOrderRateLimitLazyQuery>;
export type MealMenuOrderRateLimitQueryResult = ApolloReactCommon.QueryResult<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>;
export const MenuOrderSuggestionsDocument = gql`
    query MenuOrderSuggestions($targetMacros: MacrosInput!, $mealMenuId: ID!, $avoidFoodGroupIds: [ID!]!, $limit: Int) {
  menuOrderSuggestions(
    targetMacros: $targetMacros
    mealMenuId: $mealMenuId
    limit: $limit
    avoidFoodGroupIds: $avoidFoodGroupIds
  ) {
    ...MenuOrderSuggestion
  }
}
    ${MenuOrderSuggestionFragmentDoc}`;
export type MenuOrderSuggestionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>, 'query'> & ({ variables: MenuOrderSuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuOrderSuggestionsComponent = (props: MenuOrderSuggestionsComponentProps) => (
      <ApolloReactComponents.Query<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables> query={MenuOrderSuggestionsDocument} {...props} />
    );
    

/**
 * __useMenuOrderSuggestionsQuery__
 *
 * To run a query within a React component, call `useMenuOrderSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuOrderSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuOrderSuggestionsQuery({
 *   variables: {
 *      targetMacros: // value for 'targetMacros'
 *      mealMenuId: // value for 'mealMenuId'
 *      avoidFoodGroupIds: // value for 'avoidFoodGroupIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMenuOrderSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>(MenuOrderSuggestionsDocument, baseOptions);
      }
export function useMenuOrderSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>(MenuOrderSuggestionsDocument, baseOptions);
        }
export type MenuOrderSuggestionsQueryHookResult = ReturnType<typeof useMenuOrderSuggestionsQuery>;
export type MenuOrderSuggestionsLazyQueryHookResult = ReturnType<typeof useMenuOrderSuggestionsLazyQuery>;
export type MenuOrderSuggestionsQueryResult = ApolloReactCommon.QueryResult<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>;
export const RestaurantMenuLinkSuggestionsDocument = gql`
    query RestaurantMenuLinkSuggestions($targetMacros: MacrosInput!, $restaurantMenuLinkId: ID!, $avoidFoodGroupIds: [ID!]!, $limit: Int) {
  restauranMenuLinkSuggestions(
    targetMacros: $targetMacros
    restaurantMenuLinkId: $restaurantMenuLinkId
    limit: $limit
    avoidFoodGroupIds: $avoidFoodGroupIds
  ) {
    ...MenuOrderSuggestion
  }
}
    ${MenuOrderSuggestionFragmentDoc}`;
export type RestaurantMenuLinkSuggestionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>, 'query'> & ({ variables: RestaurantMenuLinkSuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantMenuLinkSuggestionsComponent = (props: RestaurantMenuLinkSuggestionsComponentProps) => (
      <ApolloReactComponents.Query<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables> query={RestaurantMenuLinkSuggestionsDocument} {...props} />
    );
    

/**
 * __useRestaurantMenuLinkSuggestionsQuery__
 *
 * To run a query within a React component, call `useRestaurantMenuLinkSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantMenuLinkSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantMenuLinkSuggestionsQuery({
 *   variables: {
 *      targetMacros: // value for 'targetMacros'
 *      restaurantMenuLinkId: // value for 'restaurantMenuLinkId'
 *      avoidFoodGroupIds: // value for 'avoidFoodGroupIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRestaurantMenuLinkSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>(RestaurantMenuLinkSuggestionsDocument, baseOptions);
      }
export function useRestaurantMenuLinkSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>(RestaurantMenuLinkSuggestionsDocument, baseOptions);
        }
export type RestaurantMenuLinkSuggestionsQueryHookResult = ReturnType<typeof useRestaurantMenuLinkSuggestionsQuery>;
export type RestaurantMenuLinkSuggestionsLazyQueryHookResult = ReturnType<typeof useRestaurantMenuLinkSuggestionsLazyQuery>;
export type RestaurantMenuLinkSuggestionsQueryResult = ApolloReactCommon.QueryResult<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>;
export const RestaurantMenuSuggestionsDocument = gql`
    query RestaurantMenuSuggestions($targetMacros: MacrosInput!, $restaurantMenuId: ID!, $avoidFoodGroupIds: [ID!]!, $limit: Int) {
  restaurantMenuSuggestions(
    targetMacros: $targetMacros
    restaurantMenuId: $restaurantMenuId
    limit: $limit
    avoidFoodGroupIds: $avoidFoodGroupIds
  ) {
    ...MenuOrderSuggestion
  }
}
    ${MenuOrderSuggestionFragmentDoc}`;
export type RestaurantMenuSuggestionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>, 'query'> & ({ variables: RestaurantMenuSuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantMenuSuggestionsComponent = (props: RestaurantMenuSuggestionsComponentProps) => (
      <ApolloReactComponents.Query<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables> query={RestaurantMenuSuggestionsDocument} {...props} />
    );
    

/**
 * __useRestaurantMenuSuggestionsQuery__
 *
 * To run a query within a React component, call `useRestaurantMenuSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantMenuSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantMenuSuggestionsQuery({
 *   variables: {
 *      targetMacros: // value for 'targetMacros'
 *      restaurantMenuId: // value for 'restaurantMenuId'
 *      avoidFoodGroupIds: // value for 'avoidFoodGroupIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRestaurantMenuSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>(RestaurantMenuSuggestionsDocument, baseOptions);
      }
export function useRestaurantMenuSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>(RestaurantMenuSuggestionsDocument, baseOptions);
        }
export type RestaurantMenuSuggestionsQueryHookResult = ReturnType<typeof useRestaurantMenuSuggestionsQuery>;
export type RestaurantMenuSuggestionsLazyQueryHookResult = ReturnType<typeof useRestaurantMenuSuggestionsLazyQuery>;
export type RestaurantMenuSuggestionsQueryResult = ApolloReactCommon.QueryResult<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>;
export const RecordMenuOrderSuggestionsViewDocument = gql`
    mutation RecordMenuOrderSuggestionsView($input: RecordMenuOrderSuggestionsViewInput!) {
  recordMenuOrderSuggestionsView(input: $input)
}
    `;
export type RecordMenuOrderSuggestionsViewMutationFn = ApolloReactCommon.MutationFunction<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables>;
export type RecordMenuOrderSuggestionsViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables>, 'mutation'>;

    export const RecordMenuOrderSuggestionsViewComponent = (props: RecordMenuOrderSuggestionsViewComponentProps) => (
      <ApolloReactComponents.Mutation<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables> mutation={RecordMenuOrderSuggestionsViewDocument} {...props} />
    );
    

/**
 * __useRecordMenuOrderSuggestionsViewMutation__
 *
 * To run a mutation, you first call `useRecordMenuOrderSuggestionsViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordMenuOrderSuggestionsViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordMenuOrderSuggestionsViewMutation, { data, loading, error }] = useRecordMenuOrderSuggestionsViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordMenuOrderSuggestionsViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables>) {
        return ApolloReactHooks.useMutation<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables>(RecordMenuOrderSuggestionsViewDocument, baseOptions);
      }
export type RecordMenuOrderSuggestionsViewMutationHookResult = ReturnType<typeof useRecordMenuOrderSuggestionsViewMutation>;
export type RecordMenuOrderSuggestionsViewMutationResult = ApolloReactCommon.MutationResult<RecordMenuOrderSuggestionsViewMutation>;
export type RecordMenuOrderSuggestionsViewMutationOptions = ApolloReactCommon.BaseMutationOptions<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables>;
export const RecordMenuOrderSuggestionDecisionDocument = gql`
    mutation RecordMenuOrderSuggestionDecision($input: RecordMenuOrderSuggestionDecisionInput!) {
  recordMenuOrderSuggestionDecision(input: $input)
}
    `;
export type RecordMenuOrderSuggestionDecisionMutationFn = ApolloReactCommon.MutationFunction<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables>;
export type RecordMenuOrderSuggestionDecisionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables>, 'mutation'>;

    export const RecordMenuOrderSuggestionDecisionComponent = (props: RecordMenuOrderSuggestionDecisionComponentProps) => (
      <ApolloReactComponents.Mutation<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables> mutation={RecordMenuOrderSuggestionDecisionDocument} {...props} />
    );
    

/**
 * __useRecordMenuOrderSuggestionDecisionMutation__
 *
 * To run a mutation, you first call `useRecordMenuOrderSuggestionDecisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordMenuOrderSuggestionDecisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordMenuOrderSuggestionDecisionMutation, { data, loading, error }] = useRecordMenuOrderSuggestionDecisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordMenuOrderSuggestionDecisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables>) {
        return ApolloReactHooks.useMutation<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables>(RecordMenuOrderSuggestionDecisionDocument, baseOptions);
      }
export type RecordMenuOrderSuggestionDecisionMutationHookResult = ReturnType<typeof useRecordMenuOrderSuggestionDecisionMutation>;
export type RecordMenuOrderSuggestionDecisionMutationResult = ApolloReactCommon.MutationResult<RecordMenuOrderSuggestionDecisionMutation>;
export type RecordMenuOrderSuggestionDecisionMutationOptions = ApolloReactCommon.BaseMutationOptions<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables>;
export const NutrientsWithDriDocument = gql`
    query NutrientsWithDri($age: Int, $sex: SexType, $kcal: Float) {
  nutrients {
    ...NutrientWithDri
  }
}
    ${NutrientWithDriFragmentDoc}`;
export type NutrientsWithDriComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>, 'query'>;

    export const NutrientsWithDriComponent = (props: NutrientsWithDriComponentProps) => (
      <ApolloReactComponents.Query<NutrientsWithDriQuery, NutrientsWithDriQueryVariables> query={NutrientsWithDriDocument} {...props} />
    );
    

/**
 * __useNutrientsWithDriQuery__
 *
 * To run a query within a React component, call `useNutrientsWithDriQuery` and pass it any options that fit your needs.
 * When your component renders, `useNutrientsWithDriQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNutrientsWithDriQuery({
 *   variables: {
 *      age: // value for 'age'
 *      sex: // value for 'sex'
 *      kcal: // value for 'kcal'
 *   },
 * });
 */
export function useNutrientsWithDriQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>) {
        return ApolloReactHooks.useQuery<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>(NutrientsWithDriDocument, baseOptions);
      }
export function useNutrientsWithDriLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>(NutrientsWithDriDocument, baseOptions);
        }
export type NutrientsWithDriQueryHookResult = ReturnType<typeof useNutrientsWithDriQuery>;
export type NutrientsWithDriLazyQueryHookResult = ReturnType<typeof useNutrientsWithDriLazyQuery>;
export type NutrientsWithDriQueryResult = ApolloReactCommon.QueryResult<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>;
export const ServingsWithNutrientAmountsDocument = gql`
    query ServingsWithNutrientAmounts($servingIds: [ID!]!) {
  servings(ids: $servingIds) {
    ...ServingWithNutrientAmounts
  }
}
    ${ServingWithNutrientAmountsFragmentDoc}`;
export type ServingsWithNutrientAmountsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>, 'query'> & ({ variables: ServingsWithNutrientAmountsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ServingsWithNutrientAmountsComponent = (props: ServingsWithNutrientAmountsComponentProps) => (
      <ApolloReactComponents.Query<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables> query={ServingsWithNutrientAmountsDocument} {...props} />
    );
    

/**
 * __useServingsWithNutrientAmountsQuery__
 *
 * To run a query within a React component, call `useServingsWithNutrientAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServingsWithNutrientAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServingsWithNutrientAmountsQuery({
 *   variables: {
 *      servingIds: // value for 'servingIds'
 *   },
 * });
 */
export function useServingsWithNutrientAmountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>) {
        return ApolloReactHooks.useQuery<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>(ServingsWithNutrientAmountsDocument, baseOptions);
      }
export function useServingsWithNutrientAmountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>(ServingsWithNutrientAmountsDocument, baseOptions);
        }
export type ServingsWithNutrientAmountsQueryHookResult = ReturnType<typeof useServingsWithNutrientAmountsQuery>;
export type ServingsWithNutrientAmountsLazyQueryHookResult = ReturnType<typeof useServingsWithNutrientAmountsLazyQuery>;
export type ServingsWithNutrientAmountsQueryResult = ApolloReactCommon.QueryResult<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>;
export const ConvertedServingDocument = gql`
    query ConvertedServing($input: ConvertedServingInput!) {
  convertedServing(input: $input) {
    ...ServingWithFoodOrRecipe
  }
}
    ${ServingWithFoodOrRecipeFragmentDoc}`;
export type ConvertedServingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConvertedServingQuery, ConvertedServingQueryVariables>, 'query'> & ({ variables: ConvertedServingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConvertedServingComponent = (props: ConvertedServingComponentProps) => (
      <ApolloReactComponents.Query<ConvertedServingQuery, ConvertedServingQueryVariables> query={ConvertedServingDocument} {...props} />
    );
    

/**
 * __useConvertedServingQuery__
 *
 * To run a query within a React component, call `useConvertedServingQuery` and pass it any options that fit your needs.
 * When your component renders, `useConvertedServingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConvertedServingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertedServingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConvertedServingQuery, ConvertedServingQueryVariables>) {
        return ApolloReactHooks.useQuery<ConvertedServingQuery, ConvertedServingQueryVariables>(ConvertedServingDocument, baseOptions);
      }
export function useConvertedServingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConvertedServingQuery, ConvertedServingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConvertedServingQuery, ConvertedServingQueryVariables>(ConvertedServingDocument, baseOptions);
        }
export type ConvertedServingQueryHookResult = ReturnType<typeof useConvertedServingQuery>;
export type ConvertedServingLazyQueryHookResult = ReturnType<typeof useConvertedServingLazyQuery>;
export type ConvertedServingQueryResult = ApolloReactCommon.QueryResult<ConvertedServingQuery, ConvertedServingQueryVariables>;
export const UnitsDocument = gql`
    query Units {
  units {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;
export type UnitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnitsQuery, UnitsQueryVariables>, 'query'>;

    export const UnitsComponent = (props: UnitsComponentProps) => (
      <ApolloReactComponents.Query<UnitsQuery, UnitsQueryVariables> query={UnitsDocument} {...props} />
    );
    

/**
 * __useUnitsQuery__
 *
 * To run a query within a React component, call `useUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
        return ApolloReactHooks.useQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, baseOptions);
      }
export function useUnitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, baseOptions);
        }
export type UnitsQueryHookResult = ReturnType<typeof useUnitsQuery>;
export type UnitsLazyQueryHookResult = ReturnType<typeof useUnitsLazyQuery>;
export type UnitsQueryResult = ApolloReactCommon.QueryResult<UnitsQuery, UnitsQueryVariables>;
export const UnitConversionsFromDocument = gql`
    query UnitConversionsFrom($id: ID!) {
  unit(id: $id) {
    ...UnitWithConversions
  }
}
    ${UnitWithConversionsFragmentDoc}`;
export type UnitConversionsFromComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>, 'query'> & ({ variables: UnitConversionsFromQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UnitConversionsFromComponent = (props: UnitConversionsFromComponentProps) => (
      <ApolloReactComponents.Query<UnitConversionsFromQuery, UnitConversionsFromQueryVariables> query={UnitConversionsFromDocument} {...props} />
    );
    

/**
 * __useUnitConversionsFromQuery__
 *
 * To run a query within a React component, call `useUnitConversionsFromQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitConversionsFromQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitConversionsFromQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnitConversionsFromQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>) {
        return ApolloReactHooks.useQuery<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>(UnitConversionsFromDocument, baseOptions);
      }
export function useUnitConversionsFromLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>(UnitConversionsFromDocument, baseOptions);
        }
export type UnitConversionsFromQueryHookResult = ReturnType<typeof useUnitConversionsFromQuery>;
export type UnitConversionsFromLazyQueryHookResult = ReturnType<typeof useUnitConversionsFromLazyQuery>;
export type UnitConversionsFromQueryResult = ApolloReactCommon.QueryResult<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>;
export const UnitsWithConversionsDocument = gql`
    query UnitsWithConversions {
  units {
    ...UnitWithConversions
  }
}
    ${UnitWithConversionsFragmentDoc}`;
export type UnitsWithConversionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>, 'query'>;

    export const UnitsWithConversionsComponent = (props: UnitsWithConversionsComponentProps) => (
      <ApolloReactComponents.Query<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables> query={UnitsWithConversionsDocument} {...props} />
    );
    

/**
 * __useUnitsWithConversionsQuery__
 *
 * To run a query within a React component, call `useUnitsWithConversionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsWithConversionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsWithConversionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitsWithConversionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>) {
        return ApolloReactHooks.useQuery<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>(UnitsWithConversionsDocument, baseOptions);
      }
export function useUnitsWithConversionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>(UnitsWithConversionsDocument, baseOptions);
        }
export type UnitsWithConversionsQueryHookResult = ReturnType<typeof useUnitsWithConversionsQuery>;
export type UnitsWithConversionsLazyQueryHookResult = ReturnType<typeof useUnitsWithConversionsLazyQuery>;
export type UnitsWithConversionsQueryResult = ApolloReactCommon.QueryResult<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>;
export const GetUserLocaleDocument = gql`
    query GetUserLocale {
  myUser {
    id
    locale {
      id
      code
    }
  }
}
    `;
export type GetUserLocaleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserLocaleQuery, GetUserLocaleQueryVariables>, 'query'>;

    export const GetUserLocaleComponent = (props: GetUserLocaleComponentProps) => (
      <ApolloReactComponents.Query<GetUserLocaleQuery, GetUserLocaleQueryVariables> query={GetUserLocaleDocument} {...props} />
    );
    

/**
 * __useGetUserLocaleQuery__
 *
 * To run a query within a React component, call `useGetUserLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLocaleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserLocaleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserLocaleQuery, GetUserLocaleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserLocaleQuery, GetUserLocaleQueryVariables>(GetUserLocaleDocument, baseOptions);
      }
export function useGetUserLocaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserLocaleQuery, GetUserLocaleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserLocaleQuery, GetUserLocaleQueryVariables>(GetUserLocaleDocument, baseOptions);
        }
export type GetUserLocaleQueryHookResult = ReturnType<typeof useGetUserLocaleQuery>;
export type GetUserLocaleLazyQueryHookResult = ReturnType<typeof useGetUserLocaleLazyQuery>;
export type GetUserLocaleQueryResult = ApolloReactCommon.QueryResult<GetUserLocaleQuery, GetUserLocaleQueryVariables>;
export const RecipeWithFullIngredientsDocument = gql`
    query RecipeWithFullIngredients($id: ID!) {
  recipe(id: $id) {
    id
    ingredients {
      ...FullServingAmount
    }
  }
}
    ${FullServingAmountFragmentDoc}`;
export type RecipeWithFullIngredientsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>, 'query'> & ({ variables: RecipeWithFullIngredientsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecipeWithFullIngredientsComponent = (props: RecipeWithFullIngredientsComponentProps) => (
      <ApolloReactComponents.Query<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables> query={RecipeWithFullIngredientsDocument} {...props} />
    );
    

/**
 * __useRecipeWithFullIngredientsQuery__
 *
 * To run a query within a React component, call `useRecipeWithFullIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeWithFullIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeWithFullIngredientsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecipeWithFullIngredientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>(RecipeWithFullIngredientsDocument, baseOptions);
      }
export function useRecipeWithFullIngredientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>(RecipeWithFullIngredientsDocument, baseOptions);
        }
export type RecipeWithFullIngredientsQueryHookResult = ReturnType<typeof useRecipeWithFullIngredientsQuery>;
export type RecipeWithFullIngredientsLazyQueryHookResult = ReturnType<typeof useRecipeWithFullIngredientsLazyQuery>;
export type RecipeWithFullIngredientsQueryResult = ApolloReactCommon.QueryResult<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>;
export const AddAthleteDislikedFoodDocument = gql`
    mutation AddAthleteDislikedFood($input: AthleteFoodPreferenceInput!) {
  addAthleteDislikedFood(input: $input) {
    athlete {
      id
      dislikedFoods {
        ...Food
      }
    }
  }
}
    ${FoodFragmentDoc}`;
export type AddAthleteDislikedFoodMutationFn = ApolloReactCommon.MutationFunction<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables>;
export type AddAthleteDislikedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables>, 'mutation'>;

    export const AddAthleteDislikedFoodComponent = (props: AddAthleteDislikedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables> mutation={AddAthleteDislikedFoodDocument} {...props} />
    );
    

/**
 * __useAddAthleteDislikedFoodMutation__
 *
 * To run a mutation, you first call `useAddAthleteDislikedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteDislikedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteDislikedFoodMutation, { data, loading, error }] = useAddAthleteDislikedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteDislikedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables>(AddAthleteDislikedFoodDocument, baseOptions);
      }
export type AddAthleteDislikedFoodMutationHookResult = ReturnType<typeof useAddAthleteDislikedFoodMutation>;
export type AddAthleteDislikedFoodMutationResult = ApolloReactCommon.MutationResult<AddAthleteDislikedFoodMutation>;
export type AddAthleteDislikedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables>;
export const AddAthleteDislikedFoodGroupDocument = gql`
    mutation AddAthleteDislikedFoodGroup($input: AthleteFoodGroupPreferenceInput!) {
  addAthleteDislikedFoodGroup(input: $input) {
    athlete {
      id
      dislikedFoodGroups {
        ...FullFoodGroup
      }
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type AddAthleteDislikedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables>;
export type AddAthleteDislikedFoodGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables>, 'mutation'>;

    export const AddAthleteDislikedFoodGroupComponent = (props: AddAthleteDislikedFoodGroupComponentProps) => (
      <ApolloReactComponents.Mutation<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables> mutation={AddAthleteDislikedFoodGroupDocument} {...props} />
    );
    

/**
 * __useAddAthleteDislikedFoodGroupMutation__
 *
 * To run a mutation, you first call `useAddAthleteDislikedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteDislikedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteDislikedFoodGroupMutation, { data, loading, error }] = useAddAthleteDislikedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteDislikedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables>(AddAthleteDislikedFoodGroupDocument, baseOptions);
      }
export type AddAthleteDislikedFoodGroupMutationHookResult = ReturnType<typeof useAddAthleteDislikedFoodGroupMutation>;
export type AddAthleteDislikedFoodGroupMutationResult = ApolloReactCommon.MutationResult<AddAthleteDislikedFoodGroupMutation>;
export type AddAthleteDislikedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables>;
export const AddAthleteEmailDocument = gql`
    mutation AddAthleteEmail($input: AddAthleteEmailInput!) {
  addAthleteEmail(input: $input) {
    athlete {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type AddAthleteEmailMutationFn = ApolloReactCommon.MutationFunction<AddAthleteEmailMutation, AddAthleteEmailMutationVariables>;
export type AddAthleteEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAthleteEmailMutation, AddAthleteEmailMutationVariables>, 'mutation'>;

    export const AddAthleteEmailComponent = (props: AddAthleteEmailComponentProps) => (
      <ApolloReactComponents.Mutation<AddAthleteEmailMutation, AddAthleteEmailMutationVariables> mutation={AddAthleteEmailDocument} {...props} />
    );
    

/**
 * __useAddAthleteEmailMutation__
 *
 * To run a mutation, you first call `useAddAthleteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteEmailMutation, { data, loading, error }] = useAddAthleteEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteEmailMutation, AddAthleteEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteEmailMutation, AddAthleteEmailMutationVariables>(AddAthleteEmailDocument, baseOptions);
      }
export type AddAthleteEmailMutationHookResult = ReturnType<typeof useAddAthleteEmailMutation>;
export type AddAthleteEmailMutationResult = ApolloReactCommon.MutationResult<AddAthleteEmailMutation>;
export type AddAthleteEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteEmailMutation, AddAthleteEmailMutationVariables>;
export const AddAthleteLikedFoodDocument = gql`
    mutation AddAthleteLikedFood($input: AthleteFoodPreferenceInput!) {
  addAthleteLikedFood(input: $input) {
    athlete {
      id
      likedFoods {
        ...Food
      }
    }
  }
}
    ${FoodFragmentDoc}`;
export type AddAthleteLikedFoodMutationFn = ApolloReactCommon.MutationFunction<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables>;
export type AddAthleteLikedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables>, 'mutation'>;

    export const AddAthleteLikedFoodComponent = (props: AddAthleteLikedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables> mutation={AddAthleteLikedFoodDocument} {...props} />
    );
    

/**
 * __useAddAthleteLikedFoodMutation__
 *
 * To run a mutation, you first call `useAddAthleteLikedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteLikedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteLikedFoodMutation, { data, loading, error }] = useAddAthleteLikedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteLikedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables>(AddAthleteLikedFoodDocument, baseOptions);
      }
export type AddAthleteLikedFoodMutationHookResult = ReturnType<typeof useAddAthleteLikedFoodMutation>;
export type AddAthleteLikedFoodMutationResult = ApolloReactCommon.MutationResult<AddAthleteLikedFoodMutation>;
export type AddAthleteLikedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables>;
export const AddAthleteLikedFoodGroupDocument = gql`
    mutation AddAthleteLikedFoodGroup($input: AthleteFoodGroupPreferenceInput!) {
  addAthleteLikedFoodGroup(input: $input) {
    athlete {
      id
      likedFoodGroups {
        ...FullFoodGroup
      }
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type AddAthleteLikedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables>;
export type AddAthleteLikedFoodGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables>, 'mutation'>;

    export const AddAthleteLikedFoodGroupComponent = (props: AddAthleteLikedFoodGroupComponentProps) => (
      <ApolloReactComponents.Mutation<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables> mutation={AddAthleteLikedFoodGroupDocument} {...props} />
    );
    

/**
 * __useAddAthleteLikedFoodGroupMutation__
 *
 * To run a mutation, you first call `useAddAthleteLikedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteLikedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteLikedFoodGroupMutation, { data, loading, error }] = useAddAthleteLikedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteLikedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables>(AddAthleteLikedFoodGroupDocument, baseOptions);
      }
export type AddAthleteLikedFoodGroupMutationHookResult = ReturnType<typeof useAddAthleteLikedFoodGroupMutation>;
export type AddAthleteLikedFoodGroupMutationResult = ApolloReactCommon.MutationResult<AddAthleteLikedFoodGroupMutation>;
export type AddAthleteLikedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables>;
export const AddAthletePhoneNumberDocument = gql`
    mutation AddAthletePhoneNumber($input: AddAthletePhoneNumberInput!) {
  addAthletePhoneNumber(input: $input) {
    athlete {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type AddAthletePhoneNumberMutationFn = ApolloReactCommon.MutationFunction<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables>;
export type AddAthletePhoneNumberComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables>, 'mutation'>;

    export const AddAthletePhoneNumberComponent = (props: AddAthletePhoneNumberComponentProps) => (
      <ApolloReactComponents.Mutation<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables> mutation={AddAthletePhoneNumberDocument} {...props} />
    );
    

/**
 * __useAddAthletePhoneNumberMutation__
 *
 * To run a mutation, you first call `useAddAthletePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthletePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthletePhoneNumberMutation, { data, loading, error }] = useAddAthletePhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthletePhoneNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables>(AddAthletePhoneNumberDocument, baseOptions);
      }
export type AddAthletePhoneNumberMutationHookResult = ReturnType<typeof useAddAthletePhoneNumberMutation>;
export type AddAthletePhoneNumberMutationResult = ApolloReactCommon.MutationResult<AddAthletePhoneNumberMutation>;
export type AddAthletePhoneNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables>;
export const AddEventDocument = gql`
    mutation AddEvent($input: AddEventInput!) {
  addEvent(input: $input) {
    event {
      ...Event
    }
  }
}
    ${EventFragmentDoc}`;
export type AddEventMutationFn = ApolloReactCommon.MutationFunction<AddEventMutation, AddEventMutationVariables>;
export type AddEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddEventMutation, AddEventMutationVariables>, 'mutation'>;

    export const AddEventComponent = (props: AddEventComponentProps) => (
      <ApolloReactComponents.Mutation<AddEventMutation, AddEventMutationVariables> mutation={AddEventDocument} {...props} />
    );
    

/**
 * __useAddEventMutation__
 *
 * To run a mutation, you first call `useAddEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventMutation, { data, loading, error }] = useAddEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddEventMutation, AddEventMutationVariables>) {
        return ApolloReactHooks.useMutation<AddEventMutation, AddEventMutationVariables>(AddEventDocument, baseOptions);
      }
export type AddEventMutationHookResult = ReturnType<typeof useAddEventMutation>;
export type AddEventMutationResult = ApolloReactCommon.MutationResult<AddEventMutation>;
export type AddEventMutationOptions = ApolloReactCommon.BaseMutationOptions<AddEventMutation, AddEventMutationVariables>;
export const AddMealOptionDocument = gql`
    mutation AddMealOption($input: AddMealOptionInput!) {
  addMealOption(input: $input) {
    mealOption {
      ...FullMealOption
    }
  }
}
    ${FullMealOptionFragmentDoc}`;
export type AddMealOptionMutationFn = ApolloReactCommon.MutationFunction<AddMealOptionMutation, AddMealOptionMutationVariables>;
export type AddMealOptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMealOptionMutation, AddMealOptionMutationVariables>, 'mutation'>;

    export const AddMealOptionComponent = (props: AddMealOptionComponentProps) => (
      <ApolloReactComponents.Mutation<AddMealOptionMutation, AddMealOptionMutationVariables> mutation={AddMealOptionDocument} {...props} />
    );
    

/**
 * __useAddMealOptionMutation__
 *
 * To run a mutation, you first call `useAddMealOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMealOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMealOptionMutation, { data, loading, error }] = useAddMealOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMealOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMealOptionMutation, AddMealOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMealOptionMutation, AddMealOptionMutationVariables>(AddMealOptionDocument, baseOptions);
      }
export type AddMealOptionMutationHookResult = ReturnType<typeof useAddMealOptionMutation>;
export type AddMealOptionMutationResult = ApolloReactCommon.MutationResult<AddMealOptionMutation>;
export type AddMealOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMealOptionMutation, AddMealOptionMutationVariables>;
export const ArchiveAthleteDocument = gql`
    mutation ArchiveAthlete($input: ArchiveAthleteInput!) {
  archiveAthlete(input: $input) {
    athlete {
      id
    }
  }
}
    `;
export type ArchiveAthleteMutationFn = ApolloReactCommon.MutationFunction<ArchiveAthleteMutation, ArchiveAthleteMutationVariables>;
export type ArchiveAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveAthleteMutation, ArchiveAthleteMutationVariables>, 'mutation'>;

    export const ArchiveAthleteComponent = (props: ArchiveAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<ArchiveAthleteMutation, ArchiveAthleteMutationVariables> mutation={ArchiveAthleteDocument} {...props} />
    );
    

/**
 * __useArchiveAthleteMutation__
 *
 * To run a mutation, you first call `useArchiveAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAthleteMutation, { data, loading, error }] = useArchiveAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveAthleteMutation, ArchiveAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ArchiveAthleteMutation, ArchiveAthleteMutationVariables>(ArchiveAthleteDocument, baseOptions);
      }
export type ArchiveAthleteMutationHookResult = ReturnType<typeof useArchiveAthleteMutation>;
export type ArchiveAthleteMutationResult = ApolloReactCommon.MutationResult<ArchiveAthleteMutation>;
export type ArchiveAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveAthleteMutation, ArchiveAthleteMutationVariables>;
export const CopyExchangeSetDocument = gql`
    mutation CopyExchangeSet($input: CopyExchangeSetInput!) {
  copyExchangeSet(input: $input) {
    exchangeSet {
      ...FullExchangeSet
    }
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type CopyExchangeSetMutationFn = ApolloReactCommon.MutationFunction<CopyExchangeSetMutation, CopyExchangeSetMutationVariables>;
export type CopyExchangeSetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyExchangeSetMutation, CopyExchangeSetMutationVariables>, 'mutation'>;

    export const CopyExchangeSetComponent = (props: CopyExchangeSetComponentProps) => (
      <ApolloReactComponents.Mutation<CopyExchangeSetMutation, CopyExchangeSetMutationVariables> mutation={CopyExchangeSetDocument} {...props} />
    );
    

/**
 * __useCopyExchangeSetMutation__
 *
 * To run a mutation, you first call `useCopyExchangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyExchangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyExchangeSetMutation, { data, loading, error }] = useCopyExchangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyExchangeSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyExchangeSetMutation, CopyExchangeSetMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyExchangeSetMutation, CopyExchangeSetMutationVariables>(CopyExchangeSetDocument, baseOptions);
      }
export type CopyExchangeSetMutationHookResult = ReturnType<typeof useCopyExchangeSetMutation>;
export type CopyExchangeSetMutationResult = ApolloReactCommon.MutationResult<CopyExchangeSetMutation>;
export type CopyExchangeSetMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyExchangeSetMutation, CopyExchangeSetMutationVariables>;
export const CreateOrgMembershipForAthleteDocument = gql`
    mutation CreateOrgMembershipForAthlete($input: CreateOrgMembershipForAthleteInput!) {
  createOrgMembershipForAthlete(input: $input) {
    athlete {
      id
      activationLinkSent
      user {
        id
        username
      }
      orgMembership {
        id
        isActive
      }
    }
    generatedSelfServiceActivationLink
  }
}
    `;
export type CreateOrgMembershipForAthleteMutationFn = ApolloReactCommon.MutationFunction<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables>;
export type CreateOrgMembershipForAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables>, 'mutation'>;

    export const CreateOrgMembershipForAthleteComponent = (props: CreateOrgMembershipForAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables> mutation={CreateOrgMembershipForAthleteDocument} {...props} />
    );
    

/**
 * __useCreateOrgMembershipForAthleteMutation__
 *
 * To run a mutation, you first call `useCreateOrgMembershipForAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMembershipForAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMembershipForAthleteMutation, { data, loading, error }] = useCreateOrgMembershipForAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgMembershipForAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables>(CreateOrgMembershipForAthleteDocument, baseOptions);
      }
export type CreateOrgMembershipForAthleteMutationHookResult = ReturnType<typeof useCreateOrgMembershipForAthleteMutation>;
export type CreateOrgMembershipForAthleteMutationResult = ApolloReactCommon.MutationResult<CreateOrgMembershipForAthleteMutation>;
export type CreateOrgMembershipForAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables>;
export const CreateAthletesDocument = gql`
    mutation CreateAthletes($input: [CreateAthleteInput!]!) {
  createAthletes(input: $input) {
    athletes {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type CreateAthletesMutationFn = ApolloReactCommon.MutationFunction<CreateAthletesMutation, CreateAthletesMutationVariables>;
export type CreateAthletesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAthletesMutation, CreateAthletesMutationVariables>, 'mutation'>;

    export const CreateAthletesComponent = (props: CreateAthletesComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAthletesMutation, CreateAthletesMutationVariables> mutation={CreateAthletesDocument} {...props} />
    );
    

/**
 * __useCreateAthletesMutation__
 *
 * To run a mutation, you first call `useCreateAthletesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAthletesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAthletesMutation, { data, loading, error }] = useCreateAthletesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAthletesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAthletesMutation, CreateAthletesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAthletesMutation, CreateAthletesMutationVariables>(CreateAthletesDocument, baseOptions);
      }
export type CreateAthletesMutationHookResult = ReturnType<typeof useCreateAthletesMutation>;
export type CreateAthletesMutationResult = ApolloReactCommon.MutationResult<CreateAthletesMutation>;
export type CreateAthletesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAthletesMutation, CreateAthletesMutationVariables>;
export const CreateBrandDocument = gql`
    mutation CreateBrand($input: CreateBrandInput!) {
  createBrand(input: $input) {
    brand {
      ...Brand
    }
  }
}
    ${BrandFragmentDoc}`;
export type CreateBrandMutationFn = ApolloReactCommon.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;
export type CreateBrandComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBrandMutation, CreateBrandMutationVariables>, 'mutation'>;

    export const CreateBrandComponent = (props: CreateBrandComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBrandMutation, CreateBrandMutationVariables> mutation={CreateBrandDocument} {...props} />
    );
    

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, baseOptions);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = ApolloReactCommon.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const CreateFoodServingsDocument = gql`
    mutation CreateFoodServings($input: CreateFoodServingsInput!) {
  createFoodServings(input: $input) {
    servings {
      ...Serving
    }
  }
}
    ${ServingFragmentDoc}`;
export type CreateFoodServingsMutationFn = ApolloReactCommon.MutationFunction<CreateFoodServingsMutation, CreateFoodServingsMutationVariables>;
export type CreateFoodServingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateFoodServingsMutation, CreateFoodServingsMutationVariables>, 'mutation'>;

    export const CreateFoodServingsComponent = (props: CreateFoodServingsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateFoodServingsMutation, CreateFoodServingsMutationVariables> mutation={CreateFoodServingsDocument} {...props} />
    );
    

/**
 * __useCreateFoodServingsMutation__
 *
 * To run a mutation, you first call `useCreateFoodServingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoodServingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFoodServingsMutation, { data, loading, error }] = useCreateFoodServingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFoodServingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFoodServingsMutation, CreateFoodServingsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFoodServingsMutation, CreateFoodServingsMutationVariables>(CreateFoodServingsDocument, baseOptions);
      }
export type CreateFoodServingsMutationHookResult = ReturnType<typeof useCreateFoodServingsMutation>;
export type CreateFoodServingsMutationResult = ApolloReactCommon.MutationResult<CreateFoodServingsMutation>;
export type CreateFoodServingsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFoodServingsMutation, CreateFoodServingsMutationVariables>;
export const CreateGoalDocument = gql`
    mutation CreateGoal($input: CreateGoalInput!) {
  createGoal(input: $input) {
    goal {
      ...FullGoal
    }
  }
}
    ${FullGoalFragmentDoc}`;
export type CreateGoalMutationFn = ApolloReactCommon.MutationFunction<CreateGoalMutation, CreateGoalMutationVariables>;
export type CreateGoalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGoalMutation, CreateGoalMutationVariables>, 'mutation'>;

    export const CreateGoalComponent = (props: CreateGoalComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGoalMutation, CreateGoalMutationVariables> mutation={CreateGoalDocument} {...props} />
    );
    

/**
 * __useCreateGoalMutation__
 *
 * To run a mutation, you first call `useCreateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalMutation, { data, loading, error }] = useCreateGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGoalMutation, CreateGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGoalMutation, CreateGoalMutationVariables>(CreateGoalDocument, baseOptions);
      }
export type CreateGoalMutationHookResult = ReturnType<typeof useCreateGoalMutation>;
export type CreateGoalMutationResult = ApolloReactCommon.MutationResult<CreateGoalMutation>;
export type CreateGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGoalMutation, CreateGoalMutationVariables>;
export const CreateMetricAnthropometryEntriesDocument = gql`
    mutation CreateMetricAnthropometryEntries($input: [CreateMetricAnthropometryEntryInput!]!) {
  createMetricAnthropometryEntries(input: $input) {
    anthropometryEntries {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type CreateMetricAnthropometryEntriesMutationFn = ApolloReactCommon.MutationFunction<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables>;
export type CreateMetricAnthropometryEntriesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables>, 'mutation'>;

    export const CreateMetricAnthropometryEntriesComponent = (props: CreateMetricAnthropometryEntriesComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables> mutation={CreateMetricAnthropometryEntriesDocument} {...props} />
    );
    

/**
 * __useCreateMetricAnthropometryEntriesMutation__
 *
 * To run a mutation, you first call `useCreateMetricAnthropometryEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricAnthropometryEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricAnthropometryEntriesMutation, { data, loading, error }] = useCreateMetricAnthropometryEntriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricAnthropometryEntriesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables>(CreateMetricAnthropometryEntriesDocument, baseOptions);
      }
export type CreateMetricAnthropometryEntriesMutationHookResult = ReturnType<typeof useCreateMetricAnthropometryEntriesMutation>;
export type CreateMetricAnthropometryEntriesMutationResult = ApolloReactCommon.MutationResult<CreateMetricAnthropometryEntriesMutation>;
export type CreateMetricAnthropometryEntriesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables>;
export const CreateMetricAnthropometryEntryDocument = gql`
    mutation CreateMetricAnthropometryEntry($input: CreateMetricAnthropometryEntryInput!) {
  createMetricAnthropometryEntry(input: $input) {
    anthropometryEntry {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type CreateMetricAnthropometryEntryMutationFn = ApolloReactCommon.MutationFunction<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables>;
export type CreateMetricAnthropometryEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables>, 'mutation'>;

    export const CreateMetricAnthropometryEntryComponent = (props: CreateMetricAnthropometryEntryComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables> mutation={CreateMetricAnthropometryEntryDocument} {...props} />
    );
    

/**
 * __useCreateMetricAnthropometryEntryMutation__
 *
 * To run a mutation, you first call `useCreateMetricAnthropometryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricAnthropometryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricAnthropometryEntryMutation, { data, loading, error }] = useCreateMetricAnthropometryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricAnthropometryEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables>(CreateMetricAnthropometryEntryDocument, baseOptions);
      }
export type CreateMetricAnthropometryEntryMutationHookResult = ReturnType<typeof useCreateMetricAnthropometryEntryMutation>;
export type CreateMetricAnthropometryEntryMutationResult = ApolloReactCommon.MutationResult<CreateMetricAnthropometryEntryMutation>;
export type CreateMetricAnthropometryEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables>;
export const CreateMetricAthletesDocument = gql`
    mutation CreateMetricAthletes($input: [CreateMetricAthleteInput!]!) {
  createMetricAthletes(input: $input) {
    athletes {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type CreateMetricAthletesMutationFn = ApolloReactCommon.MutationFunction<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables>;
export type CreateMetricAthletesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables>, 'mutation'>;

    export const CreateMetricAthletesComponent = (props: CreateMetricAthletesComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables> mutation={CreateMetricAthletesDocument} {...props} />
    );
    

/**
 * __useCreateMetricAthletesMutation__
 *
 * To run a mutation, you first call `useCreateMetricAthletesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricAthletesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricAthletesMutation, { data, loading, error }] = useCreateMetricAthletesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricAthletesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables>(CreateMetricAthletesDocument, baseOptions);
      }
export type CreateMetricAthletesMutationHookResult = ReturnType<typeof useCreateMetricAthletesMutation>;
export type CreateMetricAthletesMutationResult = ApolloReactCommon.MutationResult<CreateMetricAthletesMutation>;
export type CreateMetricAthletesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables>;
export const CreateNoteDocument = gql`
    mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    note {
      ...NoteWithType
    }
  }
}
    ${NoteWithTypeFragmentDoc}`;
export type CreateNoteMutationFn = ApolloReactCommon.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;
export type CreateNoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNoteMutation, CreateNoteMutationVariables>, 'mutation'>;

    export const CreateNoteComponent = (props: CreateNoteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNoteMutation, CreateNoteMutationVariables> mutation={CreateNoteDocument} {...props} />
    );
    

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, baseOptions);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = ApolloReactCommon.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const CreateNoteTypeDocument = gql`
    mutation CreateNoteType($input: CreateNoteTypeInput!) {
  createNoteType(input: $input) {
    noteType {
      ...NoteType
    }
  }
}
    ${NoteTypeFragmentDoc}`;
export type CreateNoteTypeMutationFn = ApolloReactCommon.MutationFunction<CreateNoteTypeMutation, CreateNoteTypeMutationVariables>;
export type CreateNoteTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNoteTypeMutation, CreateNoteTypeMutationVariables>, 'mutation'>;

    export const CreateNoteTypeComponent = (props: CreateNoteTypeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNoteTypeMutation, CreateNoteTypeMutationVariables> mutation={CreateNoteTypeDocument} {...props} />
    );
    

/**
 * __useCreateNoteTypeMutation__
 *
 * To run a mutation, you first call `useCreateNoteTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteTypeMutation, { data, loading, error }] = useCreateNoteTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNoteTypeMutation, CreateNoteTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNoteTypeMutation, CreateNoteTypeMutationVariables>(CreateNoteTypeDocument, baseOptions);
      }
export type CreateNoteTypeMutationHookResult = ReturnType<typeof useCreateNoteTypeMutation>;
export type CreateNoteTypeMutationResult = ApolloReactCommon.MutationResult<CreateNoteTypeMutation>;
export type CreateNoteTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNoteTypeMutation, CreateNoteTypeMutationVariables>;
export const CreateOrgFoodGroupDocument = gql`
    mutation CreateOrgFoodGroup($input: CreateFoodGroupInput!) {
  createOrgFoodGroup(input: $input) {
    foodGroup {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type CreateOrgFoodGroupMutationFn = ApolloReactCommon.MutationFunction<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables>;
export type CreateOrgFoodGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables>, 'mutation'>;

    export const CreateOrgFoodGroupComponent = (props: CreateOrgFoodGroupComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables> mutation={CreateOrgFoodGroupDocument} {...props} />
    );
    

/**
 * __useCreateOrgFoodGroupMutation__
 *
 * To run a mutation, you first call `useCreateOrgFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgFoodGroupMutation, { data, loading, error }] = useCreateOrgFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables>(CreateOrgFoodGroupDocument, baseOptions);
      }
export type CreateOrgFoodGroupMutationHookResult = ReturnType<typeof useCreateOrgFoodGroupMutation>;
export type CreateOrgFoodGroupMutationResult = ApolloReactCommon.MutationResult<CreateOrgFoodGroupMutation>;
export type CreateOrgFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables>;
export const CreateOrgFoodServingsDocument = gql`
    mutation CreateOrgFoodServings($input: CreateFoodServingsInput!) {
  createOrgFoodServings(input: $input) {
    servings {
      ...Serving
    }
  }
}
    ${ServingFragmentDoc}`;
export type CreateOrgFoodServingsMutationFn = ApolloReactCommon.MutationFunction<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables>;
export type CreateOrgFoodServingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables>, 'mutation'>;

    export const CreateOrgFoodServingsComponent = (props: CreateOrgFoodServingsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables> mutation={CreateOrgFoodServingsDocument} {...props} />
    );
    

/**
 * __useCreateOrgFoodServingsMutation__
 *
 * To run a mutation, you first call `useCreateOrgFoodServingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgFoodServingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgFoodServingsMutation, { data, loading, error }] = useCreateOrgFoodServingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgFoodServingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables>(CreateOrgFoodServingsDocument, baseOptions);
      }
export type CreateOrgFoodServingsMutationHookResult = ReturnType<typeof useCreateOrgFoodServingsMutation>;
export type CreateOrgFoodServingsMutationResult = ApolloReactCommon.MutationResult<CreateOrgFoodServingsMutation>;
export type CreateOrgFoodServingsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables>;
export const CreateSharedFoodGroupDocument = gql`
    mutation CreateSharedFoodGroup($input: CreateFoodGroupInput!) {
  createSharedFoodGroup(input: $input) {
    foodGroup {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type CreateSharedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables>;
export type CreateSharedFoodGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables>, 'mutation'>;

    export const CreateSharedFoodGroupComponent = (props: CreateSharedFoodGroupComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables> mutation={CreateSharedFoodGroupDocument} {...props} />
    );
    

/**
 * __useCreateSharedFoodGroupMutation__
 *
 * To run a mutation, you first call `useCreateSharedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedFoodGroupMutation, { data, loading, error }] = useCreateSharedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSharedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables>(CreateSharedFoodGroupDocument, baseOptions);
      }
export type CreateSharedFoodGroupMutationHookResult = ReturnType<typeof useCreateSharedFoodGroupMutation>;
export type CreateSharedFoodGroupMutationResult = ApolloReactCommon.MutationResult<CreateSharedFoodGroupMutation>;
export type CreateSharedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables>;
export const DeleteAnthropometryEntryDocument = gql`
    mutation DeleteAnthropometryEntry($input: DeleteAnthropometryEntryInput!) {
  deleteAnthropometryEntry(input: $input) {
    anthropometryEntryId
  }
}
    `;
export type DeleteAnthropometryEntryMutationFn = ApolloReactCommon.MutationFunction<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables>;
export type DeleteAnthropometryEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables>, 'mutation'>;

    export const DeleteAnthropometryEntryComponent = (props: DeleteAnthropometryEntryComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables> mutation={DeleteAnthropometryEntryDocument} {...props} />
    );
    

/**
 * __useDeleteAnthropometryEntryMutation__
 *
 * To run a mutation, you first call `useDeleteAnthropometryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnthropometryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnthropometryEntryMutation, { data, loading, error }] = useDeleteAnthropometryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAnthropometryEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables>(DeleteAnthropometryEntryDocument, baseOptions);
      }
export type DeleteAnthropometryEntryMutationHookResult = ReturnType<typeof useDeleteAnthropometryEntryMutation>;
export type DeleteAnthropometryEntryMutationResult = ApolloReactCommon.MutationResult<DeleteAnthropometryEntryMutation>;
export type DeleteAnthropometryEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables>;
export const DeleteMealPlansDocument = gql`
    mutation DeleteMealPlans($input: DeleteMealPlansInput!) {
  deleteMealPlans(input: $input) {
    mealPlanIds
  }
}
    `;
export type DeleteMealPlansMutationFn = ApolloReactCommon.MutationFunction<DeleteMealPlansMutation, DeleteMealPlansMutationVariables>;
export type DeleteMealPlansComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteMealPlansMutation, DeleteMealPlansMutationVariables>, 'mutation'>;

    export const DeleteMealPlansComponent = (props: DeleteMealPlansComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteMealPlansMutation, DeleteMealPlansMutationVariables> mutation={DeleteMealPlansDocument} {...props} />
    );
    

/**
 * __useDeleteMealPlansMutation__
 *
 * To run a mutation, you first call `useDeleteMealPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMealPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMealPlansMutation, { data, loading, error }] = useDeleteMealPlansMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMealPlansMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMealPlansMutation, DeleteMealPlansMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMealPlansMutation, DeleteMealPlansMutationVariables>(DeleteMealPlansDocument, baseOptions);
      }
export type DeleteMealPlansMutationHookResult = ReturnType<typeof useDeleteMealPlansMutation>;
export type DeleteMealPlansMutationResult = ApolloReactCommon.MutationResult<DeleteMealPlansMutation>;
export type DeleteMealPlansMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMealPlansMutation, DeleteMealPlansMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation DeleteNote($input: DeleteNoteInput!) {
  deleteNote(input: $input) {
    noteId
  }
}
    `;
export type DeleteNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;
export type DeleteNoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteNoteMutation, DeleteNoteMutationVariables>, 'mutation'>;

    export const DeleteNoteComponent = (props: DeleteNoteComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteNoteMutation, DeleteNoteMutationVariables> mutation={DeleteNoteDocument} {...props} />
    );
    

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, baseOptions);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = ApolloReactCommon.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const DeleteOrgFoodDocument = gql`
    mutation DeleteOrgFood($input: DeleteFoodInput!) {
  deleteOrgFood(input: $input) {
    foodId
  }
}
    `;
export type DeleteOrgFoodMutationFn = ApolloReactCommon.MutationFunction<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables>;
export type DeleteOrgFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables>, 'mutation'>;

    export const DeleteOrgFoodComponent = (props: DeleteOrgFoodComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables> mutation={DeleteOrgFoodDocument} {...props} />
    );
    

/**
 * __useDeleteOrgFoodMutation__
 *
 * To run a mutation, you first call `useDeleteOrgFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgFoodMutation, { data, loading, error }] = useDeleteOrgFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrgFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables>(DeleteOrgFoodDocument, baseOptions);
      }
export type DeleteOrgFoodMutationHookResult = ReturnType<typeof useDeleteOrgFoodMutation>;
export type DeleteOrgFoodMutationResult = ApolloReactCommon.MutationResult<DeleteOrgFoodMutation>;
export type DeleteOrgFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables>;
export const DeprecateSharedFoodDocument = gql`
    mutation DeprecateSharedFood($input: DeprecateFoodInput!) {
  deprecateSharedFood(input: $input) {
    foodId
  }
}
    `;
export type DeprecateSharedFoodMutationFn = ApolloReactCommon.MutationFunction<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables>;
export type DeprecateSharedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables>, 'mutation'>;

    export const DeprecateSharedFoodComponent = (props: DeprecateSharedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables> mutation={DeprecateSharedFoodDocument} {...props} />
    );
    

/**
 * __useDeprecateSharedFoodMutation__
 *
 * To run a mutation, you first call `useDeprecateSharedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeprecateSharedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deprecateSharedFoodMutation, { data, loading, error }] = useDeprecateSharedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeprecateSharedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables>(DeprecateSharedFoodDocument, baseOptions);
      }
export type DeprecateSharedFoodMutationHookResult = ReturnType<typeof useDeprecateSharedFoodMutation>;
export type DeprecateSharedFoodMutationResult = ApolloReactCommon.MutationResult<DeprecateSharedFoodMutation>;
export type DeprecateSharedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables>;
export const EditAnthropometryEntryDocument = gql`
    mutation EditAnthropometryEntry($input: EditAnthropometryEntryInput!) {
  editAnthropometryEntry(input: $input) {
    anthropometryEntry {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type EditAnthropometryEntryMutationFn = ApolloReactCommon.MutationFunction<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables>;
export type EditAnthropometryEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables>, 'mutation'>;

    export const EditAnthropometryEntryComponent = (props: EditAnthropometryEntryComponentProps) => (
      <ApolloReactComponents.Mutation<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables> mutation={EditAnthropometryEntryDocument} {...props} />
    );
    

/**
 * __useEditAnthropometryEntryMutation__
 *
 * To run a mutation, you first call `useEditAnthropometryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAnthropometryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAnthropometryEntryMutation, { data, loading, error }] = useEditAnthropometryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAnthropometryEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables>(EditAnthropometryEntryDocument, baseOptions);
      }
export type EditAnthropometryEntryMutationHookResult = ReturnType<typeof useEditAnthropometryEntryMutation>;
export type EditAnthropometryEntryMutationResult = ApolloReactCommon.MutationResult<EditAnthropometryEntryMutation>;
export type EditAnthropometryEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables>;
export const EditAnthropometryEntryCommentDocument = gql`
    mutation EditAnthropometryEntryComment($input: EditAnthropometryEntryCommentInput!) {
  editAnthropometryEntryComment(input: $input) {
    anthropometryEntry {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type EditAnthropometryEntryCommentMutationFn = ApolloReactCommon.MutationFunction<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables>;
export type EditAnthropometryEntryCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables>, 'mutation'>;

    export const EditAnthropometryEntryCommentComponent = (props: EditAnthropometryEntryCommentComponentProps) => (
      <ApolloReactComponents.Mutation<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables> mutation={EditAnthropometryEntryCommentDocument} {...props} />
    );
    

/**
 * __useEditAnthropometryEntryCommentMutation__
 *
 * To run a mutation, you first call `useEditAnthropometryEntryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAnthropometryEntryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAnthropometryEntryCommentMutation, { data, loading, error }] = useEditAnthropometryEntryCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAnthropometryEntryCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables>(EditAnthropometryEntryCommentDocument, baseOptions);
      }
export type EditAnthropometryEntryCommentMutationHookResult = ReturnType<typeof useEditAnthropometryEntryCommentMutation>;
export type EditAnthropometryEntryCommentMutationResult = ApolloReactCommon.MutationResult<EditAnthropometryEntryCommentMutation>;
export type EditAnthropometryEntryCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables>;
export const EditBrandDocument = gql`
    mutation EditBrand($input: EditBrandInput!) {
  editBrand(input: $input) {
    brand {
      ...Brand
    }
  }
}
    ${BrandFragmentDoc}`;
export type EditBrandMutationFn = ApolloReactCommon.MutationFunction<EditBrandMutation, EditBrandMutationVariables>;
export type EditBrandComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditBrandMutation, EditBrandMutationVariables>, 'mutation'>;

    export const EditBrandComponent = (props: EditBrandComponentProps) => (
      <ApolloReactComponents.Mutation<EditBrandMutation, EditBrandMutationVariables> mutation={EditBrandDocument} {...props} />
    );
    

/**
 * __useEditBrandMutation__
 *
 * To run a mutation, you first call `useEditBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBrandMutation, { data, loading, error }] = useEditBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditBrandMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditBrandMutation, EditBrandMutationVariables>) {
        return ApolloReactHooks.useMutation<EditBrandMutation, EditBrandMutationVariables>(EditBrandDocument, baseOptions);
      }
export type EditBrandMutationHookResult = ReturnType<typeof useEditBrandMutation>;
export type EditBrandMutationResult = ApolloReactCommon.MutationResult<EditBrandMutation>;
export type EditBrandMutationOptions = ApolloReactCommon.BaseMutationOptions<EditBrandMutation, EditBrandMutationVariables>;
export const EditExchangeMealTemplateDocument = gql`
    mutation EditExchangeMealTemplate($input: EditExchangeMealTemplateInput!) {
  editExchangeMealTemplate(input: $input) {
    exchangeMealTemplate {
      ...EditFullExchangeMealTemplate
    }
  }
}
    ${EditFullExchangeMealTemplateFragmentDoc}`;
export type EditExchangeMealTemplateMutationFn = ApolloReactCommon.MutationFunction<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>;
export type EditExchangeMealTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>, 'mutation'>;

    export const EditExchangeMealTemplateComponent = (props: EditExchangeMealTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables> mutation={EditExchangeMealTemplateDocument} {...props} />
    );
    

/**
 * __useEditExchangeMealTemplateMutation__
 *
 * To run a mutation, you first call `useEditExchangeMealTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExchangeMealTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExchangeMealTemplateMutation, { data, loading, error }] = useEditExchangeMealTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditExchangeMealTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>(EditExchangeMealTemplateDocument, baseOptions);
      }
export type EditExchangeMealTemplateMutationHookResult = ReturnType<typeof useEditExchangeMealTemplateMutation>;
export type EditExchangeMealTemplateMutationResult = ApolloReactCommon.MutationResult<EditExchangeMealTemplateMutation>;
export type EditExchangeMealTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>;
export const EditExchangeMealTemplatesDocument = gql`
    mutation EditExchangeMealTemplates($input: [EditExchangeMealTemplateInput!]!) {
  editExchangeMealTemplates(input: $input) {
    exchangeMealTemplates {
      ...EditFullExchangeMealTemplate
    }
  }
}
    ${EditFullExchangeMealTemplateFragmentDoc}`;
export type EditExchangeMealTemplatesMutationFn = ApolloReactCommon.MutationFunction<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>;
export type EditExchangeMealTemplatesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>, 'mutation'>;

    export const EditExchangeMealTemplatesComponent = (props: EditExchangeMealTemplatesComponentProps) => (
      <ApolloReactComponents.Mutation<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables> mutation={EditExchangeMealTemplatesDocument} {...props} />
    );
    

/**
 * __useEditExchangeMealTemplatesMutation__
 *
 * To run a mutation, you first call `useEditExchangeMealTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExchangeMealTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExchangeMealTemplatesMutation, { data, loading, error }] = useEditExchangeMealTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditExchangeMealTemplatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>(EditExchangeMealTemplatesDocument, baseOptions);
      }
export type EditExchangeMealTemplatesMutationHookResult = ReturnType<typeof useEditExchangeMealTemplatesMutation>;
export type EditExchangeMealTemplatesMutationResult = ApolloReactCommon.MutationResult<EditExchangeMealTemplatesMutation>;
export type EditExchangeMealTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>;
export const EditFoodServingDocument = gql`
    mutation EditFoodServing($input: EditFoodServingInput!) {
  editFoodServing(input: $input) {
    serving {
      ...Serving
    }
  }
}
    ${ServingFragmentDoc}`;
export type EditFoodServingMutationFn = ApolloReactCommon.MutationFunction<EditFoodServingMutation, EditFoodServingMutationVariables>;
export type EditFoodServingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditFoodServingMutation, EditFoodServingMutationVariables>, 'mutation'>;

    export const EditFoodServingComponent = (props: EditFoodServingComponentProps) => (
      <ApolloReactComponents.Mutation<EditFoodServingMutation, EditFoodServingMutationVariables> mutation={EditFoodServingDocument} {...props} />
    );
    

/**
 * __useEditFoodServingMutation__
 *
 * To run a mutation, you first call `useEditFoodServingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFoodServingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFoodServingMutation, { data, loading, error }] = useEditFoodServingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFoodServingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFoodServingMutation, EditFoodServingMutationVariables>) {
        return ApolloReactHooks.useMutation<EditFoodServingMutation, EditFoodServingMutationVariables>(EditFoodServingDocument, baseOptions);
      }
export type EditFoodServingMutationHookResult = ReturnType<typeof useEditFoodServingMutation>;
export type EditFoodServingMutationResult = ApolloReactCommon.MutationResult<EditFoodServingMutation>;
export type EditFoodServingMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFoodServingMutation, EditFoodServingMutationVariables>;
export const EditMacroMealTemplateDocument = gql`
    mutation EditMacroMealTemplate($input: EditMacroMealTemplateInput!) {
  editMacroMealTemplate(input: $input) {
    macroMealTemplate {
      ...MacroMealTemplate
    }
  }
}
    ${MacroMealTemplateFragmentDoc}`;
export type EditMacroMealTemplateMutationFn = ApolloReactCommon.MutationFunction<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>;
export type EditMacroMealTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>, 'mutation'>;

    export const EditMacroMealTemplateComponent = (props: EditMacroMealTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables> mutation={EditMacroMealTemplateDocument} {...props} />
    );
    

/**
 * __useEditMacroMealTemplateMutation__
 *
 * To run a mutation, you first call `useEditMacroMealTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroMealTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroMealTemplateMutation, { data, loading, error }] = useEditMacroMealTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroMealTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>(EditMacroMealTemplateDocument, baseOptions);
      }
export type EditMacroMealTemplateMutationHookResult = ReturnType<typeof useEditMacroMealTemplateMutation>;
export type EditMacroMealTemplateMutationResult = ApolloReactCommon.MutationResult<EditMacroMealTemplateMutation>;
export type EditMacroMealTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>;
export const EditMacroMealTemplatesDocument = gql`
    mutation EditMacroMealTemplates($input: [EditMacroMealTemplateInput!]!) {
  editMacroMealTemplates(input: $input) {
    macroMealTemplates {
      ...MacroMealTemplate
    }
  }
}
    ${MacroMealTemplateFragmentDoc}`;
export type EditMacroMealTemplatesMutationFn = ApolloReactCommon.MutationFunction<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>;
export type EditMacroMealTemplatesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>, 'mutation'>;

    export const EditMacroMealTemplatesComponent = (props: EditMacroMealTemplatesComponentProps) => (
      <ApolloReactComponents.Mutation<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables> mutation={EditMacroMealTemplatesDocument} {...props} />
    );
    

/**
 * __useEditMacroMealTemplatesMutation__
 *
 * To run a mutation, you first call `useEditMacroMealTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroMealTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroMealTemplatesMutation, { data, loading, error }] = useEditMacroMealTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroMealTemplatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>(EditMacroMealTemplatesDocument, baseOptions);
      }
export type EditMacroMealTemplatesMutationHookResult = ReturnType<typeof useEditMacroMealTemplatesMutation>;
export type EditMacroMealTemplatesMutationResult = ApolloReactCommon.MutationResult<EditMacroMealTemplatesMutation>;
export type EditMacroMealTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>;
export const EditMacroProtocolDocument = gql`
    mutation EditMacroProtocol($input: EditMacroProtocolInput!) {
  editMacroProtocol(input: $input) {
    macroProtocol {
      ...FullMacroProtocol
    }
  }
}
    ${FullMacroProtocolFragmentDoc}`;
export type EditMacroProtocolMutationFn = ApolloReactCommon.MutationFunction<EditMacroProtocolMutation, EditMacroProtocolMutationVariables>;
export type EditMacroProtocolComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMacroProtocolMutation, EditMacroProtocolMutationVariables>, 'mutation'>;

    export const EditMacroProtocolComponent = (props: EditMacroProtocolComponentProps) => (
      <ApolloReactComponents.Mutation<EditMacroProtocolMutation, EditMacroProtocolMutationVariables> mutation={EditMacroProtocolDocument} {...props} />
    );
    

/**
 * __useEditMacroProtocolMutation__
 *
 * To run a mutation, you first call `useEditMacroProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroProtocolMutation, { data, loading, error }] = useEditMacroProtocolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroProtocolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroProtocolMutation, EditMacroProtocolMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroProtocolMutation, EditMacroProtocolMutationVariables>(EditMacroProtocolDocument, baseOptions);
      }
export type EditMacroProtocolMutationHookResult = ReturnType<typeof useEditMacroProtocolMutation>;
export type EditMacroProtocolMutationResult = ApolloReactCommon.MutationResult<EditMacroProtocolMutation>;
export type EditMacroProtocolMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroProtocolMutation, EditMacroProtocolMutationVariables>;
export const EditMacroProtocolWithNewGoalDocument = gql`
    mutation EditMacroProtocolWithNewGoal($input: EditMacroProtocolWithNewGoalInput!) {
  editMacroProtocolWithNewGoal(input: $input) {
    macroProtocol {
      ...FullMacroProtocol
    }
  }
}
    ${FullMacroProtocolFragmentDoc}`;
export type EditMacroProtocolWithNewGoalMutationFn = ApolloReactCommon.MutationFunction<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables>;
export type EditMacroProtocolWithNewGoalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables>, 'mutation'>;

    export const EditMacroProtocolWithNewGoalComponent = (props: EditMacroProtocolWithNewGoalComponentProps) => (
      <ApolloReactComponents.Mutation<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables> mutation={EditMacroProtocolWithNewGoalDocument} {...props} />
    );
    

/**
 * __useEditMacroProtocolWithNewGoalMutation__
 *
 * To run a mutation, you first call `useEditMacroProtocolWithNewGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroProtocolWithNewGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroProtocolWithNewGoalMutation, { data, loading, error }] = useEditMacroProtocolWithNewGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroProtocolWithNewGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables>(EditMacroProtocolWithNewGoalDocument, baseOptions);
      }
export type EditMacroProtocolWithNewGoalMutationHookResult = ReturnType<typeof useEditMacroProtocolWithNewGoalMutation>;
export type EditMacroProtocolWithNewGoalMutationResult = ApolloReactCommon.MutationResult<EditMacroProtocolWithNewGoalMutation>;
export type EditMacroProtocolWithNewGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables>;
export const EditMacroProtocolWithOldGoalDocument = gql`
    mutation EditMacroProtocolWithOldGoal($input: EditMacroProtocolWithOldGoalInput!) {
  editMacroProtocolWithOldGoal(input: $input) {
    macroProtocol {
      ...FullMacroProtocol
    }
  }
}
    ${FullMacroProtocolFragmentDoc}`;
export type EditMacroProtocolWithOldGoalMutationFn = ApolloReactCommon.MutationFunction<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables>;
export type EditMacroProtocolWithOldGoalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables>, 'mutation'>;

    export const EditMacroProtocolWithOldGoalComponent = (props: EditMacroProtocolWithOldGoalComponentProps) => (
      <ApolloReactComponents.Mutation<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables> mutation={EditMacroProtocolWithOldGoalDocument} {...props} />
    );
    

/**
 * __useEditMacroProtocolWithOldGoalMutation__
 *
 * To run a mutation, you first call `useEditMacroProtocolWithOldGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroProtocolWithOldGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroProtocolWithOldGoalMutation, { data, loading, error }] = useEditMacroProtocolWithOldGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroProtocolWithOldGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables>(EditMacroProtocolWithOldGoalDocument, baseOptions);
      }
export type EditMacroProtocolWithOldGoalMutationHookResult = ReturnType<typeof useEditMacroProtocolWithOldGoalMutation>;
export type EditMacroProtocolWithOldGoalMutationResult = ApolloReactCommon.MutationResult<EditMacroProtocolWithOldGoalMutation>;
export type EditMacroProtocolWithOldGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables>;
export const EditMealOptionDocument = gql`
    mutation EditMealOption($input: EditMealOptionInput!) {
  editMealOption(input: $input) {
    mealOption {
      ...FullMealOption
    }
  }
}
    ${FullMealOptionFragmentDoc}`;
export type EditMealOptionMutationFn = ApolloReactCommon.MutationFunction<EditMealOptionMutation, EditMealOptionMutationVariables>;
export type EditMealOptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealOptionMutation, EditMealOptionMutationVariables>, 'mutation'>;

    export const EditMealOptionComponent = (props: EditMealOptionComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealOptionMutation, EditMealOptionMutationVariables> mutation={EditMealOptionDocument} {...props} />
    );
    

/**
 * __useEditMealOptionMutation__
 *
 * To run a mutation, you first call `useEditMealOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealOptionMutation, { data, loading, error }] = useEditMealOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealOptionMutation, EditMealOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealOptionMutation, EditMealOptionMutationVariables>(EditMealOptionDocument, baseOptions);
      }
export type EditMealOptionMutationHookResult = ReturnType<typeof useEditMealOptionMutation>;
export type EditMealOptionMutationResult = ApolloReactCommon.MutationResult<EditMealOptionMutation>;
export type EditMealOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealOptionMutation, EditMealOptionMutationVariables>;
export const EditMealOptionPendingDocument = gql`
    mutation EditMealOptionPending($input: EditMealOptionPendingInput!) {
  editMealOptionPending(input: $input) {
    mealOptionPending {
      ...FullMealOptionPending
    }
  }
}
    ${FullMealOptionPendingFragmentDoc}`;
export type EditMealOptionPendingMutationFn = ApolloReactCommon.MutationFunction<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>;
export type EditMealOptionPendingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>, 'mutation'>;

    export const EditMealOptionPendingComponent = (props: EditMealOptionPendingComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables> mutation={EditMealOptionPendingDocument} {...props} />
    );
    

/**
 * __useEditMealOptionPendingMutation__
 *
 * To run a mutation, you first call `useEditMealOptionPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealOptionPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealOptionPendingMutation, { data, loading, error }] = useEditMealOptionPendingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealOptionPendingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>(EditMealOptionPendingDocument, baseOptions);
      }
export type EditMealOptionPendingMutationHookResult = ReturnType<typeof useEditMealOptionPendingMutation>;
export type EditMealOptionPendingMutationResult = ApolloReactCommon.MutationResult<EditMealOptionPendingMutation>;
export type EditMealOptionPendingMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>;
export const EditMealPlanExchangeTargetsDocument = gql`
    mutation EditMealPlanExchangeTargets($input: EditMealPlanExchangeTargetsInput!) {
  editMealPlanExchangeTargets(input: $input) {
    mealPlan {
      id
      exchangeTargets {
        ...EditExchangeAmount
      }
    }
  }
}
    ${EditExchangeAmountFragmentDoc}`;
export type EditMealPlanExchangeTargetsMutationFn = ApolloReactCommon.MutationFunction<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>;
export type EditMealPlanExchangeTargetsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>, 'mutation'>;

    export const EditMealPlanExchangeTargetsComponent = (props: EditMealPlanExchangeTargetsComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables> mutation={EditMealPlanExchangeTargetsDocument} {...props} />
    );
    

/**
 * __useEditMealPlanExchangeTargetsMutation__
 *
 * To run a mutation, you first call `useEditMealPlanExchangeTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealPlanExchangeTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealPlanExchangeTargetsMutation, { data, loading, error }] = useEditMealPlanExchangeTargetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealPlanExchangeTargetsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>(EditMealPlanExchangeTargetsDocument, baseOptions);
      }
export type EditMealPlanExchangeTargetsMutationHookResult = ReturnType<typeof useEditMealPlanExchangeTargetsMutation>;
export type EditMealPlanExchangeTargetsMutationResult = ApolloReactCommon.MutationResult<EditMealPlanExchangeTargetsMutation>;
export type EditMealPlanExchangeTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>;
export const EditMealPlanIsSharedDocument = gql`
    mutation EditMealPlanIsShared($input: EditMealPlanIsSharedInput!) {
  editMealPlanIsShared(input: $input) {
    mealPlan {
      ...BasicMealPlan
    }
  }
}
    ${BasicMealPlanFragmentDoc}`;
export type EditMealPlanIsSharedMutationFn = ApolloReactCommon.MutationFunction<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables>;
export type EditMealPlanIsSharedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables>, 'mutation'>;

    export const EditMealPlanIsSharedComponent = (props: EditMealPlanIsSharedComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables> mutation={EditMealPlanIsSharedDocument} {...props} />
    );
    

/**
 * __useEditMealPlanIsSharedMutation__
 *
 * To run a mutation, you first call `useEditMealPlanIsSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealPlanIsSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealPlanIsSharedMutation, { data, loading, error }] = useEditMealPlanIsSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealPlanIsSharedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables>(EditMealPlanIsSharedDocument, baseOptions);
      }
export type EditMealPlanIsSharedMutationHookResult = ReturnType<typeof useEditMealPlanIsSharedMutation>;
export type EditMealPlanIsSharedMutationResult = ApolloReactCommon.MutationResult<EditMealPlanIsSharedMutation>;
export type EditMealPlanIsSharedMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables>;
export const EditMealPlansIsSharedDocument = gql`
    mutation EditMealPlansIsShared($input: EditMealPlansIsSharedInput!) {
  editMealPlansIsShared(input: $input) {
    mealPlans {
      id
      isShared
    }
  }
}
    `;
export type EditMealPlansIsSharedMutationFn = ApolloReactCommon.MutationFunction<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables>;
export type EditMealPlansIsSharedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables>, 'mutation'>;

    export const EditMealPlansIsSharedComponent = (props: EditMealPlansIsSharedComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables> mutation={EditMealPlansIsSharedDocument} {...props} />
    );
    

/**
 * __useEditMealPlansIsSharedMutation__
 *
 * To run a mutation, you first call `useEditMealPlansIsSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealPlansIsSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealPlansIsSharedMutation, { data, loading, error }] = useEditMealPlansIsSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealPlansIsSharedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables>(EditMealPlansIsSharedDocument, baseOptions);
      }
export type EditMealPlansIsSharedMutationHookResult = ReturnType<typeof useEditMealPlansIsSharedMutation>;
export type EditMealPlansIsSharedMutationResult = ApolloReactCommon.MutationResult<EditMealPlansIsSharedMutation>;
export type EditMealPlansIsSharedMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables>;
export const EditMetricAnthropometryEntriesDocument = gql`
    mutation EditMetricAnthropometryEntries($input: [EditMetricAnthropometryEntryInput!]!) {
  editMetricAnthropometryEntries(input: $input) {
    anthropometryEntries {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type EditMetricAnthropometryEntriesMutationFn = ApolloReactCommon.MutationFunction<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables>;
export type EditMetricAnthropometryEntriesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables>, 'mutation'>;

    export const EditMetricAnthropometryEntriesComponent = (props: EditMetricAnthropometryEntriesComponentProps) => (
      <ApolloReactComponents.Mutation<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables> mutation={EditMetricAnthropometryEntriesDocument} {...props} />
    );
    

/**
 * __useEditMetricAnthropometryEntriesMutation__
 *
 * To run a mutation, you first call `useEditMetricAnthropometryEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMetricAnthropometryEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMetricAnthropometryEntriesMutation, { data, loading, error }] = useEditMetricAnthropometryEntriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMetricAnthropometryEntriesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables>(EditMetricAnthropometryEntriesDocument, baseOptions);
      }
export type EditMetricAnthropometryEntriesMutationHookResult = ReturnType<typeof useEditMetricAnthropometryEntriesMutation>;
export type EditMetricAnthropometryEntriesMutationResult = ApolloReactCommon.MutationResult<EditMetricAnthropometryEntriesMutation>;
export type EditMetricAnthropometryEntriesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables>;
export const EditMetricAnthropometryEntryDocument = gql`
    mutation EditMetricAnthropometryEntry($input: EditMetricAnthropometryEntryInput!) {
  editMetricAnthropometryEntry(input: $input) {
    anthropometryEntry {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type EditMetricAnthropometryEntryMutationFn = ApolloReactCommon.MutationFunction<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables>;
export type EditMetricAnthropometryEntryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables>, 'mutation'>;

    export const EditMetricAnthropometryEntryComponent = (props: EditMetricAnthropometryEntryComponentProps) => (
      <ApolloReactComponents.Mutation<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables> mutation={EditMetricAnthropometryEntryDocument} {...props} />
    );
    

/**
 * __useEditMetricAnthropometryEntryMutation__
 *
 * To run a mutation, you first call `useEditMetricAnthropometryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMetricAnthropometryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMetricAnthropometryEntryMutation, { data, loading, error }] = useEditMetricAnthropometryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMetricAnthropometryEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables>(EditMetricAnthropometryEntryDocument, baseOptions);
      }
export type EditMetricAnthropometryEntryMutationHookResult = ReturnType<typeof useEditMetricAnthropometryEntryMutation>;
export type EditMetricAnthropometryEntryMutationResult = ApolloReactCommon.MutationResult<EditMetricAnthropometryEntryMutation>;
export type EditMetricAnthropometryEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables>;
export const EditMetricAthletesDocument = gql`
    mutation EditMetricAthletes($input: [EditAthleteAndMetricAnthroInput!]!) {
  editMetricAthletes(input: $input) {
    athletes {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type EditMetricAthletesMutationFn = ApolloReactCommon.MutationFunction<EditMetricAthletesMutation, EditMetricAthletesMutationVariables>;
export type EditMetricAthletesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMetricAthletesMutation, EditMetricAthletesMutationVariables>, 'mutation'>;

    export const EditMetricAthletesComponent = (props: EditMetricAthletesComponentProps) => (
      <ApolloReactComponents.Mutation<EditMetricAthletesMutation, EditMetricAthletesMutationVariables> mutation={EditMetricAthletesDocument} {...props} />
    );
    

/**
 * __useEditMetricAthletesMutation__
 *
 * To run a mutation, you first call `useEditMetricAthletesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMetricAthletesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMetricAthletesMutation, { data, loading, error }] = useEditMetricAthletesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMetricAthletesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMetricAthletesMutation, EditMetricAthletesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMetricAthletesMutation, EditMetricAthletesMutationVariables>(EditMetricAthletesDocument, baseOptions);
      }
export type EditMetricAthletesMutationHookResult = ReturnType<typeof useEditMetricAthletesMutation>;
export type EditMetricAthletesMutationResult = ApolloReactCommon.MutationResult<EditMetricAthletesMutation>;
export type EditMetricAthletesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMetricAthletesMutation, EditMetricAthletesMutationVariables>;
export const EditMetricMacroProtocolDocument = gql`
    mutation EditMetricMacroProtocol($input: EditMetricMacroProtocolInput!) {
  editMetricMacroProtocol(input: $input) {
    macroProtocol {
      ...FullMacroProtocol
    }
  }
}
    ${FullMacroProtocolFragmentDoc}`;
export type EditMetricMacroProtocolMutationFn = ApolloReactCommon.MutationFunction<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables>;
export type EditMetricMacroProtocolComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables>, 'mutation'>;

    export const EditMetricMacroProtocolComponent = (props: EditMetricMacroProtocolComponentProps) => (
      <ApolloReactComponents.Mutation<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables> mutation={EditMetricMacroProtocolDocument} {...props} />
    );
    

/**
 * __useEditMetricMacroProtocolMutation__
 *
 * To run a mutation, you first call `useEditMetricMacroProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMetricMacroProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMetricMacroProtocolMutation, { data, loading, error }] = useEditMetricMacroProtocolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMetricMacroProtocolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables>(EditMetricMacroProtocolDocument, baseOptions);
      }
export type EditMetricMacroProtocolMutationHookResult = ReturnType<typeof useEditMetricMacroProtocolMutation>;
export type EditMetricMacroProtocolMutationResult = ApolloReactCommon.MutationResult<EditMetricMacroProtocolMutation>;
export type EditMetricMacroProtocolMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables>;
export const EditNoteDocument = gql`
    mutation EditNote($input: EditNoteInput!) {
  editNote(input: $input) {
    note {
      ...NoteWithType
    }
  }
}
    ${NoteWithTypeFragmentDoc}`;
export type EditNoteMutationFn = ApolloReactCommon.MutationFunction<EditNoteMutation, EditNoteMutationVariables>;
export type EditNoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditNoteMutation, EditNoteMutationVariables>, 'mutation'>;

    export const EditNoteComponent = (props: EditNoteComponentProps) => (
      <ApolloReactComponents.Mutation<EditNoteMutation, EditNoteMutationVariables> mutation={EditNoteDocument} {...props} />
    );
    

/**
 * __useEditNoteMutation__
 *
 * To run a mutation, you first call `useEditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteMutation, { data, loading, error }] = useEditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditNoteMutation, EditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<EditNoteMutation, EditNoteMutationVariables>(EditNoteDocument, baseOptions);
      }
export type EditNoteMutationHookResult = ReturnType<typeof useEditNoteMutation>;
export type EditNoteMutationResult = ApolloReactCommon.MutationResult<EditNoteMutation>;
export type EditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<EditNoteMutation, EditNoteMutationVariables>;
export const EditNoteTypeDocument = gql`
    mutation EditNoteType($input: EditNoteTypeInput!) {
  editNoteType(input: $input) {
    noteType {
      ...NoteType
    }
  }
}
    ${NoteTypeFragmentDoc}`;
export type EditNoteTypeMutationFn = ApolloReactCommon.MutationFunction<EditNoteTypeMutation, EditNoteTypeMutationVariables>;
export type EditNoteTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditNoteTypeMutation, EditNoteTypeMutationVariables>, 'mutation'>;

    export const EditNoteTypeComponent = (props: EditNoteTypeComponentProps) => (
      <ApolloReactComponents.Mutation<EditNoteTypeMutation, EditNoteTypeMutationVariables> mutation={EditNoteTypeDocument} {...props} />
    );
    

/**
 * __useEditNoteTypeMutation__
 *
 * To run a mutation, you first call `useEditNoteTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteTypeMutation, { data, loading, error }] = useEditNoteTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNoteTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditNoteTypeMutation, EditNoteTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<EditNoteTypeMutation, EditNoteTypeMutationVariables>(EditNoteTypeDocument, baseOptions);
      }
export type EditNoteTypeMutationHookResult = ReturnType<typeof useEditNoteTypeMutation>;
export type EditNoteTypeMutationResult = ApolloReactCommon.MutationResult<EditNoteTypeMutation>;
export type EditNoteTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<EditNoteTypeMutation, EditNoteTypeMutationVariables>;
export const EditOrgFoodGroupDocument = gql`
    mutation EditOrgFoodGroup($input: EditFoodGroupInput!) {
  editOrgFoodGroup(input: $input) {
    foodGroup {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type EditOrgFoodGroupMutationFn = ApolloReactCommon.MutationFunction<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables>;
export type EditOrgFoodGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables>, 'mutation'>;

    export const EditOrgFoodGroupComponent = (props: EditOrgFoodGroupComponentProps) => (
      <ApolloReactComponents.Mutation<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables> mutation={EditOrgFoodGroupDocument} {...props} />
    );
    

/**
 * __useEditOrgFoodGroupMutation__
 *
 * To run a mutation, you first call `useEditOrgFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgFoodGroupMutation, { data, loading, error }] = useEditOrgFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables>(EditOrgFoodGroupDocument, baseOptions);
      }
export type EditOrgFoodGroupMutationHookResult = ReturnType<typeof useEditOrgFoodGroupMutation>;
export type EditOrgFoodGroupMutationResult = ApolloReactCommon.MutationResult<EditOrgFoodGroupMutation>;
export type EditOrgFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables>;
export const EditOrgFoodServingDocument = gql`
    mutation EditOrgFoodServing($input: EditFoodServingInput!) {
  editOrgFoodServing(input: $input) {
    serving {
      ...Serving
    }
  }
}
    ${ServingFragmentDoc}`;
export type EditOrgFoodServingMutationFn = ApolloReactCommon.MutationFunction<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables>;
export type EditOrgFoodServingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables>, 'mutation'>;

    export const EditOrgFoodServingComponent = (props: EditOrgFoodServingComponentProps) => (
      <ApolloReactComponents.Mutation<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables> mutation={EditOrgFoodServingDocument} {...props} />
    );
    

/**
 * __useEditOrgFoodServingMutation__
 *
 * To run a mutation, you first call `useEditOrgFoodServingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgFoodServingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgFoodServingMutation, { data, loading, error }] = useEditOrgFoodServingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgFoodServingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables>) {
        return ApolloReactHooks.useMutation<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables>(EditOrgFoodServingDocument, baseOptions);
      }
export type EditOrgFoodServingMutationHookResult = ReturnType<typeof useEditOrgFoodServingMutation>;
export type EditOrgFoodServingMutationResult = ApolloReactCommon.MutationResult<EditOrgFoodServingMutation>;
export type EditOrgFoodServingMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables>;
export const EditSharedFoodGroupDocument = gql`
    mutation EditSharedFoodGroup($input: EditFoodGroupInput!) {
  editSharedFoodGroup(input: $input) {
    foodGroup {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type EditSharedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables>;
export type EditSharedFoodGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables>, 'mutation'>;

    export const EditSharedFoodGroupComponent = (props: EditSharedFoodGroupComponentProps) => (
      <ApolloReactComponents.Mutation<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables> mutation={EditSharedFoodGroupDocument} {...props} />
    );
    

/**
 * __useEditSharedFoodGroupMutation__
 *
 * To run a mutation, you first call `useEditSharedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSharedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSharedFoodGroupMutation, { data, loading, error }] = useEditSharedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSharedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables>(EditSharedFoodGroupDocument, baseOptions);
      }
export type EditSharedFoodGroupMutationHookResult = ReturnType<typeof useEditSharedFoodGroupMutation>;
export type EditSharedFoodGroupMutationResult = ApolloReactCommon.MutationResult<EditSharedFoodGroupMutation>;
export type EditSharedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables>;
export const CreateExchangeSetDocument = gql`
    mutation CreateExchangeSet($input: CreateExchangeSetInput!) {
  createExchangeSet(input: $input) {
    exchangeSet {
      ...FullExchangeSet
    }
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type CreateExchangeSetMutationFn = ApolloReactCommon.MutationFunction<CreateExchangeSetMutation, CreateExchangeSetMutationVariables>;
export type CreateExchangeSetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateExchangeSetMutation, CreateExchangeSetMutationVariables>, 'mutation'>;

    export const CreateExchangeSetComponent = (props: CreateExchangeSetComponentProps) => (
      <ApolloReactComponents.Mutation<CreateExchangeSetMutation, CreateExchangeSetMutationVariables> mutation={CreateExchangeSetDocument} {...props} />
    );
    

/**
 * __useCreateExchangeSetMutation__
 *
 * To run a mutation, you first call `useCreateExchangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExchangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExchangeSetMutation, { data, loading, error }] = useCreateExchangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExchangeSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExchangeSetMutation, CreateExchangeSetMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExchangeSetMutation, CreateExchangeSetMutationVariables>(CreateExchangeSetDocument, baseOptions);
      }
export type CreateExchangeSetMutationHookResult = ReturnType<typeof useCreateExchangeSetMutation>;
export type CreateExchangeSetMutationResult = ApolloReactCommon.MutationResult<CreateExchangeSetMutation>;
export type CreateExchangeSetMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExchangeSetMutation, CreateExchangeSetMutationVariables>;
export const EditExchangeSetDocument = gql`
    mutation EditExchangeSet($input: EditExchangeSetInput!) {
  editExchangeSet(input: $input) {
    exchangeSet {
      ...FullExchangeSet
    }
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type EditExchangeSetMutationFn = ApolloReactCommon.MutationFunction<EditExchangeSetMutation, EditExchangeSetMutationVariables>;
export type EditExchangeSetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditExchangeSetMutation, EditExchangeSetMutationVariables>, 'mutation'>;

    export const EditExchangeSetComponent = (props: EditExchangeSetComponentProps) => (
      <ApolloReactComponents.Mutation<EditExchangeSetMutation, EditExchangeSetMutationVariables> mutation={EditExchangeSetDocument} {...props} />
    );
    

/**
 * __useEditExchangeSetMutation__
 *
 * To run a mutation, you first call `useEditExchangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExchangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExchangeSetMutation, { data, loading, error }] = useEditExchangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditExchangeSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditExchangeSetMutation, EditExchangeSetMutationVariables>) {
        return ApolloReactHooks.useMutation<EditExchangeSetMutation, EditExchangeSetMutationVariables>(EditExchangeSetDocument, baseOptions);
      }
export type EditExchangeSetMutationHookResult = ReturnType<typeof useEditExchangeSetMutation>;
export type EditExchangeSetMutationResult = ApolloReactCommon.MutationResult<EditExchangeSetMutation>;
export type EditExchangeSetMutationOptions = ApolloReactCommon.BaseMutationOptions<EditExchangeSetMutation, EditExchangeSetMutationVariables>;
export const AddNixCommonFoodDocument = gql`
    mutation AddNixCommonFood($input: AddNixCommonFoodInput!) {
  addNixCommonFood(input: $input) {
    food {
      ...FoodWithDefaultServing
    }
  }
}
    ${FoodWithDefaultServingFragmentDoc}`;
export type AddNixCommonFoodMutationFn = ApolloReactCommon.MutationFunction<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables>;
export type AddNixCommonFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables>, 'mutation'>;

    export const AddNixCommonFoodComponent = (props: AddNixCommonFoodComponentProps) => (
      <ApolloReactComponents.Mutation<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables> mutation={AddNixCommonFoodDocument} {...props} />
    );
    

/**
 * __useAddNixCommonFoodMutation__
 *
 * To run a mutation, you first call `useAddNixCommonFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNixCommonFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNixCommonFoodMutation, { data, loading, error }] = useAddNixCommonFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNixCommonFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables>(AddNixCommonFoodDocument, baseOptions);
      }
export type AddNixCommonFoodMutationHookResult = ReturnType<typeof useAddNixCommonFoodMutation>;
export type AddNixCommonFoodMutationResult = ApolloReactCommon.MutationResult<AddNixCommonFoodMutation>;
export type AddNixCommonFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables>;
export const AddNixBrandedFoodDocument = gql`
    mutation AddNixBrandedFood($input: AddNixBrandedFoodInput!) {
  addNixBrandedFood(input: $input) {
    food {
      ...FoodWithDefaultServing
    }
  }
}
    ${FoodWithDefaultServingFragmentDoc}`;
export type AddNixBrandedFoodMutationFn = ApolloReactCommon.MutationFunction<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables>;
export type AddNixBrandedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables>, 'mutation'>;

    export const AddNixBrandedFoodComponent = (props: AddNixBrandedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables> mutation={AddNixBrandedFoodDocument} {...props} />
    );
    

/**
 * __useAddNixBrandedFoodMutation__
 *
 * To run a mutation, you first call `useAddNixBrandedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNixBrandedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNixBrandedFoodMutation, { data, loading, error }] = useAddNixBrandedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNixBrandedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables>(AddNixBrandedFoodDocument, baseOptions);
      }
export type AddNixBrandedFoodMutationHookResult = ReturnType<typeof useAddNixBrandedFoodMutation>;
export type AddNixBrandedFoodMutationResult = ApolloReactCommon.MutationResult<AddNixBrandedFoodMutation>;
export type AddNixBrandedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables>;
export const RemoveAthleteDislikedFoodDocument = gql`
    mutation RemoveAthleteDislikedFood($input: AthleteFoodPreferenceInput!) {
  removeAthleteDislikedFood(input: $input) {
    athlete {
      id
      dislikedFoods {
        ...Food
      }
    }
  }
}
    ${FoodFragmentDoc}`;
export type RemoveAthleteDislikedFoodMutationFn = ApolloReactCommon.MutationFunction<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables>;
export type RemoveAthleteDislikedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables>, 'mutation'>;

    export const RemoveAthleteDislikedFoodComponent = (props: RemoveAthleteDislikedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables> mutation={RemoveAthleteDislikedFoodDocument} {...props} />
    );
    

/**
 * __useRemoveAthleteDislikedFoodMutation__
 *
 * To run a mutation, you first call `useRemoveAthleteDislikedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAthleteDislikedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAthleteDislikedFoodMutation, { data, loading, error }] = useRemoveAthleteDislikedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAthleteDislikedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables>(RemoveAthleteDislikedFoodDocument, baseOptions);
      }
export type RemoveAthleteDislikedFoodMutationHookResult = ReturnType<typeof useRemoveAthleteDislikedFoodMutation>;
export type RemoveAthleteDislikedFoodMutationResult = ApolloReactCommon.MutationResult<RemoveAthleteDislikedFoodMutation>;
export type RemoveAthleteDislikedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables>;
export const RemoveAthleteDislikedFoodGroupDocument = gql`
    mutation RemoveAthleteDislikedFoodGroup($input: AthleteFoodGroupPreferenceInput!) {
  removeAthleteDislikedFoodGroup(input: $input) {
    athlete {
      id
      dislikedFoodGroups {
        ...FullFoodGroup
      }
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type RemoveAthleteDislikedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables>;
export type RemoveAthleteDislikedFoodGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables>, 'mutation'>;

    export const RemoveAthleteDislikedFoodGroupComponent = (props: RemoveAthleteDislikedFoodGroupComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables> mutation={RemoveAthleteDislikedFoodGroupDocument} {...props} />
    );
    

/**
 * __useRemoveAthleteDislikedFoodGroupMutation__
 *
 * To run a mutation, you first call `useRemoveAthleteDislikedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAthleteDislikedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAthleteDislikedFoodGroupMutation, { data, loading, error }] = useRemoveAthleteDislikedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAthleteDislikedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables>(RemoveAthleteDislikedFoodGroupDocument, baseOptions);
      }
export type RemoveAthleteDislikedFoodGroupMutationHookResult = ReturnType<typeof useRemoveAthleteDislikedFoodGroupMutation>;
export type RemoveAthleteDislikedFoodGroupMutationResult = ApolloReactCommon.MutationResult<RemoveAthleteDislikedFoodGroupMutation>;
export type RemoveAthleteDislikedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables>;
export const RemoveAthleteLikedFoodDocument = gql`
    mutation RemoveAthleteLikedFood($input: AthleteFoodPreferenceInput!) {
  removeAthleteLikedFood(input: $input) {
    athlete {
      id
      likedFoods {
        ...Food
      }
    }
  }
}
    ${FoodFragmentDoc}`;
export type RemoveAthleteLikedFoodMutationFn = ApolloReactCommon.MutationFunction<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables>;
export type RemoveAthleteLikedFoodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables>, 'mutation'>;

    export const RemoveAthleteLikedFoodComponent = (props: RemoveAthleteLikedFoodComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables> mutation={RemoveAthleteLikedFoodDocument} {...props} />
    );
    

/**
 * __useRemoveAthleteLikedFoodMutation__
 *
 * To run a mutation, you first call `useRemoveAthleteLikedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAthleteLikedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAthleteLikedFoodMutation, { data, loading, error }] = useRemoveAthleteLikedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAthleteLikedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables>(RemoveAthleteLikedFoodDocument, baseOptions);
      }
export type RemoveAthleteLikedFoodMutationHookResult = ReturnType<typeof useRemoveAthleteLikedFoodMutation>;
export type RemoveAthleteLikedFoodMutationResult = ApolloReactCommon.MutationResult<RemoveAthleteLikedFoodMutation>;
export type RemoveAthleteLikedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables>;
export const RemoveAthleteLikedFoodGroupDocument = gql`
    mutation RemoveAthleteLikedFoodGroup($input: AthleteFoodGroupPreferenceInput!) {
  removeAthleteLikedFoodGroup(input: $input) {
    athlete {
      id
      likedFoodGroups {
        ...FullFoodGroup
      }
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type RemoveAthleteLikedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables>;
export type RemoveAthleteLikedFoodGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables>, 'mutation'>;

    export const RemoveAthleteLikedFoodGroupComponent = (props: RemoveAthleteLikedFoodGroupComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables> mutation={RemoveAthleteLikedFoodGroupDocument} {...props} />
    );
    

/**
 * __useRemoveAthleteLikedFoodGroupMutation__
 *
 * To run a mutation, you first call `useRemoveAthleteLikedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAthleteLikedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAthleteLikedFoodGroupMutation, { data, loading, error }] = useRemoveAthleteLikedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAthleteLikedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables>(RemoveAthleteLikedFoodGroupDocument, baseOptions);
      }
export type RemoveAthleteLikedFoodGroupMutationHookResult = ReturnType<typeof useRemoveAthleteLikedFoodGroupMutation>;
export type RemoveAthleteLikedFoodGroupMutationResult = ApolloReactCommon.MutationResult<RemoveAthleteLikedFoodGroupMutation>;
export type RemoveAthleteLikedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables>;
export const RemoveMealOptionDocument = gql`
    mutation RemoveMealOption($input: RemoveMealOptionInput!) {
  removeMealOption(input: $input) {
    mealOptionId
  }
}
    `;
export type RemoveMealOptionMutationFn = ApolloReactCommon.MutationFunction<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>;
export type RemoveMealOptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>, 'mutation'>;

    export const RemoveMealOptionComponent = (props: RemoveMealOptionComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveMealOptionMutation, RemoveMealOptionMutationVariables> mutation={RemoveMealOptionDocument} {...props} />
    );
    

/**
 * __useRemoveMealOptionMutation__
 *
 * To run a mutation, you first call `useRemoveMealOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealOptionMutation, { data, loading, error }] = useRemoveMealOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMealOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>(RemoveMealOptionDocument, baseOptions);
      }
export type RemoveMealOptionMutationHookResult = ReturnType<typeof useRemoveMealOptionMutation>;
export type RemoveMealOptionMutationResult = ApolloReactCommon.MutationResult<RemoveMealOptionMutation>;
export type RemoveMealOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>;
export const RemoveMealOptionPendingDocument = gql`
    mutation RemoveMealOptionPending($input: RemoveMealOptionPendingInput!) {
  removeMealOptionPending(input: $input) {
    mealOptionPendingId
  }
}
    `;
export type RemoveMealOptionPendingMutationFn = ApolloReactCommon.MutationFunction<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>;
export type RemoveMealOptionPendingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>, 'mutation'>;

    export const RemoveMealOptionPendingComponent = (props: RemoveMealOptionPendingComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables> mutation={RemoveMealOptionPendingDocument} {...props} />
    );
    

/**
 * __useRemoveMealOptionPendingMutation__
 *
 * To run a mutation, you first call `useRemoveMealOptionPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealOptionPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealOptionPendingMutation, { data, loading, error }] = useRemoveMealOptionPendingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMealOptionPendingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>(RemoveMealOptionPendingDocument, baseOptions);
      }
export type RemoveMealOptionPendingMutationHookResult = ReturnType<typeof useRemoveMealOptionPendingMutation>;
export type RemoveMealOptionPendingMutationResult = ApolloReactCommon.MutationResult<RemoveMealOptionPendingMutation>;
export type RemoveMealOptionPendingMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>;
export const ShareMealOptionPendingDocument = gql`
    mutation ShareMealOptionPending($input: ShareMealOptionPendingInput!) {
  shareMealOptionPending(input: $input) {
    mealOption {
      ...FullMealOption
    }
  }
}
    ${FullMealOptionFragmentDoc}`;
export type ShareMealOptionPendingMutationFn = ApolloReactCommon.MutationFunction<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>;
export type ShareMealOptionPendingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>, 'mutation'>;

    export const ShareMealOptionPendingComponent = (props: ShareMealOptionPendingComponentProps) => (
      <ApolloReactComponents.Mutation<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables> mutation={ShareMealOptionPendingDocument} {...props} />
    );
    

/**
 * __useShareMealOptionPendingMutation__
 *
 * To run a mutation, you first call `useShareMealOptionPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareMealOptionPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareMealOptionPendingMutation, { data, loading, error }] = useShareMealOptionPendingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareMealOptionPendingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>) {
        return ApolloReactHooks.useMutation<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>(ShareMealOptionPendingDocument, baseOptions);
      }
export type ShareMealOptionPendingMutationHookResult = ReturnType<typeof useShareMealOptionPendingMutation>;
export type ShareMealOptionPendingMutationResult = ApolloReactCommon.MutationResult<ShareMealOptionPendingMutation>;
export type ShareMealOptionPendingMutationOptions = ApolloReactCommon.BaseMutationOptions<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>;
export const TextMealPlanToAthleteDocument = gql`
    mutation TextMealPlanToAthlete($input: TextMealPlanToAthleteInput!) {
  textMealPlanToAthlete(input: $input) {
    result
  }
}
    `;
export type TextMealPlanToAthleteMutationFn = ApolloReactCommon.MutationFunction<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables>;
export type TextMealPlanToAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables>, 'mutation'>;

    export const TextMealPlanToAthleteComponent = (props: TextMealPlanToAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables> mutation={TextMealPlanToAthleteDocument} {...props} />
    );
    

/**
 * __useTextMealPlanToAthleteMutation__
 *
 * To run a mutation, you first call `useTextMealPlanToAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTextMealPlanToAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [textMealPlanToAthleteMutation, { data, loading, error }] = useTextMealPlanToAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTextMealPlanToAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables>(TextMealPlanToAthleteDocument, baseOptions);
      }
export type TextMealPlanToAthleteMutationHookResult = ReturnType<typeof useTextMealPlanToAthleteMutation>;
export type TextMealPlanToAthleteMutationResult = ApolloReactCommon.MutationResult<TextMealPlanToAthleteMutation>;
export type TextMealPlanToAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables>;
export const TextMealPlansToAthletesDocument = gql`
    mutation TextMealPlansToAthletes($input: TextMealPlansToAthletesInput!) {
  textMealPlansToAthletes(input: $input) {
    result
  }
}
    `;
export type TextMealPlansToAthletesMutationFn = ApolloReactCommon.MutationFunction<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables>;
export type TextMealPlansToAthletesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables>, 'mutation'>;

    export const TextMealPlansToAthletesComponent = (props: TextMealPlansToAthletesComponentProps) => (
      <ApolloReactComponents.Mutation<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables> mutation={TextMealPlansToAthletesDocument} {...props} />
    );
    

/**
 * __useTextMealPlansToAthletesMutation__
 *
 * To run a mutation, you first call `useTextMealPlansToAthletesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTextMealPlansToAthletesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [textMealPlansToAthletesMutation, { data, loading, error }] = useTextMealPlansToAthletesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTextMealPlansToAthletesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables>) {
        return ApolloReactHooks.useMutation<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables>(TextMealPlansToAthletesDocument, baseOptions);
      }
export type TextMealPlansToAthletesMutationHookResult = ReturnType<typeof useTextMealPlansToAthletesMutation>;
export type TextMealPlansToAthletesMutationResult = ApolloReactCommon.MutationResult<TextMealPlansToAthletesMutation>;
export type TextMealPlansToAthletesMutationOptions = ApolloReactCommon.BaseMutationOptions<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables>;
export const UnarchiveAthleteDocument = gql`
    mutation UnarchiveAthlete($input: UnarchiveAthleteInput!) {
  unarchiveAthlete(input: $input) {
    athlete {
      ...AthleteWithGoalAndAnthro
      anthropometryEntries {
        ...AnthropometryEntry
      }
      orgMembership {
        id
        isActive
      }
    }
  }
}
    ${AthleteWithGoalAndAnthroFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export type UnarchiveAthleteMutationFn = ApolloReactCommon.MutationFunction<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables>;
export type UnarchiveAthleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables>, 'mutation'>;

    export const UnarchiveAthleteComponent = (props: UnarchiveAthleteComponentProps) => (
      <ApolloReactComponents.Mutation<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables> mutation={UnarchiveAthleteDocument} {...props} />
    );
    

/**
 * __useUnarchiveAthleteMutation__
 *
 * To run a mutation, you first call `useUnarchiveAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveAthleteMutation, { data, loading, error }] = useUnarchiveAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables>(UnarchiveAthleteDocument, baseOptions);
      }
export type UnarchiveAthleteMutationHookResult = ReturnType<typeof useUnarchiveAthleteMutation>;
export type UnarchiveAthleteMutationResult = ApolloReactCommon.MutationResult<UnarchiveAthleteMutation>;
export type UnarchiveAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables>;
export const AddMealOptionLocalDocument = gql`
    mutation AddMealOptionLocal($mealTemplateId: ID!, $type: String!) {
  addMealOptionLocal(mealTemplateId: $mealTemplateId, type: $type) @client
}
    `;
export type AddMealOptionLocalMutationFn = ApolloReactCommon.MutationFunction<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables>;
export type AddMealOptionLocalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables>, 'mutation'>;

    export const AddMealOptionLocalComponent = (props: AddMealOptionLocalComponentProps) => (
      <ApolloReactComponents.Mutation<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables> mutation={AddMealOptionLocalDocument} {...props} />
    );
    

/**
 * __useAddMealOptionLocalMutation__
 *
 * To run a mutation, you first call `useAddMealOptionLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMealOptionLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMealOptionLocalMutation, { data, loading, error }] = useAddMealOptionLocalMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddMealOptionLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables>(AddMealOptionLocalDocument, baseOptions);
      }
export type AddMealOptionLocalMutationHookResult = ReturnType<typeof useAddMealOptionLocalMutation>;
export type AddMealOptionLocalMutationResult = ApolloReactCommon.MutationResult<AddMealOptionLocalMutation>;
export type AddMealOptionLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables>;
export const CalculateExchangeTotalsDocument = gql`
    mutation CalculateExchangeTotals($mealPlanId: ID!) {
  calculateExchangeTotals(mealPlanId: $mealPlanId) @client
}
    `;
export type CalculateExchangeTotalsMutationFn = ApolloReactCommon.MutationFunction<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables>;
export type CalculateExchangeTotalsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables>, 'mutation'>;

    export const CalculateExchangeTotalsComponent = (props: CalculateExchangeTotalsComponentProps) => (
      <ApolloReactComponents.Mutation<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables> mutation={CalculateExchangeTotalsDocument} {...props} />
    );
    

/**
 * __useCalculateExchangeTotalsMutation__
 *
 * To run a mutation, you first call `useCalculateExchangeTotalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateExchangeTotalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateExchangeTotalsMutation, { data, loading, error }] = useCalculateExchangeTotalsMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useCalculateExchangeTotalsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables>) {
        return ApolloReactHooks.useMutation<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables>(CalculateExchangeTotalsDocument, baseOptions);
      }
export type CalculateExchangeTotalsMutationHookResult = ReturnType<typeof useCalculateExchangeTotalsMutation>;
export type CalculateExchangeTotalsMutationResult = ApolloReactCommon.MutationResult<CalculateExchangeTotalsMutation>;
export type CalculateExchangeTotalsMutationOptions = ApolloReactCommon.BaseMutationOptions<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables>;
export const ChangeExchangeTargetDocument = gql`
    mutation ChangeExchangeTarget($mealTemplateId: ID!, $exchangeId: ID!, $target: String!, $pickListServingIds: [ID!]) {
  changeExchangeTarget(
    mealTemplateId: $mealTemplateId
    exchangeId: $exchangeId
    target: $target
    pickListServingIds: $pickListServingIds
  ) @client
}
    `;
export type ChangeExchangeTargetMutationFn = ApolloReactCommon.MutationFunction<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables>;
export type ChangeExchangeTargetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables>, 'mutation'>;

    export const ChangeExchangeTargetComponent = (props: ChangeExchangeTargetComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables> mutation={ChangeExchangeTargetDocument} {...props} />
    );
    

/**
 * __useChangeExchangeTargetMutation__
 *
 * To run a mutation, you first call `useChangeExchangeTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeExchangeTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeExchangeTargetMutation, { data, loading, error }] = useChangeExchangeTargetMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      exchangeId: // value for 'exchangeId'
 *      target: // value for 'target'
 *      pickListServingIds: // value for 'pickListServingIds'
 *   },
 * });
 */
export function useChangeExchangeTargetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables>(ChangeExchangeTargetDocument, baseOptions);
      }
export type ChangeExchangeTargetMutationHookResult = ReturnType<typeof useChangeExchangeTargetMutation>;
export type ChangeExchangeTargetMutationResult = ApolloReactCommon.MutationResult<ChangeExchangeTargetMutation>;
export type ChangeExchangeTargetMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables>;
export const ChangeExchangeTargetTotalDocument = gql`
    mutation ChangeExchangeTargetTotal($mealPlanId: ID!, $exchangeId: ID!, $target: String!) {
  changeExchangeTargetTotal(
    mealPlanId: $mealPlanId
    exchangeId: $exchangeId
    target: $target
  ) @client
}
    `;
export type ChangeExchangeTargetTotalMutationFn = ApolloReactCommon.MutationFunction<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables>;
export type ChangeExchangeTargetTotalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables>, 'mutation'>;

    export const ChangeExchangeTargetTotalComponent = (props: ChangeExchangeTargetTotalComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables> mutation={ChangeExchangeTargetTotalDocument} {...props} />
    );
    

/**
 * __useChangeExchangeTargetTotalMutation__
 *
 * To run a mutation, you first call `useChangeExchangeTargetTotalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeExchangeTargetTotalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeExchangeTargetTotalMutation, { data, loading, error }] = useChangeExchangeTargetTotalMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *      exchangeId: // value for 'exchangeId'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useChangeExchangeTargetTotalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables>(ChangeExchangeTargetTotalDocument, baseOptions);
      }
export type ChangeExchangeTargetTotalMutationHookResult = ReturnType<typeof useChangeExchangeTargetTotalMutation>;
export type ChangeExchangeTargetTotalMutationResult = ApolloReactCommon.MutationResult<ChangeExchangeTargetTotalMutation>;
export type ChangeExchangeTargetTotalMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables>;
export const ChangeMacroTargetDocument = gql`
    mutation ChangeMacroTarget($mealTemplateId: ID!, $macro: MacroType!, $target: String!) {
  changeMacroTarget(
    mealTemplateId: $mealTemplateId
    macro: $macro
    target: $target
  ) @client
}
    `;
export type ChangeMacroTargetMutationFn = ApolloReactCommon.MutationFunction<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables>;
export type ChangeMacroTargetComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables>, 'mutation'>;

    export const ChangeMacroTargetComponent = (props: ChangeMacroTargetComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables> mutation={ChangeMacroTargetDocument} {...props} />
    );
    

/**
 * __useChangeMacroTargetMutation__
 *
 * To run a mutation, you first call `useChangeMacroTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMacroTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMacroTargetMutation, { data, loading, error }] = useChangeMacroTargetMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      macro: // value for 'macro'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useChangeMacroTargetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables>(ChangeMacroTargetDocument, baseOptions);
      }
export type ChangeMacroTargetMutationHookResult = ReturnType<typeof useChangeMacroTargetMutation>;
export type ChangeMacroTargetMutationResult = ApolloReactCommon.MutationResult<ChangeMacroTargetMutation>;
export type ChangeMacroTargetMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables>;
export const DeselectNegativeFoodsDocument = gql`
    mutation DeselectNegativeFoods($mealPlanId: ID!) {
  deselectNegativeFoods(mealPlanId: $mealPlanId) @client
}
    `;
export type DeselectNegativeFoodsMutationFn = ApolloReactCommon.MutationFunction<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables>;
export type DeselectNegativeFoodsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables>, 'mutation'>;

    export const DeselectNegativeFoodsComponent = (props: DeselectNegativeFoodsComponentProps) => (
      <ApolloReactComponents.Mutation<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables> mutation={DeselectNegativeFoodsDocument} {...props} />
    );
    

/**
 * __useDeselectNegativeFoodsMutation__
 *
 * To run a mutation, you first call `useDeselectNegativeFoodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeselectNegativeFoodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deselectNegativeFoodsMutation, { data, loading, error }] = useDeselectNegativeFoodsMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useDeselectNegativeFoodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables>(DeselectNegativeFoodsDocument, baseOptions);
      }
export type DeselectNegativeFoodsMutationHookResult = ReturnType<typeof useDeselectNegativeFoodsMutation>;
export type DeselectNegativeFoodsMutationResult = ApolloReactCommon.MutationResult<DeselectNegativeFoodsMutation>;
export type DeselectNegativeFoodsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables>;
export const DistributeExchangeTargetsDocument = gql`
    mutation DistributeExchangeTargets($mealPlanId: ID!) {
  distributeExchangeTargets(mealPlanId: $mealPlanId) @client
}
    `;
export type DistributeExchangeTargetsMutationFn = ApolloReactCommon.MutationFunction<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables>;
export type DistributeExchangeTargetsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables>, 'mutation'>;

    export const DistributeExchangeTargetsComponent = (props: DistributeExchangeTargetsComponentProps) => (
      <ApolloReactComponents.Mutation<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables> mutation={DistributeExchangeTargetsDocument} {...props} />
    );
    

/**
 * __useDistributeExchangeTargetsMutation__
 *
 * To run a mutation, you first call `useDistributeExchangeTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistributeExchangeTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [distributeExchangeTargetsMutation, { data, loading, error }] = useDistributeExchangeTargetsMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useDistributeExchangeTargetsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables>) {
        return ApolloReactHooks.useMutation<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables>(DistributeExchangeTargetsDocument, baseOptions);
      }
export type DistributeExchangeTargetsMutationHookResult = ReturnType<typeof useDistributeExchangeTargetsMutation>;
export type DistributeExchangeTargetsMutationResult = ApolloReactCommon.MutationResult<DistributeExchangeTargetsMutation>;
export type DistributeExchangeTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables>;
export const DistributeMacroTargetsDocument = gql`
    mutation DistributeMacroTargets($mealPlanId: ID!) {
  distributeMacroTargets(mealPlanId: $mealPlanId) @client
}
    `;
export type DistributeMacroTargetsMutationFn = ApolloReactCommon.MutationFunction<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables>;
export type DistributeMacroTargetsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables>, 'mutation'>;

    export const DistributeMacroTargetsComponent = (props: DistributeMacroTargetsComponentProps) => (
      <ApolloReactComponents.Mutation<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables> mutation={DistributeMacroTargetsDocument} {...props} />
    );
    

/**
 * __useDistributeMacroTargetsMutation__
 *
 * To run a mutation, you first call `useDistributeMacroTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistributeMacroTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [distributeMacroTargetsMutation, { data, loading, error }] = useDistributeMacroTargetsMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useDistributeMacroTargetsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables>) {
        return ApolloReactHooks.useMutation<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables>(DistributeMacroTargetsDocument, baseOptions);
      }
export type DistributeMacroTargetsMutationHookResult = ReturnType<typeof useDistributeMacroTargetsMutation>;
export type DistributeMacroTargetsMutationResult = ApolloReactCommon.MutationResult<DistributeMacroTargetsMutation>;
export type DistributeMacroTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables>;
export const EditMealOptionLocalDocument = gql`
    mutation EditMealOptionLocal($input: EditMealOptionLocalInput!) {
  editMealOptionLocal(input: $input) @client
}
    `;
export type EditMealOptionLocalMutationFn = ApolloReactCommon.MutationFunction<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables>;
export type EditMealOptionLocalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables>, 'mutation'>;

    export const EditMealOptionLocalComponent = (props: EditMealOptionLocalComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables> mutation={EditMealOptionLocalDocument} {...props} />
    );
    

/**
 * __useEditMealOptionLocalMutation__
 *
 * To run a mutation, you first call `useEditMealOptionLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealOptionLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealOptionLocalMutation, { data, loading, error }] = useEditMealOptionLocalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealOptionLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables>(EditMealOptionLocalDocument, baseOptions);
      }
export type EditMealOptionLocalMutationHookResult = ReturnType<typeof useEditMealOptionLocalMutation>;
export type EditMealOptionLocalMutationResult = ApolloReactCommon.MutationResult<EditMealOptionLocalMutation>;
export type EditMealOptionLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables>;
export const EditMealOptionPendingLocalDocument = gql`
    mutation EditMealOptionPendingLocal($input: EditMealOptionPendingLocalInput!) {
  editMealOptionPendingLocal(input: $input) @client
}
    `;
export type EditMealOptionPendingLocalMutationFn = ApolloReactCommon.MutationFunction<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables>;
export type EditMealOptionPendingLocalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables>, 'mutation'>;

    export const EditMealOptionPendingLocalComponent = (props: EditMealOptionPendingLocalComponentProps) => (
      <ApolloReactComponents.Mutation<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables> mutation={EditMealOptionPendingLocalDocument} {...props} />
    );
    

/**
 * __useEditMealOptionPendingLocalMutation__
 *
 * To run a mutation, you first call `useEditMealOptionPendingLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealOptionPendingLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealOptionPendingLocalMutation, { data, loading, error }] = useEditMealOptionPendingLocalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealOptionPendingLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables>(EditMealOptionPendingLocalDocument, baseOptions);
      }
export type EditMealOptionPendingLocalMutationHookResult = ReturnType<typeof useEditMealOptionPendingLocalMutation>;
export type EditMealOptionPendingLocalMutationResult = ApolloReactCommon.MutationResult<EditMealOptionPendingLocalMutation>;
export type EditMealOptionPendingLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables>;
export const RemoveMealOptionLocalDocument = gql`
    mutation RemoveMealOptionLocal($mealTemplateId: ID!, $type: String!, $mealOptionId: ID!) {
  removeMealOptionLocal(
    mealTemplateId: $mealTemplateId
    type: $type
    mealOptionId: $mealOptionId
  ) @client
}
    `;
export type RemoveMealOptionLocalMutationFn = ApolloReactCommon.MutationFunction<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables>;
export type RemoveMealOptionLocalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables>, 'mutation'>;

    export const RemoveMealOptionLocalComponent = (props: RemoveMealOptionLocalComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables> mutation={RemoveMealOptionLocalDocument} {...props} />
    );
    

/**
 * __useRemoveMealOptionLocalMutation__
 *
 * To run a mutation, you first call `useRemoveMealOptionLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealOptionLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealOptionLocalMutation, { data, loading, error }] = useRemoveMealOptionLocalMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      type: // value for 'type'
 *      mealOptionId: // value for 'mealOptionId'
 *   },
 * });
 */
export function useRemoveMealOptionLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables>(RemoveMealOptionLocalDocument, baseOptions);
      }
export type RemoveMealOptionLocalMutationHookResult = ReturnType<typeof useRemoveMealOptionLocalMutation>;
export type RemoveMealOptionLocalMutationResult = ApolloReactCommon.MutationResult<RemoveMealOptionLocalMutation>;
export type RemoveMealOptionLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables>;
export const RemoveMealOptionPendingLocalDocument = gql`
    mutation RemoveMealOptionPendingLocal($mealTemplateId: ID!, $type: String!, $mealOptionPendingId: ID!) {
  removeMealOptionPendingLocal(
    mealTemplateId: $mealTemplateId
    type: $type
    mealOptionPendingId: $mealOptionPendingId
  ) @client
}
    `;
export type RemoveMealOptionPendingLocalMutationFn = ApolloReactCommon.MutationFunction<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables>;
export type RemoveMealOptionPendingLocalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables>, 'mutation'>;

    export const RemoveMealOptionPendingLocalComponent = (props: RemoveMealOptionPendingLocalComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables> mutation={RemoveMealOptionPendingLocalDocument} {...props} />
    );
    

/**
 * __useRemoveMealOptionPendingLocalMutation__
 *
 * To run a mutation, you first call `useRemoveMealOptionPendingLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealOptionPendingLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealOptionPendingLocalMutation, { data, loading, error }] = useRemoveMealOptionPendingLocalMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      type: // value for 'type'
 *      mealOptionPendingId: // value for 'mealOptionPendingId'
 *   },
 * });
 */
export function useRemoveMealOptionPendingLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables>(RemoveMealOptionPendingLocalDocument, baseOptions);
      }
export type RemoveMealOptionPendingLocalMutationHookResult = ReturnType<typeof useRemoveMealOptionPendingLocalMutation>;
export type RemoveMealOptionPendingLocalMutationResult = ApolloReactCommon.MutationResult<RemoveMealOptionPendingLocalMutation>;
export type RemoveMealOptionPendingLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables>;
export const SelectMealOptionDocument = gql`
    mutation SelectMealOption($mealTemplateId: ID!, $type: String!, $mealOptionId: ID!) {
  selectMealOption(
    mealTemplateId: $mealTemplateId
    type: $type
    mealOptionId: $mealOptionId
  ) @client
}
    `;
export type SelectMealOptionMutationFn = ApolloReactCommon.MutationFunction<SelectMealOptionMutation, SelectMealOptionMutationVariables>;
export type SelectMealOptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SelectMealOptionMutation, SelectMealOptionMutationVariables>, 'mutation'>;

    export const SelectMealOptionComponent = (props: SelectMealOptionComponentProps) => (
      <ApolloReactComponents.Mutation<SelectMealOptionMutation, SelectMealOptionMutationVariables> mutation={SelectMealOptionDocument} {...props} />
    );
    

/**
 * __useSelectMealOptionMutation__
 *
 * To run a mutation, you first call `useSelectMealOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectMealOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectMealOptionMutation, { data, loading, error }] = useSelectMealOptionMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      type: // value for 'type'
 *      mealOptionId: // value for 'mealOptionId'
 *   },
 * });
 */
export function useSelectMealOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SelectMealOptionMutation, SelectMealOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<SelectMealOptionMutation, SelectMealOptionMutationVariables>(SelectMealOptionDocument, baseOptions);
      }
export type SelectMealOptionMutationHookResult = ReturnType<typeof useSelectMealOptionMutation>;
export type SelectMealOptionMutationResult = ApolloReactCommon.MutationResult<SelectMealOptionMutation>;
export type SelectMealOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<SelectMealOptionMutation, SelectMealOptionMutationVariables>;
export const ShareMealOptionPendingLocalDocument = gql`
    mutation ShareMealOptionPendingLocal($mealTemplateId: ID!, $mealOptionPendingId: ID!) {
  shareMealOptionPendingLocal(
    mealTemplateId: $mealTemplateId
    mealOptionPendingId: $mealOptionPendingId
  ) @client
}
    `;
export type ShareMealOptionPendingLocalMutationFn = ApolloReactCommon.MutationFunction<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables>;
export type ShareMealOptionPendingLocalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables>, 'mutation'>;

    export const ShareMealOptionPendingLocalComponent = (props: ShareMealOptionPendingLocalComponentProps) => (
      <ApolloReactComponents.Mutation<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables> mutation={ShareMealOptionPendingLocalDocument} {...props} />
    );
    

/**
 * __useShareMealOptionPendingLocalMutation__
 *
 * To run a mutation, you first call `useShareMealOptionPendingLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareMealOptionPendingLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareMealOptionPendingLocalMutation, { data, loading, error }] = useShareMealOptionPendingLocalMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      mealOptionPendingId: // value for 'mealOptionPendingId'
 *   },
 * });
 */
export function useShareMealOptionPendingLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables>(ShareMealOptionPendingLocalDocument, baseOptions);
      }
export type ShareMealOptionPendingLocalMutationHookResult = ReturnType<typeof useShareMealOptionPendingLocalMutation>;
export type ShareMealOptionPendingLocalMutationResult = ApolloReactCommon.MutationResult<ShareMealOptionPendingLocalMutation>;
export type ShareMealOptionPendingLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables>;
export const AllAthletesDocument = gql`
    query AllAthletes {
  athletes {
    ...AthleteWithAnthro
    team {
      ...TeamWithSport
    }
    position {
      ...Position
    }
  }
}
    ${AthleteWithAnthroFragmentDoc}
${TeamWithSportFragmentDoc}
${PositionFragmentDoc}`;
export type AllAthletesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllAthletesQuery, AllAthletesQueryVariables>, 'query'>;

    export const AllAthletesComponent = (props: AllAthletesComponentProps) => (
      <ApolloReactComponents.Query<AllAthletesQuery, AllAthletesQueryVariables> query={AllAthletesDocument} {...props} />
    );
    

/**
 * __useAllAthletesQuery__
 *
 * To run a query within a React component, call `useAllAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAthletesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAthletesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllAthletesQuery, AllAthletesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllAthletesQuery, AllAthletesQueryVariables>(AllAthletesDocument, baseOptions);
      }
export function useAllAthletesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllAthletesQuery, AllAthletesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllAthletesQuery, AllAthletesQueryVariables>(AllAthletesDocument, baseOptions);
        }
export type AllAthletesQueryHookResult = ReturnType<typeof useAllAthletesQuery>;
export type AllAthletesLazyQueryHookResult = ReturnType<typeof useAllAthletesLazyQuery>;
export type AllAthletesQueryResult = ApolloReactCommon.QueryResult<AllAthletesQuery, AllAthletesQueryVariables>;
export const AllAthletesAndAnthropometryEntriesDocument = gql`
    query AllAthletesAndAnthropometryEntries {
  athletes {
    ...Athlete
    mostRecentAnthropometryEntry {
      id
      height
    }
    team {
      ...TeamWithSport
    }
    position {
      ...Position
    }
    anthropometryEntries {
      ...AnthropometryEntry
    }
  }
}
    ${AthleteFragmentDoc}
${TeamWithSportFragmentDoc}
${PositionFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export type AllAthletesAndAnthropometryEntriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>, 'query'>;

    export const AllAthletesAndAnthropometryEntriesComponent = (props: AllAthletesAndAnthropometryEntriesComponentProps) => (
      <ApolloReactComponents.Query<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables> query={AllAthletesAndAnthropometryEntriesDocument} {...props} />
    );
    

/**
 * __useAllAthletesAndAnthropometryEntriesQuery__
 *
 * To run a query within a React component, call `useAllAthletesAndAnthropometryEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAthletesAndAnthropometryEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAthletesAndAnthropometryEntriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAthletesAndAnthropometryEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>(AllAthletesAndAnthropometryEntriesDocument, baseOptions);
      }
export function useAllAthletesAndAnthropometryEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>(AllAthletesAndAnthropometryEntriesDocument, baseOptions);
        }
export type AllAthletesAndAnthropometryEntriesQueryHookResult = ReturnType<typeof useAllAthletesAndAnthropometryEntriesQuery>;
export type AllAthletesAndAnthropometryEntriesLazyQueryHookResult = ReturnType<typeof useAllAthletesAndAnthropometryEntriesLazyQuery>;
export type AllAthletesAndAnthropometryEntriesQueryResult = ApolloReactCommon.QueryResult<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>;
export const AthleteAnthropometryDocument = gql`
    query AthleteAnthropometry($id: ID!) {
  athlete(id: $id) {
    ...Athlete
    anthropometryEntries {
      ...AnthropometryEntry
    }
  }
}
    ${AthleteFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export type AthleteAnthropometryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>, 'query'> & ({ variables: AthleteAnthropometryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteAnthropometryComponent = (props: AthleteAnthropometryComponentProps) => (
      <ApolloReactComponents.Query<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables> query={AthleteAnthropometryDocument} {...props} />
    );
    

/**
 * __useAthleteAnthropometryQuery__
 *
 * To run a query within a React component, call `useAthleteAnthropometryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteAnthropometryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteAnthropometryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteAnthropometryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>(AthleteAnthropometryDocument, baseOptions);
      }
export function useAthleteAnthropometryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>(AthleteAnthropometryDocument, baseOptions);
        }
export type AthleteAnthropometryQueryHookResult = ReturnType<typeof useAthleteAnthropometryQuery>;
export type AthleteAnthropometryLazyQueryHookResult = ReturnType<typeof useAthleteAnthropometryLazyQuery>;
export type AthleteAnthropometryQueryResult = ApolloReactCommon.QueryResult<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>;
export const AthleteNotesDocument = gql`
    query AthleteNotes($id: ID!) {
  athlete(id: $id) {
    ...Athlete
    notes {
      ...NoteWithType
    }
  }
}
    ${AthleteFragmentDoc}
${NoteWithTypeFragmentDoc}`;
export type AthleteNotesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteNotesQuery, AthleteNotesQueryVariables>, 'query'> & ({ variables: AthleteNotesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteNotesComponent = (props: AthleteNotesComponentProps) => (
      <ApolloReactComponents.Query<AthleteNotesQuery, AthleteNotesQueryVariables> query={AthleteNotesDocument} {...props} />
    );
    

/**
 * __useAthleteNotesQuery__
 *
 * To run a query within a React component, call `useAthleteNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteNotesQuery, AthleteNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteNotesQuery, AthleteNotesQueryVariables>(AthleteNotesDocument, baseOptions);
      }
export function useAthleteNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteNotesQuery, AthleteNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteNotesQuery, AthleteNotesQueryVariables>(AthleteNotesDocument, baseOptions);
        }
export type AthleteNotesQueryHookResult = ReturnType<typeof useAthleteNotesQuery>;
export type AthleteNotesLazyQueryHookResult = ReturnType<typeof useAthleteNotesLazyQuery>;
export type AthleteNotesQueryResult = ApolloReactCommon.QueryResult<AthleteNotesQuery, AthleteNotesQueryVariables>;
export const AthletesMealPlansDocument = gql`
    query AthletesMealPlans {
  athletes {
    ...AthleteWithMealPlans
  }
}
    ${AthleteWithMealPlansFragmentDoc}`;
export type AthletesMealPlansComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>, 'query'>;

    export const AthletesMealPlansComponent = (props: AthletesMealPlansComponentProps) => (
      <ApolloReactComponents.Query<AthletesMealPlansQuery, AthletesMealPlansQueryVariables> query={AthletesMealPlansDocument} {...props} />
    );
    

/**
 * __useAthletesMealPlansQuery__
 *
 * To run a query within a React component, call `useAthletesMealPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesMealPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesMealPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useAthletesMealPlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>(AthletesMealPlansDocument, baseOptions);
      }
export function useAthletesMealPlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>(AthletesMealPlansDocument, baseOptions);
        }
export type AthletesMealPlansQueryHookResult = ReturnType<typeof useAthletesMealPlansQuery>;
export type AthletesMealPlansLazyQueryHookResult = ReturnType<typeof useAthletesMealPlansLazyQuery>;
export type AthletesMealPlansQueryResult = ApolloReactCommon.QueryResult<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>;
export const MealPlanForCopyCursorConnectionDocument = gql`
    query MealPlanForCopyCursorConnection($pagination: CursorPaginationInput!, $athleteName: String, $mealPlanName: String) {
  mealPlanForCopyCursorConnection(
    input: $pagination
    athleteName: $athleteName
    mealPlanName: $mealPlanName
  ) {
    edges {
      ...MealPlanWithAthlete
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${MealPlanWithAthleteFragmentDoc}`;
export type MealPlanForCopyCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>, 'query'> & ({ variables: MealPlanForCopyCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanForCopyCursorConnectionComponent = (props: MealPlanForCopyCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables> query={MealPlanForCopyCursorConnectionDocument} {...props} />
    );
    

/**
 * __useMealPlanForCopyCursorConnectionQuery__
 *
 * To run a query within a React component, call `useMealPlanForCopyCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanForCopyCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanForCopyCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      athleteName: // value for 'athleteName'
 *      mealPlanName: // value for 'mealPlanName'
 *   },
 * });
 */
export function useMealPlanForCopyCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>(MealPlanForCopyCursorConnectionDocument, baseOptions);
      }
export function useMealPlanForCopyCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>(MealPlanForCopyCursorConnectionDocument, baseOptions);
        }
export type MealPlanForCopyCursorConnectionQueryHookResult = ReturnType<typeof useMealPlanForCopyCursorConnectionQuery>;
export type MealPlanForCopyCursorConnectionLazyQueryHookResult = ReturnType<typeof useMealPlanForCopyCursorConnectionLazyQuery>;
export type MealPlanForCopyCursorConnectionQueryResult = ApolloReactCommon.QueryResult<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>;
export const AthletesPreviewDocument = gql`
    query AthletesPreview {
  athletes {
    ...Athlete
  }
}
    ${AthleteFragmentDoc}`;
export type AthletesPreviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthletesPreviewQuery, AthletesPreviewQueryVariables>, 'query'>;

    export const AthletesPreviewComponent = (props: AthletesPreviewComponentProps) => (
      <ApolloReactComponents.Query<AthletesPreviewQuery, AthletesPreviewQueryVariables> query={AthletesPreviewDocument} {...props} />
    );
    

/**
 * __useAthletesPreviewQuery__
 *
 * To run a query within a React component, call `useAthletesPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesPreviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useAthletesPreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesPreviewQuery, AthletesPreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesPreviewQuery, AthletesPreviewQueryVariables>(AthletesPreviewDocument, baseOptions);
      }
export function useAthletesPreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesPreviewQuery, AthletesPreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesPreviewQuery, AthletesPreviewQueryVariables>(AthletesPreviewDocument, baseOptions);
        }
export type AthletesPreviewQueryHookResult = ReturnType<typeof useAthletesPreviewQuery>;
export type AthletesPreviewLazyQueryHookResult = ReturnType<typeof useAthletesPreviewLazyQuery>;
export type AthletesPreviewQueryResult = ApolloReactCommon.QueryResult<AthletesPreviewQuery, AthletesPreviewQueryVariables>;
export const AthleteSearchDocument = gql`
    query AthleteSearch($query: String!, $limit: Int) {
  athleteSearch(query: $query, limit: $limit) {
    ...Athlete
  }
}
    ${AthleteFragmentDoc}`;
export type AthleteSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AthleteSearchQuery, AthleteSearchQueryVariables>, 'query'> & ({ variables: AthleteSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AthleteSearchComponent = (props: AthleteSearchComponentProps) => (
      <ApolloReactComponents.Query<AthleteSearchQuery, AthleteSearchQueryVariables> query={AthleteSearchDocument} {...props} />
    );
    

/**
 * __useAthleteSearchQuery__
 *
 * To run a query within a React component, call `useAthleteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAthleteSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteSearchQuery, AthleteSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteSearchQuery, AthleteSearchQueryVariables>(AthleteSearchDocument, baseOptions);
      }
export function useAthleteSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteSearchQuery, AthleteSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteSearchQuery, AthleteSearchQueryVariables>(AthleteSearchDocument, baseOptions);
        }
export type AthleteSearchQueryHookResult = ReturnType<typeof useAthleteSearchQuery>;
export type AthleteSearchLazyQueryHookResult = ReturnType<typeof useAthleteSearchLazyQuery>;
export type AthleteSearchQueryResult = ApolloReactCommon.QueryResult<AthleteSearchQuery, AthleteSearchQueryVariables>;
export const TeamAthletesSelectFieldDocument = gql`
    query TeamAthletesSelectField($teamId: ID!) {
  team(id: $teamId) {
    athletes {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type TeamAthletesSelectFieldComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>, 'query'> & ({ variables: TeamAthletesSelectFieldQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamAthletesSelectFieldComponent = (props: TeamAthletesSelectFieldComponentProps) => (
      <ApolloReactComponents.Query<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables> query={TeamAthletesSelectFieldDocument} {...props} />
    );
    

/**
 * __useTeamAthletesSelectFieldQuery__
 *
 * To run a query within a React component, call `useTeamAthletesSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamAthletesSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamAthletesSelectFieldQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamAthletesSelectFieldQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>(TeamAthletesSelectFieldDocument, baseOptions);
      }
export function useTeamAthletesSelectFieldLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>(TeamAthletesSelectFieldDocument, baseOptions);
        }
export type TeamAthletesSelectFieldQueryHookResult = ReturnType<typeof useTeamAthletesSelectFieldQuery>;
export type TeamAthletesSelectFieldLazyQueryHookResult = ReturnType<typeof useTeamAthletesSelectFieldLazyQuery>;
export type TeamAthletesSelectFieldQueryResult = ApolloReactCommon.QueryResult<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>;
export const BrandsDocument = gql`
    query Brands($searchTerm: String!, $limit: Int) {
  brands(searchTerm: $searchTerm, limit: $limit) {
    ...Brand
  }
}
    ${BrandFragmentDoc}`;
export type BrandsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BrandsQuery, BrandsQueryVariables>, 'query'> & ({ variables: BrandsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BrandsComponent = (props: BrandsComponentProps) => (
      <ApolloReactComponents.Query<BrandsQuery, BrandsQueryVariables> query={BrandsDocument} {...props} />
    );
    

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBrandsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
        return ApolloReactHooks.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
      }
export function useBrandsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
        }
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = ApolloReactCommon.QueryResult<BrandsQuery, BrandsQueryVariables>;
export const GetEditMealPlanDocument = gql`
    query GetEditMealPlan($mealPlanId: ID!) {
  mealPlan(id: $mealPlanId) {
    ...EditFullMealPlan
  }
}
    ${EditFullMealPlanFragmentDoc}`;
export type GetEditMealPlanComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>, 'query'> & ({ variables: GetEditMealPlanQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEditMealPlanComponent = (props: GetEditMealPlanComponentProps) => (
      <ApolloReactComponents.Query<GetEditMealPlanQuery, GetEditMealPlanQueryVariables> query={GetEditMealPlanDocument} {...props} />
    );
    

/**
 * __useGetEditMealPlanQuery__
 *
 * To run a query within a React component, call `useGetEditMealPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditMealPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditMealPlanQuery({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useGetEditMealPlanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>(GetEditMealPlanDocument, baseOptions);
      }
export function useGetEditMealPlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>(GetEditMealPlanDocument, baseOptions);
        }
export type GetEditMealPlanQueryHookResult = ReturnType<typeof useGetEditMealPlanQuery>;
export type GetEditMealPlanLazyQueryHookResult = ReturnType<typeof useGetEditMealPlanLazyQuery>;
export type GetEditMealPlanQueryResult = ApolloReactCommon.QueryResult<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>;
export const ExchangeSetDocument = gql`
    query ExchangeSet($id: ID!) {
  exchangeSet(id: $id) {
    ...FullExchangeSet
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type ExchangeSetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExchangeSetQuery, ExchangeSetQueryVariables>, 'query'> & ({ variables: ExchangeSetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExchangeSetComponent = (props: ExchangeSetComponentProps) => (
      <ApolloReactComponents.Query<ExchangeSetQuery, ExchangeSetQueryVariables> query={ExchangeSetDocument} {...props} />
    );
    

/**
 * __useExchangeSetQuery__
 *
 * To run a query within a React component, call `useExchangeSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeSetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExchangeSetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExchangeSetQuery, ExchangeSetQueryVariables>) {
        return ApolloReactHooks.useQuery<ExchangeSetQuery, ExchangeSetQueryVariables>(ExchangeSetDocument, baseOptions);
      }
export function useExchangeSetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExchangeSetQuery, ExchangeSetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExchangeSetQuery, ExchangeSetQueryVariables>(ExchangeSetDocument, baseOptions);
        }
export type ExchangeSetQueryHookResult = ReturnType<typeof useExchangeSetQuery>;
export type ExchangeSetLazyQueryHookResult = ReturnType<typeof useExchangeSetLazyQuery>;
export type ExchangeSetQueryResult = ApolloReactCommon.QueryResult<ExchangeSetQuery, ExchangeSetQueryVariables>;
export const ExchangeSetsDocument = gql`
    query ExchangeSets {
  exchangeSets {
    ...ExchangeSetWithExchanges
  }
}
    ${ExchangeSetWithExchangesFragmentDoc}`;
export type ExchangeSetsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExchangeSetsQuery, ExchangeSetsQueryVariables>, 'query'>;

    export const ExchangeSetsComponent = (props: ExchangeSetsComponentProps) => (
      <ApolloReactComponents.Query<ExchangeSetsQuery, ExchangeSetsQueryVariables> query={ExchangeSetsDocument} {...props} />
    );
    

/**
 * __useExchangeSetsQuery__
 *
 * To run a query within a React component, call `useExchangeSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeSetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExchangeSetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExchangeSetsQuery, ExchangeSetsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExchangeSetsQuery, ExchangeSetsQueryVariables>(ExchangeSetsDocument, baseOptions);
      }
export function useExchangeSetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExchangeSetsQuery, ExchangeSetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExchangeSetsQuery, ExchangeSetsQueryVariables>(ExchangeSetsDocument, baseOptions);
        }
export type ExchangeSetsQueryHookResult = ReturnType<typeof useExchangeSetsQuery>;
export type ExchangeSetsLazyQueryHookResult = ReturnType<typeof useExchangeSetsLazyQuery>;
export type ExchangeSetsQueryResult = ApolloReactCommon.QueryResult<ExchangeSetsQuery, ExchangeSetsQueryVariables>;
export const FoodGroupDocument = gql`
    query FoodGroup($id: ID!) {
  foodGroup(id: $id) {
    ...FullFoodGroupWithFoods
  }
}
    ${FullFoodGroupWithFoodsFragmentDoc}`;
export type FoodGroupComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FoodGroupQuery, FoodGroupQueryVariables>, 'query'> & ({ variables: FoodGroupQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FoodGroupComponent = (props: FoodGroupComponentProps) => (
      <ApolloReactComponents.Query<FoodGroupQuery, FoodGroupQueryVariables> query={FoodGroupDocument} {...props} />
    );
    

/**
 * __useFoodGroupQuery__
 *
 * To run a query within a React component, call `useFoodGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoodGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodGroupQuery, FoodGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodGroupQuery, FoodGroupQueryVariables>(FoodGroupDocument, baseOptions);
      }
export function useFoodGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodGroupQuery, FoodGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodGroupQuery, FoodGroupQueryVariables>(FoodGroupDocument, baseOptions);
        }
export type FoodGroupQueryHookResult = ReturnType<typeof useFoodGroupQuery>;
export type FoodGroupLazyQueryHookResult = ReturnType<typeof useFoodGroupLazyQuery>;
export type FoodGroupQueryResult = ApolloReactCommon.QueryResult<FoodGroupQuery, FoodGroupQueryVariables>;
export const FoodGroupsDocument = gql`
    query FoodGroups($searchTerm: String!, $limit: Int) {
  foodGroups(searchTerm: $searchTerm, limit: $limit) {
    ...SimpleFoodGroup
  }
}
    ${SimpleFoodGroupFragmentDoc}`;
export type FoodGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FoodGroupsQuery, FoodGroupsQueryVariables>, 'query'> & ({ variables: FoodGroupsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FoodGroupsComponent = (props: FoodGroupsComponentProps) => (
      <ApolloReactComponents.Query<FoodGroupsQuery, FoodGroupsQueryVariables> query={FoodGroupsDocument} {...props} />
    );
    

/**
 * __useFoodGroupsQuery__
 *
 * To run a query within a React component, call `useFoodGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodGroupsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFoodGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodGroupsQuery, FoodGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodGroupsQuery, FoodGroupsQueryVariables>(FoodGroupsDocument, baseOptions);
      }
export function useFoodGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodGroupsQuery, FoodGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodGroupsQuery, FoodGroupsQueryVariables>(FoodGroupsDocument, baseOptions);
        }
export type FoodGroupsQueryHookResult = ReturnType<typeof useFoodGroupsQuery>;
export type FoodGroupsLazyQueryHookResult = ReturnType<typeof useFoodGroupsLazyQuery>;
export type FoodGroupsQueryResult = ApolloReactCommon.QueryResult<FoodGroupsQuery, FoodGroupsQueryVariables>;
export const SharedFoodGroupsTableDocument = gql`
    query SharedFoodGroupsTable($query: String, $input: OffsetPaginationInput!, $sortOrder: SortOrder!) {
  sharedFoodGroupsOffsetConnection(
    query: $query
    input: $input
    sortOrder: $sortOrder
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type SharedFoodGroupsTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>, 'query'> & ({ variables: SharedFoodGroupsTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SharedFoodGroupsTableComponent = (props: SharedFoodGroupsTableComponentProps) => (
      <ApolloReactComponents.Query<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables> query={SharedFoodGroupsTableDocument} {...props} />
    );
    

/**
 * __useSharedFoodGroupsTableQuery__
 *
 * To run a query within a React component, call `useSharedFoodGroupsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedFoodGroupsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedFoodGroupsTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      input: // value for 'input'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSharedFoodGroupsTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>(SharedFoodGroupsTableDocument, baseOptions);
      }
export function useSharedFoodGroupsTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>(SharedFoodGroupsTableDocument, baseOptions);
        }
export type SharedFoodGroupsTableQueryHookResult = ReturnType<typeof useSharedFoodGroupsTableQuery>;
export type SharedFoodGroupsTableLazyQueryHookResult = ReturnType<typeof useSharedFoodGroupsTableLazyQuery>;
export type SharedFoodGroupsTableQueryResult = ApolloReactCommon.QueryResult<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>;
export const OrgFoodGroupsTableDocument = gql`
    query OrgFoodGroupsTable($query: String, $input: OffsetPaginationInput!, $sortOrder: SortOrder!) {
  orgFoodGroupsOffsetConnection(
    query: $query
    input: $input
    sortOrder: $sortOrder
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type OrgFoodGroupsTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>, 'query'> & ({ variables: OrgFoodGroupsTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgFoodGroupsTableComponent = (props: OrgFoodGroupsTableComponentProps) => (
      <ApolloReactComponents.Query<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables> query={OrgFoodGroupsTableDocument} {...props} />
    );
    

/**
 * __useOrgFoodGroupsTableQuery__
 *
 * To run a query within a React component, call `useOrgFoodGroupsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgFoodGroupsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgFoodGroupsTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      input: // value for 'input'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useOrgFoodGroupsTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>(OrgFoodGroupsTableDocument, baseOptions);
      }
export function useOrgFoodGroupsTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>(OrgFoodGroupsTableDocument, baseOptions);
        }
export type OrgFoodGroupsTableQueryHookResult = ReturnType<typeof useOrgFoodGroupsTableQuery>;
export type OrgFoodGroupsTableLazyQueryHookResult = ReturnType<typeof useOrgFoodGroupsTableLazyQuery>;
export type OrgFoodGroupsTableQueryResult = ApolloReactCommon.QueryResult<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>;
export const SharedFoodGroupsCursorConnectionDocument = gql`
    query SharedFoodGroupsCursorConnection($searchText: String!, $pagination: CursorPaginationInput!) {
  sharedFoodGroupsCursorConnection(
    searchText: $searchText
    pagination: $pagination
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...SimpleFoodGroup
    }
  }
}
    ${SimpleFoodGroupFragmentDoc}`;
export type SharedFoodGroupsCursorConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>, 'query'> & ({ variables: SharedFoodGroupsCursorConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SharedFoodGroupsCursorConnectionComponent = (props: SharedFoodGroupsCursorConnectionComponentProps) => (
      <ApolloReactComponents.Query<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables> query={SharedFoodGroupsCursorConnectionDocument} {...props} />
    );
    

/**
 * __useSharedFoodGroupsCursorConnectionQuery__
 *
 * To run a query within a React component, call `useSharedFoodGroupsCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedFoodGroupsCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedFoodGroupsCursorConnectionQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSharedFoodGroupsCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>(SharedFoodGroupsCursorConnectionDocument, baseOptions);
      }
export function useSharedFoodGroupsCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>(SharedFoodGroupsCursorConnectionDocument, baseOptions);
        }
export type SharedFoodGroupsCursorConnectionQueryHookResult = ReturnType<typeof useSharedFoodGroupsCursorConnectionQuery>;
export type SharedFoodGroupsCursorConnectionLazyQueryHookResult = ReturnType<typeof useSharedFoodGroupsCursorConnectionLazyQuery>;
export type SharedFoodGroupsCursorConnectionQueryResult = ApolloReactCommon.QueryResult<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>;
export const FoodOrRecipeDocument = gql`
    query FoodOrRecipe($id: ID!) {
  foodOrRecipe(id: $id) {
    ... on Food {
      ...FoodWithServings
    }
    ... on Recipe {
      ...RecipeWithServings
    }
  }
}
    ${FoodWithServingsFragmentDoc}
${RecipeWithServingsFragmentDoc}`;
export type FoodOrRecipeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>, 'query'> & ({ variables: FoodOrRecipeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FoodOrRecipeComponent = (props: FoodOrRecipeComponentProps) => (
      <ApolloReactComponents.Query<FoodOrRecipeQuery, FoodOrRecipeQueryVariables> query={FoodOrRecipeDocument} {...props} />
    );
    

/**
 * __useFoodOrRecipeQuery__
 *
 * To run a query within a React component, call `useFoodOrRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodOrRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodOrRecipeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoodOrRecipeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>(FoodOrRecipeDocument, baseOptions);
      }
export function useFoodOrRecipeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>(FoodOrRecipeDocument, baseOptions);
        }
export type FoodOrRecipeQueryHookResult = ReturnType<typeof useFoodOrRecipeQuery>;
export type FoodOrRecipeLazyQueryHookResult = ReturnType<typeof useFoodOrRecipeLazyQuery>;
export type FoodOrRecipeQueryResult = ApolloReactCommon.QueryResult<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>;
export const FoodsWithDefaultServingOnlyDocument = gql`
    query FoodsWithDefaultServingOnly($searchTerm: String!, $limit: Int, $localeCodes: [String!], $dataSources: [FoodDataSource!]) {
  foods(
    searchTerm: $searchTerm
    limit: $limit
    localeCodes: $localeCodes
    dataSources: $dataSources
  ) {
    ...FoodWithDefaultServing
  }
}
    ${FoodWithDefaultServingFragmentDoc}`;
export type FoodsWithDefaultServingOnlyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>, 'query'> & ({ variables: FoodsWithDefaultServingOnlyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FoodsWithDefaultServingOnlyComponent = (props: FoodsWithDefaultServingOnlyComponentProps) => (
      <ApolloReactComponents.Query<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables> query={FoodsWithDefaultServingOnlyDocument} {...props} />
    );
    

/**
 * __useFoodsWithDefaultServingOnlyQuery__
 *
 * To run a query within a React component, call `useFoodsWithDefaultServingOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodsWithDefaultServingOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodsWithDefaultServingOnlyQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      localeCodes: // value for 'localeCodes'
 *      dataSources: // value for 'dataSources'
 *   },
 * });
 */
export function useFoodsWithDefaultServingOnlyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>(FoodsWithDefaultServingOnlyDocument, baseOptions);
      }
export function useFoodsWithDefaultServingOnlyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>(FoodsWithDefaultServingOnlyDocument, baseOptions);
        }
export type FoodsWithDefaultServingOnlyQueryHookResult = ReturnType<typeof useFoodsWithDefaultServingOnlyQuery>;
export type FoodsWithDefaultServingOnlyLazyQueryHookResult = ReturnType<typeof useFoodsWithDefaultServingOnlyLazyQuery>;
export type FoodsWithDefaultServingOnlyQueryResult = ApolloReactCommon.QueryResult<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>;
export const RestaurantFoodsWithDefaultServingOnlyDocument = gql`
    query RestaurantFoodsWithDefaultServingOnly($searchTerm: String!, $limit: Int, $restaurantId: ID!) {
  restaurantFoods(
    searchTerm: $searchTerm
    limit: $limit
    restaurantId: $restaurantId
  ) {
    ...FoodWithDefaultServing
  }
}
    ${FoodWithDefaultServingFragmentDoc}`;
export type RestaurantFoodsWithDefaultServingOnlyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>, 'query'> & ({ variables: RestaurantFoodsWithDefaultServingOnlyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RestaurantFoodsWithDefaultServingOnlyComponent = (props: RestaurantFoodsWithDefaultServingOnlyComponentProps) => (
      <ApolloReactComponents.Query<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables> query={RestaurantFoodsWithDefaultServingOnlyDocument} {...props} />
    );
    

/**
 * __useRestaurantFoodsWithDefaultServingOnlyQuery__
 *
 * To run a query within a React component, call `useRestaurantFoodsWithDefaultServingOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantFoodsWithDefaultServingOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantFoodsWithDefaultServingOnlyQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useRestaurantFoodsWithDefaultServingOnlyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>(RestaurantFoodsWithDefaultServingOnlyDocument, baseOptions);
      }
export function useRestaurantFoodsWithDefaultServingOnlyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>(RestaurantFoodsWithDefaultServingOnlyDocument, baseOptions);
        }
export type RestaurantFoodsWithDefaultServingOnlyQueryHookResult = ReturnType<typeof useRestaurantFoodsWithDefaultServingOnlyQuery>;
export type RestaurantFoodsWithDefaultServingOnlyLazyQueryHookResult = ReturnType<typeof useRestaurantFoodsWithDefaultServingOnlyLazyQuery>;
export type RestaurantFoodsWithDefaultServingOnlyQueryResult = ApolloReactCommon.QueryResult<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>;
export const GenericFoodsDocument = gql`
    query GenericFoods($searchTerm: String!, $limit: Int) {
  genericFoods(searchTerm: $searchTerm, limit: $limit) {
    ...Food
  }
}
    ${FoodFragmentDoc}`;
export type GenericFoodsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GenericFoodsQuery, GenericFoodsQueryVariables>, 'query'> & ({ variables: GenericFoodsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GenericFoodsComponent = (props: GenericFoodsComponentProps) => (
      <ApolloReactComponents.Query<GenericFoodsQuery, GenericFoodsQueryVariables> query={GenericFoodsDocument} {...props} />
    );
    

/**
 * __useGenericFoodsQuery__
 *
 * To run a query within a React component, call `useGenericFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenericFoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenericFoodsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGenericFoodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenericFoodsQuery, GenericFoodsQueryVariables>) {
        return ApolloReactHooks.useQuery<GenericFoodsQuery, GenericFoodsQueryVariables>(GenericFoodsDocument, baseOptions);
      }
export function useGenericFoodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenericFoodsQuery, GenericFoodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenericFoodsQuery, GenericFoodsQueryVariables>(GenericFoodsDocument, baseOptions);
        }
export type GenericFoodsQueryHookResult = ReturnType<typeof useGenericFoodsQuery>;
export type GenericFoodsLazyQueryHookResult = ReturnType<typeof useGenericFoodsLazyQuery>;
export type GenericFoodsQueryResult = ApolloReactCommon.QueryResult<GenericFoodsQuery, GenericFoodsQueryVariables>;
export const GoalTypesDocument = gql`
    query GoalTypes {
  goalTypes {
    ...GoalType
  }
}
    ${GoalTypeFragmentDoc}`;
export type GoalTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GoalTypesQuery, GoalTypesQueryVariables>, 'query'>;

    export const GoalTypesComponent = (props: GoalTypesComponentProps) => (
      <ApolloReactComponents.Query<GoalTypesQuery, GoalTypesQueryVariables> query={GoalTypesDocument} {...props} />
    );
    

/**
 * __useGoalTypesQuery__
 *
 * To run a query within a React component, call `useGoalTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoalTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GoalTypesQuery, GoalTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GoalTypesQuery, GoalTypesQueryVariables>(GoalTypesDocument, baseOptions);
      }
export function useGoalTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoalTypesQuery, GoalTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GoalTypesQuery, GoalTypesQueryVariables>(GoalTypesDocument, baseOptions);
        }
export type GoalTypesQueryHookResult = ReturnType<typeof useGoalTypesQuery>;
export type GoalTypesLazyQueryHookResult = ReturnType<typeof useGoalTypesLazyQuery>;
export type GoalTypesQueryResult = ApolloReactCommon.QueryResult<GoalTypesQuery, GoalTypesQueryVariables>;
export const GroceryListCategoriesDocument = gql`
    query GroceryListCategories {
  groceryListCategories {
    id
    name
  }
}
    `;
export type GroceryListCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>, 'query'>;

    export const GroceryListCategoriesComponent = (props: GroceryListCategoriesComponentProps) => (
      <ApolloReactComponents.Query<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables> query={GroceryListCategoriesDocument} {...props} />
    );
    

/**
 * __useGroceryListCategoriesQuery__
 *
 * To run a query within a React component, call `useGroceryListCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroceryListCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroceryListCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroceryListCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>(GroceryListCategoriesDocument, baseOptions);
      }
export function useGroceryListCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>(GroceryListCategoriesDocument, baseOptions);
        }
export type GroceryListCategoriesQueryHookResult = ReturnType<typeof useGroceryListCategoriesQuery>;
export type GroceryListCategoriesLazyQueryHookResult = ReturnType<typeof useGroceryListCategoriesLazyQuery>;
export type GroceryListCategoriesQueryResult = ApolloReactCommon.QueryResult<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>;
export const MealPlanBulkCopyChooseAthletesContainerDocument = gql`
    query MealPlanBulkCopyChooseAthletesContainer($teamId: ID!) {
  team(id: $teamId) {
    ...Team
    athletes {
      ...AthleteWithGoalAndAnthro
    }
  }
  exchangeSets {
    ...ExchangeSet
  }
}
    ${TeamFragmentDoc}
${AthleteWithGoalAndAnthroFragmentDoc}
${ExchangeSetFragmentDoc}`;
export type MealPlanBulkCopyChooseAthletesContainerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>, 'query'> & ({ variables: MealPlanBulkCopyChooseAthletesContainerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MealPlanBulkCopyChooseAthletesContainerComponent = (props: MealPlanBulkCopyChooseAthletesContainerComponentProps) => (
      <ApolloReactComponents.Query<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables> query={MealPlanBulkCopyChooseAthletesContainerDocument} {...props} />
    );
    

/**
 * __useMealPlanBulkCopyChooseAthletesContainerQuery__
 *
 * To run a query within a React component, call `useMealPlanBulkCopyChooseAthletesContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanBulkCopyChooseAthletesContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanBulkCopyChooseAthletesContainerQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useMealPlanBulkCopyChooseAthletesContainerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>(MealPlanBulkCopyChooseAthletesContainerDocument, baseOptions);
      }
export function useMealPlanBulkCopyChooseAthletesContainerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>(MealPlanBulkCopyChooseAthletesContainerDocument, baseOptions);
        }
export type MealPlanBulkCopyChooseAthletesContainerQueryHookResult = ReturnType<typeof useMealPlanBulkCopyChooseAthletesContainerQuery>;
export type MealPlanBulkCopyChooseAthletesContainerLazyQueryHookResult = ReturnType<typeof useMealPlanBulkCopyChooseAthletesContainerLazyQuery>;
export type MealPlanBulkCopyChooseAthletesContainerQueryResult = ApolloReactCommon.QueryResult<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>;
export const MealPlanBulkCopyDocument = gql`
    query MealPlanBulkCopy {
  teams {
    ...MealPlanBulkCopyTeamPreview
  }
  exchangeSets {
    ...ExchangeSet
  }
}
    ${MealPlanBulkCopyTeamPreviewFragmentDoc}
${ExchangeSetFragmentDoc}`;
export type MealPlanBulkCopyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>, 'query'>;

    export const MealPlanBulkCopyComponent = (props: MealPlanBulkCopyComponentProps) => (
      <ApolloReactComponents.Query<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables> query={MealPlanBulkCopyDocument} {...props} />
    );
    

/**
 * __useMealPlanBulkCopyQuery__
 *
 * To run a query within a React component, call `useMealPlanBulkCopyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanBulkCopyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanBulkCopyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMealPlanBulkCopyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>(MealPlanBulkCopyDocument, baseOptions);
      }
export function useMealPlanBulkCopyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>(MealPlanBulkCopyDocument, baseOptions);
        }
export type MealPlanBulkCopyQueryHookResult = ReturnType<typeof useMealPlanBulkCopyQuery>;
export type MealPlanBulkCopyLazyQueryHookResult = ReturnType<typeof useMealPlanBulkCopyLazyQuery>;
export type MealPlanBulkCopyQueryResult = ApolloReactCommon.QueryResult<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>;
export const NoteTypesDocument = gql`
    query NoteTypes {
  noteTypes {
    ...NoteType
  }
}
    ${NoteTypeFragmentDoc}`;
export type NoteTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NoteTypesQuery, NoteTypesQueryVariables>, 'query'>;

    export const NoteTypesComponent = (props: NoteTypesComponentProps) => (
      <ApolloReactComponents.Query<NoteTypesQuery, NoteTypesQueryVariables> query={NoteTypesDocument} {...props} />
    );
    

/**
 * __useNoteTypesQuery__
 *
 * To run a query within a React component, call `useNoteTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNoteTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NoteTypesQuery, NoteTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<NoteTypesQuery, NoteTypesQueryVariables>(NoteTypesDocument, baseOptions);
      }
export function useNoteTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NoteTypesQuery, NoteTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NoteTypesQuery, NoteTypesQueryVariables>(NoteTypesDocument, baseOptions);
        }
export type NoteTypesQueryHookResult = ReturnType<typeof useNoteTypesQuery>;
export type NoteTypesLazyQueryHookResult = ReturnType<typeof useNoteTypesLazyQuery>;
export type NoteTypesQueryResult = ApolloReactCommon.QueryResult<NoteTypesQuery, NoteTypesQueryVariables>;
export const OrgFoodGroupsDocument = gql`
    query OrgFoodGroups($searchTerm: String!, $limit: Int) {
  orgFoodGroups(searchTerm: $searchTerm, limit: $limit) {
    ...FullFoodGroup
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type OrgFoodGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>, 'query'> & ({ variables: OrgFoodGroupsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgFoodGroupsComponent = (props: OrgFoodGroupsComponentProps) => (
      <ApolloReactComponents.Query<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables> query={OrgFoodGroupsDocument} {...props} />
    );
    

/**
 * __useOrgFoodGroupsQuery__
 *
 * To run a query within a React component, call `useOrgFoodGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgFoodGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgFoodGroupsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOrgFoodGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>(OrgFoodGroupsDocument, baseOptions);
      }
export function useOrgFoodGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>(OrgFoodGroupsDocument, baseOptions);
        }
export type OrgFoodGroupsQueryHookResult = ReturnType<typeof useOrgFoodGroupsQuery>;
export type OrgFoodGroupsLazyQueryHookResult = ReturnType<typeof useOrgFoodGroupsLazyQuery>;
export type OrgFoodGroupsQueryResult = ApolloReactCommon.QueryResult<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>;
export const PingDocument = gql`
    query Ping {
  ping
}
    `;
export type PingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PingQuery, PingQueryVariables>, 'query'>;

    export const PingComponent = (props: PingComponentProps) => (
      <ApolloReactComponents.Query<PingQuery, PingQueryVariables> query={PingDocument} {...props} />
    );
    

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PingQuery, PingQueryVariables>) {
        return ApolloReactHooks.useQuery<PingQuery, PingQueryVariables>(PingDocument, baseOptions);
      }
export function usePingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PingQuery, PingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PingQuery, PingQueryVariables>(PingDocument, baseOptions);
        }
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingQueryResult = ApolloReactCommon.QueryResult<PingQuery, PingQueryVariables>;
export const RecipesDocument = gql`
    query Recipes($searchTerm: String!, $limit: Int) {
  recipes(searchTerm: $searchTerm, limit: $limit) {
    ...RecipeWithServings
    hasFullAccess
    org {
      id
      name
    }
    descendantFoods {
      ...Food
    }
  }
  orgGroupRecipes(searchTerm: $searchTerm, limit: $limit) {
    ...RecipeWithServings
    hasFullAccess
    org {
      id
      name
    }
    descendantFoods {
      ...Food
    }
    orgGroup {
      name
    }
  }
}
    ${RecipeWithServingsFragmentDoc}
${FoodFragmentDoc}`;
export type RecipesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecipesQuery, RecipesQueryVariables>, 'query'> & ({ variables: RecipesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecipesComponent = (props: RecipesComponentProps) => (
      <ApolloReactComponents.Query<RecipesQuery, RecipesQueryVariables> query={RecipesDocument} {...props} />
    );
    

/**
 * __useRecipesQuery__
 *
 * To run a query within a React component, call `useRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRecipesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, baseOptions);
      }
export function useRecipesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, baseOptions);
        }
export type RecipesQueryHookResult = ReturnType<typeof useRecipesQuery>;
export type RecipesLazyQueryHookResult = ReturnType<typeof useRecipesLazyQuery>;
export type RecipesQueryResult = ApolloReactCommon.QueryResult<RecipesQuery, RecipesQueryVariables>;
export const SchedulesDefaultAndTeamDocument = gql`
    query SchedulesDefaultAndTeam($teamId: ID!) {
  schedules {
    ...FullSchedule
  }
  schedulesForTeam(teamId: $teamId) {
    ...FullSchedule
  }
}
    ${FullScheduleFragmentDoc}`;
export type SchedulesDefaultAndTeamComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>, 'query'> & ({ variables: SchedulesDefaultAndTeamQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SchedulesDefaultAndTeamComponent = (props: SchedulesDefaultAndTeamComponentProps) => (
      <ApolloReactComponents.Query<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables> query={SchedulesDefaultAndTeamDocument} {...props} />
    );
    

/**
 * __useSchedulesDefaultAndTeamQuery__
 *
 * To run a query within a React component, call `useSchedulesDefaultAndTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulesDefaultAndTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulesDefaultAndTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSchedulesDefaultAndTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>(SchedulesDefaultAndTeamDocument, baseOptions);
      }
export function useSchedulesDefaultAndTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>(SchedulesDefaultAndTeamDocument, baseOptions);
        }
export type SchedulesDefaultAndTeamQueryHookResult = ReturnType<typeof useSchedulesDefaultAndTeamQuery>;
export type SchedulesDefaultAndTeamLazyQueryHookResult = ReturnType<typeof useSchedulesDefaultAndTeamLazyQuery>;
export type SchedulesDefaultAndTeamQueryResult = ApolloReactCommon.QueryResult<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>;
export const SharedFoodGroupsDocument = gql`
    query SharedFoodGroups($searchTerm: String!, $limit: Int) {
  sharedFoodGroups(searchTerm: $searchTerm, limit: $limit) {
    ...FullFoodGroup
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type SharedFoodGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>, 'query'> & ({ variables: SharedFoodGroupsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SharedFoodGroupsComponent = (props: SharedFoodGroupsComponentProps) => (
      <ApolloReactComponents.Query<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables> query={SharedFoodGroupsDocument} {...props} />
    );
    

/**
 * __useSharedFoodGroupsQuery__
 *
 * To run a query within a React component, call `useSharedFoodGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedFoodGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedFoodGroupsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSharedFoodGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>(SharedFoodGroupsDocument, baseOptions);
      }
export function useSharedFoodGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>(SharedFoodGroupsDocument, baseOptions);
        }
export type SharedFoodGroupsQueryHookResult = ReturnType<typeof useSharedFoodGroupsQuery>;
export type SharedFoodGroupsLazyQueryHookResult = ReturnType<typeof useSharedFoodGroupsLazyQuery>;
export type SharedFoodGroupsQueryResult = ApolloReactCommon.QueryResult<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>;
export const SimpleFoodsDocument = gql`
    query SimpleFoods($searchTerm: String!, $limit: Int, $localeCodes: [String!], $dataSources: [FoodDataSource!]) {
  foods(
    searchTerm: $searchTerm
    limit: $limit
    localeCodes: $localeCodes
    dataSources: $dataSources
  ) {
    ...Food
    ... on BrandedFood {
      __typename
    }
    ... on GenericFood {
      __typename
    }
  }
}
    ${FoodFragmentDoc}`;
export type SimpleFoodsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SimpleFoodsQuery, SimpleFoodsQueryVariables>, 'query'> & ({ variables: SimpleFoodsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SimpleFoodsComponent = (props: SimpleFoodsComponentProps) => (
      <ApolloReactComponents.Query<SimpleFoodsQuery, SimpleFoodsQueryVariables> query={SimpleFoodsDocument} {...props} />
    );
    

/**
 * __useSimpleFoodsQuery__
 *
 * To run a query within a React component, call `useSimpleFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleFoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleFoodsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      localeCodes: // value for 'localeCodes'
 *      dataSources: // value for 'dataSources'
 *   },
 * });
 */
export function useSimpleFoodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleFoodsQuery, SimpleFoodsQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleFoodsQuery, SimpleFoodsQueryVariables>(SimpleFoodsDocument, baseOptions);
      }
export function useSimpleFoodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleFoodsQuery, SimpleFoodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleFoodsQuery, SimpleFoodsQueryVariables>(SimpleFoodsDocument, baseOptions);
        }
export type SimpleFoodsQueryHookResult = ReturnType<typeof useSimpleFoodsQuery>;
export type SimpleFoodsLazyQueryHookResult = ReturnType<typeof useSimpleFoodsLazyQuery>;
export type SimpleFoodsQueryResult = ApolloReactCommon.QueryResult<SimpleFoodsQuery, SimpleFoodsQueryVariables>;
export const SlackFoodRequestsUrlDocument = gql`
    query SlackFoodRequestsUrl {
  slackFoodRequestsUrl
}
    `;
export type SlackFoodRequestsUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>, 'query'>;

    export const SlackFoodRequestsUrlComponent = (props: SlackFoodRequestsUrlComponentProps) => (
      <ApolloReactComponents.Query<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables> query={SlackFoodRequestsUrlDocument} {...props} />
    );
    

/**
 * __useSlackFoodRequestsUrlQuery__
 *
 * To run a query within a React component, call `useSlackFoodRequestsUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlackFoodRequestsUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlackFoodRequestsUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useSlackFoodRequestsUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>(SlackFoodRequestsUrlDocument, baseOptions);
      }
export function useSlackFoodRequestsUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>(SlackFoodRequestsUrlDocument, baseOptions);
        }
export type SlackFoodRequestsUrlQueryHookResult = ReturnType<typeof useSlackFoodRequestsUrlQuery>;
export type SlackFoodRequestsUrlLazyQueryHookResult = ReturnType<typeof useSlackFoodRequestsUrlLazyQuery>;
export type SlackFoodRequestsUrlQueryResult = ApolloReactCommon.QueryResult<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>;
export const SportsDocument = gql`
    query Sports {
  sports {
    ...FullSport
  }
}
    ${FullSportFragmentDoc}`;
export type SportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SportsQuery, SportsQueryVariables>, 'query'>;

    export const SportsComponent = (props: SportsComponentProps) => (
      <ApolloReactComponents.Query<SportsQuery, SportsQueryVariables> query={SportsDocument} {...props} />
    );
    

/**
 * __useSportsQuery__
 *
 * To run a query within a React component, call `useSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportsQuery, SportsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportsQuery, SportsQueryVariables>(SportsDocument, baseOptions);
      }
export function useSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportsQuery, SportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportsQuery, SportsQueryVariables>(SportsDocument, baseOptions);
        }
export type SportsQueryHookResult = ReturnType<typeof useSportsQuery>;
export type SportsLazyQueryHookResult = ReturnType<typeof useSportsLazyQuery>;
export type SportsQueryResult = ApolloReactCommon.QueryResult<SportsQuery, SportsQueryVariables>;
export const TeamAnthropometryDocument = gql`
    query TeamAnthropometry($id: ID!) {
  team(id: $id) {
    ...TeamWithSport
    athletes {
      ...Athlete
      position {
        ...Position
      }
      anthropometryEntries {
        ...AnthropometryEntry
      }
    }
  }
}
    ${TeamWithSportFragmentDoc}
${AthleteFragmentDoc}
${PositionFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export type TeamAnthropometryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>, 'query'> & ({ variables: TeamAnthropometryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamAnthropometryComponent = (props: TeamAnthropometryComponentProps) => (
      <ApolloReactComponents.Query<TeamAnthropometryQuery, TeamAnthropometryQueryVariables> query={TeamAnthropometryDocument} {...props} />
    );
    

/**
 * __useTeamAnthropometryQuery__
 *
 * To run a query within a React component, call `useTeamAnthropometryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamAnthropometryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamAnthropometryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamAnthropometryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>(TeamAnthropometryDocument, baseOptions);
      }
export function useTeamAnthropometryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>(TeamAnthropometryDocument, baseOptions);
        }
export type TeamAnthropometryQueryHookResult = ReturnType<typeof useTeamAnthropometryQuery>;
export type TeamAnthropometryLazyQueryHookResult = ReturnType<typeof useTeamAnthropometryLazyQuery>;
export type TeamAnthropometryQueryResult = ApolloReactCommon.QueryResult<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>;
export const TeamRosterDocument = gql`
    query TeamRoster($id: ID!) {
  team(id: $id) {
    ...TeamWithSport
    athletes {
      ...Athlete
      position {
        ...Position
      }
    }
  }
}
    ${TeamWithSportFragmentDoc}
${AthleteFragmentDoc}
${PositionFragmentDoc}`;
export type TeamRosterComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamRosterQuery, TeamRosterQueryVariables>, 'query'> & ({ variables: TeamRosterQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamRosterComponent = (props: TeamRosterComponentProps) => (
      <ApolloReactComponents.Query<TeamRosterQuery, TeamRosterQueryVariables> query={TeamRosterDocument} {...props} />
    );
    

/**
 * __useTeamRosterQuery__
 *
 * To run a query within a React component, call `useTeamRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamRosterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamRosterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamRosterQuery, TeamRosterQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamRosterQuery, TeamRosterQueryVariables>(TeamRosterDocument, baseOptions);
      }
export function useTeamRosterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamRosterQuery, TeamRosterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamRosterQuery, TeamRosterQueryVariables>(TeamRosterDocument, baseOptions);
        }
export type TeamRosterQueryHookResult = ReturnType<typeof useTeamRosterQuery>;
export type TeamRosterLazyQueryHookResult = ReturnType<typeof useTeamRosterLazyQuery>;
export type TeamRosterQueryResult = ApolloReactCommon.QueryResult<TeamRosterQuery, TeamRosterQueryVariables>;
export const NutritionixInstantSearchDocument = gql`
    query NutritionixInstantSearch($query: String!, $region: Int!, $localeCode: String!) {
  nutritionixBrandedInstantSearch(query: $query, region: $region) @rest(type: "NutritionixBrandedInstantSearchPayload", method: "GET", path: "/search/instant?query={args.query}&branded_region={args.region}&common=false", endpoint: "v2") {
    branded {
      ...NutritionixBrandedFood
    }
  }
  nutritionixCommonInstantSearch(query: $query, localeCode: $localeCode) @rest(type: "NutritionixCommonInstantSearchPayload", method: "GET", path: "/search/instant?query={args.query}&locale={args.localeCode}&branded=false", endpoint: "v2") {
    common {
      ...NutritionixCommonFood
    }
  }
}
    ${NutritionixBrandedFoodFragmentDoc}
${NutritionixCommonFoodFragmentDoc}`;
export type NutritionixInstantSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>, 'query'> & ({ variables: NutritionixInstantSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NutritionixInstantSearchComponent = (props: NutritionixInstantSearchComponentProps) => (
      <ApolloReactComponents.Query<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables> query={NutritionixInstantSearchDocument} {...props} />
    );
    

/**
 * __useNutritionixInstantSearchQuery__
 *
 * To run a query within a React component, call `useNutritionixInstantSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNutritionixInstantSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNutritionixInstantSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      region: // value for 'region'
 *      localeCode: // value for 'localeCode'
 *   },
 * });
 */
export function useNutritionixInstantSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>(NutritionixInstantSearchDocument, baseOptions);
      }
export function useNutritionixInstantSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>(NutritionixInstantSearchDocument, baseOptions);
        }
export type NutritionixInstantSearchQueryHookResult = ReturnType<typeof useNutritionixInstantSearchQuery>;
export type NutritionixInstantSearchLazyQueryHookResult = ReturnType<typeof useNutritionixInstantSearchLazyQuery>;
export type NutritionixInstantSearchQueryResult = ApolloReactCommon.QueryResult<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>;
export const NutritionixBrandedInstantSearchDocument = gql`
    query NutritionixBrandedInstantSearch($query: String!, $region: Int!) {
  nutritionixBrandedInstantSearch(query: $query, region: $region) @rest(type: "NutritionixBrandedInstantSearchPayload", method: "GET", path: "/search/instant?query={args.query}&branded_region={args.region}&common=false", endpoint: "v2") {
    branded {
      ...NutritionixBrandedFood
    }
  }
}
    ${NutritionixBrandedFoodFragmentDoc}`;
export type NutritionixBrandedInstantSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>, 'query'> & ({ variables: NutritionixBrandedInstantSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NutritionixBrandedInstantSearchComponent = (props: NutritionixBrandedInstantSearchComponentProps) => (
      <ApolloReactComponents.Query<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables> query={NutritionixBrandedInstantSearchDocument} {...props} />
    );
    

/**
 * __useNutritionixBrandedInstantSearchQuery__
 *
 * To run a query within a React component, call `useNutritionixBrandedInstantSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNutritionixBrandedInstantSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNutritionixBrandedInstantSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useNutritionixBrandedInstantSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>(NutritionixBrandedInstantSearchDocument, baseOptions);
      }
export function useNutritionixBrandedInstantSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>(NutritionixBrandedInstantSearchDocument, baseOptions);
        }
export type NutritionixBrandedInstantSearchQueryHookResult = ReturnType<typeof useNutritionixBrandedInstantSearchQuery>;
export type NutritionixBrandedInstantSearchLazyQueryHookResult = ReturnType<typeof useNutritionixBrandedInstantSearchLazyQuery>;
export type NutritionixBrandedInstantSearchQueryResult = ApolloReactCommon.QueryResult<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>;
export const NutritionixV1RestaurantSearchDocument = gql`
    query NutritionixV1RestaurantSearch($input: NutritionixV1RestaurantSearchInput!) {
  nutritionixV1RestaurantSearch(input: $input) @rest(type: "NutritionixV1RestaurantSearchPayload", method: "POST", path: "/", endpoint: "v1_search") {
    total
    branded {
      ...NutritionixBrandedFood
    }
  }
}
    ${NutritionixBrandedFoodFragmentDoc}`;
export type NutritionixV1RestaurantSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>, 'query'> & ({ variables: NutritionixV1RestaurantSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NutritionixV1RestaurantSearchComponent = (props: NutritionixV1RestaurantSearchComponentProps) => (
      <ApolloReactComponents.Query<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables> query={NutritionixV1RestaurantSearchDocument} {...props} />
    );
    

/**
 * __useNutritionixV1RestaurantSearchQuery__
 *
 * To run a query within a React component, call `useNutritionixV1RestaurantSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNutritionixV1RestaurantSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNutritionixV1RestaurantSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNutritionixV1RestaurantSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>(NutritionixV1RestaurantSearchDocument, baseOptions);
      }
export function useNutritionixV1RestaurantSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>(NutritionixV1RestaurantSearchDocument, baseOptions);
        }
export type NutritionixV1RestaurantSearchQueryHookResult = ReturnType<typeof useNutritionixV1RestaurantSearchQuery>;
export type NutritionixV1RestaurantSearchLazyQueryHookResult = ReturnType<typeof useNutritionixV1RestaurantSearchLazyQuery>;
export type NutritionixV1RestaurantSearchQueryResult = ApolloReactCommon.QueryResult<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>;
export const UsdaFoodDetailsDocument = gql`
    query USDAFoodDetails($usdaFdcId: ID!, $apiKey: String!) {
  usdaFoodDetail(usdaFdcId: $usdaFdcId, apiKey: $apiKey) @rest(type: "USDAFoodDetail", method: "GET", path: "/{args.usdaFdcId}?api_key={args.apiKey}", endpoint: "v1") {
    ...USDAFoodDetail
  }
}
    ${UsdaFoodDetailFragmentDoc}`;
export type UsdaFoodDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>, 'query'> & ({ variables: UsdaFoodDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UsdaFoodDetailsComponent = (props: UsdaFoodDetailsComponentProps) => (
      <ApolloReactComponents.Query<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables> query={UsdaFoodDetailsDocument} {...props} />
    );
    

/**
 * __useUsdaFoodDetailsQuery__
 *
 * To run a query within a React component, call `useUsdaFoodDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsdaFoodDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsdaFoodDetailsQuery({
 *   variables: {
 *      usdaFdcId: // value for 'usdaFdcId'
 *      apiKey: // value for 'apiKey'
 *   },
 * });
 */
export function useUsdaFoodDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>(UsdaFoodDetailsDocument, baseOptions);
      }
export function useUsdaFoodDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>(UsdaFoodDetailsDocument, baseOptions);
        }
export type UsdaFoodDetailsQueryHookResult = ReturnType<typeof useUsdaFoodDetailsQuery>;
export type UsdaFoodDetailsLazyQueryHookResult = ReturnType<typeof useUsdaFoodDetailsLazyQuery>;
export type UsdaFoodDetailsQueryResult = ApolloReactCommon.QueryResult<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>;
export const UsdaFoodsDocument = gql`
    query USDAFoods($searchTerm: String!, $apiKey: String!, $brandOwner: String!, $includedDataTypes: String!) {
  usdaFoods(
    searchTerm: $searchTerm
    apiKey: $apiKey
    brandOwner: $brandOwner
    includedDataTypes: $includedDataTypes
  ) @rest(type: "[USDAFood]", method: "GET", path: "/search?generalSearchInput={args.searchTerm}&api_key={args.apiKey}&brandOwner={args.brandOwner}&includeDataTypeList={args.includedDataTypes}", endpoint: "v1") {
    ...USDAFood
  }
}
    ${UsdaFoodFragmentDoc}`;
export type UsdaFoodsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsdaFoodsQuery, UsdaFoodsQueryVariables>, 'query'> & ({ variables: UsdaFoodsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UsdaFoodsComponent = (props: UsdaFoodsComponentProps) => (
      <ApolloReactComponents.Query<UsdaFoodsQuery, UsdaFoodsQueryVariables> query={UsdaFoodsDocument} {...props} />
    );
    

/**
 * __useUsdaFoodsQuery__
 *
 * To run a query within a React component, call `useUsdaFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsdaFoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsdaFoodsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      apiKey: // value for 'apiKey'
 *      brandOwner: // value for 'brandOwner'
 *      includedDataTypes: // value for 'includedDataTypes'
 *   },
 * });
 */
export function useUsdaFoodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsdaFoodsQuery, UsdaFoodsQueryVariables>) {
        return ApolloReactHooks.useQuery<UsdaFoodsQuery, UsdaFoodsQueryVariables>(UsdaFoodsDocument, baseOptions);
      }
export function useUsdaFoodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsdaFoodsQuery, UsdaFoodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsdaFoodsQuery, UsdaFoodsQueryVariables>(UsdaFoodsDocument, baseOptions);
        }
export type UsdaFoodsQueryHookResult = ReturnType<typeof useUsdaFoodsQuery>;
export type UsdaFoodsLazyQueryHookResult = ReturnType<typeof useUsdaFoodsLazyQuery>;
export type UsdaFoodsQueryResult = ApolloReactCommon.QueryResult<UsdaFoodsQuery, UsdaFoodsQueryVariables>;
export const PublicMealPlanDocument = gql`
    query PublicMealPlan($mealPlanId: ID!) {
  mealPlan(id: $mealPlanId) {
    ...PublicMealPlan
  }
}
    ${PublicMealPlanFragmentDoc}`;
export type PublicMealPlanComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PublicMealPlanQuery, PublicMealPlanQueryVariables>, 'query'> & ({ variables: PublicMealPlanQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PublicMealPlanComponent = (props: PublicMealPlanComponentProps) => (
      <ApolloReactComponents.Query<PublicMealPlanQuery, PublicMealPlanQueryVariables> query={PublicMealPlanDocument} {...props} />
    );
    

/**
 * __usePublicMealPlanQuery__
 *
 * To run a query within a React component, call `usePublicMealPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicMealPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicMealPlanQuery({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function usePublicMealPlanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublicMealPlanQuery, PublicMealPlanQueryVariables>) {
        return ApolloReactHooks.useQuery<PublicMealPlanQuery, PublicMealPlanQueryVariables>(PublicMealPlanDocument, baseOptions);
      }
export function usePublicMealPlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublicMealPlanQuery, PublicMealPlanQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublicMealPlanQuery, PublicMealPlanQueryVariables>(PublicMealPlanDocument, baseOptions);
        }
export type PublicMealPlanQueryHookResult = ReturnType<typeof usePublicMealPlanQuery>;
export type PublicMealPlanLazyQueryHookResult = ReturnType<typeof usePublicMealPlanLazyQuery>;
export type PublicMealPlanQueryResult = ApolloReactCommon.QueryResult<PublicMealPlanQuery, PublicMealPlanQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BulkOrderPublicationPayload": [
      "AddBulkOrderPayload",
      "EditBulkOrderPayload",
      "RemoveBulkOrderPayload"
    ],
    "CbordBusinessUnit": [
      "CbordFacility",
      "CbordServiceUnit"
    ],
    "Food": [
      "BrandedFood",
      "GenericFood"
    ],
    "FoodLogMealPublicationPayload": [
      "AddFoodLogMealPayload",
      "EditFoodLogMealTimePayload",
      "RemoveFoodLogMealPayload",
      "EditFoodLogMealServingAmountsPayload",
      "EditFoodLogMealImagePayload",
      "AddFoodLogMealCommentPayload",
      "AddFoodLogMealImagePayload"
    ],
    "FoodOrRecipe": [
      "BrandedFood",
      "GenericFood",
      "Recipe"
    ],
    "MealMenu": [
      "StandaloneMealMenu"
    ],
    "MealMenuIdentity": [
      "BonAppetitMenuIdentity",
      "CbordMenuIdentity",
      "CompassMenuIdentity",
      "SodexoMenuIdentity"
    ],
    "MealPlanTemplate": [
      "ExchangeMealPlanTemplate",
      "MacroMealPlanTemplate"
    ],
    "MealPlanTemplateAnthropometry": [
      "AnthropometryTemplate",
      "AnthropometryForm"
    ],
    "MealPlanTemplateMeal": [
      "ExchangeMealPlanTemplateMeal",
      "MacroMealPlanTemplateMeal"
    ],
    "MealTemplate": [
      "ExchangeMealTemplate",
      "MacroMealTemplate"
    ],
    "MenuOrderPublicationPayload": [
      "AddMenuOrderPayload",
      "EditMenuOrderPayload",
      "RemoveMenuOrderPayload",
      "AddMenuOrderCommentPayload"
    ],
    "MenuSelection": [
      "MealMenuLog",
      "MealMenuPlateOrder",
      "MenuOrder",
      "MenuOrderSuggestion",
      "RestaurantMenuLinkOrder",
      "RestaurantMenuLinkPlate",
      "RestaurantMenuLinkPlateOrder",
      "RestaurantMenuLog"
    ],
    "MenuSelectionItem": [
      "MealMenuLogItem",
      "MealMenuPlateOrderItem",
      "MenuItemAmount",
      "MenuOrderItem",
      "RestaurantMenuLinkOrderItem",
      "RestaurantMenuLinkPlateItem",
      "RestaurantMenuLinkPlateOrderItem",
      "RestaurantMenuLogItem"
    ],
    "MenuSelectionItemOption": [
      "MealMenuLogItemOption",
      "MealMenuPlateOrderItemOption",
      "MenuItemChoiceOptionAmount",
      "MenuOrderItemOption",
      "RestaurantMenuLinkOrderItemOption",
      "RestaurantMenuLinkPlateItemOption",
      "RestaurantMenuLinkPlateOrderItemOption",
      "RestaurantMenuLogItemOption"
    ],
    "Notification": [
      "AthleteSelfOnboardedNotification",
      "BulkOrderStatusNotification",
      "FoodLogMealCommentNotification",
      "FoodLogMealEditNotification",
      "FoodLogMealImageNotification",
      "GoalReminderNotification",
      "MealMenuAvailableNotification",
      "MealMenuIsSharedNotification",
      "MealMenuOrderDueNotification",
      "MealPlanSharedNotification",
      "MenuOrderCommentNotification",
      "MenuOrderImageNotification",
      "MenuOrderStatusNotification",
      "TimelineMealCommentNotification",
      "TimelineMealEditNotification",
      "TimelineMealImageNotification"
    ],
    "Rating": [
      "MenuItemRating"
    ],
    "Tag": [
      "AthleteTag",
      "ComboTag",
      "PositionTag",
      "TeamTag"
    ],
    "TagCondition": [
      "ComboTag",
      "PositionTag",
      "TeamTag"
    ],
    "TimelineActivityOrTemplate": [
      "FoodLogActivity",
      "TimelineActivityTemplate"
    ],
    "TimelineItem": [
      "FoodLogMeal",
      "FoodLogActivity",
      "MenuOrder",
      "MealPlanDate",
      "StandaloneMealMenu",
      "RestaurantMenuLog"
    ],
    "TimelineMealOrTemplate": [
      "TimelineMeal",
      "TimelineMealTemplate"
    ],
    "TimelineMealPublicationPayload": [
      "AddTimelineMealCommentPayload"
    ]
  }
};
      export default result;
    
export const TeamsPickerTeamFragmentFactory = (): TeamsPickerTeamFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const DisplaySettingsFormMacroMealPlanDisplaySettingsFragmentFactory = (): DisplaySettingsFormMacroMealPlanDisplaySettingsFragment =>  {
  return {
    __typename: "MacroMealPlanDisplaySettings",
    dailyProgressViews: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    mealProgressViews: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
  };
}

export const DisplaySettingsFormExchangeMealPlanDisplaySettingsFragmentFactory = (): DisplaySettingsFormExchangeMealPlanDisplaySettingsFragment =>  {
  return {
    __typename: "ExchangeMealPlanDisplaySettings",
    dailyProgressViewsForExchanges: faker.helpers.arrayElement(["all", "none"]),
    mealProgressViewsForExchanges: faker.helpers.arrayElement(["all", "none"]),
    dailyProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    mealProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
  };
}

export const DisplaySettingsFormNoTargetsDisplaySettingsFragmentFactory = (): DisplaySettingsFormNoTargetsDisplaySettingsFragment =>  {
  return {
    __typename: "NoTargetsDisplaySettings",
    dailyProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    mealProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
  };
}

export const OrgEditableFoodFragmentFactory = (): OrgEditableFoodFragment =>  {
  return {
    ...FoodFragmentFactory(),
    ...({
      __typename: "BrandedFood",
      brand: {
        ...BrandFragmentFactory(),
      },
    }),
    servings: [{
      ...ServingWithFoodOrRecipeFragmentFactory(),
      customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
      unit: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
      unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
      unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    createdBy: {
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    nutrientAmounts: [{
      ...NutrientAmountFragmentFactory(),
    }],
    groceryListCategory: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    excludeFromSuggestions: faker.datatype.boolean(),
    source: faker.helpers.arrayElement(["manual", "usda", "nutritionix", "bonAppetit", "compass", "sodexo", "cbord", "restaurant", "mccance"]),
    locales: [faker.internet.password(10, false, /[0-9A-Z]/)],
    updatedAt: faker.datatype.datetime().toISOString(),
    foodGroups: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const SharedEditableFoodFragmentFactory = (): SharedEditableFoodFragment =>  {
  return {
    ...FoodFragmentFactory(),
    ...({
      __typename: "BrandedFood",
      brand: {
        ...BrandFragmentFactory(),
      },
    }),
    servings: [{
      ...ServingWithFoodOrRecipeFragmentFactory(),
      customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
      unit: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
      unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
      unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    nutrientAmounts: [{
      ...NutrientAmountFragmentFactory(),
    }],
    groceryListCategory: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    excludeFromSuggestions: faker.datatype.boolean(),
    source: faker.helpers.arrayElement(["manual", "usda", "nutritionix", "bonAppetit", "compass", "sodexo", "cbord", "restaurant", "mccance"]),
    locales: [faker.internet.password(10, false, /[0-9A-Z]/)],
    updatedAt: faker.datatype.datetime().toISOString(),
    foodGroups: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const FoodOwnerPreviewFragmentFactory = (): FoodOwnerPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const CompassRecipeFragmentFactory = (): CompassRecipeFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    shortName: faker.internet.password(10, false, /[0-9A-Z]/),
    masterRefNumber: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const RecipeSearchFragmentFactory = (): RecipeSearchFragment =>  {
  return {
    ...RecipeWithServingsFragmentFactory(),
    hasFullAccess: faker.datatype.boolean(),
    org: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    descendantFoods: [{
      ...FoodFragmentFactory(),
    }],
  };
}

export const LocaleFragmentFactory = (): LocaleFragment =>  {
  return {
    id: faker.datatype.uuid(),
    code: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MealMenuIdentityFragmentFactory = (): MealMenuIdentityFragment =>  {
  return {
    __typename: "BonAppetitMenuIdentity",
    id: faker.datatype.uuid(),
    isOverridden: faker.datatype.boolean(),
  };
}

export const FullServingAmountGroupFragmentFactory = (): FullServingAmountGroupFragment =>  {
  return {
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const MealTemplateWithOptionsFragmentFactory = (): MealTemplateWithOptionsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealOptions: [{
      id: faker.datatype.uuid(),
    }],
  };
}

export const AthleteForMealPlanTemplateAssignmentFragmentFactory = (): AthleteForMealPlanTemplateAssignmentFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    myNamedTagsOffsetConnection: {
      edges: [{
        ...NamedTagForSelectionFragmentFactory(),
      }],
      pageInfo: {
        total: faker.datatype.number(),
      },
    },
    team: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    mostRecentAnthropometryEntryForRmrMethod: {
      ...AnthropometryEntryFragmentFactory(),
    },
  };
}

export const ScheduleWithTeamFragmentFactory = (): ScheduleWithTeamFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    team: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    meals: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      start: faker.datatype.datetime().toISOString().split("T")[1],
      end: faker.datatype.datetime().toISOString().split("T")[1],
      type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    }],
    activities: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      start: faker.datatype.datetime().toISOString().split("T")[1],
      end: faker.datatype.datetime().toISOString().split("T")[1],
      type: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
    }],
  };
}

export const PrintableNutrientInfoMenuItemFragmentFactory = (): PrintableNutrientInfoMenuItemFragment =>  {
  return {
    ...MenuItemPreviewFragmentFactory(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
    choices: [{
      id: faker.datatype.uuid(),
      options: [{
        id: faker.datatype.uuid(),
        servingAmounts: [{
          id: faker.datatype.uuid(),
        }],
      }],
    }],
  };
}

export const MenuItemPreviewSearchFragmentFactory = (): MenuItemPreviewSearchFragment =>  {
  return {
    ...MenuItemPreviewFragmentFactory(),
    isMissingIngredients: faker.datatype.boolean(),
  };
}

export const MenuItemAppearanceFormFragmentFactory = (): MenuItemAppearanceFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    maxAmount: faker.datatype.number(),
    availableForOrder: faker.datatype.boolean(),
    allowSpecialRequests: faker.datatype.boolean(),
    menuItem: {
      ...MenuItemFormFragmentFactory(),
    },
  };
}

export const MenuOrderFormFragmentFactory = (): MenuOrderFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuOrder",
    mealTemplate: {
      ...MealTemplateForTargetsFragmentFactory(),
    },
    mealMenu: {
      ...MealMenuForOrderFormFragmentFactory(),
      theme: faker.internet.password(10, false, /[0-9A-Z]/),
      mealMenuDiningStations: [{
        menuItemAppearances: [{
          id: faker.datatype.uuid(),
          availableForOrder: faker.datatype.boolean(),
          maxAmount: faker.datatype.number(),
          allowSpecialRequests: faker.datatype.boolean(),
          menuItem: {
            id: faker.datatype.uuid(),
          },
        }],
      }],
    },
    pickupTime: faker.datatype.datetime().toISOString(),
    code: faker.internet.password(10, false, /[0-9A-Z]/),
    createdAt: faker.datatype.datetime().toISOString(),
    items: [{
      ...MenuOrderItemFormFragmentFactory(),
    }],
  };
}

export const MealMenuForOrderFormFragmentFactory = (): MealMenuForOrderFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "StandaloneMealMenu",
    mealType: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    timezone: "America/New_York",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    prepTimeInMinutes: faker.datatype.number(),
    lastOrderTimeBeforeEndInMinutes: faker.datatype.number(),
    userOrderLimit: faker.datatype.number(),
    isViewOnlyForMe: faker.datatype.boolean(),
    theme: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const RecentMenuOrderFragmentFactory = (): RecentMenuOrderFragment =>  {
  return {
    items: [{
      amount: faker.datatype.float(),
      percentConsumed: faker.datatype.float(),
      menuItem: {
        ...MenuItemFormFragmentFactory(),
      },
      options: [{
        amount: faker.datatype.float(),
        percentConsumed: faker.datatype.float(),
        menuItemChoiceOption: {
          ...MenuItemChoiceOptionFormFragmentFactory(),
        },
      }],
    }],
  };
}

export const MealPlanPreviewItemFragmentFactory = (): MealPlanPreviewItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["macro", "exchange"]),
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    dates: [faker.datatype.datetime().toISOString().split("T")[0]],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    isShared: faker.datatype.boolean(),
    createdAt: faker.datatype.datetime().toISOString(),
    macroProtocol: {
      ...FullMacroProtocolFragmentFactory(),
    },
    exchangeTargets: [{
      ...EditExchangeAmountFragmentFactory(),
    }],
  };
}

export const AthleteTimelineFragmentFactory = (): AthleteTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    birthDate: faker.datatype.datetime().toISOString().split("T")[0],
    sex: faker.helpers.arrayElement(["male", "female"]),
    sharedMealPlans: [{
      ...MealPlanPreviewItemFragmentFactory(),
    }],
    likedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    dislikedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    likedFoods: [{
      ...FoodFragmentFactory(),
    }],
    dislikedFoods: [{
      ...FoodFragmentFactory(),
    }],
    user: {
      id: faker.datatype.uuid(),
    },
    team: {
      id: faker.datatype.uuid(),
    },
  };
}

export const NotificationStubFragmentFactory = (): NotificationStubFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "AthleteSelfOnboardedNotification",
    viewedAt: faker.datatype.datetime().toISOString(),
  };
}

export const RecipeFullFragmentFactory = (): RecipeFullFragment =>  {
  return {
    ...RecipeFragmentFactory(),
    hasFullAccess: faker.datatype.boolean(),
    org: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    descendantFoods: [{
      ...FoodFragmentFactory(),
    }],
    descendantRecipes: [{
      ...RecipeWithIngredientsFragmentFactory(),
    }],
    ingredients: [{
      ...FullServingAmountFragmentFactory(),
    }],
    servings: [{
      ...FullServingFragmentFactory(),
      customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
      unit: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
      unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
      unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    owner: {
      id: faker.datatype.uuid(),
    },
    orgGroup: {
      id: faker.datatype.uuid(),
    },
    isAiGenerated: faker.datatype.boolean(),
    isAiTranscribed: faker.datatype.boolean(),
  };
}

export const GptRecipeFragmentFactory = (): GptRecipeFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    servingYield: {
      amount: faker.datatype.float(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    steps: [faker.internet.password(10, false, /[0-9A-Z]/)],
    ingredients: [{
      serving: {
        ...FullServingFragmentFactory(),
      },
      position: faker.datatype.number(),
      amount: faker.datatype.float(),
    }],
    unmatchedIngredients: {
      unmatchedName: [{
        ...UnmatchedIngredientFragmentFactory(),
      }],
      unmatchedServings: [{
        ...UnmatchedIngredientFragmentFactory(),
      }],
    },
    aiType: faker.helpers.arrayElement(["generated", "transcribed"]),
  };
}

export const UnmatchedIngredientFragmentFactory = (): UnmatchedIngredientFragment =>  {
  return {
    amount: faker.datatype.float(),
    foodName: faker.internet.password(10, false, /[0-9A-Z]/),
    servingName: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const RestaurantLocationSearchResultFragmentFactory = (): RestaurantLocationSearchResultFragment =>  {
  return {
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      restaurantType: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
    restaurantLocation: {
      ...RestaurantLocationPreviewFragmentFactory(),
      googlePlaceId: faker.internet.password(10, false, /[0-9A-Z]/),
      address: {
        displayName: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
    restaurantMenu: {
      id: faker.datatype.uuid(),
    },
  };
}

export const RestaurantMenuSearchResultFragmentFactory = (): RestaurantMenuSearchResultFragment =>  {
  return {
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      restaurantType: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
    restaurantMenu: {
      id: faker.datatype.uuid(),
    },
  };
}

export const ScheduleBasicFragmentFactory = (): ScheduleBasicFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    meals: [{
      ...MealFragmentFactory(),
    }],
    activities: [{
      ...ActivityFragmentFactory(),
    }],
  };
}

export const ServingWithUnitsFragmentFactory = (): ServingWithUnitsFragment =>  {
  return {
    customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
    unit: {
      ...UnitFragmentFactory(),
    },
    unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
    unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const UnitConversionFullFragmentFactory = (): UnitConversionFullFragment =>  {
  return {
    fromQuantity: faker.datatype.float(),
    fromUnit: {
      ...UnitFragmentFactory(),
    },
    toQuantity: faker.datatype.float(),
    toUnit: {
      ...UnitFragmentFactory(),
    },
  };
}

export const ServingAmountForConversionFragmentFactory = (): ServingAmountForConversionFragment =>  {
  return {
    __typename: "ServingAmount",
    id: faker.datatype.uuid(),
    amount: faker.datatype.float(),
    position: faker.datatype.number(),
    serving: {
      id: faker.datatype.uuid(),
      foodOrRecipe: {
        ...({
          id: faker.datatype.uuid(),
          name: faker.internet.password(10, false, /[0-9A-Z]/),
        }),
      },
      units: faker.internet.password(10, false, /[0-9A-Z]/),
      customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
      unit: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
      unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
      unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
      weight: faker.datatype.float(),
    },
  };
}

export const TeamsForImportAthleteTeamFragmentFactory = (): TeamsForImportAthleteTeamFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    gender: faker.internet.password(10, false, /[0-9A-Z]/),
    sport: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      positions: [{
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      }],
    },
  };
}

export const BulkImportFoodFragmentFactory = (): BulkImportFoodFragment =>  {
  return {
    ...FoodFragmentFactory(),
    ...({
      __typename: "BrandedFood",
      brand: {
        ...BrandFragmentFactory(),
      },
    }),
    nutrientAmounts: [{
      ...NutrientAmountFragmentFactory(),
    }],
    defaultServing: {
      ...ServingWithUnitsFragmentFactory(),
      ...FullServingFragmentFactory(),
    },
    servings: [{
      id: faker.datatype.uuid(),
    }],
    updatedAt: faker.datatype.datetime().toISOString(),
    groceryListCategory: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    foodGroups: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const NotificationWebFragmentFactory = (): NotificationWebFragment =>  {
  return {
    ...NotificationStubFragmentFactory(),
    __typename: "AthleteSelfOnboardedNotification",
  };
}

export const FoodLogMealCommentNotificationWebFragmentFactory = (): FoodLogMealCommentNotificationWebFragment =>  {
  return {
    timelineMeal: {
      ...TimelineMealForNotificationWebFragmentFactory(),
      comments: [{
        ...CommentTimelineFragmentFactory(),
      }],
    },
  };
}

export const FoodLogMealImageNotificationWebFragmentFactory = (): FoodLogMealImageNotificationWebFragment =>  {
  return {
    timelineMeal: {
      ...TimelineMealForNotificationWebFragmentFactory(),
      images: [{
        id: faker.datatype.uuid(),
        url: faker.internet.password(10, false, /[0-9A-Z]/),
        position: faker.datatype.number(),
      }],
    },
  };
}

export const MenuOrderCommentNotificationWebFragmentFactory = (): MenuOrderCommentNotificationWebFragment =>  {
  return {
    timelineMeal: {
      ...TimelineMealForNotificationWebFragmentFactory(),
      comments: [{
        ...CommentTimelineFragmentFactory(),
      }],
    },
  };
}

export const MenuOrderImageNotificationWebFragmentFactory = (): MenuOrderImageNotificationWebFragment =>  {
  return {
    timelineMeal: {
      ...TimelineMealForNotificationWebFragmentFactory(),
      images: [{
        id: faker.datatype.uuid(),
        url: faker.internet.password(10, false, /[0-9A-Z]/),
        position: faker.datatype.number(),
      }],
    },
  };
}

export const FoodLogMealEditNotificationWebFragmentFactory = (): FoodLogMealEditNotificationWebFragment =>  {
  return {
    timelineMeal: {
      ...TimelineMealForNotificationWebFragmentFactory(),
    },
  };
}

export const TimelineMealCommentNotificationWebFragmentFactory = (): TimelineMealCommentNotificationWebFragment =>  {
  return {
    timelineMeal: {
      ...TimelineMealForNotificationWebFragmentFactory(),
    },
  };
}

export const TimelineMealEditNotificationWebFragmentFactory = (): TimelineMealEditNotificationWebFragment =>  {
  return {
    timelineMeal: {
      ...TimelineMealForNotificationWebFragmentFactory(),
    },
  };
}

export const TimelineMealImageNotificationWebFragmentFactory = (): TimelineMealImageNotificationWebFragment =>  {
  return {
    timelineMeal: {
      ...TimelineMealForNotificationWebFragmentFactory(),
      images: [{
        id: faker.datatype.uuid(),
        url: faker.internet.password(10, false, /[0-9A-Z]/),
        position: faker.datatype.number(),
      }],
    },
  };
}

export const TimelineMealForNotificationWebFragmentFactory = (): TimelineMealForNotificationWebFragment =>  {
  return {
    id: faker.datatype.uuid(),
    athlete: {
      id: faker.datatype.uuid(),
      team: {
        id: faker.datatype.uuid(),
      },
    },
  };
}

export const NavAthleteFragmentFactory = (): NavAthleteFragment =>  {
  return {
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    team: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const NavMealMenuFragmentFactory = (): NavMealMenuFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    teams: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const NavPlannedMenuFragmentFactory = (): NavPlannedMenuFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MessageOptionFragmentFactory = (): MessageOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    userId: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    teamName: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const TeamOptionFragmentFactory = (): TeamOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const NavTeamFragmentFactory = (): NavTeamFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const StaffDashboardRecipePreviewFragmentFactory = (): StaffDashboardRecipePreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    mealTypes: [faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"])],
    isShared: faker.datatype.boolean(),
    createdBy: {
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      id: faker.datatype.uuid(),
    },
    servings: [{
      perRecipeYield: faker.datatype.float(),
      isDefault: faker.datatype.boolean(),
      units: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const NavOrgFragmentFactory = (): NavOrgFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const NavRestaurantFragmentFactory = (): NavRestaurantFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const CuisineFragmentFactory = (): CuisineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const PaymentMethodFragmentFactory = (): PaymentMethodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const ReportingOrgFragmentFactory = (): ReportingOrgFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    territoryName: faker.internet.password(10, false, /[0-9A-Z]/),
    teamworksId: faker.datatype.number(),
    performanceKitchen: faker.datatype.boolean(),
  };
}

export const ReportingUserFragmentFactory = (): ReportingUserFragment =>  {
  return {
    id: faker.datatype.uuid(),
    teamworksId: faker.datatype.number(),
    email: faker.internet.password(10, false, /[0-9A-Z]/),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
    dateJoined: faker.datatype.datetime().toISOString(),
  };
}

export const TimelineDateFragmentFactory = (): TimelineDateFragment =>  {
  return {
    date: faker.datatype.datetime().toISOString().split("T")[0],
    timezone: "America/New_York",
    mealPlan: {
      ...MealPlanTimelineFragmentFactory(),
    },
    meals: [{
      ...TimelineMealOrTemplateFragmentFactory(),
    }],
    activities: [{
      ...TimelineActivityOrTemplateFragmentFactory(),
    }],
  };
}

export const TimelineActivityOrTemplateFragmentFactory = (): TimelineActivityOrTemplateFragment =>  {
  return {
    ...({
      ...FoodLogActivityTimelineFragmentFactory(),
    }),
  };
}

export const TimelineMealOrTemplateFragmentFactory = (): TimelineMealOrTemplateFragment =>  {
  return {
    ...({
      ...TimelineMealFragmentFactory(),
    }),
  };
}

export const TimelineMealTemplateFragmentFactory = (): TimelineMealTemplateFragment =>  {
  return {
    __typename: "TimelineMealTemplate",
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    timezone: "America/New_York",
    template: {
      ...MealTemplateTimelineFragmentFactory(),
    },
    mealMenus: [{
      ...MealMenuTimelineFragmentFactory(),
    }],
  };
}

export const TimelineActivityTemplateFragmentFactory = (): TimelineActivityTemplateFragment =>  {
  return {
    __typename: "TimelineActivityTemplate",
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    timezone: "America/New_York",
    template: {
      ...ActivityTemplateTimelineFragmentFactory(),
    },
  };
}

export const TimelineMealBaseFragmentFactory = (): TimelineMealBaseFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "TimelineMeal",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    timezone: "America/New_York",
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    mealTemplate: {
      ...MealTemplateTimelineFragmentFactory(),
    },
    mealMenus: [{
      ...MealMenuTimelineFragmentFactory(),
    }],
  };
}

export const TimelineMealFragmentFactory = (): TimelineMealFragment =>  {
  return {
    ...TimelineMealBaseFragmentFactory(),
    images: [{
      ...ImageFragmentFactory(),
    }],
    comments: [{
      ...CommentTimelineFragmentFactory(),
    }],
    notifications: [{
      id: faker.datatype.uuid(),
      __typename: "AthleteSelfOnboardedNotification",
      viewedAt: faker.datatype.datetime().toISOString(),
    }],
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
    restaurantMenuLogs: [{
      ...RestaurantMenuLogTimelineFragmentFactory(),
    }],
    restaurantMenuLinkPlateOrders: [{
      ...RestaurantMenuLinkPlateOrderSharedTimelineFragmentFactory(),
    }],
    restaurantMenuLinkOrders: [{
      ...RestaurantMenuLinkOrderSharedTimelineFragmentFactory(),
    }],
    menuOrders: [{
      ...MenuOrderTimelineFragmentFactory(),
    }],
    mealMenuLogs: [{
      ...MealMenuLogTimelineFragmentFactory(),
    }],
  };
}

export const MealMenuWithItemPreviewsAndTimeInfoFragmentFactory = (): MealMenuWithItemPreviewsAndTimeInfoFragment =>  {
  return {
    timezone: "America/New_York",
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    prepTimeInMinutes: faker.datatype.number(),
    lastOrderTimeBeforeEndInMinutes: faker.datatype.number(),
    ...MealMenuWithItemPreviewsFragmentFactory(),
    theme: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const RestaurantMenuLogTimelineFragmentFactory = (): RestaurantMenuLogTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "RestaurantMenuLog",
    mealType: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    mealTemplate: {
      id: faker.datatype.uuid(),
    },
    restaurantMenu: {
      id: faker.datatype.uuid(),
    },
    restaurant: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    dateTime: faker.datatype.datetime().toISOString(),
    timezone: "America/New_York",
    items: [{
      id: faker.datatype.uuid(),
      ...MenuSelectionItemFormFragmentFactory(),
    }],
  };
}

export const FoodLogActivityTimelineFragmentFactory = (): FoodLogActivityTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "FoodLogActivity",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    activityType: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
    date: faker.datatype.datetime().toISOString().split("T")[0],
    startTime: faker.datatype.datetime().toISOString().split("T")[1],
    endTime: faker.datatype.datetime().toISOString().split("T")[1],
    timezone: "America/New_York",
    rpe: faker.datatype.number(),
    activityTemplate: {
      id: faker.datatype.uuid(),
    },
  };
}

export const MenuOrderTimelineFragmentFactory = (): MenuOrderTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuOrder",
    mealTemplate: {
      id: faker.datatype.uuid(),
    },
    mealMenu: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    pickupTime: faker.datatype.datetime().toISOString(),
    code: faker.internet.password(10, false, /[0-9A-Z]/),
    items: [{
      ...MenuOrderItemFormFragmentFactory(),
    }],
  };
}

export const MealMenuLogTimelineFragmentFactory = (): MealMenuLogTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealMenuLog",
    mealMenu: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    items: [{
      ...MealMenuLogItemFormFragmentFactory(),
    }],
  };
}

export const MealMenuTimelineFragmentFactory = (): MealMenuTimelineFragment =>  {
  return {
    ...MealMenuSharedTimelineFragmentFactory(),
    isOrderable: faker.datatype.boolean(),
    theme: faker.internet.password(10, false, /[0-9A-Z]/),
    mealMenuDiningStations: [{
      id: faker.datatype.uuid(),
    }],
  };
}

export const MealMenuWithItemPreviewsFragmentFactory = (): MealMenuWithItemPreviewsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    mealMenuDiningStations: [{
      ...MealMenuDiningStationWithItemPreviewsFragmentFactory(),
    }],
    restaurantMenuLinks: [{
      ...RestaurantMenuLinkWithItemPreviewsFragmentFactory(),
    }],
    isViewOnlyForMe: faker.datatype.boolean(),
  };
}

export const MealPlanDateTimelineFragmentFactory = (): MealPlanDateTimelineFragment =>  {
  return {
    __typename: "MealPlanDate",
    date: faker.datatype.datetime().toISOString().split("T")[0],
    timezone: "America/New_York",
    mealPlan: {
      ...MealPlanTimelineFragmentFactory(),
    },
  };
}

export const MealPlanTimelineFragmentFactory = (): MealPlanTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    athlete: {
      birthDate: faker.datatype.datetime().toISOString().split("T")[0],
    },
    macroProtocol: {
      ...FullMacroProtocolFragmentFactory(),
    },
    exchangeTargets: [{
      ...ExchangeAmountFragmentFactory(),
    }],
    mealTemplates: [{
      ...MealTemplateTimelineFragmentFactory(),
    }],
    activityTemplates: [{
      ...ActivityTemplateTimelineFragmentFactory(),
    }],
    promotedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    avoidedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    promotedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    avoidedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
  };
}

export const MealTemplateTimelineFragmentFactory = (): MealTemplateTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    meal: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      start: faker.datatype.datetime().toISOString().split("T")[1],
      end: faker.datatype.datetime().toISOString().split("T")[1],
      type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    },
    ...({
      __typename: "ExchangeMealTemplate",
      exchangeTargets: [{
        ...ExchangeAmountFragmentFactory(),
      }],
    }),
  };
}

export const ActivityTemplateTimelineFragmentFactory = (): ActivityTemplateTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ActivityTemplate",
    activity: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      start: faker.datatype.datetime().toISOString().split("T")[1],
      end: faker.datatype.datetime().toISOString().split("T")[1],
      type: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
    },
  };
}

export const CommentTimelineFragmentFactory = (): CommentTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Comment",
    text: faker.internet.password(10, false, /[0-9A-Z]/),
    at: faker.datatype.datetime().toISOString(),
    by: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    notification: {
      ...NotificationStubFragmentFactory(),
    },
  };
}

export const AthleteGoalConfigurationForCompliancePageFragmentFactory = (): AthleteGoalConfigurationForCompliancePageFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    emoji: faker.internet.password(10, false, /[0-9A-Z]/),
    timezone: faker.internet.password(10, false, /[0-9A-Z]/),
    category: {
      ...GoalCategoryFullFragmentFactory(),
    },
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    target: {
      ...GoalTargetForTableFragmentFactory(),
    },
    completedGoalCount: faker.datatype.number(),
    totalGoalCount: faker.datatype.number(),
  };
}

export const GoalTrackerGoalForComplianceFragmentFactory = (): GoalTrackerGoalForComplianceFragment =>  {
  return {
    id: faker.datatype.uuid(),
    date: faker.datatype.datetime().toISOString().split("T")[0],
    completedAtInUTC: faker.internet.password(10, false, /[0-9A-Z]/),
    value: faker.datatype.number(),
  };
}

export const GoalConfigurationForTableFragmentFactory = (): GoalConfigurationForTableFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    emoji: faker.internet.password(10, false, /[0-9A-Z]/),
    category: {
      ...GoalCategoryFullFragmentFactory(),
    },
    isArchived: faker.datatype.boolean(),
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    target: {
      ...GoalTargetForTableFragmentFactory(),
    },
    timezone: faker.internet.password(10, false, /[0-9A-Z]/),
    completedGoalCount: faker.datatype.number(),
    totalGoalCount: faker.datatype.number(),
    dayOfWeekAssignments: [faker.helpers.arrayElement(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"])],
    individualDates: [faker.datatype.datetime().toISOString().split("T")[0]],
    reminders: [{
      id: faker.datatype.uuid(),
      time: faker.datatype.datetime().toISOString().split("T")[1],
      message: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const GoalCategoryFullFragmentFactory = (): GoalCategoryFullFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultEmoji: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const GoalTargetForTableFragmentFactory = (): GoalTargetForTableFragment =>  {
  return {
    id: faker.datatype.uuid(),
    value: faker.datatype.number(),
    unit: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const AssignFromMealPlanTemplateFragmentFactory = (): AssignFromMealPlanTemplateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    orgId: faker.datatype.uuid(),
    orgGroupId: faker.datatype.uuid(),
    macroProtocol: {
      calorieBudget: {
        rmrMethod: faker.helpers.arrayElement(["cunningham", "mifflin", "harrisBenedict", "average"]),
        goals: [{
          ...MealPlanTemplateGoalFragmentFactory(),
        }],
      },
      anthropometry: {
        ...({
          ...AnthropometryTemplateFragmentFactory(),
        }),
      },
    },
  };
}

export const AssignFromMealPlanTemplateAthleteFragmentFactory = (): AssignFromMealPlanTemplateAthleteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    birthDate: faker.datatype.datetime().toISOString().split("T")[0],
    mostRecentAnthropometryEntry: {
      ...AnthropometryEntryFragmentFactory(),
    },
  };
}

export const MealTemplateScheduleFragmentFactory = (): MealTemplateScheduleFragment =>  {
  return {
    ...MealTemplateTimeFragmentFactory(),
    hasDateModifications: faker.datatype.boolean(),
  };
}

export const ActivityTemplateScheduleFragmentFactory = (): ActivityTemplateScheduleFragment =>  {
  return {
    ...ActivityTemplateTimeFragmentFactory(),
    hasDateModifications: faker.datatype.boolean(),
  };
}

export const MealTemplateTimeFragmentFactory = (): MealTemplateTimeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    meal: {
      ...MealFragmentFactory(),
    },
  };
}

export const ActivityTemplateTimeFragmentFactory = (): ActivityTemplateTimeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    activity: {
      ...ActivityFragmentFactory(),
    },
  };
}

export const MealPlanDayOfWeekAssignmentFragmentFactory = (): MealPlanDayOfWeekAssignmentFragment =>  {
  return {
    dayOfWeekPriority: {
      ...DayOfWeekPriorityFragmentFactory(),
    },
    mealTemplateModifications: [{
      ...MealTemplateModificationFragmentFactory(),
    }],
    activityTemplateModifications: [{
      ...ActivityTemplateModificationFragmentFactory(),
    }],
  };
}

export const MealPlanCalendarDateAssignmentFragmentFactory = (): MealPlanCalendarDateAssignmentFragment =>  {
  return {
    date: faker.datatype.datetime().toISOString().split("T")[0],
    mealPlan: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      mealTemplates: [{
        ...MealTemplateTimeFragmentFactory(),
      }],
      activityTemplates: [{
        ...ActivityTemplateTimeFragmentFactory(),
      }],
      daysOfWeek: [{
        ...MealPlanDayOfWeekAssignmentFragmentFactory(),
      }],
    },
    dayOfWeekPriority: {
      ...DayOfWeekPriorityFragmentFactory(),
    },
    mealTemplateModifications: [{
      ...MealTemplateModificationFragmentFactory(),
    }],
    activityTemplateModifications: [{
      ...ActivityTemplateModificationFragmentFactory(),
    }],
  };
}

export const MealPlanDateModificationsFragmentFactory = (): MealPlanDateModificationsFragment =>  {
  return {
    date: faker.datatype.datetime().toISOString().split("T")[0],
    mealTemplateModifications: [{
      ...MealTemplateModificationFragmentFactory(),
    }],
    activityTemplateModifications: [{
      ...ActivityTemplateModificationFragmentFactory(),
    }],
  };
}

export const MealPlanDateAssignmentPreviewFragmentFactory = (): MealPlanDateAssignmentPreviewFragment =>  {
  return {
    date: faker.datatype.datetime().toISOString().split("T")[0],
    mealPlan: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const MealTemplateModificationFragmentFactory = (): MealTemplateModificationFragment =>  {
  return {
    mealTemplate: {
      id: faker.datatype.uuid(),
    },
    start: faker.datatype.datetime().toISOString().split("T")[1],
    end: faker.datatype.datetime().toISOString().split("T")[1],
  };
}

export const ActivityTemplateModificationFragmentFactory = (): ActivityTemplateModificationFragment =>  {
  return {
    activityTemplate: {
      id: faker.datatype.uuid(),
    },
    start: faker.datatype.datetime().toISOString().split("T")[1],
    end: faker.datatype.datetime().toISOString().split("T")[1],
  };
}

export const DayOfWeekPriorityFragmentFactory = (): DayOfWeekPriorityFragment =>  {
  return {
    dayOfWeek: faker.helpers.arrayElement(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]),
    priority: faker.datatype.number(),
  };
}

export const ExternalEventFragmentFactory = (): ExternalEventFragment =>  {
  return {
    id: faker.internet.password(10, false, /[0-9A-Z]/),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    timezone: "America/New_York",
    startDate: faker.datatype.datetime().toISOString(),
    endDate: faker.datatype.datetime().toISOString(),
    isAllDay: faker.datatype.boolean(),
    eventType: faker.internet.password(10, false, /[0-9A-Z]/),
    location: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const CommonMealPlanFragmentFactory = (): CommonMealPlanFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealPlan",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    isShared: faker.datatype.boolean(),
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    type: faker.helpers.arrayElement(["macro", "exchange"]),
    updatedAt: faker.datatype.datetime().toISOString(),
    exchangeSet: {
      ...ExchangeSetWithExchangesFragmentFactory(),
    },
    isAutosaving: faker.datatype.boolean(),
    exchangeTargets: [{
      ...EditExchangeAmountFragmentFactory(),
    }],
    macroProtocol: {
      ...FullMacroProtocolFragmentFactory(),
    },
    schedule: {
      ...ScheduleFragmentFactory(),
    },
    avoidedFoodGroups: [{
      ...FullFoodGroupFragmentFactory(),
    }],
    promotedFoodGroups: [{
      ...FullFoodGroupFragmentFactory(),
    }],
    avoidedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    promotedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    mostRecentEvent: {
      ...EventFragmentFactory(),
    },
    activityTemplates: [{
      ...ActivityTemplateFragmentFactory(),
    }],
    mealTemplates: [{
      ...({
        ...EditFullExchangeMealTemplateFragmentFactory(),
      }),
    }],
  };
}

export const AthleteSummaryAthleteFragmentFactory = (): AthleteSummaryAthleteFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    foodLogNotificationSettings: {
      ...FoodLogNotificationSettingsFragmentFactory(),
    },
    position: {
      ...PositionFragmentFactory(),
    },
    mostRecentAnthropometryEntry: {
      ...AnthropometryEntryFragmentFactory(),
    },
    currentGoal: {
      ...FullGoalFragmentFactory(),
    },
    likedFoods: [{
      ...FoodFragmentFactory(),
    }],
    dislikedFoods: [{
      ...FoodFragmentFactory(),
    }],
    likedFoodGroups: [{
      ...FullFoodGroupFragmentFactory(),
    }],
    dislikedFoodGroups: [{
      ...FullFoodGroupFragmentFactory(),
    }],
    team: {
      ...TeamWithSportFragmentFactory(),
      foodLogNotificationSettings: {
        ...FoodLogNotificationSettingsFragmentFactory(),
      },
      defaultHideAnthropometry: faker.datatype.boolean(),
      defaultMacroDisplaySettings: {
        ...MacroMealPlanDisplaySettingsFragmentFactory(),
      },
      defaultExchangeDisplaySettings: {
        ...ExchangeMealPlanDisplaySettingsFragmentFactory(),
      },
      defaultNoTargetsDisplaySettings: {
        ...NoTargetsDisplaySettingsFragmentFactory(),
      },
    },
    mealPlans: [{
      ...MealPlanFragmentFactory(),
      events: [{
        ...EventFragmentFactory(),
      }],
    }],
    defaultHideAnthropometry: faker.datatype.boolean(),
    defaultMacroDisplaySettings: {
      ...MacroMealPlanDisplaySettingsFragmentFactory(),
    },
    defaultExchangeDisplaySettings: {
      ...ExchangeMealPlanDisplaySettingsFragmentFactory(),
    },
    defaultNoTargetsDisplaySettings: {
      ...NoTargetsDisplaySettingsFragmentFactory(),
    },
    macroDisplaySettings: {
      ...MacroMealPlanDisplaySettingsFragmentFactory(),
    },
    exchangeDisplaySettings: {
      ...ExchangeMealPlanDisplaySettingsFragmentFactory(),
    },
    hideAnthropometry: faker.datatype.boolean(),
    noTargetsDisplaySettings: {
      ...NoTargetsDisplaySettingsFragmentFactory(),
    },
    user: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastLogin: faker.datatype.datetime().toISOString(),
      username: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    orgMembership: {
      id: faker.datatype.uuid(),
      isActive: faker.datatype.boolean(),
      teamworksId: faker.datatype.number(),
      isNotemealOnly: faker.datatype.boolean(),
    },
    allOrderedNamedTags: [{
      ...NamedTagForSelectionFragmentFactory(),
    }],
    activeTransfer: {
      id: faker.datatype.uuid(),
    },
  };
}

export const EditedAthleteFragmentFactory = (): EditedAthleteFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    timelineMealNotifications: [{
      ...NotificationStubFragmentFactory(),
    }],
    team: {
      ...TeamWithSportFragmentFactory(),
      athletes: [{
        id: faker.datatype.uuid(),
      }],
      recentNotifications: [{
        ...NotificationStubFragmentFactory(),
      }],
    },
    position: {
      ...PositionFragmentFactory(),
    },
    allOrderedNamedTags: [{
      ...NamedTagForSelectionFragmentFactory(),
    }],
  };
}

export const AthletePageAthleteFragmentFactory = (): AthletePageAthleteFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    team: {
      id: faker.datatype.uuid(),
    },
    orgMembership: {
      id: faker.datatype.uuid(),
      isActive: faker.datatype.boolean(),
    },
    timelineMealNotifications: [{
      ...NotificationStubFragmentFactory(),
    }],
  };
}

export const ClientCredentialFragmentFactory = (): ClientCredentialFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    clientId: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
  };
}

export const DiningStationTemplatePreviewFragmentFactory = (): DiningStationTemplatePreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    __typename: "DiningStationTemplate",
  };
}

export const DiningStationTemplateFullFragmentFactory = (): DiningStationTemplateFullFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "DiningStationTemplate",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultMaxAmount: faker.datatype.number(),
    mealTypes: [faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"])],
    menuItemAppearances: [{
      ...MenuItemAppearancePreviewFragmentFactory(),
    }],
  };
}

export const DiningStationTemplateTableFragmentFactory = (): DiningStationTemplateTableFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "DiningStationTemplate",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    mealTypes: [faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"])],
    menuItemAppearances: [{
      position: faker.datatype.number(),
      menuItem: {
        id: faker.datatype.uuid(),
        isOneOff: faker.datatype.boolean(),
        isMissingIngredients: faker.datatype.boolean(),
      },
    }],
  };
}

export const MenuItemAppearanceForMenuBuilderFragmentFactory = (): MenuItemAppearanceForMenuBuilderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    menuItem: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      isMissingIngredients: faker.datatype.boolean(),
    },
  };
}

export const KdsPageMealMenuInDateRangeFragmentFactory = (): KdsPageMealMenuInDateRangeFragment =>  {
  return {
    ...MealMenuSummaryFragmentFactory(),
    mealMenuDiningStations: [{
      ...KdsPageMealMenuDiningStationFragmentFactory(),
    }],
  };
}

export const MealMenuSummaryFragmentFactory = (): MealMenuSummaryFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    start: faker.datatype.datetime().toISOString(),
    lastOrderTimeBeforeEndInMinutes: faker.datatype.number(),
    durationInMinutes: faker.datatype.number(),
  };
}

export const KdsPageMealMenuFragmentFactory = (): KdsPageMealMenuFragment =>  {
  return {
    ...MealMenuSummaryFragmentFactory(),
    allOrders: [{
      ...KdsPageMenuOrderPreviewFragmentFactory(),
    }],
    allMealMenuPlateOrders: [{
      ...KdsPageMealMenuPlateOrderPreviewFragmentFactory(),
    }],
  };
}

export const KdsAnalyzeDialogOrderFragmentFactory = (): KdsAnalyzeDialogOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuOrder",
    itemsByDiningStation: [{
      ...KdsTicketMenuOrderItemFragmentFactory(),
    }],
  };
}

export const KdsAnalyzeDialogPlateOrderFragmentFactory = (): KdsAnalyzeDialogPlateOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealMenuPlateOrder",
    itemsByDiningStation: [{
      ...KdsTicketMealMenuPlateOrderItemFragmentFactory(),
    }],
  };
}

export const KdsPageOrderPreviewAthleteFragmentFactory = (): KdsPageOrderPreviewAthleteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    position: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    team: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    jerseyNumber: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const KdsPageMenuOrderPreviewFragmentFactory = (): KdsPageMenuOrderPreviewFragment =>  {
  return {
    __typename: "MenuOrder",
    id: faker.datatype.uuid(),
    pickupTime: faker.datatype.datetime().toISOString(),
    createdAt: faker.datatype.datetime().toISOString(),
    updatedAt: faker.datatype.datetime().toISOString(),
    code: faker.internet.password(10, false, /[0-9A-Z]/),
    itemCount: faker.datatype.number(),
    itemsByDiningStation: [{
      id: faker.datatype.uuid(),
      status: faker.helpers.arrayElement(["new", "cooking", "done", "cancelled"]),
      updateCount: faker.datatype.number(),
      updatedAt: faker.datatype.datetime().toISOString(),
      menuItem: {
        id: faker.datatype.uuid(),
      },
    }],
    mealMenu: {
      id: faker.datatype.uuid(),
    },
    userFullName: faker.internet.password(10, false, /[0-9A-Z]/),
    athlete: {
      ...KdsPageOrderPreviewAthleteFragmentFactory(),
    },
  };
}

export const KdsPageMealMenuPlateOrderPreviewFragmentFactory = (): KdsPageMealMenuPlateOrderPreviewFragment =>  {
  return {
    __typename: "MealMenuPlateOrder",
    id: faker.datatype.uuid(),
    createdAt: faker.datatype.datetime().toISOString(),
    updatedAt: faker.datatype.datetime().toISOString(),
    code: faker.internet.password(10, false, /[0-9A-Z]/),
    itemCount: faker.datatype.number(),
    itemsByDiningStation: [{
      id: faker.internet.password(10, false, /[0-9A-Z]/),
      status: faker.helpers.arrayElement(["new", "cooking", "done", "cancelled"]),
      updateCount: faker.datatype.number(),
      updatedAt: faker.datatype.datetime().toISOString(),
      menuItem: {
        id: faker.datatype.uuid(),
      },
    }],
    mealMenu: {
      id: faker.datatype.uuid(),
    },
    userFullName: faker.internet.password(10, false, /[0-9A-Z]/),
    athlete: {
      ...KdsPageOrderPreviewAthleteFragmentFactory(),
    },
    parentBulkOrder: {
      pickupTime: faker.datatype.datetime().toISOString(),
      deliveryLocation: faker.internet.password(10, false, /[0-9A-Z]/),
      code: faker.internet.password(10, false, /[0-9A-Z]/),
      team: {
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
  };
}

export const MenuOrderItemStatusesPublisherScopeFragmentFactory = (): MenuOrderItemStatusesPublisherScopeFragment =>  {
  return {
    mealMenuId: faker.datatype.uuid(),
  };
}

export const MealMenuPlateItemOrderStatusPublisherScopeFragmentFactory = (): MealMenuPlateItemOrderStatusPublisherScopeFragment =>  {
  return {
    mealMenuId: faker.datatype.uuid(),
  };
}

export const MenuOrderPublisherScopeFragmentFactory = (): MenuOrderPublisherScopeFragment =>  {
  return {
    mealMenuId: faker.datatype.uuid(),
  };
}

export const BulkOrderPublisherScopeFragmentFactory = (): BulkOrderPublisherScopeFragment =>  {
  return {
    mealMenuId: faker.datatype.uuid(),
  };
}

export const KdsTicketOrderAthleteFragmentFactory = (): KdsTicketOrderAthleteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    jerseyNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    team: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    position: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    dislikedFoods: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    dislikedFoodGroups: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    printableTagsForAthlete: [{
      ...NamedTagForSelectionFragmentFactory(),
    }],
  };
}

export const KdsTicketMenuOrderFragmentFactory = (): KdsTicketMenuOrderFragment =>  {
  return {
    __typename: "MenuOrder",
    id: faker.datatype.uuid(),
    mealMenu: {
      id: faker.datatype.uuid(),
    },
    pickupTime: faker.datatype.datetime().toISOString(),
    createdAt: faker.datatype.datetime().toISOString(),
    updatedAt: faker.datatype.datetime().toISOString(),
    code: faker.internet.password(10, false, /[0-9A-Z]/),
    itemsByDiningStation: [{
      ...KdsTicketMenuOrderItemFragmentFactory(),
    }],
    itemCount: faker.datatype.number(),
    userFullName: faker.internet.password(10, false, /[0-9A-Z]/),
    athlete: {
      ...KdsTicketOrderAthleteFragmentFactory(),
    },
  };
}

export const KdsTicketMealMenuPlateOrderFragmentFactory = (): KdsTicketMealMenuPlateOrderFragment =>  {
  return {
    __typename: "MealMenuPlateOrder",
    id: faker.datatype.uuid(),
    mealMenu: {
      id: faker.datatype.uuid(),
    },
    createdAt: faker.datatype.datetime().toISOString(),
    updatedAt: faker.datatype.datetime().toISOString(),
    code: faker.internet.password(10, false, /[0-9A-Z]/),
    itemsByDiningStation: [{
      ...KdsTicketMealMenuPlateOrderItemFragmentFactory(),
    }],
    itemCount: faker.datatype.number(),
    userFullName: faker.internet.password(10, false, /[0-9A-Z]/),
    athlete: {
      ...KdsTicketOrderAthleteFragmentFactory(),
    },
    parentBulkOrder: {
      pickupTime: faker.datatype.datetime().toISOString(),
      deliveryLocation: faker.internet.password(10, false, /[0-9A-Z]/),
      code: faker.internet.password(10, false, /[0-9A-Z]/),
      team: {
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
  };
}

export const KdsTicketMenuOrderItemFragmentFactory = (): KdsTicketMenuOrderItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    status: faker.helpers.arrayElement(["new", "cooking", "done", "cancelled"]),
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
    updateCount: faker.datatype.number(),
    updatedAt: faker.datatype.datetime().toISOString(),
    menuItem: {
      ...KdsPageMenuItemFragmentFactory(),
    },
    options: [{
      ...KdsTicketMenuOrderItemOptionFragmentFactory(),
    }],
  };
}

export const KdsTicketMealMenuPlateOrderItemFragmentFactory = (): KdsTicketMealMenuPlateOrderItemFragment =>  {
  return {
    id: faker.internet.password(10, false, /[0-9A-Z]/),
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    status: faker.helpers.arrayElement(["new", "cooking", "done", "cancelled"]),
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
    updateCount: faker.datatype.number(),
    updatedAt: faker.datatype.datetime().toISOString(),
    menuItem: {
      ...KdsPageMenuItemFragmentFactory(),
    },
    options: [{
      ...KdsTicketMealMenuPlateOrderItemOptionFragmentFactory(),
    }],
  };
}

export const KdsPageMenuItemFragmentFactory = (): KdsPageMenuItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    servingName: faker.internet.password(10, false, /[0-9A-Z]/),
    choices: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      position: faker.datatype.number(),
      options: [{
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        position: faker.datatype.number(),
      }],
    }],
  };
}

export const KdsTicketMenuOrderItemOptionFragmentFactory = (): KdsTicketMenuOrderItemOptionFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      ...KdsPageMenuItemChoiceOptionFragmentFactory(),
    },
  };
}

export const KdsTicketMealMenuPlateOrderItemOptionFragmentFactory = (): KdsTicketMealMenuPlateOrderItemOptionFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      ...KdsPageMenuItemChoiceOptionFragmentFactory(),
    },
  };
}

export const KdsPageMenuItemChoiceOptionFragmentFactory = (): KdsPageMenuItemChoiceOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const KdsPageMealMenuDiningStationFragmentFactory = (): KdsPageMealMenuDiningStationFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    menuItemAppearances: [{
      menuItem: {
        id: faker.datatype.uuid(),
      },
    }],
  };
}

export const KioskTeamPreviewFragmentFactory = (): KioskTeamPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const KioskMealMenuPreviewFragmentFactory = (): KioskMealMenuPreviewFragment =>  {
  return {
    ...MealMenuSharedTimelineFragmentFactory(),
    teams: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    athletes: [{
      id: faker.datatype.uuid(),
    }],
    hasAdvancedSelection: faker.datatype.boolean(),
    userOrderLimit: faker.datatype.number(),
    isHubCheckInEnabled: faker.datatype.boolean(),
    hubEventUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    isOrderable: faker.datatype.boolean(),
    mealMenuDiningStations: [{
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      id: faker.datatype.uuid(),
      menuItemAppearances: [{
        menuItem: {
          id: faker.datatype.uuid(),
          name: faker.internet.password(10, false, /[0-9A-Z]/),
          imageUrl: faker.internet.password(10, false, /[0-9A-Z]/),
        },
      }],
    }],
    theme: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const OrderKioskAthletePreviewFragmentFactory = (): OrderKioskAthletePreviewFragment =>  {
  return {
    ...KioskAthleteFragmentFactory(),
    kioskPreviewOrderInfo: [{
      ...MenuOrderInfoFragmentFactory(),
    }],
  };
}

export const MenuOrderInfoFragmentFactory = (): MenuOrderInfoFragment =>  {
  return {
    mealMenuId: faker.datatype.uuid(),
    menuOrderCount: faker.datatype.number(),
    mostRecentEditableMenuOrderId: faker.datatype.uuid(),
  };
}

export const OrdersForAthleteFragmentFactory = (): OrdersForAthleteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuOrder",
    items: [{
      id: faker.datatype.uuid(),
      status: faker.helpers.arrayElement(["new", "cooking", "done", "cancelled"]),
    }],
    createdAt: faker.datatype.datetime().toISOString(),
    code: faker.internet.password(10, false, /[0-9A-Z]/),
    pickupTime: faker.datatype.datetime().toISOString(),
    mealMenu: {
      id: faker.datatype.uuid(),
    },
  };
}

export const CheckInKioskAthletePreviewFragmentFactory = (): CheckInKioskAthletePreviewFragment =>  {
  return {
    ...KioskAthleteFragmentFactory(),
    checkInsForMealMenus: [{
      ...CheckInFragmentFactory(),
    }],
  };
}

export const CheckInFragmentFactory = (): CheckInFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealMenuId: faker.datatype.uuid(),
    athleteId: faker.datatype.uuid(),
  };
}

export const KioskAthleteFragmentFactory = (): KioskAthleteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    team: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    user: {
      id: faker.datatype.uuid(),
    },
  };
}

export const AthleteForKioskAthleteFragmentFactory = (): AthleteForKioskAthleteFragment =>  {
  return {
    ...KioskAthleteFragmentFactory(),
    mealPlans: [{
      ...PreviewMealPlanFragmentFactory(),
      createdAt: faker.datatype.datetime().toISOString(),
    }],
    macroDisplaySettings: {
      mealProgressViews: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    },
    timelineDate: {
      ...TimelineDateForKioskFragmentFactory(),
    },
    likedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    dislikedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    likedFoods: [{
      ...FoodFragmentFactory(),
    }],
    dislikedFoods: [{
      ...FoodFragmentFactory(),
    }],
    ordersForMenu: [{
      ...MenuOrderFormFragmentFactory(),
    }],
  };
}

export const TimelineDateForKioskFragmentFactory = (): TimelineDateForKioskFragment =>  {
  return {
    date: faker.datatype.datetime().toISOString().split("T")[0],
    timezone: "America/New_York",
    mealPlan: {
      ...MealPlanForKioskFragmentFactory(),
    },
    meals: [{
      ...TimelineMealOrTemplateForKioskFragmentFactory(),
    }],
    activities: [{
      ...TimelineActivityOrTemplateForKioskFragmentFactory(),
    }],
  };
}

export const TimelineActivityOrTemplateForKioskFragmentFactory = (): TimelineActivityOrTemplateForKioskFragment =>  {
  return {
    ...({
      ...FoodLogActivityTimelineFragmentFactory(),
    }),
  };
}

export const TimelineMealOrTemplateForKioskFragmentFactory = (): TimelineMealOrTemplateForKioskFragment =>  {
  return {
    ...({
      ...TimelineMealForKioskFragmentFactory(),
    }),
  };
}

export const TimelineMealTemplateForKioskFragmentFactory = (): TimelineMealTemplateForKioskFragment =>  {
  return {
    __typename: "TimelineMealTemplate",
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    timezone: "America/New_York",
    template: {
      ...MealTemplateSharedTimelineFragmentFactory(),
      ...MealTemplateForTargetsFragmentFactory(),
    },
    mealMenus: [{
      ...MealMenuSharedTimelineFragmentFactory(),
    }],
  };
}

export const TimelineMealForKioskFragmentFactory = (): TimelineMealForKioskFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "TimelineMeal",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    timezone: "America/New_York",
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    mealMenus: [{
      ...MealMenuSharedTimelineFragmentFactory(),
    }],
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
    mealTemplate: {
      ...MealTemplateSharedTimelineFragmentFactory(),
      ...MealTemplateForTargetsFragmentFactory(),
    },
    restaurantMenuLogs: [{
      ...RestaurantMenuLogTimelineFragmentFactory(),
    }],
    restaurantMenuLinkPlateOrders: [{
      ...RestaurantMenuLinkPlateOrderSharedTimelineFragmentFactory(),
    }],
    restaurantMenuLinkOrders: [{
      ...RestaurantMenuLinkOrderSharedTimelineFragmentFactory(),
    }],
    menuOrders: [{
      ...MenuOrderTimelineFragmentFactory(),
    }],
    mealMenuLogs: [{
      ...MealMenuLogTimelineFragmentFactory(),
    }],
  };
}

export const MealPlanForKioskFragmentFactory = (): MealPlanForKioskFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealTemplates: [{
      ...MealTemplateSharedTimelineFragmentFactory(),
      ...MealTemplateForTargetsFragmentFactory(),
    }],
    activityTemplates: [{
      ...ActivityTemplateTimelineFragmentFactory(),
    }],
    promotedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    avoidedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    promotedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    avoidedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
  };
}

export const DietitianMealPlanTemplatePendingStateFragmentFactory = (): DietitianMealPlanTemplatePendingStateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    pendingState: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const DietitianMealPlanTemplateIsSharedFragmentFactory = (): DietitianMealPlanTemplateIsSharedFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isShared: faker.datatype.boolean(),
  };
}

export const DietitianMealPlanTemplateAssignFragmentFactory = (): DietitianMealPlanTemplateAssignFragment =>  {
  return {
    id: faker.datatype.uuid(),
    teams: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    hasAdvancedSelection: faker.datatype.boolean(),
    advancedSelection: {
      ...AdvancedSelectionFragmentFactory(),
    },
  };
}

export const DietitianMealPlanTemplateListItemFragmentFactory = (): DietitianMealPlanTemplateListItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    isShared: faker.datatype.boolean(),
    pendingState: faker.internet.password(10, false, /[0-9A-Z]/),
    teams: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    hasAdvancedSelection: faker.datatype.boolean(),
    advancedSelection: {
      ...AdvancedSelectionFragmentFactory(),
    },
  };
}

export const MealPlanTemplateOwnerPreviewFragmentFactory = (): MealPlanTemplateOwnerPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const DietitianOrgGroupMealPlanTemplateListItemFragmentFactory = (): DietitianOrgGroupMealPlanTemplateListItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    pendingState: faker.internet.password(10, false, /[0-9A-Z]/),
    owner: {
      ...MealPlanTemplateOwnerPreviewFragmentFactory(),
    },
    updatedAt: faker.datatype.datetime().toISOString(),
    copiedCount: faker.datatype.number(),
  };
}

export const DietitianStaffMealPlanTemplateListItemFragmentFactory = (): DietitianStaffMealPlanTemplateListItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    isShared: faker.datatype.boolean(),
    teams: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    hasAdvancedSelection: faker.datatype.boolean(),
    advancedSelection: {
      ...AdvancedSelectionFragmentFactory(),
    },
  };
}

export const AthleteForMenuAttendanceFragmentFactory = (): AthleteForMenuAttendanceFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    team: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    position: {
      ...PositionFragmentFactory(),
    },
  };
}

export const PlannedMenuGridMealFragmentFactory = (): PlannedMenuGridMealFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    startTime: faker.internet.password(10, false, /[0-9A-Z]/),
    endTime: faker.internet.password(10, false, /[0-9A-Z]/),
    themes: [faker.internet.password(10, false, /[0-9A-Z]/)],
    plannedMenuMealRows: [{
      ...PlannedMenuMealGridRowFragmentFactory(),
    }],
  };
}

export const PlannedMenuSummaryFragmentFactory = (): PlannedMenuSummaryFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    firstOccurrence: faker.datatype.datetime().toISOString().split("T")[0],
    lastOccurrence: faker.datatype.datetime().toISOString().split("T")[0],
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    occurrence: faker.helpers.arrayElement(["daily", "weekdays"]),
    timezone: faker.internet.password(10, false, /[0-9A-Z]/),
    lastEditedAt: faker.datatype.datetime().toISOString(),
    lockCreatedAt: faker.datatype.datetime().toISOString(),
    lockCreatedBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    weeks: [{
      ...PlannedMenuWeekSummaryFragmentFactory(),
    }],
  };
}

export const PlannedMenuWeekSummaryFragmentFactory = (): PlannedMenuWeekSummaryFragment =>  {
  return {
    id: faker.datatype.uuid(),
    week: faker.datatype.number(),
    disabledDays: [faker.helpers.arrayElement(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"])],
  };
}

export const PlannedMenuMealGridRowFragmentFactory = (): PlannedMenuMealGridRowFragment =>  {
  return {
    id: faker.datatype.uuid(),
    diningStationName: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    foodType: faker.internet.password(10, false, /[0-9A-Z]/),
    plannedMenuMealRowItems: [{
      ...PlannedMenuMealRowGridItemFragmentFactory(),
    }],
  };
}

export const PlannedMenuMealRowGridItemFragmentFactory = (): PlannedMenuMealRowGridItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    dayOfWeek: faker.helpers.arrayElement(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]),
    position: faker.datatype.number(),
    menuItem: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      isMissingIngredients: faker.datatype.boolean(),
    },
  };
}

export const AthletesBirthdaysFragmentFactory = (): AthletesBirthdaysFragment =>  {
  return {
    id: faker.datatype.uuid(),
    birthDate: faker.datatype.datetime().toISOString().split("T")[0],
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    team: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const MenuItemForDigitalDisplayFragmentFactory = (): MenuItemForDigitalDisplayFragment =>  {
  return {
    id: faker.datatype.uuid(),
    menuItem: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      description: faker.internet.password(10, false, /[0-9A-Z]/),
      servingAmounts: [{
        ...FullServingAmountFragmentFactory(),
      }],
      choices: [{
        id: faker.datatype.uuid(),
      }],
    },
  };
}

export const MealMenuDiningStationForDigitalDisplayFragmentFactory = (): MealMenuDiningStationForDigitalDisplayFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    menuItemAppearances: [{
      ...MenuItemForDigitalDisplayFragmentFactory(),
    }],
  };
}

export const DigiDisplaysMealMenuFragmentFactory = (): DigiDisplaysMealMenuFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    mealMenuDiningStations: [{
      ...MealMenuDiningStationForDigitalDisplayFragmentFactory(),
    }],
  };
}

export const PlannedMenuTableItemFragmentFactory = (): PlannedMenuTableItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    firstOccurrence: faker.datatype.datetime().toISOString().split("T")[0],
    lastOccurrence: faker.datatype.datetime().toISOString().split("T")[0],
    occurrence: faker.helpers.arrayElement(["daily", "weekdays"]),
    timezone: faker.internet.password(10, false, /[0-9A-Z]/),
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    weeks: [{
      id: faker.datatype.uuid(),
      week: faker.datatype.number(),
      disabledDays: [faker.helpers.arrayElement(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"])],
    }],
    mealTypes: [faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"])],
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    lastEditedAt: faker.datatype.datetime().toISOString(),
    lastEditedBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const PlannedMenuFormFragmentFactory = (): PlannedMenuFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    occurrence: faker.helpers.arrayElement(["daily", "weekdays"]),
    timezone: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MenuItemTableFragmentFactory = (): MenuItemTableFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    servingName: faker.internet.password(10, false, /[0-9A-Z]/),
    isMissingIngredients: faker.datatype.boolean(),
    history: {
      id: faker.datatype.uuid(),
      ratingCounts: {
        ...RatingCountValuesFragmentFactory(),
      },
    },
    choices: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    ratingCounts: {
      ...RatingCountValuesFragmentFactory(),
    },
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    foodCategory: {
      ...FoodCategoryNameFragmentFactory(),
    },
    aiAugmented: {
      isAiGenerated: faker.datatype.boolean(),
      isAiTranscribed: faker.datatype.boolean(),
    },
  };
}

export const RatingForMenuItemFragmentFactory = (): RatingForMenuItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    review: faker.internet.password(10, false, /[0-9A-Z]/),
    value: faker.helpers.arrayElement(["one", "two", "three", "four", "five"]),
    createdAt: faker.datatype.datetime().toISOString(),
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    menuOrderItem: {
      id: faker.datatype.uuid(),
      order: {
        id: faker.datatype.uuid(),
        pickupTime: faker.datatype.datetime().toISOString(),
      },
    },
    menuItem: {
      id: faker.datatype.uuid(),
      createdAt: faker.datatype.datetime().toISOString(),
      isCurrent: faker.datatype.boolean(),
    },
  };
}

export const RatingCountValuesFragmentFactory = (): RatingCountValuesFragment =>  {
  return {
    one: faker.datatype.number(),
    two: faker.datatype.number(),
    three: faker.datatype.number(),
    four: faker.datatype.number(),
    five: faker.datatype.number(),
  };
}

export const BonAppetitCafeFragmentFactory = (): BonAppetitCafeFragment =>  {
  return {
    id: faker.internet.password(10, false, /[0-9A-Z]/),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const BonAppetitMenusPreviewFragmentFactory = (): BonAppetitMenusPreviewFragment =>  {
  return {
    cafeId: faker.internet.password(10, false, /[0-9A-Z]/),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    days: [{
      date: faker.internet.password(10, false, /[0-9A-Z]/),
      dayParts: [{
        id: faker.internet.password(10, false, /[0-9A-Z]/),
        label: faker.internet.password(10, false, /[0-9A-Z]/),
        startTime: faker.internet.password(10, false, /[0-9A-Z]/),
        endTime: faker.internet.password(10, false, /[0-9A-Z]/),
      }],
    }],
  };
}

export const CbordBusinessUnitFragmentFactory = (): CbordBusinessUnitFragment =>  {
  return {
    ...({
      ...CbordFacilityFragmentFactory(),
    }),
  };
}

export const CbordFacilityFragmentFactory = (): CbordFacilityFragment =>  {
  return {
    __typename: "CbordFacility",
    oid: faker.datatype.number(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    businessUnits: [{
      ...({
        __typename: "CbordFacility",
        oid: faker.datatype.number(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      }),
    }],
  };
}

export const CbordServiceUnitFragmentFactory = (): CbordServiceUnitFragment =>  {
  return {
    __typename: "CbordServiceUnit",
    oid: faker.datatype.number(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    facilityOid: faker.datatype.number(),
  };
}

export const CbordServiceMenuFragmentFactory = (): CbordServiceMenuFragment =>  {
  return {
    oid: faker.datatype.number(),
    meal: faker.internet.password(10, false, /[0-9A-Z]/),
    serviceDate: faker.datatype.datetime().toISOString(),
    menuItems: [{
      ...CbordMenuItemFragmentFactory(),
    }],
  };
}

export const CbordMenuItemFragmentFactory = (): CbordMenuItemFragment =>  {
  return {
    itemOid: faker.datatype.number(),
    serviceName: faker.internet.password(10, false, /[0-9A-Z]/),
    servedUofmName: faker.internet.password(10, false, /[0-9A-Z]/),
    serviceStationOid: faker.datatype.number(),
    serviceStationSort: faker.internet.password(10, false, /[0-9A-Z]/),
    serviceStationName: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const CompassLocationFragmentFactory = (): CompassLocationFragment =>  {
  return {
    locationId: faker.datatype.number(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    mealPeriods: [{
      displayOrder: faker.datatype.number(),
      mealPeriodId: faker.datatype.number(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      stations: [{
        displayOrder: faker.datatype.number(),
        stationId: faker.datatype.number(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      }],
    }],
  };
}

export const CompassMenuItemFragmentFactory = (): CompassMenuItemFragment =>  {
  return {
    id: faker.datatype.number(),
    date: faker.internet.password(10, false, /[0-9A-Z]/),
    displayOrder: faker.datatype.number(),
    stationId: faker.datatype.number(),
    mealPeriodId: faker.datatype.number(),
    locationId: faker.datatype.number(),
    portion: faker.internet.password(10, false, /[0-9A-Z]/),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const PlannedMenuWithWeeksFragmentFactory = (): PlannedMenuWithWeeksFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    timezone: faker.internet.password(10, false, /[0-9A-Z]/),
    occurrence: faker.helpers.arrayElement(["daily", "weekdays"]),
    firstOccurrence: faker.datatype.datetime().toISOString().split("T")[0],
    lastOccurrence: faker.datatype.datetime().toISOString().split("T")[0],
    weeks: [{
      id: faker.datatype.uuid(),
      week: faker.datatype.number(),
      disabledDays: [faker.helpers.arrayElement(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"])],
    }],
  };
}

export const PlannedMenuWeekForImportFragmentFactory = (): PlannedMenuWeekForImportFragment =>  {
  return {
    id: faker.datatype.uuid(),
    week: faker.datatype.number(),
    meals: [{
      ...PlannedMenuMealForImportFragmentFactory(),
    }],
  };
}

export const PlannedMenuMealForImportFragmentFactory = (): PlannedMenuMealForImportFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    startTime: faker.internet.password(10, false, /[0-9A-Z]/),
    endTime: faker.internet.password(10, false, /[0-9A-Z]/),
    themes: [faker.internet.password(10, false, /[0-9A-Z]/)],
    plannedMenuMealRows: [{
      ...PlannedMenuMealRowForImportFragmentFactory(),
    }],
  };
}

export const PlannedMenuMealRowForImportFragmentFactory = (): PlannedMenuMealRowForImportFragment =>  {
  return {
    id: faker.datatype.uuid(),
    diningStationName: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    foodType: faker.internet.password(10, false, /[0-9A-Z]/),
    plannedMenuMealRowItems: [{
      ...PlannedMenuMealRowItemForImportFragmentFactory(),
    }],
  };
}

export const PlannedMenuMealRowItemForImportFragmentFactory = (): PlannedMenuMealRowItemForImportFragment =>  {
  return {
    id: faker.datatype.uuid(),
    dayOfWeek: faker.helpers.arrayElement(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]),
    position: faker.datatype.number(),
    menuItem: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      defaultAvailableForOrder: faker.datatype.boolean(),
      defaultAllowSpecialRequests: faker.datatype.boolean(),
    },
  };
}

export const SodexoMenuSourceFragmentFactory = (): SodexoMenuSourceFragment =>  {
  return {
    locationId: faker.internet.password(10, false, /[0-9A-Z]/),
    menuSourceKey: faker.internet.password(10, false, /[0-9A-Z]/),
    menuSourceName: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const SodexoMealTimesByDayFragmentFactory = (): SodexoMealTimesByDayFragment =>  {
  return {
    locationId: faker.internet.password(10, false, /[0-9A-Z]/),
    menuSourceKey: faker.internet.password(10, false, /[0-9A-Z]/),
    mealTimesByDay: [{
      date: faker.internet.password(10, false, /[0-9A-Z]/),
      mealTimes: [{
        start: faker.internet.password(10, false, /[0-9A-Z]/),
        end: faker.internet.password(10, false, /[0-9A-Z]/),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        sortOrder: faker.internet.password(10, false, /[0-9A-Z]/),
      }],
    }],
  };
}

export const MealMenuIngredientsAnalyzerFragmentFactory = (): MealMenuIngredientsAnalyzerFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealMenuDiningStations: [{
      ...MealMenuDiningStationIngredientsAnalyzerFragmentFactory(),
    }],
  };
}

export const MealMenuDiningStationIngredientsAnalyzerFragmentFactory = (): MealMenuDiningStationIngredientsAnalyzerFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    diningStationTemplate: {
      id: faker.datatype.uuid(),
    },
    menuItemAppearances: [{
      position: faker.datatype.number(),
      menuItem: {
        ...MenuItemIngredientsAnalyzerFragmentFactory(),
      },
    }],
  };
}

export const MenuItemIngredientsAnalyzerFragmentFactory = (): MenuItemIngredientsAnalyzerFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    servingName: faker.internet.password(10, false, /[0-9A-Z]/),
    servingAmounts: [{
      ...ExcessiveServingAmountFragmentFactory(),
    }],
    choices: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      position: faker.datatype.number(),
      options: [{
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        position: faker.datatype.number(),
        servingAmounts: [{
          ...ExcessiveServingAmountFragmentFactory(),
        }],
      }],
    }],
  };
}

export const MealMenuMissingOrdersFragmentFactory = (): MealMenuMissingOrdersFragment =>  {
  return {
    allOrders: [{
      ...MenuOrderMissingOrdersFragmentFactory(),
    }],
    athletes: [{
      ...AthleteMissingOrdersFragmentFactory(),
    }],
    restaurantMenuLinks: [{
      ...RestaurantMenuLinkMissingOrdersFragmentFactory(),
    }],
  };
}

export const MenuOrderMissingOrdersFragmentFactory = (): MenuOrderMissingOrdersFragment =>  {
  return {
    id: faker.datatype.uuid(),
    athlete: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    items: [{
      id: faker.datatype.uuid(),
      forOrder: faker.datatype.boolean(),
    }],
    user: {
      id: faker.datatype.uuid(),
    },
  };
}

export const AthleteMissingOrdersFragmentFactory = (): AthleteMissingOrdersFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    team: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const RestaurantMenuLinkMissingOrdersFragmentFactory = (): RestaurantMenuLinkMissingOrdersFragment =>  {
  return {
    orders: [{
      id: faker.datatype.uuid(),
      athlete: {
        id: faker.datatype.uuid(),
        firstName: faker.internet.password(10, false, /[0-9A-Z]/),
        lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      },
      items: [{
        id: faker.datatype.uuid(),
      }],
      user: {
        id: faker.datatype.uuid(),
      },
    }],
    plates: [{
      id: faker.datatype.uuid(),
      orders: [{
        id: faker.datatype.uuid(),
        athlete: {
          id: faker.datatype.uuid(),
          firstName: faker.internet.password(10, false, /[0-9A-Z]/),
          lastName: faker.internet.password(10, false, /[0-9A-Z]/),
        },
        user: {
          id: faker.datatype.uuid(),
        },
      }],
    }],
  };
}

export const MealMenuPlateItemOptionFragmentFactory = (): MealMenuPlateItemOptionFragment =>  {
  return {
    amount: faker.datatype.float(),
    menuItemChoiceOption: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const MealMenuPlateItemFragmentFactory = (): MealMenuPlateItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    amount: faker.datatype.float(),
    menuItem: {
      ...MenuItemFormFragmentFactory(),
    },
    options: [{
      ...MealMenuPlateItemOptionFragmentFactory(),
    }],
  };
}

export const MealMenuPlateFragmentFactory = (): MealMenuPlateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealMenuPlateOrders: [{
      user: {
        id: faker.datatype.uuid(),
      },
    }],
    plateItems: [{
      ...MealMenuPlateItemFragmentFactory(),
    }],
  };
}

export const BulkOrderFragmentFactory = (): BulkOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    createdBy: {
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    team: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    pickupTime: faker.datatype.datetime().toISOString(),
    childMealMenuPlates: [{
      ...MealMenuPlateFragmentFactory(),
    }],
    status: faker.helpers.arrayElement(["new", "cooking", "done", "cancelled"]),
  };
}

export const AthleteMenuSuggestionsPreviewFragmentFactory = (): AthleteMenuSuggestionsPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    dislikedFoodGroups: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    mealPlans: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      avoidedFoodGroups: [{
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      }],
      mealTemplates: [{
        id: faker.datatype.uuid(),
        meal: {
          id: faker.datatype.uuid(),
          type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
        },
        ...({
          __typename: "ExchangeMealTemplate",
          exchangeTargets: [{
            ...ExchangeAmountFragmentFactory(),
          }],
        }),
      }],
    }],
  };
}

export const MealMenuCalendarBaseFragmentFactory = (): MealMenuCalendarBaseFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "StandaloneMealMenu",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    start: faker.datatype.datetime().toISOString(),
    timezone: "America/New_York",
    durationInMinutes: faker.datatype.number(),
    prepTimeInMinutes: faker.datatype.number(),
    lastOrderTimeBeforeEndInMinutes: faker.datatype.number(),
    notificationSentBeforeOrderDueInMinutes: faker.datatype.number(),
    userOrderLimit: faker.datatype.number(),
    isHubCheckInEnabled: faker.datatype.boolean(),
    isOrderAndLogRestricted: faker.datatype.boolean(),
    identity: {
      ...MealMenuIdentityFragmentFactory(),
    },
    orderRateLimit: {
      ...MenuOrderRateLimitFragmentFactory(),
    },
    allOrders: [{
      ...MealMenuOrdersPlacedOrderFragmentFactory(),
    }],
    teams: [{
      ...TeamMealMenuPreviewFragmentFactory(),
    }],
    hasAdvancedSelection: faker.datatype.boolean(),
    athleteCount: faker.datatype.number(),
    theme: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MealMenuCalendarFragmentFactory = (): MealMenuCalendarFragment =>  {
  return {
    ...MealMenuCalendarBaseFragmentFactory(),
    mealMenuDiningStations: [{
      ...MealMenuDiningStationPreviewFragmentFactory(),
    }],
    restaurantMenuLinks: [{
      ...RestaurantMenuLinkPreviewFragmentFactory(),
    }],
  };
}

export const MealMenuModalFragmentFactory = (): MealMenuModalFragment =>  {
  return {
    ...MealMenuCalendarBaseFragmentFactory(),
    advancedSelection: {
      ...AdvancedSelectionFragmentFactory(),
    },
    allOrders: [{
      ...MenuOrderCountsFragmentFactory(),
    }],
    mealMenuDiningStations: [{
      ...MealMenuDiningStationFormFragmentFactory(),
    }],
    restaurantMenuLinks: [{
      ...RestaurantMenuLinkFormFragmentFactory(),
    }],
  };
}

export const CurrentMealMenuFragmentFactory = (): CurrentMealMenuFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealMenuDiningStations: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      position: faker.datatype.number(),
      maxAmount: faker.datatype.number(),
      diningStationTemplate: {
        id: faker.datatype.uuid(),
      },
      menuItemAppearances: [{
        id: faker.datatype.uuid(),
        maxAmount: faker.datatype.number(),
        position: faker.datatype.number(),
        availableForOrder: faker.datatype.boolean(),
        allowSpecialRequests: faker.datatype.boolean(),
        menuItem: {
          history: {
            current: {
              ...MenuItemPreviewFragmentFactory(),
            },
          },
        },
      }],
    }],
    restaurantMenuLinks: [{
      ...RestaurantMenuLinkFormFragmentFactory(),
    }],
    advancedSelection: {
      ...AdvancedSelectionFragmentFactory(),
    },
  };
}

export const MealMenuDiningStationPreviewFragmentFactory = (): MealMenuDiningStationPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
  };
}

export const MealMenuDiningStationFormFragmentFactory = (): MealMenuDiningStationFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    maxAmount: faker.datatype.number(),
    diningStationTemplate: {
      id: faker.datatype.uuid(),
    },
    menuItemAppearances: [{
      ...MenuItemAppearancePreviewFragmentFactory(),
    }],
  };
}

export const RestaurantMenuLinkPreviewFragmentFactory = (): RestaurantMenuLinkPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    orders: [{
      ...MealMenuRmlOrderPlacedOrderFragmentFactory(),
    }],
    plates: [{
      ...MealMenuRmlPlateWithOrdersFragmentFactory(),
    }],
  };
}

export const MealMenuRmlOrderPlacedOrderFragmentFactory = (): MealMenuRmlOrderPlacedOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    items: [{
      id: faker.datatype.uuid(),
    }],
    athlete: {
      id: faker.datatype.uuid(),
    },
    user: {
      id: faker.datatype.uuid(),
    },
  };
}

export const MealMenuRmlPlateWithOrdersFragmentFactory = (): MealMenuRmlPlateWithOrdersFragment =>  {
  return {
    id: faker.datatype.uuid(),
    orders: [{
      id: faker.datatype.uuid(),
      athlete: {
        id: faker.datatype.uuid(),
      },
      user: {
        id: faker.datatype.uuid(),
      },
    }],
  };
}

export const MealMenuOrdersPlacedOrderFragmentFactory = (): MealMenuOrdersPlacedOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    items: [{
      id: faker.datatype.uuid(),
      forOrder: faker.datatype.boolean(),
    }],
    athlete: {
      id: faker.datatype.uuid(),
    },
    user: {
      id: faker.datatype.uuid(),
    },
  };
}

export const RestaurantMenuLinkFormFragmentFactory = (): RestaurantMenuLinkFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    allowCustomOrders: faker.datatype.boolean(),
    allowPlateOrders: faker.datatype.boolean(),
    restaurant: {
      ...RestaurantPreviewFragmentFactory(),
    },
    restaurantMenu: {
      ...RestaurantMenuFormFragmentFactory(),
    },
    restaurantLocation: {
      ...RestaurantLocationPreviewFragmentFactory(),
    },
    deliveryLocation: {
      ...DeliveryLocationMenuLinkFormFragmentFactory(),
    },
    sections: [{
      maxAmount: faker.datatype.number(),
      restaurantMenuSection: {
        id: faker.datatype.uuid(),
      },
      menuItemAppearances: [{
        id: faker.datatype.uuid(),
        maxAmount: faker.datatype.number(),
        availableForOrder: faker.datatype.boolean(),
        allowSpecialRequests: faker.datatype.boolean(),
        menuItem: {
          id: faker.datatype.uuid(),
        },
      }],
    }],
    plates: [{
      ...RestaurantMenuLinkPlateFormFragmentFactory(),
    }],
  };
}

export const RestaurantMenuLinkPlateFormFragmentFactory = (): RestaurantMenuLinkPlateFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    bulkOrderAmount: faker.datatype.number(),
    isDefault: faker.datatype.boolean(),
    items: [{
      ...RestaurantMenuLinkPlateItemFormFragmentFactory(),
    }],
    orders: [{
      id: faker.datatype.uuid(),
    }],
  };
}

export const DeliveryLocationMenuLinkFormFragmentFactory = (): DeliveryLocationMenuLinkFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    address: {
      displayName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const RestaurantMenuFormFragmentFactory = (): RestaurantMenuFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    sections: [{
      ...RestaurantMenuSectionPreviewFragmentFactory(),
    }],
  };
}

export const RestaurantMenuSectionPreviewFragmentFactory = (): RestaurantMenuSectionPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    menuItemAppearances: [{
      ...MenuItemAppearancePreviewFragmentFactory(),
    }],
  };
}

export const RestaurantPreviewFragmentFactory = (): RestaurantPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const RestaurantLocationPreviewFragmentFactory = (): RestaurantLocationPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    email: faker.internet.password(10, false, /[0-9A-Z]/),
    phoneNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    notes: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const TeamMealMenuPreviewFragmentFactory = (): TeamMealMenuPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    athletes: [{
      id: faker.datatype.uuid(),
    }],
  };
}

export const MenuOrderCountsFragmentFactory = (): MenuOrderCountsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    items: [{
      id: faker.datatype.uuid(),
      amount: faker.datatype.float(),
      forOrder: faker.datatype.boolean(),
      menuItem: {
        id: faker.datatype.uuid(),
      },
    }],
    athlete: {
      id: faker.datatype.uuid(),
    },
    user: {
      id: faker.datatype.uuid(),
    },
  };
}

export const MenuOrderRateLimitFragmentFactory = (): MenuOrderRateLimitFragment =>  {
  return {
    limit: faker.datatype.number(),
    excludedTeamIds: [faker.datatype.uuid()],
  };
}

export const OrderPageMealMenuFragmentFactory = (): OrderPageMealMenuFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    allOrders: [{
      ...OrderPageMenuOrderFragmentFactory(),
    }],
    mealMenuDiningStations: [{
      ...OrderPageMealMenuDiningStationFragmentFactory(),
    }],
    restaurantMenuLinks: [{
      ...OrderPageRestaurantMenuLinkPreviewFragmentFactory(),
    }],
  };
}

export const OrderPageRestaurantMenuLinkPreviewFragmentFactory = (): OrderPageRestaurantMenuLinkPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    deliveryLocation: {
      ...OrderPageRestaurantMenuLinkDeliveryLocationFragmentFactory(),
    },
  };
}

export const OrderPageRestaurantMenuLinkFragmentFactory = (): OrderPageRestaurantMenuLinkFragment =>  {
  return {
    id: faker.datatype.uuid(),
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    deliveryLocation: {
      ...OrderPageRestaurantMenuLinkDeliveryLocationFragmentFactory(),
    },
    plates: [{
      ...OrderPageRestaurantMenuLinkPlateFragmentFactory(),
    }],
    orders: [{
      ...OrderPageRestaurantMenuLinkOrderFragmentFactory(),
    }],
  };
}

export const OrderPageRestaurantMenuLinkDeliveryLocationFragmentFactory = (): OrderPageRestaurantMenuLinkDeliveryLocationFragment =>  {
  return {
    id: faker.datatype.uuid(),
    address: {
      displayName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const OrderPageRestaurantMenuLinkOrderFragmentFactory = (): OrderPageRestaurantMenuLinkOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    userFullName: faker.internet.password(10, false, /[0-9A-Z]/),
    athlete: {
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      jerseyNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    items: [{
      ...OrderPageRestaurantMenuLinkOrderItemFragmentFactory(),
    }],
  };
}

export const OrderPageRestaurantMenuLinkOrderItemFragmentFactory = (): OrderPageRestaurantMenuLinkOrderItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItem: {
      ...OrderPageMenuItemFragmentFactory(),
    },
    options: [{
      ...OrderPageRestaurantMenuLinkOrderItemOptionFragmentFactory(),
    }],
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const OrderPageRestaurantMenuLinkOrderItemOptionFragmentFactory = (): OrderPageRestaurantMenuLinkOrderItemOptionFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      ...OrderPageMenuItemChoiceOptionFragmentFactory(),
    },
  };
}

export const OrderPageRestaurantMenuLinkPlateFragmentFactory = (): OrderPageRestaurantMenuLinkPlateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    bulkOrderAmount: faker.datatype.number(),
    items: [{
      ...OrderPageRestaurantMenuLinkPlateItemFragmentFactory(),
    }],
    orders: [{
      ...OrderPageRestaurantMenuLinkPlateOrderFragmentFactory(),
    }],
  };
}

export const OrderPageRestaurantMenuLinkPlateItemFragmentFactory = (): OrderPageRestaurantMenuLinkPlateItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItem: {
      ...OrderPageMenuItemFragmentFactory(),
    },
    options: [{
      ...OrderPageRestaurantMenuLinkPlateItemOptionFragmentFactory(),
    }],
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const OrderPageRestaurantMenuLinkPlateItemOptionFragmentFactory = (): OrderPageRestaurantMenuLinkPlateItemOptionFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      ...OrderPageMenuItemChoiceOptionFragmentFactory(),
    },
  };
}

export const OrderPageRestaurantMenuLinkPlateOrderFragmentFactory = (): OrderPageRestaurantMenuLinkPlateOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    userFullName: faker.internet.password(10, false, /[0-9A-Z]/),
    athlete: {
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      jerseyNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const OrderPageMenuOrderFragmentFactory = (): OrderPageMenuOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    pickupTime: faker.datatype.datetime().toISOString(),
    code: faker.internet.password(10, false, /[0-9A-Z]/),
    items: [{
      ...OrderPageMenuOrderItemFragmentFactory(),
    }],
    mealMenu: {
      id: faker.datatype.uuid(),
    },
  };
}

export const OrderPageMenuOrderItemFragmentFactory = (): OrderPageMenuOrderItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    forOrder: faker.datatype.boolean(),
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
    menuItem: {
      ...OrderPageMenuItemFragmentFactory(),
    },
    options: [{
      ...OrderPageMenuOrderItemOptionFragmentFactory(),
    }],
    order: {
      id: faker.datatype.uuid(),
      pickupTime: faker.datatype.datetime().toISOString(),
      code: faker.internet.password(10, false, /[0-9A-Z]/),
      userFullName: faker.internet.password(10, false, /[0-9A-Z]/),
      athlete: {
        id: faker.datatype.uuid(),
        firstName: faker.internet.password(10, false, /[0-9A-Z]/),
        lastName: faker.internet.password(10, false, /[0-9A-Z]/),
        jerseyNumber: faker.internet.password(10, false, /[0-9A-Z]/),
        team: {
          id: faker.datatype.uuid(),
          name: faker.internet.password(10, false, /[0-9A-Z]/),
        },
      },
    },
  };
}

export const OrderPageMenuItemFragmentFactory = (): OrderPageMenuItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    choices: [{
      ...OrderPageMenuItemChoiceFragmentFactory(),
    }],
  };
}

export const OrderPageMenuItemChoiceFragmentFactory = (): OrderPageMenuItemChoiceFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    options: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      position: faker.datatype.number(),
      canEditAmount: faker.datatype.boolean(),
    }],
  };
}

export const OrderPageMenuOrderItemOptionFragmentFactory = (): OrderPageMenuOrderItemOptionFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      ...OrderPageMenuItemChoiceOptionFragmentFactory(),
    },
  };
}

export const OrderPageMenuItemChoiceOptionFragmentFactory = (): OrderPageMenuItemChoiceOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const OrderPageMealMenuDiningStationFragmentFactory = (): OrderPageMealMenuDiningStationFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    menuItemAppearances: [{
      menuItem: {
        id: faker.datatype.uuid(),
      },
    }],
  };
}

export const TableRecipeFragmentFactory = (): TableRecipeFragment =>  {
  return {
    ...RecipeWithIngredientsFragmentFactory(),
    createdAt: faker.datatype.datetime().toISOString(),
    org: {
      id: faker.datatype.uuid(),
    },
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    servings: [{
      ...ServingFragmentFactory(),
    }],
    isShared: faker.datatype.boolean(),
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    owner: {
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      id: faker.datatype.uuid(),
    },
    orgGroup: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    copiedCount: faker.datatype.number(),
    isAiGenerated: faker.datatype.boolean(),
    isAiTranscribed: faker.datatype.boolean(),
  };
}

export const RecipeOwnerPreviewFragmentFactory = (): RecipeOwnerPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const DeliveryLocationWithTeamsFragmentFactory = (): DeliveryLocationWithTeamsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
    deliveryInstructions: faker.internet.password(10, false, /[0-9A-Z]/),
    address: {
      displayName: faker.internet.password(10, false, /[0-9A-Z]/),
      lat: faker.datatype.float(),
      long: faker.datatype.float(),
    },
    cityInfo: faker.internet.password(10, false, /[0-9A-Z]/),
    googlePlaceId: faker.internet.password(10, false, /[0-9A-Z]/),
    teamLabels: [{
      ...TeamLabelFragmentFactory(),
    }],
  };
}

export const DietFragmentFactory = (): DietFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const StaffMealPlanTemplateListItemFragmentFactory = (): StaffMealPlanTemplateListItemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    isShared: faker.datatype.boolean(),
    pendingState: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const StaffMealPlanTemplatePendingStateFragmentFactory = (): StaffMealPlanTemplatePendingStateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    pendingState: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const StaffMealPlanTemplateIsSharedFragmentFactory = (): StaffMealPlanTemplateIsSharedFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isShared: faker.datatype.boolean(),
  };
}

export const FullMealPlanTemplateFragmentFactory = (): FullMealPlanTemplateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeMealPlanTemplate",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    additionalState: faker.internet.password(10, false, /[0-9A-Z]/),
    isShared: faker.datatype.boolean(),
    pendingState: faker.internet.password(10, false, /[0-9A-Z]/),
    teams: [{
      ...TeamFragmentFactory(),
    }],
  };
}

export const MealPlanTemplateActivityFragmentFactory = (): MealPlanTemplateActivityFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
    start: faker.datatype.datetime().toISOString().split("T")[1],
    end: faker.datatype.datetime().toISOString().split("T")[1],
  };
}

export const MacroMealPlanTemplateMealFragmentFactory = (): MacroMealPlanTemplateMealFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    start: faker.datatype.datetime().toISOString().split("T")[1],
    end: faker.datatype.datetime().toISOString().split("T")[1],
    mealOptions: [{
      ...MealPlanTemplateMealOptionFragmentFactory(),
    }],
    choRatio: faker.datatype.float(),
    proRatio: faker.datatype.float(),
    fatRatio: faker.datatype.float(),
  };
}

export const MealPlanTemplateMealOptionFragmentFactory = (): MealPlanTemplateMealOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const MealPlanTemplateMacroProtocolFragmentFactory = (): MealPlanTemplateMacroProtocolFragment =>  {
  return {
    id: faker.datatype.uuid(),
    useExistingAnthropometry: faker.datatype.boolean(),
    anthropometry: {
      ...MealPlanTemplateAnthropometryFragmentFactory(),
    },
    calorieBudget: {
      ...MealPlanTemplateCalorieBudgetFragmentFactory(),
    },
    cho: {
      ...MacroMathFragmentFactory(),
    },
    pro: {
      ...MacroMathFragmentFactory(),
    },
    fat: {
      ...MacroMathFragmentFactory(),
    },
  };
}

export const MealPlanTemplateAnthropometryFragmentFactory = (): MealPlanTemplateAnthropometryFragment =>  {
  return {
    ...({
      ...AnthropometryTemplateFragmentFactory(),
    }),
  };
}

export const AnthropometryTemplateFragmentFactory = (): AnthropometryTemplateFragment =>  {
  return {
    __typename: "AnthropometryTemplate",
    id: faker.datatype.uuid(),
    height: faker.datatype.float(),
    weight: faker.datatype.float(),
    percentBodyFat: faker.datatype.float(),
  };
}

export const AnthropometryFormFragmentFactory = (): AnthropometryFormFragment =>  {
  return {
    __typename: "AnthropometryForm",
    questions: [{
      field: faker.helpers.arrayElement(["height", "weight", "percentBodyFat"]),
    }],
  };
}

export const MealPlanTemplateCalorieBudgetFragmentFactory = (): MealPlanTemplateCalorieBudgetFragment =>  {
  return {
    id: faker.datatype.uuid(),
    rmrMethod: faker.helpers.arrayElement(["cunningham", "mifflin", "harrisBenedict", "average"]),
    goals: [{
      ...MealPlanTemplateGoalFragmentFactory(),
    }],
    activityFactor: faker.datatype.float(),
  };
}

export const MealPlanTemplateGoalFragmentFactory = (): MealPlanTemplateGoalFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    kcalOffset: faker.datatype.number(),
    goalType: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const OrgGroupOrgFragmentFactory = (): OrgGroupOrgFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const EditableOrgGroupFragmentFactory = (): EditableOrgGroupFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    allowsAthleteTransfer: faker.datatype.boolean(),
    allowsResourceSharing: faker.datatype.boolean(),
    orgs: [{
      ...OrgGroupOrgFragmentFactory(),
    }],
    parentOrgGroup: {
      ...ParentOrgGroupOptionFragmentFactory(),
    },
  };
}

export const ParentOrgGroupOptionFragmentFactory = (): ParentOrgGroupOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const StaffDashboardOrgGroupFragmentFactory = (): StaffDashboardOrgGroupFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    orgsCount: faker.datatype.number(),
    parentOrgGroup: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      username: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const PositionWithMappingsFragmentFactory = (): PositionWithMappingsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    teamworksMappings: [{
      teamworksName: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const SportWithPositionMappingsFragmentFactory = (): SportWithPositionMappingsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    positions: [{
      ...PositionWithMappingsFragmentFactory(),
    }],
  };
}

export const TeamworksSportMappingFragmentFactory = (): TeamworksSportMappingFragment =>  {
  return {
    teamworksName: faker.internet.password(10, false, /[0-9A-Z]/),
    sport: {
      ...SportWithPositionMappingsFragmentFactory(),
    },
  };
}

export const TeamLinkPreviewFragmentFactory = (): TeamLinkPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    gender: faker.internet.password(10, false, /[0-9A-Z]/),
    sport: {
      ...SportWithPositionMappingsFragmentFactory(),
    },
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    teamworksId: faker.datatype.number(),
  };
}

export const OrgLinkerOrgFragmentFactory = (): OrgLinkerOrgFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    teamworksId: faker.datatype.number(),
    teams: [{
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const OrgForLinkingFragmentFactory = (): OrgForLinkingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    teamworksId: faker.datatype.number(),
    isFullySynced: faker.datatype.boolean(),
    isNotemealLinked: faker.datatype.boolean(),
    isTeamworksLinked: faker.datatype.boolean(),
    teams: [{
      ...TeamLinkPreviewFragmentFactory(),
    }],
    teamworksProfileSyncRules: [{
      ...TeamworksProfileSyncRuleFragmentFactory(),
    }],
    memberships: [{
      ...OrgMembershipForLinkingFragmentFactory(),
    }],
    athletes: [{
      ...AthleteForLinkingFragmentFactory(),
    }],
  };
}

export const StaffDashboardOrgPreviewFragmentFactory = (): StaffDashboardOrgPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
    allowsPublicAccess: faker.datatype.boolean(),
    teamworksId: faker.datatype.number(),
    integrations: [{
      ...IntegrationFragmentFactory(),
    }],
    isFullySynced: faker.datatype.boolean(),
    isNotemealLinked: faker.datatype.boolean(),
    isTeamworksLinked: faker.datatype.boolean(),
    agreementForm: {
      ...AgreementFormFragmentFactory(),
    },
    scoringSystem: {
      ...ScoringSystemFragmentFactory(),
    },
    localeCode: faker.internet.password(10, false, /[0-9A-Z]/),
    territoryName: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const IntegrationFragmentFactory = (): IntegrationFragment =>  {
  return {
    externalOrgId: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["compass", "cbord", "bonAppetit", "sodexo"]),
  };
}

export const TeamworksTeamFragmentFactory = (): TeamworksTeamFragment =>  {
  return {
    id: faker.datatype.number(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    sport: faker.internet.password(10, false, /[0-9A-Z]/),
    sportMappings: [{
      ...TeamworksSportMappingFragmentFactory(),
    }],
    notemealTeams: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const TeamworksOrgFragmentFactory = (): TeamworksOrgFragment =>  {
  return {
    id: faker.datatype.number(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    shortName: faker.internet.password(10, false, /[0-9A-Z]/),
    teams: [{
      ...TeamworksTeamFragmentFactory(),
    }],
  };
}

export const TeamworksProfileFragmentFactory = (): TeamworksProfileFragment =>  {
  return {
    id: faker.datatype.number(),
    userId: faker.datatype.number(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    email: faker.internet.password(10, false, /[0-9A-Z]/),
    cellPhone: faker.internet.password(10, false, /[0-9A-Z]/),
    birthDate: faker.datatype.datetime().toISOString().split("T")[0],
    gender: faker.helpers.arrayElement(["M", "F"]),
    hasLoginAccess: faker.datatype.boolean(),
    active: faker.datatype.boolean(),
    memberships: [{
      ...TeamworksProfileMembershipFragmentFactory(),
    }],
  };
}

export const TeamworksProfileMembershipFragmentFactory = (): TeamworksProfileMembershipFragment =>  {
  return {
    teamId: faker.datatype.number(),
    teamName: faker.internet.password(10, false, /[0-9A-Z]/),
    athleteStatus: {
      id: faker.datatype.number(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      active: faker.datatype.boolean(),
    },
    userTypes: [{
      userTypeId: faker.datatype.number(),
      userTypeName: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    positions: [{
      id: faker.datatype.number(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const TeamworksPositionFragmentFactory = (): TeamworksPositionFragment =>  {
  return {
    id: faker.datatype.number(),
    label: faker.internet.password(10, false, /[0-9A-Z]/),
    teamId: faker.datatype.number(),
    type: faker.helpers.arrayElement(["team", "user_type", "athlete_status", "position", "academic_year"]),
  };
}

export const TeamworksPositionLinkFragmentFactory = (): TeamworksPositionLinkFragment =>  {
  return {
    teamworksId: faker.datatype.number(),
    teamworksTeamId: faker.datatype.number(),
    position: {
      ...PositionWithMappingsFragmentFactory(),
    },
  };
}

export const TeamworksProfileSyncRuleFragmentFactory = (): TeamworksProfileSyncRuleFragment =>  {
  return {
    id: faker.datatype.uuid(),
    profileIds: [faker.datatype.number()],
    teamIds: [faker.datatype.number()],
    athleteStatusIds: [faker.datatype.number()],
    userTypeIds: [faker.datatype.number()],
    notemealAccountType: faker.helpers.arrayElement(["athlete", "dietitian", "chef"]),
    priority: faker.datatype.number(),
    matchNotemealTeamIds: [faker.datatype.uuid()],
    positionIds: [faker.datatype.number()],
    genders: [faker.helpers.arrayElement(["M", "F"])],
  };
}

export const TeamworksGroupFragmentFactory = (): TeamworksGroupFragment =>  {
  return {
    id: faker.datatype.number(),
    label: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["team", "user_type", "athlete_status", "position", "academic_year"]),
    teamId: faker.datatype.number(),
  };
}

export const OrgMembershipForLinkingFragmentFactory = (): OrgMembershipForLinkingFragment =>  {
  return {
    __typename: "OrgMembership",
    id: faker.datatype.uuid(),
    isActive: faker.datatype.boolean(),
    teamworksId: faker.datatype.number(),
    user: {
      id: faker.datatype.uuid(),
      teamworksId: faker.datatype.number(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      email: faker.internet.password(10, false, /[0-9A-Z]/),
      phoneNumber: faker.internet.password(10, false, /[0-9A-Z]/),
      username: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    athlete: {
      id: faker.datatype.uuid(),
      isArchived: faker.datatype.boolean(),
      sex: faker.helpers.arrayElement(["male", "female"]),
    },
    isNotemealOnly: faker.datatype.boolean(),
    roles: [faker.helpers.arrayElement(["athlete", "dietitian", "chef", "foodmanager", "kiosk", "nonRdAdmin"])],
  };
}

export const AthleteForLinkingFragmentFactory = (): AthleteForLinkingFragment =>  {
  return {
    __typename: "Athlete",
    id: faker.datatype.uuid(),
    isArchived: faker.datatype.boolean(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    email: faker.internet.password(10, false, /[0-9A-Z]/),
    phoneNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    birthDate: faker.datatype.datetime().toISOString().split("T")[0],
    user: {
      id: faker.datatype.uuid(),
    },
    team: {
      teamworksId: faker.datatype.number(),
      id: faker.datatype.uuid(),
    },
    sex: faker.helpers.arrayElement(["male", "female"]),
    isNotemealOnly: faker.datatype.boolean(),
  };
}

export const OrgStatusSyncFragmentFactory = (): OrgStatusSyncFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    teamworksId: faker.datatype.number(),
    teamworksSyncStatus: {
      ...TeamworksSyncStatusFragmentFactory(),
    },
  };
}

export const TeamworksSyncStatusFragmentFactory = (): TeamworksSyncStatusFragment =>  {
  return {
    linkedProfileCount: faker.datatype.number(),
    toDeactivateProfileCount: faker.datatype.number(),
    unlinkedNotemealProfileCount: faker.datatype.number(),
    unlinkedTeamworksProfileCount: faker.datatype.number(),
    updatedAt: faker.datatype.datetime().toISOString(),
    isFullySynced: faker.datatype.boolean(),
    isNotemealLinked: faker.datatype.boolean(),
    isTeamworksLinked: faker.datatype.boolean(),
  };
}

export const ContactFragmentFactory = (): ContactFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    title: faker.internet.password(10, false, /[0-9A-Z]/),
    email: faker.internet.password(10, false, /[0-9A-Z]/),
    phoneNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    preferredContactMethod: faker.helpers.arrayElement(["email", "phoneNumber"]),
  };
}

export const RestaurantLocationFragmentFactory = (): RestaurantLocationFragment =>  {
  return {
    id: faker.datatype.uuid(),
    address: {
      displayName: faker.internet.password(10, false, /[0-9A-Z]/),
      lat: faker.datatype.float(),
      long: faker.datatype.float(),
    },
    cityInfo: faker.internet.password(10, false, /[0-9A-Z]/),
    googlePlaceId: faker.internet.password(10, false, /[0-9A-Z]/),
    email: faker.internet.password(10, false, /[0-9A-Z]/),
    phoneNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    notes: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
    contacts: [{
      ...ContactFragmentFactory(),
    }],
    deliveryLocations: [{
      ...DeliveryLocationMenuLinkFormFragmentFactory(),
    }],
  };
}

export const StaffDashboardRestaurantFragmentFactory = (): StaffDashboardRestaurantFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    nutritionixId: faker.internet.password(10, false, /[0-9A-Z]/),
    menu: {
      ...RestaurantMenuFragmentFactory(),
    },
  };
}

export const RestaurantMenuFragmentFactory = (): RestaurantMenuFragment =>  {
  return {
    id: faker.datatype.uuid(),
    sections: [{
      ...StaffDashboardRestaurantMenuSectionFragmentFactory(),
    }],
    supportedDiets: [{
      ...DietFragmentFactory(),
    }],
  };
}

export const StaffDashboardRestaurantMenuSectionFragmentFactory = (): StaffDashboardRestaurantMenuSectionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    menuItemAppearances: [{
      ...MenuItemAppearancePreviewFragmentFactory(),
    }],
  };
}

export const RestaurantTypeFragmentFactory = (): RestaurantTypeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const StaffDashboardRestaurantPreviewFragmentFactory = (): StaffDashboardRestaurantPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    menu: {
      id: faker.datatype.uuid(),
    },
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    nutritionixId: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
    athleteVisible: faker.datatype.boolean(),
  };
}

export const StaffDashboardRestaurantFullFragmentFactory = (): StaffDashboardRestaurantFullFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    nutritionixId: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
    website: faker.internet.password(10, false, /[0-9A-Z]/),
    proteinPortionSizeInOunces: faker.datatype.number(),
    firstPartyDelivery: faker.datatype.boolean(),
    athleteVisible: faker.datatype.boolean(),
    refundPolicy: faker.helpers.arrayElement(["full", "labor"]),
    refundDueBeforeInMinutes: faker.datatype.number(),
    restaurantType: {
      ...RestaurantTypeFragmentFactory(),
    },
    paymentMethods: [{
      ...PaymentMethodFragmentFactory(),
    }],
    cuisines: [{
      ...CuisineFragmentFactory(),
    }],
  };
}

export const TeamLabelFragmentFactory = (): TeamLabelFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
  };
}

export const TerritoryFragmentFactory = (): TerritoryFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    orgs: [{
      id: faker.datatype.uuid(),
    }],
  };
}

export const OrgForTerritoryFragmentFactory = (): OrgForTerritoryFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const StaffDashboardUserPreviewFragmentFactory = (): StaffDashboardUserPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    username: faker.internet.password(10, false, /[0-9A-Z]/),
    email: faker.internet.password(10, false, /[0-9A-Z]/),
    phoneNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
    teamworksId: faker.datatype.number(),
    orgInfo: [{
      org: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
      orgMembership: {
        id: faker.datatype.uuid(),
        isActive: faker.datatype.boolean(),
        roles: [faker.helpers.arrayElement(["athlete", "dietitian", "chef", "foodmanager", "kiosk", "nonRdAdmin"])],
        teamworksId: faker.datatype.number(),
      },
      athlete: {
        id: faker.datatype.uuid(),
        firstName: faker.internet.password(10, false, /[0-9A-Z]/),
        lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    }],
  };
}

export const StaffDashboardOrgFragmentFactory = (): StaffDashboardOrgFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    isActive: faker.datatype.boolean(),
    allowsPublicAccess: faker.datatype.boolean(),
    teamworksId: faker.datatype.number(),
    memberships: [{
      ...StaffDashboardOrgMembershipFragmentFactory(),
    }],
    featureFlags: {
      ...FeatureFlagsFragmentFactory(),
    },
    integrations: [{
      ...IntegrationFragmentFactory(),
    }],
    isFullySynced: faker.datatype.boolean(),
    isNotemealLinked: faker.datatype.boolean(),
    isTeamworksLinked: faker.datatype.boolean(),
    hasTwSuperuserPersonId: faker.datatype.boolean(),
    agreementForm: {
      ...AgreementFormFragmentFactory(),
    },
    scoringSystem: {
      ...ScoringSystemFragmentFactory(),
    },
    localeCode: faker.internet.password(10, false, /[0-9A-Z]/),
    territoryName: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const StaffDashboardOrgMembershipFragmentFactory = (): StaffDashboardOrgMembershipFragment =>  {
  return {
    id: faker.datatype.uuid(),
    roles: [faker.helpers.arrayElement(["athlete", "dietitian", "chef", "foodmanager", "kiosk", "nonRdAdmin"])],
    isActive: faker.datatype.boolean(),
    isAdmin: faker.datatype.boolean(),
    lastRefresh: faker.datatype.datetime().toISOString(),
    user: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      username: faker.internet.password(10, false, /[0-9A-Z]/),
      lastLogin: faker.datatype.datetime().toISOString(),
      phoneNumber: faker.internet.password(10, false, /[0-9A-Z]/),
      email: faker.internet.password(10, false, /[0-9A-Z]/),
      userTeamworksId: faker.datatype.number(),
    },
    teamworksId: faker.datatype.number(),
  };
}

export const NamedTagPreviewFragmentFactory = (): NamedTagPreviewFragment =>  {
  return {
    tag: {
      id: faker.datatype.uuid(),
      __typename: "AthleteTag",
      athleteCount: faker.datatype.number(),
      createdBy: {
        id: faker.datatype.uuid(),
        firstName: faker.internet.password(10, false, /[0-9A-Z]/),
        lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
    tagName: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      description: faker.internet.password(10, false, /[0-9A-Z]/),
      color: faker.internet.password(10, false, /[0-9A-Z]/),
      isPrintable: faker.datatype.boolean(),
    },
  };
}

export const NamedTagForEditFragmentFactory = (): NamedTagForEditFragment =>  {
  return {
    tagName: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      description: faker.internet.password(10, false, /[0-9A-Z]/),
      isPrintable: faker.datatype.boolean(),
    },
    tag: {
      id: faker.datatype.uuid(),
      isComplement: faker.datatype.boolean(),
      __typename: "AthleteTag",
      ...({
        athletes: [{
          ...AthleteForCustomTagFragmentFactory(),
        }],
      }),
    },
  };
}

export const NamedTagForSelectionFragmentFactory = (): NamedTagForSelectionFragment =>  {
  return {
    tagName: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      color: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    tag: {
      id: faker.datatype.uuid(),
      __typename: "AthleteTag",
    },
  };
}

export const PositionWitSportFragmentFactory = (): PositionWitSportFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    sport: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const TeamPreviewFragmentFactory = (): TeamPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const AthleteForCustomTagFragmentFactory = (): AthleteForCustomTagFragment =>  {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    myNamedTagsOffsetConnection: {
      edges: [{
        ...NamedTagForSelectionFragmentFactory(),
      }],
      pageInfo: {
        total: faker.datatype.number(),
      },
    },
    team: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const AdvancedSelectionFragmentFactory = (): AdvancedSelectionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    includeAthletes: [{
      ...AthleteForCustomTagFragmentFactory(),
    }],
    excludeAthletes: [{
      ...AthleteForCustomTagFragmentFactory(),
    }],
    groups: [{
      ...AdvancedSelectionGroupFragmentFactory(),
    }],
  };
}

export const AdvancedSelectionGroupFragmentFactory = (): AdvancedSelectionGroupFragment =>  {
  return {
    conditions: [{
      ...AdvancedSelectionConditionFragmentFactory(),
    }],
  };
}

export const AdvancedSelectionConditionFragmentFactory = (): AdvancedSelectionConditionFragment =>  {
  return {
    __typename: "ComboTag",
    ...({
      id: faker.datatype.uuid(),
      isComplement: faker.datatype.boolean(),
      combinationMethod: faker.helpers.arrayElement(["union", "intersection"]),
      namedChildTags: [{
        ...NamedTagForSelectionFragmentFactory(),
      }],
    }),
  };
}

export const TeamAthletesMealPlanFragmentFactory = (): TeamAthletesMealPlanFragment =>  {
  return {
    ...MealPlanFragmentFactory(),
    wasCreatedFromMealPlanTemplate: faker.datatype.boolean(),
    exchangeSet: {
      ...ExchangeSetWithExchangesFragmentFactory(),
    },
    macroProtocol: {
      ...MacroProtocolFragmentFactory(),
      calorieBudget: {
        ...FullCalorieBudgetFragmentFactory(),
      },
    },
    schedule: {
      ...ScheduleFragmentFactory(),
    },
    athlete: {
      ...AthleteFragmentFactory(),
    },
    mostRecentEvent: {
      ...EventFragmentFactory(),
    },
    events: [{
      ...EventFragmentFactory(),
    }],
    activityTemplates: [{
      ...ActivityTemplateFragmentFactory(),
    }],
    mealTemplates: [{
      ...MealTemplateFragmentFactory(),
    }],
    avoidedFoodGroups: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    promotedFoodGroups: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const TeamAthleteNoteFragmentFactory = (): TeamAthleteNoteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    datetime: faker.datatype.datetime().toISOString(),
    content: faker.internet.password(10, false, /[0-9A-Z]/),
    type: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    athlete: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const TeamRosterAthleteFragmentFactory = (): TeamRosterAthleteFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    team: {
      ...TeamFragmentFactory(),
    },
    user: {
      ...EditableRosterAthleteUserFragmentFactory(),
    },
    orgMembership: {
      ...EditableRosterAthleteOrgMembershipFragmentFactory(),
    },
    position: {
      ...PositionFragmentFactory(),
    },
    currentGoal: {
      ...FullGoalFragmentFactory(),
    },
    mostRecentAnthropometryEntry: {
      ...AnthropometryEntryFragmentFactory(),
    },
    anthropometryEntries: [{
      ...AnthropometryEntryFragmentFactory(),
    }],
  };
}

export const AllTableViewsAthleteFragmentFactory = (): AllTableViewsAthleteFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    foodLogNotificationSettings: {
      ...FoodLogNotificationSettingsFragmentFactory(),
    },
    user: {
      ...EditableRosterAthleteUserFragmentFactory(),
    },
    orgMembership: {
      ...EditableRosterAthleteOrgMembershipFragmentFactory(),
    },
    position: {
      ...PositionFragmentFactory(),
    },
  };
}

export const EngagementTableAthleteFragmentFactory = (): EngagementTableAthleteFragment =>  {
  return {
    ...AllTableViewsAthleteFragmentFactory(),
    mealsLoggedCount: faker.datatype.number(),
    mealsOrderedCount: faker.datatype.number(),
    totalMealsCount: faker.datatype.number(),
    imageUploadCount: faker.datatype.number(),
    mealPlanViewCount: faker.datatype.number(),
  };
}

export const PreferencesTableAthleteFragmentFactory = (): PreferencesTableAthleteFragment =>  {
  return {
    ...AllTableViewsAthleteFragmentFactory(),
    dislikedFoodGroups: [{
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
  };
}

export const MyNamedTagsPreviewFragmentFactory = (): MyNamedTagsPreviewFragment =>  {
  return {
    previewNamedTags: [{
      ...NamedTagForSelectionFragmentFactory(),
    }],
    total: faker.datatype.number(),
    hiddenPreviewText: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const ProfileTableAthleteFragmentFactory = (): ProfileTableAthleteFragment =>  {
  return {
    ...AllTableViewsAthleteFragmentFactory(),
    mostRecentAnthropometryEntry: {
      ...AnthropometryEntryFragmentFactory(),
    },
    currentGoal: {
      ...FullGoalFragmentFactory(),
    },
    myNamedTagsPreview: {
      ...MyNamedTagsPreviewFragmentFactory(),
    },
  };
}

export const EditableRosterAthleteUserFragmentFactory = (): EditableRosterAthleteUserFragment =>  {
  return {
    __typename: "User",
    id: faker.datatype.uuid(),
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastLogin: faker.datatype.datetime().toISOString(),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    username: faker.internet.password(10, false, /[0-9A-Z]/),
    dateJoined: faker.datatype.datetime().toISOString(),
  };
}

export const EditableRosterAthleteOrgMembershipFragmentFactory = (): EditableRosterAthleteOrgMembershipFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isActive: faker.datatype.boolean(),
    teamworksId: faker.datatype.number(),
  };
}

export const TeamDashboardScheduleFragmentFactory = (): TeamDashboardScheduleFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    meals: [{
      ...MealFragmentFactory(),
    }],
    activities: [{
      ...ActivityFragmentFactory(),
    }],
    mealPlans: [{
      id: faker.datatype.uuid(),
    }],
  };
}

export const TeamGoalConfigurationFragmentFactory = (): TeamGoalConfigurationFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    emoji: faker.internet.password(10, false, /[0-9A-Z]/),
    category: {
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      defaultEmoji: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    completedGoalCount: faker.datatype.number(),
    totalGoalCount: faker.datatype.number(),
    athlete: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      position: {
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
  };
}

export const TeamPageTeamFragmentFactory = (): TeamPageTeamFragment =>  {
  return {
    ...TeamFragmentFactory(),
    foodLogNotificationSettings: {
      ...FoodLogNotificationSettingsFragmentFactory(),
    },
    sport: {
      ...SportFragmentFactory(),
      positions: [{
        ...PositionFragmentFactory(),
      }],
    },
    athletes: [{
      ...TeamPageAthleteFragmentFactory(),
    }],
  };
}

export const TeamPageAthleteFragmentFactory = (): TeamPageAthleteFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    timelineMealNotifications: [{
      ...NotificationStubFragmentFactory(),
    }],
  };
}

export const TeamsTableTeamFragmentFactory = (): TeamsTableTeamFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Team",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    sport: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    gender: faker.internet.password(10, false, /[0-9A-Z]/),
    athletes: [{
      id: faker.datatype.uuid(),
      activationLinkSent: faker.datatype.datetime().toISOString(),
      isArchived: faker.datatype.boolean(),
      orgMembership: {
        id: faker.datatype.uuid(),
        isActive: faker.datatype.boolean(),
      },
    }],
    recentNotifications: [{
      ...NotificationStubFragmentFactory(),
    }],
    foodLogNotificationSettings: {
      edits: faker.datatype.boolean(),
      messages: faker.datatype.boolean(),
      imageUploads: faker.datatype.boolean(),
    },
    canBeDeleted: faker.datatype.boolean(),
  };
}

export const ActiveTransferFragmentFactory = (): ActiveTransferFragment =>  {
  return {
    id: faker.datatype.uuid(),
    createdAt: faker.datatype.datetime().toISOString(),
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    releasedByOrg: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    orgGroup: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    athlete: {
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
      birthDate: faker.datatype.datetime().toISOString().split("T")[0],
      activeTransfer: {
        id: faker.datatype.uuid(),
      },
    },
  };
}

export const ActiveTransfersTeamsOptionFragmentFactory = (): ActiveTransfersTeamsOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const AgreementFormFragmentFactory = (): AgreementFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    title: faker.internet.password(10, false, /[0-9A-Z]/),
    url: faker.internet.password(10, false, /[0-9A-Z]/),
    acceptAgreementText: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const CommentWithNotificationFragmentFactory = (): CommentWithNotificationFragment =>  {
  return {
    id: faker.datatype.uuid(),
    notification: {
      id: faker.datatype.uuid(),
      viewedAt: faker.datatype.datetime().toISOString(),
    },
  };
}

export const MacroMealPlanDisplaySettingsFragmentFactory = (): MacroMealPlanDisplaySettingsFragment =>  {
  return {
    __typename: "MacroMealPlanDisplaySettings",
    dailyProgressViews: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    mealProgressViews: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
  };
}

export const ExchangeMealPlanDisplaySettingsFragmentFactory = (): ExchangeMealPlanDisplaySettingsFragment =>  {
  return {
    __typename: "ExchangeMealPlanDisplaySettings",
    dailyProgressViewsForExchanges: faker.helpers.arrayElement(["all", "none"]),
    mealProgressViewsForExchanges: faker.helpers.arrayElement(["all", "none"]),
    dailyProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    mealProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
  };
}

export const NoTargetsDisplaySettingsFragmentFactory = (): NoTargetsDisplaySettingsFragment =>  {
  return {
    dailyProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    mealProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
  };
}

export const ExchangeSummaryLabelFragmentFactory = (): ExchangeSummaryLabelFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeAmount",
    amount: faker.datatype.float(),
    exchange: {
      __typename: "Exchange",
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      shortName: faker.internet.password(10, false, /[0-9A-Z]/),
      type: faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"]),
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
  };
}

export const FeatureFlagsFragmentFactory = (): FeatureFlagsFragment =>  {
  return {
    athleteMenuSuggestions: faker.datatype.boolean(),
    clientCredentials: faker.datatype.boolean(),
    hasRestaurantMenus: faker.datatype.boolean(),
    restaurantLogging: faker.datatype.boolean(),
    teamworksSchedules: faker.datatype.boolean(),
    mealPlanSuggestions: faker.datatype.boolean(),
    prepPlan: faker.datatype.boolean(),
    foodCategory: faker.datatype.boolean(),
    pk2MenuBuilder: faker.datatype.boolean(),
    orgGroupRecipes: faker.datatype.boolean(),
    orgGroupFoods: faker.datatype.boolean(),
    performanceKitchen: faker.datatype.boolean(),
    printableTags: faker.datatype.boolean(),
    hubMenuCheckIn: faker.datatype.boolean(),
    goalTracker: faker.datatype.boolean(),
    dietitianOnlyMenus: faker.datatype.boolean(),
    bulkOrdering: faker.datatype.boolean(),
    ukMealPlanOptionSuggestions: faker.datatype.boolean(),
    digitalDisplays: faker.datatype.boolean(),
    selfServiceOnboarding: faker.datatype.boolean(),
    messaging: faker.datatype.boolean(),
    eatingDisorderScreens: faker.datatype.boolean(),
    profilesV3Api: faker.datatype.boolean(),
    mealPlanTemplateBulkAssignments: faker.datatype.boolean(),
  };
}

export const RecipeFoodCategoryFragmentFactory = (): RecipeFoodCategoryFragment =>  {
  return {
    category: faker.helpers.arrayElement(["carbohydrate", "protein", "fats", "combination"]),
  };
}

export const MenuItemFoodCategoryFragmentFactory = (): MenuItemFoodCategoryFragment =>  {
  return {
    category: faker.helpers.arrayElement(["carbohydrate", "protein", "fats", "combination"]),
  };
}

export const MealMenuWithTimesFragmentFactory = (): MealMenuWithTimesFragment =>  {
  return {
    id: faker.datatype.uuid(),
    timezone: "America/New_York",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    prepTimeInMinutes: faker.datatype.number(),
    lastOrderTimeBeforeEndInMinutes: faker.datatype.number(),
    isViewOnlyForMe: faker.datatype.boolean(),
    theme: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MealMenuDiningStationWithItemPreviewsFragmentFactory = (): MealMenuDiningStationWithItemPreviewsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    maxAmount: faker.datatype.number(),
    position: faker.datatype.number(),
    menuItemAppearances: [{
      ...MenuItemAppearancePreviewFragmentFactory(),
    }],
  };
}

export const MenuItemAppearancePreviewFragmentFactory = (): MenuItemAppearancePreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    maxAmount: faker.datatype.number(),
    availableForOrder: faker.datatype.boolean(),
    allowSpecialRequests: faker.datatype.boolean(),
    menuItem: {
      ...MenuItemPreviewFragmentFactory(),
    },
  };
}

export const MenuItemPreviewFragmentFactory = (): MenuItemPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuItem",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    servingName: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    imageUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    isOneOff: faker.datatype.boolean(),
    defaultAvailableForOrder: faker.datatype.boolean(),
    defaultAllowSpecialRequests: faker.datatype.boolean(),
    defaultMaxAmount: faker.datatype.number(),
    isDeleted: faker.datatype.boolean(),
    servingAmounts: [{
      id: faker.datatype.uuid(),
      serving: {
        id: faker.datatype.uuid(),
        foodOrRecipe: {
          ...FoodOrRecipePreferencesFragmentFactory(),
        },
      },
    }],
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    foodCategory: {
      ...FoodCategoryNameFragmentFactory(),
    },
  };
}

export const MenuOrderRateLimitRemainderFragmentFactory = (): MenuOrderRateLimitRemainderFragment =>  {
  return {
    start: faker.datatype.datetime().toISOString(),
    remaining: faker.datatype.number(),
  };
}

export const MealMenuLogItemFormFragmentFactory = (): MealMenuLogItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const ViewMealOptionFragmentFactory = (): ViewMealOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealOption",
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const MealTemplateForTargetsFragmentFactory = (): MealTemplateForTargetsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    ...({
      __typename: "ExchangeMealTemplate",
      exchangeTargets: [{
        ...ExchangeAmountFragmentFactory(),
      }],
    }),
  };
}

export const ExchangeMealTemplateViewFragmentFactory = (): ExchangeMealTemplateViewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeMealTemplate",
    mealOptions: [{
      ...ViewMealOptionFragmentFactory(),
    }],
    exchangeTargets: [{
      ...ExchangeAmountFragmentFactory(),
    }],
    meal: {
      ...MealFragmentFactory(),
    },
  };
}

export const MacroMealTemplateViewFragmentFactory = (): MacroMealTemplateViewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MacroMealTemplate",
    mealOptions: [{
      ...ViewMealOptionFragmentFactory(),
    }],
    meal: {
      ...MealFragmentFactory(),
    },
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    kcal: faker.datatype.float(),
  };
}

export const MenuItemWithIdsFragmentFactory = (): MenuItemWithIdsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    choices: [{
      id: faker.datatype.uuid(),
      options: [{
        id: faker.datatype.uuid(),
      }],
    }],
  };
}

export const MenuSelectionItemOptionWithIdsFragmentFactory = (): MenuSelectionItemOptionWithIdsFragment =>  {
  return {
    amount: faker.datatype.float(),
    menuItemChoiceOption: {
      id: faker.datatype.uuid(),
    },
    percentConsumed: faker.datatype.float(),
  };
}

export const MenuItemFormFragmentFactory = (): MenuItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuItem",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    servingName: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    isOneOff: faker.datatype.boolean(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
    choices: [{
      ...MenuItemChoiceFormFragmentFactory(),
    }],
    imageUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    suggestionCategory: faker.helpers.arrayElement(["combo", "main", "protein", "starch", "vegetable", "fruit", "other"]),
    defaultMaxAmount: faker.datatype.number(),
    defaultAvailableForOrder: faker.datatype.boolean(),
    defaultAllowSpecialRequests: faker.datatype.boolean(),
    isDeleted: faker.datatype.boolean(),
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    foodCategory: {
      ...FoodCategoryNameFragmentFactory(),
    },
  };
}

export const MenuItemChoiceFormFragmentFactory = (): MenuItemChoiceFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    required: faker.datatype.boolean(),
    maxOptionsCount: faker.datatype.number(),
    options: [{
      ...MenuItemChoiceOptionFormFragmentFactory(),
    }],
  };
}

export const MenuItemChoiceOptionFormFragmentFactory = (): MenuItemChoiceOptionFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    canEditAmount: faker.datatype.boolean(),
    defaultAmount: faker.datatype.float(),
    maxAmount: faker.datatype.float(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const FoodCategoryNameFragmentFactory = (): FoodCategoryNameFragment =>  {
  return {
    category: faker.helpers.arrayElement(["carbohydrate", "protein", "fats", "combination"]),
  };
}

export const MenuOrderItemFormFragmentFactory = (): MenuOrderItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    forOrder: faker.datatype.boolean(),
    status: faker.helpers.arrayElement(["new", "cooking", "done", "cancelled"]),
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
    rating: {
      ...RatingFragmentFactory(),
    },
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const RatingFragmentFactory = (): RatingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    review: faker.internet.password(10, false, /[0-9A-Z]/),
    value: faker.helpers.arrayElement(["one", "two", "three", "four", "five"]),
  };
}

export const MenuSelectionFormFragmentFactory = (): MenuSelectionFormFragment =>  {
  return {
    items: [{
      ...MenuSelectionItemFormFragmentFactory(),
    }],
  };
}

export const MenuSelectionItemWithMacrosFragmentFactory = (): MenuSelectionItemWithMacrosFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItem: {
      id: faker.datatype.uuid(),
      servingAmounts: [{
        ...FullServingAmountFragmentFactory(),
      }],
    },
    options: [{
      ...MenuSelectItemOptionWithMacrosFragmentFactory(),
    }],
  };
}

export const MenuSelectItemOptionWithMacrosFragmentFactory = (): MenuSelectItemOptionWithMacrosFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      id: faker.datatype.uuid(),
      servingAmounts: [{
        ...FullServingAmountFragmentFactory(),
      }],
    },
  };
}

export const MenuSelectionItemFormFragmentFactory = (): MenuSelectionItemFormFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItem: {
      ...MenuItemFormFragmentFactory(),
    },
    options: [{
      ...MenuSelectionItemOptionFormFragmentFactory(),
    }],
  };
}

export const MenuSelectionItemOptionFormFragmentFactory = (): MenuSelectionItemOptionFormFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      ...MenuItemChoiceOptionFormFragmentFactory(),
    },
  };
}

export const MenuOrderSuggestionFragmentFactory = (): MenuOrderSuggestionFragment =>  {
  return {
    items: [{
      amount: faker.datatype.float(),
      percentConsumed: faker.datatype.float(),
      menuItem: {
        ...MenuItemFormFragmentFactory(),
      },
      options: [{
        amount: faker.datatype.float(),
        percentConsumed: faker.datatype.float(),
        menuItemChoiceOption: {
          ...MenuItemChoiceOptionFormFragmentFactory(),
        },
      }],
    }],
  };
}

export const ServingWithNutrientAmountsFragmentFactory = (): ServingWithNutrientAmountsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    nutrientAmounts: [{
      ...NutrientAmountFragmentFactory(),
    }],
    foodOrRecipe: {
      ...({
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      }),
    },
  };
}

export const NutrientAmountFragmentFactory = (): NutrientAmountFragment =>  {
  return {
    amount: faker.datatype.float(),
    nutrient: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const NutrientWithDriFragmentFactory = (): NutrientWithDriFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    unitName: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultDietaryReferenceIntake: {
      id: faker.datatype.uuid(),
      type: faker.helpers.arrayElement(["RDA", "UL", "AI", "DGA", "unitsPerKcal"]),
      amount: faker.datatype.float(),
    },
  };
}

export const RestaurantMenuWithItemPreviewsFragmentFactory = (): RestaurantMenuWithItemPreviewsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    sections: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      position: faker.datatype.number(),
      menuItemAppearances: [{
        ...MenuItemAppearancePreviewFragmentFactory(),
      }],
    }],
  };
}

export const RestaurantMenuLinkWithMyOrdersFragmentFactory = (): RestaurantMenuLinkWithMyOrdersFragment =>  {
  return {
    id: faker.datatype.uuid(),
    allowCustomOrders: faker.datatype.boolean(),
    allowPlateOrders: faker.datatype.boolean(),
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    order: {
      ...RestaurantMenuLinkOrderFragmentFactory(),
    },
    plateOrder: {
      ...RestaurantMenuLinkPlateOrderFragmentFactory(),
    },
  };
}

export const RestaurantMenuLinkWithOrdersForAthleteFragmentFactory = (): RestaurantMenuLinkWithOrdersForAthleteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    allowCustomOrders: faker.datatype.boolean(),
    allowPlateOrders: faker.datatype.boolean(),
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    order: {
      ...RestaurantMenuLinkOrderFragmentFactory(),
    },
    plateOrder: {
      ...RestaurantMenuLinkPlateOrderFragmentFactory(),
    },
  };
}

export const RestaurantMenuLinkWithItemPreviewsFragmentFactory = (): RestaurantMenuLinkWithItemPreviewsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    allowCustomOrders: faker.datatype.boolean(),
    allowPlateOrders: faker.datatype.boolean(),
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    sections: [{
      restaurantMenuSection: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        position: faker.datatype.number(),
      },
      maxAmount: faker.datatype.number(),
      menuItemAppearances: [{
        ...MenuItemAppearancePreviewFragmentFactory(),
      }],
    }],
    plates: [{
      ...RestaurantMenuLinkPlateFragmentFactory(),
    }],
  };
}

export const RestaurantMenuLinkPlateFragmentFactory = (): RestaurantMenuLinkPlateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    items: [{
      ...RestaurantMenuLinkPlateItemFormFragmentFactory(),
    }],
    position: faker.datatype.number(),
  };
}

export const RestaurantMenuLinkOrderFragmentFactory = (): RestaurantMenuLinkOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    items: [{
      ...RestaurantMenuLinkOrderItemFormFragmentFactory(),
    }],
  };
}

export const RestaurantMenuLinkOrderItemFormFragmentFactory = (): RestaurantMenuLinkOrderItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const RestaurantMenuLinkPlateItemFormFragmentFactory = (): RestaurantMenuLinkPlateItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const RestaurantMenuLinkPlateOrderFragmentFactory = (): RestaurantMenuLinkPlateOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    userFullName: faker.internet.password(10, false, /[0-9A-Z]/),
    items: [{
      ...RestaurantMenuLinkPlateOrderItemFormFragmentFactory(),
    }],
    plate: {
      id: faker.datatype.uuid(),
      items: [{
        ...RestaurantMenuLinkPlateItemFormFragmentFactory(),
      }],
      position: faker.datatype.number(),
    },
    restaurantMenuLink: {
      id: faker.datatype.uuid(),
    },
  };
}

export const RestaurantMenuLinkPlateOrderItemFormFragmentFactory = (): RestaurantMenuLinkPlateOrderItemFormFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    plateItem: {
      id: faker.datatype.uuid(),
    },
    menuItem: {
      ...MenuItemFormFragmentFactory(),
    },
    options: [{
      ...RestaurantMenuLinkPlateOrderItemOptionFormFragmentFactory(),
    }],
  };
}

export const RestaurantMenuLinkPlateOrderItemOptionFormFragmentFactory = (): RestaurantMenuLinkPlateOrderItemOptionFormFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      ...MenuItemChoiceOptionFormFragmentFactory(),
    },
  };
}

export const RestaurantMenuLogFragmentFactory = (): RestaurantMenuLogFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealTemplate: {
      id: faker.datatype.uuid(),
    },
    items: [{
      ...RestaurantMenuLogItemFormFragmentFactory(),
    }],
    restaurantMenu: {
      id: faker.datatype.uuid(),
    },
    dateTime: faker.datatype.datetime().toISOString(),
    timezone: "America/New_York",
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
  };
}

export const RestaurantMenuLogItemFormFragmentFactory = (): RestaurantMenuLogItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const ScoreAssignmentFragmentFactory = (): ScoreAssignmentFragment =>  {
  return {
    value: faker.datatype.float(),
    scoringSystem: {
      ...ScoringSystemAssignmentFragmentFactory(),
    },
  };
}

export const ScoringSystemAssignmentFragmentFactory = (): ScoringSystemAssignmentFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    id: faker.datatype.uuid(),
  };
}

export const ScoringSystemFragmentFactory = (): ScoringSystemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const ServingWithFoodOrRecipeMacrosFragmentFactory = (): ServingWithFoodOrRecipeMacrosFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Serving",
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    foodOrRecipe: {
      __typename: "BrandedFood",
      ...({
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
      }),
    },
  };
}

export const RecipeIngredientServingAmountFragmentFactory = (): RecipeIngredientServingAmountFragment =>  {
  return {
    id: faker.datatype.uuid(),
    amount: faker.datatype.float(),
    position: faker.datatype.number(),
    serving: {
      ...RecipeIngredientServingFragmentFactory(),
    },
  };
}

export const TimelineItemForGroupByDateFragmentFactory = (): TimelineItemForGroupByDateFragment =>  {
  return {
    ...({
      id: faker.datatype.uuid(),
      __typename: "FoodLogMeal",
    }),
  };
}

export const TimelineMealForGroupByDateFragmentFactory = (): TimelineMealForGroupByDateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    start: faker.datatype.datetime().toISOString(),
    timezone: "America/New_York",
    notifications: [{
      ...NotificationTimelineFragmentFactory(),
    }],
  };
}

export const NotificationTimelineFragmentFactory = (): NotificationTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "AthleteSelfOnboardedNotification",
    viewedAt: faker.datatype.datetime().toISOString(),
  };
}

export const MealMenuSharedTimelineFragmentFactory = (): MealMenuSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "StandaloneMealMenu",
    mealType: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    timezone: "America/New_York",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    prepTimeInMinutes: faker.datatype.number(),
    lastOrderTimeBeforeEndInMinutes: faker.datatype.number(),
    isLogOnly: faker.datatype.boolean(),
    isInHouseMenuOrderable: faker.datatype.boolean(),
    isRestaurantMenuOrderable: faker.datatype.boolean(),
    isViewOnlyForMe: faker.datatype.boolean(),
    isBulkOrderable: faker.datatype.boolean(),
    restaurantMenuLinks: [{
      id: faker.datatype.uuid(),
      allowCustomOrders: faker.datatype.boolean(),
      allowPlateOrders: faker.datatype.boolean(),
      restaurant: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    }],
  };
}

export const RestaurantMenuLinkOrderSharedTimelineFragmentFactory = (): RestaurantMenuLinkOrderSharedTimelineFragment =>  {
  return {
    ...RestaurantMenuLinkOrderFragmentFactory(),
    mealMenu: {
      id: faker.datatype.uuid(),
    },
    restaurantMenuLink: {
      id: faker.datatype.uuid(),
      restaurant: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
  };
}

export const RestaurantMenuLinkPlateOrderSharedTimelineFragmentFactory = (): RestaurantMenuLinkPlateOrderSharedTimelineFragment =>  {
  return {
    ...RestaurantMenuLinkPlateOrderFragmentFactory(),
    mealMenu: {
      ...MealMenuWithTimesFragmentFactory(),
    },
    restaurantMenuLink: {
      id: faker.datatype.uuid(),
      restaurant: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
  };
}

export const MenuOrderSharedTimelineFragmentFactory = (): MenuOrderSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuOrder",
    mealMenu: {
      id: faker.datatype.uuid(),
    },
  };
}

export const MealMenuLogSharedTimelineFragmentFactory = (): MealMenuLogSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealMenuLog",
    mealMenu: {
      id: faker.datatype.uuid(),
    },
  };
}

export const MealPlanDateSharedTimelineFragmentFactory = (): MealPlanDateSharedTimelineFragment =>  {
  return {
    __typename: "MealPlanDate",
    date: faker.datatype.datetime().toISOString().split("T")[0],
    timezone: "America/New_York",
    mealPlan: {
      ...MealPlanSharedTimelineFragmentFactory(),
    },
  };
}

export const MealPlanSharedTimelineFragmentFactory = (): MealPlanSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealTemplates: [{
      ...MealTemplateSharedTimelineFragmentFactory(),
    }],
    activityTemplates: [{
      ...ActivityTemplateSharedTimelineFragmentFactory(),
    }],
  };
}

export const MealTemplateSharedTimelineFragmentFactory = (): MealTemplateSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    meal: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      start: faker.datatype.datetime().toISOString().split("T")[1],
      end: faker.datatype.datetime().toISOString().split("T")[1],
      type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    },
    ...({
      __typename: "ExchangeMealTemplate",
    }),
  };
}

export const ActivityTemplateSharedTimelineFragmentFactory = (): ActivityTemplateSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ActivityTemplate",
    activity: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      start: faker.datatype.datetime().toISOString().split("T")[1],
      end: faker.datatype.datetime().toISOString().split("T")[1],
      type: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
    },
  };
}

export const TimelineMealSharedTimelineFragmentFactory = (): TimelineMealSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "TimelineMeal",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    timezone: "America/New_York",
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    mealTemplate: {
      id: faker.datatype.uuid(),
    },
    mealMenus: [{
      ...MealMenuSharedTimelineFragmentFactory(),
    }],
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
    restaurantMenuLogs: [{
      ...RestaurantMenuLogSharedTimelineFragmentFactory(),
    }],
    restaurantMenuLinkPlateOrders: [{
      ...RestaurantMenuLinkPlateOrderSharedTimelineFragmentFactory(),
    }],
    restaurantMenuLinkOrders: [{
      ...RestaurantMenuLinkOrderSharedTimelineFragmentFactory(),
    }],
    menuOrders: [{
      ...MenuOrderSharedTimelineFragmentFactory(),
    }],
    mealMenuLogs: [{
      ...MealMenuLogSharedTimelineFragmentFactory(),
    }],
  };
}

export const FoodLogActivitySharedTimelineFragmentFactory = (): FoodLogActivitySharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "FoodLogActivity",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    activityType: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
    startTime: faker.datatype.datetime().toISOString().split("T")[1],
    endTime: faker.datatype.datetime().toISOString().split("T")[1],
    timezone: "America/New_York",
    activityTemplate: {
      id: faker.datatype.uuid(),
    },
  };
}

export const RestaurantMenuLogSharedTimelineFragmentFactory = (): RestaurantMenuLogSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "RestaurantMenuLog",
    mealType: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    dateTime: faker.datatype.datetime().toISOString(),
    timezone: "America/New_York",
    items: [{
      id: faker.datatype.uuid(),
      ...MenuSelectionItemFormFragmentFactory(),
    }],
  };
}

export const UnitFragmentFactory = (): UnitFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const UnitWithConversionsFragmentFactory = (): UnitWithConversionsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    conversionsFrom: [{
      id: faker.datatype.uuid(),
      fromQuantity: faker.datatype.float(),
      toQuantity: faker.datatype.float(),
      toUnit: {
        ...UnitFragmentFactory(),
        isProductionOnly: faker.datatype.boolean(),
      },
    }],
  };
}

export const ActivityFragmentFactory = (): ActivityFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Activity",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString().split("T")[1],
    end: faker.datatype.datetime().toISOString().split("T")[1],
    type: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
  };
}

export const ActivityTemplateFragmentFactory = (): ActivityTemplateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ActivityTemplate",
    activity: {
      ...ActivityFragmentFactory(),
    },
  };
}

export const AnthropometryEntryFragmentFactory = (): AnthropometryEntryFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "AnthropometryEntry",
    height: faker.datatype.float(),
    weight: faker.datatype.float(),
    leanBodyMass: faker.datatype.float(),
    bodyFatMass: faker.datatype.float(),
    percentBodyFat: faker.datatype.float(),
    datetime: faker.datatype.datetime().toISOString(),
    type: faker.helpers.arrayElement(["estimate", "dexa", "bodpod", "bioelectricalImpedance", "isak", "weighIn"]),
    sex: faker.helpers.arrayElement(["male", "female"]),
    comment: faker.internet.password(10, false, /[0-9A-Z]/),
    boneMineralDensity: faker.datatype.float(),
    boneMineralDensityZScore: faker.datatype.float(),
    boneMineralDensityTScore: faker.datatype.float(),
    boneMineralContent: faker.datatype.float(),
    boneArea: faker.datatype.float(),
    boneMass: faker.datatype.float(),
    dryLeanMass: faker.datatype.float(),
    skeletalMuscleMass: faker.datatype.float(),
    trunkFat: faker.datatype.float(),
    visceralFatArea: faker.datatype.float(),
    heightInCm: faker.datatype.float(),
    weightInKg: faker.datatype.float(),
    leanBodyMassInKg: faker.datatype.float(),
    bodyFatMassInKg: faker.datatype.float(),
    skeletalMuscleMassInKg: faker.datatype.float(),
    trunkFatInKg: faker.datatype.float(),
    dryLeanMassInKg: faker.datatype.float(),
  };
}

export const AthleteFragmentFactory = (): AthleteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Athlete",
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    sex: faker.helpers.arrayElement(["male", "female"]),
    birthDate: faker.datatype.datetime().toISOString().split("T")[0],
    phoneNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    email: faker.internet.password(10, false, /[0-9A-Z]/),
    headshotUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    secaUid: faker.internet.password(10, false, /[0-9A-Z]/),
    inbodyUid: faker.internet.password(10, false, /[0-9A-Z]/),
    isArchived: faker.datatype.boolean(),
    activationLinkSent: faker.datatype.datetime().toISOString(),
    jerseyNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    isProfileNotemealOnly: faker.datatype.boolean(),
  };
}

export const AthleteWithMealPlansFragmentFactory = (): AthleteWithMealPlansFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    mealPlans: [{
      ...MealPlanSearchFormFragmentFactory(),
    }],
  };
}

export const AthleteWithGoalAndAnthroFragmentFactory = (): AthleteWithGoalAndAnthroFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    position: {
      ...PositionFragmentFactory(),
    },
    mostRecentAnthropometryEntry: {
      ...AnthropometryEntryFragmentFactory(),
    },
    currentGoal: {
      ...FullGoalFragmentFactory(),
    },
  };
}

export const AthleteWithFoodPrefsFragmentFactory = (): AthleteWithFoodPrefsFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    likedFoods: [{
      ...FoodFragmentFactory(),
    }],
    dislikedFoods: [{
      ...FoodFragmentFactory(),
    }],
    likedFoodGroups: [{
      ...FullFoodGroupFragmentFactory(),
    }],
    dislikedFoodGroups: [{
      ...FullFoodGroupFragmentFactory(),
    }],
  };
}

export const BrandFragmentFactory = (): BrandFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Brand",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaManufacturerName: faker.internet.password(10, false, /[0-9A-Z]/),
    foodCount: faker.datatype.number(),
  };
}

export const CalorieBudgetFragmentFactory = (): CalorieBudgetFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "CalorieBudget",
    rmrMethod: faker.helpers.arrayElement(["cunningham", "mifflin", "harrisBenedict", "average"]),
    rmrCalories: faker.datatype.number(),
    activityFactor: faker.datatype.float(),
    kcalOffset: faker.datatype.number(),
  };
}

export const FullCalorieBudgetFragmentFactory = (): FullCalorieBudgetFragment =>  {
  return {
    ...CalorieBudgetFragmentFactory(),
    goalSnapshot: {
      ...FullGoalFragmentFactory(),
    },
  };
}

export const ExchangeFragmentFactory = (): ExchangeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Exchange",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    shortName: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"]),
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    kcal: faker.datatype.float(),
  };
}

export const FullExchangeFragmentFactory = (): FullExchangeFragment =>  {
  return {
    ...ExchangeFragmentFactory(),
    exchangeServingList: {
      ...FullExchangeServingListFragmentFactory(),
    },
  };
}

export const ExchangeAmountFragmentFactory = (): ExchangeAmountFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeAmount",
    amount: faker.datatype.float(),
    pickListServingIds: [faker.datatype.uuid()],
    exchange: {
      ...ExchangeFragmentFactory(),
    },
  };
}

export const EditExchangeAmountFragmentFactory = (): EditExchangeAmountFragment =>  {
  return {
    ...ExchangeAmountFragmentFactory(),
    amountInput: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const ExchangeMealTemplateFragmentFactory = (): ExchangeMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    __typename: "ExchangeMealTemplate",
  };
}

export const FullExchangeMealTemplateFragmentFactory = (): FullExchangeMealTemplateFragment =>  {
  return {
    ...FullMealTemplateFragmentFactory(),
    ...ExchangeMealTemplateFragmentFactory(),
    exchangeTargets: [{
      ...ExchangeAmountFragmentFactory(),
    }],
  };
}

export const EditExchangeMealTemplateFragmentFactory = (): EditExchangeMealTemplateFragment =>  {
  return {
    ...EditMealTemplateFragmentFactory(),
    exchangeTargets: [{
      ...EditExchangeAmountFragmentFactory(),
    }],
  };
}

export const EditFullExchangeMealTemplateFragmentFactory = (): EditFullExchangeMealTemplateFragment =>  {
  return {
    ...EditFullMealTemplateFragmentFactory(),
    ...ExchangeMealTemplateFragmentFactory(),
    ...EditExchangeMealTemplateFragmentFactory(),
  };
}

export const ExchangeRatioFragmentFactory = (): ExchangeRatioFragment =>  {
  return {
    __typename: "ExchangeRatio",
    ratio: faker.datatype.float(),
    exchange: {
      ...ExchangeFragmentFactory(),
    },
  };
}

export const FoodOrRecipeExchangeRatiosFragmentFactory = (): FoodOrRecipeExchangeRatiosFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "FoodOrRecipeExchangeRatios",
    foodOrRecipeId: faker.datatype.uuid(),
    exchangeRatios: [{
      ...ExchangeRatioFragmentFactory(),
    }],
  };
}

export const ExchangeServingListFragmentFactory = (): ExchangeServingListFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeServingList",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const FullExchangeServingListFragmentFactory = (): FullExchangeServingListFragment =>  {
  return {
    ...ExchangeServingListFragmentFactory(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const ExchangeSetFragmentFactory = (): ExchangeSetFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeSet",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const ExchangeSetWithExchangesFragmentFactory = (): ExchangeSetWithExchangesFragment =>  {
  return {
    ...ExchangeSetFragmentFactory(),
    exchanges: [{
      ...ExchangeFragmentFactory(),
    }],
  };
}

export const FullExchangeSetWithoutExchangeRatiosFragmentFactory = (): FullExchangeSetWithoutExchangeRatiosFragment =>  {
  return {
    ...ExchangeSetFragmentFactory(),
    exchanges: [{
      ...FullExchangeFragmentFactory(),
    }],
  };
}

export const FullExchangeSetFragmentFactory = (): FullExchangeSetFragment =>  {
  return {
    ...FullExchangeSetWithoutExchangeRatiosFragmentFactory(),
    foodOrRecipeExchangeRatios: [{
      id: faker.datatype.uuid(),
      foodOrRecipeId: faker.datatype.uuid(),
      __typename: "FoodOrRecipeExchangeRatios",
      exchangeRatios: [{
        __typename: "ExchangeRatio",
        ratio: faker.datatype.float(),
        exchange: {
          ...ExchangeFragmentFactory(),
        },
      }],
    }],
  };
}

export const FoodFragmentFactory = (): FoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "BrandedFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcFoodCategoryId: faker.datatype.number(),
    usdaFdcDataType: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcId: faker.datatype.number(),
    isRecipeIngredientOnly: faker.datatype.boolean(),
    choPer100g: faker.datatype.float(),
    proPer100g: faker.datatype.float(),
    fatPer100g: faker.datatype.float(),
    kcalPer100g: faker.datatype.float(),
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    locales: [faker.internet.password(10, false, /[0-9A-Z]/)],
  };
}

export const FoodLimitedAccessFragmentFactory = (): FoodLimitedAccessFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "BrandedFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    hasFullAccess: faker.datatype.boolean(),
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    org: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    ...({
      brand: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    }),
  };
}

export const FoodPreviewFragmentFactory = (): FoodPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const BrandedFoodFragmentFactory = (): BrandedFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "BrandedFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcFoodCategoryId: faker.datatype.number(),
    usdaFdcDataType: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcId: faker.datatype.number(),
    isRecipeIngredientOnly: faker.datatype.boolean(),
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    choPer100g: faker.datatype.float(),
    proPer100g: faker.datatype.float(),
    fatPer100g: faker.datatype.float(),
    kcalPer100g: faker.datatype.float(),
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    groceryListCategory: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const GenericFoodFragmentFactory = (): GenericFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "GenericFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcFoodCategoryId: faker.datatype.number(),
    usdaFdcDataType: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcId: faker.datatype.number(),
    isRecipeIngredientOnly: faker.datatype.boolean(),
    choPer100g: faker.datatype.float(),
    proPer100g: faker.datatype.float(),
    fatPer100g: faker.datatype.float(),
    kcalPer100g: faker.datatype.float(),
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    groceryListCategory: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    owner: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    orgGroup: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const FoodWithDefaultServingFragmentFactory = (): FoodWithDefaultServingFragment =>  {
  return {
    ...FoodFragmentFactory(),
    defaultServing: {
      ...FullServingFragmentFactory(),
    },
    hasFullAccess: faker.datatype.boolean(),
    org: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    ...({
      __typename: "BrandedFood",
      brand: {
        ...BrandFragmentFactory(),
      },
    }),
  };
}

export const FoodWithServingsFragmentFactory = (): FoodWithServingsFragment =>  {
  return {
    ...FoodFragmentFactory(),
    showGramServing: faker.datatype.boolean(),
    showOunceServing: faker.datatype.boolean(),
    servings: [{
      ...ServingWithFoodOrRecipeFragmentFactory(),
    }],
  };
}

export const FullFoodGroupFragmentFactory = (): FullFoodGroupFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "FoodGroup",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    source: faker.internet.password(10, false, /[0-9A-Z]/),
    foodsCount: faker.datatype.number(),
  };
}

export const FullFoodGroupWithFoodsFragmentFactory = (): FullFoodGroupWithFoodsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "FoodGroup",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    source: faker.internet.password(10, false, /[0-9A-Z]/),
    foodsCount: faker.datatype.number(),
    foods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
  };
}

export const FoodLogNotificationSettingsFragmentFactory = (): FoodLogNotificationSettingsFragment =>  {
  return {
    edits: faker.datatype.boolean(),
    imageUploads: faker.datatype.boolean(),
    messages: faker.datatype.boolean(),
  };
}

export const GoalFragmentFactory = (): GoalFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Goal",
    kcalOffset: faker.datatype.number(),
    start: faker.datatype.datetime().toISOString().split("T")[0],
    end: faker.datatype.datetime().toISOString().split("T")[0],
  };
}

export const FullGoalFragmentFactory = (): FullGoalFragment =>  {
  return {
    ...GoalFragmentFactory(),
    type: {
      ...GoalTypeFragmentFactory(),
    },
  };
}

export const GoalTypeFragmentFactory = (): GoalTypeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "GoalType",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultKcalOffset: faker.datatype.number(),
  };
}

export const ImageFragmentFactory = (): ImageFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    url: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MacroMathFragmentFactory = (): MacroMathFragment =>  {
  return {
    gPerKg: faker.datatype.float(),
    leftOverRatio: faker.datatype.float(),
  };
}

export const MacroMealTemplateFragmentFactory = (): MacroMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    __typename: "MacroMealTemplate",
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    kcal: faker.datatype.float(),
  };
}

export const FullMacroMealTemplateFragmentFactory = (): FullMacroMealTemplateFragment =>  {
  return {
    ...FullMealTemplateFragmentFactory(),
    ...MacroMealTemplateFragmentFactory(),
  };
}

export const EditMacroMealTemplateFragmentFactory = (): EditMacroMealTemplateFragment =>  {
  return {
    ...EditMealTemplateFragmentFactory(),
    choInput: faker.internet.password(10, false, /[0-9A-Z]/),
    proInput: faker.internet.password(10, false, /[0-9A-Z]/),
    fatInput: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const EditFullMacroMealTemplateFragmentFactory = (): EditFullMacroMealTemplateFragment =>  {
  return {
    ...EditFullMealTemplateFragmentFactory(),
    ...MacroMealTemplateFragmentFactory(),
    ...EditMacroMealTemplateFragmentFactory(),
  };
}

export const MacroProtocolFragmentFactory = (): MacroProtocolFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MacroProtocol",
    cho: {
      ...MacroMathFragmentFactory(),
    },
    pro: {
      ...MacroMathFragmentFactory(),
    },
    fat: {
      ...MacroMathFragmentFactory(),
    },
    weightTarget: faker.datatype.float(),
    weightTargetInKg: faker.datatype.float(),
  };
}

export const FullMacroProtocolFragmentFactory = (): FullMacroProtocolFragment =>  {
  return {
    ...MacroProtocolFragmentFactory(),
    anthropometrySnapshot: {
      ...AnthropometryEntryFragmentFactory(),
    },
    calorieBudget: {
      ...FullCalorieBudgetFragmentFactory(),
    },
  };
}

export const MealFragmentFactory = (): MealFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Meal",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString().split("T")[1],
    end: faker.datatype.datetime().toISOString().split("T")[1],
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
  };
}

export const MealOptionFragmentFactory = (): MealOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealOption",
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const EditMealOptionFragmentFactory = (): EditMealOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isAutosaving: faker.datatype.boolean(),
  };
}

export const FullMealOptionFragmentFactory = (): FullMealOptionFragment =>  {
  return {
    ...MealOptionFragmentFactory(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const EditFullMealOptionFragmentFactory = (): EditFullMealOptionFragment =>  {
  return {
    ...FullMealOptionFragmentFactory(),
    ...EditMealOptionFragmentFactory(),
  };
}

export const MealOptionPendingFragmentFactory = (): MealOptionPendingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealOptionPending",
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const EditMealOptionPendingFragmentFactory = (): EditMealOptionPendingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isAutosaving: faker.datatype.boolean(),
  };
}

export const FullMealOptionPendingFragmentFactory = (): FullMealOptionPendingFragment =>  {
  return {
    ...MealOptionPendingFragmentFactory(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const EditFullMealOptionPendingFragmentFactory = (): EditFullMealOptionPendingFragment =>  {
  return {
    ...FullMealOptionPendingFragmentFactory(),
    ...EditMealOptionPendingFragmentFactory(),
  };
}

export const MealPlanFragmentFactory = (): MealPlanFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealPlan",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    isShared: faker.datatype.boolean(),
    isAutoSuggestionsEnabled: faker.datatype.boolean(),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    usesDateRange: faker.datatype.boolean(),
    dates: [faker.datatype.datetime().toISOString().split("T")[0]],
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    type: faker.helpers.arrayElement(["macro", "exchange"]),
    createdAt: faker.datatype.datetime().toISOString(),
    updatedAt: faker.datatype.datetime().toISOString(),
    exchangeSet: {
      id: faker.datatype.uuid(),
    },
  };
}

export const EditMealPlanFragmentFactory = (): EditMealPlanFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isAutosaving: faker.datatype.boolean(),
    exchangeTargets: [{
      ...EditExchangeAmountFragmentFactory(),
    }],
  };
}

export const MealPlanSearchFormFragmentFactory = (): MealPlanSearchFormFragment =>  {
  return {
    ...MealPlanFragmentFactory(),
    macroProtocol: {
      ...FullMacroProtocolFragmentFactory(),
    },
  };
}

export const BasicMealPlanFragmentFactory = (): BasicMealPlanFragment =>  {
  return {
    ...MealPlanFragmentFactory(),
    exchangeSet: {
      ...ExchangeSetWithExchangesFragmentFactory(),
    },
  };
}

export const PreviewMealPlanFragmentFactory = (): PreviewMealPlanFragment =>  {
  return {
    ...BasicMealPlanFragmentFactory(),
    macroProtocol: {
      ...FullMacroProtocolFragmentFactory(),
    },
    schedule: {
      ...ScheduleFragmentFactory(),
    },
    avoidedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    promotedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    avoidedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    promotedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    mostRecentEvent: {
      ...EventFragmentFactory(),
    },
    exchangeTargets: [{
      ...EditExchangeAmountFragmentFactory(),
    }],
  };
}

export const CopyableMealPlanFragmentFactory = (): CopyableMealPlanFragment =>  {
  return {
    ...BasicMealPlanFragmentFactory(),
    macroProtocol: {
      ...MacroProtocolFragmentFactory(),
      calorieBudget: {
        ...CalorieBudgetFragmentFactory(),
      },
    },
  };
}

export const EditFullMealPlanFragmentFactory = (): EditFullMealPlanFragment =>  {
  return {
    ...PreviewMealPlanFragmentFactory(),
    ...EditMealPlanFragmentFactory(),
    athlete: {
      ...AthleteWithFoodPrefsFragmentFactory(),
      macroDisplaySettings: {
        ...MacroMealPlanDisplaySettingsFragmentFactory(),
      },
      exchangeDisplaySettings: {
        ...ExchangeMealPlanDisplaySettingsFragmentFactory(),
      },
    },
    exchangeSet: {
      ...FullExchangeSetFragmentFactory(),
    },
    activityTemplates: [{
      ...ActivityTemplateFragmentFactory(),
    }],
    events: [{
      ...EventFragmentFactory(),
    }],
    mealTemplates: [{
      ...({
        ...EditFullExchangeMealTemplateFragmentFactory(),
      }),
    }],
  };
}

export const FullMealPlanFragmentFactory = (): FullMealPlanFragment =>  {
  return {
    ...PreviewMealPlanFragmentFactory(),
    athlete: {
      ...AthleteWithFoodPrefsFragmentFactory(),
      macroDisplaySettings: {
        ...MacroMealPlanDisplaySettingsFragmentFactory(),
      },
      exchangeDisplaySettings: {
        ...ExchangeMealPlanDisplaySettingsFragmentFactory(),
      },
    },
    exchangeSet: {
      ...FullExchangeSetFragmentFactory(),
    },
    activityTemplates: [{
      ...ActivityTemplateFragmentFactory(),
    }],
    events: [{
      ...EventFragmentFactory(),
    }],
    mealTemplates: [{
      ...({
        ...FullExchangeMealTemplateFragmentFactory(),
      }),
    }],
  };
}

export const EventFragmentFactory = (): EventFragment =>  {
  return {
    __typename: "Event",
    type: faker.internet.password(10, false, /[0-9A-Z]/),
    datetime: faker.datatype.datetime().toISOString(),
  };
}

export const MealTemplateFragmentFactory = (): MealTemplateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeMealTemplate",
    meal: {
      ...MealFragmentFactory(),
    },
  };
}

export const EditMealTemplateFragmentFactory = (): EditMealTemplateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isAutosaving: faker.datatype.boolean(),
    selectedMealOptionId: faker.datatype.uuid(),
    newMealOptionIds: [faker.datatype.uuid()],
  };
}

export const FullMealTemplateFragmentFactory = (): FullMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    mealOptions: [{
      ...FullMealOptionFragmentFactory(),
    }],
    pendingMealOptions: [{
      ...FullMealOptionPendingFragmentFactory(),
    }],
  };
}

export const ExportMealTemplateFragmentFactory = (): ExportMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    mealOptions: [{
      ...ViewMealOptionFragmentFactory(),
    }],
  };
}

export const EditFullMealTemplateFragmentFactory = (): EditFullMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    ...EditMealTemplateFragmentFactory(),
    mealOptions: [{
      ...EditFullMealOptionFragmentFactory(),
    }],
    pendingMealOptions: [{
      ...EditFullMealOptionPendingFragmentFactory(),
    }],
  };
}

export const NoteFragmentFactory = (): NoteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Note",
    datetime: faker.datatype.datetime().toISOString(),
    content: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const NoteWithTypeFragmentFactory = (): NoteWithTypeFragment =>  {
  return {
    ...NoteFragmentFactory(),
    type: {
      ...NoteTypeFragmentFactory(),
    },
  };
}

export const FullNoteFragmentFactory = (): FullNoteFragment =>  {
  return {
    ...NoteWithTypeFragmentFactory(),
    athlete: {
      ...AthleteFragmentFactory(),
    },
  };
}

export const NoteTypeFragmentFactory = (): NoteTypeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "NoteType",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const NutrientDietaryReferenceIntakeFragmentFactory = (): NutrientDietaryReferenceIntakeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    type: faker.helpers.arrayElement(["RDA", "UL", "AI", "DGA", "unitsPerKcal"]),
  };
}

export const PositionFragmentFactory = (): PositionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Position",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const RecipeFragmentFactory = (): RecipeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Recipe",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    steps: [faker.internet.password(10, false, /[0-9A-Z]/)],
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    prepTimeInMinutes: faker.datatype.number(),
    cookTimeInMinutes: faker.datatype.number(),
    isCurrent: faker.datatype.boolean(),
    images: [{
      ...ImageFragmentFactory(),
    }],
    isShared: faker.datatype.boolean(),
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    mealTypes: [faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"])],
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    foodCategory: {
      ...RecipeFoodCategoryFragmentFactory(),
    },
  };
}

export const RecipeLimitedAccessFragmentFactory = (): RecipeLimitedAccessFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Recipe",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    isCurrent: faker.datatype.boolean(),
    images: [{
      ...ImageFragmentFactory(),
    }],
    descendantFoods: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
      nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    hasFullAccess: faker.datatype.boolean(),
    org: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    ingredients: [{
      ...RecipeIngredientServingAmountFragmentFactory(),
    }],
  };
}

export const RecipeWithIngredientsFragmentFactory = (): RecipeWithIngredientsFragment =>  {
  return {
    ...RecipeFragmentFactory(),
    ingredients: [{
      ...ServingAmountWithFoodOrRecipeFragmentFactory(),
    }],
  };
}

export const RecipeWithServingsFragmentFactory = (): RecipeWithServingsFragment =>  {
  return {
    ...RecipeWithIngredientsFragmentFactory(),
    hasFullAccess: faker.datatype.boolean(),
    descendantRecipes: [{
      ...RecipeWithIngredientsFragmentFactory(),
    }],
    servings: [{
      ...ServingWithFoodOrRecipeFragmentFactory(),
    }],
  };
}

export const ScheduleFragmentFactory = (): ScheduleFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Schedule",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    activityFactor: faker.datatype.float(),
  };
}

export const FullScheduleFragmentFactory = (): FullScheduleFragment =>  {
  return {
    ...ScheduleFragmentFactory(),
    meals: [{
      ...MealFragmentFactory(),
    }],
    activities: [{
      ...ActivityFragmentFactory(),
    }],
    mealPlans: [{
      ...MealPlanFragmentFactory(),
    }],
  };
}

export const ServingFragmentFactory = (): ServingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Serving",
    units: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultAmount: faker.datatype.float(),
    isDefault: faker.datatype.boolean(),
    weight: faker.datatype.float(),
    usdaWeightSeq: faker.internet.password(10, false, /[0-9A-Z]/),
    perRecipeYield: faker.datatype.float(),
    isRecipeServingOnly: faker.datatype.boolean(),
    hasOwnNutrients: faker.datatype.boolean(),
  };
}

export const ServingWithMacrosFragmentFactory = (): ServingWithMacrosFragment =>  {
  return {
    ...ServingFragmentFactory(),
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
  };
}

export const ServingWithFoodOrRecipeFragmentFactory = (): ServingWithFoodOrRecipeFragment =>  {
  return {
    ...ServingFragmentFactory(),
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
    unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
    unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
    unit: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    foodOrRecipe: {
      ...({
        ...FoodFragmentFactory(),
        hasFullAccess: faker.datatype.boolean(),
        org: {
          id: faker.datatype.uuid(),
          name: faker.internet.password(10, false, /[0-9A-Z]/),
        },
        ...({
          brand: {
            id: faker.datatype.uuid(),
            name: faker.internet.password(10, false, /[0-9A-Z]/),
          },
        }),
      }),
    },
  };
}

export const RecipeIngredientServingFragmentFactory = (): RecipeIngredientServingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Serving",
    defaultAmount: faker.datatype.float(),
    isDefault: faker.datatype.boolean(),
    weight: faker.datatype.float(),
    perRecipeYield: faker.datatype.float(),
    hasOwnNutrients: faker.datatype.boolean(),
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    units: faker.internet.password(10, false, /[0-9A-Z]/),
    unit: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    foodOrRecipe: {
      ...({
        ...FoodLimitedAccessFragmentFactory(),
      }),
    },
  };
}

export const FullServingFragmentFactory = (): FullServingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Serving",
    units: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultAmount: faker.datatype.float(),
    isDefault: faker.datatype.boolean(),
    weight: faker.datatype.float(),
    perRecipeYield: faker.datatype.float(),
    hasOwnNutrients: faker.datatype.boolean(),
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    unit: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    foodOrRecipe: {
      ...({
        ...FoodLimitedAccessFragmentFactory(),
      }),
    },
  };
}

export const ExcessiveServingFragmentFactory = (): ExcessiveServingFragment =>  {
  return {
    ...ServingFragmentFactory(),
    customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
    unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
    unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
    unit: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    foodOrRecipe: {
      ...({
        ...FoodFragmentFactory(),
        ...({
          brand: {
            id: faker.datatype.uuid(),
            name: faker.internet.password(10, false, /[0-9A-Z]/),
          },
        }),
      }),
    },
  };
}

export const ServingAmountWithFoodOrRecipeFragmentFactory = (): ServingAmountWithFoodOrRecipeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ServingAmount",
    amount: faker.datatype.float(),
    position: faker.datatype.number(),
    serving: {
      ...ServingWithFoodOrRecipeFragmentFactory(),
    },
  };
}

export const FullServingAmountFragmentFactory = (): FullServingAmountFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ServingAmount",
    amount: faker.datatype.float(),
    position: faker.datatype.number(),
    serving: {
      ...FullServingFragmentFactory(),
    },
  };
}

export const ExcessiveServingAmountFragmentFactory = (): ExcessiveServingAmountFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ServingAmount",
    amount: faker.datatype.float(),
    position: faker.datatype.number(),
    serving: {
      ...ExcessiveServingFragmentFactory(),
    },
  };
}

export const SportFragmentFactory = (): SportFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Sport",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const FullSportFragmentFactory = (): FullSportFragment =>  {
  return {
    ...SportFragmentFactory(),
    positions: [{
      ...PositionFragmentFactory(),
    }],
  };
}

export const TeamFragmentFactory = (): TeamFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Team",
    gender: faker.internet.password(10, false, /[0-9A-Z]/),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const TeamWithSportFragmentFactory = (): TeamWithSportFragment =>  {
  return {
    ...TeamFragmentFactory(),
    sport: {
      ...FullSportFragmentFactory(),
    },
  };
}

export const UsdaFoodFragmentFactory = (): UsdaFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "USDAFood",
    allHighlightFields: [faker.internet.password(10, false, /[0-9A-Z]/)],
    brandOwner: faker.internet.password(10, false, /[0-9A-Z]/),
    dataType: faker.helpers.arrayElement(["Branded", "Survey", "Legacy", "Foundation"]),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    gtinUpc: faker.internet.password(10, false, /[0-9A-Z]/),
    ingredients: [faker.internet.password(10, false, /[0-9A-Z]/)],
    publishedDate: faker.datatype.datetime().toISOString().split("T")[0],
    score: faker.datatype.float(),
  };
}

export const UsdaFoodDetailFragmentFactory = (): UsdaFoodDetailFragment =>  {
  return {
    __typename: "USDAFoodDetail",
    id: faker.datatype.uuid(),
    usdaFdcFoodCategoryId: faker.datatype.number(),
    usdaFdcDataType: faker.internet.password(10, false, /[0-9A-Z]/),
    cho: {
      ...UsdaFoodNutrientFragmentFactory(),
    },
    pro: {
      ...UsdaFoodNutrientFragmentFactory(),
    },
    fat: {
      ...UsdaFoodNutrientFragmentFactory(),
    },
    foodNutrients: [{
      ...UsdaFoodNutrientFragmentFactory(),
    }],
    servings: [{
      ...UsdaFoodServingFragmentFactory(),
    }],
  };
}

export const UsdaFoodNutrientFragmentFactory = (): UsdaFoodNutrientFragment =>  {
  return {
    id: faker.datatype.uuid(),
    amount: faker.datatype.float(),
    dataPoints: faker.datatype.number(),
    unitName: faker.internet.password(10, false, /[0-9A-Z]/),
    max: faker.datatype.float(),
    min: faker.datatype.float(),
    median: faker.datatype.float(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    footnote: faker.internet.password(10, false, /[0-9A-Z]/),
    __typename: "USDAFoodNutrient",
  };
}

export const UsdaFoodServingFragmentFactory = (): UsdaFoodServingFragment =>  {
  return {
    __typename: "USDAFoodServing",
    idx: faker.datatype.number(),
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    weight: faker.datatype.float(),
    defaultAmount: faker.datatype.float(),
    units: faker.internet.password(10, false, /[0-9A-Z]/),
    isDefault: faker.datatype.boolean(),
    usdaWeightSeq: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const AthleteWithAnthroFragmentFactory = (): AthleteWithAnthroFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    position: {
      ...PositionFragmentFactory(),
    },
    mostRecentAnthropometryEntry: {
      ...AnthropometryEntryFragmentFactory(),
    },
  };
}

export const MealPlanWithAthleteFragmentFactory = (): MealPlanWithAthleteFragment =>  {
  return {
    ...MealPlanSearchFormFragmentFactory(),
    athlete: {
      ...AthleteFragmentFactory(),
    },
  };
}

export const SimpleFoodGroupFragmentFactory = (): SimpleFoodGroupFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MealPlanBulkCopyTeamPreviewFragmentFactory = (): MealPlanBulkCopyTeamPreviewFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    id: faker.datatype.uuid(),
  };
}

export const NutritionixCommonFoodFragmentFactory = (): NutritionixCommonFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "NutritionixCommonFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const NutritionixBrandedFoodFragmentFactory = (): NutritionixBrandedFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "NutritionixBrandedFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    brand: {
      id: faker.datatype.uuid(),
      __typename: "NutritionixBrand",
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      type: faker.datatype.number(),
    },
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const FoodOrRecipePreferencesFragmentFactory = (): FoodOrRecipePreferencesFragment =>  {
  return {
    ...({
      __typename: "BrandedFood",
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }),
  };
}

export const PublicMealPlanFragmentFactory = (): PublicMealPlanFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["macro", "exchange"]),
    athlete: {
      id: faker.datatype.uuid(),
      birthDate: faker.datatype.datetime().toISOString().split("T")[0],
      macroDisplaySettings: {
        __typename: "MacroMealPlanDisplaySettings",
        dailyProgressViews: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
        mealProgressViews: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
      },
      exchangeDisplaySettings: {
        __typename: "ExchangeMealPlanDisplaySettings",
        dailyProgressViewsForExchanges: faker.helpers.arrayElement(["all", "none"]),
        mealProgressViewsForExchanges: faker.helpers.arrayElement(["all", "none"]),
        dailyProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
        mealProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
      },
    },
    macroProtocol: {
      id: faker.datatype.uuid(),
      __typename: "MacroProtocol",
      cho: {
        gPerKg: faker.datatype.float(),
        leftOverRatio: faker.datatype.float(),
      },
      pro: {
        gPerKg: faker.datatype.float(),
        leftOverRatio: faker.datatype.float(),
      },
      fat: {
        gPerKg: faker.datatype.float(),
        leftOverRatio: faker.datatype.float(),
      },
      weightTarget: faker.datatype.float(),
      anthropometrySnapshot: {
        id: faker.datatype.uuid(),
        __typename: "AnthropometryEntry",
        height: faker.datatype.float(),
        weight: faker.datatype.float(),
        leanBodyMass: faker.datatype.float(),
        bodyFatMass: faker.datatype.float(),
        boneMineralDensityZScore: faker.datatype.float(),
        percentBodyFat: faker.datatype.float(),
        datetime: faker.datatype.datetime().toISOString(),
        type: faker.helpers.arrayElement(["estimate", "dexa", "bodpod", "bioelectricalImpedance", "isak", "weighIn"]),
        sex: faker.helpers.arrayElement(["male", "female"]),
        comment: faker.internet.password(10, false, /[0-9A-Z]/),
        boneMineralDensity: faker.datatype.float(),
        boneMineralDensityTScore: faker.datatype.float(),
        boneMineralContent: faker.datatype.float(),
        boneArea: faker.datatype.float(),
        boneMass: faker.datatype.float(),
        dryLeanMass: faker.datatype.float(),
        skeletalMuscleMass: faker.datatype.float(),
        trunkFat: faker.datatype.float(),
        visceralFatArea: faker.datatype.float(),
      },
      calorieBudget: {
        id: faker.datatype.uuid(),
        __typename: "CalorieBudget",
        rmrMethod: faker.helpers.arrayElement(["cunningham", "mifflin", "harrisBenedict", "average"]),
        rmrCalories: faker.datatype.number(),
        activityFactor: faker.datatype.float(),
        kcalOffset: faker.datatype.number(),
        goalSnapshot: {
          id: faker.datatype.uuid(),
          __typename: "Goal",
          kcalOffset: faker.datatype.number(),
          start: faker.datatype.datetime().toISOString().split("T")[0],
          end: faker.datatype.datetime().toISOString().split("T")[0],
          type: {
            id: faker.datatype.uuid(),
            __typename: "GoalType",
            name: faker.internet.password(10, false, /[0-9A-Z]/),
            defaultKcalOffset: faker.datatype.number(),
          },
        },
      },
    },
    exchangeTargets: [{
      id: faker.datatype.uuid(),
      __typename: "ExchangeAmount",
      amount: faker.datatype.float(),
      exchange: {
        __typename: "Exchange",
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        shortName: faker.internet.password(10, false, /[0-9A-Z]/),
        type: faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"]),
        cho: faker.datatype.float(),
        pro: faker.datatype.float(),
        fat: faker.datatype.float(),
      },
    }],
    exchangeSet: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      exchanges: [{
        id: faker.datatype.uuid(),
        __typename: "Exchange",
        type: faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"]),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        shortName: faker.internet.password(10, false, /[0-9A-Z]/),
        cho: faker.datatype.float(),
        pro: faker.datatype.float(),
        fat: faker.datatype.float(),
        exchangeServingList: {
          id: faker.datatype.uuid(),
          name: faker.internet.password(10, false, /[0-9A-Z]/),
          servingAmounts: [{
            id: faker.datatype.uuid(),
            amount: faker.datatype.float(),
            position: faker.datatype.number(),
            serving: {
              id: faker.datatype.uuid(),
              __typename: "Serving",
              isRecipeServingOnly: faker.datatype.boolean(),
              defaultAmount: faker.datatype.float(),
              isDefault: faker.datatype.boolean(),
              weight: faker.datatype.float(),
              usdaWeightSeq: faker.internet.password(10, false, /[0-9A-Z]/),
              perRecipeYield: faker.datatype.float(),
              macros: {
                cho: faker.datatype.float(),
                pro: faker.datatype.float(),
                fat: faker.datatype.float(),
                kcal: faker.datatype.float(),
              },
              foodOrRecipe: {
                ...({
                  id: faker.datatype.uuid(),
                  __typename: "BrandedFood",
                  name: faker.internet.password(10, false, /[0-9A-Z]/),
                }),
              },
              units: faker.internet.password(10, false, /[0-9A-Z]/),
              customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
              unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
              unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
            },
          }],
        },
      }],
    },
    mealTemplates: [{
      ...PublicMealTemplateFragmentFactory(),
    }],
    activityTemplates: [{
      id: faker.datatype.uuid(),
      __typename: "ActivityTemplate",
      activity: {
        __typename: "Activity",
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        start: faker.datatype.datetime().toISOString().split("T")[1],
        end: faker.datatype.datetime().toISOString().split("T")[1],
        type: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
      },
    }],
  };
}

export const PublicMealTemplateFragmentFactory = (): PublicMealTemplateFragment =>  {
  return {
    __typename: "ExchangeMealTemplate",
    ...({
      id: faker.datatype.uuid(),
      meal: {
        __typename: "Meal",
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        start: faker.datatype.datetime().toISOString().split("T")[1],
        end: faker.datatype.datetime().toISOString().split("T")[1],
        type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
      },
      exchangeTargets: [{
        __typename: "ExchangeAmount",
        id: faker.datatype.uuid(),
        amount: faker.datatype.float(),
        pickListServingIds: [faker.datatype.uuid()],
        exchange: {
          __typename: "Exchange",
          id: faker.datatype.uuid(),
          type: faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"]),
          name: faker.internet.password(10, false, /[0-9A-Z]/),
          shortName: faker.internet.password(10, false, /[0-9A-Z]/),
          cho: faker.datatype.float(),
          pro: faker.datatype.float(),
          fat: faker.datatype.float(),
        },
      }],
    }),
    mealOptions: [{
      id: faker.datatype.uuid(),
      __typename: "MealOption",
      position: faker.datatype.number(),
      note: faker.internet.password(10, false, /[0-9A-Z]/),
      servingAmounts: [{
        id: faker.datatype.uuid(),
        amount: faker.datatype.float(),
        position: faker.datatype.number(),
        serving: {
          id: faker.datatype.uuid(),
          __typename: "Serving",
          units: faker.internet.password(10, false, /[0-9A-Z]/),
          isRecipeServingOnly: faker.datatype.boolean(),
          defaultAmount: faker.datatype.float(),
          isDefault: faker.datatype.boolean(),
          weight: faker.datatype.float(),
          usdaWeightSeq: faker.internet.password(10, false, /[0-9A-Z]/),
          perRecipeYield: faker.datatype.float(),
          macros: {
            cho: faker.datatype.float(),
            pro: faker.datatype.float(),
            fat: faker.datatype.float(),
            kcal: faker.datatype.float(),
          },
          foodOrRecipe: {
            ...({
              id: faker.datatype.uuid(),
              __typename: "BrandedFood",
              name: faker.internet.password(10, false, /[0-9A-Z]/),
              usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
            }),
          },
        },
      }],
    }],
  };
}
