import { Box } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import CalendarMonth, { CalendarMonthRendDayProps } from "apps/web/src/components/Calendar/Month";
import { MealPlanDateAssignmentPreviewFragment } from "apps/web/src/types";
import classnames from "classnames";
import { addWeeks, endOfMonth, startOfMonth } from "date-fns";
import React from "react";
import { MealPlanDateAssignment } from "../../../views/Athlete/MealPlans/Content/Calendar/Form/types";
import { getMealPlanAssignedDatesInRange } from "../../../views/Athlete/MealPlans/Content/Calendar/Form/utils";
import { useMealPlanCalendarContext } from "../../../views/Athlete/MealPlans/contexts/Calendar";
import { useMealPlanColorsContext } from "../../../views/Athlete/MealPlans/contexts/Colors";

const useStyles = makeStyles(() =>
  createStyles({
    day: {
      position: "relative",
    },
    baseDot: {
      position: "absolute",
      height: 6,
      width: 6,
      borderRadius: "50%",
      bottom: 2,
    },
    singleDot: {
      left: "calc(50% - 3px)",
    },
    doubleDot1: {
      left: "calc(50% - 7.5px)",
    },
    doubleDot2: {
      left: "calc(50% + 1.5px)",
    },
  })
);

interface AssignMealPlanTemplateDateAssignmentCalendarProps {
  dateAssignment: MealPlanDateAssignment;
  setDateAssignment: (dateAssignment: MealPlanDateAssignment) => void;
  otherMealPlanDateAssignments: readonly MealPlanDateAssignmentPreviewFragment[];
  mealPlanColor: string;
  onClickCalendarDate: (date: string) => void;
  isLoading?: (startOfMonthDate: Date) => boolean;
}

export const AssignMealPlanTemplateDateAssignmentCalendar = ({
  dateAssignment,
  setDateAssignment,
  otherMealPlanDateAssignments,
  mealPlanColor,
  onClickCalendarDate,
  isLoading,
}: AssignMealPlanTemplateDateAssignmentCalendarProps) => {
  const classes = useStyles();
  const { startOfMonthDate, onChangeStartOfMonthDate } = useMealPlanCalendarContext();
  const { getMealPlanColor } = useMealPlanColorsContext();

  // Add / subtract extra week to handle days not part of the month still shown on calendar
  const startDate = serializeDate(addWeeks(startOfMonth(startOfMonthDate), -1));
  const endDate = serializeDate(addWeeks(endOfMonth(startOfMonthDate), 1));

  const assignedDates = getMealPlanAssignedDatesInRange(dateAssignment, startDate, endDate);

  const renderDay = (props: PickersDayProps<Date> & CalendarMonthRendDayProps) => {
    const { isoDate, dayText, isCurrentMonth, ...other } = props;
    const selected = assignedDates.includes(isoDate);
    const otherDateAssignment = otherMealPlanDateAssignments.find(a => a.date === isoDate);
    const backgroundColor = selected ? mealPlanColor : undefined;
    if (dateAssignment.mode === "individual") {
      return (
        <Box
          className={classes.day}
          onClick={() => {
            let individualDates = dateAssignment.individualDates;
            if (dateAssignment.individualDates.includes(isoDate)) {
              individualDates = individualDates.filter(d => d !== isoDate);
            } else {
              individualDates = individualDates.concat(isoDate);
            }

            setDateAssignment({ ...dateAssignment, individualDates });
            onClickCalendarDate(isoDate);
          }}
        >
          <PickersDay
            {...other}
            selected={false}
            sx={{
              backgroundColor,
              ":hover": {
                backgroundColor,
              },
              ":focus": {
                backgroundColor,
              },
            }}
          >
            {dayText}
          </PickersDay>
          {otherDateAssignment && (
            <Box
              className={classnames(classes.baseDot, classes.singleDot)}
              sx={{
                backgroundColor: getMealPlanColor(otherDateAssignment.mealPlan.id),
              }}
            />
          )}
        </Box>
      );
    } else {
      return (
        <Box
          className={classes.day}
          onClick={() => {
            onClickCalendarDate(isoDate);
          }}
        >
          <PickersDay {...other} selected={false}>
            {dayText}
          </PickersDay>
          {selected && (
            <Box
              className={classnames(classes.baseDot, {
                [classes.singleDot]: !otherDateAssignment,
                [classes.doubleDot1]: otherDateAssignment,
              })}
              sx={{ backgroundColor }}
            />
          )}
          {otherDateAssignment && (
            <Box
              className={classnames(classes.baseDot, {
                [classes.singleDot]: !selected,
                [classes.doubleDot2]: selected,
              })}
              sx={{
                backgroundColor: getMealPlanColor(otherDateAssignment.mealPlan.id),
              }}
            />
          )}
        </Box>
      );
    }
  };

  return (
    <CalendarMonth
      renderDay={renderDay}
      loading={isLoading?.(startOfMonthDate)}
      calendarDate={startOfMonthDate}
      onChangeCalendarDate={onChangeStartOfMonthDate}
    />
  );
};
