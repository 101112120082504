import { Box, Checkbox, FormControlLabel, Radio, Typography } from "@mui/material";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { serializeDate, today } from "@notemeal/shared/ui/utils/dateTimes";
import React, { useState } from "react";
import { StartDateInPastBehavior } from "../../../views/Athlete/MealPlans/Content/Calendar/Form/DateAssignment";
import DayOfWeekSelector from "../../../views/Athlete/MealPlans/Content/Calendar/Form/DayOfWeekSelector";
import { MealPlanDateAssignment } from "../../../views/Athlete/MealPlans/Content/Calendar/Form/types";

interface AssignMealPlanTemplateDateAssignmentFormProps {
  dateAssignment: MealPlanDateAssignment;
  setDateAssignment: (dateAssignment: MealPlanDateAssignment) => void;
  calendarLocation: "below" | "on left";
  startDateInPastBehavior: StartDateInPastBehavior;
  size?: "small" | "medium";
}

export const AssignMealPlanTemplateDateAssignmentForm = ({
  dateAssignment,
  setDateAssignment,
  startDateInPastBehavior,
  calendarLocation,
  size,
}: AssignMealPlanTemplateDateAssignmentFormProps) => {
  const [startDateErrorMessage, setStartDateErrorMessage] = useState<string | null>(null);
  const [endDateErrorMessage, setEndDateErrorMessage] = useState<string | null>(null);

  const startDateProps: Partial<DesktopDatePickerProps<Date>> =
    startDateInPastBehavior === "error"
      ? {
          disablePast: true,
        }
      : {
          disabled: true,
        };

  return (
    <>
      <Typography variant={size === "small" ? "body2" : "body1"} color="textSecondary">
        {dateAssignment.mode === "weekly"
          ? "Select the days from the options listed below"
          : `Select dates from the calendar displayed ${calendarLocation}`}
      </Typography>
      <FormControlLabel
        control={
          <Radio
            size="small"
            checked={dateAssignment.mode === "weekly"}
            onChange={() => setDateAssignment({ ...dateAssignment, mode: "weekly" })}
          />
        }
        sx={{ pl: 1.5 }}
        label="Weekly"
        labelPlacement="end"
      />
      {dateAssignment.mode === "weekly" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, pl: 4 }}>
          <DayOfWeekSelector
            daysOfWeek={dateAssignment.dayOfWeekPriorities.map(d => d.dayOfWeek)}
            onToggleDayOfWeek={dayOfWeek => {
              let dayOfWeekPriorities = dateAssignment.dayOfWeekPriorities;
              const matchingDayOfWeek = dateAssignment.dayOfWeekPriorities.find(d => d.dayOfWeek === dayOfWeek);
              if (matchingDayOfWeek) {
                dayOfWeekPriorities = dayOfWeekPriorities.filter(d => d !== matchingDayOfWeek);
              } else {
                dayOfWeekPriorities = [...dayOfWeekPriorities, { dayOfWeek: dayOfWeek, priority: 0 }];
              }

              setDateAssignment({ ...dateAssignment, dayOfWeekPriorities });
            }}
            sx={{ width: "100%", justifyContent: "left" }}
          />
          <DesktopDatePicker
            sx={{ mt: 3, width: "220px" }}
            label="Start Date*"
            onChange={date => {
              if (!date) {
                setStartDateErrorMessage("No start date selected");
              } else if (startDateInPastBehavior === "error" && date < today) {
                setStartDateErrorMessage("Start Date cannot be in the past");
              } else {
                setStartDateErrorMessage(null);
              }

              setDateAssignment({ ...dateAssignment, startDateRaw: date });
            }}
            slotProps={{
              textField: {
                helperText: startDateErrorMessage,
                error: !!startDateErrorMessage,
              },
            }}
            value={dateAssignment.startDateRaw}
            {...startDateProps}
          />
          <DesktopDatePicker
            sx={{ mt: 3, width: "220px" }}
            label="End Date"
            onChange={date => {
              if (!date) {
                setEndDateErrorMessage("No end date selected");
              } else if (date < today) {
                setEndDateErrorMessage("End Date cannot be in the past");
              } else if (serializeDate(date) < dateAssignment.startDate) {
                setEndDateErrorMessage("End Date cannot not be before Start Date");
              } else {
                setEndDateErrorMessage(null);
              }

              setDateAssignment({ ...dateAssignment, endDateRaw: date });
            }}
            minDate={dateAssignment.startDateRaw}
            slotProps={{
              textField: {
                helperText: endDateErrorMessage,
                error: !!endDateErrorMessage,
              },
            }}
            disabled={dateAssignment.disableEndDate}
            value={dateAssignment.endDateRaw}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={dateAssignment.disableEndDate}
                onChange={() => {
                  setDateAssignment({ ...dateAssignment, disableEndDate: !dateAssignment.disableEndDate });
                  setEndDateErrorMessage(null);
                }}
              />
            }
            label="No end date"
          />
        </Box>
      )}
      <FormControlLabel
        control={
          <Radio
            size="small"
            checked={dateAssignment.mode === "individual"}
            onChange={() => setDateAssignment({ ...dateAssignment, mode: "individual" })}
          />
        }
        sx={{ pl: 1.5 }}
        label="Individual Dates"
        labelPlacement="end"
      />
    </>
  );
};
