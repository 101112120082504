import { LoginCallback } from "@okta/okta-react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom-v5-compat";
import { ActivateUserPage } from "./ActivateUserPage";
import { ApiDocsPage } from "./ApiDocsPage";
import { ForgotPasswordPage } from "./ForgotPasswordPage";
import { ForgotUsernamePage } from "./ForgotUsernamePage";
import { LoginPage } from "./LoginPage";
import { MealPlanPage } from "./MealPlanPage";
import { NoAuthNavLayout } from "./NoAuthNavLayout";
import { NAV_API_DOCS, NAV_LOGIN, NAV_SELF_SERVICE_ONBOARDING } from "./NoAuthPaths";
import { ResetPasswordPage } from "./ResetPasswordPage";
import { SelfServiceAthletesPage } from "./SelfServiceAthletesPage";

export const NoAuthRouter = () => (
  <Routes>
    <Route element={<NoAuthNavLayout />}>
      <Route path={NAV_LOGIN} element={<LoginPage />} />
      <Route path="/forgot-username" element={<ForgotUsernamePage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/activate/:activateToken" element={<ActivateUserPage />} />
      <Route path="/reset-password/:resetPasswordToken" element={<ResetPasswordPage />} />
      {/* used for teamworks SSO */}
      <Route path="/login/callback" element={<LoginCallback />} />
    </Route>
    <Route path={NAV_API_DOCS} element={<ApiDocsPage />} />
    <Route path="/meal-plans/:mealPlanId" element={<MealPlanPage />} />
    <Route path={`${NAV_SELF_SERVICE_ONBOARDING}/:orgId?`} element={<SelfServiceAthletesPage />} />
    <Route path="*" element={<Navigate to={NAV_LOGIN} replace />} />
  </Routes>
);
