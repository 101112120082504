import { Box, Divider, Typography } from "@mui/material";
import { AssignMealPlanTemplateContent } from "apps/web/src/components/MealPlanTemplate/AssignMealPlanTemplate/AssignMealPlanTemplateContent";
import { AssignMealPlanTemplateProvider } from "apps/web/src/components/MealPlanTemplate/AssignMealPlanTemplate/AssignMealPlanTemplateContext";
import { useMealPlanTemplateForAssignmentQuery } from "apps/web/src/types";
import React from "react";
import { useParams } from "react-router-dom-v5-compat";
import { SecondaryNavLayout } from "../../SecondaryNav/SecondaryNavLayout";
import { mealPlanAssignTemplateLink, mealPlanTemplatesLink } from "./MealPlansPaths";

export const AssignTemplatePage = () => {
  const { id: templateId } = useParams<{ id: string }>();

  const { data, loading } = useMealPlanTemplateForAssignmentQuery({ variables: { id: templateId ?? "" }, skip: !templateId });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!templateId || !data?.mealPlanTemplate) {
    return <Typography>Error: no meal plan template found with id: {templateId}</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ flex: 0 }}>
        <SecondaryNavLayout
          current={mealPlanAssignTemplateLink}
          enableAthleteSearch={false}
          breadCrumbs={[mealPlanTemplatesLink]}
          tabs={[]}
          showTitle
        />
      </Box>
      <Divider />
      <AssignMealPlanTemplateProvider mealPlanTemplate={data.mealPlanTemplate}>
        <AssignMealPlanTemplateContent />
      </AssignMealPlanTemplateProvider>
    </Box>
  );
};
