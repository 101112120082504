import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import ChevronRight from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, List, styled, TextField, Typography, useTheme } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { useDebounce } from "@notemeal/shared/ui/hooks/useDebounce";
import { sortByFn } from "@notemeal/utils/sort";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import {
  AthleteAnthropometryFilter,
  AthleteForMealPlanTemplateAssignmentFragment,
  AthletesForMealPlanTemplateAssignmentDocument,
  AthletesForMealPlanTemplateAssignmentQuery,
  AthletesForMealPlanTemplateAssignmentQueryVariables,
  GoalTypeFragment,
  NamedTagForSelectionFragment,
  TeamPreviewFragment,
  useAthletesForMealPlanTemplateAssignmentQuery,
  useSelectedAthletesForCustomTagCreateQuery,
} from "apps/web/src/types";
import NamedTagSelector from "apps/web/src/views/Tags/Selectors/NamedTagSelector";
import TeamSelector from "apps/web/src/views/Tags/Selectors/TeamSelector";
import React, { useCallback, useMemo, useState } from "react";
import WeightTargetTypeSelect from "../../Goal/TypeSelect";
import useInfiniteCursorConnectionScroll from "../../universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import LabeledSelect from "../../universal/LabeledSelect";
import { AssignMealPlanTemplateAthleteInfiniteScroll } from "./AssignMealPlanTemplateAthleteInfiniteScroll";
import { AssignMealPlanTemplateAthleteListItem } from "./AssignMealPlanTemplateAthleteListItem";
import { useAssignMealPlanTemplate } from "./AssignMealPlanTemplateContext";

const ListHeader = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 16px 0px 16px",
}));

const AthleteContainer = styled("div")(
  ({
    theme: {
      spacing,
      palette: { grey },
    },
  }) => ({
    flex: 1,
    height: "calc(100vh - 650px)",
    minHeight: "450px",
    border: `1px solid ${grey[500]}`,
    borderRadius: "5px",
    marginBottom: spacing(2),
  })
);

const athleteListStyle = { overflowY: "auto", height: "calc(100% - 40px)" };
const listItemStyle = { width: `calc(100% - ${1})` };

interface AssignMealPlanTemplateAthleteSelectProps {
  athletes: AthleteForMealPlanTemplateAssignmentFragment[];
  setAthletes: (athletes: AthleteForMealPlanTemplateAssignmentFragment[]) => void;
}

type AnthropometryFilterOption = { id: AthleteAnthropometryFilter | "view_all"; name: string };

const ANTHROPOMETRY_FILTER_OPTIONS: AnthropometryFilterOption[] = [
  { id: "has_anthropometry_entry", name: "Has anthro entry" },
  { id: "no_anthropometry_entry", name: "No anthro entry" },
  { id: "view_all", name: "View all" },
];

export const AssignMealPlanTemplateAthleteSelect = ({ athletes, setAthletes }: AssignMealPlanTemplateAthleteSelectProps) => {
  const {
    palette: { grey },
  } = useTheme();
  const { mealPlanTemplate } = useAssignMealPlanTemplate();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedQuery = useDebounce(searchQuery, 200);
  const [teamsFilter, setTeamsFilter] = useState<TeamPreviewFragment[]>([]);
  const [tagsFilter, setTagsFilter] = useState<NamedTagForSelectionFragment[]>([]);
  const [weightTargetTypeFilter, setWeightTargetTypeFilter] = useState<GoalTypeFragment | null>(null);
  const [anthropometryFilter, setAnthropometryFilter] = useState<AnthropometryFilterOption | null>(null);
  const apolloClient = useApolloClient();

  const hasFilter =
    searchQuery !== "" ||
    teamsFilter.length > 0 ||
    tagsFilter.length > 0 ||
    weightTargetTypeFilter !== null ||
    anthropometryFilter !== null;
  const resetFilter = () => {
    setSearchQuery("");
    setTeamsFilter([]);
    setTagsFilter([]);
    setWeightTargetTypeFilter(null);
    setAnthropometryFilter(null);
  };

  const rmrMethod =
    mealPlanTemplate.__typename === "MacroMealPlanTemplate" ? mealPlanTemplate.macroProtocol.calorieBudget?.rmrMethod ?? null : null;

  const tagFilterIds = useMemo(() => {
    return tagsFilter.map(namedTag => namedTag.tag.id);
  }, [tagsFilter]);

  const teamFilterIds = useMemo(() => {
    return teamsFilter.map(({ id }) => id);
  }, [teamsFilter]);

  const selectedAthleteIds = useMemo(() => {
    return athletes.map(({ id }) => id);
  }, [athletes]);

  const { data, loading } = useSelectedAthletesForCustomTagCreateQuery({
    variables: {
      query: debouncedQuery,
      tagIds: tagFilterIds,
      teamIds: teamFilterIds,
    },
  });

  const searchResults = useInfiniteCursorConnectionScroll({
    useCursorConnectionQuery: useAthletesForMealPlanTemplateAssignmentQuery,
    getQueryVariablesFromPagination: useCallback(
      ({ cursor, limit }) => ({
        variables: {
          pagination: { cursor, limit },
          query: debouncedQuery,
          tagIds: tagFilterIds,
          teamIds: teamFilterIds,
          weightTargetTypeId: weightTargetTypeFilter?.id ?? null,
          anthropometryFilter: anthropometryFilter?.id === "view_all" ? null : anthropometryFilter?.id ?? null,
          rmrMethod,
        },
      }),
      [debouncedQuery, tagFilterIds, teamFilterIds, weightTargetTypeFilter, anthropometryFilter, rmrMethod]
    ),
    queryKey: "athletesForMealPlanTemplateAssignmentCursorConnection",
    edgesAreEqual: useCallback(
      (athlete1: AthleteForMealPlanTemplateAssignmentFragment, athlete2: AthleteForMealPlanTemplateAssignmentFragment) => {
        return athlete1.id === athlete2.id;
      },
      []
    ),
    limit: 25,
  });

  const onClickItem = (athlete: AthleteForMealPlanTemplateAssignmentFragment) => {
    const included = athletes.some(({ id }) => id === athlete.id);
    if (!included) {
      setAthletes(
        sortByFn([...athletes, athlete], ath => `${ath.lastName}, ${ath.firstName}`) as AthleteForMealPlanTemplateAssignmentFragment[]
      );
    }
  };

  const onSelectAll = async () => {
    const limit = 100;

    let athletesToAdd: AthleteForMealPlanTemplateAssignmentFragment[] = [];
    let hasError = false;
    let hasNextPage = true;
    let cursor = null;

    while (!hasError && hasNextPage) {
      const result = (await apolloClient.query<
        AthletesForMealPlanTemplateAssignmentQuery,
        AthletesForMealPlanTemplateAssignmentQueryVariables
      >({
        query: AthletesForMealPlanTemplateAssignmentDocument,
        variables: {
          pagination: { limit, cursor },
          query: debouncedQuery,
          tagIds: tagFilterIds,
          teamIds: teamFilterIds,
          weightTargetTypeId: weightTargetTypeFilter?.id ?? null,
          anthropometryFilter: anthropometryFilter?.id === "view_all" ? null : anthropometryFilter?.id ?? null,
          rmrMethod,
        },
      })) as ApolloQueryResult<AthletesForMealPlanTemplateAssignmentQuery>; // casting because theres some sort of ts bug with the query in a while loop

      hasNextPage = result.data.athletesForMealPlanTemplateAssignmentCursorConnection.pageInfo.hasNextPage;
      cursor = result.data.athletesForMealPlanTemplateAssignmentCursorConnection.pageInfo.endCursor;
      hasError = result.errors !== undefined;

      athletesToAdd = [
        ...athletesToAdd,
        ...result.data.athletesForMealPlanTemplateAssignmentCursorConnection.edges
          .filter(a => !!a.mostRecentAnthropometryEntryForRmrMethod)
          .filter(a => !selectedAthleteIds.includes(a.id)),
      ];
    }

    setAthletes(
      sortByFn(
        [...athletes, ...athletesToAdd],
        ath => `${ath.lastName}, ${ath.firstName}`
      ) as AthleteForMealPlanTemplateAssignmentFragment[]
    );
  };

  const filteredRHSAthletes = useMemo(() => {
    if (!hasFilter) {
      return sortByFn(athletes, ath => `${ath.lastName}, ${ath.firstName}`);
    }
    if (!data || loading) {
      return undefined;
    }
    const possibleAthleteIds = data.athletesForCustomTagCreation.map(({ id }) => id);
    return athletes.filter(({ id }) => possibleAthleteIds.includes(id));
  }, [data, loading, hasFilter, athletes]);

  const onSelectedAthleteClick = (athlete: AthleteForMealPlanTemplateAssignmentFragment) => {
    setAthletes(athletes.filter(({ id }) => id !== athlete.id));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <SectionHeader header="Search for Athletes" />
      <TextField
        sx={{ mt: 1, width: 400 }}
        placeholder="Search for athletes"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
      />
      <Box sx={{ display: "flex", alignItems: "flex-end", my: 2, mx: 0, gap: 2 }}>
        <TeamSelector
          sx={{ mt: 0, width: 280 }}
          selectedTeams={teamsFilter}
          onChange={teams => setTeamsFilter(teams)}
          limitTags={2} />
        <NamedTagSelector
          sx={{ mt: 0, width: 280 }}
          selectedNamedTags={tagsFilter}
          onChange={tags => setTagsFilter(tags)}
          limitTags={2} />
        <Box sx={{ mt: 0, width: 280 }}>
          <WeightTargetTypeSelect onChange={wtt => setWeightTargetTypeFilter(wtt)} selectedType={weightTargetTypeFilter} />
        </Box>
        <Box sx={{ mt: 0, width: 280 }}>
          <LabeledSelect
            placeholder="Anthropometry"
            options={ANTHROPOMETRY_FILTER_OPTIONS}
            selectedOption={anthropometryFilter}
            onChange={option => setAnthropometryFilter(option)}
            optionToName={o => o.name}
            textFieldProps={{
              margin: "dense",
            }}
          />
        </Box>
        {hasFilter && (
          <Button variant="textInformational" onClick={() => resetFilter()}>
            Clear Filters
          </Button>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <AthleteContainer>
          <ListHeader>
            <Typography variant="h4">Available Athletes</Typography>
            <Button
              onClick={onSelectAll}
              size="small"
              variant="textInformational">
              Select All
            </Button>
          </ListHeader>
          <AssignMealPlanTemplateAthleteInfiniteScroll
            searchResults={searchResults}
            onClickItem={onClickItem}
            selectedAthleteIds={selectedAthleteIds}
            sx={athleteListStyle}
          />
        </AthleteContainer>
        <ChevronRight fontSize="large" />
        <AthleteContainer>
          <ListHeader sx={{ gap: 1 }}>
            <Typography variant="h4">Selected Athletes ({athletes.length})</Typography>
            <Typography variant="body1Medium" sx={{ color: grey[500] }}>
              ({filteredRHSAthletes ? filteredRHSAthletes.length : 0} Results Shown)
            </Typography>
          </ListHeader>
          <List disablePadding sx={athleteListStyle}>
            {filteredRHSAthletes === undefined ? (
              <Loading />
            ) : (
              filteredRHSAthletes.map(athlete => {
                return (
                  <AssignMealPlanTemplateAthleteListItem
                    sx={listItemStyle}
                    athlete={athlete}
                    key={athlete.id}
                    onClick={onSelectedAthleteClick}
                    showRemove
                  />
                );
              })
            )}
          </List>
        </AthleteContainer>
      </Box>
    </Box>
  );
};
