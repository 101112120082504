import { useApolloClient } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { NutritionColorIcon } from "@notemeal/shared/ui/global/Icons";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { AssignMealPlanTemplateStatus } from "apps/web/src/components/MealPlanTemplate/AssignMealPlanTemplate/AssignMealPlanTemplateStatus";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useOffsetPagination } from "apps/web/src/utils/pagination";
import CreateOrgMealPlanTemplate from "apps/web/src/views/MealPlanTemplates/CreateOrgMealPlanTemplate";
import { ManagedByMultiSelect } from "apps/web/src/views/MealPlanTemplates/ManagedByMultiselect";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";
import { MealPlanTemplateOwnerPreviewFragment, useGoalTypesQuery, useResourceSharingOrgGroupsQuery } from "../../../../types";
import { OrgGroupMealPlanTemplates } from "../../../../views/MealPlanTemplates/OrgGroupMealPlanTemplates";
import { OrgMealPlanTemplates } from "../../../../views/MealPlanTemplates/OrgMealPlanTemplates";
import { StaffMealPlanTemplates } from "../../../../views/MealPlanTemplates/StaffMealPlanTemplates";

export const ROWS_PER_PAGE = 10;
export const ORG = "Organization Templates";
export const ORG_GROUP = "Org Group Templates";
export const STAFF = "Templates";
export const BULK_ASSIGNMENTS_STATUS = "Assignment Status";

const getTabFromSearchParams = (searchParams: URLSearchParams) => {
  const tab = searchParams.get("tab");

  // allows navigation from the assignment flow page back to this page with the assignment status tab selected by default
  if (tab === "assignment-status") {
    return BULK_ASSIGNMENTS_STATUS;
  }

  return ORG;
};

export const MealPlanTemplatesPage = () => {
  const [searchParams] = useSearchParams();
  const { isMetricLocale } = useLocaleContext();
  const goalTypeResult = useGoalTypesQuery();
  const goalTypes = goalTypeResult.data?.goalTypes || [];

  const orgGroupResult = useResourceSharingOrgGroupsQuery();
  const hasOrgGroupTemplates = (orgGroupResult.data && orgGroupResult.data.resourceSharingOrgGroups.length > 0) ?? false;
  const hasMealPlanTemplateBulkAssignments = useHasFeature("mealPlanTemplateBulkAssignments");

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const [selected, setSelected] = useState(getTabFromSearchParams(searchParams));
  const staffTab = {
    label: STAFF,
    icon: <NutritionColorIcon />,
  };
  const tabs: (
    | string
    | {
        label: string;
        icon: React.JSX.Element;
      }
  )[] = [ORG];

  if (hasOrgGroupTemplates) {
    tabs.push(ORG_GROUP);
  }

  if (!isMetricLocale) {
    tabs.push(staffTab);
  }

  if (hasMealPlanTemplateBulkAssignments) {
    tabs.push(BULK_ASSIGNMENTS_STATUS);
  }

  const changeTab = (tab: string) => handleSelected(tab);
  const pagination = useOffsetPagination(ROWS_PER_PAGE);
  const { queryText, onChangeQueryText, onChangePage } = pagination;

  const [selectedUsers, setSelectedUsers] = useState<MealPlanTemplateOwnerPreviewFragment[]>([]);

  const apolloClient = useApolloClient();
  const { setMessage } = useSnackbar();
  const onCompleteAction = (action: string, name: string, resetPaging: boolean, mealPlanTemplateId?: string) => {
    if (resetPaging) {
      // Evict both org and orgGroup queries, since meal plan template can be transferred between org and org group
      apolloClient.cache.evict({ fieldName: "mealPlanTemplateOffsetConnection", broadcast: true });
      apolloClient.cache.evict({ fieldName: "orgGroupMealPlanTemplateOffsetConnection", broadcast: true });
      // Also evict org group owner query, since the set of owners may have changed
      apolloClient.cache.evict({ fieldName: "orgGroupMealPlanTemplateOwnerCursorConnection", broadcast: true });
    }
    setMessage("success", `Successfully ${action} Meal Plan Template ${name}`);
    setSelectedTemplateId(mealPlanTemplateId || null);
  };

  const onCopiedTemplate = (mealPlanTemplateId: string) => {
    onCompleteAction("copied", "", true);
    handleSelected(ORG);
    setSelectedTemplateId(mealPlanTemplateId);
  };

  const handleSelected = (selected: string) => {
    onChangeQueryText("");
    setSelected(selected);
  };

  const [showCreate, setShowCreate] = useState(false);

  return (
    <Box sx={{ flex: 1, height: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
        <TWTabGroup
          tabs={tabs}
          onSelected={handleSelected}
          outsideSelected={selected} />
        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", alignItems: "flex-end" }}>
          {selected === ORG_GROUP && (
            <ManagedByMultiSelect
              searchText={queryText}
              selectedUsers={selectedUsers}
              onChangeSelectedUsers={setSelectedUsers}
              sx={{ minWidth: 200 }}
            />
          )}
          {![ORG_GROUP, BULK_ASSIGNMENTS_STATUS].includes(selected) && (
            <TextField
              sx={{ width: 200 }}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={queryText}
              onChange={e => onChangeQueryText(e.target.value)}
            />
          )}
          {selected === ORG && <Button onClick={() => setShowCreate(true)}>Create Template</Button>}
        </Box>
      </Box>
      {selected === ORG && (
        <OrgMealPlanTemplates
          pagination={pagination}
          onCompleteAction={onCompleteAction}
          onSelectTemplateId={setSelectedTemplateId}
          selectedTemplateId={selectedTemplateId}
          orgGroups={orgGroupResult.data?.resourceSharingOrgGroups || []}
          goalTypes={goalTypes}
        />
      )}
      {selected === ORG_GROUP && (
        <OrgGroupMealPlanTemplates
          changeTab={changeTab}
          selectedUsers={selectedUsers}
          onCompleteAction={onCompleteAction}
          onSelectEditTemplateId={setSelectedTemplateId}
          selectedEditTemplateId={selectedTemplateId}
          goalTypes={goalTypes}
          hasOrgGroupTemplates={hasOrgGroupTemplates}
        />
      )}
      {selected === STAFF && <StaffMealPlanTemplates
        pagination={pagination}
        onCopiedTemplate={onCopiedTemplate}
        goalTypes={goalTypes} />}
      {selected === BULK_ASSIGNMENTS_STATUS && <AssignMealPlanTemplateStatus />}
      {showCreate && (
        <CreateOrgMealPlanTemplate
          open={showCreate}
          onCompleteAction={(...args) => {
            onCompleteAction(...args);
            setShowCreate(false);
            onChangePage(0);
          }}
          onClose={() => setShowCreate(false)}
          goalTypes={goalTypes}
        />
      )}
    </Box>
  );
};
