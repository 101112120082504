import { datadogRum } from "@datadog/browser-rum";
import { Box } from "@mui/material";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import axios from "axios";
import { LocaleContextProvider } from "libs/shared/ui/src/lib/contexts/Locale/LocaleContext";
import React, { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom-v5-compat";
import { Snackbar } from "./components/Snackbar/Snackbar";
import { useSnackbar } from "./components/Snackbar/SnackbarContext";
import { MuiLocalizationProvider } from "./contexts/MuiLocalizationProvider";
import { AuthRouter } from "./pages/Auth/AuthRouter";
import { getOrgUserDetails } from "./pages/Auth/OrgUtils";
import { AuthRoute } from "./pages/Auth/ProtectedRoutes";
import ErrorBoundary from "./pages/ErrorBoundary";
import { NoAuthRouter } from "./pages/NoAuth/NoAuthRouter";
import { oktaAuth } from "./utils/oktaAuth";
import { useUser } from "./utils/tokens";

const App = () => {
  const { variant, message, setMessage } = useSnackbar();
  const user = useUser();
  const { userId: id, userName: name, isStaff, orgMembershipId, orgName } = getOrgUserDetails(user);
  datadogRum.setUser({ id, name, isStaff, orgName });

  // Refresh browser on navigation if our client is out of date
  const location = useLocation();
  useEffect(() => {
    const checkRemoteEtag = async () => {
      const { headers } = await axios.head("/index.html");
      const remoteEtag = headers["etag"];

      const localEtag = sessionStorage.getItem("localEtag") || "first time";
      if (localEtag !== remoteEtag) {
        sessionStorage.setItem("localEtag", remoteEtag);
        window.location.reload();
      }
    };
    checkRemoteEtag();
  }, [location]);

  const history = useHistory();
  const restoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    // Key is used here to force an unmount of the app when a user switches orgs.
    // This will force the queries to retrigger and when used in tandem with useSwitchOrgs in
    // apps/web/src/components/OrgMembership/utils.ts guarantees we have fresh data only for the new org.
    <Box sx={{ display: "flex", height: "100vh", width: "100wh" }} key={orgMembershipId}>
      <DndProvider backend={HTML5Backend}>
        {/* used for teamworks SSO */}
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <ErrorBoundary user={user}>
            {user ? (
              <LocaleContextProvider>
                <MuiLocalizationProvider>
                  <AuthRoute>
                    <AuthRouter />
                  </AuthRoute>
                </MuiLocalizationProvider>
              </LocaleContextProvider>
            ) : (
              <NoAuthRouter />
            )}
            <>
              <Snackbar
                variant={variant}
                message={message}
                setMessage={setMessage} />
            </>
          </ErrorBoundary>
        </Security>
      </DndProvider>
    </Box>
  );
};

export default App;
