import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import {
  NAV_ORG_MEAL_PLANS_MEAL_PLAN_TEMPLATES,
  NAV_ORG_MEAL_PLANS_MEAL_PLAN_TEMPLATES_ASSIGNMENT_STATUS,
} from "apps/web/src/pages/Auth/Org/MealPlans/MealPlansPaths";
import {
  AthleteForMealPlanTemplateAssignmentFragment,
  GoalType,
  MealPlanTemplateForAssignmentQuery,
  MealPlanTemplateGoal,
  useCreateBulkAssignMealPlanTemplateJobMutation,
} from "apps/web/src/types";
import React, { ReactNode, createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { initMealPlanDateAssignmentState } from "../../../views/Athlete/MealPlans/Content/Calendar/Form/reducer";
import { MealPlanDateAssignment } from "../../../views/Athlete/MealPlans/Content/Calendar/Form/types";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import { AssignMealPlanTemplateStep } from "./AssignMealPlanTemplateStepper";

export interface AssignMealPlanTemplateContextType {
  mealPlanTemplate: MealPlanTemplateForAssignmentQuery["mealPlanTemplate"];
  step: AssignMealPlanTemplateStep;
  setStep: (step: AssignMealPlanTemplateStep) => void;
  onNext: () => void;
  onBack: () => void;
  name: string;
  setName: (name: string) => void;
  notes: string | null;
  setNotes: (notes: string | null) => void;
  weightTargetTypeId: string;
  setWeightTargetTypeId: (weightTargetTypeId: string) => void;
  dateAssignment: MealPlanDateAssignment;
  setDateAssignment: (dateAssignment: MealPlanDateAssignment) => void;
  isAutoSuggestionsEnabled: boolean;
  setIsAutoSuggestionsEnabled: (isAutoSuggestionsEnabled: boolean) => void;
  athletes: AthleteForMealPlanTemplateAssignmentFragment[];
  setAthletes: (athletes: AthleteForMealPlanTemplateAssignmentFragment[]) => void;
  goals: readonly (Pick<MealPlanTemplateGoal, "id" | "name" | "kcalOffset"> & {
    readonly goalType: Pick<GoalType, "id" | "name">;
  })[];
}

export const AssignMealPlanTemplateContext = createContext<AssignMealPlanTemplateContextType>({
  mealPlanTemplate: {} as MealPlanTemplateForAssignmentQuery["mealPlanTemplate"],
  step: 0,
  setStep: () => {},
  onNext: () => {},
  onBack: () => {},
  name: "",
  setName: () => {},
  notes: null,
  setNotes: () => {},
  weightTargetTypeId: "",
  setWeightTargetTypeId: () => {},
  dateAssignment: initMealPlanDateAssignmentState(""),
  setDateAssignment: () => {},
  isAutoSuggestionsEnabled: false,
  setIsAutoSuggestionsEnabled: () => {},
  athletes: [],
  setAthletes: () => {},
  goals: [],
});

export const AssignMealPlanTemplateProvider = ({
  children,
  mealPlanTemplate,
}: {
  children: ReactNode;
  mealPlanTemplate: MealPlanTemplateForAssignmentQuery["mealPlanTemplate"];
}) => {
  const navigate = useNavigate();
  const { setMessage } = useSnackbar();
  const clientTimezone = useClientTimezone();
  const goals = mealPlanTemplate.__typename === "MacroMealPlanTemplate" ? mealPlanTemplate.macroProtocol.calorieBudget?.goals ?? [] : [];

  const [step, setStep] = React.useState<AssignMealPlanTemplateStep>(AssignMealPlanTemplateStep.NameAndType);
  const [name, setName] = useState<string>(mealPlanTemplate.name);
  const [notes, setNotes] = useState<string | null>(null);
  const [weightTargetTypeId, setWeightTargetTypeId] = useState<string>(goals[0].id);
  const [dateAssignment, setDateAssignment] = useState<MealPlanDateAssignment>(initMealPlanDateAssignmentState(clientTimezone));
  const [isAutoSuggestionsEnabled, setIsAutoSuggestionsEnabled] = useState<boolean>(false);
  const [athletes, setAthletes] = useState<AthleteForMealPlanTemplateAssignmentFragment[]>([]);

  const [createJob] = useCreateBulkAssignMealPlanTemplateJobMutation({});

  const onNext = async () => {
    if (step === AssignMealPlanTemplateStep.NameAndType) {
      if (!name) {
        setMessage("error", "Name is required");
        return;
      }

      setStep(AssignMealPlanTemplateStep.DateAssignment);
    } else if (step === AssignMealPlanTemplateStep.DateAssignment) {
      if (dateAssignment.mode === "individual" && dateAssignment.individualDates.length === 0) {
        setMessage("error", "No dates selected");
        return;
      } else if (dateAssignment.mode === "weekly" && dateAssignment.dayOfWeekPriorities.length === 0) {
        setMessage("error", "Name is required");
        return;
      }

      setStep(AssignMealPlanTemplateStep.ChooseAthletes);
    } else if (step === AssignMealPlanTemplateStep.ChooseAthletes) {
      if (athletes.length === 0) {
        setMessage("error", "At least one athlete is required");
        return;
      }

      try {
        await createJob({
          variables: {
            input: {
              mealPlanTemplateId: mealPlanTemplate.id,
              name,
              description: notes,
              weightTargetTypeId,
              dateAssignment: {
                individualDates: dateAssignment.individualDates.length ? dateAssignment.individualDates : null,
                startDate: dateAssignment.startDate,
                endDate: dateAssignment.endDate,
                daysOfWeek: dateAssignment.dayOfWeekPriorities.length
                  ? dateAssignment.dayOfWeekPriorities.map(({ dayOfWeek }) => dayOfWeek)
                  : null,
              },
              isAutoSuggestionsEnabled,
              athleteIds: athletes.map(a => a.id),
            },
          },
        });
        setMessage("success", "Meal plan template assignment has started...");
        navigate(NAV_ORG_MEAL_PLANS_MEAL_PLAN_TEMPLATES_ASSIGNMENT_STATUS);
      } catch (e) {
        setMessage("error", "Something went wrong assigning the meal plan template. Please try again in a few minutes.");
      }
    }
  };

  const onBack = () => {
    if (step === AssignMealPlanTemplateStep.NameAndType) {
      navigate(NAV_ORG_MEAL_PLANS_MEAL_PLAN_TEMPLATES);
    } else if (step === AssignMealPlanTemplateStep.DateAssignment) {
      setStep(AssignMealPlanTemplateStep.NameAndType);
    } else if (step === AssignMealPlanTemplateStep.ChooseAthletes) {
      setStep(AssignMealPlanTemplateStep.DateAssignment);
    }
  };

  return (
    <AssignMealPlanTemplateContext.Provider
      value={{
        mealPlanTemplate,
        step,
        setStep,
        onNext,
        onBack,
        name,
        setName,
        notes,
        setNotes,
        weightTargetTypeId,
        setWeightTargetTypeId,
        dateAssignment,
        setDateAssignment,
        isAutoSuggestionsEnabled,
        setIsAutoSuggestionsEnabled,
        athletes,
        setAthletes,
        goals,
      }}
    >
      {children}
    </AssignMealPlanTemplateContext.Provider>
  );
};

export const useAssignMealPlanTemplate = () => {
  return useContext(AssignMealPlanTemplateContext);
};
