import { Box, Button, Divider } from "@mui/material";
import {
  AssignMealPlanTemplateStep,
  AssignMealPlanTemplateStepper,
} from "apps/web/src/components/MealPlanTemplate/AssignMealPlanTemplate/AssignMealPlanTemplateStepper";
import React from "react";
import { useAssignMealPlanTemplate } from "./AssignMealPlanTemplateContext";

export const AssignMealPlanTemplateContent = () => {
  const { step, onNext, onBack } = useAssignMealPlanTemplate();

  return (
    <>
      <Box sx={{ pt: 3, flex: 1, minHeight: 0, overflowY: "scroll" }}>
        <AssignMealPlanTemplateStepper />
      </Box>
      <Divider />
      <Box sx={{ flex: 0, display: "flex", px: 3, pt: 3, gap: 1, flexDirection: "row", justifyContent: "flex-end" }}>
        <Button variant="outlined" onClick={onBack}>
          {step === AssignMealPlanTemplateStep.NameAndType ? "Cancel" : "Back"}
        </Button>
        <Button color="primary" onClick={onNext}>
          {step === AssignMealPlanTemplateStep.ChooseAthletes ? "Submit" : "Next"}
        </Button>
      </Box>
    </>
  );
};
