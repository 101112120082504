import { OktaAuth, OktaAuthOptions } from "@okta/okta-auth-js";

const config: OktaAuthOptions = {
  scopes: ["openid", "profile", "email", "offline_access"],
  issuer: `https://${process.env.REACT_APP_TW_OKTA_DOMAIN}/oauth2/default`,
  clientId: process.env.REACT_APP_TW_OKTA_WEB_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  pkce: true,
};

export const oktaAuth = new OktaAuth(config);
